import React, { Component } from "react"

import chroma from "chroma-js";

import { Line } from "react-chartjs-2";

import {
  Segment,
  Header,
  Dropdown,
} from "semantic-ui-react";

import { getDateArray, extractYears } from "@/helpers/weather";

class WeatherAccumulated extends Component {
  state = {
    activePlotItem: "temperature",
  };

  getSubSegment = (title, description, plotData, plotOptions) => {
    
    return (
      <article 
        className="canvas-container"
        style={{
          height: "50vh",
          padding: "1em",
          paddingTop: 0,
        }}          
      >
        <Line data={plotData} options={plotOptions} />
      </article>
    )
  };

  extractDatesAndData = (data, year, colorIndex, removeNegative=true) => {
    let dates = data && Object.keys(data).filter((x) => x.includes(year));
    dates = dates && dates.filter((x) => ["03", "04", "05", "06"].includes(x.split("-")[1]))
    dates = removeNegative ? dates && dates.filter((x) => data[x] > 0.0) : dates;

    let dailyData = dates && dates.map((x) => data[x]);
    let accData = [];

    dailyData && dailyData.reduce(function(a, b, i) {
      if (b > 0.0) {
        return (accData[i] = Math.round(a + b));
      } else {
        return (accData[i] = a);
      }
    }, 0.0);

    let colorScale = chroma.scale("dark2").colors(8)

    return (
      {
        label: year,
        data: accData,
        fill: false,
        pointRadius: 0,
        borderColor: colorScale[colorIndex],
        backgroundColor: "#ffcc72",
      }       
    )
  };

  handleItemClick = (item) => this.setState({ activePlotItem: item });

  render() {
    const {
      irradiationData,
      temperatureData,
      rainfallData,
    } = this.props;

    const {
      activePlotItem,
    } = this.state;

    let yearsArr = temperatureData && extractYears(temperatureData);
    yearsArr = yearsArr && yearsArr.reverse();
    
    let plotOptions = { 
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: true, 
        position: "bottom",
      },
      series: {
        showPoints: false,
      }
    };

    let plotIrradiationData = {
      labels: getDateArray(),
      datasets: yearsArr && yearsArr.map((x, index) => this.extractDatesAndData(irradiationData, x, index)),
    }; 

    let plotTemperatureData = {
      labels: getDateArray(),
      datasets: yearsArr && yearsArr.map((x, index) => this.extractDatesAndData(temperatureData, x, index, false))
    };  

    let plotRainfallData = {
      labels: getDateArray(),
      datasets: yearsArr && yearsArr.map((x, index) => this.extractDatesAndData(rainfallData, x, index, false))
    };  
    
    let menuItems = [
      {
        key: 'temperature',
        value: 'temperature',
        text: "Dygnsgrader",
        onClick: () => this.handleItemClick('temperature'),
      },
      {
        key: 'rainfall',
        value: 'rainfall',
        text: "Nederbörd",
        onClick: () => this.handleItemClick('rainfall'),
      },
      {
        key: 'irradiation',
        value: 'irradiation',
        text: "Solinstrålning",
        onClick: () => this.handleItemClick('irradiation'),
      },                   
    ];    

    return (
      <Segment
        style={{
          padding: "1.5em",
          fontWeight: "normal",
        }}
        loading={!irradiationData || !temperatureData || !rainfallData}
      >
        <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1em",
          }}
        >
          <Header
            as="h3"
            style={{ 
              paddingTop: "0",
              fontWeight: 500,
              color: "grey",
            }}
          >
            Ackumulerat väder under säsongen
          </Header>
                            
          <Dropdown
            className="textLink"
            options={menuItems}
            value={activePlotItem}
          />
        </div>
                  
        {activePlotItem === 'temperature' && this.getSubSegment("Dygnsgrader", "...", plotTemperatureData, plotOptions)}
        {activePlotItem === 'rainfall' && this.getSubSegment("Total nederbörd (mm)", "...", plotRainfallData, plotOptions)}
        {activePlotItem === 'irradiation' && this.getSubSegment("Total solinstrålning (W/m2)", "...", plotIrradiationData, plotOptions)}
      </Segment>
    );
  }
}

export default WeatherAccumulated;
