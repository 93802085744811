import React from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";

import { Confirm } from "semantic-ui-react";

import { cloudFunctions } from "@";

import GuidesTemplate from "@/containers/Guides/GuidesTemplate";
import MenuGuide from "@/components/guide/GuideMenu";

import SelectFields from "@/components/guide/GuideSelectFields";
import SelectStrategy from "@/components/guides/nitrogenV2/selectStrategy";
import NitrogenNeed from "@/components/guides/nitrogenV1/nitrogenNeed";
import NitrogenSupply from "@/components/guides/nitrogenV1/nitrogenSupply";
import BaseRation from "@/components/guides/nitrogenV2/baseRation";
import PlanNewRation from "@/components/guides/nitrogenV2/planNewRation";
import AdjustFiles from "@/components/guide/GuideAdjustFiles";
import DownloadFiles from "@/components/guide/GuideDownloadFiles";

import { INITIAL_STATE_TEMPLATE } from "@/containers/Guides/GuidesTemplate";

import { CONTROL_GUIDES } from "@/constants/viewGuides";

import { getCurrentDate, getPreviousYear, getCurrentYear } from "@/helpers/dates";
import { reformatBooleansInPayload } from "@/helpers/guides";

const CURRENT_GUIDE = CONTROL_GUIDES.find((x) => x.name === "nitrogenV2");
const GUIDE_NAME = CURRENT_GUIDE.name;
const GUIDE_URL = CURRENT_GUIDE.url;
// const GUIDE_ICON = CURRENT_GUIDE.icon;


const GUIDE_PURPOSE = 'kvävegödsling';
const COMPOUND_NAME = "Kväve";
const COMPOUND_TYPE = "N";
const NO_COMPOUND_UNIT = 'kg N/ha';

// const GettingStartedHeader = 'Kvävegödsling';

// const GettingStartedDescription = 'Denna guide hjälper dig att enkelt ta fram styrfiler för att variera din kvävegiva. Med hjälp av Freja kan du bygga upp styrfiler som tar hänsyn till markkartering, skördekartor och grönmassa för dina skiften. Denna informationen hämtar vi från dina uppladdade markkarteringar och skördekartor, grönmassamätningar från satellit samt markdata från den digitala åkermarkskartan.';

// const GettingStartedBeforeYouStart = 'Egna markkarteringar är alltid att föredra över den digitala åkermarkskartan. Skördekartor gör det mycket enklare att uppskatta skördepotentialen. Se till att du har laddat upp dina markkarteringar samt skördekartor för varje skifte under vyn "Utforska" innan du börjar.';

const INITIAL_STATE = {
  planningDone: false,
  guideSettings: {},
  guideCrop: '',
  guideCropRegion: '',
  guideCropQuality: '',
  guideAimRation: {},
  guideCompoundType: 'none',
  guideCompoundRation: '100.0',
  guideCompoundNutrientRation: 100.0,
  currentRationNo: 0,
  guideNoAdditionalRations: 1,
  guideRationSizes: { '0': 50.0, '1': 50.0, '2': 0.0, '3': 0.0 },
  guidePreviousRation: '0',
  guidePreviousRationCompoundType: 'none',
  guidePreviousRationCompoundWeight: '0',
  guideCompoundSpreadingBonus: false,
  guideCompoundSpreadingBonusNumber: 0.0,
  guideExpectedYield: {},
  guideExpectedYieldCalibration: {},
  guidePreviousCrops: {},
  guideIncreasedYieldFromPreviousCrop: false,
  guideSupplyFromPreviousCrop: false,
  guidePreviousCropsYieldContribution: {},
  guidePreviousCropsNitrogenContribution: {},
  guideEstimatedGroundDelivery: '0',
  guideUseHumusDelivery: false,
  manualHumus: {},
  guideHelpBaseRation: true,
  showConfirmLeaveBaseRation: false,
  showConfirmLockRation: false,
  openSegmentSelectStrategy: "crop",
  openSegmentNitrogenNeed: "aimRation",
  openSegmentNitrogenSupply: "previousCrop",
  openSegmentBaseRation: "help",
  openSegmentPlanNewRation: "updateYield",
  guideOwnEstimateOfGroundDelivery: false,
  completedStepsSelectStrategy: [],
  completedStepsNitrogenNeed: [],
  completedStepsNitrogenSupply: [],
  completedStepsBaseRation: [],
  completedStepsPlanNewRation: [],
};

class GuideNitrogenV2 extends GuidesTemplate {

  state = {
    ...this.state,
    ...INITIAL_STATE,
  };

  onClickStartOver = () => {
    this.props.setGuidesWrapperState({ selectedFieldIds: [] });
    this.setState({ ...INITIAL_STATE_TEMPLATE, ...INITIAL_STATE });
    this.onSaveGuide(GUIDE_NAME, GUIDE_URL);
    this.onGotoPreviousStep(GUIDE_URL, "selectFields", false, { ...INITIAL_STATE_TEMPLATE, ...INITIAL_STATE });
  };

  getNitrogenBaseRation = (selectedFieldIds) => {
    const {
      match: {
        params: {
          guideId,
        }
      },
      userId,
    } = this.props;

    const {
      guideNoAdditionalRations,
      currentRationNo,
      guideRationSizes,
      guideCompoundType,
      guideCompoundNutrientRation,
      guideCrop,
      guideCropRegion,
      guideCropQuality,
      guidePreviousCrops,
      guideSupplyFromPreviousCrop,
      guideUseHumusDelivery,
      guideEstimatedGroundDelivery,
      guidePreviousRation,
      manualHumus,
      guideAimRation,
      guideExpectedYield,
      guideExpectedYieldCalibration,
      guideIncreasedYieldFromPreviousCrop,
      guideCompoundSpreadingBonusNumber,
      guidePreviousCropsYieldContribution,
      guidePreviousCropsNitrogenContribution,
    } = this.state;

    let guideSettings = {
      noRations: guideNoAdditionalRations,
      rationSizes: guideRationSizes,
      currentRationNo: currentRationNo,
      crop: guideCrop,
      region: guideCropRegion ? guideCropRegion : "null",
      quality: guideCropQuality ? guideCropQuality : "null",
      compoundType: guideCompoundType,
      guideCompoundNutrientRation,
      previousRations: guidePreviousRation,
      estimatedGroundDelivery: guideEstimatedGroundDelivery,
      usePreviousCropSupply: guideSupplyFromPreviousCrop,
      previousCropsNitrogenContribution: guidePreviousCropsYieldContribution,
      useHumusMap: guideUseHumusDelivery,
      manualHumus: manualHumus,
      ration: guideAimRation,
      expectedYield: guideExpectedYield,
      expectedYieldCalibration: guideExpectedYieldCalibration,
      previousCrops: guidePreviousCrops,
      compoundSpreadBonus: guideCompoundSpreadingBonusNumber,
      usePreviousCropYield: guideIncreasedYieldFromPreviousCrop,
      previousCropsYieldContribution: guidePreviousCropsNitrogenContribution,
    };

    guideSettings = guideSettings && reformatBooleansInPayload(guideSettings);

    selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
      cloudFunctions.httpsCallable('createVraMapSuggestion')(
        {
          userId: userId,
          fieldId: fieldId,
          guideId: guideId,
          endpoint: "/v1/getNitrogenBaseRation",
          payload: guideSettings,
        }
      )
        .catch(error => this.handleError(error));
    });
  };


  getVraMapSuggestion = (selectedFieldIds) => {
    const {
      match: {
        params: {
          guideId,
        }
      },
      userId,
    } = this.props;

    const {
      guideNoAdditionalRations,
      currentRationNo,
      guideRationSizes,
      guideCompoundType,
      guideCompoundNutrientRation,
      guideCrop,
      guideCropRegion,
      guideCropQuality,
      guidePreviousCrops,
      guideSupplyFromPreviousCrop,
      guideUseHumusDelivery,
      guideEstimatedGroundDelivery,
      guidePreviousRation,
      manualHumus,
      guideAimRation,
      guideExpectedYield,
      guideExpectedYieldCalibration,
      guideIncreasedYieldFromPreviousCrop,
      guideCompoundSpreadingBonusNumber,
      guidePreviousCropsYieldContribution,
      guidePreviousCropsNitrogenContribution,
    } = this.state;

    let guideSettings = {
      noRations: guideNoAdditionalRations,
      rationSizes: guideRationSizes,
      currentRationNo: currentRationNo,
      crop: guideCrop,
      region: guideCropRegion ? guideCropRegion : "null",
      quality: guideCropQuality ? guideCropQuality : "null",
      compoundType: guideCompoundType,
      guideCompoundNutrientRation,
      previousRations: guidePreviousRation,
      estimatedGroundDelivery: guideEstimatedGroundDelivery,
      usePreviousCropSupply: guideSupplyFromPreviousCrop,
      previousCropsNitrogenContribution: guidePreviousCropsNitrogenContribution,
      useHumusMap: guideUseHumusDelivery,
      manualHumus: manualHumus,
      ration: guideAimRation,
      expectedYield: guideExpectedYield,
      expectedYieldCalibration: guideExpectedYieldCalibration,
      previousCrops: guidePreviousCrops,
      compoundSpreadBonus: guideCompoundSpreadingBonusNumber,
      usePreviousCropYield: guideIncreasedYieldFromPreviousCrop,
      previousCropsYieldContribution: guidePreviousCropsYieldContribution,
    };

    guideSettings = guideSettings && reformatBooleansInPayload(guideSettings);

    selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
      this.clearVraMap(fieldId);

      cloudFunctions.httpsCallable('createVraMapSuggestion')(
        {
          userId: userId,
          fieldId: fieldId,
          guideId: guideId,
          endpoint: "/v1/getNitrogenMap",
          payload: guideSettings,
        }
      )
        .catch(error => this.handleError(error));
    });
  };

  generateVraMapFile = (fieldId, geoJsonData) => {
    const {
      match: {
        params: {
          guideId,
        }
      },
      userId,
    } = this.props;

    const {
      currentRationNo,
    } = this.state;

    let guideCompoundNutrientRation = this.computeNutrientContent(COMPOUND_TYPE);

    const db = this.getDb();

    // Clear old files in database to trigger loading in frontend
    let rationString = typeof (currentRationNo) === "number" ? currentRationNo.toFixed(0) : "1";

    this.getUser().collection("guides").doc(guideId).update({
      ["downloadable_files_" + rationString + ".all_fields"]: '',
      ["downloadable_files_" + rationString + "." + fieldId]: '',
    });

    let geoJsonFieldKey = 'value';
    let compoundRatioContent = guideCompoundNutrientRation ? parseFloat(guideCompoundNutrientRation) : 100.0;

    let fieldName = db && fieldId && db.fields && db.fields[fieldId] && db.fields[fieldId].name;
    let dateString = getCurrentDate("YYYYMMDD");
    let fileName = fieldName.toLowerCase().replace(" ", "_") + "_kvave_" + dateString;

    cloudFunctions.httpsCallable('createVraMapFiles')(
      {
        userId: userId,
        fieldId: fieldId,
        guideId: guideId,
        endpoint: "/v1/getVraMap",
        payload: {
          file_name: fileName,
          current_ration: currentRationNo.toFixed(0),
          layer_name: "Kvävegödning",
          grid_names: ['nitrogenRation'],
          geo_json_data: geoJsonData,
          geo_json_keys: [geoJsonFieldKey],
          compound_content: compoundRatioContent,
          nutrient_name: 'kväve',
          color_map: "Spectral_r"
        }
      }
    )
      .then(res => console.log(res))
      .catch(error => this.handleError(error));
  };

  saveNewInformationToDb = () => {
    const {
      match: {
        params: {
          guideId,
        }
      },
    } = this.props;

    const {
      guideCrop,
      guideCropQuality,
      guideCropRegion,
      guidePreviousCrops,
      guideCompoundType,
      selectedFieldIds,
      manualHumus,
    } = this.state;

    let db = this.getDb();
    let dbRef = this.getUser();
    let currentYear = getCurrentYear();
    let previousYear = getPreviousYear();

    // Update guide with crop
    guideId && guideCrop && dbRef.collection("guides").doc(guideId).set({ crop: guideCrop }, { merge: true });

    // Save compound type and region to guide settings
    let currentGuideSettings = db && db.settings && db.settings.guides;
    currentGuideSettings = currentGuideSettings && GUIDE_NAME && currentGuideSettings[GUIDE_NAME];
    currentGuideSettings = currentGuideSettings ? currentGuideSettings : {};

    currentGuideSettings = { ...currentGuideSettings, compoundType: guideCompoundType, region: guideCropRegion };
    currentGuideSettings = guideCropRegion ? { ...currentGuideSettings, region: guideCropRegion } : currentGuideSettings;

    dbRef.collection("settings").doc("guides").set({ [GUIDE_NAME]: currentGuideSettings }, { merge: true });

    // Update new crop codes and qualities
    selectedFieldIds && selectedFieldIds.forEach((x) => {
      let currentFieldInfo = db && db.fieldsInfo && db.fieldsInfo[x];

      let currentCropCodes = currentFieldInfo && currentFieldInfo.crop_code;
      currentCropCodes = currentCropCodes ? currentCropCodes : {};

      let currentCropQuality = currentFieldInfo && currentFieldInfo.crop_quality;
      currentCropQuality = currentCropQuality ? currentCropCodes : {};

      currentCropCodes = guideCrop ? { ...currentCropCodes, [currentYear]: guideCrop } : currentCropCodes;
      currentCropCodes = guidePreviousCrops && guidePreviousCrops[x] ? { ...currentCropCodes, [previousYear]: guidePreviousCrops[x] } : currentCropCodes;

      currentCropQuality = guideCropQuality ? { ...currentCropQuality, [currentYear]: guideCropQuality } : currentCropQuality;

      dbRef.collection("fieldsInfo").doc(x).set({
        crop_code: currentCropCodes,
        crop_quality: currentCropQuality,
      }, { merge: true });

      manualHumus && manualHumus[x] && dbRef.collection("fieldsInfo").doc(x).set({
        average_humus_content: manualHumus[x],
      }, { merge: true });
    })
  };

  getFieldsWithoutYieldMaps = () => {
    const db = this.getDb();

    let fields = db && db.fields;
    let fieldsYieldMaps = db && db.fieldsYieldMapsProcessed;

    let fieldIdsWithoutYieldMaps = fields && Object.keys(fields);

    fieldsYieldMaps && Object.keys(fieldsYieldMaps).forEach((x) => {
      let fieldYieldMaps = fieldsYieldMaps && x && fieldsYieldMaps[x];
      let uploadedYears = fieldYieldMaps && fieldYieldMaps.rawdata_harvest_maps_geojson;

      uploadedYears = uploadedYears && Object.keys(uploadedYears) && Object.keys(uploadedYears).length;

      if (uploadedYears && uploadedYears >= 1) {
        fieldIdsWithoutYieldMaps = fieldIdsWithoutYieldMaps.filter((fieldId) => fieldId !== x);
      };
    });

    return fieldIdsWithoutYieldMaps;
  };;

  onRationDone = () => {
    const {
      currentRationNo,
    } = this.state;

    this.setState({
      currentRationNo: currentRationNo + 1,
      openSegmentPlanNewRation: "updateYield",
      completedStepsPlanNewRation: [],
    });

    this.onGotoNextStep(GUIDE_URL, "overview", true);
  };

  showNewRationSuggestions = () => {
    const {
      selectedFieldIds,
      currentRationNo,
    } = this.state;

    selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
      this.getUpdatedVraMapSuggestion(fieldId, currentRationNo, currentRationNo + 1);
    });

    this.onGotoNextStep(GUIDE_URL, "adjustFiles", true);
  };

  render() {
    const {
      match: {
        params: {
          layer,
          guideId,
        }
      },
      userId,
      userPlanType,
      cacheFieldData,
      updateCacheFieldData,
      isAdminUser,
    } = this.props;

    const {
      selectedFieldIds,
      guideSettings,
      currentRationNo,
      guideNoAdditionalRations,
    } = this.state;

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    let currentSettings = db && db.settings && db.settings.guides && db.settings.guides;
    let currentSettingsValue = currentSettings && currentSettings[GUIDE_NAME];

    let showFullGrid = currentSettingsValue &&
      typeof (currentSettingsValue['hide_help_text_col']) === "boolean" ?
      !currentSettingsValue['hide_help_text_col'] : true;

    let fieldId = null;

    let guide = guideId && db && db.guides && db.guides[guideId] && db.guides[guideId];

    let fieldsWithoutRelevantData = this.getFieldsWithoutYieldMaps();

    let menuItems = [
      {
        name: "selectFields",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Välj skiften",
        description: "Markera de skiften som du vill använda denna guide för. Bara skiften uppladdade skördekartor eller skapade skördekartor från grönmassa kan hanteras av denna guide.",
        active: !layer || layer === "selectFields",
      },
      {
        name: "planning",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Planering",
        description: "Fyll i information om vilken gröda du kommer att odla samt vilken strategi du har valt för din kvävegödsling. Du får även möjlighet att justera riktgivorna, ta hänsyn till förfrukt, restkväve och mullhalt.",
        active: ["selectStrategy", "nitrogenNeed", "nitrogenSupply"].includes(layer),
        items: [
          {
            name: "selectStrategy",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Gröda och strategi",
            description: "Fyll i information om vilken gröda du vill odla för att få förslag på en lämplig riktgiva. Freja behöver också information om den grundgiva som du har lagt samt hur stor andel av det återstående kvävebehovet som du vill lägga på.",
            active: layer === "selectStrategy",
          },
          {
            name: "nitrogenNeed",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Kvävebehov",
            description: "Justera förslaget på riktgiva, ange förväntad skörd samt ange om du vill ta hänsyn till förfrukt.",
            active: layer === "nitrogenNeed",
          },
          {
            name: "nitrogenSupply",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Kvävetillgång",
            description: "Ange uppskattad markleverans och välj om du vill ta hänsyn till förfrukt och mullhalt.",
            active: layer === "nitrogenSupply",
          },
        ]
      },
      {
        name: "baseRation",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Grundgiva",
        description: "Få hjälp att planera din grundgiva eller fyll i den givan som du redan har lagt.",
        active: layer === "baseRation",
      },
      {
        name: "ration1",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Kompletteringsgiva 1",
        description: "Få hjälp att planera din kompletteringsgiva genom att uppdatera informationen och justera förslag.",
        active: ["overview", "adjustFiles", "downloadFiles"].includes(layer) && currentRationNo === 1,
        items: [
          {
            name: "overview",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Planera giva",
            description: "Uppdatera tidigare inställningar och bekräfta den förväntad skörden. Ange gödslingsmedel och storlek på kompletteringsgivan.",
            active: layer === "overview",
          },
          {
            name: "adjustFiles",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Justera styrfiler",
            description: "Godkänn och justera det styrfilsförslag som Freja har räknat fram åt dig.",
            active: layer === "adjustFiles",
          },
          {
            name: "downloadFiles",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Hämta styrfiler",
            description: "Ladda ned en pdf-fil av styrfilen som du kan skriva ut samt styrfiler till de flesta terminaler på marknaden. Du kan även hämta en bakgrundskarta till Yaras N-sensor.",
            active: layer === "downloadFiles",
          },
        ]
      }
    ];

    menuItems = guideNoAdditionalRations >= 2 ?
      [...menuItems,
      {
        name: "ration2",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Kompletteringsgiva 2",
        description: "Få hjälp att planera din kompletteringsgiva genom att uppdatera informationen och justera förslag.",
        active: ["overview", "adjustFiles", "downloadFiles"].includes(layer) && currentRationNo === 2,
        items: [
          {
            name: "overview",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Planera giva",
            description: "Godkänn och justera det styrfilsförslag som Freja har räknat fram åt dig.",
            active: layer === "overview",
          },
          {
            name: "adjustFiles",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Justera styrfiler",
            description: "Godkänn och justera det styrfilsförslag som Freja har räknat fram åt dig.",
            active: layer === "adjustFiles",
          },
          {
            name: "downloadFiles",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Hämta styrfiler",
            description: "Ladda ned en pdf-fil av styrfilen som du kan skriva ut samt styrfiler till de flesta terminaler på marknaden. Du kan även hämta en bakgrundskarta till Yaras N-sensor.",
            active: layer === "downloadFiles",
          },
        ]
      }
      ] : menuItems;

    menuItems = guideNoAdditionalRations >= 3 ?
      [...menuItems,
      {
        name: "ration3",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Kompletteringsgiva 3",
        description: "Få hjälp att planera din kompletteringsgiva genom att uppdatera informationen och justera förslag.",
        active: ["overview", "adjustFiles", "downloadFiles"].includes(layer) && currentRationNo === 3,
        items: [
          {
            name: "overview",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Planera giva",
            description: "Uppdatera tidigare inställningar och bekräfta den förväntad skörden. Ange gödslingsmedel och storlek på kompletteringsgivan.",
            active: layer === "overview",
          },
          {
            name: "adjustFiles",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Justera styrfiler",
            description: "Godkänn och justera det styrfilsförslag som Freja har räknat fram åt dig.",
            active: layer === "adjustFiles",
          },
          {
            name: "downloadFiles",
            href: "",
            onClick: () => { },
            disabled: true,
            caption: "Hämta styrfiler",
            description: "Ladda ned en pdf-fil av styrfilen som du kan skriva ut samt styrfiler till de flesta terminaler på marknaden. Du kan även hämta en bakgrundskarta till Yaras N-sensor.",
            active: layer === "downloadFiles",
          },
        ]
      },
      ] : menuItems;

    let guideSubMenuActive = menuItems && menuItems.find((x) => x.active) && menuItems.find((x) => x.active).items;

    let commonProps = {
      firebase: this.props.firebase,
      slideDirection: this.state.slideDirection,
      params: this.props.match.params,
      db,
      getUser: this.getUser,
      userId,
      guideId,
      guideUrl: GUIDE_URL,
      guideName: GUIDE_NAME,
      isAdminUser,
      fileBucketRef,
      showFullGrid,
      userPlanType,
      selectedFieldIds,
      toggleHelpTexts: () => this.toggleHelpTexts(GUIDE_NAME),
      cacheFieldData,
      updateCacheFieldData,
      setParentState: this.setParentState,
      guideSubMenuActive,
      currentMenuItem: menuItems.find((x) => x.active),
      currentRationNo: typeof (currentRationNo) === "number" && currentRationNo.toFixed(0),
    };

    return (
      <React.Fragment>
        <Confirm
          open={this.state.showConfirmLeaveBaseRation}
          onCancel={() => this.setState({ showConfirmLeaveBaseRation: false })}
          onConfirm={() => {
            this.onGotoPreviousStep(GUIDE_URL, "nitrogenSupply", true);
            this.setState({ showConfirmLeaveBaseRation: false });
          }}
          size="tiny"
          header={"Gå tillbaka"}
          content={"Om du går tillbaka från justera förslag kommer alla förändringar som du har gjort i styrfilsförslagen att försvinna. Är du helt säker på att du vill gå tillbaka?"}
          confirmButton={"Ja"}
          cancelButton={"Nej"}
        />

        <Confirm
          open={this.state.showConfirmLockRation}
          onCancel={() => this.setState({ showConfirmLockRation: false })}
          onConfirm={() => {
            this.onRationDone()
            this.setState({ showConfirmLockRation: false });
          }}
          size="tiny"
          header={"Planera nästa giva"}
          content={"Om du väljer att börja planera nästa giva så kan du inte gå tillbaka för att justera eller ladda ner styrfiler för denna giva. Är du säker på att du vill gå vidare?"}
          confirmButton={"Ja"}
          cancelButton={"Nej"}
        />

        <MenuGuide
          db={db}
          fieldId={fieldId}
          menuItems={menuItems}
        />

        {(!layer || layer === "selectFields") &&
          <SelectFields
            {...commonProps}
            enforceSameCrop={true}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "gettingStarted", false)}
            onClickNextStep={() => this.onSaveGuide(GUIDE_NAME, GUIDE_URL, "selectStrategy")}
            onSelectField={this.onSelectField}
            fieldsWithoutRelevantData={fieldsWithoutRelevantData}
            fieldsWithoutRelevantDataReason="Detta skifte saknar skördekartor och kan därför inte användas i denna guide."
            mainColumnBody="Markera alla de skiften som du vill skapa styrfiler för kvävegödsling till. Alla markerade skiften måste odla samma gröda."
            requiredData="För att använda denna guide behöver du ha laddat upp eller skapat skördekartor från grönmassa. Guiden använder också mullhalten från uppladdade markkarteringar om dessa finns tillgängliga."
          />
        }

        {layer === "selectStrategy" &&
          <SelectStrategy
            {...this.state}
            {...commonProps}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "selectFields", true)}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "nitrogenNeed", true)}

          />
        }

        {layer === "nitrogenNeed" &&
          <NitrogenNeed
            {...this.state}
            {...commonProps}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "selectStrategy", true)}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "nitrogenSupply", true)}
          />
        }

        {layer === "nitrogenSupply" &&
          <NitrogenSupply
            {...this.state}
            {...commonProps}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "nitrogenNeed", true)}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "baseRation", true)}
          />
        }

        {layer === "baseRation" &&
          <BaseRation
            {...this.state}
            {...commonProps}
            compoundUnit={NO_COMPOUND_UNIT}
            getNitrogenBaseRation={this.getNitrogenBaseRation}
            onClickPreviousStep={() => this.setState({ showConfirmLeaveBaseRation: true })}
            onClickNextStep={() => this.onRationDone()}

          />
        }

        {layer === "overview" &&
          <PlanNewRation
            {...this.state}
            {...commonProps}
            onClickNextStep={() => this.showNewRationSuggestions()}
          />
        }

        {layer === "adjustFiles" &&
          <AdjustFiles
            {...this.state}
            {...commonProps}
            guidePurpose={GUIDE_PURPOSE}
            guideSettings={guideSettings}
            onClickPreviousStep={null}
            onClickNextStep={() => {
              this.saveNewInformationToDb();
              this.onGotoNextStep(GUIDE_URL, "downloadFiles", true);
            }}
            tryGetVraMapSuggestionAgain={this.tryGetVraMapSuggestionAgain}
            generateVraMapFile={this.generateVraMapFile}
            computeSavings={this.computeSavings}
            // editorDefaultLayer='greenmass_percent'
            // editorDefaultLayerId='latest'
            saveAdjustedVraMapToDatabase={this.saveAdjustedVraMapToDatabase}
            geoJsonFieldKey={'value'}
            layer={'vraMap_nitrogen'}
            pureCompoundName={COMPOUND_NAME}
            compoundText={COMPOUND_NAME}
          />
        }

        {layer === "downloadFiles" &&
          <DownloadFiles
            {...this.state}
            {...commonProps}
            guide={guide}
            guidePurpose={GUIDE_PURPOSE}
            fileBucketRef={fileBucketRef}
            saveNoteToGuide={this.saveNoteToGuide}
            saveInsightForGuide={this.saveInsightForGuide}
            generateVraMapFile={this.generateVraMapFile}
            compoundUnit={NO_COMPOUND_UNIT}
            noAdditionalRations={guideNoAdditionalRations}
            onClickStartOver={() => this.onClickStartOver()}
            menuItems={{
              previous: [
                {
                  caption: "Justera styrfiler",
                  onClick: () => this.onGotoPreviousStep(GUIDE_URL, "adjustFiles", false),
                }
              ],
              next: [
                {
                  caption: "Stäng guide",
                  onClick: this.onCloseGuide,
                },
                {
                  caption: currentRationNo === guideNoAdditionalRations ? "Avsluta guide" : "Planera nästa giva",
                  onClick: currentRationNo === guideNoAdditionalRations ? this.onGuideDone : () => this.setState({ showConfirmLockRation: true }),
                }
              ],
            }}
          />
        }
      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(props => {
    const {
      selectedFieldIds,
    } = props;

    let results = [
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fields" },
        ],
        storeAs: 'fields'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsInfo" },
        ],
        storeAs: 'fieldsInfo'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsWeather", doc: "farm" },
        ],
        storeAs: 'fieldsWeather'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "guides" },
        ],
        storeAs: 'guides'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "reminders" },
        ],
        storeAs: 'reminders'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "profile" },
        ],
        storeAs: 'profile'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'settings'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsYieldMapsProcessed" },
        ],
        storeAs: 'fieldsYieldMapsProcessed'
      },
    ];

    selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
      results = [...results,
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSoilMapsRaw", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSoilMapsRaw_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsMoistureMapsProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsMoistureMapsProcessed_' + fieldId
      },         
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSoilMapsProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSoilMapsProcessed_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsYieldMapsProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsYieldMapsProcessed_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSentinelHubRaw", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSentinelHubRaw_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSentinelHubProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSentinelHubProcessed_' + fieldId
      },
      ];
    });

    return results;
  }),
  connect(store => {
    return {
      firestoreData: store.firestore.data
    };
  })
)(GuideNitrogenV2);