import React, { Component } from "react";

import {
  Header,
  Popup,
  Icon,
  Menu,
  Modal,
  Button,
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import { getCurrentColorRGBA } from "@/components/fieldData/FieldDataColorScales";

import { CROP_CODES } from "@/constants/cropCodes";

class SentinelClusterAnalysis extends Component {
  state = {
    modalOpen: false,
    stateLayerId: "mean",
  };

  toggleModalOpen = () => {
    const currentValue = this.state.modalOpen;
    currentValue === true && this.setState({ stateLayerId: "mean" })
    this.setState({ modalOpen: !currentValue });
  };

  setStateLayerId = (id) => {
    this.setState({ stateLayerId: id })
  };

  render() {
    const {
      fieldInfo,
      layer,
      layerId,
      statJson,
      selectedGroup,
      colorScales,
      legendVisible,
      toggleLegendVisible,
    } = this.props;

    const {
      stateLayerId,
      modalOpen,
    } = this.state;

    const localLayerId = stateLayerId ? stateLayerId : layerId;

    let caption = '';
    caption = layer === "greenmassAnalysis_clustering" ? "grönmassa" : caption;
    caption = layer === "moistureAnalysis_clustering" ? "fuktighet" : caption;
    caption = layer === "laiAnalysis_clustering" ? "bladyta" : caption;
    caption = layer === "psriAnalysis_clustering" ? "morgnadsgrad" : caption;
  
    let description = "";
    description = layer === "greenmassAnalysis_clustering" ? "Denna karta visar hur den typiska grönmassan för varje grupp varierar från år till år. Grönmassan hos de flesta punkterna som tillhör varje grupp varierar på samma sätt." : description;
    description = layer === "moistureAnalysis_clustering" ? "Denna karta visar hur den typiska fukthalten för varje grupp varierar från år till år. Fukthalten hos de flesta punkterna som tillhör varje grupp varierar på samma sätt." : description;
    description = layer === "laiAnalysis_clustering" ? "Denna karta visar hur den typiska badytan för varje grupp varierar från år till år. Bladytan hos de flesta punkterna som tillhör varje grupp varierar på samma sätt." : description;
    description = layer === "psriAnalysis_clustering" ? "Denna karta visar hur den typiska mognaden för varje grupp varierar från år till år. Mognaden hos de flesta punkterna som tillhör varje grupp varierar på samma sätt." : description;

    let descriptionModal = "";
    descriptionModal = layer === "greenmassAnalysis_clustering" ? "I denna vy kan du se hur grönmassan utvecklade sig för varje vecka i de olika områdena som Freja har hittat på skiftet. Du kan exempelvis undersöka när tillväxtspruten inleddes och vilka områden som växte till i början eller slutet av spurten." : descriptionModal;

    descriptionModal = layer === "moistureAnalysis_clustering" ? "I denna vy kan du se hur fuktigheten utvecklade sig för varje vecka i de olika områdena som Freja har hittat på skiftet. Du kan exempelvis undersöka när skiftet började torka upp i början av säsongen och hur fuktigheten i grödan utvecklade sig." : descriptionModal;

    descriptionModal = layer === "laiAnalysis_clustering" ? "I denna vy kan du se hur bladytan utvecklade sig för varje vecka i de olika områdena som Freja har hittat på skiftet. Du kan exempelvis undersöka när tillväxtspruten inleddes och vilka områden som växte till i början eller slutet av spurten." : descriptionModal;

    descriptionModal = layer === "psriAnalysis_clustering" ? "I denna vy kan du se hur mognadsgraden utvecklade sig för varje vecka i de olika områdena som Freja har hittat på skiftet. Du kan exempelvis undersöka när mognaden inleddes och vilka områden som mognade till i början eller slutet av spurten." : descriptionModal;

    let clusteringStats = statJson && statJson.filtering_clustering && statJson.filtering_clustering;
    clusteringStats = clusteringStats && layerId && clusteringStats[layerId];

    let yearsArr = clusteringStats && clusteringStats["0"] && Object.keys(clusteringStats["0"]).map(x => x);
    let typicalValuesCluster = clusteringStats && Object.keys(clusteringStats).map(x => clusteringStats[x][localLayerId]);

    let datesArr = typicalValuesCluster && typicalValuesCluster["0"] && Object.keys(typicalValuesCluster["0"]).map((x) => x);
    typicalValuesCluster = typicalValuesCluster && Object.keys(typicalValuesCluster).map((x) => typicalValuesCluster[x]);

    const noClusters = colorScales && colorScales.colorLimits.length - 1;
    typicalValuesCluster && typicalValuesCluster.splice(noClusters, -1);

    const plotData = {
      labels: datesArr && datesArr,
      datasets:
        typicalValuesCluster &&
        typicalValuesCluster.map((x, index) => ({
          label: "Område" + (index + 1).toFixed(0),
          data: Object.keys(x).map((week) => x[week]),
          steppedLine: true,
          fill: false,
          borderColor: getCurrentColorRGBA(colorScales, index, selectedGroup),
          backgroundColor: getCurrentColorRGBA(colorScales, index, selectedGroup)
        }))
    };

    let currentCropCode = (localLayerId && localLayerId !== "mean") && fieldInfo &&
      fieldInfo.crop_code &&
      fieldInfo.crop_code[localLayerId] &&
      fieldInfo.crop_code[localLayerId];

    let cropShortText = currentCropCode && CROP_CODES.find(x => x.value === currentCropCode);
    cropShortText = cropShortText && cropShortText.shorttext;    

    if (modalOpen) {
      return (
        <Modal
          open={modalOpen}
          style={{
            padding: "1em",
          }}
        >
          <Modal.Header
            style={{ 
              fontWeight: 500,
            }}
          >
            Förändring i {caption && " " + caption}
            {cropShortText && " för gröda: " + cropShortText + '.'}
          </Modal.Header>

          <Modal.Content>
            <p
            style={{
              paddingBottom: "1em",
              fontSize: "110%",
            }}>
              {descriptionModal && descriptionModal}
            </p>

            <Menu
              pointing
              fluid
              secondary
              style={{
                paddingLeft: 0,
                textAlign: "center",
                paddingBottom: "1em",
              }}
              widths={yearsArr.length}
            >
              {yearsArr.map((x) => {
                return (
                  <Menu.Item
                    key={"menuItem_year_" + x}
                    active={(typeof localLayerId === "number" ? localLayerId.toFixed(0) : localLayerId) === x}
                    onClick={() => this.setStateLayerId(x)}
                  >
                    {x === "mean" ? "Genomsnitt" : x}
                  </Menu.Item>
                )
              })}
            </Menu>

            <article
              className="canvas-container"
              style={{
                width: "100%",
                height: "50vh",
                background: "transparent",
                padding: "1em"
              }}
            >
              <Line
                data={plotData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  layout: {
                    padding: {
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0
                    }
                  },
                  animation: {
                    duration: 0
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                      fontSize: 14,
                      fontColor: 'black',
                      padding: 20,
                    }
                  },
                  scales: {
                    yAxes: [{
                      ticks: {
                        fontSize: 14,
                        fontColor: 'lightgrey',
                      },
                      scaleLabel: {
                        display: true,
                        fontSize: 18,
                        fontColor: 'white',
                        labelString: "Grönmassaindex",
                      }
                    }],
                    xAxes: [{
                      ticks: {
                        fontSize: 14,
                        fontColor: 'lightgrey',
                      },
                      scaleLabel: {
                        display: true,
                        fontSize: 18,
                        fontColor: 'black',
                        labelString: "Veckonummer",
                      }
                    }],
                  }
                }}
              />
            </article>
          </Modal.Content>
          <Modal.Actions
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div />
            <Button
              style={{
                backgroundColor: "#868D8E",
                color: "white",
              }}
              onClick={() => this.toggleModalOpen()}
            >
              Stäng
              </Button>
          </Modal.Actions>
        </Modal>
      )
    } else {
      return (
        <React.Fragment>
          <div
            style={{
              marginTop: 0,
              marginBottom: 0,
              padding: 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Header
              as="h4"
              style={{
                marginTop: 0,
                color: 'white',
                fontWeight: 500,
              }}
            >
              Förändring i {caption && " " + caption}

                <Popup
                  position="top center"
                  trigger={
                    <Icon 
                      name="question circle" 
                      style={{ 
                        marginLeft: '0.25em', 
                        fontSize: '80%', 
                        lineHeight: '80%'
                      }} 
                    />              
                  }
                  style={{
                    zIndex: '1000',
                  }}
                  content={description}
              />
            </Header>

            <div>
              <Icon
                style={{
                  color: 'white',
                  right: 0,
                }}
                name={'expand'}
                onClick={() => this.toggleModalOpen()}
              />
              <Icon
                style={{
                  color: 'white',
                  right: 0,
                }}
                name={legendVisible ? 'chevron down' : 'chevron up'}
                onClick={() => toggleLegendVisible()}
              />
            </div>
          </div>
          
          {legendVisible &&
            <article
              className="canvas-container"
              style={{
                width: "100%",
                height: "30vh",
                background: "transparent",
                padding: "1em"
              }}
            >
              <Line
                data={plotData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  layout: {
                    padding: {
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0
                    }
                  },
                  legend: {
                    display: false,
                  },
                  scales: {
                    yAxes: [{
                      ticks: { fontColor: "white" },
                      gridLines: { color: "#868D8E" },
                    }],
                    xAxes: [{
                      ticks: { fontColor: "white" },
                      gridLines: { color: "#868D8E" },
                    }],
                  }
                }}
              />
            </article>
          }
        </React.Fragment>
      )
    }
  }
};

export default SentinelClusterAnalysis;
