import React, { Component } from 'react';

import {
  Map as LeafletMap,
  Marker as LeafletMarker,
  TileLayer,
  Polygon,
  CircleMarker,
  } from 'react-leaflet';

import L from 'leaflet';

import { 
  Button,
  Icon,
} from 'semantic-ui-react';

import HouseIcon from '@/assets/icons/map/icon_house.png';

const iconHouse = new L.Icon({
  iconUrl: HouseIcon,
  iconRetinaUrl: HouseIcon,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowAnchor: null,
  iconSize: new L.Point(50, 50),
});

const INITIAL_STATE = {
  errorMessage: '',
  position: [],
};

class AddNewFieldsPlaceField extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  clickMap = ({ latlng }) => {
    const currentPosition = this.state.position;
    this.setState({position: [...currentPosition, latlng]});
  };

  onClearPosition = () => {
    this.setState({position: []});
  };

  onUndoPosition = () => {
    const currentPosition = this.state.position;
    this.setState({position: currentPosition.filter((x, index) => index !== currentPosition.length - 1)})
  };

  render () {
    const {
      db,
    } = this.props;

    const {
      position,
    } = this.state;  
  
    let farmPosition = db && db.profile && db.profile.farm && db.profile.farm.location;
    farmPosition = farmPosition && {lng: farmPosition.longitude, lat: farmPosition.latitude};
    farmPosition = farmPosition ? farmPosition : {lat: 58.41086, lng: 15.62157};
        
    return (
      <React.Fragment>
        <LeafletMap
          center={farmPosition}
          zoom={14}
          minZoom={8}
          maxZoom={18}
          style={{
            height: '67vh',
            width: '100%',
            cursor: "initial",
          }}
          onClick={this.clickMap}
        >
    
          <TileLayer 
            url="https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?app_id=6Cy9N8yG1SZYlO61DQC7&app_code=6yLDAiRRtx2-9-7Wkn4M4g&lg=eng"
          />

          {position && position.map((point, idx) => (
            <CircleMarker
              key={"marker" + idx}
              center={point}
              color='rgba(239, 181, 46, 1.0)'
              fillColor={'rgba(239, 181, 46, 1.0)'}
              fillOpacity={1.0}
              radius={4}
              zIndex={100}                      
            />
          ))};   

          {position &&
            <Polygon
              positions={position}
              color='rgba(239, 181, 46, 0.9)'
              fillColor={'rgba(239, 181, 46, 0.7)'}
              fillOpacity={1.0}
              weight={3}
            />
          };        

          {farmPosition && 
            <LeafletMarker
              position={farmPosition}
              icon={iconHouse}
            />
          }
        </LeafletMap>  
        
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div />
          <Button
            icon
            negative
            labelPosition='left'
            floated='left'
            style={{
              marginTop: '1em',
            }}
            onClick={() => this.onClearPosition()}
          >
            Rensa punkter
            <Icon name='trash' />
          </Button>

          <Button
            icon
            negative
            labelPosition='left'
            floated='right'
            style={{
              marginLeft: "1em",
              marginTop: '1em',
            }}
            onClick={() => this.onUndoPosition()}
          >
            Ta bort punkt
            <Icon name='undo' />
          </Button>

          <Button
            primary
            icon
            labelPosition='left'
            floated='right'
            style={{
              marginLeft: "1em",
              marginTop: '1em',
            }}
            disabled={!position || position.length < 3}
            onClick={() => this.props.addFieldManually(position)}
          >
            Spara skifte
            <Icon name='save' />
          </Button>          
          <div />
        </div>
      </React.Fragment>
    );
  };
};

export default AddNewFieldsPlaceField;
