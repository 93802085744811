import React, { Component } from "react";

import { 
  Header, 
  Input,
  Dropdown,
  Segment,
  Table,
} from "semantic-ui-react";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideMapPreview from "@/components/guide/GuideMapPreview";
import GuideSegment from "@/components/guide/GuideSegment";

import LockIcon from "@/components/misc/LockIcon";

import { checkForHumusInformation } from "@/helpers/guides";

import { CROP_CODES } from "@/constants/cropCodes";
import { PREVIOUS_CROP_NITROGEN_SUPPLY } from "./constants";

const INITIAL_STATE = {
  overrideDisabledPreviousCrop: [],
};
class NitrogenNeed extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickNextStep = () => {
    const {
      completedStepsNitrogenSupply,
    } = this.props;

    this.props.setParentState({
      openSegmentNitrogenSupply: '',
      completedStepsNitrogenSupply: [...completedStepsNitrogenSupply, "groundDelivery", "remainingNitrogen"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment, data=null, forceUpdate=false) => {
    const {
      db,
      selectedFieldIds,
      guidePreviousCrops,
      manualHumus,
    } = this.props;

    const {
      overrideDisabledPreviousCrop,
    } = this.state;

    switch(newSegment) {

        case "previousCrop":
          let previousCrops = {};
          let newValueOverride = [];
    
          selectedFieldIds && selectedFieldIds.forEach((x) => {
            let fieldInfoDb = db && x && db.fieldsInfo && db.fieldsInfo[x];
            let cropCodes = fieldInfoDb && fieldInfoDb.crop_code;
            let previousYear = (new Date().getFullYear() - 1).toFixed(0);
      
            if (cropCodes && previousYear && cropCodes[previousYear]) {
              previousCrops = {...previousCrops, [x]: cropCodes[previousYear]}
            } else {
              previousCrops = {...previousCrops, [x]: '0'}
            };

            newValueOverride = previousCrops[x] === '0' ? [...newValueOverride, x] : newValueOverride;
          });

          this.setState({ overrideDisabledPreviousCrop: [...overrideDisabledPreviousCrop, ...newValueOverride] });

          !forceUpdate && this.props.setParentState({ guidePreviousCrops: { ...previousCrops, ...guidePreviousCrops } });
          forceUpdate && this.props.setParentState({ guidePreviousCrops: previousCrops });

          this.setNitrogenContributionFromPreviousCrop({ ...previousCrops, ...guidePreviousCrops });
          break;

        case "humus":
          let fieldsSoilMaps = db && db.fieldsSoilMapsProcessed;
          let newManualHummus = {};
      
          selectedFieldIds && selectedFieldIds.forEach((x) => {
            let allKeys = [];
            let fieldInfoDb = db && x && db.fieldsInfo && db.fieldsInfo[x];
            let humusContent = fieldInfoDb && fieldInfoDb.average_humus_content ? fieldInfoDb.average_humus_content : "0";
      
            let fieldSoilMaps = fieldsSoilMaps && x && fieldsSoilMaps[x];
            let uploadedKeys = fieldSoilMaps && fieldSoilMaps.rawdata_uploaded_soilmap_available_keys;
            
            uploadedKeys && Object.keys(uploadedKeys).forEach((year) => {
              allKeys = [...allKeys, ...uploadedKeys[year]];
            });
      
            if (!allKeys.includes("organic")) {
              newManualHummus = {...newManualHummus, [x]: humusContent};
            };
          });

          !forceUpdate && this.props.setParentState({ manualHumus: {...newManualHummus, ...manualHumus }});
          forceUpdate && this.props.setParentState({ manualHumus: newManualHummus });
        break;          

      default:
        break;

    }
  };

  setNitrogenContributionFromPreviousCrop = (guidePreviousCrops) => {
    const {
      guideCrop,
    } = this.props;

    // Nitrogen supply from previous crops
    let newValue = {};

    guidePreviousCrops && Object.keys(guidePreviousCrops).forEach((fieldId) => {
      let cropEffect = '0'

      let lastCrop = guidePreviousCrops[fieldId];
      
      let cropsWithEffect = Object.keys(PREVIOUS_CROP_NITROGEN_SUPPLY).map((x) => x);

      if (lastCrop && cropsWithEffect.includes(lastCrop)) {
        let cropsEffected = Object.keys(PREVIOUS_CROP_NITROGEN_SUPPLY[lastCrop]).map((x) => x);
        if (cropsEffected.includes(guideCrop)) {
          cropEffect = PREVIOUS_CROP_NITROGEN_SUPPLY[lastCrop][guideCrop].toFixed(0);
        };
      };

      newValue = {...newValue, [fieldId]: cropEffect}
    });

    this.props.setParentState({ guidePreviousCropsNitrogenContribution: newValue})
  };

  updateNitrogenContributionFromPreviousCrop = (fieldId, lastCrop) => {
    const {
      guideCrop,
      guidePreviousCropsNitrogenContribution,
    } = this.props;

    // Nitrogen supply from previous crops
    let cropEffect = '0'
    
    let cropsWithEffect = Object.keys(PREVIOUS_CROP_NITROGEN_SUPPLY).map((x) => x);

    if (lastCrop && cropsWithEffect.includes(lastCrop)) {
      let cropsEffected = Object.keys(PREVIOUS_CROP_NITROGEN_SUPPLY[lastCrop]).map((x) => x);
      if (cropsEffected.includes(guideCrop)) {
        cropEffect = PREVIOUS_CROP_NITROGEN_SUPPLY[lastCrop][guideCrop].toFixed(0);
      };
    };

    let newValue = {...guidePreviousCropsNitrogenContribution, [fieldId]: cropEffect}

    this.props.setParentState({ guidePreviousCropsNitrogenContribution: newValue})
  };

  onChange = (data, onlyNumbers=true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  }; 

  onChangeStruct = (e, data, variableName, onlyNumbers=true) => {
    let currentValue = this.props[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: {...currentValue, [data.name]: value },
    });
  }; 

  setOpenSegment = (newSegment) => {
    const {
      openSegmentNitrogenSupply
    } = this.props;

    (openSegmentNitrogenSupply === newSegment) && this.props.setParentState({ openSegmentNitrogenSupply: null });
    (openSegmentNitrogenSupply !== newSegment) && this.props.setParentState({ openSegmentNitrogenSupply: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedStepsNitrogenSupply, 
    } = this.props;

    !completedStepsNitrogenSupply.includes(currentSegment) && this.props.setParentState({
      completedStepsNitrogenSupply: [...completedStepsNitrogenSupply, currentSegment],
    });

    this.setDefaultSettings(newSegment);
    !completedStepsNitrogenSupply.includes(newSegment) && this.setOpenSegment(newSegment);
    completedStepsNitrogenSupply.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  render() {

    const {
      db,
      userId,
      fileBucketRef,
      guideMode,
      selectedFieldIds,
      guidePreviousCropsNitrogenContribution,
      guideEstimatedGroundDelivery,
      guidePreviousCrops,
      manualHumus,
      openSegmentNitrogenSupply,
      completedStepsNitrogenSupply,
      guideOwnEstimateOfGroundDelivery,
    } = this.props;

    const {
      overrideDisabledPreviousCrop,
    } = this.state;
    
    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],      
      next: [
        {
          caption: "Gå vidare",
          hidden: completedStepsNitrogenSupply.length < 3,
          onClick: this.onClickNextStep,
        }
      ],
    };

    let hasAllPreviousCrops = guidePreviousCrops && !Object.keys(guidePreviousCrops).map((x) => guidePreviousCrops[x]).includes("0");

    let fieldIdsMissingHumus = checkForHumusInformation(db, selectedFieldIds);
    let needsHumusInformation = fieldIdsMissingHumus && fieldIdsMissingHumus.length > 0;
    
    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegment
          caption="Metod för att uppskatta kvävetillgång"
          simpleModeQuestion="Har du en egen uppskattning av markleveransen?"
          simpleModeDescription="Freja kan hjälpa dig att uppskatta markleveransen i tre enkla steg om du inte redan har egen uppskattning från en nollruta, egen erfarenhet eller råd från en rådgivare."
          open={openSegmentNitrogenSupply === "estimationMethod"}
          complete={completedStepsNitrogenSupply && completedStepsNitrogenSupply.includes("estimationMethod")}
          onClickOpenSegment={() => this.setOpenSegment("estimationMethod")}
          disabled={openSegmentNitrogenSupply !== "estimationMethod" && !completedStepsNitrogenSupply.includes("estimationMethod")}
          simpleModeOpenText="Ja"
          simpleModeOnOpen={() => {
              this.props.setParentState({ guideOwnEstimateOfGroundDelivery: true });
              this.props.setParentState({ guideSupplyFromPreviousCrop: false });
              this.props.setParentState({ guideUseHumusDelivery: false });
              this.gotoNextStep("estimationMethod", "groundDelivery");
            }
          }
          simpleModeSkipText="Nej"
          simpleModeOnSkip={() => {
            this.props.setParentState({ guideOwnEstimateOfGroundDelivery: false });
            this.gotoNextStep("estimationMethod", "previousCrop");
          }}
        />    

        <GuideSegment
          caption="Kväveeffektverkan från förfrukt"
          hidden={guideOwnEstimateOfGroundDelivery}
          skipSimpleMode={guideMode && guideMode === "advanced"}
          simpleModeQuestion="Vill du att Freja tar hänsyn till förfruktens kväveleverans?"
          simpleModeDescription="Vissa förfrukter fixerar kväve i marken som grödan under nästa år kan använda för sin tillväxt. Freja kan hjälpa till att ta hänsyn till detta tillskott av kväve så att du kan minska min kvävegiva och spara pengar."          
          open={openSegmentNitrogenSupply === "previousCrop"}
          complete={completedStepsNitrogenSupply && completedStepsNitrogenSupply.includes("previousCrop")}
          disabled={openSegmentNitrogenSupply !== "previousCrop" && !completedStepsNitrogenSupply.includes("previousCrop")}
          onClickOpenSegment={() => this.setOpenSegment("previousCrop")}
          onClickNextStep={() => this.gotoNextStep("previousCrop", "humus")}
          simpleModeOpenText="Ja"
          simpleModeOnOpen={hasAllPreviousCrops ? 
            () => {
              this.props.setParentState({ guideSupplyFromPreviousCrop: true });
              this.gotoNextStep("previousCrop", "humus");
            } :
            null
          }
          simpleModeAndOpen={hasAllPreviousCrops ? 
            null : 
            () => {
              this.props.setParentState({ guideSupplyFromPreviousCrop: true });
            }
          }
          simpleModeSkipText="Nej"
          simpleModeOnSkip={() => {
            this.props.setParentState({ guideSupplyFromPreviousCrop: false });
            this.gotoNextStep("previousCrop", "humus");
          }}
          editButtonText="Ta inte hänsyn till förfrukt"
          onClickEdit={() => {
            this.props.setParentState({ guideSupplyFromPreviousCrop: false });
            this.gotoNextStep("previousCrop", "humus");
          }}
        disableNextStep={guidePreviousCropsNitrogenContribution && Object.keys(guidePreviousCropsNitrogenContribution).find((fieldId) => !guidePreviousCropsNitrogenContribution[fieldId] || guidePreviousCropsNitrogenContribution[fieldId].length === 0)}
        >       
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 30,
              width: "80%",
              fontSize: "110%",
            }}
          >
            Freja kan lägga till den kväveeffekt som normalt kommer från förfrukten. 
            Storleken på detta kvävetillskott kommer från Jordbruksverkets rekommendationer och är lika stor över hela skiften.
          </p>
          
          <Table 
            celled 
            striped 
            columns="3"
            style={{
              marginTop: "4em",
              maxWidth: "100%",
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Skifte</Table.HeaderCell>
                <Table.HeaderCell>Förfrukt</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Kväveeffekt (kg N/ha)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {selectedFieldIds && selectedFieldIds.map((x) => {
                  let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;                  

                  return (
                    <Table.Row
                      key={"previousCropDropdown_" + x}
                    >
                      <Table.Cell textAlign="center">{fieldName && fieldName}</Table.Cell>

                      <Table.Cell>
                        <Dropdown
                          style={{
                            width: "50%"
                          }}
                          selection
                          options={CROP_CODES}
                          name={x}
                          onChange={(e, d) => {
                            this.onChangeStruct(e, d, "guidePreviousCrops", false);
                            this.updateNitrogenContributionFromPreviousCrop(x, d.value);
                          }}
                          disabled={!overrideDisabledPreviousCrop.includes(x) && (guidePreviousCrops && guidePreviousCrops[x])}
                          value={guidePreviousCrops[x] ? guidePreviousCrops[x] : ''}
                        />

                        <LockIcon 
                          locked={!overrideDisabledPreviousCrop.includes(x)}
                          onClickUnlock={() => this.setState({ overrideDisabledPreviousCrop: [...overrideDisabledPreviousCrop, x] })}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Input 
                          name={x}
                          onChange={(e, d) => this.onChangeStruct(e, d, "guidePreviousCropsNitrogenContribution", true)}
                          value={guidePreviousCropsNitrogenContribution && guidePreviousCropsNitrogenContribution[x]}
                          error={!guidePreviousCropsNitrogenContribution || !guidePreviousCropsNitrogenContribution[x] || guidePreviousCropsNitrogenContribution[x].length === 0}                          
                        />
                      </Table.Cell> 
                    </Table.Row>
                )})}
              </Table.Body>
            </Table>
        </GuideSegment>

        <GuideSegment
          caption="Mullhalt"
          hidden={guideOwnEstimateOfGroundDelivery}
          skipSimpleMode={guideMode && guideMode === "advanced"}
          simpleModeQuestion="Vill du ta hänsyn till kväveleverans från mull?"
          simpleModeDescription="Fält med god mullhalt lagrar kväve i marken som grödan kan använda för sin tillväxt. Freja kan hjälpa till att ta hänsyn till detta tillskott av kväve från mullen så att du kan minska min kvävegiva och spara pengar."          
          open={openSegmentNitrogenSupply === "humus"}
          disabled={openSegmentNitrogenSupply !== "humus" && !completedStepsNitrogenSupply.includes("humus")}
          complete={completedStepsNitrogenSupply && completedStepsNitrogenSupply.includes("humus")}
          simpleModeOpenText="Ja"
          simpleModeAndOpen={needsHumusInformation ?
            () => {
              this.props.setParentState({ guideUseHumusDelivery: true });
            }:
            null
          }
          simpleModeOnOpen={needsHumusInformation ? 
            null : 
            () => {
              this.props.setParentState({ guideUseHumusDelivery: true });
              this.gotoNextStep("humus", "remainingNitrogen");
            }
          }          
          simpleModeSkipText="Nej"
          simpleModeOnSkip={() => {
            this.props.setParentState({ guideUseHumusDelivery: false });
            this.gotoNextStep("humus", "remainingNitrogen");
          }}   
          onClickOpenSegment={() => this.setOpenSegment("humus")}
          onClickNextStep={() => this.gotoNextStep("humus", "remainingNitrogen")}
          doneButtonText="Ta hänsyn till mullhalt"
          editButtonText="Ta inte hänsyn till angiven mullhalt"
          onClickEdit={() => {
            this.props.setParentState({ guideUseHumusDelivery: false });
            this.gotoNextStep("humus", "remainingNitrogen");
          }}              
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 0,
              width: "80%",
              fontSize: "110%",
            }}
          >
            Freja kan ta hänsyn till kvävetillskottet som kommer från mullhalten genom att använda din uppladdad markkartering med mullhaltskarta.
            Saknar ditt skifte denna information så behöver du komplettera detta genom att fylla genomsnittlig mullhalt nedanför.
          </p>
          
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 30,
              width: "80%",
              fontSize: "110%",
            }}
          >
            Jordbruksverkets rekommendationer används och tillskottet beräknas som 2 kg N/ha per procentenhet mull. 
            Områden med under 4% mullhalt får inget kvävebidrag från mullen.
          </p>

          <Table 
            celled 
            striped 
            columns="3"
            style={{
              marginTop: "4em",
              maxWidth: "100%",
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Skifte</Table.HeaderCell>
                <Table.HeaderCell>Mullhalt (%)</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
            {fieldIdsMissingHumus && fieldIdsMissingHumus.map((x) => {
                let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;

                return (
                  <Table.Row
                    key={"humusDropdown_" + x}
                  >
                    <Table.Cell textAlign="center">{fieldName && fieldName}</Table.Cell>

                    <Table.Cell>
                      <Input
                        fluid
                        name={x}
                        onChange={(e, d) => this.onChangeStruct(e, d, "manualHumus", true)}
                        value={manualHumus[x]}
                      />
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
              )})}

              {selectedFieldIds && fieldIdsMissingHumus && selectedFieldIds
                .filter((x) => !fieldIdsMissingHumus.includes(x))
                .map((x) => {
                  let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;

                  return (
                    <Table.Row
                      key={"humusExistsRow_" + x}
                    >
                      <Table.Cell textAlign="center">{fieldName && fieldName}</Table.Cell>
                      <Table.Cell>Mullkarta finns i uppladdad markkartering.</Table.Cell>
                      <Table.Cell textAlign="center">
                        <GuideMapPreview
                          db={db}
                          fileBucketRef={fileBucketRef}
                          userId={userId}
                          fieldId={x}
                          layer="soilMapUploaded_organic"
                          layerId="latest"
                          popupText="Klicka för att visa mullhaltskartan."
                        />
                      </Table.Cell>
                    </Table.Row>
              )})}
              </Table.Body>
            </Table>
        </GuideSegment>   

        <GuideSegment
          caption="Restkväve"
          hidden={guideOwnEstimateOfGroundDelivery}
          skipSimpleMode={guideMode && guideMode === "advanced"}
          simpleModeQuestion="Har du en uppskattning av restkväve?"
          simpleModeDescription="Restkväve kan exempelvis vara gödsel som du lade på under förra året som inte togs upp av grödan och han stannat kvar i marken under vintern. Freja kan hjälpa till att ta hänsyn till detta tillskott av kväve så att du kan minska min kvävegiva och spara pengar."
          open={openSegmentNitrogenSupply === "remainingNitrogen"}
          complete={completedStepsNitrogenSupply && completedStepsNitrogenSupply.includes("remainingNitrogen")}
          disabled={openSegmentNitrogenSupply !== "remainingNitrogen" && !completedStepsNitrogenSupply.includes("remainingNitrogen")}
          onClickOpenSegment={() => this.setOpenSegment("remainingNitrogen")}
          onClickNextStep={() => this.onClickNextStep()}
          simpleModeAndSkip={() => this.props.setParentState({ guideEstimatedGroundDelivery: '0' })}
          disableNextStep={!guideEstimatedGroundDelivery || guideEstimatedGroundDelivery.length === 0}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 15,
              width: "80%",
              fontSize: "110%",
            }}
          >
            Ange en uppskattning av den genomsnittliga mängden restkväve som finns kvar på skiftet.
          </p>

          <Segment
            secondary
            style={{
              marginTop: "5em",
              marginBottom: 0,
              width: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "2em",
            }}
          >
            <div
              style={{
                borderBottom: "thin solid black",
              }}
            >
              <Header 
                as="h4"
                style={{
                  marginBottom: "1.5em",
                  fontWeight: 500,
                }}
              >
                Restkväve (kg N/ha)
              </Header>

              <Input
                transparent
                fluid
                autoFocus
                style={{
                  fontSize: "250%",
                }}
                value={guideEstimatedGroundDelivery}
                name="guideEstimatedGroundDelivery"
                onChange={(e, d) => this.onChange(d, true)}
                error={!guideEstimatedGroundDelivery || guideEstimatedGroundDelivery.length === 0}
              />   
            </div>
          </Segment>
        </GuideSegment> 

        <GuideSegment
          caption="Uppskattad markleverans"
          hidden={!guideOwnEstimateOfGroundDelivery}
          open={openSegmentNitrogenSupply === "groundDelivery"}
          complete={completedStepsNitrogenSupply && completedStepsNitrogenSupply.includes("groundDelivery")}
          disabled={openSegmentNitrogenSupply !== "groundDelivery" && !completedStepsNitrogenSupply.includes("groundDelivery")}
          onClickOpenSegment={() => this.setOpenSegment("groundDelivery")}
          onClickNextStep={() => this.onClickNextStep()}
          simpleModeAndSkip={() => this.props.setParentState({ guideEstimatedGroundDelivery: '0' })}
          disableNextStep={!guideEstimatedGroundDelivery || guideEstimatedGroundDelivery.length === 0}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 15,
              width: "80%",
              fontSize: "110%",
            }}
          >
            Ange en uppskattad markleverans från en egen nollruta eller växtodlingsrådgivare som gäller på skiftet.
            Denna leverans brukar vanligtvis inkludera långtidsverkan av stallgödsel, restkväve, kväveeffektsverkan av förfrukt och kvävetillförsel från mull.
          </p>

          <Segment
            secondary
            style={{
              marginTop: "5em",
              marginBottom: 0,
              width: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "2em",
            }}
          >
            <div
              style={{
                borderBottom: "thin solid black",
              }}
            >
              <Header 
                as="h4"
                style={{
                  marginBottom: "1.5em",
                  fontWeight: 500,
                }}
              >
                Uppskattad markleverans (kg N/ha)
              </Header>

              <Input
                transparent
                fluid
                autoFocus
                style={{
                  fontSize: "250%",
                }}
                value={guideEstimatedGroundDelivery}
                name="guideEstimatedGroundDelivery"
                error={!guideEstimatedGroundDelivery || guideEstimatedGroundDelivery.length === 0}
                onChange={(e, d) => this.onChange(d, true)}
              />   
            </div>
          </Segment>
        </GuideSegment>        
      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader="Uppskatta kvävetillgången"
        mainColumnBody="För att kunna beräkna kvävetillgången behöver du fylla i informationen nedanför. Svara på alla frågor för att gå vidare till nästa steg."
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default NitrogenNeed;