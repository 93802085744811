import React, { Component } from "react";

import { 
  Segment,
  Icon,
  Button,
} from "semantic-ui-react";

const CORRELATION_SCORE_COLOR = {
  1: "#868D8E",
  2: "#C36126",
  3: "#F4A14E",
  4: "#FBBD08",
  5: "#4C8440",
}

const INITIAL_STATE = {};

class MapOverviewCorrelationWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render () {
    const {
      correlationCoef,
    } = this.props;

    if (!correlationCoef || typeof(correlationCoef) !== "number") {
      return <div></div>;
    };

    let correlationLabel = 'inget';
    correlationLabel = correlationCoef >= 0.2 ? "svagt" : correlationLabel;
    correlationLabel = correlationCoef >= 0.4 ? "ganska svagt" : correlationLabel;
    correlationLabel = correlationCoef >= 0.6 ? "ganska starkt" : correlationLabel;
    correlationLabel = correlationCoef >= 0.8 ? "starkt" : correlationLabel;

    let correlationPoints = 1;
    correlationPoints = correlationCoef >= 0.2 ? 2 : correlationPoints;
    correlationPoints = correlationCoef >= 0.4 ? 3 : correlationPoints;
    correlationPoints = correlationCoef >= 0.6 ? 4 : correlationPoints;   
    correlationPoints = correlationCoef >= 0.8 ? 5 : correlationPoints;   

    return (
      <Segment
        basic 
        inverted
        style={{
          width: "100%", 
          marginTop: 10, 
          background: "#444444",
          boxShadow: "0 1px 2px 0 rgba(174, 179, 179, 0.15)",
          borderRadius: "0.28571429rem",
          opacity: 0.95,
          padding: "1em",             
        }}
      >
        {/* <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Header
            as="h4"
            style={{
              marginTop: 0,
              fontWeight: 500,
              color: "white",
            }}
          >
            Jordprover
          </Header>

          <div/>       
        </div>    */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            marginLeft: "10%",
            marginBottom: "1.5em",
          }}
        >
          <Icon name="star" style={{ color: CORRELATION_SCORE_COLOR[correlationPoints] }} />
          <Icon name={correlationPoints >= 2 ? "star" : "star outline"} style={{ color: CORRELATION_SCORE_COLOR[correlationPoints] }}/>
          <Icon name={correlationPoints >= 3 ? "star" : "star outline"} style={{ color: CORRELATION_SCORE_COLOR[correlationPoints] }}/>
          <Icon name={correlationPoints >= 4 ? "star" : "star outline"} style={{ color: CORRELATION_SCORE_COLOR[correlationPoints] }}/>
          <Icon name={correlationPoints >= 5 ? "star" : "star outline"} style={{ color: CORRELATION_SCORE_COLOR[correlationPoints] }}/>

        </div>

        <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 5,
            whiteSpace: "pre-line",
          }}
        >
          Freja bedömmer att sambandet mellan dessa två lager är {correlationLabel} med en poäng på 
          {correlationCoef && " " + (100 * correlationCoef).toFixed(0)} av 100 möjliga.
        </div> 

        <Button
          basic
          inverted
          fluid
          style={{
            marginTop: 10,
          }}
          size="small"
          onClick={() => this.props.toogleShowCorrelationGraph()}
        >
          Visa graf
        </Button>
      </Segment>
    )
  }
}

export default MapOverviewCorrelationWidget;