import React, { Component } from "react";

import { 
  Button, 
  Form, 
  Header, 
  Segment,
  Image,
  Loader,
} from "semantic-ui-react";

import Background from "@/assets/backgrounds/wheat2.jpg";
import Illustration from "@/assets/illustrations/onboarding_guide_3.png";

import "./style.css";

const INITIAL_STATE = {
  password1: "",
  password2: "",
  invalidPassword: false,
  isProcessing: false,
  error: '',
};

class ResetSetNewPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    const { password1, password2 } = this.state;
    const { firebase, resetCode } = this.props;

    if (password1 === "" || password1.length < 8) {
      this.setState({
        error: {message: "Ange ett nytt lösenord med minst 8 tecken."},
        invalidPassword: true,
      });

      return null;
    };

    if (password1 !== password2) {
      this.setState({
        error: {message: "Lösenorden matchar inte."},
        invalidPassword: true,
      });

      return null;
    };

    this.setState({ isProcessing: true });
    

    firebase
      .confirmPasswordReset(resetCode, password1)
      .then(response => {
        this.setState({ isProcessing: false });
        this.props.onDone();
      })
      .catch(error => {
        this.setState({ error });
        this.setState({ isProcessing: false });
      });
  };

  render() {
    const { password1, password2, invalidPassword, isProcessing, error } = this.state;

    let errorMessage = error && error.message;

    return (
      <div 
        style={{
          height: "100vh",
          paddingLeft: "20%",
          paddingRight: "20%",
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "hidden"          
        }}
      >
        <div
          style={{
            height: "60%",
            marginTop: "20vh",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}
        >
          <Segment
            style={{
              width: "50%",
              height: "100%",
              padding: "2em",
            }}
          >
            <div
              style={{
                width: "67%",
              }}            
            >
            {!isProcessing &&
              <React.Fragment>
                <Form
                  size="large"
                  onSubmit={this.onSubmit}
                  style={{ 
                    width: "67%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Form.Input
                    fluid
                    focus
                    icon="lock"
                    name="password1"
                    iconPosition="right"
                    type='password'
                    placeholder="Ange ett nytt lösenord..."
                    value={password1}
                    onChange={this.onChange}
                    error={invalidPassword}
                  />

                  <Form.Input
                    fluid
                    focus
                    icon="lock"
                    name="password2"
                    iconPosition="right"
                    type='password'
                    placeholder="Bekräfta det nya lösenordet..."
                    value={password2}
                    onChange={this.onChange}
                    error={invalidPassword}
                  />

                  {errorMessage &&
                    <p
                      style={{ 
                        marginTop: "1em",
                        marginBottom: "1em",
                        color: "#D6946B", 
                      }}
                    >
                      {errorMessage}
                    </p>
                  }          

                  <Button
                    fluid
                    size="large"
                    onClick={this.onSubmit}
                    style={{ 
                      marginTop: "2em", 
                      backgroundColor: "#6B9D9F",
                      color: "white",                  
                    }}
                  >
                    Byt lösenord
                  </Button>
                </Form>

              </React.Fragment>
            }

            {isProcessing &&
              <Loader 
                active 
                indeterminate 
                size="massive"
              >
                <div
                   style={{
                     fontSize: "80%", 
                     marginTop: "0.5em",
                     color: "grey",
                    }}
                  >
                  Byter lösenord...
                </div>
              </Loader>            
            }  
            </div>        
          </Segment>

          <Segment
            style={{
              width: "50%",
              height: "90%",
              padding: "2em",
              marginTop: "auto",
              marginBottom: "auto",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: "#f7f4f0",
            }}
          > 
            <Image 
              style={{
                height: isProcessing ? "80%" : "70%",
                marginTop: isProcessing ? "5%" : null,
                marginBottom: isProcessing ? "15%" : null,
                transition: "height 0.3s",
              }}            
              centered 
              src={Illustration}             
            />

            {!isProcessing &&
              <Header
                as="h2"
                textAlign="center"
                color="grey"
                style={{
                  fontWeight: 500,
                }}
              >
                Ange ett nytt lösenord
              </Header>
            }

            {!isProcessing &&
              <p
                style={{
                  width: "75%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                }}
              >
                Ange ett nytt lösenord och bekräfta det för din användare hos AgriOpt. Ditt nya lösenord ska innehålla <strong>minst 8 tecken</strong>.
              </p>
            }
          </Segment>
        </div>
      </div>
    );
  }
}

export default ResetSetNewPasswordForm;
