import React, { Component } from 'react';

import { 
  Form,
  Button,
} from "semantic-ui-react";

import { emailIsValid } from '@/helpers/validators';

const INITIAL_STATE = {
  email1: '',
  email2: '',
  password1: '',
  password2: '',
  invalidEmail: false,
  invalidPassword: false,
  showLoader: false,
};

class RegistrationCreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onChange = (data) => {
    this.setState({
      [data.name]: data.value,
    }); 
  }; 

  onChangeToLowerCase = (data) => {
    this.setState({
      [data.name]: data.value.toLowerCase(),
    }); 
  }; 

  onSubmit = () => {
    const {
      email1,
      email2,
      password1,
      password2,
    } = this.state;
    
    let error = false;
    let errorMessage = [];
    
    if (email1 === '') {
      this.setState({invalidEmail: true});
      errorMessage.push('Ange en korrekt mailadress.');
      error = true;
    };
    
    if (!emailIsValid(email1)) {
      this.setState({invalidEmail: true});
      errorMessage.push('Ange en korrekt mailadress.');
      error = true;
    };

    if (email1 !== email2) {
      this.setState({invalidEmail: true});
      errorMessage.push('Mailadresserna matchar inte.');
      error = true;
    };    
    
    if (password1 === '' || password2 === '') {
      this.setState({invalidPassword: true});
      errorMessage.push('Ange ett korrekt lösenord.');
      error = true;
    };
    
    if (password1.length < 7) {
      this.setState({invalidPassword: true});
      errorMessage.push('Ange ett lösenord med minst 8 tecken.');
      error = true;
    };  
    
    if (password1 !== password2) {
      this.setState({invalidPassword: true});
      errorMessage.push('Lösenorden matchar inte..');
      error = true;
    };

    if (error) {
      this.setState({errorMessage: errorMessage})
      return;
    };
  
  this.props.createUser(this.state);
  };

  render() {

    const {
      showCreatingUserLoader,
    } = this.props;

    const {
      email1,
      email2,
      password1,
      password2,
      invalidEmail,
      invalidPassword,  
      errorMessage,
    } = this.state;

    let errorMessageFirestore = this.props.errorMessageFirestore;
    errorMessageFirestore = errorMessageFirestore && errorMessageFirestore.message && errorMessageFirestore.message;
    errorMessageFirestore = (errorMessageFirestore && errorMessageFirestore === "The email address is already in use by another account.") ? 
      "Det finns redan en användare registrerad med denna mailadress." : errorMessageFirestore;

    let usernameError = errorMessageFirestore && errorMessageFirestore === "Det finns redan en användare registrerad med denna mailadress.";

    return (
      <React.Fragment>
        <div
          style={{
            width: "67%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",   
          }}            
        >
          <Form onSubmit={this.onSubmit}>
            <Form.Input
              focus
              autoFocus
              fluid
              name='email1'
              icon='mail'
              iconPosition='left'
              label="Mailadress"
              placeholder='Ange din mailadress'
              error={invalidEmail || usernameError}
              value={email1}
              onChange={(e, d) => {this.onChangeToLowerCase(d)}}
            />

            <Form.Input
              focus
              fluid
              name='email2'
              icon='mail'
              iconPosition='left'
              placeholder='Bekräfta mailadressen'
              error={invalidEmail || usernameError}
              value={email2}
              onChange={(e, d) => {this.onChangeToLowerCase(d)}}
            />
          </Form>

          <Form onSubmit={this.onSubmit} style={{ marginTop: "3em" }}>
            <Form.Input
              focus
              name='password1'
              icon='lock'
              iconPosition='left'
              type='password'
              label="Lösenord"
              placeholder='Ange ett lösenord (minst 8 tecken)'
              style={{ color: password1.length > 7 ? "green" : null }}
              error={invalidPassword || (password1 !== '' && password1.length < 7)}
              value={password1}
              onChange={(e, d) => {this.onChange(d)}}
            />

            <Form.Input
              focus
              name='password2'
              icon='lock'
              iconPosition='left'
              type='password'
              placeholder='Bekräfta lösenordet (minst 8 tecken)'
              style={{ color: (password2 !== '' && password1 === password2) ? "green" : null }}
              error={invalidPassword || (password2 !== '' && password1 !== password2)}
              value={password2}
              onChange={(e, d) => {this.onChange(d)}}
            />

            <Button
              fluid
              onClick={this.onSubmit}
              style={{ 
                marginTop: "2em", 
                backgroundColor: "#6B9D9F",
                color: "white",                  
              }}
              loading={showCreatingUserLoader}
            >
              Skapa konto
            </Button>          
          </Form>

          {errorMessage &&
            <p
              style={{ 
                marginTop: "1em",
                marginBottom: "1em",
                color: "#D6946B", 
              }}
            >
              {errorMessage}
            </p>
          }     
        </div>     

        <p
          style={{
            width: "67%",
            position: "absolute",
            bottom: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <p
            style={{
              marginBottom: "1em",
            }}
          >
            Genom att skapa ett konto accepterar du dessa villkor:
          </p>

          <a 
            style={{ marginRight: 4 }}
            href="https://agriopt.se/@/assets/agriopt-anvandarvillkor-current.pdf"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Användarvillkor
          </a>          

          &middot;

          <a 
            style={{ marginLeft: 4 }}
            href="https://agriopt.se/@/assets/agriopt-integritetspolicy-current.pdf"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Integritets- och cookievillkor
          </a>
        </p>             
      </React.Fragment>
    )
  }
};

export default RegistrationCreateUser;
