import React, { Component } from "react";

import { 
  Container,
  Segment,
  Image,
  Header,
  Grid,
} from "semantic-ui-react";

import imgStep1 from "@/assets/icons/guideSteps/step-one.svg";
import imgStep2 from "@/assets/icons/guideSteps/step-two.svg";
import imgStep3 from "@/assets/icons/guideSteps/step-three.svg";

import GuideNavigation from "@/components/guide/GuideNavigation";

class HarvestReportGuideGettingStarted extends Component {
  
  render() {

    let menuItems = {
      next: [
        {
          caption: "Sätt igång",
          onClick: this.props.onClickGoToStep1,
        }
      ],
    };

    return (
      <React.Fragment>
        <GuideNavigation menuItems={menuItems} />

        <Container
          style={{
            padding: "2em",
            marginTop: "4em",
            paddingBottom: "70px",
            width: "100%",
          }}
        >        
          <Segment 
            style={{
              padding: "2em",
              paddingTop: "3em",
            }}
          >
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column width={8}>
                  <Header 
                    as="h1"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Kom igång
                  </Header>
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: "120%",
                      whiteSpace: "pre-line",
                      width: "90%",
                    }}
                  >
                     Med den här guiden kan du skapa översikter för dina
                     skördekartor, både för varje enskilt skifte och för flera 
                     skiften som samlas i en gårdsöversikt.
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: "120%",
                      whiteSpace: "pre-line",
                      width: "90%",
                    }}
                  >
                    Läs hur du det går till nedan och gå sedan vidare steg för
                    steg där det finns ytterligare guidning. 
                  </p>              
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header 
                    as="h1"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Vad är en skördeöversikt?
                  </Header>
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: "120%",
                      whiteSpace: "pre-line",
                      width: "90%",
                    }}
                  >
                    En skördekartsöversikt eller gårdsöversikt är en tolkningar av flera
                    års skördekartsdata ur ett eller flera skiften från din gård, där
                    vi genom dessa översikter hjälper till att tolka din data ur
                    flera perspektiv och utifrån olika parametrar. Se det som ett
                    komplement till din egen erfarenhet och kunskap för att förstå
                    och ta hand om dina skiften ännu bättre.
                  </p>                 

                </Grid.Column>                
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment 
            style={{
              padding: "1.5em",
            }}
          >
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column width={2}>
                  <Image
                    style={{ 
                      marginLeft: "1em",
                      marginTop: "1em",
                      marginRight: 50, 
                      width: "8em",
                      height: "8em",
                      filter: "brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(7315%) hue-rotate(341deg) brightness(109%) saturate(80%)"
                    }}
                    src={imgStep1}
                  />
                </Grid.Column>
                <Grid.Column width={14}>
                  <Header
                    as="h1"
                    style={{
                      marginTop: "0.75em",
                      fontWeight: "normal",
                      whiteSpace: "pre-line",
                    }}
                  >
                    Välj skiften
                  </Header>
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: "120%",
                      whiteSpace: "pre-line",
                      maxWidth: "67%",
                      paddingBottom: "1em",
                    }}
                  >
                    Välj alla skiften som du vill skapa skördeöversikter för. 
                    Här kan du också se vilken data som saknas för att kunna skapa en översikt.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment 
            style={{
              padding: "1.5em",
            }}
          >
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column width={2}>
                  <Image
                    style={{ 
                      marginLeft: "1em",
                      marginTop: "1em",
                      marginRight: 50, 
                      width: "8em",
                      height: "8em",
                      filter: "brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(7315%) hue-rotate(341deg) brightness(109%) saturate(80%)"
                    }}
                    src={imgStep2}
                  />
                </Grid.Column>
                <Grid.Column width={14}>
                  <Header
                    as="h1"
                    style={{
                      marginTop: "0.75em",
                      fontWeight: "normal",
                      whiteSpace: "pre-line",
                    }}
                  >
                    Kontrollera skördekartor
                  </Header>
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: "120%",
                      whiteSpace: "pre-line",
                      maxWidth: "67%",
                      paddingBottom: "1em",
                    }}
                  >
                    Gå genom alla skördekartor som finns för skiftet och kontrollera att information om växtföljd och kvävegiva är korrekt.
                    Du kan även välja att inte ta med vissa år i översikten om det exempelvis saknas mycket data.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>  

          <Segment 
            style={{
              padding: "1.5em",
            }}
          >
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column width={2}>
                  <Image
                    style={{ 
                      marginLeft: "1em",
                      marginTop: "1em",
                      marginRight: 50, 
                      width: "8em",
                      height: "8em",
                      filter: "brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(7315%) hue-rotate(341deg) brightness(109%) saturate(80%)"
                    }}
                    src={imgStep3}
                  />
                </Grid.Column>
                <Grid.Column width={14}>
                  <Header
                    as="h1"
                    style={{
                      marginTop: "0.75em",
                      fontWeight: "normal",
                      whiteSpace: "pre-line",
                    }}
                  >
                    Hämta skiftesöversikter
                  </Header>
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: "120%",
                      whiteSpace: "pre-line",
                      maxWidth: "67%",
                      paddingBottom: "1em",
                    }}
                  >
                    Skiftesöversikten och gårdsöversikten skapas automatiskt efter att skördekartorna är godkända.
                    Du kan enkelt ladda ned dem här senare.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>         
        </Container>
      </React.Fragment>
    )
  }
}

export default HarvestReportGuideGettingStarted;