import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Modal,
  Button,
  Image,
} from "semantic-ui-react";

import moment from 'moment';
import 'moment/locale/sv';

import { DateInput } from 'semantic-ui-calendar-react';
import { toast } from "react-toastify";

import imgQuestion from "@/assets/illustrations/question.png";

const INITIAL_STATE = {
  modalStep: "menu",
  selectedDate: null,
  isWorking: false,
};

class GuideMarkVraMapUsed extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.open !== this.props.open && this.props.open === true) {
      if (this.props.onlyAllowMarkDone) {
        this.setState({ modalStep: "markDone" });
      };
    };
  };

  openVraMapGuide = (data) => {
    if (data && data.guide_id && data.data_type) {
      this.props.history.push("/" + data.data_type + "/downloadFiles/" + data.guide_id);
      this.props.closeModal();
    };
  };

  setVraMapToUsed = (data) => {
    const {
      db,
      getUser,
      guideId,
    } = this.props;

    if (!data || !guideId) {
      return null;
    };

    this.setState({ isWorking: true });

    let currentGuide = guideId && db && db.guides && db.guides[guideId];

    let allPromises = [];

    let guidePayload = {
      vra_maps_used: true,
      date_vra_maps_used: moment(this.state.selectedDate).toDate(),
      date_complete: new Date(),
      date_latest_update: new Date(),
      complete: true,
    };

    let reminderPayload = {
      condition_active: false,
      urgent: false,
      complete: true,
    };

    if (currentGuide) {
      allPromises.push(getUser().collection("guides").doc(guideId).update(guidePayload));
    };

    currentGuide && currentGuide.reminders && currentGuide.reminders.forEach((reminderId) => {
      if (db && db.reminders && db.reminders[reminderId]) {
        allPromises.push(getUser().collection("reminders").doc(reminderId).update(reminderPayload));
      };
    });

    if (data && data.id) {
      allPromises.push(getUser().collection("reminders").doc(data.id).update(reminderPayload));
    };

    Promise.all(allPromises)
      .then(() => {
        toast.success("Styrfilerna är klarmarkerade och kommer snart att dyka upp under Utforska och Samla.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
          hideProgressBar: false,
        });

        this.setState({ isWorking: false });
        this.props.closeModal();
      })
      .catch((error) => {
        toast.error("Styrfilerna kunde tyvärr inte klarmarkeras. Felmeddelande: " + error.toString(), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
          hideProgressBar: false,
        });

        this.setState({ isWorking: false });
      });
  };

  render() {
    const {
      open,
      guide,
    } = this.props;

    const {
      modalStep,
      isWorking,
    } = this.state;

    let modalContent = "";

    if (modalStep === "menu") {
      modalContent =
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Image
              style={{
                width: "50%",
                height: "50%",
              }}
              src={imgQuestion}
            />

            <div>
              <Button
                style={{
                  marginTop: "3em",
                }}
                fluid
                primary
                onClick={() => this.openVraMapGuide(guide)}
              >
                Hämta styrfiler och PDF-karta
              </Button>

              <Button
                style={{
                  marginTop: "3em",
                  marginBottom: "3em",
                }}
                fluid
                positive
                onClick={() => this.setState({ modalStep: "markDone" })}
              >
                Klarmarkera styrfiler
              </Button>
            </div>
          </div>
        </React.Fragment>
    }

    if (modalStep === "markDone") {
      modalContent =
        <React.Fragment>
          <p>
            När du klarmarkerar styrfiler kommer guiden att låsas så att du inte längre kan gå tillbaka och göra förändringar.
            Freja kommer även föra över information från styrfilen till vyerna Samla och Utforska.
          </p>

          <p>
            Ange det datum som du använde styrfilerna som du skapat i Freja.
          </p>

          <DateInput
            pickerStyle={{
              minWidth: "50%",
              maxWidth: "100%",
              fontSize: "85%",
            }}
            inline
            dateFormat="YYYY-MM-DD"
            value={this.state.selectedDate}
            markColor="green"
            onChange={(e, d) => this.setState({ selectedDate: d.value })}
            localization='sv'
          />
        </React.Fragment>
    }

    return (
      <React.Fragment>
        <Modal
          open={open}
          size="tiny"
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            {modalStep === "menu" ? "Vad vill du göra?" : "Klarmarkera styrfiler"}
          </Modal.Header>

          <Modal.Content>
            {modalContent && modalContent}
          </Modal.Content>

          <Modal.Actions>
            <Button disabled={isWorking} onClick={() => this.props.closeModal()}>Stäng</Button>
            {modalStep === "markDone" &&
              <Button
                primary
                disabled={!this.state.selectedDate || isWorking}
                loading={isWorking}
                onClick={() => this.setVraMapToUsed(guide)}
              >
                Klarmarkera
              </Button>
            }
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(GuideMarkVraMapUsed);