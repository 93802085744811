import React, { Component } from "react";

import { 
  Container,
} from "semantic-ui-react";

import GuidesAddGuideWidget from "@/components/guideData/GuidesAddGuideWidget";
import GuidesCollectDataDropzone from "@/components/guideData/GuidesCollectDataDropzone";

const INITIAL_STATE = {
  uploadDataType: 'uploadYieldMaps',
};

class GuidesCollectData extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  setUploadDataType = (newType) => {
    this.setState({ uploadDataType: newType });
    this.props.setParentState({ collectDataError: null });
  };

  render() {
    const {
      db,
      fieldId,
      dataType,
      userPlanType,
      collectDataError,
    } = this.props;

    const {
      uploadDataType,
    } = this.state;

    return (
      <React.Fragment>
        <Container
          style={{
            display: "grid",
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 70,
            width: "100%",
            height: "calc(100vh - 70px)",
            overflowY: "auto",
          }}
        >
          {/* <GuidesBarWidget db={db} /> */}

          <div
            style={{
              padding: 20,
              paddingTop: 0,
              marginLeft: "5px",
              marginRight: "15px",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div 
              style={{ 
                width: "60%",
                height: "calc(100vh - 100px)",
              }}
            >
              <GuidesCollectDataDropzone 
                activeView={uploadDataType}
                onSetDataType={this.setUploadDataType}
                onDroppedFiles={this.props.onDroppedCollectedFiles}
                collectDataError={collectDataError}
              />
            </div>

            <div 
              style={{ 
                width: "39%",
                height: "calc(100vh - 100px)",
              }}
            >
              <GuidesAddGuideWidget 
                db={db}
                fieldId={fieldId}
                userPlanType={userPlanType}
                dataType={dataType}
                onAddGuide={this.props.onAddGuide}
                caption="Samla data"
                description="Välj en guide nedan för att börja samla data."
                guideType={null}             
              />         
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
};

export default GuidesCollectData;
