import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Header,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";

import LoaderModal from "@/components/modals/LoaderModal";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";

import MapPreviewPerField from "./mapPreviewPerField";
import IconWithPlate from "@/components/misc/IconWithPlate";
import imgYieldMap from "@/assets/icons/dataTypes/yield-data.svg";

import { getAvailableLayerIds } from "@/helpers/dataFetcher";

import { isLoaded } from "react-redux-firebase";
class Summary extends Component {

  state = {};

  getPlaceholder = (isDone) => (
    <Segment
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1em",
        paddingTop: "2em",
        paddingBottom: "2em",
        backgroundColor: isDone ? "#89A275" : null,
        color: isDone ? "white" : null,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "12em",
          marginTop: "auto",
          marginBottom: "auto",          
          overflowY: "hidden",
        }}
      >
        <IconWithPlate
          size="10em"
          src={imgYieldMap}
        />
      </div>

      <div
        style={{
          height: "100%",
          width: "calc(100% - 12em)",
          marginLeft: "2em",
          marginRight: "2em",
          marginTop: "auto",
          marginBottom: "auto",              
          whiteSpace: "pre-line",
        }}          
      >
        <Header 
          as="h1" 
          style={{
            marginBottom: 10,
            fontWeight: 500,
            color: isDone ? "white" : null,
          }}
        >
          {isDone && "Skördekartor har skapats från grönmassamätningar"}
          {!isDone && "Skapar skördekartor från grönmassamätningar..."}
        </Header>

        {isDone && 
          <p
            style={{
              width: "80%",
              whiteSpace: "pre-line",
              fontSize: "110%",
            }}
          >
            Freja är klar med att skapa skördekartor från grönmassa. Du kan nu använda de skapade skördekartorna i alla vyer och guider.
            Klicka på "Stäng guiden" nedan för att avsluta guiden.
          </p>
        }

        {!isDone && 
          <p
            style={{
              width: "80%",
              whiteSpace: "pre-line",
              fontSize: "110%",
            }}
          >
            Freja håller just nu på att skapa skördekartor från de grönmassakartor som du har valt ut.
            Du kan se hur status för detta nedanför. När processen är klar kan du se och använda dem som vanliga skördekartor i Freja.
          </p>
        }

        {!isDone && 
          <p
            style={{
              width: "80%",
              whiteSpace: "pre-line",
              fontSize: "110%",
            }}
          >
            Under tiden som Freja arbetar kan du använda appen som vanligt.
            När kartorna är klara kommer det att dyka upp en notis under "Min gård".
            Om du vill komma tillbaka hit för att se hur det går hittar du denna guide under "Samla" genom att klicka på gårdsnamnet eller skiftesnamnet.
          </p>
        }
      </div>
    </Segment>
  )

  getMapPreviewPerField = () => {
    const {
      db,
      fileBucketRef,
      userId,
      guideId,
    } = this.props;

    let fields = db && db.fields && db.fields;
    let fieldsInfo = db && db.fieldsInfo && db.fieldsInfo;    

    let guideData = guideId && db && db.guides && db.guides[guideId];

    let handledFieldIds = guideData && guideData.field_ids;
    let allFieldIds = handledFieldIds && [...handledFieldIds].sort((a, b) => {
      const textA = fields[a] && fields[a].name && fields[a].name.toUpperCase();
      const textB = fields[b] && fields[b].name && fields[b].name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    
    return allFieldIds && allFieldIds.map((fieldId) => {

      let fieldsYieldMapsArtificial = db && fieldId && db["fieldsYieldMapsArtificial_" + fieldId];

      return (
        <MapPreviewPerField
          db={db}
          fileBucketRef={fileBucketRef}
          userId={userId}
          key={fieldId}
          fieldId={fieldId}
          fields={fields}
          fieldsInfo={fieldsInfo}
          uploadedFileData={fieldsYieldMapsArtificial}  
        />
      )    
    });    
  }

  render () {
    const {
      db,
      fieldId,
      guideId,
    } = this.props;

    let fields = db && db.fields && db.fields;
    
    let guideData = guideId && db && db.guides && db.guides[guideId];
    
    let handledFieldIds = guideData && guideData.field_ids;
    let processingIsDone = true;
    
    handledFieldIds && handledFieldIds.forEach((id) => {
      let uploadedFileData = id && db && fieldId && db.fieldsYieldMapsUploaded[id];

      let availableYears = getAvailableLayerIds(db, id, "yieldMap");
      let yearsInGuideForField = uploadedFileData && uploadedFileData.dates_to_use && Object.keys(uploadedFileData.dates_to_use);

      yearsInGuideForField && yearsInGuideForField.forEach((year) => {
        processingIsDone = !availableYears.includes(year) ? false : processingIsDone;
      });
    });
    
    let menuItems = {
      next: [
        {
          caption: "Stäng guiden",
          onClick: processingIsDone ? () => this.props.onGuideDone() : () => this.props.onCloseGuide(),
        }
      ]
    };

    let mainColumn = 
      <React.Fragment>
        {this.getPlaceholder(processingIsDone)}
        {this.getMapPreviewPerField()}
      </React.Fragment>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Sammanfattning
        </Header>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Till vänster ser du status för skapadet och bearbetningen av skördekartor från grönmassan.
        </p>   

        <Header
          as="h2"
          style={{
              marginTop: "1em",
              fontWeight: "normal",
              whiteSpace: "pre-line",
          }}
        >
          Vill du skapa fler?
        </Header>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Om du vill skapa fler skördekartor från grönmassamätningar klickar du på knappen nedan för att påbörja en ny guide.
        </p>

        <Button
          icon
          fluid
          style={{
            marginTop: "1em",
            backgroundColor: "#6B9D9F",
            color: "white",                      
          }}
          labelPosition="left"
          onClick={() => this.props.history.push("/guidesArtificialYieldMapsV1/selectFields")}
        >
          <Icon name="upload" />
          Skapa skördekartor
        </Button>     
    </React.Fragment>

    if (isLoaded(fields) && isLoaded(guideData)) {
      return (
        <GuidesBasicGrid
          params={this.props.params}
          slideDirection={this.props.slideDirection}
          mainColumn={mainColumn}
          helpColumn={helpColumn}
          showFullGrid={this.props.showFullGrid}
          toggleHelpTexts={this.props.toggleHelpTexts}
          menuItems={menuItems}
        />
      )
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      )
    }
  }
}

export default withRouter(Summary);