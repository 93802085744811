import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';

import {
  ReactReduxFirebaseProvider,
  firebaseReducer
} from 'react-redux-firebase';

import { 
  createFirestoreInstance, 
  firestoreReducer,
} from 'redux-firestore';

import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import "leaflet/dist/leaflet.css";

import App from './App';
import reportWebVitals from './reportWebVitals';

import { firebaseConfig } from '@/constants/firebase';

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
}

// Initialize firebase instance and other services
firebase.initializeApp(firebaseConfig);
firebase.firestore();
export const cloudFunctions = firebase.app().functions("europe-west1");
firebase.analytics();

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
})

// Create store with reducers and initial state
const initialState = {};
const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

function RenderApp() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

ReactDOM.render(<RenderApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();