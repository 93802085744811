import React, { Component } from "react";

import { getColorScales, getDataStats } from "@/components/fieldData/FieldDataColorScales";

import MapOverviewWidget from "@/components/map/MapOverviewWidget";
import MapWithCircleMarkers from "@/components/map/MapWithCircleMarkers";

import MapGroupsWidget from "@/components/map/MapGroupsWidget";
import MapStatsWidget from "@/components/map/MapStatsWidget";

import { DATA_LAYERS, getCurrentDataLayer } from "@/constants/viewLayersAnalysis";
import { fetchData, populateCacheWrapper } from "@/helpers/dataFetcher";
import { ANALYSIS_TO_CACHE, LAYERS_TO_CACHE } from "@/helpers/dataFetcher";
import { sleep } from "@/helpers/dates";

import { CROP_CODES } from "@/constants/cropCodes";
class FieldDataViewer extends Component {
  state = {
    geoJsonData: null,
    geoJsonFieldKey: null,
    statJson: null,
    markers: null,
    polygonImage: null,
    selectedIdx: null,
    legendVisible: true,
    loadingData: false,
    dataLayerMissing: false,
  };

  componentDidMount() {
    fetchData({ ...this.props, downloadNewData: true, setParentState: this.setParentState });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      db,
      fieldId,
      layer,
      layerId,
    } = this.props;

    const {
      geoJsonData,
      selectedIdx,
    } = this.state;

    let hasChangedLayer = false;
    let payload = { ...this.props, downloadNewData: true, setParentState: this.setParentState };

    // Get the data type of the current data layer (float, percent, integer, etc.)
    let dataLayer = getCurrentDataLayer(layer);

    // Get the collection name
    let dbDataDocName = dataLayer && dataLayer.dbDataDocName;
    dbDataDocName = typeof(dbDataDocName) === "string" ? dbDataDocName : dbDataDocName && dbDataDocName[layer];

    let dbDataColName = dbDataDocName && dbDataDocName.split("/")[0];

    if (
      fieldId !== prevProps.fieldId ||
      layer !== prevProps.layer ||
      layerId !== prevProps.layerId
    ) {

      let downloadNewData = true;

      if (fieldId === prevProps.fieldId) {
        // Same field

        if (layer && prevProps.layer && (layer.split("_")[0] === prevProps.layer.split("_")[0])) {
          // Same family of layer

          if (layerId === prevProps.layerId) {
            // Same date, year or type of crop
            downloadNewData = false;
          };

          if (layer === "soilMapUploaded_samples" || prevProps.layer === "soilMapUploaded_samples") {
            downloadNewData = true;
          };

          if (layer === "soilMapAnalysis_samples" || prevProps.layer === "soilMapAnalysis_samples") {
            downloadNewData = true;
          };
        };
      };

      if (!geoJsonData) {
        downloadNewData = true;
      };

      downloadNewData && this.setState({
        selectedMarker: null,
        selectedGroupMarkers: null,
        geoJsonData: null,
        polygonImage: null,
        statJson: null,
        markers: null,
        loadingData: true,
        dataLayerMissing: false,
      });

      // Layer has been changed so skip checking for changes in database
      hasChangedLayer = true;

      downloadNewData && fetchData({ ...payload, downloadNewData: downloadNewData, smartPreCacheCallback: this.smartPreCache});
      !downloadNewData && fetchData({ ...payload, downloadNewData: downloadNewData});

      // downloadNewData && this.smartPreCache(fieldId, layer, layerId);
    };

    let dbCollection = dbDataColName && db && fieldId && db[dbDataColName + "_" + fieldId];
    let prevDbCollection = dbDataColName && prevProps.db && fieldId && prevProps.db[dbDataColName + "_" + fieldId];

    // Data has finished loading
    if (!geoJsonData && !hasChangedLayer && (dbCollection !== prevDbCollection || selectedIdx !== prevState.selectedIdx)) {
      fetchData({...payload, smartPreCacheCallback: this.smartPreCache});

      // this.smartPreCache(fieldId, layer, layerId);
    };

    // Extra checks for Sentinel data (loads slower than the others so caching does not work)
    dbCollection = dbDataColName && db && fieldId && db["fieldsSentinelHubProcessed_" + fieldId];
    prevDbCollection = dbDataColName && prevProps.db && fieldId && prevProps.db["fieldsSentinelHubProcessed_" + fieldId];
    
    if (!hasChangedLayer && (dbCollection !== prevDbCollection || selectedIdx !== prevState.selectedIdx)) {
      // console.log("Triggering extra caching call...", "fieldsSentinelHubProcessed_" + fieldId)

      this.smartPreCache(fieldId, layer, layerId);
    };
  };

  smartPreCache = (fieldId, layer, layerId) => {
    const {
      dataType,
    } = this.props;

    let currentLayer = getCurrentDataLayer(layer);

    if (currentLayer && dataType === "layers") {
      LAYERS_TO_CACHE && LAYERS_TO_CACHE.forEach((cacheLayer) => {
        sleep(1000).then(() => {
          let cacheLayerInfo = getCurrentDataLayer(cacheLayer);

          let placeHolderLayerId = null;
          placeHolderLayerId = cacheLayerInfo && cacheLayerInfo.layerIdTypes.includes("dates") ? "latest" : placeHolderLayerId;

          let cacheLayerId = cacheLayerInfo.layerIdTypes[0] === currentLayer.layerIdTypes[0] ? 
            layerId : 
            placeHolderLayerId;

          layer !== cacheLayer && populateCacheWrapper({
            ...this.props,
            fieldId,
            layer: cacheLayer,
            layerId: cacheLayerId,
          });
        });
      });    
    };
    
    if (currentLayer && dataType === "analysis") {
      ANALYSIS_TO_CACHE && ANALYSIS_TO_CACHE.forEach((cacheLayer) => {
        sleep(1000).then(() => {
          layer !== cacheLayer && populateCacheWrapper({
            ...this.props,
            fieldId,
            layer: cacheLayer,
            layerId,
          });
        });
      });    
    };    
  };
  
  toggleLegendVisible = () => {
    const currentValue = this.state.legendVisible;
    this.setState({ legendVisible: !currentValue });
  };

  setParentState = (newState) => {
    this.setState(newState);
  };

  selectMarker = (marker) => {

    const {
      selectedMarker,
    } = this.props;
    
    if (selectedMarker && selectedMarker.length === 1 && selectedMarker[0] === marker) {
      this.setState({
        selectedMarker: null,
      });

      if (this.props.selectMarker) {
        this.props.selectMarker(null)
      };

    } else {

      this.setState({
        selectedMarker: marker ? [marker] : null,
      });      
      
      if (this.props.selectMarker) {
        this.props.selectMarker(marker ? [marker] : null)
      };
    };
  };

  setSelectedGroup = (newIndex, group) => {
    let selectedGroupMarkers = this.props.selectedGroupMarkers;

    if (!group || (selectedGroupMarkers && selectedGroupMarkers.length > 0 && selectedGroupMarkers[0] === group.sampleIndices[0])) {

      this.props.setContainerState && this.props.setContainerState({
        selectedGroup: null,
        selectedGroupMarkers: null,
        selectedGroupMarkersCoordinates: null,
        selectedGroupPlotName: null,
      });

    } else {
      this.props.setContainerState && this.props.setContainerState({ 
        selectedGroup: newIndex,
        selectedGroupMarkers: group.sampleIndices,
        selectedGroupMarkersCoordinates: group.coords,
        selectedGroupPlotName: this.props.plotName,
      });

    };
  };

  render() {
    const {
      db,
      mode,
      userId,
      field,
      fieldInfo,
      fieldId,
      layer,
      layerId,
      lockedMap,
      showArealMap,
      markerFilteringOpen,
      weatherData,
      showMissingData,
      dataType,
      fileBucketRef,
      markerScale,
      intervalFilter,
      numberOfGroups,
      colorScaleType,
      scale,
      selectedGroup,
      hideInfoWidget,
      cacheFieldData,
      updateCacheFieldData,
      selectedGroupHovering,
      hideAttribution,
    } = this.props;

    const {
      statJson,
      legendVisible,
      geoJsonData,
      polygonImage,
      loadingData,
      dataLayerMissing,
    } = this.state;

    let markers = this.props.markers ? this.props.markers : this.state.markers;

    // Handle special case with interpolation choices for soilmaps
    let geoJsonFieldKey = this.state.geoJsonFieldKey;

    let viewSettings = db && db.settings && db.settings.view;
    let hasSelectedNNinterpolation = viewSettings && viewSettings.soilmap_interpolation && viewSettings.soilmap_interpolation === "nn";

    if (geoJsonFieldKey && layer.includes("soilMapUploaded") && hasSelectedNNinterpolation) {
      geoJsonFieldKey = geoJsonFieldKey + "_nn";
    };

    let currentLayer = getCurrentDataLayer(layer);
    
    if (currentLayer && currentLayer.hideMap && currentLayer.hideMap === true) {
      return <div />
    };

    let selectedMarker = this.props.selectedMarker ? this.props.selectedMarker : this.state.selectedMarker;
    let selectedGroupMarkers = this.props.selectedGroupMarkers ? this.props.selectedGroupMarkers : this.state.selectedGroupMarkers;

    let fieldSize = field && field.field_size && field.field_size;

    let currentYear = layerId && new Date(layerId).getFullYear() && new Date(layerId).getFullYear();

    let currentCropCode = currentYear &&
      fieldInfo &&
      fieldInfo.crop_code &&
      fieldInfo.crop_code[currentYear] &&
      fieldInfo.crop_code[currentYear];

    let cropShortText = currentCropCode && CROP_CODES.find(x => x.value === currentCropCode);
    cropShortText = cropShortText && cropShortText.shorttext;

    let layerName = layer && Object.keys(DATA_LAYERS)
      .map(x => DATA_LAYERS[x].shortCaptions)
      .find(y => Object.keys(y).includes(layer));
    
    layerName = layerName && layerName[layer] && layerName[layer];

    let sortedGeoJsonData = geoJsonData && geoJsonData.features && [...geoJsonData.features].map((x) => x);

    sortedGeoJsonData = sortedGeoJsonData && sortedGeoJsonData.sort(function (a, b) {
      return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
    });

    sortedGeoJsonData = sortedGeoJsonData && { features: sortedGeoJsonData.map((x, idx) => ({ ...x, idx: idx })) };

    let filteredGeoJsonData = (intervalFilter && intervalFilter.min !== null && intervalFilter.max !== null)
      ? {
        ...sortedGeoJsonData,
        features: sortedGeoJsonData && sortedGeoJsonData.features
          .filter(marker => marker.properties)
          .filter(marker => {
            let markerField = marker.properties[geoJsonFieldKey];
            return ((intervalFilter.min <= markerField) && (intervalFilter.max >= markerField))
          })
      }
      : sortedGeoJsonData;

    let colorScales = filteredGeoJsonData && getColorScales(layer, filteredGeoJsonData, geoJsonFieldKey, colorScaleType, numberOfGroups, scale);
    let dataStats = colorScales && sortedGeoJsonData && getDataStats(layer, colorScales, sortedGeoJsonData, geoJsonFieldKey);

    let filteredSelectedMarkers = filteredGeoJsonData && filteredGeoJsonData.features && [...filteredGeoJsonData.features];

    if (filteredSelectedMarkers && selectedGroupMarkers && selectedGroupMarkers.length > 1) {
      filteredSelectedMarkers = filteredSelectedMarkers.filter((x, idx) => selectedGroupMarkers.includes(idx));
    };

    // Show only markers (sample points for soil maps)
    if (markers) {
      filteredSelectedMarkers = markers.map((x) => ({
        geometry: {
          coordinates: [x.latitude, x.longitude]
        },
        properties: {
          samples: 1.0,
          missing_data: 0.0,
        }
      }));

      colorScales = filteredSelectedMarkers && getColorScales(layer, filteredSelectedMarkers, geoJsonFieldKey, colorScaleType, numberOfGroups, scale);
      sortedGeoJsonData = {}
    };

    if (!field) {
      return <div></div>
    };

    return (
      <React.Fragment>
        <div
          style={{
            // display: "flex",
            width: "100%",
            ...this.props.style
          }}
        >
          <MapWithCircleMarkers
            field={field && field}
            userId={userId}
            fieldId={fieldId}
            layer={layer}
            layerId={layerId}
            markers={filteredSelectedMarkers && filteredSelectedMarkers}
            allMarkers={sortedGeoJsonData && sortedGeoJsonData.features}
            dataStats={dataStats}
            colorScale={colorScales && colorScales.colorScale}
            markerScale={markerScale}
            polygonImage={polygonImage}
            onMarkerClick={this.selectMarker}
            selectedMarker={selectedMarker}
            selectedGroupHovering={selectedGroupHovering}
            selectedGroupMarkers={selectedGroupMarkers}
            geoJsonFieldKey={geoJsonFieldKey}
            showMissingData={showMissingData}
            lockedMap={lockedMap}
            showArealMap={showArealMap}
            mapStyle={this.props.mapStyle}
            mapOptions={this.props.mapOptions}
            dataLayerMissing={dataLayerMissing}
            placeholderText={this.props.placeholderText}
            placeholderOnClick={this.props.placeholderOnClick}
            loadingData={loadingData}
            loadingDataText="Freja hämtar kartan..."
            hideAttribution={hideAttribution}
            mapCenter={this.props.mapCenter}
            mapZoom={this.props.mapZoom}
            setNewMapCenterAndZoom={this.props.setNewMapCenterAndZoom}
            mouseCursorLocation={this.props.mouseCursorLocation}
            setNewMouseCursorLocation={this.props.setNewMouseCursorLocation}
          >
            {field && layer && !hideInfoWidget &&
              <MapOverviewWidget
                db={db}
                getUser={this.props.getUser}
                mode={mode}
                userId={userId}
                fieldId={fieldId}
                fieldInfo={fieldInfo}
                fieldName={field.name}
                fileBucketRef={fileBucketRef}
                size={fieldSize}
                layerName={layerName}
                date={layerId}
                cropCode={currentCropCode}
                cropType={cropShortText}
                layer={layer}
                layerId={layerId}
                weatherData={weatherData}
                statJson={statJson}
                dataType={dataType}
                markers={markers}
                dataLayerMissing={dataLayerMissing}
              />
            }

            {layer && mode !== "minimal" && !selectedMarker && (
              <MapGroupsWidget
                mode={mode}
                field={field && field}
                layer={layer}
                layerId={layerId}
                geoJsonFieldKey={geoJsonFieldKey}
                markers={filteredGeoJsonData && filteredGeoJsonData}
                allMarkers={sortedGeoJsonData && sortedGeoJsonData.features}
                statJson={statJson}
                dataStats={dataStats}
                polygonImage={polygonImage}
                selectedGroup={selectedGroup}
                onSelectGroup={this.setSelectedGroup}
                intervalFilter={intervalFilter}
                colorScaleType={colorScaleType}
                numberOfGroups={numberOfGroups}
                onChangeSettings={this.props.onSettingsSave}
                markerFilteringOpen={markerFilteringOpen}
                toggleMarkerFilteringOpen={this.props.toggleMarkerFilteringOpen}
                isUsingFiltering={this.props.isUsingFiltering}
              />
            )}

            {mode === "default" &&
              <MapStatsWidget
                db={db}
                getUser={this.props.getUser}
                userId={userId}
                fieldId={fieldId}
                layer={layer}
                layerId={layerId}
                field={field && field}
                fieldInfo={fieldInfo}
                statJson={statJson}
                dataStats={dataStats}
                allMarkers={sortedGeoJsonData && sortedGeoJsonData.features}
                markers={markers}
                fileBucketRef={fileBucketRef}
                geoJsonData={sortedGeoJsonData}
                polygonImage={polygonImage}
                toggleLegendVisible={this.toggleLegendVisible}
                legendVisible={legendVisible}
                selectedMarker={selectedMarker}
                selectedGroup={selectedGroup}
                selectedGroupMarkers={selectedGroupMarkers}
                colorScales={colorScales}
                fieldSize={fieldSize}
                setSelectedGroup={this.setSelectedGroup}
                selectMarker={this.selectMarker}
                geoJsonFieldKey={geoJsonFieldKey}
                onCalibrateYieldMap={this.props.onCalibrateYieldMap}
                cacheFieldData={cacheFieldData}
                updateCacheFieldData={updateCacheFieldData}
              />
            }
            
            {this.props.children && this.props.children}
          </MapWithCircleMarkers>
        </div>
      </React.Fragment>
    );
  }
}

export default FieldDataViewer;
