import HarvestReportIcon from "@/assets/icons/yield-overview.svg";

import iconTillage from "@/assets/icons/guides/guide-tillage.svg";
import iconSeeding from "@/assets/icons/guides/guide-seed.svg";

import iconLimeStructure from "@/assets/icons/guides/guide-ca.svg";
import iconLimePh from "@/assets/icons/guides/guide-ph.svg";
import iconPotassium from "@/assets/icons/guides/guide-k.svg";
import iconPhosphorus from "@/assets/icons/guides/guide-p.svg";
import iconNitrogen from "@/assets/icons/guides/guide-n.svg";

export const CONTROL_GUIDES = [          
    // {
    //   name: 'harvestReport',
    //   caption: 'Skördeöversikt',
    //   description: 'Skapa en översikt med hjälp av dina uppladdade skördekartor för att se hur din lönsamhet och klimatpåverkan kan ändras om du använder en variabel kvävegiva.',
    //   outputName: "skördeöversikt",
    //   cartoon: HarvestReportIcon,
    //   url: "guidesHarvestReport",
    //   href: "/guidesHarvestReport/selectFields",
    //   fieldSpecific: false,
    //   disabled: false,
    //   hidden: false,
    //   hiddenInLive: false,
    //   guideType: "report",
    //   includedInPlans: ['developer'],
    // },
    {
      name: 'artificialYieldMapsV1',
      caption: 'Skapa skördekartor från grönmassa',
      description: 'Skapa en skördekartor med hjälp av grönmassamätning och snittskördar. Dessa kartor kan du sedan använda för att exempelvis skapa styrfiler',
      outputName: "skördekartor",
      cartoon: HarvestReportIcon,
      url: "guidesArtificialYieldMapsV1",
      href: "/guidesArtificialYieldMapsV1/selectFields",
      fieldSpecific: false,
      disabled: false,
      hidden: false,
      hiddenInLive: false,
      guideType: "report",
      includedInPlans: ['developer', 'control'],
    },    
    {
      name: 'phosphorusV1',
      caption: 'Fosforgödsling',
      description: 'Skapa styrfiler enkelt för fosforgödsling på ett eller flera skiften där du odlar stråsäd. Denna guide kräver uppladdad markkartering med fosforklass samt skördekartor.',
      outputName: "fosforgödsling",
      icon: iconPhosphorus,
      url: "guidesPhosphorusV1",
      href: "/guidesPhosphorusV1/selectFields",
      fieldSpecific: false,
      disabled: false,
      hidden: false,
      hiddenInLive: false,
      guideType: "vraMap",
      includedInPlans: ['developer', "control"],
      showBetaLabel: false,
    },
    {
      name: 'potassiumV1',
      caption: 'Kaliumgödsling',
      description: 'Skapa styrfiler enkelt för kaliumgödsling på ett eller flera skiften där du odlar stråsäd. Denna guide kräver uppladdad markkartering med kaliumklass samt skördekartor.',
      outputName: "kaliumgödsling",
      icon: iconPotassium,
      url: "guidesPotassiumV1",
      href: "/guidesPotassiumV1/selectFields",
      fieldSpecific: false,
      disabled: false,
      hidden: false,
      hiddenInLive: false,
      guideType: "vraMap",
      includedInPlans: ['developer', "control"],
      showBetaLabel: false,
    },             
    {
      name: 'nitrogenV1',
      caption: 'Kvävegödsling: kompletteringsgiva',
      description: 'Skapa styrfiler enkelt för kvävegödsling på ett eller flera skiften där du odlar stråsäd. Denna guide kräver uppladdade skördekartor.',
      outputName: "kvävegödsling",
      icon: iconNitrogen,
      url: "guidesNitrogenV1",
      href: "/guidesNitrogenV1/selectFields",
      fieldSpecific: false,
      disabled: false,
      hidden: false,
      hiddenInLive: false,
      guideType: "vraMap",
      includedInPlans: ['developer', 'control'],
      showBetaLabel: false,
    },
    {
      name: 'nitrogenV2',
      caption: 'Kvävegödsling',
      description: 'Skapa styrfiler enkelt för flera variabela kvävegivor under en säsong. På detta sätt kan du öka dina skördar och minska kostnaderna för gödningen. Freja använder dina uppladdade skördekartor och markkarteringar för detta.',
      outputName: "kvävegödsling",
      icon: iconNitrogen,
      url: "guidesNitrogenV2",
      href: "/guidesNitrogenV2/selectFields",
      fieldSpecific: false,
      disabled: false,
      hidden: true,
      hiddenInLive: false,
      guideType: "vraMap",
      includedInPlans: ['developer'],
      showBetaLabel: false,
    },
    {
      name: 'limePhV1',
      caption: 'pH-kalkning',
      description: 'Skapa styrfiler enkelt för pH-kalkning på ett eller flera skiften. Denna guide kräver uppladdad markkartering med pH-värden.',
      outputName: "pH-kalkning",
      icon: iconLimePh,
      url: "guidesLimePhV1",
      href: "/guidesLimePhV1/selectFields",
      fieldSpecific: false,
      disabled: false,
      hidden: false,
      hiddenInLive: false,
      guideType: "vraMap",
      includedInPlans: ['developer', "control"],
      showBetaLabel: false,
    },
    {
      name: 'limeStructureV1',
      caption: 'Strukturkalkning',
      description: 'Skapa styrfiler enkelt för strukturkalkning på ett eller flera skiften.',
      outputName: "strukturkalkning",
      icon: iconLimeStructure,
      url: "guidesLimeStructureV1",
      href: "/guidesLimeStructureV1/selectFields",
      fieldSpecific: false,
      disabled: false,
      hidden: false,
      hiddenInLive: false,
      guideType: "vraMap",
      includedInPlans: ['developer', "control"],
      showBetaLabel: false,
    },
    {
      name: 'tillageV1',
      caption: 'Markbearbetning',
      description: 'Skapa styrfiler enkelt för ett variabelt bearbetningsdjup med din harv. På detta sätt optimerar du din dieselkonsumption. Freja använder dina uppladdade skördekartor och markkarteringar för detta.',
      outputName: "markbearbetning",
      icon: iconTillage,
      url: "guidesTillageV1",
      href: "/guidesTillageV1/selectFields",
      fieldSpecific: false,
      disabled: true,
      hidden: false,
      hiddenInLive: true,
      guideType: "vraMap",
      includedInPlans: ['developer'],
    },  
    {
      name: 'seedingV1',
      caption: 'Sådd',
      description: 'Skapa styrfiler enkelt för en variabel såmängd och djup. På detta sätt optimerar du din uppkomst för att få ut maximal skörd. Freja använder dina uppladdade skördekartor och markkarteringar för detta.',
      outputName: "sådd",
      icon: iconSeeding,
      url: "guidesSeedingV1",
      href: "/guidesSeedingV1/selectFields",
      fieldSpecific: false,
      disabled: true,
      hidden: false,
      hiddenInLive: true,
      guideType: "vraMap",
      includedInPlans: ['developer'],
    },    
];

