import home1 from "@/assets/tutorials/homeTutorial/welcome.png";
import home2 from "@/assets/tutorials/homeTutorial/mainMenu.png";
import home3 from "@/assets/tutorials/homeTutorial/secondaryMenu.png";
import home4 from "@/assets/tutorials/homeTutorial/myFarm.png";
import home5 from "@/assets/tutorials/homeTutorial/notifications.png";
import home6 from "@/assets/tutorials/helpButton.png";

import layer1 from "@/assets/tutorials/layerTutorial/welcome.svg";
import layer2 from "@/assets/tutorials/layerTutorial/selectField.png";
import layer3 from "@/assets/tutorials/layerTutorial/selectLayer.png";
import layer4 from "@/assets/tutorials/layerTutorial/histogram.png";
import layer5 from "@/assets/tutorials/layerTutorial/compareButton.png";

import analysis1 from "@/assets/tutorials/analysisTutorial/welcome.svg";
import analysis2 from "@/assets/tutorials/analysisTutorial/greenmass.png";
import analysis3 from "@/assets/tutorials/analysisTutorial/clustering.png";
import analysis4 from "@/assets/tutorials/analysisTutorial/weather.png";
import analysis5 from "@/assets/tutorials/analysisTutorial/yieldPotential.png";

import guides1 from "@/assets/tutorials/guidesTutorial/welcome.svg";
import guides2 from "@/assets/tutorials/guidesTutorial/addGuide.png";
import guides3 from "@/assets/tutorials/guidesTutorial/resumeGuide.png";

import collect1 from "@/assets/tutorials/collectTutorial/welcome.svg";
import collect2 from "@/assets/tutorials/collectTutorial/soilMap.svg";
import collect3 from "@/assets/tutorials/collectTutorial/yieldMap.svg";
import collect5 from "@/assets/tutorials/collectTutorial/ending.png";

import compare1 from "@/assets/tutorials/compareTutorial/welcome.png";
import compare2 from "@/assets/tutorials/compareTutorial/correlation.png";
import compare3 from "@/assets/tutorials/compareTutorial/selectLayer2.png";
import compare4 from "@/assets/tutorials/compareTutorial/comparePoints2.png";

import mapEditor2 from "@/assets/tutorials/guideMapEditorTutorial/image1.png";
import mapEditor3 from "@/assets/tutorials/guideMapEditorTutorial/image2.png";
import mapEditor4 from "@/assets/tutorials/guideMapEditorTutorial/image3.png";
import mapEditor5 from "@/assets/tutorials/guideMapEditorTutorial/image4.png";

export const TUTORIALS = [
  {
    name: "DesktopHomeFirstLaunchV1",
    cards: [
      {
        id: 'home1',
        header: 'Hej och välkommen till Freja', 
        image: home1, 
        imagePosition: "left",
        text: [
          'Din digitala hjälpreda Freja hjälper dig att skapa en hållbar och effektiv växtodling genom att kombinera modern teknik, insamlad data och dina egna erfarenheter.',
          'Denna välkomstguide kommer att visa dig runt i Freja.'
        ]
      },
      {
        id: 'home2',
        header: 'Hitta rätt vy',
        image: home2, 
        imagePosition: "right",
        text: [
          'Freja består av ett antal olika vyer som du kan växla mellan genom menyn som du hittar till vänster.',
          'De olika vyerna hjälper dig att utforska data, tolka data och styra genom att skapa styrfiler.',
        ]
      },
      {
        id: 'home3',
        header: 'Prova olika verktyg',
        image: home3, 
        imagePosition: "left",
        text: [
          'Freja har också ett antal olika verktyg och undervyer som du kan nå genom menyn högst upp på skärmen i varje vy.',
        ]
      },
      {
        id: 'home4',
        header: 'Min gård', 
        image: home4, 
        imagePosition: "right",
        text: [
          'När du loggar in i Freja möts du av hemvyn "Min gård". Där kan du hitta aktuellt väder, nya händelser och få en överblick över grönmassa och skörd.',
        ]
      },
      {
        id: 'home5',
        header: 'Händelser och skapade filer', 
        image: home5, 
        imagePosition: "left",
        text: [
          'I verktygsfältet hittar du dina insikter och senaste händelserna i Freja. Klicka på en insikt eller händelse för att öppna den eller ladda ned en styrfil.',
        ]
      },      
      {
        id: 'home6',
        header: 'Hitta hjälp', 
        image: home6, 
        imagePosition: "left",
        text: [
          'Du kan se denna guide igen och få mer hjälp om du klickar på hjälp-ikonen i menyn överst på skärmen.',
          'Där kan du visa denna guiden igen, hitta vårt hjälpcenter och kontakta vår support.',
          'Vi har också placerat ut hjälpikoner i Freja med hjälptexter som dyker upp när du låter muspekaren "sväva" över dem.',
        ]
      },  
    ]
  },
  {
    name: "DesktopLayersFirstLaunchV1",
    cards: [
      {
        id: 'layer1',
        header: 'Varför växer inte min gröda som den ska?', 
        iconWithPlate: layer1, 
        plateBackground: "#f1f7ed",
        imageFilter: "brightness(0) invert(60%) sepia(32%) saturate(298%) hue-rotate(50deg) brightness(96%) contrast(91%)",
        imagePadding: true,
        imagePosition: "left",
        text: [
          'Alla lantbrukare vet att det finns många saker som påverkar grödan. Freja hjälper dig att på ett enkelt sätt utforska data sida vid sida för att förstå vad som behöver göras på dina fält.',
          'Vad kan du göra i denna vy?',
        ],
        bulletList: [
          'Jämföra data från olika år och skiften',
          'Jämföra skördeutfall med markförhållanden',
          'Få en snabb överblick av årsmånen',
        ]
      },
      {
        id: 'layer2',
        header: 'Välj skifte',
        image: layer2, 
        imagePosition: "right",
        text: [
          'Du börjar utforska dina datalager med att välja vilket skifte som du vill undersöka.',
          'De små ikonerna visar vilken data som finns för varje skifte: grönmassa, markkarta och skördekarta.',
          'Den lilla röda cirkeln visar att det finns ny data att titta på.',
        ]
      },
      {
        id: 'layer3',
        header: 'Välj datalager och datum',
        image: layer3, 
        imagePosition: "left",
        text: [
          'Använd menyn till vänster för att enkelt välja datalager samt datum eller år.',
          'Har du modulen tolka kan du även byta till vyn tolka och hitta tolkningar på samma datalager.',
        ]
      },
      {
        id: 'layer4',
        header: 'Markera liknande punkter', 
        image: layer4, 
        imagePosition: "right",
        text: [
          'Genom att klicka på staplarna i färgkartan kan du markera alla punkter som har en viss grönmassa eller skörd. Detta gör att du enkelt kan hitta bättre och sämre delar av skiftet.'
        ]
      },
      {
        id: 'layer5',
        header: 'Jämför olika kartor', 
        image: layer5, 
        imagePosition: "left",
        text: [
          'Du kan också jämföra två datalager eller datum/år sida vid sida för att hitta mönster och faktorer som påverkar tillväxt eller skörd.',
          'Klicka på knappen i verktygsfältet för att enkelt komma igång och få mer hjälp.'
        ]
      },    
    ]
  },
  {
    name: "DesktopAnalysisFirstLaunchV1",
    cards: [
      {
        id: 'analysis1',
        header: 'Vilken skörd kan jag få på mitt skifte i år?', 
        iconWithPlate: analysis1,
        plateBackground: "#f8eed0", 
        imageFilter: "brightness(0) invert(77%) sepia(93%) saturate(2262%) hue-rotate(339deg) brightness(99%) contrast(89%)",
        imagePadding: true,
        imagePosition: "left",
        text: [
          'Alla lantbrukare vet att varje fält har bra och dåliga delar. Freja hjälper dig att förstå fältet och skördepotentialen bättre genom att lägga pusselbitar från år till år.',
          'Vad kan du göra i denna vy?',
        ],
        bulletList: [
          'Se hur årsmånen påverkar olika områden',
          'Utvärdera dina insatser över åren',
          'Bli varnad om möjliga problem',
        ]
      },
      {
        id: 'analysis2',
        header: 'Tolka din grönmassa',
        image: analysis2, 
        imagePosition: "right",
        text: ['Här kan du utforska hur grönmassan ser ut från säsong till säsong för att hitta områden som ofta ger glesare eller frodigare gröda.', 'Jämför gärna med markkartan eller skördekartan för att hitta ledtrådar varför det är så och om det spelar någon roll för skörden.']
      },
      {
        id: 'analysis3',
        header: 'Hitta intressanta områden',
        image: analysis3, 
        imagePosition: "left",
        text: ['Freja kan även hitta områden som har en liknande tillväxt i grönmassa över en eller flera säsonger.', 'Här hittar du områden som är tidigare och senare i sin tillväxt. Jämför gärna med markkarta eller skördekarta för att hitta ledtrådar till varför det är så och om det spelar någon roll för skörden.']
      },
      {
        id: 'analysis4',
        header: 'Jämför med vädret', 
        image: analysis4, 
        imagePosition: "right",
        text: ['För tolkningar som görs över en säsong kan du även jämföra med vädret (dygnsgrader, nederbörd och solinstrålning) för att hitta liknande årsmåner.', 'Klicka på den lilla fyrkanten för att jämföra flera säsonger samtidigt.']
      },
      {
        id: 'analysis5',
        header: 'Hitta skiftets skördepotential', 
        image: analysis5, 
        imagePosition: "left",
        text: ['Freja kan även hjälpa till att uppskatta skördepotentialen du har laddat upp skördekartor för skiftet.', 'Det finns flera olika kartor som baseras på historisk karta bland annat maximal skörd (i varje punkt), snittskörd samt en kombination av dessa som är Frejas bästa gissning.']
      },      
    ]
  },
  {
    name: "DesktopGuidesFirstLaunchV1",
    cards: [
      {
        id: 'guides1',
        header: 'Hur får jag in mina erfarenheter i terminalen?', 
        iconWithPlate: guides1,
        plateBackground: "#f1f7ed", 
        imageFilter: "brightness(0) invert(61%) sepia(8%) saturate(1361%) hue-rotate(134deg) brightness(94%) contrast(88%)",        
        imagePadding: true,
        imagePosition: "left",
        text: [
          'Alla lantbrukare vet att sunt bondförnuft är viktigt för en lyckad odling. Freja hjälper dig att väva samman din kunskap med data för att enkelt skapa styrfiler anpassade för din gård.',
          'Vad kan du göra i denna vy?',
        ],
        bulletList: [
          'Gör styrfiler för sådd, gödsling och sprutning',
          'I terminalen när det behövs - utan krångel',
          'Arbeta med flera fält samtidig',
        ]
      },
      {
        id: 'guides2',
        header: 'Lägg till en ny guide',
        image: guides2, 
        imagePosition: "right",
        text: [
          'Välj en guide att lägga till direkt när du öpnnar "Samla" eller klicka på knappen "Lägg till" för att lägga till en ny guide.',
          'I guiden kommer du sedan välja ett eller flera skiften som du vill använda guiden för.'
        ]
      },      
      {
        id: 'guides3',
        header: 'Fortsätt arbeta med en guide',
        image: guides3, 
        imagePosition: "left",
        text: [
          'Du kan enkelt fortsätta guider genom att leta upp dem i listan över pågående och avslutade guider.',
          'Klicka sedan på "Förtsätt guiden" för att ladda ned filer eller för att ändra eller fortsätta arbetet.'
        ]
      },          
    ]
  },  
  {
    name: "DesktopCollectFirstLaunchV1",
    cards: [
      {
        id: 'collect1',
        header: 'Samla all data på ett ställe', 
        iconWithPlate: collect1,
        plateBackground: "#f6f6f6",
        imageFilter: "brightness(0) invert(75%) sepia(54%) saturate(323%) hue-rotate(5deg) brightness(98%) contrast(94%)",
        imagePadding: true,
        imagePosition: "left",
        text: ['Glöm alla program och konstig data. Nu gör Freja grovjobbet åt dig och samlar ihop data om skörd, jord och grönmassa till ett och samma ställe. Inte nog med det, Freja gör det det lika lätt att skaffa nya insikter och skapa styrfiler som att skilja på havre och korn.']
      },
      {
        id: 'collect2',
        header: 'Ladda upp markkarteringar',
        iconWithPlate: collect2,
        plateBackground: "#f6f6f6",        
        imageFilter: "brightness(0) invert(75%) sepia(54%) saturate(323%) hue-rotate(5deg) brightness(98%) contrast(94%)",
        imagePadding: true,
        imagePosition: "right",
        text: ['Du kan enkelt ladda upp markkarteringar som du själv har beställt genom exempelvis Eurofins eller Hushållningssällskapen.', 'Du behöver då hitta den textfilen som du har fått med information om var proverna togs samt exempelvis näringsförrådet av kväve och mullhalt.']
      },
      {
        id: 'collect3',
        header: 'Ladda upp skördekaror',
        iconWithPlate: collect3,
        plateBackground: "#f6f6f6",                
        imageFilter: "brightness(0) invert(75%) sepia(54%) saturate(323%) hue-rotate(5deg) brightness(98%) contrast(94%)",
        imagePadding: true,
        imagePosition: "left",
        text: ['Du kan enkelt ladda upp skördekartor som du själv hämtar ur din terminal, ditt växtodlingsprogram eller maskinportal.', 'Just nu stödjer vi skördekartor från AGCO, CLAAS, DataVäxt, John Deere och Topcon.']
      },
      {
        id: 'collect4',
        header: 'Lägg till ny data',
        image: guides2, 
        imagePosition: "right",
        text: [
          'Välj vilken data du vill ladda upp direkt när du kommer in i "Samla" eller klicka på knappen "Samla data".',
          'Du kan antingen lägga till data direkt genom att dra filer eller klicka på filytan eller välja en guide som hjälper dig vidare.'
        ]
      },            
      {
        id: 'collect5',
        header: 'Samla din växtodllingsdata', 
        image: collect5, 
        imagePosition: "left",
        text: ['Du kan även information om din växtodling i Freja som exempelvis sådd, gödsling och skörd.', 'Öppna ett skifte för att lägga in denna informationen som Freja sedan använder för att göra tolkningar, jämförelser och analyser.']
      },  
    ]
  },
  {
    name: "DesktopCompareFirstLaunchV2",
    cards: [
      {
        id: 'compare1',
        header: 'Jämför datalager', 
        image: compare1, 
        imagePosition: "left",
        text: [
          'Här kan du enkelt jämföra olika datalager eller samma datalager under olika datum/år för att hitta nya insikter.',
          'Prova gärna att exempelvis jämföra markkarteringen med skördeutfall eller grönmassan under slutet av juni månad.',
        ]
      },
      {
        id: 'compare2',
        header: 'Freja tolkar åt dig',
        image: compare2, 
        imagePosition: "right",
        text: [
          'Med funktionen "jämför två lager med tolkning" så hjälper Freja dig automatiskt med att tolka de samband som finns mellan datalager.',
          'Till höger ser du punkter på kartan som har stora/små värden samtidigt eller där ena är stor när den andra är liten och visevarsa.',
          'För detta läge finns det färdiga jämförelser att hämta för att exempelvis se om gödsling eller pH-värdet påverkar din skörd..',
        ]
      },      
      {
        id: 'compare3',
        header: 'Välja datalager att jämföra',
        image: compare3, 
        imagePosition: "left",
        text: [
          'För att komma igång behöver du välja lager att jämföra med. Detta gör du enkelt i verktygsfältet ovanför jämförelserna eller genom att öppna en färdig jämförelse.',
          'Du kan även välja att spara dina egna jämförelser för att enkelt kunna använda dem för andra skiften.',
        ]
      },
      {
        id: 'compare4',
        header: 'Utforska liknande punkter och skiften',
        image: compare4, 
        imagePosition: "right",
        text: [
          'Du kan enkelt jämföra två punkter genom att klicka på en punkt i ena kartan eller genom att klicka på en färgstapel i en av kartorna.',
          'Du kan även enkelt byta skiften för jämförelsen genom att klicka på skiftesnamnet uppe till vänster och välja ett annat skifte.'
        ]
      },
    ]
  },  
  {
    name: "DesktopGuideMapEditorFirstLaunchV1",
    cards: [
      {
        id: 'mapEditor2',
        header: 'Jämför datalager', 
        image: mapEditor2, 
        imagePosition: "left",
        text: [
          'Här kan du enkelt jämföra olika datalager och justera styrfilsförslaget.',
          'Jämför exempelvis skiftets senaste grönmassakarta eller annat datalager sida vid sida om föreslagen styrfil.',
        ]
      },
      {
        id: 'mapEditor3',
        header: 'Markera område via diagram',
        image: mapEditor3, 
        imagePosition: "right",
        text: [
          'Markera ett specifikt område i styrfilen genom att antingen välja...',
          '...ett område i diagrammet som markeras på ditt valda datalager eller...',
        ]
      },      
      {
        id: 'mapEditor4',
        header: 'Markera område via verktyg',
        image: mapEditor4, 
        imagePosition: "left",
        text: [
          '...valfria punkter på någon av kartorna.',
        ]
      },
      {
        id: 'mapEditor5',
        header: 'Justera givan i de markerade punkterna',
        image: mapEditor5, 
        imagePosition: "right",
        text: [
          'Justera givan genom att exempelvis strypa ett värde till max- eller minintervaller, alternativt justera givan i procent eller kg.',
        ]
      },
    ]
  },       
];
