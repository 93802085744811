import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Container, 
  Header, 
  Grid,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";

import privacyText from "./user-terms.json";
import usertermsText from "./user-terms.json";
import thirdPartyText from "./third-party-licenses.json";

const INITIAL_STATE = {
  thirdPartyShowList: [-1]
};

class TermsList extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onClickHeader = key => {
    if (this.props.mode !== "third-party-licences") {
      return null;
    }

    const currentValue = this.state.thirdPartyShowList;
    let newValue = [];

    if (currentValue && currentValue.includes(key)) {
      newValue = currentValue.filter(x => x !== key);
    } else {
      newValue = [...currentValue, key];
    }

    this.setState({ thirdPartyShowList: newValue });
  };

  getLicenceDetails = info => (
    <Segment 
      secondary
      style={{
        padding: "1.5em",
      }}
    >
      <li>Publisher: {info.publisher}</li>
      <li>E-mail: {info.email}</li>
      <li>Homepage: {info.url}</li>
      <li>Licence: {info.licenses}</li>
      <br />
      {info.licenseText}
    </Segment>
  );

  render() {
    const { thirdPartyShowList } = this.state;

    const { mode } = this.props;

    var modes = {
      privacy: {
        header: "Integritetspolicy",
        infoText: "Detta dokument beskriver hur AgriOpt hanterar den information som du laddar upp till oss och hur vi hanterar dina personuppgifter.",
        textObject: privacyText,
        showChevron: false,
        showAll: true
      },
      "user-terms": {
        header: "Användarvillkor",
        infoText: "Detta dokument beskriver alla villkor som gäller när du använder AgriOpts tjänster.",
        textObject: usertermsText,
        showChevron: false,
        showAll: true
      },
      "third-party-licences": {
        header: "Tredjepartslicenser",
        infoText: "När vi bygger våra produkter använder vi ofta programvara under öppna licenser från andra tillverkare och privatpersoner. Här har vi samlat de paket som vi har använt och dess licenser.",
        textObject: thirdPartyText,
        showChevron: true,
        showAll: false
      }
    };

    let currentMode = modes && mode && modes[mode];

    return (
      <React.Fragment>        
        <Container
          style={{
            padding: "2em",
            paddingBottom: "70px",
            width: "100%",
          }}
        >  
          <Grid style={{ marginTop: "2em" }}>
            <Grid.Row columns="2">
              <Grid.Column width={12}>
                
                <Segment 
                  style={{
                    padding: "2em",   
                    whiteSpace: "pre-line",     
                  }}
                >        
                  <Header
                    as="h1"
                    style={{
                      fontWeight: "normal",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {currentMode.header}
                  </Header>    

                  {Object.keys(currentMode.textObject).map((key, index) => (
                    <div key={key} style={{ marginTop: "1em" }}>
                      <Header 
                        as="h3" 
                        style={{
                          fontWeight: currentMode.showChevron ? "normal" : null,
                          marginBottom: (!thirdPartyShowList.includes(index) && currentMode.showChevron) ? "1em" : null,
                        }}
                        onClick={() => this.onClickHeader(index)}
                      >
                        {key}
                        {currentMode.showChevron && 
                          <Icon 
                            style={{ 
                              fontSize: "75%", 
                              marginBottom: 10, 
                              marginLeft: 5,
                            }} 
                            className="iconButton"
                            name={thirdPartyShowList.includes(index) ? "chevron up" : "chevron down"}
                            color="grey"
                          />}
                      </Header>

                      <p
                        style={{
                          fontSize: "110%",
                          width: "85%",
                        }}
                      >
                        {currentMode.showAll && currentMode.textObject[key].content}
                        {thirdPartyShowList.includes(index) && this.getLicenceDetails(currentMode.textObject[key])}
                      </p>
                    </div>
                  ))}
              </Segment>            
            </Grid.Column>
            
            <Grid.Column width={4}>
              <Segment 
                raised
                style={{
                  padding: "3em",
                  minWidth: "250px",
                }}
              >
                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  {currentMode.header}
                </Header>   

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  {currentMode.infoText}
                </p>  

                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Läst klart?
                </Header>   

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  Då kan du gå tillbaka genom att klicka på knappen nedanför.
                </p>  

                <Button
                  fluid
                  icon
                  labelPosition="left"
                  style={{ 
                    backgroundColor: "#6B9D9F", 
                    color: "white",
                  }}
                  onClick={() => this.props.history.push("/home/legal")}
                >
                  <Icon name="chevron left" />
                  Gå tillbaka
                </Button>
              </Segment>
            </Grid.Column>        
          </Grid.Row>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(TermsList);
