import React, { Component } from "react";

import { 
  Header,
  Message,
  Segment,
  Popup,
  Icon,
  Button,
} from "semantic-ui-react";

import { toast } from "react-toastify";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideMapEditor from "@/components/guide/GuideMapEditor";
import GuideSegment from "@/components/guide/GuideSegment";

import ComputationModal from "./computationModal";

import { fetchGeoJsonData } from "@/components/guide/GuideDataFetcher";
import { getColorScales } from "@/components/fieldData/FieldDataColorScales";

import illustrationMoney from "@/assets/icons/home/money.svg";

class AdjustFiles extends Component {

  state = {
    openSegment: null,
    completedSteps: [],
    editorOpen: null,
    showConfirmEditorClose: false,
    editorOpenDataCopy: null,
    tryingToGenerateAgain: [],
    showCalculationModal: null,
    showAllFields: null,
  };

  componentDidMount = () => {
    const {
      db,
      guideId,
      fileBucketRef,
      selectedFieldIds,
      currentRationNo,
    } = this.props;

    selectedFieldIds && selectedFieldIds.forEach((x) => {
      fetchGeoJsonData({
          db,
          fieldId: x,
          guideId,
          setParentState: this.props.setParentState,
          fileBucketRef, 
          currentRationNo,
      });
    });
  };

  componentDidUpdate = (prevProps) => {
    const {
      db,
      guideId,
      fileBucketRef,
      selectedFieldIds,
      currentRationNo,
    } = this.props;

    let rationString = typeof(currentRationNo) === "number" ? currentRationNo.toFixed(0) : '1';

    let oldDb = prevProps && prevProps.db;
    let newGuides = db && guideId && db.guides && db.guides[guideId];
    let oldGuides = oldDb && guideId && oldDb.guides && oldDb.guides[guideId];    

    if (newGuides !== oldGuides) {
      selectedFieldIds && selectedFieldIds.forEach((x) => {
        let updateFlag = true;

        let oldUpdate = oldGuides && oldGuides['date_suggested_files_' + rationString] && oldGuides['date_suggested_files_' + rationString][x];
        let newUpdate = newGuides && newGuides['date_suggested_files_' + rationString] && newGuides['date_suggested_files_' + rationString][x];
        let noTimeUpdate = oldUpdate && newUpdate && oldUpdate.toDate().getTime() === newUpdate.toDate().getTime()

        updateFlag = noTimeUpdate && this.props["vraMap_" + x] ? false : updateFlag;

        updateFlag && fetchGeoJsonData({
            db,
            fieldId: x,
            guideId,
            setParentState: this.props.setParentState,
            fileBucketRef, 
            currentRationNo,
        });
      });
    };
  };

  setShowAllFields = newValue => this.setState({ showAllFields: newValue });

  toogleShowCalculation = (fieldId) => {
    this.setState({ showCalculationModal: fieldId ? fieldId : false });
  };

  setOpenSegment = (newSegment) => {
    (this.state.openSegment === newSegment) && this.setState({ openSegment: null, editorOpen: null, });
    (this.state.openSegment !== newSegment) && this.setState({ openSegment: newSegment, editorOpen: null, });
  };

  setOpenNextSegment = (fieldId, generateFile=true) => {
    const {
      db,
      skippedVraMapsForFields,
      selectedFieldIds,
      guidePurpose,
      guideId,
      fileBucketRef,
    } = this.props;

    const {
      completedSteps,
    } = this.state;

    this.setState({
      completedSteps: [...completedSteps, fieldId],
      editorOpen: null,
    });

    let fieldName = db && fieldId && db.fields && db.fields[fieldId] && db.fields[fieldId].name;
    fieldName = fieldName ? fieldName : "med okänt namn";

    let suggestedVraMap = this.props["vraMap_" + fieldId] && this.props["vraMap_" + fieldId];
    let approvedVraMap = this.props["modifiedVraMap_" + fieldId] ? this.props["modifiedVraMap_" + fieldId] : suggestedVraMap;

    if (generateFile && approvedVraMap) {
      this.props.generateVraMapFile(fieldId, approvedVraMap);
      this.props.saveAdjustedVraMapToDatabase(fieldId, suggestedVraMap, approvedVraMap);

      let text = "Freja skapar nu styrfiler för " + (guidePurpose ? guidePurpose : "") + " för skiftet " + fieldName + ".";
      toast.success(text,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
          hideProgressBar: false,
        }
      );

    } else {
      this.props.setParentState({
        skippedVraMapsForFields: [...skippedVraMapsForFields, fieldId],
      })
    }

    let currentIndex = selectedFieldIds && selectedFieldIds.indexOf(fieldId);

    if ((currentIndex + 1) >= selectedFieldIds.length) {
      this.props.onClickNextStep();

    } else {
      
      let newFieldId = selectedFieldIds[currentIndex + 1];
      newFieldId && !this.props["vraMap_" + newFieldId] && fetchGeoJsonData({
        db,
        fieldId: newFieldId,
        guideId,
        setParentState: this.props.setParentState,
        fileBucketRef, 
      });

      newFieldId && this.setOpenSegment(newFieldId);
    }
  };

  approveAllFiles = () => {
    const {
      geoJsonFieldKey,
      selectedFieldIds,
      skippedVraMapsForFields,
      overrideNoVraMapSuggestion,
    } = this.props;

    this.setState({ completedSteps: [...selectedFieldIds] });

    selectedFieldIds && selectedFieldIds.forEach((x) => {
      let suggestedVraMap = this.props["vraMap_" + x] && this.props["vraMap_" + x];
      let currentVraMap = this.props["modifiedVraMap_" + x] ? this.props["modifiedVraMap_" + x] : suggestedVraMap;
      
      let markerData = currentVraMap && currentVraMap.features && currentVraMap.features.map((y) => y.properties[geoJsonFieldKey]);
      let markerDataMin = markerData && Math.min(...markerData);
      let markerDataMax = markerData && Math.max(...markerData);
      let markerDataMean = markerData && Math.mean(markerData);

      let noVraMapNeeded = typeof(markerDataMean) === "number" && parseFloat(markerDataMean) === 0.0;
      noVraMapNeeded = typeof(markerDataMean) === "number" && parseFloat(markerDataMean) === 0 ? true : noVraMapNeeded;
      noVraMapNeeded = typeof(markerDataMin) === "number" && typeof(markerDataMax) === "number" && markerDataMin === markerDataMax ? true : noVraMapNeeded;
      noVraMapNeeded = overrideNoVraMapSuggestion.includes(x) ? false : noVraMapNeeded;

      if (currentVraMap && !noVraMapNeeded) {
        this.props.generateVraMapFile(x, currentVraMap);
        this.props.saveAdjustedVraMapToDatabase(x, suggestedVraMap, currentVraMap);
      } else {
        this.props.setParentState({
          skippedVraMapsForFields: [...skippedVraMapsForFields, x],
        })
      }
    });

    this.props.onClickNextStep();
  };

  setEditorOpen = (fieldId) => {
    let markerData = this.props["vraMap_" + fieldId] && this.props["vraMap_" + fieldId];
    markerData = this.props["modifiedVraMap_" + fieldId] ? this.props["modifiedVraMap_" + fieldId] : markerData;

    this.setState({ 
      editorOpen: fieldId,
      editorOpenDataCopy: markerData,
    });
  };


  revertChangesToVraMapSuggestion = (fieldId) => {
    let originalVraMapSuggestion = this.props["vraMap_" + fieldId] && this.props["vraMap_" + fieldId];
    originalVraMapSuggestion = this.state.editorOpenDataCopy ? this.state.editorOpenDataCopy : originalVraMapSuggestion;

    this.props.setParentState({ 
      ["modifiedVraMap_" + fieldId]: originalVraMapSuggestion,
      ["markerDataRedoHistory_" + fieldId]: [],
      ["markerDataUndoHistory_" + fieldId]: [originalVraMapSuggestion],
    });

    this.setState({ 
      editorOpen: null,
      editorOpenDataCopy: null,
    });
  };

  setOverrideNoVraMapSuggestion = (fieldId) => {
    let currentValue = this.props.overrideNoVraMapSuggestion;
    this.props.setParentState({
      overrideNoVraMapSuggestion: [...currentValue, fieldId],
    });
  };

  render() {
    const {
      db,
      guideId,
      guideName,
      layer,
      selectedFieldIds,
      descriptionNoSuggestion,
      overrideNoVraMapSuggestion,
      editorDefaultLayer,
      editorDefaultLayerId,
      geoJsonFieldKey,
      pureCompoundName,
      currentRationNo,
      compoundText,
      guideMode,
    } = this.props;

    const {
      openSegment,
      completedSteps,
      editorOpen,
      showCalculationModal,
    } = this.state;

    let showAllFields = guideMode && guideMode === "advanced";
    showAllFields = typeof(this.state.showAllFields) === "boolean" ? this.state.showAllFields : showAllFields;

    let allFilesNotReady = false;
    selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
      if (!this.props["vraMap_" + fieldId]) {
        allFilesNotReady = true;
      };
    });

    let menuItems = {
      next: [
        {
          caption: "Godkänn alla styrfiler",
          disabled: allFilesNotReady,
          onClick: this.approveAllFiles,
        }
      ],
    };

    menuItems = this.props.onClickPreviousStep ? 
      {...menuItems,
        previous: [
          {
            caption: "Tillbaka",
            onClick: this.props.onClickPreviousStep,
          }
        ],      
      } : menuItems;

    let guideData = db && guideId && db.guides && db.guides[guideId];

    let preCaption = "Grundgiva: ";
    preCaption = currentRationNo && currentRationNo > 0 ? "Kompletteringsgiva " + currentRationNo + ": " : preCaption;

    let totalRationSavings = 0.0;
    let totalRationOld = 0.0;

    selectedFieldIds && selectedFieldIds.forEach((x) => {
      let fieldInfo = db && x && db.fields && db.fields[x];
      let markerData = this.props["vraMap_" + x] && this.props["vraMap_" + x];
      let rationSavings = markerData && fieldInfo && this.props.computeSavings(fieldInfo, markerData);

      if (rationSavings && rationSavings.diff && rationSavings.diff > 0.0) {
        totalRationSavings += rationSavings.diff;
        totalRationOld += rationSavings.oldRation;
      };
    });

    let totalRationSavingsDiff = totalRationOld && totalRationOld > 0.0 ? totalRationSavings / totalRationOld : null;
    let totalRationSavingsDiffText = totalRationSavingsDiff ? (100 * totalRationSavingsDiff).toFixed(0) : null;

    let totalRationSavingsText = '';
    totalRationSavingsText = totalRationSavings && totalRationSavings >= 1000.0 ? Math.floor(totalRationSavings).toLocaleString('sv') : totalRationSavingsText;
    totalRationSavingsText = totalRationSavings && totalRationSavings < 1000.0 ? totalRationSavings.toFixed(0) : totalRationSavingsText;

    let allMarkersAllFields = [];

    selectedFieldIds && selectedFieldIds.forEach((x) => {
      let markerData = this.props["vraMap_" + x] && this.props["vraMap_" + x];
      markerData = this.props["modifiedVraMap_" + x] ? this.props["modifiedVraMap_" + x] : markerData;
      markerData = markerData && markerData.features;

      allMarkersAllFields = markerData ? [...allMarkersAllFields, ...markerData] : allMarkersAllFields;
    });

    let colorScales = showAllFields && allMarkersAllFields && getColorScales("vraMap", {features: allMarkersAllFields}, geoJsonFieldKey, "e", 5, null);
    
    let mainColumn =
      <React.Fragment>
        
        <ComputationModal
          open={showCalculationModal}
          guideName={guideName}
          markerData={showCalculationModal && this.props["vraMap_" + showCalculationModal]}
          toogleShowCalculation={this.toogleShowCalculation}
        />
    
        <div
          style={{
            width: "67%",
            paddingBottom: "4em",
          }}
        >
          {totalRationSavingsText && totalRationSavingsDiffText &&
            <Segment
              secondary
              style={{
                padding: "1em",
              }}
            >
              <div 
                style={{ 
                  display: "flex",
                }
              }>
                <img 
                  style={{
                    width: "15%",
                  }}
                  alt=''
                  src={illustrationMoney} 
                />     

                <div 
                  style={{
                    whiteSpace: "pre-line",
                    fontFamily: "Istok Web",
                    width: "85%",
                    marginLeft: "5%",
                    marginTop: "0.75em",
                  }}
                >
                  <Header
                    as="h2"
                    style={{
                      color: "rgb(107, 157, 159)",
                      whiteSpace: "pre-line",
                      fontWeight: 500,
                    }}
                  >
                    Du sparar upp till
                    <span style={{ fontWeight: "600", fontSize: "120%", marginLeft: 7, marginRight: 7 }}>
                      {totalRationSavingsText && totalRationSavingsText}
                    </span>
                    {compoundText ? "kg " + compoundText.toLowerCase() + " " : "kg "}
                    med dessa styrfiler.
                  </Header>   

                  <p
                    style={{
                      whiteSpace: "pre-line",
                      fontSize: "110%",
                      width: "67%",
                    }}
                  >
                    Detta motsvarar ungefär <strong> {" " + totalRationSavingsDiffText + " % "} </strong>
                    av den totala insatsen. Som du sparar med hjälp av styrfiler från Freja och precisionsodling.
                  </p>
                </div>
                
                <Popup
                  trigger={<Icon name="help circle" />}
                  content={"Vi har beräknat detta genom att jämföra den föreslagna styrfilen ovan med en rak giva över hela skiftet på 90% av den maximalt föreslagna givan."}
                />
              </div>
            </Segment>
          }

          {/* <Segment
            secondary
            style={{
              marginTop: "0.5em",
              marginBottom: "1em",
              padding: "1em",
              display: "flex",
              justifyContent: "space-between",                
            }}
          >
            <Header
              as="h3"
              style={{
                width: "30%",
                marginTop: "auto",
                marginBottom: "auto",
                fontWeight: 500,
              }}
            >
              Valt gödslingsmedel
            </Header>

            <p
              style={{
                fontWeight: 500,
                marginTop: "auto",
                marginBottom: "auto",
                width: "40%"
              }}                  
            >
              {compoundText ? compoundText : "Inget valt (ställ in i terminalen)"}
            </p>

            <div
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Button
                basic
                disabled
                size="small"
                onClick={() => this.toggleShowAllFiles()}
              >
                Ändra
              </Button>
            </div>
          </Segment>   */}
          
          {selectedFieldIds && selectedFieldIds.map((x, idx) => {
            let errorMessage = guideData && guideData.errors_suggested_files && guideData.errors_suggested_files[x];
            let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;
            fieldName = fieldName ? fieldName : "okänt skiftesnamn";

            if (errorMessage) {
              return (
                <GuideSegment
                  key={"GuideSegment_" + x}
                  caption={preCaption + fieldName}
                  open={showAllFields || openSegment === x || (!openSegment && idx === 0)}
                  complete={completedSteps && completedSteps.includes(x)}
                  onClickOpenSegment={() => this.setOpenSegment(x)}
                  onClickNextStep={() => this.setOpenNextSegment(x, false)}
                >
                  <Message
                    style={{
                      padding: "2em",
                      whiteSpace: "pre-line",
                      backgroundColor: "#C36126",
                      color: "white",
                    }}
                    icon='exclamation triangle'
                    header='Styrfilsförslaget kunde inte skapas'
                    content={"Freja hade tyvärr problem med att skapa ett styrfilsförslag. Supporten har redan meddelats detta problem och kommer kolla upp och höra av sig till dig. Om du vill så kan du prova att skapa ett förslag igen."}
                  />

                  <Message
                    style={{
                      padding: "1.5em",
                      whiteSpace: "pre-line",
                    }}
                    size="tiny"
                  >
                    <Message.Header>Felmeddelande</Message.Header>

                    <Message.Content>
                      {errorMessage}

                      <Button
                        style={{
                          display: "block",
                          marginTop: "1em",
                        }}
                        primary
                        loading={this.state.tryingToGenerateAgain.includes(x)}
                        onClick={() => {
                          this.setState({ tryingToGenerateAgain: [...this.state.tryingToGenerateAgain, x] });
                          this.props.tryGetVraMapSuggestionAgain(x);
                        }}
                      >
                        Försök igen
                      </Button>
                    </Message.Content>
                  </Message>
                </GuideSegment>
              )
            }
            
            let markerData = this.props["vraMap_" + x] && this.props["vraMap_" + x];
            markerData = this.props["modifiedVraMap_" + x] ? this.props["modifiedVraMap_" + x] : markerData;

            let markerDataRedoHistory = this.props["markerDataRedoHistory_" + x] ? this.props["markerDataRedoHistory_" + x] : [];
            let markerDataUndoHistory = this.props["markerDataUndoHistory_" + x] ? this.props["markerDataUndoHistory_" + x] : [];

            let markerDataFeatures = markerData && markerData.features && markerData.features.map((y) => y.properties[geoJsonFieldKey]);
            let markerDataMin = markerDataFeatures && Math.min(...markerDataFeatures);
            let markerDataMax = markerDataFeatures && Math.max(...markerDataFeatures);
            let markerDataMean = markerDataFeatures && Math.mean(markerDataFeatures);
      
            let noVraMapNeeded = typeof(markerDataMean) === "number" && parseFloat(markerDataMean) === 0.0;
            noVraMapNeeded = typeof(markerDataMean) === "number" && parseFloat(markerDataMean) === 0 ? true : noVraMapNeeded;
            noVraMapNeeded = typeof(markerDataMin) === "number" && typeof(markerDataMax) === "number" && markerDataMin === markerDataMax ? true : noVraMapNeeded;
            // noVraMapNeeded = overrideNoVraMapSuggestion.includes(x) ? false : noVraMapNeeded;
            
            if (!noVraMapNeeded) {
              return (
                <GuideSegment
                  compact={true}
                  key={"GuideSegment_" + x}
                  caption={preCaption + fieldName}
                  open={showAllFields || openSegment === x || (!openSegment && idx === 0)}
                  complete={completedSteps && completedSteps.includes(x)}
                  onClickOpenSegment={() => this.setOpenSegment(x)}
                  onClickNextStep={() => this.setOpenNextSegment(x, true)}
                  onClickEdit={() => this.setEditorOpen(x)}
                  onClickCalculation={() => this.toogleShowCalculation(x)}
                  doneButtonText="Godkänn förslag"
                  editButtonTest="Redigera förslag"
                >
                  <GuideMapEditor
                    {...this.props}
                    fieldId={x}
                    field={db && db.fields && db.fields[x]}
                    markerData={markerData}
                    markerDataUndoHistory={markerDataUndoHistory}
                    markerDataRedoHistory={markerDataRedoHistory}
                    editorOpen={editorOpen === x}
                    setParentState={this.props.setParentState}
                    computeSavings={this.props.computeSavings}
                    hideSavedStats={overrideNoVraMapSuggestion.includes(x)}
                    layer={layer}
                    editorDefaultLayer={editorDefaultLayer}
                    editorDefaultLayerId={editorDefaultLayerId}
                    geoJsonFieldKey={geoJsonFieldKey}
                    colorScales={colorScales}
                    pureCompoundName={pureCompoundName}
                    onSaveChanges={() => this.setState({ 
                      editorOpenDataCopy: null, 
                      editorOpen: null 
                    })}
                    onCancelChanges={this.revertChangesToVraMapSuggestion}
                  />              
                </GuideSegment>      
              )
            } else {
              return (
                <GuideSegment
                  key={"GuideSegment_" + x}
                  caption={preCaption + fieldName}
                  open={showAllFields || openSegment === x || (!openSegment && idx === 0)}
                  complete={completedSteps && completedSteps.includes(x)}
                  onClickOpenSegment={() => this.setOpenSegment(x)}
                  onClickNextStep={() => this.setOpenNextSegment(x, false)}
                  onClickEdit={typeof(markerDataMax) === "number" && markerDataMax > 0 ? () => {this.setOverrideNoVraMapSuggestion(x); this.setEditorOpen(x)} : null}
                >
                  <Message
                    icon
                    style={{
                      marginTop: "3em",
                      padding: "2em",
                      whiteSpace: "pre-line",
                      backgroundColor: "#89A275",
                      color: "white",
                    }}
                    size="large"
                  >
                    <Icon name='check' />
                    <Message.Content>
                      <Message.Header>Freja bedömmer att detta skifte inte behöver någon styrfil</Message.Header>
                      
                      {descriptionNoSuggestion && descriptionNoSuggestion} 
                      {!descriptionNoSuggestion && typeof(markerDataMax) === "number" && markerDataMax > 0 && "Istället rekommenderas en rak giva på " + markerDataMax.toFixed(0) + ' kg/ha. Om du ändå vill skapa en styrfil kan du klicka på knappen "Redigera styrfil" nedanför.'}
                      {!descriptionNoSuggestion && (typeof(markerDataMax) !== "number" || markerDataMax === 0) && "Behovet av " + pureCompoundName + " är redan uppnått. Gå tillbaka i guiden och ändra inställningarna för att skapa en styrfil."}
                    </Message.Content>
                  </Message>
                </GuideSegment>
              )
            }
          })}

          {showAllFields &&
            <Segment
              secondary
              style={{
                padding: "1.5em",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Header
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  fontWeight: 500,
                }}
                as="h2"
              >
                Nöjd med alla förslag?
              </Header>

              <Button 
                primary
                size="big"
                onClick={() => this.approveAllFiles()}
              >
                Godkänn alla förslag
              </Button>
            </Segment>
          }
        </div>
      </React.Fragment>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Justera förslag
        </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Här kan du justera de förslag på styrfiler som Freja har tagit fram baserat på de val du har gjort i tidigare steg.
        </p>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Du kan redigera styrfilen i Frejas editor och se en exempelberäkning för en genomsnittlig punkt på skiftet. När du är nöjd så godkänner du förslaget, då kommer Freja att skapa en styrfil för din terminal.
        </p>        

        <Button
          icon
          fluid
          style={{
            marginTop: "1em",
            backgroundColor: "#6B9D9F",
            color: "white",                      
          }}
          labelPosition="left"
          onClick={() => this.setShowAllFields(!showAllFields)}
        >
          <Icon name="folder open outline" />
          {(!showAllFields || showAllFields === false) ? "Visa alla styrfiler på samma gång" : "Visa en styrfil i taget"}
        </Button>   

        <Header
          as="h3"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Redigera styrfil
        </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Editorn gör det enkelt att förändra förslagen på flera olika sätt så att du får styrfilen precis som du vill ha den.
          
        </p>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >          
          Du kan markera punkter och sedan lägga till eller dra ifrån en viss mängd eller procent till förslaget.
          Det är också enkelt att justera styrfilen med hjälp av andra datalager till exempel grönmassan eller dina egna insikter.
        </p>

        <Button
          icon
          fluid
          style={{
            marginTop: "1em",
            backgroundColor: "#6B9D9F",
            color: "white",                      
          }}
          labelPosition="left"
          href="/help"
          target="_blank" 
          rel="noopener noreferrer"
        >
          <Icon name="question circle outline" />
          Läs mer i hjälpcentret
        </Button>

        <Header
          as="h3"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Är du inte nöjd?
        </Header>
            
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Om du inte är nöjd med dina styrfiler så kan du gå tillbaka genom att klicka på knappen "Tillbaka" nere till vänster.
          Du kan också klicka på knappen nedanför för att återställa guiden och börja om från början igen.

          <Button
            icon
            fluid
            style={{
              marginTop: "1em",
              backgroundColor: "#6B9D9F",
              color: "white",                      
            }}
            labelPosition="left"
            onClick={() => this.props.onClickStartOver()}
          >
            <Icon name="chevron left" />
            Gör om styrfilerna från början
          </Button>                      
        </p>                
      </React.Fragment>

    return (
      <React.Fragment>
        <GuidesBasicGrid
          params={this.props.params}
          slideDirection={this.props.slideDirection}        
          mainColumn={mainColumn}
          mainColumnHeader="Justera och godkänn förslag på styrfiler"
          helpColumn={helpColumn}
          showFullGrid={this.props.showFullGrid}
          toggleHelpTexts={this.props.toggleHelpTexts}
          menuItems={menuItems}
          guideSubMenuActive={this.props.guideSubMenuActive}
        />               
      </React.Fragment>
    )
  }
}

export default AdjustFiles;