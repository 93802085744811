import React, { Component } from "react";

import {
  Header,
  Input,
  Button,
  Table,
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import { hasLayerData } from "@/helpers/dataFetcher";

import { AIM_RATION } from "./constants";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

import GuideSegmentAdvancedMode from "@/components/guide/GuideSegmentAdvancedMode";
import GuideSegmentCompound from "@/components/guide/GuideSegmentCompound";
import GuideSegmentHarvest from "@/components/guide/GuideSegmentHarvest";
import GuideSegmentPreviousRation from "@/components/guide/GuideSegmentPreviousRation";


const INITIAL_STATE = {
  overrideDisabledPreviousCrop: [],
};

class PhosphorousNeed extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickNextStep = () => {
    const {
      completedSteps,
    } = this.props;

    this.props.setParentState({
      openSegment: '',
      completedSteps: [...completedSteps, "yield"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment, data = null, forceUpdate = false) => {
    const {
      db,
      guideName,
      guideAimRation,
    } = this.props;

    switch (newSegment) {

      case "compound":
        let previousCompound = db && db.settings && db.settings.guides;
        previousCompound = previousCompound && previousCompound[guideName];
        previousCompound = previousCompound && previousCompound.compoundType;
        previousCompound = previousCompound ? previousCompound : "none";

        previousCompound && this.props.setParentState({ guideCompoundType: previousCompound });
        break;

      case "aimRation":
        !forceUpdate && this.props.setParentState({ guideAimRation: { ...AIM_RATION, ...guideAimRation } });
        forceUpdate && this.props.setParentState({ guideAimRation: { ...AIM_RATION } });
        break;

      default:
        break;
    };
  };

  onChange = (data, onlyNumbers = true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  };

  onChangeStruct = (e, data, variableName, onlyNumbers = true) => {
    let currentValue = this.props[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: { ...currentValue, [data.name]: value },
    });
  };

  setOpenSegment = (newSegment) => {
    const {
      openSegment
    } = this.props;

    (openSegment === newSegment) && this.props.setParentState({ openSegment: null });
    (openSegment !== newSegment) && this.props.setParentState({ openSegment: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedSteps,
    } = this.props;

    !completedSteps.includes(currentSegment) && this.props.setParentState({
      completedSteps: [...completedSteps, currentSegment],
    });

    this.setDefaultSettings(newSegment);

    !completedSteps.includes(newSegment) && this.setOpenSegment(newSegment);
    completedSteps.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  render() {

    const {
      db,
      fileBucketRef,
      userId,
      selectedFieldIds,
      guideAimRation,
      guideExpectedYield,
      guideExpectedYieldCalibration,
      openSegment,
      completedSteps,
      guideMode,
    } = this.props;

    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],
      next: [
        {
          caption: "Gå vidare",
          hidden: completedSteps.length < 5,
          onClick: this.onClickNextStep,
        }
      ],
    };

    let missingYieldMapStatistics = [];

    selectedFieldIds && selectedFieldIds.forEach((x) => {
      missingYieldMapStatistics = !hasLayerData(db, x, "yieldStatistics") ? [...missingYieldMapStatistics, x] : missingYieldMapStatistics;
    });

    const plotData = {
      labels: guideAimRation && Object.keys(guideAimRation),
      datasets: [{
        data: guideAimRation && Object.keys(guideAimRation).map((x) => guideAimRation[x] ? parseFloat(guideAimRation[x]) : 0.0),
        fill: false,
        backgroundColor: "#89A275",
        borderColor: "#4C8440"
      }]
    };

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegmentAdvancedMode
          open={openSegment === "guideMode"}
          complete={completedSteps && completedSteps.includes("guideMode")}
          setParentState={this.props.setParentState}
          onClickOpenSegment={() => this.setOpenSegment("guideMode")}
          onClickNextStep={() => this.gotoNextStep("guideMode", "previousRation")}
        />

        <GuideSegmentPreviousRation
          {...this.props}
          skipSimpleMode={guideMode && guideMode === "advanced"}
          guideAction="fosforgödsling"
          compoundType="P"
          open={openSegment === "previousRation"}
          complete={completedSteps && completedSteps.includes("previousRation")}
          onClickOpenSegment={() => this.setOpenSegment("previousRation")}
          onClickNextStep={() => this.gotoNextStep("previousRation", "compound")}
        />

        <GuideSegmentCompound
          {...this.props}
          open={openSegment === "compound"}
          complete={completedSteps && completedSteps.includes("compound")}
          onClickOpenSegment={() => this.setOpenSegment("compound")}
          onClickNextStep={() => this.gotoNextStep("compound", "aimRation")}
          caption="Gödslingsmedel"
          question="Vill du ange gödslingsmedel?"
          description="Välj den typ av gödslingsmedel som du kommer att använda i din fosforgödsling."
          compoundName="fosfor"
          compoundType="P"
        />

        <GuideSegment
          skipSimpleMode={guideMode && guideMode === "advanced"}
          caption="Målgiva"
          simpleModeQuestion="Vill du göra förändringar i Jordbruksverkets rekommendationer för målgivan?"
          simpleModeOpenText="Justera"
          open={openSegment === "aimRation"}
          complete={completedSteps && completedSteps.includes("aimRation")}
          onClickOpenSegment={() => this.setOpenSegment("aimRation")}
          onClickNextStep={() => this.gotoNextStep("aimRation", "yield")}
          simpleModeOnSkip={() => this.setDefaultSettings("aimRation", null, true)}
          disableNextStep={guideAimRation && Object.keys(guideAimRation).find((x) => !guideAimRation[x] || guideAimRation[x].length === 0)}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
            }}
          >
            Freja utgår från Jordbruksverkets rekommendationer för riktgivor tillsammans med den förväntade skörden för att beräkna fosforbehovet på dina skiften.
            I tabellen nedanför kan du justera givan (kg P/ha) för varje fosforklass.
          </p>

          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 15,
              width: "80%",
              fontSize: "110%",
            }}
          >
            Dessa riktgivor justeras med 3 kg P/ha per ton skörd som avviker från 6 ton/ha.
          </p>

          {guideAimRation &&
            <article
              className="canvas-container"
              style={{
                width: "100%",
                height: "30vh",
                background: "transparent",
                padding: 0,
                marginTop: "4em",
              }}
            >
              <Line
                data={plotData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  layout: {
                    padding: {
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0
                    }
                  },
                  legend: {
                    display: false,
                  },
                  scales: {
                    yAxes: [{
                      ticks: {
                        fontSize: 14,
                        fontColor: 'grey',
                      },
                      scaleLabel: {
                        display: true,
                        fontSize: 12,
                        fontColor: 'black',
                        labelString: "Giva (kg P/ha)",
                      }
                    }],
                    xAxes: [{
                      ticks: {
                        fontSize: 14,
                        fontColor: 'grey',
                      },
                      scaleLabel: {
                        display: false,
                        fontSize: 12,
                        fontColor: 'black',
                        labelString: "Fosforklass",
                      }
                    }],
                  }
                }}
              />
            </article>
          }

          {guideAimRation &&
            <Table
              celled
              striped
              columns={Object.keys(guideAimRation) && typeof (Object.keys(guideAimRation).length) === "number" ? Object.keys(guideAimRation).length.toFixed(0) : Object.keys(guideAimRation).length}
            >
              <Table.Header>
                <Table.Row>
                  {guideAimRation && Object.keys(guideAimRation).map((x) => (
                    <Table.HeaderCell textAlign="center">
                      Klass {" " + x}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  {guideAimRation && Object.keys(guideAimRation).map((x) => (
                    <Table.Cell>
                      <Input
                        key={"InputCropAimRation_" + x}
                        onChange={(e, d) => this.onChangeStruct(e, d, "guideAimRation")}
                        fluid
                        value={guideAimRation[x]}
                        error={!guideAimRation[x] || guideAimRation[x].length === 0}
                        name={x}
                      />
                    </Table.Cell>
                  ))}
                </Table.Row>
              </Table.Body>
            </Table>
          }
        </GuideSegment>

        <GuideSegmentHarvest
          db={db}
          fileBucketRef={fileBucketRef}
          userId={userId}
          guideMode={guideMode}
          caption="Skörd"
          open={openSegment === "yield"}
          simpleModeQuestion={missingYieldMapStatistics.length === 0 ? "Hur mycket skörd vill du beräkna givan för?" : null}
          simpleModeOpenText="Ange annat"
          simpleModeSkipText="Senaste skörden"
          complete={completedSteps && completedSteps.includes("yield")}
          onClickOpenSegment={() => this.setOpenSegment("yield")}
          onClickNextStep={() => this.onClickNextStep()}
          description={
            <React.Fragment>
              <p
                style={{
                  whiteSpace: "pre-line",
                  width: "80%",
                  fontSize: "110%",
                }}
              >
                Freja behöver veta hur mycket skörd som du vill kompensera för. En större skörd medför att mer fosfor tas bort från marken och måste ersättas.
              </p>

              <p
                style={{
                  whiteSpace: "pre-line",
                  width: "80%",
                  fontSize: "110%",
                }}
              >
                Om du vill kompensera för skörden under flera år kan du räkna upp snittskörden nedan.
              </p>
            </React.Fragment>
          }
          setParentState={this.props.setParentState}
          selectedFieldIds={selectedFieldIds}
          guideExpectedYield={guideExpectedYield}
          guideExpectedYieldCalibration={guideExpectedYieldCalibration}
        />
      </div>

    let helpColumn =
      <React.Fragment>
        <Header
          as="h3"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Vad baseras styrfilen på?
        </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Frejas styrfilsförslag bygger på välkända riktlinjer från Jordbruksverket som baseras på omfattade växtodlingsförsök. Läs mer genom att klicka på knapparna nedanför.
        </p>

        <Button
          fluid
          primary
          href="https://jordbruksverket.se/vaxter/odling/vaxtnaring/riktgivor-och-strategier-for-godsling"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            marginTop: "1em",
            backgroundColor: "#BF8136",
            color: "white",
          }}
        >
          Jordbruksverkets rekommendationer
        </Button>
      </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader="Uppskatta fosforbehovet"
        mainColumnBody="För att kunna beräkna fosforbehovet behöver du fylla i informationen nedanför. Svara på alla frågor för att gå vidare till nästa steg."
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />
    )
  }
}

export default PhosphorousNeed;