import React, { Component } from "react";

import { 
  Segment,
} from "semantic-ui-react";

import GuideSegment from "@/components/guide/GuideSegment";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const INITIAL_STATE = {};

class GuideSegmentSplitRation extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onChangeSlider = (rationSize) => {
    this.props.setParentState({
      rationSize: rationSize,
    });
  };

  render() {
    const {
      rationSize,
      guideMode,
      simpleModeQuestion,
      simpleModeDescription,
      disabled,
    } = this.props;

    return (
      <GuideSegment
        caption="Dela upp givan"
        disabled={disabled}
        skipSimpleMode={guideMode && guideMode === "advanced"}
        simpleModeQuestion={simpleModeQuestion}
        simpleModeDescription={simpleModeDescription}
        open={this.props.open}
        complete={this.props.complete}
        onClickOpenSegment={this.props.onClickOpenSegment}
        onClickNextStep={this.props.onClickNextStep}  
      >
        <p
          style={{
            whiteSpace: "pre-line",
            marginBottom: 15,
            fontSize: "110%",
          }}
        >
          Hur stor del av givan vill du lägga ut denna gång?
        </p>
        
        <Segment
          secondary
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "2em",
            marginTop: "4em",
          }}
        >
          <div
            style={{
              width: "92%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Slider
              trackStyle={{ background: "#9CB5B6" }}
              dotStyle={{ borderColor: "#9CB5B6" }}
              handleStyle={{ borderColor: "#9CB5B6" }}
              value={rationSize}
              step={5.0}
              onChange={this.onChangeSlider}
            />
          </div>

          <div
            style={{
              width: "8%",
              marginTop: -5,
              marginLeft: "10%",
              fontSize: "150%",
            }}
          >
            {rationSize && rationSize.toFixed(0) + "%"}
          </div>
        </Segment>                
      </GuideSegment>   
    )
  }
}

export default GuideSegmentSplitRation;