import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import {
  Menu,
  Icon,
  Popup,
} from "semantic-ui-react";

import MenuSecondary from "@/components/menus/MenuSecondary";

import "./style.css";

const INITIAL_STATE = {};

class MenuHome extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {

    const {
      db,
      userId,
    } = this.props;

    let noFields = db && db.fields && Object.keys(db.fields).length;

    let activeView = this.props.page ? this.props.page : "home";

    const profile = db && db.profile;
    const userPlanType = profile && profile.plan && profile.plan.plan_type && profile.plan.plan_type;

    let menuItems = {
      home: {
        caption: "Översikt",
        tooltip: null,
        name: "home",
        aliases: ["home"],
        icon: "home",
        includedInPlans: ['all'],
        position: "left",
        disabled: false,
      },
      summary: {
        caption: "Växtodlingsplan",
        tooltip: null,
        name: "home/plan",
        aliases: ["plan"],
        icon: "columns",
        includedInPlans: ['developer'],
        position: "left",
        disabled: (!noFields || noFields === 0),
      },           
      reminders: {
        caption: "Saker att göra",
        tooltip: null,
        name: "home/reminders",
        aliases: ["reminders"],
        icon: "tasks",
        includedInPlans: ['all'],
        position: "left",
        disabled: (!noFields || noFields === 0),
      },              
      timeline: {
        caption: "Tidslinje",
        tooltip: null,
        name: "home/timeline",
        aliases: ["timeline"],
        icon: "chart bar outline",
        includedInPlans: ['all'],
        position: "left",
        disabled: (!noFields || noFields === 0),
      },
      weather: {
        caption: "Väder",
        tooltip: null,
        name: "home/weather",
        aliases: ["weather"],
        icon: "cloud",
        includedInPlans: ['all'],
        position: "left",
        disabled: false,
      },         
      // summary: {
      //   caption: "Insikter",
      //   tooltip: null,
      //   name: "home/summary",
      //   aliases: ["summary"],
      //   icon: "book",
      //   includedInPlans: ['all'],
      //   position: "left",
      //   disabled: true, // (!noFields || noFields === 0),
      // },         
    };

    return (
      <React.Fragment>
        <MenuSecondary
          userId={userId}
          isAdminUser={this.props.isAdminUser}
          trueUserId={this.props.trueUserId}
          params={this.props.match.params}
          tutorialName="DesktopHomeFirstLaunchV1"
        >  
          {menuItems && Object.keys(menuItems).map((x, idx) => {
            let menuItem = menuItems[x];

            if (!menuItems[x].includedInPlans.includes("all") && userPlanType && !menuItems[x].includedInPlans.includes(userPlanType)) {
              return <div key={'MenuHomeItem_' + menuItem.name} />
            }

            if (menuItem.position && menuItem.position === "left") {
              return (
                <Popup
                  key={'MenuHomeItem_' + menuItem.name}
                  trigger={
                    <Menu.Item
                      className="menuSecondaryItem"
                      style={{
                        marginTop: 0,
                        height: "55px",
                        marginRight: 0,
                        width: "16em",
                        background: menuItem.aliases.includes(activeView) ? "rgba(234, 204, 116, 0.25)" : "transparent",
                        borderBottom: menuItem.aliases.includes(activeView) ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                        fontWeight: menuItem.aliases.includes(activeView) ? 600 : null,
                        justifyContent: "center",
                      }}
                      disabled={menuItem.disabled && menuItem.disabled}
                      name={menuItem.name}
                      active={menuItem.aliases.includes(activeView)}
                      onClick={() => this.props.history.push("/" + menuItem.name)}
                    >
                      <Icon.Group style={{ marginRight: 5 }}>
                        <Icon size='large' name={menuItem.icon} />
                      </Icon.Group>
                      {menuItem.caption}
                    </Menu.Item>
                  }
                  position="bottom center"
                  disabled={!menuItem.tooltip}
                  content={menuItem.tooltip}
                />
              )
            } else {
              return <div key={'MenuHomeItem_' + menuItem.name} />
            }
          })}
        </MenuSecondary>
      </React.Fragment>
    );
  }
}

export default withRouter(MenuHome);

