import React, { Component } from "react";

import { 
  Icon,
  Segment,
  Header,
  Button,
} from "semantic-ui-react";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuidesCollectDataDropzone from "@/components/guideData/GuidesCollectDataDropzone";
import TopBarAnnouncement from "@/components/bars/TopBarAnnouncement";

class UploadSoilMapGuideUploadFiles extends Component {
  
  render () {

  let menuItems = {
    previous: [
      {
      caption: "Tillbaka",
      onClick: this.props.gotoGettingStarted,
      }
    ]
  };

  let mainColumn = 
    <Segment 
      style={{
        padding: "1em",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",    
        height: "calc(100vh - 170px)"           
      }}
    >             
      <GuidesCollectDataDropzone 
        inSegment={false}
        activeView="uploadSoilMaps"
        onDroppedFiles={this.props.onUploadFiles}
        collectDataError={this.props.collectDataError}
      />
    </Segment>

  let helpColumn = 
    <React.Fragment>
      <Header
        as="h2"
        style={{
          fontWeight: "normal",
          whiteSpace: "pre-line",
        }}
      >
        Samla rätt markkarteringsfiler
      </Header>
      <p
          style={{
          fontWeight: "normal",
          fontSize: "110%",
          whiteSpace: "pre-line",
        }}
      >
        Börja med att se till att du har rätt filer. Vi stödjer just nu markkarteringar från Hushållningssällskapen, DataVäxt och andra leverantörer som erbjuder markkarteringar i text-filformat. Du kan även ladda upp filer från Excel, LibreOffice och OpenOffice. Se till att kalkylbladet i så fall endast har ett blad och att första raden är kolumnnamnen.
      </p>          

      <Header
        as="h2"
        style={{
          marginTop: "1em",
          fontWeight: "normal",
          whiteSpace: "pre-line",
        }}
      >
        Ladda upp markkarteringar
      </Header>
      <p
        style={{
          fontWeight: "normal",
          fontSize: "110%",
          whiteSpace: "pre-line",
        }}
      >
        Välj sedan markkarteringar och ladda upp. Därefter
        kommer du direkt till "Kontrollera filer" Du kan alltid
        gå tillbaka hit och ladda upp nya markkarteringar.
      </p>
      {this.props.onClickNextStep &&
        <Button
          fluid
          icon
          labelPosition="right"
          size="large"
          style={{
            backgroundColor: "#6B9D9F",
            color: "white",
          }}
            onClick={() => this.props.onClickNextStep()}
          >
            <Icon name="chevron right" />
            Gå vidare till steg 2
        </Button>
      }                
    </React.Fragment>

  return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        // mainColumnHeader="Välj skiften att skapa översikter för"
        helpColumn={helpColumn}
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        menuItems={menuItems}
      >
        {this.props.showSkipGettingStarted &&
          <TopBarAnnouncement 
            text='Vi hoppade över att visa hur du kommer igång eftersom du nyligen har sett den informationen. Om du ändå vill se den kan du klicka på knappen "Tillbaka" längst ned till vänster på skärmen.'
            onHideBar={this.props.onHideSkipGettingStarted}
            skipGettingStarted={this.props.skipGettingStarted}
          />
        }
      </GuidesBasicGrid>
    )
   }
}

export default UploadSoilMapGuideUploadFiles;