// The second line in this file should always be the build date. For the scripts to work.
export const BUILD_DATE = "20220301";
export const MAIN_APP_VERSION = "v4.1";

export const HELP_PAGE_URL = "https://help.freja.agriopt.se/";

export const FIRST_GREENMASS_YEAR = 2013;

export const WEATHER_FIRST_YEAR = "2010";
export const SEASON_FIRST_DATE = "-03-01";
export const SEASON_LAST_DATE = "-06-30";

export const MISSING_DATA_LIMIT = 0.15;
export const LOW_QUALITY_LIMIT = 0.6;

export const APP_BETA_OPEN = false;