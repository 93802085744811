import React from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";

import { cloudFunctions } from "@";

import GuidesTemplate from "@/containers/Guides/GuidesTemplate";
import MenuGuide from "@/components/guide/GuideMenu";

import GettingStarted from "@/components/guide/GuideGettingStarted";
import SelectFields from "@/components/guide/GuideSelectFields";
import GreenmassYield from "@/components/guides/artificalYieldMapsV1/greenmassYield";
import Summary from "@/components/guides/artificalYieldMapsV1/summary";

import { INITIAL_STATE_TEMPLATE } from "@/containers/Guides/GuidesTemplate";

const GUIDE_NAME = 'artificialYieldMapsV1'
const GUIDE_URL = 'guidesArtificialYieldMapsV1';

const GettingStartedHeader = 'Skapa skördekartor från grönmassa';

const GettingStartedDescription = 'Denna guide hjälper dig att skapa skördekartor genom att använda grönmassamätningar för att hitta variationen i grödan på dina skiften.';

const GettingStartedBeforeYouStart = 'Egna skördekartor är alltid att föredra men denna guide hjälper dig att skapa ungefärliga skördekartor som du kan använda för att skapa styrfiler.';

const INITIAL_STATE = {
  guideSettings: {},
  datesToUse: {},
  layerIds: {},
  selectedYears: {},
  yearsProcessed: {},
  guideCrop: {},
  guideYield: {},
  guideYieldUnit: {},
  guideIndex: {},
};
class ArtificialYieldMapsV1 extends GuidesTemplate {

  state = {
    ...this.state,
    ...INITIAL_STATE,
  };

  onClickStartOver = () => {
    this.props.setGuidesWrapperState({ selectedFieldIds: [] });
    this.setState({...INITIAL_STATE_TEMPLATE, ...INITIAL_STATE});
    this.onSaveGuide(GUIDE_NAME, GUIDE_URL);
    this.onGotoPreviousStep(GUIDE_URL, "selectFields", false, {...INITIAL_STATE_TEMPLATE, ...INITIAL_STATE});
  };

  saveFieldInfoToDatabase = (fieldId) => {
    const {
      userId,
    } = this.props;

    const {
      guideCrop,
      guideIndex,
      guideYield,
      guideYieldUnit,
      datesToUse,
    } = this.state;

    if (!datesToUse || !datesToUse[fieldId]) {
      return null
    };

    let db = this.getDb();

    let fieldsDoc = fieldId && db && db.fields && db.fields[fieldId];
    let fieldsInfoDoc = fieldId && db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let cropCodes = fieldsInfoDoc && fieldsInfoDoc.crop_code ? fieldsInfoDoc.crop_code : {};
    cropCodes = {...cropCodes, ...guideCrop[fieldId]};

    // Adjust yield to get correct unit
    let fieldSize = fieldsDoc && fieldsDoc.field_size;

    let harvestYields = fieldsInfoDoc && fieldsInfoDoc.harvest_yields ? fieldsInfoDoc.harvest_yields : {};

    let adjustedHarvestYields = {};

    guideYieldUnit && guideYieldUnit[fieldId] && Object.keys(guideYieldUnit[fieldId]).forEach((year) => {
     if (guideYieldUnit[fieldId][year] === "perha") {

       if (guideYield && guideYield[fieldId] && guideYield[fieldId][year]) {
          adjustedHarvestYields = {...adjustedHarvestYields,
            [year]: (parseFloat(guideYield[fieldId][year]) * parseFloat(fieldSize)).toFixed(2),
          };
        };

        if (harvestYields && harvestYields[year]) {
          adjustedHarvestYields = {...adjustedHarvestYields,
            [year]: (parseFloat(harvestYields && harvestYields[year]) * parseFloat(fieldSize)).toFixed(2),
          };
        };        
      };
    });

    harvestYields = {...harvestYields, ...guideYield[fieldId], ...adjustedHarvestYields};

    let yieldMapDoc = fieldId && db && db["fieldsYieldMapsArtificial_"  + fieldId];

    // Update yields and crops in database
    let dbPayload1 = {};

    cropCodes && Object.keys(cropCodes).forEach((x) => {
      dbPayload1 = {...dbPayload1, ['crop_code.' + x]: cropCodes[x]}
    });

    harvestYields && Object.keys(harvestYields).forEach((x) => {
      dbPayload1 = {...dbPayload1, ['harvest_yields.' + x]: harvestYields[x]}
    });

    if (!fieldsInfoDoc) {
      this.getUser().collection("fieldsInfo").doc(fieldId).set({
        crop_code: cropCodes,
        harvest_yields: harvestYields,
      });
    } else {
      dbPayload1 && this.getUser().collection("fieldsInfo").doc(fieldId).update(dbPayload1);
    };

    // Save info for backend to create artificial yield map
    let dbPayload2 = {
      ...dbPayload1
    };

    datesToUse && datesToUse[fieldId] && Object.keys(datesToUse[fieldId]).forEach((year) => {
      dbPayload2 = {...dbPayload2, ['dates_to_use.' + year]: datesToUse[fieldId][year]}
    });

    guideIndex && guideIndex[fieldId] && Object.keys(guideIndex[fieldId]).forEach((year) => {
      dbPayload2 = {...dbPayload2, ['image_types.' + year]: guideIndex[fieldId][year]}
    });

    if (!yieldMapDoc) {
      this.getUser().collection("fieldsYieldMapsArtificial").doc(fieldId).set({
        crop_code: cropCodes,
        harvest_yields: harvestYields,
        dates_to_use: datesToUse[fieldId],
        image_types: guideIndex[fieldId],
      });
    } else {
      dbPayload2 && this.getUser().collection("fieldsYieldMapsArtificial").doc(fieldId).update(dbPayload2);
    };
    
    cloudFunctions.httpsCallable('callDatahubHarvest')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/artificialYieldMaps/field",
      }
    );
  };

  render() {
    const { 
      match: {
        params: {
          layer,
          guideId,
        }
      },
      userId,
      userPlanType,
      cacheFieldData,
      updateCacheFieldData,
      isAdminUser,
    } = this.props;

    const {
      selectedFieldIds,
      skipGettingStarted,
    } = this.state;

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    let currentSettings = db && db.settings && db.settings.guides && db.settings.guides;
    let currentSettingsValue = currentSettings && currentSettings[GUIDE_NAME];
    
    let showSkipGettingStarted = currentSettingsValue && 
      typeof(currentSettingsValue['skip_get_started_announce']) === "boolean" ? 
      !currentSettingsValue['skip_get_started_announce'] : true;
    
    let showFullGrid = currentSettingsValue && 
      typeof(currentSettingsValue['hide_help_text_col']) === "boolean" ? 
      !currentSettingsValue['hide_help_text_col'] : true;

    let menuItems = [
      {
        name: "gettingStarted",
        href: "",
        onClick : () => {},
        disabled: true,
        caption: "Kom igång",
        active: !layer || layer === "gettingStarted",
      },
      {
        name: "selectFields",
        href: "",
        onClick : () => {},
        disabled: true,
        caption: "Välj skiften",
        description: "...",
        active: layer === "selectFields",
      },
      {
        name: "greenmassYield",
        href: "",
        onClick : () => {},
        disabled: true,
        caption: "Grönmassa och skörd",
        description: "...",
        active: layer === "greenmassYield",
      },
      {
        name: "summary",
        href: "",
        onClick : () => {},
        disabled: true,
        caption: "Sammanfattning",
        description: "...",
        active: layer === "summary",
      },      
    ];

    let commonProps = {
      slideDirection: this.state.slideDirection,
      params: this.props.match.params,
      db,
      getUser: this.getUser,
      userId,
      guideId,
      guideUrl: GUIDE_URL,
      guideName: GUIDE_NAME,
      isAdminUser,
      fileBucketRef,
      showFullGrid,
      userPlanType,
      selectedFieldIds,
      toggleHelpTexts: () => this.toggleHelpTexts(GUIDE_NAME),
      cacheFieldData,
      updateCacheFieldData,
      setParentState: this.setParentState,
    };

    return (
      <React.Fragment>
        <MenuGuide 
          db={db}
          fieldId={null}
          menuItems={menuItems} 
        />    

        {(!layer || layer === "gettingStarted") &&
          <GettingStarted 
            menuItems={menuItems}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "selectFields", false)}
            header={GettingStartedHeader}
            description={GettingStartedDescription}
            beforeYouStart={GettingStartedBeforeYouStart}
          />   
        }

        {layer === "selectFields" &&
          <SelectFields 
            {...commonProps}
            enforceSameCrop={false}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "gettingStarted", false)}
            onClickNextStep={() => this.onSaveGuide(GUIDE_NAME, GUIDE_URL, "greenmassYield")}
            skipGettingStarted={skipGettingStarted}
            showSkipGettingStarted={showSkipGettingStarted}
            onHideSkipGettingStarted={this.onHideSkipGettingStarted}
            onSelectField={this.onSelectField}
            mainColumnHeader="Välj de skiften som du vill skapa skördekartor för"
          />
        }

        {layer === "greenmassYield" &&
          <GreenmassYield
            {...this.state}
            {...commonProps}
            saveFieldInfoToDatabase={this.saveFieldInfoToDatabase}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "selectFields", true)}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "summary", true)}
          />
        }         

        {layer === "summary" &&
          <Summary
            {...this.state}
            {...commonProps}
            onGuideDone={() => this.onGuideDone()}
            onCloseGuide={() => this.onCloseGuide()}
          />
        }                   
      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(props => {
    const { 
      selectedFieldIds,
    } = props;

    let results = [
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "fields" },
        ],
        storeAs: 'fields'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "fieldsInfo" },
        ],
        storeAs: 'fieldsInfo'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "fieldsWeather", doc: "farm"  },
        ],
        storeAs: 'fieldsWeather'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "guides" },
        ],
        storeAs: 'guides'
      },      
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "profile" },
        ],
        storeAs: 'profile'
      },           
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'settings'
      },        
    ];
    
    selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
      results = [...results,
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsYieldMapsProcessed", doc: `${fieldId}` },
          ],
          storeAs: 'fieldsYieldMapsProcessed_' + fieldId
        },  
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsSentinelHubRaw", doc: `${fieldId}` },
          ],
          storeAs: 'fieldsSentinelHubRaw_' + fieldId
        },  
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsSentinelHubProcessed", doc: `${fieldId}` },
          ],
          storeAs: 'fieldsSentinelHubProcessed_' + fieldId
        },
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsYieldMapsArtificial", doc: `${fieldId}` },
          ],
          storeAs: 'fieldsYieldMapsArtificial_' + fieldId
        },         
      ];
    });

    return results;
  }),
  connect(store => {
    return {
      firestoreData: store.firestore.data
    };
  })
)(ArtificialYieldMapsV1);