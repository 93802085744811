import React, { Component } from "react";

import {
  Header,
  Segment,
} from "semantic-ui-react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import illustrationGlobe from "@/assets/icons/home/globe.svg";
import illustrationMoney from "@/assets/icons/home/money.svg";
import illustrationYield from "@/assets/icons/home/yield.svg";

const INITIAL_STATE = {
  activeSlide: 0,
};

const MONEY_PER_HECTAR = 650;
const INCREASED_YIELD = 0.03 * 6.3; // * 1.42; // Increase percent, average yield, price per kg

class PaBenefitsWidget extends Component {

  state = { ...INITIAL_STATE };

  getYieldPage = (totalHectar) => (
    <div>
      <div style={{ display: "flex" }}>
        <div 
          style={{
            whiteSpace: "pre-line",
            fontFamily: "Istok Web",
            width: "95%",
            zIndex: 9,
          }}
        >
          <Header
            as="h1"
            style={{
              marginBottom: "1em",
              color: "rgb(240, 189, 56)",
              whiteSpace: "pre-line",
              fontWeight: 500,
            }}
          >
            Öka din skörd med
          </Header>   

          <Header
            as="h1"
            style={{
              marginTop: "1.5em",
              marginBottom: "1em",
              color: "rgb(240, 189, 56)",
            }}
          > 
            <span style={{ fontSize: "300%", marginRight: 5 }}>
              {totalHectar && (INCREASED_YIELD * totalHectar).toFixed(0)}
            </span>
            ton
          </Header>

          Precisionsodling ökar skördarna i snitt med 3%. Siffran gäller om du skulle odla höstvete med snittskörden 6.3 ton/ha på hela din gård.
        </div>
          <img 
            style={{
              marginTop: "-1.5em",
              marginLeft: "-6em",
              width: "6em",
            }}
            alt=''
            src={illustrationYield} 
          />
      </div>            
    </div>        
  );

  getClimatePage = (totalHectar) => (
    <div>
      <div style={{ display: "flex" }}>
        <div 
          style={{
            whiteSpace: "pre-line",
            fontFamily: "Istok Web",
            width: "95%"
          }}
        >
          <Header
            as="h1"
            style={{
              marginBottom: "1em",
              color: "rgb(76, 132, 64)",
              width: "67%",
              whiteSpace: "pre-line",
              fontWeight: 500,
            }}
          >
            Minska din klimatpåverkan med
          </Header>   

          <Header
            as="h1"
            style={{
              marginTop: "1.5em",
              marginBottom: "1em",
              color: "rgb(76, 132, 64)",
            }}
          >
            <span style={{ fontSize: "300%", marginRight: 5 }}>
              21
            </span>
            ton CO2e
          </Header>          
          
          genom att använda variabel kvävegödslingsgiva
          för stråsäd. Denna minskning motsvarar utsläppet
          från bilresor på upp till 10 289 mil.  
        </div>
        
        <img 
          style={{
            marginTop: "-4em",
            marginLeft: "-14em",
            width: "10em",
          }}
          alt=''
          src={illustrationGlobe} 
        />        
      </div>
    </div>        
  );

  getMoneyPage = (totalHectar) => (
    <div>
      <div style={{ display: "flex" }}>
        <div 
          style={{
            whiteSpace: "pre-line",
            fontFamily: "Istok Web",
            width: "95%"
          }}
        >
          <Header
            as="h1"
            style={{
              marginBottom: "1em",
              color: "rgb(107, 157, 159)",
              width: "67%",
              whiteSpace: "pre-line",
              fontWeight: 500,
            }}
          >
            Öka intäkerna med
          </Header>   

          <Header
            as="h1"
            style={{
              marginTop: "1.5em",
              marginBottom: "1em",
              color: "rgb(107, 157, 159)",
            }}
          >
            <span style={{ fontSize: "300%", marginRight: 5 }}>
              {totalHectar && (Math.floor(MONEY_PER_HECTAR * totalHectar / 1000) * 1000).toLocaleString('sv')}
            </span>
            kr
          </Header>          
          
          med precisionsodling på din gård. Genom att insatsmedel genom optimering av kvävegödsling och ökad skörd. Detta motsvarar ungefär 650 kr/ha och år.
          </div>

          <img 
            style={{
              marginTop: "-4em",
              marginLeft: "-14em",
              width: "10em",
            }}
            alt=''
            src={illustrationMoney} 
          />              
      </div>
    </div>        
  );  

  render() {

    const {
      db,
    } = this.props;

    const settings = {
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };

    let fields = db && db.fields;
    let totalHectar = fields && Object.keys(fields).map((x) => fields[x].field_size);
    totalHectar = totalHectar && totalHectar.reduce((a, b) => a + b, 0.0);

    return (
      <div style={{ height: "100%" }}>
        <Segment
          basic
          style={{
            padding: "1.5em",
            paddingBottom: "3em",
            fontWeight: "normal",
            height: "100%",
            backgroundColor: "#f7f4f0",
          }}
        >
          <div 
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5em",
              }}
            >
              <Header
                as="h3"
                style={{ 
                  paddingTop: "0",
                  fontWeight: 500,
                  color: "grey",
                }}
              >
                Nyttan med precisionsodling
              </Header>
                            
            {/* <p
              style={{
                color: "#6B9D9F",
                fontSize: "95%",
                cursor: "pointer",
                fontWeight: 600,
              }}
              onClick={() => this.markNotificationsRead()}
            >
              Läs mer
            </p> */}
          </div>

          <Slider 
            ref={c => (this.slider = c)} 
            className="home-page-slider" 
            {...settings}
          >
            {this.getYieldPage(totalHectar)}
            {/* {this.getClimatePage()} */}
            {this.getMoneyPage(totalHectar)}
          </Slider>
            
        </Segment> 
      </div>        
    )
  }
}

export default PaBenefitsWidget;
