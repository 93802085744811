import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";

import {
  withFirebase,
  withFirestore as withFirestoreActions,
  firestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";

import moment from 'moment';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FREJA_MOBILE_URL, APP_VERSION } from "@/constants/firebase";

import { APP_BETA_OPEN } from "@/constants/settings";
import { MAIN_VIEWS } from "@/constants/views";
import { COLLECT_GUIDES } from "@/constants/viewCollect";
import { CONTROL_GUIDES } from "@/constants/viewGuides";
import { UserContext } from "@/helpers/context";

import MenuPrimary from "@/components/menus/MenuPrimary";

import LoadingScreen from "@/components/modals/LoadingScreen";
import LoginPage from "@/containers/LoginPage";

import AdminPage from "@/containers/AdminPage";
import AdvisorPage from "@/containers/AdvisorPage";

import FieldDataPage from "@/containers/FieldDataPage";
import HelpPage from "@/containers/HelpPage";
import HomePage from "@/containers/HomePage";

import GuidesPage from "@/containers/GuidesPage";
import GuidesWrapperPage from "@/containers/GuidesWrapperPage";

import NoAccessModal from "@/components/modals/NoAccessModal";
import NoAccessToBetaModal from "@/components/modals/NoAccessToBetaModal"
import NoAccessOnPlanModal from "@/components/modals/NoAccessOnPlanModal";
import PageNotFoundPage from "@/components/modals/PageNotFoundModal";

import PasswordPage from "@/containers/PasswordPage";

import "./App.css";

const GUIDES = [...COLLECT_GUIDES, ...CONTROL_GUIDES];

const INITIAL_STATE = {
  overrideUserId: null,
  cacheFieldData: {
    geoJsonData: {},
    statJson: {},
  },
  isAdminUser: null,
  advisorClientIds: [],
};

Math.median = (arr, noDecimals = 2) => {
  const sortedArray = arr && arr.sort();
  const medianElement = sortedArray && sortedArray[Math.floor(arr.length / 2)];
  return medianElement && +medianElement.toFixed(noDecimals);
};

Math.mean = (arr, noDecimals = 2) => {
  return arr && +arr.reduce((curr, acc) => (typeof (curr) === "number" ? curr : 0.0) + acc / arr.length, 0).toFixed(noDecimals);
};

Math.sum = (arr) => {
  return arr && +arr.reduce((curr, acc) => curr + acc, 0);
};

Math.var = (arr) => {
  let mean = arr && +arr.reduce((curr, acc) => curr + acc / arr.length, 0.0);
  return arr && +arr.reduce((curr, acc) => curr + (acc - mean) * (acc - mean) / (arr.length - 1), 0.0);
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = () => {
    if (this.isMobileDevice()) {
      window.location.href = FREJA_MOBILE_URL;
    };
  };

  componentDidUpdate = (prevProp) => {
    if (this.props.firebaseData && prevProp.firebaseData) {
      if (this.props.firebaseData.auth !== prevProp.firebaseData.auth) {
        this.updateLatestLogin();
      };
    };

    if (typeof (this.state.isAdminUser) !== "boolean") {
      this.props.firebase.auth() &&
        this.props.firebase.auth().currentUser &&
        this.props.firebase.auth().currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.adminUser) {
              this.setState({ isAdminUser: true });
            } else {
              this.setState({ isAdminUser: false });
            };

            if (idTokenResult.claims.clientIds) {
              this.setState({ advisorClientIds: idTokenResult.claims.clientIds });
            };
          })
          .catch((error) => {
            console.log(error);
          });
    };
  };

  updateLatestLogin = () => {
    let userId = this.props.firebaseData.auth.uid;

    this.props.firestore
      .collection("users")
      .doc(userId)
      .collection("profile")
      .doc("log")
      .update({ 
        date_latest_login_desktop: new Date() 
      });
  }

  updateCacheFieldData = (geoJsonData, statJson, identifier) => {
    let currentValue = this.state.cacheFieldData;

    geoJsonData && this.setState({
      cacheFieldData: {
        ...currentValue,
        geoJsonData: {
          ...currentValue.geoJsonData,
          [identifier]: geoJsonData,
        }
      },
    });

    statJson && this.setState({
      cacheFieldData: {
        ...currentValue,
        statJson: {
          ...currentValue.statJson,
          [identifier]: statJson,
        }
      },
    });
  };

  isMobileDevice = () => {
    // https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  switchUser = (userId) => {
    this.setState({ overrideUserId: userId });
  };

  userHasViewAccess = (viewName, userPlanType, isAdvisorUser) => {
    if (isAdvisorUser) {
      return true
    };

    return userPlanType && (MAIN_VIEWS[viewName].includedInPlans.includes(userPlanType) || MAIN_VIEWS[viewName].includedInPlans.includes("all"))
  };

  userHasGuideAccess = (guideName, userPlanType) => {
    let fieldGuide = GUIDES && GUIDES.find((x) => x.name === guideName);
    return userPlanType && fieldGuide && (fieldGuide.includedInPlans.includes(userPlanType) || fieldGuide.includedInPlans.includes("all"))
  };

  render() {
    const {
      firebaseData,
      firestoreData,
    } = this.props;

    const {
      overrideUserId,
      cacheFieldData,
      isAdminUser,
      advisorClientIds,
    } = this.state;

    if (APP_VERSION === "beta" && !APP_BETA_OPEN) {
      return <NoAccessToBetaModal />
    }


    if (!isLoaded(firebaseData.auth) || !isLoaded(firestoreData.data)) {
      return <LoadingScreen />
    };


    if (isLoaded(firebaseData.auth) && isLoaded(firestoreData.data) && isEmpty(firebaseData.auth)) {
      return (
        <Router>
          <Switch>
            <Route
              path="/resetPassword"
              exact
              render={props => (<PasswordPage {...props} />)}
            />

            <Route
              path={"/register/:key?/:view?"}
              render={props =>
                <GuidesWrapperPage
                  {...props}
                  guideName="registrationV1Part1"
                  guideUrl="register"
                  firebase={this.props.firebase}
                />
              }
            />

            <Route render={() => <LoginPage />} />

            <ToastContainer
              hideProgressBar={true}
              autoClose={2000}
              position="bottom-center"
              pauseOnHover={false}
            />
          </Switch>
        </Router>
      )
    };

    const db = this.props.firestoreData && this.props.firestoreData.data;
    const userId = overrideUserId ? overrideUserId : firebaseData.auth.uid;

    const trueUserId = firebaseData.auth.uid;
    const userList = isAdminUser && db.allUsersApp && Object.keys(db.allUsersApp).map((x) => x);
    const isInAdminMode = isAdminUser && typeof (overrideUserId) === "string";

    let isAdvisorUser = advisorClientIds && advisorClientIds.length > 0;

    const userProfileApp =
      firestoreData &&
      firestoreData.data &&
      firestoreData.data.userProfileApp;

    const userPlanType = userId &&
      userProfileApp &&
      userProfileApp.plan &&
      userProfileApp.plan.plan_type;

    const validToDate = userId &&
      userProfileApp &&
      userProfileApp.plan &&
      userProfileApp.plan.plan_valid_to &&
      userProfileApp.plan.plan_valid_to.toDate();

    const validTo = validToDate && moment(validToDate);

    const now = moment();

    if (validTo && validTo.isValid() && validTo.isBefore(now)) {
      return <NoAccessModal userPlanType={userPlanType} />;
    };

    return (
      <UserContext.Provider
        value={{
          id: userId
        }}
      >
        <Router>
          <Route
            path="/:view?/:fieldId?/:layer?/:layerId?"
            render={props => (
              <MenuPrimary
                {...props}
                db={db}
                userId={userId}
                changeView={this.changeView}
                userPlanType={userPlanType}
                isAdminUser={isAdminUser}
                isAdvisorUser={isAdvisorUser}
              />
            )}
          />

          <div
            style={{
              height: "100vh",
              marginLeft: "85px",
              marginBottom: "-30px",
              paddingBottom: "-30px",
              overflowY: "auto",
              whiteSpace: "nowrap",
              marginTop: 0,
            }}
          >
            <Switch>
              <Route
                path={"/register2/:layer?"}
                render={props =>
                  <GuidesWrapperPage
                    {...props}
                    guideName="registrationV1Part2"
                    guideUrl="register2"
                    userId={userId}
                  />
                }
              />

              <Route
                path="/help/:view?/:subView?"
                render={props => (
                  <HelpPage
                    {...props}
                  />
                )}
              />

              {this.userHasViewAccess("layers", userPlanType, isAdvisorUser) &&
                <Route
                  path="/layers/:fieldId?/:layer?/:layerId?"
                  render={props => (
                    <FieldDataPage
                      {...props}
                      trueUserId={trueUserId}
                      userId={userId}
                      userPlanType={userPlanType}
                      changeView={this.changeView}
                      dataType="layers"
                      isAdminUser={isInAdminMode}
                      cacheFieldData={cacheFieldData}
                      updateCacheFieldData={this.updateCacheFieldData}
                    />
                  )}
                />
              }

              {this.userHasViewAccess("analysis", userPlanType, isAdvisorUser) &&
                <Route
                  path={"/analysis/:fieldId?/:layer?/:layerId?"}
                  render={props => (
                    <FieldDataPage
                      {...props}
                      trueUserId={trueUserId}
                      userId={userId}
                      userPlanType={userPlanType}
                      dataType="analysis"
                      isAdminUser={isInAdminMode}
                      cacheFieldData={cacheFieldData}
                      updateCacheFieldData={this.updateCacheFieldData}
                    />
                  )}
                />
              }

              {!this.userHasViewAccess("analysis", userPlanType, isAdvisorUser) &&
                <Route
                  path={"/analysis/:fieldId?/:layer?/:layerId?"}
                  render={props => (<NoAccessOnPlanModal />)}
                />
              }

              {this.userHasViewAccess("guides", userPlanType, isAdvisorUser) &&
                <Route
                  path="/guides/:fieldId?/:layer?/:layerId?"
                  render={props =>
                    <GuidesPage
                      {...props}
                      isAdminUser={isInAdminMode}
                      trueUserId={trueUserId}
                      userId={userId}
                      dataType="guides"
                    />
                  }
                />
              }

              {!this.userHasViewAccess("guides", userPlanType, isAdvisorUser) &&
                <Route
                  path="/guides/:fieldId?/:layer?/:layerId?"
                  render={props => (<NoAccessOnPlanModal />)}
                />
              }

              <Route
                path="/"
                exact
                render={props => (
                  <HomePage
                    {...props}
                    userId={userId}
                    changeView={this.changeView}
                    isAdminUser={isInAdminMode}
                    trueUserId={trueUserId}
                    isAdvisorUser={isAdvisorUser}
                  />
                )}
              />

              <Route
                path="/home/:page?/:subPage?"
                exact
                render={props => (
                  <HomePage
                    {...props}
                    userId={userId}
                    changeView={this.changeView}
                    isAdminUser={isInAdminMode}
                    trueUserId={trueUserId}
                    isAdvisorUser={isAdvisorUser}
                  />
                )}
              />

              {this.userHasViewAccess("collect", userPlanType, isAdvisorUser) &&
                <Route
                  path="/collect/:fieldId?/:layer?/:layerId?"
                  render={props =>
                    <GuidesPage
                      {...props}
                      isAdminUser={isInAdminMode}
                      trueUserId={trueUserId}
                      userId={userId}
                      dataType="collect"
                    />
                  }
                />
              }

              {isAdvisorUser &&
                <Route
                  path="/advisor"
                  render={props =>
                    <AdvisorPage
                      {...props}
                      userId={userId}
                      trueUserId={trueUserId}
                      userList={advisorClientIds}
                      changeView={this.changeView}
                      switchUser={this.switchUser}
                    />
                  }
                />
              }

              {isAdminUser &&
                <Route
                  path="/admin"
                  render={props =>
                    <AdminPage
                      {...props}
                      userId={userId}
                      trueUserId={trueUserId}
                      isAdminUser={isAdminUser}
                      userList={userList}
                      changeView={this.changeView}
                      switchUser={this.switchUser}
                    />
                  }
                />
              }

              {GUIDES
                .filter((x) => this.userHasGuideAccess(x.name, userPlanType))
                .filter((x) => x.url)
                .map((x) => (
                  <Route
                    key={'Route_' + x.url}
                    path={"/" + x.url + "/:layer?/:guideId?/:layerId?"}
                    render={props =>
                      <GuidesWrapperPage
                        {...props}
                        guideName={x.name}
                        guideUrl={x.url}
                        userId={userId}
                        isAdminUser={isInAdminMode}
                        userPlanType={userPlanType}
                        cacheFieldData={cacheFieldData}
                        updateCacheFieldData={this.updateCacheFieldData}
                      />
                    }
                  />
                ))}

              <Route render={() => <PageNotFoundPage />} />

            </Switch>
          </div>

          {APP_VERSION === 'staging' &&
            <div
              style={{
                backgroundColor: 'transparent',
                padding: 1,
                position: 'absolute',
                top: 63,
                left: 0,
                width: "85px",
                zIndex: 11000,
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  color: "white",
                  margin: 0,
                  padding: 0,
                  fontSize: "75%",
                }}
              >
                Devel
              </div>
            </div>
          }

          <ToastContainer
            hideProgressBar={true}
            autoClose={2000}
            position="bottom-center"
            pauseOnHover={false}
          />
        </Router>
      </UserContext.Provider>
    );
  };
}

const withFirebaseData = connect(store => {
  return {
    firebaseData: store.firebase
  };
});

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore
  };
});

export default compose(
  withFirebaseData,
  withFirestoreData,
  firestoreConnect(props => [
    {
      collection: 'admins',
      storeAs: 'adminsApp'
    },
    {
      collection: 'users',
      storeAs: 'allUsersApp'
    },
    {
      collection: 'users',
      doc: `${props.firebaseData.auth.uid}`,
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fieldsApp'
    },
    {
      collection: 'users',
      doc: `${props.firebaseData.auth.uid}`,
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'userProfileApp'
    },
  ]),
  withFirebase,
  withFirestoreActions,
)(App);