import React, { Component } from "react";

import { 
  Icon,
  Segment,
  Header,
  Button,
} from "semantic-ui-react";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuidesCollectDataDropzone from "@/components/guideData/GuidesCollectDataDropzone";
import TopBarAnnouncement from "@/components/bars/TopBarAnnouncement";

class UploadYieldMapGuideUploadFiles extends Component {
  
  render () {

  let menuItems = {
    previous: [
      {
      caption: "Tillbaka",
      onClick: this.props.gotoGettingStarted,
      }
    ]
  };

  let mainColumn = 
    <Segment 
      style={{
        padding: "1em",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",    
        height: "calc(100vh - 170px)"           
      }}
    >             
      <GuidesCollectDataDropzone 
        inSegment={false}
        activeView="uploadYieldMaps"
        onDroppedFiles={this.props.onUploadFiles}
        collectDataError={this.props.collectDataError}
      />
    </Segment>

  let helpColumn = 
    <React.Fragment>
      <Header
        as="h2"
        style={{
          fontWeight: "normal",
          whiteSpace: "pre-line",
        }}
      >
        Samla rätt skördekartor
      </Header>
      <p
          style={{
          fontWeight: "normal",
          fontSize: "110%",
          whiteSpace: "pre-line",
        }}
      >
        Börja med att se till att du har rätt filer. Vi stödjer just nu skördekartor från några olika märken:
      </p>
      <p
        style={{
          fontWeight: "normal",
          fontSize: "110%",
          whiteSpace: "pre-line",
        }}
      >
        <ul>
          <li>Skördekartor exporterade från John Deeres portal (Shapefiler i zip-format).</li>
          <li>Skördekartor exporterade från DataVäxt (Shapefiler i zip-format).</li>
          <li>Skördekartor från Claas-terminaler i aft-format (antingen zippade eller en och en).</li>
        </ul>
      </p>          

      <Header
        as="h2"
        style={{
          marginTop: "1em",
          fontWeight: "normal",
          whiteSpace: "pre-line",
        }}
      >
        Ladda upp skördekartor
      </Header>
      <p
        style={{
          fontWeight: "normal",
          fontSize: "110%",
          whiteSpace: "pre-line",
        }}
      >
        Välj sedan skördekartor och ladda upp. Därefter
        kommer du direkt till "Kontrollera filer" Du kan alltid
        gå tillbaka hit och ladda upp nya skördekartor.
      </p>
      {this.props.onClickNextStep &&
        <Button
          fluid
          icon
          labelPosition="right"
          size="large"
          style={{
            backgroundColor: "#6B9D9F",
            color: "white",
          }}
            onClick={() => this.props.onClickNextStep()}
          >
            <Icon name="chevron right" />
            Gå vidare till steg 2
        </Button>
      }                
    </React.Fragment>

  return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        // mainColumnHeader="Välj skiften att skapa översikter för"
        helpColumn={helpColumn}
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        menuItems={menuItems}
      >
        {this.props.showSkipGettingStarted &&
          <TopBarAnnouncement 
            text='Vi hoppade över att visa hur du kommer igång eftersom du nyligen har sett den informationen. Om du ändå vill se den kan du klicka på knappen "Tillbaka" längst ned till vänster på skärmen.'
            onHideBar={this.props.onHideSkipGettingStarted}
            skipGettingStarted={this.props.skipGettingStarted}
          />
        }
      </GuidesBasicGrid>
    )
   }
}

export default UploadYieldMapGuideUploadFiles;