import React, { Component } from 'react';

import {
  Form,
  TextArea,
  Input,
  Icon,
  Header,
  Segment,
  Modal,
  Button,
} from 'semantic-ui-react';

import moment from 'moment';

import { DateInput } from 'semantic-ui-calendar-react';

import { toast } from "react-toastify";

const INITIAL_STATE = {
  header: '',
  note: '',
  isSaving: false,
};

export class AddReminderGuide extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidUpdate = (prevProps) => {
    const {
      db,
      reminderId,
      guideMode,
    } = this.props;

    if (guideMode === "editReminder" && prevProps.guideMode !== guideMode) {
      if (!this.state.header) {
        let currentValue = db && db.reminders && db.reminders[reminderId];
        currentValue && this.setState(currentValue);
      };
    };
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onSelectDate = (date) => {
    this.setState({
      condition: {
        type: 'date',
        first_date: date,
      },
    });
  };

  onSaveNewReminder = () => {
    const {
      reminderId,
      guideMode,
    } = this.props;

    if (guideMode === "editReminder") {
      this.updateReminder(reminderId);
    } else {
      this.saveNewReminder();
    }
  };

  saveNewReminder = () => {
    const {
      userId,
      getUser,
    } = this.props;

    this.setState({
      isSaving: true,
    });

    const payload = {
      user_id: userId,
      date_created: new Date(),
      date_latest_update: new Date(),
      complete: false,
      condition: this.state.condition,
      header: this.state.header,
      note: this.state.note,
      type: 'userReminder',
      reminder_type: 'userReminder',
      field_id: '',
      field_name: '',
      observation_type: '',
      observation_id: '',
      date: new Date(),
    };

    getUser().collection("reminders").add(payload)
      .then(() => {
        this.setState({ isSaving: false });

        toast.success("Sak att göra sparad!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
          hideProgressBar: false,
        });

        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ isSaving: false });

        toast.error("Något gick fel :( Felmeddelande: " + error.toString(), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
          hideProgressBar: false,
        });
      });
  };

  updateReminder = () => {
    const {
      userId,
      getUser,
      reminderId
    } = this.props;

    this.setState({
      isSaving: true,
    });

    const formattedNewReminder = {
      user_id: userId,
      date_created: new Date(),
      date_latest_update: new Date(),
      ...this.state,
    };

    getUser().collection("reminders").doc(reminderId).set(formattedNewReminder)
      .then(() => {

        this.setState({ isSaving: false });

        toast.success("Sak att göra uppdaterad!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
          hideProgressBar: false,
        });

        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ isSaving: false });

        toast.error("Något gick fel :( Felmeddelande: " + error.toString(), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
          hideProgressBar: false,
        });
      });
  };

  getNewReminderSegment = () => {
    const {
      condition,
    } = this.state;

    let reminderDate = condition && condition.first_date && moment(condition.first_date).format("YYYY-MM-DD");

    return (
      <Segment
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
      >
        Freja kommer att påminna dig den {reminderDate && reminderDate}.

        <div
          style={{
            width: "1em",
            fontSize: "80%"
          }}
        >
          <Icon
            className="iconButton"
            name="close"
            onClick={() => this.setState({ condition: null })}
          />
        </div>
      </Segment>
    );
  };

  render() {
    const {
      open,
      guideMode,
    } = this.props;

    const {
      header,
      note,
      condition,
      isSaving,
    } = this.state;

    let dateValue = this.state.condition && this.state.condition.first_date;
    dateValue = dateValue ? dateValue : moment().format("YYYY-MM-DD");

    return (
      <Modal
        open={open}
        size="tiny"
      >
        <Modal.Header
          style={{
            fontWeight: 500,
          }}
        >
          {guideMode === "editReminder" ? "Redigera en sak att göra" : "Lägg till sak att göra"}
        </Modal.Header>

        <Modal.Content>
          <Header
            as="h3"
            style={{
              fontWeight: 500,
            }}
          >
            Rubrik
          </Header>

          <Form
            style={{
              width: '100%',
            }}
          >
            <Input
              fluid
              name='header'
              placeholder='Ange en rubrik...'
              onChange={e => this.onChange(e)}
              value={header ? header : ''}
            />
          </Form>

          <Header
            as="h3"
            style={{
              fontWeight: 500,
            }}
          >
            Anteckning (frivilligt)
          </Header>

          <Form
            style={{
              width: '100%',
            }}
          >
            <TextArea
              style={{
                fontSize: '12pt',
              }}
              rows={4}
              name='note'
              placeholder='Skriv en valfri anteckning...'
              onChange={e => this.onChange(e)}
              value={note ? note : ''}
            />
          </Form>

          <Header
            as="h3"
            style={{
              fontWeight: 500,
            }}
          >
            Datum för påminnelse
          </Header>

          <DateInput
            pickerStyle={{
              marginLeft: "10%",
              minWidth: "80%",
              maxWidth: "80%",
              fontSize: "85%",
            }}
            inline
            name='date'
            dateFormat="YYYY-MM-DD"
            value={dateValue}
            markColor="yellow"
            onChange={(e, d) => this.onSelectDate(d.value)}
            localization='sv'
          />
        </Modal.Content>

        <Modal.Actions>
          <Button
            disabled={isSaving}
            onClick={() => this.props.closeModal()}
          >
            Avbryt
          </Button>

          <Button
            primary
            disabled={isSaving || !header || !condition || !condition.first_date}
            loading={isSaving}
            onClick={() => this.onSaveNewReminder()}
          >
            Spara
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
};

export default AddReminderGuide;


