import React, { Component } from "react";

import {
  isLoaded,
} from "react-redux-firebase";

import { 
  Segment,
  Image,
} from "semantic-ui-react";

import imgClock from "@/assets/icons/home/clock.svg";
import imgFields from "@/assets/icons/home/fields.svg";
import imgFrejaHappy from "@/assets/icons/home/freja-happy.svg";
import imgWeather from "@/assets/icons/home/weather.svg";

import moment from 'moment';



const INITIAL_STATE = {};

class HomeBarWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render () {
    const {
        db,
    } = this.props;

    let firstName = db && 
      db.profile && 
      db.profile.contact && 
      db.profile.contact.first_name && 
      db.profile.contact.first_name;

    let tempData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather &&
      db.fieldsWeather.temp_last24hrs;
    tempData = tempData && Object.keys(tempData).map(x => tempData[x].value);

    let precData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather &&
      db.fieldsWeather.prec_last24hrs;      
    precData = precData && Object.keys(precData).map(x => precData[x].value);
    precData = precData && precData.reduce((x, y) => x + y, 0.0);

    let fieldIds = db && isLoaded(db) && db.fields && 
      Object.keys(db.fields)
      .map((x) => x)
      .filter((x) => db.fields[x] && db.fields[x])
      .filter((x) => (typeof(db.fields[x].available_to_user) !== "boolean") || (typeof(db.fields[x].available_to_user) === "boolean" && db.fields[x].available_to_user === true))

    let totalArea = db && fieldIds && fieldIds
      .map(x => db.fields[x] && db.fields[x].field_size && db.fields[x].field_size)
      .reduce((partial_sum, a) => partial_sum + a, 0);
    totalArea = totalArea ? totalArea : null;

    let noFields = fieldIds && fieldIds.length;

    return (
      <Segment
        basic
        style={{
          padding: "2em",
          marginTop: "10px",
          marginLeft: "5px",
          marginRight: "20px",
          marginBottom: "0",
          backgroundColor: "transparent",
          border: "0px",
          boxShadow: "0px",
          width: "100%",
        }}              
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{ 
              display: "flex",
              width: "25%",
              paddingLeft: "2em",
            }}
          >
            <Image 
              style={{
                display: "inline-block",
                filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)"
              }}
              src={imgFrejaHappy} 
            />

            <div style={{ marginLeft: 20, marginTop: 5 }}>
              <p style={{ color: "grey", marginBottom: 0, }}>Välkommen</p>
              <p style={{ fontWeight: 400, fontSize: "130%", marginTop: 0 }}>{firstName}</p>
            </div>
          </div>

          <div
            style={{
              margin: 0,
              paddingLeft: "2em",
              borderLeft: "3px solid #f2e9df",
              display: "flex",
              width: "25%",
            }}
          >
            <Image 
              style={{
                display: "inline-block",
                filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)"
              }}
              src={imgClock} 
            />

            <div style={{ marginLeft: 20, marginTop: 5 }}>
              <p style={{ color: "grey", marginBottom: 0 }}>Dagens datum</p>
              <p style={{ fontWeight: 400, fontSize: "130%", marginTop: 0 }}>{moment().format('D MMMM YYYY')}</p>
            </div>

            {/* <div style={{ marginLeft: 20 }}>
              <p style={{ color: "grey", marginBottom: 0 }}>Aktuell tid</p>
              <p style={{ fontWeight: 400, fontSize: "130%" }}>{moment().format('HH:MM')}</p>
            </div>                     */}
          </div>

          <div
            style={{
              margin: 0,
              paddingLeft: "2em",
              borderLeft: "3px solid #f2e9df",
              display: "flex",
              width: "25%",
            }}                  
          >
            <Image 
              style={{
                display: "inline-block",
                filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)"
              }}
              src={imgWeather} 
            />
            <div style={{ marginLeft: 20, marginTop: 5 }}>
              <p style={{ color: "grey", marginBottom: 0 }}>Aktuell temperatur och nederbörd</p>
              <p style={{ fontWeight: 400, fontSize: "130%", marginTop: 0 }}>
                {tempData && tempData.slice(-1)[0].toFixed(1).replace(".", ",")} grader 
                <span style={{ color: "grey", marginLeft: 5, marginRight: 5 }}>&middot;</span>
                {precData && " " + precData.toFixed(1).replace(".", ",")} mm                      
              </p>
            </div>
          </div> 

          <div
            style={{
              margin: 0,
              paddingLeft: "2em",
              borderLeft: "3px solid #f2e9df",
              display: "flex",
              width: "25%",
            }}                  
          >
            <Image 
              style={{
                display: "inline-block",
                filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)"
              }}
              src={imgFields} 
            />
            <div style={{ marginLeft: 20, marginTop: 5 }}>
              <p style={{ color: "grey", marginBottom: 0 }}>Freja övervakar</p>
              <p style={{ fontWeight: 400, fontSize: "130%", marginTop: 0 }}>{totalArea && " " + totalArea.toFixed(0)} ha ({noFields && noFields} skiften)</p>
            </div>
          </div>                                                                                                  
        </div>
      </Segment>
    )
  }
}

export default HomeBarWidget;
