import React, { Component } from "react";

import { 
    Icon,
    Table,
    Button,
    Segment,
    Header,
} from "semantic-ui-react";

import {
  DateInput,
} from 'semantic-ui-calendar-react';

import moment from 'moment';

import { getCurrentYear } from "@/helpers/dates";
import { fetchWeatherData, extractYears, extractDatesAndData } from "@/helpers/weather";

import {
  SEASON_FIRST_DATE,
  SEASON_LAST_DATE,
} from "constants/settings";

const INITIAL_STATE = {
  temperatureData: null,
  rainfallData: null,
  irradiationData: null,
  overridingLayerId: null,
  sortIdx: "year_reversed",
};

class WeatherSeasonSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    const {
      weatherData,
      fileBucketRef,
    } = this.props;

    fetchWeatherData(weatherData, fileBucketRef, this.setParentState);
  };

  componentDidUpdate(prevProps) {
    const {
      weatherData,
      fileBucketRef,
    } = this.props;

    if (!this.state.temperatureData && weatherData !== prevProps.weatherData) {
      fetchWeatherData(weatherData, fileBucketRef, this.setParentState);
    }
  };

  setParentState = (newState) => this.setState(newState);

  setSortTable = (newIndex) => () => {
    if (newIndex === this.state.sortIdx) {
      this.setState({ sortIdx: newIndex + "_reversed" });
    } else {
      this.setState({ sortIdx: newIndex });
    }
  };  

  sortTable = (weatherData) => (a, b) => {
    const yearA = weatherData[a].year;
    const yearB = weatherData[b].year;

    const temperatureA = weatherData[a].temperature;
    const temperatureB = weatherData[b].temperature;

    const rainfallA = weatherData[a].rainfall;
    const rainfallB = weatherData[b].rainfall;
    
    const irradiationA = weatherData[a].irradiation;
    const irradiationB = weatherData[b].irradiation;

    switch (this.state.sortIdx) {

      case "year":
        return parseInt(yearA) - parseInt(yearB);
      
      case "year_reversed":
        return parseInt(yearB) - parseInt(yearA);
      
      case "temperature":
        return temperatureA - temperatureB;
      
      case "temperature_reversed":
        return temperatureB - temperatureA;
      
      case "rainfall":
        return rainfallA - rainfallB;
      
      case "rainfall_reversed":
        return rainfallB - rainfallA;
      
      case "irradiation":
        return irradiationA - irradiationB;
      
      case "irradiation_reversed":
        return irradiationB - irradiationA;  

      default: 
        return -1;        
    };
  };   

  handleChange = (event, {name, value}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  onClickPreviousDate = (oldDate) => {
    let oldDateMoment = moment(oldDate, "YYYY-MM-DD");
    let newDate = oldDateMoment.subtract(1, 'days');
    
    this.setState({
      overridingLayerId: newDate.format("YYYY-MM-DD")
    })
  };

  onClickNextDate = (oldDate) => {
    let oldDateMoment = moment(oldDate, "YYYY-MM-DD");
    let newDate = oldDateMoment.add(1, 'days');
    
    this.setState({
      overridingLayerId: newDate.format("YYYY-MM-DD")
    })
  };

  getContent () {
    const {
      layer,
    } = this.props;

    const {        
      irradiationData,
      temperatureData,
      rainfallData,
      overridingLayerId,
      sortIdx,
    } = this.state;

    let thisYear = getCurrentYear(true);

    let weatherYears = temperatureData && extractYears(temperatureData);

    let layerId = this.props.layerId ? this.props.layerId : moment().format("YYYY-MM-DD");
    let currentYear = layerId && layerId.split("-")[0];
    currentYear = (moment(layerId, "YYYY-MM-DD") < moment(currentYear + SEASON_FIRST_DATE, "YYYY-MM-DD")) ? 
      (Number(currentYear) - 1).toFixed(0) : 
      currentYear;

    let localLayerId = overridingLayerId ? overridingLayerId : layerId;
    localLayerId = moment(localLayerId, "YYYY-MM-DD") > moment(currentYear + SEASON_LAST_DATE, "YYYY-MM-DD") ? 
      currentYear + SEASON_LAST_DATE : 
      localLayerId;

    let weatherData = {};

    weatherYears && weatherYears.forEach((year) => {
      let yearTemp = extractDatesAndData(temperatureData, localLayerId, year);
      let yearRainfall = extractDatesAndData(rainfallData, localLayerId, year);
      let yearIrradiation = extractDatesAndData(irradiationData, localLayerId, year) / 1000.0;

      let [startDate, endDate] = extractDatesAndData(irradiationData, localLayerId, year, false, true);
      startDate = startDate && moment(startDate).format("LL");
      endDate = endDate && moment(endDate).format("LL");

      weatherData = {...weatherData,
        [year]: {
          year: year,
          temperature: yearTemp,
          rainfall: yearRainfall,
          irradiation: yearIrradiation,
          startDate,
          endDate,
        }
      }
    });

    weatherYears = weatherData && weatherYears && weatherYears.sort(this.sortTable(weatherData));

    return (
      <React.Fragment>
        <p
          style={{
            whiteSpace: "pre-line",
            marginBottom: "1em"
          }}
        >
          Nedanför ser du en jämförelse av vädret under olika växtodlingssäsonger. Freja beräknar de ackumulerade dygnsgraderna, nederbörden och solinstrålningen från den första mars varje år till ett slutdatum som du kan justera själv nedanför.
        </p>

        <Table 
          sortable
          striped
          celled
          selectable
          style={{ 
            width: "100%", 
            textAlign: "center" 
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                onClick={this.setSortTable("year")}
              >
                Säsong
                {sortIdx === "year" && <Icon name="sort ascending" />}
                {sortIdx === "year_reversed" && <Icon name="sort descending" />}
              </Table.HeaderCell>
              <Table.HeaderCell 
                onClick={this.setSortTable("temperature")}
              >
                Dygnsgrader
                {sortIdx === "temperature" && <Icon name="sort ascending" />}
                {sortIdx === "temperature_reversed" && <Icon name="sort descending" />}
              </Table.HeaderCell>

              <Table.HeaderCell 
                onClick={this.setSortTable("rainfall")}
              >
                Nederbörd
                {sortIdx === "rainfall" && <Icon name="sort ascending" />}
                {sortIdx === "rainfall_reversed" && <Icon name="sort descending" />}
              </Table.HeaderCell>

              <Table.HeaderCell 
                onClick={this.setSortTable("irradiation")}
              >
                Solinstrålning
                {sortIdx === "irradiation" && <Icon name="sort ascending" />}
                {sortIdx === "irradiation_reversed" && <Icon name="sort descending" />}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {weatherData && weatherYears && weatherYears
              .filter((year) => Object.keys(weatherData).includes(year))
              .map((year) => {

                let weatherYear = weatherData[year];

                return (
                  <Table.Row 
                    key={'table_row_' + year}
                    style={{
                      backgroundColor: year === thisYear ? "#F1DDA2" : null,
                    }}
                  >
                    <Table.Cell>{weatherYear.year}</Table.Cell>
                    <Table.Cell>{
                      typeof(weatherYear.temperature) === "number" ? 
                        Math.floor(weatherYear.temperature).toLocaleString().replace(/,/g," ",) : 
                        "0"
                      }&deg;C
                    </Table.Cell>
                    <Table.Cell>
                      {typeof(weatherYear.rainfall) === "number" ? 
                        weatherYear.rainfall.toFixed(0) : 
                        "0"
                      } mm
                    </Table.Cell>
                    <Table.Cell>{
                      typeof(weatherYear.irradiation) === "number" ? 
                      weatherYear.irradiation.toFixed(0) : 
                      "0"
                    } kW/m2
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>

        {!layer.includes("yieldMap") &&
          <div 
            style={{ 
              display: "flex", 
              justifyContent: "space-between",
              margin: "0 auto" 
            }}
          >
            <Button
              basic
              icon
              labelPosition="left"
              size="small"
              style={{
                marginRight: 10,
                color: "white",
                backgroundColor: "#FBBD08",
              }}
              onClick={() => this.onClickPreviousDate(localLayerId)}
              disabled={localLayerId === currentYear + '-03-02'}
            >
              <Icon name="chevron left" />
              Föregående
            </Button>

            <DateInput
              name="overridingLayerId"
              placeholder="Datum"
              value={localLayerId}
              minDate={currentYear + '-02-02'}
              maxDate={currentYear + '-06-30'}
              popupPosition="bottom right"
              dateFormat="YYYY-MM-DD"
              localization='sv'              
              iconPosition="left"
              onChange={this.handleChange}
            />            
            
            <Button
              basic
              icon
              labelPosition="right"
              size="small"
              style={{
                marginLeft: 10,
                color: "white",
                backgroundColor: "#FBBD08",
              }}
              onClick={() => this.onClickNextDate(localLayerId)}
              disabled={localLayerId === currentYear + '-06-30'}
            >
              <Icon name="chevron right" />
              Nästa
            </Button>
          </div>
        }    
      </React.Fragment>
    )
  }

  render () {
    const {
      type,
    } = this.props;

    const {        
      irradiationData,
      temperatureData,
      rainfallData,
    } = this.state;

    if (!type || type === "widget") {
      return (
        <Segment
          style={{
            padding: "2em",
            paddingTop: "1.5em",
            fontWeight: "normal",
            height: "100%",
          }}
          loading={!temperatureData || !rainfallData || !irradiationData}
        >
          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
            <Header
              as="h3"
              style={{ 
                paddingTop: "0",
                fontWeight: 500,
                color: "grey",
              }}
            >
              Utforska vädret under olika säsonger
            </Header>
                              
            <div />
          </div>

          {this.getContent()}
        </Segment>
      )
    };

    if (type === "modal") {
      return this.getContent()
    };    
  }
}

export default WeatherSeasonSummary;
