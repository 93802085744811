import React, { Component } from "react";

import { 
  Image, 
  Icon, 
  Dropdown, 
  Button, 
  Popup, 
  Segment, 
  Menu,
  Header,
  Label,
} from "semantic-ui-react";

import { 
  getCurrentDataLayer,
  FIELD_DATA_LAYERS, 
  FIELD_ANALYSIS_LAYERS,
  FIELD_SUMMARY_LAYERS,
} from "@/constants/viewLayersAnalysis";

import { 
  CROP_CODES,
} from "@/constants/cropCodes";

import { getAvailableLayerIds } from "@/helpers/dataFetcher";
import { KEEP_DATE_LAYERS } from "@/constants/viewLayersAnalysis";

import { isLoaded } from "react-redux-firebase";

import { DateInput } from 'semantic-ui-calendar-react';

import FieldDataFieldScore from "@/components/fieldData/FieldDataFieldScore";

import moment from 'moment';

const SELECTED_PLOT = {
  'compareUpperLeftLayer': "Övre vänster",
  'compareUpperRightLayer': "Övre höger",
  'compareLowerLeftLayer': "Nedre vänster",
  'compareLowerRightLayer': "Nedre höger",
  'compareLayerVraMap': "Jämförelselager",
};

const REMOVE_FOR_CORRELATION_SUBSTRINGS = [
  '_potential',
  '_clustering',
  '_segmentationMedian',
  '_segmentationVariance',
  '_difference',
  '_hotspot',
];

const REMOVE_FOR_CORRELATION_MATCH = [
  'soilMapAnalysis',
  'trueColor',
  'soilMapSweden_texture',
  'soilMapUploaded_samples',
];

const getNotificationDot = () => (
  <div 
    style={{ 
      position: "relative", 
      top: "-1.7em", 
      right: "-1.7em", 
      width: "0.5em", 
      height: "0.5em", 
      borderRadius: "50%", 
      backgroundColor: "#C36126" 
    }} 
  />
);

const ListItem = ({ 
    id,
    layerInfo, 
    isBetaMode,
    isCompare,
    isCorrelation,
    layer, 
    layerId, 
    selectedTab, 
    onSelectTab, 
    onChangeLayer, 
    onChangeDate, 
    onChangeDateSelect,
    onClickNextDate,
    onClickPreviousDate,
    dates,
    years,
    crops,
    onChangeCropSelect,
    keysInUploadedSoilMaps,
    newDataAvailable,
  }) => {

    let checkedLayerId = moment(layerId, "YYYY-MM-DD", true).isValid() ? layerId : null;

    let yearsSelect = years && [...years]
    .sort(function(a, b) {
      a = new Date(a);
      b = new Date(b);
      return a > b ? -1 : a < b ? 1 : 0;
    })
    .map(year => ({
      key: year,
      text: year,
      value: year
    }));

    let cropsSelect = [];
     crops && crops.forEach(crop => {
        if (!crop || ['all', 'cereals'].includes(crop)) {
          return;
        };

        let cropName = CROP_CODES.find((x) => x.value === crop);
        cropName = cropName && cropName.shorttext && cropName.shorttext;

        if (!cropName) {
          return;
        };

        cropsSelect = [...cropsSelect, {
          key: crop,
          text: cropName,
          value: crop,
        }]
      })
      
      cropsSelect = cropsSelect && cropsSelect
      .sort(function(a, b) {
        let textA = a.text;
        let textB = b.text;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });

      if (crops && crops.includes("cereals")) {
        cropsSelect = [{
          key: "cereals",
          text: "Stråsäd",
          value: "cereals",
        },
        ...cropsSelect
        ]
      };

      if (crops && crops.includes("all")) {
        cropsSelect = [{
          key: "all",
          text: "Alla grödor",
          value: "all",
        },
        ...cropsSelect
        ]
      };

    let tabOpen = layerInfo && layerInfo.aliases && layerInfo.aliases.includes(selectedTab) && layerInfo.captions;

    if (isCorrelation && REMOVE_FOR_CORRELATION_MATCH.includes(layerInfo.name)) {
      return <div key={id} />
    };

    let layerIdTypes = layerInfo && layerInfo.layerIdTypes;
    layerIdTypes = Array.isArray(layerIdTypes) ? layerIdTypes : layerIdTypes[layer];

    cropsSelect = layerIdTypes && !layerIdTypes.includes("crops") ? null : cropsSelect;
    yearsSelect = layerIdTypes && !layerIdTypes.includes("years") ? null : yearsSelect;

    let layerIdList;
    layerIdList = layerIdTypes && layerIdTypes.includes("dates") ? dates : layerIdList;
    layerIdList = layerIdTypes && layerIdTypes.includes("crops") ? crops : layerIdList;
    layerIdList = layerIdTypes && layerIdTypes.includes("years") ? (layerIdList ? [...layerIdList, ...years] : years) : layerIdList;

    let noMaps = layerIdList && layerIdList.length;

    let caption = layerInfo.caption;
    caption = (!isCompare && layerInfo.shortCaption) ? layerInfo.shortCaption : caption;

    return (
      <Segment 
        key={id}
        className={layerInfo.aliases.includes(selectedTab) ? null : "fieldLayerCardOnHover"}
        style={{
          borderColor: layerInfo.aliases.includes(selectedTab) ? "rgb(234, 204, 116)" : null,
          backgroundColor: layerInfo.aliases.includes(selectedTab) ? "#F8EED0" : null,
          boxShadow: "0 1px 2px lightgrey",
          marginTop: 0,
          marginBottom: 8,
          marginRight: isCompare ? 8 : null,
        }}
        onClick={() => layerInfo.aliases.includes(selectedTab) ? null : onSelectTab(layerInfo.name) }        
      >
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between"
          }}          
        >
          <div 
            style={{ 
              margin: 0,
              color: "black",
              display: "flex",
            }}
          >
            <div 
              className="fieldCartoon"
              style={{
                backgroundColor: layerInfo.aliases.includes(selectedTab) ? "white" :"#F8EED0",
                borderRadius: 5,
                width: "2.4em",
                height: "2.4em",
                marginRight: "0.4em",
              }}
            >
              <Image 
                style={{ 
                  marginTop: "0.5em",
                  marginLeft: "0.5em",
                  width: "1.4em", 
                  height: "1.4em",
                  filter: "invert(0%)",
                }}
                src={layerInfo.icon} 
              />

              {!isCompare && newDataAvailable && newDataAvailable.length > 0 && getNotificationDot()} 
            </div>

            <span 
              style={{ 
                marginTop: "0.5em", 
                fontWeight: 600, 
                marginLeft: "0.45em" 
              }}
            >
              {caption}
            </span>
          </div>

          <div style={{ marginTop: "0.5em" }}>
            {layerInfo.showBetaLabel && layerInfo.showBetaLabel === true && <Label basic size="small">Nyhet</Label>}
            {layerInfo.aliases.includes(selectedTab) && (noMaps ? " " + noMaps + " kartor  " : "")}
            {layerInfo.captions && !layerInfo.aliases.includes(selectedTab) && <Icon style={{ textAlign: "right" }} name={isCompare ? "chevron right" : "chevron down"} />}
            {!layerInfo.captions && <Icon style={{ textAlign: "right" }} name="chevron right" />}
          </div>          
        </div>

        {tabOpen && 
          <Menu
            vertical
            fluid  
            style={{
              marginTop: "2em"
            }}
          >
            {Object.keys(layerInfo.captions).map((x) => {
              if (layerInfo.onlyBeta && layerInfo.onlyBeta.includes(x) && !isBetaMode) {
                return null;
              };      

              if (selectedTab === "soilMapUploaded" && keysInUploadedSoilMaps && !keysInUploadedSoilMaps.includes(layerInfo.geoJsonFieldKey[x].replace("_nn", ""))) {
                return null;
              };
              
              if (selectedTab === "soilMapAnalysis" && keysInUploadedSoilMaps && !keysInUploadedSoilMaps.includes(layerInfo.geoJsonFieldKey[x])) {
                return null;
              };                  

              if (isCorrelation && REMOVE_FOR_CORRELATION_MATCH.includes(x)) {
                return <div />
              };

              let returnNull = false;
              
              isCorrelation && REMOVE_FOR_CORRELATION_SUBSTRINGS.forEach((y) => {
                if (x.includes(y)) {
                  returnNull = true;
                }
              });

              if (isCorrelation && returnNull) {
                return <div />
              };
              
              if (x === "elevationMap_yieldMap" && (!years || years.length <1)) {
                return <div />
              };

              return (
                <Menu.Item
                  key={'subItem_' + x}
                  active={layer === x}
                  onClick={() => onChangeLayer(x, layerId) }
                  style={{
                    backgroundColor: layer === x ? "rgb(234, 204, 116)" : null,
                  }}
                >
                  {layerInfo && layerInfo.captions && layerInfo.captions[x]}

                  <Popup
                    trigger={layerInfo.onlyBeta && layerInfo.onlyBeta.includes(x) && isBetaMode && <Icon name="code branch" color="red" />}
                    content="Detta är en betafunktion som inte ännu är fullt utvecklad. Vänlig använd den med försiktighet."
                  />

                  <Icon 
                    name="chevron right" 
                  />   

                  {layerInfo && layerInfo.helpText && layerInfo.helpText[x] &&
                    <Popup
                      trigger={<Icon name="help circle" color="grey" />}
                      content={layerInfo.helpText[x]}
                    />
                  }
                 
                </Menu.Item>
              )
            })}
          </Menu>
        }

        {tabOpen && dates && dates.length > 0 && checkedLayerId && dates.includes(checkedLayerId) &&
          <Header 
            as="h5"
            style={{
              fontWeight: 600,
              marginTop: 10,
              marginBottom: 4,
            }}
          >
            Välj datum
          </Header>
        }

        {tabOpen && dates && dates.length > 0 && checkedLayerId && dates.includes(checkedLayerId) &&
          <DateInput
            pickerStyle={{
              minWidth: "50%",
              maxWidth: "100%",
              fontSize: "85%",
            }}
            inline
            name='date'
            minDate='2013-01-01'
            dateFormat="YYYY-MM-DD"
            value={(dates && checkedLayerId && dates.includes(checkedLayerId)) ? checkedLayerId : ""}
            initialDate={(dates && checkedLayerId && dates.includes(checkedLayerId)) ? checkedLayerId : ""}
            marked={dates}
            enable={dates}
            markColor="green"
            onChange={(e, d) => onChangeDate(d.value)}
            localization='sv'
          /> 
        }
        
        {tabOpen && ((cropsSelect && cropsSelect.length > 0) || (yearsSelect && yearsSelect.length > 0))&&
          <Segment
            secondary
            style={{
              padding: "1em",
            }}
          >
            {tabOpen && cropsSelect && cropsSelect.length > 0 && 
              <Header 
                as="h5"
                style={{
                  fontWeight: 600,
                  marginTop: 0,
                  marginBottom: 4,
                }}
              >
                Välj typ av gröda
              </Header>
            }

            {tabOpen && cropsSelect && cropsSelect.length > 0 && 
              <Dropdown
                style={{ 
                  marginBottom: "1em",
                  border: "1px solid grey",
                }}          
                fluid
                selection
                upward
                onChange={onChangeCropSelect}
                value={cropsSelect.find((x) => x.key === layerId) ? layerId : null}
                options={cropsSelect}
                placeholder="Välj en gröda..."
              />
            }

            {tabOpen && yearsSelect && yearsSelect.length > 0 &&
              <Header 
                as="h5"
                style={{ 
                  fontWeight: 600,
                  marginTop: 0,
                  marginBottom: 4,
                }}
              >
                {cropsSelect && cropsSelect.length > 0 ? "eller välj år" : "Välj år"}
              </Header>
            }

            {tabOpen && yearsSelect && 
              <Dropdown
                style={{ 
                  marginBottom: "1em",
                  border: "1px solid grey" 
                }}          
                fluid
                selection
                upward
                placeholder="Välj ett år..."
                onChange={onChangeDateSelect}
                value={yearsSelect.find((x) => x.key === layerId) ? layerId : null}
                options={yearsSelect}
              />
            }
          </Segment>
        }

        {tabOpen && layerIdList && layerIdList.length > 0 &&
          <div 
            style={{ 
              width: "100%",
              paddingTop: 0,
              display: "flex",
              justifyContent: "space-between",
          }}>
            <Button
            primary
              icon
              labelPosition="left"
              size="tiny"
              style={{
                marginRight: 10,
              }}
              onClick={() => onClickPreviousDate(layer, layerId, layerIdList)}
            >
              <Icon name="chevron left" />
              {layerIdTypes && layerIdTypes.includes("years") ? "Föregående år" : "Föregående"}
            </Button>

            <Button
              primary
              icon
              labelPosition="right"
              size="tiny"
              style={{
                marginLeft: 10,
              }}
              onClick={() => onClickNextDate(layer, layerId, layerIdList)}
            >
              <Icon name="chevron right" />
              {layerIdTypes && layerIdTypes.includes("years") ? "Nästa år" : "Nästa"}
            </Button>
          </div>
        }
      </Segment>
  )
}

class FieldDataLayerList extends Component {
  state = {
    selectedTab: null,
    pointsItemOpen: false,
  };  

  componentDidMount = () => {
    const {
      layer,
      isCompare,
    } = this.props;

    !isCompare && this.setState({
      selectedTab: (layer && layer.split("_")[0]) ? layer.split("_")[0] : null ,
    });
  };

  componentDidUpdate = (prevProps) => {
    const {
      open,
      layer,
      plotName,
      isCompare,
    } = this.props;

    if (isCompare) {
      if ((prevProps.plotName !== plotName) || (prevProps.layer !== layer)){
        this.setState({
          selectedTab: (layer && layer.split("_")[0]) ? layer.split("_")[0] : null ,
        });
      };
  
      if (prevProps.open === false && open === true) {
        this.setState({
          selectedTab: (layer && layer.split("_")[0]) ? layer.split("_")[0] : null ,
        });
      };    

    } else {
      if ((prevProps.layer !== this.props.layer) || !this.state.selectedTab) {
        if (layer && layer.split("_")[0]) {
          this.setState({
            selectedTab: layer.split("_")[0],
          });
        };
      };
  
      this.props.selectedCompareView !== prevProps.selectedCompareView && this.onChangeSelectedCompareView()
    }
  };

  onChangeLayer = (newLayer, newLayerId) => {
    const {
      db,
      layer,
      fieldId,
    } = this.props;

    let plotName = this.props.plotName;
    plotName = plotName && plotName.replace("compare", "").replace("Layer", "");

    let newDataLayer = getCurrentDataLayer(newLayer);

    // Check if the new and old layer are not the same
    if (newLayer && layer && newLayer.split("_")[0] !== layer.split("_")[0]) {
    
      // Check if a subfield is present on the current layer and not on the new
      // This indicates that a new tab has been clicked in the UI      
      if (newLayer.split("_").length === 1 && layer.split("_").length === 2) {
        let subLayerType = layer.split("_")[1];

        // Check if the new layer has the corresponding sublayer, in that case append it
        if (newDataLayer.aliases.includes(newLayer + "_" + subLayerType)) {
          newLayer = newLayer + "_" + subLayerType;
        }
      };
    };
    
    let availableDates = getAvailableLayerIds(db, fieldId, newLayer);
    let checkedDate = availableDates ? (availableDates.includes(newLayerId) ? newLayerId : null) : newLayerId;

    this.props.openLayer(newLayer, checkedDate, plotName);
  };
  
  onSelectTab = (newMainLayer) => {
    const { 
      layerId, 
    } = this.props;

    const {
      selectedTab,
    } = this.state;

    if (selectedTab === newMainLayer) {
      this.setState({ selectedTab: null });
    } else {
      this.setState({ selectedTab: newMainLayer });

      if (KEEP_DATE_LAYERS.includes(selectedTab) && KEEP_DATE_LAYERS.includes(newMainLayer)) {
        this.onChangeLayer(newMainLayer, layerId);
      } else {
        this.onChangeLayer(newMainLayer);
      };      
    };
  };

  togglePointsItemOpen = () => {
    let currentValue = this.state.pointsItemOpen;
    this.setState({pointsItemOpen: !currentValue});
  };

  onChangeDateSelect = (e, data) => {
    const { layer } = this.props;
    this.onChangeLayer(layer, data.value);
  };

  onChangeCropSelect = (e, data) => {
    const { layer } = this.props;
    this.onChangeLayer(layer, data.value);
  };

  onChangeDate = (layerId) => {
    const { layer } = this.props;
    this.onChangeLayer(layer, layerId);
  };

  onClickPreviousDate = (layer, layerId, dates) => {
    let sortedDates = null 
    let copyDates = [...dates];

    if (copyDates && copyDates[0] && copyDates[0].includes("-")) {
      sortedDates = copyDates && copyDates.sort()
    } else {
      sortedDates = copyDates && copyDates.map((x) => parseInt(x)).sort();
      sortedDates = sortedDates && sortedDates.map((x) => (typeof(x) === "number" ? x.toFixed(0) : x))
    };    

    let currentIndex = sortedDates && sortedDates.indexOf(layerId);

    if (typeof(currentIndex) === "number" && (currentIndex > 0)) {
      let newDate = sortedDates[currentIndex - 1];
      this.onChangeLayer(layer, newDate);
    };
  };

  onClickNextDate = (layer, layerId, dates) => {
    let sortedDates = null 
    let copyDates = [...dates];

    if (copyDates && copyDates[0] && copyDates[0].includes("-")) {
      sortedDates = copyDates && copyDates.sort()
    } else {
      sortedDates = copyDates && copyDates.map((x) => parseInt(x)).sort();
      sortedDates = sortedDates && sortedDates.map((x) => (typeof(x) === "number" ? x.toFixed(0) : x))
    } 
    
    let currentIndex = sortedDates && sortedDates.indexOf(layerId);

    if (typeof(currentIndex) === "number" && currentIndex < (sortedDates.length - 1)) {
      let newDate = sortedDates[currentIndex + 1];
      this.onChangeLayer(layer, newDate);
    };    
  };
  
  render() {
    const {
      open,
      db,
      fieldId,
      layer,
      layerId,
      isCompare,
      isCorrelation,
      dataType,
      plotName,
      activeView,
    } = this.props;

    const { 
      selectedTab,
    } = this.state;
    
    const userPlanType = db && db.profile && db.profile.plan && db.profile.plan.plan_type;

    let DATA_LAYERS = dataType && dataType === "layers" ? FIELD_DATA_LAYERS : {...FIELD_SUMMARY_LAYERS, ...FIELD_ANALYSIS_LAYERS};
    DATA_LAYERS = isCompare ? {...FIELD_DATA_LAYERS, ...FIELD_ANALYSIS_LAYERS} : DATA_LAYERS;

    let currentDataLayer = layer && getCurrentDataLayer(layer);

    let field = db && fieldId && db.fields && db.fields[fieldId];
    let newDataAvailable = field && field.new_data_available && field.new_data_available;

    let dbSettingsView = db && db.settings && db.settings.view;
    let isBetaMode = dbSettingsView && dbSettingsView.beta_mode && dbSettingsView.beta_mode;
    let selectedGreenmassIndex = dbSettingsView && dbSettingsView.greenmass_index;
    selectedGreenmassIndex = selectedGreenmassIndex ? selectedGreenmassIndex : 'msavi';

    let dataLayers = Object.keys(DATA_LAYERS).map((x) => DATA_LAYERS[x]);

    let keysInUploadedSoilMaps = db && fieldId && layerId && db['fieldsSoilMapsProcessed_' + fieldId];
    keysInUploadedSoilMaps = keysInUploadedSoilMaps && keysInUploadedSoilMaps.rawdata_uploaded_soilmap_available_keys;
    keysInUploadedSoilMaps = keysInUploadedSoilMaps && keysInUploadedSoilMaps[layerId];

    keysInUploadedSoilMaps = keysInUploadedSoilMaps && [...keysInUploadedSoilMaps, "samples"];

    if (!db || !isLoaded(db)) {
      return <div></div>
    }

    if (isCompare && !open) {
      return <div></div>
    };
    
    let leftPosition = "20em";
    if (plotName === "compareLayerVraMap") { leftPosition = "0em" };
    if (plotName === "compareUpperRightLayer") { leftPosition = "35em" };
    if (plotName === "compareLowerLeftLayer" && activeView === "compare2correlation") { leftPosition = "35em" };
    if (plotName === "compareLowerLeftLayer" && activeView !== "compare2correlation") { leftPosition = "50em" };
    if (plotName === "compareLowerRightLayer") { leftPosition = "65em" };

    let listData = dataLayers && dataLayers
      .filter((x) => !Object.keys(FIELD_SUMMARY_LAYERS).includes(x.name))
      .sort((a, b) => {
        let labelA = a.shortCaption ? a.shortCaption : a.caption;
        let labelB = b.shortCaption ? b.shortCaption : b.caption;

        let sortedArray = [labelA, labelB].sort()
        if (sortedArray[0] === labelB) {
          return 1.0
        } else {
          return -1.0
        };
      });
    
    // In comparison mode, place the current layer first in the list
    listData = isCompare ? listData.filter((x) => x !== currentDataLayer) : listData;
    listData = (isCompare && currentDataLayer) ? [currentDataLayer, ...listData] : listData;

    listData = listData && (dataType === "analysis" && !isCompare ? [...Object.keys(FIELD_SUMMARY_LAYERS).map((x) => FIELD_SUMMARY_LAYERS[x]), ...listData] : listData);

    listData = listData && listData.map((x) => {
      let availableLayersIds = getAvailableLayerIds(db, fieldId, x && x.name);

      if (!availableLayersIds) {
        return null
      };

      if (x.includedInPlans && userPlanType && !x.includedInPlans.includes("all") && !x.includedInPlans.includes(userPlanType)) {
        return null
      };
      
      if (x.onlyBeta && x.onlyBeta.includes(x.name) && isBetaMode !== true) {
        return null
      };

      if (selectedGreenmassIndex === 'msavi' && ['ndvi', 'ndviAnalysis'].includes(x.name)) {
        return null
      };

      if (selectedGreenmassIndex === 'ndvi' && ['greenmass', 'greenmassAnalysis'].includes(x.name)) {
        return null
      };

      let dataSource = x.source;
      let subNewDataAvailable = newDataAvailable && dataSource;
      subNewDataAvailable = subNewDataAvailable && newDataAvailable[dataSource] && newDataAvailable[dataSource];

      let layerIdTypes = x.layerIdTypes;
      layerIdTypes = Array.isArray(layerIdTypes) ? layerIdTypes : layerIdTypes && layerIdTypes[layer];
      
      let dates = layerIdTypes && layerIdTypes.includes("dates") ? [...availableLayersIds] : [];
      let years = layerIdTypes && layerIdTypes.includes("years") ? [...availableLayersIds].filter((x) => !isNaN(x) && parseInt(x) > 1000).sort() : [];
      let crops = layerIdTypes && layerIdTypes.includes("crops") ? [...availableLayersIds].filter((x) => isNaN(x) || parseInt(x) < 1000) : [];

      return (
        <ListItem 
          key={'listItem_' + x.name}
          isBetaMode={isBetaMode}
          isCompare={isCompare}
          isCorrelation={isCorrelation}
          layer={layer}
          layerId={layerId}
          layerInfo={x}
          selectedTab={selectedTab}
          onSelectTab={this.onSelectTab}
          onChangeLayer={this.onChangeLayer}
          onChangeDate={this.onChangeDate}
          onChangeDateSelect={this.onChangeDateSelect}
          onClickPreviousDate={this.onClickPreviousDate}
          onClickNextDate={this.onClickNextDate}
          dates={dates}
          years={years}
          crops={crops}
          keysInUploadedSoilMaps={keysInUploadedSoilMaps}
          dataType={dataType}
          newDataAvailable={subNewDataAvailable}
          onChangeCropSelect={this.onChangeCropSelect}
        />
      )
    })

    if (!isCompare) {

      return (
        <React.Fragment>
          <FieldDataFieldScore 
            isOpen={this.state.pointsItemOpen}
            toggleOpen={this.togglePointsItemOpen}
            onChangeLayer={this.onChangeLayer}
            db={db}
            fieldId={fieldId}
          />
          {listData && listData}        
        </React.Fragment>
      );

    } else {

      return (
        <div
          style={{
            position: "absolute",
            left: leftPosition,
            top: "45px",
            width: "20vw",
            zIndex: 9999,
            padding: "1em",
            maxHeight: "50vh",
            
          }}
        >
          <Segment
            style={{
              padding: "0.5em",
              border: "0.5px lightgrey solid",
              borderRadius: 5,
            }}
          >
            {selectedTab &&
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "0.75em",
                  marginBottom: "1.25em",
                  color: "black",
                }}
              > 
                <Icon 
                  className="iconButton"
                  name="chevron left" 
                  onClick={() => this.setState({ selectedTab: null })} 
                />

                {SELECTED_PLOT[plotName] ? SELECTED_PLOT[plotName] : "Välj datalager"}
                
                <Icon
                  className="iconButton"
                  name="close" 
                  onClick={() => this.props.toogleShowSelectLayer(null)} 
                />
              </div>
            }

            {!selectedTab &&
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "0.75em",
                  marginBottom: "1.25em",
                }}
              > 
                <Icon 
                  className="iconButton"
                  name="chevron left" 
                  onClick={() => this.props.toogleShowSelectLayer()} 
                />
                
                Välj datalager
                
                <Icon                   
                  className="iconButton"
                  name="close" 
                  onClick={() => this.props.toogleShowSelectLayer(null)} 
                />
              </div>
            }

            <div
              style={{
                maxHeight: "70vh",
                overflowY: "auto",
                marginRight: "-0.5em",
              }}          
            >
              {listData && listData}
            </div>
          </Segment>
        </div>
      )
    }
  }
}

export default FieldDataLayerList;
