import React, { Component } from "react";

import { 
  Map as LeafletMap, 
  TileLayer, 
  Circle, 
  Polygon 
} from "react-leaflet";

import { 
  Segment, 
  Header,
  Button,
  Icon,
  Dropdown,
  Image,
  Loader,
  Modal,
} from "semantic-ui-react";

import { FIELD_DATA_LAYERS } from "@/constants/viewLayersAnalysis";
import { AREAL_TILE_URL } from "@/constants/map";

import { geoPointToPosition } from "@/helpers/db";
import { getFieldMapOptions } from "@/helpers/maps";

import illustrationDone from "@/assets/illustrations/done_simple.png";
import illustrationError from "@/assets/illustrations/error_simple.png";
import illustrationQuestion from "@/assets/illustrations/question_simple.png";

import IconWithPlate from "@/components/misc/IconWithPlate";
import imgSoilMap from "@/assets/icons/dataTypes/soil-data.svg";

class UploadSoilMapsMapPreview extends Component {
  state = {
    newFileFields: [],
    newFileYear: null,
    center: [58.41086, 15.62157],
    error: false,
    processing: false,
    showDetailsModal: false,
    fileNeedsMoreInfo: false,
  };

  onChangeField = (event) => {
    let name = (event.target && event.target.name) ? event.target.name : event.name;
    let value = (event.target && event.target.value) ? event.target.value : event.value;
    this.setState({ [name]: value });
  };

  onChangeFieldOnlyNumbers = (event) => {
    let name = (event.target && event.target.name) ? event.target.name : event.name;
    let value = (event.target && event.target.value) ? event.target.value : event.value;
    value = value && value.replace(/[^\d]/g, '');

    this.setState({ [name]: value });  
  };

  toggleShowDetailsModal = () => {
    let currentValue = this.state.showDetailsModal;
    this.setState({ showDetailsModal: !currentValue });
  };

  saveNewFieldInfo = () => {
    const { 
      fileId,
    } = this.props;

    const { 
      newFileFields,
      newFileYear,
    } = this.state;

    this.props.onProcessFileAgain(fileId, newFileFields, newFileYear);

    this.setState({
      newFileFields: [],
      newFileYear: null,
      fileNeedsMoreInfo: false,
    });    

    this.toggleShowDetailsModal();
  };

  onEditFile = () => {
    const { 
      uploadedFileData,
    } = this.props;

    let fieldsInFile = uploadedFileData && uploadedFileData.fields_in_file;
    fieldsInFile = typeof(fieldsInFile) === "string" ? [fieldsInFile] : fieldsInFile;
    let fileYear = uploadedFileData && uploadedFileData.year;

    this.setState({
      newFileFields: fieldsInFile ? fieldsInFile : [],
      newFileYear: fileYear ? fileYear : null,
      fileNeedsMoreInfo: true,
    });

    this.toggleShowDetailsModal();
  };

  getDetailsModal = (fields, markers, mapOptions, fieldsInFile, fieldNamesInFile, fileName, fileYear, columnsInFile, noMarkers) => {
    const {
      fileNeedsMoreInfo,
      newFileFields,
      newFileYear,
    } = this.state;

    let availableYearList = Array.apply(null, {length: 31}).map(Number.call, Number);
    availableYearList = availableYearList && availableYearList.map((x) => (1990 + x).toFixed(0));
  
    availableYearList = availableYearList && availableYearList.reverse().map((x) => ({
      key: x,
      value: x,
      text: x,
    }));
  
    let availableFields = fields && Object.keys(fields).map((x) => ({
      key: x,
      value: x,
      text: fields[x].name,
    }));
  
    return (
      <Modal open={true}>
        <Modal.Content>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em"
            }}
          >

            <div>
              {!markers &&
                <Header 
                  as="h4"
                  style={{
                    textAlign: "center",
                    fontWeight: "normal",
                    marginTop: "2em",
                  }}
                >
                  <Icon name="spinner" loading />
                  Laddar markkartering...
                </Header>
              }

              {markers &&
                <LeafletMap
                  {...mapOptions}
                  zoom={15}
                  maxZoom={20}
                  zoomSnap={0.1}
                >
                  {fields && fieldsInFile &&
                    Object.keys(fields).map(key => (
                      <Polygon
                        key={key}
                        positions={geoPointToPosition(fields[key].position)}
                        color={
                          fieldsInFile.includes(key)
                            ? "rgba(251, 189, 8, 0.9)"
                            : "lightgrey"
                        }
                        fillOpacity={0.2}
                        weight={3}
                      />
                    ))}

                  <TileLayer url={AREAL_TILE_URL} />
                  
                  {markers && markers.map((marker, idx) => (
                    <Circle
                      key={idx}
                      center={{
                        lat: marker.latitude,
                        lng: marker.longitude
                      }}
                      color={"rgba(251, 189, 8, 1.0)"}
                      interactive={false}
                      radius={14}
                      fill={true}
                      fillOpacity={0.75}
                    />
                  ))}
                </LeafletMap>
              }
          </div>
        
          <div 
            style={{
              marginLeft: "4em",
            }}
          >
            <Header 
              as="h3"
              style={{
                fontWeight: 500,
              }}              
            >
              Information om markkartering
            </Header>
            
            {fileName &&
              <div style={{ opacity: 0.7 }}>
                <strong>Filnamn</strong>: {fileName}
              </div>
            }

            {columnsInFile &&
              <div style={{ opacity: 0.7, whiteSpace: "pre-line" }}>
                <strong>Mätningar</strong>: {columnsInFile}
              </div>           
            }

            {noMarkers &&
              <div style={{ opacity: 0.7 }}>
                <strong>Antal markprover</strong>: {noMarkers.toFixed(0) + " st."}
              </div>                    
            }

            {!fileNeedsMoreInfo && 
              <div style={{ marginTop: "1em" }}>
                <div style={{ opacity: 0.7 }}>
                <strong>Karteringen gjordes år</strong>: {fileYear ? fileYear + "." : "."}
                </div>
                <div style={{ opacity: 0.7 }}>
                <strong>Skiften</strong>: {fieldNamesInFile ? fieldNamesInFile + "." : "okänt."}
                </div>   
              </div>
            }

            {fileNeedsMoreInfo && 
              <React.Fragment>
                <Header 
                  as="h3" 
                  style={{
                    marginBottom: 15,
                    fontWeight: 500,
                  }}
                >
                  Freja behöver hjälp med följande information
                </Header>
                      
                <Header 
                  as="h5" 
                  style={{
                    marginTop: 0,
                    marginBottom: 5,
                    fontWeight: 500,
                  }}
                >
                  Karteringen gjordes år
                </Header>
          
                <Dropdown
                  fluid
                  search
                  selection
                  name="newFileYear"
                  options={availableYearList}
                  closeOnChange={true}
                  value={newFileYear}
                  onChange={(event, data) => this.onChangeField(data)}
                  error={newFileYear === ''}
                />   
          
                <Header 
                  as="h5" 
                  style={{
                    marginBottom: 5,
                    fontWeight: 500,
                  }}
                >
                  Skiften
                </Header>  
                                
                <Dropdown
                  fluid
                  search
                  selection
                  multiple
                  name="newFileFields"
                  options={availableFields}
                  closeOnChange={true}
                  value={newFileFields}
                  error={newFileFields.length === 0}
                  onChange={(event, data) => this.onChangeField(data)}
                />
              </React.Fragment>
            }
          </div>
          </div>
        </Modal.Content>

        <Modal.Actions
          style={{ 
            display: "flex",
            justifyContent: "space-between"
          }}                 
        >
          {fileNeedsMoreInfo &&
            <Button  
              style={{ 
                backgroundColor: "#868D8E",
                color: "white",
              }}
              onClick={() => this.toggleShowDetailsModal()}
            >
              Stäng
            </Button>    
          } 

          {!fileNeedsMoreInfo && <div></div>}

          <Button  
            style={{ 
              backgroundColor: "#6B9D9F",
              color: "white",
            }}
            onClick={fileNeedsMoreInfo ? () => this.saveNewFieldInfo() : () => this.toggleShowDetailsModal()}
          >
            {fileNeedsMoreInfo ? "Spara" : "Stäng"}
          </Button>            
        </Modal.Actions>
      </Modal>
    );
  };

  getRemovedSegment = (key, fileName) => (
    <Segment
      key={key}
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1em",
      }}
    >
    <div
      style={{
        height: "100%",
        width: "10em",
        marginTop: "auto",
        marginBottom: "auto",          
        overflowY: "hidden",
      }}
    >
      <Image src={illustrationError} />
    </div>
      <div
        style={{
          height: "100%",
          width: "calc(100% - 10em)",
          marginLeft: "2em",
          marginRight: "2em",
          marginTop: "auto",
          marginBottom: "auto",              
          whiteSpace: "pre-line",
        }}          
      >
        <Header 
          as="h2" 
          style={{
            marginBottom: 5,
            fontWeight: 500,
          }}
        >
          Markkartering borttagen
        </Header>

        <div style={{ opacity: 0.7 }}>
          <strong>Filnamn</strong>: {fileName && fileName}
        </div>
      </div>   
    </Segment>  
  )

  getErrorSegment = (key, fileName, fileId) => (
    <Segment
      key={key}
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1em",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "10em",
          marginTop: "auto",
          marginBottom: "auto",          
          overflowY: "hidden",
        }}
      >
        <Image src={illustrationError} />
      </div>

      <div
        style={{
          height: "100%",
          width: "calc(100% - 10em)",
          marginLeft: "2em",
          marginRight: "2em",
          marginTop: "auto",
          marginBottom: "auto",              
          whiteSpace: "pre-line",
        }}          
      >
        <Header 
          as="h2" 
          style={{
            marginBottom: 5,
            fontWeight: 500,
          }}
        >
          Markkartering kunde inte läsas
        </Header>

        <div style={{ opacity: 0.7 }}>
          <strong>Filnamn</strong>: {fileName && fileName}
        </div>
      </div>
      <div
        style={{
          width: "13em",
        }}
      >
        <Button
          icon
          fluid
          labelPosition="left"
          size="small"
          style={{
            backgroundColor: "#868D8E",
            color: "white",
            marginTop: "1em",
          }}
          onClick={() => this.props.onRemoveUploadedFile(fileId)}
        >
          <Icon name="delete" />
          Ta bort
        </Button>
      </div>          
    </Segment>
  );

  getDoneSegment = (key, fileName, noMarkers, columnsInFile, fileYear, fieldNamesInFile, isProcessing, latestProcessingStep) => {

    let description = '';

    if (latestProcessingStep && latestProcessingStep === "preprocessing"){
      description = "Filtypen har identifierats och sorterats in i databasen."
    };

    if (latestProcessingStep && latestProcessingStep === "parsing"){
      description = "Filen har lästs in och väntar nu på att delas upp på varje skifte."
    };

    return (
  
      <Segment
        key={key}
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1em",
          paddingTop: "2em",
          paddingBottom: "2em",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "10em",
            marginTop: "auto",
            marginBottom: "auto",          
            overflowY: "hidden",
          }}
        >
          {!isProcessing && <Image src={illustrationDone} />}
          {isProcessing && <Loader style={{ marginLeft: "3em" }} active inline size="large" />}
        </div>

        <div
          style={{
            height: "100%",
            width: "calc(100% - 10em)",
            marginLeft: "2em",
            marginRight: "2em",
            marginTop: "auto",
            marginBottom: "auto",              
            whiteSpace: "pre-line",
          }}          
        >
          <Header 
            as="h2" 
            style={{
              marginBottom: 5,
              fontWeight: 500,
            }}
          >
            {!isProcessing ? "Markkartering uppladdad" : "Markkartering bearbetas"}
          </Header>
          
          {isProcessing &&
            <div style={{ opacity: 0.7 }}>
              <strong>Status</strong>: {description && description}
            </div> 
          }

          {fileName &&
              <div style={{ opacity: 0.7 }}>
                <strong>Filnamn</strong>: {fileName}
              </div>
            }

            {columnsInFile &&
              <div style={{ opacity: 0.7, whiteSpace: "pre-line" }}>
                <strong>Mätningar</strong>: {columnsInFile}
              </div>           
            }
            
            {noMarkers &&
              <div style={{ opacity: 0.7 }}>
                <strong>Antal markprover</strong>: {noMarkers.toFixed(0) + " st."}
              </div>                    
            }                  

          <div style={{ marginTop: "1em" }}>
            <div style={{ opacity: 0.7 }}>
            <strong>Karteringen gjordes år</strong>: {fileYear ? fileYear + "." : "okänt."}
            </div>
            <div style={{ opacity: 0.7 }}>
            <strong>Skiften</strong>: {fieldNamesInFile ? fieldNamesInFile + "." : "okänt."}
            </div>   
          </div>
        </div>

        <div
          style={{
            width: "13em",
          }}
        >
          <Button
            icon
            fluid
            labelPosition="left"
            size="small"
            style={{
              marginBottom: 8,
              backgroundColor: "#89A275",
              color: "white",
            }}
            onClick={() => this.toggleShowDetailsModal()}
          >
            <Icon name="expand" />
            Visa fil
          </Button>    
        </div>          
      </Segment>
    );
  };

  getMoreInformationSegment = (key, fileName, noMarkers, columnsInFile, fileId) => (
    <Segment
      key={key}
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1em",
        paddingTop: "2em",
        paddingBottom: "2em",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "10em",
          marginTop: "auto",
          marginBottom: "auto",          
          overflowY: "hidden",
        }}
      >
        <Image src={illustrationQuestion} />
      </div>

      <div
        style={{
          height: "100%",
          width: "calc(100% - 10em)",
          marginLeft: "2em",
          marginRight: "2em",
          marginTop: "auto",
          marginBottom: "auto",              
          whiteSpace: "pre-line",
        }}          
      >
        <Header 
          as="h2" 
          style={{
            marginBottom: 5,
            fontWeight: 500,
          }}
        >
          Freja behöver mer information
        </Header>

        <div style={{ opacity: 0.7 }}>
          <strong>Filnamn</strong>: {fileName && fileName}
        </div>
        <div style={{ opacity: 0.7, whiteSpace: "pre-line" }}>
          <strong>Mätningar</strong>: {columnsInFile ? columnsInFile + "." : " ."}
        </div>           
        <div style={{ opacity: 0.7 }}>
          <strong>Antal markprover</strong>: {noMarkers ? noMarkers.toFixed(0) + " st." : "okänt antal."}
        </div>                    
      </div>

      <div
          style={{
            width: "13em",
          }}
        >
          <Button
            icon
            fluid
            labelPosition="left"
            size="small"
            style={{
              marginBottom: 8,
              backgroundColor: "#6B9D9F",
              color: "white",
            }}
            onClick={() => this.onEditFile()}
          >
            <Icon name="edit" />
            Redigera
          </Button>    

          <Button
            icon
            fluid
            labelPosition="left"
            size="small"
            style={{
              backgroundColor: "#868D8E",
              color: "white",
              marginBottom: 0,
            }}
            onClick={() => this.props.onRemoveUploadedFile(fileId)}
          >
            <Icon name="delete" />
            Ta bort
          </Button>
        </div>        
    </Segment>
  );  

  getInQueueSegment = (key, fileName, latestProcessingStep) => {
    let description = 'Filen står i kö för att identifieras och sorteras.';

    if (latestProcessingStep && latestProcessingStep === "preprocessing"){
      description = "Filtypen har identifierats och sorterats in i databasen."
    };

    if (latestProcessingStep && latestProcessingStep === "parsing"){
      description = "Filen har lästs in och väntar nu på att delas upp på varje skifte."
    };

    return (
      <Segment
        key={key}
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1em",
          paddingTop: "2em",
          paddingBottom: "2em",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "10em",
            marginTop: "auto",
            marginBottom: "auto",          
            overflowY: "hidden",
          }}
        >
          <IconWithPlate
            size="6em"
            src={imgSoilMap}
          />
        </div>

        <div
          style={{
            height: "100%",
            width: "calc(100% - 10em)",
            marginLeft: "2em",
            marginRight: "2em",
            marginTop: "auto",
            marginBottom: "auto",              
            whiteSpace: "pre-line",
          }}          
        >
          <Header 
            as="h2" 
            style={{
              marginBottom: 5,
              fontWeight: 500,
            }}
          >
            Markkartering står i kö
          </Header>
          
          {description &&
            <div style={{ opacity: 0.7 }}>
              <strong>Status</strong>: {description && description}
            </div>
          } 

          <div style={{ opacity: 0.7 }}>
            <strong>Filnamn</strong>: {fileName && fileName}
          </div>               

          <div style={{ marginTop: "1em" }}>
            <div style={{ opacity: 0.7 }}>
              Filen står i kö för att bearbetas...
            </div>
          </div>
        </div>

        <div
          style={{
            width: "13em",
          }}
        >  
        </div>          
      </Segment>
    );
  };

  render() {
    const { 
      key,
      fileId,
      fields,
      uploadedFileData,
      filesToRemove,
    } = this.props;

    const { 
      showDetailsModal,
    } = this.state;

    let segmentData = ''

    let isProcessing = true;
    isProcessing = (uploadedFileData && uploadedFileData.status && ["need_more_info", "done"].includes(uploadedFileData.status)) ?
      false : isProcessing;

    let fileName = uploadedFileData && uploadedFileData.original_file_name;

    if (filesToRemove && filesToRemove.includes(fileId)) {
      segmentData = this.getRemovedSegment(key, fileName);
    }

    let isError = uploadedFileData && uploadedFileData.status && uploadedFileData.status === "error"; 

    if (isError && segmentData === '') {
      return (
        this.getErrorSegment(key, fileName, fileId)
      )
    };  

    let markers = uploadedFileData && uploadedFileData.samples_in_file;
    let noMarkers = markers && markers.length;
    let fieldsInFile = uploadedFileData && uploadedFileData.fields_in_file;
    let fileYear = uploadedFileData && uploadedFileData.year;

    let columnsInFile = uploadedFileData && uploadedFileData.columns_in_file;
    columnsInFile = columnsInFile && columnsInFile
      .filter((x) => Object.keys(FIELD_DATA_LAYERS.soilMapUploaded.captions).includes('soilMapUploaded_' + x))
      .map((x) => " " + FIELD_DATA_LAYERS.soilMapUploaded.captions['soilMapUploaded_' + x].toLowerCase()).toString()

    let isDone = uploadedFileData && uploadedFileData.status && uploadedFileData.status === "done";
    let fileNeedsMoreInfo = uploadedFileData && uploadedFileData.status && uploadedFileData.status === 'need_more_info';

    let fieldNamesInFile = fieldsInFile && fieldsInFile.map((x) => " " + fields[x].name).toString();
    fieldNamesInFile = fieldNamesInFile ? fieldNamesInFile : "Okänd"

    let latestProcessingStep = uploadedFileData && uploadedFileData.latest_processing_step && uploadedFileData.latest_processing_step;

    let detectedFieldPositions = [];
    fieldsInFile && fieldsInFile.forEach((fieldId) => {
      detectedFieldPositions = [...detectedFieldPositions, ...fields[fieldId].position]
    });
    
    detectedFieldPositions = detectedFieldPositions.length > 0 ? 
      detectedFieldPositions : 
      markers && markers.map((x) => ({latitude: x.latitude, longitude: x.longitude}));

    const mapOptions = getFieldMapOptions(
      { 
        position: detectedFieldPositions 
      },
      { 
        height: "30em",
        width: "30em",
        top: null,
      },
      {
        zoom: 8,
        boundsOptions: { padding: [10, 10] },
      }
    );

    if (isProcessing && !segmentData && uploadedFileData.status === "in_queue") {
      segmentData = this.getInQueueSegment(key, fileName, latestProcessingStep);
    };    

    if ((isDone || (isProcessing && segmentData === ''))) {
      segmentData = this.getDoneSegment(key, fileName, noMarkers, columnsInFile, fileYear, fieldNamesInFile, isProcessing, latestProcessingStep);
    };  

    if (fileNeedsMoreInfo && segmentData === '') {
      segmentData = this.getMoreInformationSegment(key, fileName, noMarkers, columnsInFile, fileId)
    };

    return (
      <React.Fragment>
        {showDetailsModal && this.getDetailsModal(fields, markers, mapOptions, fieldsInFile, fieldNamesInFile, fileName, fileYear, columnsInFile, noMarkers)}
        {segmentData && segmentData}
      </React.Fragment>
    );
  } 
}

export default UploadSoilMapsMapPreview;

