import React, { Component } from "react";

import { 
  Modal,
  Button,
} from "semantic-ui-react";

import { Scatter } from "react-chartjs-2";

import { getColorScales, getDataStats } from "@/components/fieldData/FieldDataColorScales";
import { correlation, computeCorrelationMap } from "@/helpers/stats";

import MapWithCircleMarkers from "@/components/map/MapWithCircleMarkers";

import MapGroupsWidget from "@/components/map/MapGroupsWidget";
import MapOverviewWidget from "@/components/map/MapOverviewWidget";

import { DATA_LAYERS } from "@/constants/viewLayersAnalysis";

class FieldDataViewerCorrelation extends Component {
  state = {
    selectedMarker: null,
    selectedGroupMarkers: null,
    showCorrelationGraph: false,
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.compareUpperGeoJsonData !== prevProps.compareUpperGeoJsonData) {
      this.setState({});
    };

    if (this.props.compareLowerGeoJsonData !== prevProps.compareLowerGeoJsonData) {
      this.setState({});
    };
  };

  toogleShowCorrelationGraph = () => {
    let currentValue = this.state.showCorrelationGraph;
    this.setState({ showCorrelationGraph: !currentValue });
  };

  computeCorrelationCoef = (markersUpper, keyUpper, markersLower, keyLower) => {
    let dataLeft = markersUpper && markersUpper.map((x) => x.properties && x.properties[keyUpper]);
    let dataRight = markersLower && markersLower.map((x) => x.properties && x.properties[keyLower]);

    return dataLeft && dataRight && correlation(dataLeft, dataRight);
  };

  selectMarker = (marker) => {
    const {
      selectedMarker,
    } = this.props;

    
      if ((selectedMarker && selectedMarker.length === 1 && selectedMarker[0] === marker)) {

        this.setState({
          selectedMarker: null,
        });
  
        this.props.setParentState({
          selectedGroup: null,
          selectedGroupPlotName: null,
        })

        if (this.props.selectMarker) {
          this.props.selectMarker(null)
        };

      } else {
        this.setState({
          selectedMarker: [marker],
        });
  
        this.props.setParentState({
          selectedGroup: null,
          selectedGroupPlotName: null,
        })

        if (this.props.selectMarker) {
          this.props.selectMarker([marker]);
        };
    };
  };

  setSelectedGroup = (newIndex, group) => {
    let selectedGroupMarkers = this.props.selectedGroupMarkers;
    
    if (selectedGroupMarkers && selectedGroupMarkers.length > 0 && selectedGroupMarkers[0] === group.sampleIndices[0]) {

      this.props.setContainerState({
        selectedGroup: null,
        selectedGroupPlotName: null,
      });
  
      this.setState({
        selectedGroupMarkers: null,
      });
  
      if (this.props.selectGroupMarkers) {
        this.props.selectGroupMarkers(null)
      };

    } else {
      this.props.setContainerState({ 
        selectedGroup: newIndex,
        selectedGroupPlotName: this.props.plotName,
      });
  
      this.setState({
        selectedGroupMarkers: group.sampleIndices,
      });
  
      if (this.props.selectGroupMarkers) {
        this.props.selectGroupMarkers(group.sampleIndices)
      };
    }
  };  
 

  render() {
    const {
      compareUpperGeoJsonData,
      compareLowerGeoJsonData,
      field,
      layerId,
      showArealMap,
      markerScale,
      selectedGroup,
      compareUpperLeftLayer,
      compareLowerLeftLayer,
    } = this.props;

    const {
      showCorrelationGraph,
    } = this.state;
    
    let selectedMarker = this.props.selectedMarker ? this.props.selectedMarker : this.state.selectedMarker;
    let selectedGroupMarkers = this.props.selectedGroupMarkers ? this.props.selectedGroupMarkers : this.state.selectedGroupMarkers;

    let fieldDataLayerUpper = compareUpperLeftLayer && DATA_LAYERS[compareUpperLeftLayer.split("_")[0]];
    let captionUpper = fieldDataLayerUpper && fieldDataLayerUpper.captions[compareUpperLeftLayer];
    let geoJsonFieldKeyUpper = fieldDataLayerUpper && fieldDataLayerUpper.geoJsonFieldKey[compareUpperLeftLayer];

    let fieldDataLayerLower = compareLowerLeftLayer && DATA_LAYERS[compareLowerLeftLayer.split("_")[0]];
    let captionLower = fieldDataLayerLower && fieldDataLayerLower.captions[compareLowerLeftLayer];
    let geoJsonFieldKeyLower = fieldDataLayerLower && fieldDataLayerLower.geoJsonFieldKey[compareLowerLeftLayer];

    let allMarkersUpper = compareUpperGeoJsonData && compareUpperGeoJsonData.features;

    allMarkersUpper && allMarkersUpper.sort(function (a, b) {   
      return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
    });    

    let allMarkersLower = compareLowerGeoJsonData && compareLowerGeoJsonData.features;

    allMarkersLower && allMarkersLower.sort(function (a, b) {   
      return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
    });  

    let correlationMap = computeCorrelationMap(allMarkersUpper, geoJsonFieldKeyUpper, allMarkersLower, geoJsonFieldKeyLower);
    correlationMap = correlationMap && correlationMap.map((x, idx) => ({...x, idx: idx}));

    let correlationCoef = this.computeCorrelationCoef(allMarkersUpper, geoJsonFieldKeyUpper, allMarkersLower, geoJsonFieldKeyLower);

    let colorScales = correlationMap && getColorScales("correlation", {features: correlationMap}, "correlation", "e", 5, null);
    let dataStats = colorScales && correlationMap && getDataStats("correlation", colorScales, {features: correlationMap}, "correlation");

    let filteredSelectedMarkers = correlationMap && correlationMap;
    if (filteredSelectedMarkers && selectedGroupMarkers && selectedGroupMarkers.length > 1) {
      filteredSelectedMarkers = filteredSelectedMarkers.filter((x, idx) => selectedGroupMarkers.includes(idx));
    };

    const plotData = {
      labels: ['Scatter'],
      datasets: [
        {
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          pointBorderColor: '#6B9D9F',
          pointBackgroundColor: '#BDCDCE',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          data: allMarkersUpper && allMarkersLower && allMarkersUpper.map((x, idx) => (
            { 
              x: x.properties && x.properties[geoJsonFieldKeyUpper], 
              y: allMarkersLower[idx] && allMarkersLower[idx].properties && allMarkersLower[idx].properties[geoJsonFieldKeyLower] 
            } 
          )),
        }
      ]
    };

    let dataLayerMissing = !compareUpperLeftLayer || !compareLowerLeftLayer;

    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
          }}
        >
          <Modal 
            open={showCorrelationGraph} 
            style={{
              padding: "1em",
            }}
          >
            <Modal.Header
              style={{ 
                fontWeight: 500,
              }}
            >
              Samband
            </Modal.Header>

            <Modal.Content>
              <p 
                style={{ 
                  paddingBottom: "1em",
                  fontSize: "110%",
                }}>

                Här kan du se alla datapunkter på kartan och hur sambandet mellan dem ser ut. 
              
              </p>

              <article
                className="canvas-container"
                style={{
                  width: "100%",
                  height: "50vh",
                  background: "transparent",
                  padding: "1em"
                }}
              >
                <Scatter 
                  data={plotData} 
                  options={{ 
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                        }
                    },
                    animation: {
                        duration: 0
                    },
                    legend: {
                      display: false,
                      position: "bottom",
                      labels: {
                        fontSize: 14,
                        fontColor: 'black',
                        padding: 20,
                      }
                    },
                    scales: {
                      yAxes: [{
                        ticks: {
                          fontSize: 14,
                          fontColor: 'lightgrey',
                        },
                        scaleLabel: {
                          display: true,
                          fontSize: 14,
                          fontColor: 'black',
                          labelString: captionLower,
                        }
                      }],
                      xAxes: [{
                        ticks: {
                          fontSize: 14,
                          fontColor: 'lightgrey',
                        },
                        scaleLabel: {
                          display: true,
                          fontSize: 14,
                          fontColor: 'black',
                          labelString: captionUpper,
                        }
                      }],           
                    }
                  }} 
                />
              </article>
            </Modal.Content>

            <Modal.Actions
              style={{ 
                display: "flex",
                justifyContent: "space-between"
              }}          
            >
              <div />
              <Button 
                style={{ 
                  backgroundColor: "#868D8E", 
                  color: "white",
                }}
                onClick={() => this.toogleShowCorrelationGraph()}
              >
                Stäng
              </Button>   
            </Modal.Actions>             
          </Modal>

          <MapWithCircleMarkers
            field={field && field}
            markers={!dataLayerMissing && filteredSelectedMarkers && filteredSelectedMarkers}
            allMarkers={!dataLayerMissing && correlationMap && correlationMap}
            dataStats={dataStats}
            colorScale={colorScales && colorScales.colorScale}
            markerScale={markerScale}
            onMarkerClick={this.selectMarker}
            selectedMarker={selectedMarker}            
            geoJsonFieldKey={"correlation"}
            showMissingData={true}
            lockedMap={true}
            showArealMap={showArealMap}
            mapStyle={this.props.mapStyle}
            mapOptions={this.props.mapOptions}
            loadingData={!dataLayerMissing && (!correlationMap || correlationMap.length === 0)}
            placeholderText={"Välj datalager för båda kartorna till vänster för att se tolkning av sambandet mellan dem."}
          >
            <MapGroupsWidget
              mode="compare2correlationPlot"
              field={field && field}
              layer="correlation"
              layerId={layerId}
              geoJsonFieldKey="correlation"
              selectedGroup={selectedGroup}
              onSelectGroup={this.setSelectedGroup}              
              markers={correlationMap && correlationMap.features}
              allMarkers={correlationMap && correlationMap.features}
              dataStats={dataStats}
              colorScaleType="e"
              numberOfGroups={3}
            />

              <MapOverviewWidget
                mode={"compare"}
                layerName="Tolkning av samband"
                hideInfo={true}
                hideMarkers={true}
                hideWeather={true}
                showCorrelation={false}
                correlationCoef={correlationCoef}
                toogleShowCorrelationGraph={this.toogleShowCorrelationGraph}
              />                                    
          </MapWithCircleMarkers>        
        </div>
        

      </React.Fragment>
    );
  }
}

export default FieldDataViewerCorrelation;
