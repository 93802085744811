import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Dropdown, 
  Menu, 
  Image,
} from "semantic-ui-react";

import iconHelp from "@/assets/icons/menuSecondary/help.svg";

import ContactSupportModal from "@/components/modals/ContactSupportModal";
import TutorialModal from "@/components/modals/TutorialModal";

const INITIAL_STATE = {};

class MenuHelp extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };  

  render() {
    const {
      style,
    } = this.props;

    let openUrl = this.props.view !== "home";

    return (
      <Dropdown
        icon={null}
        closeOnChange={true}
        style={{
          ...style,
        }}
        trigger={
          <Menu.Item
            className="menuSecondaryItem"
              style={{
                marginTop: 0,
                height: "55px",
              }}
            >
            <Image src={iconHelp} style={{ height: 22.5 }} />
          </Menu.Item>
        }
      >
        <Dropdown.Menu>

          <Dropdown.Item
            href={openUrl ? "/help" + (this.props.view ? ("/docs/" + this.props.view) : "") : null}
            onClick={!openUrl ? () => this.props.history.push("/help" + (this.props.view ? ("/docs/" + this.props.view) : "")) : () => {}}
            target="_blank" 
            rel="noopener noreferrer"
          >
            Hjälpcenter
            <div style={{ color: "grey", marginTop: 3, fontSize: "90%" }}>
              Få hjälp med vanliga problem
            </div>
          </Dropdown.Item>   
          
          <Dropdown.Item
            href="https://www.facebook.com/groups/361010158354046"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Facebook-grupp
            <div style={{ color: "grey", marginTop: 3, fontSize: "90%" }}>
              Freja-användare tipsar varandra
            </div>
          </Dropdown.Item>             
        
          <TutorialModal
            isAdminUser={this.props.isAdminUser}
            db={this.props.db}
            getUser={this.props.getUser}
            tutorialName={this.props.tutorialName}
            trigger={
              <Dropdown.Item>
                Visa startguiden
                <div style={{ color: "grey", marginTop: 3, fontSize: "90%" }}>
                  Få en rundtur av Freja
                </div>
              </Dropdown.Item>              
            }
          />         

          <ContactSupportModal
            db={this.props.db}
            userId={this.props.userId}
            view={this.props.view}
            params={this.props.params}
            trigger={
              <Dropdown.Item>
                Kontakta support
                <div style={{ color: "grey", marginTop: 3, fontSize: "90%" }}>
                  Få personlig hjälp från vårt team
                </div>
              </Dropdown.Item>  
            }
          />   
          
          <Dropdown.Item
            href={openUrl ? "/help/changelog/" : null}
            onClick={!openUrl ? () => this.props.history.push("/help/changelog/") : () => {}}
            target="_blank" 
            rel="noopener noreferrer"
          >
            Nyheter
            <div style={{ color: "grey", marginTop: 3, fontSize: "90%" }}>
              Se vad som är nytt i Freja
            </div>
          </Dropdown.Item>  

        </Dropdown.Menu>
      </Dropdown>       
    );
  }
}

export default withRouter(MenuHelp);

