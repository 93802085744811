import React, { Component } from "react";

import {
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import {
  Container,
  Segment,
  Header,
} from "semantic-ui-react";

import { Calendar, momentLocalizer } from 'react-big-calendar';

import LoaderModal from "@/components/modals/LoaderModal";

import RemindersWidget from "@/components/reminders/RemindersWidget";

import moment from 'moment';

const localizer = momentLocalizer(moment);

const INITIAL_STATE = {};

class RemindersPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {
    const {
      db,
      getUser,
      userId,
    } = this.props;

    const weatherData = db && db.fieldsWeather;

    let hasLoadedData = isLoaded(db) && !isEmpty(db) && weatherData;

    if (hasLoadedData) {
      return (
        <Container
          style={{
            paddingTop: "50px",
            backgroundColor: "#f7f4f0",
            overflow: "hidden",
            width: "100vw",
          }}
        >
          <div
            style={{
              padding: 20,
              paddingTop: 0,
              marginLeft: "5px",
              marginRight: "15px",
            }}
          >

            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "67%" }}>
                <Segment
                  style={{
                    padding: "2em",
                    fontWeight: "normal",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1em",
                    }}
                  >
                    <Header
                      as="h3"
                      style={{
                        paddingTop: "0",
                        fontWeight: 500,
                        color: "grey",
                      }}
                    >
                      Kalender
                    </Header>

                    <div />
                  </div>

                  <Calendar
                    localizer={localizer}
                    events={[]}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: "60vh" }}
                    views={['month', 'week', 'agenda']}
                    culture="sv"
                  />

                </Segment>
              </div>

              <div style={{ width: "32%" }}>
                <RemindersWidget
                  db={db}
                  userId={userId}
                  getUser={getUser}
                  noRemindersToShow={15}
                />
              </div>
            </div>
          </div>
        </Container>
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      );
    }
  }
}

export default RemindersPage;
