import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";

import {
  firestoreConnect,
  isLoaded,
} from "react-redux-firebase";

import { 
  Button,
  Table,
  Icon,
  Menu,
} from "semantic-ui-react";

import moment from 'moment';

const USERS_PER_PAGE = 10;

const INITIAL_STATE = {
  activeView: "impersonate",
  currentSection: 0,
};

class AdvisorPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  switchUser = (newUserId) => {
    newUserId !== this.props.userId && this.props.history.push("/");
    newUserId !== this.props.userId && this.props.switchUser(newUserId);
  };

  getImpersonateUsers = () => {
    const {
      userList,
    } = this.props;

    const {
      currentSection,
    } = this.state;

    const db = this.getDb();

    let noUsers = userList && userList.length;
    let noSections = noUsers && Math.ceil(noUsers / USERS_PER_PAGE);

    let fullUserList = db && isLoaded(db) && userList && userList.map((x) => {
      
      let userProfile = db && db["profile_" + x];
      let contactPerson = userProfile && userProfile.contact && userProfile.contact.first_name ? userProfile.contact.first_name : "";
      contactPerson = userProfile && userProfile.contact && userProfile.contact.last_name ? contactPerson + " " + userProfile.contact.last_name : contactPerson;

      return (
        {
          id: x,
          farmName: userProfile && userProfile.farm && userProfile.farm.name,
          contactPerson,
          latestLoginDesktop: userProfile && userProfile.log && userProfile.log.date_latest_login_desktop,
          latestLoginMobile: userProfile && userProfile.log && userProfile.log.date_latest_login_mobile,
        }
      )
    });

    fullUserList = fullUserList && fullUserList.filter(x => {
      return (x.farmName || x.contactPerson)
    });    

    fullUserList = fullUserList && fullUserList.sort(function (a, b) {
      if (a.farmName > b.farmName) {
          return 1;
      }
      if (b.farmName > a.farmName) {
          return -1;
      }
      return 0;
    });

    let filteredUserList = fullUserList && fullUserList.filter((x, idx) => idx >= currentSection * USERS_PER_PAGE && idx < (currentSection + 1) * USERS_PER_PAGE);    

    return (
      <Table celled padded selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Gårdsnamn</Table.HeaderCell>
            <Table.HeaderCell>Kontaktperson</Table.HeaderCell>
            <Table.HeaderCell>Senast inloggad (desktop)</Table.HeaderCell>
            <Table.HeaderCell>Senast inloggad (mobil)</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredUserList && filteredUserList.map((x) => (
            <Table.Row
              key={"impersonateUserRow_" + x.id}
            >
              <Table.Cell>{x.farmName ? x.farmName : "-"}</Table.Cell>
              <Table.Cell>{x.contactPerson ? x.contactPerson : "-"}</Table.Cell>
              <Table.Cell>{x.latestLoginDesktop ? " " + moment(x.latestLoginDesktop.toDate()).format("YYYY-MM-DD") : "-"}</Table.Cell>
              <Table.Cell>{x.latestLoginMobile ? " " + moment(x.latestLoginMobile.toDate()).format("YYYY-MM-DD") : "-"}</Table.Cell>
              <Table.Cell >
                <Button
                  icon
                  labelPosition="left"
                  primary                 
                  onClick={() => this.switchUser(x.id)}
                  size="small"
                >
                  <Icon name="sign-in" />
                  Öppna gård
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='8'>
              <Menu floated='right' pagination>
                <Menu.Item 
                  as='a' 
                  icon
                  onClick={() => this.setState({ currentSection: Math.max(0, currentSection - 1)})}
                >
                  <Icon name='chevron left' />
                </Menu.Item>
                {noSections && Array.from(Array(noSections).keys()).map((x) => (
                  <Menu.Item 
                    active={currentSection === x}
                    key={"Menu_item_" + x.toFixed(0)}
                    as='a'
                    onClick={() => this.setState({ currentSection: x })}
                  >
                    {x + 1}
                  </Menu.Item>
                ))}
                <Menu.Item 
                  as='a' 
                  icon
                  onClick={() => this.setState({ currentSection: Math.min(noSections - 1, currentSection + 1)})}
                >
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>             
      </Table>
    )
  };

  render() {  
    const {
      activeView,
    } = this.state;

    return (
      <React.Fragment>      
        <div
          style={{
            marginTop: 70,
            marginLeft: "15%",
            width: "70%",
            paddingBottom: 30,
          }}
        >
          {activeView === "impersonate" && this.getImpersonateUsers()}  
        </div>
      </React.Fragment>
    );
  }
};

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => {
    let output = [];

    props && props.userList && props.userList.forEach((x) => {
      output = [...output, 
        { 
          collection: 'users', 
          doc: `${x}`, 
          subcollections: [
            { collection: "profile" },
          ],
          storeAs: 'profile_' + x
        },
      ]
    })

    return output;
  }),
  withFirestoreData,
  withRouter,
)(AdvisorPage);
