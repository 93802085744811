import React, { Component } from "react";

import { 
  Menu, 
  Button,
  Icon, 
} from "semantic-ui-react";

class GuideNavigation extends Component {
  
  render() {
    const {
      menuItems,
    } = this.props;

    return (
      <div
        style={{
          backgroundColor: "#D6D9D9",
          position: "absolute",
          left: 85,
          height: "55px",
          width: "calc(100% - 85px)",
          bottom: 0,
          paddingBottom: 0,
          marginTop: 10,
          paddingLeft: 0,
          zIndex: 1000,
        }}
      >
        <Menu
          pointing
          secondary
          fluid
          style={{
            paddingTop: 3,
            margin: 0,
          }}
          size="large"
        >
          <Menu.Menu position='left'>      
            <Menu.Item>
              {menuItems && menuItems.previous && 
                menuItems.previous
                .filter((x) => !(typeof(x.hidden) === "boolean" && x.hidden === true))
                .map((x, index) => (
                  <Button
                    key={"backButton_" + index}
                    icon={index === 0 ? true : false}
                    labelPosition={index === 0 ? "left" : null}
                    style={{ 
                      backgroundColor: "#767676", 
                      color: "white",
                    }}
                    disabled={x.disabled ? x.disabled : false}
                    onClick={() => x.onClick()}
                  >
                    {index === 0 && <Icon name="chevron left" />}
                    {x.caption}
                  </Button>
                ))
              }
            </Menu.Item>
          </Menu.Menu>

          <Menu.Menu position='right'>      
            <Menu.Item>
              {menuItems && menuItems.next && 
                menuItems.next
                .filter((x) => !(typeof(x.hidden) === "boolean" && x.hidden === true))
                .map((x, index) => (
                  <Button
                    key={"nextButton_" + index}
                    icon={index === (menuItems.next.length - 1) ? true : false}
                    labelPosition={index === (menuItems.next.length - 1) ? "right" : null}
                    style={{ 
                      backgroundColor: "#6B9D9F", 
                      color: "white",
                      marginLeft: 10,
                    }}
                    disabled={x.disabled ? x.disabled : false}
                    onClick={() => x.onClick()}
                  >
                    {index === (menuItems.next.length - 1) &&
                      <Icon 
                        name={(typeof(x.loading) === "boolean" && x.loading === true) ? "spinner" : "chevron right"} 
                        loading={typeof(x.loading) === "boolean" ? x.loading : false} 
                      />
                    }

                    {x.caption}
                  </Button>
                ))
              }              
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

export default GuideNavigation;
