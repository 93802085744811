import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Menu, 
  Icon,  
  Image,
  Button,
} from "semantic-ui-react";

import FieldCartoon from "@/components/misc/FieldCartoon";
import MenuSecondary from "@/components/menus/MenuSecondary";

import imgFarm from "@/assets/icons/menuPrimary/my-farm.svg"

import "./style.css";

class GuidesTopMenu extends Component {
  
  render() {
    const {
      db,
      fieldId,
      dataType,
      activeView,
    } = this.props;
    
    let fieldDb = db && db.fields && db.fields[fieldId];
    
    let fieldCartoon = fieldDb && fieldDb.position && 
      <div 
        className="fieldCartoon"
        style={{
          backgroundColor: "#F8EED0",
          borderRadius: 5,
          width: "2.5em",
          height: "2.5em",
          marginRight: "0.4em",
        }}
      >
      <FieldCartoon 
        style={{
          display: "inline-block",
          margin: 0,
          padding: 0,            
          marginTop: "0.25em",
          marginLeft: "0em",
          width: "2em", 
          height: "2em",
        }}
        position={fieldDb.position} 
        stroke="#e0ba8c" 
        color="white"
      />
      </div>
      
    let farmCartoon = 
      <div 
        className="fieldCartoon"
        style={{
          backgroundColor: "#F8EED0",
          borderRadius: 5,
          width: "2.5em",
          height: "2.5em",
          marginRight: "0.4em",
        }}
      >
        <Image 
          style={{ 
            display: "inline-block",
            margin: 0,
            padding: 0,            
            marginTop: "0.25em",
            marginLeft: "0em",
            width: "2em", 
            height: "1.75em",
            filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)"
          }}
          src={imgFarm} 
        />    
      </div>
    
    fieldCartoon = (fieldId && fieldId === "farm") ? farmCartoon : fieldCartoon;
    
    let menuItems = [
      {
        name: 'selectField',
        href: '',
        onClick: activeView === "selectLayer" && this.props.onClickSelectField,
        caption: (activeView !== "selectField" && fieldDb) ? fieldDb.name + " (" + fieldDb.field_size.toFixed(0) + " ha)" : 'Välj skifte',
        img: fieldCartoon,
        disabled: activeView === "compare",
        active: activeView === "selectField",
      },     
    ];

    // menuItems = activeView !== "selectLayer" ? menuItems :
    // [...menuItems,
    //   {
    //     name: 'selectLayer',
    //     href: '',
    //     onClick: '',
    //     caption: "Välj datatyp",
    //     img: '',
    //     active: activeView === "selectLayer",
    //   }, 
    // ];    

    return (
      <MenuSecondary
        userId={this.props.userId}
        isAdminUser={this.props.isAdminUser}
        trueUserId={this.props.trueUserId}
        params={this.props.match.params}
        tutorialName={dataType === "guides" ? "DesktopGuidesFirstLaunchV1" : "DesktopCollectFirstLaunchV1"}
        rightMenuItems={fieldId && 
          <Menu.Item name='add'>
            <Button
              primary
              icon
              labelPosition="left"
              size='large' 
              onClick={this.props.onClickAdd}
            >
              <Icon name="add" />
              Lägg till
            </Button>
          </Menu.Item>
        }
      >
        {menuItems.map((x, index) => {
          return (
            <Menu.Item
              className="menuSecondaryItem"
              key={"menuItem_" + x.name}
              disabled={x.disabled}
              active={x.active}
              onClick={() => x.href ? this.props.history.push(x.href) : x.onClick && x.onClick(x.name)}
              style={{
                marginTop: 0,
                height: "55px",
                // marginRight: "1em",
                // paddingLeft: "3em",
                // paddingRight: "3em",                    
                background: x.active ? "rgba(234, 204, 116, 0.25)" : "transparent",
                borderBottom: x.active ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                fontWeight: x.active ? 500 : null,
                width: "25em"
              }}
            >
              {activeView !== "selectField" && x.img && x.img}
              
              {x.caption}

              {activeView === "selectLayer" && 
                x.name === "selectField" &&
                <Icon name="caret down" size="small" />
              } 
              
              {activeView === "selectField" &&
                x.name === "selectField" &&
                <Icon name="caret up" size="small" />
              } 
            </Menu.Item>              
          )
        })}
      </MenuSecondary>
    );
  }
}

export default withRouter(GuidesTopMenu);
