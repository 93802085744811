import React, { Component } from "react";

import { 
  Header, 
  Segment,
  Image, 
} from "semantic-ui-react";

import Background from "@/assets/backgrounds/bruno-martins-442354-unsplash-small.jpg";
import Illustration from "@/assets/illustrations/onboarding_guide_1.png";

class NoAccessModal extends Component {
  
  render() {
    const { 
     userPlanType,
    } = this.props;

    return (
      <div 
        style={{
          height: "100vh",
          paddingLeft: "20%",
          paddingRight: "20%",
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "hidden"          
        }}
      >
        <div
          style={{
            height: "60%",
            marginTop: "20vh",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}
        >
          <Segment
            style={{
              width: "50%",
              height: "100%",
              padding: "2em",
            }}
          >
            <div
              style={{
                width: "67%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                fontSize: "110%",
              }}            
            >
              {userPlanType === "demo" &&
                <p>
                  Din demoperiod för Freja har tagit slut. 
                  Vi hoppas att du har gillat att använda din digitala assistent.
                  Inom kort kommer du att bli kontaktad av en säljare för uppföljning. <br/> <br/>
                  Om du redan idag vill teckna ett abonemang hos AgriOpt, hör av dig till vår support på <a href="mailto:support@agriopt.se">support@agriopt.se</a>.
                </p>
              }
              
              {userPlanType !== "demo" &&
                <p>
                  Ditt abonemang för Freja har tagit slut. 
                  Vi hoppas att du har gillat att använda din digitala assistent.
                  Inom kort kommer du att bli kontaktad av en säljare för uppföljning. <br/> <br/>
                  Om du redan idag vill förlänga ditt abonemang hos AgriOpt, hör av dig till vår support på <a href="mailto:support@agriopt.se">support@agriopt.se</a>.
                </p>
              }    
            
              <p>Med vänliga hälsningar, Teamet bakom Freja</p>
            </div> 
          </Segment>

          <Segment
            style={{
              width: "50%",
              height: "90%",
              padding: "2em",
              marginTop: "auto",
              marginBottom: "auto",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: "#f7f4f0",
            }}
          > 
            <Image 
              style={{
                height: "70%",
                transition: "height 0.3s",
              }}            
              centered 
              src={Illustration}             
            />

              <Header
                as="h2"
                textAlign="center"
                color="grey"
                style={{
                  fontWeight: 500,
                }}
              >
                Du har tyvärr inte tillgång till Freja
              </Header>
          </Segment>
        </div>
      </div>
    );
  }
}

export default NoAccessModal;
