import React, { Component } from "react";

import { 
  Button,
  Header, 
  Segment,
  Image, 
} from "semantic-ui-react";

import Background from "@/assets/backgrounds/bruno-martins-442354-unsplash-small.jpg";
import Illustration from "@/assets/illustrations/onboarding_guide_1.png";

class NoAccessToBetaModal extends Component {
  
  render() {

    return (
      <div 
        style={{
          height: "100vh",
          paddingLeft: "20%",
          paddingRight: "20%",
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "hidden"          
        }}
      >
        <div
          style={{
            height: "60%",
            marginTop: "20vh",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}
        >
          <Segment
            style={{
              width: "50%",
              height: "100%",
              padding: "2em",
            }}
          >
            <div
              style={{
                width: "67%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                fontSize: "110%",
              }}            
            >
              <p>
                Just nu är Frejas miljö för användartester stängd eftersom vi jobbar vidare med nästa version av Freja. Ett stort tack för din medverkan i användartesterna.

                <br/> <br/>
                Om du har några förslag, idéer eller frågor är du som alltid välkommen att maila vår support på <a href="mailto:support@agriopt.se">support@agriopt.se</a>.
              </p>

              <p>Med vänliga hälsningar, Teamet bakom Freja</p>

              <Button
                primary
                style={{
                  display: "block",
                  marginTop: "2em",
                  marginBottom: "3em"
                }}
                href="https://freja.agriopt.se/"
                rel="noopener noreferrer"
                size="large"                
              >
                Öppna Freja
              </Button>
              
              <strong>Tips</strong>: Om du är nyfiken på de allra senaste funktionerna i Freja kan du aktivera betaläget under inställningar.
            </div> 
          </Segment>

          <Segment
            style={{
              width: "50%",
              height: "90%",
              padding: "2em",
              marginTop: "auto",
              marginBottom: "auto",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: "#f7f4f0",
            }}
          > 
            <Image 
              style={{
                height: "70%",
                transition: "height 0.3s",
              }}            
              centered 
              src={Illustration}             
            />

              <Header
                as="h2"
                textAlign="center"
                color="grey"
                style={{
                  fontWeight: 500,
                }}
              >
                Testmiljön är stängd
              </Header>
          </Segment>
        </div>
      </div>
    );
  }
}

export default NoAccessToBetaModal;
