import React, { Component } from "react";

import { 
  Image,
} from "semantic-ui-react";
class IconWithPlate extends Component {
  
  render() {
    const {
      size,
      src,
      filter,
      plateBackground,
      style,
      fitted,
      inline,
    } = this.props;

    return (
      <div
        style={{          
          width: size ? size : "7vw",
          height: size ? size : "7vw",
          margin: inline ? null : "auto",
          borderRadius: "50%",
          backgroundColor: plateBackground ? plateBackground : "#f7ebcd",
          ...style,
        }}
      >
        {src &&
          <Image
            style={{
              display: "block",
              width: fitted ? "80%" : "66%",
              height: "100%",
              margin: "auto",
              filter: filter ? filter : "brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(7315%) hue-rotate(341deg) brightness(109%) saturate(80%)",
            }}
            src={src}
          />
        }
      </div>
    )
  }
}

export default IconWithPlate;
