import React, { Component } from "react";

import { 
  Segment,
  Image, 
  Loader,
} from "semantic-ui-react";

import Background from "@/assets/backgrounds/bruno-martins-442354-unsplash-small.jpg";
import Illustration from "@/assets/illustrations/onboarding_guide_1.png";

class LoadingScreen extends Component {
  
  render() {
  
    return (
      <div 
        style={{
          height: "100vh",
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "hidden"          
        }}
      >
        <div
          style={{
            height: "60vh",
            width: "120vh",
            marginTop: "20vh",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}
        >
          <Segment
            style={{
              width: "50%",
              height: "100%",
              padding: "2em",
            }}
          >
            <div
              style={{
                width: "67%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}            
            >
              <Loader 
                active 
                indeterminate 
                size="massive"
              >
                <div
                   style={{
                     fontSize: "80%", 
                     marginTop: "0.5em",
                     color: "grey",
                    }}
                  >
                  Öppnar Freja...
                </div>
              </Loader> 
            </div>        
          </Segment>

          <Segment
            style={{
              width: "50%",
              height: "90%",
              padding: "2em",
              marginTop: "auto",
              marginBottom: "auto",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: "#f7f4f0",
            }}
          > 
            <Image 
              style={{
                height: "80%",
                marginTop: "5%",
                marginBottom: "15%",
              }}            
              centered 
              src={Illustration} 
            />
          </Segment>
        </div>
      </div>
    );
  }
}

export default LoadingScreen;

