import React, { Component } from "react";

import { 
  Header, 
  Segment,
  Dropdown,
  Button,
  Input,
  Modal,
} from "semantic-ui-react";

import { toast } from "react-toastify";
import { DateInput } from 'semantic-ui-calendar-react';

import { cloudFunctions } from "@";
import { CROP_CODES, CROP_VARIETIES, CROP_QUALITY } from "@/constants/cropCodes";

class FieldManagementSeeding extends Component {
  state = {   
    mode: null,
    editYear: null,
    editCropCode: null,
    editCropQuality: null,
    editCropVariety: null,
    editCropSeedDate: null,   
    editCropSeedAmount: '', 
    editExpectedHarvestYield: '', 
    yearToRemove: null,
  };

  updateSeedingInformation = (year, payload) => {
    const {
      db,
      userId,
      fieldId,
      getUser,
    } = this.props;

    let dbRef = getUser().collection("fieldsInfo").doc(fieldId);
    let field = db && db.fields[fieldId] && db.fields[fieldId];
    let fieldInfo = db && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];

    let currentKeysInFieldInfo = fieldInfo && Object.keys(fieldInfo).map((x) => x);

    // Iterate over all fields in payload
    Object.keys(payload).filter((key) => payload[key]).forEach((key) => {
      if (currentKeysInFieldInfo && currentKeysInFieldInfo.includes(key)) {
        dbRef.update({ [key + "." + year]: payload[key] });
      } else {
        dbRef.update({ [key]: { [year]: payload[key] } });
      };
    });

    let text = "Freja har uppdaterat informationen om sådden för: " + year + " för skiftet: " + (field && field.name && field.name) + ".";

    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });

    payload && Object.keys(payload) && Object.keys(payload).includes("crop_code") &&
      cloudFunctions.httpsCallable('updatedCropCodes')(
        {
          userId: userId,
          fieldId: fieldId,
          year: year,
        }
      );
  };

  onChangeField = (data, onlyNumbers=false) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.setState({
      [data.name]: value,
    }); 
  }; 

  onResetState = () => {
    this.setState({
      mode: null,
      editYear: null,
      editCropCode: null,
      editCropQuality: null,
      editCropSeedDate: null,
      editCropSeedAmount: '',
      editCropVariety: null,
      editExpectedHarvestYield: '',
    });
  }

  onToogleEditSeeding = (year, mode) => {
    const {
      db,
      fieldId,
    } = this.props;

    const {
      editYear,
      editCropCode,
      editCropQuality,
      editCropSeedDate,
      editCropSeedAmount,
      editCropVariety,
      editExpectedHarvestYield,
    } = this.state;

    let fieldInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId];

    if (!year) {
      this.updateSeedingInformation(editYear,
        {
          crop_code: editCropCode,
          crop_quality: editCropQuality,
          crop_variety: editCropVariety,
          seeding_date: editCropSeedDate,
          seeding_amount: editCropSeedAmount,
          expected_harvest_yields: editExpectedHarvestYield
      });      

      this.onResetState()

    } else {

      this.setState({
        mode: mode ? mode : "edit",
        editYear: year,
        editCropCode: fieldInfo && fieldInfo.crop_code && fieldInfo.crop_code[year] ? fieldInfo.crop_code[year] : '0',
        editCropQuality: fieldInfo && fieldInfo.crop_quality && fieldInfo.crop_quality[year] ? fieldInfo.crop_quality[year] : "",
        editCropVariety: fieldInfo && fieldInfo.crop_variety && fieldInfo.crop_variety[year] ? fieldInfo.crop_variety[year] : "",
        editCropSeedAmount: fieldInfo && fieldInfo.seeding_amount && fieldInfo.seeding_amount[year] ? fieldInfo.seeding_amount[year] : "",
        editCropSeedDate: fieldInfo && fieldInfo.seeding_date && fieldInfo.seeding_date[year] ? fieldInfo.seeding_date[year] : year + "-03-01",
        editExpectedHarvestYield: fieldInfo && fieldInfo.expected_harvest_yields && fieldInfo.expected_harvest_yields[year] ? fieldInfo.expected_harvest_yields[year] : "",
      });

    };
  };

  onClickAddSeeding = () => {
    const {
      editYear,
      editCropCode,
      editCropQuality,
      editCropVariety,
      editCropSeedAmount,
      editCropSeedDate,
      editExpectedHarvestYield,
    } = this.state;

    this.updateSeedingInformation(editYear,
      {
        crop_code: editCropCode,
        crop_quality: editCropQuality,
        crop_variety: editCropVariety,        
        seeding_date: editCropSeedDate,
        seeding_amount: editCropSeedAmount,
        expected_harvest_yields: editExpectedHarvestYield,
    });

    this.onResetState()
  };

  removeCrop = () => {
    const {
      yearToRemove,
    } = this.state;

    this.setState({
      yearToRemove: null,
    });   

    this.updateSeedingInformation(yearToRemove,
      {
        crop_code: null,
        crop_quality: null,
        crop_variety: null,
        seeding_amount: null,
        seeding_date: null,
        expected_harvest_yields: '',
    }); 
  };

  onToggleRemoveSeeding = (year) => {
    this.setState({
      mode: null,
      yearToRemove: year,
    });
  };

  getSeedingRow = (year, idx, compact=false) => {
    const {
      db,
      fieldId,
    } = this.props;
    
    let fieldSize = db && db.fields && db.fields[fieldId] && db.fields[fieldId].field_size;
    let fieldInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let currentCropCode = fieldInfo && fieldInfo.crop_code && fieldInfo.crop_code[year];
    let cropSeedAmount = fieldInfo && fieldInfo.seeding_amount && fieldInfo.seeding_amount[year] ? fieldInfo.seeding_amount[year] : '';
    let cropExpectedHarvest = fieldInfo && fieldInfo.expected_harvest_yields && fieldInfo.expected_harvest_yields[year] ? fieldInfo.expected_harvest_yields[year] : '';
    let cropSeedDate = fieldInfo && fieldInfo.seeding_date && fieldInfo.seeding_date[year] ? fieldInfo.seeding_date[year] : '';

    let cropExpectedHarvestPerHa = cropExpectedHarvest && (parseFloat(cropExpectedHarvest) / parseFloat(fieldSize)).toFixed(1);

    if (!currentCropCode) {
      return this.getEmptySeedingRow(year, idx, compact)
    };

    let cropText = currentCropCode && CROP_CODES.find(x => x.value === currentCropCode);
    cropText = (cropText && cropText.shorttext) ? cropText.shorttext : "Övrigt";

    let cropQuality = fieldInfo && fieldInfo.crop_quality && fieldInfo.crop_quality[year] ? fieldInfo.crop_quality[year] : '';

    let cropQualityText = currentCropCode && CROP_QUALITY.find(x => x.value === currentCropCode);
    cropQualityText = cropQualityText && cropQualityText.values && cropQualityText.values.find((x) => x.value === cropQuality); 
    cropQualityText = cropQualityText && cropQualityText.text;

    let cropVariety = fieldInfo && fieldInfo.crop_variety && fieldInfo.crop_variety[year] ? fieldInfo.crop_variety[year] : '';

    let hasCompleteInformation = cropText && cropSeedAmount && cropSeedDate;

    if (compact) {
      return (
        <Segment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.25em",
            }}
          >
            <Header
              as="h4"
              style={{
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Sådd
            </Header>

            <p
              style={{
                color: "#6B9D9F",
                fontSize: "95%",
                cursor: "pointer",
                fontWeight: 600,
              }}
              onClick={() => this.onToogleEditSeeding(year, "edit")}
            >
              Redigera
            </p>            
          </div>          
  
          <p style={{ margin: 0 }}>Gröda: {cropText && cropText}</p>
          {cropVariety && <p style={{ margin: 0 }}>Sort: {cropVariety} {cropQualityText ? " odlas som " + cropQualityText.toLowerCase() + "" : ""}</p>}
          <p style={{ margin: 0 }}>Datum: {cropSeedDate ? cropSeedDate : "saknas."}</p>
          {cropSeedAmount && <p style={{ margin: 0 }}>Utsädesmängd: {cropSeedAmount} kg/ha.</p>}     
          {cropExpectedHarvest && <p style={{ margin: 0 }}>Förväntad skörd: {cropExpectedHarvest} ton ({cropExpectedHarvestPerHa} ton/ha).</p>}
        </Segment>
      );
    } else {
      return (
        <Segment 
          key={'seedingInfoRow' + year} 
          style={{
            padding: "1.5em",
            width: "70%",
            marginLeft: "15%",
            borderColor: hasCompleteInformation ? "#4C8440" : "#FBBD08",
            cursor: "pointer",
          }}
          onClick={() => this.onToogleEditSeeding(year, "edit")}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                marginTop: 15,
                fontSize: "140%",
                width: "10%",
                opacity: 0.7,
              }}
            >
              {year}
            </div>       
              
            <div
              style={{
                width: "90%"
              }}
            >
              <div
                style={{
                  fontSize: "170%",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                {cropText ? cropText : "-"}
              </div>   
      
              <div 
                style={{
                  fontSize: "140%",
                  marginTop: 0,
                }}
              >
                {cropVariety ? "Sorten " + cropVariety : "Okänd sort"}{cropQualityText ? " odlas som " + cropQualityText.toLowerCase() + "" : ""}
              </div>

              <div 
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                  fontWeight: 500,
                  fontSize: "120%",
                  opacity: cropSeedDate ? null : 0.7,
                }}
              >
                Datum för sådd: {cropSeedDate ? cropSeedDate : "saknas."}
              </div>  

              <div
                style={{
                  marginBottom: 0,
                  fontWeight: 500,
                  fontSize: "120%",
                  opacity: cropSeedAmount ? null : 0.7,
                }}
              >
                Utsädesmängd: {cropSeedAmount ? cropSeedAmount + " kg/ha" : "saknas."}
              </div>               

              <div
                style={{
                  marginBottom: 0,
                  fontWeight: 500,
                  fontSize: "120%",
                  opacity: cropExpectedHarvest ? null : 0.7,
                }}
              >
                Förväntad skörd: {cropExpectedHarvest ? cropExpectedHarvest + " ton (" + cropExpectedHarvestPerHa + " ton/ha)" : "saknas."}
              </div>
            </div>           
          </div>          
        </Segment>
      );
    };
  };

  getEmptySeedingRow = (year, idx, compact) => {
    if (compact) {
      return (
        <Segment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.25em",
            }}
          >
            <Header
              as="h4"
              style={{
                fontWeight: 500,
              }}
            >
              Sådd
            </Header>

            <p
              style={{
                color: "#6B9D9F",
                fontSize: "95%",
                cursor: "pointer",
                fontWeight: 600,
              }}
              onClick={() => this.onToogleEditSeeding(year, "add")}
            >
              Lägg till
            </p>            
          </div>
          
          <p style={{ color: "darkgrey" }}>
            Freja saknar information om sådden för denna säsong.
          </p>
          
        </Segment>
      );
    } else {
      return (
        <Segment 
          key={'seedingInfoRow' + year} 
          style={{
            padding: "1.5em",
            width: "70%",
            marginLeft: "15%",
            borderStyle: "dashed", 
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          secondary
          className="opacityHover"
          onClick={() => this.onToogleEditSeeding(year, "add")}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: "140%",
                width: "10%"
              }}
            >
              {year}
            </div>       
              
            <div
              style={{
                width: "90%"
              }}
            >
              <div
                style={{
                  marginTop: 4,
                  fontSize: "110%",
                }}
              >
                Information saknas! Klicka på rutan för att fylla i information om säsongens sådd.
              </div>               
            </div>            
          </div>          
        </Segment>
      );
    }
  };

  getEditSeedingRow = () => {

    const {
      editYear,
      editCropCode,
      editCropVariety,
      editCropQuality,
      editCropSeedAmount,
      editCropSeedDate,
      editExpectedHarvestYield,
    } = this.state;

    let cropQualityOptions = editCropCode && CROP_QUALITY.find((x) => x.key === editCropCode);
    cropQualityOptions = cropQualityOptions && cropQualityOptions.values;
  
    return (
      <React.Fragment>
        <Segment
          basic
        >
          <div
            style={{
              display: "flex"
            }}
          >
            <div
              style={{
                width: "69%"
              }}
            >
              <Header 
                as="h5" 
                style={{
                  marginBottom: 5,
                  fontWeight: 600
                }}
              >
                Gröda
              </Header>  
                              
              <Dropdown
                fluid
                search
                selection
                name="editCropCode"
                options={CROP_CODES}
                closeOnChange={true}
                value={editCropCode}
                onChange={(event, data) => this.onChangeField(data)}
              />
            </div>
  
            <div
              style={{
                marginLeft: "2%",
                width: "29%"
              }}
            >
              {cropQualityOptions &&
                <Header 
                  as="h5" 
                  style={{
                    marginBottom: 5,
                    fontWeight: 600
                  }}
                >
                  Önskad grödkvalité
                </Header>  
              }     
  
              {cropQualityOptions &&     
                <Dropdown
                  fluid
                  search
                  selection
                  name="editCropQuality"
                  options={cropQualityOptions}
                  closeOnChange={true}
                  value={editCropQuality}
                  onChange={(event, data) => this.onChangeField(data)}
                />
              }
            </div>
          </div>
  
          {editCropCode &&
            <React.Fragment>
              <Header 
                as="h5" 
                style={{
                  marginTop: 12,
                  marginBottom: 5,
                  fontWeight: 600
                }}
              >
                Sort (frivillig uppgift)
              </Header> 
                
              {CROP_VARIETIES.find((x) => x.value === editCropCode) &&
                <Dropdown
                  fluid
                  search
                  selection
                  name="editCropVariety"
                  options={CROP_VARIETIES.find((x) => x.value === editCropCode).values}
                  value={editCropVariety}
                  closeOnChange={true}
                  // additionLabel="Annan... "
                  // additionPosition="bottom"
                  // allowAdditions={true}
                  onChange={(event, data) => this.onChangeField(data)}
                />
              }
  
              {!CROP_VARIETIES.find((x) => x.value === editCropCode) &&
                <Input
                  fluid
                  name="editCropVariety"
                  value={editCropVariety}
                  onChange={(event, data) => this.onChangeField(data)}
                />
              }
            </React.Fragment>
          }
  
          <Header 
            as="h5" 
            style={{
              marginTop: 12,
              marginBottom: 5,
              fontWeight: 600
            }}
          >
            Datum för sådd
          </Header> 
  
          <DateInput
            fluid
            name='editCropSeedDate'
            minDate={(parseInt(editYear)-1).toFixed(0) + '-08-01'}
            maxDate={editYear + '-06-01'}
            dateFormat="YYYY-MM-DD"
            value={editCropSeedDate ? editCropSeedDate : editYear + '-03-01'}
            initialDate={editYear + '-03-01'}
            onChange={(event, data) => this.onChangeField(data)}
            localization='sv'
          />

          <Header 
            as="h5" 
            style={{
              marginTop: 12,
              marginBottom: 5,
              fontWeight: 600
            }}
          >
            Såmängd (kg/ha)
          </Header> 
  
          <Input
            fluid
            name='editCropSeedAmount'
            value={editCropSeedAmount ? editCropSeedAmount : ''}
            onChange={(event, data) => this.onChangeField(data, true)}
          /> 

          <Header 
            as="h5" 
            style={{
              marginTop: 12,
              marginBottom: 5,
              fontWeight: 600
            }}
          >
            Förväntad skörd (ton)
          </Header> 
  
          <Input
            fluid
            name='editExpectedHarvestYield'
            value={editExpectedHarvestYield ? editExpectedHarvestYield : ''}
            onChange={(event, data) => this.onChangeField(data, true)}
          /> 
        </Segment>
      </React.Fragment>
    )
  };

  render() {
    const {
      db,
      fieldId,
      widget,
      widgetYear,
    } = this.props;

    const {
      mode,
      editYear,
      editCropCode,
      yearToRemove,
    } = this.state;

    let availableYearList = ["2017", "2018", "2019", "2020", "2021", "2022"];

    let yieldMapYears = db && fieldId && db["fieldsYieldMapsProcessed_" + fieldId];
    yieldMapYears = yieldMapYears && yieldMapYears.rawdata_harvest_maps_geojson;
    yieldMapYears = yieldMapYears && Object.keys(yieldMapYears).map((x) => x);
    
    yieldMapYears && yieldMapYears.forEach((x) => {
      availableYearList = !availableYearList.includes(x) ? [...availableYearList, x] : availableYearList;
    });

    availableYearList = availableYearList && availableYearList.sort();

    let modalData = 
      <React.Fragment>
        <Modal 
          open={["add", "edit"].includes(mode)} 
          size="small"
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            {mode === "edit" ? "Redigera såinformation för " + editYear : "Lägg till såinformation för " + editYear}
          </Modal.Header>

          <Modal.Content>
            <Modal.Description>
              {this.getEditSeedingRow()}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}          
          >
            <Button 
              style={{ 
                backgroundColor: "#868D8E", 
                color: "white",
              }}
              onClick={() => this.onResetState()}
            >
              Avbryt
            </Button>
            
            {mode === "edit" &&
              <Button
                basic
                style={{ 
                  color: "#868D8E",
                }}                
                onClick={() => this.onToggleRemoveSeeding(editYear)}
              >
                Ta bort
              </Button>
            }

            <Button 
              style={{ 
                backgroundColor: "#6B9D9F", 
                color: "white",
              }}
              disabled={mode === "add" && !editCropCode}
              onClick={mode === "add" ? () => this.onClickAddSeeding() : () => this.onToogleEditSeeding()}
            >
              Spara
            </Button>            
          </Modal.Actions>            
        </Modal>

        <Modal size="small" open={yearToRemove !== null}>
            <Modal.Header>Ta bort såinformation</Modal.Header>
            <Modal.Content> 
              <Modal.Description>
                <p>Vill du ta bort såinformationen för detta skifte under {yearToRemove}?</p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions
              style={{ 
                display: "flex",
                justifyContent: "space-between"
              }}             
            >
              <Button 
                style={{ 
                  backgroundColor: "#868D8E", 
                  color: "white",
                }}
                onClick={() => this.setState({ yearToRemove: null })}
              >
                Avbryt
              </Button>
                          
              <Button 
                style={{ 
                  backgroundColor: "#C36126", 
                  color: "white",
                }}
                onClick={() => this.removeCrop()}
              >
                Ta bort
              </Button>
            </Modal.Actions>                 
          </Modal>  
      </React.Fragment>

    if (widget) {
      return (
        <React.Fragment>
          {modalData && modalData}
          {widgetYear && this.getSeedingRow(widgetYear, widgetYear, true)}
        </React.Fragment>
      )

    } else {
      return (
        <React.Fragment>
          {modalData && modalData}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5em",
            }}
          >
            <div
              style={{
                width: "70%",
              }}
            >
              {availableYearList && availableYearList.reverse().map((year, idx) => this.getSeedingRow(year, idx, false))}
            </div>              

            <div
              style={{
                maxWidth: "30%",
              }}          
            >
              <Segment 
                style={{
                  marginTop: 0,
                  padding: "2em",
                  minWidth: "250px",
                }}
              >
                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Sådd
                </Header>
                    
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  I denna vy kan du lägga till och redigera information om säsongens gröda. 
                  Detta innefattar vilken gröda och sort som du har odlat samt information om utsädesmängd och datum för sådd. 
                  Denna information använder Freja för tolkningar och uppföljningar.
                </p>

                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Nuvarande information
                </Header>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  Till vänster kan du se den information om sådd som du har angivit för detta skifte. 
                  Du kan enkelt redigera information genom att klicka på knappen "Redigera" till höger.
                </p>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  År som helt eller delvis saknar information men som har data i form av grönmassamätningar eller uppladdade skördekartor är markerade med gult. 
                  År med komplett information är markerade med grönt. 
                </p>
              </Segment>
            </div>              
          </div>          
        </React.Fragment>
      );
    }
  }
}

export default FieldManagementSeeding;
