import React, { Component } from "react";

import { 
  Header, 
} from "semantic-ui-react";

import DataLayerIcon from "@/assets/icons/menuPrimary/raw-data.svg";
import IconWithPlate from "@/components/misc/IconWithPlate";

class PageNotFoundPage extends Component {

  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          paddingLeft: "0.5em"
        }}
      >
        <div
          style={{
            position: "absolute",
            marginTop: "10%",
            left: "40%",    
          }}
        >
          <IconWithPlate
            size="25vw"
            src={DataLayerIcon}
            fitted={true}
            plateBackground="#d9d9d9"
            filter="brightness(0%) invert(27%) sepia(37%) saturate(0%) hue-rotate(155deg) brightness(96%) contrast(5%)"
          />
        
          <Header 
            as='h2'
            color='grey'
            style={{
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            Sidan kunde inte hittas...
          </Header>
        </div>
      </div>
    );
  }
};

export default PageNotFoundPage;
