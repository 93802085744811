import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { toast } from 'react-toastify';

import {
  firestoreConnect,
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import {
  Header,
  Icon
} from "semantic-ui-react";

import { cloudFunctions } from "@";
import { LAYERS_WITH_NEW_DATA_NOTIFICATIONS } from "@/constants/viewLayersAnalysis";

import { hasLayerData } from "@/helpers/dataFetcher";
import { getPDFvariables } from "@/helpers/pdfHelper";

import DataAnalysisIcon from "@/assets/icons/menuPrimary/analysis.svg";
import DataLayerIcon from "@/assets/icons/menuPrimary/raw-data.svg";
import IconWithPlate from "@/components/misc/IconWithPlate"

import FieldDataComparePalettes from "@/components/fieldData/FieldDataComparePalettes";
import FieldDataInsights from "@/components/fieldData/FieldDataInsights";

import FieldDataLayerList from "@/components/fieldData/FieldDataLayerList";
import FieldDataTopMenu from "@/components/fieldData/FieldDataTopMenu";
import FieldDataUnlockFields from "@/components/fieldData/FieldDataUnlockFields";

import FieldDataViewer from "@/components/fieldData/FieldDataViewer";
import FieldDataViewerCorrelation from "@/components/fieldData/FieldDataViewerCorrelation";
import FieldDataViewerFarm from "@/components/fieldData/FieldDataViewerFarm";

import FieldSummaryTimeline from "@/components/fieldSummary/FieldSummaryTimeline";
import FieldSummaryYield from "@/components/fieldSummary/FieldSummaryYield";

import MenuFieldList from "@/components/menus/MenuFieldList";
import SettingsPage from "@/containers/SettingsPage";

const INITIAL_STATE = {
  activeView: "selectField",
  compareUpperLeftLayer: null,
  compareUpperLeftLayerId: null,
  compareUpperRightLayer: null,
  compareUpperRightLayerId: null,
  compareLowerLeftLayer: null,
  compareLowerLeftLayerId: null,
  compareLowerRightLayer: null,
  compareLowerRightLayerId: null,
  markerScale: 1.0,
  fullscreenMode: false,
  lockedMap: true,
  selectedMarker: null,
  showUnlockFieldGuide: false,
  unlockFieldGuideFirstFieldId: null,
  showArealMap: true,
  showSettings: false,
  markerFilteringOpen: false,
  showMissingData: false,
  selectedGroup: null,
  selectedGroupMarkers: null,
  selectedGroupMarkersCoordinates: null,
  selectedGroupPlotName: null,
  numberOfGroups: 5,
  colorScaleType: "e",
  colorScaleName: null,
  intervalFilter: null,
  isUsingFiltering: false,
  allMarkers: null,
  geoJsonFieldKey: null,
  //All layer(s) and combination of left right layers that are currently loading PDFs
  loadingPDFlayers: new Set(),
  showSelectLayer: false,
  showSelectLayerPlotName: null,
  showLayerMenuForSubPlot: null,
  showComparePaletteModal: false,
  showSavePaletteModal: false,
  compareUpperGeoJsonData: null,
  compareLowerGeoJsonData: null,
  insightsMode: false,
  compareMapCenter: null,
  compareMapZoom: null,
  compareMapMouseCursorLocation: null,
};

class FieldDataPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.MapViewerMain = React.createRef();
    this.MapViewerLowerLeft = React.createRef();
    this.MapViewerLowerRight = React.createRef();
    this.MapViewerUpperLeft = React.createRef();
    this.MapViewerUpperRight = React.createRef();
  };

  componentDidMount = () => {
    const {
      match: {
        params: {
          fieldId,
          layer,
        }
      }
    } = this.props;

    if (fieldId && fieldId !== "farm") {
      this.setState({ activeView: "selectLayer" })
    } else {
      this.setState({ activeView: "selectField" })
    };

    if (fieldId && !layer) {
      this.smartOpenField(fieldId)
    };

    if (this.props.history.location.state) {
      this.setState(this.props.history.location.state);
    };
  };

  componentDidUpdate = (prevProp) => {
    const {
      match: {
        params: {
          fieldId,
        }
      }
    } = this.props;

    const {
      activeView,
    } = this.state;

    if (prevProp.history.location.state !== this.props.history.location.state) {
      this.setState(this.props.history.location.state);
      this.setState({ updated: new Date() })
    } else {

      if (activeView !== "selectField" && !fieldId) {
        this.setState({ activeView: "selectField" })
      };

      const db = this.getDb();
      const fields = db && db.fields;
      let field = fieldId && fields && fields[fieldId];

      let availableToUser = field && field.available_to_user;
      availableToUser = typeof (availableToUser) === "boolean" ? availableToUser : true;

      if (!availableToUser) {
        this.props.history.push("/")
      }
    }
  };

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);


  onSelectPalette = (newState, paletteSettings) => {
    // console.log("onSelectPalette", newState, paletteSettings)

    let modifiedNewState = newState;

    paletteSettings && Object.keys(paletteSettings).forEach((x) => {
      Object.keys(newState).forEach((y) => {
        let newValue = newState[y];
        if (typeof (newValue) === "string") {
          newValue = newValue.replace("{" + x + "}", paletteSettings[x]);
          modifiedNewState = { ...modifiedNewState, [y]: newValue };
        };
      })
    });

    this.setState({
      ...modifiedNewState,
      showComparePaletteModal: false,
    })
  };

  onSavePalette = (name) => {
    let payload = {
      text: name,
      date_added: new Date(),
      settings: {
        activeView: this.state.activeView,
        fullscreenMode: true,
        compareUpperLeftLayer: this.state.compareUpperLeftLayer,
        compareUpperLeftLayerId: this.state.compareUpperLeftLayerId,
        compareUpperRightLayer: this.state.compareUpperRightLayer,
        compareUpperRightLayerId: this.state.compareUpperRightLayerId,
        compareLowerLeftLayer: this.state.compareLowerLeftLayer,
        compareLowerLeftLayerId: this.state.compareLowerLeftLayerId,
        compareLowerRightLayer: this.state.compareLowerRightLayer,
        compareLowerRightLayerId: this.state.compareLowerRightLayerId,
      }
    };

    let dbRef = this.getUser().collection("settings").doc("palettes");
    let unixTimeStamp = Math.floor(Date.now() / 1000).toFixed(0);

    dbRef.set({
      [unixTimeStamp]: payload,
    }, { merge: true })

    this.setState({
      showSavePaletteModal: false,
    })
  };


  toggleSavePaletteModal = () => {
    let currentValue = this.state.showSavePaletteModal;
    this.setState({ showSavePaletteModal: !currentValue });
  };

  toggleShowComparePaletteModal = () => {
    let currentValue = this.state.showComparePaletteModal;
    this.setState({ showComparePaletteModal: !currentValue });
  };

  toggleShowSelectLayer = (plotName) => {
    const {
      showSelectLayer,
      showSelectLayerPlotName,
    } = this.state;

    if (!plotName) {
      this.setState({
        showSelectLayer: false,
        showSelectLayerPlotName: null,
      });

      return;
    };

    if (!showSelectLayer) {
      this.setState({
        showSelectLayer: true,
        showSelectLayerPlotName: plotName,
      });

      return;
    };

    if (showSelectLayer && showSelectLayerPlotName === plotName) {
      this.setState({
        showSelectLayer: false,
        showSelectLayerPlotName: null,
      });
    };

    if (showSelectLayer && showSelectLayerPlotName !== plotName) {
      this.setState({
        showSelectLayer: true,
        showSelectLayerPlotName: plotName,
      });
    };

  };

  selectMarker = (marker) => {
    this.setState({
      selectedMarker: marker,
      selectedGroup: null,
      selectedGroupMarkers: null,
      selectedGroupMarkersCoordinates: null,
      selectedGroupPlotName: null,
    });
  };

  selectGroupMarkers = (markers) => {
    this.setState({
      selectedMarker: null,
      selectedGroup: null,
      selectedGroupMarkers: markers,
      selectedGroupMarkersCoordinates: null,
      selectedGroupPlotName: null,
    });
  };

  openFilteringMenu = () => {
    const currentMapState = this.MapViewerMain && this.MapViewerMain.current && this.MapViewerMain.current.state;
    let allMarkers = currentMapState && currentMapState.geoJsonData && currentMapState.geoJsonData.features && currentMapState.geoJsonData.features;
    let geoJsonFieldKey = currentMapState && currentMapState.geoJsonFieldKey && currentMapState.geoJsonFieldKey;

    this.setState({
      allMarkers,
      geoJsonFieldKey,
    });
  };

  onScaleChange = (e, data) => {
    this.setState({
      scale: data.value
    });
  };

  onSettingsSave = (settings) => {
    this.setState(settings);
    this.setState({
      selectedGroup: null,
      selectedGroupPlotName: null,
    });
  };

  setParentState = (newState) => {
    this.setState(newState);
  };

  toggleShowSettings = () => {
    let currentValue = this.state.showSettings;
    this.setState({ showSettings: !currentValue });
  };

  toggleUnlockFieldGuide = (fieldId) => {
    let currentValue = this.state.showUnlockFieldGuide;

    this.setState({
      showUnlockFieldGuide: !currentValue,
      unlockFieldGuideFirstFieldId: fieldId ? fieldId : null,
    });
  };

  toggleShowCalibrateYieldMapModal = (oldYield) => {
    let currentValue = this.state.showCalibrateYieldMapModal;
    this.setState({ showCalibrateYieldMapModal: !currentValue });
    if (oldYield) {
      this.setState({
        oldYield: oldYield
      });
    } else {
      this.setState({
        oldYield: null
      });
    }
  };

  downloadFile = (filePath) => {
    const fileBucketRef = this.props.firebase.storage().ref();
    filePath && fileBucketRef.child(filePath).getDownloadURL().then((url) => {
      fetch(url)
        .then(async res => ({
          filename: filePath.split("/").slice(-1)[0],
          blob: await res.blob()
        }))
        .then(resObj => {
          // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
          const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });

          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement('a');
            link.href = objUrl;
            link.download = resObj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
          }
        })
        .catch((error) => {
          console.log('DOWNLOAD ERROR', error);
        });
    });
  };

  clearNotification = (notId) => {
    let dbRef = this.getUser().collection("notifications").doc(notId);
    dbRef.update({ seen: true });
  };

  openField = (fieldId) => {
    const {
      dataType,
      match: {
        params: {
          layer,
          layerId,
        },
      },
    } = this.props;

    const {
      activeView,
    } = this.state;

    // If we are in compare mode, switch the field and keep the other things constant
    if (activeView.includes("compare")) {
      this.onToggleFullscreenMode(true);
      this.onToggleLockedMap(true);

      this.props.history.push("/" + dataType + "/" + fieldId + "/" + layer + (layerId ? "/" + layerId : ""));
      return null;
    };

    // If we are opening a farm, forget the layer and layer Id
    if (fieldId === "farm") {
      this.props.history.push("/" + dataType + "/" + fieldId);
      return null
    };

    this.smartOpenField(fieldId);

    this.setState({
      activeView: "selectLayer",
      compareUpperLeftLayer: null,
      compareUpperLeftLayerId: null,
    });
  };

  smartOpenField = (fieldId) => {
    const {
      dataType,
      match: {
        params: {
          layer,
          layerId,
        }
      }
    } = this.props;

    const db = this.getDb();
    let field = db && fieldId && db.fields && db.fields[fieldId] && db.fields[fieldId];

    // Get the current last layer and layer id for view
    let lastLayerKey = "view_" + dataType + "_last_layer"
    let lastLayerIdKey = "view_" + dataType + "_last_layerId"

    let lastLayer = field && field[lastLayerKey] ? field[lastLayerKey] : "";
    let lastLayerId = field && field[lastLayerIdKey] ? field[lastLayerIdKey] : "";

    let newLayer = layer ? layer : lastLayer;
    let newLayerId = layerId ? layerId : lastLayerId;

    newLayer = !newLayer && dataType === "layers" && hasLayerData(db, fieldId, "greenmass") ? "greenmass" : newLayer;
    newLayer = !newLayer && dataType === "layers" && hasLayerData(db, fieldId, "soilMapSweden") ? "soilMapSweden" : newLayer;

    newLayer = !newLayer && dataType === "analysis" && hasLayerData(db, fieldId, "greenmassAnalysis") ? "greenmass" : newLayer;

    this.props.history.push("/" + dataType + "/" + fieldId + "/" + newLayer + (newLayerId ? "/" + newLayerId : ""));
  };

  openLayer = (layer, layerId, plotName = null) => {
    const {
      match: {
        params: {
          fieldId,
        }
      },
      dataType,
      userId,
      isAdminUser,
    } = this.props;

    const {
      showSelectLayerPlotName,
    } = this.state;

    plotName = plotName ? plotName : showSelectLayerPlotName;

    if (!plotName) {
      this.props.history.push("/" + dataType + "/" + fieldId + "/" + layer + (layerId ? "/" + layerId : ""));

      this.setState({
        selectedGroup: null,
        selectedGroupMarkers: null,
        selectedGroupMarkersCoordinates: null,
        selectedGroupPlotName: null,
        numberOfGroups: 5,
        colorScaleType: "e",
        colorScaleName: null,
        intervalFilter: null,
        isUsingFiltering: false,
      });
    } else {
      this.setState({
        ["compare" + plotName + "Layer"]: layer,
        ["compare" + plotName + "LayerId"]: layerId ? layerId : null,
      })
    };

    // Update DB with new latest layer and layer id
    let docRef = this.props.firestore
      .collection("users")
      .doc(userId)
      .collection("fields")
      .doc(fieldId)

    if (!plotName && !isAdminUser && dataType === "layers") {
      docRef.update({
        view_layers_last_layer: layer,
        view_layers_last_layerId: layerId ? layerId : null,
      });
    }

    if (!plotName && !isAdminUser && dataType === "analysis") {
      docRef.update({
        view_analysis_last_layer: layer,
        view_analysis_last_layerId: layerId ? layerId : null,
      });
    }

    // Reset available data (notification for new data in interface)
    let db = this.getDb();
    let fields = db && db.fields && db.fields;

    if (!plotName && !isAdminUser && LAYERS_WITH_NEW_DATA_NOTIFICATIONS && Object.keys(LAYERS_WITH_NEW_DATA_NOTIFICATIONS).includes(layer)) {

      let newValue = fields && fieldId && fields[fieldId] && fields[fieldId].new_data_available && fields[fieldId].new_data_available;

      if (!newValue || !newValue[LAYERS_WITH_NEW_DATA_NOTIFICATIONS[layer]]) {
        return
      };

      docRef && docRef.update({
        new_data_available: { ...newValue, [LAYERS_WITH_NEW_DATA_NOTIFICATIONS[layer]]: [] },
      });
    };
  };

  onClickSelectField = () => {
    const {
      activeView,
    } = this.state;

    if (activeView.includes("compare")) {
      this.onToggleFullscreenMode();
      this.onToggleLockedMap();

    } else {

      this.onToggleFullscreenMode(false);

      this.setState({
        activeView: "selectField",
        insightsMode: false,
        compareUpperLeftLayer: null,
        compareUpperLeftLayerId: null,
      });
    }
  };

  onClickSelectCompare = (newLayer) => {
    const {
      match: {
        params: { layer, layerId }
      }
    } = this.props;

    const {
      activeView,
      compareUpperLeftLayer,
      compareUpperLeftLayerId,
    } = this.state;

    if (activeView.includes("compare") && !newLayer) {
      this.openLayer(compareUpperLeftLayer, compareUpperLeftLayerId);

      this.setState({
        activeView: "selectLayer",
        compareUpperLeftLayer: null,
        compareUpperLeftLayerId: null,
        selectMarker: null,
        showSelectLayer: false,
        showSelectLayerPlotName: null,
      });

      this.onToggleFullscreenMode(false);

    } else {

      if (!activeView.includes("compare")) {

        this.setState({
          activeView: newLayer,
          compareUpperLeftLayer: layer,
          compareUpperLeftLayerId: layerId,
          selectedGroup: null,
          selectedGroupMarkers: null,
          selectedGroupMarkersCoordinates: null,
          selectedGroupPlotName: null,
          numberOfGroups: 5,
          colorScaleType: "e",
          colorScaleName: null,
          intervalFilter: null,
          isUsingFiltering: false,
        });

        this.onToggleFullscreenMode(true);
        this.onToggleLockedMap(true);

      } else {

        this.setState({
          activeView: newLayer,
        });

      };
    };
  };

  toogleInsightsMode = (newValue) => {
    const currentValue = this.state.insightsMode;
    this.setState({ insightsMode: typeof (newValue) === "boolean" ? newValue : !currentValue });
  };

  onToggleFullscreenMode = (newValue) => {
    const currentValue = this.state.fullscreenMode;
    this.setState({ fullscreenMode: typeof (newValue) === "boolean" ? newValue : !currentValue });
  };

  onToggleLockedMap = (newValue) => {
    const currentValue = this.state.lockedMap;
    this.setState({ lockedMap: typeof (newValue) === "boolean" ? newValue : !currentValue });
  };

  // Fetches pdf file from data in current layer and saves it to db and creates notification in Freja with a Toast popup download link
  getPDFLocal = () => {
    const state = this.state;
    const props = this.props;
    const db = this.getDb();
    const mapRefs = [this.MapViewerMain && this.MapViewerMain.current, this.MapViewerUpperLeft && this.MapViewerUpperLeft.current, this.MapViewerUpperRight && this.MapViewerUpperRight.current,
    this.MapViewerLowerLeft && this.MapViewerLowerLeft.current, this.MapViewerLowerRight && this.MapViewerLowerRight.current]
    return (() => {
      const { payload, userId, fieldId, loadingLayers } = getPDFvariables(state, props, mapRefs, db)

      this.setState(prevState => {
        prevState.loadingPDFlayers.add(loadingLayers)
        return { loadingPDFlayers: prevState.loadingPDFlayers }
      });

      cloudFunctions.httpsCallable('createPlotPdf')(
        {
          userId: userId,
          fieldId: fieldId,
          payload: JSON.stringify(payload),
          saveTo: "app",
        }
      )
        .then(res => {
          if (!res.ok) { throw res }
          return (res.json())
        })

        .then(res => {
          // update currently loading layers
          this.setState(prevState => {
            prevState.loadingPDFlayers.delete(loadingLayers)
            return { loadingPDFlayers: prevState.loadingPDFlayers }
          })

          const toastMsg = () => {
            return (<div onClick={() => {
              this.downloadFile(res.link);
              this.clearNotification(res.pdfId)
              toast.dismiss()
            }}>
              {'Karta exporterad som: ' + res.pdfName.replace("_", " ")} <Icon size='large' link name='download' />
            </div>)
          }

          toast.success(toastMsg(),
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: false,
              closeOnClick: false,
              autoClose: 60 * 1000,
            })
        })
        .catch((error) => {
          this.setState(prevState => {
            prevState.loadingPDFlayers.delete(loadingLayers)
            return { loadingPDFlayers: prevState.loadingPDFlayers }
          })

          toast.warning("PDF-Exportering mislyckades.",
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: false,
              closeOnClick: true,
              autoClose: 5000,
            })

          this.setState(prevState => {
            prevState.loadingPDFlayers.delete(loadingLayers)
            return { loadingPDFlayers: prevState.loadingPDFlayers }
          })
          console.log('PDF ERROR', error);
        });
    })
  };


  // Fetches pdf file from data in current layer and saves it to db and creates notification in Freja with a Toast popup download link
  getPDFMail = () => {
    const state = this.state;
    const props = this.props;
    const db = this.getDb();
    const mapRefs = [this.MapViewerMain && this.MapViewerMain.current, this.MapViewerUpperLeft && this.MapViewerUpperLeft.current, this.MapViewerUpperRight && this.MapViewerUpperRight.current,
    this.MapViewerLowerLeft && this.MapViewerLowerLeft.current, this.MapViewerLowerRight && this.MapViewerLowerRight.current]

    return (() => {
      const { payload, userId, fieldId, loadingLayers } = getPDFvariables(state, props, mapRefs, db)
      this.setState(prevState => {
        prevState.loadingPDFlayers.add(loadingLayers)
        return { loadingPDFlayers: prevState.loadingPDFlayers }
      });

      cloudFunctions.httpsCallable('createPlotPdf')(
        {
          userId: userId,
          fieldId: fieldId,
          payload: JSON.stringify(payload),
          saveTo: "email",
        }
      )
        .then(res => {
          if (!res.ok) { throw res }

          // update currently loading layers
          this.setState(prevState => {
            prevState.loadingPDFlayers.delete(loadingLayers)
            return { loadingPDFlayers: prevState.loadingPDFlayers }
          })

          toast.success('PDF skickad till gårdens mailadress: ' + res.pdfName.replace("_", " "),
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: false,
              closeOnClick: true,
              autoClose: 8000,
            })
        })
        .catch((error) => {
          toast.warning("PDF-Exportering mislyckades.",
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: false,
              closeOnClick: true,
              autoClose: 5000,
            })
          this.setState(prevState => {
            prevState.loadingPDFlayers.delete(loadingLayers)
            return { loadingPDFlayers: prevState.loadingPDFlayers }
          })
          console.log('PDF ERROR', error);
        });
    }
    )
  };

  setCompareMapCenterAndZoom = (compareMapCenter, compareMapZoom) => this.setState({compareMapCenter, compareMapZoom});
  setCompareMapMouseCursorLocation = (compareMapMouseCursorLocation) => this.setState({compareMapMouseCursorLocation});

  render() {
    const {
      match: {
        params: { fieldId, layer, layerId }
      },
      userId,
      dataType,
      userPlanType,
      updateCacheFieldData,
      cacheFieldData,
    } = this.props;

    const {
      activeView,
      compareUpperLeftLayer,
      compareUpperLeftLayerId,
      compareUpperRightLayer,
      compareUpperRightLayerId,
      compareLowerLeftLayer,
      compareLowerLeftLayerId,
      compareLowerRightLayer,
      compareLowerRightLayerId,
      lockedMap,
      fullscreenMode,
      insightsMode,
      selectedMarker,
      showSettings,
      markerFilteringOpen,
      showUnlockFieldGuide,
      unlockFieldGuideFirstFieldId,
      selectedGroup,
      selectedGroupMarkers,
      selectedGroupMarkersCoordinates,
      selectedGroupPlotName,
      numberOfGroups,
      colorScaleType,
      colorScaleName,
      intervalFilter,
      isUsingFiltering,
      allMarkers,
      geoJsonFieldKey,
      loadingPDFlayers,
      showSelectLayer,
      showSelectLayerPlotName,
      showComparePaletteModal,
      showSavePaletteModal,
      compareUpperGeoJsonData,
      compareLowerGeoJsonData,
    } = this.state;

    const db = this.getDb();

    const rawYieldMap = db && fieldId && db["fieldsYieldMapsRaw_" + fieldId];
    const processedYieldMap = db && fieldId && db["fieldsYieldMapsProcessed_" + fieldId];
    const weatherData = db && db.fieldsWeather;

    const fields = db && db.fields;
    const fieldsInfo = db && db.fieldsInfo;
    let fieldDb = fieldId && fields && fields[fieldId];

    const fileBucketRef = this.props.firebase.storage().ref();

    let hasLoadedData =
      isLoaded(db) &&
      !isEmpty(db) &&
      isLoaded(fields) &&
      !isEmpty(fields)

    let showArealMap = db && db.settings &&
      db.settings.view &&
      db.settings.view.show_areal_map &&
      db.settings.view.show_areal_map;

    showArealMap = typeof (showArealMap) === "boolean" ? showArealMap : this.state.showArealMap;

    let markerScale = db && db.settings &&
      db.settings.view &&
      db.settings.view.marker_scale &&
      db.settings.view.marker_scale;

    markerScale = typeof (markerScale) === "number" ? markerScale : this.state.markerScale;

    let showMissingData = db && db.settings &&
      db.settings.view &&
      db.settings.view.show_missing_data &&
      db.settings.view.show_missing_data;

    showMissingData = typeof (showMissingData) === "boolean" ? showMissingData : this.state.showMissingData;

    let farmPosition = db && db.profile && db.profile.farm && db.profile.farm.position;

    let availableToUser = fieldDb && fieldDb.available_to_user;
    availableToUser = typeof (availableToUser) === "boolean" ? availableToUser : true;

    if (!availableToUser) {
      return null
    };

    let mapViewerProps = {
      db,
      getUser: this.getUser,
      fileBucketRef,
      dataType,
      userId,
      fieldId,
      field: fields && fields[fieldId],
      fieldInfo: fieldsInfo && fieldsInfo[fieldId],
      layer,
      layerId,
      weatherData,
      markerScale,
      lockedMap,
      showArealMap,
      onClickSelectField: this.onClickSelectField,
      onClickSelectCompare: this.onClickSelectCompare,
      markerFilteringOpen,
      showMissingData,
      onCalibrateYieldMap: this.toggleShowCalibrateYieldMapModal,
      selectedGroup,
      selectedGroupMarkers,
      numberOfGroups,
      colorScaleType,
      colorScaleName,
      intervalFilter,
      isUsingFiltering,
      onSettingsSave: this.onSettingsSave,
      setContainerState: this.setParentState,
      selectMarker: this.selectMarker,
      selectGroupMarkers: this.selectGroupMarkers,
      selectedMarker,
      updateCacheFieldData,
      cacheFieldData,
      activeView,
      mapCenter: activeView.includes("compare") ? this.state.compareMapCenter : null,
      mapZoom: activeView.includes("compare") ? this.state.compareMapZoom : null,
      setNewMapCenterAndZoom: activeView.includes("compare") ? this.setCompareMapCenterAndZoom : null,
      mouseCursorLocation: activeView.includes("compare") ? this.state.compareMapMouseCursorLocation : null,
      setNewMouseCursorLocation: activeView.includes("compare") ? this.setCompareMapMouseCursorLocation : null,
    };

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <FieldDataComparePalettes
          db={db}
          fieldId={fieldId}
          openOpenModal={showComparePaletteModal}
          openSaveModal={showSavePaletteModal}
          onSelectPalette={this.onSelectPalette}
          onSavePalette={this.onSavePalette}
          toggleSavePaletteModal={this.toggleSavePaletteModal}
          toggleShowComparePaletteModal={this.toggleShowComparePaletteModal}
          setParentState={this.setParentState}
        />

        <FieldDataUnlockFields
          db={db}
          getUser={this.getUser}
          userId={userId}
          fieldId={fieldId}
          open={showUnlockFieldGuide}
          firstFieldIdToUnlock={unlockFieldGuideFirstFieldId}
          onClose={this.toggleUnlockFieldGuide}
        />

        <FieldDataTopMenu
          loadingPDFlayers={loadingPDFlayers}
          db={db}
          userId={userId}
          trueUserId={this.props.trueUserId}
          getUser={this.getUser}
          fieldId={fieldId}
          fieldDb={fieldDb}
          layer={layer}
          dataType={dataType}
          params={this.props.match.params}
          activeView={activeView}
          insightsMode={insightsMode}
          toogleInsightsMode={this.toogleInsightsMode}
          fullscreenMode={fullscreenMode}
          onToggleLockedMap={this.onToggleLockedMap}
          lockedMap={lockedMap}
          onToggleFullscreenMode={this.onToggleFullscreenMode}
          onClickSelectField={this.onClickSelectField}
          onClickSelectCompare={this.onClickSelectCompare}
          onChangeSelectedCompareView={this.onChangeSelectedCompareView}
          toggleShowSettings={this.toggleShowSettings}
          markerFilteringOpen={markerFilteringOpen}
          isAdminUser={this.props.isAdminUser}
          onSettingsSave={this.onSettingsSave}
          allMarkers={allMarkers}
          geoJsonFieldKey={geoJsonFieldKey}
          numberOfGroups={this.state.numberOfGroups}
          intervalFilter={this.state.intervalFilter}
          colorScaleType={this.state.colorScaleType}
          openFilteringMenu={this.openFilteringMenu}
          getPDFLocal={this.getPDFLocal()}
          getPDFMail={this.getPDFMail()}
          toggleShowSelectLayer={this.toggleShowSelectLayer}
          showSelectLayerPlotName={showSelectLayerPlotName}
          compareUpperLeftLayer={compareUpperLeftLayer}
          compareUpperRightLayer={compareUpperRightLayer}
          compareLowerLeftLayer={compareLowerLeftLayer}
          compareLowerRightLayer={compareLowerRightLayer}
          toggleShowComparePaletteModal={this.toggleShowComparePaletteModal}
          toggleSavePaletteModal={this.toggleSavePaletteModal}
        />

        <SettingsPage
          open={showSettings}
          userId={this.props.userId}
          onClose={this.toggleShowSettings}
        />

        {activeView.includes("compare") &&
          <FieldDataLayerList
            isCompare={true}
            isCorrelation={activeView === "compare2correlation"}
            open={showSelectLayer}
            userId={this.props.userId}
            userPlanType={userPlanType}
            activeView={activeView}
            db={db}
            fieldId={fieldId}
            dataType={dataType}
            plotName={showSelectLayerPlotName}
            openLayer={this.openLayer}
            toogleShowSelectLayer={this.toggleShowSelectLayer}
            layer={this.state[showSelectLayerPlotName]}
            layerId={this.state[showSelectLayerPlotName + "Id"]}
            rawYieldMap={rawYieldMap}
            processedYieldMap={processedYieldMap}
          />
        }

        {(!insightsMode && !fullscreenMode) &&
          <div
            style={{
              height: "100vh",
              overflowY: "auto",
              paddingRight: "0.5em",
              paddingBottom: "2em",
              paddingLeft: "0.5em",
              minWidth: "25em",
              maxWidth: "25em",
              paddingTop: "70px",
            }}
          >
            {activeView !== "selectLayer" &&
              <MenuFieldList
                db={db}
                userId={this.props.userId}
                fieldId={fieldId}
                openField={this.openField}
                openUnlockField={this.toggleUnlockFieldGuide}
                showNewDataDots={true}
              />
            }

            {activeView === "selectLayer" &&
              <FieldDataLayerList
                userId={this.props.userId}
                userPlanType={userPlanType}
                db={db}
                fieldId={fieldId}
                layer={layer}
                layerId={layerId}
                dataType={dataType}
                rawYieldMap={rawYieldMap}
                processedYieldMap={processedYieldMap}
                openLayer={this.openLayer}
                isCompare={false}
              />
            }
          </div>
        }

        {hasLoadedData && fieldId && layer && !activeView.includes("compare") &&
          <FieldDataViewer
            {...mapViewerProps}
            ref={this.MapViewerMain}
            mode="default"
            openLayer={this.openLayer}
            mapStyle={{
              top: "55px",
              height: "calc(100vh - 55px)",
            }}
          />
        }

        {hasLoadedData && fieldId && activeView.includes("compare") &&
          <div
            style={{
              height: "100%",
              width: "100%",
            }}
          >

            {activeView === "compare2" &&
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <FieldDataViewer
                  {...mapViewerProps}
                  ref={this.MapViewerUpperLeft}
                  mode={activeView}
                  plotName="UpperLeft"
                  layer={compareUpperLeftLayer}
                  layerId={compareUpperLeftLayerId}
                  openLayer={this.openLayer}
                  markerScale={activeView === "compare2" ? markerScale : 0.5 * markerScale}
                  mapStyle={{
                    top: "55px",
                    height: activeView === "compare2" ? "calc(100vh - 55px)" : "calc(50vh - 27.5px)",
                    width: insightsMode ? "calc(40vw - 42.5px)" : null,
                  }}
                  mapOptions={{
                    boundsOptions: insightsMode ? { padding: [100, 100] } : { padding: [100, 100] },
                  }}
                  selectedGroup={selectedGroupPlotName === "UpperLeft" ? selectedGroup : null}
                  placeholderText={"Klicka här för att välja ett datalager"}
                  placeholderOnClick={() => this.toggleShowSelectLayer("compareUpperLeftLayerLayer")}
                />

                <FieldDataViewer
                  {...mapViewerProps}
                  ref={this.MapViewerUpperRight}
                  mode={activeView}
                  plotName="UpperRight"
                  layer={compareUpperRightLayer}
                  layerId={compareUpperRightLayerId}
                  openLayer={this.openLayer}
                  markerScale={activeView === "compare2" ? markerScale : 0.5 * markerScale}
                  mapStyle={{
                    top: "55px",
                    height: activeView === "compare2" ? "calc(100vh - 55px)" : "calc(50vh - 27.5px)",
                    width: insightsMode ? "calc(40vw - 42.5px)" : null,
                  }}
                  mapOptions={{
                    boundsOptions: insightsMode ? { padding: [100, 100] } : { padding: [100, 100] },
                  }}
                  selectedGroup={selectedGroupPlotName === "UpperRight" ? selectedGroup : null}
                  placeholderText={"Klicka här för att välja ett datalager"}
                  placeholderOnClick={() => this.toggleShowSelectLayer("compareUpperRightLayer")}
                />
              </div>
            }

            {activeView === "compare2correlation" &&
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%"
                  }}
                >
                  <FieldDataViewer
                    {...mapViewerProps}
                    ref={this.MapViewerUpperLeft}
                    mode={activeView}
                    plotName="UpperLeft"
                    layer={compareUpperLeftLayer}
                    layerId={compareUpperLeftLayerId}
                    openLayer={this.openLayer}
                    markerScale={0.5 * markerScale}
                    mapStyle={{
                      top: "55px",
                      height: "calc(50vh - 27.5px)",
                      width: insightsMode ? "calc(40vw - 42.5px)" : null,
                    }}
                    mapOptions={{
                      boundsOptions: { padding: [50, 50] },
                    }}
                    selectedGroup={selectedGroupPlotName === "UpperLeft" ? selectedGroup : null}
                    placeholderText={"Klicka här för att välja ett datalager"}
                    placeholderOnClick={() => this.toggleShowSelectLayer("compareUpperLeftLayer")}
                  />

                  <FieldDataViewer
                    {...mapViewerProps}
                    ref={this.MapViewerLowerLeft}
                    mode={activeView}
                    plotName="LowerLeft"
                    layer={compareLowerLeftLayer}
                    layerId={compareLowerLeftLayerId}
                    openLayer={this.openLayer}
                    markerScale={0.5 * markerScale}
                    mapStyle={{
                      top: "55px",
                      height: "calc(50vh - 27.5px)",
                      width: insightsMode ? "calc(40vw - 42.5px)" : null,
                    }}
                    mapOptions={{
                      boundsOptions: { padding: [50, 50] },
                    }}
                    selectedGroup={selectedGroupPlotName === "LowerLeft" ? selectedGroup : null}
                    placeholderText={"Klicka här för att välja ett datalager"}
                    placeholderOnClick={() => this.toggleShowSelectLayer("compareLowerLeftLayer")}
                  />
                </div>

                <div
                  style={{
                    width: "50%"
                  }}
                >
                  <FieldDataViewerCorrelation
                    {...mapViewerProps}
                    mode={activeView}
                    plotName="CorrelationMap"
                    ref={this.MapViewerLowerRight}
                    compareUpperLeftLayer={compareUpperLeftLayer}
                    compareLowerLeftLayer={compareLowerLeftLayer}
                    compareUpperLeftLayerId={compareUpperLeftLayerId}
                    compareLowerLeftLayerId={compareLowerLeftLayerId}
                    compareUpperGeoJsonData={compareUpperGeoJsonData}
                    compareLowerGeoJsonData={compareLowerGeoJsonData}
                    markerScale={markerScale}
                    mapStyle={{
                      top: "55px",
                      height: "calc(100vh - 55px)",
                      width: insightsMode ? "calc(40vw - 42.5px)" : null,
                    }}
                    mapOptions={{
                      boundsOptions: insightsMode ? { padding: [100, 100] } : { padding: [50, 50] },
                    }}
                    selectedGroup={selectedGroupPlotName === "CorrelationMap" ? selectedGroup : null}
                  />
                </div>
              </div>
            }

            {activeView === "compare4" &&
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <FieldDataViewer
                    {...mapViewerProps}
                    ref={this.MapViewerUpperLeft}
                    mode={activeView}
                    plotName="UpperLeft"
                    layer={compareUpperLeftLayer}
                    layerId={compareUpperLeftLayerId}
                    openLayer={this.openLayer}
                    markerScale={activeView === "compare2" ? markerScale : 0.5 * markerScale}
                    mapStyle={{
                      top: "55px",
                      height: activeView === "compare2" ? "calc(100vh - 55px)" : "calc(50vh - 27.5px)",
                      width: insightsMode ? "calc(40vw - 42.5px)" : null,
                    }}
                    mapOptions={{
                      boundsOptions: { padding: [50, 50] },
                    }}
                    selectedGroup={selectedGroupPlotName === "UpperLeft" ? selectedGroup : null}
                    placeholderText={"Klicka här för att välja ett datalager"}
                    placeholderOnClick={() => this.toggleShowSelectLayer("compareUpperLeftLayerLayer")}
                  />

                  <FieldDataViewer
                    {...mapViewerProps}
                    ref={this.MapViewerUpperRight}
                    mode={activeView}
                    plotName="UpperRight"
                    layer={compareUpperRightLayer}
                    layerId={compareUpperRightLayerId}
                    openLayer={this.openLayer}
                    markerScale={activeView === "compare2" ? markerScale : 0.5 * markerScale}
                    mapStyle={{
                      top: "55px",
                      height: activeView === "compare2" ? "calc(100vh - 55px)" : "calc(50vh - 27.5px)",
                      width: insightsMode ? "calc(40vw - 42.5px)" : null,
                    }}
                    mapOptions={{
                      boundsOptions: { padding: [50, 50] },
                    }}
                    selectedGroup={selectedGroupPlotName === "UpperRight" ? selectedGroup : null}
                    placeholderText={"Klicka här för att välja ett datalager"}
                    placeholderOnClick={() => this.toggleShowSelectLayer("compareUpperRightLayer")}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <FieldDataViewer
                    {...mapViewerProps}
                    ref={this.MapViewerLowerLeft}
                    mode={activeView}
                    plotName="LowerLeft"
                    layer={compareLowerLeftLayer}
                    layerId={compareLowerLeftLayerId}
                    openLayer={this.openLayer}
                    markerScale={0.5 * markerScale}
                    mapStyle={{
                      top: "55px",
                      height: "calc(50vh - 27.5px)",
                      width: insightsMode ? "calc(40vw - 42.5px)" : null,
                    }}
                    mapOptions={{
                      boundsOptions: { padding: [50, 50] },
                    }}
                    selectedGroup={selectedGroupPlotName === "LowerLeft" ? selectedGroup : null}
                    placeholderText={"Klicka här för att välja ett datalager"}
                    placeholderOnClick={() => this.toggleShowSelectLayer("compareLowerLeftLayer")}
                  />

                  <FieldDataViewer
                    {...mapViewerProps}
                    ref={this.MapViewerLowerRight}
                    mode={activeView}
                    plotName="LowerRight"
                    layer={compareLowerRightLayer}
                    layerId={compareLowerRightLayerId}
                    openLayer={this.openLayer}
                    markerScale={0.5 * markerScale}
                    mapStyle={{
                      top: "55px",
                      height: "calc(50vh - 27.5px)",
                      width: insightsMode ? "calc(40vw - 42.5px)" : null,
                    }}
                    mapOptions={{
                      boundsOptions: { padding: [50, 50] },
                    }}
                    selectedGroup={selectedGroupPlotName === "LowerRight" ? selectedGroup : null}
                    placeholderText={"Klicka här för att välja ett datalager"}
                    placeholderOnClick={() => this.toggleShowSelectLayer("compareLowerRightLayer")}
                  />
                </div>
              </React.Fragment>
            }
          </div>
        }

        {insightsMode && activeView !== "selectField" &&
          <div
            style={{
              height: "100vh",
              overflowY: "auto",
              paddingTop: 70,
              minWidth: "20vw",
              maxWidth: "20vw",
            }}
          >
            <FieldDataInsights
              {...this.props}
              db={db}
              geoPointClass={this.props.firestore.GeoPoint}
              trueUserId={this.props.trueUserId}
              getUser={this.getUser}
              fileBucketRef={fileBucketRef}
              fieldId={fieldId}
              selectGroupMarkers={this.selectGroupMarkers}
              selectedGroupMarkers={selectedGroupMarkers}
              selectedGroupMarkersCoordinates={selectedGroupMarkersCoordinates}
              setParentState={this.setParentState}
              fieldDataState={this.state}
            />
          </div>
        }

        {fieldId && layer === "summary" &&
          <FieldSummaryTimeline
            db={db}
            getUser={this.getUser}
            fileBucketRef={fileBucketRef}
            userId={userId}
            fieldId={fieldId}
            layer={layer}
            layerId={layerId}
          />
        }

        {fieldId && layer === "summary_yield" &&
          <FieldSummaryYield
            db={db}
            getUser={this.getUser}
            fileBucketRef={fileBucketRef}
            userId={userId}
            fieldId={fieldId}
            layer={layer}
            layerId={layerId}
          />
        }


        {hasLoadedData && fieldId && fieldId === "farm" &&
          <FieldDataViewerFarm
            fields={fields}
            farmPosition={farmPosition}
          />
        }

        {!fieldId &&
          <div
            style={{
              position: "absolute",
              marginTop: "10%",
              left: "50%",
            }}
          >
            <IconWithPlate
              filter={dataType === "layers" ?
                "brightness(0) invert(60%) sepia(32%) saturate(298%) hue-rotate(50deg) brightness(96%) contrast(91%)" :
                "brightness(0) invert(77%) sepia(93%) saturate(2262%) hue-rotate(339deg) brightness(99%) contrast(89%)"
              }
              size="25vw"
              plateBackground={dataType === "layers" ? "#D7E0D1" : "#f8eed0"}
              src={dataType === "layers" ? DataLayerIcon : DataAnalysisIcon}
              fitted={true}
            />

            <Header
              as='h2'
              color='grey'
              style={{
                fontWeight: "normal",
                textAlign: "center",
              }}
            >
              {dataType === "layers" ? "Välj ett skifte för att utforska datalager." : "Välj ett skifte för att tolka data."}
            </Header>
          </div>
        }

      </div >
    );
  }
};

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => {
    const {
      match: {
        params: { fieldId }
      },
    } = props;

    let results = [
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fields" },
        ],
        storeAs: 'fields'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsInfo" },
        ],
        storeAs: 'fieldsInfo'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsWeather", doc: "farm" },
        ],
        storeAs: 'fieldsWeather'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "insights" },
        ],
        storeAs: 'insights'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "profile" },
        ],
        storeAs: 'profile'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'settings'
      },
    ];

    if (fieldId && fieldId !== '' && fieldId !== "farm") {
      results = [...results,
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsVraMaps", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsVraMaps_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSoilMapsRaw", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSoilMapsRaw_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSoilMapsProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSoilMapsProcessed_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsMoistureMapsProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsMoistureMapsProcessed_' + fieldId
      },      
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsYieldMapsRaw", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsYieldMapsRaw_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsYieldMapsProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsYieldMapsProcessed_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSentinelHubRaw", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSentinelHubRaw_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSentinelHubProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSentinelHubProcessed_' + fieldId
      },
      ];
    };

    return results
  }),
  withFirestoreData,
  withRouter
)(FieldDataPage);