import React, { Component } from "react";
import ReactDOM from 'react-dom';

import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import {
  withFirebase,
  withFirestore as withFirestoreActions,
  firestoreConnect,
} from "react-redux-firebase";

import {
  Menu,
  Dropdown,
  Icon,
  Popup,
  Confirm,
  Image,
} from "semantic-ui-react";

import iconNotifications from "@/assets/icons/menuSecondary/events.svg";
import iconInsights from "@/assets/icons/menuSecondary/freja-insights.svg";
import iconMessages from "@/assets/icons/menuSecondary/messages.svg";
import iconMenu from "@/assets/icons/menuSecondary/menu.svg";

import MenuHelp from "@/components/menus/MenuHelp";
import MenuMessages from "@/components/menus/MenuMessages";
import MenuNotifications from "@/components/menus/MenuNotifications";

import SettingsPage from "@/containers/SettingsPage";

import FieldDataInsights from "@/components/fieldData/FieldDataInsights";
import ChangePasswordModal from "@/components/password/changePassword";

import {
  collectionToIdArray
} from "@/helpers/db";

import "./style.css";

const INITIAL_STATE = {
  showSettingsModal: false,
  showNotifications: false,
  showMessages: false,
  showMultipleFieldsModal: false,
  showInsights: false,
  showChangePasswordModal: false,
  showConfirmLogOut: false,
  insightsSummaryOpen: false,
};

const NotificationDot = () => (
  <div
    style={{
      position: "absolute",
      top: 10,
      right: 10,
      width: "0.75em",
      height: "0.75em",
      borderRadius: "50%",
      backgroundColor: "#C36126"
    }}
  />
);

class MenuSecondary extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  getDb = () => this.props.firestoreData;

  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  onLogoutClick = () => {
    this.toogleShowConfirmLogout();
  };

  toogleShowConfirmLogout = () => {
    const currentValue = this.state.showConfirmLogOut;
    this.setState({ showConfirmLogOut: !currentValue });
  };

  handleClickOutside = event => {
    if (this.state.showMultipleFieldsModal) {
      return;
    };

    if (this.state.insightsSummaryOpen) {
      return;
    };

    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        showNotifications: false,
        showInsights: false,
        showMessages: false,
      })
    }
  };

  onClickLogout = () => {
    this.props.history.push("/");
    this.props.firebase.logout();
    this.toogleShowConfirmLogout();
  };

  toggleShowSettingsModal = () => {
    let currentValue = this.state.showSettingsModal;
    this.setState({ showSettingsModal: !currentValue });
  };

  toggleShowNotifications = () => {
    this.setState({
      showNotifications: true,
    });
  };

  toggleShowMessages = () => {
    this.setState({
      showMessages: true,
    });
  };

  closeNotificationMenu = () => {
    this.setState({
      showNotifications: false,
    });
  };

  toggleShowInsights = () => {
    let currentValue = this.state.showInsights;
    this.setState({ showInsights: !currentValue });
  };

  setParentState = (newState) => {
    this.setState(newState);
  };

  toggleShowMultipleFieldsModal = () => {
    let currentValue = this.state.showMultipleFieldsModal;
    this.setState({ showMultipleFieldsModal: !currentValue });
  };

  toggleShowChangePasswordModal = () => {
    this.setState({ showChangePasswordModal: !this.state.showChangePasswordModal })
  };

  render() {
    const {
      showSettingsModal,
      showMessages,
      showNotifications,
      showInsights,
      showChangePasswordModal,
    } = this.state;

    const {
      userId,
      hideNotifications,
      hideInsights,
      hideUserMenu,
    } = this.props;

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    const profile = db && db.profile;
    const userPlanType = profile && profile.plan && profile.plan.plan_type && profile.plan.plan_type;

    let notifications = db && collectionToIdArray(db.notifications);
    let hasNewNotifications = notifications && notifications.find((x) => typeof (x.seen) !== "boolean" || x.seen !== true);

    let messages = db && collectionToIdArray(db.messages);
    let hasNewMessages = messages && messages.find((x) => typeof (x.seen) !== "boolean" || x.seen !== true);

    let userName = db && db.profile && db.profile.farm && db.profile.farm.name;

    const trueUserId = this.props.firebaseData.auth && this.props.firebaseData.auth.uid;
    let isImpersonating = trueUserId && userId && trueUserId !== userId;

    userName = isImpersonating ? userName + " (Rådgivare)" : userName;

    let menuItems = {
      insights: {
        caption: "",
        tooltip: null,
        name: "insights",
        aliases: [""],
        icon: iconInsights,
        includedInPlans: ['all'],
        position: "right",
        hasNewNotifications: false,
        active: showInsights,
        onClick: () => this.toggleShowInsights(),
        disabled: false,
        hidden: hideInsights,
      },
      notifications: {
        caption: "",
        tooltip: null,
        name: "notifications",
        aliases: [""],
        icon: iconNotifications,
        includedInPlans: ['all'],
        position: "right",
        hasNewNotifications: hasNewNotifications,
        active: showNotifications,
        onClick: () => this.toggleShowNotifications(),
        disabled: false,
        hidden: hideNotifications,
      },
      messages: {
        caption: "",
        tooltip: null,
        name: "inbox",
        aliases: [""],
        icon: iconMessages,
        includedInPlans: ['all'],
        position: "right",
        hasNewNotifications: hasNewMessages,
        active: showMessages,
        onClick: () => this.toggleShowMessages(),
        disabled: false,
      },
    };

    return (
      <React.Fragment>
        <Confirm
          open={this.state.showConfirmLogOut}
          onCancel={this.toogleShowConfirmLogout}
          onConfirm={this.onClickLogout}
          header={"Logga ut"}
          content={"Vill du verkligen logga ut från Freja?"}
          confirmButton={"Logga ut"}
          cancelButton={"Avbryt"}
          size="tiny"
        />

        <ChangePasswordModal
          open={showChangePasswordModal}
          firebase={this.props.firebase}
          onCancel={this.toggleShowChangePasswordModal}
        />

        {showInsights &&
          <div
            style={{
              position: "absolute",
              right: "0.5em",
              top: "45px",
              zIndex: 1000,
              padding: "1em",
              overflowY: "auto",
            }}
          >
            <FieldDataInsights
              widgetType="toolbar"
              db={db}
              getUser={this.getUser}
              fileBucketRef={fileBucketRef}
              trueUserId={this.props.firebaseData && this.props.firebaseData.auth && this.props.firebaseData.auth.uid}
              userId={userId}
              setParentState={this.setParentState}
              onOpenInsight={this.onOpenInsight}
            />
          </div>
        }

        {showMessages &&
          <div
            style={{
              position: "absolute",
              right: "0.5em",
              top: "45px",
              zIndex: 1000,
              padding: "1em",
              overflowY: "auto",
            }}
          >
            <MenuMessages
              userId={userId}
              trueUserId={this.props.firebaseData && this.props.firebaseData.auth && this.props.firebaseData.auth.uid}
              db={db}
              getUser={this.getUser}
              fileBucketRef={fileBucketRef}
              firebase={this.props.firebase}
              floating={true}
              closeMessageMenu={this.closeMessageMenu}
              toggleShowMultipleFieldsModal={this.toggleShowMultipleFieldsModal}
            />
          </div>
        }

        {showNotifications &&
          <div
            style={{
              position: "absolute",
              right: "0.5em",
              top: "45px",
              zIndex: 1000,
              padding: "1em",
              overflowY: "auto",
            }}
          >
            <MenuNotifications
              fileBucketRef={fileBucketRef}
              userId={userId}
              db={db}
              getUser={this.getUser}
              floating={true}
              closeNotificationMenu={this.closeNotificationMenu}
              toggleShowMultipleFieldsModal={this.toggleShowMultipleFieldsModal}
            />
          </div>
        }

        <SettingsPage
          open={showSettingsModal}
          userId={this.props.userId}
          onClose={this.toggleShowSettingsModal}
        />

        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 1.0)",
            position: "fixed",
            top: 0,
            left: "85px",
            height: "55px",
            width: "100vw",
            paddingLeft: "0px",
            paddingRight: "85px",
            zIndex: 1000,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Menu
            pointing
            secondary
            fluid
            style={{
              textAlign: "center",
              marginTop: 0,
              height: "53px",
            }}
          >
            {this.props.children && this.props.children}

            <Menu.Menu
              position='right'
            >
              {this.props.rightMenuItems && this.props.rightMenuItems}

              {menuItems && Object.keys(menuItems).map((x) => {
                let menuItem = menuItems[x];

                if (!menuItems[x].includedInPlans.includes("all") && userPlanType && !menuItems[x].includedInPlans.includes(userPlanType)) {
                  return <div key={'MenuSecondaryItem_' + menuItem.name} />
                }

                if (menuItems[x].hidden === true) {
                  return <div key={'MenuSecondaryItem_' + menuItem.name} />
                }

                if (menuItem.position && menuItem.position === "right") {
                  return (
                    <Popup
                      key={'MenuSecondaryItem_' + menuItem.name}
                      trigger={
                        <Menu.Item
                          className="menuSecondaryItem"
                          style={{
                            marginTop: 0,
                            height: 55,
                            width: 55,
                            background: menuItem.active ? "rgba(234, 204, 116, 0.25)" : "transparent",
                            borderBottom: menuItem.active ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                          }}
                          key={'MenuItem_' + menuItem.name}
                          name={menuItem.name}
                          disabled={menuItem.disabled && menuItem.disabled}
                          active={menuItem.active}
                          onClick={() => menuItem.onClick()}
                        >
                          <Image 
                            src={menuItem.icon} 
                          />
                          {menuItem.hasNewNotifications && <NotificationDot />}
                        </Menu.Item>
                      }
                      position="bottom center"
                      content={menuItem.tooltip}
                      disabled={!menuItem.tooltip}
                    />
                  )
                } else {
                  return <div key={'MenuSecondaryItem_' + menuItem.name} />
                }
              })}

              <MenuHelp
                isAdminUser={this.props.isAdminUser}
                db={db}
                getUser={this.getUser}
                params={this.props.params}
                userId={userId}
                tutorialName={this.props.tutorialName}
              />

              {!hideUserMenu &&
                <Dropdown
                  style={{
                    zIndex: 1000,
                  }}
                  icon={null}
                  closeOnChange={true}
                  trigger={
                    <Menu.Item
                      className="menuSecondaryItem"
                      style={{
                        marginTop: 0,
                        height: "55px",
                      }}
                    >
                      <Image style={{ height: 25, marginRight: 8 }} src={iconMenu} />
                      {userName}
                      <Icon style={{ marginLeft: 2, marginRight: 0 }} name='caret down' />
                    </Menu.Item>
                  }
                >
                  <Dropdown.Menu>
                    {this.props.settingsMenuItems && this.props.settingsMenuItems}
                    {this.props.settingsMenuItems && <Dropdown.Divider />}

                    <Dropdown.Item
                      disabled
                      icon="flag outline"
                      text="Svenska"
                    />

                    <Dropdown.Divider />

                    {/* <Dropdown.Item
                      icon="user outline"
                      href="https://accounts.agriopt.se/"
                      target="_blank"
                      rel="noopener noreferrer"
                      text="Hantera konto"
                    /> */}

                    <Dropdown.Item
                      icon="key"
                      onClick={() => this.toggleShowChangePasswordModal()}
                      text="Byt lösenord"
                    />

                    <Dropdown.Item
                      icon="settings"
                      onClick={() => this.toggleShowSettingsModal()}
                      text="Inställningar"
                    />

                    <Dropdown.Divider />

                    <Dropdown.Item
                      icon="law"
                      text="Juridisk information"
                      onClick={() => this.props.history.push("/home/legal")}
                    />

                    <Dropdown.Divider />

                    <Dropdown.Item
                      icon="log out"
                      onClick={() => this.toogleShowConfirmLogout()}
                      text="Logga ut"
                    />
                  </Dropdown.Menu>
                </Dropdown>
              }
            </Menu.Menu>
          </Menu>
        </div>
      </React.Fragment>
    );
  }
}

const withFirebaseData = connect(store => {
  return {
    firebaseData: store.firebase
  };
});

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  withFirebaseData,
  withFirestoreData,
  firestoreConnect(props => {

    let output = [
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "notifications" },
        ],
        storeAs: 'notifications'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "reminders" },
        ],
        storeAs: 'reminders'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "profile" },
        ],
        storeAs: 'profile'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'settings'
      },
      {
        collection: 'users',
        doc: `${props.trueUserId}`,
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'appSettings'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "insights" },
        ],
        storeAs: 'insights'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "messages" },
        ],
        storeAs: 'messages'
      },
    ];

    return output;
  }),
  withRouter,
  withFirebase,
  withFirestoreActions,
)(MenuSecondary);
