import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Icon,
  Grid,
  Segment,
  Header,
  Button,
  Dropdown,
  Modal,
  Input,
} from "semantic-ui-react";

import chroma from "chroma-js";

import FieldCartoon from "@/components/misc/FieldCartoon";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";

import MapWithCircleMarkers from "@/components/map/MapWithCircleMarkers";
import WidgetMenu from "@/components/menus/WidgetMenu";

import { CROP_CODES } from "@/constants/cropCodes";

class HarvestReportGuideVerifyFields extends Component {
  state = {
    allowContinue: false,
  };

  componentDidMount = () => {
    const {
      selectedFieldIds,
    } = this.props;

    if (selectedFieldIds) {
      this.props.setParentState({ currentFieldId: selectedFieldIds[0] });
      this.getYieldMaps();
    };
  };  

  componentDidUpdate = (prevProps, prevState) => {
    const {
      currentFieldId,
      selectedFieldIds,
      fieldIdsProcessed,
      yieldMapsProcessed,
    } = this.props;

    let filteredSelectedFieldIds = selectedFieldIds && fieldIdsProcessed &&
      selectedFieldIds.filter((x) => !fieldIdsProcessed.includes(x));

    if (!currentFieldId && filteredSelectedFieldIds && filteredSelectedFieldIds.length > 0) {
      
      // First field
      this.props.setParentState({ currentFieldId: filteredSelectedFieldIds[0] });
      this.getYieldMaps();
    };

    if (!this.props.yieldMapsYears && (prevProps.yieldMapsProcessed !== yieldMapsProcessed)) {
      // Wait for download of yield map data
      this.getYieldMaps();
    };

    if (currentFieldId && prevProps.currentFieldId && (prevProps.currentFieldId !== currentFieldId)) {
      // Changing to another field
      this.getYieldMaps();
    };
  };

  getYieldMaps = () => {
    const {
      yieldMapsProcessed,
    } = this.props;

    let currentFieldId = this.props.currentFieldId ? 
      this.props.currentFieldId :
      this.props.selectedFieldIds.filter((x) => !this.props.fieldIdsProcessed.includes(x))[0];

    let yieldMaps = yieldMapsProcessed && currentFieldId && yieldMapsProcessed[currentFieldId];
    yieldMaps = yieldMaps && yieldMaps.rawdata_harvest_maps_geojson;

    let yieldMapsYears = yieldMaps && Object.keys(yieldMaps).map(x => x).sort();
    this.props.setParentState({ yieldMapsYears: yieldMapsYears, currentYear: null });

    yieldMapsYears && yieldMapsYears.forEach((x) => this.props.setParentState({ ["geoJsonData_" + x]: null }));
    yieldMapsYears && yieldMapsYears.forEach((x) => this.fetchGeoJson(yieldMaps[x], x));
  };

  fetchGeoJson = (filePath, year) => {
    const {
      fileBucketRef,
    } = this.props;
    
    let stateName = "geoJsonData_" + year;

    filePath && fileBucketRef.child(filePath).getDownloadURL()
      .then(url => {
        window.fetch(url)
          .then(async res => {
            this.props.setParentState({ [stateName]: await res.clone().json() });
            // console.log(await res.clone().text())
          })
          .catch(error => console.log("Cannot get download URL: ", error.toString()))
      })
      .catch(error => console.log("Cannot get download URL: ", error.toString()))
  };
  
  getColorScales = (markers) => {
    let values = markers && markers && markers.map(x => x.properties['dry_yield']);
    values = values && values.filter((x) => typeof(x) === "number");
    let colorLimits = values && chroma.limits(values, "e", 5);
    return values && chroma.scale("Greens").domain(colorLimits);
  };

  toggleShowFullScreenMap = (field, markerData, colorScale) => {
    let currentValue = this.props.showFullScreenMap;

    this.props.setParentState({
      showFullScreenMap: !currentValue,
      fullScreenField: field,
      fullScreenMarkerData: markerData,
      fullScreenColorScales: colorScale,
    });
  };

  onChangeCurrentYear = (newYear) => {
    const {
      yieldMapsYearsUse,
      yieldMapsYearsDoNotUse,
    } = this.props;

    this.props.setParentState({
      yieldMapsYearsDoNotUse: yieldMapsYearsDoNotUse ? yieldMapsYearsDoNotUse.filter((x) => x !== newYear) : [],
      yieldMapsYearsUse: yieldMapsYearsUse ? yieldMapsYearsUse.filter((x) => x !== newYear) : [],
      currentYear: newYear
    });
  };

  estimateHarvestYield = (markerData, field_size) => {
    let allDryYields = markerData && markerData.map((x) => x.properties['dry_yield']);
    let totalDryYield = allDryYields && allDryYields.reduce((partial_sum, a) => partial_sum + a, 0);
    let meanDryYield = totalDryYield && totalDryYield / allDryYields.length;

    return Math.round(meanDryYield * field_size);
  };

  onChange = (data) => {
    this.props.setParentState({ [data.name]: data.value }); 
  };   

  onChangeNumber = (event) => {
    let newValue = event.target.value;
    newValue = newValue && newValue.replace(/[^\d]/g, '');
    this.props.setParentState({ [event.target.name]: newValue });
  };

  onClickUseMap = (currentFieldId, currentYear) => {
    const {
      yieldMapsYearsUse,
      editCropCode,
      editHarvestYield,
      editNitrogenRation,
    } = this.props;

    this.props.setParentState({
      yieldMapsYearsUse: [...yieldMapsYearsUse, currentYear],
    });

    editCropCode && this.updateCropCode(currentFieldId, currentYear);
    editHarvestYield && this.updateHarvestYield(currentFieldId, currentYear);
    editNitrogenRation && this.updateNitrogenRation(currentFieldId, currentYear);
    
    this.onGoToNext(currentFieldId, currentYear);
  };

  updateCropCode = (currentFieldId, currentYear) => {
    const {
      fieldsInfo,
      getUser,
      editCropCode,
    } = this.props;

    let fieldInfo = fieldsInfo && currentFieldId && fieldsInfo[currentFieldId] && fieldsInfo[currentFieldId];
    let currentValue = fieldInfo && fieldInfo.crop_code && fieldInfo.crop_code;

    let newValue = currentValue ? {...currentValue, [currentYear]: editCropCode} : {[currentYear]: editCropCode};

    const docRef = getUser().collection("fieldsInfo").doc(currentFieldId);
    docRef.update({ crop_code: newValue })
  };

  updateHarvestYield = (currentFieldId, currentYear) => {
    const {
      fieldsInfo,
      getUser,
      editHarvestYield,
    } = this.props;

    let fieldInfo = fieldsInfo && currentFieldId && fieldsInfo[currentFieldId] && fieldsInfo[currentFieldId];
    let currentValue = fieldInfo && fieldInfo.harvest_yields && fieldInfo.harvest_yields;

    let newValue = currentValue ? {...currentValue, [currentYear]: editHarvestYield} : {[currentYear]: editHarvestYield};

    const docRef = getUser().collection("fieldsInfo").doc(currentFieldId);
    docRef.update({ harvest_yields: newValue })
  };

  updateNitrogenRation = (currentFieldId, currentYear) => {
    const {
      fieldsInfo,
      getUser,
      editNitrogenRation,
    } = this.props;

    let field = fieldsInfo && currentFieldId && fieldsInfo[currentFieldId] && fieldsInfo[currentFieldId];
    let currentValue = field && field.n_fertilizer && field.n_fertilizer;

    let newSubValue = {0: editNitrogenRation, 1: 0, 2: 0, total: editNitrogenRation};

    const docRef = getUser().collection("fields").doc(currentFieldId);

    if (!currentValue) {
      docRef.update({ n_fertilizer: { [currentYear]: newSubValue }})
    } else {
      docRef.update({ n_fertilizer: {...currentValue, [currentYear]: newSubValue}})
    }
  };

  onClickDoNotUseMap = (currentFieldId, currentYear) => {
    const {
      getUser,
      yieldMapsYearsDoNotUse,
    } = this.props;

    let reformattedCurrentYear = currentFieldId && typeof(currentYear) === "string" ? currentYear : currentYear.toFixed(0)

    reformattedCurrentYear && getUser().collection("fieldsYieldMapsUpload").get()
      .then((docRef) => {
        Object.keys(docRef).forEach((x) => {
          getUser().collection("fieldsYieldMapsUpload").doc(x).get()
          .then((currentDoc) => {
            if (currentDoc.year === reformattedCurrentYear && currentDoc.fields_in_file.includes(currentFieldId)) {
              getUser().collection("fieldsYieldMapsUpload").doc(x).update({
                do_not_use_in_analysis: true
              })
            }
          })
        })
      });

    this.props.setParentState({
      yieldMapsYearsDoNotUse: [...yieldMapsYearsDoNotUse, currentYear],
    });

    this.onGoToNext(currentFieldId, currentYear);
  };

  onGoToNext = (currentFieldId, currentYear) => {
    const {
      selectedFieldIds,
      fieldIdsProcessed,
      yieldMapsYears,
      yieldMapsYearsProcessed,
    } = this.props;

    let newYearsProcessed = [...yieldMapsYearsProcessed, currentYear];
    let remainingYears = yieldMapsYears.filter((x) => !newYearsProcessed.includes(x));

    this.props.onSaveState();

    if (remainingYears.length > 0) {
      // Move to next year on current field 

      this.props.setParentState({
        yieldMapsYearsProcessed: newYearsProcessed,
        currentYear: remainingYears[0],
        editCropCode: null,
        editHarvestYield: null,
        editNitrogenRation: null,
      })

    } else {

      // Move to first year on next field 
      this.props.createHarvestReport(currentFieldId);
      
      let newFieldsProcessed = [...fieldIdsProcessed, currentFieldId];
      let remainingFields = selectedFieldIds.filter((x) => !newFieldsProcessed.includes(x));

      if (remainingFields.length > 0) {
        this.props.setParentState({
          fieldIdsProcessed: newFieldsProcessed,
          currentFieldId: remainingFields[0],
          currentYear: null,
          yieldMapsYears: null,                    
          yieldMapsYearsProcessed: [],
          yieldMapsYearsDoNotUse: [],
          yieldMapsYearsUse: [],
          editCropCode: null,
          editHarvestYield: null,
          editNitrogenRation: null,
        })
      } else {

        // All fields and all years are processed
        this.setState({
          allowContinue: true,
        })

        this.props.setParentState({
          fieldIdsProcessed: [...fieldIdsProcessed, currentFieldId],
          currentFieldId: null,
          currentYear: null,
          yieldMapsYears: null,                    
          yieldMapsYearsProcessed: [],
          yieldMapsYearsDoNotUse: [],
          yieldMapsYearsUse: [],
          editCropCode: null,
          editHarvestYield: null,
          editNitrogenRation: null,            
        })
      };
    };
  };

  openField = (fieldId) => {
    if (!this.props.fieldIdsProcessed.includes(fieldId)) {
      this.props.setParentState({
        currentFieldId: fieldId,
        currentYear: null,
        yieldMapsYears: null,                    
        yieldMapsYearsProcessed: [],
        yieldMapsYearsDoNotUse: [],
        yieldMapsYearsUse: [],
        editCropCode: null,
        editHarvestYield: null,
        editNitrogenRation: null,      
      });
    };
  };

  getFieldCards = (fieldId) => {
    const {
      fields,
      currentFieldId,
      fieldIdsProcessed,
    } = this.props;

    let fieldName = fields && fieldId && fields[fieldId] && fields[fieldId].name;
    let position = fields && fieldId && fields[fieldId] && fields[fieldId].position;

    if (currentFieldId === fieldId) {
      return null;
    };

    return (
      <Segment 
        className={fieldIdsProcessed && fieldIdsProcessed.includes(fieldId) ? null : "fieldCardOnHover"}
        onClick={e => this.openField(fieldId)}      
        style={{
          padding: "1em",
          cursor: fieldIdsProcessed && fieldIdsProcessed.includes(fieldId) ? null : "pointer",
        }}
      >
         <div
            style={{
              display: "flex", 
            }}
          >

            <div>
              {position && 
                <FieldCartoon
                  className="fieldCartoon"
                  style={{
                    margin: 0,
                    padding: 0,
                    height: "60px",
                    width: "60px",
                    zIndex: 20,
                  }}            
                  color={fieldIdsProcessed && fieldIdsProcessed.includes(fieldId) ? "#89A275" : "#e0ba8c"}
                  position={position}
                />
              }    
            </div>

            <div 
              style={{
                marginLeft: 20,
                marginTop: "auto",
                marginBottom: "auto",                  
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
              <Header
                style={{ 
                  fontWeight: 500,
                  paddingTop: "5px",
                  paddingBottom: 0,
                  marginBottom: 0,
                }}
              >
                {fieldName && fieldName}
              </Header>
              <p>{fieldIdsProcessed && fieldIdsProcessed.includes(fieldId) ? "Skiftet kontrollerat. Skördeöversikt skapas..." : "Skiftet kvar att kontrollera"}</p>
              </div>

              <Icon 
                style={{ 
                  color: fieldIdsProcessed && fieldIdsProcessed.includes(fieldId) ? "#89A275" : "#e0ba8c"
                }} 
                name={fieldIdsProcessed && fieldIdsProcessed.includes(fieldId) ? "check circle" : "circle"}
                size="big"
              />        
            </div>
          </div>
      </Segment>
    )
  };

  render() {
    const {
      fields,
      fieldsInfo,
      selectedFieldIds,
      currentFieldId,
      yieldMapsYears,
      yieldMapsYearsUse,
      yieldMapsYearsDoNotUse,
      showFullScreenMap,
      fullScreenField,
      fullScreenMarkerData,
      fullScreenColorScales,
      editCropCode,
      editHarvestYield,
      editNitrogenRation, 
    } = this.props;

    const {
      allowContinue
    } = this.state;

    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickGoToStep1,
        }
      ],      
      next: [
        {
          caption: "Gå vidare",
          onClick: this.props.onClickGoToStep3,
          disabled: !allowContinue,
        }
      ],
    };

    let currentYear = this.props.currentYear ? this.props.currentYear : this.props.yieldMapsYears && this.props.yieldMapsYears[0];

    let widgetMenuItems = yieldMapsYears && yieldMapsYearsUse && yieldMapsYearsDoNotUse &&
      yieldMapsYears.map((x) => {
      let background = yieldMapsYearsUse.includes(x) ? "green" : (yieldMapsYearsDoNotUse.includes(x) ? "red" : "null");
      background = currentYear === x ? "yellow" : background;

      return (
        {
          id: x,
          onClick: this.onChangeCurrentYear,
          caption: x,
          active: currentYear === x,
          color: (yieldMapsYearsUse.includes(x)) ? "white" : (yieldMapsYearsDoNotUse.includes(x) ? "white" : null),
          background: background,
        }
      )
    });

    let field = fields && currentFieldId && fields[currentFieldId];
    let fieldInfo = fieldsInfo && currentFieldId && fieldsInfo[currentFieldId];

    let fieldSize = field && field.field_size && field.field_size;
    let markerData = currentYear && this.props["geoJsonData_" + currentYear] && this.props["geoJsonData_" + currentYear].features;
    let colorScale = markerData && this.getColorScales(markerData);
    
    let currentCropCode = (fieldInfo && currentYear && fieldInfo.crop_code && fieldInfo.crop_code[currentYear]) ? 
      fieldInfo.crop_code[currentYear] : "xx";
    
    let currentNitrogenRation = fieldInfo && currentYear &&  fieldInfo.n_fertilizer &&
    fieldInfo.n_fertilizer[currentYear] ? 
    fieldInfo.n_fertilizer[currentYear] : 
      "xx";
    currentNitrogenRation = currentNitrogenRation && currentNitrogenRation.total && currentNitrogenRation.total;

    let currentHarvestYield = (fieldInfo && currentYear && fieldInfo.harvest_yields && fieldInfo.harvest_yields[currentYear]) ? 
      fieldInfo.harvest_yields[currentYear] : 0;
    currentHarvestYield = currentHarvestYield ? currentHarvestYield : this.estimateHarvestYield(markerData, fieldSize);

    let mainColumn = 
      <React.Fragment>
        {/* <Segment 
          secondary
          style={{
            padding: "2em",
          }}
        >
          <Header 
            as="h2"
            style={{
              fontWeight: 500,
              marginBottom: 20,
            }}
          >
            {allowContinue ? "Du har kontrollerat alla skiften" : "Skiften att kontrollera"}
          </Header>
          {allowContinue &&
            <p 
              style={{ 
                whiteSpace: "pre-line", 
                fontSize: "110%",
              }}
            >
              Du har kontrollerat skördekartorna för alla skiften. Klicka på knappen "nästa" för att fortsätta.
            </p>
          }                    
          {!allowContinue &&
            <p 
              style={{ 
                whiteSpace: "pre-line", 
                fontSize: "110%",
              }}
            >
              Du har {noFieldsLeft && noFieldsLeft.toFixed(0)} skifte(n) kvar att kontrollera skördekartorna för: {" "}
              {fieldNames.map((x, idx) => x + (idx === fieldNames.length - 1 ? "." : ", "))}
          </p>
        }
        </Segment> */}

        {currentFieldId &&
          <Segment 
            loading={!markerData}
            style={{
              padding: "2em"
            }}
          >
            <Header 
              as="h2"
              style={{
                fontWeight: 500,
                marginBottom: 20,
              }}
            >
              {field && field.name} ({fieldSize && fieldSize} ha)
            </Header>   

            {widgetMenuItems && <WidgetMenu menuItems={widgetMenuItems} backgroundColor="#f7f4f0" />}                    
            
            <Grid>
              <Grid.Row 
                columns="3"
                style={{ 
                  marginTop: "2em",
                }}
              >
                <Grid.Column width={6}>
                  <MapWithCircleMarkers
                    field={field && field}
                    markers={markerData && markerData}
                    allMarkers={markerData && markerData}
                    colorScale={colorScale && colorScale}
                    markerScale={0.5}                                  
                    onMarkerClick={() => {}}
                    //selectedMarkers={selectedMarkers}
                    geoJsonFieldKey={'dry_yield'}
                    showMissingData={true}
                    lockedMap={true}
                    showArealMap={true}
                    mapStyle={{
                      top: 0,
                      height: "400px",
                    }}
                    mapOptions={{
                      boundsOptions: { padding: [20, 20] },
                    }}
                  >
                    {!showFullScreenMap &&
                      <Button 
                        icon 
                        size="large" 
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          zIndex: 10000,
                        }}
                        onClick={() => this.toggleShowFullScreenMap(field, markerData, colorScale)}
                      >
                        <Icon name="expand" />
                      </Button>
                    }
                  </MapWithCircleMarkers>
                </Grid.Column>    

                <Grid.Column width={7}>
                  <Segment secondary style={{ padding: "2em", height: "22vw" }}>
                    <Header
                      as="h3"
                      style={{
                        fontWeight: 500,
                        marginBottom: "2em",
                      }}
                    >
                      Skiftesinformation för {currentYear && currentYear}
                    </Header>

                    <p 
                      style={{ 
                        whiteSpace: "pre-line", 
                        fontSize: "110%", 
                        fontWeight: 600,
                        marginBottom: 5,
                      }}
                    >
                      Grödkod
                    </p>
                    
                    <Dropdown
                      fluid
                      search
                      selection
                      name="editCropCode"
                      options={CROP_CODES}
                      closeOnChange={true}
                      value={editCropCode ? editCropCode : currentCropCode}
                      onChange={(event, data) => this.onChange(data)}
                    />                       
                    
                    
                    <p 
                      style={{ 
                        marginTop: 30,
                        whiteSpace: "pre-line", 
                        fontSize: "110%", 
                        fontWeight: 600,
                        marginBottom: 5,
                      }}
                    >
                      Kvävegiva i kg N/ha (valfritt)
                    </p>  
                    
                    <Input
                      fluid
                      name="editNitrogenRation"
                      value={editNitrogenRation ? editNitrogenRation : currentNitrogenRation}
                      onChange={(e) => this.onChangeNumber(e)}
                    />

                    <p 
                      style={{ 
                        marginTop: 30,
                        whiteSpace: "pre-line", 
                        fontSize: "110%", 
                        fontWeight: 600,
                        marginBottom: 5,
                      }}
                    >
                      Total skörd in ton (valfritt)
                    </p>

                    <Input
                      fluid
                      name="editHarvestYield"
                      value={editHarvestYield ? editHarvestYield : currentHarvestYield}
                      onChange={(e) => this.onChangeNumber(e)}
                    />
                  </Segment>
                </Grid.Column>
                    
                <Grid.Column width={3}>
                  <Button
                    icon
                    fluid
                    style={{
                      backgroundColor: "#89A275",
                      color: "white",
                    }}
                    labelPosition="left"
                    onClick={() => this.onClickUseMap(currentFieldId, currentYear)}
                  >
                    <Icon name="check" />
                    Godkänn
                  </Button>
                  
                  <div style={{ height: "1em" }} />

                  <Button
                    icon
                    fluid
                    style={{
                      backgroundColor: "#767676",
                      color: "white",
                    }}
                    labelPosition="left"
                    onClick={() => this.onClickDoNotUseMap(currentFieldId, currentYear)}
                  >
                    <Icon name="delete" />
                    Använd inte
                  </Button>
                </Grid.Column>                        
                <Grid.Row>
              </Grid.Row>                        
              </Grid.Row>
            </Grid>                        
          </Segment>
        }
        
        {selectedFieldIds && selectedFieldIds.map((x) => this.getFieldCards(x))}
      </React.Fragment>

    let helpColumn =
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Kontrollera skördekartor
        </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
      >
          Kontrollera att varje års kartor har lästs in korrekt och
          ser rimliga ut. Vissa av kartorna kanske du anser inte
          håller tillräcklig kvalitet. Klicka då på "Använd inte".
      </p>

      <Header
        as="h3"
        style={{
          fontWeight: "normal",
          whiteSpace: "pre-line",
        }}
      >
        Grödkod
      </Header>
      <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
      >
          Kontrollera att rätt grödkod är angiven.
      </p>

      <Header
        as="h3"
        style={{
          fontWeight: "normal",
          whiteSpace: "pre-line",
        }}
      >
        Kvävegiva
      </Header>
      <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
      >
          Fyll i information kvävegiva om du har informationen tillgänglig.
          Kvävegivan använder vi för att räkna ut hur mycket du
          kan spara på att använda en variabel giva.
          Lämnar du detta fält tomt använder vi Jordbruksverkets rekommendationer.
      </p>

      <Header
        as="h3"
        style={{
          fontWeight: "normal",
          whiteSpace: "pre-line",
        }}
      >
        Total skörd
      </Header>
      <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
      >
          Kontrollera den totala skörden och ändra vid behov.
          Denna information använder vi samtidigt för att kalibrera skördekartorna så att totalskörden blir korrekt.
      </p>
    </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        // mainColumnHeader="Kontrollera och godkänd skördekartorna för varje skifte"
        helpColumn={helpColumn}
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        menuItems={menuItems}
      >     

        <Modal 
          basic 
          open={showFullScreenMap} 
          size='fullscreen' 
        >
          <MapWithCircleMarkers
            field={fullScreenField && fullScreenField}
            markers={fullScreenMarkerData && fullScreenMarkerData}
            allMarkers={fullScreenMarkerData && fullScreenMarkerData}
            colorScale={fullScreenColorScales && fullScreenColorScales}
            markerScale={0.5}                                  
            onMarkerClick={() => {}}
            //selectedMarkers={selectedMarkers}
            geoJsonFieldKey={'dry_yield'}
            showMissingData={true}
            lockedMap={false}
            showArealMap={true}
            mapStyle={{
              top: 0,
              height: "95vh",
            }}
            mapOptions={{
              boundsOptions: { padding: [20, 20] },
            }}            
          >
            <Button 
              icon 
              size="large" 
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 10000,
              }}
              onClick={() => this.toggleShowFullScreenMap(null, null, null)}
            >
              <Icon name="close" />
            </Button>   
          </MapWithCircleMarkers>          
        </Modal>
        
      </GuidesBasicGrid>
    )
  }
}

export default withRouter(HarvestReportGuideVerifyFields);