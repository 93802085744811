import React, { Component } from "react";

import { 
  Segment,
  Image, 
  Loader,
  Header,
} from "semantic-ui-react";

import BackgroundImage from "@/assets/backgrounds/bruno-martins-442354-unsplash-small.jpg";

class BasicPageLayout extends Component {
  
  render() { 

    const {
      imageSrc,
      showLoader,
      caption,
      description,
    } = this.props;

    return (
      <div 
        style={{
          height: "100vh",
          paddingLeft: "20%",
          paddingRight: "20%",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "hidden"          
        }}
      >
        <div
          style={{
            height: "60%",
            marginTop: "20vh",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}
        >
          <Segment
            style={{
              width: "50%",
              height: "100%",
              padding: "2em",
            }}
          >
            {showLoader &&
              <div
                style={{
                  width: "67%",
                }}            
              >
                <Loader 
                  active 
                  indeterminate 
                  size="massive"
                >
                  <div
                    style={{
                      fontSize: "80%", 
                      marginTop: "0.5em",
                      color: "grey",
                      }}
                    >
                    {caption && caption}
                  </div>
                </Loader>            
              </div>   
            }     

            {this.props.children && this.props.children}
          </Segment>

          <Segment
            style={{
              width: "50%",
              height: "90%",
              padding: "2em",
              marginTop: "auto",
              marginBottom: "auto",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: "#f7f4f0",
            }}
          > 
            <Image 
              style={{
                height: showLoader ? "80%" : "70%",
                marginTop: showLoader ? "5%" : null,
                marginBottom: showLoader ? "15%" : null,
                transition: "height 0.3s",
              }}            
              centered 
              src={imageSrc}             
            />

            {!showLoader && caption &&
              <Header
                as="h2"
                textAlign="center"
                color="grey"
                style={{
                  fontWeight: 500,
                }}
              >
                {caption}
              </Header>  
            }
            
            {!showLoader && description &&
              <p
                style={{
                  marginBottom: "1em",
                  textAlign: "center",
                  width: "80%",
                  marginLeft: "10%",
                  whiteSpace: "pre-line",
                }}
              >
                {description}
              </p>
            }
          </Segment>
        </div>
      </div>
    );
  }
}

export default BasicPageLayout;
