import React, { Component } from "react"

import { 
  Header, 
  Segment,
  Button,
  Confirm,
} from "semantic-ui-react";

import { toast } from "react-toastify";

import { cloudFunctions } from "@";

class FieldManagementDeveloper extends Component {
  state = {
    confirmationDialogHeader: '',
    confirmationDialogText: '',
    onConfirm: () => {},
    confirmDialogOpen: false,    
  };

  getConfirmation = (type) => {
    const {
      field
    } = this.props;

    let name = field && field.name ? field.name : 'okänt namn';

    switch(type) {
      case 'get_swedish_soil_maps':
        this.setState({
          confirmationDialogButtonText: 'Hämta om digitala åkermarkskartan',
          confirmationDialogText: 'Vill du verkligen hämta om den digitala åkermarkskartan för skiftet: ' + name + '. Detta ska endast göras om den inte dyker upp under tolka. Det tar ca 10 minuter för markkartorna att hämtas igen.',
          onConfirm: this.onGetSwedishSoilMaps,
          confirmDialogOpen: true,
        })
        break;

      case 'process_swedish_soil_maps':
        this.setState({
          confirmationDialogButtonText: 'Bearbeta om digitala åkermarkskartan',
          confirmationDialogText: 'Vill du verkligen bearbeta om den digitala åkermarkskartan för skiftet: ' + name + '. Detta ska endast göras om den inte dyker upp under tolka. Det tar ca 10 minuter för markkartorna att hämtas igen.',
          onConfirm: this.onProcessSwedishSoilMaps,
          confirmDialogOpen: true,
        })
        break;

        case 'get_uploaded_soil_maps':
          this.setState({
            confirmationDialogButtonText: 'Hämta om markkarteringar',
            confirmationDialogText: 'Vill du verkligen hämta om alla uppladdade markkarteringar för skiftet: ' + name + '. Detta ska endast göras om de inte dyker upp under tolka. Det tar ca 10 minuter för markkartorna att hämtas igen.',
            onConfirm: this.onGetUploadedSoilMaps,
            confirmDialogOpen: true,
          })
          break;
  
        case 'process_uploaded_soil_maps':
          this.setState({
            confirmationDialogButtonText: 'Bearbeta om markkarteringar',
            confirmationDialogText: 'Vill du verkligen bearbeta om alla upploaddade markkarteringar för skiftet: ' + name + '. Detta ska endast göras om de inte dyker upp under tolka. Det tar ca 10 minuter för markkartorna att hämtas igen.',
            onConfirm: this.onProcessUploadedSoilMaps,
            confirmDialogOpen: true,
          })
          break;        

        case 'segment_uploaded_soil_maps':
          this.setState({
            confirmationDialogButtonText: 'Tolka om markkarteringar',
            confirmationDialogText: 'Vill du verkligen tolka om alla upploaddade markkarteringar för skiftet: ' + name + '. Detta ska endast göras om de inte dyker upp under tolka. Det tar ca 10 minuter för markkartorna att hämtas igen.',
            onConfirm: this.onSegmentUploadedSoilMaps,
            confirmDialogOpen: true,
          })
          break; 

      case 'get_sentinel':
        this.setState({
          confirmationDialogButtonText: 'Hämta om data från Sentinel Hub',
          confirmationDialogText: 'Vill du verkligen hämta om alla satellitbilder för skiftet: ' + name + '. Detta ska endast göras om grönmassa, fuktighet eller satellitbilder inte dyker upp under utforska. Det tar ca en timme att hämta och tolka informationen igen.',
          onConfirm: this.onGetSentinel,
          confirmDialogOpen: true,
        })                
        break;
      
      case 'process_sentinel':
        this.setState({
          confirmationDialogButtonText: 'Bearbeta om satellitbilder',
          confirmationDialogText: 'Vill du verkligen bearbeta om alla satellitbilder för skiftet: ' + name + '. Detta ska endast göras om grönmassa, fuktighet eller satellitbilder inte dyker upp under utforska. Det tar ca en timme att tolka informationen igen.',
          onConfirm: this.onProcessSentinel,
          confirmDialogOpen: true,
        })                  
        break;

      case 'segment_sentinel':
        this.setState({
          confirmationDialogButtonText: 'Tolka om satellitbilder',
          confirmationDialogText: 'Vill du verkligen tolka om alla satellitbilder för skiftet: ' + name + '. Detta ska endast göras om grönmassa, fuktighet eller satellitbilder inte dyker upp under tolka. Det tar ca en timme att tolka informationen igen.',
          onConfirm: this.onSegmentSentinel,
          confirmDialogOpen: true,
        })                  
        break;            

      case 'get_harvest':
        this.setState({
          confirmationDialogButtonText: 'Hämta om skördekartor',
          confirmationDialogText: 'Vill du verkligen hämta om alla skördekartor för skiftet: ' + name + '. Detta ska endast göras om skördekartorna inte dyker upp under utforska.',
          onConfirm: this.onGetHarvest,
          confirmDialogOpen: true,
        })                
        break;        

      case 'process_harvest':
        this.setState({
          confirmationDialogButtonText: 'Bearbeta om skördekartor',
          confirmationDialogText: 'Vill du verkligen bearbeta om alla skördekartor för skiftet: ' + name + '. Detta ska endast göras om skördekartorna inte dyker upp under utforska.',
          onConfirm: this.onProcessHarvest,
          confirmDialogOpen: true,
        })                  
        break;

      case 'segment_harvest':
        this.setState({
          confirmationDialogButtonText: 'Tolka om skördekartor',
          confirmationDialogText: 'Vill du verkligen tolka om alla skördekartor för skiftet: ' + name + '. Detta ska endast göras om skördekartorna inte dyker upp under tolka.',
          onConfirm: this.onSegmentHarvest,
          confirmDialogOpen: true,
        })                  
        break;     

      default:
          break;
    };
  };

  resetConfirmDialog = () => {
    this.setState({
      confirmationDialogButtonText: '', 
      confirmationDialogText: '', 
      onConfirm: this.emptyFunction, 
      confirmDialogOpen: false,
    });
  };

  onGetSwedishSoilMaps = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callDatahubMaps')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/parseSwedishSoilMap"
      }
    )
    .then(() => {
      let text = "Freja hämtar och bearbetar om den digitala åkermarkskartan för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Getting soil maps for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  onProcessSwedishSoilMaps = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callAnalyticsEngine')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/swedishSoilmapProcess",
        forceUpdate: true,
        clearDb: true,
      }
      )
    .then(() => {
      let text = "Freja bearbetar om den digitala åkermarkskartan för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Processing soil maps for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };


  onGetUploadedSoilMaps = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callDatahubMaps')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/parseUploadedSoilMap"
      }
      )
    .then(() => {
      let text = "Freja hämtar och bearbetar om uppladdade markkarteringar för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Getting soil maps for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  onProcessUploadedSoilMaps = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callAnalyticsEngine')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/uploadedSoilmapProcess",
        forceUpdate: true,
        clearDb: true,
      }
      )
    .then(() => {
      let text = "Freja bearbetar om uppladdade markkarteringar för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Processing soil maps for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  onSegmentUploadedSoilMaps = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callAnalyticsEngine')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/uploadedSoilmapSegment",
        forceUpdate: true,
        clearDb: true,
      }
      )
    .then(() => {
      let text = "Freja tolkar om uppladdade markkarteringar för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Segmenting soil maps for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  onGetSentinel = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callDatahubSentinel')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/initOpticalData",
        forceUpdate: true,
        clearDb: true,        
      }
    )
    .then(() => {
      let text = "Freja hämtar och bearbetar om bilder från Sentinel Hub för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Getting sentinel data for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  onProcessSentinel = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callAnalyticsEngine')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/sentinelProcess",
        forceUpdate: true,
        clearDb: true,        
      }
      )
    .then(() => {
      let text = "Freja bearbetar om bilder från Sentinel Hub för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Processing sentinel data for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  onSegmentSentinel = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callAnalyticsEngine')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/sentinelSegment",
        forceUpdate: true,
        clearDb: true,    
      }
      )
    .then(() => {
      let text = "Freja tolkar om bilder från Sentinel Hub för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Segmenting sentinel data for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  onGetHarvest = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callDatahubHarvest')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/processYieldMaps/field",
        forceUpdate: true,
        clearDb: true,    
      }
      )
    .then(() => {
      let text = "Freja hämtar och bearbetar om skördekartor för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Getting harvest map data for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  onProcessHarvest = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callAnalyticsEngine')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/yieldMapProcess",
        forceUpdate: true,
        clearDb: true,           
      }
      )
    .then(() => {
      let text = "Freja bearbetar om skördekartor för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Processing harvest maps for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  onSegmentHarvest = () => {
    const {
      field,
      userId,
      fieldId,
    } = this.props;
      
    this.resetConfirmDialog();

    cloudFunctions.httpsCallable('callAnalyticsEngine')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/yieldMapSegment",
        forceUpdate: true,
        clearDb: true,           
      }
      )
    .then(() => {
      let text = "Freja tolkar om skördekartor för skiftet: " + (field && field.name && field.name) + ".";
      toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
    })
    .catch(error => {
      console.log('Segmenting harvest maps for field: ' + fieldId + ' failed.');
      console.log(error)
    });
  };

  render() {
    const {
      field,
    } = this.props;

    const {
      confirmationDialogText,
      confirmationDialogButtonText,
      onConfirm,
      confirmDialogOpen,      
    } = this.state;

    

    if (!field) {
      return <div></div>
    }
    
    return (
      <React.Fragment>
        <Confirm
            open={confirmDialogOpen}
            onCancel={this.resetConfirmDialog}
            onConfirm={onConfirm}
            header='Bekräfta åtgärd'
            content={confirmationDialogText}
            cancelButton="Avbryt"
            confirmButton={confirmationDialogButtonText}
            size="small"
        />

        <Segment
          style={{
            width: "70%",
            marginLeft: "15%",
            padding: "1.5em",
            marginBottom: "2em",
          }}
        >
          <Header 
            as="h2" 
            style={{ 
              fontWeight: 500 
            }}
          >
            Hantera datatyper
          </Header>

          <p 
            style={{ 
              width: "67%", 
              whiteSpace: "pre-line" 
            }}
          >
            Dessa funktioner kan du använda om något är fel i tabellen med tillgänglig data ovan. Det kan exempelvis vara om någon datatyp har fastnat i bearbetning eller om datalagret inte visas upp rätt i utforska eller tolka inte visas upp korrekt i Freja. Tryck på den knappen som motsvarar det steget där Freja har stannat.
          </p>
        </Segment>
          
        <Segment
          style={{
            width: "70%",
            marginLeft: "15%",
            padding: "1.5em",
            marginBottom: "2em",
          }}
        >
          <Header
            as="h2"
            style={{
              fontWeight: "500",
              marginBottom: 2,
            }}
          >
            Sentinel Hub
          </Header>  
          
          <p>
            Denna data används för att beräkna grönmassa, fuktighet samt att visa färgbilder av ditt skifte.
          </p>

          <div
            style={{
              marginTop: "1.5em",
              width: "70%",
              marginLeft: "15%",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              style={{
                width: "25%",
              }}
              onClick={() => this.getConfirmation('get_sentinel')}
              negative
            >
              Hämta data
            </Button>

            <Button
              style={{
                width: "25%",
              }}
              onClick={() => this.getConfirmation('process_sentinel')}
              primary
            >
              Bearbeta data
            </Button>

            <Button
              style={{
                width: "25%",
              }}
              onClick={() => this.getConfirmation('segment_sentinel')}
            >
              Tolka data
            </Button>  
          </div>
        </Segment>

        <Segment
          style={{
            width: "70%",
            marginLeft: "15%",
            padding: "1.5em",
            marginBottom: "2em",
          }}
        >
          <Header
            as="h2"
            style={{
              fontWeight: "500",
              marginBottom: 2,
            }}
          >
            Digitala åkermarkskartan
          </Header>  

          <p>
            Denna data kommer från SGU och innehåller information om ler-, sand, silthalt samt textur.
          </p>

          <div
            style={{
              marginTop: "1.5em",
              width: "70%",
              marginLeft: "15%",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              style={{
                width: "25%",
              }}
              onClick={() => this.getConfirmation('get_swedish_soil_maps')}
              negative
            >
              Hämta data
            </Button>

            <Button
              style={{
                width: "25%",
              }}
              onClick={() => this.getConfirmation('process_swedish_soil_maps')}
              primary
            >
              Bearbeta data
            </Button>

            <div style={{ width: "25%" }} />
          </div>
        </Segment>

          <Segment
            style={{
              width: "70%",
              marginLeft: "15%",
              padding: "1.5em",
              marginBottom: "2em",
            }}
          >
            <Header
              as="h2"
              style={{
                fontWeight: "500",
                marginBottom: 2,
              }}
            >
              Uppladdade markkarteringar
            </Header>  

            <p>
              Denna data har du själv laddat upp och innehåller oftast bland annat pH-värde samt mängden näringsämnen i jorden.
            </p>

            <div
              style={{
                marginTop: "1.5em",
                width: "70%",
                marginLeft: "15%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                style={{
                  width: "25%",
                }}
                onClick={() => this.getConfirmation('get_uploaded_soil_maps')}
                negative
              >
                Hämta data
              </Button>

              <Button
                style={{
                  width: "25%",
                }}
                onClick={() => this.getConfirmation('process_uploaded_soil_maps')}
                primary
              >
                Bearbeta data
              </Button>

              <Button
                style={{
                  width: "25%",
                }}
                onClick={() => this.getConfirmation('segment_uploaded_soil_maps')}
              >
                Tolka data
              </Button>  
            </div>
          </Segment>

          <Segment
            style={{
              width: "70%",
              marginLeft: "15%",
              padding: "1.5em",
              marginBottom: "2em",
            }}
          >
            <Header
              as="h2"
              style={{
                fontWeight: "500",
                marginBottom: 2,
              }}
            >
              Uppladddade skördekartor
            </Header>  

            <p>
              Denna data har du själv laddat upp används till tolkningar och styrfiler.
            </p>

            <div
              style={{
                marginTop: "1.5em",
                width: "70%",
                marginLeft: "15%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                style={{
                  width: "25%",
                }}
                onClick={() => this.getConfirmation('get_harvest')}
                negative
              >
                Hämta data
              </Button>

              <Button
                style={{
                  width: "25%",
                }}
                onClick={() => this.getConfirmation('process_harvest')}
                primary
              >
                Bearbeta data
              </Button>

              <Button
                style={{
                  width: "25%",
                }}
                onClick={() => this.getConfirmation('segment_harvest')}
              >
                Tolka data
              </Button>  
            </div>            
          </Segment>                                               
        </React.Fragment>
    );
  }
}

export default FieldManagementDeveloper;
