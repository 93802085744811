import React, { Component } from "react";

import {
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import LoaderModal from "@/components/modals/LoaderModal";

import FileList from "@/components/fieldManagement/FieldManagementCollectedData/fileList";

const INITIAL_STATE = {};

class FieldManagementCollectedData extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {
    const {
      db,
      fileBucketRef,
      getUser,
      fieldId,
      userId,
    } = this.props;

    const fields = db && db.fields;
    const fieldsInfo = db && db.fieldsInfo;
    
    const rawSentinelMaps = db && fieldId && db["fieldsSentinelHubRaw_" + fieldId];
    const processedSentinelMaps = db && fieldId && db["fieldsSentinelHubProcessed_" + fieldId];

    let hasLoadedData =
      isLoaded(db) &&
      !isEmpty(db) &&
      isLoaded(fields) &&
      !isEmpty(fields);

    if (hasLoadedData) {
      return (
        <FileList 
          userId={userId}
          getUser={getUser}
          fileBucketRef={fileBucketRef}
          fields={fields}
          fieldsInfo={fieldsInfo}
          fieldId={fieldId}
          rawSentinelMaps={rawSentinelMaps}
          processedSentinelMaps={processedSentinelMaps}
        /> 
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      );
    }
  }
};

export default FieldManagementCollectedData;
