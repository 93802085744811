import React, { Component } from "react";

import { 
  Icon, 
  Message, 
  Popup as ToolTip,
  Segment,
  Select,
} from "semantic-ui-react";

import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";

import { COLOR_SCALE_TYPES } from "@/constants/map";

const NO_SLIDER_STEPS = 25;

const INITIAL_STATE = {
  colorScaleType: "e",
  numberOfGroups: 3
};

class MapFilteringWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = () => {
    this.getMinMax();
  }

  componentDidUpdate = (prevProp) => {
    const {
      markers,
      geoJsonFieldKey,
    } = this.props;
    
    if (prevProp.markers !== markers || prevProp.geoJsonFieldKey !== geoJsonFieldKey) {
      this.getMinMax();
    };   
  };

  getMinMax = () => {
    const { markers, geoJsonFieldKey } = this.props;

    let markerData = markers && markers.filter(o => o.properties).map(o => typeof(o.properties[geoJsonFieldKey]) === "number" && o.properties[geoJsonFieldKey]);
    let minValue = markerData && +Math.min.apply(Math, markerData);
    let maxValue = markerData && +Math.max.apply(Math, markerData);

    this.setState({
      minLimit: minValue,
      maxLimit: maxValue,
    })
  };

  onRangeChange = value => {
    this.props.onSave({
      intervalFilter: {
        min: value[0],
        max: value[1],
      },
      isUsingFiltering: true,
    });
  };

  onSemanticValueChange = field => (e, data) => {
    this.props.onSave({
      [field]: data.value,
      isUsingFiltering: true,
    });
  };

  onNumberOfGroupsChange = value => {
    this.props.onSave({
      numberOfGroups: value + 2,
      isUsingFiltering: true,
    });
  };

  render() {
    const { 
      minLimit, 
      maxLimit, 
    } = this.state;

    const {
      intervalFilter,
      colorScaleType,
    } = this.props;

    let numberOfGroups = this.props.numberOfGroups && this.props.numberOfGroups - 2;
    let min = intervalFilter && intervalFilter.min;
    min = min ? min : minLimit;

    let max = intervalFilter && intervalFilter.max;
    max = max ? max : maxLimit;

    let stepSize = (maxLimit - minLimit) / NO_SLIDER_STEPS;
    
    // eslint-disable-next-line
    let hasValidRangeInput = !Number.isNaN(+min) && !Number.isNaN(+max) !== NaN && min < max;

    if (["potential", "potentialWinterWheat", "deviations", "clustering", "segmentationVariance", "segmentationMedian"].includes(this.props.layer)) {
      return (
        <Segment
          style={{
            padding: 0,
            border: "0.5px lightgrey solid",
            borderRadius: 5,
            // backgroundColor: "#f7f4f0"
          }}
        >
          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 0,
              padding: "1em",
              paddingBottom: 0,
            }}
          >
          <p
            style={{
              fontSize: "110%",
            }}
          >
            Filtrering
          </p>
        </div>

        <div
          style={{
            color: "white",
            whiteSpace: "normal",
            marginBottom: "1em"
          }}
        >
          Det finns ingen filtrering att göra för detta datalager.
        </div>
      </Segment>
      );
    }

    return (
      <Segment
        style={{
          padding: "0.5em",
          paddingTop: 0,
          border: "0.5px lightgrey solid",
          borderRadius: 5,
          // backgroundColor: "#f7f4f0"
        }}
      >
        <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 0,
            padding: "1em",
            paddingBottom: 0,
          }}
        >
        <p
          style={{
            fontSize: "110%",
          }}
        >
          Filtrering
        </p>

        <p
          className="textLink"
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
          onClick={() => this.props.onReset()}
        >
          Återställ
        </p>
      </div>
      
      <div
        style={{
          padding: "1em",
          marginTop: 0,
          width: "100%",
        }}
      >
        <div 
          style={{ 
            marginBottom: "0.75em" 
          }}
        >
          Dela upp områden enligt
        </div>

        <Select
          value={colorScaleType}
          fluid
          onChange={this.onSemanticValueChange("colorScaleType")}
          options={COLOR_SCALE_TYPES}
        />
            
        <div 
          style={{ 
            marginBottom: "0.75em",
            marginTop: "2em",
          }}
        >
          Hur många grupper ska maximalt visas?

          <ToolTip
            style={{
              zIndex: "1000"
            }}
            trigger={
              <Icon
                name="question circle"
                style={{
                  marginLeft: "0.25em",
                  fontSize: "80%",
                  lineHeight: "80%"
                }}
              />
            }
            content={"Punkterna i kartan markeras i ett antal grupper. Här kan du välja hur många grupper du vill ha."}
          />                  
        </div>
        
        <div
          style={{
            width: "85%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Slider
            trackStyle={{ background: "#fbbd08" }}
            dotStyle={{ borderColor: "#fbbd08" }}
            handleStyle={{ borderColor: "#fbbd08" }}
            marks={[2, 3, 4, 5, 6, 7, 8, 9, 10]}
            min={0}
            max={8}
            value={numberOfGroups}
            onChange={this.onNumberOfGroupsChange}
          />
        </div>

        <div
          style={{
            marginTop: "4em",
            marginBottom: "0.75em",
          }}
        >
          Vilket intervall av värden ska visas?

          <ToolTip
              style={{
                zIndex: "1000"
              }}
              trigger={
                <Icon
                  name="question circle"
                  style={{
                    marginLeft: "0.25em",
                    fontSize: "80%",
                    lineHeight: "80%"
                  }}
                />
              }
              content={"Välj vilket intervall av värden som ska visas på kartan. Med denna funktion kan du dölja små eller stora världen för att zooma in skalan och se skillnader bättre."}
            />                
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "85%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "0.5em",
          }}
        >
          <div style={{ marginRight: "1em" }}>
            {min && min.toFixed(1)}            
          </div>
          <Range
            trackStyle={[
              { background: "#fbbd08" },
              { background: "#fbbd08" }
            ]}
            dotStyle={[
              { borderColor: "#fbbd08" },
              { borderColor: "#fbbd08" }
            ]}
            handleStyle={[
              { borderColor: "#fbbd08" },
              { borderColor: "#fbbd08" }
            ]}
            value={[min, max]}
            min={minLimit}
            max={maxLimit}
            step={stepSize}
            pushable={0.01}
            onChange={this.onRangeChange}
          />
          <div style={{ marginLeft: "1em" }}>
            {max && max.toFixed(1)}
          </div>
        </div>

        {!hasValidRangeInput && (
          <Message 
            fluid 
            style={{ 
              backgroundColor: "#D6946B",
                                  
              whiteSpace: "normal" 
            }}>
            Intervallet du har fyllt i kan inte användas för att filtrera
            punkter i detta datalager.
          </Message>
        )}
        </div>
      </Segment>
    );
  }
}

export default MapFilteringWidget;


