import React, { Component } from "react";

import { 
  Header, 
  Input,
  Button,
  Dropdown,
  Table,
  Popup,
  Icon,
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import { hasLayerData } from "@/helpers/dataFetcher";

import { CROP_CODES } from "@/constants/cropCodes";

import { AIM_RATION, SPRING_CROPS } from "./constants";
import { PREVIOUS_CROP_YIELD_INCREASE } from "./constants";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegmentHarvest from "@/components/guide/GuideSegmentHarvest";
import GuideSegment from "@/components/guide/GuideSegment";

import LockIcon from "@/components/misc/LockIcon";

const INITIAL_STATE = {
  overrideDisabledPreviousCrop: [],
};

class NitrogenNeed extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    this.setDefaultSettings("aimRation");
  };  

  onClickNextStep = () => {
    const {
      completedStepsNitrogenNeed,
    } = this.props;

    this.props.setParentState({
      openSegmentNitrogenNeed: '',
      completedStepsNitrogenNeed: [...completedStepsNitrogenNeed, "previousCrop"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment, data=null, forceUpdate=false) => {
    const {
      db,
      selectedFieldIds,
      guideCrop,
      guideCropRegion,
      guideCropQuality,
      guideAimRation,
      guidePreviousCrops,
    } = this.props;

    const {
      overrideDisabledPreviousCrop,
    } = this.state;

    switch(newSegment) {

      case "aimRation":
        let cropAimRation = AIM_RATION && AIM_RATION.find((x) => {
          let correctCrop = guideCrop && x.crop === guideCrop;
          let correctRegion = (!guideCropRegion || !x.region) ? true : guideCropRegion && x.region.key === guideCropRegion;
          let correctQuality = (!guideCropQuality || !x.quality) ? true : guideCropQuality && x.quality === guideCropQuality;
    
          return correctCrop && correctRegion && correctQuality;
        });

        !forceUpdate && cropAimRation && this.props.setParentState({ guideAimRation: {...cropAimRation.ration, ...guideAimRation} });
        forceUpdate && cropAimRation && this.props.setParentState({ guideAimRation: {...cropAimRation.ration} });
        break;

      case "previousCrop":
        let previousCrops = {};
        let newValueOverride = [];

        selectedFieldIds && selectedFieldIds.forEach((x) => {
          let fieldInfoDb = db && x && db.fieldsInfo && db.fieldsInfo[x];
          let cropCodes = fieldInfoDb && fieldInfoDb.crop_code;
          let previousYear = (new Date().getFullYear() - 1).toFixed(0);
    
          if (cropCodes && previousYear && cropCodes[previousYear]) {
            previousCrops = {...previousCrops, [x]: cropCodes[previousYear]}

          } else {
            previousCrops = {...previousCrops, [x]: '0'}
          };

          newValueOverride = previousCrops[x] === '0' ? [...newValueOverride, x] : newValueOverride;
        });

        this.setState({ overrideDisabledPreviousCrop: [...overrideDisabledPreviousCrop, ...newValueOverride] });
        
        !forceUpdate && this.props.setParentState({ guidePreviousCrops: { ...previousCrops, ...guidePreviousCrops } });
        forceUpdate && this.props.setParentState({ guidePreviousCrops: previousCrops });

        this.setYieldIncreaseFromPreviousCrop({ ...previousCrops, ...guidePreviousCrops });
        break;

      default:
        break;
    };
  };

  setYieldIncreaseFromPreviousCrop = (guidePreviousCrops) => {
    const {
      guideCrop,
      guideExpectedYield,
    } = this.props;

    // Yield increase from previous crops
    let newValue = {};
    
    guidePreviousCrops && Object.keys(guidePreviousCrops).forEach((fieldId) => {
      let cropEffect = '0.0'

      let lastCrop = guidePreviousCrops[fieldId];
      let expectedYieldType = guideExpectedYield && guideExpectedYield[fieldId];
      
      let cropsWithEffect = Object.keys(PREVIOUS_CROP_YIELD_INCREASE).map((x) => x);

      if (!expectedYieldType || expectedYieldType !== "yieldStatistics_max") {
        if (lastCrop && cropsWithEffect.includes(lastCrop)) {
          let cropsEffected = Object.keys(PREVIOUS_CROP_YIELD_INCREASE[lastCrop]).map((x) => x);
          if (cropsEffected.includes(guideCrop)) {
            cropEffect = PREVIOUS_CROP_YIELD_INCREASE[lastCrop][guideCrop].toFixed(0);
          };
        };
      };

      newValue = {...newValue, [fieldId]: cropEffect};
    });

    this.props.setParentState({ guidePreviousCropsYieldContribution: newValue });
  };


  updateYieldIncreaseFromPreviousCrop = (fieldId, lastCrop) => {
    const {
      guideCrop,
      guideExpectedYield,
      guidePreviousCropsYieldContribution,
    } = this.props;

    // Yield increase from previous crops
    let cropEffect = '0.0'

    let expectedYieldType = guideExpectedYield && guideExpectedYield[fieldId];
    
    let cropsWithEffect = Object.keys(PREVIOUS_CROP_YIELD_INCREASE).map((x) => x);

    if (!expectedYieldType || expectedYieldType !== "yieldStatistics_max") {
      if (lastCrop && cropsWithEffect.includes(lastCrop)) {
        let cropsEffected = Object.keys(PREVIOUS_CROP_YIELD_INCREASE[lastCrop]).map((x) => x);
        if (cropsEffected.includes(guideCrop)) {
          cropEffect = PREVIOUS_CROP_YIELD_INCREASE[lastCrop][guideCrop].toFixed(0);
        };
      };
    };

    let newValue = {...guidePreviousCropsYieldContribution, [fieldId]: cropEffect};

    this.props.setParentState({ guidePreviousCropsYieldContribution: newValue });
  };


  setBonusFromSpreadingMethod = () => {
    const {
      guideCropRegion,
      guideCompoundSpreadingBonus
    } = this.props;

      // Bonus for spreading method
      let compoundSpreadBonus = 0.0;
      compoundSpreadBonus = (guideCompoundSpreadingBonus && ["middle", "north"].includes(guideCropRegion)) ? 10.0 : compoundSpreadBonus;
      compoundSpreadBonus = (guideCompoundSpreadingBonus && ["south"].includes(guideCropRegion)) ? 5.0 : compoundSpreadBonus;

      this.props.setParentState({ guideCompoundSpreadingBonusNumber: compoundSpreadBonus });
  };

  onChange = (data, onlyNumbers=true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  }; 

  onChangeStruct = (e, data, variableName, onlyNumbers=true) => {
    let currentValue = this.props[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: {...currentValue, [data.name]: value },
    });
  }; 

  setOpenSegment = (newSegment) => {
    const {
      openSegmentNitrogenNeed
    } = this.props;

    (openSegmentNitrogenNeed === newSegment) && this.props.setParentState({ openSegmentNitrogenNeed: null });
    (openSegmentNitrogenNeed !== newSegment) && this.props.setParentState({ openSegmentNitrogenNeed: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedStepsNitrogenNeed, 
    } = this.props;

    !completedStepsNitrogenNeed.includes(currentSegment) && this.props.setParentState({
      completedStepsNitrogenNeed: [...completedStepsNitrogenNeed, currentSegment],
    });

    this.setDefaultSettings(newSegment);

    !completedStepsNitrogenNeed.includes(newSegment) && this.setOpenSegment(newSegment);
    completedStepsNitrogenNeed.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  onSelectCrop = (data) => {
    this.onChange(data, false);
    this.setDefaultSettings("crop", data);
  };

  render() {

    const {
      db,
      fileBucketRef,
      userId,
      selectedFieldIds,
      guideCrop,
      guideAimRation,
      guideExpectedYield,
      guideExpectedYieldCalibration,
      guidePreviousCrops,
      guidePreviousCropsYieldContribution,
      openSegmentNitrogenNeed,
      completedStepsNitrogenNeed,
      guideMode,
    } = this.props;

    const {
      overrideDisabledPreviousCrop,
    } = this.state;
    
    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],      
      next: [
        {
          caption: "Gå vidare",
          hidden: completedStepsNitrogenNeed.length < 3,
          onClick: this.onClickNextStep,
        }
      ],
    };
    
    let hasAllPreviousCrops = true;

    selectedFieldIds && selectedFieldIds.forEach((x) => {
      let expectedYield = guideExpectedYield && guideExpectedYield[x];
      let hasCrop = guidePreviousCrops && guidePreviousCrops[x] && guidePreviousCrops[x] !== "0";

      if (!expectedYield === "yieldStatistics_max" && !hasCrop) {
        hasAllPreviousCrops = false;
      } 
    });

    let missingYieldMapStatistics = [];

    selectedFieldIds && selectedFieldIds.forEach((x) => {
      missingYieldMapStatistics = !hasLayerData(db, x, "yieldStatistics") ? [...missingYieldMapStatistics, x] : missingYieldMapStatistics;
    });

    let isSpringCrop = SPRING_CROPS && guideCrop && SPRING_CROPS.includes(guideCrop);

    const plotData = {
      labels: guideAimRation && Object.keys(guideAimRation),
      datasets: [{
          data: guideAimRation && Object.keys(guideAimRation).map((x) => guideAimRation[x] ? parseFloat(guideAimRation[x]) : 0.0),
          fill: false,
          backgroundColor: "#89A275",
          borderColor: "#4C8440"
      }]
    };

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegment
          caption="Målgiva"
          skipSimpleMode={guideMode && guideMode === "advanced"}
          simpleModeQuestion="Vill du göra förändringar i Jordbruksverkets rekommendationer för målgivan?"
          simpleModeOpenText="Justera"
          open={openSegmentNitrogenNeed === "aimRation"}
          disabled={openSegmentNitrogenNeed !== "aimRation" && !completedStepsNitrogenNeed.includes("aimRation")}
          complete={completedStepsNitrogenNeed && completedStepsNitrogenNeed.includes("aimRation")}
          onClickOpenSegment={() => this.setOpenSegment("aimRation")}
          onClickNextStep={isSpringCrop ? () => this.gotoNextStep("aimRation", "spreadMethod") : () => this.gotoNextStep("aimRation", "yield")}
          simpleModeOnSkip={() => this.setDefaultSettings("aimRation", null, true)}
          disableNextStep={guideAimRation && Object.keys(guideAimRation).find((x) => !guideAimRation[x] || guideAimRation[x].length === 0)}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 15,
              width: "80%",
              fontSize: "110%",
            }}
          >
            Freja utgår från Jordbruksverkets rekommendationer för riktgivor tillsammans med den förväntade skörden för att beräkna kvävebehovet på dina skiften.
            I tabellen nedanför kan du justera givan (kg N/ha) för varje skördenivå (ton/ha).
          </p>          
          
          {guideAimRation &&
            <article
              className="canvas-container"
              style={{
                width: "100%",
                height: "30vh",
                background: "transparent",
                padding: 0,
                marginTop: "4em",
              }}
            >
              <Line 
                data={plotData} 
                options={{ 
                  responsive: true,
                  maintainAspectRatio: false,
                  layout: {
                      padding: {
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0
                      }
                  },
                  legend: {
                    display: false,
                  },
                  scales: {
                    yAxes: [{
                      ticks: {
                        fontSize: 14,
                        fontColor: 'grey',
                      },
                      scaleLabel: {
                        display: true,
                        fontSize: 12,
                        fontColor: 'black',
                        labelString: "Giva (kg N/ha)",
                      }
                    }],
                    xAxes: [{
                      ticks: {
                        fontSize: 14,
                        fontColor: 'grey',
                      },
                      scaleLabel: {
                        display: false,
                        fontSize: 12,
                        fontColor: 'black',
                        labelString: "ton/ha",
                      }
                    }],           
                  }
                }}  
              />            
            </article>
          }

          {guideAimRation &&
            <Table 
              celled 
              striped 
              columns={Object.keys(guideAimRation) && typeof(Object.keys(guideAimRation).length) === "number" ? Object.keys(guideAimRation).length.toFixed(0) : Object.keys(guideAimRation).length}
            >
              <Table.Header>
                <Table.Row>
                    {guideAimRation && Object.keys(guideAimRation).map((x) => (
                      <Table.HeaderCell textAlign="center">
                        {x} ton/ha
                      </Table.HeaderCell>
                    ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  {guideAimRation && Object.keys(guideAimRation).map((x) => (
                    <Table.Cell>
                      <Input
                        key={"InputCropAimRation_" + x}
                        onChange={(e, d) => this.onChangeStruct(e, d, "guideAimRation")}
                        fluid 
                        value={guideAimRation[x]} 
                        name={x}
                        error={!guideAimRation[x] || guideAimRation[x].length === 0}
                      />
                    </Table.Cell>
                  ))}
                </Table.Row>
              </Table.Body>
            </Table> 
          } 
        </GuideSegment>

        <GuideSegment
          caption="Spridningsmetod"
          hidden={!isSpringCrop}
          simpleModeQuestion="Hur sprider du ditt kvävegödningspreparat?"
          open={openSegmentNitrogenNeed === "spreadMethod"}
          disabled={openSegmentNitrogenNeed !== "spreadMethod" && !completedStepsNitrogenNeed.includes("spreadMethod")}
          simpleModeOpenText="Radmyllning"
          simpleModeOnOpen={() => this.gotoNextStep("spreadMethod", "yield")}
          simpleModeSkipText="Bredspridning"
          simpleModeOnSkip={() => {
            this.gotoNextStep("spreadMethod", "yield"); 
            this.props.setParentState({ guideCompoundSpreadingBonus: true });
          }}
          complete={completedStepsNitrogenNeed && completedStepsNitrogenNeed.includes("spreadMethod")}
        />

        <GuideSegmentHarvest
          db={db}
          fileBucketRef={fileBucketRef}
          userId={userId}
          guideMode={guideMode}
          caption="Förväntad skörd"
          open={openSegmentNitrogenNeed === "yield"}
          disabled={openSegmentNitrogenNeed !== "yield" && !completedStepsNitrogenNeed.includes("yield")}
          simpleModeQuestion={missingYieldMapStatistics.length === 0 ? "Hur vill du att Freja ska räkna ut förväntad skörd?" : null}
          simpleModeOpenText="Ange annat"
          simpleModeSkipText="Maximal skörd punkt-för-punkt"       
          complete={completedStepsNitrogenNeed && completedStepsNitrogenNeed.includes("yield")}
          onClickOpenSegment={() => this.setOpenSegment("yield")}
          onClickNextStep={() => this.gotoNextStep("yield", "previousCrop")}
          description={
            <React.Fragment>
              <p
                style={{
                  whiteSpace: "pre-line",
                  width: "80%",
                  fontSize: "110%",
                }}
              >
                Freja hjälper dig att beräkna din förväntade skörd på skiftet med hjälp av uppladdade skördekartor eller skördekartor som du har skapat från grönmassamätningar. Den förväntade skörden är en viktig del i att beräkna grödans behov av kväve.
              </p>

              <p
                style={{
                  whiteSpace: "pre-line",
                  width: "80%",
                  fontSize: "110%",
                }}
              >
                Den förväntade skörden räknas ut för varje del av skiftet på några olika sätt och grödor. Freja beräknar den uppskattade skörden för en viss gröda om du har tre eller fler skördekartor från skiftet uppladdade med den grödan.
              </p>

              <p
                style={{
                  whiteSpace: "pre-line",
                  width: "80%",
                  fontSize: "110%",
                }}
              >
                Välj en karta där du tycker att variationen stämmer överens med skörden på fältet. Du kan sedan justera snittskörden för att passa den nivå som du tror att grödan kommer nå upp till under denna säsongen.
              </p>
            </React.Fragment>                      
          }
          setParentState={this.props.setParentState}
          selectedFieldIds={selectedFieldIds}
          guideCrop={guideCrop}
          guideExpectedYield={guideExpectedYield}
          guideExpectedYieldCalibration={guideExpectedYieldCalibration}
        />

        <GuideSegment
          caption="Ökad skörd från förfrukt"
          skipSimpleMode={guideMode && guideMode === "advanced"}
          disabled={openSegmentNitrogenNeed !== "previousCrop" && !completedStepsNitrogenNeed.includes("previousCrop")}
          simpleModeQuestion="Vill du att Freja ökar skördens storlek baserat på förfrukten?"
          simpleModeDescription="Vissa förfrukter förbättrar bland annat markstrukturen vilket leder till en bättre skörd året efter för vissa grödor. Freja kan hjälpa till med att lägga på denna skördeökning på snittskörden om du redan inte har tagit hänsyn till det i förra steget."
          simpleModeOpenText="Ja"
          simpleModeOnOpen={hasAllPreviousCrops ?
            () => {
              this.props.setParentState({ guideIncreasedYieldFromPreviousCrop: true });
              this.setYieldIncreaseFromPreviousCrop();
              this.onClickNextStep();
            }:
            null
          }
          simpleModeAndOpen={hasAllPreviousCrops ? 
            null : 
            () => {
              this.props.setParentState({ guideIncreasedYieldFromPreviousCrop: true });
              this.setYieldIncreaseFromPreviousCrop();
            }
          }          
          simpleModeSkipText="Nej"
          simpleModeOnSkip={() => {
            this.props.setParentState({ guideIncreasedYieldFromPreviousCrop: false });
            this.onClickNextStep(); 
          }}        
          open={openSegmentNitrogenNeed === "previousCrop"}
          complete={completedStepsNitrogenNeed && completedStepsNitrogenNeed.includes("previousCrop")}
          onClickOpenSegment={() => this.setOpenSegment("previousCrop")}
          onClickNextStep={() => this.onClickNextStep()}  
          editButtonText="Ta inte hänsyn till förfrukt"
          onClickEdit={() => {
            this.props.setParentState({ guideIncreasedYieldFromPreviousCrop: false });
            this.onClickNextStep(); 
          }}
          disableNextStep={guidePreviousCropsYieldContribution && Object.keys(guidePreviousCropsYieldContribution).find((fieldId) => !guidePreviousCropsYieldContribution[fieldId] || guidePreviousCropsYieldContribution[fieldId].length === 0)}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 0,
              width: "80%",
              fontSize: "110%",
            }}
          >
            Freja kan lägga till den skördeökning som normalt kommer från förfrukten till den förväntade skörden.
            Storleken på denna skördeökning kommer från Jordbruksverkets rekommendationer och är lika stor över hela skiften.
          </p>

          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 30,
              width: "80%",
              fontSize: "110%",
            }}
          >
            Har du redan tagit förfruktens effekt på skörden i beaktning när ju angav en förväntad snittskörd i föregående steg kan du hoppa detta steg.
          </p>          
          
          <Table 
            celled 
            striped 
            columns="3"
            style={{
              marginTop: "4em",
              maxWidth: "100%",
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Skifte</Table.HeaderCell>
                <Table.HeaderCell>Förfrukt</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Skördeökning (ton/ha)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {selectedFieldIds && selectedFieldIds.map((x) => {
                  let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;
                  let expectedYield = guideExpectedYield && guideExpectedYield[x];                  

                  return (
                    <Table.Row
                      key={"previousCropDropdown_" + x}
                    >
                      <Table.Cell textAlign="center">{fieldName && fieldName}</Table.Cell>

                      <Table.Cell>
                        {expectedYield !== "yieldStatistics_max" &&
                          <Dropdown
                            style={{
                              width: "50%"
                            }}
                            selection
                            options={CROP_CODES}
                            name={x}
                            onChange={(e, d) => {
                              this.onChangeStruct(e, d, "guidePreviousCrops", false);
                              this.updateYieldIncreaseFromPreviousCrop(x, d.value);
                            }}
                            disabled={!overrideDisabledPreviousCrop.includes(x) && (guidePreviousCrops && guidePreviousCrops[x])}
                            value={guidePreviousCrops[x]}
                          />

}
                        {expectedYield !== "yieldStatistics_max" &&
                          <LockIcon 
                            locked={!overrideDisabledPreviousCrop.includes(x)}
                            onClickUnlock={() => this.setState({ overrideDisabledPreviousCrop: [...overrideDisabledPreviousCrop, x] })}
                          />
                        }

                        {expectedYield === "yieldStatistics_max" && "Ingår i maxmimal skörd. "}

                        {expectedYield === "yieldStatistics_max" && 
                          <Popup
                            trigger={<Icon name="help circle" />}
                            content="Eftersom du har valt maximal skörd som förväntad skörd så är förfrukten troligen medräknad i denna. Freja har därför automatiskt tagit bort justeringen. Om du ändå vill öka den förväntade skörden ytterligare så kan du fylla i ett värde i kolumnen längst till höger."
                          />
                        }
                      </Table.Cell>

                      <Table.Cell textAlign="center">
                        <Input 
                          name={x}
                          onChange={(e, d) => this.onChangeStruct(e, d, "guidePreviousCropsYieldContribution", true)}
                          value={guidePreviousCropsYieldContribution && guidePreviousCropsYieldContribution[x]}
                          error={!guidePreviousCropsYieldContribution || !guidePreviousCropsYieldContribution[x] || guidePreviousCropsYieldContribution[x].length === 0}
                        />
                      </Table.Cell>                      
                    </Table.Row>
                )})}
              </Table.Body>
            </Table>
        </GuideSegment>  
      </div>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h3"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Vad baseras styrfilen på?
        </Header>
        
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
         Frejas styrfilsförslag bygger på välkända riktlinjer från Jordbruksverket som baseras på omfattade växtodlingsförsök. Läs mer genom att klicka på knapparna nedanför.
        </p> 

        <Button 
          fluid
          primary
          href="https://jordbruksverket.se/vaxter/odling/vaxtnaring/riktgivor-och-strategier-for-godsling"
          target="_blank" 
          rel="noopener noreferrer"
          style={{
            marginTop: "1em",
            backgroundColor: "#BF8136",
            color: "white",
          }}          
        >
          Jordbruksverkets rekommendationer
        </Button>             
      </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader="Uppskatta kvävebehovet"
        mainColumnBody="För att kunna beräkna kvävebehovet behöver du fylla i informationen nedanför. Svara på alla frågor för att gå vidare till nästa steg."    
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default NitrogenNeed;