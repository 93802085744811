import React from "react";
import { Select } from "semantic-ui-react";

import "./style.css";

export const InvertedSelect = props => {
  return (
    <Select
      className="invertedSelect"
      {...props}
      style={{ background: "#1b1c1d", color: "white", border: "3px solid white !important"}}
      options={props && props.options && props.options.map(option => ({
        ...option,
        style: { background: "#1b1c1d", color: "white", border: "1px solid white !important" },
        className: "default"
      }))}
    />
  );
};
