import React, { Component } from "react";

import { 
  Dropdown,
  Table,
  Input,
  Segment,
  Header,
} from "semantic-ui-react";

import { NUTRIENT_FERTILIZERS } from "@/constants/fertilizers";

import GuideSegment from "@/components/guide/GuideSegment";

const INITIAL_STATE = {};

class GuideSegmentPreviousRation extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };
  
  onComputePreviousRation = (variableNameField, value) => {
    const {
      compoundType,
      selectedFieldIds,
      guidePreviousRation,
      guidePreviousRationCompoundType,
      guidePreviousRationCompoundWeight,
    } = this.props;

    let variableName = variableNameField.split("/")[0];
    let fieldId = variableNameField.split("/")[1];
    let newCompoundWeight, currentFertilizer, compoundRation;

    if (variableName === "guidePreviousRationCompoundWeight") {

      let newWeight = value && value.replace(/[^\d,.]/g, '');
      newWeight = newWeight && newWeight.replace(",", ".");

      currentFertilizer = NUTRIENT_FERTILIZERS.find((x) => x.value === guidePreviousRationCompoundType);
      compoundRation = currentFertilizer ? currentFertilizer.nutrients[compoundType] : '100';
      newCompoundWeight = value ? (parseFloat(value) * (parseFloat(compoundRation) / 100.0)).toFixed(0) : ''

      if (fieldId) {
        this.props.setParentState({
          guidePreviousRationCompoundWeight: {...guidePreviousRationCompoundWeight, [fieldId]: newWeight},
          guidePreviousRation: {...guidePreviousRation, [fieldId]: newCompoundWeight},
        });

      } else {
        this.props.setParentState({
          guidePreviousRationCompoundWeight: newWeight,
          guidePreviousRation: newCompoundWeight,
        });
      };
    };

    if (variableName === "guidePreviousRationCompoundType") {
      currentFertilizer = NUTRIENT_FERTILIZERS.find((x) => x.value === value);
      compoundRation = currentFertilizer ? currentFertilizer.nutrients[compoundType] : '100';
      this.props.setParentState({ guidePreviousRationCompoundType: value });

      if (typeof(guidePreviousRationCompoundWeight) === "string") {
        newCompoundWeight = value ? (parseFloat(guidePreviousRationCompoundWeight) * (parseFloat(compoundRation) / 100.0)).toFixed(0) : '';
        this.props.setParentState({ guidePreviousRation: newCompoundWeight });
      } else {
        newCompoundWeight = {};

        selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
          let newValue = value ? (parseFloat(guidePreviousRationCompoundWeight[fieldId]) * (parseFloat(compoundRation) / 100.0)).toFixed(0) : '';
          newCompoundWeight = {...newCompoundWeight, [fieldId]: newValue};
        });

        this.props.setParentState({ guidePreviousRation: newCompoundWeight });
      }
    };
  };

  togglePreviousRationPerField = () => {
    const {
      selectedFieldIds,
      guidePreviousRation,
      guidePreviousRationCompoundWeight,
    } = this.props;

    let newPreviousRation, newPreviousRationCompoundWeight;

    if (typeof(guidePreviousRation) === "string") {
      newPreviousRation = {};
      newPreviousRationCompoundWeight = {};

      selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
        newPreviousRation = {...newPreviousRation, [fieldId]: guidePreviousRation}
        newPreviousRationCompoundWeight = {...newPreviousRationCompoundWeight, [fieldId]: guidePreviousRationCompoundWeight}
      });

    } else {

      newPreviousRation = guidePreviousRation && guidePreviousRation[selectedFieldIds[0]];
      newPreviousRationCompoundWeight = guidePreviousRationCompoundWeight && guidePreviousRationCompoundWeight[selectedFieldIds[0]];
    };

    this.props.setParentState({
      guidePreviousRation: newPreviousRation,
      guidePreviousRationCompoundWeight: newPreviousRationCompoundWeight,
    });
  };

  render() {

    const {
      db,
      selectedFieldIds,
      guideMode,
      guidePreviousRation,
      guidePreviousRationCompoundType,
      guidePreviousRationCompoundWeight,
      guideAction,
      compoundType,
      disabled,
    } = this.props;

    let isPerField = guidePreviousRation && typeof(guidePreviousRation) !== "string";

    let filteredFertilizers = NUTRIENT_FERTILIZERS;

    let tableAllFields = 
      <Table
        style={{
          marginTop: "4em",
          width: "80%",
          marginLeft: "10%",
        }}
        celled
        striped
        columns="3"
      >
        <Table.Header>
          <Table.HeaderCell width={12}>Gödslingsmedel</Table.HeaderCell>
          <Table.HeaderCell width={2}>Total giva (kg/ha)</Table.HeaderCell>
          <Table.HeaderCell width={2} textAlign="center">Grundgiva</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Dropdown
                fluid
                selection
                search
                options={[
                  {
                    key: 'none', 
                    value: 'none', 
                    text: 'Ange som kg ' + compoundType + '/ka',
                    nutrients: {[compoundType] : '100'},
                  }, 
                  ...filteredFertilizers
                ]}
                onChange={(e, d) => this.onComputePreviousRation("guidePreviousRationCompoundType/", d.value)}
                value={guidePreviousRationCompoundType}
              />      
            </Table.Cell>
            <Table.Cell>
              <Input
                value={guidePreviousRationCompoundWeight}
                onChange={(e, d) => this.onComputePreviousRation("guidePreviousRationCompoundWeight/", d.value)}
              />  
            </Table.Cell>
            <Table.Cell textAlign="center">
              {guidePreviousRation ? guidePreviousRation + " " : "- "} {"kg " + compoundType + "/ha"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

    let tablePerFields = 
      <React.Fragment>
        <Segment
          secondary
          style={{
            marginTop: "4em",
            width: "80%",
            marginLeft: "10%",
            padding: "1.5em",
          }}
        >
          <Header 
            as="h6"
            style={{
              marginBottom: 5,
            }}
          >
            Gödslingsmedel
          </Header>
            <Dropdown
              fluid
              selection
              search
              options={[
                {
                  key: 'none', 
                  value: 'none', 
                  text: 'Ange som kg ' + compoundType + '/ka',
                  nutrients: {[compoundType] : '100'},
                }, 
                ...filteredFertilizers
              ]}
              onChange={(e, d) => this.onComputePreviousRation("guidePreviousRationCompoundType/", d.value)}
              value={guidePreviousRationCompoundType}
            />     
        </Segment>

        <Table
          style={{
            marginTop: "1em",
            width: "80%",
            marginLeft: "10%",
          }}
          celled
          striped
          columns="3"
        >
          <Table.Header>
            <Table.HeaderCell width={12}>Skifte</Table.HeaderCell>
            <Table.HeaderCell width={2}>Total giva (kg/ha)</Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="center">Grundgiva</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {selectedFieldIds && selectedFieldIds.map((x) => {
              let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;

              return (
                <Table.Row
                  key={"guidePreviousRationSegmentRow_" + x}
                >
                  <Table.Cell>{fieldName && fieldName}</Table.Cell>
                  <Table.Cell>
                    <Input
                      value={guidePreviousRationCompoundWeight && guidePreviousRationCompoundWeight[x]}
                      onChange={(e, d) => this.onComputePreviousRation("guidePreviousRationCompoundWeight/" + x, d.value)}
                    />  
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {guidePreviousRation && guidePreviousRation[x] ? guidePreviousRation[x] + " " : "- "} {"kg " + compoundType + "/ha"}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </React.Fragment>

    return (
      <GuideSegment
        caption="Tidigare gödsling"
        skipSimpleMode={guideMode && guideMode === "advanced"}
        simpleModeQuestion={"Vill du ange tidigare " + guideAction + " denna säsongen?"}
        simpleModeDescription='I framtiden kommer Freja kunna fylla i alla värden automatiskt men nu behöver vissa fyllas i manuellt.'
        open={this.props.open}
        disabled={disabled}
        complete={this.props.complete}
        onClickOpenSegment={this.props.onClickOpenSegment}
        onClickNextStep={this.props.onClickNextStep}
        editButtonText={isPerField ? "Ange samma för alla skiften" : "Ange per skifte"}
        editButtonBasic={true}
        onClickEdit={() => this.togglePreviousRationPerField()}
      >
        <p
          style={{
            whiteSpace: "pre-line",
            fontSize: "110%",
          }}
        >
          {"Hur mycket " + guideAction + " har du lagt på tidigare under denna säsong?"}
        </p>
        {!isPerField && tableAllFields && tableAllFields}          
        {isPerField && tablePerFields && tablePerFields}
      </GuideSegment>
    )
  }
}

export default GuideSegmentPreviousRation;