import { cloudFunctions } from "@";

export function sendFieldUnlockEmailToSupport (db, userId, fieldsToUnlock, fieldsToUnlockNames) {
  const fields = db && db.fields && db.fields;
  const profile = db && db.profile && db.profile;

  const {
    farm: {
      name,
    },
    plan: {
      plan_name,
    },
    contact: {
      first_name,
      last_name,
    },
    invoicing: {
      company_id,
      address1,
      address2,
      zip_code,
      town,
      email,
      method,
    }
  } = profile;

  let emailAddress = (email && name) ? name + ' <' + email + '>' : "Unknown user <no-reply@agriopt.se>";

  let addedFieldsArea = fields && fieldsToUnlock && fieldsToUnlock.map((x) => parseFloat(fields[x].field_size));
  addedFieldsArea = addedFieldsArea && addedFieldsArea.reduce((a, b) => a + b, 0.0);

  let message = "Nya skiften har låsts upp för användaren: " + userId + ".\n\n\n";
  message = message + "==============================\n";
  message = message + "Fakturainformation:\n";
  message = message + "==============================\n\n";
  message = company_id ? message + "Organisationsnummer: " + company_id + "\n\n" : message;
  message = name ? message + name + "\n\n" : message;
  message = first_name && last_name ? message + first_name + " " + last_name + "\n" : message;
  message = address1 ? message + address1 + "\n" : message;
  message = address2 ? (message + address2 + "\n") : message;
  message = zip_code && town ? message + zip_code + " " + town + "\n\n" : message;
  message = email ? message + email + "\n" : message;
  message = method ? message + "Faktureringsmetod: " + method + "\n" : message;
  message = plan_name ? message + "Funktionspaket: " + plan_name + "\n" : message;
  
  message = message + "\n\n==============================\n";
  message = message + "Skiften som har lagts till:\n";
  message = message + "==============================\n\n";
  
  fieldsToUnlock && fieldsToUnlock.forEach((fieldId) => {
    let fieldName = fieldsToUnlockNames && fieldsToUnlockNames[fieldId];
    let fieldSize = fields && fields[fieldId] && fields[fieldId].field_size;

    message = fieldName && fieldSize ? message + fieldName + " (" + fieldSize + " ha).\n" : message;
  });

  message = addedFieldsArea ? message + "\n\nTotal areal: " + addedFieldsArea.toFixed(2) + " ha.\n\n" : message;

  cloudFunctions.httpsCallable('sendSupportEmail')(
    {
      email: emailAddress,
      subject: 'Nya skiften har låsts upp',
      message: message
    }
  )
  .then(x => {
    console.log("Unlocked fields e-mail sent to support@agriopt.se");
  })
  .catch(error => {
    console.log("Unlocked fields e-mail failed to send to support@agriopt.se");
    console.log(error);
  });
};