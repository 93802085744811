import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Image, 
  Icon, 
  Segment, 
  Progress,
  Menu, 
  Popup,
} from "semantic-ui-react";

import imgFieldDataScore from "@/assets/icons/dataTypes/field-data-points.svg";

class FieldDataFieldScore extends Component {
  state = {};  

  toggleOpen = () => {
    this.props.toggleOpen && this.props.toggleOpen()
  };

  getFieldMapYears = () => {
    const {
      db,
      fieldId,
    } = this.props;

    let yearsSentinelMaps = db && fieldId && db["fieldsSentinelHubProcessed_" + fieldId];

    yearsSentinelMaps = yearsSentinelMaps &&
      yearsSentinelMaps.analysis_sentinel_msavi_geojson &&
      Object.keys(yearsSentinelMaps.analysis_sentinel_msavi_geojson)
        .map((x) => x)
        .filter((x) => !isNaN(x) && parseInt(x) > 1000);


    let yearsYieldMaps = db && fieldId && db["fieldsYieldMapsProcessed_" + fieldId];
    
    yearsYieldMaps = yearsYieldMaps && 
      yearsYieldMaps.rawdata_harvest_maps_geojson &&
      Object.keys(yearsYieldMaps.rawdata_harvest_maps_geojson).map((x) => x);

    let availableYears = []
    availableYears = yearsSentinelMaps ? [...availableYears, ...yearsSentinelMaps] : availableYears;
    availableYears = yearsYieldMaps ? [...availableYears, ...yearsYieldMaps] : availableYears;

    return [...new Set(availableYears)]
  };

  getYieldMapYears = () => {
    const {
      db,
      fieldId,
    } = this.props;

    let yearsYieldMaps = db && fieldId && db["fieldsYieldMapsProcessed_" + fieldId];

    yearsYieldMaps = yearsYieldMaps && 
      yearsYieldMaps.rawdata_harvest_maps_geojson &&
      Object.keys(yearsYieldMaps.rawdata_harvest_maps_geojson).map((x) => x);

    return yearsYieldMaps
  };  

  getCalibratedYieldMaps = (yieldMapYears) => {
    const {
      db,
      fieldId,
    } = this.props;

    let calibratedHarvestYears = db && fieldId &&
      db.fieldsInfo &&
      db.fieldsInfo[fieldId] &&
      db.fieldsInfo[fieldId].harvest_yields &&
      Object.keys(db.fieldsInfo[fieldId].harvest_yields)
        .map((x) => x)
        .filter((x) => yieldMapYears.includes(x));

    return calibratedHarvestYears
  };

  getCropCycleYears = (years) => {
    const {
      db,
      fieldId,
    } = this.props;

    let cropCycleYears = db && fieldId &&
      db.fieldsInfo &&
      db.fieldsInfo[fieldId] &&
      db.fieldsInfo[fieldId].crop_code &&
      Object.keys(db.fieldsInfo[fieldId].crop_code)
        .map((x) => x)
        .filter((x) => years.includes(x));

    return cropCycleYears
  };  

  getNitrogenRationMaps = (years) => {
    const {
      db,
      fieldId,
    } = this.props;

    let nitrogenRationYears = db && fieldId &&
      db.fieldsInfo &&
      db.fieldsInfo[fieldId] &&
      db.fieldsInfo[fieldId].n_fertilizer &&
      Object.keys(db.fieldsInfo[fieldId].n_fertilizer)
        .map((x) => x)
        .filter((x) => years.includes(x));

    return nitrogenRationYears
  };  

  getSoilMapYears = () => {
    const {
      db,
      fieldId,
    } = this.props;

    let yearsSoilMaps = db && fieldId && db["fieldsSoilMapsProcessed_" + fieldId];
    
    yearsSoilMaps = yearsSoilMaps &&
      yearsSoilMaps.rawdata_uploaded_soilmap_geojson &&
      Object.keys(yearsSoilMaps.rawdata_uploaded_soilmap_geojson).map((x) => x) &&
      Object.keys(yearsSoilMaps.rawdata_uploaded_soilmap_geojson).map((x) => x).sort().reverse();

    return yearsSoilMaps
  };  

  render() {
    const {
      fieldId,
    } = this.props;

    let isOpen = typeof(this.props.isOpen) === "boolean" ? this.props.isOpen : true;

    let availableYears = this.getFieldMapYears();

    let hasCropCycle = availableYears && this.getCropCycleYears(availableYears);
    let hasNitrogenRation = availableYears && this.getNitrogenRationMaps(availableYears);

    let hasYieldMaps = this.getYieldMapYears();
    let hasCalibratedYieldMaps = hasYieldMaps && this.getCalibratedYieldMaps(hasYieldMaps);

    let hasSoilMaps = this.getSoilMapYears();

    let menuItems = [
      {
        key: "cropcycle",
        notDoneCaption: "Växtföljd inmatad för" + (availableYears ? 
          (" " + 
          (hasCropCycle ? hasCropCycle.length.toFixed(0) : "0") + " av " + availableYears.length.toFixed(0) + " år") : 
          null),
        doneCaption: "Växtföljd inmatad för alla år",
        tooltip: "Freja använder växtföljden för att gruppera tolkningar för olika grödor.",
        done: hasCropCycle && availableYears && hasCropCycle.length === availableYears.length,
        onClick: () => this.props.history.push("/collect/" + fieldId + "/seeding"),
      },
      {
        key: "fertilization",
        notDoneCaption: "Kvävegivor inmatade för" + (availableYears ? 
          (" " + 
          (hasNitrogenRation ? hasNitrogenRation.length.toFixed(0) : "0") + " av " + availableYears.length.toFixed(0) + " år") : 
          null),
        doneCaption: "Kvävegivor inmatade för alla år",
        tooltip: "Freja använder kvävegivorna för att utvärdera kvävenetto för dina skiften.",
        done: hasNitrogenRation && availableYears && hasNitrogenRation.length === availableYears.length,
        onClick: () => this.props.history.push("/collect/" + fieldId + "/nutrition"),
      },
      {
        key: "yieldMaps",
        notDoneCaption: "Inga skördekartor uppladdade",
        doneCaption: "Skördekartor uppladdade" + (hasYieldMaps ? (" (" + hasYieldMaps.length.toFixed(0) + " år)") : null),
        tooltip: "Freja använder uppladdade skördekartor för att beräkna skördestatistik, skördepotential och för att skapa styrfiler.",
        done: hasYieldMaps && hasYieldMaps.length > 0,
        onClick: () => this.props.history.push("/guidesUploadYieldMaps/gettingStarted"),
      },
      {
        key: "yieldMapsCalibrated",
        notDoneCaption: "Skördekartor kalibrerade" + (hasYieldMaps ? 
          (" " + 
          (hasCalibratedYieldMaps ? hasCalibratedYieldMaps.length.toFixed(0) : "0") + " av " + hasYieldMaps.length.toFixed(0) + " år") : 
          null),
        doneCaption: "Alla skördekartor kalibrerade",
        tooltip: "Freja använder den invägda vikten för att kalibrera skördekartorna så att den uppskattade skörden stämmer med den verkliga. Detta gör att tolkningar och styrfiler blir ännu bättre.",
        hidden: !hasYieldMaps || hasYieldMaps.length === 0,
        done: hasCalibratedYieldMaps && hasYieldMaps && hasCalibratedYieldMaps.length === hasYieldMaps.length,
        onClick: () => this.props.history.push("/collect/" + fieldId + "/harvest"),
      },
      {
        key: "soilMaps",
        notDoneCaption: "Ingen markkartering uppladdad",
        doneCaption: "Markkartering uppladdad",
        tooltip: "Freja använder uppladdade markkarteringar för att skapa tolkningar och styrfiler.",
        done: hasSoilMaps && hasSoilMaps.length > 0,
        onClick: () => this.props.history.push("/guidesUploadSoilMaps/gettingStarted"),
      }
    ];

    let currentProgress = menuItems && menuItems.filter((x) => x.done);
    currentProgress = currentProgress && (100.0 * (1 + currentProgress.length) / (1 + menuItems.length));

    let colorProgress = "yellow";
    colorProgress = colorProgress && currentProgress < 25.0 ? "red" : colorProgress;
    colorProgress = colorProgress && currentProgress > 90.0 ? "green" : colorProgress;

    currentProgress = currentProgress && Number(currentProgress.toFixed(0));

    return (
      <Segment 
        style={{
          background: "white",
          boxShadow: "0 1px 2px lightgrey",
          marginTop: 0,
          marginBottom: 8
        }}
        className={isOpen ? null : "fieldLayerCardOnHover"}
        onClick={() => this.toggleOpen()}
      >
      <div
        style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between"
        }}      
      >
        <div 
        style={{ 
          margin: 0,
          color: "black",
          display: "flex",
        }}
        >
        <div 
          style={{
          backgroundColor: "#F8EED0",
          borderRadius: 5,
          width: "2.4em",
          height: "2.4em",
          marginRight: "0.4em",
          }}
        >
          <Image 
            style={{ 
              marginTop: "0.5em",
              marginLeft: "0.5em",
              width: "1.4em", 
              height: "1.4em",
              filter: "invert(0%)",
            }}
            src={imgFieldDataScore} 
            />
          </div>
    
          <span 
            style={{ 
              marginTop: "0.5em", 
              fontWeight: 600, 
              marginLeft: "0.45em" 
            }}
          >
            Skiftesdatapoäng
          </span>
        </div>
  
        <Progress 
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            width: "5em",
          }}
          size="tiny" 
          percent={currentProgress} 
          color={colorProgress}
        />
  
        <div style={{ marginTop: "0.5em" }}>
        
        <Icon 
          style={{ 
            marginLeft: 20, 
            textAlign: "right",
          }} 
          className="iconButton"
          name={isOpen ? "chevron up" : "chevron down"} 
        />
        </div>
      </div>  
  
      {isOpen &&
        <Menu
          compact
          vertical
          fluid
          style={{
            marginTop: "2em"
          }}
        >
          {menuItems && menuItems.filter((x) => typeof(x.hidden) !== "boolean" || x.hidden !== true)
            .map((x) => (
            <Popup
              key={x.key}
              content={x.tooltip}
              trigger={
                <Menu.Item
                  className="opacityInvertedHover"
                  key={x.key}
                  onClick={() => x.onClick()}
                >
                  {(typeof(x.done) === Boolean && x.done === true) ? x.doneCaption : x.notDoneCaption}
                  <Icon style={{ color: x.done ? "#89A275" : "#D6946B" }} name={x.done ? "check" : "close"} />
                </Menu.Item>
              }
            />
          ))}
        </Menu>
      }  
      </Segment>
    );
  }
}

export default withRouter(FieldDataFieldScore);
