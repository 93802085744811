import React, { Component } from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import {
  firestoreConnect,
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import LoaderModal from "@/components/modals/LoaderModal";
import MenuHome from "@/components/menus/MenuHome";

import DashboardPage from "@/components/home/DashboardPage";
import LegalPage from "@/components/home/LegalPage";
import RemindersPage from "@/components/reminders/RemindersPage";
import TimelinePage from "@/components/home/TimelinePage";
import PlanPage from "@/components/home/PlanPage";
import WeatherPage from "@/components/weather/WeatherPage";

const INITIAL_STATE = {};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };
  
  componentDidUpdate = (prevProp) => {
    const {
      db,
      isAdminUser,
    } = this.props;

    if (isAdminUser !== prevProp.isAdminUser || prevProp.db !== this.props.db) {
      let profileLog = db && db.profile && db.profile.log;

      isLoaded(profileLog) && !profileLog.date_first_login_desktop &&
      this.getUser()
        .collection("profile")
        .doc("log")
        .update({ 
          date_first_login_desktop: new Date() 
        });
    };
  };

  getDb = () => this.props.firestoreData;

  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  render() {
    const {
      match: {
        params: { page, subPage }
      },
      isAdminUser,
      isAdvisorUser,
      userId,
      trueUserId,
    } = this.props;

    const db = this.getDb();

    let hasLoadedData = isLoaded(db) && !isEmpty(db);
    let fileBucketRef = this.props.firebase.storage().ref();

    if (hasLoadedData) {
      return (
        <React.Fragment>
          <MenuHome
            db={db}
            page={page}
            subPage={subPage}
            userId={userId}
            trueUserId={trueUserId}
            params={this.props.match.params}
            changeView={this.props.changeView}
            isAdminUser={isAdminUser}
          />

          {(!page || page === "home") &&
            <DashboardPage
              userId={userId}
              db={db}
              getUser={this.getUser}
              fileBucketRef={fileBucketRef}
              isAdvisorUser={isAdvisorUser}
            />
          }

          {page === "weather" &&
            <WeatherPage
              db={db}
              fileBucketRef={fileBucketRef}
            />
          }

          {page === "legal" &&
            <LegalPage
              db={db}
              subPage={subPage}
            />
          }

          {page === "reminders" &&
            <RemindersPage
              db={db}
              getUser={this.getUser}
              userId={userId}
            />
          }

          {page === "plan" &&
            <PlanPage
              db={db}
              userId={userId}
              getUser={this.getUser}
              fileBucketRef={fileBucketRef}
            />
          }

          {page === "timeline" &&
            <TimelinePage
              db={db}
              fileBucketRef={fileBucketRef}
            />
          }
        </React.Fragment>
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      );
    }
  }
}

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "dashboard" },
      ],
      storeAs: 'dashboard'
    },
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fields'
    },
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "fieldsInfo" },
      ],
      storeAs: 'fieldsInfo'
    },
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "fieldsWeather", doc: "farm" },
      ],
      storeAs: 'fieldsWeather'
    },
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "guides" },
      ],
      storeAs: 'guides'
    },
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "notifications" },
      ],
      storeAs: 'notifications'
    },
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "reminders" },
      ],
      storeAs: 'reminders'
    },
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'profile'
    },
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "settings" },
      ],
      storeAs: 'settings'
    },
  ]),
  withFirestoreData,
  withRouter
)(HomePage);
