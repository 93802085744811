import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Segment,
  Modal,
  Button,
  Image,
  Confirm,
  Dropdown,
  Icon,
} from "semantic-ui-react";

import MenuFieldList from "@/components/menus/MenuFieldList";

import { FIELD_DATA_LAYERS } from "@/constants/viewLayersAnalysis";
import { CONTROL_GUIDES } from "@/constants/viewGuides";
import { COLLECT_LAYERS } from "@/constants/viewCollect";

import guidesIcon from "@/assets/icons/menuPrimary/guides.svg";
import insightsIcon from "@/assets/icons/menuSecondary/freja-insights.svg";
import observationIcon from "@/assets/icons/dataTypes/geotag.svg";
import yieldOverviewIcon from "@/assets/icons/yield-overview.svg";

import emptyIllustration from "@/assets/illustrations/done_simple.png";

import moment from 'moment';
import 'moment/locale/sv';

import { CROP_CODES } from "@/constants/cropCodes";

const INITIAL_STATE = {
  showMultipleFieldsModal: false,
  fieldIds: null,
  layer: null,
  layerId: null,
  showConfirmDeleteNotification: false,
  notificationIdToBeDeleted: null,
  notificationsRead: [],
  notificationsDeleted: [],
  noNotificationsToShow: 10,
  categoryFilter: 'all',
  categoryFilterArray: [],
  prevY: 0,
};

const NotificationDot = ({widget}) => (
  <div
    style={{
      position: "relative",
      top: widget ? "-2.0em" : "-1.7em",
      right: widget ? "-2.0em" : "-1.7em",
      width: widget ? "0.7em" : "0.6em",
      height: widget ? "0.7em" : "0.6em",
      borderRadius: "50%",
      backgroundColor: "#C36126"
    }}
  />
);

const NotificationSegment = ({ id, onClick, icon, noIconBackground, header, description, markNotificationRead, deleteNotification, seen, notificationsRead, notificationsDeleted, widget }) => {

  if (notificationsDeleted && notificationsDeleted.includes(id)) {
    return;
  };

  let adjustedSeen = (notificationsRead && notificationsRead.includes(id)) ? true : seen;

  return (
    <Segment
      basic
      className={typeof (onClick) === "function" ? "backgroundColorOnHover" : null}
      style={{
        margin: 7,
        padding: 10,
        cursor: typeof (onClick) === "function" ? "pointer" : null,
        backgroundColor: "#f6f6f6",
        // border: "3px solid #F8EED0",
        borderRadius: 5,
        minWidth: "20vw",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {noIconBackground &&
            <Image
              style={{
                width: widget ? "3.2em" : "2.4em",
                height: widget ? "3.2em" : "2.4em",
                marginRight: "1em",
              }}
            src={icon}
          />        
        }

        {!noIconBackground &&
          <div
            style={{
              backgroundColor: "#F8EED0",
              borderRadius: 5,
              width: widget ? "3.2em" : "2.4em",
              height: widget ? "3.2em" : "2.4em",
              marginRight: "1em",
            }}
          >
            <Image
              style={{
                marginTop: widget ? "0.75em" : "0.5em",
                marginLeft: widget ? "0.75em" : "0.5em",
                width: widget ? "1.75em" : "1.4em",
                height: widget ? "1.75em" : "1.4em",
                filter: "invert(0%)",
              }}
              src={icon}
            />

            {(typeof (adjustedSeen) !== "boolean" || adjustedSeen !== true) && <NotificationDot widget={widget} />}
          </div>
        }

        <div
          style={{
            marginBottom: 0,
            marginLeft: 10,
            width: "90%",
            whiteSpace: "pre-line",
          }}
          onClick={() => onClick()}
        >
          <div style={{ fontWeight: 500, marginBottom: 0 }}>{header && header}</div>
          <div style={{ color: "grey", fontSize: "90%" }}>{description}</div>
        </div>

        <div
          style={{
            width: "1em",
          }}
        >
          <Dropdown
            icon='ellipsis vertical'
            floating
            direction="left"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon='check'
                text={(typeof (seen) === "boolean" && seen === true) ? 'Markera oläst' : 'Markera läst'}
                onClick={() => markNotificationRead(id, (typeof (seen) === "boolean" && seen === true) ? true : false)}
              />
              <Dropdown.Item
                icon='trash'
                text='Ta bort'
                onClick={() => deleteNotification()}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Segment>
  )
};


class MenuNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );

    this.observer.observe(this.bottomDivRef);
  };

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y) {
      this.setState({ noNotificationsToShow: this.state.noNotificationsToShow + 5 });
    };

    this.setState({ prevY: y });
  };

  downloadFile = (filePath) => {
    const {
      fileBucketRef,
    } = this.props;

    filePath && fileBucketRef.child(filePath).getDownloadURL().then((url) => {
      fetch(url)
        .then(async res => ({
          filename: filePath.split("/").slice(-1)[0],
          blob: await res.blob()
        }))
        .then(resObj => {
          // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
          const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });

          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement('a');
            link.href = objUrl;
            link.download = resObj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
          }
        })
        .catch((error) => {
          console.log('DOWNLOAD ERROR', error);
        });
    });
  };

  openField = (notificationId, fieldIds, layer, layerIds) => {
    notificationId && this.markNotificationRead(notificationId, false);

    let layerId = layerIds && (Array.isArray(layerIds) ? layerIds.slice(-1)[0] : layerIds);

    if (fieldIds.length === 1) {
      this.props.closeNotificationMenu && this.props.closeNotificationMenu();
      this.props.history.push("/layers/" + fieldIds[0] + "/" + layer + (layerId ? ("/" + layerId) : ''))
    } else {
      this.setState({
        showMultipleFieldsModal: true,
        fieldIds: fieldIds,
        layer: layer,
        layerId: layerId,
      });

      this.props.toggleShowMultipleFieldsModal && this.props.toggleShowMultipleFieldsModal();
    };
  };

  openSelectedField = (fieldId) => {
    this.openField(null, [fieldId], this.state.layer, this.state.layerId);
    this.props.closeNotificationMenu && this.props.closeNotificationMenu();
  };

  markNotificationRead = (id, currentValue) => {
    const {
      getUser,
    } = this.props;

    // let currentValue = db && id && db.notifications && db.notifications[id] && db.notifications[id].seen;
    // currentValue = typeof(currentValue) === "boolean" ? currentValue : false;

    let dbRef = getUser().collection("notifications").doc(id);
    dbRef.update({ seen: !currentValue });

    let currentNotificationsRead = this.state.notificationsRead;

    if (currentNotificationsRead.includes(id)) {
      this.setState({ notificationsRead: currentNotificationsRead.filter((x) => x !== id) });
    } else {
      this.setState({ notificationsRead: [...currentNotificationsRead, id] });
    };
  };

  toggleDeleteNotification = (id) => {
    this.props.toggleShowMultipleFieldsModal && this.props.toggleShowMultipleFieldsModal();

    let currentValue = this.state.showConfirmDeleteNotification;
    
    this.setState({
      showConfirmDeleteNotification: !currentValue,
      notificationIdToBeDeleted: id ? id : null,
    })
  };

  deleteNotification = () => {
    const {
      getUser,
    } = this.props;

    let id = this.state.notificationIdToBeDeleted;
    getUser().collection("notifications").doc(id).delete();

    let currentValue = this.state.notificationsDeleted;
    this.setState({ notificationsDeleted: [...currentValue, id] });
    this.toggleDeleteNotification()
  };

  markNotificationsRead = () => {
    const {
      db,
      getUser,
    } = this.props;

    const {
      categoryFilterArray,
    } = this.state;

    let notifications = db && db.notifications && db.notifications;
    notifications = notifications && Object.keys(notifications).map(x => ({ id: x, ...db.notifications[x] }));
    notifications = notifications && notifications.filter((x) => typeof (x.type) === "string");
    notifications = notifications && categoryFilterArray.length > 0 ? notifications.filter((x) => categoryFilterArray.includes(x.type)) : notifications;

    let currentNotificationsRead = this.state.notificationsRead;
    this.setState({ notificationsRead: [...currentNotificationsRead, ...notifications.map((x) => x.id)] });

    notifications.forEach((x) => {
      let dbRef = getUser().collection("notifications").doc(x.id);
      dbRef.update({
        seen: true,
      });
    });

    this.props.markAllNotificationsRead && this.props.markAllNotificationsRead()
  };

  getNotification = (data) => {
    if (data && data.type && data.type === "newFields") {
      return this.getNewFieldsSegment(data)
    };

    if (data && data.type && data.type === "newData") {
      return this.getUpdateSegment(data)
    };

    if (data && data.type && data.type === "newComment") {
      return this.getCommentSegment(data)
    };    

    if (data && data.type && data.type === "newFile") {
      return this.getFileSegment(data)
    };

    if (data && data.type && data.type === "newFileGuide") {
      return this.getFileGuideSegment(data)
    };    
  };

  getEmptySegment = (widget) => {

    if (widget) {
      return (
        <Segment 
          basic 
          style={{
            display: "block",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          <Image  
            centered 
            style={{
              width: "50%",
            }}
            src={emptyIllustration}
          />
  
          <div 
            style={{ 
              fontWeight: 500, 
              marginBottom: 0, 
              marginLeft: "15%",
              textAlign: "center",
              width: "70%",
              whiteSpace: "pre-line",
            }}
          >
            Det finns inga nya händelser som du inte redan har läst. Du hittar alla händelser i menyn överst på sidan.
          </div>
        </Segment>
      )
    } else {
      return (
        <Segment
          basic
          style={{
            margin: 0,
            padding: 10,
            backgroundColor: "white",
          }}
        >
          <div 
            style={{
              fontWeight: 500,
              marginBottom: 0,
              textAlign: "center",
              color: "grey",
              marginTop: "40%",
              marginLeft: "15%",
              width: "70%",          
            }}
          >
            Inga händelser.
          </div>
        </Segment>
      )
    };
  };

  getUpdateSegment = (data) => {
    const {
      db,
    } = this.props;

    let title = "Ny data tillgänglig";
    let layer = "greenmass";
    let icon = FIELD_DATA_LAYERS && FIELD_DATA_LAYERS.greenmass.icon;

    let layerIds = data && data.new_entries && data.new_entries.length > 0 && [...data.new_entries].sort();

    if (data && data.data_type && data.data_type === "sentinel") {
      layer = "greenmass";
      title = layerIds.length === 1 ? "En ny grönmassamätning tillgänglig för " : layerIds && layerIds.length.toFixed(0) + " nya grönmassamätningar tillgängliga för ";
      icon = FIELD_DATA_LAYERS && FIELD_DATA_LAYERS.greenmass.icon
    };

    if (data && data.data_type && data.data_type === "soilMapUploaded") {
      layer = "soilMapUploaded";
      title = layerIds.length === 1 ? "En ny markkartering tillgänglig för " : layerIds && layerIds.length.toFixed(0) + " nya markkarteringar tillgängliga för ";
      icon = FIELD_DATA_LAYERS && FIELD_DATA_LAYERS.soilMapUploaded.icon;
    };

    if (data && data.data_type && data.data_type === "moistureMapSweden") {
      layer = "moistureMapSweden";
      title = layerIds.length === 1 ? "Markfuktskartor tillgängliga för " : layerIds && layerIds.length.toFixed(0) + " nya markfuktskartor tillgängliga för ";
      icon = FIELD_DATA_LAYERS && FIELD_DATA_LAYERS.moistureMapSweden.icon;
    };

    if (data && data.data_type && data.data_type === "soilMapSweden") {
      layer = "soilMapSweden";
      title = layerIds.length === 1 ? "Digitala åkermarkskartan tillgänglig för " : layerIds && layerIds.length.toFixed(0) + " digitala åkermarkskartor tillgängliga för ";
      icon = FIELD_DATA_LAYERS && FIELD_DATA_LAYERS.soilMapSweden.icon;
    };

    if (data && data.data_type && data.data_type === "yieldMap") {
      layer = "yieldMap";
      title = layerIds.length === 1 ? "En ny skördekarta tillgänglig för " : layerIds && layerIds.length.toFixed(0) + " nya skördekartor tillgängliga för ";
      icon = FIELD_DATA_LAYERS && FIELD_DATA_LAYERS.yieldMap.icon;
    };

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();

    let fieldNames = '';
    let fieldIds = data && data.field_names && data.field_names;
    fieldIds = fieldIds && [...new Set(fieldIds)];

    let isAvailableToUser = false;

    fieldIds && fieldIds.forEach((x) => {
      let availableToUser = db && db.fields && db.fields[x] && db.fields[x].available_to_user;
      availableToUser = typeof (availableToUser) === "boolean" ? availableToUser : true;

      if (availableToUser) {
        isAvailableToUser = true;
      }
    });

    if (fieldIds && fieldIds.length === 1) {
      let fieldId = fieldIds && fieldIds[0];
      fieldNames = db && db.fields && db.fields[fieldId] && db.fields[fieldId].name ? db.fields[fieldId].name : 'Okänt skiftesnamn';
    } else {
      fieldNames = fieldIds && fieldIds.length.toFixed(0) + " skiften";
    };

    return (
      <NotificationSegment
        key={"update_" + data.id}
        widget={this.props.widget}
        id={data.id}
        header={(title ? title : "") + " " + (fieldNames ? fieldNames : " ") + "."}
        description={(date ? date : "") + "."}
        icon={icon}
        onClick={isAvailableToUser ? () => this.openField(data.id, fieldIds, layer, layerIds) : null}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}
        notificationsRead={this.state.notificationsRead}
        notificationsDeleted={this.state.notificationsDeleted}
      />
    )
  };

  getCommentSegment = (data) => {
    const {
      db,
    } = this.props;

    let title = "Ny kommentar";
    title = title && data.comment ? title + ": " + data.comment : title;

    let icon = insightsIcon;
    icon = data && data.data_type === "observation" ? observationIcon : icon;
    
    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();

    let fieldId = data && data.field_name && data.field_name;
    let fieldIds = data && data.field_names && data.field_names;
    fieldIds = fieldIds && fieldIds.length > 0 ? fieldIds : [ fieldId ];

    let fieldNames = '';

    let isAvailableToUser = false;

    fieldIds && fieldIds.forEach((x) => {
      let availableToUser = db && db.fields && db.fields[x] && db.fields[x].available_to_user;
      availableToUser = typeof (availableToUser) === "boolean" ? availableToUser : true;

      if (availableToUser) {
        isAvailableToUser = true;
      }
    });

    if (fieldId) {
      fieldNames = db && db.fields && db.fields[fieldId] && db.fields[fieldId].name ? db.fields[fieldId].name : '';
    };

    return (
      <NotificationSegment
        key={"update_" + data.id}
        widget={this.props.widget}
        id={data.id}
        header={(title ? title : "") + "."}
        description={(date ? date : "") + "." + (fieldNames ? "Skifte: " + fieldNames + "." : "")}
        icon={icon}
        onClick={isAvailableToUser ? () => this.openField(data.id, fieldIds, null, null) : null}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}
        notificationsRead={this.state.notificationsRead}
        notificationsDeleted={this.state.notificationsDeleted}
      />
    )
  };

  getNewFieldsSegment = (data) => {
    const {
      db,
    } = this.props;

    let title = "Ny skiften tillgängliga";
    let icon = COLLECT_LAYERS.fieldInfo.icon;
    let layerIds = data && data.field_names && data.field_names.length > 0;

    if (data && data.data_type && data.data_type === "unlocked") {
      title = layerIds.length === 1 ? "Grattis! Du har låst upp skiftet: " : "Grattis! Du har låst upp ";
    };

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();

    let fieldNames = '';
    let fieldIds = data && data.field_names && data.field_names;
    fieldIds = fieldIds && [...new Set(fieldIds)];

    fieldIds = fieldIds && fieldIds.filter((x) => {
      let availableToUser = db && db.fields && db.fields[x] && db.fields[x].available_to_user;
      availableToUser = typeof (availableToUser) === "boolean" ? availableToUser : true;

      return availableToUser;
    });

    if (fieldIds.length === 0) {
      return null
    };

    if (fieldIds && fieldIds.length === 1) {
      let fieldId = fieldIds && fieldIds[0];
      fieldNames = db && db.fields && db.fields[fieldId] && db.fields[fieldId].name ? db.fields[fieldId].name : 'Okänt skiftesnamn';

    } else {
      fieldNames = fieldIds && fieldIds.length.toFixed(0) + " nya skiften";
    };

    return (
      <NotificationSegment
        widget={this.props.widget}
        key={"newFields_" + data.id}
        id={data.id}
        header={(title ? title : "") + " " + (fieldNames ? fieldNames : " ") + "."}
        description={(date ? date : "") + "."}
        icon={icon}
        onClick={() => this.openField(data.id, fieldIds, 'greenmass', null)}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}
        notificationsRead={this.state.notificationsRead}
        notificationsDeleted={this.state.notificationsDeleted}
      />
    )
  };

  openUrl = (data) => {
    data && data.id && this.markNotificationRead(data.id, false);

    if (data && data.url) {
      window.location = data.url;
    };

    if (data && data.file_path) {
      this.downloadFile(data.file_path);
    };
  };

  onResumeGuide = (guideUrl, filePath) => {
    const {
      fileBucketRef,
    } = this.props;

    filePath && fileBucketRef.child(filePath).getDownloadURL().then((url) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      
      xhr.onload = function(event) {
        // eslint-disable-next-line
        var blob = xhr.response;
      };
      xhr.open('GET', url);
      xhr.send();
    
      try {
        window.fetch(url).then(async (res) => {
          this.props.history.push(
            guideUrl,
            await res.clone().json(),
          ) 
          // console.log(await res.clone().json())
        });
      } catch (error) {}
    }).catch(function(error) {
      // Handle any errors
    });

    !filePath && this.props.history.push(guideUrl)
  };

  getFileGuideSegment = (data) => {
    const {
      db,
    } = this.props;

    let title = "Nya styrfiler skapade för ";

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();
    
    let guideId = data && data.guide_id;
    let guideData = db && guideId && db && db.guides && db.guides[guideId];
    let guideType = guideData && guideData.guide_type;

    if (!guideData) { 
      return (
        <div />
      )
    };
    
    let currentGuide = CONTROL_GUIDES && guideType && CONTROL_GUIDES.find((x) => x.name === guideType);
    let outputName = currentGuide && currentGuide.outputName;
    
    let noFields = guideData && guideData.field_ids && guideData.field_ids.length;
    noFields = noFields ? " på " + noFields.toFixed(0) + " skiften" : "";

    let crop = guideData && guideData.crop;
    crop = CROP_CODES && crop && CROP_CODES.find((x) => x.name === crop);
    crop = crop && crop.shorttext ? " med " + crop.shorttext : "";
    
    let guideUrl = currentGuide && currentGuide.url && guideId && "/" + currentGuide.url + "/downloadFiles/" + guideId;
    let filePath = guideData && guideData.current_step_state_file_path && guideData.current_step_state_file_path;

    let onClick = () => guideUrl && this.onResumeGuide(guideUrl, filePath);

    return (
      <NotificationSegment
        widget={this.props.widget}
        key={"fileGuide_" + data.id}
        id={data.id}
        header={(title ? title : "") + (outputName ? outputName : "") + (noFields ? noFields : "") + (crop ? crop : "") + "."}
        description={(date ? date : "") + "."}
        icon={currentGuide && currentGuide.icon}
        noIconBackground={true}
        onClick={onClick}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}
        notificationsRead={this.state.notificationsRead}
        notificationsDeleted={this.state.notificationsDeleted}        
      />
    )
  };

  getFileSegment = (data) => {
    const {
      db,
    } = this.props;

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();
    let fieldId = data && data.field_name && data.field_name;
    let fieldName = db && db.fields && db.fields[fieldId] && db.fields[fieldId].name ? db.fields[fieldId].name : 'okänt skiftesnamn';    
    fieldName = fieldId === "farm" ? null : fieldName;

    let title = "Ny fil skapad";
    let icon = yieldOverviewIcon;
    let onClick = () => this.openUrl(data);

    if (data && data.data_type && data.data_type === "backgroundMap") {
      title = "En ny bakgrundskarta är skapad för "
      icon = guidesIcon;
    };

    if (data && data.data_type && data.data_type === "farmReport") {
      title = "En ny gårdsöversikt är skapad för din gård"
      icon = yieldOverviewIcon;
    };

    if (data && data.data_type && data.data_type === "harvestReport") {
      title = "En ny skördeöversikt är skapad för "
      icon = yieldOverviewIcon;
    };

    if (data && data.data_type && data.data_type === "MapPDF") {
      let pdfTitle = (data.file_path).split("/").pop().replace(".pdf", "").replaceAll("_", " ").replace("agriopt ", "").replace(fieldName.toLowerCase(), "").replace(fieldName, "")
      title = "Ny karta exporterad som PDF: " + pdfTitle
      icon = yieldOverviewIcon;
      if (title.length + fieldName.length > 58) title = title.substring(0, 53 - fieldName.length) + ".. "
    };

    return (
      <NotificationSegment
        widget={this.props.widget}
        key={"file_" + data.id}
        id={data.id}
        header={(title ? title : "") + (fieldName ? fieldName : " ") + "."}
        description={(date ? date : "") + "."}
        icon={icon}
        onClick={onClick}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}
        notificationsRead={this.state.notificationsRead}
        notificationsDeleted={this.state.notificationsDeleted}
      />
    )
  };

  render() {
    const {
      userId,
      db,
      widget,
    } = this.props;

    const {
      fieldIds,
      showMultipleFieldsModal,
      showConfirmDeleteNotification,
      categoryFilter,
      categoryFilterArray,
    } = this.state;

    let noNotificationsToShow = this.props.widgetNoNotificationsToShow ? this.props.widgetNoNotificationsToShow : this.state.noNotificationsToShow;

    let menuItems = [
      {
        key: 'all',
        value: 'all',
        onClick: () => this.setState({ categoryFilter: 'all', categoryFilterArray: []}),
        text: "Ingen filtrering",
      },      
      {
        key: 'newFields',
        value: 'newFields',
        onClick: () => this.setState({ categoryFilter: 'newFields', categoryFilterArray: ['newFields']}),
        text: "Nya skiften",
      },
      {
        key: 'newData',
        value: 'newData',
        onClick: () => this.setState({ categoryFilter: 'newData', categoryFilterArray: ['newData']}),
        text: "Ny data",
      },
      {
        key: 'newFiles',
        value: 'newFiles',
        onClick: () => this.setState({ categoryFilter: 'newFiles', categoryFilterArray: ["newFile", "newFiles", "newFileGuide"]}),
        text: "Nya filer",
      },
    ];

    let notifications = db && db.notifications && db.notifications;
    notifications = notifications && Object.keys(notifications).map(x => ({ id: x, ...db.notifications[x] }));
    notifications = notifications && notifications.filter((x) => !widget || (widget && !x.seen));
    notifications = notifications && notifications.filter((x) => typeof (x.type) === "string");
    notifications = notifications && notifications.sort((a, b) => b.date - a.date);

    notifications = notifications && categoryFilterArray.length > 0 ? notifications.filter((x) => categoryFilterArray.includes(x.type)) : notifications;
    let noNotifications = notifications && notifications.length;
    notifications = notifications && notifications.splice(0, noNotificationsToShow);

    let notificationSegments = notifications && notifications.map((x) => this.getNotification(x));
    notificationSegments = notificationSegments && notificationSegments.filter((x) => x !== null);
    notificationSegments = notificationSegments && notificationSegments.length > 0 ? notificationSegments : this.getEmptySegment(widget);

    return (
      <React.Fragment>
        <Confirm
          open={showConfirmDeleteNotification}
          header="Vill du ta bort händelsen?"
          content="Denna handling går inte att ångra."
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.deleteNotification()}
          onCancel={() => this.toggleDeleteNotification()}
          size="tiny"
        />

        <Modal
          open={showMultipleFieldsModal}
        >
          <Modal.Header style={{ fontWeight: 500 }}>
            Välj skifte att öppna
          </Modal.Header>

          <Modal.Content
            style={{
              height: "60vh",
              overflowY: "auto",
            }}
          >
            <MenuFieldList
              db={db}
              userId={userId}
              fieldId={null}
              openField={this.openSelectedField}
              compact={true}
              hideFarmCard={true}
              hideLockedFields={true}
              showOnlyFieldIds={fieldIds}
            />
          </Modal.Content>

          <Modal.Actions
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              style={{
                backgroundColor: "#868D8E",
                color: "white",
              }}
              onClick={() => { 
                this.props.toggleShowMultipleFieldsModal && this.props.toggleShowMultipleFieldsModal(); 
                this.setState({ showMultipleFieldsModal: false })
              }}
            >
              Avbryt
            </Button>
            <div />
          </Modal.Actions>
        </Modal>

        <Segment
          style={{
            padding: widget ? "0.75em" : 0,
            border: "0.5px lightgrey solid",
            borderRadius: 5,
            borderColor: widget ? "transparent" : null,
            boxShadow: widget ? "none" : null,
            // backgroundColor: "#f7f4f0"
            width: widget ? "100%" : "25vw",
            height: widget ? "100%" : null,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 0,
              padding: "1em",
              paddingTop: widget ? "0.5em" : "1em",
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                fontSize: "110%",
              }}
            >
              {widget ? "De senaste händelserna" : "Händelser"}
            </p>
            
            {notificationSegments && notificationSegments.length > 0 &&
              <div>
                <p
                  style={{
                    marginTop: 0,
                    marginRight: 10,
                  }}
                  className="textLink"
                  onClick={() => this.markNotificationsRead()}
                >
                  Markera alla lästa
                </p>

                &bull;

                <Dropdown
                  style={{
                    marginLeft: 10,
                  }}
                  className="textLink"
                  options={menuItems}
                  value={categoryFilter}
                />         
              </div>  
            } 
          </div>

          <Segment.Group
            style={{
              border: "none",
              boxShadow: "none",
              marginTop: 10,
              height: widget ? "100%" : "50vh",
              width: "100%",
              overflowY: "auto",
            }}
          >
            {notificationSegments && notificationSegments}

            {(!widget && (noNotifications >= noNotificationsToShow)) &&
              <Segment
                basic
                className="textLink"
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: 0,
                  margin: 0,
                  marginBottom: 0,
                  paddingBottom: 10,
                  fontWeight: "normal",
                  fontSize: "85%",
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ noNotificationsToShow: this.state.noNotificationsToShow + 5 })}
              >
                Klicka här eller scrolla för att visa fler händelser
                <Icon name="caret down" />
              </Segment>
            }

            {(!widget && (notificationSegments && notificationSegments.length > 0 && noNotifications < noNotificationsToShow)) &&
              <Segment
                basic
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: 0,
                  margin: 0,
                  marginBottom: 0,
                  paddingBottom: 10,
                  fontWeight: "normal",
                  fontSize: "85%",
                }}
              >
                Inga fler händelser hittades
              </Segment>
            }

            <div ref={bottomDivRef => (this.bottomDivRef = bottomDivRef)} />
          </Segment.Group>
        </Segment>
      </React.Fragment>
    )
  }
};

export default withRouter(MenuNotifications);
