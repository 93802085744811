import React, { Component } from "react";

import { 
  Header, 
  Segment,
  Button,
  Icon,
  Label,
  Confirm,
  Image,
} from "semantic-ui-react";

import CollectIcon from "@/assets/icons/menuPrimary/collect-data.svg";
import GuidesIcon from "@/assets/icons/menuPrimary/guides.svg";
import IconWithPlate from "@/components/misc/IconWithPlate";

import { COLLECT_GUIDES } from "@/constants/viewCollect";
import { CROP_CODES } from "@/constants/cropCodes";
import { CONTROL_GUIDES } from "@/constants/viewGuides";

import moment from 'moment';
import 'moment/locale/sv';

const INITIAL_STATE = {
  isOpening: null,
  guideIdToRemove: null,
  showRemoveGuideConfirm: false,
  removedFieldIds: [],
};

class GuidesFieldActiveList extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onToggleRemoveGuideModal = (guideId) => {
    let currentValue = this.state.showRemoveGuideConfirm;
    this.setState({
      showRemoveGuideConfirm: !currentValue,
      guideIdToRemove: guideId ? guideId : null,
    })
  };

  onRemoveGuideId = () => {
    this.props.removeGuide(this.state.guideIdToRemove);
    this.onToggleRemoveGuideModal()
  };

  onResumeGuide = (guideData, selectedGuideConstant) => {
    this.setState({ isOpening: guideData.id });
    this.props.onResumeGuide(guideData, selectedGuideConstant);
  };

  downloadFile = (filePath) => {
    const {
      fileBucketRef,
    } = this.props;

    filePath && fileBucketRef.child(filePath).getDownloadURL().then((url) => {
      fetch(url)
        .then(async res => ({
            filename: filePath.split("/").slice(-1)[0],
            blob: await res.blob()
        }))
        .then(resObj => {
            // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
            const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
  
            // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
            } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);
  
                let link = document.createElement('a');
                link.href = objUrl;
                link.download = resObj.filename;
                link.click();
  
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
            }
        })
        .catch((error) => {
            console.log('DOWNLOAD ERROR', error);
        });
    });
  };

  getDownloadLink = (caption, file_path) => {
    if (file_path && file_path.includes("http")) {
      return (    
        <div style={{ marginBottom: 5 }}>
          <Label
            style={{
              backgroundColor: "#89A275",
              color: "white", 
            }}
            href={file_path} 
            target="_blank" 
            rel="noopener noreferrer"
            size="large"
          >
            <Icon name='file outline' /> {caption}
          </Label>   
        </div> 
      )
    }

    return (
      <div style={{ marginBottom: 5 }}>
        <Label
          style={{
            backgroundColor: "#89A275",
            color: "white", 
          }}
          onClick={() => this.downloadFile(file_path)}
          size="large"
        >
          <Icon name='file outline' /> {caption}
        </Label>   
      </div> 
    )
  }

  render() {
    const {
      fields,
      fieldId,
      guidesForField,
      dataType,
      floating,
    } = this.props;

    const {
      isOpening,
      showRemoveGuideConfirm,
    } = this.state;

    const GUIDES = dataType && (dataType === "guides" ? CONTROL_GUIDES : COLLECT_GUIDES);

    let filteredGuidesForField = guidesForField && guidesForField.filter((x) => x);
    filteredGuidesForField = filteredGuidesForField && filteredGuidesForField
      .sort((a, b) => {
        if (b && b.date_latest_update && a && a.date_latest_update) {
          return b.date_latest_update.toDate() - a.date_latest_update.toDate()
        }
        
        return null
      })

    filteredGuidesForField = filteredGuidesForField && filteredGuidesForField.filter((x) => {
      let selectedGuideConstant = GUIDES && GUIDES.find((y) => y.name === x.guide_type || y.name === x.type);
      return selectedGuideConstant;
    });

    let divStyle = floating ? {} : {
      // display: "grid",
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 70,
      width: "100%",
      height: "calc(100vh - 70px)",
      overflowY: "auto",
    }

    if (filteredGuidesForField && filteredGuidesForField.length > 0) {
      return (
        <React.Fragment>
          <Confirm
            open={showRemoveGuideConfirm}
            size="mini"
            header="Vill du ta bort guiden?"
            content="Denna handling går inte att ångra."
            confirmButton="Ta bort"
            cancelButton="Avbryt"
            onConfirm={() => this.onRemoveGuideId()}
            onCancel={() => this.onToggleRemoveGuideModal()}
          />

          <div
            style={divStyle}
          >     
            {filteredGuidesForField.map((x) => {
              let userGuideName = x && x.name;
              let selectedGuideConstant = GUIDES && GUIDES.find((y) => y.name === x.guide_type || y.name === x.type);
              
              let fieldsInGuide = x.field_ids && x.field_ids.map((y) => fields && fields[y] && fields[y].name);
              fieldsInGuide = fieldsInGuide && fieldsInGuide.join(", ");

              let cropInGuide = x.crop;
              cropInGuide = cropInGuide ? CROP_CODES.find((x) => cropInGuide === x.value) : cropInGuide;
              cropInGuide = cropInGuide ? cropInGuide.shorttext : cropInGuide;

                return (
                  <Segment
                    key={x.id}
                    style={{
                      padding: "1.5em",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "70%",
                      marginLeft: "15%",
                    }}
                  >
                    <div style={{ width: "15%", margin: "auto", }}>
                      {selectedGuideConstant.cartoon && <IconWithPlate size="5vw" src={selectedGuideConstant.cartoon} />}
                      
                      {selectedGuideConstant.icon && 
                        <Image
                          style={{
                            width: "5vw",
                            margin: "auto",
                          }}
                          src={selectedGuideConstant.icon} 
                      />}
                    </div>

                    <div style={{ width: "65%", marginRight: "1.5em" }}>
                      <Header
                        as="h2"
                        style={{
                          fontWeight: 500,
                          whiteSpace: "pre-line",
                          marginBottom: 2,
                        }}
                      >
                        {userGuideName ? userGuideName : (selectedGuideConstant && selectedGuideConstant.caption)}
                      </Header>
                      
                      {/* <p 
                        style={{ 
                          whiteSpace: "pre-line",
                          width: "67%",
                          fontSize: "110%",
                        }}>
                        {selectedGuideConstant && selectedGuideConstant.description}
                      </p> */}
                      
                      {fieldsInGuide && 
                        <p 
                          style={{ 
                            whiteSpace: "pre-line",
                            marginBottom: cropInGuide ? 0 : null,
                          }}
                        >
                          Skiften: {fieldsInGuide}. <br/>
                        </p>
                      }

                      {cropInGuide && 
                        <p 
                          style={{ 
                            whiteSpace: "pre-line",
                            marginTop: 0,
                          }}
                        >
                          Gröda: {cropInGuide}. <br/>
                        </p>
                      }                      

                      {!x.complete && 
                        <p 
                          style={{ 
                            whiteSpace: "pre-line",
                          }}
                        >
                          Guiden påbörjades: {moment(x.date_created.toDate()).format("YYYY-MM-DD")}. <br/>
                          Guiden öppnades {moment(x.date_latest_update.toDate()).locale("sv").locale('sv').fromNow()}.
                        </p>
                      }

                      {x.complete && 
                        <p 
                          style={{ 
                            marginTop: 2,
                            marginBottom: 0,
                            whiteSpace: "pre-line",
                          }}
                        >
                          Guiden avslutades: {moment(x.date_latest_update.toDate()).format("YYYY-MM-DD")}. <br/>
                        </p>
                      }

                      {(x.downloadable_file || (x.downloadable_file_names && x.downloadable_file_names[fieldId])) &&
                        <p 
                          style={{ 
                            whiteSpace: "pre-line",
                          }}
                        >
                          Guiden skapade fil(er) som du han hämta:
                        </p>
                      }                                 
                      {x.downloadable_file && this.getDownloadLink(x.downloadable_file_name, x.downloadable_file)}

                      {x.downloadable_file_names && x.downloadable_file_names[fieldId] 
                        && x.downloadable_file_names[fieldId].map((y, idx) => this.getDownloadLink(y, x.downloadable_files[fieldId][idx])
                      )}
                    </div>  
                    
                    <div style={{ width: "20%" }}>
                       <Button
                        fluid
                        style={{
                          backgroundColor: x.current_step && x.current_step === "downloadFiles" ? "#4C8440" : "#6B9D9F",
                          color: "white",     
                        }}
                        onClick={() => this.onResumeGuide(x, selectedGuideConstant)}
                        size="small"
                        loading={isOpening === x.id}
                      >
                        {x.current_step && x.current_step === "downloadFiles" ? 'Hämta filer' : 'Fortsätt guiden'}
                      </Button> 

                      <div style={{ height: 10 }} />                         

                      <Button
                        fluid
                        style={{
                          backgroundColor: "#868D8E",
                          color: "white",                    
                        }}
                        size="small"
                        onClick={() => this.onToggleRemoveGuideModal(x.id)}
                      >
                        Ta bort
                      </Button>
                    </div>                                              
                  </Segment>
                )
              })
            }
            <div style={{ height: "1em "}} />
          </div>
        </React.Fragment>
      );
    }

    return (
      <div
        style={{
          position: "absolute",
          marginTop: "10%",
          left: "50%",    
        }}
      >
        <IconWithPlate
          filter={dataType === "guides" ? 
            "brightness(0) invert(61%) sepia(8%) saturate(1361%) hue-rotate(134deg) brightness(94%) contrast(88%)" : 
            "brightness(0) invert(53%) sepia(72%) saturate(406%) hue-rotate(353deg) brightness(88%) contrast(92%)"
          }
          size="25vw"
          plateBackground={dataType === "guides" ? "#DEE6E7" : "#F0E4D6"}
          src={dataType === "guides" ? GuidesIcon : CollectIcon}
          fitted={true}
        />

        <Header 
          as='h2'
          color='grey'
          style={{
            fontWeight: "normal",
            textAlign: "center",
          }}
        >
          {dataType === "guides" && ((fieldId && fieldId === "farm") ? "Freja hittar inga pågående guider för denna gård." : "Freja hittar inga pågående guider för detta skifte.")}
          {dataType === "collect" && "Freja hittar ingen uppladdad data för detta skifte."}
        </Header>
      </div>
    )
  }
};

export default GuidesFieldActiveList;
