import React, { Component } from 'react';

import { 
  Header,
  Container,
  Segment,
} from 'semantic-ui-react';

const INITIAL_STATE = {};

class AlreadyCreatedUser extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {

    return (
      <Container
        text
        style={{
          textAlign: "center",
          height: "100vh",
        }}
      >   
        <Segment
          style={{
            marginTop: '30vh',
            padding: "2em",
          }}
        >
          <Header 
            as='h1' 
            style={{
              fontWeight: "normal",
            }}
          >
            Användaren är redan skapad
          </Header>   
        
          <p
            style={{
              fontSize: '1em'
            }}
          >
            Om du vill göra om registreringen eller om något blev fel, kontakta <a href="mailto:support@agriopt.se">support@agriopt.se</a>.
          </p>  
        </Segment>         
      </Container>
    )
  }
};

export default AlreadyCreatedUser;