import React, { Component } from "react";

import FieldCartoonHelper from "@/components/misc/FieldCartoon/fieldCartoonHelper";

class FieldCartoon extends Component {

  render() {
    const {
        position,
        color,
        style,
        stroke,
        className,
        onClick,
    } = this.props;
    
    let newPositionLat = position && position.map((x) => x.latitude);
    let newPositionLng = position && position.map((x) => x.longitude);

    let newPositionLatMin = newPositionLat && Math.min(...newPositionLat);
    let newPositionLatMax = newPositionLat && Math.max(...newPositionLat);

    let newPositionLngMin = newPositionLng && Math.min(...newPositionLng);
    let newPositionLngMax = newPositionLng && Math.max(...newPositionLng);   

    let cartoonPoints = '';
    
    newPositionLng.forEach((x, idx) => {
        let newLat = 0.5 + 31 * (newPositionLat[idx] - newPositionLatMax) / (newPositionLatMin - newPositionLatMax);
        let newLng = 0.5 + 31 * (newPositionLng[idx] - newPositionLngMin) / (newPositionLngMax - newPositionLngMin);
        cartoonPoints = cartoonPoints + (newLng + ',' + newLat + ' ')
    });    

    return (
        <FieldCartoonHelper
          className={className}
          onClick={onClick}
          style={style}      
          color={color}
          stroke={stroke}
          points={cartoonPoints}
      />
    );
  }
}

export default FieldCartoon;
