import React, { Component } from "react";

import {
  Segment,
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import moment from 'moment';

const INITIAL_STATE = {};

class WeatherDailySummaryWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render() {
    const db = this.props.db;

    let tempData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather &&
      db.fieldsWeather.temp_last24hrs;

    let precData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather &&
      db.fieldsWeather.prec_last24hrs;

    let totalPrec = precData && precData.map(x => x.value).reduce((partial_sum, a) => partial_sum + a, 0);

    let meanTemp = tempData && tempData.map(x => x.value).reduce((partial_sum, a) => partial_sum + a, 0);
    meanTemp = meanTemp && meanTemp / 24.0;

    let maxTemp = tempData && Math.max(...tempData.map(x => x.value));
    let minTemp = tempData && Math.min(...tempData.map(x => x.value));

    let plotOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      animation: {
        duration: 0
      },
      tooltips: {
        mode: "nearest",
        intersect: false,
        titleFontStyle: "normal",
        bodyFontStyle: "bold",
        callbacks: {
          label: function (tooltipItem, data) {
            return Math.round(tooltipItem.yLabel) + "°C";
          }
        },
        backgroundColor: 'rgba(141,141,141,0.9)',
        displayColors: false
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          display: false
        },
        ],
        xAxes: [{
          display: false
        }],
      }
    };

    let weatherDates = tempData && tempData.map(x => moment(x.date.toDate()).format("HH:00")).reverse();

    let plotDataLast24hrs = {
      labels: weatherDates,
      datasets: [
        {
          label: "Temperatur (grader)",
          data: tempData && tempData.map(x => x.value).reverse(),
          fill: false,
          borderColor: "#D6946B",
          pointRadius: 0
        },
      ]
    };

    return (
      <div
        style={{ 
          display: "block-inline",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ width: "40%" }}>
            <p
              style={{
                fontWeight: "normal",
                margin: 0,
                textAlign: "center",
                fontSize: "140%",
                color: "grey",
                width: "100%",
              }}
            >
              Medeltemperatur
          </p>
            <p
              style={{
                fontWeight: 600,
                margin: 0,
                textAlign: "center",
                fontSize: "360%",
                width: "100%",
              }}
            >
              {meanTemp && meanTemp.toFixed(1).replace(".", ",")}
              <span
                style={{
                  fontWeight: "normal",
                  color: "grey",
                  fontSize: "50%",
                }}
              >
                &deg;C
              </span>
            </p>
            <p
              style={{
                fontWeight: "normal",
                margin: 0,
                textAlign: "center",
                fontSize: "90%",
                color: "grey",
                width: "100%",
              }}
            >
              under det senaste dygnet
          </p>              
          </div>

          <div style={{ width: "60%" }}>
            <article
              className="canvas-container"
              style={{
                marginLeft: "2em",
                alignContent: "center",
                width: "90%",
                height: "85%",
              }}
            >
              <Line
                data={plotDataLast24hrs}
                options={plotOptions}
              />
            </article>
          </div>
        </div>

        <Segment
          basic
          secondary
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            marginTop: "1em",
            marginBottom: 0,
            fontWeight: 600,
            fontSize: "160%",
            backgroundColor: "#f7f4f0"
          }}
          loading={!minTemp && !maxTemp}
        >
          <div style={{ width: "33%" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "normal",
                margin: 0,
                fontSize: "70%",
                color: "grey",
                width: "100%",
              }}
            >
              Lägst
          </p>
            <p style={{ marginBottom: 0, marginTop: 10, width: "100%", }}>
              {minTemp && minTemp.toFixed(1).replace(".", ",")}
                <span
                  style={{
                    textAlign: "center",
                    fontWeight: "normal",
                    color: "grey",
                    fontSize: "75%",
                  }}
                >
                  &deg;C
                </span>                
            </p>
          </div>

          <div style={{ width: "33%" }}>
            <p
              style={{
                fontWeight: "normal",
                margin: 0,
                textAlign: "center",
                fontSize: "70%",
                color: "grey",
                width: "100%",
              }}
            >
              Högst
          </p>
            <p style={{ marginBottom: 0, marginTop: 10 }}>
              {maxTemp && maxTemp.toFixed(1).replace(".", ",")}
              <span
                style={{
                  textAlign: "center",
                  fontWeight: "normal",
                  color: "grey",
                  fontSize: "75%",
                }}
              >
                &deg;C
              </span>
            </p>
          </div>

          <div style={{ width: "33%" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "normal",
                margin: 0,
                width: "100%",
                fontSize: "70%",
                color: "grey",
              }}
            >
              Nederbörd
          </p>
            <p style={{ marginBottom: 0, marginTop: 10 }}>
              {totalPrec && totalPrec.toFixed(1).replace(".", ",")}

              <span
                style={{
                  textAlign: "center",
                  fontWeight: "normal",
                  color: "grey",
                  fontSize: "75%",
                }}
              >
                {" mm"}
              </span>
            </p>
          </div>
        </Segment>
      </div>
    )
  }
}

export default WeatherDailySummaryWidget;
