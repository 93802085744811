import React, { Component } from "react";
import { 
  Header, 
  Table, 
  Menu, 
  Popup, 
  Icon 
} from "semantic-ui-react";

import { getCurrentColor } from "@/components/fieldData/FieldDataColorScales";

const INITIAL_STATE = {
  selectedUnit: "partial"
};

class HarvestSegmentationMedianAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  toggleUnit = () => {
    const currentValue = this.state.selectedUnit;
    this.setState({
      selectedUnit: currentValue === "partial" ? "total" : "partial"
    });
  };

  render() {
    const {
      layerId,
      dataStats,
      statJson,
      selectedGroup,
      colorScales,
      fieldSize,
      legendVisible,
      toggleLegendVisible,
    } = this.props;

    const { selectedUnit } = this.state;

    let segmentationStats = statJson && layerId&& statJson.segmentation_median && statJson.segmentation_median[layerId];
    let fieldSizes = dataStats && dataStats.groups.map(x => (fieldSize * x.samples.length) / dataStats.noSamples);

    return (
      <div>
        <div
          style={{
            marginTop: 0,
            marginBottom: 0,
            padding: 0,
            display: "flex",
            justifyContent: "space-between",                
          }}
        >
          <Header 
            as="h4"
            style={{
              marginTop: 0,
              color: 'white',
              fontWeight: 500,
            }}        
          >
            Skördeavvikelser{" "}
            <Popup
              position="top center"
              trigger={
                <Icon 
                  name="question circle" 
                  style={{ 
                    marginLeft: '0.25em', 
                    fontSize: '80%', 
                    lineHeight: '80%'
                  }} 
                />              
              }
              style={{
                zIndex: '1000',
              }}
              content={
                "Denna karta visar områden som ofta har högre eller lägre skörd änsnittet. Tabellen nedan visar hur mycket skörden i dessa två regioner minst avviker från snittet över åren samt hur detta påverkar totalskörden."
              }
            />
          </Header>
          <Icon 
            style={{
              color: 'white',
              right: 0,
            }}
            name={legendVisible ? 'chevron down' : 'chevron up'} 
            onClick={() => toggleLegendVisible()}
          />       
        </div>        
        {legendVisible &&
          <Menu inverted fluid secondary widths={2} style={{ marginTop: 0 }}>
            <Menu.Item
              name="partial"
              active={selectedUnit === "partial"}
              onClick={this.toggleUnit}
            >
              Skördebidrag
            </Menu.Item>
            <Menu.Item
              name="total"
              active={selectedUnit === "total"}
              onClick={this.toggleUnit}
            >
              Total skörd
            </Menu.Item>
          </Menu>
        }
        {legendVisible &&
          <Table inverted celled style={{ width: "100%", textAlign: "center", marginTop: 0 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ fontWeight: 500 }}>År</Table.HeaderCell>
                <Table.HeaderCell style={{ fontWeight: 500 }}>
                  {selectedUnit === "partial" ? "Låg" : "Förlust"}
                </Table.HeaderCell>
                <Table.HeaderCell style={{ fontWeight: 500 }}>Snitt</Table.HeaderCell>
                <Table.HeaderCell style={{ fontWeight: 500 }}>
                  {selectedUnit === "partial" ? "Hög" : "Vinst"}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {segmentationStats &&
                Object.keys(segmentationStats).map(index => (
                  <Table.Row key={'table_row_' + index}>
                    <Table.Cell>{index}</Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: getCurrentColor(
                          colorScales,
                          -1.0,
                          selectedGroup !== null
                            ? selectedGroup - 1
                            : selectedGroup,
                          0.4
                        ),
                        backgroundBlendMode: "lighten"
                      }}
                    >
                      {selectedUnit === "partial" &&
                        segmentationStats &&
                        segmentationStats[index] &&
                        segmentationStats[index].lower_limit &&
                        segmentationStats[index].lower_limit.toFixed(1)}
                      {selectedUnit === "total" &&
                        segmentationStats &&
                        segmentationStats[index] &&
                        segmentationStats[index].lower_limit &&
                        (segmentationStats[index].lower_limit * fieldSizes[0]).toFixed(
                          0
                        )}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: getCurrentColor(
                          colorScales,
                          0.0,
                          selectedGroup !== null
                            ? selectedGroup - 1
                            : selectedGroup,
                          0.4
                        ),
                        backgroundBlendMode: "lighten"
                      }}
                    >
                      {selectedUnit === "partial" &&
                        segmentationStats &&
                        segmentationStats[index] &&
                        segmentationStats[index].median &&
                        segmentationStats[index].median.toFixed(1) + " ton/ha"}
                      {selectedUnit === "total" &&
                        segmentationStats &&
                        segmentationStats[index] &&
                        segmentationStats[index].median &&
                        (segmentationStats[index].median * fieldSizes[1]).toFixed(0) + " ton"}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: getCurrentColor(
                          colorScales,
                          1.0,
                          selectedGroup !== null
                            ? selectedGroup - 1
                            : selectedGroup,
                          0.4
                        )
                      }}
                    >
                      {selectedUnit === "partial" &&
                        segmentationStats &&
                        segmentationStats[index] &&
                        segmentationStats[index].upper_limit &&
                        "+" + segmentationStats[index].upper_limit.toFixed(1)}
                      {selectedUnit === "total" &&
                        segmentationStats &&
                        segmentationStats[index] &&
                        segmentationStats[index].upper_limit &&
                        (segmentationStats[index].upper_limit * fieldSizes[2]).toFixed(
                          0
                        )}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        }
      </div>
    );
  }
}

export default HarvestSegmentationMedianAnalysis;
