export function orgNumberIsValid(x) {
    // https://gist.github.com/peppelorum/5856691
    // Check valid length & form
    if (!x) { return false; }

    if (x.indexOf('-') === -1) {
        if (x.length === 10) {
            x = x.slice(0, 6) + "-" + x.slice(6);
        } else {
            x = x.slice(0, 8) + "-" + x.slice(8);
        }
    }
    if (!x.match(/^(\d{2})(\d{2})(\d{2})-(\d{4})|(\d{4})(\d{2})(\d{2})-(\d{4})$/)) { return false };

    // Clean x
    x = x.replace('-', '');
    if (x.length === 12) {
        x = x.substring(2);
    }

    // Declare variables
    var d = new Date(((!!RegExp.$1) ? RegExp.$1 : RegExp.$5), (((!!RegExp.$2) ? RegExp.$2 : RegExp.$6)-1), ((!!RegExp.$3) ? RegExp.$3 : RegExp.$7)),
            sum = 0,
            numdigits = x.length,
            parity = numdigits % 2,
            i,
            digit;

    // Check valid date
    if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) return false;

    // Check luhn algorithm
    for (i = 0; i < numdigits; i = i + 1) {
        digit = parseInt(x.charAt(i), 10);
        if (i % 2 === parity) { digit *= 2; }
        if (digit > 9) { digit -= 9; }
        sum += digit;
    }
    return (sum % 10) === 0;
}

export function emailIsValid(email) {
    // https://tylermcginnis.com/validate-email-address-javascript/
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
};

export function zipCodeIsValid(zipCode) {
    // http://www.pellesoft.se/communicate/forum/view.aspx?msgid=180951&sum=0
    return /^\d{3} \d{2}$/.test(zipCode)
};