import React, { Component } from "react";

import { 
  Button,
  Segment,
  Header,
} from "semantic-ui-react";

class ModalMenuItem extends Component {
  
  render() {
    const {
      title,
      description,
      children,
      buttonValue,
      buttonDisabled,
      buttonText,
      buttonOnClick,
    } = this.props;

    return (
        <Segment
          style={{
            backgroundColor: "#ffffff",
            padding: "1em",
            minHeight: "70px",
          }}
        >   
          <Header
            style={{ 
              display: "flex", 
              justifyContent: "space-between",
              fontWeight: "500",
              marginBottom: 0,
            }}
            >
            {title && title}

            {typeof(buttonOnClick) === "function" &&
              <Button 
                compact
                basic={!buttonValue}
                style={{
                  backgroundColor: buttonValue ? "#4C8440" : "#6B9D9F",
                  color: "white"
                }}
                onClick={() => buttonOnClick()}
                size="small"
                disabled={typeof(buttonDisabled) === "boolean" ? buttonDisabled : false}
                content={buttonText ? buttonText : (buttonValue ? "Avaktivera" : "Aktivera")}
              />
            }
          </Header>

          <p 
            style={{
              marginTop: typeof(buttonOnClick) === "function" ? 0 : 5,
              whiteSpace: 'pre-line',
              width: "80%",
            }}
          >
            {description && description}
          </p>

          {children && children}
      </Segment>
    )
  }
};

export default ModalMenuItem