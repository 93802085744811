import React, { Component } from 'react';

import { 
  Form,
  Button,
  Modal,
  Message,
} from 'semantic-ui-react';

import { toast } from "react-toastify";

const INITIAL_STATE = {
  oldPassword: '',
  newPassword1: '',
  newPassword2: '',
  invalidOldPassword: false,
  invalidNewPassword: false,
  isSubmitting: false,
};

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = () => {
    const {
      oldPassword,
      newPassword1,
      newPassword2,
    } = this.state;

    const {
      firebase,
    } = this.props;

    this.setState({ isSubmitting: true });
    
    let error = false;
    let errorMessage = [];
    
    if (newPassword1 === '' || newPassword1 === '') {
      this.setState({ invalidNewPassword: true});
      errorMessage.push('Ange ett korrekt nytt lösenord.');
      error = true;
    };
    
    if (newPassword1.length < 7) {
      this.setState({ invalidNewPassword: true});
      errorMessage.push('Ange ett nytt lösenord med minst 8 tecken.');
      error = true;
    };  
    
    if (newPassword1 !== newPassword2) {
      this.setState({ invalidNewPassword: true});
      errorMessage.push('De nya lösenorden matchar inte..');
      error = true;
    };

    if (!error) {
      let credential = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        oldPassword
      );

      firebase
        .reauthenticate({credential: credential})
        .then(x => {
          this.setState({ invalidOldPassword: false });
          
          let user = this.props.firebase.auth().currentUser;

          user.updatePassword(newPassword1)
            .then((x) => {
              toast.success("Ditt lösenord har uppdaterats.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 7000,
                  hideProgressBar: false,
                }
              );    
              
              this.setState({ isSubmitting: false });
              this.props.onCancel();
            })
            .catch((error) => {
              toast.error("Ditt lösenord kunde inte uppdaterats. Felmeddelande: " + error,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 7000,
                  hideProgressBar: false,
                }
              );

              this.setState({ isSubmitting: false });
          });        
        })
        .catch(error => {
          errorMessage.push('Det gamla lösenordet är inte korrekt.');
          this.setState({ 
            invalidOldPassword: true,
            errorMessage: errorMessage,
            isSubmitting: false,
          });
        });
      } else {
        this.setState({ 
          errorMessage: errorMessage, 
          isSubmitting: false,
        });
      }
  };

  render() {
    const {
      open,
    } = this.props;

    const {
      oldPassword,
      newPassword1,
      newPassword2,
      invalidOldPassword,
      invalidNewPassword,
      errorMessage,
      isSubmitting,
    } = this.state;

    return (
      <React.Fragment>
        <Modal
          open={open}
          size="tiny"
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            Byt lösenord
          </Modal.Header>
          
          <Modal.Content>
            <p
              style={{
                width: "90%",
                fontSize: "110%",
                whiteSpace: "pre-line",
                marginBottom: "1em",
              }}
            >
              Ange ditt nuvarande lösenord tillsammans med ett nytt lösenord nedan för att byta lösenord för din användare.
            </p>

            <Form>
              <Form.Input
                name='oldPassword'
                icon='lock'
                iconPosition='left'
                type='password'
                label="Nuvarande lösenord"
                placeholder='Ange ditt nuvarande lösenord...'
                style={{
                  color: oldPassword.length > 7 ? "green" : null,
                  width: "67%",
                  marginBottom: "0.5em",
                }}
                error={invalidOldPassword || (oldPassword !== '' && oldPassword.length < 7)}
                value={oldPassword}
                disabled={isSubmitting}
                onChange={(e) => this.onChange(e)}
              />

              <Form.Input
                name='newPassword1'
                icon='lock'
                iconPosition='left'
                type='password'
                label="Nytt lösenord"
                placeholder='Ange ett nytt lösenord...'
                style={{
                  color: newPassword1.length > 7 ? "green" : null,
                  width: "67%",
                  marginBottom: "0.5em",
                }}          
                error={invalidNewPassword || (newPassword1 !== '' && newPassword1.length < 7)}
                value={newPassword1}
                disabled={isSubmitting}
                onChange={(e) => this.onChange(e)}
              />

              <Form.Input
                name='newPassword2'
                icon='lock'
                iconPosition='left'
                type='password'
                label="Bekräfta nytt lösenord"
                placeholder='Bekräfta det nya lösenordet...'
                style={{ 
                  color: (newPassword2 !== '' && newPassword1 === newPassword2) ? "green" : null,
                  width: "67%",
                }}
                error={invalidNewPassword || (newPassword2 !== '' && newPassword1 !== newPassword2)}
                value={newPassword2}
                disabled={isSubmitting}
                onChange={(e) => this.onChange(e)}
              />
            </Form>

            {errorMessage &&
              <Message error style={{ width: "67%" }}>
                {errorMessage && errorMessage.map((x, index) => <li key={'error_' + index}>{x}</li>)}
              </Message>
            }             
          </Modal.Content>
          
          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}              
          >
            <Button 
              disabled={isSubmitting}
              onClick={() => this.props.onCancel()}
            >
              Avbryt
            </Button>

            <Button 
              primary
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={() => this.onSubmit()}
            >
              Byt lösenord
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
};

export default ChangePasswordModal;
