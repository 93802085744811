import imgGuides from "@/assets/icons/menuPrimary/guides.svg";
import imgAdd from "@/assets/icons/menuPrimary/collect-data.svg";
import imgHome from "@/assets/icons/menuPrimary/my-farm.svg";
import imgLayers from "@/assets/icons/menuPrimary/raw-data.svg";
import imgAnalysis from "@/assets/icons/menuPrimary/analysis.svg";

import { COLLECT_GUIDES } from "./viewCollect";
import { CONTROL_GUIDES } from "./viewGuides";

export const MAIN_VIEWS = {
  home: {
    caption: "Min gård",
    tooltip: "Översikt över din gård med händelser, varningar, topplistor och väder.",
    name: "home",
    aliases: ["home", "home/legal", "home/weather", "help", "help/changelog"],
    icon: imgHome,
    includedInPlans: ['all'],
    position: "top",
  },
  layers: {
    caption: "Utforska",
    tooltip: "Utforska och jämför olika datalager enkelt på samma ställe.",
    name: "layers",
    aliases: [],
    icon: imgLayers,
    hidden: false,
    includedInPlans: ['all'],
    position: "top",
  },  
  analysis: {
    caption: "Tolka",
    tooltip: "Låt Freja tolka din data för att hitta mönster och förändringar över tid..",
    name: "analysis",
    aliases: [],
    icon: imgAnalysis,
    hidden: false,
    includedInPlans: ['developer', 'demo', 'analyze', 'control', 'collaborate'],
    position: "top",
  },
  guides: {
    caption: "Styra",
    tooltip: "Skapa styrfiler och rapporter baserat på data och dina insikter.",
    name: "guides",
    aliases: CONTROL_GUIDES && CONTROL_GUIDES.map((x) => x.url),
    icon: imgGuides,
    includedInPlans: ['developer', 'control', 'collaborate'],
    position: "top",
  },     
  collect: {
    caption: "Samla",
    tooltip: "Samla all data från din gård enkelt på samma ställe.",
    name: "collect",
    aliases: COLLECT_GUIDES && COLLECT_GUIDES.map((x) => x.url),
    icon: imgAdd,
    includedInPlans: ['all'],
    position: "bottom",
  },      
};

