import React, { Component } from "react";

import {
  Header,
  Dropdown,
  Segment,
} from "semantic-ui-react";

import { N_GUIDE_CROPS } from "@/components/guides/nitrogenV1/constants";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

import GuideSegmentAdvancedMode from "@/components/guide/GuideSegmentAdvancedMode";
import GuideSegmentCompound from "@/components/guide/GuideSegmentCompound";
import GuideSegmentPreviousRation from "@/components/guide/GuideSegmentPreviousRation";

const INITIAL_STATE = {};

class SelectStrategy extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickNextStep = () => {
    const {
      completedStepsSelectStrategy,
    } = this.props;

    this.props.setParentState({
      openSegmentSelectStrategy: '',
      completedStepsSelectStrategy: [...completedStepsSelectStrategy, "compound"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment) => {
    const {
      db,
      guideCrop,
      guideName,
    } = this.props;

    switch (newSegment) {

      case "crop":
        let selectedCropSettings = guideCrop && N_GUIDE_CROPS && N_GUIDE_CROPS.find((x) => x.key === guideCrop);
        let cropRegions = selectedCropSettings && selectedCropSettings.regions;
        let cropQualities = selectedCropSettings && selectedCropSettings.qualities;

        cropRegions && this.props.setParentState({ guideCropRegion: "middle" });
        cropQualities && this.props.setParentState({ guideCropQuality: "feed" });
        break;

      case "compound":
        let previousCompound = db && db.settings && db.settings.guides;
        previousCompound = previousCompound && previousCompound[guideName];
        previousCompound = previousCompound && previousCompound.compoundType;
        previousCompound = previousCompound ? previousCompound : "none";

        previousCompound && this.props.setParentState({ guideCompoundType: previousCompound });
        break;

      case "strategy":
        this.props.setParentState({ guideNoAdditionalRations: 1 });
        break;

      default:
        break;
    };
  };

  onChange = (data, onlyNumbers = true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  };

  setOpenSegment = (newSegment) => {
    const {
      openSegmentSelectStrategy
    } = this.props;

    (openSegmentSelectStrategy === newSegment) && this.props.setParentState({ openSegmentSelectStrategy: null });
    (openSegmentSelectStrategy !== newSegment) && this.props.setParentState({ openSegmentSelectStrategy: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedStepsSelectStrategy,
    } = this.props;

    !completedStepsSelectStrategy.includes(currentSegment) && this.props.setParentState({
      completedStepsSelectStrategy: [...completedStepsSelectStrategy, currentSegment],
    });

    this.setDefaultSettings(newSegment);
    this.setOpenSegment(newSegment);
  };

  onSelectCrop = (data) => {
    this.onChange(data, false);
    this.setDefaultSettings("crop");
  };

  render() {

    const {
      guideMode,
      guideCrop,
      guideCropRegion,
      guideCropQuality,
      openSegmentSelectStrategy,
      completedStepsSelectStrategy,
    } = this.props;

    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],
      next: [
        {
          caption: "Gå vidare",
          hidden: completedStepsSelectStrategy.length < 4,
          onClick: this.onClickNextStep,
        }
      ],
    };

    let selectedCropSettings = guideCrop && N_GUIDE_CROPS && N_GUIDE_CROPS.find((x) => x.key === guideCrop);
    let cropRegions = selectedCropSettings && selectedCropSettings.regions;
    let cropQualities = selectedCropSettings && selectedCropSettings.qualities;

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegmentAdvancedMode
          open={openSegmentSelectStrategy === "guideMode"}
          complete={completedStepsSelectStrategy && completedStepsSelectStrategy.includes("guideMode")}
          setParentState={this.props.setParentState}
          onClickOpenSegment={() => this.setOpenSegment("guideMode")}
          onClickNextStep={() => this.gotoNextStep("guideMode", "previousRation")}
        />

        <GuideSegmentPreviousRation
          {...this.props}
          guideAction="kvävegödsling"
          compoundType="N"
          open={openSegmentSelectStrategy === "previousRation"}
          disabled={openSegmentSelectStrategy !== "previousRation" && !completedStepsSelectStrategy.includes("previousRation")}
          complete={completedStepsSelectStrategy && completedStepsSelectStrategy.includes("previousRation")}
          onClickOpenSegment={() => this.setOpenSegment("previousRation")}
          onClickNextStep={() => this.gotoNextStep("previousRation", "crop")}
        />

        <GuideSegment
          caption="Gröda"
          skipSimpleMode={guideMode && guideMode === "advanced"}
          open={openSegmentSelectStrategy === "crop"}
          disabled={openSegmentSelectStrategy !== "crop" && !completedStepsSelectStrategy.includes("crop")}
          complete={completedStepsSelectStrategy && completedStepsSelectStrategy.includes("crop")}
          onClickOpenSegment={() => this.setOpenSegment("crop")}
          onClickNextStep={() => this.gotoNextStep("crop", "compound")}
          disableNextStep={!guideCrop || (cropRegions && !guideCropRegion) || (cropQualities && !guideCropQuality)}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
            }}
          >
            Välj den typ av gröda som du kommer att odla samt eventuell region och proteinhalt.
            Denna information använder Freja för att beräkna en målgiva enligt Jordbruksverkets rekommendationer.
          </p>

          <Segment
            secondary
            style={{
              marginTop: "4em",
              width: "80%",
              marginLeft: "10%",
              padding: "1.5em",
            }}
          >
            <Header
              as="h6"
              style={{
                marginBottom: 5,
              }}
            >
              Gröda
            </Header>

            <Dropdown
              placeholder='Gröda'
              fluid
              selection
              options={N_GUIDE_CROPS}
              name="guideCrop"
              value={guideCrop}
              onChange={(e, d) => this.onSelectCrop(d)}
            />

            {cropRegions &&
              <React.Fragment>
                <Header
                  as="h6"
                  style={{
                    marginBottom: 5,
                  }}
                >
                  Region
                </Header>

                <Dropdown
                  placeholder='Region'
                  fluid
                  selection
                  options={cropRegions}
                  name="guideCropRegion"
                  value={guideCropRegion}
                  onChange={(e, d) => this.onChange(d, false)}
                />
              </React.Fragment>
            }

            {cropQualities &&
              <React.Fragment>
                <Header
                  as="h6"
                  style={{
                    marginBottom: 5,
                  }}
                >
                  Proteinhalt
                </Header>

                <Dropdown
                  placeholder='Proteinhalt'
                  fluid
                  selection
                  options={cropQualities}
                  name="guideCropQuality"
                  value={guideCropQuality}
                  onChange={(e, d) => this.onChange(d, false)}
                />
              </React.Fragment>
            }
          </Segment>
        </GuideSegment>

        <GuideSegmentCompound
          {...this.props}
          open={openSegmentSelectStrategy === "compound"}
          disabled={openSegmentSelectStrategy !== "compound" && !completedStepsSelectStrategy.includes("compound")}
          complete={completedStepsSelectStrategy && completedStepsSelectStrategy.includes("compound")}
          onClickOpenSegment={() => this.setOpenSegment("compound")}
          onClickNextStep={() => this.onClickNextStep()}
          caption="Gödslingsmedel"
          question="Vill du ange gödslingsmedel för kompletteringsgivan?"
          description="Välj den typ av gödslingsmedel som du kommer att använda i din kvävegödsling."
          compoundName="kväve"
          compoundType="N"
        />
      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader="Gröda och strategi"
        mainColumnBody="För att kunna beräkna kvävebehovet och skapa dina styrfiler behöver Freja veta mer om vad du ska odla och hur många samt hur stor giva du tänker lägga. Svara på alla frågor för att gå vidare till nästa steg."
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />
    )
  }
}

export default SelectStrategy;