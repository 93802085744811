import React from 'react';
import { useDropzone } from 'react-dropzone';

import IconWithPlate from "@/components/misc/IconWithPlate";

import { 
  Segment,
  Header,
  Dropdown,
} from "semantic-ui-react";

import SoilMapIcon from "@/assets/icons/dataTypes/soil-data.svg";
import YieldMapIcon from "@/assets/icons/dataTypes/yield-data.svg";

export default function GuidesCollectDataDropzone({ activeView, onSetDataType, onDroppedFiles, collectDataError, inSegment=true }) {

  let acceptedFileTypes = '';
  acceptedFileTypes = activeView === "uploadSoilMaps" ? '.xls, .xlsx, .ods, .txt, .csv' : acceptedFileTypes;
  acceptedFileTypes = activeView === "uploadYieldMaps" ? '.zip, .aft, .X01' : acceptedFileTypes;
  acceptedFileTypes = activeView === "uploadSamApplication" ? '.xml' : acceptedFileTypes;

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: 200000000,
    onDrop: (files) => onDroppedFiles(files, activeView),
  });

  let menuItems = [
    {
      key: 'uploadYieldMaps',
      value: 'uploadYieldMaps',
      text: "Skördekartor",
      img: YieldMapIcon,
      onClick: () => onSetDataType('uploadYieldMaps'),
    },
    {
      key: 'uploadSoilMaps',
      value: 'uploadSoilMaps',
      text: "Markkarteringar",
      img:  SoilMapIcon,
      onClick: () => onSetDataType('uploadSoilMaps'),     
    },           
    {
      key: 'uploadSamApplication',
      value: 'uploadSamApplication',
      text: "SAM-ansökan",
      img: YieldMapIcon,
      hidden: true,
      onClick: () => onSetDataType('uploadSamApplication'),     
    },        
  ];

  let currentMenuItem = activeView && menuItems.find((x) => x.key === activeView);
  
  if (inSegment) {
    return (
      <Segment
        style={{
          padding: "1.5em",
          height: "100%"
        }}
      > 
        <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginBottom: "1.5em",
            }}
        >
          <Header
            as="h3"
            style={{ 
              paddingTop: "0",
              fontWeight: 500,
              color: "grey",
            }}
          >
            {"Ladda upp " + currentMenuItem && currentMenuItem.text}
          </Header>

          {onSetDataType &&       
            <Dropdown
              className="textLink"
              options={menuItems.filter((x) => !x.hidden)}
              value={activeView}
            />
          }
        </div>    

        <p 
          style={{
            marginTop: "-0.5em",
            marginBottom: "1.5em",
          }}
        >
          Välj vilken typ av data som du vill ladda upp ovanför till höger.
        </p>    

        <div 
          className="container"
          style={{
            height: "calc(100% - 2.5em)",
            width: "100%",
          }}          
        >
          <div
            style={{
              height: "calc(100% - 2.5em)",
              width: "100%",
            }}         
            {...getRootProps({
              className: 'dropzone',
            })}
          >

            <Segment
              style={{
                height: "100%",
                padding: "2em",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",           
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                borderWidth: 2,
                borderRadius: 2,
                borderColor: '#eeeeee',
                borderStyle: 'dashed',
                outline: 'none',
                transition: 'border .24s ease-in-out',
              }}
            >
              <IconWithPlate 
                inline
                size="10em" 
                src={currentMenuItem.img}
              />

              <Header 
                as="h2"
                style={{
                  fontWeight: 500,
                }}
              >   
                {"Ladda upp " + currentMenuItem && currentMenuItem.text}
              </Header>

              <p>
                Dra och släpp de filer här som du vill ladda upp eller klicka på rutan för att välja filer.
              </p>
              
              {collectDataError &&
                <p  
                  style={{
                    color: "#C36126"
                  }}
                >
                  {collectDataError}
                </p>
              }

              <input {...getInputProps()} />
            </Segment>
          </div>
        </div>
      </Segment>
    );
  } else {
    return (
      <div 
        className="container"
        style={{
          height: "100%",
          width: "100%",
        }}          
      >
        <div
          style={{
            height: "100%",
            width: "100%",
          }}         
          {...getRootProps({
            className: 'dropzone',
          })}
        >
          <Segment
            style={{
              height: "100%",
              padding: "2em",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",           
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              borderWidth: 2,
              borderRadius: 2,
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              outline: 'none',
              transition: 'border .24s ease-in-out',
            }}
          >
            <IconWithPlate 
              inline
              size="10em" 
              src={currentMenuItem.img}
            />

            <Header 
              as="h2"
              style={{
                fontWeight: 500,
              }}
            >   
              {"Ladda upp " + currentMenuItem && currentMenuItem.text}
            </Header>

            <p>
              Dra och släpp de filer här som du vill ladda upp eller klicka på rutan för att välja filer.
            </p>
            
            {collectDataError &&
              <p  
                style={{
                  color: "#C36126"
                }}
              >
                {collectDataError}
              </p>
            }

            <input {...getInputProps()} />
          </Segment>
        </div>
      </div>
    )
  };
};