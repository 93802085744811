import React, { Component } from "react";

import { Line } from "react-chartjs-2";

import { 
  Header,
  Segment,
} from "semantic-ui-react";

import moment from 'moment';

const INITIAL_STATE = {};

class WeatherLast24Hours extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render () {
    const {
        db,
    } = this.props;

    let plotOptions = { 
      responsive: true,
      maintainAspectRatio: false,
      layout: {
          padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
          }
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: 'grey',
          padding: 20,
        }
      },
      scales: {
        yAxes: [
          {
            id: 'temperature',
            type: 'linear',
            position: 'left',
            color: "blue",
            ticks: {
              fontColor: '#e44d2e',
            },            
            gridLines: {
              zeroLineColor: "rgba(0, 0, 0, 0)",
              color: "rgba(0, 0, 0, 0)",
            }
          },
          {
            id: 'rainfall',
            type: 'linear',
            position: 'right',
            ticks: {
              fontColor: '#1f305e',
            },            
            gridLines: {
              zeroLineColor: "rgba(0, 0, 0, 0)",
              color: "rgba(0, 0, 0, 0)",
            }
          }
        ],
        xAxes: [{
          gridLines: {
            zeroLineColor: "rgba(0, 0, 0, 0.1)",
          },
          ticks: {
            fontColor: 'grey',
          },
          scaleLabel: {
            display: true,
            fontColor: 'grey',
            labelString: "Timme",
          }
        }],           
      }
    };

    let tempData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather &&
      db.fieldsWeather.temp_last24hrs;

    let rainfallData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather &&
      db.fieldsWeather.prec_last24hrs;

    let weatherDates = tempData && tempData.map(x => moment(x.date.toDate()).format("HH")).reverse();

    let plotDataLast24hrs = {
      labels: weatherDates,
      datasets: [
        {
          type: "line",
          label: "Temperatur (grader)",
          yAxisID: 'temperature',
          data: tempData && tempData.map(x => x.value).reverse(),
          fill: true,
          backgroundColor: "#D6946B",
        },
        {
          type: "bar",
          label: "Nederbörd (mm)",
          yAxisID: 'rainfall',
          data: rainfallData && rainfallData.map(x => x.value).reverse(),
          fill: false,
          backgroundColor: "#9CB5B6",
        },        
      ]
    };

    return (
      <Segment
        style={{
          padding: "1.5em",
          fontWeight: "normal",
        }}
        loading={!rainfallData || !tempData}
      >
        <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1em",
          }}
        >
          <Header
            as="h3"
            style={{ 
              paddingTop: "0",
              fontWeight: 500,
              color: "grey",
            }}
          >
            Vädret de senaste 24 timmarna
          </Header>
                            
          <div />
        </div>
        
        <article
          className="canvas-container"
          style={{
            height: "50vh",
            padding: "1em",
            paddingTop: 0,
          }}
        >
          <Line 
            data={plotDataLast24hrs}
            options={plotOptions}
          />
        </article>
      
      </Segment>
    )
  }
}

export default WeatherLast24Hours;
