import React, { Component } from "react";

import { 
  Segment,
  Image,
  Header,
} from "semantic-ui-react";

import imgStep1 from "@/assets/icons/guideSteps/step-one.svg";
import imgStep2 from "@/assets/icons/guideSteps/step-two.svg";
import imgStep3 from "@/assets/icons/guideSteps/step-three.svg";
import imgStep4 from "@/assets/icons/guideSteps/step-four.svg";
import imgStep5 from "@/assets/icons/guideSteps/step-five.svg";
import imgStep6 from "@/assets/icons/guideSteps/step-six.svg";
import imgStep7 from "@/assets/icons/guideSteps/step-seven.svg";
import imgStep8 from "@/assets/icons/guideSteps/step-eight.svg";
import imgStep9 from "@/assets/icons/guideSteps/step-nine.svg";

import imgGuides from "@/assets/icons/menuPrimary/guides.svg";
import imgSoilMap from "@/assets/icons/dataTypes/soil-data.svg";
import imgYieldMap from "@/assets/icons/dataTypes/yield-data.svg";

import IconWithPlate from "@/components/misc/IconWithPlate";
import GuideNavigation from "@/components/guide/GuideNavigation";

const imgSteps = {
  0: imgStep1,
  1: imgStep2,
  2: imgStep3,
  3: imgStep4,
  4: imgStep5,
  5: imgStep6,
  6: imgStep7,
  7: imgStep8,
  8: imgStep9,
  9: imgStep9,
};

class GuidesTemplateGettingStarted extends Component {
  
  render() {
    const {
      header,
      description,
      beforeYouStart,
      menuItems,
      onClickNextStep,
      iconType,
      guideIcon,
    } = this.props;

    let icon = imgGuides;
    icon = (iconType && iconType === "soilMap") ? imgSoilMap : icon;
    icon = (iconType && iconType === "yieldMap") ? imgYieldMap : icon;

    return (
      <React.Fragment>
        <GuideNavigation 
          menuItems={{
            next: [
              {
                caption: "Sätt igång",
                onClick: onClickNextStep,
              }
            ]            
          }}
        />

        <div
          style={{
            padding: "2em",
            paddingBottom: "70px",
            marginTop: "4em",
            width: "66%",
            marginLeft: "15%",
          }}
        >        
          <Segment 
            style={{
              padding: "2em",
              paddingTop: "2em",
              display: "flex",
            }}
          >
            <div style={{ width: "30%", margin: "auto" }}>
              {!guideIcon && <IconWithPlate size="15em" src={icon} />}
              {guideIcon && 
                <Image
                  style={{
                    width: "15em",
                    margin: "auto",
                  }}
                  src={guideIcon} 
              />}
            </div>                                     
              
            <div style={{ width: "70%" }}>
              <Header 
                as="h1"
                style={{
                  fontWeight: 500,
                }}
              >
                {header && header}
              </Header>

              <p
                style={{
                  fontWeight: "normal",
                  fontSize: "120%",
                  whiteSpace: "pre-line",
                  width: "90%",
                }}
              >
                {description && description}
              </p>
              
              <Header 
                as="h3"
                style={{
                  fontWeight: 500,
                }}                
              >
                Innan du börjar
              </Header>
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: "120%",
                  whiteSpace: "pre-line",
                  width: "90%",
                }}
              >
                {beforeYouStart && beforeYouStart}
              </p>               
            </div>
          </Segment>
          
          {menuItems && menuItems.filter((x) => x.name !== "gettingStarted").map((x, idx) => (
            <Segment 
              key={"step" + idx}
              style={{
                padding: "1.5em",
                display: "flex",
              }}
            >
              <div style={{ width: "5em" }}>
                <Image
                  style={{ 
                    marginLeft: "1em",
                    marginTop: "0.5em",
                    marginRight: 50, 
                    width: "5em",
                    height: "5em",
                    filter: "brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(7315%) hue-rotate(341deg) brightness(109%) saturate(80%)"
                  }}
                  src={imgSteps[idx]}
                />
              </div>

              <div style={{ width: "calc(100%-5em)", marginLeft: "5%" }}>
                <Header
                  as="h3"
                  style={{
                    marginTop: 10,
                    fontWeight: "normal",
                    marginBottom: 15,
                  }}
                >
                  {x.caption && x.caption}
                </Header>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "normal",
                    width: "80%",
                    paddingBottom: 10,
                  }}
                >
                  {x.description && x.description}
                </p>                
              </div>
            </Segment>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

export default GuidesTemplateGettingStarted;
