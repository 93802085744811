import React, { Component } from "react";

import {
  Icon,
  Header,
  Button,
  Popup,
} from "semantic-ui-react";

import { COLOR_SCALE_TYPES } from "@/constants/map";
import { groupLabelGenerator } from "./groupLabelGenerator";

const MAX_HISTOGRAM_SHARE = 0.5;

const INITIAL_STATE = {
  legendVisible: true,
  groupSelectedByHover: null,
};

class MapGroupWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  toggleLegendVisible = () => {
    const currentValue = this.state.legendVisible;
    this.setState({ legendVisible: !currentValue });
  };

  
  resetIntervalFilter = () => {
    this.props.onChangeSettings({
      intervalFilter: {
        min: null,
        max: null,
      },
      colorScaleType: "e",
      numberOfGroups: 5,
      isUsingFiltering: false,
    });
  };

  render() {
    const {
      layer,
      layerId,
      statJson,
      mode,
      field,
      onSelectGroup,
      selectedGroup,
      dataStats,
      intervalFilter,
      numberOfGroups,
      colorScaleType,
      polygonImage,
      isUsingFiltering,
    } = this.props;

    const { 
      legendVisible,
      groupSelectedByHover, 
    } = this.state;
    
    let fieldSize = field && field.field_size && field.field_size;
    
    let totSamples = dataStats && dataStats.groups && dataStats.groups.reduce((tot, x) => x.noSamples + tot, 0);
    
    const colorScale = COLOR_SCALE_TYPES.find(type => type.value === colorScaleType);

    if (polygonImage || !dataStats) {
      return <div />;
    };

    let miniFormat = mode && (mode.includes("compare") || mode.includes("vraMapEditor"));

    return (
      <div
        style={{
          position: "absolute",
          top:  mode.includes("vraMapEditor") ? 8 : 64,
          right: 8,            
          zIndex: 500,
          background: "#444444",
          boxShadow: "0 1px 2px 0 rgba(174, 179, 179, 0.15)",
          borderRadius: "0.28571429rem",
          color: "white",
          opacity: 0.95,
          padding: miniFormat ? "0.75em" : "1em",
          paddingBottom: miniFormat ? "0em" : (legendVisible ? "1em" : "0em"),
          width: miniFormat ? "8vw" : "18vw",
          minWidth: miniFormat ? 200 : 300,
          // transition: "width 0.3s",
          fontSize: miniFormat ? "95%" : null,
        }}
      >
        <div>
          {(!mode || (!mode.includes("compare") && mode !== "vraMapEditorSimple")) &&
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Header
                as={mode === "vraMapEditor" ? "h6" : "h4"}
                style={{
                  marginTop: 0,
                  fontWeight: 500,
                  color: "white",
                }}
              >
                Områden
                
                <Popup
                  style={{
                    zIndex: "1000"
                  }}
                  trigger={
                    <Icon
                      name="question circle"
                      style={{
                        marginLeft: "0.25em",
                        fontSize: "80%",
                        lineHeight: "80%"
                      }}
                    />
                  }
                  content={
                    "Klicka på stapeln eller krysset för att markera/avmarkera punkterna på skiftet som tillhör området. Du kan dölja/visa denna ruta genom att klicka på pilen upp/ned till höger."
                  }
                />
              
              </Header>
              <div>
                {selectedGroup !== null && 
                  <Icon
                    style={{
                      marginRight: "1em",
                    }}
                    className="iconButton"
                    onClick={() => onSelectGroup(null)}
                    name="trash"                  
                  />
                }
                
                <Icon
                  className="iconButton"
                  name={legendVisible ? "chevron up" : "chevron down"}
                  onClick={() => this.toggleLegendVisible()}
                />
              </div>
            </div>
          }

          {(legendVisible && intervalFilter && isUsingFiltering) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "black",
                background: "#fbbd08",
                width: "calc(100% + 2em)",
                padding: "0.5em 1em",
                marginBottom: "0.5em",
                marginLeft: "-1em",
                marginRight: "-1em"
              }}
            >
              <div
                style={{
                  whiteSpace: "normal",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Icon name="filter" />

                <div style={{ marginLeft: "1em" }}>
                  {colorScale && (
                    <div style={{ whiteSpace: "nowrap" }}>
                      {colorScale.short}
                    </div>
                  )}
                  <div style={{ opacity: 0.7 }}>
                    {numberOfGroups} områden ({intervalFilter.min} -{" "}
                    {intervalFilter.max})
                  </div>
                </div>
              </div>

              <Button
                size="tiny"
                secondary
                onClick={this.resetIntervalFilter}
              >
                Återställ
              </Button>
            </div>
          )}

          {legendVisible && dataStats.groups.map((group, groupIdx) => {

              let share = Math.min(group.samples.length / totSamples, MAX_HISTOGRAM_SHARE);

              if (group && typeof(group.noSamples) === "number" && group.noSamples === 0) {
                return <div />;
              };
              
              let label = groupLabelGenerator({layer, layerId, group, numberOfGroups, statJson});
              
              return (
                <div
                  key={"legend_row_" + layer + "_" + layerId + "_" + groupIdx}
                  onClick={() => {
                    !groupSelectedByHover && onSelectGroup(groupIdx, group);
                    groupIdx === selectedGroup && this.setState({ groupSelectedByHover: false });
                  }}
                  // onMouseEnter={() => {
                  //   !selectedGroup && onSelectGroup(groupIdx, group, true);
                  //   !selectedGroup && this.setState({ groupSelectedByHover: true });
                  // }}
                  // onMouseLeave={() => {
                  //   groupSelectedByHover && onSelectGroup(null, null);
                  //   groupSelectedByHover && this.setState({ groupSelectedByHover: null });
                  // }}
                  className="opacityHover"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    opacity: groupIdx === selectedGroup ? 1 : "",
                    paddingBottom: miniFormat ? 0 : 0,
                    marginBottom: miniFormat ? 5 : 15,
                  }}
                >
                  <div
                    style={{ 
                      width: "30%",                        
                      display: "flex", 
                      justifyContent: "flex-end",
                    }}
                  >

                    <div
                      style={{
                        background:
                          selectedGroup !== null
                            ? groupIdx === selectedGroup
                              ? group.color
                              : "grey"
                            : group.color,
                        height: miniFormat ? 19 : 44,
                        width: share ? (92.5 / MAX_HISTOGRAM_SHARE * share).toFixed(0) + "%" : "0%",
                      }}
                    />

                    <div
                      style={{
                        background:
                          selectedGroup !== null
                            ? groupIdx === selectedGroup
                              ? group.color
                              : "grey"
                            : group.color,
                        height: miniFormat ? 19 : 44,
                        width: "5%",
                        marginLeft: "2.5%",
                      }}
                    />

                  </div>

                  <div
                    style={{ 
                      width: "70%",
                      height: miniFormat ? 25 : 40,
                      marginLeft: 15,
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >

                    {label &&
                      <div
                        style={{
                          height: miniFormat ? 12.5 : 20,
                        }}
                      >
                        <Header
                          as="h4"
                          style={{
                            color: "white",
                            fontWeight: miniFormat ? 500 : 700,
                            fontSize: miniFormat ? "90%" : null,
                          }}
                        >
                          {label}
                        </Header>
                      </div>
                    }

                    {!miniFormat &&
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",         
                          height: miniFormat ? 12.5 : 20,
                          marginTop: label ? null : (miniFormat ? 3.75 : 10),           
                          marginLeft: miniFormat ? -20 : null,
                        }}
                      >
                        <div
                          style={{
                            fontSize: miniFormat ? "90%" : null,
                          }}
                        >
                          <Icon 
                            name="percent" 
                            style={{ 
                              fontSize: "65%", 
                            }} 
                          />

                          {((100.0 * group.samples.length) / totSamples).toFixed(0)}
                        </div>
                        
                        <div
                          style={{
                            fontSize: miniFormat ? "90%" : null,
                          }}                          
                        >
                          <Icon 
                            style={{ 
                              marginLeft: "1em", 
                              fontSize: "65%" 
                            }} 
                            name="chart area" 
                          />

                          {((fieldSize * group.samples.length) / totSamples).toFixed(1) + " ha"}
                        </div>

                        <div />
                      </div>
                    }
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    )
  }
}

export default MapGroupWidget;


