import React, { Component } from "react";

import { 
  Header,
  Segment,
  Dropdown,
  Form,
  Button,
  Popup,
  Icon,
} from "semantic-ui-react";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import MenuFieldList from "@/components/menus/MenuFieldList";

import { CROP_CODES } from "@/constants/cropCodes";

import { getCurrentYear, getPreviousYear } from "@/helpers/dates";

class GuidesTemplateSelectFields extends Component {

  state = {
    filterCurrentCrop: 'all',
    filterPreviousCrop: 'all',
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.selectedFieldIds !== prevProps.selectedFieldIds) {
      if (this.props.selectedFieldIds.length === 0) {
        this.props.setParentState({ selctedCropCode: '' });
      };
    };
  };

  toogleShowAll = () => {
    let currentValue = this.state.showAllFields;
    this.setState({ showAllFields: !currentValue });
  };
  
  onSelectField = (newFieldId, override=false) => {
    const {
      db, 
      enforceSameCrop,
    } = this.props;

    let fieldIds = typeof(newFieldId) === "string" ? [newFieldId] : newFieldId;

    this.props.onSelectField(fieldIds, override);

    if (fieldIds && enforceSameCrop && !this.props.guideCrop) {
      let currentYear = getCurrentYear();
      let fieldId = fieldIds && fieldIds.length > 0 && fieldIds[0];

      let fieldsInfo = fieldId && db && db.fieldsInfo && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];
      let crop = fieldsInfo && fieldsInfo.crop_code && fieldsInfo.crop_code[currentYear];
      crop && this.props.setParentState({ guideCrop: crop })
    };

    override && !newFieldId && this.props.setParentState({ guideCrop: '' });
  };

  onClickNextStep = () => {
    this.props.onClickNextStep();
  };

  onChange = (data) => this.setState({ [data.name]: data.value });

  render() {

    const {
      db,
      userId,
      guideCrop,
      selectedFieldIds,
      enforceSameCrop,
      mainColumnHeader,
      fieldsWithoutRelevantData,
      fieldsWithoutRelevantDataReason,
    } = this.props;

    const {
      filterCurrentCrop,
      filterPreviousCrop,
    } = this.state;

    let currentYear = getCurrentYear();
    let previousYear = getPreviousYear();

    let allFieldIds = db && db.fields && Object.keys(db.fields).map((x) => x);
    let fieldsRemovedReason = {};
    let fieldIdsRemoved = [];
    
    // Fields removed due to missing data
    fieldsWithoutRelevantData && fieldsWithoutRelevantData.forEach((x) => {
      fieldsRemovedReason = {...fieldsRemovedReason, [x]: { reason: fieldsWithoutRelevantDataReason }}
    });

    fieldIdsRemoved = fieldsWithoutRelevantData ? [...fieldIdsRemoved, ...fieldsWithoutRelevantData] : fieldIdsRemoved;

    // Filtered by selected field crop code
    let fieldIdsToRemoveDueToEnforceCope = [];

    allFieldIds && guideCrop && allFieldIds.forEach((fieldId) => {
      let fieldsInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];
      let currentCrop = fieldsInfo && fieldsInfo.crop_code && fieldsInfo.crop_code[currentYear];

      if (currentCrop && guideCrop !== currentCrop) {
        fieldIdsToRemoveDueToEnforceCope = [...fieldIdsToRemoveDueToEnforceCope, fieldId];
        fieldsRemovedReason = {...fieldsRemovedReason, [fieldId]: { reason: "Detta skifte kan inte väljas eftersom alla skiften måste ha samma gröda denna odlingssäsong." }};
      };
    });

    fieldIdsRemoved = fieldIdsToRemoveDueToEnforceCope ? [...fieldIdsRemoved, ...fieldIdsToRemoveDueToEnforceCope] : fieldIdsRemoved;

    // User filtered in view
    let fieldIdsToRemoveDueToFilter = [];

    allFieldIds && allFieldIds.forEach((fieldId) => {
      let fieldsInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];
      
      let previousCrop = fieldsInfo && fieldsInfo.crop_code && fieldsInfo.crop_code[previousYear];
      let currentCrop = fieldsInfo && fieldsInfo.crop_code && fieldsInfo.crop_code[currentYear];

      if (previousCrop && filterPreviousCrop && filterPreviousCrop !== "all" && filterPreviousCrop !== previousCrop) {
        fieldIdsToRemoveDueToFilter = [...fieldIdsToRemoveDueToFilter, fieldId];
        fieldsRemovedReason = {...fieldsRemovedReason, [fieldId]: { reason: "Detta skifte kan inte väljas på grund av filterinställingarna." }};
        return;
      };

      if (currentCrop && filterCurrentCrop && filterCurrentCrop !== "all" && filterCurrentCrop !== currentCrop) {
        fieldIdsToRemoveDueToFilter = [...fieldIdsToRemoveDueToFilter, fieldId];
        fieldsRemovedReason = {...fieldsRemovedReason, [fieldId]: { reason: "Detta skifte kan inte väljas på grund av filterinställingarna." }};
        return;
      };
    });

    fieldIdsRemoved = fieldIdsToRemoveDueToFilter ? [...fieldIdsRemoved, ...fieldIdsToRemoveDueToFilter] : fieldIdsRemoved;

    let showOnlyFieldIds = allFieldIds && allFieldIds
      .filter((x) => !fieldsWithoutRelevantData || !fieldsWithoutRelevantData.includes(x))
      .filter((x) => fieldIdsToRemoveDueToEnforceCope && !fieldIdsToRemoveDueToEnforceCope.includes(x))
      .filter((x) => fieldIdsToRemoveDueToFilter && !fieldIdsToRemoveDueToFilter.includes(x))
    
    let menuItems = {
      next: [
        {
          caption: "Gå vidare",
          onClick: () => this.onClickNextStep(),
          disabled: !selectedFieldIds || selectedFieldIds.length < 1,
        }
      ],
    };

    menuItems = this.props.onClickPreviousStep ? {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],
      ...menuItems
    } : menuItems;

    let mainColumn =
      <div
        style={{
          width: "60%",
          marginLeft: this.props.showFullGrid ? "auto" : null,
          marginRight: this.props.showFullGrid ? "auto" : null,
          paddingBottom: "4em",
        }}
      >
        <Segment
          secondary
          style={{
            marginTop: "1em",
            marginBottom: "0.5em",
            padding: "1em",
            display: "flex",
            justifyContent: "space-between",           
          }}
        >
          <Header 
            as="h3"
            style={{
              height: "100%",
              width: "20%",
              marginTop: "auto",
              marginBottom: "auto",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            Filtrera 
            {" "}
            <Popup
              trigger={<Icon style={{ fontSize: "65%", lineHeight: "45%" }} name="help circle" />}
              content="Du kan filtrera listan på skiften nedanför genom att ange årets gröda eller förfrukten."
            />                
          </Header>                

          <div
            style={{
              width: "35%",
            }}
          >
            <Header 
              as="h6"
              style={{
                marginBottom: 2,
                fontSize: "9pt",
                fontWeight: 500,
              }}
            >
              Årets gröda
            </Header> 
            <Form 
              size="tiny"
            >
              <Dropdown
                selection
                placeholder="Välj gröda..."
                name="filterCurrentCrop"
                onChange={(e, d) => this.onChange(d)}
                value={filterCurrentCrop}
                options={[
                  {
                    key: 'all',
                    value: 'all',
                    text: 'Alla grödor',
                  },
                  ...CROP_CODES
                ]}
                
              />  
            </Form>
          </div>
        
          <div
            style={{
              width: "35%",
            }}
          >
            <Header 
              as="h6"
              style={{
                marginBottom: 2,
                fontSize: "9pt",
                fontWeight: 500,
              }}
            >
              Förfrukt
            </Header> 
              <Form 
                size="tiny"
              >
                <Dropdown
                  selection
                  placeholder="Välj gröda..."
                  name="filterPreviousCrop"
                  onChange={(e, d) => this.onChange(d)}
                  value={filterPreviousCrop}
                  options={[
                    {
                      key: 'all',
                      value: 'all',
                      text: 'Alla grödor',
                    },
                    ...CROP_CODES
                  ]}
                />
              </Form> 
          </div>  
        </Segment>   

        <Segment
          secondary
          style={{
            marginTop: "0.5em",
            marginBottom: "1em",
            padding: "1em",   
            display: "flex",
            justifyContent: "space-between",  
          }}
        >
          <Header 
            as="h3"
            style={{
              height: "100%",
              width: "20%",
              marginTop: "auto",
              marginBottom: "auto",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            Markera
            {" "}
            <Popup
              trigger={<Icon style={{ fontSize: "65%", lineHeight: "45%" }} name="help circle" />}
              content="Markera alla de skiften nedanför som du vill använda i guiden. Du kan också klicka på snabbknapparna till höger för att rensa markeringarna eller för att markera alla."
            />                              
          </Header>   

          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
          <Button
            basic
            size="small"
            onClick={() => this.onSelectField([], true)}
          >
            Rensa markeringar
          </Button>

          <Button
            basic
            size="small"
            style={{
              marginLeft: "1em",
            }}
            onClick={() => this.onSelectField(showOnlyFieldIds, true)}
          >
            Markera alla
          </Button>
          </div>
        </Segment> 

        <MenuFieldList
          db={db}
          userId={userId}
          fieldId={null}
          selectedFieldIds={selectedFieldIds}
          openField={this.onSelectField}
          // compact={true}
          hideFarmCard={true}
          hideLockedFields={true}
          addCropInformation={true}
          hideAvailableDataTypes={true}
          showOnlyFieldIds={showOnlyFieldIds}
        /> 

        <div style={{ marginTop: "1em" }} />

        <MenuFieldList
          disabledList={true}
          disableHover={true}
          db={db}
          userId={userId}
          fieldId={null}
          selectedFieldIds={[]}
          openField={() => {}}
          // compact={true}
          hideFarmCard={true}
          hideLockedFields={true}
          addCropInformation={false}
          hideAvailableDataTypes={true}
          showOnlyFieldIds={fieldIdsRemoved}
          additionalInformation={fieldsRemovedReason}
        />         
      </div>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Välj skiften
        </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Markera alla de skiften som du vill använda i denna guide. Du markerar och avmarkerar skiften genom att klicka på dem. Du kan även filtrera skiften baserat på den gröda som du odlar.
        </p>

        {enforceSameCrop &&
          <React.Fragment>
            <Header
              as="h3"
              style={{
                fontWeight: "normal",
                whiteSpace: "pre-line",
              }}
            >
              Samma gröda
            </Header>

            <p
              style={{
                fontWeight: "normal",
                fontSize: "110%",
                whiteSpace: "pre-line",
              }}
            >
              Alla skiften som du väljer måste ha samma gröda under denna säsong. 
              Om du odlar flera olika grödor kan du lägga till en guide för varje typ av gröda som du odlar.
            </p>
          </React.Fragment>
        }

        {this.props.requiredData &&
          <React.Fragment>
            <Header
              as="h3"
              style={{
                fontWeight: "normal",
                whiteSpace: "pre-line",
              }}
            >
              Datalager som krävs
            </Header>
      
            <p
              style={{
                fontWeight: "normal",
                fontSize: "110%",
                whiteSpace: "pre-line",
              }}
            >
              {this.props.requiredData}
            </p>
          </React.Fragment>
        }
      </React.Fragment>

    let mainColumnBody = '';

    mainColumnBody = enforceSameCrop ? 
      mainColumnBody + " De skiften du väljer måste odla samma gröda under denna säsong. Vill du skapa styrfiler för skiften med andra grödor så får du använda separata guider för varje gröda." :
      mainColumnBody;

    mainColumnBody = this.props.mainColumnBody ? this.props.mainColumnBody : mainColumnBody;

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        mainColumnHeader={mainColumnHeader ? mainColumnHeader : "Välj de skiften som du vill skapa styrfiler för"}
        mainColumnBody={mainColumnBody}
        helpColumn={helpColumn}
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        menuItems={menuItems}
      />
    )
  }
}

export default GuidesTemplateSelectFields;