import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  isLoaded,
} from "react-redux-firebase";

import {
  Container,
} from "semantic-ui-react";

import HomeBarWidget from "@/components/home/HomeBarWidget";

import MobileAppBanner from "@/components/home/MobileAppBanner";

import AddFieldsBanner from "@/components/home/AddFieldsBanner";
import PaBenefitsWidget from "@/components/home/PaBenefitsWidget";

import FieldsWidget from '@/components/home/FieldsWidget';
import RemindersWidget from "@/components/reminders/RemindersWidget";
import NotificationsWidget from "@/components/menus/MenuNotifications";

import WeatherDailyForecastWidget from "@/components/weather/WeatherDailyForecastWidget";
import WeatherDailySummaryWidget from "@/components/weather/WeatherDailySummaryWidget";
import WeatherSeasonSummaryWidget from "@/components/weather/WeatherSeasonSummaryWidget";

const INITIAL_STATE = {};

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {
    const {
      db,
      getUser,
      userId,
      fileBucketRef,
      isAdvisorUser,
    } = this.props;

    let noFields = db && db.fields && Object.keys(db.fields).length;
    noFields = db && db.fields && !isLoaded(db.fields) ? 1 : noFields;

    const weatherData = db && db.fieldsWeather;

    return (
      <React.Fragment>
        <Container
          style={{
            paddingTop: "50px",
            backgroundColor: "#f7f4f0",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <HomeBarWidget db={db} />

          {!isAdvisorUser && isLoaded(db.fields) && (!noFields || noFields === 0) && <AddFieldsBanner />}
          {!isAdvisorUser && <MobileAppBanner db={db} userId={userId} getUser={getUser} />}

          {weatherData &&
            <div
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: 75,
                paddingBottom: 75,
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "white",
              }}
            >
              <div style={{ width: "22.5%" }}>
                <WeatherDailySummaryWidget db={db} />
              </div>

              <div style={{ width: "22.5%" }}>
                <WeatherDailyForecastWidget db={db} />
              </div>

              <div style={{ width: "10%" }} />

              <div style={{ width: "45%" }}>
                <WeatherSeasonSummaryWidget
                  weatherData={weatherData}
                  fileBucketRef={fileBucketRef}
                  showOnlyRainfallIrradiation={true}
                />
              </div>
            </div>
          }

          <div
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingTop: 75,
              paddingBottom: 75,
              display: "flex",
              justifyContent: "space-between",

            }}
          >
            <div style={{ width: "45%" }}>
              <NotificationsWidget
                fileBucketRef={fileBucketRef}
                userId={userId}
                db={db}
                getUser={getUser}
                floating={true}
                widget={true}
                widgetNoNotificationsToShow={5}
              />
            </div>

            <div style={{ width: "45%" }}>
              <RemindersWidget
                db={db}
                userId={userId}
                getUser={getUser}
                widget={true}
              />
            </div>
          </div>

          <div
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingTop: 75,
              paddingBottom: 75,
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "white",

            }}
          >

            <div style={{ width: "45%" }}>
              {noFields > 0 && <PaBenefitsWidget userId={userId} db={db} />}
            </div>

            <div style={{ width: "45%" }}>
              {noFields > 0 && <FieldsWidget db={db} />}
            </div>
          </div>

        </Container>
      </React.Fragment>
    );
  }
};

export default withRouter(DashboardPage);
