export const computeMean = (x) => {
  return x && x.reduce((y, xi) => xi + y, 0.0) / x.length;
};

export const computeVar = (x) => {
  let meanX = computeMean(x);
  return x && x.reduce((y, xi) => (xi - meanX) * (xi - meanX) + y, 0.0) / (x.length - 1);
};

export const computeStDev = (x) => {
  return Math.sqrt(computeVar(x));
};

export const computeCov = (x, y) => {
  let meanX = computeMean(x);
  let meanY = computeMean(y);

  return x.reduce((old, xi, idx) => (xi - meanX) * (y[idx] - meanY) + old, 0.0) / (x.length - 1);
};

const sort = (x) => {
  let copy;
  copy = x.slice();

  return copy.sort(function (a, b) {
    return a - b;
  });
};

export const computeRank = (x) => {

  let noPoints = x.length;
  let sorted = x && sort(x);
  let ranks = new Array(noPoints);

  let idx;

  for (idx = 0; idx < noPoints; idx++) {
    let rank, first, last;

    // Ties
    first = sorted.indexOf(x[idx]);
    last = sorted.lastIndexOf(x[idx]);

    if (first === last) {
      rank = first;
    } else {
      rank = (first + last) / 2;
    };

    ranks[idx] = rank + 1;
  };

  return ranks;
};


export const correlation = (x, y) => {

  x = x && x.map((xi) => xi);
  y = y && y.map((yi) => yi);

  let rankX = x && computeRank(x);
  let rankY = y && computeRank(y);

  let stdevX = rankX && computeStDev(rankX);
  let stdevY = rankY && computeStDev(rankY);

  let covXY = rankX && rankY && computeCov(rankX, rankY);
  let corrXY = covXY && (covXY / (stdevX * stdevY));

  return corrXY
};

export const computeCorrelationMap = (markersUpper, keyUpper, markersLower, keyLower) => {

  let dataLeft = markersUpper && markersUpper.map((x) => x.properties && x.properties[keyUpper]);
  let dataRight = markersLower && markersLower.map((x) => x.properties && x.properties[keyLower]);

  dataLeft = dataLeft && dataLeft.filter((x) => x && x);
  dataRight = dataRight && dataRight.filter((x) => x && x);

  let quantilesLeft = dataLeft && quantiles(dataLeft);
  let quantilesRight = dataRight && quantiles(dataRight);

  let correlationMap = [];
  dataLeft && dataRight && dataLeft.length > 0 && dataRight.length > 0 && dataLeft.forEach((x, idx) => {

    let outputCorrelation = 4;
    let outputMissingData = 1.0;

    // Both in 4th quantile
    if (dataLeft[idx] >= quantilesLeft[3] && dataRight[idx] >= quantilesRight[3]) {
      outputCorrelation = 0;
      outputMissingData = 0.0;
    };

    // Both in 1st quantile
    if (dataLeft[idx] <= quantilesLeft[1] && dataRight[idx] <= quantilesRight[1]) {
      outputCorrelation = 1;
      outputMissingData = 0.0;
    };

    // Left in 4th quantile and right in 1st quantile
    if (dataLeft[idx] >= quantilesLeft[3] && dataRight[idx] <= quantilesRight[1]) {
      outputCorrelation = 2;
      outputMissingData = 0.0;
    };

    // Left in 1st quantile and right in 4th quantile
    if (dataLeft[idx] <= quantilesLeft[1] && dataRight[idx] >= quantilesRight[3]) {
      outputCorrelation = 3;
      outputMissingData = 0.0;
    };

    correlationMap = [...correlationMap,
    {
      geometry: markersUpper[idx].geometry,
      properties: {
        correlation: outputCorrelation,
        missing_data: outputMissingData,
      }
    }
    ];
  });

  return correlationMap;
};

export const correlationSimple = (x, y) => {
  // Simplified computation of Spearman without ties

  let noPoints = x.length;

  let sortedX = x && x.map((xi) => xi).slice().sort();
  let sortedY = y && y.map((yi) => yi).slice().sort();

  let distSquared = [];

  x.forEach((xi, idx) => {
    let idxX = sortedX.indexOf(xi);
    let idxY = sortedY.indexOf(y[idx]);

    distSquared = [...distSquared, (idxX - idxY) * (idxX - idxY)]
  })

  let distSquaredSum = distSquared && Math.sum(distSquared);

  return 6 * distSquaredSum / (noPoints * (noPoints * noPoints - 1))
};

export const quantiles = (x) => {
  let sortedX = x.map((y) => y).slice().sort();
  let noPoints = sortedX.length;

  let pointsPerQuantile = noPoints / 4;
  let minValue = Math.min(...x);
  let maxValue = Math.max(...x);

  let secondQuantileIdx = sortedX[Math.floor(pointsPerQuantile)];
  let thirdQuantileIdx = sortedX[Math.floor(2 * pointsPerQuantile)];
  let fourthQuantileIdx = sortedX[Math.floor(3 * pointsPerQuantile)];

  return [minValue, secondQuantileIdx, thirdQuantileIdx, fourthQuantileIdx, maxValue];
};

  export const percentile = (x, limit=90) => {
    let sortedX = [...x].sort((a, b) => a - b);
    let idx = Math.ceil(x.length * limit / 100);
    return sortedX[idx];
  };
