import React, { Component } from "react";

import {
  Header,
  Icon,
} from "semantic-ui-react";

import MapOverviewCorrelationWidget from "@/components/map/MapOverviewWidget/CorrelationWidget";
import MapOverviewInfoWidget from "@/components/map/MapOverviewWidget/InfoWidget";
import MapOverviewMarkersWidget from "@/components/map/MapOverviewWidget/MarkersWidget"
import MapOverviewWeatherWidget from "@/components/map/MapOverviewWidget/WeatherWidget";

class MapOverviewWidget extends Component {

  state = {
    widgetVisible: true,
    hideWeather: false,
  }

  toggleWidgetVisible = () => {
    let currentValue = this.state.widgetVisible;
    this.setState({ widgetVisible: !currentValue });
  }; 

  setHideWeather = (newState) => {
    this.setState({ hideWeather: newState });
  };

  render() {

    const {
      mode,
      layerName,
      hideInfo,
      hideMarkers,
      showCorrelation,
    } = this.props;

    let dataLayerMissing = typeof(this.props.dataLayerMissing) === "boolean" ? this.props.dataLayerMissing : false;
    let hideWeather = this.props.hideWeather ? this.props.hideWeather : this.state.hideWeather;

    let widgetVisible = dataLayerMissing === true ? false : this.state.widgetVisible;
    let miniFormat = mode && (mode.includes("compare") || mode.includes("vraMapEditor"));

    return (
      <div
        style={{
          position: "absolute",
          top: mode && mode.includes("vraMapEditor") ? 8 : 64,
          left: 8,
          zIndex: 450,
          color: "white",
          width: !miniFormat ? "12vw" : "9vw",
          minWidth: !miniFormat ? 250 : null,
          fontSize: !miniFormat ? null : "80% !important",
        }}
      >
        {!dataLayerMissing &&
          <div
            style={{ 
              width: "100%", 
              textAlign: "right", 
              display: "flex",
              justifyContent: "space-between",
              background: "#444444",
              boxShadow: "0 1px 2px 0 rgba(174, 179, 179, 0.15)",
              borderRadius: "0.28571429rem",
              opacity: 0.95,
              padding: !miniFormat ? "1em" : "0.5em",

            }}
          >
            <Header
              as="h5"
              style={{
                fontWeight: 500,
                color: "white",
                paddingBottom: 0,
                marginBottom: 0,
                fontSize: !miniFormat ? null : "90%",
              }}
            >
              {layerName && layerName + " "}
            </Header>
            
            {!miniFormat &&
              <Icon
              className="iconButton"
                name={widgetVisible ? "chevron up" : "chevron down"}
                onClick={() => this.toggleWidgetVisible()}
              />
            } 
          </div>
        }
        
        {widgetVisible && !hideInfo && <MapOverviewInfoWidget {...this.props} setHideWeather={this.setHideWeather} /> }
        {widgetVisible && showCorrelation && <MapOverviewCorrelationWidget {...this.props} />}
        {widgetVisible && !hideMarkers && <MapOverviewMarkersWidget {...this.props} />}        
        {widgetVisible && !hideWeather && <MapOverviewWeatherWidget {...this.props} />}
        {this.props.children && this.props.children}
      </div>
    );
  }
}

export default MapOverviewWidget;

