
import React, { Component } from "react";

import { 
  Segment,
} from "semantic-ui-react";

const INITIAL_STATE = {};

class MapOverviewMarkersWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render () {
    const {
      markers,
      size,
    } = this.props;

    if (!markers) {
      return <div></div>;
    };

    let samplesPerHectar = markers.length / size;

    return (
      <Segment
        basic 
        inverted
        loading={!samplesPerHectar}
        style={{
          width: "100%", 
          marginTop: 10, 
          background: "#444444",
          boxShadow: "0 1px 2px 0 rgba(174, 179, 179, 0.15)",
          borderRadius: "0.28571429rem",
          opacity: 0.95,
          padding: "1em",             
        }}
      >
        {/* <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Header
            as="h4"
            style={{
              marginTop: 0,
              fontWeight: 500,
              color: "white",
            }}
          >
            Jordprover
          </Header>

          <div/>       
        </div>    */}

        <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 5,
            whiteSpace: "pre-line",
          }}
        >
          {markers.length.toFixed(0)} prover är tagna på skiftet. Vilket motsvarar {samplesPerHectar.toFixed(1)} prov per hektar.
        </div> 
      </Segment>
    )
  }
}

export default MapOverviewMarkersWidget;