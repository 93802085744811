import React, { Component } from "react";

import { 
  Container,
} from "semantic-ui-react";

import GuidesAddGuideWidget from "@/components/guideData/GuidesAddGuideWidget";

const INITIAL_STATE = {};

class GuidesAddGuide extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };


  render() {
    const {
      db,
      fieldId,
      dataType,
      userPlanType,
    } = this.props;

    return (
      <React.Fragment>
        <Container
          style={{
            display: "grid",
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 70,
            width: "100%",
            height: "calc(100vh - 70px)",
            overflowY: "auto",
          }}
        >
          {/* <GuidesBarWidget db={db} /> */}

          <div
            style={{
              padding: 20,
              paddingTop: 0,
              marginLeft: "5px",
              marginRight: "15px",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div 
              style={{ 
                width: "49%",
                height: "calc(100vh - 100px)",
              }}
            >
              <GuidesAddGuideWidget 
                db={db}
                fieldId={fieldId}
                userPlanType={userPlanType}
                dataType={dataType}
                onAddGuide={this.props.onAddGuide}
                caption="Skapa styrfiler"
                description="Välj en guide för att börja skapa en styrfil."
                guideType="vraMap"
              />                       
            </div>

            <div 
              style={{ 
                width: "49%",
                height: "calc(100vh - 100px)",
              }}
            >
              <GuidesAddGuideWidget 
                db={db}
                fieldId={fieldId}
                userPlanType={userPlanType}
                dataType={dataType}
                onAddGuide={this.props.onAddGuide}
                caption="Andra guider"
                description="Välj en guide för att börja skapa andra saker än styrfiler."
                guideType="report"
              />         
            </div>
          
          </div>
        </Container>
      </React.Fragment>
    );
  }
};

export default GuidesAddGuide;
