import React from "react";

const SVG = ({
  style = {},
  color = "#e0ba8c",
  stroke = "#e0ba8c", 
  width = "100%",
  className = "",
  viewBox = "0 0 32 32",
  onClick = () => {},
  points = "32,10 10,32 0,0",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    onClick={onClick}
  >
     <polygon points={points} fill={color} stroke={stroke} strokeWidth={0.75} />
  </svg>
);

export default SVG;
