import React, { Component } from "react";

import { Animated } from "react-animated-css";

import { 
  Button, Icon,
} from "semantic-ui-react";

const BACKGROUND_COLORS = {
  'yellow': "#e0ba8c",
  'blue': "#9CB5B6",
  'green': "#89A275",
  'brown': "#CF9D61",
  'red': "#D6946B",
};

class TopBarAnnouncement extends Component {
  state = {
    visible: null,
  };
  
  onHideBar = () => {
    this.setState({ visible: false });
    this.props.onHideBar && this.props.onHideBar();
  };
  
  render () {
    const {
      text,
      buttonText,
      buttonFunction,
      backgroundColor,
      color,
      skipGettingStarted,
    } = this.props;

    let visible = typeof(this.state.visible) === "boolean" ? this.state.visible : skipGettingStarted;

    let backgroundColorHex = backgroundColor && Object.keys(BACKGROUND_COLORS).includes(backgroundColor) ? 
      BACKGROUND_COLORS[backgroundColor] : BACKGROUND_COLORS['yellow'];

    return (
      <Animated
        style={{
          position: "absolute",
          top: "55px",
          left: "85px",
          height: "55px",
          width: "100vw",
          bottom: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: "85px",
          zIndex: 9,
        }}      
        animationIn="slideInDown" 
        animationOut="slideOutUp" 
        animationInDuration={1000} 
        animationOutDuration={1000} 
        isVisible={visible}
        animateOnMount={false}
      >
        <div
          style={{
            backgroundColor: backgroundColorHex,
            color: color ? color : null,
            display: "flex",
            justifyContent: "space-between",
            padding: "1em",
            width: "100%"
          }}
        >
          <div
            style={{
              marginLeft: "0.5em",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {text}
          </div>

          <div
            style={{
              marginRight: "0.5em",
              marginTop: "auto",
              marginBottom: "auto",
            }}      
          >
            {buttonText && typeof(buttonFunction) === "function" &&
              <Button
                style={{
                  marginRight: "1.5em",
                }}
                size="medium"
                onClick={() => buttonFunction()}
              >
                {buttonText}
              </Button>
            }
            <Icon
              style={{
                fontSize: "120%",
                lineHeight: "120%",
              }}
              onClick={() => this.onHideBar()}
              name="close"      
            />
          </div>
        </div>
      </Animated>
    )
  }
};

export default TopBarAnnouncement;
