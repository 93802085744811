import React, { Component } from "react";

import {
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import LoaderModal from "@/components/modals/LoaderModal";

import FileManagerFileList from "@/components/fieldManagement/FieldManagementFileManager/fileList";

const INITIAL_STATE = {};

class FieldManagementFileManager extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {
    const {
      db,
      fileBucketRef,
      getUser,
      fieldId,
      userId,
    } = this.props;

    const fields = db && db.fields;
    const fieldsInfo = db && db.fieldsInfo;

    const uploadedYieldMaps = db && fieldId && db.fieldsYieldMapsUploaded;
    const rawYieldMaps = db && fieldId && db["fieldsYieldMapsRaw_" + fieldId];
    const processedYieldMaps = db && fieldId && db["fieldsYieldMapsProcessed_" + fieldId];

    const uploadedSoilMaps = db && fieldId && db.fieldsSoilMapsUploaded;
    const rawSoilMaps = db && fieldId && db["fieldsSoilMapsRaw_" + fieldId];
    const processedSoilMaps = db && fieldId && db["fieldsSoilMapsProcessed_" + fieldId];

    let hasLoadedData =
      isLoaded(db) &&
      !isEmpty(db) &&
      isLoaded(fields) &&
      !isEmpty(fields) &&
      (isLoaded(uploadedYieldMaps) || isEmpty(uploadedYieldMaps)) &&
      (isLoaded(rawYieldMaps) || isEmpty(rawYieldMaps)) &&
      (isLoaded(processedYieldMaps) || isEmpty(processedYieldMaps)) &&
      (isLoaded(rawSoilMaps) || isEmpty(rawSoilMaps)) &&
      (isLoaded(processedSoilMaps) || isEmpty(processedSoilMaps));

    if (hasLoadedData) {
      return (
        <FileManagerFileList 
          userId={userId}
          getUser={getUser}
          fileBucketRef={fileBucketRef}
          fields={fields}
          fieldsInfo={fieldsInfo}
          fieldId={fieldId}
          uploadedYieldMaps={uploadedYieldMaps}
          rawYieldMaps={rawYieldMaps}
          uploadedSoilMaps={uploadedSoilMaps}
          rawSoilMaps={rawSoilMaps}
          processedYieldMaps={processedYieldMaps}
          processedSoilMaps={processedSoilMaps}
        /> 
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      );
    }
  }
};

export default FieldManagementFileManager;
