import React, { Component } from "react";

import { 
  Button, 
  Message,
  Segment,  
  Header,
  Form,
} from "semantic-ui-react";

import { cloudFunctions } from "@";

const INITIAL_STATE = {
  open: false,
  done: false,
  newYield: null,
  errorMessage: null,
  doneMessage: null,
};

class FieldDataGuideCalibrateYieldMap extends Component {
  state = { ...INITIAL_STATE };

  onCalibrateYield = () => {
    const { 
      userId,
      fieldId,
      fieldInfo,
      layerId,
      estimatedTotalHarvest,
    } = this.props;

    const {
      newYield,
    } = this.state;
    
    let estimatedHarvestFormatted = estimatedTotalHarvest && (typeof(estimatedTotalHarvest) === "string" ? parseInt(estimatedTotalHarvest) : estimatedTotalHarvest);
    let inputtedYieldFormatted = newYield && (typeof(newYield) === "string" ? parseInt(newYield) : newYield);

    let newYieldOutput = inputtedYieldFormatted ? inputtedYieldFormatted : estimatedHarvestFormatted;

    if (newYieldOutput > 5000) {
      this.setState({
        errorMessage: "Torrvikten som du har angivit är för stor. Vänligen ange torrvikten i ton."
      });

      return null;
    }

    // Update calibration factors (if given)
    let currentHarvestYields = fieldInfo && fieldInfo.harvest_yields;

    if (newYieldOutput && currentHarvestYields) {
      currentHarvestYields = {...currentHarvestYields, [layerId.toString()]: Math.floor(newYieldOutput)}
    } else {
      currentHarvestYields = {[layerId.toString()]: Math.floor(newYieldOutput)}
    };

    currentHarvestYields && this.props.getUser().collection("fieldsInfo").doc(fieldId).update({ harvest_yields: currentHarvestYields});

    currentHarvestYields && cloudFunctions.httpsCallable('updatedHarvestYields')(
      {
        userId: userId,
        fieldId: fieldId,
        year: layerId.toString(),
      }
    );

    this.setState({ 
      done: true,
      doneMessage: "Kalibreringen av skördekartan genomförs och Freja kommer inom kort uppdatera kartor och tolkningarna för detta skifte.",
    });
  };  

  onChange = (event) => {
    let newValue = event.target.value;
    newValue = newValue && newValue.replace(/[^\d]/g, '');

    this.setState({ [event.target.name]: newValue });
  };

  render() {
    const { 
      layer,
      layerId, 
      estimatedTotalHarvest
    } = this.props;

    const {
      open,
      done,      
      newYield,
      errorMessage,
      doneMessage,
    } = this.state;

    if (layer && (layer.includes("wetYield") || layer.includes("waterContent"))) {
      return <div />
    }

    if (!done && open) {
      return (
        <React.Fragment>
          <Segment 
            inverted
            style={{ 
              marginTop: 20 
            }} 
          >          
            <Header 
              as="h4"
              style={{
                marginTop: 0,
                color: 'white',
                fontWeight: 500,
              }}        
            >
              Kalibrera skördekarta
            </Header>
            <p
              style={{ 
                color: "white",
                whiteSpace: "pre-line",
                marginBottom: 10,
              }}
            >
              Ange skörden för skiftet {layerId && layerId} som torrvikt (med 14% vattenhalt) i hela ton:
            </p>
            
            <Form
              inverted
              onSubmit={this.onCalibrateYield}
            >
              <Form.Input
                fluid
                name="newYield"
                placeholder="Ny torrvikt i ton för hela skiftet"
                value={newYield ? newYield : parseInt(estimatedTotalHarvest)}
                onChange={this.onChange}
              />
            </Form>

            {errorMessage && 
              <Message 
                style={{
                  backgroundColor: "#D6946B",
                  color: "white",                      
                  marginTop: '20px',
                  whiteSpace: "pre-line",
                }}                
                error
              >
                {errorMessage}
              </Message>
            }

            <Button 
              fluid
              inverted
              style={{
                marginTop: 10,
              }}
              onClick={() => this.onCalibrateYield()}
            >
              Kalibrera
            </Button>
          </Segment>
        </React.Fragment>
      )
    };

    if (!done && !open) {
      return (
        <Button
          fluid
          inverted
          style={{
            marginTop: 10,
          }}
          onClick={() => this.setState({ open: true })}
        >
          Kalibrera skördekartan
        </Button>
      )
    }; 
    
    if (done) {
      return (
        <Message 
          style={{
            backgroundColor: "#89A275",
            color: "white",                      
            marginTop: '20px',
            whiteSpace: "pre-line",
          }}                
          positive
        >
          {doneMessage}
        </Message>
      )
    };
  }
}

export default FieldDataGuideCalibrateYieldMap;
