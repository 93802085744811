import { Component } from "react";

import { toast } from "react-toastify";

import { percentile } from "@/helpers/stats";

import { cloudFunctions } from "@";

import { NUTRIENT_FERTILIZERS, LIMESTONE_TYPES } from "@/constants/fertilizers";

export const INITIAL_STATE_TEMPLATE = {
  showFullGrid: true,
  skipGettingStarted: false,
  selectedFieldIds: [],
  overrideNoVraMapSuggestion: [],
  skippedVraMapsForFields: [],
  slideDirection: null,
};
class GuidesTemplate extends Component {
  state = { ...INITIAL_STATE_TEMPLATE };

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  componentDidMount = () => {
    if (this.props.history.location.state) {
      this.setState(this.props.history.location.state);

      if (this.props.history.location.state && this.props.history.location.state.selectedFieldIds) {
        this.props.setGuidesWrapperState({
          selectedFieldIds: this.props.history.location.state.selectedFieldIds,
        })
      };
    };
  };

  componentDidUpdate = (prevProp) => {
    if (prevProp.history.location.state !== this.props.history.location.state) {
      this.setState(this.props.history.location.state);

      if (this.props.history.location.state && this.props.history.location.state.selectedFieldIds) {
        this.props.setGuidesWrapperState({
          selectedFieldIds: this.props.history.location.state.selectedFieldIds,
        })
      };
    };
  };

  toggleHelpTexts = (guideName) => {
    let db = this.getDb();
    let settingsGuides = db && db.settings && db.settings.guides && db.settings.guides;

    let dbRefSettings = this.getUser().collection("settings").doc("guides");

    let currentValue = settingsGuides &&
      settingsGuides[guideName] &&
      settingsGuides[guideName]['hide_help_text_col'] ?
      settingsGuides[guideName]['hide_help_text_col'] : false;

    settingsGuides && dbRefSettings.update({ [guideName + ".hide_help_text_col"]: !currentValue });
    !settingsGuides && dbRefSettings.set({ guideName: { hide_help_text_col: !currentValue } });
  };

  onSelectField = (fieldIds, override = false) => {
    let newValue = this.state.selectedFieldIds;

    fieldIds && fieldIds.forEach((fieldId) => {
      if (newValue.includes(fieldId)) {
        newValue = newValue.filter((x) => x !== fieldId);
      } else {
        newValue = [...newValue, fieldId]
      };
    });

    newValue = override ? fieldIds : newValue;

    (!newValue || newValue.length === 0) && this.setState({ guideCrop: '' });
    this.setState({ selectedFieldIds: newValue });
    this.props.setGuidesWrapperState({ selectedFieldIds: newValue });
  };

  saveInsightForGuide = (note, guideProps) => {
    const {
      match: {
        params: {
          guideId
        }
      },
    } = this.props;

    const {
      selectedFieldIds,
    } = this.state;

    const {
      guidePurpose,
      guideUrl,
    } = guideProps;

    let dbRef = this.getUser().collection("insights").doc(guideId);

    if (!note) {
      dbRef.delete();
      return;
    };

    let caption = guidePurpose ? 'Styrfilsguide för ' + guidePurpose : "Styrfilsguide";
    let href = guideUrl ? "/" + guideUrl + "/downloadFiles/" + guideId : "";

    dbRef.set({
      href,
      caption,
      date_created: new Date(),
      fieldIds: selectedFieldIds,
      fieldId: '',
      layer: '',
      layerId: '',
      guideId: guideId,
      markers: [],
      note: note,
      tags: [],
    });
  };

  saveNoteToGuide = (note) => {
    const {
      match: {
        params: {
          guideId
        }
      },
    } = this.props;

    let dbRef = this.getUser().collection("guides").doc(guideId);

    if (note) {
      dbRef.update({ note: note });
    } else {
      dbRef.update({ note: '' });
    };
  };

  onSaveGuide = (guideType, guideUrl, nextStep) => {
    const {
      match: {
        params: {
          guideId
        }
      },
    } = this.props;

    const {
      selectedFieldIds,
    } = this.state;

    this.wakeVraMapSuggestion();

    if (guideId) {
      this.getUser().collection("guides").doc(guideId).set({
        type: guideUrl,
        url: guideUrl,
        guide_type: guideType,
        field_id: '',
        field_ids: selectedFieldIds,
        date_created: new Date(),
        date_latest_update: new Date(),
        complete: false,
        current_step: nextStep ? nextStep : '',
      });

      nextStep && this.onSaveState(guideId, nextStep);
      nextStep && this.setState(
        {
          slideDirection: "right"
        },
        this.props.history.push("/" + guideUrl + "/" + nextStep + "/" + guideId, this.state)
      );

    } else {
      this.getUser().collection("guides").add({
        type: guideUrl,
        url: guideUrl,
        guide_type: guideType,
        field_id: '',
        field_ids: selectedFieldIds,
        date_created: new Date(),
        date_latest_update: new Date(),
        complete: false,
        current_step: nextStep ? nextStep : '',
      })
        .then((x) => {
          nextStep && this.onSaveState(x.id, nextStep);
          nextStep && this.setState(
            {
              slideDirection: "right"
            },
            this.props.history.push("/" + guideUrl + "/" + nextStep + (x.id ? ("/" + x.id) : ""), this.state)
          );
        });
    };
  }

  onGotoNextStep = (guideUrl, step, saveStep = true, newState = null, stepToSave = null) => {
    this.setState({
      slideDirection: "right"
    }, this.onGotoStep(guideUrl, step, saveStep, newState, stepToSave))
  };

  onGotoPreviousStep = (guideUrl, step, saveStep = true, newState = null, stepToSave = null) => {
    this.setState({
      slideDirection: "left"
    }, this.onGotoStep(guideUrl, step, saveStep, newState, stepToSave))
  };

  onGotoStep = (guideUrl, step, saveStep = true, newState = null, stepToSave = null) => {
    const {
      match: {
        params: {
          guideId,
          layer,
        }
      },
    } = this.props;

    const {
      selectedFieldIds,
    } = this.state;

    let stateToSend = newState ? newState : this.state;

    let filteredNewState = {};

    Object.keys(stateToSend)
      .map((x) => x)
      .filter((x) => !x.includes("vraMap_") && !x.includes("modifiedVraMap_") && !x.includes("markerDataUndoHistory_") && !x.includes("markerDataRedoHistory_"))
      .forEach((x) => filteredNewState = { ...filteredNewState, [x]: stateToSend[x] });

    filteredNewState = step === "adjustFiles" ? filteredNewState : stateToSend;

    this.props.history.push("/" + guideUrl + "/" + step + (guideId ? ("/" + guideId) : ""));

    step === "adjustFiles" && layer !== "downloadFiles" && this.getVraMapSuggestion(selectedFieldIds);
    saveStep && this.onSaveState(guideId, stepToSave ? stepToSave : step, newState);
  };

  onClickGoToStep1 = () => {
    const {
      guideUrl,
    } = this.props;

    this.onGotoPreviousStep(guideUrl, "selectFields", false);
  };

  onGuideDone = () => {
    const {
      match: {
        params: {
          guideId,
        }
      }
    } = this.props;

    let db = this.getDb()
    let guideInfo = guideId && db && db.guides && db.guides[guideId] && db.guides[guideId];

    // Remove all reminders
    guideInfo && guideInfo.reminder_id && this.getUser().collection("reminders").doc(guideInfo.reminder_id).delete()
    guideInfo && guideInfo.reminder_ids && guideInfo.reminder_ids.forEach((id) => {
      this.getUser().collection("reminders").doc(id).delete()
    });

    this.getUser().collection("guides").doc(guideId).update({
      complete: true,
      date_latest_update: new Date(),
    });

    this.props.history.push("/guides");
  };

  onCloseGuide = () => {
    this.props.history.push("/guides");
  };

  onSaveState = (guideId, step, newState = null) => {
    const {
      userId,
    } = this.props;

    const {
      selectedFieldIds,
    } = this.state;

    const fileBucketRef = this.props.firebase.storage().ref();
    const dbRef = this.getUser();

    const docRef = dbRef.collection("guides").doc(guideId);

    const fileName = guideId + "_current_state.json";
    const key = "users/" + userId + "/guides/" + guideId + "/" + fileName;

    const fileRef = fileBucketRef.child(key);
    guideId && fileRef.putString(JSON.stringify(newState ? newState : this.state))
      .then((x) => {
        docRef.update({
          current_step_state_file_path: key,
          field_ids: selectedFieldIds ? selectedFieldIds : [],
          current_step: step,
          date_latest_update: new Date(),
        });
      })
  };

  handleError = (error) => {
    let errorMessage = error.toString().substring(0, 500);

    if (errorMessage && errorMessage.includes("Error: internal")) {
      return null;
    };

    toast.error("Något gick tyvärr fel. Prova gärna igen och hör av dig till support@agriopt.se om det inte funkar då. Felmeddelande: " + errorMessage + ".",
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 15000,
        hideProgressBar: false,
      });
  };

  setParentState = (payload, callback = null) => {
    this.setState(payload, callback);
  }

  wakeVraMapSuggestion = () => {

    cloudFunctions.httpsCallable('createVraMapSuggestion')(
      {
        userId: this.props.userId,
        fieldId: "none",
        guideId: "none",
        endpoint: "/v1/checkAlive",
        payload: { 'status': 'alive' },
      }
    )
      .then(res => console.log(res))
      .catch(error => this.handleError(error));

    cloudFunctions.httpsCallable('createVraMapFiles')(
      {
        userId: this.props.userId,
        fieldId: "none",
        guideId: "none",
        endpoint: "/v1/checkAlive",
        payload: { 'status': 'alive' },
      }
    )
      .then(res => console.log(res))
      .catch(error => this.handleError(error));
  };

  getUpdatedVraMapSuggestion = (fieldId, previousRationNo, currentRationNo) => {
    const {
      match: {
        params: {
          guideId,
        }
      },
      userId,
    } = this.props;

    cloudFunctions.httpsCallable('createVraMapSuggestion')(
      {
        userId: userId,
        fieldId: fieldId,
        guideId: guideId,
        endpoint: "/v1/getUpdatedMap",
        payload: {
          previousRationNo: previousRationNo,
          currentRationNo: currentRationNo,
        },
      }
    )
      .then(res => console.log(res))
      .catch(error => this.handleError(error));
  };

  tryGetVraMapSuggestionAgain = (fieldId) => {
    const {
      currentRationNo,
    } = this.state;

    currentRationNo === 0 && this.getVraMapSuggestion([fieldId]);
    currentRationNo === 1 && this.getVraMapSuggestion([fieldId]);
    currentRationNo > 1 && this.getUpdatedVraMapSuggestion(fieldId, currentRationNo - 1, currentRationNo);
  };

  saveAdjustedVraMapToDatabase = (fieldId, oldGeoJsonData, geoJsonData) => {
    const {
      match: {
        params: {
          guideId,
        }
      },
      userId,
    } = this.props;

    const {
      currentRationNo,
    } = this.state;

    let currentRation = typeof (currentRationNo) === "number" ? currentRationNo.toFixed(0) : '1';

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    const dbGuideRef = this.getUser().collection("guides").doc(guideId);

    let fieldInfo = db && fieldId && db.fields && db.fields[fieldId];
    let fieldSize = fieldInfo && fieldInfo.field_size;
    let geoJsonDataValues = geoJsonData && geoJsonData.features && geoJsonData.features.map((x) => x.properties.value);

    let meanRation = geoJsonDataValues && Math.mean(geoJsonDataValues);
    let totalRation = meanRation && fieldSize && parseFloat(meanRation) * parseFloat(fieldSize);

    let rationSavings = geoJsonData && this.computeSavings(fieldInfo, oldGeoJsonData);
    let savedTotalRation = rationSavings && rationSavings.diff;

    const fileName = "approved_file_" + fieldId + "_ration_" + currentRation + ".json";
    const filePath = "users/" + userId + "/guides/" + guideId + "/" + fileName;
    const fileRef = fileBucketRef.child(filePath);
    filePath && fileRef.putString(JSON.stringify(geoJsonData));

    filePath && dbGuideRef.update({
      ["approved_files_" + currentRation + "." + fieldId]: filePath,
      ["approved_files_stats_" + currentRation + "." + fieldId + ".mean"]: meanRation ? Math.round(100.0 * meanRation) / 100.0 : '0.0',
      ["approved_files_stats_" + currentRation + "." + fieldId + ".totalRation"]: totalRation ? Math.round(100.0 * totalRation) / 100.0 : '0.0',
      ["approved_files_stats_" + currentRation + "." + fieldId + ".savedTotalRation"]: savedTotalRation ? Math.round(100.0 * savedTotalRation) / 100.0 : '0.0',
    });
  };

  computeSavings = (fieldInfo, geoJson, geoJsonFieldKey = "value") => {

    let values = geoJson && geoJson.features && geoJson.features.map((x) => x.properties[geoJsonFieldKey]);
    values = values && values.filter((x) => x > 0.0);

    let percentile90 = values && percentile(values, 90);

    let fieldSize = fieldInfo && parseFloat(fieldInfo.field_size);
    let oldRation = fieldSize && (fieldSize * percentile90);
    let newRation = values && fieldSize && (Math.mean(values) * fieldSize);

    let diff = oldRation - newRation;
    let percentDiff = diff && 100.0 * diff / oldRation;

    return { diff, percentDiff, percentile90, oldRation }
  };

  clearVraMap = (fieldId) => {
    this.setState({
      ["vraMap_" + fieldId]: null,
      ["modifiedVraMap_" + fieldId]: null,
      ["markerDataRedoHistory_" + fieldId]: [],
      ["markerDataUndoHistory_" + fieldId]: [],
    });
  };

  computeNutrientContent = (compoundType) => {
    const {
      guideCompoundType,
    } = this.state;

    if (guideCompoundType && guideCompoundType !== '') {
      let currentFertilizer = [...LIMESTONE_TYPES, ...NUTRIENT_FERTILIZERS].find((x) => x.value === guideCompoundType);
      let guideCompoundNutrientRation = currentFertilizer ? currentFertilizer.nutrients[compoundType] : '100'

      this.setState({ guideCompoundNutrientRation });
      return guideCompoundNutrientRation
    };

    return 100.0
  };

};
export default GuidesTemplate;
