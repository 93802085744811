import imgFieldInfo from "@/assets/icons/fields.svg";
import FieldsIcon from "@/assets/icons/fields.svg";
import SoilMapIcon from "@/assets/icons/dataTypes/soil-data.svg";
import YieldMapIcon from "@/assets/icons/dataTypes/yield-data.svg";

export const COLLECT_LAYERS = {
  fieldInfo: {
    name: "fieldInfo",
    aliases: [
      "fieldInfo", 
      "fieldInfo_uploadedFilesGuides",
      "fieldInfo_uploadedFiles",
      "fieldInfo_collectedData",
      "fieldInfo_developer",
    ],
    icon: imgFieldInfo,
    caption: "Skiftesdata",
    captions: {
      fieldInfo: "Översikt",
      fieldInfo_uploadedFilesGuides: "Guider för filuppladdning",
      fieldInfo_uploadedFiles: "Hantera uppladdade filer",
      fieldInfo_collectedData: "Hantera insamlad data",
      fieldInfo_developer: "Utvecklarfunktioner",
    },
    shortCaptions: {
      fieldInfo: "Översikt",
      fieldInfo_uploadedFilesGuides: "Guider för filuppladdning",
      fieldInfo_uploadedFiles: "Hantera uppladdade filer",
      fieldInfo_collectedData: "Hantera insamlad data",
      fieldInfo_developer: "Utvecklarfunktioner",
    },    
    helpText: {
      fieldInfo: "Översikt",
      fieldInfo_uploadedFilesGuides: "Guider för filuppladdning",
      fieldInfo_uploadedFiles: "Hantera uppladdade filer",
      fieldInfo_collectedData: "Hantera insamlad data",
      fieldInfo_developer: "Utvecklarfunktioner",
    },
    onlyBeta: [],
    fieldSpecific: {
      fieldInfo: true,
      fieldInfo_uploadedFilesGuides: false,
      fieldInfo_uploadedFiles: false,
      fieldInfo_collectedData: false,
      fieldInfo_developer: true,
    },
    includedInPlans: ['all'],
  },
  seeding: {
    name: "seeding",
    aliases: [
      "seeding", 
    ],
    icon: imgFieldInfo,
    caption: "Sådd",
    captions: {
      seeding: "Sådd",
      // cropCycles: "Växtföljder"
    },
    shortCaptions: {
      seeding: "Sådd",
    },    
    helpText: {
      seeding: "Lägg till och ändra information om din sådd: gröda, sort, utsädesmängd och sådatum.",
    },
    onlyBeta: [],
    fieldSpecific: true,
    includedInPlans: ['all'],
  },  
  nutrition: {
    name: "nutrition",
    aliases: [
      "nutrition"
    ],
    icon: imgFieldInfo,
    caption: "Gödsling",
    captions: {
      nutrition: "Gödning",
      // cropCycles: "Gödslingsmedel"
    },
    shortCaptions: {
      nutrition: "Gödning",
    },    
    helpText: {
      nutrition: "Lägg till och ändra kvävegivor för detta skifte.",
    },
    onlyBeta: [],
    fieldSpecific: true,
    includedInPlans: ['all'],
  },
  harvest: {
    name: "harvest",
    aliases: [
      "harvest", 
    ],
    icon: imgFieldInfo,
    caption: "Skörd",
    captions: {
      harvest: "Skörd",
    },
    shortCaptions: {
      harvest: "Skörd",
    },    
    helpText: {
      harvest: "Lägg till och ändra information om din skörd: invängd skördevikt, proteinhalt och skördedatum.",
    },
    onlyBeta: [],
    fieldSpecific: true,
    includedInPlans: ['all'],
  },    
};

export const COLLECT_GUIDES = [   
  {
    name: 'addNewFieldsV1',
    caption: 'Lägg till skiften',
    description: 'Lägg till nya skiften från SAM-ansökningar eller genom att själv rita ut dem på en karta.',
    cartoon: FieldsIcon,
    url: "guidesAddNewFieldsV1",
    href: "/guidesAddNewFieldsV1/addField",
    fieldSpecific: false,
    disabled: false,
    hidden: false,
    includedInPlans: ['all'],  
  },  
  {
    name: 'uploadSoilMaps',
    caption: 'Ladda upp markkarteringar',
    description: 'Samla dina markkarteringar enkelt i Freja för att se tolkningar och skapa styrfiler.',
    cartoon: SoilMapIcon,
    url: "guidesUploadSoilMaps",
    href: "/guidesUploadSoilMaps/gettingStarted",
    fieldSpecific: false,
    disabled: false,
    hidden: false,
    includedInPlans: ['all'],  
  },            
  {
      name: 'uploadYieldMaps',
      caption: 'Ladda upp skördekartor',
      description: 'Samla dina skördekartor enkelt i Freja för att se tolkningar, hitta skördepotentialen och skapa styrfiler.',
      cartoon: YieldMapIcon,
      url: "guidesUploadYieldMaps",
      href: "/guidesUploadYieldMaps/gettingStarted",
      fieldSpecific: false,
      disabled: false,
      hidden: false,
      includedInPlans: ["all"],
  },               
];
