import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";

import {
  Button,
  Image,
  Header,
  Modal,
  Loader,
} from "semantic-ui-react";

import { sendSignUpEmailToSupport } from '@/helpers/emails';
import { parseSamApplication, saveSamApplication } from "@/helpers/sam";

import GuidesTemplate from "@/containers/Guides/GuidesTemplate";
import MenuGuide from "@/components/guide/GuideMenu";

import AlreadyCreatedUser from "@/components/guides/registrationV1/alreadyCreatedUser";
import FarmInformation from "@/components/guides/registrationV1/farmInformation";
import ContactInformation from "@/components/guides/registrationV1/contactInformation";
import SelectPlan from "@/components/guides/registrationV1/selectPlan";
import Summary from "@/components/guides/registrationV1/summary";

import illustrationDone from "@/assets/illustrations/done.png";
import illustrationError from "@/assets/illustrations/error.png";

const GUIDE_URL = "register2";

const INITIAL_STATE = {
  openSegmentFarm: 'samApplication',
  completedStepsFarm: [],
  openSegmentContact: 'contactPerson',
  completedStepsContact: [],
  openSegmentPlan: 'functions',
  completedStepsPlan: [],
  pickedFiles: null,
  contact: {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    zipCode: '',
    town: '',
    region: '',
    email: '',
    phone: '',
  },
  farm: {
    name: '',
    region: '',
    location: null,
  },
  invoicing: {
    companyId: '',
    address1: '',
    address2: '',
    zipCode: '',
    town: '',
    email: '',
    method: 'E-postfaktura',
  },
  plan: {
    type: '',
    typeName: '',
    price: '',
    validTo: null,
  },
  showSubmitLoader: false,
  showDoneModal: false,
  showErrorModal: false,
  firebaseErrorMessage: null,
  collectDataErrorMessage: null,
  hasJustRegistered: false,
};
class RegistrationV1Part2 extends GuidesTemplate {

  state = INITIAL_STATE;

  componentDidMount = () => {
    const authData = this.props.firebaseData.auth;
    this.setState({
      contact: {
        ...this.state.contact,
        email: authData.email,
      },
      invoicing: {
        ...this.state.invoicing,
        email: authData.email,
      },
    })
  };

  onUploadFiles = (pickedFiles, dataType) => {
    if (pickedFiles.length === 0) {
      this.setState({
        collectDataErrorMessage: "Inga filer med rätt filformat hittades."
      });
      return;
    };

    this.setState({
      collectDataErrorMessage: null,
      openSegmentFarm: 'farmInformation',
      completedStepsFarm: [...this.state.completedStepsFarm, "samApplication"]
    });

    saveSamApplication(this.props, pickedFiles[0]);

    parseSamApplication({
      ...this.props,
      storeParsedInfo: this.storeParsedInfo,
      setParentState: this.setParentState,
    }, pickedFiles[0])
  };

  storeParsedInfo = (newState) => {
    newState && Object.keys(newState).forEach((key) => {
      this.setState({
        [key]: {
          ...this.state[key],
          ...newState[key],
        }
      });
    });
  };

  showSubmitLoader = () => (
    <Modal
      dimmer='inverted'
      open={true}
    >
      <Loader
        size='massive'
      >
        Registrerar användare...
      </Loader>
    </Modal>
  );

  getDoneModal = (open) => (
    <Modal
      open={open}
      size="small"
    >
      <Modal.Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Image
            src={illustrationDone}
            size="medium"
          />

          <div
            style={{
              marginTop: "4em",
              marginLeft: "2em",
            }}
          >
            <Header
              as="h3"
              style={{
                fontWeight: 500,
              }}
            >
              Din användare är registrerad!
            </Header>

            <p>
              Grattis, du har nu skapat ett konto hos AgriOpt!
            </p>

            <p>
              Innan du kan börja använda Freja så behöver du lägga till skiften på din gård. Om du vill så kan du göra detta nu eller senare.
            </p>
          </div>

        </div>
      </Modal.Content>

      <Modal.Actions
        style={{
          display: "flex-end",
          justifyContent: "space-between"
        }}
      >
        <Button
          onClick={() => this.props.history.push("/")}
        >
          Stäng
        </Button>

        <Button
          primary
          onClick={() => this.props.history.push("/guidesAddNewFieldsV1/addField")}
        >
          Lägg till skiften
        </Button>
      </Modal.Actions>
    </Modal>
  )

  getErrorModal = (open, errorMessage) => (
    <Modal
      open={open}
      size="small"
    >
      <Modal.Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Image
            src={illustrationError}
            size="medium"
          />

          <div
            style={{
              marginTop: "1em",
              marginLeft: "2em",
            }}
          >
            <Header
              as="h3"
              style={{
                fontWeight: 500,
              }}
            >
              Något gick fel
            </Header>

            <p>
              Freja kunde inte skapa din användare.
              Vänligen försök senare eller kontakta supporten på <a href="mailto:support@agriopt.se">support@agriopt.se</a> om problemet kvarstår.
            </p>

            <Header
              as="h4"
              style={{
                fontWeight: 500,
              }}
            >
              Felmeddelande
            </Header>

            <p>{errorMessage && errorMessage}</p>
          </div>

        </div>
      </Modal.Content>

      <Modal.Actions
        style={{
          display: "flex-end",
          justifyContent: "space-between"
        }}
      >
        <Button
          style={{
            backgroundColor: "#6B9D9F",
            color: "white",
          }}
          onClick={() => this.setState({ showErrorModal: false })}
        >
          Stäng
        </Button>
      </Modal.Actions>
    </Modal>
  );

  onCreateUserAccount = () => {
    const {
      userId,
    } = this.props;

    const {
      farm,
      contact,
      plan,
      invoicing,
    } = this.state;

    this.setState({
      showSubmitLoader: true,
      hasJustRegistered: true,
    });

    Promise.all(this.saveToFirestore())
      .then((res) => {
        sendSignUpEmailToSupport({ profile: { farm, contact, plan, invoicing } }, userId);

        this.setState({
          showSubmitLoader: false,
          showDoneModal: true,
        });

      })
      .catch((error) => {
        console.log("Error during registration of new user", error.toString());

        this.setState({
          showErrorModal: true,
          firebaseErrorMessage: error.toString(),
        });
      });
  };

  saveToFirestore = () => {
    const {
      contact,
      farm,
      invoicing,
      plan,
    } = this.state;

    let payload = {
      profile: {
        contact: {
          first_name: contact.firstName,
          last_name: contact.lastName,
          address1: contact.address1,
          address2: contact.address2,
          zip_code: contact.zipCode,
          town: contact.town,
          region: contact.region,
          phone: contact.phone,
          email: contact.email,
        },
        invoicing: {
          company_id: invoicing.companyId,
          address1: invoicing.address1,
          address2: invoicing.address2,
          zip_code: invoicing.zipCode,
          town: invoicing.town,
          email: invoicing.email,
          method: invoicing.method,
        },
        legal: {
          date_accepted_toc: new Date(),
        },
        log: {
          date_account_created: new Date(),
          has_created_user: true,
          has_created_farm: false,
        },
        plan: {
          plan_first_date: new Date(),
          plan_type: plan.type,
          plan_name: plan.typeName,
          plan_valid_to: plan.validTo,
        },
        farm: {
          name: farm.name,
          location: farm.location,
          statistics_region: farm.region,
        },
      },
    };

    let allPromises = []

    payload && Object.keys(payload).forEach((colKey) => {
      payload[colKey] && Object.keys(payload[colKey]).forEach((docKey) => {
        allPromises.push(
          this.getUser().collection(colKey).doc(docKey).set(payload[colKey][docKey], { merge: true })
        )
      });
    });

    return allPromises;
  };

  render() {
    const {
      match: {
        params: {
          layer,
        }
      },
      userId,
    } = this.props;

    const {
      showSubmitLoader,
      showDoneModal,
      showErrorModal,
      collectDataErrorMessage,
      firebaseErrorMessage,
      hasJustRegistered,
    } = this.state;

    const db = this.getDb();

    let fieldId = null;

    let menuItems = [
      {
        name: "user",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Skapa användare",
        description: "",
        active: false,
      },
      {
        name: "farm",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Gårdsinformation",
        description: "",
        active: !layer || layer === "farm",
      },
      {
        name: "contact",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Kontaktuppgifter",
        description: "",
        active: layer === "contact",
      },
      {
        name: "plan",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Välj funktionspaket",
        description: "",
        active: layer === "plan",
      },
      {
        name: "summary",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Sammanställning",
        description: "",
        active: layer === "summary",
      },
    ];

    let commonProps = {
      slideDirection: this.state.slideDirection,
      params: this.props.match.params,
      firestore: this.props.firestore,
      db,
      getUser: this.getUser,
      userId,
      showFullGrid: false,
      setParentState: this.setParentState,
      currentMenuItem: menuItems.find((x) => x.active),
    };

    if (!hasJustRegistered && db && db.profile && db.profile.log && db.profile.log.date_account_created) {
      return <AlreadyCreatedUser />
    }

    return (
      <React.Fragment>
        <MenuGuide
          db={db}
          fieldId={fieldId}
          menuItems={menuItems}
        />

        {showSubmitLoader && this.showSubmitLoader()}
        {this.getDoneModal(showDoneModal)}
        {this.getErrorModal(showErrorModal, firebaseErrorMessage)}

        {(!layer || layer === "farm") &&
          <FarmInformation
            {...this.state}
            {...commonProps}
            collectDataError={collectDataErrorMessage}
            onUploadFiles={this.onUploadFiles}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "contact", false)}

          />
        }

        {layer === "contact" &&
          <ContactInformation
            {...this.state}
            {...commonProps}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "farm", false)}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "plan", false)}

          />
        }

        {layer === "plan" &&
          <SelectPlan
            {...this.state}
            {...commonProps}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "contact", false)}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "summary", false)}

          />
        }

        {layer === "summary" &&
          <Summary
            {...this.state}
            {...commonProps}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "contact", false)}
            onClickNextStep={() => this.onCreateUserAccount()}
          />
        }
      </React.Fragment>
    )
  }
}

const withFirebaseData = connect(store => {
  return {
    firebaseData: store.firebase
  };
});

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'profile'
    },
  ]),
  withFirebaseData,
  withFirestoreData,
  withRouter
)(RegistrationV1Part2);