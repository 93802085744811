import React, { Component } from "react";

import { 
  Map as LeafletMap, 
  TileLayer, 
  Circle, 
  Polygon 
} from "react-leaflet";

import { 
  Segment, 
  Header,
  Button,
  Icon,
  Dropdown,
  Image,
  Modal,
  Loader,
} from "semantic-ui-react";

import { CROP_CODES } from "@/constants/cropCodes";
import { AREAL_TILE_URL } from "@/constants/map";

import { geoPointToPosition } from "@/helpers/db";
import { getFieldMapOptions } from "@/helpers/maps";

import IconWithPlate from "@/components/misc/IconWithPlate";
import imgYieldMap from "@/assets/icons/dataTypes/yield-data.svg";
import illustrationError from "@/assets/illustrations/error_simple.png";
import illustrationQuestion from "@/assets/illustrations/question_simple.png";

class UploadYieldMapsMapPreviewPerFile extends Component {
  state = {
    newFileFields: [],
    newFileYear: null,
    newFileCrop: null,
    center: [58.41086, 15.62157],
    error: false,
    processing: false,
    showDetailsModal: false,
    fileNeedsMoreInfo: false,
  };

  onChangeField = (event) => {
    let name = (event.target && event.target.name) ? event.target.name : event.name;
    let value = (event.target && event.target.value) ? event.target.value : event.value;
    this.setState({ [name]: value });

    if (['newFileFields', 'newFileYear'].includes(name)) {
      this.getCropCode({[name]: value});
    };
  };

  onChangeFieldOnlyNumbers = (event) => {
    let name = (event.target && event.target.name) ? event.target.name : event.name;
    let value = (event.target && event.target.value) ? event.target.value : event.value;
    value = value && value.replace(/[^\d]/g, '');

    this.setState({ [name]: value });  
  };

  toggleShowDetailsModal = () => {
    let currentValue = this.state.showDetailsModal;
    this.setState({ showDetailsModal: !currentValue });
  };

  getCropCode = (newValue) => {
    const {
      fieldsInfo,
    } = this.props;

    let newFileFields = newValue.newFileFields ? newValue.newFileFields : this.state.newFileFields;
    let newFileYear = newValue.newFileYear ? newValue.newFileYear : this.state.newFileYear;

    let fieldId = newFileFields && newFileFields[0];
    let fieldInfo = fieldId && fieldsInfo && fieldsInfo[fieldId];
    let cropCodes = fieldInfo && fieldInfo.crop_code;
    let cropCode = cropCodes && newFileYear && cropCodes[newFileYear];

    cropCode && this.setState({newFileCrop: cropCode});
  }

  saveNewFieldInfo = () => {
    const { 
      fileId,
    } = this.props;

    const { 
      newFileFields,
      newFileYear,
      newFileCrop,
    } = this.state;

    this.props.onProcessFileAgain(fileId, newFileFields, newFileYear, newFileCrop);

    this.setState({
      newFileFields: [],
      newFileYear: null,
      fileNeedsMoreInfo: false,
    });    

    this.toggleShowDetailsModal();
  };

  onEditFile = () => {
    const { 
      uploadedFileData,
    } = this.props;

    let fieldsInFile = uploadedFileData && uploadedFileData.fields_in_file;
    fieldsInFile = typeof(fieldsInFile) === "string" ? [fieldsInFile] : fieldsInFile;
    let fileYear = uploadedFileData && uploadedFileData.year;

    this.setState({
      newFileFields: fieldsInFile ? fieldsInFile : [],
      newFileYear: fileYear ? fileYear : null,
      fileNeedsMoreInfo: true,
    });

    this.toggleShowDetailsModal();
  };

  getDetailsModal = (fields, markers, mapOptions, fieldsInFile, fieldNamesInFile, fileName, fileYear, fileCrop) => {
    const {
      fileNeedsMoreInfo,
      newFileFields,
      newFileYear,
      newFileCrop,
    } = this.state;

    let availableYearList = Array.apply(null, {length: 31}).map(Number.call, Number);
    availableYearList = availableYearList && availableYearList.map((x) => (1990 + x).toFixed(0));
  
    availableYearList = availableYearList && availableYearList.reverse().map((x) => ({
      key: x,
      value: x,
      text: x,
    }));
  
    let availableFields = fields && Object.keys(fields).map((x) => ({
      key: x,
      value: x,
      text: fields[x].name,
    }));

    let fileCropString = typeof(fileCrop) === "string" ? fileCrop : (typeof(fileCrop) === "number" ? fileCrop.toFixed(0) : fileCrop);
    let cropName = fileCropString && CROP_CODES && 
      CROP_CODES.find((x) => x.key === fileCropString) && 
      CROP_CODES.find((x) => x.key === fileCropString).shorttext;
  
    return (
      <Modal open={true}>
        <Modal.Content>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em"
            }}
          >

            <div>
              {!markers &&
                <Header 
                  as="h4"
                  style={{
                    textAlign: "center",
                    fontWeight: "normal",
                    marginTop: "2em",
                  }}
                >
                  <Icon name="spinner" loading />
                  Laddar skördekartor...
                </Header>
              }

              {markers &&
                <LeafletMap
                  {...mapOptions}
                  zoom={15}
                  maxZoom={20}
                  zoomSnap={0.1}
                >
                  {fields && fieldsInFile &&
                    Object.keys(fields).map(key => (
                      <Polygon
                        key={key}
                        positions={geoPointToPosition(fields[key].position)}
                        color={
                          fieldsInFile.includes(key)
                            ? "rgba(251, 189, 8, 0.9)"
                            : "lightgrey"
                        }
                        fillOpacity={0.2}
                        weight={3}
                      />
                    ))}

                  <TileLayer url={AREAL_TILE_URL} />
                  
                  {markers && markers.map((marker, idx) => (
                    <Circle
                      key={idx}
                      center={{
                        lat: marker.latitude,
                        lng: marker.longitude
                      }}
                      color={"rgba(251, 189, 8, 1.0)"}
                      interactive={false}
                      radius={14}
                      fill={true}
                      fillOpacity={0.75}
                    />
                  ))}
                </LeafletMap>
              }
          </div>
        
          <div 
            style={{
              marginLeft: "4em",
            }}
          >
            <Header 
              as="h3"
              style={{
                fontWeight: 500,
              }}              
            >
              Information om skördekarta
            </Header>
            
            <div style={{ opacity: 0.7, whiteSpace: "normal", wordBreak: "break-all" }}>
              <strong>Filnamn</strong>: {fileName && fileName}
            </div>                

            {!fileNeedsMoreInfo && 
              <div style={{ marginTop: "1em" }}>
                {fileYear &&
                  <div style={{ opacity: 0.7 }}>
                    <strong>År</strong>: {fileYear}
                  </div>
                }
                {cropName &&
                  <div style={{ opacity: 0.7 }}>
                    <strong>Gröda</strong>: {cropName}}
                  </div>
                }
                {fieldNamesInFile &&
                  <div style={{ opacity: 0.7 }}>
                    <strong>Skiften</strong>: {fieldNamesInFile}
                  </div>   
                }
              </div>
            }

            {fileNeedsMoreInfo && 
              <React.Fragment>
                <Header 
                  as="h3" 
                  style={{
                    marginBottom: 15,
                    fontWeight: 500,
                  }}
                >
                  Freja behöver hjälp med följande information
                </Header>
                      
                <Header 
                  as="h5" 
                  style={{
                    marginTop: 0,
                    marginBottom: 5,
                    fontWeight: 500,
                  }}
                >
                  År
                </Header>
          
                <Dropdown
                  fluid
                  search
                  selection
                  name="newFileYear"
                  options={availableYearList}
                  closeOnChange={true}
                  value={newFileYear}
                  onChange={(event, data) => this.onChangeField(data)}
                  error={newFileYear === ''}
                />   

                <Header 
                  as="h5" 
                  style={{
                    marginBottom: 5,
                    fontWeight: 500,
                  }}
                >
                  Gröda
                </Header>  
                                
                <Dropdown
                  fluid
                  search
                  selection
                  name="newFileCrop"
                  options={CROP_CODES}
                  closeOnChange={true}
                  value={newFileCrop}
                  error={newFileCrop && newFileCrop.length === 0}
                  onChange={(event, data) => this.onChangeField(data)}
                />

                <Header 
                  as="h5" 
                  style={{
                    marginBottom: 5,
                    fontWeight: 500,
                  }}
                >
                  Skiften
                </Header>  
                                
                <Dropdown
                  fluid
                  search
                  selection
                  multiple
                  name="newFileFields"
                  options={availableFields}
                  closeOnChange={true}
                  value={newFileFields}
                  error={newFileFields && newFileFields.length === 0}
                  onChange={(event, data) => this.onChangeField(data)}
                />
              </React.Fragment>
            }
          </div>
          </div>
        </Modal.Content>

        <Modal.Actions
          style={{ 
            display: "flex",
            justifyContent: "space-between"
          }}                 
        >
          {fileNeedsMoreInfo &&
            <Button  
              style={{ 
                backgroundColor: "#868D8E",
                color: "white",
              }}
              onClick={() => this.toggleShowDetailsModal()}
            >
              Stäng
            </Button>    
          } 

          {!fileNeedsMoreInfo && <div></div>}

          <Button  
            style={{ 
              backgroundColor: "#6B9D9F",
              color: "white",
            }}
            onClick={fileNeedsMoreInfo ? () => this.saveNewFieldInfo() : () => this.toggleShowDetailsModal()}
          >
            {fileNeedsMoreInfo ? "Spara" : "Stäng"}
          </Button>            
        </Modal.Actions>
      </Modal>
    );
  };

  getProcessingSegment = (key, fileName, fileYear, fileCrop, fieldNamesInFile, latestProcessingStep) => {
    let fileCropString = typeof(fileCrop) === "string" ? fileCrop : (typeof(fileCrop) === "number" ? fileCrop.toFixed(0) : fileCrop);
    let cropName = fileCropString && CROP_CODES && 
      CROP_CODES.find((x) => x.key === fileCropString) && 
      CROP_CODES.find((x) => x.key === fileCropString).shorttext;

    let description = '';

    if (latestProcessingStep && latestProcessingStep === "preprocessing"){
      description = "Filtypen har identifierats och sorterats in i databasen."
    };

    if (latestProcessingStep && latestProcessingStep === "parsing"){
      description = "Filen har lästs in och väntar nu på att delas upp på varje skifte."
    };

    return (
      <Segment
        key={key}
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1em",
          paddingTop: "2em",
          paddingBottom: "2em",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "10em",
            marginTop: "auto",
            marginBottom: "auto",          
            overflowY: "hidden",
          }}
        >
          <Loader style={{ marginLeft: "3em" }} active inline size="large" />
        </div>

        <div
          style={{
            height: "100%",
            width: "calc(100% - 10em)",
            marginLeft: "2em",
            marginRight: "2em",
            marginTop: "auto",
            marginBottom: "auto",              
            whiteSpace: "pre-line",
          }}          
        >
          <Header 
            as="h2" 
            style={{
              marginBottom: 5,
              fontWeight: 500,
            }}
          >
            Skördekarta bearbetas
          </Header>
          
          {description &&
            <div style={{ opacity: 0.7 }}>
              <strong>Status</strong>: {description && description}
            </div>          
          }

          <div style={{ opacity: 0.7, wordBreak: "break-all" }}>
            <strong>Filnamn</strong>: {fileName && fileName}
          </div>               

          <div style={{ marginTop: "1em" }}>
            {fileYear &&
              <div style={{ opacity: 0.7 }}>
                <strong>År</strong>: {fileYear}
              </div>
            }
            {cropName &&
              <div style={{ opacity: 0.7 }}>
                <strong>Gröda</strong>: {cropName}}
              </div>
            }
            {fieldNamesInFile &&
              <div style={{ opacity: 0.7, whiteSpace: "pre-line" }}>
                <strong>Skiften</strong>: {fieldNamesInFile}
              </div>   
            }
          </div>
        </div>

        <div
          style={{
            width: "13em",
          }}
        >  
        </div>          
      </Segment>
    );
  };

  getInQueueSegment = (key, fileName, latestProcessingStep) => {
    let description = 'Filen står i kö för att identifieras och sorteras.';

    if (latestProcessingStep && latestProcessingStep === "preprocessing"){
      description = "Filtypen har identifierats och sorterats in i databasen."
    };

    if (latestProcessingStep && latestProcessingStep === "parsing"){
      description = "Filen har lästs in och väntar nu på att delas upp på varje skifte."
    };

    return (
      <Segment
        key={key}
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1em",
          paddingTop: "2em",
          paddingBottom: "2em",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "10em",
            marginTop: "auto",
            marginBottom: "auto",          
            overflowY: "hidden",
          }}
        >
          <IconWithPlate
            size="6em"
            src={imgYieldMap}
          />
        </div>

        <div
          style={{
            height: "100%",
            width: "calc(100% - 10em)",
            marginLeft: "2em",
            marginRight: "2em",
            marginTop: "auto",
            marginBottom: "auto",              
            whiteSpace: "pre-line",
          }}          
        >
          <Header 
            as="h2" 
            style={{
              marginBottom: 5,
              fontWeight: 500,
            }}
          >
            Skördekarta står i kö
          </Header>
          
          {description &&
            <div style={{ opacity: 0.7 }}>
              <strong>Status</strong>: {description && description}
            </div> 
          }
          
          <div style={{ opacity: 0.7, wordBreak: "break-all" }}>
            <strong>Filnamn</strong>: {fileName && fileName}
          </div>               

          <div style={{ marginTop: "1em" }}>
            <div style={{ opacity: 0.7 }}>
              Filen står i kö för att bearbetas...
            </div>
          </div>
        </div>

        <div
          style={{
            width: "13em",
          }}
        >  
        </div>          
      </Segment>
    );
  };

  getRemovedSegment = (key, fileName) => (
    <Segment
      key={key}
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1em",
      }}
    >
    <div
      style={{
        height: "100%",
        width: "10em",
        marginTop: "auto",
        marginBottom: "auto",          
        overflowY: "hidden",
      }}
    >
      <Image src={illustrationError} />
    </div>
      <div
        style={{
          height: "100%",
          width: "calc(100% - 10em)",
          marginLeft: "2em",
          marginRight: "2em",
          marginTop: "auto",
          marginBottom: "auto",              
          whiteSpace: "pre-line",
        }}          
      >
        <Header 
          as="h2" 
          style={{
            marginBottom: 5,
            fontWeight: 500,
          }}
        >
          Skördekarta borttagen
        </Header>

        <div style={{ opacity: 0.7 }}>
          <strong>Filnamn</strong>: {fileName && fileName}
        </div>
      </div>   
    </Segment>  
  )

  getErrorSegment = (key, fileName, fileId, errorMessage) => (
    <Segment
      key={key}
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1em",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "10em",
          marginTop: "auto",
          marginBottom: "auto",          
          overflowY: "hidden",
        }}
      >
        <Image src={illustrationError} />
      </div>

      <div
        style={{
          height: "100%",
          width: "calc(100% - 10em)",
          marginLeft: "2em",
          marginRight: "2em",
          marginTop: "auto",
          marginBottom: "auto",              
          whiteSpace: "pre-line",
        }}          
      >
        <Header 
          as="h2" 
          style={{
            marginBottom: 5,
            fontWeight: 500,
          }}
        >
          Skördekarta kunde inte läsas
        </Header>

        <div style={{ opacity: 0.7, wordBreak: "break-all" }}>
          <strong>Filnamn</strong>: {fileName && fileName}
        </div>
        <div style={{ opacity: 0.7, whiteSpace: "pre-line" }}>
          <strong>Felmeddelande</strong>: {errorMessage && errorMessage}
        </div>
        <div style={{color: "#C36126" }}>
          Filen är borttagen från Freja. Prova att ladda upp den igen om du tror att något blev fel.
        </div>           
      </div>
      <div
        style={{
          width: "13em",
        }}
      >
      </div>          
    </Segment>
  );

  getMoreInformationSegment = (key, fileName, fileId, statusText) => (
    <Segment
      key={key}
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1em",
        paddingTop: "2em",
        paddingBottom: "2em",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "10em",
          marginTop: "auto",
          marginBottom: "auto",          
          overflowY: "hidden",
        }}
      >
        <Image src={illustrationQuestion} />
      </div>

      <div
        style={{
          height: "100%",
          width: "calc(100% - 10em)",
          marginLeft: "2em",
          marginRight: "2em",
          marginTop: "auto",
          marginBottom: "auto",              
          whiteSpace: "pre-line",
        }}          
      >
        <Header 
          as="h2" 
          style={{
            marginBottom: 5,
            fontWeight: 500,
          }}
        >
          Freja behöver mer information
        </Header>
        
        <div>
          <div style={{ opacity: 0.7, whiteSpace: "pre-line" }}>
            <strong>Saknad information</strong>: {statusText && statusText}
          </div>

          <div style={{ opacity: 0.7, wordBreak: "break-all" }}>
            <strong>Filnamn</strong>: {fileName && fileName}
          </div>

          <div style={{ marginTop: "1em" }}>
            <div style={{ opacity: 0.7 }}>
              Hjälp Freja att fortsätta bearbetningen genom att ange den information som saknas.
              Klicka på knappen "Redigera" för att ange mer information eller knappen "Ta bort" för att ta bort den uppladdade skördekartan.
            </div>
          </div>          
        </div>                  
      </div>

      <div
          style={{
            width: "13em",
          }}
        >
          <Button
            icon
            fluid
            labelPosition="left"
            size="small"
            style={{
              marginBottom: 8,
              backgroundColor: "#6B9D9F",
              color: "white",
            }}
            onClick={() => this.onEditFile()}
          >
            <Icon name="edit" />
            Redigera
          </Button>    

          <Button
            icon
            fluid
            labelPosition="left"
            size="small"
            style={{
              backgroundColor: "#868D8E",
              color: "white",
              marginBottom: 0,
            }}
            onClick={() => this.props.onRemoveUploadedFile(fileId)}
          >
            <Icon name="delete" />
            Ta bort
          </Button>
        </div>        
    </Segment>
  );  

  render() {
    const { 
      key,
      fileId,
      fields,
      uploadedFileData,
      filesToRemove,
    } = this.props;

    const { 
      showDetailsModal,
    } = this.state;

    let segmentData;

    let isProcessing = true;
    isProcessing = (uploadedFileData && uploadedFileData.status && ["need_more_info", "done"].includes(uploadedFileData.status)) ?
      false : isProcessing;

    let fileName = uploadedFileData && uploadedFileData.original_file_name;

    if (filesToRemove && filesToRemove.includes(fileId)) {
      segmentData = this.getRemovedSegment(key, fileName);
    }

    let isError = uploadedFileData && uploadedFileData.status && uploadedFileData.status === "error"; 
    let errorMessage = uploadedFileData && uploadedFileData.error_message && uploadedFileData.error_message; 

    if (isError && !segmentData) {
      return (
        this.getErrorSegment(key, fileName, fileId, errorMessage)
      )
    };  
    
    let fieldsInFile = uploadedFileData && uploadedFileData.fields_in_file;
    let fileYear = uploadedFileData && uploadedFileData.year;
    let fileCrop = uploadedFileData && uploadedFileData.crop;
    let markers = uploadedFileData && uploadedFileData.samples_in_file;
    
    let fileNeedsMoreInfo = uploadedFileData && uploadedFileData.status && uploadedFileData.status === 'need_more_info';

    let fieldNamesInFile = fieldsInFile && fieldsInFile.map((x) => " " + fields[x].name).toString();
    fieldNamesInFile = fieldNamesInFile ? fieldNamesInFile : ""

    let latestProcessingStep = uploadedFileData && uploadedFileData.latest_processing_step && uploadedFileData.latest_processing_step;

    let detectedFieldPositions = [];
    fieldsInFile && fieldsInFile.forEach((fieldId) => {
      detectedFieldPositions = [...detectedFieldPositions, ...fields[fieldId].position]
    });
    
    detectedFieldPositions = detectedFieldPositions && detectedFieldPositions.length > 0 ? 
      detectedFieldPositions : 
      markers && markers.map((x) => ({latitude: x.latitude, longitude: x.longitude}));

    const mapOptions = getFieldMapOptions(
      { 
        position: detectedFieldPositions 
      },
      { 
        height: "30em",
        width: "30em",
        top: null,
      },
      {
        zoom: 8,
        boundsOptions: { padding: [10, 10] },
      }
    );

    if (fileNeedsMoreInfo && !segmentData) {
      let statusText = [];
      statusText = statusText && fieldsInFile && fieldsInFile.length === 0 ? [...statusText, " inga skiften på gården kunde matchas till skördekartan"] : statusText;
      statusText = statusText && !fileYear ? [...statusText, " skördesäsong kunde inte identfieras"] : statusText;
      statusText = statusText && !fileCrop ? [...statusText, " odlad gröda kunde inte identfieras"] : statusText;

      segmentData = this.getMoreInformationSegment(key, fileName, fileId, statusText.join(",") + ".")
    };
    
    if (isProcessing && !segmentData && uploadedFileData.status === "running") {
      segmentData = this.getProcessingSegment(key, fileName, fileYear, fileCrop, fieldNamesInFile, latestProcessingStep);
    };

    if (isProcessing && !segmentData && uploadedFileData.status === "in_queue") {
      segmentData = this.getInQueueSegment(key, fileName, latestProcessingStep);
    };    

    return (
      <React.Fragment>
        {showDetailsModal && this.getDetailsModal(fields, markers, mapOptions, fieldsInFile, fieldNamesInFile, fileName, fileYear, fileCrop)}
        {segmentData && segmentData}
      </React.Fragment>
    );
  } 
}

export default UploadYieldMapsMapPreviewPerFile;

