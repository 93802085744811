import React, { Component } from "react";

import {
  isLoaded,
} from "react-redux-firebase";

import {
  Segment, 
  Grid, 
  Header, 
  Button,
} from "semantic-ui-react";

import ForecastDataHorizontal from '@/components/weather/ForecastDataHorizontal'

const INITIAL_STATE = {};


class WeatherForecastWidgetHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE, page: 0, leftState: "disabled", rightState: "" };
  }

  // Variables configured for two pages of forecasts
  nHorizontal = 4
  maxPage = 1 * this.nHorizontal
  minPage = 0

  goLeft = () => {
    this.setState({ page: Math.max(this.state.page - this.nHorizontal, this.minPage), leftState: "disabled", rightState: "" })
  }

  goRight = () => {
    this.setState({ page: Math.min(this.state.page + this.nHorizontal, this.maxPage), leftState: "", rightState: "disabled" })
  }

  render() {
    const {
      db
    } = this.props;


    let dataComponent = () => {
      let forecastComponents = []
      for (var i = this.state.page; i <= this.state.page + this.nHorizontal - 1; i++) {
        forecastComponents.push(
          <Grid.Row >
            <ForecastDataHorizontal db={db} forecastOffset={i} relativeDate={true} />
          </Grid.Row>
        )
      }
      return (
        <Grid rows={this.nHorizontal} className={"vertically divided inner"} style={{
          fontWeight: 600,
          fontSize: "160%"
        }}>
          {forecastComponents}
        </Grid>)

    }

    return (
      <Segment
        style={{ 
          padding: "1.5em",
          paddingBottom: "3em",
        }}
        loading={!db || !isLoaded(db.fieldsWeather) || !isLoaded(db.fieldsWeather)}
      >
        <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Header
            as="h3"
            style={{ 
              paddingTop: "0",
              fontWeight: 500,
              color: "grey",
            }}
          >
            Prognos
          </Header>
                            
          <div />
        </div>

        {dataComponent()}

        <Button.Group>
          <Button className={this.state.leftState} style={{ backgroundColor: "white", position: "absolute", right: "50%" }} icon='angle left' onClick={this.goLeft} />
          <Button className={this.state.rightState} style={{ backgroundColor: "white", position: "absolute", left: "50%" }} icon='angle right' onClick={this.goRight} />
        </Button.Group>        
      </Segment>
    )
  }
}

export default WeatherForecastWidgetHorizontal;
