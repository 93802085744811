import React, { Component } from "react";

import {
  Popup,
  Segment,
  Icon,
  Dropdown,
} from "semantic-ui-react";

import { 
  extractYears,
  fetchWeatherData, 
  extractDatesAndData, 
  extractDatesAndDataComparison, 
} from "@/helpers/weather";

import { getCurrentYear } from "@/helpers/dates";

const INITIAL_STATE = {
  temperatureData: null,
  rainfallData: null,
  irradiationData: null,
  comparisonYear: parseInt(getCurrentYear(true) - 1).toFixed(0),
};

class WeatherSeasonSummaryWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    const {
      weatherData,
      fileBucketRef,
    } = this.props;

    fetchWeatherData(weatherData, fileBucketRef, this.setParentState);
  };

  componentDidUpdate(prevProps) {
    const {
      weatherData,
      fileBucketRef,
    } = this.props;

    if (!this.state.temperatureData && weatherData !== prevProps.weatherData) {
      fetchWeatherData(weatherData, fileBucketRef, this.setParentState);
    }
  };

  setParentState = (newState) => this.setState(newState);

  render () {
    const {
      comparisonYear,
      temperatureData,
      rainfallData,
      irradiationData,
    } = this.state;

    let currentYear = getCurrentYear(true);
    let dataYears = temperatureData && extractYears(temperatureData);

    let menuItems = dataYears && dataYears.filter((x) => x !== getCurrentYear(true)).map((x) => (
      {
        key: x,
        value: x,
        text: parseInt(x),
        onClick: () => this.setState({ comparisonYear: x}),
      }
    ));

    let accTemperatureCurrentYear = currentYear && temperatureData && extractDatesAndData(temperatureData, currentYear);
    let accRainfallCurrentYear = currentYear && rainfallData && extractDatesAndData(rainfallData, currentYear);
    let accIrradiationCurrentYear = currentYear && irradiationData && extractDatesAndData(irradiationData, currentYear) / 1000.0;

    let accTemperatureLastYear = comparisonYear && temperatureData && extractDatesAndDataComparison(temperatureData, comparisonYear);
    let accRainfallLastYear = comparisonYear && rainfallData && extractDatesAndDataComparison(rainfallData, comparisonYear);
    let accIrradiationLastYear = comparisonYear && irradiationData && extractDatesAndDataComparison(irradiationData, comparisonYear) / 1000.0;

    let comparisonYearText = comparisonYear ? comparisonYear : '';

    let percentAccTemperature = accTemperatureCurrentYear && accTemperatureLastYear &&
      100.0 * (accTemperatureCurrentYear - accTemperatureLastYear) / accTemperatureLastYear;

    let percentAccRainfall = accRainfallCurrentYear && accRainfallLastYear &&
      100.0 * (accRainfallCurrentYear - accRainfallLastYear) / accRainfallLastYear;

    let percentAccIrradiation = accIrradiationCurrentYear && accIrradiationLastYear &&
      100.0 * (accIrradiationCurrentYear - accIrradiationLastYear) / accIrradiationLastYear;
  
    let temperaturePopupContent = "Värdet är " + (percentAccTemperature ? percentAccTemperature.toFixed(0).replace("-", "") : '-') + "% " + (percentAccTemperature && percentAccTemperature > 0.0 ? "mer" : "mindre") + " än samma period under " + comparisonYearText + " Beräkningen är gjord baserad på data från SMHI under perioden april till juni."

    let rainfallPopupContent = "Värdet är " + (percentAccRainfall ? percentAccRainfall.toFixed(0).replace("-", "") : '-') + "% " + (percentAccRainfall && percentAccRainfall > 0.0 ? "mer" : "mindre") + " än samma period under " + comparisonYearText + " Beräkningen är gjord baserad på data från SMHI under perioden april till juni."

    let irradiationPopupContent = "Värdet är " + (percentAccIrradiation ? percentAccIrradiation.toFixed(0).replace("-", "") : '-') + "% " + (percentAccIrradiation && percentAccIrradiation > 0.0 ? "mer" : "mindre") + " än samma period under " + comparisonYearText + " Beräkningen är gjord baserad på data från SMHI under perioden april till juni."

    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div 
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "1em",
            }}
          >
            <Popup
              trigger={
                <Dropdown
                  style={{
                    border: "1px solid white",
                    borderRadius: 5,
                    padding: 5,
                    paddingLeft: 12,
                  }}
                  className="textLink"
                  options={menuItems}
                  value={comparisonYear}
                />
              }
              content="Välj jämförelseår..."
            />
          </div> 

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",          
            }}
          >           
            <Segment
              basic
              style={{
                margin: 0,
                padding: 0,
              }}
              loading={!comparisonYear || typeof(accTemperatureCurrentYear) !== "number"}
            >
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "center",
                  fontSize: "130%",
                  width: "100%",
                  marginBottom: "1em",
                }}
              >
                Dygnsgrader
              </p>

              <p
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                  fontSize: "360%",
                  width: "100%",
                  color: "#D6946B",
                }}
              >
                {accTemperatureCurrentYear ? Math.floor(accTemperatureCurrentYear).toLocaleString().replace(/,/g, " ",) + " " : '-'}
                <span style={{ fontSize: "35%" }}>&deg;C</span>
              </p>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "250%",
                  width: "100%",
                  color: "#D6946B",
                  paddingTop: 0,
                  marginTop: 0,
                }}
              >
                {typeof(percentAccTemperature) === "number" && percentAccTemperature !== 0 &&
                  <Popup
                    trigger={
                      <Icon 
                        name={percentAccTemperature > 0 ? "arrow alternate circle up outline" : "arrow alternate circle down outline"} 
                      />
                    }
                    content={temperaturePopupContent}
                  />
                }
                <span style={{ fontSize: "75%" }}>
                  {percentAccTemperature ? " " + percentAccTemperature.toFixed(0) + "%" : ''}
                </span>
              </p>            
            </Segment>

            <Segment
              basic
              style={{
                margin: 0,
                padding: 0,
              }}        
              loading={!comparisonYear || typeof(percentAccRainfall) !== "number"}
            >
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "center",
                  fontSize: "130%",
                  width: "100%",
                  marginBottom: "1em",
                }}
              >
                Nederbörd
              </p>

              <p
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                  fontSize: "360%",
                  width: "100%",
                  color: "#9CB5B6",
                }}
              >
                {accRainfallCurrentYear ? Math.floor(accRainfallCurrentYear).toLocaleString().replace(/,/g, " ",) : '-'}
                <span style={{ fontSize: "35%" }}> mm</span>
              </p>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "250%",
                  width: "100%",
                  color: "#9CB5B6",
                  paddingTop: 0,
                  marginTop: 0,
                }}
              >
                {typeof(percentAccRainfall) === "number" && percentAccRainfall !== 0 &&
                  <Popup
                    trigger={
                      <Icon 
                        name={percentAccRainfall > 0 ? "arrow alternate circle up outline" : "arrow alternate circle down outline"} 
                      />
                    }
                    content={rainfallPopupContent}
                  />
                }

                <span style={{ fontSize: "75%" }}>
                  {percentAccRainfall ? " " + percentAccRainfall.toFixed(0) + "%" : ''}
                </span>
              </p>            
            </Segment>

            <Segment
              basic
              style={{
                margin: 0,
                padding: 0,
              }}        
              loading={!comparisonYear || typeof(accIrradiationCurrentYear) !== "number"}
            >
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "center",
                  fontSize: "130%",
                  width: "100%",
                  marginBottom: "1em",
                }}
              >
                Solinstrålning
              </p>

              <p
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                  fontSize: "360%",
                  width: "100%",
                  color: "#e0ba8c",
                }}
              >
                {accIrradiationCurrentYear ? Math.floor(accIrradiationCurrentYear).toLocaleString().replace(/,/g, " ",) : '-'}
                <span style={{ fontSize: "35%" }}> kW/m2</span>
              </p>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "250%",
                  width: "100%",
                  color: "#e0ba8c",
                  paddingTop: 0,
                  marginTop: 0,
                }}
              >
                {typeof(percentAccIrradiation) === "number" && percentAccIrradiation !== 0 &&
                  <Popup
                    trigger={
                      <Icon 
                        name={percentAccIrradiation > 0 ? "arrow alternate circle up outline" : "arrow alternate circle down outline"} 
                      />
                    }
                    content={irradiationPopupContent}
                  />
                }
                <span style={{ fontSize: "75%" }}>
                  {percentAccIrradiation ? " " + percentAccIrradiation.toFixed(0) + "%" : ''}
                </span>
              </p>            
            </Segment>        
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default WeatherSeasonSummaryWidget;