import React, { Component } from "react";

import {
  Map as LeafletMap,
  TileLayer,
  Polygon,
  Marker as LeafletMarker,
  LayersControl,
} from "react-leaflet";

import L from 'leaflet';

import overlayUrl from "@/assets/backgrounds/transparent_bg.png";
import HouseIcon from '@/assets/icons/map/icon_house.png';

import { AREAL_TILE_URL, STREET_TILE_URL } from "@/constants/map";

import { geoPointToPosition } from "@/helpers/db";
import { getFieldMapOptions } from "@/helpers/maps";

const { BaseLayer } = LayersControl;

const iconHouse = new L.Icon({
  iconUrl: HouseIcon,
  iconRetinaUrl: HouseIcon,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowAnchor: null,
  iconSize: new L.Point(50, 50),
});

class FieldDataViewerFarm extends Component {
  state = {};

  render() {
    const {
      fields,
      farmPosition,
    } = this.props;

    let allPositions = [];
    fields && Object.keys(fields).forEach((fieldId) => {
      allPositions = [...allPositions, ...fields[fieldId].position];
    });

    let allPositionHolesAvailable = [];
    fields && Object.keys(fields).forEach((fieldId) => {
      if (typeof(fields[fieldId].available_to_user) === "boolean" && fields[fieldId].available_to_user === false) {
        return null
      }

      fields[fieldId].position_holes && Object.keys(fields[fieldId].position_holes).forEach((idx) => {
        allPositionHolesAvailable = [...allPositionHolesAvailable, fields[fieldId].position_holes[idx]];
      })
    });

    let allPositionHolesUnAvailable = [];
    fields && Object.keys(fields).forEach((fieldId) => {
      if (typeof(fields[fieldId].available_to_user) === "boolean" && fields[fieldId].available_to_user === true) {
        return null
      }
      fields[fieldId].position_holes && Object.keys(fields[fieldId].position_holes).forEach((idx) => {
        allPositionHolesUnAvailable = [...allPositionHolesUnAvailable, fields[fieldId].position_holes[idx]];
      })
    });

    let field = {position: allPositions};
    const mapOptions = getFieldMapOptions(field, {}, { touchZoom: true, scrollWheelZoom: true, dragging: true });

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          left: null,
        }}
      >
        <div style={{ flex: 1, position: "relative" }}>
          <LeafletMap 
            {...mapOptions}
          >
            <LayersControl position="topright">
                <BaseLayer name="Kartvy">
                    <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={STREET_TILE_URL} 
                    />
                </BaseLayer>
                <BaseLayer checked name="Flygbild">
                    <TileLayer url={AREAL_TILE_URL} />
                    
                    <TileLayer opacity={0.6} url={overlayUrl} />
                </BaseLayer>
            </LayersControl>

            {fields && Object.keys(fields).map((fieldId) => (
              <Polygon
                key={fieldId}
                positions={geoPointToPosition(fields[fieldId].position)}
                color={(typeof(fields[fieldId].available_to_user) === "boolean" && fields[fieldId].available_to_user === false) ? "rgba(134, 141, 142, 0.9)" : "rgba(251, 189, 8, 0.9)"}
                fillOpacity={0.6}
                weight={3}
              />
            ))}

            {farmPosition && 
              <LeafletMarker
                position={geoPointToPosition(farmPosition)}
                icon={iconHouse}
              />
            }
            
            {allPositionHolesAvailable && allPositionHolesAvailable.map((positionHole, idx) => (
              <Polygon
                key={"fieldhole_" + idx}
                positions={geoPointToPosition(positionHole)}
                color="rgba(251, 189, 8, 0.9)"
                fillOpacity={0}
                weight={3}
              />                   
            ))}

            {allPositionHolesUnAvailable && allPositionHolesUnAvailable.map((positionHole, idx) => (
              <Polygon
                key={"fielDhole_" + idx}
                positions={geoPointToPosition(positionHole)}
                color="rgba(134, 141, 142, 0.9)"
                fillOpacity={0}
                weight={3}
              />                   
            ))}            
          </LeafletMap>
        </div>
      </div>
    );
  }
}

export default FieldDataViewerFarm;
