import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { HELP_PAGE_URL } from "@/constants/settings";

import Iframe from 'react-iframe'

const HELP_VIEWS = [
  'layers',
  'analysis',
  'guides',
  'collect',
  'changelog'
]

const INITIAL_STATE = {};   

class HelpPage extends Component {
  constructor(props) {
  super(props);
    this.state = { ...INITIAL_STATE };
  }


  render() {
    const {
      match: {
        params: {
          view, 
          subView,
        }
      }
    } = this.props;

    let url = HELP_PAGE_URL;
    url = url && (view && HELP_VIEWS.includes(view)) ? (url + view + "/") : url;
    url = url && (view && HELP_VIEWS.includes(view) && subView) ? (url + subView + "/") : url;

    return (
      <div
        style={{
          margin: 0,
          padding: 0,
          height: "100%",
          width: "100%",
        }}
      >
        <Iframe 
          url={url}
          frameBorder={0}
          width="100%"
          height="100%"
          
        />
      </div>
    );
  }
};

export default withRouter(HelpPage);
