import React, { Component } from "react";

import { COLLECT_LAYERS } from "@/constants/viewCollect";

import { 
  Image, 
  Icon, 
  Dropdown, 
  Button, 
  Popup, 
  Segment, 
  Menu,
  Header
} from "semantic-ui-react";

import { isLoaded } from "react-redux-firebase";

const ListItem = ({ 
    id,
    layerInfo, 
    isBetaMode,
    layer, 
    layerId,
    fieldId,
    selectedTab, 
    onSelectTab, 
    onChangeLayer, 
    onChangeDateSelect,
    onClickNextDate,
    onClickPreviousDate,
    dates,
  }) => {

    let datesSelect = dates && dates
    .sort(function(a, b) {
      a = new Date(a);
      b = new Date(b);
      return a > b ? -1 : a < b ? 1 : 0;
    })
    .map(date => ({
      key: date,
      text: date,
      value: date
    }));

    let tabOpen = layerInfo.aliases.includes(selectedTab) && layerInfo.captions;
    let fieldSpecific = typeof(layerInfo.fieldSpecific) === "boolean" ? layerInfo.fieldSpecific : false;

    if (fieldSpecific && fieldId === "farm") {
      return <div />
    };

    return (
      <Segment 
        key={id}
        className={layerInfo.aliases.includes(selectedTab) ? null : "fieldLayerCardOnHover"}
        style={{
          background: layerInfo.aliases.includes(selectedTab) ? "#e0ba8c" : "white",
          boxShadow: "0 1px 2px lightgrey",
          marginTop: 0,
          marginBottom: 8
        }}
        onClick={() => layerInfo.aliases.includes(selectedTab) ? null : onSelectTab(layerInfo.name) }        
      >
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between"
          }}          
        >
          <div 
            style={{ 
              margin: 0,
              color: "black",
              display: "flex",
            }}
          >
            <div 
              style={{
                backgroundColor: "#F8EED0",
                borderRadius: 5,
                width: "2.4em",
                height: "2.4em",
                marginRight: "0.4em",
              }}
            >
              <Image 
                style={{ 
                  marginTop: "0.5em",
                  marginLeft: "0.5em",
                  width: "1.4em", 
                  height: "1.4em",
                  filter: "invert(0%)",
                }}
                src={layerInfo.icon} 
              />

              {/* {newDataAvailable && newDataAvailable.length > 0 && getNotificationDot()}  */}
            </div>

            <span style={{ marginTop: "0.5em", fontWeight: 600, marginLeft: "0.45em" }}>{layerInfo.caption}</span>
          </div>

          <div style={{ marginTop: "0.5em" }}>    
            {layerInfo.captions && !layerInfo.aliases.includes(selectedTab) && Object.keys(layerInfo.captions).length > 1 && <Icon style={{ textAlign: "right" }} name={"chevron down"} />}
            {layerInfo.captions && !layerInfo.aliases.includes(selectedTab) && Object.keys(layerInfo.captions).length <= 1 && <Icon style={{ textAlign: "right" }} name={"chevron right"} />}
            {!layerInfo.captions && <Icon style={{ textAlign: "right" }} name="chevron right" />}
          </div>          
        </div>

        {tabOpen && Object.keys(layerInfo.captions).length > 1 &&
          <Menu
            vertical
            fluid  
            style={{
              marginTop: "2em"
            }}
          >
            {Object.keys(layerInfo.captions).map((x) => {
              if (layerInfo.onlyBeta && layerInfo.onlyBeta.includes(x) && !isBetaMode) {
                return null;
              };
              
              let fieldSpecific = layerInfo.fieldSpecific && layerInfo.fieldSpecific[x] && 
                typeof(layerInfo.fieldSpecific[x]) === "boolean" ? layerInfo.fieldSpecific[x] : false;

              if (fieldSpecific && fieldId === "farm") {
                return <div />;
              }

              return (
                <Menu.Item
                  key={'subItem_' + x}
                  active={layer === x}
                  onClick={() => onChangeLayer(x, layerId) }
                >
                  {layerInfo && layerInfo.captions && layerInfo.captions[x]}

                  <Popup
                    trigger={layerInfo.onlyBeta && layerInfo.onlyBeta.includes(x) && isBetaMode && <Icon name="code branch" color="red" />}
                    content="Detta är en betafunktion som inte ännu är fullt utvecklad. Vänlig använd den med försiktighet."
                  />

                  <Icon 
                    name="chevron right" 
                  />   

                  {layerInfo && layerInfo.helpText && layerInfo.helpText[x] &&
                    <Popup
                      trigger={<Icon name="help circle" color="grey" />}
                      content={layerInfo.helpText[x]}
                    />
                  }
                 
                </Menu.Item>
              )
            })}
          </Menu>
        }

        {tabOpen && dates &&
          <Segment
            secondary
            style={{
              padding: "1em",
            }}
          >
            {tabOpen && dates && 
              <Header 
                as="h5"
                style={{ 
                  fontWeight: 600,
                  marginTop: 0,
                  marginBottom: 4,
                }}
              >
                Välj år
              </Header>
            }

            {tabOpen && dates &&
              <Dropdown
                style={{ 
                  marginBottom: "1em",
                  border: "1px solid grey" 
                }}          
                fluid
                selection
                placeholder="Välj ett år..."
                onChange={onChangeDateSelect}
                value={datesSelect.find((x) => x.key === layerId) ? layerId : null}
                options={datesSelect}
              />
            }
          </Segment>
        }

        {tabOpen && dates &&
          <div 
            style={{ 
              width: "100%",
              paddingTop: 0,
              display: "flex",
              justifyContent: "space-between",
          }}>
            <Button
              icon
              labelPosition="left"
              size="tiny"
              style={{
                marginRight: 10,
                color: "black",
                backgroundColor: "white",
                opacity: 0.9,
                border: "1px solid white"
              }}
              onClick={() => onClickPreviousDate(layer, layerId, dates)}
            >
              <Icon name="chevron left" />
              Föregående år
            </Button>

            <Button
              icon
              labelPosition="right"
              size="tiny"
              style={{
                marginLeft: 10,
                color: "black",
                backgroundColor: "white",
                opacity: 0.9,
                border: "1px solid white"
              }}
              onClick={() => onClickNextDate(layer, layerId, dates)}
            >
              <Icon name="chevron right" />
              Nästa år
            </Button>
          </div>
        }
      </Segment>
  )
}

class GuidesTypesList extends Component {
  state = {
    selectedTab: null,
  };  

  componentDidMount = () => {
    const {
      layer,
    } = this.props;
    
    if (layer && layer.split("_")[0]) {
      this.setState({
        selectedTab: layer.split("_")[0],
      });
    };
  };

  componentDidUpdate = (prevProps) => {
    const {
      layer,
    } = this.props;

    if ((prevProps.layer !== this.props.layer) || !this.state.selectedTab) {
      if (layer && layer.split("_")[0]) {
        this.setState({
          selectedTab: layer.split("_")[0],
        });
      };
    };
  };

  onChangeLayer = (type, idx) => {
    this.props.openLayer(type, null);
  };

  onSelectTab = (tabName) => {
    this.onChangeLayer(tabName);
  };

  onChangeDateSelect = (e, data) => {
    const { layer } = this.props;
    this.onChangeLayer(layer, data.value);
  };

  onClickPreviousDate = (layer, layerId, dates) => {
    let sortedDates = null 

    if (dates[0].includes("-")) {
      sortedDates = dates.reverse()
    } else {
      sortedDates = dates && dates.map((x) => parseInt(x)).sort();
      sortedDates = sortedDates && sortedDates.map((x) => (typeof(x) === "number" ? x.toFixed(0) : x))
    } 

    let currentIndex = sortedDates && sortedDates.indexOf(layerId);

    if (typeof(currentIndex) === "number" && (currentIndex > 0)) {
      let newDate = sortedDates[currentIndex - 1];
      this.onChangeLayer(layer, newDate);
    };
  };

  onClickNextDate = (layer, layerId, dates) => {
    let sortedDates = null 

    if (dates[0].includes("-")) {
      sortedDates = dates.reverse()
    } else {
      sortedDates = dates && dates.map((x) => parseInt(x)).sort();
      sortedDates = sortedDates && sortedDates.map((x) => (typeof(x) === "number" ? x.toFixed(0) : x))
    } 
    
    let currentIndex = sortedDates && sortedDates.indexOf(layerId);

    if (typeof(currentIndex) === "number" && currentIndex < (sortedDates.length - 1)) {
      let newDate = sortedDates[currentIndex + 1];
      this.onChangeLayer(layer, newDate);
    };    
  };
  
  render() {
    const {
      db,
      layer,
      layerId,
      fieldId,
      dataType,
    } = this.props;

    const {
      selectedTab,
    } = this.state;

    let dataLayers = COLLECT_LAYERS;
    
    let isBetaMode = db && 
      db.settings && 
      db.settings.view && 
      db.settings.view.beta_mode && 
      db.settings.view.beta_mode;

    let dates = null;

    if (!db || !isLoaded(db)) {
      return <div></div>
    }

    return (
      <React.Fragment>
        {dataLayers && Object.keys(dataLayers).map((x) => (
          <ListItem 
            key={'listItem_' + dataLayers[x].name}
            isBetaMode={isBetaMode}
            fieldId={fieldId}
            layer={layer}
            layerId={layerId}
            layerInfo={dataLayers[x]}
            selectedTab={selectedTab}
            onSelectTab={this.onSelectTab}
            onChangeLayer={this.onChangeLayer}
            onChangeDateSelect={this.onChangeDateSelect}
            onClickPreviousDate={this.onClickPreviousDate}
            onClickNextDate={this.onClickNextDate}
            dates={dates}
            dataType={dataType}
          />
        ))}
      </React.Fragment>
    );
  }
}

export default GuidesTypesList;
