import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Modal,
  Button,
  Input,
  Segment,
  Icon,
  Image,
  Header,
  Label,
  Dropdown,
  Form,
  TextArea,
  Loader,
} from "semantic-ui-react";

import moment from 'moment';
import 'moment/locale/sv';

import iconInsights from "@/assets/icons/menuSecondary/freja-insights.svg";

import GuideMapPreview from "@/components/guide/GuideMapPreview";

import { collectionToIdArray, positionToGeoPoint, getAvailableFieldIds } from "@/helpers/db";

import { INSIGHT_TAGS } from "@/constants/insights";

const INITIAL_STATE = {
  showAddNewModal: false,
  editingDocId: null,
  caption: '',
  note: '',
  selectedTags: [],
  deletedItems: [],
  insightIdMarkersSelected: '',
  markersSelected: [],
  showAddComment: '',
  fieldFilter: 'all',
  showSummaryModal: false,
  dataSummaryModal: null,
  openingInsight: false,
  noInsightsToShow: 10,
};
class FieldDataInsights extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  showInsightLoader = () => (
    <Modal 
      dimmer='inverted' 
      open={true}
    >
      <Loader 
        size='massive'
      >
        Öppnar insikt...
      </Loader>
    </Modal>
  );

  getEmptySegment = () => {
    const {
      widgetType,
    } = this.props;

    return (
      <Segment 
        basic 
        style={{
          width: "70%",
          margin: "auto",
          marginTop: "50%",
        }}
      >
        <div 
          style={{ 
            fontWeight: 500, 
            textAlign: "center" ,
            color: "grey",
            whiteSpace: "pre-line",
          }}
        >
          {widgetType === "vraEditor" && <p>Du har inte samlat några insikter ännu som har markerade områden på skiftet.</p>}
          {widgetType === "toolbar" && <p>Du har inte samlat några insikter ännu.</p>}
          {!widgetType && <p>Du har inte samlat några insikter ännu för detta skifte.</p>}
          {!widgetType && <p>För att spara din första insikt klickar du på "Lägg till" ovanför.</p>}
          {!widgetType && <p>Om du vill spara insikten tillsammans med ett markerat område, så markerar du först området genom att klicka på en stapel under "Områden" och klickar sedan på "Lägg till".</p>} 
        </div>
      </Segment>
    )
  };

  getInsightSegment = (data, widgetTypeOverride=null) => {
    const {
      db,
      userId,
    } = this.props;

    const {
      showAddComment,
      insightIdMarkersSelected,
      newReplyNote,
    } = this.state;

    let widgetType = widgetTypeOverride ? widgetTypeOverride : this.props.widgetType;

    let header = data && data.caption;
    let note= data && data.note;
    let tags = data.tags ? data.tags : [];
    let markers = data.markers ? data.markers : [];
    let comments = data.comments ? data.comments : [];

    let fieldName = db && data.fieldId && db.fields && db.fields[data.fieldId] && db.fields[data.fieldId].name;

    let dateCreatedString = data && data.date_created && moment(data.date_created.toDate()).locale('sv').fromNow();

    let source = data && data.userId ? data.userId : " dig ";
    source = source === "freja" ? " Freja " : source;
    source = source === userId ? " dig " : source;
    source = source ? source : " din rådgivare ";

    let tagsString = INSIGHT_TAGS && INSIGHT_TAGS.map((x) => tags.includes(x.value) && x.text);
    tagsString = tagsString && tagsString.filter((x) => typeof(x) === "string").join(", ");

    return (
      <React.Fragment
        key={"segment_" + data.id}
      >
        <Segment 
          basic
          className={widgetType === "toolbar" ? "backgroundColorOnHover" : null}
          onClick={widgetType === "toolbar" ? () => this.toggleSummaryModal(data) : () => {}}
          style={{
            margin: widgetType === "toolbar" ? 7 : null,
            cursor: widgetType === "toolbar" ? "pointer" : null,
            marginBottom: ((comments && comments.length > 0) || showAddComment === data.id) ? "0.35em" : "1em",
            padding: 10,
            backgroundColor: (!widgetType || widgetType === "details") ? "white" : "#f6f6f6",
            borderRadius: 5,
          }}
        >
          <div 
            style={{
              display: "flex",
              justifyContent: !widgetType ? "space-between" : "flex-start",
              marginBottom: "1em",
            }}
          >
          
            <div
              style={{
                width: "4em"
              }}
            >
              <div 
                style={{
                  backgroundColor: "#F8EED0",
                  borderRadius: 5,
                  width: "2.4em",
                  height: "2.4em",
                  marginRight: "1em",
                }}
              >
                <div style={{ height: "0.5em" }} />
                
                <Image 
                  style={{ 
                    marginLeft: "0.5em",
                    width: "1.4em", 
                    height: "1.4em",
                  }}
                  src={iconInsights} 
                />
              </div>
            </div>
      
            <div
              style={{
                marginBottom: 0,
                marginLeft: 10,
                width: "100%",
              }}
              onClick={markers && markers.length > 0 ? () => this.onClickShowMarkers(data) : () => {}}
            >
              <div 
                style={{ 
                  fontWeight: 500, 
                  marginBottom: 0,
                  whiteSpace: "pre-line", 
                }}
              >
                {header && header}
              </div>

              <div 
                style={{ 
                  color: "grey", 
                  fontSize: "70%",
                  whiteSpace: "pre-line" ,
                }}
              >
                {fieldName && widgetType === "toolbar" && "Skifte: " + fieldName + ". "}
                Lades till av {source} {dateCreatedString}.
              </div>           
            </div>

            {!widgetType &&
              <div
                style={{
                  width: "1.25em",
                }}
              >
                <Dropdown
                  style={{
                    marginLeft: 5,
                    marginRight: 0,
                  }}
                  icon='ellipsis vertical'
                  floating
                  direction="left" 
                >
                  <Dropdown.Menu>
                    <Dropdown.Item 
                      icon='edit' 
                      text='Redigera'
                      onClick={() => this.editInsight(data)}
                    />  

                    <Dropdown.Item 
                      icon='trash' 
                      text='Ta bort' 
                      onClick={() => this.removeInsight(data.id)}
                    />                    
                  </Dropdown.Menu>
                </Dropdown> 
              </div>
            }  
          </div>

          {widgetType !== "toolbar" && note && 
            <div 
              style={{ 
                color: "grey", 
                fontSize: "90%",
                marginBottom: "1em",
                whiteSpace: "pre-line" ,
              }}>
                {note}
            </div>
          }

          {tagsString && 
            <div 
              style={{ 
                color: "grey", 
                fontSize: "80%",
                whiteSpace: "pre-line" ,
              }}>
                Taggar: {tagsString.toLowerCase()}.
            </div>
          }

          {markers && markers.length > 0 && 
            <div 
              style={{ 
                color: "grey", 
                fontSize: "80%",
                whiteSpace: "pre-line" ,
              }}>
                Denna insikt har {markers.length} markerade punkter.
            </div>
          }

          {widgetType === "toolbar" && comments && comments.length > 0 && 
            <div 
              style={{ 
                fontSize: "80%",
                whiteSpace: "pre-line",
                color: "#6B9D9F",
              }}>
                {comments.length} kommentarer
            </div>
          }

          {widgetType !== "toolbar" &&
            <div  
              style={{
                marginTop: "1em",
                display: "flex",
                justifyContent: "flex-start",
                color: "#6B9D9F",
                fontSize: "80%",            
              }}
            >
              {(!widgetType || widgetType === "details") &&
                <div 
                  className="textLink"
                  style={{ 
                    marginRight: 7,
                  }}
                  onClick={() => this.onAddComment(data)}
                >
                  <Icon name="comment" />
                  Kommentera
                </div>          
              }

              {false && (!widgetType || widgetType === "details") &&
                <div 
                  className="textLink"
                  style={{ 
                    marginLeft: 7,
                    marginRight: 7,
                  }}
                  onClick={() => this.onAddComment(data)}
                >
                  <Icon name="share" />
                  Dela med rådgivare
                </div>
              }

              {widgetType === "details" && data.href &&
                <div
                  className="textLink"
                  style={{ 
                    marginLeft: 7,
                    marginRight: 7,
                  }}
                  onClick={() => this.openInsight(data.href, data.current_step_state_file_path)}
                >
                  <Icon name="expand" />
                  Öppna insikt
                </div>
              } 

              {widgetType !== "details" && markers && markers.length > 0 &&
                <div
                  className="textLink"
                  style={{ 
                    marginLeft: 7,
                    marginRight: 7,
                  }}
                  onClick={() => this.onClickShowMarkers(data)}
                >
                  <Icon name="point" />
                  {insightIdMarkersSelected === data.id ? "Avmarkera punkter" : "Markera punkter"}
                </div>
              } 
            </div>
          }
        </Segment>

        {widgetType !== "toolbar" && comments && comments.length > 0 && comments.map((x, idx) => {
          let dateReplyCreatedString = x && x.date_created && moment(x.date_created.toDate()).locale('sv').fromNow();

          let replySource = x && x.userId ? x.userId : " dig ";
          replySource = replySource === "freja" ? " Freja " : replySource;
          replySource = replySource === userId ? " dig " : replySource;
          replySource = replySource ? replySource : " din rådgivare ";

          return (
            <Segment
              key={"reply_" + data.id + "_" + idx} 
              basic 
              style={{
                marginLeft: "1em",
                marginBottom: ((idx + 1 === comments.length) && showAddComment !== data.id) ? "1em" : "0.35em",
                padding: 10,
                backgroundColor: "white",
                borderRadius: 5,
              }}
            >
              <div 
                style={{ 
                  color: "grey", 
                  fontSize: "90%",
                  marginBottom: "1em",
                  whiteSpace: "pre-line" ,
                }}>
                  {x.note}
              </div>

              <div 
                style={{ 
                  color: "grey", 
                  fontSize: "70%",
                  whiteSpace: "pre-line" ,
                }}
              >
                Lades till av {replySource} {dateReplyCreatedString}
              </div>
            </Segment>
          )
        })}   
        
        {widgetType !== "toolbar" && showAddComment === data.id && 
          <Segment 
            basic 
            style={{
              marginLeft: "1em",
              marginBottom: "1em",
              padding: 10,
              backgroundColor: "white",
              borderRadius: 5,
            }}
          >
            <Form>
              <TextArea 
                autoFocus
                focus
                placeholder="Skriv en kommentar..."
                size="small"
                name="newReplyNote"
                value={newReplyNote}
                onChange={(e) => this.onChange(e)}
              />
            </Form>

            <div
              style={{
                marginTop: "0.5em",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                primary
                size="mini"
                onClick={() => this.onSaveComment(data)}
                disabled={!newReplyNote || newReplyNote.length === 0}
                loading={this.state.isSubmitting}
              >
                Skicka
              </Button>

              <Button
                size="mini"
                onClick={() => this.onAddComment()}
                disabled={this.state.isSubmitting}
              >
                Avbryt
              </Button>
            </div>
          </Segment>
        }
      </React.Fragment>
    )
  };

  toggleSummaryModal = (data) => {
    this.setState({
      showSummaryModal: data ? true : false,
      dataSummaryModal: data ? data : null,
    });

    this.props.setParentState({ insightsSummaryOpen: data ? true : false });
  };

  editInsight = (data) => {
    this.setState({
      showAddNewModal: true,
      caption: data && data.caption ? data.caption : '',
      note: data && data.note ? data.note : '',
      selectedTags: data && data.tags ? data.tags : [],
      editingDocId: data.id,
      markersSelected: data.markers,
      insightIdMarkersSelected: '',
    })
  };

  onAddComment = (data) => {
    this.setState({
      showAddComment: data && data.id ? data.id : '',
      newReplyNote: '',
    });
  };

  removeInsight = (id) => {
    this.props.getUser().collection("insights").doc(id).delete();    
    this.setState({ deletedItems: [...this.state.deletedItems, id ]});
  };

  onClickShowMarkers = (data) => {
    if (this.state.markersSelected === data.markers) {
      this.setState({ 
        markersSelected: [],
        insightIdMarkersSelected: '',
      });

      this.props.selectGroupMarkers && this.props.selectGroupMarkers([]);
    } else {
      
      this.setState({ 
        markersSelected: data.markers,
        insightIdMarkersSelected: data.id,
      });

      this.props.selectGroupMarkers && this.props.selectGroupMarkers(data.markers);
    }
  }

  onChange = (event) => {
    let newValue = event.target.value;
    this.setState({ [event.target.name]: newValue });
  };

  onChangeDropdown = (e, d) => {
    let newValue = d.value;
    this.setState({ [d.name]: newValue });
  };  

  resetAddNewModal = () => {
    this.setState({ 
      caption: '', 
      note: '',
      selectedTags: [],
      editingDocId: null,
      showAddNewModal: false,
      markersSelected: [],
      insightIdMarkersSelected: '',      
    });
  };

  onSaveComment = (data) => {
    const {
      db,
      trueUserId,
    } = this.props;

    const {
      newReplyNote,
    } = this.state;

    this.setState({ isSubmitting: true });
    
    const docRef = this.props.getUser().collection("insights").doc(data.id);
    let currentPost = db && db.insights && db.insights[data.id];

    let newComment = {
      date_created: new Date(),
      userId: trueUserId,
      note: newReplyNote,
    };
    
    let newComments = currentPost.comments && currentPost.comments.length > 0 ? 
      [...currentPost.comments, newComment] :
      [newComment];
    
    docRef.update({
      date_latest_update: new Date(),
      comments: newComments,
    })
      .then((res) => {
        this.setState({
          isSubmitting: false,
          newReplyNote: '',
          showAddComment: null,
        })
      });
  };

  saveInsight = () => {
    const {
      userId,
      trueUserId,
      fileBucketRef,
      dataType,
      match: {
        params: {
          layer,
          layerId,
          fieldId
        },
      },
      selectedGroupMarkers,
      selectedGroupMarkersCoordinates,
      geoPointClass,
      fieldDataState,
    } = this.props;

    this.setState({ isSubmitting: true });

    const docRef = this.props.getUser().collection("insights");

    this.state.editingDocId && this.removeInsight(this.state.editingDocId);

    let href = (dataType && fieldId && layer) && "/" + dataType + "/" + fieldId + "/" + layer + (layerId ? "/" + layerId : "");

    docRef.add({
      href: href ? href : '',
      markers: selectedGroupMarkers,
      markers_coordinates: selectedGroupMarkersCoordinates ? positionToGeoPoint(selectedGroupMarkersCoordinates, geoPointClass) : [],
      date_created: new Date(),
      date_latest_update: new Date(),
      caption: this.state.caption,
      note: this.state.note,
      tags: this.state.selectedTags,
      userId: trueUserId ? trueUserId : '',
      fieldId: fieldId ? fieldId : '',
      layer: layer ? layer : '',
      layerId: layerId ? layerId : '',
    })
      .then((x) => {
        let insightId = x.id;

        const fileName = insightId + "_current_state.json";
        const key = "users/" + userId + "/insights/" + insightId + "/" + fileName;
        const fileRef = fileBucketRef.child(key);

        fileRef.putString(JSON.stringify(fieldDataState))
          .then((x) => {
            docRef.doc(insightId).update({
              current_step_state_file_path: key,
            });
          });
        
        this.setState({ isSubmitting: false });
        this.props.selectGroupMarkers();
      })
      .catch((error) => this.setState({ isSubmitting: false }))

    this.resetAddNewModal();
  };

  openInsight = (href, savedStatePath) => {
    const fileBucketRef = this.props.fileBucketRef;
    this.setState({ openingInsight : true });

    savedStatePath && fileBucketRef && fileBucketRef.child(savedStatePath).getDownloadURL().then((url) => {
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      
      xhr.onload = function(event) {
        // eslint-disable-next-line
        let blob = xhr.response;
      };
      
      xhr.open('GET', url);
      xhr.send();
    
      try {
        window.fetch(url).then(async (res) => {
          this.props.history.push(href, await res.clone().json())
          this.setState({ tmp: await res.clone().json(), openingInsight : false });
        });
      } catch (error) {
        this.setState({ openingInsight : false });
      }
    }).catch(function(error) {
      // Handle any errors
      this.setState({ openingInsight : false });
    });

    !savedStatePath && this.props.history.push(href)
  };  


  onSelectTag = (tag) => {
    let currentValue = this.state.selectedTags;
    currentValue.includes(tag) && this.setState({ selectedTags : currentValue.filter((x) => x !== tag) });
    !currentValue.includes(tag) && this.setState({ selectedTags : [...currentValue, tag] });
  };

  getInsightTag = (key, label, detail='', visible=true) => {
    if(!visible) {
      return ( <div /> )
    } else {
      return (
        <Label
          style={{
            cursor: "pointer",
            backgroundColor: this.state.selectedTags.includes(key) ? "#BF8136" : null,
            color: this.state.selectedTags.includes(key) ? "white" : null,
          }}  
          onClick={() => this.onSelectTag(key)}
        >
          {label}
          <Label.Detail>
            {detail}
          </Label.Detail>
        </Label>
      )
    };
  };

  render() {
    const {
      db,
      userId,
      widgetType,
    } = this.props;

    const { 
      showAddNewModal,
      showSummaryModal,
      dataSummaryModal,
      caption,
      note,
      deletedItems,
      selectedTags,
      editingDocId,
      fieldFilter,
      openingInsight,
      noInsightsToShow,
    } = this.state;

    let fieldId = this.props.fieldId ? this.props.fieldId : this.state.fieldFilter;
    fieldId = fieldId === "all" ? null : fieldId;

    let fieldIds = getAvailableFieldIds(db);

    let menuItems = fieldIds && fieldIds.map((x) => ({ key: x, value: x, text: db.fields[x].name, onClick: () => this.setState({ fieldFilter: x }) }));
    menuItems = menuItems && menuItems.sort(function(a, b) { 
      let sortedArray = [b.text, a.text].sort()

      if (sortedArray[0] === b.text) {
        return 1.0
      } else {
        return -1.0
      }
    });
    
    menuItems = menuItems && [{value: 'all', text: 'Alla skiften', key: 'all', onClick: () => this.setState({ fieldFilter: 'all' })}, ...menuItems];

    let markersSelected = this.state.markersSelected && this.state.markersSelected.length > 0 ? 
      this.state.markersSelected : this.props.selectedGroupMarkers;
    
    let insights = db && db.insights;
    insights = insights && collectionToIdArray(insights);
    insights = insights && insights.filter((x) => !fieldId || (x.fieldId && x.fieldId === fieldId) || (x.fieldIds && x.fieldIds.includes(fieldId)));
    insights = insights && insights.filter((x) => !deletedItems.includes(x.id));
    insights = insights && insights.filter((x) => widgetType !== "vraEditor" || (widgetType === "vraEditor" && x.markers));
    insights = insights && insights.sort((a, b) => b.date_created.toDate() - a.date_created.toDate());

    let noInsights = insights && insights.length;
    insights = insights && widgetType === "toolbar" ? insights.splice(0, noInsightsToShow) : insights;

    let insightSegments = insights && insights.map((x) => this.getInsightSegment(x));
    insightSegments = insightSegments && insightSegments.filter((x) => x !== null);
    insightSegments = insightSegments && insightSegments.length > 0 ? insightSegments : this.getEmptySegment();

    if (!widgetType) {
      return (
        <React.Fragment>
          <Modal
            open={showAddNewModal}
            size="tiny"
          >
            <Modal.Header
              style={{
                fontWeight: 500,
              }}
            >
              {!editingDocId && "Spara insikt"}
              {editingDocId && "Redigera insikt"}
            </Modal.Header>

            <Modal.Content>
              <p>
                Skriv en beskrivning för denna insikt så att du kommer ihåg vad den handlar om.
                Du kan även välja en eller flera taggar nedanför som gör det enklare för Freja att hjälpa dig senare när du jämför kartor, diskuterar med din rådgivare, skapar styrfiler eller planerar för insatser.
              </p>

              <Header
                as="h6"
                style={{
                  marginTop: 10,
                  marginBottom: 5,
                }}
              >
                Namn
              </Header>

              <Input
                style={{
                  width: "100%",
                }}
                autoFocus
                name="caption"
                value={caption}
                placeholder="Skriv ett namn..."
                onChange={(e) => this.onChange(e)}
              />  

              <Header
                as="h6"
                style={{
                  marginTop: 10,
                  marginBottom: 5,
                }}
              >
                Beskrivning
              </Header>            

              <Form>
                <TextArea
                  style={{
                    width: "100%",
                  }}
                  rows={5}
                  name="note"
                  placeholder="Skriv en längre beskrivning här..."
                  onChange={e => this.onChange(e)}
                  value={note}
                />
              </Form>
              
              <Header
                as="h6"
                style={{
                  marginTop: 10,
                  marginBottom: 5,
                }}
              >
                Markerade punkter
              </Header>
              <p>
                Denna insikt har {markersSelected && markersSelected.length > 0 ? markersSelected.length : "inga"} punkter markerade.
              </p>

              <React.Fragment>
                <Header
                  as="h6"
                  style={{
                    marginTop: 10,
                    marginBottom: 5,
                  }}
                >
                  Välj taggar
                </Header>

                <Dropdown
                  selection
                  multiple
                  search
                  fluid
                  options={INSIGHT_TAGS}
                  name="selectedTags"
                  value={selectedTags}
                  placeholder="Välj taggar för insikten..."
                  onChange={(e, d) => this.onChangeDropdown(e, d)}
                />
              </React.Fragment>

            </Modal.Content>
            
            <Modal.Actions
              style={{ 
                display: "flex",
                justifyContent: "space-between"
              }}            
            >
              <Button 
                style={{ 
                  backgroundColor: "#868D8E", 
                  color: "white",
                }}
                onClick={() => this.resetAddNewModal()}
                disabled={this.state.isSubmitting}
              >
                Avbryt
              </Button>

              <Button 
                style={{ 
                  backgroundColor: "#6B9D9F", 
                  color: "white",
                }}
                disabled={!caption}
                onClick={() => this.saveInsight()}
                loading={this.state.isSubmitting}
              >
                Spara
              </Button>              
            </Modal.Actions>    
          </Modal>

          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "1em",
              marginRight: "1em",
            }}
          >
            <p
              style={{
                fontSize: "125%",
              }}
            >
              Insikter
            </p>

            <p
              style={{
                marginTop: "auto",
                marginBottom: "auto",
              }}
              className="textLink"
              onClick={() => this.setState({ showAddNewModal: true })}
            >
              Lägg till
            </p>
          </div>
            
          <Segment.Group 
            style={{
              border: "none",
              boxShadow: "none",
              overflowY: "auto",        
              minHeight: "40vh",
              marginLeft: "1em",
              marginRight: "1em",
            }}
          >
            {insightSegments && insightSegments}
          </Segment.Group>   
        </React.Fragment>
      );
    }

    if (widgetType === "toolbar") {
      return (
        <React.Fragment>
          {openingInsight && this.showInsightLoader()}

          <Modal
            open={showSummaryModal}
            closeOnDimmerClick={false}
          >
            <Modal.Header
              style={{
                fontWeight: 500,
              }}
            >
              Insikt
            </Modal.Header>          

            <Modal.Content
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "55vh",
              }}
            >
              <GuideMapPreview 
                mode="map"
                db={db}
                userId={userId}
                layer="soilMapUploaded_samples"
                fieldId={dataSummaryModal && dataSummaryModal.fieldId}
                markers={dataSummaryModal && dataSummaryModal.markers_coordinates}
                mapStyle={{
                  height: "50vh",
                  width: "25vw",
                }}
                hideFullScreen={true}
                hideInfoWidget={true}
              />

              <Segment.Group 
                style={{
                  border: "none",
                  boxShadow: "none",
                  marginTop: 0,
                  height: "100%",
                  width: "100%",
                  overflowY: "auto",
                  marginLeft: "1em",
                  backgroundColor: "#f6f6f6",
                  padding: "0.5em",
                }}
              >
                {dataSummaryModal && this.getInsightSegment({id: dataSummaryModal.id, ...db && db.insights && db.insights[dataSummaryModal.id]}, "details")}
              </Segment.Group>   
            </Modal.Content>  
            <Modal.Actions
              style={{ 
                display: "flex",
                justifyContent: "flex-end"
              }}            
            >
              <Button 
                primary
                onClick={() => {
                  this.toggleSummaryModal();
                  this.onAddComment();
                }}
              >
                Stäng
              </Button>              
            </Modal.Actions>              
          </Modal>

          <Segment
            style={{
              padding: 0,
              border: "0.5px lightgrey solid",
              borderRadius: 5,
              // backgroundColor: "#f7f4f0"
              width: "25vw",              
            }}
          >
            <div 
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 0,
                padding: "1em",
                paddingBottom: 0,
              }}
            >
            <p
              style={{
                fontSize: "110%",
              }}
            >
              Insikter
            </p>

            <Dropdown
              style={{
                marginLeft: 10,
              }}
              className="textLink"
              scrolling
              direction="left"
              options={menuItems}
              value={fieldFilter}
            />                    
          </div>

          <Segment.Group 
            style={{
              border: "none",
              boxShadow: "none",
              marginTop: 10,
              height: "50vh",
              width: "100%",
              overflowY: "auto",
            }}
          >
            {insightSegments && insightSegments}

            {(noInsights > noInsightsToShow) &&
              <Segment
                basic
                className="hoverOpacity"
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: 0,
                  margin: 0,
                  marginBottom: 15,
                  fontWeight: "normal",
                  fontSize: "85%",
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ noInsightsToShow: noInsightsToShow + 5 })}
              >
                Visa fler insikter
              <Icon name="caret down" />
              </Segment>
            }            
          </Segment.Group>   
          </Segment>
        </React.Fragment>
      );
    }

    if (widgetType === "vraEditor") {
      return (
        <React.Fragment>
          <Segment.Group 
            style={{
              border: "none",
              boxShadow: "none",
              marginTop: 10,
              minHeight: "50vh",
              overflowY: "auto",        
            }}
          >
            {insightSegments && insightSegments}
          </Segment.Group>
        </React.Fragment>
      );
    }

  }
}

export default withRouter(FieldDataInsights);


