import React, { Component } from "react";

import {
  Icon,
  Segment,
  Header,
  Button,
  Image,
  Table,
  Dropdown,
  Form,
  Confirm,
  Input,
  Message,
  Popup,
  Modal,
} from "semantic-ui-react";

import { DateInput } from 'semantic-ui-calendar-react';
import { toast } from "react-toastify";

import moment from 'moment';

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

import GuideMarkVraMapUsed from "@/components/guide/GuideMarkVraMapUsed";

import imgDoneIllustration from "@/assets/illustrations/done.png";

import { VRA_MAP_TYPES, REMINDER_WEATHER_TYPES, REMINDER_WEATHER_TYPES_TIME } from "./constants";

const INITIAL_STATE = {
  selectedVraMapType: '',
  noteField: '',
  showAddInsightSegment: false,
  showAddReminderSegment: false,
  showConfirmDeleteInsight: false,
  showConfirmDeleteReminder: false,
  showMarkVraMapsUsed: false,
  showAddGuideName: false,
  reminderGuideStage: 1,
  reminderReason: '',
  reminderBasedOn: '',
  reminderBasedOnWeatherType: '',
  reminderBasedOnWeatherWhen: '',
  reminderEarliestDate: moment().format("YYYY-MM-DD"),
  reminderLatestDate: moment().endOf("year").format("YYYY-MM-DD"),
  reminderConditionTemperature: '100',
  reminderConditionRainfall: '10',
  reminderSumFirstDay: moment().format("YYYY-MM-DD"),
  tryingToGenerateAgain: [],
  newGuideName: '',
  processing: false,
  processingTryAgain: false,
};
class GuideDownloadFiles extends Component {

  state = { ...INITIAL_STATE };

  onClickTryGenerateAgain = (fieldId) => {
    let suggestedVraMap = this.props["vraMap_" + fieldId] && this.props["vraMap_" + fieldId];
    let approvedVraMap = this.props["modifiedVraMap_" + fieldId] ? this.props["modifiedVraMap_" + fieldId] : suggestedVraMap;

    this.setState({ tryingToGenerateAgain: [...this.state.tryingToGenerateAgain, fieldId] });
    this.props.generateVraMapFile(fieldId, approvedVraMap);
  };

  createVraFilesAgain = async () => {
    const {
      selectedFieldIds,
    } = this.props;

    this.setState({ processingTryAgain: true });

    selectedFieldIds && selectedFieldIds.forEach((fieldId) => this.onClickTryGenerateAgain(fieldId));

    await new Promise(r => setTimeout(r, 40000));
    this.setState({ processingTryAgain: false });
  };

  onChange = (data, onlyNumbers = false) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.setState({
      [data.name]: value,
    });
  };

  onSaveInsight = () => {
    this.props.saveInsightForGuide(this.state.noteField, this.props);

    this.setState({
      showAddInsightSegment: false,
      showConfirmDeleteInsight: false,
    });
  };

  toggleConfirmDeleteInsight = () => {
    let currentValue = this.state.showConfirmDeleteInsight;
    this.setState({ showConfirmDeleteInsight: !currentValue });
  };

  toggleConfirmDeleteReminder = () => {
    let currentValue = this.state.showConfirmDeleteReminder;
    this.setState({ showConfirmDeleteReminder: !currentValue });
  };

  downloadFile = (filePath) => {
    const {
      fileBucketRef,
    } = this.props;

    filePath && fileBucketRef.child(filePath).getDownloadURL().then((url) => {
      fetch(url)
        .then(async res => ({
          filename: filePath.split("/").slice(-1)[0],
          blob: await res.blob()
        }))
        .then(resObj => {
          // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
          const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });

          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement('a');
            link.href = objUrl;
            link.download = resObj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
          }
        })
        .catch((error) => {
          console.log('DOWNLOAD ERROR', error);
        });
    });
  };

  handleChange = (event, data) => {
    this.setState({ [data.name]: data.value })
  };

  onChangeText = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSaveReminder = () => {
    const {
      db,
      guideId,
      guideName,
      guideUrl,
      guidePurpose,
      selectedFieldIds,
    } = this.props;

    const {
      reminderReason,
      reminderBasedOn,
      reminderBasedOnWeatherType,
      reminderBasedOnWeatherWhen,
      reminderEarliestDate,
      reminderLatestDate,
      reminderConditionTemperature,
      reminderConditionRainfall,
      reminderSumFirstDay,
    } = this.state;

    let dbRefReminders = this.props.getUser().collection("reminders").doc(guideId);

    let dbDocGuide = db && guideId && db.guides && db.guides[guideId];
    let dbRefGuide = this.props.getUser().collection("guides").doc(guideId);

    let payload = {
      date: new Date(),
      guide_id: guideId,
      guide_name: guideName,
      guide_purpose: guidePurpose,
      current_guide_step: "downloadFiles",
      type: 'guideReminder',
      data_type: guideUrl,
      reminder_type: reminderReason, // planNewFiles or useFiles
      condition: {
        type: reminderBasedOn, // weather or date
        weather_type: reminderBasedOnWeatherType, // from REMINDER_WEATHER_TYPES
        first_date: reminderEarliestDate && moment(reminderEarliestDate, "YYYY-MM-DD").toDate(),
        last_date: reminderLatestDate && moment(reminderLatestDate, "YYYY-MM-DD").toDate(),
        weather_from_date: reminderSumFirstDay && moment(reminderSumFirstDay, "YYYY-MM-DD").toDate(),
        min_temp: reminderConditionTemperature,
        min_rainfall: reminderConditionRainfall,
        days_ahead: reminderBasedOnWeatherWhen, // from REMINDER_WEATHER_TYPES_TIME
      },
      new_entries: selectedFieldIds,
      complete: false,
    };

    dbRefReminders.set(payload)
      .then(() => {
        toast.success("Freja har sparat din påminnelse.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 7000,
            hideProgressBar: false,
          });
      })

    let currentReminders = dbDocGuide && dbDocGuide.reminders ? dbDocGuide.reminders : [];
    dbRefGuide.update({
      reminders: [...currentReminders, guideId],
    });

    this.onClickCancelReminder();
  };

  onRemoveReminder = () => {
    const {
      guideId,
    } = this.props;

    let dbRefReminders = this.props.getUser().collection("reminders").doc(guideId);
    dbRefReminders.delete();

    let dbRefGuide = this.props.getUser().collection("guides").doc(guideId);
    dbRefGuide.update({ reminders: this.props.firebase.firestore.FieldValue.delete() });

    this.setState({
      showConfirmDeleteReminder: false,
    });
  };

  onClickCancelReminder = () => {
    let initialReminderState = {};

    INITIAL_STATE && Object.keys(INITIAL_STATE).forEach((x) => {
      if (x.includes("reminder")) {
        initialReminderState = { ...initialReminderState, [x]: INITIAL_STATE[x] };
      };
    });

    this.setState({
      ...initialReminderState,
      showAddReminderSegment: false,
    });
  };

  onEditReminder = () => {
    const {
      db,
      guideId,
    } = this.props;

    let guideReminders = db && db.reminders && db.reminders[guideId];
    let reminderConditions = guideReminders && guideReminders.condition;

    this.setState({
      reminderReason: guideReminders.reminder_type,
      reminderBasedOn: reminderConditions.type,
      reminderBasedOnWeatherType: reminderConditions.weather_type,
      reminderEarliestDate: reminderConditions.first_date && reminderConditions.first_date.toDate(),
      reminderLatestDate: reminderConditions.last_date && reminderConditions.last_date.toDate(),
      reminderSumFirstDay: reminderConditions.weather_from_date && reminderConditions.weather_from_date.toDate(),
      reminderConditionTemperature: reminderConditions.min_temp,
      reminderConditionRainfall: reminderConditions.min_rainfall,
      reminderBasedOnWeatherWhen: reminderConditions.days_ahead,
      showAddReminderSegment: true,
      reminderGuideStage: 3,
    });
  }

  getReminderSegments = () => {
    const {
      db,
      guideId,
    } = this.props;

    const {
      showAddReminderSegment,
      reminderBasedOn,
      reminderReason,
      reminderGuideStage,
      reminderBasedOnWeatherType,
      reminderBasedOnWeatherWhen,
      reminderEarliestDate,
      reminderLatestDate,
      reminderConditionTemperature,
      reminderConditionRainfall,
      reminderSumFirstDay,
      showConfirmDeleteReminder,
    } = this.state;

    let guideReminders = db && db.reminders && db.reminders[guideId];
    let reminderConditions = guideReminders && guideReminders.condition;

    let reminderSettings = reminderBasedOnWeatherType && REMINDER_WEATHER_TYPES.find((x) => x.key === reminderBasedOnWeatherType);
    reminderSettings = reminderSettings && reminderSettings.settings;

    let showTemperatureField = reminderSettings && reminderSettings.showTemperatureField;
    let showRainfallField = reminderSettings && reminderSettings.showRainfallField;
    let usedSummedData = reminderSettings && reminderSettings.usedSummedData;

    let reminderReasonText = reminderReason && (reminderReason === "planNewFiles" ? "planera nya styrfiler" : "använda dessa styrfiler");

    let reminderWeatherConditions = reminderConditions && reminderConditions.type === "weather";
    reminderWeatherConditions = reminderWeatherConditions && reminderConditions.weather_type && REMINDER_WEATHER_TYPES.find((x) => x.value === reminderConditions.weather_type);
    reminderWeatherConditions = reminderWeatherConditions && reminderWeatherConditions.text.toLowerCase();

    let reminderWeatherComputations = reminderConditions && reminderConditions.type === "weather";
    reminderWeatherComputations = reminderWeatherComputations && reminderConditions.days_ahead && REMINDER_WEATHER_TYPES_TIME.find((x) => x.value === reminderConditions.days_ahead);
    reminderWeatherComputations = reminderWeatherComputations && reminderWeatherComputations.text.toLowerCase();

    return (
      <React.Fragment>
        <Confirm
          open={showConfirmDeleteReminder}
          header="Vill du ta bort påminnelsen?"
          content="Denna handling går inte att ångra."
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.onRemoveReminder()}
          onCancel={() => this.toggleConfirmDeleteReminder()}
          size="tiny"
        />

        {(!showAddReminderSegment && guideReminders) &&
          <Segment
            style={{
              padding: "2em",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "80%",
              }}
            >
              <Header
                as="h3"
                style={{
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                Påminnelse
              </Header>

              <p
                style={{
                  width: "90%",
                  whiteSpace: "pre-line",
                  fontSize: "120%",
                }}
              >
                Freja kommer att påminna dig om denna guide när du
                {guideReminders.reminder_type && guideReminders.reminder_type === "planNewFiles" && " ska planera nya styrfiler "}
                {guideReminders.reminder_type && guideReminders.reminder_type === "useFiles" && " ska använda styrfilerna "}

                {reminderConditions.type && reminderConditions.type === "weather" && "baserat på datum och väder."}
                {reminderConditions.type && reminderConditions.first_date && reminderConditions.type === "date" && " den: " + moment(reminderConditions.first_date.toDate()).format("YYYY-MM-DD") + "."}

                {reminderConditions.type && reminderConditions.first_date && reminderConditions.last_date && reminderConditions.type === "weather" && " Påminnelsen kommer att skickas någon gång mellan " + moment(reminderConditions.first_date.toDate()).format("YYYY-MM-DD") + " och " + moment(reminderConditions.last_date.toDate()).format("YYYY-MM-DD")}

                {reminderWeatherConditions && " när " + reminderWeatherConditions + " når "}
                {reminderConditions.min_temp && reminderConditions.min_temp + " grader"}
                {reminderConditions.min_temp && reminderConditions.min_rainfall ? " och " : ""}
                {reminderConditions.min_rainfall && reminderConditions.min_rainfall + " mm"}

                {reminderConditions.weather_from_date && " beräknat från den " + moment(reminderConditions.weather_from_date.toDate()).format("YYYY-MM-DD") + "."}

                {reminderWeatherComputations && " Påminnelsen kommar att skickas ut " + reminderWeatherComputations + "."}
              </p>
            </div>

            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Dropdown
                style={{
                  margin: 0,
                  padding: 0,
                }}
                icon='ellipsis vertical'
                floating
                direction="left"
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon='edit'
                    text="Redigera"
                    onClick={() => this.onEditReminder()}
                  />

                  <Dropdown.Item
                    icon='trash'
                    text='Ta bort'
                    onClick={() => this.toggleConfirmDeleteReminder()}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Segment>
        }

        <GuideSegment
          caption="Påminnelse"
          hidden={!showAddReminderSegment || reminderGuideStage !== 1}
          simpleModeQuestion="Vad vill du bli påmind om?"
          simpleModeOpenText="Planera nya styrfiler"
          simpleModeOnOpen={() => {
            this.setState({ reminderReason: "planNewFiles", reminderGuideStage: 2 })
          }}
          simpleModeSkipText="Använda dessa styrfiler"
          simpleModeOnSkip={() => {
            this.setState({ reminderReason: "useFiles", reminderGuideStage: 2 })
          }}
          open={true}
          complete={false}
        />

        <GuideSegment
          caption="Påminnelse"
          hidden={!showAddReminderSegment || reminderGuideStage !== 2}
          simpleModeQuestion="Vad ska styra när påminnelsen skickas till dig?"
          simpleModeOpenText="Dygnsgrader, nederbörd och/eller datum"
          simpleModeOnOpen={() => {
            this.setState({ reminderBasedOn: "weather", reminderGuideStage: 3 })
          }}
          simpleModeSkipText="Ett visst datum"
          simpleModeOnSkip={() => {
            this.setState({ reminderBasedOn: "date", reminderGuideStage: 3 })
          }}
          open={true}
          complete={false}
        />

        <GuideSegment
          compact
          caption="Påminnelse"
          hidden={!showAddReminderSegment || reminderGuideStage !== 3 || reminderBasedOn !== "weather"}
          open={true}
          complete={false}
          onClickNextStep={() => this.onSaveReminder()}
          onClickCancel={() => this.onClickCancelReminder()}
        >
          <p
            style={{
              marginTop: "1em",
              whiteSpace: "pre-line",
              width: "70%",
              fontSize: "120%",
            }}
          >
            Freja kommer att visa påminnelsen i appen under vyn "Min gård" om du har Freja på en Androidmobil med aktiverade pushnotiser kommer påminnelsen även att skickas dit.
            Denna påminnelse kommer att baseras på villkoren som du bestämmer nedanför.
            Notisen kommer att antingen skickas ut på slutdatumet eller när exempelvis de sammanräknade dygngraderna och/eller nederbörden har nått upp till en viss nivå.
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2em",
            }}
          >
            <Segment
              secondary
              style={{
                width: "49%",
                height: "28em",
                marginTop: 0,
              }}
            >
              <Header
                as="h3"
                style={{
                  fontWeight: 500,
                }}
              >
                Villkor
              </Header>

              <Header
                as="h6"
                style={{
                  marginBottom: 5,
                }}
              >
                Jag vill bli påmind om att {reminderReasonText && reminderReasonText} när...
              </Header>

              <Form
                style={{
                  display: "block",
                  width: "100%",
                }}
              >
                <Form.Dropdown
                  placeholder='Välj vilka faktorer som ska avgöra när påminnelsen skickas...'
                  selection
                  name="reminderBasedOnWeatherType"
                  options={REMINDER_WEATHER_TYPES}
                  value={reminderBasedOnWeatherType}
                  onChange={(e, d) => this.onChange(d)}
                />
              </Form>

              {(showTemperatureField || showRainfallField) &&
                <Header
                  as="h6"
                  style={{
                    marginTop: 10,
                    marginBottom: 5,
                  }}
                >
                  når över..
                </Header>
              }

              {showTemperatureField &&
                <React.Fragment>
                  <Input
                    fluid
                    onChange={(e, d) => this.onChange(d, true)}
                    placeholder="Ange dygnsgrader/temperatur (grader)..."
                    name="reminderConditionTemperature"
                    value={reminderConditionTemperature}
                  />
                  <Header
                    as="h6"
                    style={{
                      marginTop: 5,
                    }}
                  >
                    {showRainfallField ? "grader och..." : "grader."}
                  </Header>
                </React.Fragment>
              }

              {showRainfallField &&
                <React.Fragment>
                  <Input
                    fluid
                    onChange={(e, d) => this.onChange(d, true)}
                    placeholder="Ange nederbörd (mm)..."
                    name="reminderConditionRainfall"
                    value={reminderConditionRainfall}
                  />
                  <Header
                    as="h6"
                    style={{
                      marginTop: 5,
                    }}
                  >
                    mm.
                  </Header>
                </React.Fragment>
              }
              {usedSummedData &&
                <React.Fragment>
                  <Header
                    as="h6"
                    style={{
                      marginTop: 10,
                      marginBottom: 5,
                    }}
                  >
                    Jag vill att de sammanräknade värdena ska innehålla dagarna från och med...
                  </Header>
                  <DateInput
                    name="reminderSumFirstDay"
                    placeholder="Datum"
                    value={reminderSumFirstDay}
                    popupPosition="bottom right"
                    dateFormat="YYYY-MM-DD"
                    localization='sv'
                    iconPosition="left"
                    onChange={this.handleChange}
                  />
                </React.Fragment>
              }
            </Segment>

            <Segment
              secondary
              style={{
                width: "49%",
                height: "28em",
                marginTop: 0,
              }}
            >
              <Header
                as="h3"
                style={{
                  fontWeight: 500,
                }}
              >
                När
              </Header>

              <Header
                as="h6"
                style={{
                  marginBottom: 5,
                }}
              >
                Jag vill bli påmind om detta...
              </Header>

              <Dropdown
                style={{
                  display: "block",
                  width: "100%",
                }}
                placeholder='Välj när du vill att påminnelsen ska skickas ut...'
                selection
                name="reminderBasedOnWeatherWhen"
                options={REMINDER_WEATHER_TYPES_TIME}
                value={reminderBasedOnWeatherWhen}
                onChange={(e, d) => this.onChange(d)}
              />

              <Header
                as="h6"
                style={{
                  marginBottom: 5,
                }}
              >
                Jag vill som tidigast ha denna påminnelse den...
              </Header>

              <DateInput
                name="reminderEarliestDate"
                placeholder="Datum"
                value={reminderEarliestDate}
                popupPosition="bottom right"
                dateFormat="YYYY-MM-DD"
                localization='sv'
                iconPosition="left"
                onChange={this.handleChange}
              />

              <Header
                as="h6"
                style={{
                  marginBottom: 5,
                }}
              >
                Jag vill som senast ha denna påminnelse den...
              </Header>

              <DateInput
                name="reminderLatestDate"
                placeholder="Datum"
                value={reminderLatestDate}
                popupPosition="bottom right"
                dateFormat="YYYY-MM-DD"
                localization='sv'
                iconPosition="left"
                onChange={this.handleChange}
              />
            </Segment>
          </div>
        </GuideSegment>

        <GuideSegment
          caption="Påminnelse"
          hidden={!showAddReminderSegment || reminderGuideStage !== 3 || reminderBasedOn !== "date"}
          open={true}
          complete={false}
          onClickNextStep={() => this.onSaveReminder()}
          onClickCancel={() => this.onClickCancelReminder()}
        >
          <p
            style={{
              marginTop: "1em",
              whiteSpace: "pre-line",
              width: "70%",
              fontSize: "120%",
            }}
          >
            Freja kommer att visa påminnelsen i appen under vyn "Min gård" om du har Freja på en Androidmobil med aktiverade pushnotiser kommer påminnelsen även att skickas dit.
            Denna påminnelse skickas ut det datumet som du bestämmer nedanför.
          </p>

          <Segment
            secondary
            style={{
              width: "49%",
              height: "23em",
              marginTop: "2em",
            }}
          >
            <Header
              as="h3"
              style={{
                fontWeight: 500,
              }}
            >
              När
            </Header>

            <Header
              as="h6"
              style={{
                marginBottom: 5,
              }}
            >
              Jag vill bli påmind om detta...
            </Header>

            <DateInput
              name="reminderEarliestDate"
              placeholder="Datum"
              value={reminderEarliestDate}
              popupPosition="bottom right"
              dateFormat="YYYY-MM-DD"
              localization='sv'
              iconPosition="left"
              onChange={this.handleChange}
            />
          </Segment>
        </GuideSegment>
      </React.Fragment>
    )
  };

  getInsightSegment = () => {
    const {
      db,
      guideId,
    } = this.props;

    const {
      noteField,
      showAddInsightSegment,
      showConfirmDeleteInsight,
    } = this.state;

    let guideInsight = guideId && db && db.insights && db.insights[guideId] && db.insights[guideId].note;

    return (
      <React.Fragment>
        <Confirm
          open={showConfirmDeleteInsight}
          header="Vill du ta bort insikten?"
          content="Denna handling går inte att ångra."
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.onSaveInsight(null)}
          onCancel={() => this.toggleConfirmDeleteInsight()}
          size="tiny"
        />

        {(!showAddInsightSegment && guideInsight) &&
          <Segment
            style={{
              padding: "2em",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "80%",
              }}
            >
              <Header
                as="h3"
                style={{
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                Insikt
              </Header>

              <p
                style={{
                  width: "80%",
                  whiteSpace: "pre-line",
                  fontSize: "120%",
                }}
              >
                {guideInsight && guideInsight}
              </p>
            </div>

            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Dropdown
                style={{
                  margin: 0,
                  padding: 0,
                }}
                icon='ellipsis vertical'
                floating
                direction="left"
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon='edit'
                    text="Redigera"
                    onClick={() => this.setState({ showAddInsightSegment: true, noteField: guideInsight })}
                  />

                  <Dropdown.Item
                    icon='trash'
                    text='Ta bort'
                    onClick={() => this.toggleConfirmDeleteInsight()}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Segment>
        }

        <GuideSegment
          caption="Insikt"
          hidden={!showAddInsightSegment}
          open={true}
          complete={false}
          onClickNextStep={() => this.onSaveInsight()}
          onClickCancel={() => this.setState({ showAddInsightSegment: false, noteField: '' })}
        >
          <p
            style={{
              width: "70%",
              marginTop: "1em",
              whiteSpace: "pre-line",
              fontSize: "120%",
            }}
          >
            Här kan du spara en kommentar i form av en insikt som du exempelvis kan se nästa gång du ska göra en styrfil eller när du ska följa upp säsongen.
          </p>

          <Form
            size="large"
            style={{
              marginTop: "1em",
              width: "70%",
            }}
          >

            <Form.TextArea
              autoFocus
              rows={10}
              name="noteField"
              placeholder="Skriv din kommentar här..."
              onChange={e => this.onChangeText(e)}
              value={noteField}
            />
          </Form>
        </GuideSegment>
      </React.Fragment>
    )
  };

  onChangeVraMapType = (d) => {
    this.onChange(d);

    let dbRef = this.props.getUser();
    dbRef.collection("settings").doc("guides").set({ terminal_file_type: d.value }, { merge: true });
  };

  getAllFieldsRow = (downloadableFiles, vraFileStats, selectedVraMapTypeData) => {
    const {
      compoundUnit,
      selectedFieldIds,
      skippedVraMapsForFields,
    } = this.props;

    let fieldsMeanRation = 0.0;
    let fieldsTotalRation = 0.0;
    let fieldsSavedTotalRation = 0.0;

    let fieldDownloadableFiles = downloadableFiles && downloadableFiles["all_fields"];

    selectedFieldIds && selectedFieldIds
      .filter((y) => !skippedVraMapsForFields || !skippedVraMapsForFields.includes(y))
      .forEach((y) => {

        let fieldStats = vraFileStats && vraFileStats[y];

        let fieldMeanRation = fieldStats && fieldStats.mean && parseFloat(fieldStats.mean);
        let fieldTotalRation = fieldStats && fieldStats.totalRation && parseFloat(fieldStats.totalRation);
        let fieldSavedTotalRation = fieldStats && fieldStats.savedTotalRation && parseFloat(fieldStats.savedTotalRation);

        fieldsMeanRation = fieldMeanRation ? fieldsMeanRation + fieldMeanRation : fieldsMeanRation;
        fieldsTotalRation = fieldTotalRation ? fieldsTotalRation + fieldTotalRation : fieldsTotalRation;
        fieldsSavedTotalRation = fieldSavedTotalRation ? fieldsSavedTotalRation + fieldSavedTotalRation : fieldsSavedTotalRation;
      });

    let meanRation = '';
    meanRation = fieldsMeanRation && fieldsMeanRation >= 1000 ? (Math.floor(fieldsMeanRation / 10) * 10).toLocaleString('sv') : meanRation;
    meanRation = fieldsMeanRation && fieldsMeanRation < 1000 ? fieldsMeanRation.toFixed(0) : meanRation;

    let totalRation = ''
    totalRation = (fieldsTotalRation && fieldsTotalRation >= 1000.0) ? (Math.floor(fieldsTotalRation / 10) * 10).toLocaleString('sv') : totalRation;
    totalRation = (fieldsTotalRation && fieldsTotalRation < 1000.0) ? fieldsTotalRation.toFixed(0) : totalRation;

    let savedRation = '';
    savedRation = (fieldsSavedTotalRation && fieldsSavedTotalRation >= 1000.0) ? (Math.floor(fieldsSavedTotalRation / 10) * 10).toLocaleString('sv') : savedRation;
    savedRation = (fieldsSavedTotalRation && fieldsSavedTotalRation < 1000.0) ? fieldsSavedTotalRation.toFixed(0) : savedRation;

    let savedRationPercent = fieldsSavedTotalRation && fieldsTotalRation && fieldsSavedTotalRation / (fieldsSavedTotalRation + fieldsTotalRation);
    savedRationPercent = savedRationPercent && (savedRationPercent * 100.0).toFixed(0);

    return (
      <Table.Row>
        <Table.Cell>Alla skiften</Table.Cell>
        <Table.Cell textAlign="center">{meanRation && meanRation + " " + compoundUnit}</Table.Cell>
        <Table.Cell textAlign="center">{totalRation && totalRation + " " + compoundUnit.replace("/ha", "")}</Table.Cell>
        <Table.Cell textAlign="center">{savedRation && savedRation + " " + compoundUnit.replace("/ha", "")} ({savedRationPercent && savedRationPercent}%)</Table.Cell>

        {selectedVraMapTypeData && selectedVraMapTypeData.files && selectedVraMapTypeData.files.map((x) => {

          let filePath = fieldDownloadableFiles && fieldDownloadableFiles[x.url_name];

          return (
            <Table.Cell
              key={"vraMapTypeTableCellAllFields_" + x.url_name}
              textAlign="center"
            >
              {filePath &&
                <Button
                  primary
                  size="small"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  onClick={() => this.downloadFile(filePath)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hämta
                  </Button>
              }

              {!filePath && <Icon loading name="circle notched" />}
              {!filePath && "Skapar fil..."}
            </Table.Cell>
          )
        })}
      </Table.Row>
    )
  };

  getNameSegment = () => {
    const {
      newGuideName,
      showAddGuideName,
    } = this.state;

    return (
      <Modal
        open={showAddGuideName}
        size="mini"
      >
        <Modal.Header>Namnge guide</Modal.Header>
        <Modal.Content>
          <p
            style={{
              marginBottom: "1em",
            }}
          >
            Ge guiden ett namn så att du enkelt hittar tillbaka senare.
          </p>

          <Input
            fluid
            name="newGuideName"
            value={newGuideName}
            onChange={(e, d) => this.onChange(d, false)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => this.setState({ showAddGuideName: false })}
          >
            Avbryt
          </Button>

          <Button
            primary
            loading={this.state.processing}
            disabled={!newGuideName || newGuideName.length === 0}
            onClick={() => this.setGuideName()}
          >
            Spara
          </Button>
        </Modal.Actions>
      </Modal>
    )
  };

  setGuideName = () => {
    const {
      guideId,
    } = this.props;

    const {
      newGuideName,
    } = this.state;

    this.setState({
      processing: true,
    });

    let dbRef = this.props.getUser().collection("guides").doc(guideId);
    dbRef.update({ name: newGuideName })
      .then(() => {

        toast.success("Du har namngivit guiden.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 7000,
            hideProgressBar: false,
          });

        this.setState({
          newGuideName: '',
          showAddGuideName: false,
          processing: false,
        });
      })
      .catch((error) => {
        toast.error("Något gick tyvärr fel. Felmeddelande: " + error.toString(),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 7000,
            hideProgressBar: false,
          });

        this.setState({
          processing: false,
        });
      })
  };

  render() {
    const {
      db,
      guide,
      guideId,
      guidePurpose,
      currentRationNo,
      compoundUnit,
      skippedVraMapsForFields,
    } = this.props;

    const {
      showAddInsightSegment,
      showAddReminderSegment,
      showMarkVraMapsUsed,
      processingTryAgain,
    } = this.state;

    let rationString = typeof (currentRationNo) === "number" ? currentRationNo.toFixed(0) : "1";

    let settingsGuides = db && db.settings && db.settings.guides;
    let selectedVraMapType = settingsGuides && settingsGuides.terminal_file_type ? settingsGuides.terminal_file_type : 'agco';
    selectedVraMapType = this.state.selectedVraMapType ? this.state.selectedVraMapType : selectedVraMapType;

    let selectedFieldId = guide && guide.field_id && guide.field_id;
    let selectedFieldIds = guide && guide.field_ids && guide.field_ids;
    selectedFieldIds = selectedFieldIds ? selectedFieldIds : [selectedFieldId];

    let downloadableFile = guide && guide.downloadable_file && guide.downloadable_file;
    let downloadableFiles = guide && guide["downloadable_files_" + rationString] && guide["downloadable_files_" + rationString];
    downloadableFiles = downloadableFiles ? downloadableFiles : [downloadableFile];

    let currentGuideName = guide && guide.name;
    let vraFileStats = guide && guide["approved_files_stats_" + rationString];
    let vraMapsUsed = guide && typeof (guide["vra_maps_used"]) === "boolean" && guide["vra_maps_used"] === true;
    let vraMapsUsedDate = guide && guide['date_vra_maps_used'] && moment(guide['date_vra_maps_used'].toDate()).format("YYYY-MM-DD");

    let menuItems = this.props.menuItems;
    menuItems = vraMapsUsed && menuItems ?
      { ...menuItems, previous: [{ ...menuItems.previous[0], hidden: true }] } :
      menuItems;

    let errorMessages = guide && guide.errors_downloadable_files;
    let hasErrorMessages = errorMessages && Object.keys(errorMessages).find((x) => errorMessages[x]);

    let selectVraMapType = VRA_MAP_TYPES && VRA_MAP_TYPES.map((x) => ({
      key: x.key,
      value: x.key,
      text: x.caption,
    }));

    let selectedVraMapTypeData = VRA_MAP_TYPES && selectedVraMapType && VRA_MAP_TYPES.find((x) => x.key === selectedVraMapType);

    let guideInsight = guideId && db && db.insights && db.insights[guideId];
    let guideReminder = guideId && db && db.reminders && db.reminders[guideId];

    let preCaption = " för grundgiva";
    preCaption = currentRationNo && currentRationNo > 0 ? " för kompletteringsgiva " + currentRationNo : preCaption;

    let mainColumn =
      <React.Fragment>
        <Segment
          style={{
            padding: "2em",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              width: "33%"
            }}
          >
            <Image
              centered
              size="medium"
              src={imgDoneIllustration}
            />
          </div>

          <div
            style={{
              width: "67%",
              marginLeft: "2em",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <Header
              as="h1"
              style={{
                fontWeight: 500,
              }}
            >
              Hämta styrfiler
            </Header>

            <p
              style={{
                fontWeight: "normal",
                fontSize: "120%",
                whiteSpace: "pre-line",
                width: "90%",
              }}
            >
              Grattis! Du har nu skapat styrfiler till din terminal som du kan använda för att {guidePurpose && guidePurpose}.
              Välj din terminaltillverkare nedanför för att ladda ned rätt fil.
              Du kan också ladda ned en PDF-fil med styrfilen som en karta för att kontrollera att filen blir rätt inläst i terminalen.
            </p>
          </div>
        </Segment>

        <GuideMarkVraMapUsed
          db={db}
          guideId={guideId}
          guide={guide}
          getUser={this.props.getUser}
          open={showMarkVraMapsUsed}
          onlyAllowMarkDone={true}
          closeModal={() => this.setState({ showMarkVraMapsUsed: false })}
        />

        {(!vraMapsUsed && !(showAddInsightSegment || showAddReminderSegment) && !(guideInsight && guideReminder)) &&
          <Segment
            style={{
              padding: "2em",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Header
              as="h3"
              style={{
                fontWeight: 500,
                height: "100%",
                width: "30%",
                marginTop: "auto",
                marginBottom: "auto",
                textAlign: "center",
              }}
            >
              Vill du göra något mer?
          </Header>

            <div
              style={{
                width: "70%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    newGuideName: currentGuideName ? currentGuideName : '',
                    showAddGuideName: true,
                  });
                }}
                style={{
                  backgroundColor: "#BF8136",
                  color: "white",
                  width: "15em",
                }}
              >
                Namnge guide
            </Button>

              {!showAddInsightSegment && !guideInsight &&
                <Button
                  onClick={() => this.setState({ showAddInsightSegment: true })}
                  style={{
                    backgroundColor: "#BF8136",
                    color: "white",
                    marginLeft: "1em",
                    width: "15em",
                  }}
                >
                  Lägga till insikt
              </Button>
              }

              {!showAddReminderSegment && !guideReminder &&
                <Button
                  onClick={() => this.setState({ showAddReminderSegment: true })}
                  style={{
                    backgroundColor: "#BF8136",
                    color: "white",
                    marginLeft: "1em",
                    width: "15em",
                  }}
                >
                  Skapa påminnelse
              </Button>
              }

              <Button
                positive
                onClick={() => this.setState({ showMarkVraMapsUsed: true })}
                style={{
                  marginLeft: "1em",
                  width: "15em",
                }}
              >
                Klarmarkera styrfiler
              </Button>
            </div>
          </Segment>
        }

        {vraMapsUsed &&
          <Segment
            style={{
              padding: "2em",
              display: "flex",
              justifyContent: "center",
              borderColor: "#4C8440",
            }}
          >
            <Header
              as="h3"
              style={{
                color: "#4C8440",
                fontWeight: 500,
                height: "100%",
                marginTop: "auto",
                marginBottom: "auto",
                textAlign: "center",
              }}
            >
              Styrfilerna användes den: {vraMapsUsedDate ? vraMapsUsedDate : "Okänt datum"}.
            </Header>
          </Segment>
        }
        {this.getNameSegment()}
        {this.getInsightSegment()}
        {this.getReminderSegments()}

        <Segment
          style={{
            padding: "2em",
          }}
        >

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2em",
            }}
          >
            <Header
              as="h2"
              style={{
                fontWeight: 500,
              }}
            >
              Styrfiler {preCaption && preCaption}
            </Header>

            <Form
              style={{
                marginTop: -5,
              }}
            >
              <Form.Dropdown
                selection
                name="selectedVraMapType"
                onChange={(e, d) => this.onChangeVraMapType(d)}
                value={selectedVraMapType}
                options={selectVraMapType}
              />
            </Form>
          </div>

          {selectedVraMapType &&
            <React.Fragment>
              <Table
                celled
                striped
                style={{
                  marginTop: "1em",
                }}
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Skifte</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">{selectedVraMapTypeData.key === "yara" ? "Målgiva" : "Snittgiva"}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Total giva</Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign="center"
                    >
                      {"Sparad giva "}
                      <Popup
                        trigger={<Icon name="help circle" />}
                        content={"Vi har beräknat detta genom att jämföra den föreslagna styrfilen ovan med en rak giva över hela skiftet på 90% av den maximalt föreslagna givan."}
                      />
                    </Table.HeaderCell>
                    {selectedVraMapTypeData && selectedVraMapTypeData.files && selectedVraMapTypeData.files.map((x) => (
                      <Table.HeaderCell
                        // width={(8 / selectedVraMapTypeData.files.length).toFixed(0)}
                        textAlign="center"
                        key={"vraMapTypeTableHeaderCell_" + x.url_name}
                      >
                        {x.caption}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {downloadableFiles && Object.keys(downloadableFiles).length > 0 && this.getAllFieldsRow(downloadableFiles, vraFileStats, selectedVraMapTypeData)}

                  {selectedFieldIds && selectedFieldIds
                    .filter((y) => !skippedVraMapsForFields || !skippedVraMapsForFields.includes(y))
                    .filter((y) => !errorMessages || !errorMessages[y])
                    .filter((y) => vraFileStats && vraFileStats[y])
                    .map((y) => {

                      let fieldName = db && y && db.fields && db.fields[y] && db.fields[y].name;

                      let fieldDownloadableFiles = downloadableFiles && downloadableFiles[y];

                      let fieldStats = vraFileStats && vraFileStats[y];
                      let fieldMeanRation = fieldStats && fieldStats.mean && parseFloat(fieldStats.mean);

                      let meanRation = '';
                      meanRation = fieldMeanRation && fieldMeanRation >= 1000 ? (Math.floor(fieldMeanRation / 10) * 10).toLocaleString('sv') : meanRation;
                      meanRation = fieldMeanRation && fieldMeanRation < 1000 ? fieldMeanRation.toFixed(0) : meanRation;

                      let fieldTotalRation = fieldStats && fieldStats.totalRation && parseFloat(fieldStats.totalRation);

                      let totalRation = ''
                      totalRation = (fieldTotalRation && fieldTotalRation >= 1000.0) ? (Math.floor(fieldTotalRation / 10) * 10).toLocaleString('sv') : totalRation;
                      totalRation = (fieldTotalRation && fieldTotalRation < 1000.0) ? fieldTotalRation.toFixed(0) : totalRation;

                      let fieldSavedTotalRation = fieldStats && fieldStats.savedTotalRation && parseFloat(fieldStats.savedTotalRation);

                      let savedRation = '';
                      savedRation = (typeof (fieldSavedTotalRation) === "number" && fieldSavedTotalRation >= 1000.0) ? (Math.floor(fieldSavedTotalRation / 10) * 10).toLocaleString('sv') : savedRation;
                      savedRation = (typeof (fieldSavedTotalRation) === "number" && fieldSavedTotalRation < 1000.0) ? fieldSavedTotalRation.toFixed(0) : savedRation;

                      let savedRationPercent = fieldSavedTotalRation && fieldTotalRation && fieldSavedTotalRation / (fieldSavedTotalRation + fieldTotalRation);
                      savedRationPercent = savedRationPercent && (savedRationPercent * 100.0).toFixed(0);

                      return (
                        <Table.Row
                          key={"vraMapTypeTable_" + y}
                        >
                          <Table.Cell>{fieldName && fieldName}</Table.Cell>
                          <Table.Cell textAlign="center">{meanRation && meanRation + " " + compoundUnit}</Table.Cell>
                          <Table.Cell textAlign="center">{totalRation && totalRation + " " + compoundUnit.replace("/ha", "")}</Table.Cell>
                          <Table.Cell textAlign="center">{savedRation && savedRation + " " + compoundUnit.replace("/ha", "")} ({savedRationPercent && savedRationPercent}%)</Table.Cell>
                          {selectedVraMapTypeData && selectedVraMapTypeData.files && selectedVraMapTypeData.files.map((x) => {

                            let filePath = fieldDownloadableFiles && fieldDownloadableFiles[x.url_name];

                            return (
                              <Table.Cell
                                key={"vraMapTypeTableCell_" + y + "_" + x.url_name}
                                textAlign="center"
                              >
                                {filePath &&
                                  <Button
                                    primary
                                    size="small"
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                    onClick={() => this.downloadFile(filePath)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Hämta
                                </Button>
                                }

                                {!filePath && <Icon loading name="circle notched" />}
                                {!filePath && "Skapar fil..."}
                              </Table.Cell>
                            )
                          })}
                        </Table.Row>
                      )
                    })}
                </Table.Body>
              </Table>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <Popup
                  trigger={
                    <Button
                      icon
                      labelPosition="left"
                      size="small"
                      onClick={() => this.createVraFilesAgain()}
                      loading={processingTryAgain}
                      disabled={processingTryAgain}
                    >
                      <Icon name="refresh" />
                    Skapa filer igen
                  </Button>
                  }
                  content="Ibland kan Freja fastna på att skapa styrfiler. Står någon fil fortfarande och skapas efter några minuter kan du prova att klicka här för att försöka skapa filerna igen."
                />
              </div>
            </React.Fragment>
          }
        </Segment>

        {selectVraMapType && hasErrorMessages &&
          <Segment
            style={{
              padding: "2em",
            }}
          >
            <Message
              style={{
                padding: "2em",
                whiteSpace: "pre-line",
                backgroundColor: "#C36126",
                color: "white",
              }}
              icon='exclamation triangle'
              header='Styrfilerna kunde inte skapas för ett eller fler skiften'
              content={"Freja hade tyvärr problem med att skapa styrfiler för ett eller fler skiften. Supporten har redan meddelats detta problem och kommer kolla upp och höra av sig till dig. Om du vill så kan du prova att skapa ett förslag igen."}
            />

            {selectedFieldIds && selectedFieldIds
              .filter((y) => !skippedVraMapsForFields || !skippedVraMapsForFields.includes(y))
              .filter((y) => errorMessages && errorMessages[y])
              .map((y) => {

                let fieldName = db && y && db.fields && db.fields[y] && db.fields[y].name;

                return (
                  <Message
                    style={{
                      padding: "1.5em",
                      whiteSpace: "pre-line",
                    }}
                    size="tiny"
                  >
                    <Message.Header>{'Felmeddelande för skiftet ' + fieldName}</Message.Header>
                    <Message.Content>
                      <p>{errorMessages && errorMessages[y]}</p>
                      <Button
                        primary
                        loading={this.state.tryingToGenerateAgain.includes(y)}
                        onClick={() => this.onClickTryGenerateAgain(y)}
                      >
                        Försök igen
                    </Button>
                    </Message.Content>
                  </Message>
                )
              })
            }
          </Segment>
        }
      </React.Fragment>



    let helpColumn =
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Hämta styrfiler
      </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Här kan du hämta styrfilerna som du har skapat i denna guiden.
          Du kan även hämta hem en PDF-karta som du kan skriva ut och ta med i traktorn.
          Samma karta kan du också hitta i Frejas mobilapp.
      </p>

        <Header
          as="h3"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Insikter och påminnelser
      </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Du kan enkelt lägga till en insikt om styrfilerna tills du ska använda dem denna gången eller när du vill planera nya för nästa säsong.
      </p>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Du kan också lägga till påminnelser om att använda eller skapa nya styrfiler. Då kan du låta Freja hålla koll på vädret och exempelvis påminna sig när dygnsgraderna har ökat till en viss nivå eller om väderprognosen säger att det kommer regna om två dagar.
      </p>

        <Button
          icon
          fluid
          style={{
            marginTop: "1em",
            backgroundColor: "#6B9D9F",
            color: "white",
          }}
          labelPosition="left"
          href="/help"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="question circle outline" />
        Läs mer i hjälpcentret
      </Button>

        <Header
          as="h3"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Är du inte nöjd?
      </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Om du inte är nöjd med dina styrfiler så kan du gå tillbaka genom att klicka på knappen "Justera styrfiler" nere till vänster.
          Du kan också klicka på knappen nedanför för att återställa guiden och börja om från början igen.

        <Button
            icon
            fluid
            style={{
              marginTop: "1em",
              backgroundColor: "#6B9D9F",
              color: "white",
            }}
            labelPosition="left"
            onClick={() => this.props.onClickStartOver()}
          >
            <Icon name="chevron left" />
          Gör om styrfilerna från början
        </Button>
        </p>
      </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        guideSubMenuActive={this.props.guideSubMenuActive}
        menuItems={menuItems}
      />
    )
  }
}

export default GuideDownloadFiles;