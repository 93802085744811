import React, { Component } from "react";

import { 
  Dropdown,
  Table,
  Segment,
  Header,
} from "semantic-ui-react";

import { NUTRIENTS_TO_SKIP, NUTRIENTS_SHOW_IN_SUMMARY } from "@/constants/fertilizers";
import { NUTRIENT_FERTILIZERS, LIMESTONE_TYPES } from "@/constants/fertilizers";

import GuideSegment from "@/components/guide/GuideSegment";

const INITIAL_STATE = {};

class GuideSegmentCompound extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onChangeDropDown = (d, variableName) => {
    const {
      compoundType,
    } = this.props;

    this.props.setParentState({ [variableName]: d.value });

    if (variableName === "guideCompoundType") {
      let currentFertilizer = [...LIMESTONE_TYPES, ...NUTRIENT_FERTILIZERS].find((x) => x.value === d.value);
      let guideCompoundNutrientRation = currentFertilizer ? currentFertilizer.nutrients[compoundType] : '100'

      this.props.setParentState({ guideCompoundNutrientRation });
    }
  };

  render() {
    const {
      guideMode,
      guideCompoundType,
      open,
      disabled,
      complete,
      compoundType,
      compoundName, 
      caption,
      question,
      description,
    } = this.props;

    let filteredFertilizers = [...LIMESTONE_TYPES, ...NUTRIENT_FERTILIZERS] && [...LIMESTONE_TYPES, ...NUTRIENT_FERTILIZERS].filter((x) => {
      return compoundType && x.nutrients && x.nutrients[compoundType] && x.nutrients[compoundType] > 0
    });

    let simpleModeDescription = 'Väljer du "Hoppa över" så beräknas styrfilen i kg ' + compoundType + '/ha och du får själv ställa in ' + compoundName + 'halten i terminalen.'

    let currentFertilizer = guideCompoundType && [...LIMESTONE_TYPES, ...NUTRIENT_FERTILIZERS].find((x) => x.value === guideCompoundType);
    let currentFertilizerNutrients = currentFertilizer && currentFertilizer.nutrients;

    return (
      <GuideSegment
        skipSimpleMode={guideMode && guideMode === "advanced"}
        caption={caption}
        open={open}
        disabled={disabled}
        simpleModeQuestion={question}
        simpleModeDescription={simpleModeDescription}
        simpleModeOnSkip={() => {
          this.props.setParentState({ guideCompoundType: 'none' });
          this.props.onClickNextStep();
        }}
        complete={complete}
        onClickOpenSegment={() => this.props.onClickOpenSegment()}
        onClickNextStep={() => this.props.onClickNextStep()}
      >
        <p
          style={{
            whiteSpace: "pre-line",
            fontSize: "110%",
          }}
        >
          {description && description}
        </p>

        <Segment
          secondary
          style={{
            marginTop: "4em",
            width: "80%",
            marginLeft: "10%",
            padding: "1.5em",
          }}
        >
          <Header 
            as="h6"
            style={{
              marginBottom: 5,
            }}
          >
            Gödslingsmedel
          </Header>

          <Dropdown
            fluid
            selection
            options={[
              ...filteredFertilizers, 
              { key: 'none', value: 'none', text: "Rent " + compoundName },
            ]}
            onChange={(e, d) => this.onChangeDropDown(d, "guideCompoundType")}
            value={guideCompoundType}
          />
        
          {compoundType && compoundType !== "Ca" && currentFertilizerNutrients && Object.keys(currentFertilizerNutrients).length > 0 &&
            <React.Fragment>
              <Header
                as="h3"
                style={{
                  fontWeight: 500,
                }}
              >
                Näringsinnehåll
              </Header>

              <Table 
                stackable
                celled 
                size='small' 
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <Table.Header>
                  <Table.Row>
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || !NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div/>
                      };
                      return <Table.HeaderCell key={"tableHeader" + x} textAlign="center">{x}</Table.HeaderCell>
                    })}
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div/>
                      };
                      return <Table.HeaderCell key={"tableHeader" + x} textAlign="center">{x}</Table.HeaderCell>
                    })}                  
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || !NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div />
                      };
                      return <Table.Cell key={"tableCell" + x} textAlign="center">{currentFertilizerNutrients[x]} %</Table.Cell>
                    })}
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div />
                      };
                      return <Table.Cell key={"tableCell" + x} textAlign="center">{currentFertilizerNutrients[x]} %</Table.Cell>
                    })}
                  </Table.Row>
                </Table.Body>
              </Table>
            </React.Fragment>
          }
        </Segment>
      </GuideSegment>
    )
  }
}

export default GuideSegmentCompound;