import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Header,
  Segment,
  Button,
  Icon, 
  Image,
} from "semantic-ui-react";

import ImageFarm from "@/assets/icons/menuPrimary/my-farm.svg"
import FieldCartoon from "@/components/misc/FieldCartoon";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";

import imgDoneIllustration from "@/assets/illustrations/done.png";

class HarvestReportGuideDownloadReports extends Component {

  state = {};

  downloadFile = (filePath) => {
    const {
      fileBucketRef,
    } = this.props;

    filePath && fileBucketRef.child(filePath).getDownloadURL().then((url) => {
      fetch(url)
        .then(async res => ({
            filename: filePath.split("/").slice(-1)[0],
            blob: await res.blob()
        }))
        .then(resObj => {
            // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
            const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
  
            // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
            } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);
  
                let link = document.createElement('a');
                link.href = objUrl;
                link.download = resObj.filename;
                link.click();
  
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
            }
        })
        .catch((error) => {
            console.log('DOWNLOAD ERROR', error);
        });
    });
  };

  render() {

    const {
      farmName,
      fields,
      selectedFieldIds,
      reportYieldMaps,
    } = this.props;

    let hasFarmReportPdf = reportYieldMaps && reportYieldMaps.farm && reportYieldMaps.farm.farm_report_pdf;

    let mainColumn = 
      <React.Fragment>
        <Segment
          style={{ 
            padding: "2em",
            display: "flex",
            justifyContent: "flex-start"
          }}
        >
          
          <Image 
            centered
            size="medium" 
            src={imgDoneIllustration} 
          />       
      
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "2em",
            }}
          >
            <Header as="h1" style={{ fontWeight: 500 }}>
              Hämta översikter
            </Header>
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: "120%",
                  whiteSpace: "pre-line",
                  width: "90%",
                }}
              >
                Grattis! Du har nu skapat skördeöversikter för de skiften du markerade tidigare.
                Freja håller också på att samla ihop en översikt över hela gården som sammanställer alla skördeöversikter som du har gjort.
                Du kan ladda ned alla dessa reporter nedanför när de är klara.
                Det kommer också dyka upp ett meddelande under "Min gård" på händelser när allt är klart.
              </p>

            </div>                
        </Segment>

        <Segment
          style={{
            backgroundColor: "ffffff",
            padding: "15px",
            paddingTop: 5,
            paddingRight: 12,
            paddingBottom: 5,
            marginTop: 10,
            marginBottom: 0,
            height: "100px",
          }}
        >
          <div
            style={{
              display: "flex", 
            }}
          >
            <div>
              <Image 
                className="fieldCartoon"
                style={{
                  margin: 0,
                  padding: 0,
                  paddingLeft: "2px",
                  marginTop: "15px",
                  height: "60px",
                  width: "60px",
                  zIndex: 20,
                  filter: "brightness(0) invert(75%) sepia(54%) saturate(323%) hue-rotate(5deg) brightness(98%) contrast(94%)",
                }}
                src={ImageFarm} 
              /> 
            </div>
            <div
              style={{
                marginLeft: 20,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <div 
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Header
                  style={{ 
                    fontWeight: 500,
                    paddingTop: "5px",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  {farmName && farmName}
                </Header>
              </div>

              {!hasFarmReportPdf &&
              <div>
                <Icon name='spinner' loading />
                Skapar gårdsöversikt                         
              </div>
            }                          
            </div>

            <div
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                height: "50%",
              }}                        
            >
            {hasFarmReportPdf &&
              <Button
                icon
                style={{
                  backgroundColor: "#6B9D9F",
                  color: "white",                
                }}
                labelPosition="left"
                onClick={() => this.downloadFile(hasFarmReportPdf)}
              >
                <Icon name="download" />
                Hämta översikt
              </Button>
            }           
            </div>
          </div>
        </Segment>

        {selectedFieldIds && selectedFieldIds.map((x) => {
          let name = fields && fields[x] && fields[x].name;
          let position = fields && fields[x] && fields[x].position;

          let hasReportPdf = reportYieldMaps && reportYieldMaps[x] && reportYieldMaps[x].harvest_report_pdf;
          let hasMapsPdf = reportYieldMaps && reportYieldMaps[x] && reportYieldMaps[x].harvest_report_maps_pdf;

          return (
            <Segment
              style={{
                backgroundColor: "ffffff",
                padding: "15px",
                paddingTop: 5,
                paddingRight: 12,
                paddingBottom: 5,
                marginTop: 10,
                marginBottom: 0,
                height: "100px",
              }}
            >
              <div
                style={{
                  display: "flex", 
                }}
              >
                <div>
                  {position && 
                    <FieldCartoon
                      className="fieldCartoon"
                      style={{
                        margin: 0,
                        padding: 0,
                        marginTop: "15px",
                        height: "60px",
                        width: "60px",
                        zIndex: 20,
                      }}            
                      color={"#e0ba8c"}
                      position={position}
                    />
                  }    
                </div>
                <div
                  style={{
                    marginLeft: 20,
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  <div 
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Header
                      style={{ 
                        fontWeight: 500,
                        paddingTop: "5px",
                        paddingBottom: 0,
                        marginBottom: 0,
                      }}
                    >
                      {name && name}
                    </Header>
                  </div>

                  {!hasReportPdf &&
                  <div>
                    <Icon name='spinner' loading />
                    Skapar skördeöversikt                         
                  </div>
                }                          
                </div>

                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "auto",
                    height: "50%",
                  }}                        
                >
                {hasMapsPdf &&
                  <Button
                    icon
                    style={{
                      backgroundColor: "#6B9D9F",
                      color: "white",                
                    }}
                    labelPosition="left"
                    onClick={() => this.downloadFile(hasMapsPdf)}                                 
                  >
                    <Icon name="download" />
                    Hämta kartor
                  </Button>
                }           

                {hasReportPdf &&
                  <Button
                    icon
                    style={{
                      backgroundColor: "#6B9D9F",
                      color: "white",                
                    }}
                    labelPosition="left"
                    onClick={() => this.downloadFile(hasReportPdf)}                                    
                  >
                    <Icon name="download" />
                    Hämta översikt
                  </Button>
                }             
                </div>
              </div>
            </Segment>
        )})}
      </React.Fragment>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Vill du ändra något?
        </Header>
            
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Är du inte nöjd med dina översikter och vill ändra något?
          Du kan du gå tillbaka till börja om från början.

          <Button
            icon
            fluid
            style={{
                marginTop: "1em",
                backgroundColor: "#6B9D9F",
                color: "white",                      
            }}
            labelPosition="left"
            onClick={() => this.props.onResetGuide()}
          >
            <Icon name="chevron left" />
            Börja om
          </Button>  
        </p>   
      </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        // mainColumnHeader="Hämta översikter"
        helpColumn={helpColumn}
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
      />   
    )
  }
}

export default withRouter(HarvestReportGuideDownloadReports);