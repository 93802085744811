import React, { Component } from "react";


import { 
  Popup,
  Icon,
} from "semantic-ui-react";


const INITIAL_STATE = {};

class LockIcon extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickUnlock = () => {
    this.props.onClickUnlock && this.props.onClickUnlock();
  };

  render () {
    const {
      locked,
    } = this.props;

    return (
      <Popup
        trigger={
          <Icon 
            style={{
              cursor: locked ? "pointer" : null,
              marginLeft: 5,
            }}
            name={locked ? "lock" : "lock open"}
            onClick={() => this.onClickUnlock()}
          />
        }
        content="Detta är ett värde som Freja automatiskt har ställt in. Om du vill ändra det kan du klicka på låset."
      />
    )
  };
};

export default LockIcon;
