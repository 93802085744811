import {
  isEmpty,
  isLoaded,
} from "react-redux-firebase";

import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";
import { CEREALS_CROP_CODES } from "@/constants/cropCodes";

export const ANALYSIS_TO_CACHE = [
  "greenmassAnalysis", 
  "laiAnalysis", 
  "moistureAnalysis", 
  "psriAnalysis",
  "soilMapAnalysis",
  "yieldAnalysis", 
  "yieldStatistics"
];

export const LAYERS_TO_CACHE = [
  "greenmass", 
  "lai", 
  "moisture", 
  "psri", 
  "soilMapSweden", 
  "moistureMapSweden", 
  "soilMapUploaded", 
  "yieldMap",
  // "elevationMap",
];

const CACHE_WINDOW = [1, 2, 3, 4];
const CACHE_WINDOW_PRECACHE = [0];
const CACHE_WINDOW_ALL = ['moistureMapSweden', 'soilMapSweden', 'soilMapUploaded', 'yieldMap', 'elevationMap'];

const filterAndSortLayerIds = (layer, layerIdTypes, array) => {

  let layerIdType = layerIdTypes && typeof(layerIdTypes) === "string" ? layerIdTypes : layer && layerIdTypes && layerIdTypes[layer];
  layerIdType = layerIdType && typeof(layerIdType) === "string" ? [layerIdType] : layerIdType;

  let filteredArray = array && [...array].filter((x) => {
    // TODO: Look into filtering for handling elevation map

    // if (layerIdType && layerIdType.includes("dates") && layerIdType.length === 1 && isNaN(new Date(x).valueOf())) {
    //   return false
    // };

    if (layerIdType && layerIdType.includes("years") && layerIdType.length === 1 && (isNaN(x) || x.length !== 4)) {
      return false
    };

    // if (layerIdType && !layerIdType.includes("years") && layerIdType.includes("strings") && !isNaN(x)) {
    //   return false
    // };    

    // if (layerIdType && layerIdType.includes("crops") && typeof(x) === "string") {
    //   return false
    // };

    // if (layerIdType && layerIdType.includes("strings") && typeof(x) === "string") {
    //   return false
    // };
    
    return true
  });

  if (layerIdType && layerIdType.includes("dates") && layerIdType.length === 1) {
    return filteredArray && filteredArray.sort((a, b) => {
      return new Date(b) - new Date(a);
    });
  
  } else {
    return filteredArray && filteredArray.sort();
  };  
};

export const hasLayerData = (db, fieldId, layer) => {

  if (!layer || !fieldId) {
    return false
  }

  let dataLayer = getCurrentDataLayer(layer);

  if (!dataLayer) {
    return false
  };

  let dbDataDocName = dataLayer && dataLayer.dbDataDocName;
  dbDataDocName = typeof(dbDataDocName) === "string" ? dbDataDocName : dbDataDocName[layer];

  let dbColName = dbDataDocName && dbDataDocName.split("/")[0];
  let dbDocName = dbDataDocName && dbDataDocName.split("/")[1];

  let dbDocData = db && fieldId &&
    db[dbColName + "_" + fieldId] &&
    db[dbColName + "_" + fieldId][dbDocName];

  if (dbDocData) {
    return true
  } else {
    return false
  }
};

export const getLayerStatus = (db, fieldId, layer) => {

  if (!layer || !fieldId) {
    return false
  }

  let dataLayer = getCurrentDataLayer(layer);

  if (!dataLayer) {
    return false
  };

  let dbDataDocName = dataLayer && dataLayer.dbDataDocName;
  dbDataDocName = typeof(dbDataDocName) === "string" ? dbDataDocName : dbDataDocName[layer];

  let dbColName = dbDataDocName && dbDataDocName.split("/")[0];

  let dbDocData = db && fieldId &&
    db[dbColName + "_" + fieldId] &&
    db[dbColName + "_" + fieldId];

  return dbDocData && dbDocData.status
};

export const getAvailableLayerIds = (db, fieldId, layer, reverseDates=false) => {

  if (!layer || !fieldId) {
    return []
  };
  
  let dataLayer = getCurrentDataLayer(layer);

  if (!dataLayer) {
    return []
  };

  let dbDataDocName = dataLayer && dataLayer.dbDataDocName;
  dbDataDocName = typeof(dbDataDocName) === "string" ? dbDataDocName : dbDataDocName && dbDataDocName[layer];

  let dbColName = dbDataDocName && dbDataDocName.split("/")[0];
  let dbDocName = dbDataDocName && dbDataDocName.split("/")[1];

  let dbDocData = db && fieldId &&
    db[dbColName + "_" + fieldId] &&
    db[dbColName + "_" + fieldId][dbDocName];

  let dates = dbDocData && Array.isArray(dbDocData) && [...dbDocData];
  dates = dates ? dates : dbDocData && [...Object.keys(dbDocData)];
  dates = dates && filterAndSortLayerIds(layer, dataLayer.layerIdTypes, dates);

  if (dataLayer && dataLayer.firstDate) {
    dates = dates && dates.filter((x) => {
      let currentDate = new Date(x);
      let firstDate = new Date(dataLayer.firstDate);

      return currentDate >= firstDate;
    });
  };

  if (reverseDates) {
    return dates && [...dates].reverse()
  } else {
    return dates && [...dates]
  }
};

export const fetchData = (props) => {
  const {
    layer,
  } = props;

  // console.log(layer, props.layerId)
  
  if (layer && layer.includes("trueColor")) { 
    fetchImageData(props); 
    return;
  }

  if (layer && layer.includes("samples")) { 
    fetchMarkerData(props); 
    return;
  }

  layer && fetchGeoJsonData(props);
};

export const fetchGeoJsonData = (props) => {
  const {
    db,
    fieldId,
    layer,
    layerId,
    plotName,
    setParentState,
    openLayer,
    cacheFieldData,
  } = props;

  if (handleSpecialLayerId(props)) {
    return;
  };

  let isCompareMode = plotName && plotName.length > 0;

  // Get the data type of the current data layer (float, percent, integer, etc.)
  let dataLayer = getCurrentDataLayer(layer);

  let geoJsonFieldKey = dataLayer && dataLayer.geoJsonFieldKey && dataLayer.geoJsonFieldKey[layer];

  // Get the db information
  let dbDataDocNameFull = dataLayer && dataLayer.dbDataDocName;
  dbDataDocNameFull = typeof(dbDataDocNameFull) === "string" ? dbDataDocNameFull : dbDataDocNameFull && dbDataDocNameFull[layer];

  let dbDataColName = dbDataDocNameFull && dbDataDocNameFull.split("/")[0];
  let dbDataDocName = dbDataDocNameFull && dbDataDocNameFull.split("/")[1];
  let dbPathDocName = dataLayer && dataLayer.dbPathDocName && dataLayer.dbPathDocName.split("/")[1];

  let dbStatDocNameFull = dataLayer && dataLayer.dbStatDocName;
  dbStatDocNameFull = typeof(dbStatDocNameFull) === "string" ? dbStatDocNameFull : dbStatDocNameFull && dbStatDocNameFull[layer];

  let dbStatColName = dbStatDocNameFull && dbStatDocNameFull.split("/")[0];
  let dbStatDocName = dbStatDocNameFull && dbStatDocNameFull.split("/")[1];

  let dbDataCol = dbDataColName && db && db[dbDataColName + "_" + fieldId];

  if (!isLoaded(dbDataCol)) {
    return;
  };

  let dbDataDoc = dbDataCol && dbDataDocName && dbDataCol[dbDataDocName];
  let dbPathDoc = dbDataCol && dbPathDocName && dbDataCol[dbPathDocName];
  let docHasNoLayerId = dbDataDoc && typeof(dbDataDoc) === "string";

  let dbDocDataKeys = dbDataDoc && (Array.isArray(dbDataDoc) ? [...dbDataDoc] : Object.keys(dbDataDoc).map((x) => x));
  dbDocDataKeys = dbDocDataKeys && filterAndSortLayerIds(layer, dataLayer.layerIdTypes, dbDocDataKeys);

  if (dataLayer && dataLayer.firstDate) {
    dbDocDataKeys = dbDocDataKeys && dbDocDataKeys.filter((x) => {
      let currentDate = new Date(x);
      let firstDate = new Date(dataLayer.firstDate);

      return currentDate >= firstDate;
    });
  };

  let latestDate = dbDocDataKeys && dbDocDataKeys.length >= 1 && dbDocDataKeys;
  let sortedDates = latestDate && Array.isArray(latestDate) && filterAndSortLayerIds(layer, dataLayer.layerIdTypes, latestDate);

  latestDate = sortedDates ? sortedDates.reverse()[0] : latestDate;  
  latestDate = latestDate && latestDate.includes("all") ? "all" : latestDate;

  let checkedLayerId = layerId && layerId === "latest" ? latestDate : layerId;

  let dbStatCol = dbStatColName && fieldId && db && db[dbStatColName + "_" + fieldId];
  let dbStatDoc = dbStatCol && dbStatDocName && dbStatCol[dbStatDocName];

  let identifier = fieldId && layer && (fieldId + "_" + layer.split("_")[0] + "_" + (checkedLayerId ? checkedLayerId : ""));

  // Fetch from cache if available
  if (cacheFieldData && cacheFieldData.geoJsonData && Object.keys(cacheFieldData.geoJsonData).includes(identifier)) {
    // console.log("Found in cache: ", identifier)
    fetchCache({...props, identifier, geoJsonFieldKey});
    populateCache({...props, identifier, dbDataDoc, dbPathDoc, dbDocDataKeys});
    return null;
  };

  // Change key to fetch in data file
  setParentState({ 
    geoJsonFieldKey,
    dataLayerMissing: false,
    loadingData: true,
  });

  // Skip download of new data (if just the layerId changes)
  let downloadNewData = typeof(props.downloadNewData) === "boolean" ? props.downloadNewData : true;

  if (!downloadNewData) {
    return;
  };

  // Get the statistics
  dbStatDoc && fetchStatJson({
    ...props,
    filePath: dbStatDoc,
    identifier
  });

  // Get the data
  if (docHasNoLayerId || (dbDocDataKeys && dbDocDataKeys.includes(checkedLayerId))) {
    let filePath = docHasNoLayerId ? dbDataDoc : dbDataDoc[checkedLayerId];
    filePath = dbPathDoc ? dbPathDoc.replace("[DATE]", checkedLayerId) : filePath;    

    fetchGeoJson({
      ...props,
      filePath,
      identifier
    });

    populateCache({...props, identifier, dbDataDoc, dbPathDoc, dbDocDataKeys});

  } else {

    if (!isCompareMode && !latestDate) {
      (isLoaded(dbDataCol) || isEmpty(dbDataCol)) && setParentState({ loadingData: false, dataLayerMissing: true });   
      return null;
    };

    !checkedLayerId && openLayer && openLayer(layer, latestDate, plotName);
    checkedLayerId && dbDocDataKeys && !dbDocDataKeys.includes(checkedLayerId) && openLayer && openLayer(layer, latestDate, plotName);

    // Not compare mode and the db is loaded
    !isCompareMode && latestDate && isLoaded(dbDataCol) && openLayer && openLayer(layer, latestDate, plotName);

    // In compare mode and the db is loaded or the collection is missing
    isCompareMode && 
      (isLoaded(dbDataCol) || isEmpty(dbDataCol)) && 
      setParentState({ loadingData: false, dataLayerMissing: true });
  };
};

async function wrapperFetchGeoJson(props) {
  try {
      await fetchGeoJson(props);
      return true;
  } catch(e) {
      console.log("ERROR: wrapperFetchGeoJson: ", e);
      throw e;
  };
};

export const populateCacheWrapper = (props) => {
  const {
    db,
    fieldId,
    layer,
    layerId,
    cacheFieldData,
  } = props;
        
  // console.log("Caching layer " + layer + " with layerId: " + layerId)

  // Get the data type of the current data layer (float, percent, integer, etc.)
  let dataLayer = getCurrentDataLayer(layer);

  // Get the db information
  let dbDataDocNameFull = dataLayer && dataLayer.dbDataDocName;
  dbDataDocNameFull = typeof(dbDataDocNameFull) === "string" ? dbDataDocNameFull : dbDataDocNameFull && dbDataDocNameFull[layer];

  let dbDataColName = dbDataDocNameFull && dbDataDocNameFull.split("/")[0];
  let dbDataDocName = dbDataDocNameFull && dbDataDocNameFull.split("/")[1];
  let dbPathDocName = dataLayer && dataLayer.dbPathDocName && dataLayer.dbPathDocName.split("/")[1];

  let dbDataCol = dbDataColName && db && db[dbDataColName + "_" + fieldId];

  if (!isLoaded(dbDataCol)) {
    // console.log("Database is not ready", dbDataColName + "_" + fieldId, layer, layerId)
    return;
  };

  let dbDataDoc = dbDataCol && dbDataDocName && dbDataCol[dbDataDocName];
  let dbPathDoc = dbDataCol && dbPathDocName && dbDataCol[dbPathDocName];
  let docHasNoLayerId = dbDataDoc && typeof(dbDataDoc) === "string";

  let dbDocDataKeys = dbDataDoc && (Array.isArray(dbDataDoc) ? [...dbDataDoc] : Object.keys(dbDataDoc).map((x) => x));
  dbDocDataKeys = dbDocDataKeys && filterAndSortLayerIds(layer, dataLayer.layerIdTypes, dbDocDataKeys);

  if (dataLayer && dataLayer.firstDate) {
    dbDocDataKeys = dbDocDataKeys && dbDocDataKeys.filter((x) => {
      let currentDate = new Date(x);
      let firstDate = new Date(dataLayer.firstDate);

      return currentDate >= firstDate;
    });
  };

  let latestDate = dbDocDataKeys && dbDocDataKeys.length >= 1 && dbDocDataKeys;
  let sortedDates = latestDate && Array.isArray(latestDate) && filterAndSortLayerIds(layer, dataLayer.layerIdTypes, latestDate);

  latestDate = sortedDates ? sortedDates.reverse()[0] : latestDate;  
  latestDate = latestDate && latestDate.includes("all") ? "all" : latestDate;

  let checkedLayerId = layerId && layerId === "latest" ? latestDate : layerId;

  let identifier = fieldId && layer && (fieldId + "_" + layer.split("_")[0] + "_" + (checkedLayerId ? checkedLayerId : ""));

  // Check if already in cache
  if (cacheFieldData && cacheFieldData.geoJsonData && Object.keys(cacheFieldData.geoJsonData).includes(identifier)) {
    // console.log("Already found in cache...")
    return null;
  };

  // Get the data (if layerId was specified)
  if (docHasNoLayerId || (dbDocDataKeys && dbDocDataKeys.includes(checkedLayerId))) {
    // console.log("Calling populateCache with: ", fieldId, layer, "Using date", checkedLayerId)
    populateCache({...props, identifier, dbDataDoc, dbPathDoc, dbDocDataKeys, layerId: checkedLayerId}, CACHE_WINDOW_PRECACHE);
  };

  // Get all data for certain families of layers (when layerId is null)
  if (checkedLayerId === null && CACHE_WINDOW_ALL.includes(dataLayer.name)) {
    sortedDates && sortedDates.forEach((layerId) => {

      identifier = fieldId && layer && (fieldId + "_" + layer.split("_")[0] + "_" + (layerId ? layerId : ""));
    
      if (cacheFieldData && cacheFieldData.geoJsonData && Object.keys(cacheFieldData.geoJsonData).includes(identifier)) {
        // console.log("Already found in cache...")
        return null;
      };

      if (docHasNoLayerId || (dbDocDataKeys && dbDocDataKeys.includes(layerId))) {
        // console.log("Calling populateCache with: ", fieldId, layer, "Using date", layerId)
        populateCache({...props, identifier, dbDataDoc, dbPathDoc, dbDocDataKeys}, CACHE_WINDOW_PRECACHE);
      };

    });
  };  
};

export const populateCache = (props, cacheWindow=CACHE_WINDOW) => {
  const {
    activeView,
    dbDataDoc,
    dbPathDoc,
    dbDocDataKeys,
    layerId,
    identifier,
    cacheFieldData,
  } = props;
  
  if (activeView && activeView.includes("compare")) {
    return;
  };

  let currentCachedIdentifiers = cacheFieldData && 
    cacheFieldData.geoJsonData && 
    Object.keys(cacheFieldData.geoJsonData).map((x) => x);

  if (currentCachedIdentifiers && dbDocDataKeys && dbDocDataKeys.length >= 10) {
    let currentIndex = dbDocDataKeys.indexOf(layerId);

    cacheWindow && cacheWindow.forEach((offset) => {
      let previousLayerId = (currentIndex - offset) >= 0 ? dbDocDataKeys[currentIndex - offset] : null;
      let previousIdentifier = identifier.replace(layerId, previousLayerId);
  
      let nextLayerId = (currentIndex + offset) < dbDocDataKeys.length ? dbDocDataKeys[currentIndex + offset] : null;
      let nextIdentifier = identifier.replace(layerId, nextLayerId);
  
      let previousLayerFilePath = dbDataDoc[previousLayerId];
      previousLayerFilePath = dbPathDoc ? dbPathDoc.replace("[DATE]", previousLayerId) : previousLayerFilePath;
  
      let nextLayerFilePath = dbDataDoc[nextLayerId];
      nextLayerFilePath = dbPathDoc ? dbPathDoc.replace("[DATE]", nextLayerId) : nextLayerFilePath;

      previousLayerId && !currentCachedIdentifiers.includes(previousIdentifier) &&
        wrapperFetchGeoJson({
          ...props,
          filePath: previousLayerFilePath,
          identifier: previousIdentifier,
          onlyCache: true,
          
        });
    
      nextLayerId && nextIdentifier !== previousIdentifier && !currentCachedIdentifiers.includes(nextIdentifier) &&
        wrapperFetchGeoJson({
          ...props,
          filePath: nextLayerFilePath,
          identifier: nextIdentifier,
          onlyCache: true,
        });
    });

  } else {

    currentCachedIdentifiers && dbDocDataKeys && dbDocDataKeys.forEach((x) => {
      let newIdentifier = identifier.replace(layerId, x);
      if (!currentCachedIdentifiers.includes(newIdentifier)) {
        wrapperFetchGeoJson({
          ...props,
          filePath: dbDataDoc[x],
          identifier: newIdentifier,
          onlyCache: true,
        });
      }
    })
  }
}

export const fetchCache = (props) => {
  const {
    layerId,
    identifier,
    geoJsonFieldKey,
    cacheFieldData,
    setParentState,
    setContainerState,
    plotName
  } = props;

  cacheFieldData && setParentState({ 
    geoJsonFieldKey,
    dataLayerMissing: false,   
    loadingData: false,
    geoJsonData: cacheFieldData.geoJsonData[identifier],
    statJson: cacheFieldData.statJson[identifier.replace("_" + layerId, "")],
  });

  cacheFieldData && plotName === "UpperLeft" && setContainerState({ compareUpperGeoJsonData: cacheFieldData.geoJsonData[identifier] });
  cacheFieldData && plotName === "LowerLeft" && setContainerState({ compareLowerGeoJsonData: cacheFieldData.geoJsonData[identifier] });
  
  setContainerState && setContainerState({ updatedPlotData: new Date() });
};

export const handleSpecialLayerId = (props) => {
  const {
    db,
    fieldId,
    layer,
    layerId,
    fieldInfo,
    plotName,
    openLayer,
    setParentState,
  } = props;

  // Get the data type of the current data layer (float, percent, integer, etc.)
  let dataLayer = getCurrentDataLayer(layer);

  // Get the db information
  let dbDataDocNameFull = dataLayer && dataLayer.dbDataDocName;
  dbDataDocNameFull = typeof(dbDataDocNameFull) === "string" ? dbDataDocNameFull : dbDataDocNameFull && dbDataDocNameFull[layer];

  let dbDataColName = dbDataDocNameFull && dbDataDocNameFull.split("/")[0];
  let dbDataDocName = dbDataDocNameFull && dbDataDocNameFull.split("/")[1];
  
  let dbDataCol = dbDataColName && db && db[dbDataColName + "_" + fieldId];
  let dbDataDoc = dbDataCol && dbDataDocName && dbDataCol[dbDataDocName];

  let dbDocDataKeys = dbDataDoc && (Array.isArray(dbDataDoc) ? [...dbDataDoc] : Object.keys(dbDataDoc).map((x) => x));

  if (layer && layerId && layer.includes("yieldMap") && layerId.includes("/")) {
    let cropCode = layerId.split("/")[0];
    cropCode = cropCode && cropCode.split("=")[1]
    
    // let dateRange = layerId.split("/")[1];
    // dateRange = dateRange && dateRange.split("=")[1];
    
    let dateIndex = layerId.split("/")[2];
    dateIndex = dateIndex && parseInt(dateIndex.split("=")[1]);

    let cropCodes = fieldInfo && fieldInfo.crop_code;

    let yieldMapYears = (dbDocDataKeys && !["all", "cereals"].includes(cropCode)) ? dbDocDataKeys.filter((x) => cropCodes[x] === cropCode) : dbDocDataKeys;
    yieldMapYears = (yieldMapYears && cropCode === "cereals") ? yieldMapYears.filter((x) => CEREALS_CROP_CODES.includes(cropCodes[x])) : yieldMapYears;
    yieldMapYears = yieldMapYears && yieldMapYears.sort().reverse();

    if (yieldMapYears && yieldMapYears.length > dateIndex) {
      // console.log("openLayer", layer, yieldMapYears[dateIndex])
      openLayer && openLayer(layer, yieldMapYears[dateIndex], plotName);
      return true
    } 
  };
  
  if (layer && layerId && (layer.includes("greenmassAnalysis") || layer.includes("moistureAnalysis")) && layerId.includes("/")) {
    let cropCode = layerId.split("/")[0];
    cropCode = cropCode && cropCode.split("=")[1]
    
    // let dateRange = layerId.split("/")[1];
    // dateRange = dateRange && dateRange.split("=")[1];
    
    let dateIndex = layerId.split("/")[2];
    dateIndex = dateIndex && parseInt(dateIndex.split("=")[1]);

    let cropCodes = fieldInfo && fieldInfo.crop_code;

    let sentinelAnalysisYears = (dbDocDataKeys && !["all", "cereals"].includes(cropCode)) ? cropCodes && dbDocDataKeys.filter((x) => cropCodes[x] === cropCode) : dbDocDataKeys;
    sentinelAnalysisYears = (sentinelAnalysisYears && cropCode === "all") ? sentinelAnalysisYears.filter((x) => parseInt(x) && parseInt(x) > 1000) : sentinelAnalysisYears;
    sentinelAnalysisYears = (sentinelAnalysisYears && cropCode === "cereals") ? cropCodes && sentinelAnalysisYears.filter((x) => CEREALS_CROP_CODES.includes(cropCodes[x])) : sentinelAnalysisYears;
    sentinelAnalysisYears = sentinelAnalysisYears && sentinelAnalysisYears.sort().reverse();

    if (sentinelAnalysisYears && sentinelAnalysisYears.length > dateIndex) {
      // console.log("openLayer", layer, sentinelAnalysisYears[dateIndex])
      openLayer && openLayer(layer, sentinelAnalysisYears[dateIndex], plotName);
      return true
    } 
  };

  if (layer && layerId && layer.includes("greenmass") && layerId.includes("/")) { 
    let cropCode = layerId.split("/")[0];
    cropCode = cropCode && cropCode.split("=")[1]
    
    let dateRange = layerId.split("/")[1];
    dateRange = dateRange && dateRange.split("=")[1];
    
    let dateIndex = layerId.split("/")[2];
    dateIndex = dateIndex && dateIndex.split("=")[1];

    let cropCodes = fieldInfo && fieldInfo.crop_code;

    let greenMassDates = (dbDocDataKeys && !["all", "cereals"].includes(cropCode)) ? dbDocDataKeys && dbDocDataKeys.filter((x) => cropCodes[x.split("-")[0]] === cropCode) : dbDocDataKeys;
    greenMassDates = (dbDocDataKeys && cropCode === "cereals") ? dbDocDataKeys.filter((x) => CEREALS_CROP_CODES.includes(cropCodes[x.split("-")[0]])) : dbDocDataKeys;
    greenMassDates = greenMassDates ? greenMassDates && greenMassDates.filter((x) => x.includes(dateRange)) : greenMassDates;


    if (dateIndex === "first") {
      dateIndex = 0;
    };

    if (dateIndex === "middle") {
      dateIndex = Math.floor((greenMassDates.length - 1) / 2.0);
    };

    if (dateIndex === "last") {
      dateIndex = greenMassDates.length - 1;
    };

    if (greenMassDates && greenMassDates.length > dateIndex) {
      openLayer && openLayer(layer, greenMassDates[dateIndex], plotName);
      return true
    };

    setParentState({ 
      dataLayerMissing: true,
      loadingData: false,
    });

    return false
  }
};


export const fetchImageData = (props) => {
  const {
    db, 
    userId,
    fieldId, 
    layerId, 
    fileBucketRef, 
    plotName, 
    setParentState, 
    setContainerState,
    openLayer,
  } = props;

  setParentState({ 
    geoJsonData: null ,
    geoJsonFieldKey: null,
    statJson: null, 
    markers: null,
  });

  let dbDoc = db && fieldId && 
    db['fieldsSentinelHubRaw_' + fieldId] &&
    db['fieldsSentinelHubRaw_' + fieldId].rawdata_sentinel_true_color_png;

  let latestDate, url;

  if(Array.isArray(dbDoc)) {
    latestDate = dbDoc && [...dbDoc].sort(function(a, b) {
      a = new Date(a);
      b = new Date(b);
      return a > b ? -1 : a < b ? 1 : 0;
    });
    latestDate = latestDate && latestDate[0];
    url = "users/" + userId + "/fieldsSentinelHubRaw/" + fieldId + "/" + layerId + "/true_color_raw.png";
  } else {
    latestDate = dbDoc && filterAndSortLayerIds("trueColor", ["dates"], Object.keys(dbDoc).map(x => x)).slice(-1)[0];
    url = dbDoc && layerId && dbDoc[layerId];
  };
  

  url && fileBucketRef.child(url).getDownloadURL()
    .then(url => {
      setParentState({ 
        polygonImage: url,
        loadingData: false,
      });

      setContainerState && setContainerState({ updatedPlotData: new Date() });
    })
    .catch(error => {
      // console.log("Error in getSatelliteImage", error);
      openLayer && latestDate && openLayer("trueColor", latestDate, plotName);
    })
};

export const fetchMarkerData = (props) => {
  const {
    db,
    fieldId,
    layer,
    layerId,
    setParentState,
  } = props;

  // Get the data type of the current data layer (float, percent, integer, etc.)
  let dataLayer = getCurrentDataLayer(layer);

  // Get the db information
  let geoJsonFieldKey = dataLayer && dataLayer.geoJsonFieldKey && dataLayer.geoJsonFieldKey[layer];

  let dbMarkersColName = dataLayer && dataLayer.dbMarkersDocName && dataLayer.dbMarkersDocName.split("/")[0];
  let dbMarkersDocName = dataLayer && dataLayer.dbMarkersDocName && dataLayer.dbMarkersDocName.split("/")[1];  

  let dbMarkersCol = dbMarkersColName && db && db[dbMarkersColName + "_" + fieldId];

  let dbMarkersDoc = dbMarkersCol && dbMarkersDocName && dbMarkersCol[dbMarkersDocName];

  setParentState({ 
    geoJsonData: null ,
    geoJsonFieldKey,
    statJson: null, 
    markers: null,
  });

  if (dbMarkersDoc && dbMarkersDoc[layerId]) {
    setParentState({
      markers: dbMarkersDoc[layerId],
      loadingData: false,
    })
  };
};

export const fetchGeoJson = (props) => {
  const {
    fieldId,
    layer,
    layerId,
    filePath,
    fileBucketRef, 
    setParentState, 
    setContainerState,
    updateCacheFieldData,
    identifier,
    onlyCache,
    plotName,
    smartPreCacheCallback,
  } = props;

  !onlyCache && setParentState({
    geoJsonData: null,
    markers: null,
    loadingData: true,
  });

  // console.log("Getting data for ", identifier)

  return filePath && fileBucketRef.child(filePath).getDownloadURL()
    .then(url => {
      window.fetch(url)
        .then(async res => {
          let geoJsonData = await res.clone().text();
          geoJsonData = geoJsonData && geoJsonData.replace(/\bNaN\b/g, "0.0");
          geoJsonData = geoJsonData && geoJsonData.replace(/\b-Infinity\b/g, "0.0");
          geoJsonData = geoJsonData && geoJsonData.replace(/\bInfinity\b/g, "0.0");
          geoJsonData = geoJsonData && JSON.parse(geoJsonData);

          !onlyCache && setParentState({ 
            loadingData: false,
            geoJsonData,
          });

          !onlyCache && plotName === "UpperLeft" && setContainerState({ compareUpperGeoJsonData: geoJsonData });
          !onlyCache && plotName === "LowerLeft" && setContainerState({ compareLowerGeoJsonData: geoJsonData });

          updateCacheFieldData && updateCacheFieldData(geoJsonData, null, identifier);
          // updateCacheFieldData && console.log("Fetched to cache with identifier", identifier)
          
          !onlyCache && setContainerState && setContainerState({ updatedPlotData: new Date() });
          // console.log(await res.clone().text())

          !onlyCache && smartPreCacheCallback && fieldId && layer && layerId &&
            smartPreCacheCallback(fieldId, layer, layerId);          
        })
        .catch(error => console.log("Cannot get download URL: ", error.toString()))
    })
    .catch(error => console.log("Cannot get download URL: ", error.toString()))
};


export const fetchStatJson = (props) => {
  const {
    layerId,
    filePath,
    fileBucketRef, 
    setParentState,
    updateCacheFieldData,
    identifier,
    onlyCache,
  } = props

  !onlyCache && setParentState({
    statJson: null
  });

  fileBucketRef.child(filePath).getDownloadURL()
  .then(url => {
    window.fetch(url)
      .then(async res => {
        let statJson = await res.clone().text();
        statJson = statJson && statJson.replace(/\bNaN\b/g, "0.0");
        statJson = statJson && statJson.replace(/\b-Infinity\b/g, "0.0");
        statJson = statJson && statJson.replace(/\bInfinity\b/g, "0.0");
        statJson = statJson && JSON.parse(statJson);

        !onlyCache && setParentState({ statJson });
        updateCacheFieldData && updateCacheFieldData(null, statJson, identifier.replace("_" + layerId, ""));
        // console.log(await res.clone().text())
      })
      .catch(error => console.log("Cannot get download URL: ", error.toString()))
  })
  .catch(error => console.log("Cannot get download URL: ", error.toString()))  
};