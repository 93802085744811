import React, { Component } from "react";

import {
  isLoaded,
} from "react-redux-firebase";

import {  
  Segment,
  Icon,
  Header,
  Button,
  Modal,
  Image,
} from "semantic-ui-react";

import Illustration from "@/assets/illustrations/onboarding_guide_1.png";

const INITIAL_STATE = {
  discarded: false,
  showMobileInformationModal: false,
};

class MobileAppBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  showMoreInformation = () => {
    let currentValue = this.state.showMobileInformationModal;
    this.setState({showMobileInformationModal: !currentValue});
  };

  discardBanner = () => {
    this.setState({
      discarded: true,
    });

    this.props.getUser()
      .collection("profile")
      .doc("log")
      .update({
        mobile_app_banner_discarded: true,
      });
  }  

  render() {
    const {
      db,
    } = this.props;

    const {
      discarded,
      showMobileInformationModal,
    } = this.state;

    let profileLog = db && db.profile && db.profile.log && isLoaded(db.profile.log) && db.profile.log;
    let dateLoginDesktop = profileLog && profileLog.date_latest_login_desktop;
    let dateLoginMobile = profileLog && profileLog.date_latest_login_mobile;
    let bannerDiscarded = profileLog && profileLog.mobile_app_banner_discarded;
    bannerDiscarded = (typeof(discarded) === "boolean" && discarded === true) ? true : bannerDiscarded;

    if (dateLoginDesktop && !dateLoginMobile && bannerDiscarded) {
      return (
        <div />
      )
    };

    if (dateLoginDesktop && !dateLoginMobile) {
      return (
        <React.Fragment>
          <Modal
            open={showMobileInformationModal}
            size="small"
          >
            <Modal.Header>Hämta Frejas mobilapp</Modal.Header>
            <Modal.Content
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "40%",
                }}
              >
                <Image src={Illustration} />
              </div>

              <div
                style={{
                  width: "60%",
                  marginLeft: "1em",
                }}
              >
                <p>
                  För att få ut mer av din digitala hjälpreda så rekommenderar vi dig till att ladda ned Freja till både telefonen och surfplattan. 
                </p>

                <div style={{ height: "2em" }} />

                <ul>
                  <li>Öppna Google Chrome på telefonen och gå till <b>freja.agriopt.se</b></li>
                  <li>Ladda ned sidan som en app på din hemskärm. Klicka på knappen ned för mer instruktioner för din mobil.
                    <div
                      style={{
                        marginTop: "2em",
                        marginBottom: "2em",
                        width: "70%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        className="opacityInvertedHover"
                        style={{ 
                          backgroundColor: "#F0BD38", 
                          color: "white",
                        }}                      
                        href="https://sv.androidhow.eu/google-chrome-101/google-chrome-101-sa-har-sparar-du-webbsidor-och-pwa-till-din-startsida-for-direktatkomst/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Android
                      </Button>

                      <Button 
                        className="opacityInvertedHover"
                        style={{ 
                          backgroundColor: "#F0BD38", 
                          color: "white",
                        }}                      
                        href="https://www.enkelteknik.se/lagg-till-safari-genvag-pa-hemskarmen-iphone"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Iphone
                      </Button>
                    </div>
                    </li>
                  <li>Öppna Freja-appen från din hemskärm</li>
                  <li>Logga in på Freja</li>
                </ul>
              </div>              

            </Modal.Content>
            <Modal.Actions
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div/>
              <Button 
                style={{ 
                  backgroundColor: "#6B9D9F", 
                  color: "white",
                }}
                onClick={() => this.showMoreInformation()}
              >
                Stäng
              </Button>
            </Modal.Actions>
          </Modal>

          <Segment
            style={{
              marginLeft: "16.5%",
              width: "67%",
              marginBottom: "3em",              
              padding: "1.5em",
              paddingTop: 0, 
              paddingRight: 0,
              fontWeight: "normal",
              height: "160px",
              background: "#6B9D9F",
              color: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  paddingTop: "1.5em",
                  width: "20%",
                  display: "flex",
                  justifyContent: "center"                  
                }}
              >
                <Icon 
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: -25,
                  }}
                  name="mobile alternate" 
                  size="massive"
                />
              </div>
              <div
                style={{
                  paddingTop: "1.5em",
                  width: "75%",
                  whiteSpace: "pre-line",
                }}
              >
                <Header
                  style={{
                    fontWeight: 600,
                    color: "white",
                  }}
                >
                  Freja blir bättre med mobilappen
                </Header>

                <p>
                  Mobilappen gör att du får notiser direkt i mobilen när Freja har laddat hem ny data eller har varningar om dina skiften.
                </p>
                
                <Button
                  inverted
                  style={{
                    marginTop: 20,
                  }}
                  onClick={() => this.showMoreInformation()}
                >
                  Mer information
                </Button>
              </div>
              <div
                style={{
                  width: "5%",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <Icon 
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                  }}
                  name="close"
                  onClick={() => this.discardBanner()}
                />
              </div>            
            </div>
          </Segment> 
        </React.Fragment>
      )
    };

    return ( <div /> )
  }
}

export default MobileAppBanner;
