export const getAvailableFieldIds = (db) => {
  let fieldIds = db && db.fields && Object.keys(db.fields);

  fieldIds = fieldIds && fieldIds.filter((fieldId) => {
    if (typeof(db.fields[fieldId].available_to_user) !== "boolean") {
      return true
    };

    if (typeof(db.fields[fieldId].available_to_user) === "boolean" && db.fields[fieldId].available_to_user === true) {
      return true
    };

    return false
  });

  return fieldIds;
};

export const collectionToIdArray = map => {
  let arr = [];

  if (map) {
    Object.keys(map).forEach(key => {
      arr.push({
        id: key,
        ...map[key]
      });
    });
  }

  return arr;
};

export const geoPointToPosition = geopoints => {
  if (geopoints instanceof Array) {
    return geopoints.map(
      point =>
        typeof point === "number"
          ? point
          : [
              point.latitude ? point.latitude : point[0],
              point.longitude ? point.longitude : point[1]
            ]
    );
  }
  return [geopoints.latitude, geopoints.longitude];
};

export const positionToGeoPoint = (positions, GeoPointClass) => {
  if (
    positions.length === 2 &&
    typeof positions[0] === "number" &&
    typeof positions[1] === "number"
  ) {
    return new GeoPointClass(positions[0], positions[1]);
  }
  return positions.map(x => new GeoPointClass(x[0], x[1]));
};

export const LatLngToGeoPoint = (positions, GeoPointClass) => {
  return positions.map(x => new GeoPointClass(x.lat, x.lng));
};

export const createRandomDocumentId = (length, existingIds=[]) => {
  
  let characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let output = '';
  let fieldIdExists = true;

  while (fieldIdExists) {
    output = ''

    for (let i = length; i > 0; --i) {
      output += characters[Math.floor(Math.random() * characters.length)];
    };

    fieldIdExists = existingIds ? existingIds.includes(output) : false;
  };

  return output;
}