import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";

import {
  firestoreConnect,
  isLoaded,
} from "react-redux-firebase";

import { 
  Menu, 
  Image, 
  Container,
} from "semantic-ui-react";

import imgAdmin from "@/assets/icons/menuPrimary/advisor-mode.svg";
import agriOptLogo from "@/assets/logos/white_notext.png";

import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";
import { MAIN_VIEWS } from "@/constants/views";

const KEEP_LAYER_ID_FOR_VIEWS = ["analysis", "layers", "guides", "collect"];
class MenuPrimary extends Component {

  getDb = () => this.props.firestoreData;

  changeView = (newView) => this.props.history.push(this.getLink(newView));

  getLink = (newView) => {
    const {
      match: { 
        params: {
          fieldId,
          layer,
        }
      },
    } = this.props;

    if (!newView || newView === "home") {
      return "/"
    }
    
    let checkedFieldId = (fieldId && typeof(fieldId) === "string" && fieldId.length === 20) ? fieldId : false;

    // Remember the layer and make a good switch (switching to field data)
    if (checkedFieldId && KEEP_LAYER_ID_FOR_VIEWS.includes(newView)) {
      let currentDataLayer = getCurrentDataLayer(layer);

      if (["layers", "analysis"].includes(newView) && currentDataLayer && currentDataLayer.layerConnectedWith) {
        return "/" + newView + "/" + fieldId + "/" + currentDataLayer.layerConnectedWith
      };

      if (newView === "collect") {
        return "/" + newView + "/" + fieldId + (fieldId === "farm" ? "/fieldInfo_guides" : "/fieldInfo")
      };      

      if (newView === "guides") {
        return "/" + newView + (fieldId === "farm" ? null : ("/" + fieldId))
      };  

      return "/" + newView + "/" + fieldId
      
    } else {

      return "/" + newView
    }   
  }

  render() {
    const { 
      isAdminUser,
      isAdvisorUser,
    } = this.props;

    const db = this.getDb();

    let noFields = db && db.fields && Object.keys(db.fields).length;
    noFields = db && db.fields && !isLoaded(db.fields) ? 1 : noFields;

    let userPlanType = db && db.profile &&
      db.profile.plan &&
      db.profile.plan.plan_type;

    let activeView =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.view &&
      this.props.match.params.view;     
    
    activeView = activeView ? activeView : "home";

    userPlanType = activeView !== "register" ? userPlanType : null;

    return (
      <div
        style={{
          backgroundColor: "#444444",
          position: "fixed",
          top: 0,
          height: "100vh",
          width: "85px"
        }}
      >
        <Container
          textAlign="center"
          style={{
            marginTop: "2em",
            marginBottom: "2em"
          }}
        >
          <Image
            style={{
              width: "3.5em",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={agriOptLogo}
          />
        </Container>
        
        <Menu
          secondary
          vertical
          fluid
          style={{
            paddingLeft: 10,
            backgroundColor: "#444444",
            textAlign: "center"
          }}
        >
          {userPlanType && Object.keys(MAIN_VIEWS).map(x => {
            const view = MAIN_VIEWS[x];
            let isActive = view.name === activeView || view.aliases.includes(activeView);
            isActive = (activeView === "home" && x === "home") ? true : isActive;
            
            if (!MAIN_VIEWS[x].includedInPlans.includes("all") && userPlanType && !MAIN_VIEWS[x].includedInPlans.includes(userPlanType)) {
              return null
            };

            if (MAIN_VIEWS[x].position !== "top") {
              return null
            };

            if ((!noFields || noFields === 0) && view.name !== "home") {
              return null
            }

            return (
              <Menu.Item
                key={"menu_" + view.name}
                className="menuPrimaryItem"
                style={{
                  color: isActive ? "rgb(0, 0, 0, 0.85)" : "white",
                  background: isActive ? "#F8EED0" : "transparent",
                  padding: 10,
                  marginLeft: -5,
                  marginRight: -5,
                  marginBottom: 3,
                  borderRight: isActive ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                  fontSize: "80%",
                }}
                active={isActive}
                onClick={() => this.changeView(view.name)}
              >

                <Image
                  className="menuPrimaryItemIcon"
                  centered
                  src={view.icon}
                  style={{
                    filter: isActive ?
                      "brightness(0) invert(35%)" :
                      "brightness(0) invert(85%)",
                    marginBottom: 8,                 
                    width: "50%",
                    marginLeft: "25%",
                  }}
                />

                {view.caption}
              </Menu.Item>
            );
          })}
        </Menu>

        <Menu
          secondary
          vertical
          fluid
          style={{
            position: "absolute",
            bottom: "10px",
            left: "0px",
            paddingLeft: 10,
            backgroundColor: "#444444",
            textAlign: "center"
          }}
        >
          {noFields > 0 && userPlanType && Object.keys(MAIN_VIEWS).map(x => {
            const view = MAIN_VIEWS[x];
            let isActive = view.name === activeView || view.aliases.includes(activeView);
            
            if (!MAIN_VIEWS[x].includedInPlans.includes("all") && userPlanType && !MAIN_VIEWS[x].includedInPlans.includes(userPlanType)) {
              return null
            }

            if (MAIN_VIEWS[x].position !== "bottom") {
              return null
            }

            return (
              <Menu.Item
                key={"menu_" + view.name}
                className="menuPrimaryItem"
                style={{
                  color: isActive ? "rgb(0, 0, 0, 0.85)" : "white",
                  background: isActive ? "#F8EED0" : "transparent",
                  padding: 10,
                  marginLeft: -5,
                  marginRight: -5,
                  marginBottom: 3,
                  borderRight: isActive ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                  fontSize: "80%",
                }}
                onClick={() => this.changeView(view.name)}
                active={isActive}
              >

                <Image
                  className="menuPrimaryItemIcon"
                  centered
                  src={view.icon}
                  style={{
                    filter: isActive ?
                      "brightness(0) invert(35%)" :
                      "brightness(0) invert(85%)",
                    marginBottom: 8,
                    width: "50%",
                    marginLeft: "25%",
                  }}
                />

                {view.caption}

              </Menu.Item>
            );
          })}
          
         {isAdvisorUser === true &&
            <Menu.Item
              className="menuPrimaryItem"
              style={{
                color: activeView === "advisor" ? "rgb(0, 0, 0, 0.85)" : "white",
                background: activeView === "advisor" ? "#F8EED0" : "transparent",
                padding: 10,
                marginLeft: -5,
                marginRight: -5,
                marginBottom: 0,
                borderRight: activeView === "advisor" ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                fontSize: "80%",
              }}
              active={activeView === "advisor"}
              onClick={() => this.changeView("advisor")}
            >
              <Image
                className="menuPrimaryItemIcon"
                centered
                src={imgAdmin}
                style={{
                  filter: activeView === "advisor" ?
                    "brightness(0) invert(35%)" :
                    "brightness(0) invert(85%)",
                  marginBottom: 8,                 
                  width: "50%",
                  marginLeft: "25%",               
                }}
              />
                Rådgivarläge
            </Menu.Item>
          }

          {isAdminUser === true &&
            <Menu.Item
              className="menuPrimaryItem"
              style={{
                color: activeView === "admin" ? "rgb(0, 0, 0, 0.85)" : "white",
                background: activeView === "admin" ? "#F8EED0" : "transparent",
                padding: 10,
                marginLeft: -5,
                marginRight: -5,
                marginBottom: 0,
                borderRight: activeView === "admin" ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                fontSize: "80%",
              }}
              active={activeView === "admin"}
              onClick={() => this.changeView("admin")}
            >
              <Image
                className="menuPrimaryItemIcon"
                centered
                src={imgAdmin}
                style={{
                  filter: activeView === "admin" ?
                    "brightness(0) invert(35%)" :
                    "brightness(0) invert(85%)",
                  marginBottom: 8,                 
                  width: "50%",
                  marginLeft: "25%",               
                }}
              />
              Admin
            </Menu.Item>
          }
        </Menu>        
      </div>
    );
  }
};

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fields'
    },     
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'profile'
    },   
  ]),
  withFirestoreData,
  withRouter,
)(MenuPrimary);