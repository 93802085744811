import React, { Component } from "react";

import { 
  Modal,
  Button,
} from "semantic-ui-react";

import MenuFieldList from "@/components/menus/MenuFieldList";
import UnlockFieldList from "@/components/fieldData/FieldDataUnlockFields/unlockFieldList";

import { sendFieldUnlockEmailToSupport } from "./emails";

import { toast } from "react-toastify";

const INITIAL_STATE = {
  fieldsToUnlock: [],
  fieldsToUnlockNames: {},
  activeView: 'selectFields',  
  showConfirmUnlockFieldWorkingSpinner: false,
};

class FieldDataUnlockFields extends Component {
  state = { ...INITIAL_STATE };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.open && this.props.open) {
      this.props.firstFieldIdToUnlock &&
        this.setState({
          fieldsToUnlock: [...this.state.fieldsToUnlock, this.props.firstFieldIdToUnlock]
        })
    }
  };

  toggleFieldsToUnlock = (fieldId) => {
    const {
      db,
    } = this.props;
    
    const fields = db && db.fields;

    let currentFieldsToUnlock = this.state.fieldsToUnlock;
    let currentFieldsToUnlockNames = this.state.fieldsToUnlockNames;

    if (currentFieldsToUnlock.includes(fieldId)) {
      delete currentFieldsToUnlockNames[fieldId];

      this.setState({ 
        fieldsToUnlock: currentFieldsToUnlock.filter((x) => x !== fieldId),
        fieldsToUnlockNames: currentFieldsToUnlockNames,
      });
    } else {
      let newName = fields && fields[fieldId] && fields[fieldId].name && fields[fieldId].name;
      newName = newName && newName.replace("Låst s", "S");

      this.setState({ 
        fieldsToUnlock: [...currentFieldsToUnlock, fieldId],
        fieldsToUnlockNames: {...currentFieldsToUnlockNames, [fieldId]: newName},
      });
    };
  };

  changeFieldsToUnlockNames = (fieldId, value) => {
    let currentValue = this.state.fieldsToUnlockNames;
    this.setState({ fieldsToUnlockNames: {...currentValue, [fieldId]: value }})
  };

  setNameSuggestions = () => {
    const {
      db,
    } = this.props;

    const {
      fieldsToUnlock,
    } = this.state;

    let newFieldNames = {};

    fieldsToUnlock && fieldsToUnlock.forEach((fieldId, idx) => {
      let fieldName = fieldId && db && db.fields && db.fields[fieldId] && db.fields[fieldId].name;
      fieldName = fieldName ? fieldName.replace("Låst skifte", "Skifte") : "Skifte #" + (idx + 1).toFixed(0);

      newFieldNames = { ...newFieldNames, [fieldId]: fieldName };
    });

    this.setState({
      fieldsToUnlockNames: newFieldNames,
    })
  };

  unlockFields = () => {
    const {
      db,
      userId,
      getUser
    } = this.props;

    const {
      fieldsToUnlock,
      fieldsToUnlockNames,
    } = this.state;

    this.setState({ showConfirmUnlockFieldWorkingSpinner: true });
    
    let dbRef = getUser();
    
    fieldsToUnlock && fieldsToUnlock.forEach((fieldId) => {
      let fieldRef = dbRef.collection("fields").doc(fieldId);

      let payload = {
        available_to_user: true,
        date_unlocked: new Date(),
      }

      payload = fieldsToUnlockNames && fieldsToUnlockNames[fieldId] ? 
          {...payload, name: fieldsToUnlockNames[fieldId] } : payload

      fieldRef.update(payload);
    });
    
    dbRef.collection("notifications").add({
      date: new Date(),
      type: "newFields",
      data_type: "unlocked",
      field_names: fieldsToUnlock,
    });

    sendFieldUnlockEmailToSupport (db, userId, fieldsToUnlock, fieldsToUnlockNames);

    toast.success("Du har nu låst upp: " + fieldsToUnlock.length.toFixed(0) +  ' st skiften. Freja påbörjar arbetet med att samla ihop data för de nya skiftena. Detta arbete tar upp till 24 timmar.',
    {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });

    this.onClose();
  };

  onClose = () => {
    this.setState({
      showConfirmUnlockFieldModal: false,
      showConfirmUnlockFieldWorkingSpinner: false,
      fieldsToUnlock: [],
    });
    
    this.props.onClose()
  };

  render() {
    const {
      db,
      userId,
      open,
    } = this.props;   

    const {
      activeView,
      fieldsToUnlock,
      fieldsToUnlockNames,
      showConfirmUnlockFieldWorkingSpinner
    } = this.state;

    const fields = db && db.fields;

    let totalAreaToUnlock = fieldsToUnlock && fields && 
      fieldsToUnlock.reduce((old, fieldId) => 
        fields[fieldId] && fields[fieldId].field_size ? 
        old + parseFloat(fields[fieldId].field_size) : 
        old, 
      0.0);

    if (!open) {
      return null
    }

    return (
      <React.Fragment>
        <Modal 
          open={open && activeView === "selectFields"}
          size="small"
        >
          <Modal.Header
            style={{
              fontWeight: "500"
            }}
          >
            Lås upp skiften
          </Modal.Header>

          <Modal.Content
            style={{
              height: "60vh",
              overflowY: "scroll",
            }}
          >
            <p
              style={{
                marginBottom: "1em"
              }}
            >
              Markera de skiften nedan som du vill låsa upp och använda i Freja. 
              I nästa steg för du chans att döpa om dem till något som är enklare att komma ihåg. 
              Längst ned summerar vi den totala nya arealen som du vill öppna upp. 
            </p>
            
            <MenuFieldList
              db={db}
              userId={userId}
              fieldId={null}
              selectedFieldIds={fieldsToUnlock}
              openUnlockField={this.toggleFieldsToUnlock}
              compact={true}
              hideFarmCard={true}
              hideLockedFields={false}
              hideUnLockedFields={true}
            />     
          </Modal.Content>

          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}          
          >
            <Button 
              onClick={() => this.onClose()}
            >
              Avbryt
            </Button>     

            <p
              style={{
                marginTop: 10,
                fontSize: "110%",
              }}
            >
              Du har markerat totalt <strong>{totalAreaToUnlock && totalAreaToUnlock.toFixed(0)} ha</strong> att låsa upp.
            </p>       

            <Button 
              primary
              disabled={!totalAreaToUnlock}
              onClick={() => {
                this.setNameSuggestions();
                this.setState({ activeView: "changeNames" })
              }}
            >
              Nästa
            </Button>
          </Modal.Actions> 
        </Modal>

        <Modal 
          open={open && activeView === "changeNames"}
          size="small"
        >
          <Modal.Header
            style={{
              fontWeight: "500"
            }}
          >
            Namnge dina nya skiften
          </Modal.Header>

          <Modal.Content
            style={{
              height: "60vh",
              overflowY: "scroll",
            }}
          >
            <p
              style={{
                marginBottom: "1em",
              }}
            >
              Markera de skiften nedan som du vill låsa upp och använda i Freja. 
              I nästa steg för du chans att döpa om dem till något som är enklare att komma ihåg. 
              Längst ned summerar vi den totala nya arealen som du vill öppna upp. 
            </p>
            
            <UnlockFieldList
              db={db}
              userId={userId}
              fieldIds={fieldsToUnlock}
              fieldsToUnlockNames={fieldsToUnlockNames}
              changeFieldsToUnlockNames={this.changeFieldsToUnlockNames}
            />     
          </Modal.Content>

          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}          
          >
            <Button 
              onClick={() => this.setState({ activeView: "selectFields" })}
            >
              Tillbaka
            </Button>     

            <p
              style={{
                marginTop: 10,
                fontSize: "110%",
              }}
            >
              Du har markerat totalt <strong>{totalAreaToUnlock && totalAreaToUnlock.toFixed(0)} ha</strong> att låsa upp.
            </p>       

            <Button 
              primary
              disabled={!totalAreaToUnlock}
              onClick={() => this.setState({ activeView: "confirm" })}
            >
              Nästa
            </Button>
          </Modal.Actions> 
        </Modal>        

        <Modal 
          open={open && activeView === "confirm"}
          size="tiny"
        >
          <Modal.Header
            style={{
              fontWeight: 500
            }}
          >
            Bekräfta lås upp skiften
          </Modal.Header>

          <Modal.Content>
            <p>
              Du kommer nu låsa upp skiften med en total areal på <strong>{totalAreaToUnlock && totalAreaToUnlock.toFixed(0)} ha</strong>. 
              Vi kommer att fakturera dig för denna extra areal enligt det överenskommet pris för din plan.
            </p>              
          </Modal.Content>

          <Modal.Actions 
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}              
          >
            <Button 
              disabled={showConfirmUnlockFieldWorkingSpinner}
              onClick={() => this.onClose()}
            >
              Avbryt
            </Button>
                        
            <Button 
              primary
              loading={showConfirmUnlockFieldWorkingSpinner}
              disabled={showConfirmUnlockFieldWorkingSpinner}
              onClick={() => this.unlockFields()}
            >
              {showConfirmUnlockFieldWorkingSpinner ? " Låser upp..." : "Lås upp"}
            </Button>
          </Modal.Actions> 
        </Modal>   
      </React.Fragment>       
    )
  }
};

export default FieldDataUnlockFields;
