import React, { Component } from "react"

import {
  Header,
  Segment,
  Button,
  Input,
  Modal,
} from "semantic-ui-react";

import { toast } from "react-toastify";
import { DateInput } from 'semantic-ui-calendar-react';

import { cloudFunctions } from "@";
import { CROP_CODES, CROP_QUALITY } from "@/constants/cropCodes";

class FieldManagementHarvest extends Component {
  state = {
    mode: null,
    editYear: null,
    editCropCode: null,
    editCropQuality: null,
    editCropVariety: null,
    editCropHarvestDate: null,
    editCropHarvestAmount: '',
    editCropHarvestProtein: '',
    yearToRemove: null,
  };

  updateHarvestInformation = (year, payload) => {
    const {
      db,
      userId,
      fieldId,
      getUser,
    } = this.props;

    let dbRef = getUser().collection("fieldsInfo").doc(fieldId);
    let field = db && db.fields[fieldId] && db.fields[fieldId];
    let fieldInfo = db && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];

    let currentKeysInFieldInfo = fieldInfo && Object.keys(fieldInfo).map((x) => x);

    // Iterate over all fields in payload
    Object.keys(payload).filter((key) => payload[key]).forEach((key) => {
      if (currentKeysInFieldInfo && currentKeysInFieldInfo.includes(key)) {
        dbRef.update({ [key + "." + year]: payload[key] });
      } else {
        dbRef.update({ [key]: { [year]: payload[key] } });
      };
    });

    let text = "Freja har uppdaterat informationen om skörden för: " + year + " för skiftet: " + (field && field.name && field.name) + ".";

    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });

    payload && Object.keys(payload) && Object.keys(payload).includes("crop_code") &&
      cloudFunctions.httpsCallable('updatedCropCodes')(
        {
          userId: userId,
          fieldId: fieldId,
          year: year,
        }
      );
  };

  onChangeField = (data, onlyNumbers = false) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.setState({
      [data.name]: value,
    });
  };

  onResetState = () => {
    this.setState({
      mode: null,
      editYear: null,
      editCropCode: null,
      editCropQuality: null,
      editCropVariety: null,
      editCropHarvestDate: null,
      editCropHarvestAmount: '',
      editCropHarvestProtein: '',
    });
  }

  onToogleEditHarvest = (year, mode) => {
    const {
      db,
      fieldId,
    } = this.props;

    const {
      editYear,
      editCropCode,
      editCropQuality,
      editCropVariety,
      editCropHarvestDate,
      editCropHarvestAmount,
      editCropHarvestProtein,
    } = this.state;

    let fieldInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId];

    if (!year) {
      this.updateHarvestInformation(editYear,
        {
          crop_code: editCropCode,
          crop_quality: editCropQuality,
          crop_variety: editCropVariety,
          harvest_dates: editCropHarvestDate,
          harvest_yields: editCropHarvestAmount,
          harvest_proteins: editCropHarvestProtein,
        });

      this.onResetState()

    } else {

      this.setState({
        mode: mode ? mode : "edit",
        editYear: year,
        editCropCode: fieldInfo && fieldInfo.crop_code && fieldInfo.crop_code[year] ? fieldInfo.crop_code[year] : '0',
        editCropQuality: fieldInfo && fieldInfo.crop_quality && fieldInfo.crop_quality[year] ? fieldInfo.crop_quality[year] : '',
        editCropVariety: fieldInfo && fieldInfo.crop_variety && fieldInfo.crop_variety[year] ? fieldInfo.crop_variety[year] : '',
        editCropHarvestAmount: fieldInfo && fieldInfo.harvest_yields && fieldInfo.harvest_yields[year] ? fieldInfo.harvest_yields[year] : '',
        editCropHarvestDate: fieldInfo && fieldInfo.harvest_dates && fieldInfo.harvest_dates[year] ? fieldInfo.harvest_dates[year] : year + "-08-15",
        editCropHarvestProtein: fieldInfo && fieldInfo.harvest_proteins && fieldInfo.harvest_proteins[year] ? fieldInfo.harvest_proteins[year] : '',
      });

    };
  };

  onClickAddHarvest = () => {
    const {
      editYear,
      editCropHarvestAmount,
      editCropHarvestDate,
      editCropHarvestProtein,
    } = this.state;

    this.updateHarvestInformation(editYear,
      {
        harvest_yields: editCropHarvestAmount,
        harvest_dates: editCropHarvestDate,
        harvest_proteins: editCropHarvestProtein,
      });

    this.onResetState()
  };

  removeCrop = () => {
    const {
      yearToRemove,
    } = this.state;

    this.setState({
      yearToRemove: null,
    });

    this.updateHarvestInformation(yearToRemove,
      {
        harvest_yields: null,
        harvest_dates: null,
        harvest_proteins: null,
      });
  };

  onToggleRemoveHarvest = (year) => {
    this.setState({
      mode: null,
      yearToRemove: year,
    });
  };

  getHarvestRow = (year, idx, compact = false) => {
    const {
      db,
      fieldId,
    } = this.props;

    let field = db && db.fields && db.fields[fieldId];
    let fieldInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let fieldSize = field && field.field_size;
    let currentCropCode = fieldInfo && fieldInfo.crop_code && fieldInfo.crop_code[year];
    let cropHarvestAmount = fieldInfo && fieldInfo.harvest_yields && fieldInfo.harvest_yields[year] ? fieldInfo.harvest_yields[year] : '';
    let cropHarvestDate = fieldInfo && fieldInfo.harvest_dates && fieldInfo.harvest_dates[year] ? fieldInfo.harvest_dates[year] : year + "-08-15";
    let cropHarvestProtein = fieldInfo && fieldInfo.harvest_proteins && fieldInfo.harvest_proteins[year] ? fieldInfo.harvest_proteins[year] : '';

    let cropHarvestAmountPerHectar = cropHarvestAmount && fieldSize && (parseFloat(cropHarvestAmount) / parseFloat(fieldSize)).toFixed(1);

    if (!currentCropCode || (compact && !cropHarvestDate)) {
      return this.getEmptyHarvestRow(year, idx, compact)
    };

    let cropText = currentCropCode && CROP_CODES.find(x => x.value === currentCropCode);
    cropText = (cropText && cropText.shorttext) ? cropText.shorttext : "Okänd gröda";

    let cropQuality = fieldInfo && fieldInfo.crop_quality && fieldInfo.crop_quality[year] ? fieldInfo.crop_quality[year] : '';

    let cropQualityText = currentCropCode && CROP_QUALITY.find(x => x.value === currentCropCode);
    cropQualityText = cropQualityText && cropQualityText.values && cropQualityText.values.find((x) => x.value === cropQuality);
    cropQualityText = cropQualityText && cropQualityText.text;

    let cropVariety = fieldInfo && fieldInfo.crop_variety && fieldInfo.crop_variety[year] ? fieldInfo.crop_variety[year] : '';

    let hasCompleteInformation = cropHarvestAmount && cropHarvestDate;

    if (compact) {
      return (
        <Segment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.25em",
            }}
          >
            <Header
              as="h4"
              style={{
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Skörd
            </Header>

            <p
              className="textLink"
              onClick={() => this.onToogleEditHarvest(year, "edit")}
            >
              Redigera
            </p>
          </div>
          <p style={{ margin: 0 }}>Datum: {cropHarvestDate ? cropHarvestDate : "saknas."}</p>

          {cropHarvestAmount && <p style={{ margin: 0 }}>Total skörd: {cropHarvestAmount} ton {cropHarvestAmount ? " (" + cropHarvestAmountPerHectar + " ton/ha)" : ""} </p>}

          {cropHarvestProtein && <p style={{ margin: 0 }}>Proteinhalt: {cropHarvestProtein} %</p>}
        </Segment>
      );
    } else {
      return (
        <Segment
          key={'harvestingInfoRow' + year}
          style={{
            padding: "1.5em",
            width: "70%",
            marginLeft: "15%",
            borderColor: hasCompleteInformation ? "#4C8440" : "#FBBD08",
            cursor: "pointer",
          }}
          onClick={() => this.onToogleEditHarvest(year, "edit")}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                marginTop: 15,
                fontSize: "140%",
                width: "10%",
                opacity: 0.7,
              }}
            >
              {year}
            </div>

            <div
              style={{
                width: "90%"
              }}
            >
              <div
                style={{
                  fontSize: "170%",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                {cropText ? cropText : "-"}
              </div>

              <div
                style={{
                  fontSize: "140%",
                  marginTop: 0,
                }}
              >
                {cropVariety ? "Sorten " + cropVariety : "Okänd sort"}{cropQualityText ? " odlas som " + cropQualityText.toLowerCase() + "" : ""}
              </div>

              <div
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                  fontWeight: 500,
                  fontSize: "120%",
                  opacity: cropHarvestDate ? null : 0.7,
                }}
              >
                Datum för skörd: {cropHarvestDate ? cropHarvestDate : "saknas."}
              </div>

              <div
                style={{
                  marginBottom: 0,
                  fontWeight: 500,
                  fontSize: "120%",
                  opacity: cropHarvestAmount ? null : 0.7,
                }}
              >
                Total skörd: {cropHarvestAmount ? cropHarvestAmount + " ton" : "saknas."}
                {cropHarvestAmount ? " (" + cropHarvestAmountPerHectar + " ton/ha)" : ""}
              </div>

              <div
                style={{
                  marginBottom: 0,
                  fontWeight: 500,
                  fontSize: "120%",
                  opacity: cropHarvestProtein ? null : 0.7,
                }}
              >
                Proteinhalt: {cropHarvestProtein ? cropHarvestProtein + "%" : "saknas."}
              </div>
            </div>
          </div>
        </Segment>
      );
    };
  };

  getEmptyHarvestRow = (year, idx, compact) => {
    if (compact) {
      return (
        <Segment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.25em",
            }}
          >
            <Header
              as="h4"
              style={{
                fontWeight: 500,
              }}
            >
              Skörd
            </Header>

            <p
              className="textLink"
              onClick={() => this.onToogleEditHarvest(year, "add")}
            >
              Lägg till
            </p>
          </div>

          <p style={{ color: "darkgrey" }}>
            Freja saknar information om skörden för denna säsong.
          </p>
        </Segment>
      );
    } else {
      return (
        <Segment
          key={'harvestingInfoRow' + year}
          style={{
            padding: "1.5em",
            width: "70%",
            marginLeft: "15%",
            borderStyle: "dashed",
            backgroundColor: "transparent",
          }}
          secondary
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: "140%",
                width: "10%"
              }}
            >
              {year}
            </div>

            <div
              style={{
                width: "90%"
              }}
            >
              <div
                style={{
                  marginTop: 4,
                  fontSize: "110%",
                }}
              >
                Information saknas! Lägg till säsongens gröda under "sådd" innan du kan fortsätta.
              </div>
            </div>
          </div>
        </Segment>
      );
    }
  };

  getEditHarvestRow = () => {

    const {
      editYear,
      editCropHarvestAmount,
      editCropHarvestDate,
      editCropHarvestProtein,
    } = this.state;

    return (
      <Segment basic>
        <Header
          as="h5"
          style={{
            marginBottom: 5,
            fontWeight: 600
          }}
        >
          Datum för skörd
        </Header>

        <DateInput
          fluid
          name='editCropHarvestDate'
          minDate={(parseInt(editYear) - 1).toFixed(0) + '-08-01'}
          maxDate={editYear + '-06-01'}
          dateFormat="YYYY-MM-DD"
          value={editCropHarvestDate ? editCropHarvestDate : editYear + '-03-01'}
          initialDate={editYear + '-03-01'}
          onChange={(event, data) => this.onChangeField(data)}
          localization='sv'
        />

        <Header
          as="h5"
          style={{
            marginTop: 12,
            marginBottom: 5,
            fontWeight: 600
          }}
        >
          Total skörd (ton, torrvikt)
        </Header>

        <Input
          fluid
          name='editCropHarvestAmount'
          value={editCropHarvestAmount ? editCropHarvestAmount : ''}
          onChange={(event, data) => this.onChangeField(data, true)}
        />

        <Header
          as="h5"
          style={{
            marginTop: 12,
            marginBottom: 5,
            fontWeight: 600
          }}
        >
          Proteinhalt (procent)
        </Header>

        <Input
          fluid
          name='editCropHarvestProtein'
          value={editCropHarvestProtein ? editCropHarvestProtein : ''}
          onChange={(event, data) => this.onChangeField(data, true)}
        />

      </Segment>
    )
  };

  render() {
    const {
      db,
      fieldId,
      widget,
      widgetYear,
    } = this.props;

    const {
      mode,
      editYear,
      yearToRemove,
      editCropHarvestAmount,
      editCropHarvestDate,
    } = this.state;

    let availableYearList = ["2017", "2018", "2019", "2020", "2021", "2022"];

    let yieldMapYears = db && fieldId && db["fieldsYieldMapsProcessed_" + fieldId];
    yieldMapYears = yieldMapYears && yieldMapYears.rawdata_harvest_maps_geojson;
    yieldMapYears = yieldMapYears && Object.keys(yieldMapYears).map((x) => x);

    yieldMapYears && yieldMapYears.forEach((x) => {
      availableYearList = !availableYearList.includes(x) ? [...availableYearList, x] : availableYearList;
    });

    availableYearList = availableYearList && availableYearList.sort();

    let modalData =
      <React.Fragment>
        <Modal
          open={["add", "edit"].includes(mode)}
          size="small"
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            {mode === "edit" ? "Redigera skördeinformation för " + editYear : "Lägg till skördeinformation för " + editYear}
          </Modal.Header>

          <Modal.Content>
            <Modal.Description>
              {this.getEditHarvestRow()}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              style={{
                backgroundColor: "#868D8E",
                color: "white",
              }}
              onClick={() => this.onResetState()}
            >
              Avbryt
            </Button>

            {mode === "edit" &&
              <Button
                basic
                style={{
                  color: "#868D8E",
                }}
                onClick={() => this.onToggleRemoveHarvest(editYear)}
              >
                Ta bort
              </Button>
            }

            <Button
              style={{
                backgroundColor: "#6B9D9F",
                color: "white",
              }}
              disabled={mode === "add" && (!editCropHarvestAmount || !editCropHarvestDate)}
              onClick={mode === "add" ? () => this.onClickAddHarvest() : () => this.onToogleEditHarvest()}
            >
              Spara
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal size="small" open={yearToRemove !== null}>
          <Modal.Header>Ta bort skörd</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>Vill du ta bort skördeinformationen för detta skifte under {yearToRemove}?</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              style={{
                backgroundColor: "#868D8E",
                color: "white",
              }}
              onClick={() => this.setState({ yearToRemove: null })}
            >
              Avbryt
            </Button>

            <Button
              style={{
                backgroundColor: "#C36126",
                color: "white",
              }}
              onClick={() => this.removeCrop()}
            >
              Ta bort
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>

    if (widget) {
      return (
        <React.Fragment>
          {modalData && modalData}
          {widgetYear && this.getHarvestRow(widgetYear, widgetYear, true)}
        </React.Fragment>
      )

    } else {

      return (
        <React.Fragment>
          {modalData && modalData}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5em",
            }}
          >
            <div
              style={{
                width: "70%",
              }}
            >
              {availableYearList && availableYearList.reverse().map((year, idx) => this.getHarvestRow(year, idx, false))}
            </div>

            <div
              style={{
                maxWidth: "30%",
              }}
            >
              <Segment
                style={{
                  marginTop: 0,
                  padding: "2em",
                  minWidth: "250px",
                }}
              >
                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Skörd
                </Header>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  I denna vy kan du lägga till och redigera information om säsongens skörd.
                  Detta innefattar information om invägd vikt, proteinhalt och datum för skörd.
                  Denna information används till att kalibrera skördekartor eller skapa skördekartor from satellitbilder.
                </p>

                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Nuvarande information
                </Header>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  Till vänster kan du se den information om skörden som du har angivit för detta skifte.
                  Du kan enkelt redigera information genom att klicka på knappen "Redigera" till höger.
                </p>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  År som helt eller delvis saknar information men som har data i form av grönmassamätningar eller uppladdade skördekartor är markerade med gult.
                  År med komplett information är markerade med grönt.
                </p>
              </Segment>
            </div>
          </div>
        </React.Fragment>
      );
    };
  }
}

export default FieldManagementHarvest;
