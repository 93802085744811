export const COLOR_LIMITS_CORRELATION = [-0.1, 0.9, 1.9, 2.9, 3.9, 4.9];
export const COLOR_LIMITS_CLUSTERING = [-1.9, 0.9, 1.9, 2.9, 3.9, 4.9, 5.9, 6.9];
export const COLOR_LIMITS_SEG_MEDIAN = [-20.0, -0.01, 0.01, 20.0];
export const COLOR_LIMITS_SEG_VAR = [-20.0, 0.9, 1.9, 2.9, 3.9];

export const COLOR_SCALE_DIFF = ['#B22222', '#d07a7a', '#F7F7F7', '#93b58c', '#4C8440'];
export const COLOR_SCALE_HOTSPOT = ['#B22222', '#d07a7a', '#F7F7F7', '#93b58c', '#4C8440'];
export const COLOR_SCALE_PERCENT = ['#B22222', '#d07a7a', '#F7F7F7', '#93b58c', '#4C8440'];

export const COLOR_SCALE_CORRELATION = ["#4C8440", "#6B9D9F", "#BF8136", "#A67BB2", "#767676"];
export const COLOR_SCALE_SEG_MEDIAN = ["#B22222", "#949494", "#4C8440"];
export const COLOR_SCALE_SEG_VAR = ["#949494", "#B22222", "#4C8440", "#F0BD38"];

export const HIST_LABELS_CLUSTERING = {
  "0": "Grupp 1",
  "1": "Grupp 2",
  "2": "Grupp 3",
  "3": "Grupp 4",
  "4": "Grupp 5",
  "5": "Grupp 6",
  "6": "Grupp 7",
  "7": "Grupp 8",
  "8": "Grupp 9",
};

export const CLASS_MAP2 = {
  "0": 'För hög nivå',
  "1": 'God nivå',
};

export const CLASS_MAP4 = {
  "0": 'Brist',
  "1": 'Gränsnivå',
  "2": 'God nivå',
};

export const CLASS_MAP5 = {
  "0": 'Brist (Klass I)',
  "1": 'Brist (Klass II)',
  "2": 'Lämplig nivå (Klass III)',
  "3": 'Goda reserver (Klass IV)',
  "4": 'Mycket goda reserver (Klass V)',
};

export const CLASS_MAP6 = {
  "0": 'Brist (Klass I)',
  "1": 'Brist (Klass II)',
  "2": 'Lämplig nivå (Klass III)',
  "3": 'Goda reserver (Klass IV A)',
  "4": 'Goda reserver (Klass IV B)',
  "5": 'Mycket goda reserver (Klass V)',
};