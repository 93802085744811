import React from "react";
import { Header, Popup, Icon } from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import { getCurrentColorRGBA } from "@/components/fieldData/FieldDataColorScales";

export const HarvestClusterAnalysis = ({
  layer,
  layerId,
  clusterYears,
  statJson,
  selectedGroup,
  colorScales,
  legendVisible,
  toggleLegendVisible,
}) => {
  
  let clusterCenters = statJson && layerId && statJson.clustering && {...statJson.clustering[layerId]};
  clusterCenters = clusterCenters && Object.keys(clusterCenters).map((x) => clusterCenters[x]);

  const plotData = {
    labels: layerId && clusterYears && [...clusterYears[layerId]].sort(),
    datasets:
    clusterCenters &&
    clusterCenters.map((x, index) => ({
        label: "Område" + (index + 1).toFixed(0),
        data: x,
        fill: false,
        borderColor: getCurrentColorRGBA(colorScales, index, selectedGroup),
        backgroundColor: getCurrentColorRGBA(colorScales, index, selectedGroup)
      }))
  };

    return (
      <div>
        <div
          style={{
            marginTop: 0,
            marginBottom: 0,
            padding: 0,
            display: "flex",
            justifyContent: "space-between",                
          }}
        >
          <Header 
            as="h4"
            style={{
              marginTop: 0,
              color: 'white',
              fontWeight: 500,
            }}        
          >
            {layer === "yieldAnalysis_clustering" ? "Skördeutveckling " : "Vattenhaltsutveckling"}
            <Popup
              position="top center"
              trigger={
                <Icon 
                  name="question circle" 
                  style={{ 
                    marginLeft: '0.25em', 
                    fontSize: '80%', 
                    lineHeight: '80%'
                  }} 
                />              
              }
              style={{
                zIndex: '1000',
              }}
              content={layer === "yieldAnalysis_clustering" ? "Denna karta visar hur typskörden för varje grupp varierar från år till år. Skörden hos de flesta punkterna som tillhör varje grupp varierar på samma sätt." : "Denna karta visar hur den typiska vattenhalten för varje grupp varierar från år till år. Vattenhalten hos de flesta punkterna som tillhör varje grupp varierar på samma sätt."}
            />
          </Header>
          <Icon 
            style={{
              color: 'white',
              right: 0,
            }}
            name={legendVisible ? 'chevron down' : 'chevron up'} 
            onClick={() => toggleLegendVisible()}
          />       
        </div>    
        {legendVisible &&         
          <article
            className="canvas-container"
            style={{
              width: "100%",
              height: "30vh",
              background: "transparent",
              padding: "1em"
            }}
          >
            <Line 
              data={plotData} 
              options={{ 
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                    }
                },
                legend: {
                  display: false,
                },
                scales: {
                  yAxes: [{
                    ticks: { fontColor: "white" },
                    gridLines: { color: "#868D8E" },
                  }],
                  xAxes: [{
                    ticks: { fontColor: "white" },
                    gridLines: { color: "#868D8E" },
                  }],
                }
              }}  
            />            
          </article>
        }
      </div>
    );
};
