import React, { Component } from "react";

import { 
  Header, 
  Table,
  Input,
  Button,
} from "semantic-ui-react";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideMapPreview from "@/components/guide/GuideMapPreview";
import GuideSegment from "@/components/guide/GuideSegment";

import GuideSegmentAdvancedMode from "@/components/guide/GuideSegmentAdvancedMode";
import GuideSegmentCompound from "@/components/guide/GuideSegmentCompound";
import GuideSegmentSplitRation from "@/components/guide/GuideSegmentSplitRation";

import { checkForHumusInformation } from "@/helpers/guides";

import {
  MUD_TYPES, 
  ORGANIC_TYPES,
  PH_GOAL_PER_SOILTYPE,
  LIME_PER_HALF_PH_PER_SOILTYPE,
} from "./constants";

const INITIAL_STATE = {};

class NitrogenNeed extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickNextStep = () => {
    const {
      completedSteps,
    } = this.props;

    this.props.setParentState({
      completedSteps: [...completedSteps, "split"],
    });
    
    this.setDefaultSettings("aim");
    this.setDefaultSettings("need");

    this.props.onClickNextStep();
  };

  onChange = (e, data, variableName, onlyNumbers=true) => {
    let currentValue = this.state[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: {...currentValue, [data.name]: value },
    });
  }; 

  onChangeStruct = (e, data, variableName, onlyNumbers=true) => {
    let currentValue = this.props[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: {...currentValue, [data.name]: value },
    });
  };

  setOpenSegment = (newSegment) => {
    (this.props.openSegment === newSegment) && this.props.setParentState({ openSegment: null });
    (this.props.openSegment !== newSegment) && this.props.setParentState({ openSegment: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedSteps, 
    } = this.props;

    !completedSteps.includes(currentSegment) && this.props.setParentState({
      completedSteps: [...completedSteps, currentSegment],
    });

    this.setDefaultSettings(newSegment, false);
    !completedSteps.includes(newSegment) && this.setOpenSegment(newSegment);
    completedSteps.includes(newSegment) && this.setOpenSegment(currentSegment);
  }

  setDefaultSettings = (newSegment, forceUpdate=false) => {
    const {
      db,
      selectedFieldIds,
      manualHumus,
      goalPhPerSoilType,
      rationLimePerHalfPhPerSoilType,
    } = this.props;

    switch(newSegment) {

      case "aim":
        let defaultGoalPhPerSoilType = {};

        PH_GOAL_PER_SOILTYPE && Object.keys(PH_GOAL_PER_SOILTYPE).forEach((x) => {
          defaultGoalPhPerSoilType = {...defaultGoalPhPerSoilType, [x]: PH_GOAL_PER_SOILTYPE[x]};
        });

        !forceUpdate && this.props.setParentState({ goalPhPerSoilType: {...defaultGoalPhPerSoilType, ...goalPhPerSoilType }});
        forceUpdate && this.props.setParentState({ goalPhPerSoilType: {...defaultGoalPhPerSoilType }});
      break;          


      case "need":
        let defaultRationPerHalfPhPerSoilType = {};

        LIME_PER_HALF_PH_PER_SOILTYPE && Object.keys(LIME_PER_HALF_PH_PER_SOILTYPE).forEach((x) => {
          defaultRationPerHalfPhPerSoilType = {...defaultRationPerHalfPhPerSoilType, [x]: LIME_PER_HALF_PH_PER_SOILTYPE[x]};
        });    

        !forceUpdate && this.props.setParentState({ rationLimePerHalfPhPerSoilType: {...defaultRationPerHalfPhPerSoilType, ...rationLimePerHalfPhPerSoilType }});
        forceUpdate && this.props.setParentState({ rationLimePerHalfPhPerSoilType: {...defaultRationPerHalfPhPerSoilType }});
      break;          

      case "humus":
        let fieldsSoilMaps = db && db.fieldsSoilMapsProcessed;
        let newManualHummus = {};
    
        selectedFieldIds && selectedFieldIds.forEach((x) => {
          let allKeys = [];
          let fieldInfoDb = db && x && db.fieldsInfo && db.fieldsInfo[x];
          let humusContent = fieldInfoDb && fieldInfoDb.average_humus_content ? fieldInfoDb.average_humus_content : "0";
    
          let fieldSoilMaps = fieldsSoilMaps && x && fieldsSoilMaps[x];
          let uploadedKeys = fieldSoilMaps && fieldSoilMaps.rawdata_uploaded_soilmap_available_keys;
          
          uploadedKeys && Object.keys(uploadedKeys).forEach((year) => {
            allKeys = [...allKeys, ...uploadedKeys[year]];
          });
    
          if (!allKeys.includes("organic")) {
            newManualHummus = {...newManualHummus, [x]: humusContent};
          };
        });

        !forceUpdate && this.props.setParentState({ manualHumus: {...newManualHummus, ...manualHumus }});
        forceUpdate && this.props.setParentState({ manualHumus: newManualHummus });
      break;          

      default:
        break;

    }
  }

  render() {

    const {
      db,
      fileBucketRef,
      userId,
      selectedFieldIds,
      rationSize,
      goalPhPerSoilType,
      rationLimePerHalfPhPerSoilType,
      manualHumus,      
      openSegment,
      completedSteps, 
      guideMode,    
    } = this.props;
    
    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],      
      next: [
        {
          caption: "Gå vidare",
          hidden: completedSteps.length < 5,
          onClick: this.onClickNextStep,
        }
      ],
    };
    
    let fieldIdsMissingHumus = checkForHumusInformation(db, selectedFieldIds);
    let needsHumusInformation = fieldIdsMissingHumus && fieldIdsMissingHumus.length > 0;

    let missingPhGoal = [];
    let missingRationLime = [];

    ORGANIC_TYPES && ORGANIC_TYPES.forEach((x) => {
      MUD_TYPES && MUD_TYPES.forEach((y) => {
        if (!goalPhPerSoilType[x.key + y.key] || goalPhPerSoilType[x.key + y.key].length === 0) {
          missingPhGoal = [...missingPhGoal, x.key + y.key]
        };

        if (!rationLimePerHalfPhPerSoilType[x.key + y.key] || rationLimePerHalfPhPerSoilType[x.key + y.key].length === 0) {
          missingRationLime = [...missingRationLime, x.key + y.key]
        };        
      });
    });

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegmentAdvancedMode
          open={openSegment === "guideMode"}
          complete={completedSteps && completedSteps.includes("guideMode")}
          setParentState={this.props.setParentState}
          onClickOpenSegment={() => this.setOpenSegment("guideMode")}
          onClickNextStep={() => this.gotoNextStep("guideMode", "compound")}
        />

        <GuideSegmentCompound
          {...this.props}
          open={openSegment === "compound"}
          disabled={openSegment !== "compound" && !completedSteps.includes("compound")}
          complete={completedSteps && completedSteps.includes("compound")}
          onClickOpenSegment={() => this.setOpenSegment("compound")}
          onClickNextStep={() => {
            (needsHumusInformation || (guideMode && guideMode === "advanced")) && this.gotoNextStep("compound", "humus");
            (!needsHumusInformation && (guideMode && guideMode !== "advanced")) && this.gotoNextStep("compound", "humus");
            (!needsHumusInformation && (guideMode && guideMode !== "advanced")) && this.gotoNextStep("humus", "aim");
          }}             
          caption="Kalkningsmedel"
          question="Vill du ange kalkningsmedel?"
          description="Välj den typ av kalkningsmedel som du kommer att använda i din pH-kalkning."          
          compoundName="kalcium"
          compoundType="Ca"
        />
          
        {(needsHumusInformation || (guideMode && guideMode === "advanced")) &&
          <GuideSegment
            caption="Mullhalt"
            open={openSegment === "humus"}
            complete={completedSteps && completedSteps.includes("humus")}
            onClickOpenSegment={() => this.setOpenSegment("humus")}
            onClickNextStep={() => this.gotoNextStep("humus", "aim")}
          >
            <p
              style={{
                whiteSpace: "pre-line",
                width: "80%",
              }}
            >
              Freja hämtar mullhalten från dina uppladdade markkarteringar.
              Saknar ditt skifte denna information så behöver du komplettera detta genom att fylla genomsnittlig mullhalt nedanför.
            </p>

            <p
              style={{
                whiteSpace: "pre-line",
                width: "80%",
              }}
            >
              <strong> Observera: kalkningsbehovet ökar mycket kraftigt med ökad mullhalt.</strong>
            </p>            
            
            <Table 
              celled 
              striped 
              columns="3"
              style={{
                marginTop: "4em",
                maxWidth: "100%",
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center">Skifte</Table.HeaderCell>
                  <Table.HeaderCell>Mullhalt (%)</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {fieldIdsMissingHumus && fieldIdsMissingHumus.map((x) => {
                  let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;

                  return (
                    <Table.Row
                      key={"humusDropdown_" + x}
                    >
                      <Table.Cell textAlign="center">{fieldName && fieldName}</Table.Cell>

                      <Table.Cell>
                        <Input
                          fluid
                          name={x}
                          onChange={(e, d) => this.onChangeStruct(e, d, "manualHumus", true)}
                          error={!manualHumus[x] || manualHumus[x].length === 0}
                          value={manualHumus[x]}
                        />
                      </Table.Cell>
                      <Table.Cell />
                    </Table.Row>
                  )})}

                  {selectedFieldIds && fieldIdsMissingHumus && selectedFieldIds
                    .filter((x) => !fieldIdsMissingHumus.includes(x))
                    .map((x) => {
                      let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;

                      return (
                        <Table.Row
                          key={"humusExistsRow_" + x}
                        >
                          <Table.Cell textAlign="center">{fieldName && fieldName}</Table.Cell>
                          <Table.Cell>Mullkarta finns i uppladdad markkartering.</Table.Cell>
                          <Table.Cell textAlign="center">
                            <GuideMapPreview
                              db={db}
                              fileBucketRef={fileBucketRef}
                              userId={userId}
                              fieldId={x}
                              layer="soilMapUploaded_organic"
                              layerId="latest"
                              popupText="Klicka för att visa mullhaltskartan."
                            />
                          </Table.Cell>
                        </Table.Row>
                  )})}
                </Table.Body>
              </Table>
          </GuideSegment>   
        }

        <GuideSegment
          skipSimpleMode={guideMode && guideMode === "advanced"}
          caption="Önskat pH-värde"
          simpleModeQuestion="Vill du göra förändringar i Jordbruksverkets rekommendationer för lämpligt pH-värde?"
          open={openSegment === "aim"}
          simpleModeOpenText="Justera"
          complete={completedSteps && completedSteps.includes("aim")}
          onClickOpenSegment={() => this.setOpenSegment("aim")}
          simpleModeOnSkip={() => {
            this.gotoNextStep("aim", "need") 
            this.setDefaultSettings("aim", true);
          }}               
          onClickNextStep={() => this.gotoNextStep("aim", "need")}
          disableNextStep={missingPhGoal && missingPhGoal.length > 0}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: "4em",
              width: "80%",
              fontSize: "110%",
            }}
          >
            Freja använder Jordbruksverkets rekommendationer för lämpligt pH-värde beserat på vilken ler- och mullhalt dina skiften har. Du kan justera dessa rekommendationer i tabellen nedanför.
          </p>          
          
          <Table celled striped columns="7">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Mull-/lerhalt</Table.HeaderCell>
                  {MUD_TYPES && MUD_TYPES.map((x) => (
                    <Table.HeaderCell textAlign="center">
                      {x.caption}
                      <p
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        {x.min.toFixed(0) + " - " + x.max.toFixed(0) + "%"}
                      </p>
                    </Table.HeaderCell>
                  ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {ORGANIC_TYPES && ORGANIC_TYPES.map((x) => (
                <Table.Row
                  key={"tableRow_" + x.key}
                >
                  <Table.HeaderCell 
                    textAlign="center"
                  >
                    {x.caption}
                    <p
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        {x.min.toFixed(0) + " - " + x.max.toFixed(0) + "%"}
                      </p>                    
                  </Table.HeaderCell>

                  {MUD_TYPES && MUD_TYPES.map((y) => (
                    <Table.Cell>
                      <Input 
                        key={"Input_" + x.key + y.key}
                        fluid 
                        value={goalPhPerSoilType[x.key + y.key]}
                        error={!goalPhPerSoilType[x.key + y.key] || goalPhPerSoilType[x.key + y.key].length === 0}
                        name={x.key + y.key}
                        onChange={(e, d) => this.onChangeStruct(e, d, "goalPhPerSoilType")}
                      />
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>   
        </GuideSegment>

        <GuideSegment
          skipSimpleMode={guideMode && guideMode === "advanced"}
          caption="Kalkningsbehov"
          simpleModeQuestion="Vill du göra förändringar i Jordbruksverkets rekommendationer för kalkningsbehovet?"
          open={openSegment === "need"}
          complete={completedSteps && completedSteps.includes("need")}
          onClickOpenSegment={() => this.setOpenSegment("need")}
          onClickNextStep={() => this.gotoNextStep("need", "split")}
          simpleModeOnSkip={() => {
            this.gotoNextStep("need", "split") 
            this.setDefaultSettings("need", true);
          }} 
          disableNextStep={missingRationLime && missingRationLime.length > 0}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: "4em",
              width: "80%",
              fontSize: "110%",
            }}
          >
            Freja använder Jordbruksverkets rekommendationer för kalkningsbehovet (kg/ha) för att öka pH-värdet med 0.5 enheter. Dessa beseras på vilken ler- och mullhalt dina skiften har. Du kan justera dessa rekommendationer i tabellen nedanför.
          </p>         
          
          <Table celled striped columns="7">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Mull-/lerhalt</Table.HeaderCell>
                  {MUD_TYPES && MUD_TYPES.map((x) => (
                    <Table.HeaderCell textAlign="center">
                      {x.caption}
                      <p
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        {x.min.toFixed(0) + " - " + x.max.toFixed(0) + "%"}
                      </p>
                    </Table.HeaderCell>
                  ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {ORGANIC_TYPES && ORGANIC_TYPES.map((x) => (
                <Table.Row
                  key={"tableRow_" + x.key}
                >
                  <Table.HeaderCell 
                    textAlign="center"
                  >
                    {x.caption}
                    <p
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        {x.min.toFixed(0) + " - " + x.max.toFixed(0) + "%"}
                      </p>                    
                  </Table.HeaderCell>

                  {MUD_TYPES && MUD_TYPES.map((y) => (
                    <Table.Cell>
                      <Input 
                        key={"Input_" + x.key + y.key}
                        fluid 
                        value={rationLimePerHalfPhPerSoilType[x.key + y.key]}
                        error={!rationLimePerHalfPhPerSoilType[x.key + y.key] || rationLimePerHalfPhPerSoilType[x.key + y.key].length === 0}
                        name={x.key + y.key}
                        onChange={(e, d) => this.onChangeStruct(e, d, "rationLimePerHalfPhPerSoilType")}
                      />
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table> 
        </GuideSegment>

        <GuideSegmentSplitRation
          guideMode={guideMode}
          simpleModeQuestion="Vill du dela upp din pH-kalkning i flera omgångar?"
          simpleModeDescription='Välj "Ange" om du vill dela upp kalkningen i flera givor.'
          open={openSegment === "split"}
          complete={completedSteps && completedSteps.includes("split")}
          onClickOpenSegment={() => this.setOpenSegment("split")}
          onClickNextStep={() => this.onClickNextStep()}  
          setParentState={this.props.setParentState}
          rationSize={rationSize}
        />
      </div>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h3"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Vad baseras styrfilen på?
        </Header>
        
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
         Frejas styrfilsförslag bygger på välkända riktlinjer från Jordbruksverket som baseras på omfattade växtodlingsförsök. Läs mer genom att klicka på knapparna nedanför.
        </p> 

        <Button 
          fluid
          primary
          href="https://jordbruksverket.se/vaxter/odling/vaxtnaring/kalkning"
          target="_blank" 
          rel="noopener noreferrer"
          style={{
            marginTop: "1em",
            backgroundColor: "#BF8136",
            color: "white",
          }}
        >
          Jordbruksverkets rekommendationer
        </Button>

        <Button
          style={{
            marginTop: 5,
            backgroundColor: "#BF8136",
            color: "white",
          }}
          fluid
          primary
          href="http://greppa.nu/atgarder/kalka-for-battre-markstruktur.html?I_praktiken=Markf%C3%B6rb%C3%A4ttra"
          target="_blank" 
          rel="noopener noreferrer"
        >
          Greppa näringens rekommendationer
        </Button>               
      </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader="Uppskatta kalkningsbehovet"
        mainColumnBody="För att kunna beräkna kalkningsbehovet behöver du fylla i informationen nedanför. Svara på alla frågor för att gå vidare till nästa steg."
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default NitrogenNeed;