export const NUTRIENTS_TO_SKIP = [ 'NO3', 'NH4', 'PC' ];

export const NUTRIENTS_SHOW_IN_SUMMARY = [ 'N', 'P', 'K', 'S' ];

export const YARA_FERTILIZERS = [
    {
        text: "YaraLiva Nitrabor",
        key: "yaralivanitrabor",
        value: "yaralivanitrabor",
        nutrients: {
            N: 15.4,
            NO3: 14.1,
            NH4: 1.3,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 18.3,
            B: 0.3,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraLiva Kalksalpeter",
        key: "yaralivakalksalpeter",
        value: "yaralivakalksalpeter",
        nutrients: {
            N: 15.5,
            NO3: 14.4,
            NH4: 1.1,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 18.8,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraLiva Sulfan",
        key: "yaralivasulfan",
        value: "yaralivasulfan",
        nutrients: {
            N: 24.0,
            NO3: 12.0,
            NH4: 12.0,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.5,
            Na: 0.0,
            S: 6.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraLiva Axan",
        key: "yaralivaaxan",
        value: "yaralivaaxan",
        nutrients: {
            N: 27.0,
            NO3: 13.5,
            NH4: 13.5,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.6,
            Na: 0.0,
            S: 3.7,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraBela Suprasalpeter N27",
        key: "yarabelasuprasalpetern27",
        value: "yarabelasuprasalpetern27",
        nutrients: {
            N: 27.0,
            NO3: 13.5,
            NH4: 13.5,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 2.4,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "Yara Superfosfat  P20",
        key: "yarasuperfosfatp20",
        value: "yarasuperfosfatp20",
        nutrients: {
            N: 0.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 20.0,
            P: 19.3,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 1.2,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "Yara Polysulphate",
        key: "yarapolysulphate",
        value: "yarapolysulphate",
        nutrients: {
            N: 0.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 0.0,
            K: 11.6,
            Mg: 3.6,
            Na: 0.0,
            S: 19.2,
            Ca: 12.2,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "Yara PK 11-21",
        key: "yarapk1121",
        value: "yarapk1121",
        nutrients: {
            N: 0.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 11.0,
            P: 10.3,
            K: 21.0,
            Mg: 0.0,
            Na: 0.0,
            S: 1.5,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila 22-0-12",
        key: "yaramila22012",
        value: "yaramila22012",
        nutrients: {
            N: 22.0,
            NO3: 10.0,
            NH4: 12.0,
            PC: 0.0,
            P: 0.0,
            K: 11.6,
            Mg: 0.7,
            Na: 0.0,
            S: 3.0,
            Ca: 0.0,
            B: 0.05,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.1,
        },
    },
    {
        text: "YaraMila 20-5-10",
        key: "yaramila20510",
        value: "yaramila20510",
        nutrients: {
            N: 19.6,
            NO3: 7.7,
            NH4: 11.9,
            PC: 4.6,
            P: 2.8,
            K: 9.6,
            Mg: 0.0,
            Na: 0.0,
            S: 3.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila 21-3-10",
        key: "yaramila21310",
        value: "yaramila21310",
        nutrients: {
            N: 20.6,
            NO3: 9.0,
            NH4: 11.6,
            PC: 2.6,
            P: 1.9,
            K: 9.6,
            Mg: 1.0,
            Na: 0.0,
            S: 3.6,
            Ca: 0.0,
            B: 0.02,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila 21-4-7",
        key: "yaramila2147",
        value: "yaramila2147",
        nutrients: {
            N: 20.6,
            NO3: 7.9,
            NH4: 12.7,
            PC: 3.6,
            P: 0.0,
            K: 6.6,
            Mg: 0.9,
            Na: 0.0,
            S: 3.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila 22-6-6",
        key: "yaramila2266",
        value: "yaramila2266",
        nutrients: {
            N: 21.6,
            NO3: 8.4,
            NH4: 13.2,
            PC: 5.9,
            P: 5.0,
            K: 5.8,
            Mg: 0.6,
            Na: 0.0,
            S: 3.0,
            Ca: 0.0,
            B: 0.02,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila 23-3-7",
        key: "yaramila2337",
        value: "yaramila2337",
        nutrients: {
            N: 22.6,
            NO3: 10.2,
            NH4: 12.4,
            PC: 2.6,
            P: 1.8,
            K: 6.6,
            Mg: 0.4,
            Na: 0.0,
            S: 2.5,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila 24-4-5",
        key: "yaramila2445",
        value: "yaramila2445",
        nutrients: {
            N: 23.6,
            NO3: 10.3,
            NH4: 13.3,
            PC: 3.6,
            P: 2.6,
            K: 4.6,
            Mg: 0.5,
            Na: 0.0,
            S: 3.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila 26-3-5",
        key: "yaramila2635",
        value: "yaramila2635",
        nutrients: {
            N: 25.6,
            NO3: 11.4,
            NH4: 14.2,
            PC: 2.6,
            P: 1.6,
            K: 4.6,
            Mg: 0.0,
            Na: 0.0,
            S: 2.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila 27-2-3",
        key: "yaramila2723",
        value: "yaramila2723",
        nutrients: {
            N: 27.0,
            NO3: 12.2,
            NH4: 14.8,
            PC: 2.0,
            P: 1.2,
            K: 3.0,
            Mg: 0.0,
            Na: 0.0,
            S: 2.5,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila 27-3-3",
        key: "yaramila2733",
        value: "yaramila2733",
        nutrients: {
            N: 26.6,
            NO3: 11.9,
            NH4: 14.7,
            PC: 2.6,
            P: 1.8,
            K: 2.6,
            Mg: 0.5,
            Na: 0.0,
            S: 3.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila Host 8-10.5-20 Mn",
        key: "yaramilahost810.520mn",
        value: "yaramilahost810.520mn",
        nutrients: {
            N: 8.0,
            NO3: 0.8,
            NH4: 7.2,
            PC: 10.5,
            P: 9.2,
            K: 19.9,
            Mg: 0.0,
            Na: 0.0,
            S: 2.0,
            Ca: 0.0,
            B: 0.01,
            Fe: 0.1,
            Cu: 0.0,
            Mn: 0.5,
            Mo: 0.0,
            Zn: 0.01,
        },
    },
    {
        text: "Yara Balans 26-3-4",
        key: "yarabalans2634",
        value: "yarabalans2634",
        nutrients: {
            N: 25.6,
            NO3: 11.3,
            NH4: 14.3,
            PC: 2.6,
            P: 1.8,
            K: 3.6,
            Mg: 0.6,
            Na: 0.0,
            S: 3.6,
            Ca: 0.0,
            B: 0.0,
            Fe: 3.6,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila Raps",
        key: "yaramilaraps",
        value: "yaramilaraps",
        nutrients: {
            N: 17.0,
            NO3: 6.8,
            NH4: 10.2,
            PC: 4.6,
            P: 3.3,
            K: 10.0,
            Mg: 1.2,
            Na: 0.0,
            S: 4.0,
            Ca: 0.0,
            B: 0.15,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila ProBeta",
        key: "yaramilaprobeta",
        value: "yaramilaprobeta",
        nutrients: {
            N: 15.0,
            NO3: 6.3,
            NH4: 8.7,
            PC: 3.6,
            P: 3.1,
            K: 8.0,
            Mg: 0.9,
            Na: 8.0,
            S: 2.0,
            Ca: 0.0,
            B: 0.1,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.6,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila ProMagna 8-5-19",
        key: "yaramilapromagna8519",
        value: "yaramilapromagna8519",
        nutrients: {
            N: 8.0,
            NO3: 2.6,
            NH4: 5.4,
            PC: 5.0,
            P: 4.0,
            K: 19.0,
            Mg: 2.5,
            Na: 0.0,
            S: 11.7,
            Ca: 0.0,
            B: 0.05,
            Fe: 0.0,
            Cu: 0.05,
            Mn: 0.25,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "YaraMila ProMagna 11-5-18",
        key: "yaramilapromagna11518",
        value: "yaramilapromagna11518",
        nutrients: {
            N: 11.0,
            NO3: 4.4,
            NH4: 6.6,
            PC: 4.6,
            P: 3.5,
            K: 17.6,
            Mg: 1.6,
            Na: 0.0,
            S: 10.0,
            Ca: 0.0,
            B: 0.05,
            Fe: 0.08,
            Cu: 0.03,
            Mn: 0.25,
            Mo: 0.002,
            Zn: 0.04,
        },
    },
    {
        text: "Yara Urea",
        key: "yaraurea",
        value: "yaraurea",
        nutrients: {
            N: 46.2,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "Yara Unika Calcium",
        key: "yaraunikacalcium",
        value: "yaraunikacalcium",
        nutrients: {
            N: 14.2,
            NO3: 13.7,
            NH4: 0.5,
            PC: 0.0,
            P: 0.0,
            K: 19.9,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 8.6,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "Yara Plus",
        key: "yaraplus",
        value: "yaraplus",
        nutrients: {
            N: 12.0,
            NO3: 12.0,
            NH4: 0.0,
            PC: 0.0,
            P: 0.0,
            K: 38.2,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
];

export const OTHER_FERTILIZERS = [
    {
        text: "N 27",
        key: "n27",
        value: "n27",
        nutrients: {
            N: 27.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "NS 26-14",
        key: "ns2614",
        value: "ns2614",
        nutrients: {
            N: 26.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 14.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },    
    {
        text: "NS 27-4",
        key: "ns274",
        value: "ns274",
        nutrients: {
            N: 27.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 4.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "N 34",
        key: "n34",
        value: "n34",
        nutrients: {
            N: 34.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "PK 11-21",
        key: "pl1121",
        value: "pk1121",
        nutrients: {
            N: 0.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 11.0,
            K: 21.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "PK 13-13",
        key: "pk1313",
        value: "pk1313",
        nutrients: {
            N: 0.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 13.0,
            K: 13.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "PK 7-25",
        key: "pk725",
        value: "pk725",
        nutrients: {
            N: 0.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 7.0,
            K: 25.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "NP 12-23",
        key: "np1223",
        value: "np1223",
        nutrients: {
            N: 12.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 23.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "NPK 20-4-8+3 S",
        key: "npk20483s",
        value: "npk20483s",
        nutrients: {
            N: 20.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 4.0,
            K: 8.0,
            Mg: 0.0,
            Na: 0.0,
            S: 3.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "NPK 21-3-10",
        key: "npk21310",
        value: "npk21310",
        nutrients: {
            N: 21.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 3.0,
            K: 10.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },    
    {
        text: "Flex NP 16-1",
        key: "np161",
        value: "np161",
        nutrients: {
            N: 16.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 1.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "Flex NP 18-1",
        key: "np181",
        value: "np181",
        nutrients: {
            N: 18.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 1.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "Flex NPK 20-2-0",
        key: "npk2020",
        value: "npk2020",
        nutrients: {
            N: 20.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 2.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 0.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },
    {
        text: "Flex NS 30-2",
        key: "ns302",
        value: "ns302",
        nutrients: {
            N: 30.0,
            NO3: 0.0,
            NH4: 0.0,
            PC: 0.0,
            P: 0.0,
            K: 0.0,
            Mg: 0.0,
            Na: 0.0,
            S: 2.0,
            Ca: 0.0,
            B: 0.0,
            Fe: 0.0,
            Cu: 0.0,
            Mn: 0.0,
            Mo: 0.0,
            Zn: 0.0,
        },
    },      
];

export const LIMESTONE_TYPES = [
    {
      text: "Kalksten (45%)",
      key: "limestone1",
      value: "limestone1",
      nutrients: { Ca: "45"},
    },
    {
      text: "Dolomit (48%)",
      key: "dolomite",
      value: "dolomite",
      nutrients: { Ca: "48"},
    },
    {
      text: "Bränd kalk (85%)",
      key: "limestone2",
      value: "limestone2",
      nutrients: { Ca: "85"},
    },
    {
      text: "Släckt kalk (65%)",
      key: "limestone3",
      value: "limestone3",
      nutrients: { Ca: "65"},
    },
    {
      text: "Silikatkalker (42%)",
      key: "silikatlimestone",
      value: "silikatlimestone",
      nutrients: { Ca: "42"},
    },
    {
      text: "Sockerbrukskalk (37%)",
      key: "sugarlimestone",
      value: "sugarlimestone",
      nutrients: { Ca: "37"},
    },
    {
      text: "Mesakalk (51%)",
      key: "mesalimestone",
      value: "mesalimestone",
      nutrients: { Ca: "51"},
    },
];

export const NUTRIENT_FERTILIZERS = [...YARA_FERTILIZERS, ...OTHER_FERTILIZERS];