import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import { 
  Button,
  Image,
  Header,
  Modal,
  Loader,
} from "semantic-ui-react";

import { LatLngToGeoPoint, createRandomDocumentId } from "@/helpers/db";
import { sendNewFieldsEmailToSupport } from "@/helpers/emails";
import { computeAreaInPolygon } from "@/helpers/maps";
import { parseSamApplication, saveSamApplication } from "@/helpers/sam";

import GuidesTemplate from "@/containers/Guides/GuidesTemplate";
import MenuGuide from "@/components/guide/GuideMenu";

import AddField from '@/components/guides/addNewFieldsV1/addField';
import Summary from '@/components/guides/addNewFieldsV1/summary';

import illustrationDone from "@/assets/illustrations/done.png";
import illustrationError from "@/assets/illustrations/error.png";

const GUIDE_URL = "guidesAddNewFieldsV1";

const INITIAL_STATE = {
  samApplications: [],
  pickedFiles: null,
  collectDataError: '',
  openSegment: 'source',
  completedSteps: [],
  fieldSource: null,
  fields: [],
  fieldsToAdd: [],
  fieldIdsAdded: [],
  fieldIdsRemoved: [],
  existingFieldsSamIds: [],
  fieldIdAlreadyExists: [],
  showSmallFields: false,
  isParsingFile: false,
  showDoneModal: false,
  showErrorModal: false,
  firebaseErrorMessage: null,
};
class AddNewFieldsV1 extends GuidesTemplate {

  state = INITIAL_STATE;

  componentDidMount = () => {
    this.getOldSamApplicationFields();
    this.getSamApplications(this.state.showSmallFields);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.firestoreData.fields !== this.props.firestoreData.fields) {
      this.getOldSamApplicationFields();
    };

    if (prevProps.firestoreData.profile !== this.props.firestoreData.profile) {
      this.getSamApplications(this.state.showSmallFields);
    };
  };

  onSubmit = () => {
    const {
      userId,
    } = this.props;

    const {
      fields,
      fieldsToAdd,
      fieldIdsAdded,
    } = this.state;

    this.setState({ showSubmitLoader: true });

    let allPromises = [];

    // Get database information
    let db = this.getDb();
    let dbRef = this.getUser();

    let isAddingMode = db && db.fields && Object.keys(db.fields).length > 0 ? true : false;
    let existingFieldIds = db && db.fields && Object.keys(db.fields);

    let isDemoPlan = db && 
      db.profile && 
      db.profile.plan && 
      db.profile.plan.plan_type &&
      db.profile.plan.plan_type === "demo";

    // Save user information to database if no previous fields have been added
    if (!isAddingMode) {
      let payload = {
        profile: {
          log: {
            has_created_farm: true,
            date_farm_created: new Date(),
          },
        },
        settings: {
          notifications: {
            show_guides: true,
            show_reminders: true,
            show_new_data: true,
            show_warnings: true,
          },
          tutorials: {
            show_tutorials: true,    
          },
          view: {
            beta_mode: false,
            developer_mode: false,
            marker_scale: 1.0,
            show_areal_map: true,
            show_missing_data: true,
          }
        },
      };

      payload && Object.keys(payload).forEach((colKey) => {
        payload[colKey] && Object.keys(payload[colKey]).forEach((docKey) => {
          allPromises.push(this.getUser().collection(colKey).doc(docKey).set(payload[colKey][docKey], { merge: true }))
        });
      });
    };   

    // Save each field to database
    let newFieldIds = fields && fields.map((x) => createRandomDocumentId(20, existingFieldIds));

    fields && fields.forEach((field, idx) => {
      let newField = fieldIdsAdded.includes(field.id) && fieldsToAdd.find((x) => x.id === field.id);

      if (!fieldIdsAdded.includes(field.id) && parseFloat(field.area) < 4.0) {
        return null;
      };

      let firestorePosition = LatLngToGeoPoint(
        field.position,
        this.props.firestore.GeoPoint,
      );

      let firestorePositionHoles = {} 
      field.positionHoles && field.positionHoles.forEach((x, index) => (
        firestorePositionHoles = {...firestorePositionHoles, [index]: LatLngToGeoPoint(x, this.props.firestore.GeoPoint)}
      ));   
      
      let firestorePositionMainBlock = field.positionMainBlock && LatLngToGeoPoint(
        field.positionMainBlock,
        this.props.firestore.GeoPoint,
      );

      let fieldInfo = {
        crop_code: newField.cropCode ? newField.cropCode : {},
        crop_variety: newField.cropVariety ? newField.cropVariety : {},
      };

      let fieldData = {
        sam_id: field && field.id ? field.id : '',
        sam_block_id: field && field.blockId ? parseInt(field.blockId) : 0,
        sam_block_sub_id: field && field.blockSubId ? parseInt(field.blockSubId) : 0,
        name: newField ? newField.name : ("Låst skifte #" + (idx + 1).toFixed(0)),
        field_size: field && field.area ? parseFloat(field.area) : 0.0,
        position: firestorePosition ? firestorePosition : [],
        position_holes: firestorePositionHoles ? firestorePositionHoles : {},
        position_main_block: firestorePositionMainBlock ? firestorePositionMainBlock : [],
        date_created: new Date(),
        available_to_user : newField ? true : false,
        disabled: (isDemoPlan && !newField) ? true : false,
      };

      let fieldId = newFieldIds[idx];
      allPromises.push(dbRef.collection("fields").doc(fieldId).set(fieldData))
      allPromises.push(dbRef.collection("fieldsInfo").doc(fieldId).set(fieldInfo))
    }); 

    Promise.all(allPromises)
      .then((res) => {
        sendNewFieldsEmailToSupport(db, userId, fieldsToAdd, isAddingMode);

        this.setState({ 
          showDoneModal: true,
          showSubmitLoader: false,
        });
      })
      .catch((error) => {
        this.setState({ 
          showErrorModal: true,
          showSubmitLoader: false,
          firebaseErrorMessage: error.toString(),
        })
      })
  };

  getSamApplications = () => {
    let samApplicationsInfo = [];

    const db = this.getDb();
    const samApplications = db && db.profile && db.profile.sam_applications;

    samApplications && Object.keys(samApplications).forEach((key) => {
      let fileInfo = samApplications && samApplications[key];

      let fileName = fileInfo && fileInfo.original_file_name;
      let filePath = fileInfo && fileInfo.file_path;
      let dateUploaded = fileInfo && fileInfo.date_uploaded && fileInfo.date_uploaded.toDate();
      
      samApplicationsInfo = [...samApplicationsInfo, 
        {
          fileName,
          filePath,
          dateUploaded,
        }
      ];
    });

    this.setState({ samApplications: samApplicationsInfo })
  };   

  getOldSamApplicationFields = () => {
    const {
      existingFieldsSamIds,
    } = this.state;

    const db = this.getDb();
    const fields = db && isLoaded(db) && db.fields;

    // Check if there are any existing fields and save their SAM ids to avoid duplicates
    let newFieldsSamIds = [];

    fields && Object.keys(fields).forEach(fieldId => {     
      let newSamId = fields && fields[fieldId];
      newSamId = newSamId && newSamId['samID'] ? newSamId['samID'] : newSamId;
      newSamId = newSamId && newSamId['samBlockId'] ? newSamId['samBlockId'] : newSamId;

      newFieldsSamIds = newSamId ? [...newFieldsSamIds, newSamId] : newFieldsSamIds;
    });

    newFieldsSamIds && this.setState({ existingFieldsSamIds: [...existingFieldsSamIds, newFieldsSamIds] }); 
  };

  onUploadFiles = (pickedFiles, dataType) => {
    if (pickedFiles.length === 0) {
      this.setState({ 
        collectDataError: "Inga filer med rätt filformat hittades." 
      });
      return;
    };

    this.setState({ 
      isParsingFile: true,
      collectDataError: null,
      openSegment: 'samApplicationSelectFields',
      completedSteps: [...this.state.completedSteps, "samApplication"]
     });

     saveSamApplication(this.props, pickedFiles[0])
     parseSamApplication({...this.props, setParentState: this.setParentState}, pickedFiles[0], true)
  }; 

  addFieldManually = (positions) => {
    const {
      fields,
      fieldsToAdd,
      fieldIdsAdded,
    } = this.state;

    let id = "ownField_" + (+new Date()).toFixed(0);
    let index = fields ? fields.length + 1 : 1;
    let area = positions && computeAreaInPolygon(positions);

    let newEntry = {
      name: "Eget skifte #" + index.toFixed(0),
      index,
      blockId: null,
      blockSubId: null,
      id,
      area: area,
      position: positions,
      positionHoles: [],
      positionMainBlock: positions,
      cropCode: {},
      cropText: {},
    };

    this.setState({ fields: [...fields, newEntry] });
    this.setState({ fieldsToAdd: [...fieldsToAdd, newEntry] });
    this.setState({ fieldIdsAdded: [...fieldIdsAdded, id] });
  }

  addField = (field) => {
    const {
      fieldsToAdd,
      fieldIdsAdded,
    } = this.state;

    this.setState({ 
      fieldsToAdd: [...fieldsToAdd, field],
      fieldIdsAdded: [...fieldIdsAdded, field.id],
    });
  };

  removeField = (fieldId) => {
    const {
      fieldIdsAdded,
      fieldsToAdd,
    } = this.state;

    this.setState({ 
      fieldIdsAdded: fieldIdsAdded && fieldIdsAdded.filter((x) => x !== fieldId),
      fieldsToAdd: fieldsToAdd && fieldsToAdd.filter((x) => x.id !== fieldId),
    });
  };  

  toggleShowSmallFields = () => {
    let currentValue = this.state.showSmallFields;
    this.setState({ showSmallFields: !currentValue });
  };  

  showSubmitLoader = () => (
    <Modal 
      dimmer='inverted' 
      open={true}
    >
      <Loader 
        size='massive'
      >
        Lägger till nya skiften...
      </Loader>
    </Modal>
  );

  getDoneModal = (open, noFields) => (
    <Modal
      open={open}
      size="small"
    >
      <Modal.Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Image
            src={illustrationDone}
            size="medium"
          />

          <div 
            style={{
              marginTop: "4em",
              marginLeft: "2em",
            }}
          >
            <Header 
              as="h3"
              style={{
                fontWeight: 500,
              }}
            >
              Du har lagt till {noFields.toFixed(0)} nya skiften!
            </Header>

            <p>
             Freja påbörjar nu arbetet med att samla ihop information om dina nya skiften.
            </p>

            <p>
             Arbetet kan ta upp till 24 timmar och Freja kommer att skapa en notis som du kan se i vyn "Min gård" när arbetet är klart.
            </p>            
          </div>

        </div>
      </Modal.Content>

      <Modal.Actions
        style={{ 
          display: "flex-end",
          justifyContent: "space-between"
        }}               
      >
        <Button 
          primary
          onClick={() => this.props.history.push("/")}
        >
          Stäng
        </Button>
      </Modal.Actions>
    </Modal>  
  )

  getErrorModal  = (open, errorMessage) => (
    <Modal
      open={open}
      size="small"
    >
      <Modal.Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Image
            src={illustrationError}
            size="medium"
          />

          <div 
            style={{
              marginTop: "1em",
              marginLeft: "2em",
            }}
          >
            <Header 
              as="h3"
              style={{
                fontWeight: 500,
              }}
            >
              Något gick fel
            </Header>

            <p>
              Freja kunde inte lägga till dina nya skiften. 
              Vänligen försök senare eller kontakta supporten på <a href="mailto:support@agriopt.se">support@agriopt.se</a> om problemet kvarstår.
            </p>            

            <Header 
              as="h4"
              style={{
                fontWeight: 500,
              }}
            >
              Felmeddelande
            </Header>

            <p>{errorMessage && errorMessage}</p>
          </div>

        </div>
      </Modal.Content>

      <Modal.Actions
        style={{ 
          display: "flex-end",
          justifyContent: "space-between"
        }}               
      >
        <Button 
          primary
          onClick={() => this.setState({ showErrorModal: false })}
        >
          Stäng
        </Button>
      </Modal.Actions>
    </Modal>      
  );

  render() {
    const { 
      match: {
        params: {
          layer,
        }
      },
      userId,
    } = this.props;

    const {
      fieldIdsAdded,
      showSubmitLoader,
      showDoneModal,
      showErrorModal,
      firebaseErrorMessage,
    } = this.state;

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    let isAddingMode = db && db.fields && Object.keys(db.fields).length > 0 ? true : false;
    
    let fieldId = null;

    let menuItems = [
      {
        name: "addField",
        href: "",
        onClick : () => {},
        disabled: true,
        caption: "Lägg till skiften",
        description: "",
        active: false,
      },      
      {
        name: "summary",
        href: "",
        onClick : () => {},
        disabled: true,
        caption: "Sammanställning",
        description: "",
        active: layer === "summary",
      },      
    ];

    let commonProps = {
      slideDirection: this.state.slideDirection,
      params: this.props.match.params,
      firestore: this.props.firestore,
      db,
      getUser: this.getUser,
      userId,
      fileBucketRef,
      showFullGrid: false,
      setParentState: this.setParentState,
      currentMenuItem: menuItems.find((x) => x.active),
    };

    return (
      <React.Fragment>
        {showSubmitLoader && this.showSubmitLoader()}
        {this.getDoneModal(showDoneModal, fieldIdsAdded && fieldIdsAdded.length)}
        {this.getErrorModal(showErrorModal, firebaseErrorMessage)}

        <MenuGuide 
          db={db}
          fieldId={fieldId}
          menuItems={menuItems} 
        />    

        {(!layer || layer === "addField") &&
          <AddField
            {...this.state}
            {...commonProps}
            addField={this.addField}
            removeField={this.removeField}
            onUploadFiles={this.onUploadFiles}
            toggleShowSmallFields={this.toggleShowSmallFields}
            addFieldManually={this.addFieldManually}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "summary", false)}
          />
        }     

        {layer === "summary" &&
          <Summary
            {...this.state}
            {...commonProps}
            isAddingMode={isAddingMode}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "addField", false)}
            onClickNextStep={() => this.onSubmit()}
    
          />
        }                                
      </React.Fragment>
    )
  }
}

const withFirebaseData = connect(store => {
  return {
    firebaseData: store.firebase
  };
});

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fields'
    },        
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'profile'
    },    
  ]),
  withFirebaseData,
  withFirestoreData,
  withRouter
)(AddNewFieldsV1);