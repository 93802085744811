import React, { Component } from "react"

import { 
  Header, 
  Segment,
  Input,
  Modal,
  Button,
  Icon,
  Table,
} from "semantic-ui-react";

import { toast } from "react-toastify";

import moment from 'moment';

import { hasLayerData, getAvailableLayerIds, getLayerStatus } from "@/helpers/dataFetcher";

import FieldCartoon from "@/components/misc/FieldCartoon";

const LAYERS_TO_SHOW = [
  {
      caption: "Bladyta", 
      noLayersLayer: "trueColor",
      noLayersUnit: "datum",
      rawDataLayer: "trueColor",
      processedDataLayer: "lai",
      analysedDataLayer: "laiAnalysis",
  },
  {
      caption: "Höjddata", 
      noLayersLayer: "elevationMap",
      noLayersUnit: "källor",
      rawDataLayer: "elevationMap",
      processedDataLayer: "elevationMap",
      analysedDataLayer: null,
  },
  {
      caption: "Grönmassa", 
      noLayersLayer: "trueColor",
      noLayersUnit: "datum",
      rawDataLayer: "trueColor",
      processedDataLayer: "greenmass",
      analysedDataLayer: "greenmassAnalysis",
  },
  {
      caption: "Fuktighet", 
      noLayersLayer: "trueColor",
      noLayersUnit: "datum",
      rawDataLayer: "trueColor",
      processedDataLayer: "moisture",
      analysedDataLayer: "moistureAnalysis"
  },
  {
      caption: "Mognadsgrad", 
      noLayersLayer: "trueColor",
      noLayersUnit: "datum",
      rawDataLayer: "trueColor",
      processedDataLayer: "psri",
      analysedDataLayer: "psriAnalysis"
  },                          
  {
      caption: "Digitala åkermarkskartan (ler-, sand-, silthalt)", 
      noLayersLayer: "soilMapSweden",
      noLayersUnit: "år",
      rawDataLayer: "soilMapSweden",
      processedDataLayer: "soilMapSweden",
      analysedDataLayer: null,
  },
  {
      caption: "Markkarteringar", 
      noLayersLayer: "soilMapUploaded",
      noLayersUnit: "år",
      rawDataLayer: "soilMapUploaded",
      processedDataLayer: "soilMapUploaded",
      analysedDataLayer: "soilMapAnalysis",
  },
  {
      caption: "Skördekartor", 
      noLayersLayer: "yieldMap",
      noLayersUnit: "år",
      rawDataLayer: "yieldMap",
      processedDataLayer: "yieldMap",
      analysedDataLayer: "yieldAnalysis",
  },
];

class FieldManagementGeneral extends Component {
  state = {
    showChangeNameModal: false,
    showRemoveFieldModal: false, 
    showLockFieldModal: false,
    newFieldName: null,
  };

  getNoLayers = (layer) => {
    const {
      db,
      fieldId,
    } = this.props;

    let allLayerIds = getAvailableLayerIds(db, fieldId, layer);

    return allLayerIds ? allLayerIds.length.toFixed(0) : "0"
  }

  getCurrentDataStatus = (layer) => {
    const {
      db,
      fieldId,
    } = this.props;

    if (!layer) {
      return "-"
    }

    let layerHasData = fieldId && layer && hasLayerData(db, fieldId, layer);
    let layerStatus = fieldId && layer && getLayerStatus(db, fieldId, layer);
    
    let dataStatus = "empty";
    dataStatus = layerStatus && ["queued_process", "running_process"].includes(layerStatus) ? "running" : dataStatus;
    dataStatus = layerStatus && ["queued_segment", "running_segment"].includes(layerStatus) ? "running" : dataStatus;
    dataStatus = layerHasData ? "done" : layerStatus;
    
    return this.getStatusIcon(dataStatus)
  };

  getStatusIcon = (status) => {
    let name = 'circle';
    let color = 'red';
    let loading = false;

    if (['queued', 'running'].includes(status)) {
      name = 'spinner';
      color = null;
      loading = true;
    }

    if (['done'].includes(status)) {
      color = 'green'
    } 

    return (
      <Icon name={name} color={color} loading={loading} />
    )
  };  
  
  openChangeFieldNameModal = () => {
    const {
      field
    } = this.props;

    this.setState({ 
      newFieldName: field && field.name && field.name,
      showChangeNameModal: true 
    });    
  };

  changeFieldName = () => {
    const {
      field,
      fieldId,
    } = this.props;    

    const {
      newFieldName,
    } = this.state;

    let text = "Freja har ny bytit namn på skiftet " + (field && field.name && field.name) + " till " + newFieldName + '.';
    toast.success(text,
    {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });    

    this.props.changeFieldName(newFieldName, fieldId);

    this.setState({
      showChangeNameModal: false,
      newFieldName: null
    });
  };

  lockField = () => {
    const {
      field,
      fieldId,
    } = this.props;

    this.props.lockField(fieldId);

    let text = "Freja har nu markerat skiftet: " + (field && field.name && field.name) + " som låst.";

    toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
  };

  removeField = () => {
    const {
      field,
      fieldId,
    } = this.props;

    this.props.removeField(fieldId);

    let text = "Freja har nu markerat skiftet: " + (field && field.name && field.name) + " för borttagning.";

    toast.success(text,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });
  };


  onChange = (data) => {
    this.setState({ [data.name]: data.value });
  };

  render() {
    const {
      userId,
      field,
      fieldId,
    } = this.props;

    const {
      showChangeNameModal,
      showRemoveFieldModal,
      showLockFieldModal,
      newFieldName,
    } = this.state;

    let position = field && field.position && field.position;

    if (!field) {
      return <div></div>
    }
    
    return (
      <React.Fragment>
        <Modal size="tiny" open={showChangeNameModal}>
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            Byt namn på skifte
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>
                Ange ett nytt namn för ditt skifte:
              </p>
              <Input 
                style={{ width: "100%", marginTop: 10}} 
                value={newFieldName}
                name="newFieldName"
                onChange={(event, data) => this.onChange(data)}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}                       
          >
            <Button 
              onClick={() => this.setState({ showChangeNameModal: false, newFieldName: null })}
            >
              Avbryt
            </Button>

            <Button 
              primary
              onClick={() => this.changeFieldName()}
            >
              Byt namn
            </Button>            
          </Modal.Actions>          
        </Modal>

        <Modal size="tiny" open={showLockFieldModal}>
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            Lås skifte
          </Modal.Header>
          <Modal.Content> 
            <Modal.Description>
              <p>Om du väljer att låsa ditt skifte så kommer du inte längre ha tillgång till den informationen och de tolkningar som finns för detta skifte i Freja. Du kommer inte heller debiteras för detta skifte vid din nästa faktura.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}                     
          >
            <Button 
              onClick={() => this.setState({ showLockFieldModal: false })}
            >
              Avbryt
            </Button>

            <Button 
              negative
              onClick={() => this.lockField()}
            >
              Lås skifte
            </Button>
          </Modal.Actions>                 
        </Modal>    

        <Modal size="tiny" open={showRemoveFieldModal}>
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            Ta bort skifte
          </Modal.Header>
          <Modal.Content> 
            <Modal.Description>
              <p>Om du väljer att ta bort ditt skifte så kommer du inte längre ha tillgång till den informationen och de tolkningar som finns för detta skifte i Freja. Du kommer inte heller debiteras för detta skifte vid din nästa faktura.</p>
              
              <p><strong>90 dagar efter du har tagit bort skiftet så kommer vi rensa bort datat och de kan då inte återskapas!</strong>.</p>
              
              <p>Skulle du välja att lägga tillbaka detta skifte under övervakning kommer din gamla data därför inte dyka upp som innan.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}                     
          >
            <Button 
              onClick={() => this.setState({ showRemoveFieldModal: false })}
            >
              Avbryt
            </Button>

            <Button 
              negative
              onClick={() => this.removeField()}
            >
              Ta bort skifte
            </Button>
          </Modal.Actions>                 
        </Modal>        
        
        <Segment
          style={{
            padding: "2em",
            display: "flex",
            justifyContent: "space-between",
            width: "70%",
            marginLeft: "15%",
          }}        
        >
          <div 
            style={{
              width: "40%"
            }}
          >
            {position && 
              <FieldCartoon 
                style={{
                  width: "60%",
                  display: "block",
                  margin: "auto",
                }}            
                color={"#e0ba8c"}
                position={position}
              />
            }  
          </div>
              
          <div 
            style={{
              width: "50%",
              marginLeft: "2em",
            }}
          >
            <Header 
              as="h5" 
              style={{
                marginBottom: 2,
                fontWeight: 600
              }}
            >
              Skiftesnamn
            </Header>
            
            {field && field.name && field.name}

            <Header 
              as="h5" 
              style={{
                marginBottom: 2,
                fontWeight: 600
              }}
            >
              Storlek
            </Header>
            {(field && field.field_size) ? field.field_size.toFixed(2) : '-'} ha             

            <Header 
              as="h5" 
              style={{
                marginBottom: 2,
                fontWeight: 600
              }}
            >
              Lades till i Freja
            </Header>
            {(field && field.date_created) ? moment(field.date_created.toDate()).format('D MMMM YYYY') : '-'}
            {(field && field.sam_block_id) ? " från SAM-ansökan." : " av användaren manuellt."}
            <Header 
              as="h5" 
              style={{
                marginBottom: 2,
                fontWeight: 600
              }}
            >
              Jordbruksblock
            </Header>
            {(field && field.sam_block_id) ? field.sam_block_id : '-'} / {(field && field.sam_block_sub_id) ? field.sam_block_sub_id : '-'} 

            <Header 
              as="h5" 
              style={{
                marginBottom: 2,
                fontWeight: 600
              }}
            >
              Frejas interna ID
            </Header>
            {userId} / {fieldId}                  
          </div>
        </Segment>    

        <Segment
          style={{
            padding: "1.5em",
            width: "70%",
            marginLeft: "15%",
            display: "flex",
            justifyContent: "space-between",                 
          }}        
        >
          <Header
            as="h3"
            style={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "auto",
              marginBottom: "auto",
              width: "20%",
            }}
          >
            Verktyg
          </Header>
            
          <Button
            style={{
              width: "23%",
            }}          
            primary
            content='Ändra skiftesnamn' 
            onClick={() => this.openChangeFieldNameModal()}
          />

          <Button
            style={{
              width: "23%",
            }}                
            content='Lås skifte' 
            onClick={() => this.setState({ showLockFieldModal: true })}
          />     

          <Button
            style={{
              width: "23%",
            }}                
            negative
            content='Ta bort skifte' 
            onClick={() => this.setState({ showRemoveFieldModal: true })}
          />
        </Segment>                      

        <Segment
          style={{
            padding: "2em",
            width: "70%",
            marginLeft: "15%",
            marginBottom: "2em",
          }}
        >
          <Header 
            as="h2" 
            style={{ 
              fontWeight: 500,
            }}
          >
            Tillgänglig data
          </Header>

          <p>
            Här kan du se statusen för de olika datalagren som Freja kan läsa in, tolka och visa. En grön cirkel betyder att datan finns tillgänglig. En röd cirkel innebär att datan saknas. En roterande ikon innebär att Freja håller på att arbeta med datan.
          </p>

          <p>
            Du kan se mer detaljer och vilka filer som har laddats upp i filhanteraren som du hittar i menyn till vänster.
          </p>

          <Table 
            celled
            style={{
              marginTop: "1.5em",
              marginBottom: "1.5em",
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Datatyp</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Antal</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Hämtad</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Bearbetad</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Tolkad</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {LAYERS_TO_SHOW && LAYERS_TO_SHOW.map((x) => (
                <Table.Row
                  key={x.caption}
                >
                  <Table.Cell>{x.caption}</Table.Cell>
                  <Table.Cell textAlign='center'>{this.getNoLayers(x.noLayersLayer)} {" " + x.noLayersUnit}</Table.Cell>
                  <Table.Cell textAlign='center'>{this.getCurrentDataStatus(x.rawDataLayer)}</Table.Cell>
                  <Table.Cell textAlign='center'>{this.getCurrentDataStatus(x.processedDataLayer)}</Table.Cell>
                  <Table.Cell textAlign='center'>{this.getCurrentDataStatus(x.analysedDataLayer)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>              
      </React.Fragment>
    );
  }
}

export default FieldManagementGeneral;
