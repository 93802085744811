import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import {
  Segment,
  Icon,
  Image,
  Confirm,
  Header,
  Dropdown,
} from "semantic-ui-react";

import moment from 'moment';
import 'moment/locale/sv';

import AddReminderGuide from "@/components/reminders/AddReminderGuide";
import GuideMarkVraMapUsed from "@/components/guide/GuideMarkVraMapUsed";

import { FIELD_DATA_LAYERS, FIELD_ANALYSIS_LAYERS } from "@/constants/viewLayersAnalysis";

import yieldOverviewIcon from "@/assets/icons/yield-overview.svg";
import observationIcon from "@/assets/icons/dataTypes/geotag.svg";
import emptyIllustration from "@/assets/illustrations/done_simple.png";
import userIcon from "@/assets/icons/menuSecondary/to-do.svg";

const ReminderSegment = ({ id, onClick, icon, header, description, descriptionMargin, fieldNames, seen, urgent, deleteReminder, markReminderRead, editReminder, widget }) => {
  return (
    <Segment
      key={id}
      basic
      className={!seen ? "backgroundColorOnHover" : null}
      style={{
        margin: 7,
        padding: 10,
        cursor: !seen ? "pointer" : null,
        backgroundColor: "#f6f6f6",
        borderRadius: 5,
      }}
      onClick={!seen ? () => onClick() : () => { }}
    >
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
        }}
      >
        <div
          style={{
            backgroundColor: urgent && (typeof (seen) !== "boolean" || seen !== true) ? "#C36126" : "#F8EED0",
            borderRadius: 5,
            width: widget ? "3.2em" : "2.4em",
            height: widget ? "3.2em" : "2.4em",
            marginRight: "1em",
          }}
        >
          <Image
            style={{
              marginTop: widget ? "0.75em" : "0.5em",
              marginLeft: widget ? "0.75em" : "0.5em",
              width: widget ? "1.75em" : "1.4em",
              height: widget ? "1.75em" : "1.4em",
              filter: urgent && (typeof (seen) !== "boolean" || seen !== true) ? "invert(100) brightness(100)" : "invert(0)",
            }}
            src={icon}
          />
        </div>

        <div
          style={{
            marginBottom: 0,
            marginLeft: "0.5em",
            whiteSpace: "pre-line",
            width: "90%"
          }}
        >
          <div style={{ lineHeight: "120%" }}>{header && header}</div>
          {fieldNames && <div style={{ color: "grey", fontSize: "90%" }}>{fieldNames}</div>}
          {description && <div style={{ color: "grey", fontSize: "90%", marginTop: descriptionMargin ? 10 : null }}>{description}</div>}
        </div>

        <div style={{ width: 10, justifyContent: "flex-end" }}>
          <Dropdown
            icon='ellipsis vertical'
            floating
            direction="left"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon='check'
                text={(typeof (seen) === "boolean" && seen === true) ? 'Markera outförd' : 'Markera utförd'}
                onClick={() => markReminderRead(id, (typeof (seen) === "boolean" && seen === true) ? true : false)}
              />

              {typeof (editReminder) === "function" &&
                <Dropdown.Item
                  icon='edit'
                  text='Redigera'
                  onClick={() => editReminder(id)}
                />
              }

              <Dropdown.Item
                icon='trash'
                text='Ta bort'
                onClick={() => deleteReminder(id)}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Segment>
  )
};

const INITIAL_STATE = {
  mode: "current",
  noRemindersToShow: 5,
  reminderIdToBeDeleted: null,
  remindersDeleted: [],
  remindersRead: [],
  showConfirmDeleteReminder: false,
  showOpenVraMapGuide: false,
  vraMapData: null,
  showAddReminderModal: false,
  showAddReminderModalMode: "addReminder",
  reminderIdToBeEdited: null,
};

class RemindersWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  markReminderRead = (id, currentValue) => {
    const {
      getUser,
    } = this.props;

    let dbRef = getUser().collection("reminders").doc(id);
    dbRef.update({ complete: !currentValue });

    let currentNotificationsRead = this.state.remindersRead;

    if (currentNotificationsRead.includes(id)) {
      this.setState({ remindersRead: currentNotificationsRead.filter((x) => x !== id) });
    } else {
      this.setState({ remindersRead: [...currentNotificationsRead, id] });
    };
  };

  toggleOpenVraMapGuide = (data) => {
    this.setState({
      showOpenVraMapGuide: !this.state.showOpenVraMapGuide,
      vraMapData: data ? data : null,
    });
  };

  toggleDeleteReminder = (id) => {
    let currentValue = this.state.showConfirmDeleteReminder;
    this.setState({
      showConfirmDeleteReminder: !currentValue,
      reminderIdToBeDeleted: id ? id : null,
    })
  };

  deleteReminder = () => {
    const {
      getUser,
    } = this.props;

    let id = this.state.reminderIdToBeDeleted;
    getUser().collection("reminders").doc(id).delete();

    let currentValue = this.state.remindersDeleted;
    this.setState({ remindersDeleted: [...currentValue, id] });
    this.toggleDeleteReminder()
  };

  editReminder = (id) => {
    this.setState({
      showAddReminderModal: true,
      showAddReminderModalMode: "editReminder",
      reminderIdToBeEdited: id,
    });
  };

  getReminder = (data) => {
    if (data && data.type && data.type === "guideActionRequired") {
      return this.getGuideActionRequiredSegment(data)
    };

    if (data && data.type && data.type === "guideReminder") {
      return this.getGuideReminderSegment(data)
    };

    if (data && data.type && data.type === "observationReminder") {
      return this.getObservationReminderSegment(data)
    };

    if (data && data.type && data.type === "userReminder") {
      return this.getUserReminderSegment(data)
    };
  };

  getEmptySegment = (mode = "today") => {

    if (mode === "today") {
      return (
        <Segment
          basic
          style={{
            display: "block",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          <Image
            centered
            style={{
              width: "50%",
            }}
            src={emptyIllustration}
          />

          <div
            style={{
              fontWeight: 500,
              marginBottom: 0,
              textAlign: "center",
            }}
          >
            Du har inga planerade saker att göra idag.
          </div>
        </Segment>
      )
    } else {
      return (
        <Segment
          basic
          style={{
            margin: 7,
            padding: 10,
            backgroundColor: "#f6f6f6",
            borderRadius: 5,
            width: "calc(100% - 20px)",
            marginLeft: 10,
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <Image
              style={{
                width: "2.4em",
                height: "2.4em",
                marginRight: "1em",

              }}
              src={emptyIllustration}
            />

            <div
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: 10,
                width: "80%",
              }}
            >
              Inga planerade saker att göra i Freja.
            </div>
          </div>
        </Segment>
      )
    };
  };


  openGuide = (data, markDone = true) => {
    if (data && data.data_type && data.guide_id && data.current_guide_step) {
      markDone && this.markReminderRead(data.id, false)
      this.props.history.push("/" + data.data_type + "/" + data.current_guide_step + "/" + data.guide_id);
    };
  };

  openObservation = (data) => {
    // if (data && data.field_id && data.observation_id) {
    //   this.props.history.push("/geotags/" + data.field_id + "/details/" + data.observation_id);
    // }; 
  };

  getGuideActionRequiredSegment = (data) => {
    let title = "Freja behöver din hjälp";
    let icon = yieldOverviewIcon;
    let complete = data && typeof (data.complete) === "boolean" && data.complete;

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();
    let noFields = data && data.new_entries && data.new_entries.length;

    if (data && data.data_type && data.data_type === "guidesUploadSoilMaps") {
      title = "Freja behöver din hjälp med " + (noFields > 1 ? noFields : "en") + ((!noFields || noFields < 2) ? " markkartering" : " markkarteringar")
      icon = FIELD_DATA_LAYERS.soilMapSweden.icon;
    };

    if (data && data.data_type && data.data_type === "guidesUploadYieldMaps") {
      title = "Freja behöver din hjälp med " + (noFields > 1 ? noFields : "en") + ((!noFields || noFields < 2) ? " skördekarta" : " skördekartor")
      icon = FIELD_DATA_LAYERS.yieldMap.icon;
    };

    return (
      <ReminderSegment
        widget={this.props.widget}
        key={data.id}
        id={data.id}
        header={(title ? title : "") + "."}
        description={(date ? date : "") + "."}
        icon={icon}
        onClick={() => this.openGuide(data)}
        seen={complete}
        urgent={true}
        markReminderRead={this.markReminderRead}
        deleteReminder={this.toggleDeleteReminder}
      />
    )
  };


  getGuideReminderSegment = (data) => {
    let title = "Du har en påminnelse...";
    let description = "";
    let fieldNames = "";
    let icon = yieldOverviewIcon;

    let complete = data && typeof (data.complete) === "boolean" && data.complete;
    let purpose = data && data.guide_purpose;
    let urgent = data && typeof (data.condition_active) === "boolean" ? data.condition_active : false;

    if (data && data.reminder_type && data.reminder_type === "planNewFiles") {
      title = "Det är dags att planera nya styrfiler i Freja för" + (purpose ? " " + purpose : "...");
      icon = FIELD_ANALYSIS_LAYERS.vraMap.icon;
    };

    if (data && data.reminder_type && data.reminder_type === "useFiles") {
      title = "Det är dags att använda dina styrfiler i Freja för" + (purpose ? " " + purpose : "...");
      icon = FIELD_ANALYSIS_LAYERS.vraMap.icon;
    };

    let noFields = data && data.new_entries && data.new_entries.length;
    fieldNames = noFields && noFields > 0 ? noFields.toFixed(0) + " skiften. " : fieldNames;

    description = data && data.condition_reason ? data.condition_reason : description;

    return (
      <ReminderSegment
        widget={this.props.widget}
        key={data.id}
        id={data.id}
        header={(title ? title : "") + "."}
        fieldNames={fieldNames && fieldNames}
        description={description && description}
        descriptionMargin={true}
        icon={icon}
        onClick={() => this.toggleOpenVraMapGuide(data)}
        seen={complete}
        urgent={urgent}
        markReminderRead={this.markReminderRead}
        deleteReminder={this.toggleDeleteReminder}
      />
    )
  };

  getObservationReminderSegment = (data) => {
    let title = "Återbesöka en observation";
    let description = "Typ: " + data.observation_type;
    let icon = observationIcon;

    let fieldNames = data && data.field_name && "Skifte: " + data.field_name;
    let complete = data && typeof (data.complete) === "boolean" && data.complete;
    let urgent = data && typeof (data.condition_active) === "boolean" ? data.condition_active : false;

    return (
      <ReminderSegment
        widget={this.props.widget}
        key={data.id}
        id={data.id}
        header={(title ? title : "") + "."}
        description={description && description}
        fieldNames={fieldNames && fieldNames}
        descriptionMargin={false}
        icon={icon}
        onClick={() => console.log("Nada")}
        seen={complete}
        urgent={urgent}
        markReminderRead={this.markReminderRead}
        deleteReminder={this.toggleDeleteReminder}
      />
    )
  };

  getUserReminderSegment = (data) => {
    let title = data && data.header;
    let description = data && data.note;
    let icon = userIcon;

    let complete = data && typeof (data.complete) === "boolean" && data.complete;
    let urgent = data && typeof (data.condition_active) === "boolean" ? data.condition_active : false;

    return (
      <ReminderSegment
        widget={this.props.widget}
        key={data.id}
        id={data.id}
        header={(title ? title : "") + "."}
        description={description && description}
        descriptionMargin={false}
        icon={icon}
        seen={complete}
        urgent={urgent}
        onClick={() => console.log("Nada")}
        markReminderRead={this.markReminderRead}
        deleteReminder={this.toggleDeleteReminder}
        editReminder={this.editReminder}
      />
    )
  };

  getReminderSegment = (db, mode, header) => {
    const {
      noRemindersToShow,
    } = this.state;

    let reminders = db && db.reminders && db.reminders;

    reminders = reminders && Object.keys(reminders).map(x => ({ id: x, ...db.reminders[x] }));
    reminders = reminders && reminders.filter((x) => typeof (x.type) === "string");

    switch (mode) {
      case 'today':
        reminders = reminders && reminders.filter((x) =>
          !x.complete && (
            x.condition_active === true || x.urgent === true || x.type === "guideActionRequired"
          )
        );
        break;

      case 'tomorrow':
        reminders = reminders && reminders.filter((x) => {
          let formattedDate;

          if (x.condition && x.condition.first_date) {
            if (typeof (x.condition.first_date) === "string") {
              formattedDate = moment(x.condition.first_date, "YYYY-MM-DD").endOf("day").format("YYYY-MM-DD");
            } else {
              formattedDate = moment(x.condition.first_date.toDate()).endOf("day").format("YYYY-MM-DD");
            };
          };

          return !x.complete && formattedDate && formattedDate === moment().add(1, "day").format("YYYY-MM-DD");
        });
        break;

      case 'week':
        reminders = reminders && reminders.filter((x) => {
          let formattedDate;

          if (x.condition && x.condition.first_date) {
            if (typeof (x.condition.first_date) === "string") {
              formattedDate = moment(x.condition.first_date, "YYYY-MM-DD").endOf("day");
            } else {
              formattedDate = moment(x.condition.first_date.toDate()).endOf("day");
            };
          }

          return !x.complete && formattedDate && moment().add(1, "day").endOf("day") < formattedDate && formattedDate <= moment().add("7", "days").endOf("day");
        });
        break;

      case 'later':
        reminders = reminders && reminders.filter((x) => {
          let formattedDate;

          if (x.condition && x.condition.first_date) {
            if (typeof (x.condition.first_date) === "string") {
              formattedDate = moment(x.condition.first_date, "YYYY-MM-DD").endOf("day");
            } else {
              formattedDate = moment(x.condition.first_date.toDate()).endOf("day");
            };
          }

          return !x.complete && (!formattedDate || formattedDate > moment().add("7", "days").endOf("day"));
        });
        break;

      case 'done':
        reminders = reminders && reminders.filter((x) => {
          return x.complete;
        });
        break;

      default:
        return <div />
    }

    reminders = reminders && reminders.sort((a, b) => b.date - a.date);

    let noReminders = reminders && reminders.length;
    reminders = ["done", "later"].includes(mode) ? reminders && reminders.splice(0, noRemindersToShow) : reminders;

    let reminderSegments = reminders && reminders.map((x) => this.getReminder(x));
    reminderSegments = reminderSegments && reminderSegments.filter((x) => x !== null);

    if (mode === "today" && (!reminderSegments || reminderSegments.length === 0)) {
      return this.getEmptySegment(mode);
    };

    reminderSegments = reminderSegments && reminderSegments.length > 0 ? reminderSegments : this.getEmptySegment(mode);

    return (
      <React.Fragment>
        <Header
          style={{
            fontWeight: 500,
            marginLeft: "1em",
            marginRight: "1em",
            marginTop: 15,
          }}
          as="h4"
        >
          {header && header}
        </Header>

        {reminderSegments && reminderSegments}

        {["done", "later"].includes(mode) && noReminders >= noRemindersToShow &&
          <Segment
            basic
            className="textLink"
            style={{
              color: "grey",
              textAlign: "center",
              padding: 0,
              margin: 0,
              marginBottom: 0,
              paddingBottom: 10,
              fontWeight: "normal",
              fontSize: "85%",
              cursor: "pointer",
            }}
            onClick={() => this.setState({ noRemindersToShow: this.state.noRemindersToShow + 5 })}
          >
            Klicka här för att visa fler
              <Icon name="caret down" />
          </Segment>
        }

        {["done", "later"].includes(mode) && reminderSegments && reminderSegments.length > 0 && noReminders > 10 && noReminders < noRemindersToShow &&
          <Segment
            basic
            style={{
              color: "grey",
              textAlign: "center",
              padding: 0,
              margin: 0,
              marginBottom: 0,
              paddingBottom: 10,
              fontWeight: "normal",
              fontSize: "85%",
            }}
          >
            Inga fler saker att göra hittades
            </Segment>
        }
      </React.Fragment>
    )
  }

  render() {
    const {
      userId,
      db,
      getUser,
      widget,
    } = this.props;

    const {
      mode,
      showConfirmDeleteReminder,
      showAddReminderModal,
      showOpenVraMapGuide,
      vraMapData,
      showAddReminderModalMode,
      reminderIdToBeEdited,
    } = this.state;

    return (
      <React.Fragment>
        <AddReminderGuide
          db={db}
          userId={userId}
          getUser={getUser}
          guideMode={showAddReminderModalMode}
          reminderId={reminderIdToBeEdited}
          open={showAddReminderModal}
          closeModal={() => this.setState({
            showAddReminderModal: false,
            showAddReminderModalMode: "addReminder",
            reminderIdToBeEdited: null,
          })}
        />

        <Confirm
          open={showConfirmDeleteReminder}
          header="Vill du ta bort denna sak att göra?"
          content="Denna handling går inte att ångra."
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.deleteReminder()}
          onCancel={() => this.toggleDeleteReminder()}
          size="tiny"
        />

        <GuideMarkVraMapUsed
          db={db}
          guideId={vraMapData && vraMapData.guide_id}
          guide={vraMapData}
          getUser={getUser}
          open={showOpenVraMapGuide}
          onlyAllowMarkDone={false}
          closeModal={() => this.toggleOpenVraMapGuide()}
        />

        <Segment
          style={{
            padding: widget ? "0.75em" : 0,
            border: "0.5px lightgrey solid",
            borderRadius: 5,
            // backgroundColor: "#f7f4f0"
            width: "100%",
            height: "100%",
            borderColor: widget ? "transparent" : null,
            boxShadow: widget ? "none" : null,
          }}
          loading={!db && (!isLoaded(db.reminders) && !isEmpty(db.reminders))}
        >

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 0,
              padding: "1em",
              paddingTop: widget ? "0.5em" : "1em",
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                fontSize: "110%",
              }}
            >
              {widget && mode === "current" && "Saker att göra"}
              {!widget && mode === "current" && "Saker att göra"}
              {mode === "done" && "Utförda saker"}
            </p>

            <div>
              <div
                style={{
                  display: "inline-block",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: 10,
                }}
                className="textLink"
                onClick={() => this.setState({ showAddReminderModal: true })}
              >
                Lägg till
              </div>

              &bull;

              {!widget &&
                <div
                  style={{
                    display: "inline-block",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: 10,
                  }}
                  className="textLink"
                  onClick={() => this.setState({ mode: mode === "current" ? "done" : "current" })}
                >
                  {mode !== "current" && "Saker att göra"}
                  {mode === "current" && "Genomförda saker"}
                </div>
              }

              {widget &&
                <div
                  style={{
                    display: "inline-block",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: 10,
                  }}
                  className="textLink"
                  onClick={() => this.props.history.push("/home/reminders")}
                >
                  Visa fler
                </div>
              }

            </div>
          </div>

          <Segment.Group
            style={{
              border: "none",
              boxShadow: "none",
              marginTop: 10,
              height: "100%",
              width: "100%",
              overflowY: "auto",
            }}
          >
            {widget && this.getReminderSegment(db, "today", "")}

            {!widget && mode === "current" && this.getReminderSegment(db, "today", "Idag")}
            {!widget && mode === "current" && <div style={{ height: 20 }} />}
            {!widget && mode === "current" && this.getReminderSegment(db, "tomorrow", "Imorgon")}
            {!widget && mode === "current" && <div style={{ height: 20 }} />}
            {!widget && mode === "current" && this.getReminderSegment(db, "week", "Under veckan")}
            {!widget && mode === "current" && <div style={{ height: 20 }} />}
            {!widget && mode === "current" && this.getReminderSegment(db, "later", "Senare")}
            {!widget && mode === "current" && <div style={{ height: 20 }} />}

            {mode === "done" && this.getReminderSegment(db, "done", "")}
          </Segment.Group>
        </Segment>
      </React.Fragment>
    )
  }
}

export default withRouter(RemindersWidget);
