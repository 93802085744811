import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Icon,
  Segment,
  Header,
  Table,
  Message,
  Confirm,
} from "semantic-ui-react";

import { cloudFunctions } from "@";

import { CROP_CODES } from "@/constants/cropCodes";

import moment from 'moment';
class FileManagerFileList extends Component {

  state = {
    sortIdxYieldMaps: 1,
    sortIdxSoilMaps: 1,
    removedFileNames: [],
    showRemoveYieldMapConfirmation: false,
    showRemoveSoilMapConfirmation: false,
    showRemoveSamApplicationConfirmation: false,
    samApplications: {},
  };

  downloadFileFromStorage = (fileInfo) => {
    const {
      fileBucketRef,
    } = this.props;
    
    fileInfo.filePath && fileBucketRef.child(fileInfo.filePath).getDownloadURL().then((url) => {
      fetch(url)
        .then(async res => ({
            filename: fileInfo.filePath.split("/").slice(-1)[0],
            blob: await res.blob()
        }))
        .then(resObj => {
            // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
            const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
  
            // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
            } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);
  
                let link = document.createElement('a');
                link.href = objUrl;
                link.download = resObj.filename;
                link.click();
  
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
            }
        })
        .catch((error) => {
            console.log('DOWNLOAD ERROR', error);
        });
    });
  };

  onShowConfirmRemoveYieldMap = (fileInfo) => {
    this.setState({
      fileToRemove: fileInfo,
      onShowConfirmRemoveYieldMap: true,
    });
  };

  removeYieldMap = () => {
    const {
      userId,
      fieldId,
      getUser,
    } = this.props;

    const {
      fileToRemove
    } = this.state;

    let fileId = fileToRemove.id;
    getUser().collection("fieldsYieldMapsUploaded").doc(fileId).update({ removed: true });

    let currentRemovedFileNames = this.state.removedFileNames;
    this.setState({ removedFileNames: [...currentRemovedFileNames, fileToRemove.fileName] });

    cloudFunctions.httpsCallable('callDatahubHarvest')(
      {
        userId: userId,
        fieldId: fieldId,
        endpoint: "/v1/compileYieldMaps/field",
      }
    );

    this.setState({ 
      onShowConfirmRemoveYieldMap: false,
      fileToRemove: null,
    });
  };    

  onShowConfirmRemoveSoilMap = (fileInfo) => {
    this.setState({
      fileToRemove: fileInfo,
      showRemoveSoilMapConfirmation: true,
    });
  };

  removeSoilMap = () => {
    const {
      userId,
      fieldId,
      uploadedSoilMaps,
      getUser,
    } = this.props;

    const {
      fileToRemove
    } = this.state;

    let soilMapUploaded = fieldId && uploadedSoilMaps;
    let uniqueId = soilMapUploaded && Object.keys(soilMapUploaded).find((x) => soilMapUploaded[x].file_name === fileToRemove.fileName);

    let dbRef = getUser().collection("fieldsSoilMapsUploaded").doc(uniqueId);
    dbRef.update({ deleted: true });

    let currentRemovedFileNames = this.state.removedFileNames;
    this.setState({ removedFileNames: [...currentRemovedFileNames, fileToRemove.fileName] });

    cloudFunctions.httpsCallable('callDatahubHarvest')(
      {
        userId: userId,
        endpoint: "/v1/parseUploadedSoilMap",
      }
    );

    this.setState({ 
      showRemoveSoilMapConfirmation: false,
      fileToRemove: null,
    });
  };

  getSamApplications = () => {
    const {
      userId,
    } = this.props;

    const {
      removedFileNames,
    } = this.state;

    let filesPath = "samApplications/" + userId + "/";

    filesPath && this.props.fileBucketRef.child(filesPath).listAll().then((files) => {
      files.items.forEach((file) => {

        let currentValue = this.state.samApplications;
        let filePath = file && file.name && filesPath + file.name;
        let fileName = file && file.name;

        if (filePath && !removedFileNames.includes(fileName)) {
          this.setState({ samApplications: {...currentValue, [fileName]: filePath }})
        };
      });
    });
  };     

  onShowConfirmRemoveSamApplication = (fileInfo) => {
    this.setState({
      fileToRemove: fileInfo,
      showRemoveSamApplicationConfirmation: true,
    });
  };

  removeSamApplication = () => {
    const {
      userId,
    } = this.props;

    const {
      fileToRemove
    } = this.state;

    let filePath = "samApplications/" + userId + "/" + fileToRemove;
    filePath && this.props.fileBucketRef.child(filePath).delete();
    
    let currentRemovedFileNames = this.state.removedFileNames;
    this.setState({ removedFileNames: [...currentRemovedFileNames, fileToRemove] });

    this.setState({ 
      showRemoveSamApplicationConfirmation: false,
      fileToRemove: null,
    });
  };  

  setSortIdxYieldMaps = idx => () => {
    if (idx === this.state.sortIdxYieldMaps) {
      this.setState({ sortIdxYieldMaps: -idx });
    } else {
      this.setState({ sortIdxYieldMaps: idx });
    }
  };

  sortUploadedYieldMaps = uploadedYieldMap => (a, b) => {
    const textA = a.fileName && a.fileName.toUpperCase();
    const textB = b.fileName && b.fileName.toUpperCase();

    const fieldA = a.fieldName;
    const fieldB = b.fieldName;

    const yearA = a.year;
    const yearB = b.year;

    const cropA = a.crop;
    const cropB = b.crop;

    const dateUploadedA = a.dateUploaded;
    const dateUploadedB = b.dateUploaded;

    const statusA = a.status;
    const statusB = b.status;
    
    switch (this.state.sortIdxYieldMaps) {
      case 1:
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      case -1:
        return -1 * (textA < textB ? -1 : textA > textB ? 1 : 0);
      case 2:
        return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
      case -2:
        return -1 * (fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0);
      case 3:
        return new Date(yearA) - new Date(yearB);
      case -3:
        return -1 * (new Date(yearA) - new Date(yearB));
      case 4:
        return cropA < cropB ? -1 : cropA > cropB ? 1 : 0;
      case -4:
        return -1 * (cropA < cropB ? -1 : cropA > cropB ? 1 : 0);        
      case 5:
        return new Date(dateUploadedA) - new Date(dateUploadedB);
      case -5:
        return -1 * (new Date(dateUploadedA) - new Date(dateUploadedB));   
      case 6:
        return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
      case -6:
        return -1 * (statusA < statusB ? -1 : statusA > statusB ? 1 : 0);
      default: 
        return -1;
    }
  }; 

  setSortIdxSoilMaps = idx => () => {
    if (idx === this.state.sortIdxSoilMaps) {
      this.setState({ sortIdxSoilMaps: -idx });
    } else {
      this.setState({ sortIdxSoilMaps: idx });
    }
  };

  sortUploadedSoilMaps = uploadedYieldMap => (a, b) => {
    const textA = a.fileName && a.fileName.toUpperCase();
    const textB = b.fileName && b.fileName.toUpperCase();

    const fieldA = a.fieldNames;
    const fieldB = b.fieldNames;

    const yearA = a.year;
    const yearB = b.year;

    const coordinateSystemA = a.coordinateSystem;
    const coordinateSystemB = b.coordinateSystem;

    const dateUploadedA = a.dateUploaded;
    const dateUploadedB = b.dateUploaded;

    const statusA = a.status;
    const statusB = b.status;
    
    switch (this.state.sortIdxSoilMaps) {
      case 1:
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      case -1:
        return -1 * (textA < textB ? -1 : textA > textB ? 1 : 0);
      case 2:
        return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
      case -2:
        return -1 * (fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0);
      case 3:
        return new Date(yearA) - new Date(yearB);
      case -3:
        return -1 * (new Date(yearA) - new Date(yearB));
      case 4:
        return coordinateSystemA < coordinateSystemB ? -1 : coordinateSystemA > coordinateSystemB ? 1 : 0;
      case -4:
        return -1 * (coordinateSystemA < coordinateSystemB ? -1 : coordinateSystemA > coordinateSystemB ? 1 : 0);        
      case 5:
        return new Date(dateUploadedA) - new Date(dateUploadedB);
      case -5:
        return -1 * (new Date(dateUploadedA) - new Date(dateUploadedB));   
      case 6:
        return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
      case -6:
        return -1 * (statusA < statusB ? -1 : statusA > statusB ? 1 : 0);
      default: 
        return -1;        
    }
  }; 

  getDataForField = () => {
    const {
      fields,
      fieldsInfo,
      fieldId,
      uploadedYieldMaps,
      rawYieldMaps,
      processedYieldMaps,
      uploadedSoilMaps,
      rawSoilMaps,
      processedSoilMaps,
    } = this.props;

    const {
      removedFileNames,
    } = this.state;

    let field = fieldId && fields && fields[fieldId] && fields[fieldId];

    let uploadedYieldMapsList = [];
            
    uploadedYieldMaps && Object.keys(uploadedYieldMaps).forEach(id => {
      let subYieldMap = uploadedYieldMaps && uploadedYieldMaps[id];

      if (!subYieldMap) {
        return;
      };

      if (subYieldMap.fields_in_file && !subYieldMap.fields_in_file.includes(fieldId)) {
        return;
      };

      if (!subYieldMap.year) {
        return;
      };

      let year = subYieldMap && subYieldMap.year;

      let status = "Inläst";
        
      status = status && 
        rawYieldMaps && 
        rawYieldMaps &&
        rawYieldMaps.dates &&
        rawYieldMaps.dates.find(x => x === year) ?
        "Bearbetad" : status;

      status = status && 
        processedYieldMaps && 
        processedYieldMaps &&
        processedYieldMaps.rawdata_harvest_maps_geojson && 
        Object.keys(processedYieldMaps.rawdata_harvest_maps_geojson).map((x) => x).find(x => x === year) ?
        "Tolkad" : status;

      if (typeof(subYieldMap.removed) === "boolean" && subYieldMap.removed === true) {
        return;
      };

      if (removedFileNames.includes(subYieldMap.fileName)) {
        return;
      };

      let crop = fieldId && year && fieldsInfo && fieldsInfo[fieldId] && fieldsInfo[fieldId].crop_code && fieldsInfo[fieldId].crop_code[year] && fieldsInfo[fieldId].crop_code[year];
      crop = crop && CROP_CODES.find((x) => x.key === crop);
      crop = crop && crop.shorttext;

      let fileName = subYieldMap.original_file_name && subYieldMap.original_file_name;
      fileName = fileName ? fileName : subYieldMap.file_name && subYieldMap.file_name;
      fileName = fileName && (fileName.length > 30 ? fileName.substring(0, 30) + "..." : fileName);
      
      uploadedYieldMapsList = [...uploadedYieldMapsList,
        {
          id: id,
          fileName: fileName,
          filePath: subYieldMap.file_path && subYieldMap.file_path,
          crop: crop,
          year: year,
          fieldId: fieldId,
          fieldName: field && field && field.name && field.name,
          dateUploaded: subYieldMap.date_uploaded && subYieldMap.date_uploaded,
          status: status,
        }
      ]
    });
    
    let sortedYieldMaps = uploadedYieldMapsList && uploadedYieldMapsList.sort(this.sortUploadedYieldMaps(uploadedYieldMapsList));


    let uploadedSoilMapsFileInfo = [];
            
    rawSoilMaps && rawSoilMaps.uploaded_parsed_pickle &&
      Object.keys(rawSoilMaps.uploaded_parsed_pickle).sort().reverse().forEach(year => { 
          let fileName = rawSoilMaps.uploaded_file_names && year &&
            rawSoilMaps.uploaded_file_names[year] && 
            rawSoilMaps.uploaded_file_names[year];
          
          let originalFile = uploadedSoilMaps && Object.keys(uploadedSoilMaps).find((x) => uploadedSoilMaps[x].file_name === fileName);
          originalFile = originalFile && uploadedSoilMaps && uploadedSoilMaps[originalFile];

          let filePath = originalFile && originalFile.file_path;

          let fieldIdsInFile = originalFile && originalFile.fields_in_file && originalFile.fields_in_file;
          let fieldNamesInFile = fieldIdsInFile && fieldIdsInFile.map((x) => fields && fields[x] && fields[x].name && fields[x].name);
          fieldNamesInFile = fieldNamesInFile && fieldNamesInFile.join(", ")

          let status = "Bearbetad";
          fileName = fileName && (fileName.length > 30 ? fileName.substring(0, 30) + "..." : fileName);

          status = status && 
            processedSoilMaps && 
            processedSoilMaps &&
            processedSoilMaps.rawdata_uploaded_soilmap_geojson && 
            Object.keys(processedSoilMaps.rawdata_uploaded_soilmap_geojson).map((x) => x).find(x => x === year) ?
            "Tolkad" : status; 
          
            uploadedSoilMapsFileInfo = [...uploadedSoilMapsFileInfo,
            {
              fileName: fileName,
              filePath: filePath,
              year: year,
              fieldId: fieldId,
              fieldIds: fieldIdsInFile,
              coordinateSystem: originalFile && originalFile.coordinate_system && originalFile.coordinate_system,
              fieldNames: fieldNamesInFile,
              dateUploaded: originalFile && originalFile.date_uploaded && originalFile.date_uploaded,
              status: status,
            }
          ]
    });
    
    let sortedUploadedSoilMaps = uploadedSoilMapsFileInfo && uploadedSoilMapsFileInfo.sort(this.sortUploadedSoilMaps(uploadedSoilMapsFileInfo));
    
    return {sortedYieldMaps: sortedYieldMaps, sortedUploadedSoilMaps: sortedUploadedSoilMaps}
  };

  getDataForFarm = () => {
    const {
      fields,
      fieldsInfo,
      uploadedYieldMaps,
      uploadedSoilMaps,
      rawSoilMaps,
      rawYieldMaps,
      processedYieldMaps,
      processedSoilMaps,
    } = this.props;

    const {
      samApplications,
    } = this.state;

    let fieldNames = fields && Object.keys(fields).map((x) => x);

    samApplications && Object.keys(samApplications) < 1 && this.getSamApplications();

    let uploadedYieldMapsList = [];
            
    uploadedYieldMaps && Object.keys(uploadedYieldMaps).forEach((id) => {
      let subYieldMap = uploadedYieldMaps && uploadedYieldMaps[id];

      if (!subYieldMap) {
        return;
      }

      let fieldId = subYieldMap && subYieldMap.fields_in_file && subYieldMap.fields_in_file.length > 0 && subYieldMap.fields_in_file[0];
      let year = subYieldMap && subYieldMap.year && subYieldMap.year;

      let crop = fieldId && year && fieldsInfo && fieldsInfo[fieldId] && fieldsInfo[fieldId].crop_code && fieldsInfo[fieldId].crop_code[year] && fieldsInfo[fieldId].crop_code[year];
      crop = crop && CROP_CODES.find((x) => x.key === crop);
      crop = crop && crop.shorttext;

      let fileName = subYieldMap.original_file_name ? subYieldMap.original_file_name : (subYieldMap.file_name ? subYieldMap.file_name : 'Filnamn saknas');
      fileName = fileName && (fileName.length > 30 ? fileName.substring(0, 30) + "..." : fileName);

      let status = "Inläst";
        
      status = status && 
        rawYieldMaps && 
        rawYieldMaps &&
        rawYieldMaps.dates &&
        rawYieldMaps.dates.find(x => x === year) ?
        "Bearbetad" : status;

      status = status && 
        processedYieldMaps && 
        processedYieldMaps &&
        processedYieldMaps.rawdata_harvest_maps_geojson && 
        Object.keys(processedYieldMaps.rawdata_harvest_maps_geojson).map((x) => x).find(x => x === year) ?
        "Tolkad" : status;

      let fieldNamesInFile = fields && subYieldMap.fields_in_file && subYieldMap.fields_in_file.map((x) => fields[x] && fields[x].name);
      fieldNamesInFile = fieldNamesInFile && fieldNamesInFile.join(", ")

      uploadedYieldMapsList = [...uploadedYieldMapsList,
        {
            fileName: fileName,
            crop: crop,
            year: year,
            id: id,
            fieldId: subYieldMap.fields_in_file && subYieldMap.fields_in_file,
            fieldName: fieldNamesInFile,
            filePath: subYieldMap.file_path && subYieldMap.file_path,
            dateUploaded: subYieldMap.date_uploaded && subYieldMap.date_uploaded,
            status: status,
        }
      ]
    })
    
    let sortedYieldMaps = uploadedYieldMapsList && uploadedYieldMapsList.sort(this.sortUploadedYieldMaps(uploadedYieldMapsList));

    let uploadedSoilMapsFileInfo = [];  
    
    rawSoilMaps && processedSoilMaps &&
      fieldNames && fieldNames.forEach((fieldId) => {

        rawSoilMaps && rawSoilMaps.uploaded_parsed_pickle &&
          Object.keys(rawSoilMaps.uploaded_parsed_pickle).sort().reverse().forEach(year => { 
              let fileName = rawSoilMaps.uploaded_file_names && year &&
                rawSoilMaps.uploaded_file_names[year] && 
                rawSoilMaps.uploaded_file_names[year];
              
              let originalFile = uploadedSoilMaps && Object.keys(uploadedSoilMaps).find((x) => uploadedSoilMaps[x].file_name === fileName);
              originalFile = originalFile && uploadedSoilMaps && uploadedSoilMaps[originalFile];

              let filePath = originalFile && originalFile.file_path;

              let fieldIdsInFile = originalFile && originalFile.fields_in_file && originalFile.fields_in_file;
              let fieldNamesInFile = fieldIdsInFile && fieldIdsInFile.map((x) => fields && fields[x] && fields[x].name && fields[x].name);
              fieldNamesInFile = fieldNamesInFile && fieldNamesInFile.join(", ")

              let status = "Bearbetad";

              status = status && 
                processedSoilMaps && 
                processedSoilMaps &&
                processedSoilMaps.rawdata_uploaded_soilmap_geojson && 
                Object.keys(processedSoilMaps.rawdata_uploaded_soilmap_geojson).map((x) => x).find(x => x === year) ?
                "Tolkad" : status; 
              
                uploadedSoilMapsFileInfo = [...uploadedSoilMapsFileInfo,
                {
                  filePath: filePath,
                  fileName: fileName,
                  year: year,
                  fieldId: fieldId,
                  fieldIds: fieldIdsInFile,
                  coordinateSystem: originalFile && originalFile.coordinate_system && originalFile.coordinate_system,
                  fieldNames: fieldNamesInFile,
                  dateUploaded: originalFile && originalFile.date_uploaded && originalFile.date_uploaded,
                  status: status,
                }
              ]
        });
    });
    
    let sortedUploadedSoilMaps = uploadedSoilMapsFileInfo && uploadedSoilMapsFileInfo.sort(this.sortUploadedSoilMaps(uploadedSoilMapsFileInfo));
    
    return {sortedYieldMaps: sortedYieldMaps, sortedUploadedSoilMaps: sortedUploadedSoilMaps}
  };  


  render () {
    const {
      fieldId,
    } = this.props;

    const {
      sortIdxYieldMaps,
      sortIdxSoilMaps,
      showRemoveYieldMapConfirmation,
      showRemoveSamApplicationConfirmation,
      showRemoveSoilMapConfirmation,
      samApplications,
      removedFileNames,
    } = this.state;

    let sortedYieldMaps = [];
    let sortedUploadedSoilMaps = [];

    if (fieldId === "farm") {
      let dataForFarm = this.getDataForFarm();
      sortedYieldMaps =  dataForFarm.sortedYieldMaps;
      sortedUploadedSoilMaps = dataForFarm.sortedUploadedSoilMaps;
    } else {
      let dataForField = this.getDataForField();
       sortedYieldMaps = dataForField.sortedYieldMaps;
       sortedUploadedSoilMaps = dataForField.sortedUploadedSoilMaps;
    };

    return (
      <React.Fragment>
        <Confirm
          open={showRemoveYieldMapConfirmation}
          header="Vill du ta bort den uppladdade skördekartan?"
          content="Denna handling går inte att ångra."
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.removeYieldMap()}
          onCancel={() => this.setState({ showConfirmRemoveYieldMap: false, fileToRemove: null })}
          size="tiny"
        />

        <Confirm
          open={showRemoveSamApplicationConfirmation}
          header="Vill du ta bort den uppladdade SAM-ansökan?"
          content="Denna handling går inte att ångra."
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.removeSamApplication()}
          onCancel={() => this.setState({ onShowConfirmRemoveSamApplication: false, fileToRemove: null })}
          size="tiny"
        />

        <Confirm
          open={showRemoveSoilMapConfirmation}
          header="Vill du ta bort den uppladdade markkarteringen?"
          content="Denna handling går inte att ångra."
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.removeSoilMap()}
          onCancel={() => this.setState({ showRemoveSoilMapConfirmation: false, fileToRemove: null })}
          size="tiny"
        />                

        <div>
          {fieldId === "farm" &&
            <Segment 
                style={{
                  width: "70%",
                  marginLeft: "15%",                  
                  padding: "1.5em",
                  minWidth: "250px",
                }}
              >
                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Uppladdade SAM-ansökningar
                </Header>
                <p
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Här kan du se en sammanställning av alla de SAM-ansökningarna som du har laddat upp till Freja.
                </p>

                {(!samApplications || Object.keys(samApplications).length === 0) && 
                  <Message fluid>
                    Freja kunde inte hitta några uppladdade SAM-ansökningar.
                  </Message>
                }

                {samApplications && Object.keys(samApplications).length > 0 &&
                  <Table sortable selectable  compact size="small">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          Filnamn 
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                        <Table.HeaderCell />                          
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {samApplications && Object.keys(samApplications).map((file, idx) => {
                        
                        if (removedFileNames.includes(file)) {
                          return null;
                        }

                        return (
                          <Table.Row
                            key={idx}
                            style={{
                              padding: 12,
                            }}
                          >
                            <Table.Cell>
                              {file && file}
                            </Table.Cell>
                            <Table.Cell>
                              <p 
                                style={{
                                  textAlign: "center",
                                  color: "#6B9D9F",
                                  cursor: "pointer",
                                }}
                                onClick={() => file && this.downloadFileFromStorage({filePath: samApplications[file]})}
                              >
                                Ladda ned
                              </p>
                            </Table.Cell>   
                            <Table.Cell>
                              <p 
                                style={{
                                  textAlign: "center",
                                  color: "#C36126",
                                  cursor: "pointer",
                                }}
                                onClick={() => file && this.onShowConfirmRemoveSamApplication(file)}
                              >
                                Ta bort
                              </p>
                            </Table.Cell>                             
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                }       
              </Segment>
            }

            <Segment 
              style={{
                width: "70%",
                marginLeft: "15%",                  
                padding: "1.5em",
                minWidth: "250px",
              }}
            >
              <Header
                as="h2"
                style={{
                  fontWeight: "normal",
                  whiteSpace: "pre-line",
                }}
              >
                Uppladdade skördekartor
              </Header>
              <p
                style={{
                  fontWeight: "normal",
                  whiteSpace: "pre-line",
                }}
              >
                Här kan du se en sammanställning av alla de
                skördekartor som du har laddat upp samt vilka skiften
                och år som de tillhör. Du kan även se status för hur Freja har bearbetat den uppladdade filen.
              </p>

              {sortedYieldMaps.length === 0 && 
                <Message fluid>
                  Freja kunde inte hitta några uppladdade skördekartor för detta skifte. 
                  Du kan ladda upp filer i menyn ovan.
                </Message>
              }

              {sortedYieldMaps.length > 0 &&
                <Table sortable selectable  compact size="small">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell onClick={this.setSortIdxYieldMaps(1)}>
                        Filnamn 
                        {sortIdxYieldMaps === 1 && <Icon name="sort ascending" />}
                        {sortIdxYieldMaps === -1 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxYieldMaps(2)}>
                        Skifte
                        {sortIdxYieldMaps === 2 && <Icon name="sort ascending" />}
                        {sortIdxYieldMaps === -2 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxYieldMaps(3)}>
                        År
                        {sortIdxYieldMaps === 3 && <Icon name="sort ascending" />}
                        {sortIdxYieldMaps === -3 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxYieldMaps(4)}>
                        Gröda
                        {sortIdxYieldMaps === 4 && <Icon name="sort ascending" />}
                        {sortIdxYieldMaps === -4 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxYieldMaps(5)}>
                        Uppladdad 
                        {sortIdxYieldMaps === 5 && <Icon name="sort ascending" />}
                        {sortIdxYieldMaps === -5 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxYieldMaps(6)}>
                        Status 
                        {sortIdxYieldMaps === 6 && <Icon name="sort ascending" />}
                        {sortIdxYieldMaps === -6 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                      <Table.HeaderCell />                          
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {sortedYieldMaps && sortedYieldMaps.map((file, idx) => {
                        return (
                          <Table.Row
                            key={idx}
                            style={{
                              padding: 12,
                            }}
                          >
                            <Table.Cell>
                              {file && file.fileName && file.fileName}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.fieldName && file.fieldName}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.year && file.year}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.crop ? file.crop : "-"}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.dateUploaded ? moment(file.dateUploaded.toDate()).format("YYYY-MM-DD") : "-"}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.status ? file.status : "-"}
                            </Table.Cell> 
                            <Table.Cell>
                              <p 
                                style={{
                                  textAlign: "center",
                                  color: "#6B9D9F",
                                  cursor: "pointer",
                                }}
                                onClick={() => file && this.downloadFileFromStorage(file)}
                              >
                                Ladda ned
                              </p>
                            </Table.Cell>   
                            <Table.Cell>
                              <p 
                                style={{
                                  textAlign: "center",
                                  color: "#C36126",
                                  cursor: "pointer",
                                }}
                                onClick={() => file && this.onShowConfirmRemoveYieldMap(file)}
                              >
                                Ta bort
                              </p>
                            </Table.Cell>                             
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </Table>
              }       
            </Segment>

            <Segment 
              style={{
                width: "70%",
                marginLeft: "15%",                  
                padding: "1.5em",
                minWidth: "250px",
                marginBottom: fieldId !== "farm" ? null : 40,
              }}
            >
              <Header
                as="h2"
                style={{
                  fontWeight: "normal",
                  whiteSpace: "pre-line",
                }}
              >
                Uppladdade markkarteringar
              </Header>
              <p
                style={{
                  fontWeight: "normal",
                  whiteSpace: "pre-line",
                }}
              >
                Här kan du se en sammanställning av alla de
                markkarteringar som du har laddat upp samt vilka skiften
                och år som de tillhör. Du kan även se status för hur Freja har bearbetat den uppladdade filen.
              </p>

              {sortedUploadedSoilMaps.length === 0 && 
                <Message fluid>
                  Freja kunde inte hitta några uppladdade markkarteringar för detta skifte. 
                  Du kan ladda upp filer i menyn ovan.
                </Message>
              }

              {sortedUploadedSoilMaps.length > 0 &&
                <Table sortable selectable compact size="small">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell onClick={this.setSortIdxSoilMaps(1)}>
                        Filnamn 
                        {sortIdxSoilMaps === 1 && <Icon name="sort ascending" />}
                        {sortIdxSoilMaps === -1 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxSoilMaps(2)}>
                        Skiften
                        {sortIdxSoilMaps === 2 && <Icon name="sort ascending" />}
                        {sortIdxSoilMaps === -2 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxSoilMaps(3)}>
                        År
                        {sortIdxSoilMaps === 3 && <Icon name="sort ascending" />}
                        {sortIdxSoilMaps === -3 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxSoilMaps(4)}>
                        Koordinatsystem
                        {sortIdxSoilMaps === 4 && <Icon name="sort ascending" />}
                        {sortIdxSoilMaps === -4 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxSoilMaps(5)}>
                        Uppladdad 
                        {sortIdxSoilMaps === 5 && <Icon name="sort ascending" />}
                        {sortIdxSoilMaps === -5 && <Icon name="sort descending" />}
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={this.setSortIdxSoilMaps(6)}>
                        Status 
                        {sortIdxSoilMaps === 6 && <Icon name="sort ascending" />}
                        {sortIdxSoilMaps === -6 && <Icon name="sort descending" />}
                      </Table.HeaderCell>    
                      <Table.HeaderCell />
                      <Table.HeaderCell />                                          
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {sortedUploadedSoilMaps && sortedUploadedSoilMaps.map((file, idx) => {
                        return (
                          <Table.Row
                            key={idx}
                            style={{
                              padding: 12,
                            }}
                          >
                            <Table.Cell>
                              {file && file.fileName && file.fileName}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.fieldNames && file.fieldNames}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.year && file.year}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.coordinateSystem ? file.coordinateSystem : "-"}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.dateUploaded ? moment(file.dateUploaded.toDate()).format("YYYY-MM-DD") : "-"}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.status ? file.status : "-"}
                            </Table.Cell>   
                            <Table.Cell>
                              <p 
                                style={{
                                  textAlign: "center",
                                  color: "#6B9D9F",
                                  cursor: "pointer",
                                }}
                                onClick={() => file && this.downloadFileFromStorage(file)}
                              >
                                Ladda ned
                              </p>
                            </Table.Cell>   
                            <Table.Cell>
                              <p 
                                style={{
                                  textAlign: "center",
                                  color: "#C36126",
                                  cursor: "pointer",
                                }}
                                onClick={() => file && this.onShowConfirmRemoveSoilMap(file)}
                              >
                                Ta bort
                              </p>
                            </Table.Cell>                                                    
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </Table>
              }        
            </Segment>  
        </div>
        <div style={{ height: "1em "}} />
      </React.Fragment>        
    )
  }
}

export default withRouter(FileManagerFileList);
