import React, { Component } from "react";

import { 
  Image, 
  Icon, 
  Popup,
  Segment,
  Header,
  Loader,
  Button,
} from "semantic-ui-react";

import {
  isEmpty,
  isLoaded,
} from "react-redux-firebase";

import FieldCartoon from "@/components/misc/FieldCartoon";

import ImageCrop from "@/assets/icons/crop.svg";
import ImageHectar from "@/assets/icons/hectar2.svg";
import ImageFarm from "@/assets/icons/dataTypes/farm.svg";
import ImageYieldMaps from "@/assets/icons/yield-maps.svg";

import { FIELD_DATA_LAYERS } from "@/constants/viewLayersAnalysis";
import { CROP_CODES } from "@/constants/cropCodes";

import { getCurrentYear, getPreviousYear } from "@/helpers/dates";

class MenuFieldList extends Component {

  openField = (newFieldId, availableToUser) => {
    availableToUser && this.props.openField(newFieldId);
    !availableToUser && this.props.openUnlockField(newFieldId);
  };

  getNotificationDot = () => (
    <div 
      style={{ 
        position: "relative", 
        top: "-1.7em", 
        right: "-1.7em", 
        width: "0.5em", 
        height: "0.5em", 
        borderRadius: "50%", 
        backgroundColor: "#C36126" 
      }} 
    />
  );

  getDataIcons = ( dataTypes, newDataAvailable, showNewDataDots, isSelected) => (
    <div style={{ marginTop: 12, display: "flex" }}>
      {dataTypes && dataTypes.includes("sentinel") && 
        <Popup
          trigger={
            <div 
              className="fieldCartoon"
              style={{
                backgroundColor: isSelected ? "white" : "#F8EED0",
                borderRadius: 5,
                width: "2.4em",
                height: "2.4em",
                marginRight: "0.4em",
              }}
            >
              <Image 
                style={{ 
                  marginTop: "0.5em",
                  marginLeft: "0.5em",
                  width: "1.4em", 
                  height: "1.4em",
                  filter: "invert(0%)",
                }}
                src={FIELD_DATA_LAYERS.trueColor.icon} 
              />

              {showNewDataDots && newDataAvailable.sentinel && newDataAvailable.sentinel.length > 0 && this.getNotificationDot()} 
            </div>
          }
          content={'Detta skifte har' + ((newDataAvailable && newDataAvailable.sentinel && newDataAvailable.sentinel.length > 0)  ? ' nya ' : ' ') + 'satellitbilder tillgängliga.'}
        />
      }

      {dataTypes && 
        (dataTypes.includes("soilMap") || dataTypes.includes("soilMapSweden") || dataTypes.includes("soilMapSwedish") || dataTypes.includes("soilMapUploaded")) && 
        <Popup
          trigger={
            <div 
              className="fieldCartoon"
              style={{
                backgroundColor: isSelected ? "white" : "#F8EED0",
                borderRadius: 5,
                width: "2.4em",
                height: "2.4em",
                marginRight: "0.4em",
              }}
            >
              <Image 
                style={{ 
                  marginTop: "0.5em",
                  marginLeft: "0.5em",
                  width: "1.4em", 
                  height: "1.4em",
                  filter: "invert(0%)",
                }}
                src={FIELD_DATA_LAYERS.soilMapSweden.icon} 
              />
              
              {showNewDataDots && newDataAvailable.soilMapSweden && newDataAvailable.soilMapSweden.length > 0 && this.getNotificationDot()} 
              {showNewDataDots && newDataAvailable.soilMapUploaded && newDataAvailable.soilMapUploaded.length > 0 && this.getNotificationDot()} 
            </div>
          }
          content={'Detta skifte har' + ((newDataAvailable && newDataAvailable.soilMap && newDataAvailable.soilMap.length > 0) ? ' nya ' : ' ') + 'markkartor tillgängliga.'}
        />
      }

      {dataTypes && dataTypes.includes("yieldMap") && 
        <Popup
          trigger={
            <div 
              className="fieldCartoon"
              style={{
                backgroundColor: isSelected ? "white" : "#F8EED0",
                borderRadius: 5,
                width: "2.4em",
                height: "2.4em",
              }}
            >
              <Image 
                style={{ 
                  marginTop: "0.5em",
                  marginLeft: "0.5em",
                  width: "1.4em", 
                  height: "1.4em",
                  filter: "invert(0%)",
                }}
                src={FIELD_DATA_LAYERS.yieldMap.icon} 
              />

              {showNewDataDots && newDataAvailable.yieldMap && newDataAvailable.yieldMap.length > 0 && this.getNotificationDot()} 
            </div>
          }
          content={'Detta skifte har' + ((newDataAvailable && newDataAvailable.yieldMap && newDataAvailable.yieldMap.length > 0)  ? ' nya ' : ' ') + 'skördekartor tillgängliga.'}
        />      
      }
    </div>
  )

  getFieldCards = (fieldId, idx, currentFieldId, onlyAvailable, showNewDataDots) => {
    const {
      db,
      selectedFieldIds,
      compact,
      additionalInformation,
      disabledList,
      disableHover,
      hideAvailableDataTypes,
      addCropInformation,
    } = this.props;

    let currentYear = getCurrentYear();
    let lastYear = getPreviousYear();

    let field = db && db.fields &&  db.fields[fieldId] && db.fields[fieldId];
    let fieldsInfo = db && db.fieldsInfo &&  db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];
    let position = field && field.position;
    
    let availableToUser = field && field.available_to_user;
    availableToUser = typeof(availableToUser) === "boolean" ? availableToUser : true;

    let disabled = field && field.disabled;
    disabled = typeof(disabled) === "boolean" ? disabled : false;    

    let isCompact = compact && typeof(compact) === "boolean" ? compact : false;
    let isSelected = selectedFieldIds && selectedFieldIds.includes(fieldId);
    
    let size = field && field.field_size && field.field_size;
    size = size && size.toFixed(1) + " ha"
    
    let name = field && field.name && field.name;
    
    let currentCrop = fieldsInfo && fieldsInfo.crop_code && fieldsInfo.crop_code[currentYear];
    currentCrop = currentCrop && CROP_CODES.find((x) => x.value === currentCrop) && CROP_CODES.find((x) => x.value === currentCrop).shorttext;
    currentCrop = currentCrop ? currentCrop : "Okänd gröda"

    let lastCrop = fieldsInfo && fieldsInfo.crop_code && fieldsInfo.crop_code[lastYear];
    lastCrop = lastCrop && CROP_CODES.find((x) => x.value === lastCrop) && CROP_CODES.find((x) => x.value === lastCrop).shorttext;
    lastCrop = lastCrop ? lastCrop : "Okänd gröda"    

    let dataTypes = field && field.data_types && field.data_types;     
    let newDataAvailable = field && field.new_data_available && field.new_data_available;

    if ((availableToUser === false && onlyAvailable === true) || (availableToUser === true && onlyAvailable === false)) {
      return null
    };

    if (disabled) {
      return null
    };

    availableToUser = disabledList ? false : availableToUser;

    let cartoonColor = availableToUser ? "#e0ba8c" : "#888888";

    let backgroundColor = availableToUser ? (currentFieldId === fieldId ? "#F8EED0" : "#ffffff") : "#868D8E";
    backgroundColor = isSelected ? "#F8EED0" : backgroundColor;

    let className = (availableToUser && currentFieldId !== fieldId) ? "fieldCardOnHover" : null;
    className = disableHover ? null : className;
    className = disabledList ? null : className;

    return (
      <React.Fragment key={`fieldData_fieldLayers_${fieldId}`}>
        <Segment
          className={className}
          onClick={e => this.openField(fieldId, availableToUser)}
          style={{
            backgroundColor: backgroundColor,
            padding: "0.5em",
            marginTop: 0,
            marginBottom: 10,
            cursor: (disableHover || disabledList) ? null : "pointer",
            height: isCompact ? "8em" : "8.5em",
            boxShadow: "none",
          }}
        >
          <div
            style={{
              display: "flex", 
            }}
          >
            <div
              style={{ 
                width: isCompact ? null : "30%",
                maxWidth: "6em",
              }}
            >
              <div
                className="fieldCartoon"
                style={{
                  backgroundColor: (currentFieldId === fieldId || isSelected) ? "white" : (availableToUser ? "#F8EED0" : "transparent"),
                  borderRadius: 5,
                  width: "6em",
                  height: "6em",
                  marginLeft: "0.25em",
                  marginRight: "1em",
                  marginTop: "0.75em",
                }}
              >
                {position && 
                  <FieldCartoon
                    style={{
                      margin: 0,
                      padding: 0,
                      marginTop: "1em",
                      marginLeft: "1em",
                      height: "4em",
                      width: "4em",
                      zIndex: 20,
                    }}            
                    stroke={cartoonColor}
                    color="white"
                    position={position}
                  />
                }    
              </div>
            </div>
            
            <div
              style={{
                marginLeft: 20,
                width: isCompact ? null : "calc(100% - 80px)",
                marginTop: isCompact ? "auto" : null,
                marginBottom: isCompact ? "auto" : 0,
              }}
            >
              <div 
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Header
                  style={{ 
                    fontWeight: 500,
                    color: availableToUser ? "black" : "white",
                    paddingTop: isCompact ? "5px" : "10px",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  {name && name}
                </Header>

                {!disabledList && !isCompact && currentFieldId !== fieldId && 
                  <Icon 
                    style={{ 
                      marginTop: 15, 
                      textAlign: "right" 
                    }} 
                    name="chevron right" 
                    size="small" 
                  />
                }         
              </div>

              {!isCompact && size && 
                <p 
                  style={{ 
                    marginTop: addCropInformation ? 5 : null,
                    marginBottom: addCropInformation ? 0 : 10, 
                    color: availableToUser ? "grey" : "white", 
                  }}
                >
                  <Image 
                    inline 
                    src={ImageHectar} 
                    style={{ 
                      width: "15px", 
                      filter: availableToUser ? "brightness(0) invert(50%)" : "brightness(0) invert(100%)",
                      marginRight: 5,
                    }} 
                  /> 
                  {size}
                </p>
              }

              {addCropInformation && currentCrop && lastCrop &&
                <p 
                  style={{ 
                    marginTop: 0,
                    marginBottom: 0, 
                    color: availableToUser ? "grey" : "white", 
                  }}
                >
                  <Image 
                    inline 
                    src={ImageCrop} 
                    style={{ 
                      width: "15px", 
                      filter: availableToUser ? "brightness(0) invert(50%)" : "brightness(0) invert(100%)",
                      marginRight: 5,
                    }} 
                  /> 
                  {currentYear + ": " + currentCrop},  
                  {" " + lastYear + ": " + lastCrop} 
                </p>
              }           

              {additionalInformation && additionalInformation[fieldId] && additionalInformation[fieldId].noYieldMaps &&
                <p 
                  style={{ 
                    marginBottom: 10, 
                    color: availableToUser ? "grey" : "white", 
                  }}
                >
                  <Image 
                    inline 
                    src={ImageYieldMaps} 
                    style={{ 
                      width: "15px", 
                      filter: availableToUser ? null : "brightness(0) invert(100%)" 
                    }} 
                  /> 
                  {"  " + additionalInformation[fieldId].noYieldMaps.toFixed(0) + " st."}
                </p>
              }

              {additionalInformation && additionalInformation[fieldId] && additionalInformation[fieldId].reason &&
                <div 
                  style={{ 
                    marginTop: additionalInformation[fieldId].noYieldMaps ? 15 : 0,
                    width: "70%",
                    fontSize: "90%",
                    color: "white",
                    whiteSpace: "pre-line",
                  }}>
                  <Icon 
                    style={{ 
                      textAlign: "right" 
                    }} 
                    name="warning sign" 
                    color="red"
                    size="small" 
                  />
                    {additionalInformation[fieldId].reason}
                  </div>
                }       

              {!disabledList && !availableToUser && <p style={{ marginTop: 15, color: "white", }}>Klicka för att låsa upp.</p>}   

              {!isCompact && !hideAvailableDataTypes && availableToUser && dataTypes && this.getDataIcons(dataTypes, newDataAvailable, showNewDataDots, currentFieldId === fieldId)}
            </div>
          </div>
        </Segment>
    </React.Fragment>
    )
  };

  getFarmCard = (fieldId) => {
    const {
      db,
    } = this.props;

    let fieldDb = db && db.fields;
    
    let fieldIds = fieldDb && isLoaded(fieldDb) && 
      Object.keys(fieldDb)
      .filter((x) => {
        if (fieldDb[x]) {
          if (typeof(fieldDb[x].available_to_user) !== "boolean") {
            return true;
          };

          if (typeof(fieldDb[x].available_to_user) === "boolean" && fieldDb[x].available_to_user === true) {
            return true;
          };

          return false;
        } else {
          return false;
        }
      })

    let totalArea = db && fieldIds && fieldIds
      .map(x => fieldDb[x].field_size && fieldDb[x].field_size)
      .reduce((partial_sum, a) => partial_sum + a, 0);
    totalArea = totalArea ? totalArea : null;

    let name = db && db.profile && db.profile.farm && db.profile.farm.name;
    let size = totalArea && totalArea.toFixed(0) + " ha";
    let noFields = fieldIds && fieldIds.length + " st";

    return (
      <React.Fragment>
        <Segment
          className={"farm" !== fieldId ? "fieldCardOnHover" : null}
          onClick={e => this.props.openField("farm")}
          style={{
            backgroundColor: "farm" === fieldId ? "#e0ba8c" : "#ffffff",
            padding: "0.5em",
            marginTop: 0,
            marginBottom: 10,
            cursor: "pointer",
            height: "8.5em",
            boxShadow: "none",
          }}
        >
          <div
            style={{
              display: "flex", 
            }}
          >
            <div
              style={{ 
                width: "30%",
                maxWidth: "6em",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F8EED0",
                  borderRadius: 5,
                  width: "6em",
                  height: "6em",
                  marginLeft: "0.25em",
                  marginRight: "1em",
                  marginTop: "0.75em",
                }}
              >
                <Image 
                  style={{
                    display: "inline-block",
                    margin: 0,
                    padding: 0,
                    marginTop: "1em",
                    marginLeft: "1em",
                    height: "4em",
                    width: "4em",
                    zIndex: 20,
                    filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)"
                  }}
                  src={ImageFarm} 
                />                
              </div>
            </div>

            <div
              style={{
                marginLeft: 20,
                width: "70%",
              }}
            >
              <div 
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  
                }}
              >
                <Header
                  style={{ 
                    fontWeight: 500,
                    paddingTop: "10px",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  {name && name}
                </Header>

                {"farm" !== fieldId && <Icon style={{ marginTop: 15, textAlign: "right" }} name="chevron right" size="small" />}
              </div>

              {noFields && <p style={{ color: "grey", marginBottom: 8 }}><Image inline src={ImageYieldMaps} style={{ width: "15px" }} /> {noFields}</p>}    
              {size && <p style={{ marginTop: -8, color: "grey" }}><Image inline src={ImageHectar} style={{ width: "15px" }} /> {size}</p>}    
            </div>
          </div>
        </Segment>
    </React.Fragment>
    )
  };  

  getEmptyScreen = () => (
    <Segment
      style={{
        height: "100%",
      }}            
    >
      <div
        style={{
          fontSize: "140%", 
          marginTop: "25vh",
          color: "grey",
          textAlign: "center",
        }}
      >
        Inga skiften hittades
      </div>

      <div
        style={{
          color: "grey",
          marginTop: "1em",
          marginLeft: "20%",
          width: "60%",
          whiteSpace: "pre-line",
          textAlign: "center",
        }}
      >
        Vänligen lägg till skiften innan du kan börja använda Freja.
      </div>      

      <div
        style={{
          marginTop: "2em",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Button
          primary
          href="/guidesAddNewFieldsV1/addField"
        >
          Skapa gård
        </Button>
      </div>
    </Segment>   
  )

  getLoaderScreen = () => (
    <Segment
      style={{
        height: "100%",
      }}            
    >
      <Loader 
        active
        inline="centered"
        indeterminate 
        size="massive"
        style={{
          marginTop: "100%",
        }}
      >
        <div
          style={{
            fontSize: "80%", 
            marginTop: "0.5em",
            color: "grey",
          }}
        >
          Öppnar skiften...
        </div>
      </Loader>            
    </Segment>   
  )

  render() {
    const {
      db,
      fieldId,
      showNewDataDots,
      hideFarmCard,
      hideUnLockedFields,
      hideLockedFields,
      showOnlyFieldIds,
    } = this.props;

    let fieldIds = db && db.fields && Object.keys(db.fields);
    fieldIds = fieldIds && showOnlyFieldIds && Array.isArray(showOnlyFieldIds) ? fieldIds.filter((x) => showOnlyFieldIds.includes(x)) : fieldIds;
    
    db && db.fields && fieldIds && fieldIds.sort(function(a, b) { 
      let sortedArray = [db.fields[b] && db.fields[b].name, db.fields[a] && db.fields[a].name].sort()
      if (sortedArray[0] === db.fields[b].name) {
        return 1.0
      } else {
        return -1.0
      }
    });

    let farmCard = this.getFarmCard(fieldId);
    let fieldItemsAvailable = fieldIds && fieldIds.map((x, idx) => this.getFieldCards(x, idx, fieldId, true, showNewDataDots));
    let fieldItemsUnAvailable = fieldIds && fieldIds.map((x, idx) => this.getFieldCards(x, idx, fieldId, false, showNewDataDots));
    fieldItemsUnAvailable = fieldItemsUnAvailable && this.props.openUnlockField ? fieldItemsUnAvailable : null;

    if (!isLoaded(db) || !isLoaded(db.fields)) {
      return this.getLoaderScreen()
    };

    if (isLoaded(db.fields) && isEmpty(db.fields)) {
      return this.getEmptyScreen()
    };

    return (
      <div
        style={{
          paddingTop: "-10px"
        }}      
      >
        {typeof(hideFarmCard) === "boolean" && hideFarmCard === true ? null : farmCard && farmCard}
        {typeof(hideUnLockedFields) === "boolean" && hideUnLockedFields === true ? null : fieldItemsAvailable && fieldItemsAvailable}
        {typeof(hideLockedFields) === "boolean" && hideLockedFields === true ? null : fieldItemsUnAvailable && fieldItemsUnAvailable}
      </div>
    );
  }
}

export default MenuFieldList;
