import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Icon,
  Segment,
  Header,
  Table,
  Message,
  Dropdown,
} from "semantic-ui-react";

class FileManagerFileList extends Component {

  state = {
    activeYear: "2022",
    sortIdxSentinelMaps: 1,
    rawSentinelMapJson: null,
  };

  componentDidMount() {
    this.fetchData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.rawSentinelMaps !== prevProps.rawSentinelMaps || this.props.fieldId !== prevProps.fieldId) {
      this.fetchData();
    }
  };

  fetchData = () => {
    const {
      rawSentinelMaps,
      fileBucketRef,
    } = this.props;

    this.setState({
      rawSentinelMapJson: null,
    });
    
    if (rawSentinelMaps && rawSentinelMaps.rawdata_sentinel_optical_info_json) {
      fileBucketRef.child(rawSentinelMaps.rawdata_sentinel_optical_info_json).getDownloadURL()
      .then(url => {
        window.fetch(url)
          .then(async res => { 
            this.setState({ rawSentinelMapJson: await res.clone().json() });
          })
      })
      .catch(error => console.log("Cannot get download URL: ", error.toString()))
    };
  }; 

  setSortIdxSentinelMaps = idx => () => {
    if (idx === this.state.sortIdxSentinelMaps) {
      this.setState({ sortIdxSentinelMaps: -idx });
    } else {
      this.setState({ sortIdxSentinelMaps: idx });
    }
  };

  sortDownloadedSentinelMaps = sentinelMap => (a, b) => {
    const fieldA = a.fieldName;
    const fieldB = b.fieldName;

    const dateA = a.date;
    const dateB = b.date;

    const statusA = a.status;
    const statusB = b.status;
    
    switch (this.state.sortIdxSentinelMaps) {
      case 1:
        return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
      case -1:
        return -1 * (fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0);
      case 2:
        return new Date(dateA) - new Date(dateB);
      case -2:
        return -1 * (new Date(dateA) - new Date(dateB));
      case 3:
        return new Date(dateA) - new Date(dateB);
      case -3:
        return -1 * (new Date(dateA) - new Date(dateB));     
      case 4:
        return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
      case -4:
        return -1 * (statusA < statusB ? -1 : statusA > statusB ? 1 : 0);
      default: 
        return -1;        
    };
  }; 

  getDataForField = () => {
    const {
      fields,
      fieldId,
      rawSentinelMaps,
      processedSentinelMaps,
    } = this.props;

    const {
      rawSentinelMapJson,
    } = this.state;

    let field = fieldId && fields && fields[fieldId] && fields[fieldId];

    let rawSentinelAvailableDates = rawSentinelMapJson && rawSentinelMapJson.available_dates &&
      Object.keys(rawSentinelMapJson.available_dates).map(x => x);

    let rawSentinelDiscardedDates = rawSentinelMapJson && rawSentinelMapJson.removed_capture_times &&
      Object.keys(rawSentinelMapJson.removed_capture_times).map(x => x);

    let downloadedSentinelMaps = [];
            
    rawSentinelAvailableDates && rawSentinelAvailableDates &&
      rawSentinelAvailableDates.sort().reverse().forEach((date, idx) => {
        let time = rawSentinelMapJson && 
          rawSentinelMapJson.capture_times && 
          rawSentinelMapJson.capture_times[date] && 
          rawSentinelMapJson.capture_times[date];
        
        let fracMissing = rawSentinelMapJson && 
          rawSentinelMapJson.output_frac_pixels_missing && 
          rawSentinelMapJson.output_frac_pixels_missing[date] && 
          rawSentinelMapJson.output_frac_pixels_missing[date];

        let fracCloudy = rawSentinelMapJson && 
          rawSentinelMapJson.output_frac_pixels_cloudy && 
          rawSentinelMapJson.output_frac_pixels_cloudy[date] && 
          rawSentinelMapJson.output_frac_pixels_cloudy[date];

        let status = "Hämtad"
        
        status = status && 
          rawSentinelMaps && 
          rawSentinelMaps &&
          rawSentinelMaps.rawdata_sentinel_true_color_png &&
          rawSentinelMaps.rawdata_sentinel_true_color_png.find(x => x === date) ? 
          "Bearbetad" : status;

        status = status && 
          processedSentinelMaps && 
          processedSentinelMaps &&
          processedSentinelMaps.rawdata_sentinel_dates && 
          processedSentinelMaps.rawdata_sentinel_dates.find(x => x === date) ?
          "Tolkad" : status; 
        
        downloadedSentinelMaps = [...downloadedSentinelMaps,
        {
            date: date,
            time: time,
            fracMissing: fracMissing,
            fracCloudy: fracCloudy,
            fieldId: fieldId,
            fieldName: field && field && field.name && field.name,
            status: status,
        }
        ]
    });

    let discardedSentinelMaps = [];
    
    rawSentinelDiscardedDates && rawSentinelDiscardedDates &&
      rawSentinelDiscardedDates.sort().reverse().forEach((dateTime, idx) => {

        let date = dateTime.split(" ")[0];
        let time = dateTime.split(" ")[1];

        let reason = rawSentinelMapJson && 
          rawSentinelMapJson.removed_capture_times && 
          rawSentinelMapJson.removed_capture_times[dateTime] && 
          rawSentinelMapJson.removed_capture_times[dateTime];

        let percent = reason && reason.split("_").slice(-1)[0];
        percent = percent && (Number(percent) * 100.0).toFixed(0)

        reason = reason && reason === "date_duplicate" ? "Flera bilder fanns från samma dag. Freja valde ut en annan som bedömdes vara bättre." : reason;
        reason = reason && reason.includes("many_pixels_cloudy_") ? "Bilden var för molning. " + percent + "% av bilden täcktes av moln." : reason;
        reason = reason && reason.includes("many_pixels_missing_") ? "Bilden saknade information. " + percent + "% av pixlarna saknades." : reason;
        reason = reason && reason.includes("low_brightness") ? "Bilden var för mörk. Ljusstrykan var " + percent + "%." : reason;

        
        discardedSentinelMaps = [...discardedSentinelMaps,
        {
            date: date,
            time: time,
            reason: reason,
            fieldId: fieldId,
            fieldName: field && field && field.name && field.name,
        }
        ]
    });

    let sortedSentinelMaps = downloadedSentinelMaps && downloadedSentinelMaps.sort(this.sortDownloadedSentinelMaps(downloadedSentinelMaps));
    
    return { sortedSentinelMaps: sortedSentinelMaps, discardedSentinelMaps: discardedSentinelMaps }
  };

  onlyUnique = (value, index, self) => { 
    return self.indexOf(value) === index;
  };

  render () {
    const {
      fieldId,
    } = this.props;

    const {
      activeYear,
      sortIdxSentinelMaps,
    } = this.state;

    let sortedSentinelMaps = [];
    let discardedSentinelMaps = [];

    if (fieldId === "farm") {
    } else {
      let dataForField = this.getDataForField();
       sortedSentinelMaps = dataForField.sortedSentinelMaps;
       discardedSentinelMaps = dataForField.discardedSentinelMaps;
    };

    let dataYears = sortedSentinelMaps && sortedSentinelMaps.map((x) => x.date && x.date.split("-")[0])
    dataYears = dataYears && dataYears.filter(this.onlyUnique).sort();

    let menuItems = dataYears && dataYears.map((x) => (
      {
        key: x,
        value: x,
        text: parseInt(x),
        onClick: () => this.setState({ activeYear: x}),    
      }
    ));    
    
    sortedSentinelMaps = sortedSentinelMaps && sortedSentinelMaps.filter((x) => x.date.includes(activeYear));
    discardedSentinelMaps = discardedSentinelMaps && discardedSentinelMaps.filter((x) => x.date.includes(activeYear));

    return (
      <React.Fragment>
        <div>
          {fieldId === "farm" &&
            <Segment 
                style={{
                  width: "70%",
                  marginLeft: "15%",                  
                  padding: "1.5em",
                  minWidth: "250px",
                }}
              >
                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Nedladdad data från Sentinel Hub (Satellitbilder)
                </Header>
                <p
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Välj ett skifte för att se information om vilken data som har laddats ned från Sentinel Hub.
                </p>
              </Segment>
            }

            {fieldId !== "farm" &&
              <Segment 
                style={{
                  width: "70%",
                  marginLeft: "15%",                  
                  padding: "1.5em",
                  minWidth: "250px",
                  marginBottom: 40,
                }}
              >
                <div 
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.25em",
                  }}
                >
                  <Header
                    as="h2"
                    style={{
                      fontWeight: "normal",
                      whiteSpace: "pre-line",
                    }}
                  >
                    Nedladdad data från Sentinel Hub (Satellitbilder)
                  </Header>
                                    
                  <Dropdown
                    style={{
                      border: "1px solid white",
                      borderRadius: 5,
                      padding: 5,
                      paddingLeft: 12,
                    }}
                    className="textLink"
                    options={menuItems}
                    value={activeYear}
                  />
                </div>
                
                <p
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                    marginBottom: "1em",
                  }}
                >
                  Här kan du se en sammanställning av alla de
                  bilder som Freja har laddat ned från Sentinel Hub samt vilka tider som dessa togs.
                  Du kan även se status för hur Freja har bearbetat den uppladdade filen.
                </p>

                {sortedSentinelMaps.length === 0 && 
                  <Message fluid>
                    Freja kunde inte hitta några nedladdade bilder som Sentinel Hub för detta skifte. 
                  </Message>
                }

                {sortedSentinelMaps.length > 0 &&
                  <Table sortable selectable compact size="small">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell onClick={this.setSortIdxSentinelMaps(1)}>
                          Skifte
                          {sortIdxSentinelMaps === 2 && <Icon name="sort ascending" />}
                          {sortIdxSentinelMaps === -2 && <Icon name="sort descending" />}
                        </Table.HeaderCell>
                        <Table.HeaderCell onClick={this.setSortIdxSentinelMaps(2)}>
                          Datum
                          {sortIdxSentinelMaps === 3 && <Icon name="sort ascending" />}
                          {sortIdxSentinelMaps === -3 && <Icon name="sort descending" />}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Tid
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Andel saknade pixlar
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Andel molniga pixlar
                        </Table.HeaderCell>                            
                        <Table.HeaderCell onClick={this.setSortIdxSentinelMaps(4)}>
                          Status 
                          {sortIdxSentinelMaps === 6 && <Icon name="sort ascending" />}
                          {sortIdxSentinelMaps === -6 && <Icon name="sort descending" />}
                        </Table.HeaderCell>                    
                      </Table.Row>
                    </Table.Header>
                    
                    <Table.Body>
                      {sortedSentinelMaps && sortedSentinelMaps.map((file, idx) => {
                        return (
                          <Table.Row
                            key={idx}
                            style={{
                              padding: 12,
                            }}
                          >
                            <Table.Cell>
                              {file && file.fieldName && file.fieldName}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.date ? file.date : "-"}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.time ? file.time.split(" ")[1] : "-"}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.fracMissing ? (100.0 * file.fracMissing).toFixed(0) + " %" : "0 %"}
                            </Table.Cell>
                            <Table.Cell>
                              {file && file.fracCloudy ? (100.0 * file.fracCloudy).toFixed(0) + " %" : "0 %"}
                            </Table.Cell>                                                            
                            <Table.Cell>
                              {file && file.status ? file.status : "-"}
                            </Table.Cell>                          
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                }       
              </Segment>              
            }   

            {fieldId !== "farm" &&
              <Segment 
                style={{
                  width: "70%",
                  marginLeft: "15%",                  
                  padding: "1.5em",
                  minWidth: "250px",
                  marginBottom: 40,
                }}
              >
                <div 
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.25em",
                  }}
                >
                  <Header
                    as="h2"
                    style={{
                      fontWeight: "normal",
                      whiteSpace: "pre-line",
                    }}
                  >
                    Icke nedladdad data från Sentinel Hub (Satellitbilder)
                  </Header>
                                    
                  <Dropdown
                    style={{
                      border: "1px solid white",
                      borderRadius: 5,
                      padding: 5,
                      paddingLeft: 12,
                    }}
                    className="textLink"
                    options={menuItems}
                    value={activeYear}
                  />
                </div>

                <p
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                    marginBottom: "1em",
                  }}
                >
                  Här kan du se en sammanställning av alla de bilder som Freja har hittat men inte laddat ned från Sentinel Hub.
                </p>

                {discardedSentinelMaps.length === 0 && 
                  <Message fluid>
                    Freja kunde inte hitta några icke nedladdade bilder som Sentinel Hub för detta skifte. 
                  </Message>
                }

                {discardedSentinelMaps.length > 0 &&
                  <Table 
                    sortable 
                    selectable 
                    compact 
                    size="small"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          Skifte
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Datum
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Tid
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Anledning
                        </Table.HeaderCell>                
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {discardedSentinelMaps && discardedSentinelMaps.map((file, idx) => {
                          return (
                            <Table.Row
                              key={idx}
                              style={{
                                padding: 12,
                              }}
                            >
                              <Table.Cell>
                                {file && file.fieldName && file.fieldName}
                              </Table.Cell>
                              <Table.Cell>
                                {file && file.date ? file.date : "-"}
                              </Table.Cell>
                              <Table.Cell>
                                {file && file.time ? file.time : "-"}
                              </Table.Cell>
                              <Table.Cell>
                                {file && file.reason ? file.reason : "-"}
                              </Table.Cell>                    
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                }       
              </Segment>  
            }                       
        </div>
        <div style={{ height: "1em "}} />
      </React.Fragment>        
    )
  }
}

export default withRouter(FileManagerFileList);
