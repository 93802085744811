import React, { Component } from "react";

import { 
  Button,
  Message, 
} from "semantic-ui-react";

import { cloudFunctions } from "@";

import { InvertedSelect } from "@/components/misc/InvertedSelect";

import { CROP_CODES } from "@/constants/cropCodes";

const INITIAL_STATE = {
  open: false,
  done: false,
  newCrop: '',
};

class FieldDataGuideAddCrop extends Component {
  state = { ...INITIAL_STATE };
  
  onChangeCropCode = (newCrop) => {
    this.setState({ newCrop })
  };

  onSaveCropCode = () => {
    const { 
      fieldInfo, 
      userId,
      fieldId,
      layerId,
    } = this.props;

    const {
      newCrop,
    } = this.state;

    let year = layerId && layerId.split("-") && layerId.split("-")[0].toString();

    // Update crop codes
    let currentCropCode = fieldInfo && fieldInfo.crop_code;

    if (year && currentCropCode) {
      currentCropCode = {...currentCropCode, [year]: newCrop };
    } else {
      currentCropCode = { [year]: newCrop };
    };
    
    currentCropCode && this.props.getUser().collection("fieldsInfo").doc(fieldId).update({ crop_code: currentCropCode});
    currentCropCode && cloudFunctions.httpsCallable('updatedCropCodes')(
      {
        userId: userId,
        fieldId: fieldId,
        year: year,
      }
    );

    this.setState({ 
      done: true, 
      newCrop: '' 
    });
    
    this.props.setHideWeather(false);
  };  

  onOpenDropdown = () => {
    this.setState({ open: true });
    this.props.setHideWeather(true);
  };

  render() {
    const {
      done,
    } = this.state;

    let open = (typeof(this.props.open) === "boolean" && this.state.open === false) ? this.props.open : this.state.open;
    let newCrop = (this.props.currentCropCode && this.state.newCrop === '') ? this.props.currentCropCode : this.state.newCrop;

    if (!done && open) {
      return (
        <React.Fragment>
          <div style={{ marginTop: 20 }} />

          <InvertedSelect
            fluid
            onChange={(event, data) => this.onChangeCropCode(data.value)}
            value={newCrop}
            options={CROP_CODES}
            placeholder="Välj en gröda..."
          />

          <Button
            fluid
            inverted
            style={{
              marginTop: 10,
            }}
            disabled={!newCrop}
            onClick={() => this.onSaveCropCode()}
          >
          Spara gröda
        </Button>          
        </React.Fragment>
      )
    };

    if (!done && !open) {
      return (
        <Button
          fluid
          inverted
          style={{
            marginTop: 10,
          }}
          onClick={() => this.onOpenDropdown()}
        >
          Ange gröda
        </Button>
      )
    }; 
    
    if (done) {
      return (
        <Message 
        style={{
          backgroundColor: "#89A275",
          color: "white",                      
          marginTop: '20px',
          whiteSpace: "pre-line",
        }}                
        positive
      >
        Grödkoden har registrerats och Freja kommer inom kort uppdatera tolkningarna för detta skifte.
      </Message>
      )
    }
  }
}

export default FieldDataGuideAddCrop;
