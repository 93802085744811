import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {  
  Segment,
  Icon,
  Header,
  Button,
} from "semantic-ui-react";

const INITIAL_STATE = {};

class AddFieldsBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {
    return (
      <Segment
        style={{
          marginLeft: "16.5%",
          width: "67%",
          marginBottom: "3em",
          padding: "1.5em",
          paddingTop: 0, 
          paddingRight: 0,
          fontWeight: "normal",
          background: "#6B9D9F",
          color: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              paddingTop: "1.5em",
              width: "20%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Icon 
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: -25,
              }}
              name="add circle" 
              size="massive"
            />
          </div>
          <div
            style={{
              paddingTop: "1.5em",
              width: "75%",
              whiteSpace: "pre-line",
              marginRight: "1em",
            }}
          >
            <Header
              style={{
                fontWeight: 600,
                color: "white",
              }}
            >
              Du behöver lägga till skiften
            </Header>

            <p>
              Innan du kan börja använda Freja behöver du lägga till skiften till din gård.
              Detta gör du genom några få enkla steg i guiden som du hittar nedanför.
            </p>
            
            <Button
              inverted
              style={{
                marginTop: 20,
              }}
              onClick={() => this.props.history.push("/guidesAddNewFieldsV1/addField")}
            >
              Lägg till skiften
            </Button>
          </div>
        </div>
      </Segment> 
    )
  };
}

export default withRouter(AddFieldsBanner);
