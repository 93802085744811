import chroma from "chroma-js";

import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";

const NUTRIENT_MAPS = {
  'soilMapAnalysis': [0.0, 2.0, 4.0, 8.0, 12.0, 16.0, 100000.0],
  'soilMapAnalysis_p_al': [0.0, 2.0, 4.0, 8.0, 12.0, 16.0, 100000.0],
  'soilMapAnalysis_k_al': [0.0, 4.0, 8.0, 16.0, 32.0, 100000.0],
  'soilMapAnalysis_p_hcl': [0.0, 20.0, 41.0, 61.0, 80.0, 100000.0],
  'soilMapAnalysis_k_hcl': [0.0, 50.0, 101.0, 201.0, 400.0, 100000.0],
  'soilMapAnalysis_mg_al': [0.0, 4.0, 10.0, 100000.0],
  'soilMapAnalysis_k_mg_warning': [-0.01, 0.5, 1.01],
};

const MAX_ABS_VALUE = 15.0;

export const getDataStats = (layer, colorScales, geoJsonData, geoJsonFieldKey) => {

  let chromaLimits = colorScales && colorScales.colorLimits;
  let chromaColors = colorScales && colorScales.colorScale;

  let featureData = geoJsonData && geoJsonData.features;
  let values = featureData && featureData.map(x => x.properties[geoJsonFieldKey]);
  values = values && values.filter((x) => typeof (x) === "number");

  // Get the data type of the current data layer (float, percent, integer, etc.)
  let dataLayer = getCurrentDataLayer(layer);
  let dataLayerType = dataLayer && dataLayer.dataType && dataLayer.dataType[layer];

  // Override for uploaded soil maps with classes
  // TODO: Remove this here and use information in fieldData instead
  if (Object.keys(NUTRIENT_MAPS).includes(layer)) {
    dataLayerType = "float";
    chromaLimits = NUTRIENT_MAPS[layer]
  };

  // Truncate data points 
  values = (values && dataLayerType === "difference") ? values.map((x) => Math.max(-MAX_ABS_VALUE, Math.min(x, MAX_ABS_VALUE))) : values;
  values = (values && dataLayerType === "percent") ? values.map((x) => Math.max(-MAX_ABS_VALUE, Math.min(x, MAX_ABS_VALUE))) : values;

  const min = values && Math.min(...values);
  const max = values && Math.max(...values);
  const median = values && Math.median(values);
  const mean = values && Math.mean(values);
  const variance = values && Math.var(values);
  let noTotalSamples = values && values.length;

  let groups = [];
  let samples, noSamples, groupMiddle, groupMean, groupVariance, groupMedian;

  if (dataLayerType && dataLayerType !== "integer" && values && chromaLimits && chromaColors) {
    for (let idx = 0; idx < chromaLimits.length - 1; idx++) {
      let subSamples = [];

      featureData && featureData.forEach((x) => {
        let currentValue = parseFloat(x.properties[geoJsonFieldKey]);

        if (["difference", "percent"].includes(dataLayerType)) {
          currentValue = Math.max(-MAX_ABS_VALUE, Math.min(currentValue, MAX_ABS_VALUE))
        };

        let lowerLimit = chromaLimits[idx];
        let upperLimit = chromaLimits[idx + 1];

        lowerLimit = (idx === 0) ? lowerLimit - 0.001 : lowerLimit;
        upperLimit = (idx === chromaLimits.length - 2) ? upperLimit + 0.001 : upperLimit;

        if ((currentValue - lowerLimit) >= 0.0 && (currentValue - upperLimit) < 0.0) {
          subSamples = [...subSamples, { value: currentValue, idx: x.idx, coords: x.geometry.coordinates }];
        }
      });

      noSamples = subSamples && subSamples.length;
      groupMiddle = chromaLimits[idx] + (chromaLimits[idx + 1] - chromaLimits[idx]) / 2.0;

      groupMean = Math.mean(subSamples.map((x) => x.value));
      groupMedian = Math.median(subSamples.map((x) => x.value));
      groupVariance = Math.var(subSamples.map((x) => x.value));

      groups = [...groups, {
        idx: idx,
        samples: subSamples.map((x) => x.value),
        sampleIndices: subSamples.map((x) => x.idx),
        coords: subSamples.map((x) => x.coords),
        noSamples: noSamples,
        noTotalSamples: noTotalSamples,
        max: chromaLimits[idx + 1],
        mean: groupMean,
        min: chromaLimits[idx],
        median: groupMedian,
        variance: groupVariance,
        middle: groupMiddle,
        color: chromaColors(groupMean),
      }]
    }
  };

  if (dataLayerType && dataLayerType === "integer" && values && chromaLimits && chromaColors) {
    let uniqueValues = values && values.filter((v, i, a) => a.indexOf(v) === i).sort();

    for (let idx = 0; idx < uniqueValues.length; idx++) {
      let uniqueValue = uniqueValues[idx];

      // TODO: Remove if new clustering maps work better
      // samples = featureData && featureData.filter((x) => x.properties[geoJsonFieldKey] >= chromaLimits[idx] && x.properties[geoJsonFieldKey] < chromaLimits[idx + 1]);

      samples = featureData && featureData.filter((x) => x.properties[geoJsonFieldKey] === uniqueValue);
      noSamples = samples && samples.length;

      groups = [...groups, {
        idx: uniqueValue,
        samples: samples.map((x) => x.properties[geoJsonFieldKey]),
        sampleIndices: samples.map((x) => x.idx),
        coords: samples.map((x) => x.geometry.coordinates),
        noSamples: noSamples,
        max: uniqueValue,
        mean: uniqueValue,
        min: uniqueValue,
        color: chromaColors(uniqueValue),
      }];
    }
  };

  return {
    min,
    max,
    median,
    mean,
    variance,
    values,
    groups: groups,
    noSamples: values.length,
  };
};

export const getColorScales = (
  layer,
  geoJsonData,
  geoJsonFieldKey,
  colorScaleType,
  numberOfGroups,
  scale,
) => {

  // Get the data type of the current data layer (float, percent, integer, etc.)
  let dataLayer = getCurrentDataLayer(layer);
  let dataLayerType = dataLayer && dataLayer.dataType && dataLayer.dataType[layer];
  let colorScaleName = dataLayer && dataLayer.colorScale && dataLayer.colorScale[layer];
  let colorLimit = dataLayer && dataLayer.colorLimit && dataLayer.colorLimit[layer];

  let showDifference = dataLayerType === "difference";
  let showHotspot = dataLayerType === "hotspot";
  let showPercent = dataLayerType === "percent";

  let values = geoJsonData && geoJsonData.features && geoJsonData.features.map(x => x.properties[geoJsonFieldKey]);
  values = values && values.filter((x) => typeof (x) === "number");

  // Truncate data points 
  values = (values && dataLayerType === "difference") ? values.map((x) => Math.max(-MAX_ABS_VALUE, Math.min(parseFloat(x), MAX_ABS_VALUE))) : values;
  values = (values && dataLayerType === "percent") ? values.map((x) => Math.max(-MAX_ABS_VALUE, Math.min(parseFloat(x), MAX_ABS_VALUE))) : values;

  let colorScale = "";
  let colorLimits = values && typeof values[0] === "number" && chroma.limits(
    values,
    colorScaleType ? colorScaleType : "k",
    numberOfGroups ? numberOfGroups : 5
  );

  let reversedColorScale = false;

  if (colorScaleName && colorScaleName.includes("Reversed")) {
    colorScaleName = colorScaleName.split("Rev")[0];
    reversedColorScale = true;
  };

  colorLimits = colorLimit ? colorLimit : colorLimits;
  
  // Compute symmetric bounds for percentiles
  let minValue, maxValue;

  if (values && typeof (values[0]) === "number") {
    let sortedValues = values.slice().sort((a, b) => {
      return a - b;
    })

    let noValues = sortedValues && sortedValues.length;

    minValue = sortedValues && sortedValues[Math.floor(0.05 * noValues)];
    maxValue = sortedValues && sortedValues[Math.ceil(0.95 * noValues)];
  }

  // Color scale override (Not used at the moment)
  if (scale) {
    colorScale = values && colorLimits && chroma.scale(scale).domain(colorLimits);
    return { colorScale, colorLimits, scale };
  };

  colorScale = values && colorLimits && chroma.scale(colorScaleName);
  colorScale = typeof (colorScaleName) !== "string" ? colorScale && colorScale.mode('lrgb') : colorScale;
  colorScale = colorScale && (colorLimit ? 
    colorScale.classes(colorLimit) : 
    (reversedColorScale ? colorScale.domain([...colorLimits].reverse()) : colorScale.domain(colorLimits))
  );

  if (showPercent || showDifference || showHotspot) {
    if (maxValue <= 0.0) {
      colorScale = colorLimits && chroma.bezier(colorScaleName).scale().domain([minValue, 0.0]);
    } else if (minValue >= 0.0) {
      colorScale = colorLimits && chroma.bezier(colorScaleName).scale().domain([0.0, maxValue]);
    } else {
      colorScale = colorLimits && chroma.bezier(colorScaleName).scale().domain([minValue, 0.0, maxValue]);
    }
  };

  return { colorScale, colorLimits, colorScaleName };
};

export const getCurrentColor = (colorScales, x, selectedGroup, alpha = 1.0) => {
  if (selectedGroup !== null) {
    if (selectedGroup === x) {
      return colorScales && colorScales.colorScale && colorScales.colorScale(x).alpha(alpha);
    } else {
      return "grey";
    }
  } else {
    return colorScales && colorScales.colorScale && colorScales.colorScale(x).alpha(alpha);
  }
};

export const getCurrentColorRGBA = (colorScales, x, selectedGroup, alpha = 1.0) => {
  let color = getCurrentColor(colorScales, x, selectedGroup);
  if (color._rgb) {
    return 'rgba(' + color._rgb[0] + ',' + + color._rgb[1] + ',' + + color._rgb[2] + ',' + + color._rgb[3] + ')'
  } else {
    return 'grey'
  }
};
