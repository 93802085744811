import React, { Component } from "react";

import { 
  Header,
  Segment,
  Button,
  Icon,
  Container,
} from "semantic-ui-react";

import { Animated } from "react-animated-css";

import GuideNavigation from "@/components/guide/GuideNavigation";

class GuidesBasicGrid extends Component {
  state = {};
  
  onToggleHelpTexts = () => this.props.toggleHelpTexts && this.props.toggleHelpTexts();

  render() {
    const {
      menuItems,
      mainColumn,
      helpColumn,
      children,
      mainColumnHeader,
      mainColumnBody,
      guideSubMenuActive,
      currentMenuItem,
      slideDirection,
    } = this.props;

    let showFullGrid = typeof(this.props.showFullGrid) === "boolean" ? this.props.showFullGrid : true;

    let caption = currentMenuItem && currentMenuItem.caption;
    caption = currentMenuItem && currentMenuItem.items && currentMenuItem.items.find((x) => x.active) ? currentMenuItem.items.find((x) => x.active).caption : caption;

    let description = currentMenuItem && currentMenuItem.description;
    description = currentMenuItem && currentMenuItem.items && currentMenuItem.items.find((x) => x.active) ? currentMenuItem.items.find((x) => x.active).description : description;
    
    let animationIn = "fadeIn";
    animationIn = slideDirection === "right" ? "slideInRight" : animationIn;
    animationIn = slideDirection === "left" ? "slideInLeft" : animationIn;

    return (      
      <Container
        style={{
          display: "grid",
          width: "100%",
          height: guideSubMenuActive ? "calc(100vh-200px)" : "calc(100vh-70px)",
        }}
      >
        {menuItems && <GuideNavigation menuItems={menuItems} />}
        
        <Animated
          animationIn={animationIn} 
          animationInDuration={1000}  
          isVisible={true}
          animateOnMount={true}
        >
          <div
            style={{
              padding: "2em",
              marginTop: guideSubMenuActive ? "6em" : "4em",
              marginLeft: showFullGrid ? null : "auto",
              marginRight: showFullGrid ? null : "auto",
              paddingBottom: "4em",
              width: showFullGrid ? "100%" : "70%",
              transition: "width 0.25s",

            }}
          >
            {showFullGrid &&
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {mainColumn && mainColumn} 
                </div>              

                <div 
                  style={{
                    width: "25%",
                  }}
                >
                  <Segment 
                    style={{
                      marginTop: 0,
                      padding: "2em",
                      minWidth: "250px",
                    }}
                  >
                    {typeof(this.props.toggleHelpTexts) === "function" &&
                      <Icon
                        style={{
                          position: "absolute",
                          top: "1em",
                          right: "1em",
                          zIndex: 9,
                          padding: 0,
                          margin: 0,
                        }}
                        className="iconButton"
                        onClick={() => this.onToggleHelpTexts()}
                        name="close"
                      />
                    }

                    <div style={{ marginTop: "-2em"}} />
                    
                    {caption &&
                      <Header
                        as="h2"
                        style={{
                          fontWeight: "normal",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {caption}
                      </Header>
                    }

                    {description &&
                      <p
                        style={{
                          fontWeight: "normal",
                          fontSize: "110%",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {description}
                      </p>
                    }

                    {helpColumn && helpColumn} 
                  </Segment>
                </div>              
              </div>
            }

            {!showFullGrid &&
              <div key="guidesBasicGridFullGrid">
                {mainColumnHeader && 
                  <Header
                    as="h3"
                    color="grey"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {mainColumnHeader && mainColumnHeader}
                  </Header>
                }

                {mainColumnHeader && mainColumnBody &&
                  <p
                    style={{
                      width: "50%",
                      whiteSpace: "pre-line",
                      marginBottom: "1.5em",
                    }}
                  >
                    {mainColumnBody && mainColumnBody}
                  </p>
                }

                {mainColumn && mainColumn}

                {typeof(this.props.toggleHelpTexts) === "function" &&
                  <Button
                    basic
                    style={{
                      position: "absolute",
                      top: guideSubMenuActive ? "8em" : "5em",
                      right: "1em",
                    }}
                    onClick={() => this.onToggleHelpTexts()}
                  >
                    Visa hjälptexter
                  </Button>
                }
              </div>
            }
        </div>
        </Animated>

        {children && children}
      </Container>
    )
  }
}

export default GuidesBasicGrid;