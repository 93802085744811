import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { 
  Header,
  Button,
  Icon,
  Segment,
  Modal,
  Image,
} from 'semantic-ui-react';

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";

import FieldCards from "./fieldCards";

import illustrationDone from "@/assets/illustrations/done.png";

import { PLAN_TYPES } from '@/constants/regions/plans';

const INITIAL_STATE = {
  errorMessage: null,
  showDoneModal: false,  
};

class RegistrationV1Summary extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onSubmit = () => {
    this.props.onClickNextStep();
    this.setState({ showDoneModal: true });
  };

  onCloseDoneModal = () => {
    if (this.props.errorMessageFirestore) {
      this.setState({ showDoneModal: false });
    } else {
      this.props.history.push("/")
    };
  };

  getText = (fieldsToAdd, isAddingMode, addedFieldsArea, priceFixed, pricePerHectar) => {
  
    if (isAddingMode) {
      return (
        <React.Fragment>
          <p
            style={{
              fontWeight: "normal",
              fontSize: "110%",
              whiteSpace: "pre-line",
            }}
          >  
            Du har nu lagt till ytterligare {fieldsToAdd.length} skiften med en total areal på {addedFieldsArea.toFixed(0)} ha. 
          </p>
          <p
            style={{
              fontWeight: "normal",
              fontSize: "110%",
              whiteSpace: "pre-line",
            }}
          >             
            
            Du kommer att faktureras för följande:

            <ul>
              <li>
                Uppstart: 0 kr
              </li>

              <li>
                Areal: {(pricePerHectar * addedFieldsArea).toLocaleString().replace(/,/g," ",)} kr
              </li>

              <li>
                Totalt: <u>{(pricePerHectar * addedFieldsArea).toLocaleString().replace(/,/g," ",)}</u> kr
              </li>     
            </ul>           
          </p>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <p
            style={{
              fontWeight: "normal",
              fontSize: "110%",
              whiteSpace: "pre-line",
            }}
          >  
            Du har nu lagt till totalt {fieldsToAdd.length} skiften med en total areal på {addedFieldsArea.toFixed(0)} ha. 
          </p>
          <p
            style={{
              fontWeight: "normal",
              fontSize: "110%",
              whiteSpace: "pre-line",
            }}
          >          
            Du kommer att faktureras för följande:

            <ul>
              <li>
                Uppstart: {priceFixed.toLocaleString().replace(/,/g," ",)} kr
              </li>

              <li>
                Areal: {(pricePerHectar * addedFieldsArea).toLocaleString().replace(/,/g," ",)} kr
              </li>

              <li>
                Totalt: <u>{(priceFixed + pricePerHectar * addedFieldsArea).toLocaleString().replace(/,/g," ",)}</u> kr
              </li>     
            </ul>           
          </p>
        </React.Fragment>
      )
    }
  }

  render() {
    const {
      userPlan,
      fields,
      fieldsToAdd,
      fieldIdsAdded,
      isAddingMode,
      errorMessageFirestore,
    } = this.props;

    let areaAdded = fieldsToAdd && fieldsToAdd.reduce((old, item) => old + parseFloat(item.area), 0.0);   

    let priceFixed = userPlan && PLAN_TYPES.filter(x => x.name === userPlan)[0];
    priceFixed = priceFixed && priceFixed.priceFixed ? priceFixed.priceFixed : 0;

    let pricePerHectar = userPlan && PLAN_TYPES.filter(x => x.name === userPlan)[0];
    pricePerHectar = pricePerHectar && pricePerHectar.pricePerHectar ? pricePerHectar.pricePerHectar : 0;

    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],       
      next: [
        {
          caption: "Lägg till skiften",
          onClick: this.props.onClickNextStep,
        }
      ],   
    };

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <Modal
          open={this.state.showDoneModal}
        >
          <Modal.Content>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "2em"
              }}
            >
              <Image
                src={illustrationDone}
                size="medium"
              />

              <div 
                style={{
                  marginTop: "2em",
                  marginLeft: "4em",
                }}
              >
                <Header as="h3">{errorMessageFirestore ? "Något gick tyvärr fel!" : "Dina nya skiften har lagts till!"}</Header>

                {!errorMessageFirestore && <p>Freja börjar genast arbetet med att samla ihop data för dina nya skiften. Om några timmar kommer du kunna hitta denna datan i Freja. Under tiden kan du ladda upp alla dina skördekartor och markkarteringar under fliken "Samla".</p>}

                {!errorMessageFirestore && <p>Vill du lägga till fler skiften från en annan SAM-ansökan eller manuellt genom att rita ut skifteskanter kan du göra det under fliken "Samla".</p>}
            
                {errorMessageFirestore && <p>Dina nya skiften kunde tyvärr inte läggas till. Försök gärna igen lite senare och om problemet kvarstår kontakta supporten på <a href="mailto:support@agriopt.se">support@agriopt.se</a> uppge felmeddelandet: ' + <i>{errorMessageFirestore}</i> + ' så fixar vi detta så snart som möjligt.</p>}
              </div>
            </div>
          </Modal.Content>
          
          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "flex-end"
            }}                 
          >
            <Button  
              onClick={() => this.onCloseDoneModal()}
            >
              Stäng
            </Button>            
          </Modal.Actions>
        </Modal>

        {fieldIdsAdded.length > 0 &&
          <Segment 
            basic
            style={{
              margin: 0,
              padding: 0,
              backgroundColor: "transparent",              
            }}
          >
            {fields && fields.map((field, idx) => (
              fieldIdsAdded.includes(field.id) &&
                <FieldCards
                  key={field.id}
                  field={field}
                  idx={idx}
                  fieldsToAdd={fieldsToAdd}
                  fieldIdsAdded={fieldIdsAdded}
                  disableEdit={true}
                /> 
            ))}
          </Segment>
        }

        {fieldIdsAdded.length === 0 &&
          <Segment 
            placeholder
            style={{
              height: "calc(100vh - 150px)",
              backgroundColor: "#fafafa",              
              padding: "2em",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",               
            }}
          >
            <Header 
              icon
              as="h2"
              style={{
                fontWeight: "normal",
              }}
            >   
              <Icon name='square outline' style={{ fontSize: "8em" }} />
              Du har inte lagt till några skiften.
            </Header>
          </Segment>
        }    
      </div>

    let helpColumn = 
      <React.Fragment>
        {this.getText(fieldsToAdd, isAddingMode, areaAdded, priceFixed, pricePerHectar)}

        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Klar?
        </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >                
          Till vänster ser du de skiften som du har lagt till. Kontrollera att listan är korrekt och tryck sedan på knappen nedan till höger för att lägga till dessa skiften i Freja.
        </p>  

      </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader=""
        mainColumnBody=""    
        showFullGrid={true}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default withRouter(RegistrationV1Summary);