import React, { Component } from "react";
import { compose } from "redux";

import queryString from 'query-string';

import {
  withFirebase,
  withFirestore as withFirestoreActions
} from "react-redux-firebase";

import { toast } from "react-toastify";

import ResetPasswordForm from "@/components/password/resetPassword";
import ResetSetNewPasswordForm from "@/components/password/resetSetNewPassword"

const INITIAL_STATE = {
  mode: "sendEmail",
  resetCode: '',
};
class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidUpdate = () => {
    const value = queryString.parse(this.props.location.search);

    if (this.state.mode !== "verifyEmail" && value && value.oobCode) {
      this.setState({
        resetCode: value.oobCode,
        mode: "verifyEmail"
      })
    };
  }; 

  onDoneSendEmail = () => {
    toast.success("Ett mail med instruktioner om hur du återställer ditt lösenord har skickats till den adressen som du uppgav.",
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      }
    );  

    this.props.history.push("/");
  };

  onDoneSetNewPassword = () => {
    toast.success("Ditt lösenord hos AgriOpt har nu återställts. Vänligen logga in igen.",
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      }
    );  

    this.props.history.push("/");
  }  

  render() {
    const {
      mode,
      resetCode,
    } = this.state;

    if (mode === "verifyEmail") {
      return (
        <ResetSetNewPasswordForm
          firebase={this.props.firebase}
          resetCode={resetCode}
          onDone={this.onDoneSetNewPassword}
        />
      )
    } else {
      return (
        <ResetPasswordForm
          firebase={this.props.firebase}
          onDone={this.onDoneSendEmail}
        />
      )      
    }
  }
}

export default compose(
  withFirebase,
  withFirestoreActions
)(ResetPasswordPage);
