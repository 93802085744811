import React, { Component } from 'react';

import { 
  Button,
  Icon,
  Segment,
  Image,
  Popup,
  Header,
  Form,
} from 'semantic-ui-react';

import { GREENCODES } from '@/constants/regions/sweden';

import ImageHectar from "@/assets/icons/hectar.svg";
import ImageCrop from "@/assets/icons/yield-average.svg";

import FieldCartoon from "@/components/misc/FieldCartoon";
import { getCurrentYear } from '@/helpers/dates';
import { CROP_CODES } from '@/constants/cropCodes';

const INITIAL_STATE = {};

class FieldCards extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onChangeName = (e, data) => {
    const {
      field,
      fieldsToAdd,
    } = this.props;

    let currentInfo = fieldsToAdd && fieldsToAdd.find((x) => x.id === field.id);
    currentInfo = currentInfo && {...currentInfo, name: data.value};

    let newInfo = fieldsToAdd && field && fieldsToAdd.find((x) => x.id !== field.id);

    this.props.setParentState({
      fieldsToAdd: newInfo ? [...newInfo, currentInfo] : [currentInfo],
    });
  };

  onChangeCrop = (e, data) => {
    const {
      field,
      fieldsToAdd,
    } = this.props;

    let currentYear = getCurrentYear();
    let newCropCode = data.value;
    let newCropText = newCropCode && CROP_CODES.find((x) => x.value === newCropCode);    
    newCropText = newCropText && newCropText.shorttext;

    let currentInfo = fieldsToAdd && fieldsToAdd.find((x) => x.id === field.id);
    
    currentInfo = currentInfo ? 
      {
        ...currentInfo, 
        cropCode: { ...currentInfo.cropCode, [currentYear]: newCropCode }, 
        cropText: { ...currentInfo.cropText, [currentYear]: newCropText }
      } 
    :
      {
        cropCode: { [currentYear]: newCropCode }, 
        cropText: { [currentYear]: newCropText }
      }

    let newInfo = fieldsToAdd && fieldsToAdd.filter((x) => x.id !== field.id);

    this.props.setParentState({
      fieldsToAdd: newInfo && newInfo.length > 0 ? [...newInfo, currentInfo] : [currentInfo],
    });
  };   

  render () {
    const {
      field,
      idx,
      fieldsToAdd,
      fieldIdsAdded,
      existingFieldsSamIds,
      canAddField,
      disableEdit,
    } = this.props;

    let currentYear = getCurrentYear();

    let newFieldInfo = fieldIdsAdded && 
      fieldIdsAdded.includes(field.id) && 
      fieldsToAdd.find((x) => x.id === field.id);

    let name = typeof(idx) === "number" && "Skifte #" + (idx + 1);
    name = (newFieldInfo && newFieldInfo.name) ? newFieldInfo.name : name;

    let position = field && field.position && field.position.map((x) => ({latitude: x.lat, longitude: x.lng}));

    let size = field && field.area && field.area + " ha";
    
    let cropText = fieldsToAdd && fieldsToAdd.find((x) => x.id === field.id);
    cropText = cropText ? cropText : field;
    cropText = cropText && cropText.cropText;

    let years = cropText && Object.keys(cropText).map((x) => x);
    let cropTexts = cropText && years && years.map((year) => year + ': ' + cropText[year]);    
    
    if (!disableEdit && fieldIdsAdded.includes(field.id)) {
      return (
        <Segment
          key={`field_${field.id}`}
          style={{
            backgroundColor: "#ffffff",
            padding: "1em",
            paddingLeft: "2em",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex", 
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "6em",
                marginTop: "auto",
                marginBottom: "auto",                
              }}
            >
              <FieldCartoon 
                className="fieldCartoon"
                style={{
                  margin: 0,
                  padding: 0,
                  height: "6em",
                  width: "6em",
                }}                  
                position={position}
                color="#89A275"
              /> 
            </div>

            <Form.Group
              style={{
                width: "50%",
                marginLeft: "1em",
                marginRight: "1em",
                justifyContent: "flex-start",
              }}
            >
              <Form.Input
                style={{
                  marginBottom: 15,
                }}
                fluid
                autoFocus
                label="Skiftesnamn"
                placeholder='Ange ett namn på skiftet'
                value={newFieldInfo.name}
                error={!newFieldInfo || !newFieldInfo.name || newFieldInfo.name === '' }
                onChange={(e, d) => this.onChangeName(e, d)}
              />

              <Form.Select   
                style={{
                  marginBottom: 15,
                }}
                fluid             
                search
                label={"Gröda som odlas under " + currentYear + " (frivilligt)"}
                placeholder='Välj en grödkod...'
                value={newFieldInfo.cropCode && newFieldInfo.cropCode[currentYear]}
                options={GREENCODES}
                onChange={(e, d) => this.onChangeCrop(e, d)}
              />
            </Form.Group>

            <div
              style={{
                width: "11em",
              }}
            >
              <Button 
                style={{
                  marginBottom: 5,
                }}
                icon
                fluid
                primary
                labelPosition="left"
                floated="right"
                size="small"
                onClick={() => this.props.openField(field, idx)}
              >
                <Icon name="expand" />
                Visa karta
              </Button>
              
              <Button 
                icon
                fluid
                negative
                labelPosition="left"
                floated="right"
                size="small"
                onClick={() => this.props.removeField(field.id)}
              >
                <Icon name="trash" />
                Ta bort
              </Button>
            </div>
          </div>  
        </Segment>
      )
    } else {
      return (
        <Segment
          key={`field_${field.id}`}
          style={{
            backgroundColor: "#ffffff",
            padding: "1em",
            paddingLeft: "2em",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex", 
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "6em",
                marginTop: "auto",
                marginBottom: "auto",  
              }}
            >
              <FieldCartoon 
                className="fieldCartoon"
                style={{
                  margin: 0,
                  padding: 0,
                  height: "6em",
                  width: "6em",
                }}                 
                position={position}
                color="#e0ba8c"
              /> 
            </div>

            <div
              style={{
                width: "50%",
                marginLeft: "1em",
                marginRight: "1em",
                justifyContent: "flex-start",
              }}
            >
              <Header
                style={{ 
                  marginBottom: 0,
                  fontWeight: 500,
                }}
              >
              {name && name}
              </Header>

              {existingFieldsSamIds && existingFieldsSamIds.includes(field.id) &&
                <Popup
                  trigger={
                    <Icon
                      style={{
                        color: "#89A275"
                      }}
                      name="check"
                      floated="right"
                    />
                  }
                  content="Detta skifte har lagts till tidigare."
                />
              }

              {size && 
                <p style={{ marginTop: 4, color: "grey" }}>
                  <Image 
                    inline 
                    style={{ 
                      width: "15px", 
                      marginRight: 5, 
                      marginBottom: 2, 
                    }}
                    src={ImageHectar} 
                  />
                  {size}
                </p>
              }

              {cropTexts && cropTexts.map((y) =>
                <p style={{ color: "grey", marginTop: 0 }}>
                  <Image 
                    inline 
                    style={{ 
                      width: "15px", 
                      marginRight: 5, 
                      marginBottom: 2,
                    }}
                    src={ImageCrop}  
                  />
                  {y}
                </p>
              )}  
            </div>

            <div
              style={{
                width: "11em",
              }}
            >
              {!disableEdit &&
                <Button 
                  style={{
                    marginBottom: 5,
                  }}              
                  icon
                  fluid
                  primary
                  labelPosition="left"
                  floated="right"
                  size="small"
                  disabled={canAddField === false}
                  onClick={() => this.props.addField({...field, name})}
                >
                  <Icon name="add" />
                  Lägg till
                </Button>
              }
              
              {!disableEdit &&
                <Button
                  icon
                  fluid
                  labelPosition="left"
                  floated="right"
                  size="small"
                  onClick={() => this.props.openField(field, idx)}
                >
                  <Icon name="expand" />
                  Visa karta
                </Button> 
              }                      
            </div>
          </div>  
        </Segment>
      )
    }
  
  }; 
}

export default FieldCards;
