import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import {
  Segment,
  Header,
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import moment from 'moment';
import 'moment/locale/sv';

import LoaderModal from "@/components/modals/LoaderModal";

import FieldManagementHarvest from "@/components/fieldManagement/FieldManagementHarvest";
import FieldManagementNutrition from "@/components/fieldManagement/FieldManagementNutrition";
import FieldManagementSeeding from "@/components/fieldManagement/FieldManagementSeeding";

import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";

import { fetchData } from "@/helpers/dataFetcher";
import { fetchWeatherData } from "@/helpers/weather";

const INITIAL_STATE = {
  statJson: null,
  temperatureData: null,
  rainfallData: null,
  irradiationData: null,
};

class FieldSummaryTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    const {
      db,
      fieldId,
      fileBucketRef,
    } = this.props;
    
    fetchWeatherData(db && db.fieldsWeather, fileBucketRef, this.setParentState);

    fetchData({
      db,
      fieldId: fieldId,
      layer: "lai",
      layerId: "latest",
      plotName: "none",
      setParentState: (newState) => this.setState(newState),
      fileBucketRef, 
    });
  };

  componentDidUpdate = (prevProps) => {
    const {
      db,
      layer,
      fieldId,
      fileBucketRef,
    } = this.props;

    // Get the data type of the current data layer (float, percent, integer, etc.)
    let dataLayer = getCurrentDataLayer(layer);

    let dbStatDocName = dataLayer && dataLayer.dbStatDocName;
    dbStatDocName = typeof(dbStatDocName) === "string" ? dbStatDocName : dbStatDocName && dbStatDocName[layer];
    let dbDataColName = dbStatDocName && dbStatDocName.split("/")[0];

    let dbCollection = dbDataColName && db && fieldId && db[dbDataColName + "_" + fieldId];
    let prevDbCollection = dbDataColName && prevProps.db && fieldId && prevProps.db[dbDataColName + "_" + fieldId];

    let fieldHasChanged = prevProps.fieldId !== fieldId;
    let dbHasChanged =  dbCollection !== prevDbCollection;
    
    if (fieldHasChanged || dbHasChanged) { 
      fetchData({
        db,
        fieldId: fieldId,
        layer: "lai",
        layerId: "latest",
        plotName: "none",
        setParentState: (newState) => this.setState(newState),
        fileBucketRef, 
      });
    };
    
    if (!this.state.temperatureData && (db && db.fieldsWeather) !== (prevProps.db && prevProps.db.fieldsWeather)) {
      fetchWeatherData(db && db.fieldsWeather, fileBucketRef, this.setParentState)
    }
  };

  setParentState = (newState) => this.setState(newState);

  resampleTimeSeries = (oldData, oldDates, newDates) => {
    let lastValue = 0.0;
    let output = [];

    newDates.forEach((x) => {
      if (oldDates.includes(x) && oldData[oldDates.indexOf(x)] > 0.05) {
        lastValue = oldData[oldDates.indexOf(x)];
      };

      output = [...output, lastValue];
    });
    
    return output;
  };


  render() {
    const {
      userId,
      layerId,
      fieldId,
    } = this.props;

    const {
      statJson,
      temperatureData,
      rainfallData,
    } = this.state;

    let currentYear = layerId ? layerId : (parseFloat(moment().format("MM")) > 3.0 ? moment().format("YYYY") : (parseFloat(moment().format("YYYY")) - 1).toFixed(0));
    let greenmassIndex = currentYear && parseFloat(currentYear) > 2016 ? "lai" : "ndvi";

    const db = this.props.db;
    const weatherData = db && db.fieldsWeather;
    const fieldInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let seedingDate = fieldInfo && layerId && fieldInfo.seeding_date && fieldInfo.seeding_date[layerId];
    seedingDate = seedingDate ? seedingDate : layerId + "-03-01";

    let harvestDate = fieldInfo && layerId && fieldInfo.harvest_dates && fieldInfo.harvest_dates[layerId];
    harvestDate = harvestDate ? harvestDate : layerId + "-08-15";

    let hasLoadedData = isLoaded(db) && !isEmpty(db) && weatherData;

    let plotLabels = temperatureData && Object.keys(temperatureData)
      .filter((x) => moment(x, "YYYY-MM-DD") >= moment(seedingDate, "YYYY-MM-DD") && moment(x, "YYYY-MM-DD") <= moment(harvestDate, "YYYY-MM-DD"))
      .sort();

    let plotLabelsGrowth = layerId && plotLabels && plotLabels.filter((x) => moment(x, "YYYY-MM-DD") <= moment(layerId + "-06-30", "YYYY-MM-DD"));
    let plotLabelsMaturity = layerId && plotLabels && plotLabels.filter((x) => moment(x, "YYYY-MM-DD") >= moment(layerId + "-07-01", "YYYY-MM-DD"));

    let meanValues = statJson && statJson.mean_values;

    let meanValuesDates = meanValues && Object.keys(meanValues)
      .map((x) => x)
      .filter((x) => x.includes(currentYear))
      .sort();

    let datesGreenmass = layerId && meanValuesDates && meanValuesDates.filter((x) => moment(x, "YYYY-MM-DD") <= moment(layerId + "-06-30", "YYYY-MM-DD"));
    let datesPSRI = layerId && meanValuesDates && meanValuesDates.filter((x) => moment(x, "YYYY-MM-DD") >= moment(layerId + "-07-01", "YYYY-MM-DD"));

    let plotDataNDWI = meanValues && meanValuesDates && meanValuesDates.map((x) => meanValues[x].ndwi);
    plotDataNDWI = plotDataNDWI && meanValuesDates && plotLabels && this.resampleTimeSeries(plotDataNDWI, meanValuesDates, plotLabels);

    let plotDataGreenmass = meanValues && datesGreenmass && datesGreenmass.map((x) => meanValues[x][greenmassIndex ]);
    plotDataGreenmass = plotDataGreenmass && datesGreenmass && plotLabels && this.resampleTimeSeries(plotDataGreenmass, datesGreenmass, plotLabelsGrowth);

    let plotDataPSRI = meanValues && datesPSRI && datesPSRI.map((x) => meanValues[x].psri);
    plotDataPSRI = plotDataPSRI && datesPSRI && plotLabels && this.resampleTimeSeries(plotDataPSRI, datesPSRI, plotLabelsMaturity);

    plotDataPSRI = plotLabelsGrowth && plotDataPSRI && [...plotLabelsGrowth.map((x) => null), ...plotDataPSRI];

    let plotData = { 
      labels: plotLabels,
      datasets: [
          {
            label: "Grönmassa",
            data: plotDataGreenmass,
            // fill: false,
            pointBorderColor: "transparent",
            backgroundColor: "transparent",
            borderColor: "#4C8440"
          },
          {
            label: "Fuktighet",
            data: plotDataNDWI,
            // fill: false,
            pointBorderColor: "transparent",
            backgroundColor: "transparent",
            borderColor: "#9CB5B6"
          },          
          {
            label: "Mognadsgrad",
            data: plotDataPSRI,
            // fill: false,
            pointBorderColor: "transparent",
            backgroundColor: "transparent",
            borderColor: "#F0BD38",
          },          
      ],
    };
    
    let plotOptions = { 
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: true, 
        position: "bottom",
      },
      series: {
        showPoints: false,
      }
    };

    let plotWeatherData = {
      labels: plotLabels,
      datasets: [
        {
          type: "line",
          label: "Medeltemperatur (grader)",
          data: plotLabels && temperatureData && plotLabels.map((x) => temperatureData[x]),
          // fill: false,
          pointBorderColor: "transparent",
          backgroundColor: "transparent",
          borderColor: "#D6946B",
        },
        {
          type: "bar",
          label: "Nederbörd (mm)",
          data: plotLabels && rainfallData && plotLabels.map((x) => rainfallData[x]),
          fill: false,
          backgroundColor: "#9CB5B6",
        },    
      ]
    };

    if (hasLoadedData) {
      return (
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 70,
            width: "100%",
            height: "calc(100vh - 70px)",
            overflowY: "auto",
            whiteSpace: "pre-line",
          }}
        >
          <div
            style={{
              padding: 20,
              paddingTop: 0,
              marginLeft: "5px",
              marginRight: "15px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div 
              style={{
                width: "67%"
              }}
            >
              <Segment
                style={{
                  padding: "1.5em",
                  fontWeight: "normal",
                }}
                loading={!statJson}
              >
                <Header
                  as="h3"
                  style={{
                    paddingTop: 0,
                    fontWeight: 500,
                    color: "grey",
                  }}
                >
                  Grödans tillväxt och mognad
                </Header>

                <article 
                  className="canvas-container"
                  style={{
                    display: "block",
                    height: "40vh",
                    width: "100%",
                    padding: 0,
                    marginTop: 10,
                  }}          
                >
                  {plotData.labels && <Line data={plotData} options={plotOptions} />}
                </article>
              </Segment>

              <Segment
                style={{
                  padding: "1.5em",
                  fontWeight: "normal",
                }}
                loading={!temperatureData}
              >
                <Header
                  as="h3"
                  style={{
                    paddingTop: "0",
                    fontWeight: 500,
                    color: "grey",
                  }}
                >
                  Medeltemperatur och nederbörd
                </Header>

                <article 
                  className="canvas-container"
                  style={{
                    display: "block",
                    height: "40vh",
                    width: "100%",
                    padding: 0,
                    marginTop: 10,
                  }}          
                >
                  {plotData.labels && <Line data={plotWeatherData} options={plotOptions} />}
                </article>           
              </Segment>                                
            </div>
            
            <div
              style={{
                width: "30%"
              }}
            >
              <Segment
                style={{
                  padding: "1.5em",
                  fontWeight: "normal",
                }}
              >
                <div
                  style={{
                    marginBottom: "1.5em",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Header
                    as="h3"
                    style={{
                      paddingTop: "0",
                      fontWeight: 500,
                      color: "grey",
                    }}
                  >
                    Insatser under säsongen
                  </Header>
                       
                  {/* <p
                    style={{
                      color: "#6B9D9F",
                      fontSize: "95%",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                    onClick={() => this.props.history.push("/collect/" + fieldId + "/seeding")}
                  >
                    Lägg till
                  </p> */}
                </div>

                  <FieldManagementSeeding
                    db={db}
                    userId={userId}
                    fieldId={fieldId}
                    getUser={this.props.getUser}
                    widget={true}
                    widgetYear={layerId} 
                  />

                  <FieldManagementNutrition
                    db={db}
                    userId={userId}
                    fieldId={fieldId}
                    getUser={this.props.getUser}
                    widget={true}
                    widgetYear={layerId} 
                  />

                  <FieldManagementHarvest
                    db={db}
                    userId={userId}
                    fieldId={fieldId}
                    getUser={this.props.getUser}
                    widget={true}
                    widgetYear={layerId} 
                  />
              </Segment>   
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      );
    }
  }
}

export default withRouter(FieldSummaryTimeline);
