import React, { Component } from "react"

import {
  Segment,
  Header,
  Dropdown
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import { getCurrentYear } from "@/helpers/dates";
import { extractYears } from "@/helpers/weather";
class WeatherDaily extends Component {
  state = {
    activeView: "temperature",
    selectedYear: getCurrentYear(),
  };

  onlyUnique = (value, index, self) => { 
    return self.indexOf(value) === index;
  };

  onSelectYear = (newYear) => {
    this.setState({ selectedYear: newYear });
  };

  getSubSegment = (title, description, yearsArr, plotData, plotOptions) => {
    
    return (
      <article 
        className="canvas-container"
        style={{
          height: "45vh",
          padding: "1em",
          paddingTop: 0,
        }}          
      >
          <Line data={plotData} options={plotOptions} />
      </article>
    )
  };

  handleItemClick = (item) => this.setState({ activeView: item });

  render() {
    const {
      irradiationData,
      temperatureData,
      rainfallData,
    } = this.props;

    const {
      selectedYear,
      activeView,
    } = this.state;

    let yearsArr = temperatureData && extractYears(temperatureData);
    
    let plotOptions = { 
      responsive: true,
      maintainAspectRatio: false,
      layout: {
          padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
          }
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false, 
        position: "bottom",
      },
      series: {
        showPoints: false,
      }
    };

    let datesIrradiationData = irradiationData && Object.keys(irradiationData).filter((x) => x.includes(selectedYear));
    datesIrradiationData = datesIrradiationData && datesIrradiationData.filter((x) => irradiationData[x] > 0.0);

    let plotIrradiationData = {
      labels: datesIrradiationData,
      datasets: [
        {
          label: "Solinstrålning (W/m2)",
          data: datesIrradiationData && datesIrradiationData.map((x) => irradiationData[x]),
          fill: true,
          backgroundColor: "#e0ba8c"
        }        
      ]
    }; 

    let datesTemperatureData = temperatureData && Object.keys(temperatureData).filter((x) => x.includes(selectedYear));
    
    let plotTemperatureData = {
      labels: datesTemperatureData,
      datasets: [
        {
          label: "Medeltemperatur",
          data: datesTemperatureData && datesTemperatureData.map((x) => temperatureData[x]),
          fill: true,
          backgroundColor: "#D6946B"
        },      
      ]
    };  

    let datesRainfallData = rainfallData && Object.keys(rainfallData).filter((x) => x.includes(selectedYear));
    
    let plotRainfallData = {
      labels: datesRainfallData,
      datasets: [
        {
          label: "Nederbörd (mm)",
          data: datesRainfallData && datesRainfallData.map((x) => rainfallData[x]),
          fill: true,
          backgroundColor: "#9CB5B6"
        },     
      ]
    };

    let menuItems = [
      {
        key: 'temperature',
        value: 'temperature',
        text: "Medeltemperatur",
        onClick: () => this.handleItemClick('temperature'),
      },
      {
        key: 'rainfall',
        value: 'rainfall',
        text: "Nederbörd",
        onClick: () => this.handleItemClick('rainfall'),
      },
      {
        key: 'irradiation',
        value: 'irradiation',
        text: "Solinstrålning",
        onClick: () => this.handleItemClick('irradiation'),
      },            
    ];   
    
    let menuItemsYears = yearsArr && yearsArr.map((x) => (
      {
        key: x,
        value: x,
        text: x,
        onClick: () => this.onSelectYear(x),
      }
    ));    

    return (
      <Segment
        style={{
          padding: "1.5em",
          fontWeight: "normal",
        }}
        loading={!irradiationData || !temperatureData || !rainfallData}
      >
        <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1em",
          }}
        >
          <Header
            as="h3"
            style={{ 
              paddingTop: "0",
              fontWeight: 500,
              color: "grey",
            }}
          >
            Dag för dag under säsongen
          </Header>
            
          <div>
            <Dropdown
              style={{
                marginRight: 10,
              }}
              className="textLink"
              options={menuItems}
              value={activeView}
            />

            &bull;

            <Dropdown
              style={{
                marginLeft: 10,
              }}
              className="textLink"
              options={menuItemsYears}
              value={selectedYear}
            />          
          </div>
        </div>

        {activeView === 'temperature' && this.getSubSegment("Daglig medeltemperatur", "...", yearsArr, plotTemperatureData, plotOptions)}
        {activeView === 'rainfall' && this.getSubSegment("Daglig nederbörd (mm)", "...", yearsArr, plotRainfallData, plotOptions)}
        {activeView === 'irradiation' && this.getSubSegment("Daglig solinstrålning (W/m2)", "...", yearsArr, plotIrradiationData, plotOptions)}
      </Segment>
    );
  }
}

export default WeatherDaily;
