export const COMPARE_PALETTES = [
  {
    id: 'yield',
    text: 'Skördekartor',
    options: [
      {
        id: "yieldLatest4",
        caption: 'Skördekartor',
        description: 'Jämför dina fyra senaste skördekartor.',
        useYearsFrom: false,
        useCropsFrom: ["yieldStatistics"],
        requiredData: ["yieldMap"],           
        settings: {
          activeView: "compare4",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldMap',
          compareUpperLeftLayerId: 'cropCode={crop}/time=latest/index=0',
          compareUpperRightLayer: 'yieldMap',
          compareUpperRightLayerId: 'cropCode={crop}/time=latest/index=1',
          compareLowerLeftLayer: 'yieldMap',
          compareLowerLeftLayerId: 'cropCode={crop}/time=latest/index=2',
          compareLowerRightLayer: 'yieldMap',
          compareLowerRightLayerId: 'cropCode={crop}/time=latest/index=3',
        }
      },      
      {
        id: "yieldPercentLatest4",
        caption: 'Procentuella avvikelser',
        description: 'Jämför hur olika områden varierar från snittet i dina fyra senaste skördekartor.',
        useYearsFrom: false,
        useCropsFrom: ["yieldStatistics"],
        requiredData: ["yieldMap_dryYield_percent"],           
        settings: {
          activeView: "compare4",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldMap_dryYield_percent',
          compareUpperLeftLayerId: 'cropCode={crop}/time=latest/index=0',
          compareUpperRightLayer: 'yieldMap_dryYield_percent',
          compareUpperRightLayerId: 'cropCode={crop}/time=latest/index=1',
          compareLowerLeftLayer: 'yieldMap_dryYield_percent',
          compareLowerLeftLayerId: 'cropCode={crop}/time=latest/index=2',
          compareLowerRightLayer: 'yieldMap_dryYield_percent',
          compareLowerRightLayerId: 'cropCode={crop}/time=latest/index=3',        
        }
      },         
      {
        id: "yieldPotential",
        caption: 'Skördepotential',
        description: 'Jämför fyra olika kartor med skördestatistik. I denna jämförelse kan du se variationen mellan skiftets olika delar.',
        useYearsFrom: false,
        useCropsFrom: ["yieldStatistics"],
        requiredData: ["yieldStatistics"],                  
        settings: {
          activeView: "compare4",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldStatistics',
          compareUpperLeftLayerId: '{crop}',
          compareUpperRightLayer: 'yieldStatistics_max',
          compareUpperRightLayerId: '{crop}',
          compareLowerLeftLayer: 'yieldStatistics_goodYear',
          compareLowerLeftLayerId: '{crop}',
          compareLowerRightLayer: 'yieldStatistics_badYear',
          compareLowerRightLayerId: '{crop}',        
        }
      },             
    ]
  },
  {
    id: 'greenmassMoisture',
    text: 'Grönmassa och fukthalt',
    options: [     
      {
        id: "greenmassPercentLatest4",
        caption: 'Procentuella avvikelser i grönmassa',
        description: 'Jämför hur olika områden varierar från snittet under fyra år med grönmassamätningar.',
        useYearsFrom: false,
        useCropsFrom: ["greenmassAnalysis"],
        requiredData: ["greenmassAnalysis"],           
        settings: {
          activeView: "compare4",
          fullscreenMode: true,
          compareUpperLeftLayer: 'greenmassAnalysis',
          compareUpperLeftLayerId: 'cropCode={crop}/time=latest/index=0',
          compareUpperRightLayer: 'greenmassAnalysis',
          compareUpperRightLayerId: 'cropCode={crop}/time=latest/index=1',
          compareLowerLeftLayer: 'greenmassAnalysis',
          compareLowerLeftLayerId: 'cropCode={crop}/time=latest/index=2',
          compareLowerRightLayer: 'greenmassAnalysis',
          compareLowerRightLayerId: 'cropCode={crop}/time=latest/index=3',        
        }
      },
      {
        id: "moisturePercentLatest4",
        caption: 'Procentuella avvikelser i fukthalt',
        description: 'Jämför hur olika områden varierar från snittet under fyra år med fukthaltsmätningar.',
        useYearsFrom: false,
        useCropsFrom: ["moistureAnalysis"],
        requiredData: ["moistureAnalysis"],           
        settings: {
          activeView: "compare4",
          fullscreenMode: true,
          compareUpperLeftLayer: 'moistureAnalysis',
          compareUpperLeftLayerId: 'cropCode={crop}/time=latest/index=0',
          compareUpperRightLayer: 'moistureAnalysis',
          compareUpperRightLayerId: 'cropCode={crop}/time=latest/index=1',
          compareLowerLeftLayer: 'moistureAnalysis',
          compareLowerLeftLayerId: 'cropCode={crop}/time=latest/index=2',
          compareLowerRightLayer: 'moistureAnalysis',
          compareLowerRightLayerId: 'cropCode={crop}/time=latest/index=3',        
        }
      },                           
    ]
  },  
  {
    id: 'yieldSoil',
    text: 'Skörde- och markkartering',
    options: [
      {
        id: "yieldPh",
        caption: 'Hitta områden med för lågt/högt pH',
        description: 'Jämför din snittskörd med markens pH-värde för att se om det finns något samband för de områden där du har lägre skörd.',
        useYearsFrom: false,
        useCropsFrom: ["yieldStatistics"],
        requiredData: ["yieldStatistics"],          
        settings: {
          activeView: "compare2correlation",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldStatistics',
          compareUpperLeftLayerId: '{crop}',
          compareLowerLeftLayer: 'soilMapUploaded',
          compareLowerLeftLayerId: '',
        },
      },
      {
        id: "yieldPAl",
        caption: 'Hitta områden med fosforbrist',
        description: 'Jämför din snittskörd med tillgångligen på lättlöslig fosfor för att se om det finns något samband för de områden där du har lägre skörd.',
        useYearsFrom: false, 
        useCropsFrom: ["yieldStatistics"],
        requiredData: ["yieldStatistics"],    
        settings: {
          activeView: "compare2correlation",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldStatistics',
          compareUpperLeftLayerId: '{crop}',
          compareLowerLeftLayer: 'soilMapUploaded_p_al',
          compareLowerLeftLayerId: null,
        }
      },
      {
        id: "yieldKAl",
        caption: 'Hitta områden med kaliumbrist',
        description: 'Jämför din snittskörd med tillgångligen på lättlösligt kalium för att se om det finns något samband för de områden där du har lägre skörd.',
        useYearsFrom: false,
        useCropsFrom: ["yieldStatistics"],
        requiredData: ["yieldStatistics"],    
        settings: {
          activeView: "compare2correlation",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldStatistics',
          compareUpperLeftLayerId: '{crop}',
          compareLowerLeftLayer: 'soilMapUploaded_k_al',
          compareLowerLeftLayerId: '',
        }
      },
      {
        id: "yieldMgAl",
        caption: 'Hitta områden med magnesiumbrist',
        description: 'Jämför din snittskörd med tillgångligen på lättlösligt magnesium för att se om det finns något samband för de områden där du har lägre skörd.',
        useYearsFrom: false,
        useCropsFrom: ["yieldStatistics"],
        requiredData: ["yieldStatistics"],           
        settings: {
          activeView: "compare2correlation",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldStatistics',
          compareUpperLeftLayerId: '{crop}',
          compareLowerLeftLayer: 'soilMapUploaded_mg_al',
          compareLowerLeftLayerId: '',
        }
      },       
    ]    
  },
  {
    id: 'yieldMapSentinel',
    text: 'Skördekarta och satellitbilder',
    options: [
      {
        id: "yieldMapGreenmass",
        caption: 'Variation i skörd och grönmassa',
        description: 'Jämför variationen i din skörd och grönmassa.',
        useYearsFrom: ["yieldMap_dryYield_percent", "greenmassAnalysis"],  
        useCropsFrom: false,
        requiredData: ["yieldMap_dryYield_percent", "greenmassAnalysis"],              
        settings: {
          activeView: "compare2correlation",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldMap_dryYield_percent',
          compareUpperLeftLayerId: '{year}',
          compareLowerLeftLayer: 'greenmassAnalysis',
          compareLowerLeftLayerId: '{year}',
        },
      },
      {
        id: "yieldMapMoisture",
        caption: 'Variation i vattenhalt och fuktighet',
        description: 'Jämför variationen i din skörds vattenhalt och fuktigheten.',
        useYearsFrom: ["yieldMap_waterContent_percent", "moistureAnalysis"],
        useCropsFrom: false,
        requiredData: ["yieldMap_waterContent_percent", "moistureAnalysis"],           
        settings: {
          activeView: "compare2correlation",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldMap_waterContent_percent',
          compareUpperLeftLayerId: '{year}',
          compareLowerLeftLayer: 'moistureAnalysis',
          compareLowerLeftLayerId: '{year}',
        },
      },    
      {
        id: "artificialYieldMaps",
        caption: 'Skördekartor från grönmassa',
        description: 'Jämför din skördekarta med grönmassan under juni.',
        useYearsFrom: ["yieldMap_dryYield_percent", "greenmassAnalysis"],
        useCropsFrom: false,
        requiredData: ["yieldMap_dryYield_percent", "greenmassAnalysis"],
        settings: {
          activeView: "compare4",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldMap_dryYield_percent',
          compareUpperLeftLayerId: '{year}',
          compareUpperRightLayer: 'greenmass_percent',
          compareUpperRightLayerId: 'cropCode=all/time={year}-06-/index=first',
          compareLowerLeftLayer: 'greenmass_percent',
          compareLowerLeftLayerId: 'cropCode=all/time={year}-06-/index=middle',
          compareLowerRightLayer: 'greenmass_percent',
          compareLowerRightLayerId: 'cropCode=all/time={year}-06-/index=last',
        },
      }, 
    ]    
  },  
  {
    id: 'planning',
    text: 'Planering',
    options: [
      {
        id: "germination",
        caption: 'Uppkomst',
        description: 'Jämför din skördekarta med uppkomsten av grödan tidigt under odlingssäsongen.',
        useYearsFrom: ["yieldMap_dryYield_percent", "greenmassAnalysis"],
        useCropsFrom: false,
        requiredData: ["yieldMap_dryYield_percent", "greenmassAnalysis"],
        settings: {
          activeView: "compare4",
          fullscreenMode: true,
          compareUpperLeftLayer: 'yieldMap_dryYield_percent',
          compareUpperLeftLayerId: '{year}',
          compareUpperRightLayer: 'soilMapUploaded_organic',
          compareUpperRightLayerId: '2011',
          // compareUpperRightLayerId: 'cropCode=all/time=latest/index=0',
          compareLowerLeftLayer: 'greenmass_percent',
          compareLowerLeftLayerId: 'cropCode=all/time={year}-03-/index=last',
          compareLowerRightLayer: 'greenmass_percent',
          compareLowerRightLayerId: 'cropCode=all/time={year}-03-/index=middle',
        },
      },      
    ]    
  },    
  {
    id: 'custom',
    text: 'Egna'
  }, 
  ];
