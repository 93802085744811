import React, { Component } from "react";

import { 
  Map as LeafletMap, 
  TileLayer, 
  Circle, 
  Polygon 
} from "react-leaflet";

import { 
  Segment, 
  Header,
  Button,
  Icon,
  Table,
  Image,
  Modal,
} from "semantic-ui-react";

import { CROP_CODES } from "@/constants/cropCodes";
import { AREAL_TILE_URL } from "@/constants/map";

import { geoPointToPosition } from "@/helpers/db";
import { getFieldMapOptions } from "@/helpers/maps";

import illustrationDone from "@/assets/illustrations/done_simple.png";

class UploadYieldMapsMapPreviewPerField extends Component {
  state = {
    center: [58.41086, 15.62157],
    error: false,
    showDetailsModal: false,
    selectedFieldData: null,
  };

  toggleShowDetailsModal = (selectedFieldData) => {
    let currentValue = this.state.showDetailsModal;
    this.setState({ 
      showDetailsModal: !currentValue,
      selectedFieldData: selectedFieldData ? selectedFieldData : null,
    });
  };

  getDetailsModal = () => {
    const {
      fields,
    } = this.props;

    const {
      selectedFieldData: {
        markers, 
        mapOptions, 
        fieldsInFile, 
        fileName, 
        year, 
        crop
      },
    } = this.state;

    let cropString = typeof(crop) === "string" ? crop : (typeof(crop) === "number" ? crop.toFixed(0) : crop);
    let cropName = cropString && CROP_CODES && 
      CROP_CODES.find((x) => x.key === cropString) && 
      CROP_CODES.find((x) => x.key === cropString).shorttext;
  
    return (
      <Modal open={true}>
        <Modal.Content>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em"
            }}
          >

            <div>
              {!markers &&
                <Header 
                  as="h4"
                  style={{
                    yearAlign: "center",
                    fontWeight: "normal",
                    marginTop: "2em",
                  }}
                >
                  <Icon name="spinner" loading />
                  Laddar skördekartor...
                </Header>
              }

              {markers &&
                <LeafletMap
                  {...mapOptions}
                  zoom={15}
                  maxZoom={20}
                  zoomSnap={0.1}
                >
                  {fields && fieldsInFile &&
                    Object.keys(fields).map(key => (
                      <Polygon
                        key={key}
                        positions={geoPointToPosition(fields[key].position)}
                        color={
                          fieldsInFile.includes(key)
                            ? "rgba(251, 189, 8, 0.9)"
                            : "lightgrey"
                        }
                        fillOpacity={0.2}
                        weight={3}
                      />
                    ))}

                  <TileLayer url={AREAL_TILE_URL} />
                  
                  {markers && markers.map((marker, idx) => (
                    <Circle
                      key={idx}
                      center={{
                        lat: marker.latitude,
                        lng: marker.longitude
                      }}
                      color={"rgba(251, 189, 8, 1.0)"}
                      interactive={false}
                      radius={14}
                      fill={true}
                      fillOpacity={0.75}
                    />
                  ))}
                </LeafletMap>
              }
          </div>
        
          <div 
            style={{
              marginLeft: "4em",
            }}
          >
            <Header 
              as="h3"
              style={{
                fontWeight: 500,
              }}              
            >
              Information om skördekarta
            </Header>
            
            <div style={{ opacity: 0.7 }}>
              <strong>Filnamn</strong>: {fileName && fileName}
            </div>                

            <div style={{ marginTop: "1em" }}>
              <div style={{ opacity: 0.7 }}>
                <strong>År</strong>: {year ? year + "." : "okänt."}
              </div>
              <div style={{ opacity: 0.7 }}>
                <strong>Gröda</strong>: {cropName ? cropName + "." : "okänd."}
              </div>   
            </div>
          </div>
          </div>
        </Modal.Content>

        <Modal.Actions
          style={{ 
            display: "flex",
            justifyContent: "space-between"
          }}                 
        >
          <div />

          <Button  
            style={{ 
              backgroundColor: "#6B9D9F",
              color: "white",
            }}
            onClick={() => this.toggleShowDetailsModal()}
          >
            Stäng
          </Button>            
        </Modal.Actions>
      </Modal>
    );
  };

  render() {
    const { 
      key,
      fields,
      fieldId,
      uploadedFileData,
    } = this.props;

    const { 
      showDetailsModal,
    } = this.state;

    let fieldName = fieldId && fields && fields[fieldId] && fields[fieldId].name;

    let reformattedUploadedFileData = [];

    uploadedFileData && uploadedFileData.forEach((x) => {
      if (!x) {
        return;
      };

      let fileName = x && x.original_file_name && x.original_file_name;

      let fileFormat = x && x.file_format && x.file_format;
      let fieldsInFile = x && x.fields_in_file && x.fields_in_file;
      let year = x && x.year && x.year;
      let crop = x && x.crop && x.crop;
      let markers = x && x.samples_in_file && x.samples_in_file;

      fileName = (fileName && fileName.length > 50) ? fileName.substr(0, 50 - 1) + '...' : fileName;

      let cropString = typeof(crop) === "string" ? crop : (typeof(crop) === "number" ? crop.toFixed(0) : crop);
      let cropName = cropString && CROP_CODES && 
        CROP_CODES.find((x) => x.key === cropString) && 
        CROP_CODES.find((x) => x.key === cropString).shorttext;

      let detectedFieldPositions = [];
      fieldsInFile && fieldsInFile.forEach((fieldId) => {
        detectedFieldPositions = [...detectedFieldPositions, ...fields[fieldId].position]
      });
      
      detectedFieldPositions = detectedFieldPositions && detectedFieldPositions.length > 0 ? 
        detectedFieldPositions : 
        markers && markers.map((x) => ({latitude: x.latitude, longitude: x.longitude}));

      const mapOptions = getFieldMapOptions(
        { 
          position: detectedFieldPositions 
        },
        { 
          height: "30em",
          width: "30em",
          top: null,
        },
        {
          zoom: 8,
          boundsOptions: { padding: [10, 10] },
        }
      );
      
      reformattedUploadedFileData = [...reformattedUploadedFileData,
        {
          crop: crop,
          cropName: cropName,
          year: year,
          fileName: fileName,
          fileFormat: fileFormat,
          markers: markers,
          fieldsInFile: fieldsInFile,
          mapOptions: mapOptions,
      }];
    });

    reformattedUploadedFileData = reformattedUploadedFileData && reformattedUploadedFileData.sort((a, b) => {
      const yearA = a && a.year && parseInt(a.year);
      const yearB = b && b.year && parseInt(b.year);
      return yearA < yearB ? -1 : yearA > yearB ? 1 : 0;
    });

    return (      
      <React.Fragment>
        {showDetailsModal && this.getDetailsModal()}

        <Segment
          key={key}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1em",
            paddingTop: "2em",
            paddingBottom: "2em",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "10em",
              marginTop: "auto",
              marginBottom: "auto",          
              overflowY: "hidden",
            }}
          >
            <Image src={illustrationDone} />
          </div>

          <div
            style={{
              height: "100%",
              width: "calc(100% - 10em)",
              marginLeft: "2em",
              marginRight: "2em",
              marginTop: "auto",
              marginBottom: "auto",              
              whiteSpace: "pre-line",
            }}          
          >
            <Header 
              as="h2" 
              style={{
                marginBottom: 5,
                fontWeight: 500,
              }}
            >
              Bearbetade skördekartor för {fieldName && fieldName}
            </Header>
            
            <p>
              Freja har hittat följande skördekartor för detta skifte bland de filer som du laddade upp.
              Du kommer snart kunna se dess under fliken "Utforska".
            </p>
                        
            <Table 
              basic='very' 
              striped
              style={{ marginTop: "2em" }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>År</Table.HeaderCell>
                  <Table.HeaderCell>Gröda</Table.HeaderCell>
                  <Table.HeaderCell>Filnamn</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {reformattedUploadedFileData && reformattedUploadedFileData.map((x) => {
                  
                  return (
                    <Table.Row
                      key={"tableRow_" + fieldId + "_" + x.fileName}
                    >
                      <Table.Cell>{x.year ? x.year : "Okänd"}</Table.Cell>
                      <Table.Cell>{x.cropName ? x.cropName : "Okänd"}</Table.Cell>
                      <Table.Cell>{x.fileName ? x.fileName : "Okänd"}</Table.Cell>
                      <Table.Cell
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div />

                        <Button
                          icon
                          labelPosition="left"
                          size="tiny"
                          style={{
                            backgroundColor: "#89A275",
                            color: "white",
                          }}
                          onClick={() => this.toggleShowDetailsModal(x)}
                        >
                          <Icon name="expand" />
                          Visa fil
                        </Button> 
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>        
          </div>
        </Segment>
      </React.Fragment>
    );
  } 
}

export default UploadYieldMapsMapPreviewPerField;

