export const CALCULATION_LABELS_PHOSPHOROUS_V1 = {
    caption_1: 'Fosforklass',
    p_al_value: 'Lättlösligt fosfor (mg per kg)',
    p_class_cell: 'Fosforklass',

    caption_2: 'Skörd',
    expected_yield: 'Snittskörd (ton/ha)',

    caption_3: 'Fosforbehov',
    base_ration: 'Riktgiva (kg P/ha)',
    yield_compensation_factor: 'Kompensation för skördestorlek (kg P/ha)',
    previous_ration: 'Tidigare giva (kg N/ha)',
    vra_map: 'Total fosforgiva (kg P/ha)',
    ratio: 'Denna givas storlek (%)',
    vra_map_final: 'BOLDFosforgiva (kg P/ha)'
};

export const CALCULATION_LABELS_POTASSIUM_V1 = {
    caption_1: 'Kaliumklass',
    k_al_value: 'Lättlösligt kalium (mg per kg)',
    k_class_cell: 'Kaliumklass',

    caption_2: 'Skörd',
    expected_yield: 'Snittskörd (ton/ha)',

    caption_3: 'Kaliumbehov',
    base_ration: 'Riktgiva (kg K/ha)',
    yield_compensation_factor: 'Kompensation för skördestorlek (kg K/ha)',
    previous_ration: 'Tidigare giva (kg N/ha)',
    vra_map: 'Total kaliumgiva (kg K/ha)',
    ratio: 'Denna givas storlek (%)',
    vra_map_final: 'BOLDKaliumgiva (kg K/ha)'
};

export const CALCULATION_LABELS_LIME_STRUCTURE_V1 = {
    caption_1: 'Jordart',
    clay_content: 'Lerhalt (%)',
    mud_type_cell: 'Benämning',

    caption_3: 'Kalkbehov',
    vra_map: 'Total kalkningsgiva (kg Ca/ha)',
    ratio: 'Denna givas storlek (%)',
    vra_map_final: 'BOLDKalkningsgiva (kg Ca/ha)'
};

export const CALCULATION_LABELS_LIME_PH_V1 = {
    caption_1: 'Jordart',
    clay_content: 'Lerhalt (%)',
    mud_type_cell: 'Benämning',
    organic_content: 'Mullhalt (%)',
    organic_type_cell: 'Benämning',
    soil_type_cell: 'Benämning jordart',

    caption_2: 'pH',
    goal_ph: 'Målvärde för pH',
    current_ph: 'Nuvarande värde för pH',
    ph_diff: 'BOLDAntal enheter pH att justera',

    caption_3: 'Kalkbehov',
    ration_lime_per_ph: 'Mängd kalk för att höja pH med en enhet (kg Ca/ha)',
    vra_map: 'Total kalkningsgiva (kg Ca/ha)',
    ratio: 'Denna givas storlek (%)',
    vra_map_final: 'BOLDKalkningsgiva (kg Ca/ha)'
};

export const CALCULATION_LABELS_NITROGEN_V1 = {
    caption_1: 'Skördens storlek',
    expected_yield: 'Förväntad skörd från skördekarta (ton/ha)',
    extra_yield_from_previous_crop: 'Varav skördeökning från förfrukt (ton/ha)',

    caption_2: 'Kvävebehov',
    computed_ration: 'Riktgiva för skördenivå (kg N/ha)',
    compound_spread_bonus: 'Bonus för spridningssätt vid vårsådd (kg N/ha)',
    nitrogen_need: 'BOLDTotalt kvävebehov (kg N/ha)',

    caption_3: 'Kvävetillgång',
    previous_ration: 'Tidigare givor (kg N/ha)',
    ground_delivery: 'Uppskattad markleverans (kg N/ha)',
    extra_nitrogen_from_previous_crop: 'Kväveeffektverkan från förfrukt (kg N/ha)',
    extra_nitrogen_from_humus: 'Kväveleverans från mull (kg N/ha)',
    nitrogen_supply: 'BOLDTotal kvävetillgång (kg N/ha)',

    caption_4: 'Kvävegiva',
    vra_map: 'Totalt kvävegödslingsbehov (kg N/ha)',
    ratio: 'Denna givas storlek (%)',
    vra_map_final: 'BOLDKvävegiva (kg N/ha)'
};
