import React, { Component } from "react";

import {
  Popup,
  Segment,
} from "semantic-ui-react";

import sunandcloud from '@/assets/icons/weather/sunandcloud.png';
import cloud from '@/assets/icons/weather/cloud.png';
import cloudrain from '@/assets/icons/weather/cloudrain.png';
import heavyrain from '@/assets/icons/weather/heavyrain.png';
import snow from '@/assets/icons/weather/snow.png';
import sun from '@/assets/icons/weather/sun.png';
import bolt from '@/assets/icons/weather/bolt.png';

import moment from 'moment';

const symbolToPicture = {
  1: sun,
  2: sun,
  3: cloud,
  4: cloud,
  5: cloud,
  6: cloud,
  7: cloud,
  8: sunandcloud,
  9: cloudrain,
  10: heavyrain,
  11: bolt,
  12: cloud,
  13: cloudrain,
  14: heavyrain,
  15: snow,
  16: snow,
  17: snow,
  18: cloudrain,
  19: cloudrain,
  20: heavyrain,
  21: bolt,
  22: cloudrain,
  23: snow,
  24: snow,
  25: snow,
  26: snow,
  27: snow
};

const symboltextSWE = {
  1: "Klart",
  2: "Lätt molnighet",
  3: "Halvklart",
  4: "Molningt",
  5: "Mycket moln",
  6: "Mulet",
  7: "Dimma",
  8: "Lätt regnskur",
  10: "Regnskur",
  9: "Kraftig regnskur",
  11: "Åskskur",
  12: "Lätt by av regn och snö",
  13: "By av regn och snö",
  14: "Kraftig by av regn och snö",
  15: "Lätt snöby",
  16: "Snöby",
  17: "Kraftig snöby",
  18: "Lätt regn",
  19: "Regn",
  20: "Kraftigt regn",
  21: "Åska",
  22: "Lätt snöblandat regn",
  23: "Snöblandat regn",
  24: "Kraftigt snöblandat regn",
  25: "Lätt snöfall",
  26: "Snöfall",
  27: "Ymnigt snöfall"
};

const INITIAL_STATE = {};

class WeatherDailyForecastWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  getForecast = (forecastOffset, relativeDate=true) => {
    const {
      db,
    } = this.props;

    const dayIntToString = {
      0: "Söndag",
      1: "Måndag",
      2: "Tisdag",
      3: "Onsdag",
      4: "Torsdag",
      5: "Fredag",
      6: "Lördag"
    };

    const dayIntToRelativeString = {
      0: "Idag",
      1: "Imorgon"
    };

    let forecastDay;

    if (relativeDate && forecastOffset < 2) {
      forecastDay = dayIntToRelativeString[forecastOffset]
    } else {
        const forecastDateRaw = moment().add(forecastOffset, 'days')
      forecastDay = dayIntToString[forecastDateRaw.day()]
    };

    const forecastDate = moment().add(forecastOffset, 'days').format('YYYY-MM-DD')

    let forecastData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather &&
      db.fieldsWeather.forecasts &&
      db.fieldsWeather.forecasts[forecastDate];

    let totalPrec = forecastData && forecastData.rain;
    let maxTemp = forecastData && forecastData.max_temp;
    let minTemp = forecastData && forecastData.min_temp;
    let weatherSymbol = forecastData && forecastData.symbol;
    let weatherIcon = symbolToPicture[weatherSymbol];

    let forecastTempData = forecastData && forecastData["interpolated_forecast_temps"];

    let items = forecastTempData && Object.keys(forecastTempData).map(function (date) {
      return [date, forecastTempData[date]];
    });

    if (forecastTempData) items.sort((a, b) => new Date(a[0]) - new Date(b[0]));

    if (forecastData) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div
            style={{
              textAlign: "center",
              height: "20%",
            }}
          >
            <Popup
              basic
              trigger={
                <img 
                  src={weatherIcon} 
                  alt="weather" 
                  style={{ 
                    marginTop: "10%",
                    width: "40%",
                    filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)"
                  }} 
                />
              }
              content={symboltextSWE[weatherSymbol]}
            />
          </div>

          <div 
            style={{ 
              marginTop: "1em",
              fontSize: "120%",
              textAlign: "center",
            }}
          >
            {forecastDay && forecastDay}
          </div>

          <Segment
            basic
            secondary
            style={{
              bottom: 0,
              textAlign: "center",
              marginTop: "1em",
              marginBottom: 0,
              fontWeight: 600,
              fontSize: "160%",
              backgroundColor: "#f7f4f0",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "normal",
                margin: 0,
                fontSize: "70%",
                color: "grey",
                width: "100%",
              }}
            >
              <span style={{ fontWeight: "heavy" }}>{maxTemp && Math.round(maxTemp)}°C</span>
              {" / "}
              <span style={{ fontWeight: "normal" }}>{minTemp && Math.round(minTemp)}°C</span>
              
            </p>
            
            <p style={{ marginBottom: 0, marginTop: 10, width: "100%" }}>
              <span
                style={{
                  textAlign: "center",
                  fontWeight: "normal",
                  fontSize: "75%",
                }}
              >
                {totalPrec && Math.round(totalPrec)} mm
              </span>                
            </p>            
          </Segment>
        </div>
      )
    } else {
      return <div />
    }
  }

  render() {

    return (
      <div
        style={{ 
          display: "block-inline",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          {this.getForecast(0)}
          {this.getForecast(1)}
          {this.getForecast(2)}
        </div>
      </div>
    )
  }
}

export default WeatherDailyForecastWidget;
