import React, { Component } from "react";

import { 
  Table,
  Message,
} from "semantic-ui-react";

import moment from 'moment';

import GuidesCollectDataDropzone from "@/components/guideData/GuidesCollectDataDropzone";
import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

import AddNewFieldsPlaceField from "./addFieldManually";
import HandleFields from "./addFieldHandleFields";

import { getSamApplicationFields } from "@/helpers/sam";

const INITIAL_STATE = {};

class AddNewFieldsAddField extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  selectSamApplication = (filePath) => {
    const {
      fileBucketRef,
    } = this.props;

    this.props.setParentState({ isParsingFile: true });
    this.gotoNextStep("samApplication", "samApplicationSelectFields");

    filePath && fileBucketRef.child(filePath).getDownloadURL()
      .then(url => {
        window.fetch(url)
          .then(async res => {
            getSamApplicationFields({...this.props}, await res.clone().text());
          });
      });
  };

  onClickNextStep = () => {
    const {
      completedSteps,
    } = this.props;

    this.props.setParentState({
      openSegment: '',
      completedSteps: [...completedSteps, "placeFarm"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment) => {};

  onChange = (data, onlyNumbers=true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  }; 

  onChangeStruct = (e, data, variableName, onlyNumbers=true) => {
    let currentValue = this.props[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: {...currentValue, [data.name]: value },
    });
  }; 
  
  setOpenSegment = (newSegment) => {
    const {
      openSegment
    } = this.props;

    (openSegment === newSegment) && this.props.setParentState({ openSegment: null });
    (openSegment !== newSegment) && this.props.setParentState({ openSegment: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedSteps, 
    } = this.props;

    !completedSteps.includes(currentSegment) && this.props.setParentState({
      completedSteps: [...completedSteps, completedSteps],
    });

    this.setDefaultSettings(newSegment);

    !completedSteps.includes(newSegment) && this.setOpenSegment(newSegment);
    completedSteps.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  addFieldManually = (positions) => {
    this.props.addFieldManually(positions);
    this.gotoNextStep("drawFieldBoundaries", "handleFieldsFromBoundaries");
  };

  render() {

    const {
      db,
      completedSteps,
      openSegment,
      fieldSource,
      samApplications,
      fieldIdsAdded,
    } = this.props;
    
    let menuItems = {
      next: [
        {
          caption: "Gå vidare",
          onClick: this.onClickNextStep,
          disabled: fieldIdsAdded && fieldIdsAdded.length < 1,
        }
      ],
    };

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegment
          caption="Hur vill du lägga till nya skiften?"
          simpleModeQuestion="Hur vill du lägga till nya skiften?"
          simpleModeDescription="Freja kan automatiskt hitta dina skiften i SAM-ansökningar som du redan har laddat upp eller strax kan ladda upp."
          simpleModeOpenText="Rita skifteskanter"
          simpleModeOnOpen={() => {
              this.props.setParentState({ fieldSource: "draw" });
              this.gotoNextStep("source", "drawFieldBoundaries");
            }
          }
          simpleModeSkipText="Hämta från SAM-ansökan"
          simpleModeOnSkip={() => {
            this.props.setParentState({ fieldSource: "sam" });
            this.gotoNextStep("source", "samApplication");
          }}          
          open={openSegment === "source"}
          complete={completedSteps && completedSteps.includes("source")}
          onClickOpenSegment={() => this.setOpenSegment("source")}
        />

        <GuideSegment
          hidden={!fieldSource || fieldSource !== "sam"}
          caption="SAM-ansökan"
          simpleModeQuestion={samApplications && samApplications.length > 0 ? "Vilken SAM-ansökan vill du använda?" : null}
          simpleModeOpenText="Välj själv eller ladda upp ny"
          simpleModeSkipText="Senaste uppladdad SAM-ansökan"
          simpleModeOnSkip={() => {
            let file = samApplications && samApplications.sort((a, b) => b.dateUploaded - a.dateUploaded);
            file = file && file[0];
            file && this.selectSamApplication(file.filePath)
          }}               
          open={openSegment === "samApplication"}
          complete={completedSteps && completedSteps.includes("samApplication")}
          onClickOpenSegment={() => this.setOpenSegment("samApplication")}
          hideDoneButton={true}
          // onClickNextStep={() => this.gotoNextStep("samApplication", "samApplicationSelectFields")}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
            }}
          >
            Låt Freja hitta dina skiften genom att ladda upp en ny SAM-ansökan eller genom att välja en redan uppladdad ansökan.
          </p>

          {(!samApplications || samApplications.length === 0) && 
              <Message fluid>
                Freja kunde inte hitta några uppladdade SAM-ansökningar.
              </Message>
            }

            {samApplications && samApplications.length > 0 &&
              <Table sortable selectable  compact size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Filnamn</Table.HeaderCell>
                    <Table.HeaderCell>Uppladdad</Table.HeaderCell>
                    <Table.HeaderCell />                      
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                {samApplications && samApplications.map((file, idx) => {
                  return (
                    <Table.Row
                      key={idx}
                      style={{
                        padding: 12,
                      }}
                    >
                      <Table.Cell>{file.fileName}</Table.Cell>
                      <Table.Cell>{moment(file.dateUploaded).format("YYYY-MM-DD")}</Table.Cell>
                      <Table.Cell>
                        <p 
                          style={{
                            textAlign: "center",
                            color: "#6B9D9F",
                            cursor: "pointer",
                          }}
                          onClick={() => file && this.selectSamApplication(file.filePath)}
                        >
                          Välj
                        </p>
                      </Table.Cell>                              
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          }

          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
              marginTop: "2em",
              marginBottom: "2em",
            }}
          >
            Hittar du inte någon bra ansökan ovan så kan du ladda upp en ny. Din SAM-ansökan kan du ladda ned från <a href="https://jordbruksverket.se/e-tjanster-databaser-och-appar/e-tjanster-och-databaser-stod/sam-internet" target="_blank" rel="noopener noreferrer">SAM Internet</a>. Välj att exportera en XML-fil genom att logga in och sedan klicka på knappen Import/Export uppe fill höger.
          </p>
          
          <GuidesCollectDataDropzone 
            inSegment={false}
            activeView="uploadSamApplication"
            onDroppedFiles={this.props.onUploadFiles}
            collectDataError={this.props.collectDataError}
          />      
        </GuideSegment>

        <GuideSegment
          style={{
            maxHeight: null,
          }}
          hidden={!fieldSource || fieldSource !== "sam"}
          caption="Hantera skiften"
          open={openSegment === "samApplicationSelectFields"}
          complete={completedSteps && completedSteps.includes("samApplicationSelectFields")}
          onClickOpenSegment={() => this.setOpenSegment("samApplicationSelectFields")}
          onClickNextStep={() => this.onClickNextStep()}
          hideDoneButton={true}
        >
          <HandleFields 
            {...this.props}
            fieldSource={fieldSource}
            addField={this.props.addField}
            removeField={this.props.removeField}
            onGoBackToUploadSam={() => this.setOpenSegment("samApplication")}
          />
        </GuideSegment> 

        <GuideSegment
          hidden={!fieldSource || fieldSource !== "draw"}
          caption="Rita ut skifteskanter"
          open={openSegment === "drawFieldBoundaries"}
          complete={completedSteps && completedSteps.includes("drawFieldBoundaries")}
          onClickOpenSegment={() => this.setOpenSegment("drawFieldBoundaries")}
          onClickNextStep={() => this.onClickNextStep()}
          hideDoneButton={true}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
              marginBottom: "2em",
            }}
          >
            För att lägga till ett skifte behöver du först navigera till platsen där det finns genom att zooma och dra på kartan. Klicka sedan på kartan för att markera kanterna på skiftet. Du kan ta bort och rensa punkter som du markerat ut genom att använda knapparna under kartan.
          </p>

          <AddNewFieldsPlaceField 
            db={db}
            addFieldManually={this.addFieldManually}
          />
        </GuideSegment>                             

        <GuideSegment
          style={{
            maxHeight: null,
          }}
          hidden={!fieldSource || fieldSource !== "draw"}
          fieldSource={fieldSource}
          caption="Hantera skiften"
          open={openSegment === "handleFieldsFromBoundaries"}
          complete={completedSteps && completedSteps.includes("handleFieldsFromBoundaries")}
          onClickOpenSegment={() => this.setOpenSegment("handleFieldsFromBoundaries")}
          onClickNextStep={() => this.onClickNextStep()}
          editButtonText="Lägg till nytt eget skifte"
          onClickEdit={() => this.setOpenSegment("drawFieldBoundaries")}
          hideDoneButton={true}
        >
          <HandleFields 
            {...this.props} 
            addField={this.props.addField}
            removeField={this.props.removeField}
            onBackToDrawNewField={() => this.setOpenSegment("drawFieldBoundaries")}
          />
        </GuideSegment> 

      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader=""
        mainColumnBody=""    
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default AddNewFieldsAddField;