import React, { Component } from "react";

import { getColorScales, getDataStats } from "@/components/fieldData/FieldDataColorScales";

import MapWithCircleMarkers from "@/components/map/MapWithCircleMarkers";

import MapGroupsWidget from "@/components/map/MapGroupsWidget";
import MapOverviewWidget from "@/components/map/MapOverviewWidget";
import MapStatsWidget from "@/components/map/MapStatsWidget";

import { DATA_LAYERS, getCurrentDataLayer } from "@/constants/viewLayersAnalysis";
import { CROP_CODES } from "@/constants/cropCodes";
import { fetchData } from "@/helpers/dataFetcher";

class GuideMapEditorViewer extends Component {
  state = {
    geoJsonData: null,
    geoJsonFieldKey: null,
    statJson: null,
    polygonImage: null,
    selectedIdx: null,
    legendVisible: true,
    loadingData: false,
    dataLayerMissing: false,
  };

  componentDidMount() {
    fetchData({...this.props, downloadNewData: true, setParentState: this.setParentState});
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      db,
      fieldId,
      layer,
      layerId,
    } = this.props;

    let hasChangedLayer = false;
    let payload = {...this.props, downloadNewData: true, setParentState: this.setParentState};

    // Get the data type of the current data layer (float, percent, integer, etc.)
    let dataLayer = getCurrentDataLayer(layer);

    // Get the collection name
    let dbDataDocName = dataLayer && dataLayer.dbDataDocName;
    dbDataDocName = typeof(dbDataDocName) === "string" ? dbDataDocName : dbDataDocName && dbDataDocName[layer];

    let dbDataColName = dbDataDocName && dbDataDocName.split("/")[0];

    if (
      fieldId !== prevProps.fieldId ||
      layer !== prevProps.layer ||
      layerId !== prevProps.layerId
    ) {

      let downloadNewData = true;

      if (fieldId === prevProps.fieldId) {
        // Same field
        
        if (layer && prevProps.layer && (layer.split("_")[0] === prevProps.layer.split("_")[0])) {
          // Same family of layer

            if (layerId === prevProps.layerId) {
              // Same date, year or type of crop
              downloadNewData = false;
            };

            if (layer === "soilMapUploaded_samples" || prevProps.layer === "soilMapUploaded_samples") {
              downloadNewData = true;
            };

            if (layer === "soilMapAnalysis_samples" || prevProps.layer === "soilMapAnalysis_samples") {
              downloadNewData = true;
            };            
        };
      };

      if (!this.state.geoJsonData) {
        downloadNewData = true;
      }

      downloadNewData && this.setState({
        selectedMarker: null,
        geoJsonData: null,
        polygonImage: null,
        statJson: null,
        loadingData: true,
        dataLayerMissing: false,
      });

      // Layer has been changed so skip checking for changes in database
      hasChangedLayer = true;

      fetchData({...payload, downloadNewData: downloadNewData});  
    };

    let dbCollection = dbDataColName && db && fieldId && db[dbDataColName + "_" + fieldId];
    let prevDbCollection = dbDataColName && prevProps.db && fieldId && prevProps.db[dbDataColName + "_" + fieldId];

    if (!this.state.geoJsonData && !hasChangedLayer && ( 
      dbCollection !== prevDbCollection ||
      this.state.selectedIdx !== prevState.selectedIdx
    )) {
      fetchData(payload);
    };
  };

  toggleLegendVisible = () => {
    const currentValue = this.state.legendVisible;
    this.setState({ legendVisible: !currentValue });
  };

  setParentState = (newState) => {
    this.setState(newState);
  };
  
  selectMarker = (marker) => {
    const {
      selectedMarker,
    } = this.props;
    
    if ((selectedMarker && selectedMarker.length === 1 && selectedMarker[0] === marker)) {
      this.setState({
        selectedMarker: [],
      });

      this.props.setContainerState({
        selectedGroup: null,
      })

    } else {
      this.setState({
        selectedMarker: marker ? [marker] : [],
      });

      this.props.setContainerState({
        selectedGroup: null,
      });
    };

    if (this.props.selectMarker) {
      this.props.selectMarker(marker)
    };    

  };

  setSelectedGroup = (newIndex, group) => {
    let selectedGroupMarkers = this.props.selectedGroupMarkers;
    
    if (!group || (selectedGroupMarkers && selectedGroupMarkers.length > 0 && selectedGroupMarkers[0] === group.sampleIndices[0])) {

      this.props.setContainerState({
        selectedGroup: null,
      });
  
      this.setState({
        selectedGroupMarkers: [],
      });
  
      if (this.props.selectGroupMarkers) {
        this.props.selectGroupMarkers([])
      };

    } else {
      this.props.setContainerState({ 
        selectedGroup: newIndex,
      });
  
      this.setState({
        selectedGroupMarkers: group.sampleIndices,
      });
  
      if (this.props.selectGroupMarkers) {
        this.props.selectGroupMarkers(group.sampleIndices)
      };
    }
  };  

  render() {
    const {
      db,
      mode,
      userId,
      field,
      fieldId,
      layer,
      layerId,
      lockedMap,
      showArealMap,
      showMissingData,
      markerScale,
      numberOfGroups,
      colorScaleType,
      scale,
      selectedGroup,
      selectedMarker,
      selectedGroupMarkers,
      onHoverPopup,
      loadingDataText,
      activeView,
    } = this.props;

    const {
      statJson,
      geoJsonFieldKey,
      polygonImage,
      dataLayerMissing,
      legendVisible,
    } = this.state;

    let loadingData = typeof(this.props.loadingData) === "boolean" ? this.props.loadingData : this.state.loadingData;

    let fieldSize = fieldId && db && db.fields && db.fields[fieldId] && db.fields[fieldId].field_size && parseFloat(db.fields[fieldId].field_size);

    // Handle data
    let geoJsonData = this.props.markerData ? this.props.markerData : this.state.geoJsonData;
    let sortedGeoJsonData = geoJsonData && geoJsonData.features && [...geoJsonData.features].map((x) => x);

    // Sort the markers according to coordinates so index can be used to select markers
    sortedGeoJsonData = sortedGeoJsonData && sortedGeoJsonData.sort(function (a, b) {   
      return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
    })

    // Add index to points so that they can be used to as selection index
    sortedGeoJsonData = sortedGeoJsonData && {features: sortedGeoJsonData.map((x, idx) => ({...x, idx: idx}))};

    let colorScales = this.props.colorScales ? 
      this.props.colorScales : 
      sortedGeoJsonData && getColorScales(layer, sortedGeoJsonData, geoJsonFieldKey, colorScaleType, numberOfGroups, scale);
    
    let dataStats = colorScales && sortedGeoJsonData && getDataStats(layer, colorScales, sortedGeoJsonData, geoJsonFieldKey);

    let filteredSelectedMarkers = sortedGeoJsonData && sortedGeoJsonData.features;
    
    if (filteredSelectedMarkers && selectedGroupMarkers && selectedGroupMarkers.length > 1) {
      filteredSelectedMarkers = filteredSelectedMarkers.filter((x, idx) => selectedGroupMarkers.includes(idx));
    };

    if (!field) {
      return <div></div>
    };

    let fieldInfo = fieldId && db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let currentYear = layerId && new Date(layerId).getFullYear() && new Date(layerId).getFullYear();

    let currentCropCode = currentYear &&
      fieldInfo &&
      fieldInfo.crop_code &&
      fieldInfo.crop_code[currentYear] &&
      fieldInfo.crop_code[currentYear];

    let cropShortText = currentCropCode && CROP_CODES.find(x => x.value === currentCropCode);
    cropShortText = cropShortText && cropShortText.shorttext;
    
    let layerName = layer && Object.keys(DATA_LAYERS)
      .map(x => DATA_LAYERS[x].shortCaptions)
      .find(y => Object.keys(y).includes(layer));

    layerName = layerName && layerName[layer] && layerName[layer];

    return (
      <React.Fragment>
        <div
          style={{
            // display: "flex",
            width: "100%",
            ...this.props.style
          }}
        >
          <MapWithCircleMarkers
            field={field && field}
            userId={userId}
            fieldId={fieldId}
            layer={layer}
            layerId={layerId}            
            markers={filteredSelectedMarkers && filteredSelectedMarkers}
            allMarkers={sortedGeoJsonData && sortedGeoJsonData.features}
            colorScale={colorScales && colorScales.colorScale}
            dataStats={dataStats}
            markerScale={markerScale}
            polygonImage={polygonImage}
            onMarkerClick={this.selectMarker}
            selectedMarker={selectedMarker}
            geoJsonFieldKey={geoJsonFieldKey}
            showMissingData={showMissingData}
            lockedMap={lockedMap}
            showArealMap={showArealMap}
            mapStyle={this.props.mapStyle}
            mapOptions={this.props.mapOptions}
            dataLayerMissing={dataLayerMissing}
            placeholderText={this.props.placeholderText}
            placeholderOnClick={this.props.placeholderOnClick}
            loadingData={loadingData}
            loadingDataText={loadingDataText}
            onHoverPopup={onHoverPopup}
            onClickMap={this.props.onClickMap}
            selectedAreaPoints={this.props.selectedAreaPoints}
          >

            {mode === "vraMapEditor" &&
              <MapOverviewWidget
                db={db}
                getUser={this.props.getUser}
                mode={mode}
                userId={userId}
                fieldId={fieldId}
                fieldName={field.name}
                size={fieldSize}
                layerName={layerName}
                date={layerId}
                cropCode={currentCropCode}
                cropType={cropShortText}
                layer={layer}
                layerId={layerId}
                statJson={statJson}
                hideInfo={layer && layer.includes("vraMap")}
                hideMarkers={true}
                hideWeather={true}
                dataLayerMissing={false}
              />
            }
           
            {layer && 
              <MapGroupsWidget
                mode={mode}
                field={field && field}
                layer={layer}
                layerId={layerId}
                geoJsonFieldKey={geoJsonFieldKey}
                markers={sortedGeoJsonData && sortedGeoJsonData}
                allMarkers={sortedGeoJsonData && sortedGeoJsonData.features}
                statJson={statJson}
                dataStats={dataStats}
                polygonImage={polygonImage}
                selectedGroup={selectedGroup}
                onSelectGroup={this.setSelectedGroup}
              />
            }
            
            {layer && layer.includes("vraMap") && activeView !== "none" &&
              <MapStatsWidget
                db={db}
                mode={mode}
                getUser={this.props.getUser} 
                userId={userId}
                fieldId={fieldId}
                layer={layer}
                layerId={layerId}                
                field={field && field}
                statJson={statJson}
                dataStats={dataStats}
                allMarkers={sortedGeoJsonData && sortedGeoJsonData.features}
                geoJsonData={sortedGeoJsonData}
                polygonImage={polygonImage}
                toggleLegendVisible={this.toggleLegendVisible}
                legendVisible={legendVisible}
                fieldSize={fieldSize}
              />
            }

            {this.props.children && this.props.children}
          </MapWithCircleMarkers>
        </div>
      </React.Fragment>
    );
  }
}

export default GuideMapEditorViewer;
