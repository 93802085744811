import React from "react";

import {
  Modal,
  Button,
  Icon,
  Header
} from "semantic-ui-react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { TUTORIALS } from "@/constants/tutorials";

import IconWithPlate from "@/components/misc/IconWithPlate";

class TutorialModal extends React.Component {
  state = {
    open: false,
    activeSlide: 0,
  };

  updateHasSeenTutorial = () => {
    const {
      db,
      tutorialName,
    } = this.props;

    if (db && db.settings && db.settings.tutorials) {
      this.props.getUser().collection("settings").doc("tutorials").update({ [tutorialName]: new Date() });
    } else {
      this.props.getUser().collection("settings").doc("tutorials").set({ [tutorialName]: new Date() });
    }
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  closeGuide = () => {
    const {
      db,
      tutorialName,
    } = this.props;

    let hasSeenTutorialBefore = db && tutorialName &&
      db.settings &&
      db.settings.tutorials &&
      db.settings.tutorials[tutorialName] &&
      db.settings.tutorials[tutorialName];

    if (!hasSeenTutorialBefore) {
      this.updateHasSeenTutorial();
    };

    this.setState({
      open: false,
      activeSlide: 0,
    });
  };

  getSliderCard = (page, idx) => (
    <div
      key={'card_' + idx}
    >
      <div style={{ display: "flex" }}>
        {page.image && page.imagePosition === "left" &&
          <img
            style={{
              objectFit: "contain",
              height: "25em",
              maxWidth: "45%",
              marginRight: "2em",
              padding: page.imagePadding ? "2em" : null,
              filter: page.imageFilter ? page.imageFilter : null,
            }}
            alt=''
            src={page.image}
          />
        }

        {page.iconWithPlate && page.imagePosition === "left" &&
          <IconWithPlate
            style={{
              objectFit: "contain",
              maxWidth: "45%",
              marginRight: "5em",
            }}
            plateBackground={page.plateBackground ? page.plateBackground : null}
            filter={page.imageFilter ? page.imageFilter : null}
            size="22em"
            src={page.iconWithPlate}
            fitted={true}
          />
        }

        <div style={{
          flex: 1,
          display: "block",
          marginTop: "auto",
          marginBottom: "auto",
          width: "80%",
        }}
        >
          <Header
            as='h1'
            style={{
              fontWeight: "500",
            }}
          >
            {page.header}
          </Header>

          {page.text && Array.isArray(page.text) && page.text.map((x, idx) => (
            <p
              key={'tutorialParagraph_' + x}
              style={{
                marginTop: "1em",
                whiteSpace: "normal",
                fontSize: "110%",
                lineHeight: "130%"
              }}
            >
              {x}
            </p>
          ))}

          {page.bulletList && Array.isArray(page.bulletList) &&
            <React.Fragment>
              <ul
                style={{
                  fontSize: "110%",
                  lineHeight: "130%"
                }}
              >
                {page.bulletList.map((x, idx) => (<li key={'tutorialBulletList_' + x}>{x}</li>))}
              </ul>
            </React.Fragment>
          }

        </div>

        {page.imagePosition === "right" &&
          <img
            style={{
              objectFit: "contain",
              height: "25em",
              maxWidth: "45%",
              marginLeft: "2em",
              padding: page.imagePadding ? "2em" : null,
              filter: page.imageFilter ? page.imageFilter : null,
            }}
            alt=''
            src={page.image}
          />
        }

        {page.iconWithPlate && page.imagePosition === "right" &&
          <IconWithPlate
            style={{
              objectFit: "contain",
              maxWidth: "45%",
              marginLeft: "5em",
            }}
            plateBackground={page.plateBackground ? page.plateBackground : null}
            filter={page.imageFilter ? page.imageFilter : null}
            size="22em"
            src={page.iconWithPlate}
            fitted={true}
          />
        }
      </div>
    </div>
  )

  prevSlide = () => {
    this.slider.slickPrev();
  };

  nextSlide = () => {
    const {
      tutorialName,
    } = this.props;

    const {
      activeSlide
    } = this.state;

    let cards = TUTORIALS.filter((x) => x.name === tutorialName);
    cards = cards && cards[0] && cards[0].cards;

    if (activeSlide === (cards.length - 1)) {
      this.closeGuide()
    } else {
      this.slider.slickNext()
    }
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      afterChange: current => this.setState({ activeSlide: current }),
    };

    const {
      open,
      activeSlide,
    } = this.state;

    const {
      db,
      trigger,
      tutorialName,
      isAdminUser,
    } = this.props;

    let hasSeenTutorialBefore = db && tutorialName &&
      db.settings &&
      db.settings.tutorials &&
      db.settings.tutorials[tutorialName] &&
      db.settings.tutorials[tutorialName];
    

    let cards = TUTORIALS.filter((x) => x.name === tutorialName);
    cards = cards && cards[0] && cards[0].cards;

    let hasLoadedData = db && db.settings && db.settings.tutorials && db.settings.tutorials.show_tutorials === true;

    if (!hasLoadedData || !cards) {
      return <div></div>
    };

    return (
      <React.Fragment>
        {trigger && React.cloneElement(trigger, { onClick: this.toggleOpen })}

        <Modal 
          open={!isAdminUser && cards && (open || !hasSeenTutorialBefore)}
          style={{
            zIndex: 2
          }}
        >
          <Modal.Content style={{ padding: "2em" }}>
            <Slider ref={c => (this.slider = c)} className="welcome-slider" {...settings}>
              {cards && cards.map((x, idx) => this.getSliderCard(x, idx))}
            </Slider>
          </Modal.Content>

          <Modal.Actions
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              icon
              labelPosition={activeSlide === 0 ? null : "left"}
              style={{
                backgroundColor: activeSlide === 0 ? "transparent" : "#868D8E",
                color: activeSlide === 0 ? "transparent" : "white",
              }}
              disabled={activeSlide === 0}
              onClick={() => this.prevSlide()}
            >
              <Icon name="chevron left" />
              Tillbaka
            </Button>

            <div>
              {cards && cards.map((x, idx) =>
                <Icon
                  key={"navigationDot_" + idx}
                  style={{
                    marginTop: "1.25em",
                    color: (activeSlide === idx) ? null : "#D6D9D9"
                  }}
                  name="circle"
                  size="small"
                />
              )}
            </div>

            <Button
              icon
              labelPosition="right"
              style={{
                backgroundColor: "#6B9D9F",
                color: "white",
              }}
              onClick={() => this.nextSlide()}
            >
              {(activeSlide === cards.length - 1) ? "Stäng" : "Gå vidare"}
              <Icon name={(activeSlide === cards.length - 1) ? "check" : "chevron right"} />
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
};

export default TutorialModal;

