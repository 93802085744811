import React, { Component } from "react";

import { 
  Button, 
  Input,
  Menu,
  Segment,
  Modal,
} from "semantic-ui-react";

import ModalMenuItem from "@/components/menus/ModalMenuItem";

import { CROP_CODES } from "@/constants/cropCodes";
import { COMPARE_PALETTES } from "@/constants/comparePalettes";
import { FIELD_DATA_LAYERS, FIELD_ANALYSIS_LAYERS } from "@/constants/viewLayersAnalysis";

import "./style.css";

import moment from 'moment';

const INITIAL_STATE = {
  selectedMenuItem: 'yield',
  caption: '',
  paletteSettings: {},
};

export class FieldDataComparePalettes extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onChangeYearCrop = (name, value) => {
    // let currentValue = this.state.paletteSettings;
    // this.setState({paletteSettings: {...currentValue, [name]: value}});
    this.setState({paletteSettings: {[name]: value}});
  };

  handleItemClick = (x) => this.setState({ selectedMenuItem: x })

  getPaletteAlternatives = () => {
    const {
      db,
      fieldId,
    } = this.props;

    const { 
      paletteSettings,
      selectedMenuItem, 
    } = this.state;
    
    let DATA_LAYERS = { ...FIELD_DATA_LAYERS, ...FIELD_ANALYSIS_LAYERS };
    
    let paletteOptions = selectedMenuItem && COMPARE_PALETTES && COMPARE_PALETTES.find((x) => x.id === selectedMenuItem).options;
    
    return (
      <React.Fragment>
        {paletteOptions.map((x) => {

          let hasRequiredData = true;

          x.requiredData && x.requiredData.forEach((y) => {
            let dataLayer = DATA_LAYERS && Object.keys(DATA_LAYERS).find((z) => DATA_LAYERS[z].aliases.includes(y));
            dataLayer = dataLayer && DATA_LAYERS[dataLayer];

            let dbDataDocNameFull = dataLayer && dataLayer.dbDataDocName;
            dbDataDocNameFull = typeof(dbDataDocNameFull) === "string" ? dbDataDocNameFull : dbDataDocNameFull && dbDataDocNameFull[y];

            let dbDataColName = dbDataDocNameFull && dbDataDocNameFull.split("/")[0];
            let dbDataDocName = dbDataDocNameFull && dbDataDocNameFull.split("/")[1];

            let dbDataCol = dbDataColName && fieldId && db && db[dbDataColName + "_" + fieldId];
          
            let dbDataDoc = dbDataCol && dbDataDocName && dbDataCol[dbDataDocName];

            hasRequiredData = hasRequiredData ? dbDataDoc : false;
          });

          let availableYears, availableCrops;

          let getCropsYearsFrom = [];
          getCropsYearsFrom = x.useCropsFrom ? [...getCropsYearsFrom, ...x.useCropsFrom] : getCropsYearsFrom;
          getCropsYearsFrom = x.useYearsFrom ? [...getCropsYearsFrom, ...x.useYearsFrom] : getCropsYearsFrom;
          
          getCropsYearsFrom.forEach((y) => {
            let dataLayer = DATA_LAYERS && Object.keys(DATA_LAYERS).find((z) => DATA_LAYERS[z].aliases.includes(y));
            dataLayer = dataLayer && DATA_LAYERS[dataLayer];

            let dbDataDocNameFull = dataLayer && dataLayer.dbDataDocName;
            dbDataDocNameFull = typeof(dbDataDocNameFull) === "string" ? dbDataDocNameFull : dbDataDocNameFull && dbDataDocNameFull[y];

            let dbDataColName = dbDataDocNameFull && dbDataDocNameFull.split("/")[0];
            let dbDataDocName = dbDataDocNameFull && dbDataDocNameFull.split("/")[1];

            let dbDataCol = dbDataColName && fieldId && db && db[dbDataColName + "_" + fieldId];
            let dbDataDoc = dbDataCol && dbDataDocName && dbDataCol[dbDataDocName];
          
            let dbDocDataKeys = dbDataDoc && Object.keys(dbDataDoc).map((x) => x).sort();

            if (x.useYearsFrom) {
              if (availableYears) {
                availableYears = availableYears.filter((z) => dbDocDataKeys.includes(z));
              } else {
                availableYears = dbDocDataKeys;
              }
            }

            if (x.useCropsFrom) {
              if (availableCrops) {
                availableCrops = availableCrops.filter((z) => dbDocDataKeys.includes(z));
              } else {
                availableCrops = dbDocDataKeys;
              }
            }
          });

          let datesSelect = availableYears && availableYears
          .sort(function(a, b) {
            a = new Date(a);
            b = new Date(b);
            return a > b ? -1 : a < b ? 1 : 0;
          })
          .map(date => ({
            key: date,
            text: date,
            value: date
          }));

          let cropsSelect = [];

          availableCrops && availableCrops.forEach(crop => {
             if (!crop || ['all', 'cereals'].includes(crop)) {
               return;
             };
     
             let cropName = CROP_CODES.find((x) => x.value === crop);
             cropName = cropName && cropName.shorttext && cropName.shorttext;
     
             if (!cropName) {
               return;
             };
     
             cropsSelect = [...cropsSelect, {
               key: crop,
               text: cropName,
               value: crop,
             }]
           })
           
           cropsSelect = cropsSelect && cropsSelect
           .sort(function(a, b) {
             let textA = a.text;
             let textB = b.text;
             return textA > textB ? -1 : textA < textB ? 1 : 0;
           });
    
           if (availableCrops && availableCrops.includes("cereals")) {
            cropsSelect = [{
              key: "cereals",
              text: "Stråsäd",
              value: "cereals",
            },
            ...cropsSelect
            ]
          };           

          
          cropsSelect = [{
            key: "all",
            text: "Alla grödor",
            value: "all",
          },
          ...cropsSelect
          ];
      

          return (
            <ModalMenuItem
              key={x.id}
              title={x.caption}
              description={x.description}
              buttonValue={true}
              buttonText={"Hämta"}
              buttonDisabled={(x.useCropsFrom || x.useYearsFrom) && Object.keys(paletteSettings).length < 1}
              buttonOnClick={!hasRequiredData ? null : () => this.props.onSelectPalette(x.settings, paletteSettings)}
            >
              {!hasRequiredData && 
                <p
                  style={{
                    color: "#C36126",
                    whiteSpace: "pre-line",
                  }}
                >
                  Detta skifte saknar de lager som krävs för att göra denna jämförelse.
                  Om du har skördekartor eller markkartering för detta skifte kan du ladda upp dem under vyn "Samla".
                </p>
              }
              {hasRequiredData && x.useCropsFrom && cropsSelect && cropsSelect.length > 0 &&
                <Segment
                  secondary
                  style={{
                    padding: "0.25em",
                  }}
                >
                  <Menu
                    secondary
                    stackable
                    size="small"
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Menu.Item
                      header
                    >
                      Välj gröda
                    </Menu.Item>

                    {cropsSelect && cropsSelect.map((y) => (
                      <Menu.Item
                        key={y.key}
                        active={paletteSettings.crop === y.value}
                        onClick={() => this.onChangeYearCrop("crop", y.value)}
                      >
                        {y.text}
                      </Menu.Item>
                    ))}
                  </Menu>
                </Segment>   
              }

              {hasRequiredData && x.useYearsFrom && datesSelect && datesSelect.length > 0 &&
                <Segment
                  secondary
                  style={{
                    padding: "0.25em",
                  }}
                >
                  <Menu 
                    secondary
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Menu.Item
                      header
                    >
                      Välj år
                    </Menu.Item>

                    {datesSelect && datesSelect.map((y) => (
                      <Menu.Item
                        key={y.key}
                        active={paletteSettings.year === y.value}
                        onClick={() => this.onChangeYearCrop("year", y.value)}
                      >
                        {y.text}
                      </Menu.Item>
                    ))}
                  </Menu>
                </Segment>   
              }          
            </ModalMenuItem>
          )
        })}
      </React.Fragment>
  )};

  getCustomPaletteAlternatives = () => {
    const { 
      db, 
    } = this.props;

    let ownPalettes = db && db.settings && db.settings.palettes;

    let paletteOptions = [];

    ownPalettes && Object.keys(ownPalettes).forEach((x) => {  
      if (ownPalettes[x]) {
        let palette = ownPalettes[x];

        paletteOptions = [...paletteOptions,
          {
            id: x,
            caption: palette && palette.text,
            description: "Egen jämförelse skapad: " + moment(x.date_added).format("YYYY-MM-DD") + ".",
            settings: palette && palette.settings,
          }
        ]
      }
    })
    
    return (
      <React.Fragment>
        {paletteOptions && paletteOptions.map((x) => (
          <ModalMenuItem
            key={x.id}
            title={x.caption}
            description={x.description}
            buttonValue={true}
            buttonText="Hämta"
            buttonOnClick={() => this.props.onSelectPalette(x.settings)}
          />
        ))}
      </React.Fragment>
  )};

  onClose = () => {
    this.props.toggleShowComparePaletteModal()
  };

  onChange = (event) => {
    let newValue = event.target.value;
    this.setState({ [event.target.name]: newValue });
  };

  resetAddNewModal = () => {
    this.setState({ caption: '' });
    this.props.setParentState({ showSavePaletteModal: false });
  };

  savePalette = () => {
    this.props.onSavePalette(this.state.caption);
  };  

  render() {
    const { 
      selectedMenuItem, 
      caption,
    } = this.state;
    
    const { 
      openOpenModal,
      openSaveModal,
    } = this.props;

    if (!openSaveModal && !openOpenModal) {
      return <div />
    };

    return (
      <React.Fragment>
        <Modal
          open={openSaveModal}
          size="tiny"
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            Skapa en ny jämförelse
          </Modal.Header>

          <Modal.Content>
            <p>
              Välj ett namn för din nya jämförelse:
            </p>

            <Input
              style={{
                marginTop: 10,
                width: "50%",
              }}
              autoFocus
              name="caption"
              value={caption}
              onChange={(e) => this.onChange(e)}
            />  
          </Modal.Content>

          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}            
          >
            <Button 
              style={{ 
                backgroundColor: "#868D8E", 
                color: "white",
              }}
              onClick={() => this.resetAddNewModal()}
            >
              Avbryt
            </Button>

            <Button 
              style={{ 
                backgroundColor: "#6B9D9F", 
                color: "white",
              }}
              onClick={() => this.savePalette()}
            >
              Spara
            </Button>              
          </Modal.Actions>    
        </Modal>

        <Modal 
          open={openOpenModal}
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            Hämta en jämförelse
          </Modal.Header>

          <Modal.Content
            style={{
              height: "50vh",
              display: "flex",
            }}          
          >
            <Menu 
              vertical 
              tabular
              style={{
                width: "25%",
                height: "100%",
              }}
            >
              {COMPARE_PALETTES && Object.keys(COMPARE_PALETTES).map((x) => (
                <Menu.Item
                  name={COMPARE_PALETTES[x].id}
                  key={'menuItem_' + COMPARE_PALETTES[x].id}
                  active={selectedMenuItem === COMPARE_PALETTES[x].id}
                  onClick={() => this.handleItemClick(COMPARE_PALETTES[x].id)}
                >
                  {COMPARE_PALETTES[x].text}
                </Menu.Item>
              ))}                     
            </Menu>

            <div
              style={{
                width: "75%",
                height: "100%",
                overflowY: "auto",
                marginLeft: 10,
                paddingRight: 10,
              }}           
            >
              {selectedMenuItem && selectedMenuItem !== "custom" && this.getPaletteAlternatives()}
              {selectedMenuItem && selectedMenuItem === "custom" && this.getCustomPaletteAlternatives()}
            </div>
          </Modal.Content>

          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}                    
          >
            <div></div>
            <Button 
              style={{ 
                backgroundColor: "#6B9D9F", 
                color: "white",
              }}
              onClick={() => this.onClose()}
            >
              Stäng
            </Button>
          </Modal.Actions>     
        </Modal>
      </React.Fragment>
    );
  }
};

export default FieldDataComparePalettes;