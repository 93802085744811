import React, { Component } from "react";

const INITIAL_STATE = {};

const AGRIOPT_COLORS = {
  'green': "#89A275",
  'red': "#D6946B",
  'yellow': "rgba(234, 204, 116, 0.25)",
};

class WidgetMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render () {
    const {
      compact,
      menuItems,
      backgroundColor,
    } = this.props;

    let noItems = menuItems && menuItems.length;
    let widthPerItem = 100.0 / noItems; 

    return (
      <div 
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: compact ? null : "0.75em",
          backgroundColor: backgroundColor ? backgroundColor : null,
        }}
      >
        {menuItems && menuItems.map((x) => (
          <span
            key={"WidgetMenu_" + x.id}
            style={{ 
              backgroundColor: x.background ? AGRIOPT_COLORS[x.background] : null,
              fontWeight: "normal",
              color: x.active ? null : (x.color ? x.color : "grey"),
              padding: 7,
              borderBottom: x.active ? "2px solid #e0ba8c" : "2px solid transparent",
              cursor: ((typeof(x.disabled) === "boolean" && x.disabled === true) || !x.onClick) ? null : "pointer",
              width: noItems > 1 ? widthPerItem.toFixed(0) + "%" : null,
              textAlign: "center",
            }}
              onClick={() => (typeof(x.onClick) !== "function" || (typeof(x.disabled) === "boolean" && x.disabled === true)) ? {} : x.onClick(x.id)}
            >
              {x.caption}
          </span>
        ))}
      </div>
    )
  };
};

export default WidgetMenu;
