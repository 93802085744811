import React, { Component } from "react";

import { 
  Header, 
  Segment, 
  Label,
  Icon, 
  Table
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import { fetchData } from "@/helpers/dataFetcher";

import { USDA_SOIL_TEXTURE } from "@/constants/cropCodes";
import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";

const UPLOADED_SOILMAP_KEYS = [
  'ph',
  'k_al',
  'ca_al',
  'mg_al',
  'p_hcl',
  'k_hcl',
  'ca_hcl',
  'cu_hcl',
  'mg_hcl',
  'clay',
  'sand',
  'silt',
  'organic',
];

const YIELDMAP_STATISTICS_KEYS = [
  "yield_median",
  "yield_max",
  "yield_good_year",
  "yield_bad_year",
];

class MapStatsSelectedPoint extends Component {
  state = {
    dataMoistureMapSweden: {},
    dataSoilMapUploaded: {},
    dataSoilMapSweden: {},
    dataYieldMapAnalysis: {},
    dataYieldMapStatistics: {},
  };

  componentDidMount = () => {    
    this.getDataForView();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.fieldId !== prevProps.fieldId ||
      this.props.marker !== prevProps.marker
    ) {
      this.getDataForView();
    }
  };

  getDataForView = () => {
    const { 
      db,
      fieldId,
      fileBucketRef,
      cacheFieldData,
    } = this.props;

    fetchData({
      db,
      fileBucketRef,
      fieldId,
      layer: "soilMapUploaded",
      layerId: "latest",
      cacheFieldData,
      setParentState: (x) => this.setState({ dataSoilMapUploaded: {...this.state.dataSoilMapUploaded, ...x}}),
      downloadNewData: true,
    });

    fetchData({
      db,
      fileBucketRef,
      fieldId,
      layer: "moistureMapSweden",
      layerId: "latest",
      cacheFieldData,
      setParentState: (x) => this.setState({ dataMoistureMapSweden: {...this.state.dataMoistureMapSweden, ...x}}),
      downloadNewData: true,
    });

    fetchData({
      db,
      fileBucketRef,
      fieldId,
      layer: "soilMapSweden",
      layerId: "latest",
      cacheFieldData,
      setParentState: (x) => this.setState({ dataSoilMapSweden: {...this.state.dataSoilMapSweden, ...x}}),
      downloadNewData: true,
    });

    fetchData({
      db,
      fileBucketRef,
      fieldId,
      layer: "yieldStatistics",
      layerId: "all",
      cacheFieldData,
      setParentState: (x) => this.setState({ dataYieldMapStatistics: {...this.state.dataYieldMapStatistics, ...x}}),
      downloadNewData: true,
    });
    
    fetchData({
      db,
      fileBucketRef,
      fieldId,
      layer: "yieldAnalysis",
      layerId: "all",
      cacheFieldData,
      setParentState: (x) => this.setState({ dataYieldMapAnalysis: {...this.state.dataYieldMapAnalysis, ...x}}),
      downloadNewData: true,
    });

  };

  sortGeoJsonData = (data) => {
    let sortedGeoJsonData = data && data.geoJsonData && data.geoJsonData.features && [...data.geoJsonData.features].map((x) => x);

    sortedGeoJsonData = sortedGeoJsonData && sortedGeoJsonData.sort(function (a, b) {   
      return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
    });

    sortedGeoJsonData = sortedGeoJsonData && sortedGeoJsonData.map((x, idx) => ({...x, idx: idx}));

    return sortedGeoJsonData;
  }

  render() {
    const { 
      marker,
      onDeselect, 
    } = this.props;
    
    const { 
      dataMoistureMapSweden,
      dataSoilMapSweden,
      dataSoilMapUploaded,
      dataYieldMapAnalysis,
      dataYieldMapStatistics,
    } = this.state;

    // Swedish soilmap
    let pointMoistureMapSweden = marker && dataMoistureMapSweden && this.sortGeoJsonData(dataMoistureMapSweden);
    pointMoistureMapSweden = pointMoistureMapSweden && pointMoistureMapSweden.find((x) => marker.includes(x.idx));
    pointMoistureMapSweden = pointMoistureMapSweden && pointMoistureMapSweden.properties;

    // Swedish soilmap
    let pointSoilMapSweden = marker && dataSoilMapSweden && this.sortGeoJsonData(dataSoilMapSweden);
    pointSoilMapSweden = pointSoilMapSweden && pointSoilMapSweden.find((x) => marker.includes(x.idx));
    pointSoilMapSweden = pointSoilMapSweden && pointSoilMapSweden.properties;

    // Uploaded soilmap
    let pointSoilMapUploaded = marker && dataSoilMapUploaded && this.sortGeoJsonData(dataSoilMapUploaded);
    pointSoilMapUploaded = pointSoilMapUploaded && pointSoilMapUploaded.find((x) => marker.includes(x.idx));
    pointSoilMapUploaded = pointSoilMapUploaded && pointSoilMapUploaded.properties;

    let layerSoilMapUploaded = getCurrentDataLayer("soilMapUploaded");

    // Yield map statistics
    let pointYieldMapStatistics = marker && dataYieldMapStatistics && this.sortGeoJsonData(dataYieldMapStatistics);
    pointYieldMapStatistics = pointYieldMapStatistics && pointYieldMapStatistics.find((x) => marker.includes(x.idx));
    pointYieldMapStatistics = pointYieldMapStatistics && pointYieldMapStatistics.properties;

    let layerYieldMapStatistics = getCurrentDataLayer("yieldStatistics");

    // Yield maps
    let pointYieldMaps = marker && dataYieldMapAnalysis && this.sortGeoJsonData(dataYieldMapAnalysis);
    pointYieldMaps = pointYieldMaps && pointYieldMaps.find((x) => marker.includes(x.idx));
    pointYieldMaps = pointYieldMaps && pointYieldMaps.properties;
    
    let pointYieldMapsDryWeight = {}
    let pointYieldMapsWaterContent = {}

    pointYieldMaps && Object.keys(pointYieldMaps).forEach((key) => {
      pointYieldMapsDryWeight = key.includes("dry_yield") ? {...pointYieldMapsDryWeight, [key.replace("dry_yield_", "")]: pointYieldMaps[key]} : pointYieldMapsDryWeight;
      pointYieldMapsWaterContent = key.includes("water_content") ? {...pointYieldMapsDryWeight, [key.replace("water_content_", "")]: pointYieldMaps[key]} : pointYieldMapsWaterContent;
    });

    const harvestPlotData = {
      labels: pointYieldMapsDryWeight && Object.keys(pointYieldMapsDryWeight),
      datasets: pointYieldMapsDryWeight && [{
        data: pointYieldMapsDryWeight && Object.keys(pointYieldMapsDryWeight).map((x) => pointYieldMapsDryWeight[x]),
        fill: true,
        borderColor: "#BF8136",
        backgroundColor: "rgba(191, 129, 54, 0.5)",
      }],
    };

    return (
      <div>
          <div
            style={{
              marginTop: 0,
              marginBottom: 0,
              padding: 0,
              display: "flex",
              justifyContent: "space-between",            
            }}
          >
            <Header 
              as="h4"
              style={{
                marginTop: 0,
                marginBottom: 0,
                color: 'white',
                fontWeight: 500,
              }}        
            >
              Data för markerad punkt
            </Header>

            <Icon 
              style={{
                right: 0,
              }}
              className="iconButton"
              name='times'
              onClick={() => onDeselect()}
            />       
        </div> 
        
        {marker.tooltip &&
          <div
            style={{
              marginTop: 0,
              marginBottom: "0.5em",
              padding: 0,
            }}
          >
            <p style={{ color: "white", whiteSpace: "pre-line", }}>{marker.tooltip}</p>
          </div>
        }

        {pointYieldMaps &&
          <Segment
            style={{
              marginTop: "1em",
              marginBottom: 0,
              padding: 0,              
              paddingBottom: 2,
              border: "1px solid darkgrey",
              backgroundColor: "transparent",
            }}
          >
            <Label 
              color="grey" 
              attached='top'
              style={{ fontWeight: 500 }}
            >
              Skörd (ton/ha)
            </Label>

            <article
              className="canvas-container"
              style={{
                width: "100%",
                height: "20vh",
                background: "transparent",
                padding: "1em",
                paddingBottom: "0.5em",
              }}
            >
              <Line 
                data={harvestPlotData} 
                options={{ 
                  maintainAspectRatio: false,
                  scaleFontColor: "#FFFFFF",
                  legend: {
                    display: false,
                  },
                  scales: {
                    yAxes: [{
                      ticks: { fontColor: "white" },
                      gridLines: { color: "#868D8E" },
                    }],
                    xAxes: [{
                      ticks: { fontColor: "white" },
                      gridLines: { color: "#868D8E" },
                    }],
                  }
                }} 
              />
            </article>
          </Segment>
        }

        {pointYieldMapStatistics &&
          <Segment
            style={{
              marginTop: "2em",
              marginBottom: "1em",
              paddingTop: "1em",              
              paddingBottom: "0.5em",
              border: "1px solid darkgrey",
              backgroundColor: "transparent",
            }}
          >
            <Label 
              color="grey" 
              attached='top'
              style={{ fontWeight: 500 }}
            >
              Statistik från skördekartor (alla grödor)
            </Label>

            <Table columns="2" striped inverted>
              <Table.Body>
                {YIELDMAP_STATISTICS_KEYS.filter((x) => Object.keys(pointYieldMapStatistics).includes((x))).map((key) => {
                  let layer = layerYieldMapStatistics && layerYieldMapStatistics.geoJsonFieldKey;
                  layer = layer && Object.keys(layer).find((x) => layer[x] === key);
                  
                  let label = layerYieldMapStatistics && layerYieldMapStatistics.captions && layerYieldMapStatistics.captions[layer];
                  let dataHistogramLabels = layerYieldMapStatistics && layerYieldMapStatistics.histogramLabels && layerYieldMapStatistics.histogramLabels[layer];

                  let noDecimals = dataHistogramLabels && typeof(dataHistogramLabels.noDecimals) === "number" ? dataHistogramLabels.noDecimals : 1;
                  let prefix = dataHistogramLabels && dataHistogramLabels.prefix ? dataHistogramLabels.prefix : "";
                  let postfix = dataHistogramLabels && dataHistogramLabels.postfix ? dataHistogramLabels.postfix : "";

                  return (
                    <Table.Row>
                      <Table.Cell>{label && label}</Table.Cell>
                      <Table.Cell>{prefix + pointYieldMapStatistics[key] && pointYieldMapStatistics[key].toFixed(noDecimals) + postfix}</Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Segment>
        }

        {pointSoilMapUploaded &&
          <Segment
            style={{
              marginTop: "2em",
              marginBottom: "1em",
              paddingTop: "1em",              
              paddingBottom: "0.5em",
              border: "1px solid darkgrey",
              backgroundColor: "transparent",
            }}
          >
            <Label 
              color="grey" 
              attached='top'
              style={{ fontWeight: 500 }}
            >
              Egen markkartering (senaste)
            </Label>

            <Table columns="2" striped inverted>
              <Table.Body>
                {UPLOADED_SOILMAP_KEYS.filter((x) => Object.keys(pointSoilMapUploaded).includes((x))).map((key) => {
                  let layer = layerSoilMapUploaded && layerSoilMapUploaded.geoJsonFieldKey;
                  layer = layer && Object.keys(layer).find((x) => layer[x] === key);
                  
                  let label = layerSoilMapUploaded && layerSoilMapUploaded.captions && layerSoilMapUploaded.captions[layer];
                  let dataHistogramLabels = layerSoilMapUploaded && layerSoilMapUploaded.histogramLabels && layerSoilMapUploaded.histogramLabels[layer];

                  let noDecimals = dataHistogramLabels && typeof(dataHistogramLabels.noDecimals) === "number" ? dataHistogramLabels.noDecimals : 1;
                  let prefix = dataHistogramLabels && dataHistogramLabels.prefix ? dataHistogramLabels.prefix : "";
                  let postfix = dataHistogramLabels && dataHistogramLabels.postfix ? dataHistogramLabels.postfix : "";

                  return (
                    <Table.Row>
                      <Table.Cell>{label && label}</Table.Cell>
                      <Table.Cell>{prefix + pointSoilMapUploaded[key] && pointSoilMapUploaded[key].toFixed(noDecimals) + postfix}</Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Segment>
        }

        {(pointMoistureMapSweden || pointSoilMapSweden) &&
          <Segment
            style={{
              marginTop: "2em",
              marginBottom: "1em",
              paddingTop: "1em",              
              paddingBottom: "0.5em",
              border: "1px solid darkgrey",
              backgroundColor: "transparent",
            }}
            >
            <Label 
              color="grey" 
              attached='top'
              style={{ fontWeight: 500 }}
            >
              Digitala åkermarkskartan och Markfuktskarta
            </Label>

            <Table columns="2" striped inverted>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Markfukt (0-100)</Table.Cell>
                  <Table.Cell>{pointMoistureMapSweden && pointMoistureMapSweden['moisture_class'] && (100.0 * pointMoistureMapSweden['moisture_class']).toFixed(0)}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Lerhalt</Table.Cell>
                  <Table.Cell>{pointSoilMapSweden && pointSoilMapSweden.clay && pointSoilMapSweden.clay.toFixed(0)}%</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Sandhalt</Table.Cell>
                  <Table.Cell>{pointSoilMapSweden && pointSoilMapSweden.sand && pointSoilMapSweden.sand.toFixed(0)}%</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Silthalt</Table.Cell>
                  <Table.Cell>{pointSoilMapSweden && pointSoilMapSweden.silt && pointSoilMapSweden.silt.toFixed(0)}%</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Texturklass (USDA)</Table.Cell>
                  <Table.Cell>{pointSoilMapSweden && pointSoilMapSweden.texture && USDA_SOIL_TEXTURE[pointSoilMapSweden.texture.toFixed(0)] && " " + USDA_SOIL_TEXTURE[pointSoilMapSweden.texture.toFixed(0)].text}
              </Table.Cell>
                </Table.Row>                                                                
              </Table.Body>
            </Table>
            </Segment>        
          }
      </div>
    );
  }
}

export default MapStatsSelectedPoint;

