const SOUTH_SWEDEN = { key: 'south', value: 'south', text: 'Södra Götaland' };
const MIDDLE_SWEDEN = { key: 'middle', value: 'middle', text: 'Norra Götaland och Svealand' };
const NORTH_SWEDEN = { key: 'north', value: 'north', text: 'Norrland' };

export const SPRING_CROPS = ['2', '3', '5', '8'];

export const N_GUIDE_CROPS = [
    { 
        key: '1', 
        value: '1', 
        text: '1: Korn (höst)', 
        qualities: [
            { key: 'malt', value: 'malt', text: 'Malt' },
            { key: 'feed', value: 'feed', text: 'Foder' },
        ],
        regions: [SOUTH_SWEDEN, MIDDLE_SWEDEN, NORTH_SWEDEN],
    },
    {
        key: '2', 
        value: '2',
        text: '2: Korn (vår)',
        qualities: [
            { key: 'malt', value: 'malt', text: 'Malt' },
            { key: 'feed', value: 'feed', text: 'Foder' },
        ],
        regions: [SOUTH_SWEDEN, MIDDLE_SWEDEN, NORTH_SWEDEN],
    },
    { 
        key: '3', 
        value: '3', 
        text: '3: Havre', 
        regions: [SOUTH_SWEDEN, MIDDLE_SWEDEN],
    },
    { 
        key: '4', 
        value: '4', 
        text: '4: Vete (höst)', 
        qualities: [
            { key: 'bread', value: 'bread', text: 'Bröd' },
            { key: 'feed', value: 'feed', text: 'Foder' },
        ],
        regions: [SOUTH_SWEDEN, MIDDLE_SWEDEN],
    },
    { 
        key: '5', 
        value: '5', 
        text: '5: Vete (vår)',
    },
    { 
        key: '7', 
        value: '7', 
        text: '7: Rågvete (höst)', 
        regions: [SOUTH_SWEDEN, MIDDLE_SWEDEN],
    },
    { 
        key: '8', 
        value: '8', 
        text: '8: Råg', 
        regions: [SOUTH_SWEDEN, MIDDLE_SWEDEN],
    },
];

export const AIM_RATION = [
    {
        crop: '4',
        region: SOUTH_SWEDEN,
        quality: 'bread',
        ration: {
            '5': '125',
            '6': '145',
            '7': '165',
            '8': '185',
            '9': '205',
            '10': '225',
            '11': '245',
        }
    },
    {
        crop: '4',
        region: SOUTH_SWEDEN,
        quality: 'feed',
        ration: {
            '5': '115',
            '6': '135',
            '7': '150',
            '8': '165',
            '9': '180',
            '10': '195',
            '11': '210',
        }
    },
    {
        crop: '4',
        region: MIDDLE_SWEDEN,
        quality: 'bread',
        ration: {
            '4': '100',
            '5': '120',
            '6': '140',
            '7': '160',
            '8': '180',
            '9': '200',
            '10': '220',
            '11': '240',
        }
    },
    {
        crop: '4',
        region: MIDDLE_SWEDEN,
        quality: 'feed',
        ration: {
            '4': '100',
            '5': '120',
            '6': '140',
            '7': '155',
            '8': '170',
            '9': '185',
            '10': '200',
            '11': '215',
        }
    },
    {
        crop: '7',
        region: SOUTH_SWEDEN,
        ration: {
            '5': '120',
            '6': '130',
            '7': '145',
            '8': '160',
            '9': '175',
        }
    },
    {
        crop: '7',
        region: MIDDLE_SWEDEN,
        ration: {
            '4': '95',
            '5': '115',
            '6': '135',
            '7': '150',
            '8': '165',
            '9': '180',
        }
    },        
    {
        crop: '1',
        region: SOUTH_SWEDEN,
        ration: {
            '5': '120',
            '6': '130',
            '7': '145',
            '8': '160',
            '9': '175',
        }
    },
    {
        crop: '1',
        region: MIDDLE_SWEDEN,
        ration: {
            '4': '95',
            '5': '115',
            '6': '135',
            '7': '150',
            '8': '165',
            '9': '180',
        }
    },
    {
        crop: '8',
        region: SOUTH_SWEDEN,
        ration: {
            '5': '95',
            '6': '115',
            '7': '130',
            '8': '135',
            '9': '140',
        }
    },
    {
        crop: '8',
        region: MIDDLE_SWEDEN,
        ration: {
            '4': '80',
            '5': '100',
            '6': '120',
            '7': '135',
            '8': '140',
            '9': '145',
        }
    },
    {
        crop: '5',
        ration: {
            '4': '125',
            '5': '145',
            '6': '165',
            '7': '185',
            '8': '205',
        }
    },   
    {
        crop: '2',
        region: SOUTH_SWEDEN,
        quality: 'feed',
        ration: {
            '4': '60',
            '5': '80',
            '6': '95',
            '7': '110',
            '8': '125',
            '9': '140',
        }
    },
    {
        crop: '2',
        region: SOUTH_SWEDEN,
        quality: 'malt',
        ration: {
            '4': '65',
            '5': '85',
            '6': '110',
            '7': '115',
            '8': '130',
            '9': '145',
        }
    },
    {
        crop: '2',
        region: MIDDLE_SWEDEN,
        quality: 'feed',
        ration: {
            '3': '50',
            '4': '70',
            '5': '90',
            '6': '105',
            '7': '120',
            '8': '135',
        }
    },
    {
        crop: '2',
        region: MIDDLE_SWEDEN,
        quality: 'malt',
        ration: {
            '4': '70',
            '5': '90',
            '6': '105',
            '7': '120',
            '8': '135',
        }
    },
    {
        crop: '2',
        region: NORTH_SWEDEN,
        quality: 'feed',
        ration: {
            '3': '45',
            '4': '65',
            '5': '85',
            '6': '100',
        }
    },
    {
        crop: '2',
        region: NORTH_SWEDEN,
        quality: 'malt',
        ration: {
            '3': '45',
            '4': '65',
            '5': '85',
            '6': '100',
        }
    },
    {
        crop: '3',
        region: SOUTH_SWEDEN,
        ration: {
            '4': '55',
            '5': '75',
            '6': '90',
            '7': '105',
        }
    },
    {
        crop: '3',
        region: MIDDLE_SWEDEN,
        ration: {
            '3': '45',
            '4': '54',
            '5': '85',
            '6': '100',
            '7': '115',
        }
    },      
];

export const PREVIOUS_CROP_YIELD_INCREASE = {
    '3': {
        '4': 0.7,
    },
    '20': {
        '4': 1.2,
    },
    '21': {
        '2': 0.5,
        '3': 0.5,
        '4': 0.8,
        '5': 0.5
    },
    '30': {
        '2': 0.5,
        '3': 0.5,
        '4': 1.0,
        '5': 0.5
    },
    '32': {
        '2': 0.7,
        '3': 0.7,
        '4': 0.7,
        '5': 0.7
    },
    '58': {
        '2': 0.5,
        '3': 0.5,
        '4': 0.8,
        '5': 0.5
    }, 
    '59': {
        '2': 0.5,
        '3': 0.5,
        '4': 0.8,
        '5': 0.5
    },
    '62': {
        '2': 0.5,
        '3': 0.5,
        '4': 0.8,
        '5': 0.5
    },     
    '49': {
        '2': 0.2,
        '3': 0.2,
        '4': 0.4,
        '5': 0.2
    }, 
    '50': {
        '2': 0.2,
        '3': 0.2,
        '4': 0.4,
        '5': 0.2
    },
    '57': {
        '2': 0.2,
        '3': 0.2,
        '4': 0.4,
        '5': 0.2
    },            
};

export const PREVIOUS_CROP_NITROGEN_SUPPLY = {
    '20': {
        '4': 40,
    },
    '21': {
        '2': 20,
        '3': 20,
        '4': 20,
        '5': 20
    },
    '30': {
        '2': 25,
        '3': 25,
        '4': 35,
        '5': 25,
    },
    '32': {
        '2': 25,
        '3': 25,
        '4': 25,
        '5': 25
    },
    '58': {
        '2': 40,
        '3': 40,
        '4': 40,
        '5': 40
    }, 
    '59': {
        '2': 40,
        '3': 40,
        '4': 40,
        '5': 40
    },
    '62': {
        '2': 40,
        '3': 40,
        '4': 40,
        '5': 40
    },  
    '49': {
        '2': 15,
        '3': 15,
        '4': 15,
        '5': 15,
    }, 
    '50': {
        '2': 15,
        '3': 15,
        '4': 15,
        '5': 15,
    },
    '57': {
        '2': 15,
        '3': 15,
        '4': 15,
        '5': 15,
    },                        
};

export const NO_ADDITIONAL_RATIONS = [
  {
    key: '0',
    value: '0',
    text: "Endast grundgiva",
    norations: '0',
    ration: {
        '0': 100,
        '1': 0,
        '2': 0,
        '3': 0,
    }
  },  
  {
    key: '1',
    value: '1',
    text: "Grundgiva och en kompletteringsgiva",
    norations: '1',
    ration: {
        '0': 50,
        '1': 50,
        '2': 0,
        '3': 0,
    }
  },
  {
    key: '2',
    value: '2',
    text: "Grundgiva och två kompletteringsgivor",
    norations: '2',
    ration: {
        '0': 50,
        '1': 25,
        '2': 25,
        '3': 0,
    }
  },
  {
    key: '3',
    value: '3',
    text: "Grundgiva och tre kompletteringsgivor",
    norations: '3',
    ration: {
        '0': 50,
        '1': 30,
        '2': 20,
        '3': 10,
    }
  },
  {
    key: 'A',
    value: 'A',
    text: "Strategi A: grundgiva (70%) och en kompletteringsgiva (30%)",
    norations: '1',
    ration: {
        '0': 70,
        '1': 30,
        '2': 0,
        '3': 0,
    }
  },
  {
    key: 'B',
    value: 'B',
    text: "Strategi B: grundgiva (60%) och två kompletteringsgivor (20% och 20%)",
    norations: '2',
    ration: {
        '0': 60,
        '1': 20,
        '2': 20,
        '3': 0,
    }    
  },
  {
    key: 'C',
    value: 'C',
    text: "Strategi C: grundgiva (25%) och två kompletteringsgivor (50% och 25%)",
    norations: '2',
    ration: {
        '0': 25,
        '1': 50,
        '2': 25,
        '3': 0,
    }
  },
  {
    key: 'D',
    value: 'D',
    text: "Strategi D: grundgiva (25%) och en kompletteringsgiva (75%)",
    norations: '1',
    ration: {
        '0': 25,
        '1': 75,
        '2': 0,
        '3': 0,
    }
  },
  {
    key: 'E',
    value: 'E',
    text: "Strategi E: grundgiva (100%)",
    norations: '0',
    ration: {
        '0': 100,
        '1': 0,
        '2': 0,
        '3': 0,
    }
  },
];