import React, { Component } from "react";

import { 
  Form,
} from "semantic-ui-react";

import {
  Map as LeafletMap,
  Marker as LeafletMarker,
  TileLayer,
} from 'react-leaflet';

import L from 'leaflet';

import GuidesCollectDataDropzone from "@/components/guideData/GuidesCollectDataDropzone";
import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

import { orgNumberIsValid } from '@/helpers/validators';
import { PRODUCTION_AREAS } from '@/constants/regions/sweden';

import HouseIcon from '@/assets/icons/map/icon_house.png';
import MeIcon from '@/assets/icons/map/mePos.png';

const iconHouse = new L.Icon({
    iconUrl: HouseIcon,
    iconRetinaUrl: HouseIcon,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 50),
});

const iconMe = new L.Icon({
    iconUrl: MeIcon,
    iconRetinaUrl: MeIcon,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 50),
});

const INITIAL_STATE = {
  hasLocation: false,
  latlng: {
      lat: 58.411,
      lng: 15.621,
  },
  errorMessage: '',
  checkFarmInformation: false,
};

class RegistrationV1FarmInformation extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };
  
  mapRef = React.createRef();

  componentDidMount() {    
    this.mapRef.current && this.mapRef.current.leafletElement.locate({
      watch: true, //  - set to true if should track user live
    });
  };

  handleLocationFound = (e) => {
    this.setState({
      hasLocation: true,
      latlng: e.latlng,
    })
  };

  clickMap = ({ latlng }) => {
    this.props.setParentState({
      farm: {
        ...this.props.farm, 
        location: new this.props.firestore.GeoPoint(latlng.lat, latlng.lng) 
      },
    });
  };

  onClickNextStep = () => {
    const {
      completedStepsFarm,
    } = this.props;

    this.props.setParentState({
      openSegmentFarm: '',
      completedStepsFarm: [...completedStepsFarm, "placeFarm"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment) => {};

  onChange = (data, onlyNumbers=true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  }; 

  onChangeStruct = (e, data, variableName, onlyNumbers=true) => {
    let currentValue = this.props[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: {...currentValue, [data.name]: value },
    });
  }; 
  
  setOpenSegment = (newSegment) => {
    const {
      openSegmentFarm
    } = this.props;

    (openSegmentFarm === newSegment) && this.props.setParentState({ openSegmentFarm: null });
    (openSegmentFarm !== newSegment) && this.props.setParentState({ openSegmentFarm: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedStepsFarm, 
    } = this.props;

    !completedStepsFarm.includes(currentSegment) && this.props.setParentState({
      completedStepsFarm: [...completedStepsFarm, currentSegment],
    });

    this.setDefaultSettings(newSegment);

    !completedStepsFarm.includes(newSegment) && this.setOpenSegment(newSegment);
    completedStepsFarm.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  onUploadFiles = (pickedFiles, dataType) => {
    this.props.onUploadFiles(pickedFiles, dataType);
  };

  render() {

    const {
      openSegmentFarm,
      completedStepsFarm,
      farm: {
        name,
        region,
        location,
      },
      invoicing: {
        companyId,
      }
    } = this.props;

    const {  
      hasLocation,
      latlng,
      checkFarmInformation,
    } = this.state;    
    
    let menuItems = {
      next: [
        {
          caption: "Gå vidare",
          onClick: this.onClickNextStep,
          disabled: completedStepsFarm.length < 3,
        }
      ],
    };

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegment
          caption="Ladda upp SAM-ansökan"
          simpleModeQuestion="Vill du ladda upp en SAM-ansökan?"
          simpleModeDescription="Freja kan automatiskt hitta mycket av den informationen som behövs i din SAM-ansökan. Den används även senare för att lägga till skiften."
          simpleModeOpenText="Ladda upp ansökan"
          open={openSegmentFarm === "samApplication"}
          complete={completedStepsFarm && completedStepsFarm.includes("samApplication")}
          onlyClickOpenSegmentIfComplete={true}
          onClickOpenSegment={() => this.setOpenSegment("samApplication")}
          onClickNextStep={() => this.gotoNextStep("samApplication", "farmInformation")}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
              marginBottom: "2em",
            }}
          >
            Din SAM-ansökan kan du ladda ned från <a href="https://jordbruksverket.se/e-tjanster-databaser-och-appar/e-tjanster-och-databaser-stod/sam-internet" target="_blank" rel="noopener noreferrer">SAM Internet</a>. Välj att exportera en XML-fil genom att logga in och sedan klicka på knappen Import/Export uppe fill höger. Använd gärna din senaste SAM-ansökan om du exempelvis har utökat antal skiften på gården. Annars spelar det ingen roll vilket år du använder.
          </p>
          
          <GuidesCollectDataDropzone 
            inSegment={false}
            activeView="uploadSamApplication"
            onDroppedFiles={this.onUploadFiles}
            collectDataError={this.props.collectDataError}
          />
        </GuideSegment>

        <GuideSegment
          caption="Gårdsinformation"
          open={openSegmentFarm === "farmInformation"}
          complete={completedStepsFarm && completedStepsFarm.includes("farmInformation")}
          onClickOpenSegment={() => this.setOpenSegment("farmInformation")}
          onlyClickOpenSegmentIfComplete={true}
          onClickNextStep={() => {
            if (name === '' || !orgNumberIsValid(companyId) || !region) {
              this.setState({ checkFarmInformation: true });
            } else {
              this.gotoNextStep("farmInformation", "placeFarm");
              this.setState({ checkFarmInformation: false });
            };
          }}
        >
          <Form
            style={{
              marginTop: "2em",
              width: "80%",
              marginLeft: "10%"
            }}
          >
            <Form.Input
              autoFocus
              focus
              fluid
              style={{ marginBottom: "2em" }}
              placeholder='Ange ett namn på gården'
              label='Gårdens namn'            
              name='name'
              value={name}
              error={checkFarmInformation && name === '' && {content: "Ange ett namn på gården", pointing: "below"}}
              onChange={(e, d) => this.onChangeStruct(e, d, 'farm', false)}
            />

            <Form.Input
              focus
              fluid
              style={{ marginBottom: "2em" }}
              label='Företagets organisationsnummer'
              placeholder='Ange ett organisationsnummer (NNNNNN-NNNN)'
              name='companyId'
              value={companyId}
              error={checkFarmInformation && !orgNumberIsValid(companyId) && {content: "Ange ett korrekt organisations-/personnummer på formen NNNNNN-NNNN", pointing: "below"}}
              onChange={(e, d) => this.onChangeStruct(e, d, 'invoicing', false)}
            />

            <Form.Select
              fluid
              search
              label="Produktionsområde"
              placeholder='Välj ett av Jordbruksverkets produktionsområden'
              name='region'
              value={region}
              error={checkFarmInformation && !region && {content: "Välj ett produktionsområde", pointing: "below"}}
              options={PRODUCTION_AREAS}
              onChange={(e, d) => this.onChangeStruct(e, d, 'farm', false)}
            />                
          </Form>
        </GuideSegment>    

        <GuideSegment
          caption="Gårdens position"
          open={openSegmentFarm === "placeFarm"}
          complete={completedStepsFarm && completedStepsFarm.includes("placeFarm")}
          onlyClickOpenSegmentIfComplete={true}
          disableNextStep={!location}
          onClickOpenSegment={() => this.setOpenSegment("placeFarm")}
          onClickNextStep={() => this.onClickNextStep()}
        >
          <p
            style={{
              fontWeight: "normal",
              whiteSpace: "pre-line",
              marginBottom: "2em"
            }}
          >
            Navigera och klicka på kartan för att placera din gård. Du kan dra och zooma i denna kartan för att lokalisera vart din gård är belägen. Positionen använder Freja för att exempelvis hämta väderdata från SMHI.
          </p>
          
          <LeafletMap
            center={latlng}
            zoom={14}
            minZoom={8}
            maxZoom={16}
            style={{
                height: "50vh",
                width: '100%',
            }}
            ref={this.mapRef}
            onClick={this.clickMap}
            onLocationFound={this.handleLocationFound}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copybelow">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {location && <LeafletMarker position={L.latLng(location.latitude, location.longitude)} icon={iconHouse} />}
            {hasLocation && latlng && <LeafletMarker position={latlng} icon={iconMe} />}                    
          </LeafletMap> 
        </GuideSegment>                   
      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader=""
        mainColumnBody=""    
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default RegistrationV1FarmInformation;