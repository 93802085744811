import React, { Component } from "react"

import {
  Header,
  Segment,
  Dropdown,
  Button,
  Input,
  Modal,
  Table,
} from "semantic-ui-react";

import { toast } from "react-toastify";
import { DateInput } from 'semantic-ui-calendar-react';

import { CROP_CODES, CROP_QUALITY } from "@/constants/cropCodes";
import { NUTRIENT_FERTILIZERS } from "@/constants/fertilizers";
import { NUTRIENTS_TO_SKIP, NUTRIENTS_SHOW_IN_SUMMARY } from "@/constants/fertilizers";
import { SEASON_FIRST_DATE } from "@/constants/settings";

import moment from 'moment';

class FieldManagementNutrition extends Component {
  state = {
    mode: null,
    editYear: null,
    editFertilizationProduct: null,
    editFertilizationAmount: null,
    editFertilizationDate: null,
    editFertilizationDateOld: null,
    editFertilizationNutrients: null,
    dateToRemove: null,
  };

  updateFertilizationInformation = (year, payload) => {
    const {
      db,
      fieldId,
      getUser,
    } = this.props;

    let dbRef = getUser().collection("fieldsInfo").doc(fieldId);
    let field = db && db.fields[fieldId] && db.fields[fieldId];
    let fieldInfo = db && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];

    let currentKeysInFieldInfo = fieldInfo && Object.keys(fieldInfo).map((x) => x);

    // Iterate over all fields in payload
    Object.keys(payload).filter((key) => payload[key]).forEach((key) => {
      if (currentKeysInFieldInfo && currentKeysInFieldInfo.includes(key)) {
        dbRef.update({ [key + "." + year]: payload[key] });
      } else {
        dbRef.update({ [key]: { [year]: payload[key] } });
      };
    });

    let text = "Freja har uppdaterat informationen om gödslingen för: " + year + " för skiftet: " + (field && field.name && field.name) + ".";

    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });
  };

  onChangeField = (data, onlyNumbers = false, extractNutrients = false) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.setState({
      [data.name]: value,
    });

    if (extractNutrients) {
      let option = data && data.options && data.options.find((x) => x.value === data.value);
      option.nutrients && this.setState({
        editFertilizationNutrients: option.nutrients,
      })
    }
  };

  onResetState = () => {
    this.setState({
      mode: null,
      editYear: null,
      editFertilizationProduct: null,
      editFertilizationAmount: null,
      editFertilizationDate: null,
      editFertilizationDateOld: null,
    });
  }

  onToogleEditNutrition = (year, mode, date) => {
    const {
      db,
      fieldId,
    } = this.props;

    const {
      editYear,
      editFertilizationProduct,
      editFertilizationAmount,
      editFertilizationDate,
      editFertilizationDateOld,
      editFertilizationNutrients,
    } = this.state;

    let fieldInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let fertilizations = fieldInfo && fieldInfo.fertilizations && fieldInfo.fertilizations

    if (!year) {
      fertilizations = fertilizations && fertilizations[editYear];

      let nutrients = {};

      editFertilizationNutrients && editFertilizationAmount && Object.keys(editFertilizationNutrients).forEach((x) => {
        nutrients = {
          ...nutrients,
          [x]: (parseFloat(editFertilizationNutrients[x]) * parseFloat(editFertilizationAmount) / 100.0).toFixed(2),
        }
      });

      this.updateFertilizationInformation(editYear,
        {
          fertilizations: [
            ...fertilizations.filter((x) => x.date !== editFertilizationDateOld),
            {
              date: editFertilizationDate ? editFertilizationDate : editYear + SEASON_FIRST_DATE,
              amount: editFertilizationAmount,
              product: editFertilizationProduct,
              nutrients: nutrients
            },
          ]
        });

      this.onResetState()

    } else {
      fertilizations = fertilizations && fertilizations[year];
      let fertilization = fertilizations && fertilizations.find((x) => x.date === date);

      let productNutrients = fertilization && fertilization.product;
      productNutrients = productNutrients && NUTRIENT_FERTILIZERS.find((x) => x.value === productNutrients);
      productNutrients = productNutrients && productNutrients.nutrients;

      this.setState({
        mode: mode ? mode : "edit",
        editYear: year,
        editFertilizationProduct: fertilization && fertilization.product ? fertilization.product : '',
        editFertilizationAmount: fertilization && fertilization.amount ? fertilization.amount : '',
        editFertilizationDate: fertilization && fertilization.date ? fertilization.date : year + "-05-01",
        editFertilizationDateOld: fertilization && fertilization.date ? fertilization.date : year + "-05-01",
        editFertilizationNutrients: productNutrients ? productNutrients : {},
      });

    };
  };

  onClickAddNutrition = () => {
    const {
      db,
      fieldId,
    } = this.props;

    const {
      editYear,
      editFertilizationProduct,
      editFertilizationAmount,
      editFertilizationDate,
      editFertilizationNutrients,
    } = this.state;

    let fieldInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let fertilizations = fieldInfo && fieldInfo.fertilizations && fieldInfo.fertilizations[editYear];

    let nutrients = {};

    editFertilizationNutrients && editFertilizationAmount && Object.keys(editFertilizationNutrients).forEach((x) => {
      nutrients = {
        ...nutrients,
        [x]: (parseFloat(editFertilizationNutrients[x]) * parseFloat(editFertilizationAmount) / 100.0).toFixed(2),
      }
    });

    if (fertilizations && fertilizations.length > 0) {
      this.updateFertilizationInformation(editYear,
        {
          fertilizations: [
            ...fertilizations.filter((x) => x.date !== editFertilizationDate),
            {
              date: editFertilizationDate ? editFertilizationDate : editYear + SEASON_FIRST_DATE,
              amount: editFertilizationAmount,
              product: editFertilizationProduct,
              nutrients: nutrients,
            },
          ]
        });
    } else {
      this.updateFertilizationInformation(editYear,
        {
          fertilizations: [
            {
              date: editFertilizationDate ? editFertilizationDate : editYear + SEASON_FIRST_DATE,
              amount: editFertilizationAmount,
              product: editFertilizationProduct,
              nutrients: nutrients,
            },
          ]
        });
    }


    this.onResetState()
  };

  removeNutritionRation = () => {
    const {
      db,
      fieldId,
    } = this.props;

    const {
      dateToRemove,
    } = this.state;

    let fieldInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let year = dateToRemove && dateToRemove.split("-")[0];
    let fertilizations = year && fieldInfo && fieldInfo.fertilizations && fieldInfo.fertilizations[year];

    this.updateFertilizationInformation(year,
      {
        fertilizations: [
          ...fertilizations.filter((x) => x.date !== dateToRemove),
        ]
      });

    this.setState({
      dateToRemove: null,
    });
  };

  onToggleRemoveNutrition = (date) => {
    this.setState({
      mode: null,
      dateToRemove: date,
    });
  };

  getNutritionRow = (year, idx, compact = false) => {
    const {
      db,
      fieldId,
    } = this.props;

    let fieldInfo = db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let currentCropCode = fieldInfo && fieldInfo.crop_code && fieldInfo.crop_code[year];
    let currentFertilization = fieldInfo && fieldInfo.fertilizations && fieldInfo.fertilizations[year];
    currentFertilization = currentFertilization && [...currentFertilization].sort((a, b) => a.date && b.date && moment(a.date) - moment(b.date));

    if (!currentFertilization) {
      return this.getEmptyNutritionRow(year, idx, compact)
    };

    let cropText = currentCropCode && CROP_CODES.find(x => x.value === currentCropCode);
    cropText = (cropText && cropText.shorttext) ? cropText.shorttext : "Övrigt";

    let cropQuality = fieldInfo && fieldInfo.crop_quality && fieldInfo.crop_quality[year] ? fieldInfo.crop_quality[year] : '';

    let cropQualityText = currentCropCode && CROP_QUALITY.find(x => x.value === currentCropCode);
    cropQualityText = cropQualityText && cropQualityText.values && cropQualityText.values.find((x) => x.value === cropQuality);
    cropQualityText = cropQualityText && cropQualityText.text;

    let cropVariety = fieldInfo && fieldInfo.crop_variety && fieldInfo.crop_variety[year] ? fieldInfo.crop_variety[year] : '';

    let totalProduct = 0.0;
    let totalAmounts = {};
    NUTRIENTS_SHOW_IN_SUMMARY.forEach((x) => totalAmounts = { ...totalAmounts, [x]: 0.0 });

    currentFertilization && currentFertilization.forEach((x) => {
      if (!x.date || !x.amount) {
        return;
      };

      totalProduct += parseFloat(x.amount);

      NUTRIENTS_SHOW_IN_SUMMARY.forEach((y) => {
        let amount = x.nutrients && x.nutrients[y] && parseFloat(x.nutrients[y]);
        totalAmounts = { ...totalAmounts, [y]: totalAmounts[y] + amount }
      })
    });

    let hasCompleteInformation = currentFertilization && currentFertilization.length > 0;

    if (compact) {
      return currentFertilization && currentFertilization.map((x) => {
        if (!x.date) {
          return <div />;
        };

        let productName = x.product && NUTRIENT_FERTILIZERS.find((y) => y.value === x.product);
        productName = productName ? productName.text : "-";

        let amountN = x.nutrients && x.nutrients['N'] && parseFloat(x.nutrients['N']);
        amountN = amountN ? amountN : 0.0;

        let amountP = x.nutrients && x.nutrients['P'] && parseFloat(x.nutrients['P']);
        amountP = amountP ? amountP : 0.0;

        let amountK = x.nutrients && x.nutrients['K'] && parseFloat(x.nutrients['K']);
        amountK = amountK ? amountK : 0.0;

        return (
          <Segment>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "0.25em",
              }}
            >
              <Header
                as="h4"
                style={{
                  fontWeight: 500,
                  marginBottom: 5,
                }}
              >
                Gödsling
                </Header>
              
              <div>
                <span
                  style={{
                    marginRight: 10,
                  }}
                  className="textLink"
                  onClick={() => this.onToogleEditNutrition(year, "add", null)}
                >
                  Lägg till ny giva
                </span>      

                &bull;

                <span
                  style={{
                    marginLeft: 10, 
                  }}
                  className="textLink"
                  onClick={() => this.onToogleEditNutrition(year, "edit", x.date)}
                >
                  Redigera
                </span>
              </div>
            </div>

            <p style={{ margin: 0 }}>Datum: {x.date && x.date}</p>
            <p style={{ margin: 0 }}>Preparat: {productName && productName}</p>
            <p style={{ margin: 0 }}>Mängd: {x.amount && x.amount} kg/ha</p>
            <p style={{ margin: 0 }}>Näring (NPK): {amountN} / {amountP} / {amountK} kg/ha</p>
          </Segment>
        )
      })
    } else {

      return (
        <Segment
          key={'nutritionInfoRow' + year}
          style={{
            padding: "1.5em",
            width: "70%",
            marginLeft: "15%",
            borderColor: hasCompleteInformation ? "#4C8440" : "#FBBD08",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                marginTop: 15,
                fontSize: "140%",
                width: "10%",
                opacity: 0.7,
              }}
            >
              {year}
            </div>

            <div
              style={{
                width: "85%"
              }}
            >
              <div
                style={{
                  fontSize: "170%",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                {cropText ? cropText : "-"}
              </div>

              <div
                style={{
                  fontSize: "140%",
                  marginTop: 0,
                }}
              >
                {cropVariety ? "Sorten " + cropVariety : "Okänd sort"}{cropQualityText ? " odlas som " + cropQualityText.toLowerCase() + "" : ""}
              </div>
            </div>
            <div
              style={{
                width: "5%"
              }}
            >
              <Button
                basic
                icon="add"
                onClick={() => this.onToogleEditNutrition(year, "add")}
              />
            </div>
          </div>

          {currentFertilization && currentFertilization.length > 0 &&
            <div style={{ display: "flex" }}>
              <div style={{ width: "10%" }} />
              <Table
                celled
                striped
                size='small'
                selectable
                style={{
                  width: "90%"
                }}
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Datum</Table.HeaderCell>
                    <Table.HeaderCell>Preparat</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Mängd</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">N</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">P</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">K</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">S</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {currentFertilization && currentFertilization.map((x) => {
                    if (!x.date) {
                      return <div />;
                    };

                    let productName = x.product && NUTRIENT_FERTILIZERS.find((y) => y.value === x.product);
                    productName = productName ? productName.text : "-";

                    let amountN = x.nutrients && x.nutrients['N'] && parseFloat(x.nutrients['N']);
                    amountN = amountN ? amountN : 0.0;

                    let amountP = x.nutrients && x.nutrients['P'] && parseFloat(x.nutrients['P']);
                    amountP = amountP ? amountP : 0.0;

                    let amountK = x.nutrients && x.nutrients['K'] && parseFloat(x.nutrients['K']);
                    amountK = amountK ? amountK : 0.0;

                    let amountS = x.nutrients && x.nutrients['S'] && parseFloat(x.nutrients['S']);
                    amountS = amountS ? amountS : 0.0;

                    return (
                      <Table.Row
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => this.onToogleEditNutrition(year, "edit", x.date)}
                      >
                        <Table.Cell>{x.date}</Table.Cell>
                        <Table.Cell>{productName}</Table.Cell>
                        <Table.Cell textAlign="center">{x.amount && x.amount}</Table.Cell>
                        <Table.Cell textAlign="center">{amountN && amountN.toFixed(2)}</Table.Cell>
                        <Table.Cell textAlign="center">{amountP && amountP.toFixed(2)}</Table.Cell>
                        <Table.Cell textAlign="center">{amountK && amountK.toFixed(2)}</Table.Cell>
                        <Table.Cell textAlign="center">{amountS && amountS.toFixed(2)}</Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>

                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">{totalProduct ? totalProduct.toFixed(0) : "-"} kg/ha</Table.HeaderCell>
                    {totalAmounts && Object.keys(totalAmounts).map((x) => (
                      <Table.HeaderCell
                        key={"sumRow" + x}
                        textAlign="center"
                      >
                        {totalAmounts[x] ? totalAmounts[x].toFixed(1) : "0.0"} kg/ha
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Footer>
              </Table>
            </div>
          }
        </Segment>
      );
    }
  };

  getEmptyNutritionRow = (year, idx, compact) => {
    if (compact) {
      return (
        <Segment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.25em",
            }}
          >
            <Header
              as="h4"
              style={{
                fontWeight: 500,
              }}
            >
              Gödsling
            </Header>

            <p
              className="textLink"
              onClick={() => this.onToogleEditNutrition(year, "add", null)}
            >
              Lägg till
            </p>
          </div>

          <p style={{ color: "darkgrey" }}>
            Freja saknar information om gödsling för denna säsong.
          </p>

        </Segment>
      );
    } else {
      return (
        <Segment
          key={'nutritionInfoRow' + year}
          style={{
            padding: "1.5em",
            width: "70%",
            marginLeft: "15%",
            borderStyle: "dashed",
            backgroundColor: "transparent",
          }}
          secondary
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: "140%",
                width: "10%"
              }}
            >
              {year}
            </div>

            <div
              style={{
                width: "90%"
              }}
            >
              <div
                style={{
                  marginTop: 4,
                  fontSize: "110%",
                }}
              >
                Information saknas! Lägg till säsongens gröda under "sådd" innan du kan fortsätta.
              </div>
            </div>
          </div>
        </Segment>
      );
    };
  };

  getEditNutritionRow = () => {
    const {
      editYear,
      editFertilizationProduct,
      editFertilizationAmount,
      editFertilizationDate,
      editFertilizationNutrients,
    } = this.state;

    let nutrients = {};

    editFertilizationNutrients && editFertilizationAmount && Object.keys(editFertilizationNutrients).forEach((x) => {
      nutrients = {
        ...nutrients,
        [x]: (parseFloat(editFertilizationNutrients[x]) * parseFloat(editFertilizationAmount) / 100.0).toFixed(2),
      }
    });

    return (
      <React.Fragment>
        <Segment
          basic
        >
          <Header
            as="h5"
            style={{
              marginBottom: 5,
              fontWeight: 600
            }}
          >
            Datum för gödsling
          </Header>

          <DateInput
            fluid
            name='editFertilizationDate'
            minDate={(parseInt(editYear) - 1).toFixed(0) + '-08-01'}
            maxDate={editYear + '-07-30'}
            dateFormat="YYYY-MM-DD"
            value={editFertilizationDate ? editFertilizationDate : editYear + '-03-01'}
            initialDate={editYear + '-03-01'}
            onChange={(event, data) => this.onChangeField(data)}
            localization='sv'
          />

          <Header
            as="h5"
            style={{
              marginTop: 12,
              marginBottom: 5,
              fontWeight: 600
            }}
          >
            Typ av gödslingspreparat
          </Header>

          <Dropdown
            fluid
            search
            selection
            name="editFertilizationProduct"
            options={NUTRIENT_FERTILIZERS}
            closeOnChange={true}
            value={editFertilizationProduct}
            onChange={(event, data) => this.onChangeField(data, false, true)}
          />

          <Header
            as="h5"
            style={{
              marginTop: 12,
              marginBottom: 5,
              fontWeight: 600
            }}
          >
            Mängd av preparat (kg/ha)
          </Header>

          <Input
            fluid
            name='editFertilizationAmount'
            value={editFertilizationAmount ? editFertilizationAmount : ''}
            onChange={(event, data) => this.onChangeField(data, true)}
          />

          {nutrients && Object.keys(nutrients).length > 0 &&
            <Header
              as="h5"
              style={{
                marginTop: 12,
                marginBottom: 5,
                fontWeight: 600
              }}
            >
              Tillförd mängd näringsämnen
            </Header>
          }

          {nutrients && Object.keys(nutrients).length > 0 &&
            <Table
              celled
              size='small'
            >
              <Table.Header>
                <Table.Row>
                  {Object.keys(nutrients).map((x) => {
                    if (nutrients[x] === "0.00" || NUTRIENTS_TO_SKIP.includes(x) || !NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                      return <div />
                    };
                    return <Table.HeaderCell key={"tableHeader" + x} textAlign="center">{x}</Table.HeaderCell>
                  })}
                  {Object.keys(nutrients).map((x) => {
                    if (nutrients[x] === "0.00" || NUTRIENTS_TO_SKIP.includes(x) || NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                      return <div />
                    };
                    return <Table.HeaderCell key={"tableHeader" + x} textAlign="center">{x}</Table.HeaderCell>
                  })}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  {Object.keys(nutrients).map((x) => {
                    if (!nutrients[x]) {
                      return <div />
                    };

                    console.log(nutrients[x])

                    if (nutrients[x] === "0.00" || NUTRIENTS_TO_SKIP.includes(x) || !NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                      return <div />
                    };

                    return <Table.Cell key={"tableCell" + x} textAlign="center">{nutrients[x]} kg/ha</Table.Cell>
                  })}
                  {Object.keys(nutrients).map((x) => {
                    if (nutrients[x] === "0.00" || NUTRIENTS_TO_SKIP.includes(x) || NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                      return <div />
                    };
                    return <Table.Cell key={"tableCell" + x} textAlign="center">{nutrients[x]} kg/ha</Table.Cell>
                  })}
                </Table.Row>
              </Table.Body>
            </Table>
          }
        </Segment>
      </React.Fragment>
    )
  };

  render() {
    const {
      db,
      fieldId,
      widget,
      widgetYear,
    } = this.props;

    const {
      mode,
      editYear,
      editFertilizationDate,
      editFertilizationProduct,
      dateToRemove,
    } = this.state;

    let availableYearList = ["2017", "2018", "2019", "2020", "2021", "2022"];

    let yieldMapYears = db && fieldId &&
      fieldId && db["fieldsYieldMapsProcessed_" + fieldId] &&
      db["fieldsYieldMapsProcessed_" + fieldId].rawdata_harvest_maps_geojson &&
      Object.keys(db["fieldsYieldMapsProcessed_" + fieldId].rawdata_harvest_maps_geojson).map((x) => x);

    yieldMapYears && yieldMapYears.forEach((x) => {
      availableYearList = !availableYearList.includes(x) ? [...availableYearList, x] : availableYearList;
    });

    availableYearList = availableYearList && availableYearList.sort();

    let modalData =
      <React.Fragment>
        <Modal
          open={["add", "edit"].includes(mode)}
          size="small"
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            {mode === "edit" ? "Redigera gödslingsgiva för " + editYear : "Lägg till gödslingsgiva för " + editYear}
          </Modal.Header>

          <Modal.Content>
            <Modal.Description>
              {this.getEditNutritionRow()}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              style={{
                backgroundColor: "#868D8E",
                color: "white",
              }}
              onClick={() => this.onResetState()}
            >
              Avbryt
            </Button>

            {mode === "edit" &&
              <Button
                basic
                style={{
                  color: "#868D8E",
                }}
                onClick={() => this.onToggleRemoveNutrition(editFertilizationDate)}
              >
                Ta bort
              </Button>
            }

            <Button
              style={{
                backgroundColor: "#6B9D9F",
                color: "white",
              }}
              disabled={mode === "add" && (!editYear || !editFertilizationProduct)}
              onClick={mode === "add" ? () => this.onClickAddNutrition() : () => this.onToogleEditNutrition()}
            >
              Spara
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal size="small" open={dateToRemove !== null}>
          <Modal.Header>Ta bort gödslingsår</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>Vill du ta bort gödslingsinformationen för detta skifte under {dateToRemove}?</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              style={{
                backgroundColor: "#868D8E",
                color: "white",
              }}
              onClick={() => this.setState({ dateToRemove: null })}
            >
              Avbryt
            </Button>

            <Button
              style={{
                backgroundColor: "#C36126",
                color: "white",
              }}
              onClick={() => this.removeNutritionRation()}
            >
              Ta bort
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>

    if (widget) {
      return (
        <React.Fragment>
          {modalData && modalData}
          {widgetYear && this.getNutritionRow(widgetYear, widgetYear, true)}
        </React.Fragment>
      )

    } else {
      return (
        <React.Fragment>
          {modalData && modalData}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5em",
            }}
          >
            <div
              style={{
                width: "70%",
              }}
            >
              {availableYearList && availableYearList.reverse().map((year, idx) => this.getNutritionRow(year, idx))}
            </div>

            <div
              style={{
                maxWidth: "30%",
              }}
            >
              <Segment
                style={{
                  marginTop: 0,
                  padding: "2em",
                  minWidth: "250px",
                }}
              >
                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Gödsling
                </Header>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  I denna vy kan du lägga till och redigera information om säsongens gödsling.
                  Detta innefattar vilka givor och när de har lagts ut med snittgiva och preparat.
                  Denna information använder Freja för tolkningar och uppföljningar.
                </p>

                <Header
                  as="h2"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Nuvarande information
                </Header>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  Till vänster kan du se den information om gödslingen som du har angivit för detta skifte.
                  Du kan enkelt redigera information genom att klicka på knappen "Redigera" till höger.
                </p>

                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                  }}
                >
                  År som helt eller delvis saknar information men som har data i form av grönmassamätningar eller uppladdade skördekartor är markerade med gult.
                  År med komplett information är markerade med grönt.
                </p>
              </Segment>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default FieldManagementNutrition;
