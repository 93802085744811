import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";

import {
  firestoreConnect,
  isLoaded,
} from "react-redux-firebase";

import {
  Segment,
  Header,
  Button,
  Modal,
  Dropdown,
  Table,
  Icon,
  Menu,
  Confirm,
  Input,
  Checkbox,
} from "semantic-ui-react";

import {
  DateInput,
} from 'semantic-ui-calendar-react';

import MenuAdmin from "@/components/admin/MenuAdmin";

import moment from 'moment';
import { toast } from "react-toastify";

const USERS_PER_PAGE = 10;

const PLAN_CAPTIONS = {
  'demo': 'Demokonto',
  'explore': 'Utforska',
  'analyze': 'Tolka',
  'control': 'Styra',
  'developer': 'Utvecklarmiljö',
};

const INITIAL_STATE = {
  activeView: "impersonate",
  newPlanTypeName: null,
  newPlanValidTo: null,
  newUserId: null,
  showEditUserModal: false,
  currentSection: 0,
  regKeyToRemove: null,
  removedRegKeys: [],
  newRegKey: '',
  newRegKeyNote: '',
  newRegKeyIsDemo: false,
  latestRegKey: null,
};
class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    this.generateNewRegKey();
  };

  getDb = () => this.props.firestoreData;

  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  switchUser = (newUserId) => {
    newUserId !== this.props.userId && this.props.history.push("/");
    newUserId !== this.props.userId && this.props.switchUser(newUserId);
  };

  changeView = (newView) => {
    this.setState({
      activeView: newView,
      currentSection: 0,
    });
  };

  handleChange = (event, data) => {
    this.setState({ [data.name]: data.value })
  };

  toggleCheckBox = (stateVariable) => {
    let currentValue = this.state[stateVariable];
    this.setState({ [stateVariable]: !currentValue })
  }

  generateNewRegKey = () => {
    function randomString(length, chars) {
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    }

    let newRegKey = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyz');

    this.setState({ newRegKey });
  };

  addNewRegKey = () => {
    const {
      newRegKey,
      newRegKeyIsDemo,
      newRegKeyNote,
    } = this.state;

    this.props.firestore.collection("regKeys").doc(newRegKey).set({
      valid: true,
      is_demo: newRegKeyIsDemo,
      note: newRegKeyNote,
      date_added: new Date(),
    })
      .then(() => {
        this.setState({
          newRegKeyIsDemo: false,
          newRegKeyNote: '',
          latestRegKey: newRegKey
        }, this.generateNewRegKey());

        toast.success("Du har lagt till en ny registreringsnyckel",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 7000,
            hideProgressBar: false,
          });
      })
      .catch((error) => {
        toast.error(error.toString(),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 7000,
            hideProgressBar: false,
          });
      });
  };

  openEditUser = (userInfo) => {
    this.setState({
      newPlanTypeName: userInfo.planTypeName,
      newPlanValidTo: moment(userInfo.planValidTo.toDate()).format("YYYY-MM-DD"),
      newFarmName: userInfo.farmName,
      newUserId: userInfo.id,
      showEditUserModal: true,
    })
  };

  saveEditedUser = () => {
    const {
      newPlanTypeName,
      newPlanValidTo,
      newUserId,
    } = this.state;

    let dbRef = this.props.firestore.collection("users").doc(newUserId).collection("profile").doc("plan");

    // Handling annoying trailing milliseconds
    let reformattedNewPlanValidTo = newPlanValidTo && moment(newPlanValidTo, "YYYY-MM-DD").endOf("day")
    reformattedNewPlanValidTo = reformattedNewPlanValidTo && moment(reformattedNewPlanValidTo.format("LLL"), "LLL");

    dbRef.update({
      plan_type: newPlanTypeName,
      plan_valid_to: reformattedNewPlanValidTo.toDate(),
      plan_name: PLAN_CAPTIONS[newPlanTypeName],
    });

    this.setState({
      newPlanTypeName: null,
      newPlanValidTo: null,
      newFarmName: null,
      newUserId: null,
      showEditUserModal: false,
    });
  };

  getHandleUserView = () => {
    const {
      userList,
      isAdminUser,
    } = this.props;

    const {
      newPlanTypeName,
      newPlanValidTo,
      showEditUserModal,
      newFarmName,
      currentSection,
    } = this.state;

    const db = this.getDb();

    let noUsers = userList && userList.length;
    let noSections = noUsers && Math.ceil(noUsers / USERS_PER_PAGE);

    let fullUserList = isAdminUser && db && isLoaded(db) && userList && userList.map((x) => {

      let userProfile = db && db["profile_" + x];
      let contactPerson = userProfile && userProfile.contact && userProfile.contact.first_name ? userProfile.contact.first_name : "";
      contactPerson = userProfile && userProfile.contact && userProfile.contact.last_name ? contactPerson + " " + userProfile.contact.last_name : contactPerson;

      return (
        {
          id: x,
          farmName: userProfile && userProfile.farm && userProfile.farm.name,
          contactPerson,
          email: userProfile && userProfile.contact && userProfile.contact.email,
          accountCreated: userProfile && userProfile.log && userProfile.log.date_account_created,
          farmCreated: userProfile && userProfile.log && userProfile.log.date_farm_created,
          latestLoginDesktop: userProfile && userProfile.log && userProfile.log.date_latest_login_desktop,
          latestLoginMobile: userProfile && userProfile.log && userProfile.log.date_latest_login_mobile,
          planTypeName: userProfile && userProfile.plan && userProfile.plan.plan_type,
          planValidTo: userProfile && userProfile.plan && userProfile.plan.plan_valid_to,
        }
      )
    });

    fullUserList = fullUserList && fullUserList.filter(x => {
      return (x.farmName || x.contactPerson || x.accountCreated) && x.planValidTo
    });

    fullUserList = fullUserList && fullUserList.sort(function (a, b) {
      if (a.farmName > b.farmName) {
        return 1;
      }
      if (b.farmName > a.farmName) {
        return -1;
      }
      return 0;
    });

    fullUserList = fullUserList && [
      ...fullUserList.filter((x) => x.planValidTo && (moment() <= moment(x.planValidTo.toDate()))),
      ...fullUserList.filter((x) => !x.planValidTo || (moment() > moment(x.planValidTo.toDate()))),
    ];

    let filteredUserList = fullUserList && fullUserList.filter((x, idx) => idx >= currentSection * USERS_PER_PAGE && idx < (currentSection + 1) * USERS_PER_PAGE);

    let planSelects = Object.keys(PLAN_CAPTIONS).map((x) => ({
      key: x,
      value: x,
      text: PLAN_CAPTIONS[x],
    }));

    return (
      <React.Fragment>
        <Modal
          open={showEditUserModal}
          size="small"
        >
          <Modal.Header>{"Redigera användaruppgifter för: " + newFarmName}</Modal.Header>
          <Modal.Content>
            <p>
              Moduler:
              <Dropdown
                selection
                fluid
                name='newPlanTypeName'
                options={planSelects}
                value={newPlanTypeName}
                onChange={this.handleChange}
              />
            </p>

            <p>
              Plan giltig till:
              <DateInput
                fluid
                name="newPlanValidTo"
                placeholder="Datum"
                value={newPlanValidTo}
                popupPosition="bottom right"
                dateFormat="YYYY-MM-DD"
                localization='sv'
                iconPosition="left"
                onChange={this.handleChange}
              />
            </p>
          </Modal.Content>
          <Modal.Actions
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              style={{
                backgroundColor: "#868D8E",
                color: "white",
              }}
              onClick={() => this.setState({
                newPlanTypeName: null,
                newPlanValidTo: null,
                newFarmName: null,
                newUserId: null,
                showEditUserModal: false
              })}
            >
              Avbryt
            </Button>

            <Button
              style={{
                backgroundColor: "#6B9D9F",
                color: "white",
              }}
              onClick={() => this.saveEditedUser()}
            >
              Spara
            </Button>
          </Modal.Actions>
        </Modal>

        <Table celled padded selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Gårdsnamn</Table.HeaderCell>
              <Table.HeaderCell>Kontaktperson</Table.HeaderCell>
              <Table.HeaderCell>Internt id</Table.HeaderCell>
              <Table.HeaderCell>E-post</Table.HeaderCell>
              <Table.HeaderCell>Konto skapat</Table.HeaderCell>
              <Table.HeaderCell>Plan</Table.HeaderCell>
              <Table.HeaderCell>Plan giltig till</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredUserList && filteredUserList.map((x) => (
              <Table.Row
                key={"editUserRow_" + x.id}
                negative={!x.planValidTo || (moment() > moment(x.planValidTo.toDate()))}
              >
                <Table.Cell>{x.farmName ? x.farmName : "-"}</Table.Cell>
                <Table.Cell>{x.contactPerson ? x.contactPerson : "-"}</Table.Cell>
                <Table.Cell>{x.id ? x.id : "-"}</Table.Cell>
                <Table.Cell>{x.email ? x.email : "-"}</Table.Cell>
                <Table.Cell>{x.accountCreated ? moment(x.accountCreated.toDate()).format("YYYY-MM-DD") : "-"}</Table.Cell>
                <Table.Cell>{x.planTypeName ? PLAN_CAPTIONS[x.planTypeName] : "-"}</Table.Cell>
                <Table.Cell>{x.planValidTo ? moment(x.planValidTo.toDate()).format("YYYY-MM-DD") : "-"}</Table.Cell>

                <Table.Cell>
                  <Button
                    icon
                    labelPosition="left"
                    primary
                    onClick={() => this.openEditUser(x)}
                    size="small"
                  >
                    <Icon name="edit" />
                    Redigera
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='8'>
                <Menu floated='right' pagination>
                  <Menu.Item
                    as='a'
                    icon
                    onClick={() => this.setState({ currentSection: Math.max(0, currentSection - 1) })}
                  >
                    <Icon name='chevron left' />
                  </Menu.Item>
                  {noSections && Array.from(Array(noSections).keys()).map((x) => (
                    <Menu.Item
                      active={currentSection === x}
                      key={"Menu_item_" + x.toFixed(0)}
                      as='a'
                      onClick={() => this.setState({ currentSection: x })}
                    >
                      {x + 1}
                    </Menu.Item>
                  ))}
                  <Menu.Item
                    as='a'
                    icon
                    onClick={() => this.setState({ currentSection: Math.min(noSections - 1, currentSection + 1) })}
                  >
                    <Icon name='chevron right' />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </React.Fragment>
    )
  }

  getImpersonateUsers = () => {
    const {
      userList,
      isAdminUser
    } = this.props;

    const {
      currentSection,
    } = this.state;

    const db = this.getDb();

    let noUsers = userList && userList.length;
    let noSections = noUsers && Math.ceil(noUsers / USERS_PER_PAGE);

    let fullUserList = isAdminUser && db && isLoaded(db) && userList && userList.map((x) => {

      let userProfile = db && db["profile_" + x];
      let contactPerson = userProfile && userProfile.contact && userProfile.contact.first_name ? userProfile.contact.first_name : "";
      contactPerson = userProfile && userProfile.contact && userProfile.contact.last_name ? contactPerson + " " + userProfile.contact.last_name : contactPerson;

      return (
        {
          id: x,
          farmName: userProfile && userProfile.farm && userProfile.farm.name,
          contactPerson,
          accountCreated: userProfile && userProfile.log && userProfile.log.date_account_created,
          farmCreated: userProfile && userProfile.log && userProfile.log.date_farm_created,
          latestLoginDesktop: userProfile && userProfile.log && userProfile.log.date_latest_login_desktop,
          latestLoginMobile: userProfile && userProfile.log && userProfile.log.date_latest_login_mobile,
          planValidTo: userProfile && userProfile.plan && userProfile.plan.plan_valid_to,
        }
      )
    });

    fullUserList = fullUserList && fullUserList.filter(x => {
      return (x.farmName || x.contactPerson || x.accountCreated) && x.planValidTo
    });

    fullUserList = fullUserList && fullUserList.sort(function (a, b) {
      if (a.farmName > b.farmName) {
        return 1;
      }
      if (b.farmName > a.farmName) {
        return -1;
      }
      return 0;
    });

    fullUserList = fullUserList && [
      ...fullUserList.filter((x) => x.planValidTo && (moment() <= moment(x.planValidTo.toDate()))),
      ...fullUserList.filter((x) => !x.planValidTo || (moment() > moment(x.planValidTo.toDate()))),
    ];

    let filteredUserList = fullUserList && fullUserList.filter((x, idx) => idx >= currentSection * USERS_PER_PAGE && idx < (currentSection + 1) * USERS_PER_PAGE);

    return (
      <Table celled padded selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Gårdsnamn</Table.HeaderCell>
            <Table.HeaderCell>Kontaktperson</Table.HeaderCell>
            <Table.HeaderCell>Konto skapat</Table.HeaderCell>
            <Table.HeaderCell>Gård skapad</Table.HeaderCell>
            <Table.HeaderCell>Senast inloggad (desktop)</Table.HeaderCell>
            <Table.HeaderCell>Senast inloggad (mobil)</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredUserList && filteredUserList.map((x) => (
            <Table.Row
              key={"impersonateUserRow_" + x.id}
              negative={!x.planValidTo || (moment() > moment(x.planValidTo.toDate()))}
            >
              <Table.Cell>{x.farmName ? x.farmName : "-"}</Table.Cell>
              <Table.Cell>{x.contactPerson ? x.contactPerson : "-"}</Table.Cell>
              <Table.Cell>{x.accountCreated ? " " + moment(x.accountCreated.toDate()).format("YYYY-MM-DD") : "-"}</Table.Cell>
              <Table.Cell>{x.farmCreated ? " " + moment(x.farmCreated.toDate()).format("YYYY-MM-DD") : "-"}</Table.Cell>
              <Table.Cell>{x.latestLoginDesktop ? " " + moment(x.latestLoginDesktop.toDate()).format("YYYY-MM-DD") : "-"}</Table.Cell>
              <Table.Cell>{x.latestLoginMobile ? " " + moment(x.latestLoginMobile.toDate()).format("YYYY-MM-DD") : "-"}</Table.Cell>
              <Table.Cell >
                <Button
                  icon
                  labelPosition="left"
                  primary
                  onClick={() => this.switchUser(x.id)}
                  size="small"
                >
                  <Icon name="sign-in" />
                  Impersonate
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='8'>
              <Menu floated='right' pagination>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => this.setState({ currentSection: Math.max(0, currentSection - 1) })}
                >
                  <Icon name='chevron left' />
                </Menu.Item>
                {noSections && Array.from(Array(noSections).keys()).map((x) => (
                  <Menu.Item
                    active={currentSection === x}
                    key={"Menu_item_" + x.toFixed(0)}
                    as='a'
                    onClick={() => this.setState({ currentSection: x })}
                  >
                    {x + 1}
                  </Menu.Item>
                ))}
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => this.setState({ currentSection: Math.min(noSections - 1, currentSection + 1) })}
                >
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  };

  getCreateRegistrationKeys = () => {
    const {
      newRegKey,
      newRegKeyNote,
      newRegKeyIsDemo,
    } = this.state;

    return (
      <Segment
        fluid
        secondary
        style={{
          padding: "1.5em",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "30%",
          }}
        >
          <Header
            as="h5"
            style={{
              marginBottom: 5,
              fontWeight: 600
            }}
          >
            Nyckel
          </Header>

          <Input
            fluid
            value={newRegKey}
          />
        </div>

        <div
          style={{
            width: "30%",
          }}
        >
          <Header
            as="h5"
            style={{
              marginBottom: 5,
              fontWeight: 600
            }}
          >
            Anteckning
          </Header>

          <Input
            fluid
            name="newRegKeyNote"
            value={newRegKeyNote}
            onChange={(e, d) => this.handleChange(e, d)}
          />
        </div>

        <div
        >
          <Header
            as="h5"
            style={{
              marginBottom: 5,
              fontWeight: 600
            }}
          >
            Demokonto
          </Header>

          <Checkbox
            toggle
            value={newRegKeyIsDemo}
            onChange={() => this.toggleCheckBox("newRegKeyIsDemo")}
          />
        </div>

        <div
          style={{
            marginTop: 20,
          }}
        >
          <Button
            primary
            disabled={!newRegKey}
            onClick={() => this.addNewRegKey()}
          >
            Skapa nyckel
          </Button>
        </div>
      </Segment>
    )
  }

  getLatestCreatedRegKey = () => {
    const {
      latestRegKey,
    } = this.state;

    if (!latestRegKey) {
      return <div />
    };

    let regKeyUrl = "https://freja.agriopt.se/register?key=" + latestRegKey;

    return (
      <Segment
        fluid
        secondary
        style={{
          padding: "1.5em",
        }}
      >
        Kunden kan använda följande länk för att registrera sig: {" "}
        <b>
          <a href={regKeyUrl} target="_blank" rel="noreferrer">{regKeyUrl}</a>
        </b>
      </Segment>
    )
  }

  getRegistrationKeys = () => {
    const {
      currentSection,
      removedRegKeys,
    } = this.state;

    const dbRegKeys = this.props.firestoreData.regKeys;

    let regKeys = dbRegKeys && isLoaded(dbRegKeys) && Object.keys(dbRegKeys).map((x) => x);
    regKeys = regKeys && removedRegKeys && regKeys.filter((x) => !removedRegKeys.includes(x));

    let noRegKeys = regKeys && regKeys.length;
    let noSections = noRegKeys && Math.ceil(noRegKeys / USERS_PER_PAGE);

    let filteredRegKeys = regKeys && regKeys.filter((x, idx) => idx >= currentSection * USERS_PER_PAGE && idx < (currentSection + 1) * USERS_PER_PAGE);

    let regKeysInfo = filteredRegKeys && filteredRegKeys.map((x) => (
      {
        key: x,
        note: dbRegKeys[x] && dbRegKeys[x].note,
        date_used: dbRegKeys[x] && dbRegKeys[x].date ? dbRegKeys[x].date : dbRegKeys[x] && dbRegKeys[x].date_used,
        date_created: dbRegKeys[x] && dbRegKeys[x].date_created,
        is_demo: dbRegKeys[x] && dbRegKeys[x].is_demo,
        email: dbRegKeys[x] && dbRegKeys[x].email,
        valid: dbRegKeys[x] && dbRegKeys[x].valid,
      }
    ));

    return (
      <Table celled padded selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nyckel</Table.HeaderCell>
            <Table.HeaderCell>Anteckning</Table.HeaderCell>
            <Table.HeaderCell>Skapad</Table.HeaderCell>
            <Table.HeaderCell>Använd</Table.HeaderCell>
            <Table.HeaderCell>Mail registrerad</Table.HeaderCell>
            <Table.HeaderCell>Demokonto</Table.HeaderCell>
            <Table.HeaderCell>Giltig</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {regKeysInfo && regKeysInfo.map((x) => {

            if (!x) {
              return null;
            }

            let dateCreated = x && x.date_created ? moment(x.date_created.toDate()).format("YYYY-MM-DD") : "-";
            let dateUsed = x && x.date_used ? moment(x.date_used.toDate()).format("YYYY-MM-DD") : "-";

            return (
              <Table.Row
                positive={x.valid}
                negative={!x.valid}
              >
                <Table.Cell>{x.key ? x.key : "-"}</Table.Cell>
                <Table.Cell>{x.note ? x.note : "-"}</Table.Cell>
                <Table.Cell>{dateCreated ? dateCreated : "-"}</Table.Cell>
                <Table.Cell>{dateUsed ? dateUsed : "-"}</Table.Cell>
                <Table.Cell>{x.email ? x.email : "-"}</Table.Cell>
                <Table.Cell>{x.is_demo ? "Ja" : "Nej"}</Table.Cell>
                <Table.Cell
                  textAlign="center"
                >
                  {x.valid ? "Ja" : "Nej"}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    style={{
                      width: "60%",
                      marginLeft: "20%",
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                    onClick={() => this.setState({ regKeyToRemove: x.key })}
                    basic
                    size="small"
                  >
                    Ta bort
                  </Button>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='8'>
              <Menu floated='right' pagination>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => this.setState({ currentSection: Math.max(0, currentSection - 1) })}
                >
                  <Icon name='chevron left' />
                </Menu.Item>
                {noSections && Array.from(Array(noSections).keys()).map((x) => (
                  <Menu.Item
                    active={currentSection === x}
                    key={"Menu_item_" + x.toFixed(0)}
                    as='a'
                    onClick={() => this.setState({ currentSection: x })}
                  >
                    {x + 1}
                  </Menu.Item>
                ))}
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => this.setState({ currentSection: Math.min(noSections - 1, currentSection + 1) })}
                >
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  };

  removeRegKey = () => {
    const {
      regKeyToRemove,
      removedRegKeys,
    } = this.state;

    this.props.firestore.collection("regKeys").doc(regKeyToRemove).delete();

    this.setState({
      removedRegKeys: [...removedRegKeys, regKeyToRemove],
      regKeyToRemove: null
    });
  };

  render() {

    const {
      isAdminUser,
    } = this.props;

    const {
      activeView,
      regKeyToRemove,
    } = this.state;

    if (!isAdminUser) {
      return <div />
    }

    return (
      <React.Fragment>
        <MenuAdmin
          activeView={activeView}
          changeView={this.changeView}
        />

        <Confirm
          open={regKeyToRemove}
          header="Ta bort registreringsnyckel"
          content={"Denna handling går inte att ångra och ingen användare kommer kunna registrera sig med nyckeln: " + regKeyToRemove}
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.removeRegKey()}
          onCancel={() => this.setState({ regKeyToRemove: null })}
          size="tiny"
        />

        <div
          style={{
            marginTop: 70,
            marginLeft: "15%",
            width: "70%",
            paddingBottom: 30,
          }}
        >
          {activeView === "impersonate" && this.getImpersonateUsers()}
          {activeView === "handleUsers" && this.getHandleUserView()}
          {activeView === "regKeys" && this.getCreateRegistrationKeys()}
          {activeView === "regKeys" && this.getLatestCreatedRegKey()}
          {activeView === "regKeys" && this.getRegistrationKeys()}
        </div>
      </React.Fragment>
    );
  }
};

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => {

    let output = [
      {
        collection: 'admins',
        storeAs: 'admins'
      },
      {
        collection: 'regKeys',
        storeAs: 'regKeys'
      },
    ];

    props && props.userList && props.userList.forEach((x) => {
      output = [...output,
      {
        collection: 'users',
        doc: `${x}`,
        subcollections: [
          { collection: "profile" },
        ],
        storeAs: 'profile_' + x
      },
      ]
    })

    return output;
  }),
  withFirestoreData,
  withRouter,
)(AdminPage);
