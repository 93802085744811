import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { cloudFunctions } from "@";

import FieldManagementGeneral from "@/components/fieldManagement/FieldManagementGeneral";
import FieldManagementFileManager from "@/components/fieldManagement/FieldManagementFileManager";
import FieldManagementCollectedData from "./FieldManagementCollectedData";

import FieldManagementHarvest from "@/components/fieldManagement/FieldManagementHarvest"; 
import FieldManagementSeeding from "@/components/fieldManagement/FieldManagementSeeding";
import FieldManagementNutrition from "@/components/fieldManagement/FieldManagementNutrition";
import FieldManagementDeveloper from "@/components/fieldManagement/FieldManagementDeveloper";

import GuidesFieldActiveList from "@/components/guideData/GuidesFieldActiveList";

import { toast } from "react-toastify";

class FieldManagementMain extends Component {

  state = {};

  updateFieldInfo = (year, payload) => {
    const {
      userId,
      fieldId,
    } = this.props;

    let dbRef = this.props.getUser().collection("fieldsInfo").doc(fieldId);
    
    let db = this.props.getDb();
    let field = db && db.fields[fieldId] && db.fields[fieldId];
    let fieldInfo = db && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];

    // Iterate over all fields in payload
    Object.keys(payload).map((x) => x).forEach((x) => {
      let currentValue = fieldInfo && fieldInfo[x] && fieldInfo[x];

      if (payload[x]) {
        // Add or update entry
        if (currentValue) {
          dbRef.update({
            [x]: {...currentValue, [year]: payload[x]}
          })
        } else {
          dbRef.update({
            [x]: {[year]: payload[x]}
          })
        };
      } else {
        // Remove entry
        if (currentValue && currentValue[year]) {

          delete currentValue[year];
          
          dbRef.update({
            [x]: currentValue,
          })
        }
      }
    });

    let text = "Freja har uppdaterat informationen för skiftet: " + (field && field.name && field.name) + ".";

    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });

    payload && Object.keys(payload) && Object.keys(payload).includes("crop_code") &&
      cloudFunctions.httpsCallable('updatedCropCodes')(
        {
          userId: userId,
          fieldId: fieldId,
          year: year,
        }
      );
  };

  updateFertilizationRation = ({year, ration}) => {
    const {
      fieldId,
    } = this.props;

    let dbRef = this.props.getUser().collection("fieldsInfo").doc(fieldId);
    
    let db = this.props.getDb();
    let field = db && db.fields[fieldId] && db.fields[fieldId];
    let fieldInfo = db && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];

    let currentValue = fieldInfo && fieldInfo.n_fertilizer && fieldInfo.n_fertilizer;

    if (ration && ration.total && typeof(ration.total) === "number") {
      if (currentValue) {
        dbRef.update({
          n_fertilizer: {...currentValue, [year]: ration}
        })
      } else {
        dbRef.update({
          n_fertilizer: {[year]: ration}
        })
      };
    } else {

      if (currentValue && currentValue[year]) {
        delete currentValue[year];

        dbRef.update({
          n_fertilizer: currentValue,
        })
      }
    };

    let text = "Freja har uppdaterat gödslingen för skiftet: " + (field && field.name && field.name) + ".";
    
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });
  }; 
  
  updateWeightedHarvest = ({year, harvest}) => {
    const {
      userId,
      fieldId,
    } = this.props;

    let dbRef = this.props.getUser().collection("fieldsInfo").doc(fieldId);

    let db = this.props.getDb();
    let field = db && db.fields[fieldId] && db.fields[fieldId];
    let fieldInfo = db && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];

    let currentValue = fieldInfo && fieldInfo.harvest_yields && fieldInfo.harvest_yields;

    if (harvest) {
      if (currentValue) {
        dbRef.update({
          harvest_yields: {...currentValue, [year]: harvest}
        })
      } else {
        dbRef.update({
          harvest_yields: {[year]: harvest}
        })
      };
    } else {
      delete currentValue[year];

      dbRef.update({
        harvest_yields: currentValue,
      })
    };

    let text = "Freja har uppdaterat skörden för skiftet: " + (field && field.name && field.name) + ".";
    
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });

    cloudFunctions.httpsCallable('updatedHarvestYields')(
      {
        userId: userId,
        fieldId: fieldId,
        year: year,
      }
    );    
  };

  lockField = (fieldId) => {
    let dbRef = this.props.getUser().collection("fields").doc(fieldId);

    dbRef.update({
      available_to_user: false,
    });

    this.props.history.push("/collect"); 
  };

  removeField = (fieldId) => {
    let dbRef = this.props.getUser().collection("fields").doc(fieldId);

    dbRef.update({
      available_to_user: false,
      disabled: true,
      deleted: true,
      date_deleted: new Date(),
    });

    this.props.history.push("/collect"); 
  };

  changeFieldName = (newFieldName, fieldId) => {
    let dbRef = this.props.getUser().collection("fields").doc(fieldId);

    dbRef.update({
      name: newFieldName,
    });  
  };

  render() {
    const {
      db,
      userId,
      field,
      fieldInfo,
      fieldId,
      layer,
      getUser,
    } = this.props;
    
    if (!fieldId || !layer) {
      return <div />
    }
    
    return (
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          marginTop: 70,
          width: "100%",
          height: "calc(100vh - 70px)",
          overflowY: "auto",
          whiteSpace: "pre-line",
        }}
      >
        {layer === "fieldInfo" &&
          <FieldManagementGeneral
            db={db}
            getUser={getUser}
            field={field}
            fieldInfo={fieldInfo}
            userId={userId}
            fieldId={fieldId}
            changeFieldName={this.changeFieldName}
            removeField={this.removeField}
            lockField={this.lockField}
          />
        }

        {layer === "fieldInfo_uploadedFilesGuides" && <GuidesFieldActiveList {...this.props} floating={true} />}
        {layer === "fieldInfo_uploadedFiles" && <FieldManagementFileManager {...this.props} />}
        {layer === "fieldInfo_collectedData" && <FieldManagementCollectedData {...this.props} />}

        {layer === "fieldInfo_developer" && 
          <FieldManagementDeveloper
            db={db}
            field={field}
            fieldInfo={fieldInfo}
            userId={userId}
            fieldId={fieldId}
            removeField={this.removeField}
          />
        }        

        {layer === "seeding" &&
          <FieldManagementSeeding 
            db={db}
            userId={userId}
            getUser={this.props.getUser}
            fieldId={fieldId}
          />
        }

        {layer === "nutrition" &&
          <FieldManagementNutrition
            db={db}
            userId={userId}
            fieldId={fieldId}
            fieldInfo={fieldInfo}
            updateFieldInfo={this.updateFieldInfo}
          />
        }
        
        {layer === "harvest" &&
          <FieldManagementHarvest
            db={db}
            userId={userId}
            fieldId={fieldId}
            field={field}
            fieldInfo={fieldInfo}
            updateFieldInfo={this.updateFieldInfo}
          />
        }             
      </div>
    );
  }
}

export default withRouter(FieldManagementMain);
