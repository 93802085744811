import React, { Component } from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import {
  withFirebase,
  withFirestore as withFirestoreActions,  
  firestoreConnect,
} from "react-redux-firebase";

import { 
  Modal,
  Loader,
  Menu,
  Button,
  Select,
  Confirm,
} from "semantic-ui-react";

import moment from 'moment';

import { cloudFunctions } from "@";

import ModalMenuItem from "@/components/menus/ModalMenuItem";

import { BUILD_DATE, MAIN_APP_VERSION } from "@/constants/settings";

const settingsList = [
  {
    id: 'user',
    caption: 'Användare'
  },
  {
    id: 'data',
    caption: 'Datakällor'
  },
  {
    id: 'integrations',
    caption: 'Integrationer'
  },   
  {
    id: 'map',
    caption: 'Kartor'
  },
  {
    id: 'notifications',
    caption: 'Mobilnotiser'
  },   
  {
    id: 'advisors',
    caption: 'Rådgivare'
  },      
];

const MARKER_SCALES = [...Array(25).keys()].map((x) => (
  {
    key: (50 + 10 * x).toFixed(0),
    text: (50 + 10 * x).toFixed(0) + "%",
    value: (50 + 10 * x) / 100.0,
  }
));

const GREENMASS_INDICES = [
  {
    key: 'ndvi',
    text: 'NDVI',
    value: 'ndvi',
  },
  {
    key: 'msavi',
    text: 'MSAVI',
    value: 'msavi',
  },
];

const SOILMAP_INTERPOLATION = [
  {
    key: 'nn',
    text: 'Närmaste granne',
    value: 'nn',
  },
  {
    key: 'linear',
    text: 'Sammanvägning',
    value: 'linear',
  },
];

const MenuItem = ({ id, title, description, buttonText, onClick, currentValueHelper, children }) => (
  <ModalMenuItem
    key={id}
    title={title}
    description={description}
    buttonValue={currentValueHelper && currentValueHelper(id)}
    buttonOnClick={onClick ? () => onClick(id) : null}
    buttonText={buttonText}
  >
    {children && children}
  </ModalMenuItem> 
);

const INITIAL_STATE = {
  selectedMenuItem: 'user',
  handlingMessagingToken: null,
  removeAdvisorId: null,
  showLoggingOutModal: false,
};
class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  handleItemClick = (e, { name }) => this.setState({ selectedMenuItem: name });
  
  getLoggingOutModal = () => (
    <Modal 
      dimmer='inverted' 
      open={true}
    >
      <Loader 
        size='massive'
      >
        Loggar ut användare...
      </Loader>
    </Modal>
  );


  logoutAllUserSessions = () => {
    const {
      userId, 
    } = this.props;

    this.setState({
      showLoggingOutModal: true,
    });

    cloudFunctions.httpsCallable('logoutAllUserSessions')(
      { userId: userId } 
    ).then(() => {
      this.props.history.push("/");
      this.props.firebase.logout();

      this.setState({
        showLoggingOutModal: false,
      });      
    });
  }

  getCurrentDbValue = (id, enforceBoolean=true) => {
    const db = this.getDb();

    let category = id.split("/")[0];
    let value = id.split("/")[1];

    let newValue = category && value && db && db.settings && db.settings[category] && db.settings[category][value];

    if (enforceBoolean) {
      return typeof(newValue) === "boolean" ? newValue : true;
    } else {
      return newValue;
    }
  };

  onClickSubMenuItem = (id) => {
    let category = id.split("/")[0];
    let value = id.split("/")[1];

    let currentValue = this.getCurrentDbValue(id);

    category && value && this.getUser().collection("settings").doc(category)
      .set({ [value]: !currentValue }, { merge: true })
  };

  onChangeDropdown = (data, key) => {
    let newValue = data.value;
    this.getUser().collection("settings").doc("view")
    .set({ [key]: newValue }, { merge: true });
  };

  getDataSettings = () => {
    return (
      <React.Fragment>
        <MenuItem
          id="view/greenmass_index"
          title="Index för grönmassa"
          description="Välj det index som vill du använda för grönmassakartor. NDVI är det mest vanligt använda indexet och fungerar bra när grödan täcker marken. MSAVI är en förbättring som fungerar även tidigt under säsongen när grödan inte ännu har kommit upp helt och en del av marken inte är täckt."
        >
          <Select
            style={{
              marginTop: 8,
            }}          
            value={this.getCurrentDbValue("view/greenmass_index", false) ? this.getCurrentDbValue("view/greenmass_index", false) : 'msavi'}
            onChange={(e, d) => this.onChangeDropdown(d, 'greenmass_index')}
            options={GREENMASS_INDICES}
          />
        </MenuItem>

        <MenuItem
          id="view/soilmap_interpolation"
          title="Interpolering av markkarteringar"
          description="Välj vilket sätt som du vill att markkarteringen ska beräknas. Freja beräknar troliga värden mellan jordproverna med hjälp av interpolation. Närmaste granne innebär att Freja tar värdet som ligger närmast den nya punkten. Sammanvägning innebär att Freja väger samman flera omkringliggande värden."
        >
          <Select
            style={{
              marginTop: 8,
            }}          
            value={this.getCurrentDbValue("view/soilmap_interpolation", false) ? this.getCurrentDbValue("view/soilmap_interpolation", false) : 'linear'}
            onChange={(e, d) => this.onChangeDropdown(d, 'soilmap_interpolation')}
            options={SOILMAP_INTERPOLATION}
          />          
        </MenuItem>        
      </React.Fragment>
  )};

  getMapSettings = () => {
    return (
      <React.Fragment>
        <MenuItem
          id="view/show_areal_map"
          title="Visa flygbild"
          description="Vill du att en flygbild ska visas på kartan?"
          onClick={this.onClickSubMenuItem}
          currentValueHelper={this.getCurrentDbValue}   
        /> 

        <MenuItem
          title="Markörstorlek"
          description="Här kan du skala storleken på markörerna/pluttarna på kartan."
        >
          <Select
            style={{
              marginTop: 5,
            }}          
            value={this.getCurrentDbValue("view/marker_scale", false) ? this.getCurrentDbValue("view/marker_scale", false) : 1.0}
            onChange={(e, d) => this.onChangeDropdown(d, 'marker_scale')}
            options={MARKER_SCALES}
          />          
        </MenuItem>

        <MenuItem
          id="view/show_missing_data"
          title="Visa saknad data"
          description="Vill du att saknad data ska markeras med bleka punkter på kartan? Om denna funktion inte är aktiverad så kommer Freja ta bort punkter utan data från kartan."
          onClick={this.onClickSubMenuItem}
          currentValueHelper={this.getCurrentDbValue}          
        />
      </React.Fragment>
  )};

  getUserSettings = () => {
    const db = this.getDb();

    let isDeveloperPlan = db && 
      db.profile && 
      db.profile.plan &&
      db.profile.plan.plan_type &&
      db.profile.plan.plan_type === "developer";

    let planName = db && 
      db.profile && 
      db.profile.plan &&
      db.profile.plan.plan_name ?
      db.profile.plan.plan_name : "okänt paket"

    let validTo = db && 
      db.profile && 
      db.profile.plan &&
      db.profile.plan.plan_valid_to ?
      moment(db.profile.plan.plan_valid_to.toDate()).format("YYYY-MM-DD") : "okänt datum";

    return (
      <React.Fragment>
        <ModalMenuItem
          title="Moduler"
          description={"Ditt konto på Freja har tillgång till alla moduler som tillhör paketet: " + planName + " och är giltigt till och med: " + validTo + "."}
        />

        <MenuItem
          id="view/beta_mode"
          title="Betafunktioner"
          description="Visa funktioner som är under utveckling. Använd dessa med försiktighet."
          onClick={this.onClickSubMenuItem}
          currentValueHelper={this.getCurrentDbValue}             
        />

        {isDeveloperPlan &&
          <MenuItem
            id="view/developer_mode"
            title="Utvecklarfunktioner"
            description="Visa funktioner för felsökning."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          /> 
        }

        <ModalMenuItem
          key="logoutAllSessions"
          title="Logga ut från alla enheter"
          description="Om du misstänker att någon obehörig har fått tillgång till ditt konto kan du logga ut dig från alla enheter. Glöm inte bort att även byta ditt lösenord."
          buttonValue={true}
          buttonOnClick={this.logoutAllUserSessions}
          buttonText="Logga ut"
        />

        <ModalMenuItem
          title="Appversion"
          description={"Huvudversion: " + MAIN_APP_VERSION + ". Byggdatum: " + BUILD_DATE + "."}
        />        
      </React.Fragment>
  )};

  getNotificationSettings = () => {

    return (
      <React.Fragment>
        <MenuItem 
          id="notifications/show_new_data"
          title="Hämtad data"
          description="Visa notiser om ny data på mobilen."
          onClick={this.onClickSubMenuItem}
          currentValueHelper={this.getCurrentDbValue}
        />

        <MenuItem 
          id="notifications/show_guides"
          title="Guider"
          description="Visa notiser om aktiviteter och påminnelser kopplade guider på mobilen."
          onClick={this.onClickSubMenuItem}
          currentValueHelper={this.getCurrentDbValue}
        />  

        <MenuItem 
          id="notifications/show_reminders"
          title="Aktiviteter"
          description="Påminn om aktiviteter som planerade idag som en notis på mobilen."
          onClick={this.onClickSubMenuItem}
          currentValueHelper={this.getCurrentDbValue}
        />        

        <MenuItem 
          id="notifications/show_messages"
          title="Meddelanden"
          description="Visa nya meddelanden från din rådgivare som en notis på mobilen."
          onClick={this.onClickSubMenuItem}
          currentValueHelper={this.getCurrentDbValue}
        />            

        <MenuItem 
          id="notifications/show_comments"
          title="Kommentarer"
          description="Visa nya kommentarer från din rådgivare som en notis på mobilen."
          onClick={this.onClickSubMenuItem}
          currentValueHelper={this.getCurrentDbValue}
        />

        <MenuItem 
          id="notifications/show_warnings"
          title="Varningar"
          description="Visa notiser om varningar på mobilen."
          onClick={this.onClickSubMenuItem}
          currentValueHelper={this.getCurrentDbValue}
        />
      </React.Fragment>
    )
  };

  removeAdvisor = () => {
    const {
      removeAdvisorId
    } = this.state;

    const dbRef = this.getUser();
    let dbAdvisors = dbRef.collection("settings").doc("advisors");
    
    dbAdvisors
      .update({ [removeAdvisorId]: this.props.firebase.firestore.FieldValue.delete() })
      .then((x) => this.setState({ removeAdvisorId: null }))
      .catch((x) => this.setState({ removeAdvisorId: null }));
  };

  getAdvisorsSettings = () => {
    const db = this.getDb();
    let dbAdvisors = db && db.settings && db.settings.advisors;

    return (
      <React.Fragment>
        {(!dbAdvisors || Object.keys(dbAdvisors).length < 1) &&
          <ModalMenuItem
            title="Rådgivare"
            description={"Just nu har du ingen rådgivare kopplat till ditt konto på Freja."}
          />
        }

        {dbAdvisors && Object.keys(dbAdvisors).map((advisorId) => {

          let description = dbAdvisors[advisorId] && dbAdvisors[advisorId].name;
          description = description + " har tillgång till din gård och kan göra förändringar i dina inställningar, ladda upp data, förändra insikter och skapa styrfiler." 
          description = dbAdvisors[advisorId].date_added ? description + " Ditt konto kopplades ihop med din rådgivare: " + moment(dbAdvisors[advisorId].date_added.toDate()).format("YYYY-MM-DD") + "." : description;

          return (
            <ModalMenuItem
              title="Rådgivare"
              description={description}
              buttonValue={false}
              buttonOnClick={() => this.setState({removeAdvisorId: advisorId})}
              buttonText="Koppla ifrån"
            />
          )
        })}        
      </React.Fragment>
  )};
  
  getIntegrationSettings = () => {
    // const db = this.getDb();
    // let dbAgriRouter = db && db.settings && db.settings.agrirouter;

    return (
      <React.Fragment>
        <ModalMenuItem
          title="AgriRouter"
          description={"Inom kort kan du koppla samman Freja med AgriRouter för att enkelt kunna överföra styrfiler och skördekartor."}
        />     
      </React.Fragment>
  )};  

  render() {
    const {
      removeAdvisorId,
      selectedMenuItem,
      showLoggingOutModal,
    } = this.state;

    return (
      <React.Fragment>
        {showLoggingOutModal && this.getLoggingOutModal()}
        
        <Confirm
          open={removeAdvisorId}
          header="Koppla ifrån rådgivaren?"
          content="Om du kopplar ifrån din rådgivare kommer han/hon inte längre ha tillgång till din gård. Denna handling går inte att ångra."
          confirmButton="Koppla ifrån"
          cancelButton="Avbryt"
          onConfirm={() => this.removeAdvisor()}
          onCancel={() => this.setState({ removeAdvisorId: null })}
          size="tiny"
        />        
      
        <Modal 
          open={this.props.open && !removeAdvisorId}
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            Inställningar
          </Modal.Header>
          <Modal.Content
            style={{
              height: "50vh",
              display: "flex",
            }}          
          >
            <Menu 
              vertical 
              tabular
              style={{
                width: "25%",
                height: "100%",
              }}
            >
              {settingsList && Object.keys(settingsList).map((x) => (
                <Menu.Item
                  name={settingsList[x].id}
                  key={'menuItem_' + settingsList[x].id}
                  active={selectedMenuItem === settingsList[x].id}
                  onClick={this.handleItemClick}
                >
                  {settingsList[x].caption}
                </Menu.Item>
              ))}                     
            </Menu>

            <div
              style={{
                width: "75%",
                height: "100%",
                overflowY: "auto",
                marginLeft: 10,
                paddingRight: 10,
              }}           
            >
              {selectedMenuItem === "map" && this.getMapSettings()}
              {selectedMenuItem === "data" && this.getDataSettings()}
              {selectedMenuItem === "user" && this.getUserSettings()}
              {selectedMenuItem === "notifications" && this.getNotificationSettings()}
              {selectedMenuItem === "integrations" && this.getIntegrationSettings()}
              {selectedMenuItem === "advisors" && this.getAdvisorsSettings()}
            </div>
          </Modal.Content>

          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}            
          >
            <div></div>
            <Button 
              style={{ 
                backgroundColor: "#6B9D9F", 
                color: "white",
              }}
              onClick={() => this.props.onClose()}
            >
              Stäng
            </Button>
          </Modal.Actions>     
        </Modal>
      </React.Fragment>
    );
  }
};

const withFirebaseData = connect(store => {
  return {
    firebaseData: store.firebase
  };
});

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'profile'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "settings" },
      ],
      storeAs: 'settings'
    },
  ]),
  withRouter,
  withFirebase,
  withFirestoreActions,
  withFirebaseData,
  withFirestoreData,  
)(SettingsPage);
