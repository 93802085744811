import React, { Component } from "react";

import { Popup } from "semantic-ui-react";
import { Line } from "react-chartjs-2";
import moment from 'moment';

import sunandcloud from '@/assets/icons/weather/sunandcloud.png';
import cloud from '@/assets/icons/weather/cloud.png';
import cloudrain from '@/assets/icons/weather/cloudrain.png';
import heavyrain from '@/assets/icons/weather/heavyrain.png';
import snow from '@/assets/icons/weather/snow.png';
import sun from '@/assets/icons/weather/sun.png';
import bolt from '@/assets/icons/weather/bolt.png';


const INITIAL_STATE = {};

class ForecastDataHorizontal extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    render() {
        const {
            db,
            forecastOffset
        } = this.props;


        const symbolToPicture = {
            1: sun,
            2: sun,
            3: cloud,
            4: cloud,
            5: cloud,
            6: cloud,
            7: cloud,
            8: sunandcloud,
            9: cloudrain,
            10: heavyrain,
            11: bolt,
            12: cloud,
            13: cloudrain,
            14: heavyrain,
            15: snow,
            16: snow,
            17: snow,
            18: cloudrain,
            19: cloudrain,
            20: heavyrain,
            21: bolt,
            22: cloudrain,
            23: snow,
            24: snow,
            25: snow,
            26: snow,
            27: snow
        }

        const dayIntToString = {
            0: "Söndag",
            1: "Måndag",
            2: "Tisdag",
            3: "Onsdag",
            4: "Torsdag",
            5: "Fredag",
            6: "Lördag"
        }

        const monthToString = {
            1: "jan",
            2: "feb",
            3: "mar",
            4: "apr",
            5: "maj",
            6: "jun",
            7: "jul",
            8: "aug",
            9: "sep",
            10: "okt",
            11: "nov",
            12: "dec"
        }

        const dayIntToRelativeString = {
            0: "Idag",
            1: "Imorgon"
        }
        let forecastDay;

        if (this.props.relativeDate && forecastOffset < 2) {
            forecastDay = dayIntToRelativeString[forecastOffset]
        } else {
            const forecastDateRaw = moment().add(forecastOffset, 'days')
            forecastDay = dayIntToString[forecastDateRaw.day()]
        }
        const rawDate = moment().add(forecastOffset, 'days')
        const forecastDate = rawDate.format('YYYY-MM-DD')

        const forecastDateString = rawDate.format("DD") + " " + monthToString[rawDate.format('M')]

        let forecastData =
            db &&
            db.fieldsWeather &&
            db.fieldsWeather &&
            db.fieldsWeather.forecasts &&
            db.fieldsWeather.forecasts[forecastDate];

        let totalPrec =
            forecastData &&
            forecastData.rain

        let maxTemp =
            forecastData &&
            forecastData.max_temp

        let minTemp =
            forecastData &&
            forecastData.min_temp

        let weatherSymbol =
            forecastData &&
            forecastData.symbol

        let weatherIcon = symbolToPicture[weatherSymbol]
        
        let forecastTempData =
            forecastData &&
            forecastData["interpolated_forecast_temps"]

        var items = forecastTempData && Object.keys(forecastTempData).map(function (date) {
            return [date, forecastTempData[date]];
        });
        if (forecastTempData) items.sort((a, b) => new Date(a[0]) - new Date(b[0]))
        let forecastTemps = items && items.map(entry => entry[1])
        let forecastTempDates = items && items.map(entry => moment(entry[0]).format("HH:00"))

        let plotOptions = {
            maintainAspectRatio: false,
            animation: {
                animation: true,
                animationEasing: 'easeInOutQuart',
                duration: 1000
            },
            tooltips: {
                mode: "nearest",
                intersect: false,
                titleFontStyle: "normal",
                bodyFontStyle: "bold",
                callbacks: {
                    label: function (tooltipItem, data) {
                        return Math.round(tooltipItem.yLabel) + "°C";
                    }
                },
                backgroundColor: 'rgba(141,141,141,0.9)',
                displayColors: false
            },
            legend: {
                display: false,
            },
            scales: {
                yAxes: [{
                    display: false
                },
                ],
                xAxes: [{
                    display: false
                }],
            },
            responsive: true
        };


        let plotData = {
            labels: forecastTempDates,
            datasets: [
                {
                    label: "Temperatur (grader)",
                    data: forecastTemps,
                    fill: false,
                    borderColor: "#D6946B",
                    pointRadius: 0
                },
            ]
        };

        if (forecastData) {
            return (
                <div style={{
                    fontWeight: 600,
                    fontSize: "80%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "1em",
                    marginRight: "1em",
                    alignItems: "center",
                    width: "100%",
                    height: "5em",
                }}>
                    <div style={{ width: "20%" }}>
                        <div>{forecastDay}</div>
                        <div
                            style={{
                                fontWeight: "normal",
                                color: "grey"
                            }}
                        >
                            {forecastDateString}
                        </div>
                    </div>

                    <div style={{ width: "20%" }}>
                        <Popup
                            trigger=
                            {<img src={weatherIcon} alt="weather" style={{
                                marginLeft: "2em", width: "2.3em", height: "auto", filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)"
                            }} />}
                            content={symboltextSWE[weatherSymbol]}
                            basic
                        />
                    </div>

                    <div style={{ width: "20%" }}>
                        <div
                            style={{
                                fontWeight: "heavy",
                                color: "grey",
                            }}
                        >
                            {maxTemp && Math.round(maxTemp)}°C
                                </div>
                        <div
                            style={{
                                fontWeight: "normal",
                                color: "grey",
                            }}
                        >
                            {minTemp && Math.round(minTemp)}°C
                                </div>



                    </div>

                    <div
                        style={{
                            fontWeight: "normal",
                            color: "grey",
                            width: "20%"
                        }}
                    >
                        {totalPrec && Math.round(totalPrec)} mm
                </div>

                    <div
                        style={{
                            width: "20%",
                            height: "75%",
                            marginTop: "auto",
                            marginBottom: "auto",
                        }}
                    >
                        <Line
                            data={plotData}
                            options={plotOptions}
                        />
                    </div>
                </div >
            )
        }
        else {
            return (null)
        }
    }

}

/* 
const symboltextENG = {
    1: "Clear sky",
    2: "Nearly clear sky",
    3: "Variable cloudiness",
    4: "Halfclear sky",
    5: "Cloudy sky",
    6: "Overcast",
    7: "Fog",
    8: "Light rain showers",
    10: "Heavy rain showers",
    9: "Moderate rain showers",
    11: "Thunderstorm",
    12: "Light sleet showers",
    13: "Moderate sleet showers",
    14: "Heavy sleet showers",
    15: "Light snow showers",
    16: "Moderate snow showers",
    17: "Heavy snow showers",
    18: "Light rain",
    19: "Moderate rain",
    20: "Heavy rain",
    21: "Thunder",
    22: "Light sleet",
    23: "Moderate sleet",
    24: "Heavy sleet",
    25: "Light snowfall",
    26: "Moderate snowfall",
    27: "Heavy snowfall"
} */

const symboltextSWE = {
    1: "Klart",
    2: "Lätt molnighet",
    3: "Halvklart",
    4: "Molningt",
    5: "Mycket moln",
    6: "Mulet",
    7: "Dimma",
    8: "Lätt regnskur",
    10: "Regnskur",
    9: "Kraftig regnskur",
    11: "Åskskur",
    12: "Lätt by av regn och snö",
    13: "By av regn och snö",
    14: "Kraftig by av regn och snö",
    15: "Lätt snöby",
    16: "Snöby",
    17: "Kraftig snöby",
    18: "Lätt regn",
    19: "Regn",
    20: "Kraftigt regn",
    21: "Åska",
    22: "Lätt snöblandat regn",
    23: "Snöblandat regn",
    24: "Kraftigt snöblandat regn",
    25: "Lätt snöfall",
    26: "Snöfall",
    27: "Ymnigt snöfall"
}

export default ForecastDataHorizontal;
