import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import "./style.css";

import {
  firestoreConnect,
} from "react-redux-firebase";

import { 
  Button,
  Modal,
  Image,
  Header,
} from "semantic-ui-react";

import { toast } from "react-toastify";

import { cloudFunctions } from "@";

import { getSafeFileName } from "@/helpers/dataHandling";

import MenuGuide from "@/components/guide/GuideMenu";

import UploadSoilMapGuideUploadFiles from "@/components/guides/uploadSoilMaps/uploadFiles";
import UploadSoilMapGuideHandleFiles from "@/components/guides/uploadSoilMaps/handleFiles";
import UploadSoilMapGuideVerifyFiles from "@/components/guides/uploadSoilMaps/verifyFiles";

import illustrationDone from "@/assets/illustrations/done.png";
import illustrationError from "@/assets/illustrations/error.png";
class UploadSoilMaps extends Component {
  state = {
    pickedFiles: null,
    pickedFilesUploadProgress: {},
    pickedFilesUploadErrors: [],
    uploading: false,
    showMarkGuideCompleteModal: false,
    showNoNewFilesModal: false,
    skipGettingStarted: false,
    filesUnderProcessing: [],
    filesToRemove: [],
    collectDataError: null,
  };

  getDb = () => this.props.firestoreData;
  getUser = () =>
    this.props.firestore.collection("users").doc(this.props.userId);

  componentDidMount = () => {
    const { 
      match: {
        params: {
          layer,
        }
      },
    } = this.props;

    if (this.props.history.location.state) {
      this.setState(this.props.history.location.state);

      if ((!layer || layer === "gettingStarted") && 
        this.props.history && 
        this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.skipGettingStarted) {
        this.onClickGoToStep1();
        
        this.props.history.location.state &&
        this.props.history.location.state.skipGettingStarted &&
        this.setState({
          skipGettingStarted: this.props.history.location.state.skipGettingStarted
        });
      };
    };
  };

  componentDidUpdate = (prevProp) => {
    const { 
      match: {
        params: {
          layer,
        }
      },
    } = this.props;

    if (prevProp.history.location.state !== this.props.history.location.state) {
      this.setState(this.props.history.location.state);

      if ((!layer || layer === "gettingStarted") && 
        this.props.history && 
        this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.skipGettingStarted) {
        this.onClickGoToStep1();
        
        this.props.history.location.state &&
        this.props.history.location.state.skipGettingStarted &&
        this.setState({
          skipGettingStarted: this.props.history.location.state.skipGettingStarted
        });
      };
    };     
  };
  
  onSaveState = (newGuideId) => {
    const {
      userId,
    } = this.props;

    let guideId = newGuideId ? newGuideId : this.props.match.params.guideId;

    const fileBucketRef = this.props.firebase.storage().ref();
    const dbRef = this.getUser();

    const docRef = dbRef.collection("guides").doc(guideId);

    const fileName = guideId + "_current_state.json";
    const key = "users/" + userId + "/guides/" + guideId + "/" + fileName;
    
    const fileRef = fileBucketRef.child(key);
    guideId && fileRef.putString(JSON.stringify(this.state))
      .then((x) => {
        docRef.update({
          current_step_state_file_path: key,
        });
      })
  };

  onUploadFiles = (pickedFiles, dataType) => {
    if (pickedFiles.length === 0) {
      this.setState({ collectDataError: "Inga filer med rätt filformat hittades." });
      return;
    } else {
      this.setState({ collectDataError: null });
    };

    this.setState({
        pickedFiles: pickedFiles,
    }); 

    this.props.history.push("/guidesUploadSoilMaps/handleFiles");
  };  

  handleError = (error) => {
    console.log(error);
    let trucatedError = error.toString().substring(0, 120);

    toast.error("Något gick tyvärr fel. Prova gärna igen och hör av dig till support@agriopt.se om det inte funkar då. Felmeddelande: " + trucatedError + ".",
    {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 15000,
      hideProgressBar: false,
    });

    this.setState({
      uploading: false,
    })
  };

  uploadTaskPromise = async (file) => {
    const {
      userId,
    } = this.props;

    return new Promise((resolve, reject) => {
      const fileBucketRef = this.props.firebase.storage().ref();

      let safeFileName = getSafeFileName(file.name);
      const key = "soilMaps/" + userId + "/" + Date.now() + "_" + safeFileName;
      const fileName = Date.now() + "_" + safeFileName;

      const fileRef = fileBucketRef.child(key);

      const uploadTask = fileRef.put(file)
      uploadTask.on('state_changed',
        (snapshot) => {
          let progress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          this.setState({ ["progressUpload_" + file.name]: progress });
        },
        (error) => {
          this.handleError(error);
          this.setState({ pickedFilesUploadErrors: [...this.state.pickedFilesUploadErrors, file.name] });
          reject()
        },
         () => {
          resolve({
            processed: false,
            status: 'in_queue',
            original_file_name: file.name,
            file_name: fileName,
            file_path: key,
            date_uploaded: new Date(),
          })
        })
    })
  };

  uploadFiles = async (uploadAgain) => {
    this.setState({
      uploading: true,
    });

    const {
      userId,
    } = this.props;

    const { 
      pickedFiles, 
    } = this.state;
    
    const db = this.getDb();    
    let uploadedSoilMaps = db && db.fieldsSoilMapsUploaded;

    let pickedFilesArray = pickedFiles && Array.from(pickedFiles);
    pickedFilesArray = pickedFilesArray && pickedFilesArray.filter((file) => {
      if (uploadedSoilMaps && !!Object.keys(uploadedSoilMaps).find(x => uploadedSoilMaps[x].original_file_name === file.name)) {
        if (!uploadAgain.includes(file.name)) {
          return false
        }
      }

      return true
    });

    (pickedFilesArray && pickedFilesArray.length === 0) && this.setState({ showNoNewFilesModal: true });

    const fileUploadPromises = pickedFilesArray && pickedFilesArray.map(file => this.uploadTaskPromise(file))
    let dataArrays = await Promise.all(fileUploadPromises);

    const dbUploadPromises = dataArrays && dataArrays.map((x) => this.getUser().collection("fieldsSoilMapsUploaded").add(x).then((x) => (x.id)));
    
    let entryNumbers = pickedFilesArray && pickedFilesArray.map((file) => file.name);
    let uploadedFileIds = await Promise.all(dbUploadPromises);
    
    uploadedFileIds.length > 0 && this.getUser().collection("guides").add({
      type: 'guidesUploadSoilMaps',
      guide_type: 'uploadSoilMaps',
      field_id: '',
      date_created: new Date(),
      date_latest_update: new Date(),
      complete: false,
      current_step: 'verifyFiles',
      files_uploaded: entryNumbers,
      files_uploaded_ids: uploadedFileIds,
    })
    .then((x) => {
      this.setState({ 
        uploading: false, 
      });
      
      this.onSaveState(x.id);

      toast.success("Markkarteringarna har laddats upp och bearbetas nu av Freja.",
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
      });

      cloudFunctions.httpsCallable('callDatahubMaps')(
        {
          userId: userId,
          guideId: x.id,
          endpoint: "/v1/parseUploadedSoilMap",
        }
      );

      this.props.history.replace("/guidesUploadSoilMaps/verifyFiles/" + x.id)
    });
  };  

  onProcessFileAgain = (fileId, fieldsInFile, fileYear) => {
    const {
      filesUnderProcessing
    } = this.state;

    const {
      userId,
      match: {
        params: {
          guideId,
        }
      }
    } = this.props;

    this.getUser().collection("fieldsSoilMapsUploaded").doc(fileId).update({
      processed: false,
      status: 'in_queue',
      fields_in_file: fieldsInFile,
      year: fileYear,
    });

    cloudFunctions.httpsCallable('callDatahubMaps')(
      {
        userId: userId,
        guideId: guideId,
        uploadedFileId: fileId,
        endpoint: "/v1/parseUploadedSoilMap",
      }
    );

    this.setState({ filesUnderProcessing: [...filesUnderProcessing, fileId] });
  };

  gotoGettingStarted = () => {
    this.props.history.push("/guidesUploadSoilMaps/gettingStarted");
  };

  onClickGoToStep1 = () => {
    this.props.history.push("/guidesUploadSoilMaps/uploadFiles");
  };

  onGuideDone = () => {
    const {
      match: {
        params: {
          guideId,
        }
      }
    } = this.props;

    let db = this.getDb()

    let guideInfo = guideId && db && db.guides && db.guides[guideId] && db.guides[guideId];
    guideInfo && guideInfo.reminder_id && this.getUser().collection("reminders").doc(guideInfo.reminder_id).delete()
    
    guideInfo && guideInfo.files_uploaded_ids && guideInfo.files_uploaded_ids.forEach((id) => {
      let uploadedFileInfo = id && db && db.fieldsSoilMapsUploaded[id] && db.fieldsSoilMapsUploaded[id];

      if (uploadedFileInfo && ["error", "need_more_info"].includes(uploadedFileInfo)) {
        this.getUser().collection("fieldsSoilMapsUploaded").doc(id).delete()
      }
    })

    this.getUser().collection("guides").doc(guideId).update({
      complete: true,
      date_latest_update: new Date(),
    });
    
    this.props.history.push("/collect");
  };

  onCloseGuide = () => {
    this.props.history.push("/collect");
  };  

  onHideSkipGettingStarted = () => {
    let db = this.getDb();
    let currentSettings = db && db.settings && db.settings.guides && db.settings.guides;
    let dbRefSettings = this.getUser().collection("settings").doc("guides");

    let currentSettingsValue = currentSettings && currentSettings['uploadSoilMaps'];
    
    if (currentSettings) {
      dbRefSettings.update({ ...currentSettings, 
        uploadSoilMaps: { ...currentSettingsValue,
          skip_get_started_announce: true,
        }
      }); 
    } else {
      dbRefSettings.set({ ...currentSettings, 
        uploadSoilMaps: { ...currentSettingsValue,
          skip_get_started_announce: true,
        }
      }); 
    };
  };

  toggleHelpTexts = () => {
    let db = this.getDb();
    let currentSettings = db && db.settings && db.settings.guides && db.settings.guides;
    let dbRefSettings = this.getUser().collection("settings").doc("guides");

    let currentSettingsValue = currentSettings && currentSettings['uploadSoilMaps'];

    let newSettingsSubValue = currentSettingsValue && 
      typeof(currentSettingsValue['hide_help_text_col']) === "boolean" ? 
      !currentSettingsValue['hide_help_text_col'] : true;
    
    if (currentSettings) {
      dbRefSettings.update({ ...currentSettings, 
        uploadSoilMaps: { ...currentSettingsValue,
          hide_help_text_col: newSettingsSubValue,
        }
      }); 
    } else {
      dbRefSettings.set({ ...currentSettings, 
        uploadSoilMaps: { ...currentSettingsValue,
          hide_help_text_col: newSettingsSubValue,
        }
      }); 
    };
  };

  onRemoveUploadedFile = (fileId) => {
    const {
      match: {
        params: {
          guideId,
        }
      }
    } = this.props;

    const fileBucketRef = this.props.firebase.storage().ref();

    let currentValue = this.state.filesToRemove;
    this.setState({ filesToRemove: [...currentValue, fileId] });

    // Get current file information
    const db = this.getDb();
    let uploadedSoilMaps = db && db.fieldsSoilMapsUploaded;
    let filePath = uploadedSoilMaps && uploadedSoilMaps[fileId] && uploadedSoilMaps[fileId].file_path;    
    let fileName = uploadedSoilMaps && uploadedSoilMaps[fileId] && uploadedSoilMaps[fileId].file_name;  

    // Remove file from storage
    const fileRef = filePath && fileBucketRef.child(filePath);
    filePath && fileRef.delete(filePath).catch(error => {this.handleError(error)});

    // Remove file information in database
    this.getUser().collection("fieldsSoilMapsUploaded").doc(fileId).delete();

    // Remove the file from the current guide
    let filesUploaded = guideId && db && db.guides && db.guides[guideId] && db.guides[guideId].files_uploaded;
    let filesUploadedIds = guideId && db && db.guides && db.guides[guideId] && db.guides[guideId].files_uploaded_ids;

    filesUploaded = filesUploaded ? filesUploaded.filter((x) => x !== fileName) : filesUploaded;
    filesUploadedIds = filesUploadedIds ? filesUploadedIds.filter((x) => x !== fileId) : filesUploadedIds;

    this.getUser().collection("guides").doc(guideId)
      .update({
        files_uploaded: filesUploaded,
        files_uploaded_ids: filesUploadedIds,
      });
  };

  getMarkGuideCompleteModal = (open) => (
    <Modal
      open={open}
    >
      <Modal.Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "2em"
          }}
        >
          <Image
            src={illustrationDone}
            size="medium"
          />

          <div 
            style={{
              marginTop: "2em",
              marginLeft: "4em",
            }}
          >
            <Header as="h3">Vill du avsluta guiden?</Header>

            <p>
              Allt verkar vara klart med de filerna som du har laddat upp med denna guide. 
              Om du känner dig nöjd kan du välja "Avsluta guiden" nedan.
              Då tar vi bort de filerna som inte kunde läsas in eller kopplas till ett skifte och städar upp lite annat smått och gott.
            </p>

            <p>
              Om du har fyllt i mer information och vill invänta hur det blev eller om du av någon annan anledning vill spara guiden väljer du "Stäng utan att avsluta".
            </p>
          </div>

        </div>
      </Modal.Content>

      <Modal.Actions
        style={{ 
          display: "flex",
          justifyContent: "space-between"
        }}               
      >
        <Button 
          style={{
            backgroundColor: "#868D8E",
            color: "white",
          }}
          onClick={() => this.props.history.push("/collect")}
        >
          Stäng utan att avsluta
        </Button>

        <Button 
          style={{
            backgroundColor: "#6B9D9F",
            color: "white",
          }}
          onClick={() => this.onGuideDone()}
        >
          Avsluta guiden
        </Button>
      </Modal.Actions>
    </Modal>  
  )

  getNoNewFilesModal  = (open) => (
    <Modal
      open={open}
    >
      <Modal.Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "2em"
          }}
        >
          <Image
            src={illustrationError}
            size="medium"
          />

          <div 
            style={{
              marginTop: "2em",
              marginLeft: "4em",
            }}
          >
            <Header as="h3">Inga nya markkarteringar hittades</Header>

            <p>
              Freja hittade inga nya markkarteringar att ladda upp. 
              Kanske hade du redan laddat upp dessa filer sedan tidigare?
            </p>
          </div>

        </div>
      </Modal.Content>

      <Modal.Actions
        style={{ 
          display: "flex",
          justifyContent: "space-between"
        }}               
      >
        <Button 
          style={{
            backgroundColor: "#868D8E",
            color: "white",
          }}
          onClick={() => this.onCloseGuide()}
        >
          Stäng
        </Button>

        <Button 
          style={{
            backgroundColor: "#6B9D9F",
            color: "white",
          }}
          onClick={() => this.onClickGoToStep1()}
        >
          Ladda upp fler
        </Button>
      </Modal.Actions>
    </Modal>      
  );

  render() {
    const {
      match: {
        params: {
          layer,
          guideId,
        }
      },
    } = this.props;

    const { 
      filesToRemove, 
      uploading,
      pickedFiles,
      pickedFilesUploadErrors,
      pickedFilesUploadProgress,
      skipGettingStarted,
      showMarkGuideCompleteModal,
      showNoNewFilesModal,
      collectDataError,
    } = this.state;

    let db = this.getDb();

    let currentSettings = db && db.settings && db.settings.guides && db.settings.guides;
    let currentSettingsValue = currentSettings && currentSettings['uploadSoilMaps'];
    
    let showSkipGettingStarted = currentSettingsValue && 
      typeof(currentSettingsValue['skip_get_started_announce']) === "boolean" ? 
      !currentSettingsValue['skip_get_started_announce'] : true;
    
    let showFullGrid = currentSettingsValue && 
      typeof(currentSettingsValue['hide_help_text_col']) === "boolean" ? 
      !currentSettingsValue['hide_help_text_col'] : true;

    let menuItems = [
      {
        name: "uploadFiles",
        href: "",
        onClick : () => {},
        disabled: true,
        caption: "Välj filer",
        active: layer === "uploadFiles",
      },
      {
        name: "handleFiles",
        href: "",
        onClick : () => {},
        disabled: true,
        caption: "Ladda upp filer",
        active: layer === "handleFiles",
      },
      {
        name: "verifyFiles",
        href: "",
        onClick : () => {},
        disabled: true,
        caption: "Status och komplettering",
        active: layer === "verifyFiles",
      },      
    ];

    return (
      <React.Fragment>
        <MenuGuide 
          db={db}
          menuItems={menuItems} 
        />

        {this.getMarkGuideCompleteModal(showMarkGuideCompleteModal)}
        {this.getNoNewFilesModal(showNoNewFilesModal)}

        {(!layer || layer === "uploadFiles") &&
          <UploadSoilMapGuideUploadFiles 
            guideId={guideId}
            collectDataError={collectDataError}
            onUploadFiles={this.onUploadFiles}
            gotoGettingStarted={this.gotoGettingStarted}
            skipGettingStarted={skipGettingStarted}
            showSkipGettingStarted={showSkipGettingStarted}
            onHideSkipGettingStarted={this.onHideSkipGettingStarted}
            showFullGrid={showFullGrid}
            toggleHelpTexts={this.toggleHelpTexts}       
          />
        }

        {layer === "handleFiles" &&
          <UploadSoilMapGuideHandleFiles 
            {...this.state}
            db={db}
            userId={this.props.userId}
            guideId={guideId}
            pickedFiles={pickedFiles}
            pickedFilesUploadErrors={pickedFilesUploadErrors}
            pickedFilesUploadProgress={pickedFilesUploadProgress}
            uploading={uploading}
            onClickGoToStep1={this.onClickGoToStep1}
            uploadFiles={this.uploadFiles}
            showFullGrid={showFullGrid}
            toggleHelpTexts={this.toggleHelpTexts}       
          />
        }    

        {layer === "verifyFiles" &&
          <UploadSoilMapGuideVerifyFiles 
            db={db}
            userId={this.props.userId}
            guideId={guideId}
            filesToRemove={filesToRemove}
            onGuideDone={() => this.setState({ showMarkGuideCompleteModal: true })}
            onCloseGuide={this.onCloseGuide}
            showFullGrid={showFullGrid}
            toggleHelpTexts={this.toggleHelpTexts}    
            onProcessFileAgain={this.onProcessFileAgain} 
            onRemoveUploadedFile={this.onRemoveUploadedFile} 
          />
        }    

      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(props => [
    {
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fields'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsInfo" },
      ],
      storeAs: 'fieldsInfo'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "guides" },
      ],
      storeAs: 'guides'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsSoilMapsUploaded" },
      ],
      storeAs: 'fieldsSoilMapsUploaded'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsSoilMapsRaw" },
      ],
      storeAs: 'fieldsSoilMapsRaw'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsSoilMapsProcessed" },
      ],
      storeAs: 'fieldsSoilMapsProcessed'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "settings" },
      ],
      storeAs: 'settings'
    },
  ]),
  connect(store => {
    return {
      firestoreData: store.firestore.data
    };
  }),
  withRouter,
)(UploadSoilMaps);
