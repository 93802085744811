import React, { Component } from 'react';

import {
  Map as LeafletMap,
  TileLayer,
  Polygon,
  } from 'react-leaflet';

import L from 'leaflet';

import { 
  Header,
  Button,
  Message,
  Icon,
  Modal,
  Segment,
  Loader,
} from 'semantic-ui-react';

import {
  getCenterOfPolygon,
  getBoundingBoxOfPolygon,
 } from '@/helpers/maps';

import FieldCards from "./fieldCards";

import { PLAN_TYPES } from '@/constants/regions/plans';

const INITIAL_STATE = {
  errorMessage: null,
  errorMessageCard: null,
  parsedFile: null,
  hasLocation: false,
  latlng: {
    lat: 58.411,
    lng: 15.621,
  },
  showFieldModal: false,
  openFieldId: null,
  bounds: null,
  fieldNameArray: {}
};

const SMALL_FIELD_SIZE = 2.0;

class HandleFields extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  mapRef = React.createRef();

  openField = (field, idx) => {
    const {
      fields,
    } = this.props;

    let fieldId = field && field.id

    let openFieldData = fields.find((y) => y.id && y.id === fieldId);
    let fieldPosition = openFieldData && openFieldData.position && 
      openFieldData.position.map((x) => [x.lat, x.lng])

    var polygonCenter = fieldPosition && getCenterOfPolygon(fieldPosition);    
    var polygonBoundingBox = fieldPosition && getBoundingBoxOfPolygon(fieldPosition);

    var corner1 = polygonBoundingBox && L.latLng(polygonBoundingBox[1][0], polygonBoundingBox[0][1]);
    var corner2 = polygonBoundingBox && L.latLng(polygonBoundingBox[0][0], polygonBoundingBox[1][1]);

    if (corner1 && corner2 && polygonCenter) {
      this.setState({bounds: L.latLngBounds(corner1, corner2)});
      this.setState({latlng: {lat: polygonCenter[0], lng: polygonCenter[1]}});
    };

    this.setState({
      openFieldId: fieldId,
      showFieldModal: true,
      newFieldName: typeof(idx) === "number" && "Skifte #" + (idx + 1),
    });
  };

  closeField = () => {
    this.setState({
      openFieldId: null,
      showFieldModal: false,
      newFieldName: null,
    });
  };  

  removeField = (fieldId) => {     
    this.props.removeField(fieldId);
  };

  showFieldModal = () => {
    const {
      fields,
      farmPosition,
    } = this.props;

    const {    
      latlng,
      bounds,
      openFieldId,
      showFieldModal,
    } = this.state; 

    let selectedField = fields && fields.filter((x) => x.id === openFieldId);
    selectedField = selectedField && selectedField.length > 0 && selectedField[0];    
    
    return (
      <Modal 
        open={showFieldModal} 
        style={{ 
          height: "calc(50vh + 3em)",
          width: "calc(50vw + 3em)",
        }}        
      >
        <Modal.Content
          style={{ 
            height: "calc(50vh + 3em)",
            width: "calc(50vw + 3em)",
          }}
        >
          <LeafletMap
            center={farmPosition ? farmPosition : latlng}
            zoom={15}
            minZoom={5}
            maxZoom={16}
            style={{
              position: 'fixed',
              height: '50vh',
              width: '50vw',
            }}
            ref={this.mapRef}
            bounds={bounds ? bounds : null}
            boundsOptions={bounds ? {padding: [20, 20]}: null}
            onLocationFound={this.handleLocationFound}
          >
      
            <TileLayer 
              url="https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?app_id=6Cy9N8yG1SZYlO61DQC7&app_code=6yLDAiRRtx2-9-7Wkn4M4g&lg=eng"
            />

            <Polygon
              positions={selectedField.positionHoles ? [[selectedField.position], ...selectedField.positionHoles] : selectedField.position}
              color='rgba(239, 181, 46, 0.9)'
              fillColor={'rgba(239, 181, 46, 0.7)'}
              fillOpacity={1.0}
              weight={3}
            />
          </LeafletMap>
        </Modal.Content>
        <Modal.Actions
          style={{ 
            display: "flex",
            justifyContent: "flex-end"
          }}            
        >
          <Button onClick={() => this.closeField()}>Stäng</Button>
        </Modal.Actions>          
      </Modal>   
    )
  }

  render () {
    const {
      fields,
      fieldSource,
      applicationDate,
      fieldsToAdd,
      fieldIdsAdded,
      existingFieldsSamIds,
      showSmallFields,
      userPlan,
      isParsingFile,
    } = this.props;

    let noFieldsAdded = fieldIdsAdded ? fieldIdsAdded.length : 0;
    let areaAdded = fieldsToAdd && fieldsToAdd.reduce((old, item) => old + parseFloat(item.area), 0.0);

    let maxFieldsLimit = userPlan && PLAN_TYPES.filter(x => x.name === userPlan)[0];
    maxFieldsLimit = maxFieldsLimit && maxFieldsLimit.maxFields ? maxFieldsLimit.maxFields : null;
    
    let canAddField = (maxFieldsLimit && fieldIdsAdded.length >= maxFieldsLimit) ? false : true;

    return (
      <React.Fragment>
        {this.showFieldModal()}

        {fieldSource === "draw" &&
          <React.Fragment>
            <p
              style={{
                width: "80%",
                fontSize: "110%",
                whiteSpace: "pre-line",
              }}
            >
              Du kan byta namn på dina nya skiften nedanför samt lägga till vilken gröda som du odlar under nuvarande säsong.
              Vill du lägger till fler skiften genom att markera skifteskanter kan du klicka på knappen "Lägg till nytt eget skifte" nere till vänster.
            </p>

            <Header
              as="h3"
              style={{
                fontWeight: "normal",
                whiteSpace: "pre-line",
              }}
            >
              Har du lagt till de skiften som du vill ha?
            </Header>

            <p
              style={{
                width: "80%",
                fontSize: "110%",
                whiteSpace: "pre-line",
                marginBottom: "2em",
              }}
            >              
              Då kan du gå vidare till nästa steg genom att klicka på "Klar".
            </p>
          </React.Fragment>
        }

        {fieldSource === "sam" &&
          <React.Fragment>
            <p
              style={{
                width: "80%",
                fontSize: "110%",
                whiteSpace: "pre-line",
              }}
            >
              Freja hittade {fields && fields.length} skiften i den ansökan som du valde eller laddade upp. Denna ansökan har inskickningsdatum: {applicationDate ? " " + applicationDate : " okänt"}. 
            </p>

            <Header
              as="h3"
              style={{
                fontWeight: "normal",
                whiteSpace: "pre-line",
              }}
            >
              Lägg till skiften
            </Header>

            <p
              style={{
                width: "80%",
                fontSize: "110%",
                whiteSpace: "pre-line",
              }}
            >
              För att lägga till ett skifte till Freja klickar du på knappen "Lägg till" och sedan kan du välja ett namn på skiftet och välja vilken gröda som odlas i år. Ångrar du dig kan du ta bort tillagda skiften och du kan även öppna upp en karta för att enklare se var på gården skiftet ligger.
            </p>

            {!showSmallFields &&
              <React.Fragment>
                <Header
                  as="h3"
                  style={{
                    fontWeight: "normal",
                    whiteSpace: "pre-line",
                  }}
                >
                  Skyddzoner och små skiften
                </Header>

                <p
                  style={{
                    width: "80%",
                    fontSize: "110%",
                    whiteSpace: "pre-line",
                    marginBottom: "2em",
                  }}
                >
                  Freja har automatiskt tagit bort alla alla skyddszoner och små skiften (mindre än 2 ha) i listan på skiften i ansökan. 
                  Vill du se dessa skiften och lägga till dem

                  <span 
                    className="textLink" 
                    onClick={() => this.props.toggleShowSmallFields()}
                  > 
                    {" "} klickar du här.
                  </span>
                </p>   
              </React.Fragment>
            }
          </React.Fragment>
        }

        {fields && fields.length > 0 && fields
          .filter((x) => showSmallFields ? true : x.area >= SMALL_FIELD_SIZE)
          .sort((a, b) => b.area - a.area)
          .map((field, idx) => 
            <FieldCards
              key={field.id}
              field={field}
              idx={idx}
              fieldsToAdd={fieldsToAdd}
              fieldIdsAdded={fieldIdsAdded}
              existingFieldsSamIds={existingFieldsSamIds}
              openField={this.openField}
              addField={this.props.addField}
              removeField={this.removeField}
              canAddField={canAddField}
              setParentState={this.props.setParentState}
            /> 
        )}

        {isParsingFile &&
          <Segment 
            placeholder
            style={{
              height: "40vh",
              backgroundColor: "#fafafa",              
              padding: "2em",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",               
            }}
          >
            <Loader
              active
              size="big"
            >
              Freja läser in SAM-ansökan...
            </Loader>      
          </Segment>
        }     

        {(!isParsingFile && (!fields || fields.length === 0)) &&
          <Segment 
            placeholder
            style={{
              height: "40vh",
              backgroundColor: "#fafafa",              
              padding: "2em",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",               
            }}
          >
            <Header 
              icon
              as="h3"
              style={{
                marginTop: "1em",
                fontWeight: "normal",
              }}
            >   
              <Icon name='square outline' style={{ fontSize: "3em" }} />
              Freja hittade inga skiften i den valda/uppladdade SAM-ansökan.
            </Header>

            <Button
              style={{
                marginTop: "1em",
                backgroundColor: "#D6946B",
                color: "white",
              }}
              onClick={() => this.props.onGoBackToUploadSam()}
            >
              Välj en annan ansökan
            </Button>                    
          </Segment>
        }

        {(!isParsingFile && typeof(areaAdded) === "number") &&
          <Message
            style={{
              whiteSpace: "pre-line",
              marginBottom: "2em",
              fontSize: "120%",
            }}
          >
            Du har lagt till skiften {noFieldsAdded.toFixed(0)} med en yta på totalt {areaAdded.toFixed(0)} hektar.
          </Message>
        }

      </React.Fragment>
    );
  }
}

export default HandleFields;
