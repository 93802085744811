import React, { Component } from "react";

import { 
  Button, 
  Form, 
  Header, 
  Segment,
  Image,
  Loader,
} from "semantic-ui-react";

import Background from "@/assets/backgrounds/wheat2.jpg";
import Illustration from "@/assets/illustrations/onboarding_guide_3.png";

import "./style.css";

const INITIAL_STATE = {
  email: "",
  error: null,
  isProcessing: false,
};

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    const { email } = this.state;
    const { firebase } = this.props;

    if ( email === '' ) {
      this.setState({
        error: {message: "Ange en mailadress för att fortsätta."}
      })

      return null;
    }

    this.setState({ isProcessing: true });

    firebase
      .resetPassword(email)
      .then(response => {
        this.setState({ isProcessing: false });
        this.props.onDone();
      })
      .catch(error => {
        this.setState({ error });
        this.setState({ isProcessing: false });
      });

    event.preventDefault();
  };

  render() {
    const { email, error, isProcessing } = this.state;

    let errorMessage = error && error.message;
    errorMessage = errorMessage ? (errorMessage === "There is no user record corresponding to this identifier. The user may have been deleted." ? "Det finns ingen användare registrerad med denna mailadress" : errorMessage ) : errorMessage;

    return (
      <div 
      style={{
        height: "100vh",
        paddingLeft: "20%",
        paddingRight: "20%",
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflow: "hidden"          
      }}
    >
      <div
        style={{
          height: "60%",
          marginTop: "20vh",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
        }}
      >
        <Segment
          style={{
            width: "50%",
            height: "100%",
            padding: "2em",
          }}
        >
          <div
            style={{
              width: "67%",
            }}            
          >
          {!isProcessing &&
            <React.Fragment>
              <Form
                size="large"
                onSubmit={this.onSubmit}
                style={{ 
                  width: "67%",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >

                <Form.Input
                  fluid
                  focus
                  icon="mail"
                  name="email"
                  iconPosition="left"
                  placeholder="Mailadress"
                  value={email}
                  onChange={this.onChange}
                />

                {errorMessage &&
                  <p
                    style={{ 
                      marginTop: "1em",
                      marginBottom: "1em",
                      color: "#D6946B", 
                    }}
                  >
                    {errorMessage}
                  </p>
                }          

                <Button
                  fluid
                  size="large"
                  onClick={this.onSubmit}
                  style={{ 
                    marginTop: "2em", 
                    backgroundColor: "#6B9D9F",
                    color: "white",                  
                  }}
                >
                  Återställ lösenord
                </Button>
              </Form>
            </React.Fragment>
          }

          {isProcessing &&
            <Loader 
              active 
              indeterminate 
              size="massive"
            >
              <div
                 style={{
                   fontSize: "80%", 
                   marginTop: "0.5em",
                   color: "grey",
                  }}
                >
                Återställer lösenord...
              </div>
            </Loader>            
          }  
          </div>        
        </Segment>

        <Segment
          style={{
            width: "50%",
            height: "90%",
            padding: "2em",
            marginTop: "auto",
            marginBottom: "auto",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            backgroundColor: "#f7f4f0",
          }}
        > 
          <Image 
            style={{
              height: isProcessing ? "80%" : "70%",
              marginTop: isProcessing ? "5%" : null,
              marginBottom: isProcessing ? "15%" : null,
              transition: "height 0.3s",
            }}            
            centered 
            src={Illustration}             
          />

          {!isProcessing &&
            <Header
              as="h2"
              textAlign="center"
              color="grey"
              style={{
                fontWeight: 500,
              }}
            >
              Återställ lösenord
            </Header>
          }

          {!isProcessing &&
            <p
              style={{
                width: "75%",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
              }}
            >
              Fyll i din mailadress så skickar vi ett mail med fler instruktioner. 
              Kontrollera din spam-mapp om du inte har fått något mail inom några minuter. 
              Kontakta oss på <a href="mailto:support@agriopt.se">support@agriopt.se</a> om du behöver mer hjälp.
            </p>
          }
        </Segment>
      </div>
    </div>
    );
  }
}

export default ResetPasswordForm;
