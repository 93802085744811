export const fetchGeoJsonData = (props) => {
 
  const {
    db,
    fieldId,
    guideId,
    setParentState,
    fileBucketRef, 
    currentRationNo,
  } = props;

  let rationString = typeof(currentRationNo) === "number" ? currentRationNo.toFixed(0) : '1';

  let filePath = db && fieldId && db.guides && db.guides[guideId];
  filePath = filePath ? filePath['suggested_files_' + rationString] && filePath['suggested_files_' + rationString][fieldId] : null;

  setParentState({ ["vraMap_" + fieldId]: null });
  
  filePath && fileBucketRef.child(filePath).getDownloadURL()
    .then(url => {
      window.fetch(url)
        .then(async res => {
          let geoJsonData = await res.clone().json();
          
          setParentState({ ["vraMap_" + fieldId]: geoJsonData });
          setParentState({ ["markerDataUndoHistory_" + fieldId]: [geoJsonData] });
        })
        .catch(error => console.log("Cannot get download URL: ", error.toString()))
    })
    .catch(error => console.log("Cannot get download URL: ", error.toString()))
};
