import React, { Component } from "react";

import { 
  Modal,
  Icon,
  Menu,
  Popup,
  Button,
  Input,
  Label,
  Dropdown,
  Header,
  Image,
} from "semantic-ui-react";

import { FIELD_DATA_LAYERS, FIELD_ANALYSIS_LAYERS } from "@/constants/viewLayersAnalysis";

import { isPointInPolygon } from "@/helpers/maps";

import MenuHelp from "@/components/menus/MenuHelp";
import TutorialModal from "@/components/modals/TutorialModalV2";

import FieldDataInsights from "@/components/fieldData/FieldDataInsights";
import FieldDataLayerList from "@/components/fieldData/FieldDataLayerList";
import GuideMapEditorViewer from "@/components/guide/GuideMapEditor/dataViewer";

const getLayerIcon = (icon, text) => (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-start",
      padding: 0,
    }}
  >
    <div 
      style={{
        backgroundColor: "#F8EED0",
        borderRadius: 5,
        width: "2em",
        height: "2em",
        marginRight: "1em",
      }}
    >
      <Image 
        style={{ 
          marginTop: "0.3em",
          marginLeft: "0.3em",
          width: "1.4em", 
          height: "1.4em",
          filter: "invert(0%)",
        }}
        src={icon} 
      />
    </div>

    <div
      style={{
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      {text && text}
    </div>
  </div>
)

const getLayerPosition = (position, noRows = 2) => (
  <div
    style={{
      display: "block",
      fontSize: "60%",
      marginRight: 10,
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Icon style={{ margin: 0 }} name={position === "upper left" ? "square" : "square outline"} />
      <Icon style={{ margin: 0 }} name={position === "upper right" ? "square" : "square outline"} />
    </div>

    {noRows === 2 &&
      <div
        style={{
          marginTop: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Icon style={{ margin: 0 }} name={position === "lower left" ? "square" : "square outline"} />
        <Icon style={{ margin: 0 }} name={position === "lower right" ? "square" : "square outline"} />
      </div>
    }
  </div>
)

class GuideMapEditor extends Component {
  state = {
    mode: '',
    compareLayer: null,
    compareLayerId: null,
    selectedMarker: [],
    selectedGroup: null,
    selectedGroupMarkers: [],
    showSelectLayer: false,
    numberOfGroups: 5,
    colorScaleType: "e",
    colorScaleName: null,
    newValue: '',
    newValueMin: '',
    newValueMax: '',
    showInsightsModal: false,
    selectedAreaPoints: [],
  };

  toggleShowInsightsModal = () => {
    let currentValue = this.state.showInsightsModal;
    this.setState({ showInsightsModal: !currentValue });
  };

  getUploadedSoilMap = (key) => {
    const {
      db,
      fieldId,
    } = this.props;

    let fieldsSoilMaps = fieldId && db && fieldId && db["fieldsSoilMapsProcessed_" + fieldId];
    let uploadedKeys = fieldsSoilMaps && fieldsSoilMaps.rawdata_uploaded_soilmap_available_keys;
    
    let allKeys = [];

    uploadedKeys && Object.keys(uploadedKeys).forEach((year) => {
      allKeys = [...allKeys, ...uploadedKeys[year]];
    });

    return allKeys.includes(key);
  };

  onClickUndo = () => {
    const {
      fieldId,
      markerData,
      markerDataUndoHistory,
      markerDataRedoHistory,
    } = this.props;

    let newMap = markerDataUndoHistory && [...markerDataUndoHistory][0];

    this.props.setParentState({
      ["modifiedVraMap_" + fieldId]: newMap,
      ["markerDataRedoHistory_" + fieldId]: [markerData, ...markerDataRedoHistory],
      ["markerDataUndoHistory_" + fieldId]: [...markerDataUndoHistory].filter((x, idx) => idx !== 0),
    });
  };

  onClickRedo = () => {
    const {
      fieldId,
      markerData,
      markerDataUndoHistory,
      markerDataRedoHistory,
    } = this.props;

    let newMap = markerDataRedoHistory && [...markerDataRedoHistory][0];

    this.props.setParentState({
      ["modifiedVraMap_" + fieldId]: newMap,
      ["markerDataRedoHistory_"  + fieldId]: [...markerDataRedoHistory].filter((x, idx) => idx !== 0),
      ["markerDataUndoHistory_" + fieldId]: [markerData, ...markerDataUndoHistory],
    });
  };
  
  onSaveUndoList = (markerData) => {
    const {
      fieldId,
      markerDataUndoHistory,
    } = this.props;

    this.props.setParentState({
      ["markerDataUndoHistory_" + fieldId]: [markerData, ...markerDataUndoHistory],
      ["markerDataRedoHistory_" + fieldId]: [],
    });
  };

  selectAreaPoints = ({ latlng }) => {
    const currentValue = this.state.selectedAreaPoints;
    this.setState({ selectedAreaPoints: [...currentValue, latlng] });
  };

  onClearSelectedAreaPoints = () => {
    this.setState({selectedAreaPoints: []});
  };

  onUndoSelectAreaPoints = () => {
    const currentValue = this.state.selectedAreaPoints;
    this.setState({selectedAreaPoints: currentValue.filter((x, index) => index !== currentValue.length - 1)})
  };

  onDoneWithSelectAreaPoints = () => {
    const {
      markerData,
    } = this.props;

    const currentValue = this.state.selectedAreaPoints;
    let coords = currentValue && currentValue.map((x) => [x.lat, x.lng]);

    let sortedMarkerData = markerData && markerData.features && [...markerData.features].map((x) => x);
    sortedMarkerData = sortedMarkerData && sortedMarkerData.sort(function (a, b) {   
        return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
    });

    sortedMarkerData = sortedMarkerData && sortedMarkerData.map((x, idx) => ({...x, idx: idx}));

    let selectedMarkers = [];

    sortedMarkerData && sortedMarkerData.forEach((x) => {
      selectedMarkers = isPointInPolygon(x.geometry.coordinates, coords) ? [...selectedMarkers, x.idx] : selectedMarkers;
    });
    
    this.setState({
      mode: null,
      selectedMarker: selectedMarkers,
      selectedAreaPoints: [],
    })
  };

  selectMarker = (marker, force=false) => {
    const {
      mode,
      selectedMarker,
    } = this.state;

    if (!force && mode !== "markPoints") {
      return null;
    };

    if (selectedMarker && selectedMarker.includes(marker)) {
      this.setState({
        selectedMarker: selectedMarker ? [...selectedMarker].filter((x) => x !== marker) : [],
      });
    } else {
      this.setState({
        selectedMarker: selectedMarker ? [...selectedMarker, marker] : [marker],
      });

      this.setState({
        selectedGroup: null,
        selectedGroupMarkers: [],
      });      
    };
  };

  selectAllPoints = () => {
    const {
      markerData,
    } = this.props;

    let sortedMarkerData = markerData && markerData.features && [...markerData.features].map((x) => x);
    sortedMarkerData = sortedMarkerData && sortedMarkerData.sort(function (a, b) {   
        return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
    });

    sortedMarkerData = sortedMarkerData && sortedMarkerData.map((x, idx) => ({...x, idx: idx}));

    this.setState({
      mode: null,
      selectedMarker: sortedMarkerData ? [...sortedMarkerData.map((x) => x.idx)] : [],
    });

  };

  deSelectAllPoints = () => {
    this.setState({
      mode: null,
      selectedMarker: [],
      selectedGroup: null,
      selectedGroupMarkers: [],
    });
  };

  selectGroupMarkers = (markers) => {
    this.setState({
      selectedMarker: [],
      selectedGroupMarkers: markers,
      showInsightsModal: false,
    });
  };  

  setDefaultValues = (parameters, useAllPoints=false) => {
    const {
      markerData,
      geoJsonFieldKey,
    } = this.props;

    const {
      selectedMarker,
      selectedGroupMarkers,
    } = this.state;

    let sortedMarkerData = markerData && markerData.features && [...markerData.features].map((x) => x);
    sortedMarkerData = sortedMarkerData && sortedMarkerData.sort(function (a, b) {   
        return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
    });

    sortedMarkerData = sortedMarkerData && sortedMarkerData.map((x, idx) => ({...x, idx: idx}));

    let selectedMarkers = [];
    selectedMarkers = selectedMarker ? [...selectedMarkers, ...selectedMarker] : selectedMarkers;
    selectedMarkers = selectedGroupMarkers ? [...selectedMarkers, ...selectedGroupMarkers] : selectedMarkers;
    selectedMarkers = useAllPoints ? [...sortedMarkerData].map((x) => x.idx) : selectedMarkers;

    let newData;
    let currentValue = markerData && [...markerData.features];

    if (currentValue && currentValue.features && currentValue.features.length > 0) {
      newData = [...currentValue.features];
    } else {
      newData = sortedMarkerData;
    };

    let filteredNewData = newData && [...newData].filter((x) => selectedMarkers && selectedMarkers.includes(x.idx));
    let allValues = selectedMarkers && filteredNewData.map((x) => x.properties[geoJsonFieldKey]);

    if (allValues && parameters === "minMax") {
      this.setState({
        newValueMax: Math.max(...allValues).toFixed(2),
        newValueMin: Math.min(...allValues).toFixed(2),
      });
    };

    if (allValues && parameters === "mean") {
      this.setState({
        newValue: Math.mean(allValues).toFixed(2),
      });
    };    
  };

  getQuickButton = (number) => (
    <Label
      basic
      size="big"
      style={{
        cursor: "pointer",
        backgroundColor: "#BF8136",
        color: "white",
      }}                  
      onClick={() => {
        this.setState({ newValue: "-20" }, this.setPointValue());
      }}
    >
      {number.toFixed(0)}%
    </Label>
  )

  setAllPointsValue = (overrideMode=null, overrideNewValue=null) => {
    const {
      fieldId,
      markerData,
      geoJsonFieldKey,
    } = this.props;

    const {
      newValueMin,
      newValueMax,
    } = this.state;

    let mode = overrideMode ? overrideMode : this.state.mode;
    let newValue = overrideNewValue ? overrideNewValue : this.state.newValue;

    markerData && this.onSaveUndoList(markerData);

    let computedNewValue;
    let currentValue = markerData && [...markerData.features];
    let newData = markerData && [...markerData.features];

    let meanValue = currentValue && currentValue.map((x) => x.properties[geoJsonFieldKey]);

    newData = currentValue && currentValue.map((x) => {

      if (meanValue && mode === "calibrateAll") {
          computedNewValue = parseFloat(newValue) / Math.mean(meanValue) * x.properties[geoJsonFieldKey];
        return {...x, properties: { value: Math.max(computedNewValue, 0.0) }};
      }

      switch(mode) {

        case "addValue":
          computedNewValue = Math.max(Number(newValue) + x.properties[geoJsonFieldKey], 0.0)
          break;

        case "percentValue":
          computedNewValue = Math.max((1.0 + Number(newValue) / 100.0) * x.properties[geoJsonFieldKey], 0.0)
          break;

        case "truncateAll":
          computedNewValue = Math.max(Math.min(Number(newValueMax), Math.max(Number(newValueMin), x.properties[geoJsonFieldKey])), 0.0)
          break;

        default:
          computedNewValue = x.properties[geoJsonFieldKey];  
      }
      
      return {...x, properties: { ...x.properties, [geoJsonFieldKey]: Math.max(computedNewValue, 0.0) }}
    });

    this.setState({
      mode: null,
      newValue: '',
      newValueMin: '',
      newValueMax: '',
      selectedMarker: [],
      selectedGroupMarkers: [],
      selectedGroup: null,
    });

    this.props.setParentState({ ["modifiedVraMap_" + fieldId]: { ...markerData, features: newData } });
  };

  setPointValue = () => {
    const {
      fieldId,
      markerData,
      geoJsonFieldKey,
    } = this.props;

    const {
      mode,
      selectedMarker,
      selectedGroupMarkers,
      newValue,
      newValueMin,
      newValueMax,
    } = this.state;

    markerData && this.onSaveUndoList(markerData);

    let sortedMarkerData = markerData && markerData.features && [...markerData.features].map((x) => x);
    sortedMarkerData = sortedMarkerData && sortedMarkerData.sort(function (a, b) {   
        return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
    });

    sortedMarkerData = sortedMarkerData && sortedMarkerData.map((x, idx) => ({...x, idx: idx}));

    let selectedMarkers = [];
    selectedMarkers = selectedMarker ? [...selectedMarkers, ...selectedMarker] : selectedMarkers;
    selectedMarkers = selectedGroupMarkers ? [...selectedMarkers, ...selectedGroupMarkers] : selectedMarkers;

    let newData, computedNewValue;
    let currentValue = markerData && [...markerData.features];

    if (currentValue && currentValue.features && currentValue.features.length > 0) {
      newData = [...currentValue.features];
    } else {
      newData = sortedMarkerData;
    };

    let filteredNewData = newData && [...newData].filter((x) => selectedMarkers && selectedMarkers.includes(x.idx));
    let meanValue = selectedMarkers && filteredNewData.map((x) => x.properties[geoJsonFieldKey]);

    newData = newData && newData.map((x) => {
      let pointData = selectedMarkers && selectedMarkers.includes(x.idx) && x;

      if (meanValue && mode === "calibrate") {

        if (pointData) {
          computedNewValue = Math.max(0.0, Number(newValue));
        } else {
          computedNewValue = Math.max(Number(newValue) / parseFloat(meanValue) * x.properties[geoJsonFieldKey], 0.0);
        }

        return {...x, properties: { ...x.properties, [geoJsonFieldKey]: computedNewValue }}
      }

      if (pointData) {
        switch(mode) {
          case "setValue":
            computedNewValue = Math.max(Number(newValue))
            break;

          case "addValue":
            computedNewValue = Math.max(0.0, Number(newValue) + x.properties[geoJsonFieldKey])
            break;

          case "percentValue":
            computedNewValue = Math.max((1.0 + Number(newValue) / 100.0) * x.properties[geoJsonFieldKey])
            break;

          case "truncate":
            computedNewValue = Math.min(Number(newValueMax), Math.max(Number(newValueMin), x.properties[geoJsonFieldKey]))
            break;

          default:
            computedNewValue = x.properties[geoJsonFieldKey];  
        }
        
        return {...x, properties: { ...x.properties, [geoJsonFieldKey]: computedNewValue }}
      } else {
        return x
      };

    });

    this.setState({
      mode: null,
      newValue: '',
      newValueMin: '',
      newValueMax: '',
      selectedMarker: [],
      selectedGroupMarkers: [],
      selectedGroup: null,
    });

    this.props.setParentState({ ["modifiedVraMap_" + fieldId]: { ...markerData, features: newData } });
  };

  openLayer = (type, idx, plotName = null) => {
    this.setState({
      compareLayer: type,
      compareLayerId: idx ? idx : null,
    })
  };

  onChange = (data, onlyNumbers=true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.-]/g, '') : value;
    value = value && value.replace(",", ".");

    this.setState({
      [data.name]: value,
    });
  }; 

  onClearRedoList = () => this.setState({ markerDataUndoHistory: [] });
  
  getMenuItem = (name, active, onClick, icon, tooltip, disabled=false) => (
    <Popup
      disabled={disabled}
      trigger={
        <Menu.Item
          className={disabled ? null : "menuSecondaryItem"}
          name={name}
          active={active}
          disabled={disabled}
          onClick={() => onClick()}
          style={{
            textAlign: "center",
            marginTop: 0,
            height: 55,
            width: 55,
            background: active ? "rgba(234, 204, 116, 0.25)" : "transparent",
            border: "0px none transparent",
            borderBottom: active ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
          }}
        >
          <Icon
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
            }}
            size='large'
            name={icon}
          />
        </Menu.Item>
      }
      position="bottom center"
      content={tooltip}
    />
  );

  getCompareMenuItem = (plotName, layerPosition) => {
    const {
      showSelectLayerPlotName,
    } = this.props;

    return (
      <Menu.Item
        className="menuSecondaryItem"
        style={{
          marginTop: 0,
          height: "55px",
          background: showSelectLayerPlotName === plotName ? "rgba(234, 204, 116, 0.25)" : "transparent",
          borderBottom: showSelectLayerPlotName === plotName ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
          fontWeight: showSelectLayerPlotName === plotName ? 500 : null,
          width: "400px",
        }}
        onClick={() => this.toggleShowSelectLayer(plotName)}
      >
        {getLayerPosition(layerPosition, 1)}

        <div
          style={{
            // padding: 8,
            // border: "1px solid darkgrey",
            // borderRadius: 4,
            width: "100%",
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Välj jämförelselager...
          <Icon name="caret down" size="small" />
        </div>
      </Menu.Item>
    );
  };

  toggleShowFullScreenMap = () => {
    let currentValue = this.state.showFullScreenMap;
    this.setState({ showFullScreenMap: !currentValue });
  };

  setParentState = (newState) => this.setState(newState);

  toggleShowSelectLayer = () => {
    let currentValue = this.state.showSelectLayer;
    this.setState({ showSelectLayer: !currentValue });
  };

  toggleMode = (newMode) => {
    let currentValue = this.state.mode;

    newMode === currentValue && this.setState({ mode: null });
    newMode !== currentValue && this.setState({ mode: newMode });
  };

  onDeleteExperience = (id) => {
    this.props.getUser().collection("insights").doc(id).delete();
  };

  getPointModifyModal = () => {
    const {
      mode,
      newValue,
      newValueMin,
      newValueMax,
    } = this.state;

    let disableSave = false;
    disableSave = ["setValue", "addValue", "percentValue"].includes(mode) && newValue === "" ? true : disableSave;
    disableSave = ["truncate"].includes(mode) && (newValueMax === "" || newValueMin === "") ? true : disableSave;

    return (
      <Modal
        open={["setValue", "addValue", "percentValue", "truncate", "calibrate", "calibrateAll", "truncateAll"].includes(mode)}
        size="tiny"
      >
        <Modal.Header
          style={{
            fontWeight: 500,
          }}
        >
          {mode === "setValue" && "Ersätt förslagen giva med ett annat värde"}
          {mode === "addValue" && "Öka/minska förslagen giva med ett värde (kg/ha)"}
          {mode === "percentValue" && "Öka/minska förslagen giva med ett värde (procent)"}
          {mode === "calibrate" && "Kalibrera den föreslagna styrfilen"}
          {mode === "calibrateAll" && "Justera medelgivan"}
          {mode === "truncate" && "Sätt minsta och största värde för den föreslagna givan"}
          {mode === "truncateAll" && "Sätt minsta och största värde för den föreslagna givan"}
        </Modal.Header>
        <Modal.Content>
          <p
            style={{
              marginBottom: "1.5em",
            }}
          >
            {mode === "setValue" && "Fyll i ett värde nedan i kg/ha som du vill använda som giva för de markerade punkterna på kartan. Det värdet som nu är föreslaget i dessa punkter kommer att ersättas."}
            {mode === "addValue" && "Välj eller fyll i ett värde i kg/ha att lägga till eller dra bort för de markerade punkterna på kartan. Använd ett minustecken framför värdet för att dra bort det fån den föreslagna givan."}
            {mode === "percentValue" && "Välj eller fyll i ett värde i procent att lägga till eller dra bort för de markerade punkterna på kartan. Använd ett minustecken framför värdet för att dra bort den andelen fån den föreslagna givan."}
            {mode === "truncate" && "Fyll i nya minsta och största värden i kg/ha för de markerade punkterna på kartan. Alla punkterna som är markerade kommer att justeras så att de hamnar mellan dessa två värden. Värden som är mindre än det nya minsta värdet kommer sättas till det nya minsta värdet. Freja kommer att hantera värden som är större än det nya största värdet på samma sätt."}
            {mode === "truncateAll" && "Fyll i nya minsta och största värden i kg/ha för alla punkterna på kartan. Alla punkterna kommer att justeras så att de hamnar mellan dessa två värden. Värden som är mindre än det nya minsta värdet kommer sättas till det nya minsta värdet. Freja kommer att hantera värden som är större än det nya största värdet på samma sätt."}              
            {mode === "calibrate" && "Om du har fått en föreslagen giva från en rådgivare för ett område på skiftet kan du använda denna funktionen. Markera de punkter som du har fått en rekommendation för och fyll i värdet för rekommendationen nedanför. Freja kommer att räkna om resterande del av styrfilen baserat på denna rekommendation."}
            {mode === "calibrateAll" && "Fyll i den nya medelgivan som du vill ha för styrfilen. Freja kommer att räkna om givan i alla punkter så att den nya snittgivan blir kalibrerad till det värde du anger."}
          </p>

          {["percentValue"].includes(mode) &&
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              {this.getQuickButton(-20)}
              {this.getQuickButton(-10)}
              {this.getQuickButton(-5)}
              {this.getQuickButton(5)}
              {this.getQuickButton(10)}
              {this.getQuickButton(20)}
            </div>
          }

          {["setValue", "addValue", "percentValue", "calibrate", "calibrateAll"].includes(mode) &&
            <Input
              style={{
                width: "100%",
              }}
              autoFocus={["setValue"].includes(mode)}
              name="newValue"
              placeholder={mode === "percentValue" ? "Förändring i %" : "Eget värde (kg/ha)"}
              value={newValue}
              onChange={(e, d) => this.onChange(d, true)}
            />
          }

          {["truncate", "truncateAll"].includes(mode) &&
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "45%"
                }}
              >
                <Header 
                  as="h5"
                  style={{
                    marginBottom: 0,
                    paddingBottom: 0,
                    fontWeight: 500,
                  }}
                >
                  Minsta värde (kg/ha)
                </Header>

                <Input
                  fluid
                  name="newValueMin"
                  value={newValueMin}
                  placeholder="Eget värde (kg/ha)"
                  onChange={(e, d) => this.onChange(d, true)}
                />
              </div>
              
              <div
                style={{
                  width: "45%"
                }}
              >
                <Header 
                  as="h5"
                  style={{
                    marginBottom: 0,
                    paddingBottom: 0,
                    fontWeight: 500,
                  }}
                >
                  Största värde (kg/ha)
                </Header>

                <Input
                  fluid
                  name="newValueMax"
                  value={newValueMax}
                  placeholder="Eget värde (kg/ha)"
                  onChange={(e, d) => this.onChange(d, true)}
                />              
              </div>
            </div>
          }

        </Modal.Content>

        <Modal.Actions
          style={{ 
            display: "flex",
            justifyContent: "space-between"
          }}            
        >
          <Button 
            style={{ 
              backgroundColor: "#868D8E", 
              color: "white",
            }}
            onClick={() => this.setState({ mode: null })}
          >
            Avbryt
          </Button>

          <Button
            disabled={disableSave}
            style={{ 
              backgroundColor: "#6B9D9F", 
              color: "white",
            }}
            onClick={mode && ["truncateAll", "calibrateAll"].includes(mode) ? () => this.setAllPointsValue() : () => this.setPointValue()}
          >
            Spara
          </Button>              
        </Modal.Actions>           
      </Modal>
    )
  };

  getModalMenu = () => {
    const {
      markerDataUndoHistory,
      markerDataRedoHistory,
    } = this.props;

    const {
      mode,
      selectedGroupMarkers,
      selectedMarker,
      selectedAreaPoints,
    } = this.state;

    let hasSelectedMarkers = (selectedMarker && selectedMarker.length > 0) || (selectedGroupMarkers && selectedGroupMarkers.length > 0);

    return (
      <Menu
        pointing
        fluid
        secondary
        style={{
          backgroundColor: "white",
          textAlign: "center",
          marginTop: 0,
          height: "53px",
        }}
      >
        <Menu.Menu position="left">
          <Dropdown
            icon={null}
            closeOnChange={true}
            trigger={
              <Menu.Item
                className="menuSecondaryItem"
                style={{
                  height: "55px",
                  margin: 0,
                }}
              >
                {getLayerPosition("upper left", 1)}
                Välj jämförelselager...
                <Icon style={{ marginLeft: 8, marginRight: 0 }} name='caret down' />
              </Menu.Item>
              }
            >

            <Dropdown.Menu 
              style={{ 
                zIndex: 10000,
              }}
            >   
              <Dropdown.Item
                onClick={() => this.openLayer("greenmass_percent", "", "")}
              >
                {getLayerIcon(FIELD_DATA_LAYERS.greenmass.icon, "Senaste grönmassakartan")}
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item
                onClick={() => this.openLayer("elevationMap", "mapzen", "")}
              >
                {getLayerIcon(FIELD_DATA_LAYERS.elevationMap.icon, "Höjdkarta")}
              </Dropdown.Item>

              <Dropdown.Divider />
              
              {this.getUploadedSoilMap("clay") &&
                <Dropdown.Item
                  onClick={() => this.openLayer("soilMapUploaded_clay", "latest", "")}
                >
                  {getLayerIcon(FIELD_DATA_LAYERS.soilMapUploaded.icon, "Lerhaltskarta (Egen markkartering)")}
                </Dropdown.Item>                        
              }

              {!this.getUploadedSoilMap("clay") &&
                <Dropdown.Item
                  onClick={() => this.openLayer("soilMapSweden", "latest", "")}
                >
                  {getLayerIcon(FIELD_DATA_LAYERS.soilMapSweden.icon, "Lerhaltskarta (Digitala åkermarkskartan)")}
                </Dropdown.Item>                        
              }                    

              {this.getUploadedSoilMap("organic") &&
                <Dropdown.Item
                  onClick={() => this.openLayer("soilMapUploaded_organic", "latest", "")}
                >
                  {getLayerIcon(FIELD_DATA_LAYERS.soilMapSweden.icon, "Mullhaltskarta")}
                </Dropdown.Item>
              }

              {this.getUploadedSoilMap("sand") &&
                <Dropdown.Item
                  onClick={() => this.openLayer("soilMapUploaded_sand", "latest", "")}
                >
                  {getLayerIcon(FIELD_DATA_LAYERS.soilMapSweden.icon, "Sandhaltskarta (Egen markkartering)")}
                </Dropdown.Item>                        
              }

              {!this.getUploadedSoilMap("sand") &&
                <Dropdown.Item
                  onClick={() => this.openLayer("soilMapSweden_sand", "latest", "")}
                >
                  {getLayerIcon(FIELD_DATA_LAYERS.soilMapSweden.icon, "Sandhaltskarta (Digitala åkermarkskartan)")}
                </Dropdown.Item>
              }      
              <Dropdown.Divider />

              <Dropdown.Item
                onClick={() => this.openLayer("yieldStatistics_max", "cereals", "")}
              >
                {getLayerIcon(FIELD_ANALYSIS_LAYERS.yieldStatistics.icon, "Maximal skörd")}
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => this.openLayer("yieldStatistics", "cereals", "")}
              >
                {getLayerIcon(FIELD_ANALYSIS_LAYERS.yieldStatistics.icon, "Snittskörd")}
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item
                onClick={() => this.toggleShowSelectLayer("compareLayer")}
              >                 
                {getLayerIcon(FIELD_ANALYSIS_LAYERS.vraMap.icon, "Fler lager")}  
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>                
        </Menu.Menu>

        <Menu.Menu position="right">
          {mode !== "drawPoints" && this.getMenuItem("redo", false, () => this.onClickRedo(), "redo", "Upprepa", markerDataRedoHistory.length < 1)}
          {mode !== "drawPoints" && this.getMenuItem("undo", false, () => this.onClickUndo(), "undo", "Ångra", markerDataUndoHistory.length < 2)}
          
          {mode === "drawPoints" && this.getMenuItem("undo", false, () => this.onUndoSelectAreaPoints(), "undo", "Ångra senast markerade punkt",  selectedAreaPoints.length === 0)}
          {mode === "drawPoints" && this.getMenuItem("trash", false, () => this.onClearSelectedAreaPoints(), "trash", "Rensa markerat område", selectedAreaPoints.length === 0)}
          {mode === "drawPoints" && this.getMenuItem("done", false, () => this.onDoneWithSelectAreaPoints(), "check", "Markera punkterna inom området", selectedAreaPoints.length === 0)}

          <div style={{ marginLeft: "0.75em" }} />

          <Dropdown
            icon={null}
            closeOnChange={true}
            trigger={
              <Menu.Item
                className="menuSecondaryItem"
                style={{
                  height: "55px",
                  margin: 0,
                }}
              >
                <Icon name='mouse pointer' />
                Välj punkter
                <Icon style={{ marginLeft: 8, marginRight: 0 }} name='caret down' />
              </Menu.Item>
            }
          >
            <Dropdown.Menu 
              style={{ 
                zIndex: 10000,
              }}
            >
              <Dropdown.Item
                active={mode === "markPoints"}
                icon="mouse pointer"
                text="Punkt-för-punkt"
                onClick={() => this.toggleMode("markPoints")}
              />

              <Dropdown.Item
                active={mode === "drawPoints"}
                icon="pencil alternate"
                text="Rita område"
                onClick={() => this.toggleMode("drawPoints")}
              />                    

              <Dropdown.Item
                icon="book"
                text="Hämta en insikt"
                onClick={() => this.toggleShowInsightsModal()}
              />  

              <Dropdown.Item
                icon="circle"
                text="Markera alla punkter"
                onClick={() => this.selectAllPoints()}
              />     

              <Dropdown.Divider />

              <Dropdown.Item
                disabled={!hasSelectedMarkers}
                icon="circle outline"
                text="Rensa markerade punkter"
                onClick={() => this.deSelectAllPoints()}
              />     
            </Dropdown.Menu>
          </Dropdown>       

          <Dropdown
            icon={null}
            closeOnChange={true}
            trigger={
              <Menu.Item
                className="menuSecondaryItem"
                style={{
                  height: "55px",
                  margin: 0,
                }}
              >
                <Icon name='pin' />
                Justera giva
                <Icon style={{ marginLeft: 8, marginRight: 0 }} name='caret down' />
              </Menu.Item>
            }
          >
            <Dropdown.Menu 
              style={{ 
                zIndex: 10000,
              }}
            >
              <Dropdown.Item
                disabled={!hasSelectedMarkers}
                icon="pin"
                text="Ersätt med ett värde"
                onClick={() => this.toggleMode("setValue")}
              />

              <Dropdown.Divider />

              <Dropdown.Item
                disabled={!hasSelectedMarkers}
                icon="add"
                text="Öka/minska (kg/ha)"
                onClick={() => this.toggleMode("addValue")}
              />                    

              <Dropdown.Item
                disabled={!hasSelectedMarkers}
                icon="percent"
                text="Öka/minska (procent)"
                onClick={() => this.toggleMode("percentValue")}
              />   

              <Dropdown.Divider />  

              <Dropdown.Item
                disabled={!hasSelectedMarkers}
                icon="check square outline"
                text="Kalibrera kartan efter område"
                onClick={() => {
                  this.setDefaultValues("mean", false);
                  this.toggleMode("calibrate");
                }}
              /> 

              <Dropdown.Item
                disabled={!hasSelectedMarkers}
                icon="bullseye"
                text="Sätt minsta och största giva"
                onClick={() => {
                  this.setDefaultValues("minMax", false);
                  this.toggleMode("truncate");
                }}
              />                       
            </Dropdown.Menu>
          </Dropdown>       

          <Dropdown
            icon={null}
            closeOnChange={true}
            trigger={
              <Menu.Item
                className="menuSecondaryItem"
                style={{
                  height: "55px",
                  margin: 0,
                }}
              >
                <Icon name='forward' />
                Vanliga justeringar
                <Icon style={{ marginLeft: 8, marginRight: 0 }} name='caret down' />
              </Menu.Item>
            }
          >
            <Dropdown.Menu 
              style={{ 
                zIndex: 10000,
              }}
            >                
              <Dropdown.Item
                icon="add"
                text="Lägg på 10 kg på alla punkter"
                onClick={() => this.setAllPointsValue("addValue", "10.0")}
              />  

              <Dropdown.Item
                icon="minus"
                text="Dra bort 10 kg på alla punkter"
                onClick={() => this.setAllPointsValue("addValue", "-10.0")}
              />  

              <Dropdown.Divider />

              <Dropdown.Item
                icon="percent"
                text="Öka med 10% på alla punkter"
                onClick={() => this.setAllPointsValue("percentValue", "10.0")}
              />

              <Dropdown.Item
                icon="percent"
                text="Minska med 10% på alla punkter"
                onClick={() => this.setAllPointsValue("percentValue", "-10.0")}
              />    

              <Dropdown.Divider />                     

              <Dropdown.Item
                icon="check square outline"
                text="Justera medelgivan"
                onClick={() => {
                  this.setDefaultValues("mean", true);
                  this.toggleMode("calibrateAll");
                }}
              /> 

              <Dropdown.Item
                icon="bullseye"
                text="Sätt minsta och största giva för alla punkter"
                onClick={() => {
                  this.setDefaultValues("minMax", true);
                  this.toggleMode("truncateAll");
                }}
              />                       
            </Dropdown.Menu>
          </Dropdown>          
          
          <div style={{ marginLeft: "0.75em" }} />

          <TutorialModal
            isAdminUser={this.props.isAdminUser}
            db={this.props.db}
            getUser={this.props.getUser}
            tutorialName={"DesktopGuideMapEditorFirstLaunchV1"}
            trigger={
              <Menu.Item>
                <Button
                  basic
                >
                  Kom igång-guide
                </Button>
              </Menu.Item>
            }
          />

          <MenuHelp
            style={{
              zIndex: 11000,
            }}
            isAdminUser={this.props.isAdminUser}
            userId={this.props.userId}
            db={this.props.db}
            view={'guideMapEditor'}
            // tutorialName={"DesktopGuideMapEditorFirstLaunchV1"}
            getUser={this.props.getUser}
            params={this.props.params}
          />  
        </Menu.Menu>
      </Menu>
    )
  }

  render () {
    const {
      db,
      field,
      layer,
      fileBucketRef,
      markerData,
      userId,
      fieldId,
      getUser,
      userPlanType,
      cacheFieldData,
      updateCacheFieldData,
      editorOpen,
      colorScales,
    } = this.props;

    const {
      mode,
      selectedGroup,
      selectedGroupMarkers,
      selectedMarker,
      showSelectLayer,
      numberOfGroups,
      colorScaleType,
      colorScaleName,
      showInsightsModal,
      selectedAreaPoints,
    } = this.state;

    let mapViewerProps = {
      db,
      field,
      getUser: getUser,
      fileBucketRef: fileBucketRef,
      dataType: "analysis",
      userId: userId,
      fieldId: fieldId,
      markerScale: 1.0,
      lockedMap: true,
      showArealMap: true,
      onClickSelectField: this.onClickSelectField,
      onClickSelectCompare: this.onClickSelectCompare,
      showMissingData: true,
      setContainerState: this.setParentState,
      selectMarker: this.selectMarker,
      selectGroupMarkers: this.selectGroupMarkers,
      cacheFieldData,
      updateCacheFieldData,
      numberOfGroups,
      colorScaleType,
      colorScaleName,
    };
  
    let compareLayer = this.state.compareLayer ? this.state.compareLayer : this.props.editorDefaultLayer;
    let compareLayerId = this.state.compareLayerId ? this.state.compareLayerId : this.props.editorDefaultLayerId;

    const rawYieldMap = db && fieldId && db["fieldsYieldMapsRaw_" + fieldId];
    const processedYieldMap = db && fieldId && db["fieldsYieldMapsProcessed_" + fieldId];

    return (
      <React.Fragment>
        <Modal 
          open={showInsightsModal} 
          size='tiny'
          style={{
            color: "black",
          }}
        >
          <Modal.Header
            style={{
              fontWeight: 500
            }}
          >
            Välj en insikt att hämta markerade punkter från
          </Modal.Header>
          
          <Modal.Content>
            <FieldDataInsights
              widgetType="vraEditor"
              db={db}
              fieldId={fieldId}
              getUser={this.props.getUser}
              selectGroupMarkers={this.selectGroupMarkers}
              setParentState={this.setParentState}
            />
          </Modal.Content>

          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}            
          >
            <Button 
              style={{ 
                backgroundColor: "#868D8E", 
                color: "white",
              }}
              onClick={() => this.toggleShowInsightsModal()}
            >
              Stäng
            </Button>

            <div />             
          </Modal.Actions>  
        </Modal>

        {this.getPointModifyModal()}

        <Modal 
          open={editorOpen} 
          size='fullscreen'
          style={{
            color: "black",
            zIndex: 1,
          }}
        >
          <FieldDataLayerList
            open={showSelectLayer}
            isCompare={true}
            userId={userId}
            userPlanType={userPlanType}
            activeView="compare2"
            db={db}
            fieldId={fieldId}
            dataType="analysis"
            plotName="compareLayerVraMap"
            openLayer={this.openLayer}
            toogleShowSelectLayer={this.toggleShowSelectLayer}
            layer={compareLayer}
            layerId={compareLayerId}
            rawYieldMap={rawYieldMap}
            processedYieldMap={processedYieldMap}
          />

          {this.getModalMenu()}

            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: -12,
              }}
            >
              <GuideMapEditorViewer
                {...mapViewerProps}
                mode='vraMapEditor'
                activeView="compare2"
                plotName="UpperLeft"
                layer={compareLayer}
                layerId={compareLayerId}
                openLayer={this.openLayer}
                markerScale={mode === "drawPoints" ? 0.3 : 1.0}
                selectedMarker={selectedMarker}
                selectedGroup={selectedGroup}
                selectedGroupMarkers={selectedGroupMarkers}
                mapStyle={{
                  top: 0,
                  height: "calc(80vh)",
                }}
                mapOptions={{
                  boundsOptions: { padding: [25, 25] },
                }}
                placeholderText={"Klicka här för att välja ett datalager"}
                placeholderOnClick={() => this.toggleShowSelectLayer()}
                // loadingData={!markerData}
                loadingDataText="Freja hämtar jämförelselager..."
                onClickMap={mode === "drawPoints" ? this.selectAreaPoints : () => {}}
                selectedAreaPoints={selectedAreaPoints} 
              />

              <GuideMapEditorViewer
                {...mapViewerProps}
                mode='vraMapEditor'
                activeView="compare2"
                plotName="UpperRight"
                layer={layer ? layer : "vraMap"}
                layerId={null}
                markerData={markerData}
                openLayer={() => this.toggleMode("markPoints")}
                onHoverPopup={!["drawPoints", "markPoints"].includes(mode)}
                markerScale={mode === "drawPoints" ? 0.3 : 1.0}
                selectedMarker={[...selectedMarker, ...selectedGroupMarkers]}
                selectedGroup={selectedGroup}
                selectedGroupMarkers={selectedGroupMarkers}                
                mapStyle={{
                  top: 0,
                  height: "calc(80vh)",
                }}
                mapOptions={{
                  boundsOptions: { padding: [25, 25] },
                }}
                placeholderText={"Laddar förslag..."}
                // loadingData={!markerData}
                onClickMap={mode === "drawPoints" ? this.selectAreaPoints : () => {}}
                selectedAreaPoints={selectedAreaPoints}                 
              />
            </div>

            <Modal.Actions
              style={{ 
                display: "flex",
                justifyContent: "flex-end"
              }}            
            >
              <Button 
                style={{
                  backgroundColor: "#868D8E", 
                  color: "white",
                  marginRight: "1em",
                }}
                onClick={() => this.props.onCancelChanges(fieldId)}
              >
                Stäng utan att spara
              </Button>

              <Button 
                primary
                onClick={() => this.props.onSaveChanges()}
              >
                Spara och stäng
              </Button>              
            </Modal.Actions> 
        </Modal>

        <p style={{ marginBottom: "1em" }} />

        <GuideMapEditorViewer
          {...mapViewerProps}
          mode='vraMapEditorSimple'
          activeView="none"
          plotName="UpperRight"
          layer={layer ? layer : "vraMap"}
          layerId={null}
          selectedMarker={selectedMarker}
          selectedGroup={selectedGroup}
          selectedGroupMarkers={selectedGroupMarkers}   
          onHoverPopup={true}       
          markerData={markerData}
          loadingData={!markerData}
          openLayer={() => this.toggleMode("markPoints")}
          markerScale={0.75}
          colorScales={colorScales}
          mapStyle={{
            top: 0,
            height: "400px",
          }}
          mapOptions={{
            boundsOptions: { padding: [10, 10] },
          }}
          placeholderText={"Laddar förslag..."}
          loadingDataText="Freja skapar ett förslag på styrfil..."
        />

      </React.Fragment>     
    )
  }
};

export default GuideMapEditor;
