import React, { Component } from "react";

import { 
  Input,
  Table,
  Popup,
  Icon,
  Dropdown,
} from "semantic-ui-react";

import { 
  hasLayerData, 
  getAvailableLayerIds, 
  fetchData 
} from "@/helpers/dataFetcher";

import { CROP_CODES } from "@/constants/cropCodes";
import { YIELD_OPTIONS } from "@/constants/map";

import { 
  FIELD_DATA_LAYERS, 
  FIELD_ANALYSIS_LAYERS,
} from "@/constants/viewLayersAnalysis";

import GuideMapPreview from "@/components/guide/GuideMapPreview";
import GuideSegment from "@/components/guide/GuideSegment";

const INITIAL_STATE = {};

class GuideSegmentHarvest extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    this.getDataForView();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.open === false && this.props.open === true) {
      if (!this.props.complete) {
        this.setDefaultSettings();
      };
    };

    if (prevProps.db !== this.props.db) {
      this.getDataForView();
    };
  };    

  getDataForView = () => {
    const { 
      db,
      fileBucketRef,
      cacheFieldData,
      selectedFieldIds,
    } = this.props;

    selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
      fetchData({
        db,
        fileBucketRef,
        fieldId,
        layer: "yieldAnalysis",
        layerId: "all",
        cacheFieldData,
        setParentState: (x) => {
          let currentValue = this.state["yieldMapData_" + fieldId];
          currentValue = currentValue ? currentValue : {};

          this.setState({ ["yieldMapData_" + fieldId]: {...currentValue, ...x} })
        },
        downloadNewData: true,
      });
    });
  };

  setDefaultSettings = () => {
    const {
      db,
      selectedFieldIds,
      guideExpectedYield,
      guideCrop,
    } = this.props;

    let newExpectedYield = {};
    let newValue;

    if (guideCrop) {
      // Used for guide for N

      selectedFieldIds && selectedFieldIds.forEach((x) => {

        // Check if yield map statistics are available
        if (hasLayerData(db, x, "yieldStatistics")) {

          let selectedCrop = guideCrop && guideCrop[x];
          let yieldMapStatistics = getAvailableLayerIds(db, x, "yieldStatistics_max");

          let layerId = yieldMapStatistics && selectedCrop && yieldMapStatistics.includes(selectedCrop) ? selectedCrop : null;
          layerId = layerId ? layerId : (yieldMapStatistics && yieldMapStatistics.includes('cereals') ? 'cereals' : 'all');

          newValue = "yieldStatistics_max/" + layerId;

        } else {
          let yieldMapYears = getAvailableLayerIds(db, x, 'yieldMap');
          newValue = "yieldMap/" + (yieldMapYears ? yieldMapYears.slice(-1)[0] : '');  
        };

        newExpectedYield = {...newExpectedYield, [x]: newValue};
      });

    } else {
      // Used for guides for P and K
      selectedFieldIds && selectedFieldIds.forEach((x) => {

        let yieldMapYears = getAvailableLayerIds(db, x, 'yieldMap');
        
        newValue = "yieldMap/" + (yieldMapYears ? yieldMapYears.slice(-1)[0] : '');  
        newExpectedYield = {...newExpectedYield, [x]: newValue};
      });
    };

    this.getExpectedYield(newExpectedYield);

    this.props.setParentState({ 
      guideExpectedYield: {...newExpectedYield, ...guideExpectedYield},
    });
  };

  getExpectedYield = (fieldsYields) => {
    const {
      guideExpectedYieldCalibration,
    } = this.props;

    let output = {};

    fieldsYields && Object.keys(fieldsYields).forEach((fieldId) => {
      let yieldMapType = fieldsYields[fieldId];

      let yieldType = yieldMapType && yieldMapType.split("/") && yieldMapType.split("/")[0];
      let yieldCropYear = yieldMapType && yieldMapType.split("/").length > 0 && yieldMapType.split("/")[1];

      // Get the geoJsonKey for the current data layer
      let layers = {...FIELD_DATA_LAYERS, ...FIELD_ANALYSIS_LAYERS};
      let currentLayerData;
      
      if (Object.keys(layers).includes(yieldType)) {
        currentLayerData = layers && layers[yieldType];

      } else {

        let mainLayerToAlias = yieldType && Object.keys(layers).find((x) => layers[x].aliases.includes(yieldType));
        currentLayerData = layers && mainLayerToAlias && layers[mainLayerToAlias];
      };

      let geoJsonFieldKey = currentLayerData && currentLayerData.geoJsonFieldKey && currentLayerData.geoJsonFieldKey[yieldType];

      // Get basic stats and repackage years
      let basicStats = this.state["yieldMapData_" + fieldId];
      basicStats = basicStats && basicStats.statJson && basicStats.statJson.basic_stats;
      
      let reformattedBasicStats = {};

      yieldMapType && basicStats && Object.keys(basicStats).forEach((key) => {

        let subKeys = Object.keys(basicStats[key]).filter((x) => !isNaN(x));
        let newValue = {};
        
        if (subKeys.length === 0) {
          newValue = basicStats[key] && geoJsonFieldKey && basicStats[key][geoJsonFieldKey];
          reformattedBasicStats = {...reformattedBasicStats, [key]: newValue};

        } else {

          Object.keys(basicStats[key]).filter((x) => isNaN(x)).forEach((x) => {
            newValue = {...newValue, [x]: basicStats[key][x]};
          });

          newValue = newValue && geoJsonFieldKey && newValue[geoJsonFieldKey];
          reformattedBasicStats = {...reformattedBasicStats, [key]: newValue};

          Object.keys(basicStats[key]).filter((x) => !isNaN(x)).forEach((x) => {
            reformattedBasicStats = {...reformattedBasicStats, [x]: basicStats[key][x]};
          });
        }
      });
      
      let basicCropStats = yieldCropYear && reformattedBasicStats && reformattedBasicStats[yieldCropYear];    
      let newValue = basicCropStats && basicCropStats.mean ? basicCropStats.mean.toFixed(2) : '';

      output = {...output, [fieldId]: newValue}
    })

    this.props.setParentState({ guideExpectedYieldCalibration: {...guideExpectedYieldCalibration, ...output} });
  };

  onChangeStruct = (e, data, variableName, onlyNumbers=true) => {
    let currentValue = this.props[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: {...currentValue, [data.name]: value },
    });
  }; 

  render() {

    const {
      db,
      fileBucketRef,
      userId,
      selectedFieldIds,
      description,
      caption,
      guideMode,
      disabled,
      simpleModeQuestion,
      simpleModeOpenText,
      simpleModeSkipText,
      disableNextStep,
      guideExpectedYield,
      guideExpectedYieldCalibration,
    } = this.props;
    
    let missingYieldMapStatistics = [];

    selectedFieldIds && selectedFieldIds.forEach((x) => {
      missingYieldMapStatistics = !hasLayerData(db, x, "yieldStatistics") ? [...missingYieldMapStatistics, x] : missingYieldMapStatistics;
    });

    let extendedDisableNextStep = guideExpectedYieldCalibration && selectedFieldIds && selectedFieldIds.find((x) => {
      return (!guideExpectedYieldCalibration[x] || guideExpectedYieldCalibration[x].length === 0)
    });

    extendedDisableNextStep = disableNextStep || extendedDisableNextStep;

    return (
      <GuideSegment
        caption={caption}
        skipSimpleMode={guideMode && guideMode === "advanced"}
        open={this.props.open}
        disabled={disabled}
        simpleModeQuestion={simpleModeQuestion}
        simpleModeOpenText={simpleModeOpenText}
        simpleModeSkipText={simpleModeSkipText}          
        complete={this.props.complete}
        onClickOpenSegment={this.props.onClickOpenSegment}
        onClickNextStep={this.props.onClickNextStep}
        disableNextStep={extendedDisableNextStep}
      >

        {description && description}

        <Table 
          celled 
          striped 
          columns="4"
          style={{
            marginTop: "4em",
            maxWidth: "100%",
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell 
                textAlign="center"
                width={4}
              >
                Skifte
              </Table.HeaderCell>
              <Table.HeaderCell 
                textAlign="center"
                width={8}
              >
                Skördevariation {" "}
                <Popup
                  trigger={<Icon name="help circle" />}
                  content="Välj en karta där bättre och sämre områden i skörden överensstämmer med dina erfarenheter. Freja har berättat denna karta punkt-för-punkt genom att använda sig av de skördekartor som du har laddat upp. Beräkningen sker för en gröda om det finns data från minst 3 år. Du kan även välja en befintlig skördekarta."
                />
              </Table.HeaderCell>
              <Table.HeaderCell 
                textAlign="center"
                width={2}
              >
                Snittskörd
                  {" "}
                  <Popup
                    trigger={<Icon name="help circle" />}
                    content="Ange den förväntade snittskörden i ton/ha. Freja kommer att kalibrera om kartan så att den har denna snittskörd."
                  />
                  <br/>(ton/ha)
              </Table.HeaderCell>
              <Table.HeaderCell width={2}/>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {selectedFieldIds && selectedFieldIds.map((x) => {
              let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;
              let cropCodes = db && db.fieldsInfo && db.fieldsInfo[x] && db.fieldsInfo[x].crop_code;

              let availableYears = getAvailableLayerIds(db, x, "yieldMap");
              let availableCrops = getAvailableLayerIds(db, x, "yieldStatistics_max");
              
              let selectedYield = guideExpectedYield && guideExpectedYield[x];
              
              let filteredMapOptions = [];
              
              !missingYieldMapStatistics.includes(x) && availableCrops && availableCrops.forEach((crop) => {
                YIELD_OPTIONS && YIELD_OPTIONS.forEach((yieldStats) => {

                  let yieldText = CROP_CODES[crop] && CROP_CODES[crop].shorttext;
                  yieldText = crop === "all" ? "alla grödor" : yieldText;
                  yieldText = crop === "cereals" ? "stråsäd" : yieldText;

                  filteredMapOptions = [...filteredMapOptions, {
                    key: yieldStats.key + "/" + crop,
                    value: yieldStats.value + "/" + crop,
                    text: yieldStats.text + " för " + yieldText,

                  }];
                });
              });

              availableYears && availableYears.forEach((year) => {
                let crop = cropCodes && cropCodes[year];
                let cropText = CROP_CODES[crop] && CROP_CODES[crop].shorttext;
                cropText = cropText ? cropText : "okänd gröda";

                filteredMapOptions = [...filteredMapOptions, {
                  key: "yieldMap/" + year,
                  value: "yieldMap/" + year,
                  text: "År " + year + ", " + cropText,
                }];
              });

              return (
                <Table.Row
                  key={"previousCropDropdown_" + x}
                >
                  <Table.Cell textAlign="center">
                    {fieldName && fieldName}
                  </Table.Cell>
                  
                  <Table.Cell>
                    <Dropdown
                      fluid
                      search
                      selection
                      options={filteredMapOptions}
                      name={x}
                      onChange={(e, d) => {
                        this.onChangeStruct(e, d, "guideExpectedYield", false);
                        this.getExpectedYield({[x]: d.value});
                      }}
                      value={selectedYield}
                      placeholder='Välj förväntad skörd...'
                    />
                  </Table.Cell>

                  <Table.Cell>
                    <Input 
                      fluid 
                      name={x}
                      onChange={(e, d) => {
                        this.onChangeStruct(e, d, "guideExpectedYieldCalibration", true);
                      }}
                      error={!guideExpectedYieldCalibration || !guideExpectedYieldCalibration[x] || guideExpectedYieldCalibration[x].length === 0}
                      value={guideExpectedYieldCalibration && guideExpectedYieldCalibration[x]}
                    />
                  </Table.Cell>

                  <Table.Cell textAlign="center">
                    <GuideMapPreview
                      db={db}
                      fileBucketRef={fileBucketRef}
                      userId={userId}
                      fieldId={x}
                      layer={selectedYield && selectedYield.split("/")[0]}
                      layerId={selectedYield && selectedYield.split("/")[1]}
                      popupText="Klicka för att visa den förväntade skörden"
                    />
                  </Table.Cell>
                </Table.Row>
              )})}
            </Table.Body>
          </Table> 
      </GuideSegment>        
    )
  }
}

export default GuideSegmentHarvest;