import React, { Component } from "react";

import GuideArtificialYieldMapsV1 from "@/containers/Guides/ArtificialYieldMapsV1";
import GuideHarvestReport from "@/containers/Guides/HarvestReport";
import GuideLimePhV1 from "@/containers/Guides/LimePhV1";
import GuideLimeStructureV1 from "@/containers/Guides/LimeStructureV1";
import GuideNitrogenV1 from "@/containers/Guides/NitrogenV1";
import GuideNitrogenV2 from "@/containers/Guides/NitrogenV2";
import GuidePhosphorusV1 from "@/containers/Guides/PhosphorusV1";
import GuidePotassiumV1 from "@/containers/Guides/PotassiumV1";
import GuideUploadSoilMaps from "@/containers/Guides/UploadSoilMaps";
import GuideUploadYieldMaps from "@/containers/Guides/UploadYieldMaps";

import GuideRegistrationV1Part1 from "@/containers/Guides/RegistrationV1/part1";
import GuideRegistrationV1Part2 from "@/containers/Guides/RegistrationV1/part2";

import AddNewFieldsV1 from "@/containers/Guides/AddNewFieldsV1";

const INITIAL_STATE = {
  selectedFieldIds: [],
};

class GuidesWrapperPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  setGuidesWrapperState = (newState) => this.setState(newState);

  render() {
    const {
      guideName,
    } = this.props;

    const {
      selectedFieldIds,
    } = this.state;

    let guideProps = {
      ...this.props, 
      selectedFieldIds: selectedFieldIds, 
      setGuidesWrapperState: this.setGuidesWrapperState
    };

    switch(guideName) {

      case 'artificialYieldMapsV1':
        return <GuideArtificialYieldMapsV1 {...guideProps} />;

      case 'harvestReport':
        return <GuideHarvestReport {...guideProps} />;

      case 'limePhV1':
        return <GuideLimePhV1 {...guideProps} />;        

      case 'limeStructureV1':
        return <GuideLimeStructureV1 {...guideProps} />;      

      case 'nitrogenV1':
        return <GuideNitrogenV1 {...guideProps} />;              

      case 'nitrogenV2':
        return <GuideNitrogenV2 {...guideProps} />;       
        
      case 'phosphorusV1':
        return <GuidePhosphorusV1 {...guideProps} />;       

      case 'potassiumV1':
        return <GuidePotassiumV1 {...guideProps} />;     
      
      case 'uploadSoilMaps':
        return <GuideUploadSoilMaps {...guideProps} />;              

      case 'uploadYieldMaps':
        return <GuideUploadYieldMaps {...guideProps} />;              

      case 'registrationV1Part1':
        return <GuideRegistrationV1Part1 {...guideProps} />;
          
      case 'registrationV1Part2':
        return <GuideRegistrationV1Part2 {...guideProps} />;

      case 'addNewFieldsV1':
        return <AddNewFieldsV1 {...guideProps} />;
      
      default:
        return (
          <div>Okänd guide</div>
        )
    }
  }
};

export default GuidesWrapperPage;