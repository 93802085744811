import React, { Component } from "react";

import { 
  Segment, 
  Header,
  Table,
  Image,
  Loader,
} from "semantic-ui-react";

import { CROP_CODES } from "@/constants/cropCodes";
import { getAvailableLayerIds } from "@/helpers/dataFetcher";

import GuideMapPreview from "@/components/guide/GuideMapPreview";

import illustrationDone from "@/assets/illustrations/done_simple.png";
class UploadYieldMapsMapPreviewPerField extends Component {
  state = {};

  render() {
    const { 
      db,
      fileBucketRef,
      userId,
      fields,
      fieldId,
      uploadedFileData,
    } = this.props;

    const { 
      showDetailsModal,
    } = this.state;

    let fieldName = fieldId && fields && fields[fieldId] && fields[fieldId].name;
    let fieldInfo = fieldId && db && db.fieldsInfo && db.fieldsInfo[fieldId];

    let availableYears = uploadedFileData && uploadedFileData.dates_to_use && Object.keys(uploadedFileData.dates_to_use);
    let availableYieldMapYears = getAvailableLayerIds(db, fieldId, "yieldMap")

    let reformattedUploadedFileData = [];
    let allDone = true;

    availableYears && availableYears.forEach((x) => {
      if (!x) {
        return;
      };

      let year = x;
      let crop = uploadedFileData.crop_code && uploadedFileData.crop_code[x];
      crop = crop ? crop : (fieldInfo && fieldInfo.crop_code && fieldInfo.crop_code[x]);
      
      let harvestYield = uploadedFileData.harvest_yields && uploadedFileData.harvest_yields[x];
      let datesToUse = uploadedFileData.dates_to_use && uploadedFileData.dates_to_use[x];

      let cropString = typeof(crop) === "string" ? crop : (typeof(crop) === "number" ? crop.toFixed(0) : crop);
      let cropName = cropString && CROP_CODES && 
        CROP_CODES.find((x) => x.key === cropString) && 
        CROP_CODES.find((x) => x.key === cropString).shorttext;

      let status = "processing";
      status = availableYieldMapYears && availableYieldMapYears.includes(year) ? "done" : status;

      let statusText = status === "done" ? "Klar" : "Skapas...";

      allDone = status !== "done" ? false : allDone;

      reformattedUploadedFileData = [...reformattedUploadedFileData,
        {
          crop,
          cropName,
          year,
          harvestYield,
          datesToUse,
          status,
          statusText,
      }];
    });

    reformattedUploadedFileData = reformattedUploadedFileData && reformattedUploadedFileData.sort((a, b) => {
      const yearA = a && a.year && parseInt(a.year);
      const yearB = b && b.year && parseInt(b.year);
      return yearA < yearB ? -1 : yearA > yearB ? 1 : 0;
    });

    return (      
      <React.Fragment>
        {showDetailsModal && this.getDetailsModal()}

        <Segment
          key={"segmentMapPreviewPErField_" + fieldId}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1em",
            paddingTop: "2em",
            paddingBottom: "2em",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "10em",
              marginTop: "auto",
              marginBottom: "auto",          
              overflowY: "hidden",
            }}
          >
            {!allDone && <Loader style={{ marginLeft: "3em" }} active inline size="large" />}
            {allDone && <Image src={illustrationDone} /> }
          </div>

          <div
            style={{
              height: "100%",
              width: "calc(100% - 10em)",
              marginLeft: "2em",
              marginRight: "2em",
              marginTop: "auto",
              marginBottom: "auto",              
              whiteSpace: "pre-line",
            }}          
          >
            <Header 
              as="h2" 
              style={{
                marginBottom: 5,
                fontWeight: 500,
              }}
            >
              {fieldName && fieldName}
            </Header>
            
            <p>
              {allDone && "Freja har skapat följande skördekartor för detta skifte, du kan se dem här genom att klicka på ikonen till höger eller i vyn Utforska."}
              {!allDone && "Freja arbetar med att skapa dina skördekartor. Du kan följa hur det går nedanför."}
            </p>
                        
            <Table 
              basic='very' 
              striped
              style={{ marginTop: "2em" }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>År</Table.HeaderCell>
                  <Table.HeaderCell>Gröda</Table.HeaderCell>
                  <Table.HeaderCell>Grönmassamätningar</Table.HeaderCell>
                  <Table.HeaderCell>Skörd (ton)</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {reformattedUploadedFileData && reformattedUploadedFileData.map((x) => {
                  return (
                    <Table.Row
                      key={"tableRow_" + fieldId + "_" + x.year}
                    >
                      <Table.Cell>{x.year ? x.year : "Okänd"}</Table.Cell>
                      <Table.Cell>{x.cropName ? x.cropName : "Okänd"}</Table.Cell>
                      <Table.Cell>{x.datesToUse ? x.datesToUse.join(", ") : "Okänd"}</Table.Cell>
                      <Table.Cell>{x.harvestYield ? x.harvestYield : "Okänd"}</Table.Cell>
                      <Table.Cell>{x.statusText ? x.statusText : "Okänd"}</Table.Cell>
                      <Table.Cell
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {x.status === "done" &&
                          <GuideMapPreview
                            db={db}
                            fileBucketRef={fileBucketRef}
                            userId={userId}
                            fieldId={fieldId}
                            layer="yieldMap"
                            layerId={x.year}
                            popupText="Klicka för att visa den skapade skördekartan."
                          />
                        }
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>        
          </div>
        </Segment>
      </React.Fragment>
    );
  } 
}

export default UploadYieldMapsMapPreviewPerField;

