import React, { Component } from "react";

import { 
  Header, 
  Table,
  Confirm,
  Button,
  Icon,
  Label,
  Segment,
} from "semantic-ui-react";

import moment from "moment";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

import { PLAN_TYPES, PLAN_FEATURES } from '@/constants/regions/plans';

const INITIAL_STATE = {
  errorMessage: '',
  invalidType: false,
  planType: '',
  planLongName: '',
  showDemoConfirmModal: false,
};

class RegistrationV1SelectPlan extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickNextStep = () => {
    const {
      completedStepsPlan,
    } = this.props;

    this.props.setParentState({
      openSegmentPlan: '',
      completedStepsPlan: [...completedStepsPlan, "yield"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment) => {};

  onChange = (data, onlyNumbers=true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  }; 

  onChangeStruct = (e, data, variableName, onlyNumbers=true) => {
    let currentValue = this.props[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: {...currentValue, [data.name]: value },
    });
  }; 
  
  setOpenSegment = (newSegment) => {
    const {
      openSegmentPlan
    } = this.props;

    (openSegmentPlan === newSegment) && this.props.setParentState({ openSegmentPlan: null });
    (openSegmentPlan !== newSegment) && this.props.setParentState({ openSegmentPlan: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedStepsPlan, 
    } = this.props;

    !completedStepsPlan.includes(currentSegment) && this.props.setParentState({
      completedStepsPlan: [...completedStepsPlan, currentSegment],
    });

    this.setDefaultSettings(newSegment);

    !completedStepsPlan.includes(newSegment) && this.setOpenSegment(newSegment);
    completedStepsPlan.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  onSelectPlan = (planType, planLongName) => {
    const selectedPlan = PLAN_TYPES.filter((x) => x.name === planType)[0];

    let validTo = moment(new Date()).add(selectedPlan.validFor).endOf("day");
    validTo = validTo && moment(validTo.format("LLL"), "LLL");

    this.props.setParentState({
      plan: {
        ...this.props.plan,
        type: planType,
        typeName: planLongName,
        validTo: validTo.toDate()
      }
    });
  };

  onSelectDemoPlan = () => {
    this.onSelectPlan("demo", "Demokonto");
    this.props.onShowNextPage();
  };

  toggleShowDemoConfirmModal = () => {
    let currentValue = this.state.showDemoConfirmModal;
    this.setState({ showDemoConfirmModal: !currentValue });
  };

  getCheckCell = (x, y) => {
    if (x.availableFor.includes(y.name) || x.availableFor.includes("all")) {
      return (
        <Table.Cell 
          style={{ 
            textAlign: "center" 
          }}
        >
          <Icon style={{ color: y.disabled ? "#D6D9D9" : "#191919" }} name="checkmark" />
        </Table.Cell>
      )
    } else {
      return (
        <Table.Cell></Table.Cell>
      )
    }
  }

  getTableRows = (featureGroup) => {
    return (
      <React.Fragment>
        <Table.Row>
          <Table.Cell 
            colSpan='5'
            style={{
              paddingTop: "4em",
              paddingBottom: "2em",
            }}
          >
            <Header as="h2" style={{ fontWeight: 500 }}>{featureGroup.caption}</Header>
            {featureGroup.description && <p style={{ width: "90%", whiteSpace: "pre-line", fontSize: "120%" }}>{featureGroup.description}</p>}
          </Table.Cell>
        </Table.Row>

        {featureGroup.subFeatures.map((x, idx) => (
          <Table.Row
            style={{
              backgroundColor: idx % 2 ? "#FCF7E9" : null,
            }}
          >
            <Table.Cell 
              style={{ 
                display: "flex", 
                justifyContent: "space-between" 
              }}>
                {x.caption} 

                <div>
                  {x.available && <Label color="grey" style={{ marginRight: 10 }} size="tiny">Lanseras under {x.available}</Label>}
                  {/* <Popup trigger={<Icon style={{ color: "#89A275" }} name="info circle" />} content={x.tooltip} /> */}
                </div>
                
              </Table.Cell>
            {PLAN_TYPES.map((y) => y.visible && this.getCheckCell(x, y))}
          </Table.Row>
        ))}
      </React.Fragment>
    )
  }

  render() {

    const {
      openSegmentPlan,
      completedStepsPlan,
    } = this.props;

    const {
      showDemoConfirmModal,
    } = this.state;

    let planType = this.props.plan && this.props.plan.type;
    
    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],      
      next: [
        {
          caption: "Gå vidare",
          onClick: this.onClickNextStep,
          disabled: !planType,
        }
      ],
    };

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <Confirm
          open={showDemoConfirmModal}
          onCancel={this.toggleShowDemoConfirmModal}
          onConfirm={this.onSelectDemoPlan}
          header={"Bekräfta val av demokonto"}
          content={"Med ett demokonto får du full funktionalitet motsvarande paketet tolka under tre veckor för ett skifte. Under eller efter demoperioden kan du enkelt uppgradera för att låsa upp fler skiften och funktionalitet."}
          confirmButton="Fortsätt"
          cancelButton="Avbryt"
          size="tiny"
        />

        <GuideSegment
          style={{
            maxHeight: null,
          }}
          caption="Funktionspaket"
          open={openSegmentPlan === "functions"}
          complete={completedStepsPlan && completedStepsPlan.includes("functions")}
          onClickOpenSegment={() => this.setOpenSegment("functions")}
          onClickNextStep={() => this.gotoNextStep("functions", "yield")}
          hideDoneButton={true}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
            }}
          >
            Välj det paket av funktioner som passar din gård bäst.
          </p>

          <Segment secondary style={{ padding: "1em" }}>
            <Header as="h3" style={{ fontWeight: 500 }}>Demo</Header>
            <p
              style={{
                whiteSpace: "pre-line",
                width: "80%",
                marginBottom: "1em",
              }}
            >
              Om du är osäker kan du välja att skapa ett demokonto för din gård.
              Du får du funktionalitet som motsvarar paketet "Tolka" för ett skifte under tre veckor.
              Under och efter denna demoperiod kan du uppgradera för att låsa upp fler skiften och funktioner.
            </p>
                        
            <Button onClick={() => this.toggleShowDemoConfirmModal()}>
              Prova en demo
            </Button>
          </Segment>

          <Table 
            celled
            style={{
              marginBottom: 0,
              whiteSpace: "pre-line"
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ backgroundColor: "white" }}></Table.HeaderCell>
                {PLAN_TYPES.map((x) => x.visible && (
                  <Table.HeaderCell
                    style={{
                      textAlign: "center",
                      backgroundColor: "white",
                    }}
                  >
                    <Header 
                      as="h3" 
                      style={{ 
                        fontWeight: 500, 
                        fontSize: "125%", 
                        marginTop: "0.5em", 
                        color: x.disabled ? "grey" : null,
                      }}
                    >
                      {x.caption}
                    </Header>

                    <Button
                      disabled={x.disabled}
                      style={{
                        backgroundColor: planType !== x.name ? "#6B9D9F" : "#4C8440",
                        color: "white",
                      }}
                      onClick={() => this.onSelectPlan(x.name, x.caption)}
                    >
                      {planType !== x.name ? "Välj" : "Vald"}
                    </Button>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {PLAN_FEATURES.map(x => this.getTableRows(x))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell style={{ backgroundColor: "white", paddingTop: "4em" }}></Table.HeaderCell>
                {PLAN_TYPES.map((x) => x.visible && (
                  <Table.HeaderCell
                    style={{
                      textAlign: "center",
                      backgroundColor: "white",
                      paddingTop: "3em",
                    }}
                  >
                    <p style={{ 
                        color: x.disabled ? "grey" : null, 
                        fontWeight: 600, 
                        fontSize: "120%",
                        marginBottom: "2em",
                      }}
                    >
                      {x.price}
                    </p>

                    <Button
                      disabled={x.disabled}
                      style={{
                        backgroundColor: planType !== x.name ? "#6B9D9F" : "#4C8440",
                        color: "white",
                      }}
                      onClick={() => this.onSelectPlan(x.name, x.caption)}
                    >
                      {planType !== x.name ? "Välj" : "Vald"}
                    </Button>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Footer>
          </Table>          
        </GuideSegment>
      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader=""
        mainColumnBody=""    
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default RegistrationV1SelectPlan;