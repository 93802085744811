import React, { Component } from "react";

import GuideSegment from "@/components/guide/GuideSegment";

const INITIAL_STATE = {};

class GuideSegmentAdvancedMode extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {

    const {
      open,
      complete,
      disabled,
    } = this.props;

    return (
      <GuideSegment
        caption="Hjälp från Freja"
        simpleModeQuestion="Hur mycket hjälp vill du ha från Freja?"
        simpleModeDescription='Om du vill så kan Freja hjälpa till med att ange många inställningar automatiskt så att du slipper. Du kan också välja att alltid se alla steg i detalj för att hänga med bättre i hur styrfilen tas fram.'
        open={open}
        complete={complete}
        disabled={disabled}
        onClickOpenSegment={() => this.props.onClickOpenSegment()}
        onClickNextStep={() => this.props.onClickNextStep()}
        simpleModeOpenText="Jag vill se alla detaljer"
        simpleModeOnOpen={() => {
          this.props.setParentState({ guideMode: 'advanced' });
          this.props.onClickNextStep();
        }}          
        simpleModeSkipText="Jag vill att Freja löser det mesta"
        simpleModeOnSkip={() => {
          this.props.setParentState({ guideMode: 'basic' });
        }}
      />
    )
  }
};

export default GuideSegmentAdvancedMode;