import React, { Component } from "react";
import { 
  Header, 
  Table, 
  Popup, 
  Icon 
} from "semantic-ui-react";

import { getCurrentColor } from "@/components/fieldData/FieldDataColorScales";

const INITIAL_STATE = {
  selectedUnit: "partial"
};

class HarvestSegmentationVarianceAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  toggleUnit = () => {
    const currentValue = this.state.selectedUnit;
    this.setState({
      selectedUnit: currentValue === "partial" ? "total" : "partial"
    });
  };

  render() {
    const {
      layerId,
      statJson,
      dataStats,
      selectedGroup,
      colorScales,
      onSelectGroup,
      legendVisible,
      toggleLegendVisible,
    } = this.props;

    let segmentationStats = statJson && layerId && statJson.segmentation_variance && statJson.segmentation_variance[layerId];
    let groupsInPlot = dataStats && dataStats.groups && dataStats.groups.map((x) => x.idx);

    return (
      <div>
        <div
          style={{
            marginTop: 0,
            marginBottom: 0,
            padding: 0,
            display: "flex",
            justifyContent: "space-between",                
          }}
        >
          <Header 
            as="h4"
            style={{
              marginTop: 0,
              color: 'white',
              fontWeight: 500,
            }}        
          >
            Skördevariation{" "}
            <Popup
              position="top center"
              trigger={
                <Icon 
                  name="question circle" 
                  style={{ 
                    marginLeft: '0.25em', 
                    fontSize: '80%', 
                    lineHeight: '80%'
                  }} 
                />              
              }
              style={{
                zIndex: '1000',
              }}
              content={
                "Denna karta visar områden där skörden varierar mycket samt områden som över-/underpresterar från år till år."
              }
            />
          </Header>
          <Icon 
            style={{
              color: 'white',
              right: 0,
            }}
            name={legendVisible ? 'chevron down' : 'chevron up'} 
            onClick={() => toggleLegendVisible()}
          />       
        </div>

        {/* <Menu fluid secondary widths={2}>
          <Menu.Item name='partial' active={selectedUnit === 'partial'} onClick={this.toggleUnit}>Skördebidrag</Menu.Item>
          <Menu.Item name='total' active={selectedUnit === 'total'} onClick={this.toggleUnit}>Total skörd</Menu.Item>
        </Menu>      */}
        {legendVisible &&
          <Table inverted celled style={{ width: "100%", textAlign: "center", marginTop: 0 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell style={{ fontWeight: 500 }}>Skörd</Table.HeaderCell>
                <Table.HeaderCell style={{ fontWeight: 500 }}>Variation</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>

              {groupsInPlot && groupsInPlot.includes(1) &&
                <Table.Row
                  style={{
                    color:
                      selectedGroup !== null &&
                      (selectedGroup === parseInt(1) ? "black" : "grey")
                  }}
                  onClick={() => {
                    onSelectGroup(parseInt(1));
                  }}
                >
                  <Table.Cell
                    style={{
                      backgroundColor: getCurrentColor(
                        colorScales,
                        parseInt(1),
                        selectedGroup
                      )
                    }}
                  />
                  <Table.Cell>
                    {/* {meanYield && "mindre än " + meanYield.toFixed(1) + " ton/ha"} */}
                    Lägre än snitt
                  </Table.Cell>
                  <Table.Cell>
                    {segmentationStats && segmentationStats.lower_limit_variances &&
                      "+/- " +
                      segmentationStats.lower_limit_variances.toFixed(1) +
                        " ton/ha"}
                  </Table.Cell>
                </Table.Row>
              }

              {groupsInPlot && groupsInPlot.includes(2) &&
                <Table.Row
                  style={{
                    color:
                      selectedGroup !== null &&
                      (selectedGroup === parseInt(2) ? "black" : "grey")
                  }}
                  onClick={() => {
                    onSelectGroup(parseInt(2));
                  }}
                >
                  <Table.Cell
                    style={{
                      backgroundColor: getCurrentColor(
                        colorScales,
                        parseInt(2),
                        selectedGroup
                      )
                    }}
                  />
                  <Table.Cell>
                    {/* {meanYield && "mer än " + meanYield.toFixed(1) + " ton/ha"} */}
                    Högre än snitt
                  </Table.Cell>
                  <Table.Cell>
                    {segmentationStats && segmentationStats.lower_limit_variances &&
                      "+/- " +
                        segmentationStats.lower_limit_variances.toFixed(1) +
                        " ton/ha"}
                  </Table.Cell>
                </Table.Row>
              }

              {groupsInPlot && groupsInPlot.includes(3) &&
                <Table.Row
                  style={{
                    color:
                      selectedGroup !== null &&
                      (selectedGroup === parseInt(3) ? "black" : "grey")
                  }}
                  onClick={() => {
                    onSelectGroup(parseInt(3));
                  }}
                >
                  <Table.Cell
                    style={{
                      backgroundColor: getCurrentColor(
                        colorScales,
                        parseInt(3),
                        selectedGroup
                      )
                    }}
                  />
                  <Table.Cell>
                    {/* {meanYield && "i snitt " + meanYield.toFixed(1) + " ton/ha"} */}
                    Genomsnittlig
                  </Table.Cell>
                  <Table.Cell>
                    {segmentationStats && segmentationStats.upper_limit_variances &&
                      "+/- " +
                        segmentationStats.upper_limit_variances.toFixed(1) +
                        " ton/ha"}
                  </Table.Cell>
                </Table.Row>
              }
            </Table.Body>
          </Table>
        }
      </div>
    );
  }
}

export default HarvestSegmentationVarianceAnalysis;
