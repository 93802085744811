import React, { Component } from "react";

import {
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import {
  Container,
  Segment,
  Header,
  Table,
  Icon,
  Popup,
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import moment from 'moment';
import 'moment/locale/sv';

import { getCurrentYear } from "@/helpers/dates";
import { fetchWeatherData } from "@/helpers/weather";

import LoaderModal from "@/components/modals/LoaderModal";

const INITIAL_STATE = {
  sortIdx: 3,
  temperatureData: null,
  rainfallData: null,
  irradiationData: null,
};

class TimelinePage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    const {
      db,
      fileBucketRef,
    } = this.props;

    const weatherData = db && db.fieldsWeather;

    fetchWeatherData(weatherData, fileBucketRef, this.setParentState)
  };

  componentDidUpdate(prevProps) {
    const {
      db,
      fileBucketRef,
    } = this.props;

    const weatherData = db && db.fieldsWeather;

    if (!this.state.temperatureData && prevProps.db && (weatherData !== prevProps.db.fieldsWeather)) {
      fetchWeatherData(weatherData, fileBucketRef, this.setParentState)
    }
  };

  setParentState = (newState) => this.setState(newState);

  setSortIndex = idx => {
    if (idx === this.state.sortIdx) {
      this.setState({ sortIdx: -idx });
    } else {
      this.setState({ sortIdx: idx });
    };
  };

  sortFields = (a, b) => {
    const { 
      db,
    } = this.props;

    const {
      sortIdx,
    } = this.state;

    let fields = db && db.fields;
    let dashboardData = db && db.dashboard && db.dashboard.top_list_greenmass;

    const fieldA = fields && fields[a] && fields[a].name;
    const fieldB = fields && fields[b] && fields[b].name;

    const sizeA = fields && fields[a] && fields[a].field_size;
    const sizeB = fields && fields[b] && fields[b].field_size;

    const meanA = dashboardData && dashboardData.mean && dashboardData.mean[a];
    const meanB = dashboardData && dashboardData.mean && dashboardData.mean[b];

    const varianceA = dashboardData && dashboardData.variance && dashboardData.variance[a];
    const varianceB = dashboardData && dashboardData.variance && dashboardData.variance[b];
    
    switch (sortIdx) {
      case 1:
        return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
      case -1:
        return -1 * (fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0);
      case 2:
        return sizeB - sizeA;
      case -2:
        return sizeA - sizeB;
      case 3:
        return meanB - meanA;
      case -3:
        return meanA - meanB;
      case 4:
        return varianceB - varianceA;
      case -4:
        return varianceA - varianceB;
      default: 
        return -1;        
    };
  }; 

  render() {
    const { 
      db,
    } = this.props;

    const {
      sortIdx,
      temperatureData,
      rainfallData,
    } = this.state;

    let currentYear = getCurrentYear(true);

    const fieldsData = db && db.fields;
    const dashboardData = db && db.dashboard && db.dashboard.top_list_greenmass;
    const timelineData = db && db.dashboard && db.dashboard.timeline;

    let currentMeans = dashboardData && dashboardData.mean;
    let previousMeans = dashboardData && dashboardData.prev_mean;      

    let currentVariances = dashboardData && dashboardData.variance;
    let previousVariances = dashboardData && dashboardData.prev_variance;

    let hasLoadedData = isLoaded(db) && !isEmpty(db) && dashboardData;

    let fieldIds = currentMeans && Object.keys(currentMeans).sort((a, b) => this.sortFields(a, b));

    let plotLabels = temperatureData && Object.keys(temperatureData)
      .filter((x) => moment(x, "YYYY-MM-DD") >= moment(currentYear + "-03-01", "YYYY-MM-DD"))
      .filter((x) => moment(x, "YYYY-MM-DD") < moment(currentYear + "-09-01", "YYYY-MM-DD"))
      .sort();

    // let meanValuesDates = timelineData && timelineData.lai && Object.keys(timelineData.lai);
    // let datesGreenmass = meanValuesDates && meanValuesDates.filter((x) => moment(x, "YYYY-MM-DD") <= moment(currentYear + "-06-30", "YYYY-MM-DD"));

    let plotDataNDWI = timelineData && plotLabels && plotLabels.map((x) => timelineData.ndwi[x]);
    let plotDataGreenmass = timelineData && plotLabels && plotLabels.map((x) => timelineData.lai[x]);
    let plotDataPSRI = timelineData && plotLabels && plotLabels.map((x) => timelineData.psri[x]);

    // plotDataPSRI = datesGreenmass && plotDataPSRI && [...datesGreenmass.map((x) => null), ...plotDataPSRI];

    let plotData = { 
      labels: plotLabels,
      datasets: [
          {
            label: "Grönmassa",
            data: plotDataGreenmass,
            // fill: false,
            pointBorderColor: "transparent",
            backgroundColor: "transparent",
            borderColor: "#4C8440"
          },
          {
            label: "Fuktighet",
            data: plotDataNDWI,
            // fill: false,
            pointBorderColor: "transparent",
            backgroundColor: "transparent",
            borderColor: "#9CB5B6"
          },          
          {
            label: "Mognadsgrad",
            data: plotDataPSRI,
            // fill: false,
            pointBorderColor: "transparent",
            backgroundColor: "transparent",
            borderColor: "#F0BD38",
          },          
      ],
    };
    
    let plotOptions = { 
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: true, 
        position: "bottom",
      },
      series: {
        showPoints: false,
      }
    };

    let plotWeatherData = {
      labels: plotLabels,
      datasets: [
        {
          type: "line",
          label: "Medeltemperatur (grader)",
          data: plotLabels && temperatureData && plotLabels.map((x) => temperatureData[x]),
          // fill: false,
          pointBorderColor: "transparent",
          backgroundColor: "transparent",
          borderColor: "#D6946B",
        },
        {
          type: "bar",
          label: "Nederbörd (mm)",
          data: plotLabels && rainfallData && plotLabels.map((x) => rainfallData[x]),
          fill: false,
          backgroundColor: "#9CB5B6",
        },    
      ]
    };

    if (hasLoadedData) {
      return (
        <Container
          style={{
            paddingTop: 50,
            backgroundColor: "#f7f4f0",
            overflow: "hidden",
            width: "100vw",
          }}
        >
          <div
            style={{
              padding: 20,
              paddingTop: 0,
              marginLeft: "5px",
              marginRight: "15px",
            }}
          >
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "67%" }}>
                <Segment
                  style={{
                    padding: "1.5em",
                    fontWeight: "normal",
                  }}
                  loading={!plotDataGreenmass}
                >
                  <Header
                    as="h3"
                    style={{
                      paddingTop: 0,
                      fontWeight: 500,
                      color: "grey",
                    }}
                  >
                    Grödans tillväxt och mognad
                  </Header>

                  <p
                    style={{
                      width: "80%",
                      fontSize: "110%",
                      whiteSpace: "pre-line",
                    }}
                  >
                    Diagrammet nedanför visar hur grönmassan och fuktigheten varierar under tillväxten på din gård under mars till juni.
                    När grödan går över till att mogna växlar Freja över till att följa mognadsgraden och fuktigheten under juli till augusti.
                  </p>                     

                  <article 
                    className="canvas-container"
                    style={{
                      display: "block",
                      height: "40vh",
                      width: "100%",
                      padding: 0,
                      marginTop: 10,
                    }}          
                  >
                    {plotData.labels && <Line data={plotData} options={plotOptions} />}
                  </article>
                </Segment>

                <Segment
                  style={{
                    padding: "1.5em",
                    fontWeight: "normal",
                  }}
                  loading={!temperatureData}
                >
                  <Header
                    as="h3"
                    style={{
                      paddingTop: "0",
                      fontWeight: 500,
                      color: "grey",
                    }}
                  >
                    Medeltemperatur och nederbörd
                  </Header>

                  <p
                    style={{
                      width: "80%",
                      fontSize: "110%",
                      whiteSpace: "pre-line",
                    }}
                  >
                    Diagrammet nedanför visar hur medeltemperaturen och nedebörden varierar över säsongen.
                  </p>                      

                  <article 
                    className="canvas-container"
                    style={{
                      display: "block",
                      height: "40vh",
                      width: "100%",
                      padding: 0,
                      marginTop: 10,
                    }}          
                  >
                    {plotData.labels && <Line data={plotWeatherData} options={plotOptions} />}
                  </article>           
                </Segment>    
              </div>

              <div style={{ width: "32%" }}>
                <Segment
                  style={{
                    padding: "1.5em",
                    fontWeight: "normal",
                    height: "100%",
                  }}
                >
                  <Header
                    as="h3"
                    style={{
                      paddingTop: 0,
                      fontWeight: 500,
                      color: "grey",
                    }}
                  >
                    Topplista
                  </Header>

                  <p
                    style={{
                      fontSize: "110%",
                      whiteSpace: "pre-line",
                    }}
                  >
                    Tabellen nedanför visar snittvärdet för grönmassan och dess variation samt den senaste förändringen inom parantes.
                    Du kan sortera listan genom att klicka på rubriken i tabellen.
                  </p>           

                  <Table
                    fluid
                    sortable
                    selectable
                    striped
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell 
                          onClick={() => this.setSortIndex(1)}
                        >
                          Skiftesnamn
                          {sortIdx === 1 && <Icon name="sort ascending" />}
                          {sortIdx === -1 && <Icon name="sort descending" />}
                        </Table.HeaderCell>

                        <Table.HeaderCell                  
                          onClick={() => this.setSortIndex(2)}
                        >
                          Storlek
                          {sortIdx === 2 && <Icon name="sort ascending" />}
                          {sortIdx === -2 && <Icon name="sort descending" />}
                        </Table.HeaderCell>                        
                        
                        <Table.HeaderCell                       
                          onClick={() => this.setSortIndex(3)}
                        >
                          Grönmassa

                          <Popup
                            trigger={
                              <Icon style={{ marginLeft: 2 }} name="help circle" />
                            }
                            content="Grönmassan varierar beroende på hur tät grödan växer och får ett värde mellan 0 och 100 där ett högre värde betyder en tätare grönmassa."
                          />

                          {sortIdx === 3 && <Icon name="sort ascending" />}
                          {sortIdx === -3 && <Icon name="sort descending" />}
                        </Table.HeaderCell>
                        
                        <Table.HeaderCell                     
                          onClick={() => this.setSortIndex(4)}
                        >
                          Fältvariation

                          <Popup
                            trigger={
                              <Icon style={{ marginLeft: 2 }} name="help circle" />
                            }
                            content="Ett stort värde för fältvariationen betyder att grödan växer ojämt över fältet där vissa områden har tätare gröda än andra. Ett litet värde på fältvariationen betyder att all gröda på fältet är ungefär lika tät."
                          />                          
                          
                          {sortIdx === 4 && <Icon name="sort ascending" />}
                          {sortIdx === -4 && <Icon name="sort descending" />}                          
                        </Table.HeaderCell>                            
            
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {fieldIds && fieldIds.map((fieldId) => {

                        let fieldName = fieldsData && fieldsData[fieldId] && fieldsData[fieldId].name;
                        let fieldSize = fieldsData && fieldsData[fieldId] && fieldsData[fieldId].field_size;
                        fieldSize = fieldSize && Math.round(fieldSize);

                        let currentMean = currentMeans && currentMeans[fieldId] && 100.0 * currentMeans[fieldId];
                        let previousMean = previousMeans && previousMeans[fieldId] && 100.0 * previousMeans[fieldId];
                        let diffMean = currentMean && previousMean && 100.0 * (currentMean - previousMean) / previousMean;
                        
                        currentMean = currentMean && Math.round(currentMean);
                        previousMean = previousMean && Math.round(previousMean);
                        diffMean = diffMean && Math.round(diffMean);

                        let currentVariance = currentVariances && currentVariances[fieldId] && 100.0 * Math.sqrt(currentVariances[fieldId]);
                        let previousVariance = previousVariances && previousVariances[fieldId] && 100.0 * Math.sqrt(previousVariances[fieldId]);
                        let diffVariance = currentVariance && previousVariance && 100.0 * (currentVariance - previousVariance) / previousVariance;

                        currentVariance = currentVariance && Math.round(currentVariance);
                        previousVariance = previousVariance && Math.round(previousVariance);
                        diffVariance = diffVariance && Math.round(diffVariance);

                        return (
                          <Table.Row
                            key={"TimelinePageTableRow_" + fieldId}
                          >
                            <Table.Cell>{fieldName ? fieldName : "-"}</Table.Cell>
                            <Table.Cell>{fieldSize ? fieldSize.toFixed(0) + " ha" : "-"}</Table.Cell>

                            <Table.Cell>
                              {currentMean ? currentMean.toFixed(0) : "0"}
                              <span
                                style={{
                                  color: diffMean >= 0.0 ? "#4C8440" : "#C36126",
                                }}
                              >
                                {diffMean ? " (" + (diffMean > 0.0 ? "+" : "") + diffMean.toFixed(0) + "%)" : ""}
                              </span>
                            </Table.Cell>

                            <Table.Cell>
                              {currentVariance ? currentVariance.toFixed(0) : "0"}
                              <span
                                style={{
                                  color: diffVariance >= 0.0 ? "#4C8440" : "#C36126",
                                }}
                              >
                                {diffVariance ? " (" + (diffVariance > 0.0 ? "+" : "") + diffVariance.toFixed(0) + "%)" : ""}
                              </span>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                </Segment>                
              </div>
            </div>
          </div>
        </Container>
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      );
    }
  }
}

export default TimelinePage;
