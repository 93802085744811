import React, { Component } from "react";

import { UserContext } from "@/helpers/context";

import MapStatsSelectedPoint from "@/components/map/MapStatsWidget/MapStatsSelectedPoint";

import MapStatsBasic from "@/components/map/MapStatsWidget/MapStatsBasic";

import { HarvestClusterAnalysis } from "@/components/map/MapStatsWidget/HarvestClusterAnalysis";
import { HarvestPotentialAnalysis } from "@/components/map/MapStatsWidget/HarvestPotentialAnalysis";

import HarvestSegmentationMedianAnalysis from "@/components/map/MapStatsWidget/HarvestSegmentationMedianAnalysis";
import HarvestSegmentationVarianceAnalysis from "@/components/map/MapStatsWidget/HarvestSegmentationVarianceAnalysis";

import SentinelClusterAnalysis from "@/components/map/MapStatsWidget/SentinelClusterAnalysis";

import {
  SHOW_BASIC_STATS_FOR_LAYERS,
  SHOW_ADVANCED_STATS_FOR_LAYERS,
} from "@/constants/viewLayersAnalysis"

const INITIAL_STATE = {};

class MapStatsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {
    const {
      db,
      fieldId,
      fileBucketRef,
      layer,
      layerId,
      statJson,
      geoJsonData,
      markers,
      legendVisible,
      selectMarker,
      polygonImage,
      toggleLegendVisible,
      allMarkers,
      mode,
      cacheFieldData,
      updateCacheFieldData,
      selectedMarker,
    } = this.props;

    const isVraMapEditor = mode && mode.includes("vraMapEditor");
    const hasStatsToShow = SHOW_ADVANCED_STATS_FOR_LAYERS.includes(layer);

    let showBasicStats = false;

    SHOW_BASIC_STATS_FOR_LAYERS.forEach((x) => {
      showBasicStats = layer.includes(x) ? true : showBasicStats;
    });

    showBasicStats = layer.includes("_percent") ? false : showBasicStats;
    showBasicStats = layer.includes("_texture") ? false : showBasicStats;

    if (
      (geoJsonData || polygonImage || markers) && 
      !selectedMarker && 
      !hasStatsToShow && 
      (!showBasicStats && !layer.includes("vraMap"))
      ){
      return null
    };

    let clusterYears = db && fieldId && layerId && 
      db.fieldsYieldMapsProcessed &&
      db.fieldsYieldMapsProcessed &&
      db.fieldsYieldMapsProcessed.analysis_years_harvest_maps;

    if (!geoJsonData && !polygonImage && !markers) {
      return <div />
        // <div
        //   style={{
        //     color: "white"
        //   }}
        // >
        //   <Loader
        //     inline
        //     inverted
        //     active
        //     indeterminate
        //     size="small"
        //     style={{ paddingRight: "4.5em" }}
        //   />
        //   Öppnar datalager...
        // </div>
    }

    return (
      <div
        style={{
          position: "absolute",
          top: selectedMarker ? 64 : null,
          right: 8,        
          bottom: selectedMarker ? null : (isVraMapEditor ? 10 : -40),
          zIndex: 500,
          maxWidth: "25em",
          background: "#444444",
          boxShadow: "0 1px 2px 0 rgba(174, 179, 179, 0.15)",
          borderRadius: "0.28571429rem",
          color: "white",
          opacity: 0.98,
          padding: isVraMapEditor ? "0.75em" : "1em",
          paddingBottom: isVraMapEditor ? "0.75em" : (legendVisible || selectedMarker ? "1em" : "0em"),
          width: isVraMapEditor ? "6vw" : "18vw",
          minWidth: isVraMapEditor ? 220 : 300,
          fontSize: isVraMapEditor ? "80%" : null,
          maxHeight: "95%",
          overflowY: "auto",   
        }}
      >
        {selectedMarker && layer && !layer.includes("vraMap") &&
          <UserContext.Consumer>
            {user => (
              <MapStatsSelectedPoint
                db={db}
                fileBucketRef={fileBucketRef}
                fieldId={fieldId}
                allMarkers={allMarkers && allMarkers}
                marker={selectedMarker}
                toggleLegendVisible={toggleLegendVisible}
                legendVisible={legendVisible}
                onDeselect={() => selectMarker(null)}
                isFieldDataViewActive={hasStatsToShow}
                clusterYears={clusterYears}
                cacheFieldData={cacheFieldData}
                updateCacheFieldData={updateCacheFieldData}
              />
            )}
          </UserContext.Consumer>
        }

        {(!selectedMarker && showBasicStats) && 
          <MapStatsBasic 
            {...this.props} 
            field={this.props.field} 
            fieldInfo={this.props.fieldInfo} 
          />
        }

        {(statJson && !selectedMarker && 
          ["yieldAnalysis_potential"].includes(layer)) &&
          <HarvestPotentialAnalysis  {...this.props} />
        }

        {(statJson && !selectedMarker && 
          layer === "yieldAnalysis_segmentationMedian") &&
          <HarvestSegmentationMedianAnalysis {...this.props} />
        }

        {(statJson && !selectedMarker && 
          layer === "yieldAnalysis_segmentationVariance") &&
          <HarvestSegmentationVarianceAnalysis  {...this.props} />}

        {(statJson && !selectedMarker && 
          [
            "waterAnalysis_clustering", 
            "yieldAnalysis_clustering"
          ].includes(layer)) &&
          <HarvestClusterAnalysis
            {...this.props}
            clusterYears={clusterYears}
          />
        }

        {(statJson && !selectedMarker && [
            "greenmassAnalysis_clustering",
            "moistureAnalysis_clustering",
            "laiAnalysis_clustering",
            "psriAnalysis_clustering",
          ].includes(layer)) &&
          <SentinelClusterAnalysis
            {...this.props}
          />
        }
      </div>
    );
  };
};

export default MapStatsWidget;