import React, { Component } from "react";

import { 
  Modal,
  Table,
  Button
} from "semantic-ui-react";

import { 
  CALCULATION_LABELS_PHOSPHOROUS_V1,
  CALCULATION_LABELS_POTASSIUM_V1,
  CALCULATION_LABELS_LIME_STRUCTURE_V1,
  CALCULATION_LABELS_LIME_PH_V1,
  CALCULATION_LABELS_NITROGEN_V1,
} from "./constants";

class ComputationModal extends Component {

  state = {};

  render() {
    const {
      open,
      guideName,
      markerData,
    } = this.props;

    let exampleCalc = markerData && markerData.example_calc;

    let labels = {};
    labels = guideName === "limeStructureV1" ? CALCULATION_LABELS_LIME_STRUCTURE_V1 : labels;
    labels = guideName === "limePhV1" ? CALCULATION_LABELS_LIME_PH_V1 : labels;
    labels = guideName === "nitrogenV1" ? CALCULATION_LABELS_NITROGEN_V1 : labels;
    labels = guideName === "phosphorusV1" ? CALCULATION_LABELS_PHOSPHOROUS_V1 : labels;
    labels = guideName === "potassiumV1" ? CALCULATION_LABELS_POTASSIUM_V1 : labels;

    return (
      <Modal
        open={open}
        size="small"
      >
        <Modal.Header
          style={{
            fontWeight: 500,
          }}
        >
          Frejas beräkning av givan på en genomsnittlig punkt på skiftet
        </Modal.Header>
        <Modal.Content>
          <Table
            basic="very"
            striped
            size="small"
            columns="2"
          >
            {exampleCalc && labels && Object.keys(labels).map((x) => {
                if (x.includes("caption_")) {
                  return ( 
                    <Table.Row>
                      <Table.Cell
                        style={{
                          fontWeight: 600,
                          fontSize: "120%",
                        }}
                      >
                        {labels[x]}
                      </Table.Cell>
                      <Table.Cell />
                    </Table.Row>                    
                  )
                } else {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {labels[x].replace("BOLD", "")}
                      </Table.Cell>

                      <Table.Cell
                        style={{
                          fontWeight: labels[x].includes("BOLD") ? 700 : null,
                        }}
                        textAlign="center"
                      >
                        {exampleCalc[x] && typeof(exampleCalc[x]) === "number" ? exampleCalc[x].toFixed(2) : (exampleCalc[x] === 0 ? "0.0" : exampleCalc[x])}
                      </Table.Cell>                
                    </Table.Row>
                  )
                }
              
              })}
          </Table>

        </Modal.Content>
        <Modal.Actions
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <Button
            onClick={() => this.props.toogleShowCalculation()}
          >
            Stäng
          </Button>
        </Modal.Actions>

      </Modal>
    )
  }
}

export default ComputationModal;