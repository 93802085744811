import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";

import {
  Menu,
  Icon,
  Popup,
  Loader,
  Dropdown,
  Image,
} from "semantic-ui-react";

import FieldCartoon from "@/components/misc/FieldCartoon";

import MenuSecondary from "@/components/menus/MenuSecondary";
import MapFilteringWidget from "@/components/map/MapFilteringWidget";

import { FIELD_DATA_LAYERS, FIELD_ANALYSIS_LAYERS } from "@/constants/viewLayersAnalysis";

import "./style.css";

import iconInsights from "@/assets/icons/menuSecondary/freja-insights.svg";
import imgIconCompare2 from "@/assets/icons/menuCompare/compare2.png";
import imgIconCompare2correlation from "@/assets/icons/menuCompare/compare2correlation.png";
import imgIconCompare4 from "@/assets/icons/menuCompare/compare4.png";

const getLayerPosition = (position, noRows = 2) => (
  <div
    style={{
      display: "block",
      fontSize: "60%",
      marginRight: 10,
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Icon style={{ margin: 0 }} name={position === "upper left" ? "square" : "square outline"} />
      <Icon style={{ margin: 0 }} name={position === "upper right" ? "square" : "square outline"} />
    </div>

    {noRows === 2 &&
      <div
        style={{
          marginTop: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Icon style={{ margin: 0 }} name={position === "lower left" ? "square" : "square outline"} />
        <Icon style={{ margin: 0 }} name={position === "lower right" ? "square" : "square outline"} />
      </div>
    }
  </div>
)

class FieldDataTopMenu extends Component {

  state = {
    showMarkerFiltering: false,
    disableClickEscape: false,
  };

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!this.state.disableClickEscape && (!domNode || !domNode.contains(event.target))) {
      this.setState({
        showMarkerFiltering: false,
      });
    }
  };

  toggleShowMarkerFiltering = () => {
    let currentValue = this.state.showMarkerFiltering;
    this.setState({ showMarkerFiltering: !currentValue });

    if (!currentValue) {
      this.props.openFilteringMenu()
    };
  };

  setParentState = (newState) => {
    this.setState(newState);
  };

  resetMarkerFiltering = () => {
    this.props.onSettingsSave({
      intervalFilter: {
        min: null,
        max: null,
      },
      colorScaleType: "e",
      numberOfGroups: 5,
      isUsingFiltering: false,
    });

    this.toggleShowMarkerFiltering();
  };

  getCompareMenuItem = (plotName, layerPosition, layerName) => {
    const {
      activeView,
      showSelectLayerPlotName,
    } = this.props;

    let mainLayer = layerName ? layerName.split("/")[0] : "Välj lager...";
    let subLayer = layerName && layerName.split("/")[1];

    return (
      <Menu.Item
        className="menuSecondaryItem"
        style={{
          marginTop: 0,
          height: "55px",
          background: showSelectLayerPlotName === plotName ? "rgba(234, 204, 116, 0.25)" : "transparent",
          borderBottom: showSelectLayerPlotName === plotName ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
          fontWeight: showSelectLayerPlotName === plotName ? 500 : null,
          minWidth: "12%",
        }}
        onClick={() => this.props.toggleShowSelectLayer(plotName)}
      >
        {getLayerPosition(layerPosition, activeView === "compare2" ? 1 : 2)}
        <div
          style={{
            // padding: 8,
            // border: "1px solid darkgrey",
            // borderRadius: 4,
            width: "100%",
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <span>{mainLayer}</span>
            <span
              style={{
                display: subLayer ? "block" : null,
                fontSize: "70%",
                opacity: 0.5,
                marginTop: subLayer ? 3 : null,
              }}
            >
              {subLayer}
            </span>
          </div>

          <Icon 
            style={{
              marginLeft: 10,
              marginTop: "auto",
              marginBottom: "auto",
            }}
            name="caret down" 
            size="small" 
          />
        </div>
      </Menu.Item>
    );
  };

  getCompareMenu = () => {
    const {
      activeView,
      compareUpperLeftLayer,
      compareUpperRightLayer,
      compareLowerLeftLayer,
      compareLowerRightLayer,
    } = this.props;

    const FIELD_LAYERS = { ...FIELD_DATA_LAYERS, ...FIELD_ANALYSIS_LAYERS };

    let upperLeftLayer = compareUpperLeftLayer && compareUpperLeftLayer.split("_")[0];
    upperLeftLayer = upperLeftLayer && FIELD_LAYERS[upperLeftLayer];

    let upperRightLayer = compareUpperRightLayer && compareUpperRightLayer.split("_")[0];
    upperRightLayer = upperRightLayer && FIELD_LAYERS[upperRightLayer];
    
    let lowerLeftLayer = compareLowerLeftLayer && compareLowerLeftLayer.split("_")[0];
    lowerLeftLayer = lowerLeftLayer && FIELD_LAYERS[lowerLeftLayer];
    
    let lowerRightLayer = compareLowerRightLayer && compareLowerRightLayer.split("_")[0];
    lowerRightLayer = lowerRightLayer && FIELD_LAYERS[lowerRightLayer];    
    
    let upperLeftLayerName = upperLeftLayer && upperLeftLayer.caption;
    upperLeftLayerName = upperLeftLayer && upperLeftLayer.shortCaptions && upperLeftLayer.shortCaptions[compareUpperLeftLayer] ? 
    upperLeftLayerName + " / " + upperLeftLayer.shortCaptions[compareUpperLeftLayer] : 
    upperLeftLayerName;

    let upperRightLayerName = upperRightLayer && upperRightLayer.caption;
    upperRightLayerName = upperRightLayer && upperRightLayer.shortCaptions && upperRightLayer.shortCaptions[compareUpperRightLayer] ? 
    upperRightLayerName + " / " + upperRightLayer.shortCaptions[compareUpperRightLayer] : 
    upperRightLayerName;

    let lowerLeftLayerName = lowerLeftLayer && lowerLeftLayer.caption;
    lowerLeftLayerName = lowerLeftLayer && lowerLeftLayer.shortCaptions && lowerLeftLayer.shortCaptions[compareLowerLeftLayer] ? 
    lowerLeftLayerName + " / " + lowerLeftLayer.shortCaptions[compareLowerLeftLayer] : 
    lowerLeftLayerName;

    let lowerRightLayerName = lowerRightLayer && lowerRightLayer.caption;
    lowerRightLayerName = lowerRightLayer && lowerRightLayer.shortCaptions && lowerRightLayer.shortCaptions[compareLowerRightLayer] ? 
    lowerRightLayerName + " / " + lowerRightLayer.shortCaptions[compareLowerRightLayer] : 
    lowerRightLayerName;

    return (
      <React.Fragment>
        {this.getCompareMenuItem("compareUpperLeftLayer", "upper left", upperLeftLayerName)}
        {["compare2", "compare4"].includes(activeView) && this.getCompareMenuItem("compareUpperRightLayer", "upper right", upperRightLayerName)}
        {["compare2correlation", "compare4"].includes(activeView) && this.getCompareMenuItem("compareLowerLeftLayer", "lower left", lowerLeftLayerName)}
        {activeView === "compare4" && this.getCompareMenuItem("compareLowerRightLayer", "lower right", lowerRightLayerName)}
      </React.Fragment>
    )
  }

  render() {
    const {
      showMarkerFiltering,
    } = this.state;

    const {
      layer,
      fieldDb,
      activeView,
      fullscreenMode,
      lockedMap,
      dataType,
      compareUpperLeftLayer,
      compareUpperRightLayer,
      compareLowerLeftLayer,
      compareLowerRightLayer,
      loadingPDFlayers,
      insightsMode,
    } = this.props;

    // constants to check if a pdf is being generated for the active layers and then show loading status    
    // used to display loading symbol for currently loading layers and disable multiple download requests

    const getActiveLayersList = () => {
      let activeLayers = [compareUpperLeftLayer,
        compareUpperRightLayer,
        compareLowerLeftLayer,
        compareLowerRightLayer].filter(l => l != null)
      if (activeView === "compare1" || activeLayers.length === 0) {
        activeLayers = [layer]
      } else if (activeView === "compare2correlation") {
        activeLayers.push(compareUpperLeftLayer)
      }

      return (activeLayers)
    }
    const isLoading = loadingPDFlayers && typeof (loadingPDFlayers.has) === "function" && loadingPDFlayers.has(getActiveLayersList().toString())
    //const pdfText = "Ladda ned karta som pdf-fil för enkel utskrift."

    const pdfButton = () => {
      if (isLoading) {
        return (
          <Menu.Item
            className="menuSecondaryItem"
            style={{
              marginTop: 0,
              height: "55px",
            }}
          >
            <Popup
              trigger={
                <Loader active size="medium" ></Loader>
              }
              content="Laddar PDF..."
            />
          </Menu.Item>
        )
      }
      else {
        return (
          <Dropdown
            icon={null}
            disabled={!canMakePDF()}
            closeOnChange={true}
            trigger={
              <Popup
                disabled
                trigger={
                  <Menu.Item
                    className="menuSecondaryItem"
                    style={{
                      marginTop: 0,
                      height: "55px",
                      background: "transparent",
                      borderBottom: "4px solid transparent",
                    }}
                  >
                    <Icon size='large' name='share alternate' style={{ margin: 0 }} />
                  </Menu.Item>
                }
                content="Spara kartan som PDF eller maila den till dig själv."
              />              
            }
            >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="download"
                text='Hämta PDF till Freja'
                onClick={() => this.props.getPDFLocal()}
              />

              <Dropdown.Item
                icon="mail outline"
                text='Skicka PDF till mail'
                onClick={() => this.props.getPDFMail()}
              />
          </Dropdown.Menu>
        </Dropdown >
        )
      }
    }

    // // Helper function to conditionally render PDF button
    function canMakePDF() {
      const layersInView = {
        "compare2": [compareUpperLeftLayer, compareUpperRightLayer],
        "compare2correlation": [compareUpperLeftLayer, compareLowerLeftLayer],
        "compare4": [compareUpperLeftLayer, compareLowerLeftLayer, compareUpperRightLayer, compareUpperLeftLayer]
      }
      if (activeView in layersInView) {
        return (layersInView[activeView].every(currLayer => currLayer != null && currLayer !== "trueColor"))
      }

      return (layer != null && layer !== "trueColor")
    };

    let fieldCartoon = fieldDb && fieldDb.position && 
      <div 
        className="fieldCartoon"
        style={{
          backgroundColor: "#F8EED0",
          borderRadius: 5,
          width: "2.5em",
          height: "2.5em",
          marginRight: "0.4em",
        }}
      >
      <FieldCartoon 
        style={{
          display: "inline-block",
          margin: 0,
          padding: 0,            
          marginTop: "0.25em",
          marginLeft: "0em",
          width: "2em", 
          height: "2em",
        }}
        position={fieldDb.position} 
        stroke="#e0ba8c" 
        color="white"
      />
      </div> 

    let menuItems = [
      {
        name: 'selectField',
        href: '',
        onClick: (activeView === "selectLayer" || activeView.includes("compare")) && this.props.onClickSelectField,
        caption: (activeView !== "selectField" && fieldDb) ? fieldDb.name + " (" + fieldDb.field_size.toFixed(0) + " ha)" : 'Välj skifte',
        img: fieldCartoon,
        active: activeView === "selectField",
      },
    ];

    return (
      <React.Fragment>
        {showMarkerFiltering &&
          <div
            style={{
              position: "absolute",
              right: "0.5em",
              top: "45px",
              zIndex: 10000,
              padding: "1em",
              overflowY: "auto",
            }}
          >
            <MapFilteringWidget
              onSave={this.props.onSettingsSave}
              onReset={this.resetMarkerFiltering}
              markers={this.props.allMarkers}
              geoJsonFieldKey={this.props.geoJsonFieldKey}
              layer={this.props.layer}
              numberOfGroups={this.props.numberOfGroups}
              intervalFilter={this.props.intervalFilter}
              colorScaleType={this.props.colorScaleType}
            />
          </div>
        }      

        <MenuSecondary
          userId={this.props.userId}
          isAdminUser={this.props.isAdminUser}
          trueUserId={this.props.trueUserId}
          params={this.props.match.params}
          tutorialName={activeView.includes("compare") ? "DesktopCompareFirstLaunchV2" : (dataType === "layers" ? "DesktopLayersFirstLaunchV1" : "DesktopAnalysisFirstLaunchV1")}
          hideInsights={activeView !== "selectField"}
          hideNotifications={activeView.includes("compare")}
          hideUserMenu={activeView.includes("compare")}
          rightMenuItems={
            activeView !== "selectField" &&
              <React.Fragment>
                <Dropdown
                  icon={null}
                  closeOnChange={true}
                  trigger={
                    // TODO: Disable tooltip when menu is open but enable otherwise (For all menu items)
                    <Popup
                      disabled
                      trigger={
                        <Menu.Item
                          className="menuSecondaryItem"
                          style={{
                            marginTop: 0,
                            height: "55px",
                            background: activeView.includes("compare") ? "rgba(234, 204, 116, 0.25)" : "transparent",
                            borderBottom: activeView.includes("compare") ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                          }}
                          active={activeView.includes("compare")}
                        >
                          <Icon size='large' name='clone outline' style={{ margin: 0 }} />
                        </Menu.Item>
                      }
                      content="Jämför datalager sida-vid-sida för att hitta nya insikter."
                    />
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon="folder open outline"
                      text="Hämta jämförelse"
                      onClick={() => this.props.toggleShowComparePaletteModal()}
                    />

                    <Dropdown.Item
                      disabled={!activeView.includes("compare")}
                      icon="save outline"
                      text="Spara jämförelse"
                      onClick={() => this.props.toggleSavePaletteModal()}
                    />

                    <Dropdown.Divider />

                    <Dropdown.Item
                      image={{ src: imgIconCompare2, style: { marginTop: 3, height: 7 } }}
                      text="Jämför två lager"
                      onClick={() => this.props.onClickSelectCompare("compare2")}
                    />

                    <Dropdown.Item
                      image={{ src: imgIconCompare2correlation, style: { marginTop: 0.5, height: 14 } }}
                      text="Jämför två lager med tolkning"
                      onClick={() => this.props.onClickSelectCompare("compare2correlation")}
                    />

                    <Dropdown.Item
                      image={{ src: imgIconCompare4, style: { marginTop: 0.5, height: 14 } }}
                      text="Jämför fyra lager"
                      onClick={() => this.props.onClickSelectCompare("compare4")}
                    />

                    {activeView.includes("compare") && <Dropdown.Divider />}

                    {activeView.includes("compare") &&
                      <Dropdown.Item
                        icon="window close outline"
                        text="Stäng jämförelse"
                        onClick={() => this.props.onClickSelectCompare(null)}
                      />
                    }

                  </Dropdown.Menu>
                </Dropdown>

                {!activeView.includes("compare") &&
                  <Menu.Item
                    className="menuSecondaryItem"
                    name='filtering'
                    active={showMarkerFiltering}
                    style={{
                      textAlign: "center",
                      marginTop: 0,
                      height: 55,
                      width: 55,
                      background: showMarkerFiltering ? "rgba(234, 204, 116, 0.25)" : "transparent",
                      borderBottom: showMarkerFiltering ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                    }}
                    onClick={() => this.toggleShowMarkerFiltering()}
                  >
                    <Popup
                      disabled
                      trigger={
                        <Icon
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                          }}
                          size='large'
                          name='filter'
                        />}
                      content="Ändra skalor och filtrera punkter för att utforska datalager."
                    />
                  </Menu.Item>
                }
                
                <Dropdown
                  icon={null}
                  closeOnChange={true}
                  trigger={
                    <Popup
                      disabled
                      trigger={
                        <Menu.Item
                          className="menuSecondaryItem"
                          style={{
                            marginTop: 0,
                            height: "55px",
                            background: "transparent",
                            borderBottom: "4px solid transparent",
                          }}
                        >
                          <Icon size='large' name='map outline' style={{ margin: 0 }} />
                        </Menu.Item>
                      }
                      content="Inställningar för kartvisare."
                    />
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon={fullscreenMode ? "compress" : "expand"}
                      text={fullscreenMode ? "Stäng helskärmsläge" : "Öppna helskärmsläge"}
                      onClick={this.props.onToggleFullscreenMode}
                      disabled={activeView.includes("compare")}
                    />

                      <Dropdown.Item
                      icon={lockedMap ? "lock open" : "lock"}
                      text={lockedMap ? "Lås upp karta" : "Lås karta"}
                      onClick={this.props.onToggleLockedMap}
                    />  
                  </Dropdown.Menu>
                </Dropdown>

                {pdfButton()}

                {!activeView.includes("compare") && <Menu.Item />}

                <Menu.Item
                  className="menuSecondaryItem"
                  name='insights'
                  active={insightsMode}
                  onClick={() => this.props.toogleInsightsMode()}
                  style={{
                    textAlign: "center",
                    marginTop: 0,
                    height: 55,
                    width: 55,
                    background: insightsMode ? "rgba(234, 204, 116, 0.25)" : "transparent",
                    borderBottom: insightsMode ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                  }}
                >
                  <Image
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "center",
                    }}
                    src={iconInsights}
                  />
                </Menu.Item>                
            </React.Fragment>
          }          
        >  
          {menuItems.map((x, index) => {
            return (
              <Menu.Item
                className="menuSecondaryItem"
                key={"menuItem_" + x.name}
                disabled={x.disabled}
                active={x.active}
                onClick={() => x.href ? this.props.history.push(x.href) : x.onClick && x.onClick(x.name)}
                style={{
                  marginTop: 0,
                  height: "55px",
                  // // marginRight: "0.5em",
                  // paddingLeft: "3em",
                  // paddingRight: "3em",                    
                  background: x.active ? "rgba(234, 204, 116, 0.25)" : "transparent",
                  borderBottom: x.active ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                  fontWeight: x.active ? 500 : null,
                  width: activeView.includes("compare") ? "15em" : "25em",
                }}
              >
                {activeView !== "selectField" && x.img && x.img}

                {x.caption}

                {activeView !== "selectField" &&
                  x.name === "selectField" &&
                  <Icon name="caret down" size="small" />
                }

                {activeView === "selectField" &&
                  x.name === "selectField" &&
                  <Icon name="caret up" size="small" />
                }
              </Menu.Item>
            )
          })}

          {activeView.includes("compare") && this.getCompareMenu()}
        </MenuSecondary>
      </React.Fragment>
    );
  }
}

export default withRouter(FieldDataTopMenu);
