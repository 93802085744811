import React, { Component } from "react";

import { 
  Header, 
  Dropdown,
  Image,
  Table,
} from "semantic-ui-react";

import { N_GUIDE_CROPS, NO_ADDITIONAL_RATIONS } from "./constants";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

import nitrogenStrategyIllustration from "@/assets/illustrations/nitrogen_strategies.png";

const INITIAL_STATE = {};

class SelectStrategy extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    this.setDefaultSettings("crop");
  };

  onClickNextStep = () => {
    const {
      completedStepsSelectStrategy,
    } = this.props;

    this.props.setParentState({
      openSegmentSelectStrategy: '',
      completedStepsSelectStrategy: [...completedStepsSelectStrategy, "strategy"],
    });

    this.setDefaultSettings("strategy");

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment, data=null, forceUpdate=false) => {
    switch(newSegment) {
      case "crop":
        let selectedCropSettings = data && N_GUIDE_CROPS && N_GUIDE_CROPS.find((x) => x.key === data.value);
        let cropRegions = selectedCropSettings && selectedCropSettings.regions;
        let cropQualities = selectedCropSettings && selectedCropSettings.qualities;

        cropRegions && this.props.setParentState({ guideCropRegion: "middle"});
        cropQualities && this.props.setParentState({ guideCropQuality: "feed" });
        break;

      case "strategy":
        this.props.setParentState({ guideNoAdditionalRations: 1 });
        break;

      default:
        break;
    };
  };

  onChange = (data, onlyNumbers=true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  };

  setOpenSegment = (newSegment) => {
    const {
      openSegmentSelectStrategy
    } = this.props;

    (openSegmentSelectStrategy === newSegment) && this.props.setParentState({ openSegmentSelectStrategy: null });
    (openSegmentSelectStrategy !== newSegment) && this.props.setParentState({ openSegmentSelectStrategy: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedStepsSelectStrategy, 
    } = this.props;

    !completedStepsSelectStrategy.includes(currentSegment) && this.props.setParentState({
      completedStepsSelectStrategy: [...completedStepsSelectStrategy, currentSegment],
    });

    this.setDefaultSettings(newSegment);
    !completedStepsSelectStrategy.includes(newSegment) && this.setOpenSegment(newSegment);
    completedStepsSelectStrategy.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  onSelectCrop = (data) => {
    this.onChange(data, false);
    this.setDefaultSettings("crop", data);
  };

  onChangeNoAdditionalRations = (d) => {
    this.onChange(d, false);
    let selectedChoice = NO_ADDITIONAL_RATIONS && d.value && NO_ADDITIONAL_RATIONS.find((x) => x.value === d.value);

    selectedChoice && selectedChoice.norations && this.props.setParentState({ guideNoAdditionalRations: parseInt(selectedChoice.norations) });
    selectedChoice && selectedChoice.ration && this.props.setParentState({ guideRationSizes: selectedChoice.ration });
  };

  onChangeRationSizes = (d) => {
    const {
      guideRationSizes,
    } = this.props;

    let ration1 = guideRationSizes && guideRationSizes['1'] ? guideRationSizes['1'] : 0;
    ration1 = d.name === 1 ? d.value : ration1;

    let ration2 = guideRationSizes && guideRationSizes['2'] ? guideRationSizes['2'] : 0;
    ration2 = d.name === 2 ? d.value : ration2;

    let ration3 = guideRationSizes && guideRationSizes['2'] ? guideRationSizes['3'] : 0;
    ration3 = d.name === 3 ? d.value : ration3;

    let ration0 = 100 - ration1 - ration2 - ration3;
    
    this.props.setParentState({
      guideRationSizes: {
        '0': ration0,
        '1': ration1,
        '2': ration2,
        '3': ration3,
      },
    });
  };

  render() {

    const {
      guideCrop,
      guideCropRegion,
      guideCropQuality,
      guideNoAdditionalRations,
      guideRationSizes,
      openSegmentSelectStrategy,
      completedStepsSelectStrategy,
    } = this.props;
    
    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],      
      next: [
        {
          caption: "Gå vidare",
          hidden: completedStepsSelectStrategy.length < 2,
          onClick: this.onClickNextStep,
        }
      ],
    };

    let selectedCropSettings = guideCrop && N_GUIDE_CROPS && N_GUIDE_CROPS.find((x) => x.key === guideCrop);
    let cropRegions = selectedCropSettings && selectedCropSettings.regions;
    let cropQualities = selectedCropSettings && selectedCropSettings.qualities;


    let rationSizeOptions = [...Array(21).keys()].map((x) => ({
      key: x * 5,
      value: x * 5,
      text: (x * 5).toFixed(0) + "%",
    }));

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegment
          caption="Gröda"
          open={openSegmentSelectStrategy === "crop"}
          complete={completedStepsSelectStrategy && completedStepsSelectStrategy.includes("crop")}
          onClickOpenSegment={() => this.setOpenSegment("crop")}
          onClickNextStep={() => this.gotoNextStep("crop", "strategy")}
          disableNextStep={!guideCrop || (cropRegions && !guideCropRegion) || (cropQualities && !guideCropQuality)}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 15,
              width: "80%",
            }}
          >
            Välj den typ av gröda som du kommer att odla.
            Beroende på vilken gröda som du väljer kanske du även behöver ange region och förväntat proteinhalt.
            Denna information använder Freja för att beräkna en målgiva enligt Jordbruksverkets rekommendationer.
          </p>

          <Header 
            as="h6"
            style={{
              marginBottom: 5,
            }}
          >
            Gröda
          </Header>

          <Dropdown
            placeholder='Gröda'
            fluid
            selection
            options={N_GUIDE_CROPS}
            name="guideCrop"
            value={guideCrop}
            onChange={(e, d) => this.onSelectCrop(d)}
          />    

          {cropRegions &&
            <React.Fragment>
              <Header 
                as="h6"
                style={{
                  marginBottom: 5,
                }}
              >
                Region
              </Header>

              <Dropdown
                placeholder='Region'
                fluid
                selection
                options={cropRegions}
                name="guideCropRegion"
                value={guideCropRegion}
                onChange={(e, d) => this.onChange(d, false)}       
              />
            </React.Fragment>
          }
          
          {cropQualities &&
            <React.Fragment>
              <Header 
                as="h6"
                style={{
                  marginBottom: 5,
                }}
              >
                Proteinhalt
              </Header>

              <Dropdown
                placeholder='Proteinhalt'
                fluid
                selection
                options={cropQualities}
                name="guideCropQuality"
                value={guideCropQuality}
                onChange={(e, d) => this.onChange(d, false)}                
              />
            </React.Fragment>
          }
        </GuideSegment>                  

        <GuideSegment
          caption="Gödslingsstrategi"
          simpleModeQuestion="Vilken strategi vill du använda för din kvävegödsling?"
          simpleModeOpenText="Ange annan strategi"
          simpleModeSkipText="Grundgiva (50%) och kompletteringsgiva"
          open={openSegmentSelectStrategy === "strategy"}
          complete={completedStepsSelectStrategy && completedStepsSelectStrategy.includes("strategy")}
          onClickOpenSegment={() => this.setOpenSegment("strategy")}
          onClickNextStep={() => this.onClickNextStep()}
          simpleModeOnSkip={() => {
            this.props.setParentState({ 
              guideNoAdditionalRations: 1,
              guideRationSizes: {'0': 50.0, '1': 50.0, '2': 0.0, '3': 0.0},
            });

            this.onClickNextStep();
          }}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: "1em",
              width: "80%",
            }}
          >
            Freja kan hjälpa dig att planera för flera kompletteringsgivor.
            Grundgivan är oftast en så kallad rak giva som du kommer att kunna ange i nästa steg.
            Den resterande givan kan sedan fördelas på flera mindra givor.
            Nedanför ser du några exempel på strategier för flera givor när höstvete odlas.
          </p>
                    
          <Image
            centered
            src={nitrogenStrategyIllustration}
          />

          <Header 
            as="h6"
            style={{
              marginTop: "2em",
              marginBottom: 5,
            }}
          >
            Vilken gödslingsstrategi vill du använda?
          </Header>

          <Dropdown
            placeholder='Välj antal kompletteringsgivor...'
            fluid
            selection
            upward
            options={NO_ADDITIONAL_RATIONS}
            name="guideNoAdditionalRations"
            value={guideNoAdditionalRations.toFixed(0)}
            onChange={(e, d) => this.onChangeNoAdditionalRations(d)}                
          />

          <Header 
            as="h6"
            style={{
              display: "block",
              marginTop: "2em",
              marginBottom: 5,
            }}
          >
            Hur stor andel ska varje giva vara?
          </Header>

          <Table 
            stackable
            celled 
            size='small' 
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              width: "100%",
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Giva</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Andel</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="center">Grundgiva</Table.Cell>
                <Table.Cell textAlign="center">{guideRationSizes && guideRationSizes[0] ? guideRationSizes[0].toFixed(0) : "100"}%</Table.Cell>
              </Table.Row>
              {guideNoAdditionalRations && [...Array(guideNoAdditionalRations).keys()].map((x) => (
                <Table.Row>
                  <Table.Cell textAlign="center">Kompletteringsgiva {(x + 1).toFixed(0)}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <Dropdown 
                      placeholder='Välj andel...'
                      upward
                      fluid
                      selection
                      options={rationSizeOptions}
                      name={x + 1}
                      value={guideRationSizes && guideRationSizes[x + 1]}
                      onChange={(e, d) => this.onChangeRationSizes(d)}                
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </GuideSegment>
      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader="Gröda och strategi"
        mainColumnBody="För att kunna beräkna kvävebehovet och skapa dina styrfiler behöver Freja veta mer om vad du ska odla och hur många samt hur stora givor du tänker lägga. Svara på alla frågor för att gå vidare till nästa steg."
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default SelectStrategy;