import React, { Component } from "react";

import { 
  Button,
  Segment,
  Header,
  Dropdown,
  Input,
} from "semantic-ui-react";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideMapPreview from "@/components/guide/GuideMapPreview";
import GuideSegment from "@/components/guide/GuideSegment";

import WidgetMenu from "@/components/menus/WidgetMenu";

import { getAvailableLayerIds } from "@/helpers/dataFetcher";
import { getCurrentYear } from "@/helpers/dates";

import { CROP_CODES } from "@/constants/cropCodes";
import { FIRST_GREENMASS_YEAR } from "@/constants/settings";

const YIELD_OPTIONS = [
  { key: 'total', text: 'ton', value: 'total' },
  { key: 'perha', text: 'ton/ha', value: 'perha' },
];

const GREENMASS_OPTIONS = [
  { key: 'msavi', text: 'MSAVI', value: 'MSAVI' },
  { key: 'ndvi', text: 'NDVI', value: 'NDVI' },
  { key: 'lai', text: 'LAI', value: 'LAI' },
];

class GreenmassYield extends Component {
  state = {
    openSegment: '',
    completedSteps: [],
  };

  componentDidMount = () => {
    const {
      selectedFieldIds,
    } = this.props;

    !this.state.openSegment && selectedFieldIds && this.fetchGreenMapsStats(selectedFieldIds[0])
  }

  fetchGreenMapsStats = (fieldId) => {
    const {
      db,
      fileBucketRef,
    } = this.props;
    
    let filePath = db && fieldId && fieldId && db["fieldsSentinelHubRaw_" + fieldId];
    filePath = filePath && filePath.rawdata_sentinel_optical_info_json;

    filePath && fileBucketRef.child(filePath).getDownloadURL()
      .then(url => {
        window.fetch(url)
          .then(async res => {
            this.selectYearsFromStats(fieldId, await res.clone().json());
            // console.log(await res.clone().text())
          })
          .catch(error => console.log("Cannot get download URL: ", error.toString()))
      })
      .catch(error => console.log("Cannot get download URL: ", error.toString()))
  };

  selectYearsFromStats = (fieldId, data) => {
    const {
      db,
    } = this.props;
    
    let currentYieldMapYears = getAvailableLayerIds(db, fieldId, "yieldMap");
    
    let currentArtificialYieldMapYears = fieldId && db && db["fieldsYieldMapsArtificial_" + fieldId];

    currentArtificialYieldMapYears = currentArtificialYieldMapYears && 
      currentArtificialYieldMapYears.dates_to_use &&
      Object.keys(currentArtificialYieldMapYears.dates_to_use);

    let variances = data && data.variance_values;
    let currentYear = getCurrentYear();

    let greenmassYears = [...Array(20).keys()]
      .map((x) => parseInt(x) + FIRST_GREENMASS_YEAR)
      .filter((x) => x <= currentYear)
      .map((x) => x.toFixed(0));

    greenmassYears = greenmassYears && currentYieldMapYears ? 
      greenmassYears.filter((x) => !currentYieldMapYears.includes(x)) : greenmassYears;

    greenmassYears = greenmassYears && currentArtificialYieldMapYears ? 
      greenmassYears.filter((x) => !currentArtificialYieldMapYears.includes(x)) : greenmassYears;

    let layerIdArray = {};

    greenmassYears && greenmassYears.forEach((year) => {
      let relevantDates = variances && Object.keys(variances).filter((x) => x.includes(year) && x.includes("-06-"));
      relevantDates = relevantDates && relevantDates.sort((a, b) => variances[b].msavi - variances[a].msavi);
      
      layerIdArray = relevantDates && relevantDates.length > 0 ? 
        {...layerIdArray, [year]: [...relevantDates]} : 
        layerIdArray;
    });

    this.props.setParentState({ layerIds: {...this.props.layerIds, [fieldId]: layerIdArray} });
  };

  gotoNextYear = (fieldId, currentYear, processed=true) => {
    const {
      layerIds,
    } = this.props;

    // Mark current year as done for the field
    let newYears = fieldId && this.props.yearsProcessed[fieldId] ? 
      [...this.props.yearsProcessed[fieldId], currentYear] : 
      [currentYear];

    processed && this.props.setParentState({ yearsProcessed: {...this.props.yearsProcessed, [fieldId]: newYears} });

    // Check if this is the last year of the field, then move on to next field
    // otherwise change year
    let fieldLayerIds = layerIds && layerIds[fieldId]
    let layerIdYears = fieldLayerIds && Object.keys(fieldLayerIds).map((x) => x);
    let currentIndex = layerIdYears && layerIdYears.indexOf(currentYear);

    if ((currentIndex + 1) >= layerIdYears.length) {
      this.gotoNextSegment(fieldId);
    } else {
      let nextYear = layerIdYears[currentIndex + 1];
      
      this.props.setParentState({
        selectedYears: {...this.props.selectedYears, [fieldId]: nextYear},
      });
    };
  };

  onChange = (data, fieldName, fieldId, year, onlyNumbers=false) => {
    let currentValue = this.props[fieldName] && 
      this.props[fieldName][fieldId] ? this.props[fieldName][fieldId] : {}
    
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [fieldName]: {
        ...this.props[fieldName], 
        [fieldId]: {
          ...currentValue, 
          [year]: value
        }
      } 
    });
  };

  onClickUseDate = (fieldId, year, date) => {
    let currentValue = this.props.datesToUse;
    currentValue = currentValue && fieldId && currentValue[fieldId] ? currentValue[fieldId] : {};

    let newValue = currentValue && currentValue[year];

    if (newValue && newValue.includes(date)) {
      newValue = {...currentValue, [year]: currentValue[year].filter((x) => x !== date)};

    } else {
      newValue = currentValue[year] ? 
      {...currentValue, [year]: [...currentValue[year], date]} :
      {...currentValue, [year]: [date]};
    }

    this.props.setParentState({
      datesToUse: {
        ...this.props.datesToUse,
        [fieldId]: newValue,
      }
    })
  };

  setOpenSegment = (fieldId) => {
    (this.state.openSegment === fieldId) && this.setState({ openSegment: null });
    (this.state.openSegment !== fieldId) && this.setState({ openSegment: fieldId });
  };

  gotoNextSegment = (currentFieldId) => {
    const {
      selectedFieldIds,
    } = this.props;

    const {
      completedSteps, 
    } = this.state;

    this.setState({
      completedSteps: [...completedSteps, currentFieldId],
    });

    this.props.saveFieldInfoToDatabase(currentFieldId);

    let currentIndex = selectedFieldIds && selectedFieldIds.indexOf(currentFieldId);

    if ((currentIndex + 1) >= selectedFieldIds.length) {
      this.props.onClickNextStep();
    } else {
      let newFieldId = selectedFieldIds[currentIndex + 1];

      this.fetchGreenMapsStats(newFieldId);
      this.setOpenSegment(newFieldId);
    };
  };

  getMapSegment = (fieldId, noFieldLayerIds, fieldLayerIdsYear, fieldDatesToUse, selectedYear, plotNumber=0) => {
    const {
      db,
      userId,
      fileBucketRef,
      guideIndex,
    } = this.props;

    if (noFieldLayerIds && noFieldLayerIds < (plotNumber + 1)) {
      return (
        <div style={{ width: "30%" }} />
      )
    };

    let greenmassLayer = "greenmass_percent";
    greenmassLayer = guideIndex && guideIndex[fieldId] && guideIndex[fieldId][selectedYear] === "NDVI" ? "ndvi_percent" : greenmassLayer;
    greenmassLayer = guideIndex && guideIndex[fieldId] && guideIndex[fieldId][selectedYear] === "LAI" ? "lai_percent" : greenmassLayer;

    return (
      <Segment
        style={{
          margin: 0,
          width: "30%",
        }}
      >
        <GuideMapPreview
          db={db}
          fileBucketRef={fileBucketRef}
          mode="map"
          userId={userId}
          fieldId={fieldId}
          layer={noFieldLayerIds && noFieldLayerIds >= (plotNumber + 1) ? "trueColor" : null}
          layerId={noFieldLayerIds && noFieldLayerIds >= (plotNumber + 1) ? fieldLayerIdsYear[plotNumber] : null}
          hideInfoWidget={true}
          openLayer={() => {}}
          hideAttribution={true}
        />

        <div style={{ height: "0.5em" }} />

        <GuideMapPreview
          db={db}
          fileBucketRef={fileBucketRef}
          mode="map"
          userId={userId}
          fieldId={fieldId}
          layer={noFieldLayerIds && noFieldLayerIds >= (plotNumber + 1) ? greenmassLayer : null}
          layerId={noFieldLayerIds && noFieldLayerIds >= (plotNumber + 1) ? fieldLayerIdsYear[plotNumber] : null}
          hideInfoWidget={true}
          openLayer={() => {}}
          hideAttribution={true}
        />

        <Header
          as="h3"
          style={{
            margin: 0,
            marginTop: "0.5em",
            padding: 0,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {noFieldLayerIds && noFieldLayerIds >= (plotNumber + 1) ? fieldLayerIdsYear[plotNumber] : ""}
        </Header>

        <Button
          style={{
            display: "flex",
            marginTop: "0.5em",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: fieldDatesToUse && fieldDatesToUse.includes(fieldLayerIdsYear[plotNumber]) ? "#4C8440" : "#BF8136",
            color: "white",
          }}
          onClick={() => this.onClickUseDate(fieldId, selectedYear, fieldLayerIdsYear[plotNumber])}
        >
          {fieldDatesToUse && fieldDatesToUse.includes(fieldLayerIdsYear[plotNumber]) ? "Vald" : "Välj"}
        </Button>
      </Segment>
    )
  }

  render() {
    const {
      db,
      selectedFieldIds,
      guideCrop,
      guideIndex,
      guideYield,
      guideYieldUnit,
      datesToUse,
      selectedYears,
      layerIds,
      yearsProcessed,
    } = this.props;

    const {
      openSegment,
      completedSteps,
    } = this.state;

    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],      
      // next: [
      //   {
      //     caption: "Nästa",
      //     onClick: this.props.onClickNextStep,
      //   }
      // ],
    };

    let mainColumn = 
      <React.Fragment>
        {selectedFieldIds && selectedFieldIds.map((x, idx) => {
          let fieldName = db && db.fields && db.fields[x] && db.fields[x].name;
          fieldName = fieldName ? fieldName : "okänt skiftesnamn";

          let fieldLayerIds = layerIds && layerIds[x]
          let layerIdYears = fieldLayerIds && Object.keys(fieldLayerIds);
          let selectedYear = selectedYears && selectedYears[x] ? selectedYears[x] : (layerIdYears && layerIdYears[0]);
          
          let fieldLayerIdsYear = fieldLayerIds && fieldLayerIds[selectedYear];
          let noFieldLayerIds = fieldLayerIdsYear && fieldLayerIdsYear.length;

          let fieldDatesToUse = datesToUse && datesToUse[x] && datesToUse[x][selectedYear];
          
          let fieldInfo = db && db.fieldsInfo && x && db.fieldsInfo[x];
        
          let currentCropCode = fieldInfo && selectedYear && 
            fieldInfo.crop_code && fieldInfo.crop_code[selectedYear] ? 
            fieldInfo.crop_code[selectedYear] : '';
          
          let currentHarvestYield = fieldInfo && selectedYear && 
            fieldInfo.harvest_yields && fieldInfo.harvest_yields[selectedYear] ? 
            fieldInfo.harvest_yields[selectedYear] : '';

          let widgetMenuItems = layerIdYears && layerIdYears.map((y) => {
            let yearIsProcessed = yearsProcessed && yearsProcessed[x] && yearsProcessed[x].includes(y);
            let background = yearIsProcessed ? "green" : (selectedYear === y ? "yellow" : null);
            let color = yearIsProcessed ? "white" : (selectedYear === y ? "black" : "grey");
        
            return (
              {
                id: y,
                caption: y,
                color: color,
                active: selectedYear === y,
                background: background,
              }
            )
          });

          if (!layerIdYears || layerIdYears.length === 0) {
            return (
              <GuideSegment
                key={"GuideSegment_" + x}
                caption={fieldName}
                open={(openSegment === x) || (!openSegment && idx === 0)}
                complete={completedSteps && completedSteps.includes(x)}
                onClickOpenSegment={() => this.setOpenSegment(x)}
                onClickNextStep={() => this.gotoNextYear(x, selectedYear, false)}
                doneButtonText="Hoppa över år"
                compact={true}
              >
                Freja kan inte skapa några skördekartor för detta skifte. 
                Antagligen så har du redan laddat upp eller skapat skördekartor för alla år mellan 2013 tills idag.
              </GuideSegment>
            )          
          }

          return (
            <GuideSegment
              key={"GuideSegment_" + x}
              caption={fieldName}
              open={(openSegment === x) || (!openSegment && idx === 0)}
              complete={completedSteps && completedSteps.includes(x)}
              onClickOpenSegment={() => this.setOpenSegment(x)}
              onClickNextStep={() => this.gotoNextYear(x, selectedYear, true)}
              disableNextStep={
                ((!guideCrop[x] || !guideCrop[x][selectedYear]) && !currentCropCode) || 
                ((!guideYield[x] || !guideYield[x][selectedYear]) && !currentHarvestYield) || 
                (!fieldDatesToUse || fieldDatesToUse.length === 0)
              }
              onClickEdit={() => this.gotoNextYear(x, selectedYear, false)}
              editButtonText="Hoppa över år"
              compact={true}
            >
              {widgetMenuItems && 
                <Segment
                  style={{
                    marginTop: 0,
                    padding: 0,
                  }}
                >
                  <WidgetMenu 
                    compact 
                    menuItems={widgetMenuItems} 
                  />
                </Segment>
              }         

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "1.5em",
                }}
              >
                {this.getMapSegment(x, noFieldLayerIds, fieldLayerIdsYear, fieldDatesToUse, selectedYear, 0)}
                {this.getMapSegment(x, noFieldLayerIds, fieldLayerIdsYear, fieldDatesToUse, selectedYear, 1)}
                {this.getMapSegment(x, noFieldLayerIds, fieldLayerIdsYear, fieldDatesToUse, selectedYear, 2)}            
              </div>

              <Segment
                secondary
                style={{
                  marginTop: "1em",
                  marginBottom: 0,
                  padding: "1em",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "32%",
                    display: "flex",
                  }}
                >
                  <Header 
                    as="h6"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginRight: "1em",
                    }}
                  >
                    Grönmassaindex
                  </Header>

                  <Dropdown
                    style={{
                      zIndex: 9999,
                    }}      
                    fluid           
                    placeholder='Välj index att använda...'
                    selection
                    upward
                    options={GREENMASS_OPTIONS.filter((x) => !(selectedYear < 2017 && x.value === "LAI"))}
                    value={guideIndex && guideIndex[x] && guideIndex[x][selectedYear] ? guideIndex[x][selectedYear] : 'MSAVI'}
                    onChange={(e, d) => this.onChange(d, "guideIndex", x, selectedYear, false)}
                  />    
                </div>

                <div
                  style={{
                    width: "32%",
                    display: "flex",
                  }}
                >
                  <Header 
                    as="h6"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginRight: "1em",
                    }}
                  >
                    Gröda
                  </Header>

                  <Dropdown
                    style={{
                      zIndex: 9999,
                    }}              
                    fluid   
                    placeholder='Gröda'
                    selection
                    search
                    upward
                    options={CROP_CODES}
                    value={guideCrop && guideCrop[x] && guideCrop[x][selectedYear] ? guideCrop[x][selectedYear] : currentCropCode}
                    onChange={(e, d) => this.onChange(d, "guideCrop", x, selectedYear, false)}
                  />    
                </div>

                <div
                  style={{
                    width: "32%",
                    display: "flex",
                  }}
                >
                  <Header 
                    as="h6"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginRight: "1em",
                    }}
                  >
                    Total skörd (ton)
                  </Header>
                  
                  <Input
                    style={{
                      width: "50%",
                    }}
                    labelPosition='right'
                    label={
                      <Dropdown 
                        onChange={(e, d) => this.onChange(d, "guideYieldUnit", x, selectedYear, false)}    
                        value={guideYieldUnit && guideYieldUnit[x] && guideYieldUnit[x][selectedYear] ? guideYieldUnit[x][selectedYear] : 'total'}
                        options={YIELD_OPTIONS} 
                      />
                    }      
                    placeholder='Ange skörd på skiftet...'
                    value={guideYield && guideYield[x] && guideYield[x][selectedYear] ? guideYield[x][selectedYear] : currentHarvestYield}
                    onChange={(e, d) => this.onChange(d, "guideYield", x, selectedYear, true)}
                  />    
                </div>
              </Segment>              
            </GuideSegment>      
          )
        })}   
      </React.Fragment>

    let helpColumn =
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Grönmassa och skörd
        </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Freja har hittat molnfira grönmassamätningar i juni månad för dessa år.
          De åren som du redan har laddat upp eller skapat skördekartor för har även tagits bort.
          Välj de datum (minst ett och högst tre) där du tycker att variationen i grönmassa stämmer överens med variationen i skörd.
          Ange även gröda och skörden för att gå vidare.
        </p>
      </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        menuItems={menuItems}
      />     
    )
  }
}

export default GreenmassYield;
