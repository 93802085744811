import React, { Component } from "react";

import {
  Segment,
  Icon,
  Popup,
  Message,
} from "semantic-ui-react";

import { CROP_CODES } from "@/constants/cropCodes";
import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";
import { MISSING_DATA_LIMIT, LOW_QUALITY_LIMIT } from "@/constants/settings"

import FieldDataGuideAddCrop from "@/components/fieldData/FieldDataGuideAddCrop";

import "./style.css";

const INITIAL_STATE = {
  changeCropCode: false,
};

class MapOverviewInfoWidget extends Component {

  state = INITIAL_STATE

  componentDidUpdate = (prevProps) => {
    if (
      this.props.fieldId !== prevProps.fieldId ||
      this.props.layer !== prevProps.layer ||
      this.props.layerId !== prevProps.layerId
    ) {
      this.setState(INITIAL_STATE)
    }
  }

  toggleChangeCropCode = () => {
    let currentValue = this.state.changeCropCode;
    this.setState({ changeCropCode: !currentValue });
    this.props.setHideWeather(!currentValue);
  };

  getDateRange = (dates) => {
    if (!dates) {
      return null
    };

    let sortedDates = dates && dates.sort()
    let minDate = sortedDates && sortedDates[0];
    let maxDate = sortedDates && sortedDates[sortedDates.length - 1];

    return minDate + " - " + maxDate;
  };

  render() {
    const {
      db,
      fieldId,
      mode,
      layer,
      layerId,
      statJson,
      dataType,
      cropCode,
    } = this.props;

    const {
      changeCropCode
    } = this.state;

    let dataLayer = getCurrentDataLayer(layer);
    let geoJsonFieldKey = layer && dataLayer && dataLayer.geoJsonFieldKey && dataLayer.geoJsonFieldKey[layer];

    let rawYieldMap = db && fieldId && db["fieldsYieldMapsRaw_" + fieldId];
    let processedYieldMap = db && fieldId && db["fieldsYieldMapsProcessed_" + fieldId];

    let isArtificialYieldMap = rawYieldMap && rawYieldMap.artificial_yield_map_years;
    isArtificialYieldMap = layer && layer.includes("yieldMap") ? isArtificialYieldMap : null;
    isArtificialYieldMap = layerId && isArtificialYieldMap && isArtificialYieldMap.includes(layerId) ? true : false;

    let hideCropType = true;
    hideCropType = dataType === "layers" ? false : hideCropType;
    hideCropType = (dataType !== "layers" && layerId && !isNaN(layerId)) ? false : hideCropType;
    hideCropType = (dataType !== "layers" && layerId && ["all", "cereals"].includes(layerId)) ? false : hideCropType;
    hideCropType = layer && layer.includes("soilMap") ? true : hideCropType;
    hideCropType = layer && layer.includes("elevationMap") ? true : hideCropType;

    let date = this.props.date;

    let sentinelDates = layerId && statJson && statJson.dates && statJson.dates[layerId];
    let harvestDates = layerId && processedYieldMap && processedYieldMap.analysis_years_harvest_maps && processedYieldMap.analysis_years_harvest_maps[layerId];

    date = layer && layer.includes("greenmassAnalysis") ? (sentinelDates ? sentinelDates : "-") : date;
    date = layer && layer.includes("moistureAnalysis") ? (sentinelDates ? sentinelDates : "-") : date;
    date = layer && layer.includes("laiAnalysis") ? (sentinelDates ? sentinelDates : "-") : date;
    date = layer && layer.includes("psriAnalysis") ? (sentinelDates ? sentinelDates : "-") : date;

    let layerIsYieldMap = layer && layer.includes('yieldAnalysis') ? true : false;
    layerIsYieldMap = layer && layer.includes('yieldStatistics') ? true : layerIsYieldMap;
    layerIsYieldMap = layer && layer.includes('waterAnalysis') ? true : layerIsYieldMap;
    date = (layerIsYieldMap && harvestDates) ? harvestDates : date;

    let cropType = this.props.cropType;
    let canChangeCropCode = true;

    if (layer && layerId && !hideCropType && parseInt(layerId) < 1000) {
      let cropName = layerId && CROP_CODES.find((x) => x.value === layerId);
      cropType = cropName && cropName.shorttext && cropName.shorttext;
      canChangeCropCode = false;
    };

    if (layer && layerId && dataType !== "layers" && layerId === "all") {
      cropType = "Alla grödor"
      canChangeCropCode = false;
    };

    if (layer && layerId && dataType !== "layers" && layerId === "cereals") {
      cropType = "Stråsäd"
      canChangeCropCode = false;
    };

    let cloudCoverage = statJson && layerId && statJson.output_frac_pixels_cloudy && statJson.output_frac_pixels_cloudy[layerId];
    cloudCoverage = cloudCoverage ? cloudCoverage : 0.0;

    let dataQuality = rawYieldMap && rawYieldMap.data_quality && rawYieldMap.data_quality[layerId];
    dataQuality = dataQuality && dataQuality[geoJsonFieldKey];
    dataQuality = dataQuality ? dataQuality : 1.0;

    let showFewYieldMaps = layerIsYieldMap && date && date.length < 5;

    let fracMissingData = statJson && statJson.frac_missing_data && statJson.frac_missing_data;
    fracMissingData = fracMissingData && fracMissingData[layerId];
    fracMissingData = fracMissingData ? fracMissingData : statJson && layerId && statJson.output_frac_pixels_missing && statJson.output_frac_pixels_missing[layerId];
    fracMissingData = fracMissingData ? fracMissingData : 0.0;

    let showLowDataQuality = dataQuality && dataQuality < LOW_QUALITY_LIMIT;

    let showHighFracDataMissing = fracMissingData && !(fracMissingData === 0) && fracMissingData > MISSING_DATA_LIMIT;
    showHighFracDataMissing = showHighFracDataMissing === 0 ? false : showHighFracDataMissing;

    let showHighCloudCoverage = cloudCoverage && cloudCoverage > MISSING_DATA_LIMIT;

    let dataWarning = showLowDataQuality || showHighCloudCoverage || showHighFracDataMissing || showFewYieldMaps;

    let dataWarningPopup = dataWarning ? "Denna data för har bedömts ha dålig kvalité. " : "Denna data för har bedömts ha god kvalité. ";
    dataWarningPopup = typeof (cloudCoverage) === "number" ? dataWarningPopup + "Molntäcke: " + (100.0 * cloudCoverage).toFixed(0) + "%.\n " : dataWarningPopup;
    dataWarningPopup = typeof (dataQuality) === "number" ? dataWarningPopup + "Datakvalité: " + (100.0 * dataQuality).toFixed(0) + "%.\n " : dataWarningPopup;
    dataWarningPopup = typeof (fracMissingData) === "number" ? dataWarningPopup + "Saknad data: " + (100.0 * fracMissingData).toFixed(0) + "%. " : dataWarningPopup;
    dataWarningPopup = (showFewYieldMaps && date) ? dataWarningPopup + "Skiftet har endast " + date.length.toFixed(0) + " skördekartor uppladdade. Minst 5 är rekommenderat för att få bra tolkningar. " : dataWarningPopup;
    dataWarningPopup = dataWarningPopup.length === 0 ? "Inga datavarningar." : dataWarningPopup;

    let miniFormat = mode && (mode.includes("compare") || mode.includes("vraMapEditor"));

    return (
      <Segment
        basic
        inverted
        loading={!date && (!cropType && !hideCropType)}
        style={{
          textAlign: "center",
          marginTop: !miniFormat ? 10 : 5,
          background: "#444444",
          boxShadow: "0 1px 2px 0 rgba(174, 179, 179, 0.15)",
          borderRadius: "0.28571429rem",
          opacity: 0.95,
          padding: !miniFormat ? "1em" : "0.5em",
          paddingTop: !miniFormat ? "1em" : "0.75em",
          // transition: "height 0.3s",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {date &&
            <Popup
              style={{
                zIndex: "1000",
              }}
              trigger={
                <div
                  style={{
                    width: hideCropType ? "50%" : "33%",
                    fontSize: !miniFormat ? null : "80%",
                  }}
                >
                  <Icon name="calendar outline" size="big" />
                  <div style={{ marginTop: 8 }} />
                  {typeof (date) === "object" && date.length + " kartor"}
                  {typeof (date) === "string" && date}
                </div>
              }
            content={
              (dataType === "layers" ? "Denna data samlades in: " : "Denna tolkning/karta bygger på data insamlad: ") +
              (date && Array.isArray(date) && date.length > 0 ? [...date].sort().join(", ") : date) + 
              "."}
            />
          }

          {!hideCropType &&
            <Popup
              style={{
                zIndex: "1000",
              }}
              trigger={
                <div
                  style={{
                    cursor: canChangeCropCode ? "pointer" : null,
                    width: hideCropType ? "50%" : "33%",
                    fontSize: !miniFormat ? null : "80%",
                  }}
                  onClick={canChangeCropCode ? () => this.toggleChangeCropCode() : () => { }}
                >
                  <Icon name="leaf" size="big" />
                  <div style={{ marginTop: 8 }} />
                  {cropType ? (cropType.length > 12 ? cropType.substring(0, 12) + "..." : cropType) : "Okänd"}
                </div>
              }
              content={(cropType ? cropType : "Okänd gröda") + (canChangeCropCode ? " Klicka på ikonen för att ändra gröda som odlades detta år." : "")}
            />
          }

          <Popup
            style={{
              zIndex: "1000",
            }}
            trigger={
              <div
                style={{
                  width: hideCropType ? "50%" : "33%",
                  fontSize: !miniFormat ? null : "80%",
                }}
              >
                <Icon name={dataWarning ? "thumbs down outline" : "thumbs up outline"} size="big" />
                <div style={{ marginTop: 8 }} />
                {dataWarning ? "Dålig" : "Bra"}
              </div>
            }
            content={dataWarningPopup}
          />
        </div>

        {!hideCropType && !cropType && !miniFormat && <FieldDataGuideAddCrop {...this.props} />}
        {changeCropCode && <FieldDataGuideAddCrop
          {...this.props}
          currentCropCode={cropCode}
          open={true}
          toggleChangeCropCode={this.toggleChangeCropCode}
        />}

        {isArtificialYieldMap &&
          <Message
            style={{
              display: "flex",
              justifyContent: "space-between",
              whiteSpace: "pre-line"
            }}
          >
            Skapad från grönmassa.
            <Popup
              trigger={<Icon name="help circle" />}
              content="Denna skördekarta är skapad från grönmassamätningar och behöver därför inte överensstämma med den verkligen fördelningen av skörden. Använd med försiktighet."
            />
          </Message>
        }
      </Segment>
    );
  }
}

export default MapOverviewInfoWidget;
