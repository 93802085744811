
export const PLAN_TYPES = [
    { 
        name: 'explore', 
        caption: 'Utforska', 
        pricePerHectar: 39,
        priceFixed: 2000,
        price: "2000 kr + 39 kr/ha",
        validFor: {years: 1},
        maxFields: null,
        disabled: false,
        hasTrial: false,
        visible: true,
    },    
    { 
        name: 'analyze', 
        caption: 'Tolka', 
        pricePerHectar: 69,
        priceFixed: 2000,
        price: "2000 kr + 69 kr/ha",
        validFor: {years: 1},
        maxFields: null,
        disabled: false,
        hasTrial: true,    
        visible: true,    
    },
    { 
        name: 'control', 
        caption: 'Styra', 
        pricePerHectar: 89,
        priceFixed: 2000,
        price: "2000 kr + 89 kr/ha",
        validFor: {years: 1},
        maxFields: null,
        disabled: false,
        hasTrial: false,   
        visible: true,  
    },    
    { 
        name: 'demo', 
        caption: 'Demo', 
        price: "0 kr",
        pricePerHectar: 0,
        priceFixed: 0,
        validFor: {weeks: 3},
        maxFields: 1,
        visible: false,
    },      
];

export const PLAN_FEATURES = [
    {
        caption: "Datalager",
        description: "Freja samlar ihop information om din gård och dina skiften från många olika datakällor. Du kan även ladda upp egna karteringar till Freja. Det är även möjligt att utforska väder under olika växtsäsonger.",
        subFeatures: [
            {
                caption: "Satellitbilder",
                availableFor: ["all"],
                tooltip: "",
                available: null,
            },
            {
                caption: "Grönmassa",
                availableFor: ["all"],
                tooltip: "Följ hur grödan växer till under säsongen och se vilka delar som växer till snabbare än andra.",
                available: null,
            },
            {
                caption: "Fuktighetsmätningar",
                availableFor: ["all"],
                tooltip: "Följ hur fukten i marken och grödan varierar under säsongen.",
                available: null,
            },
            {
                caption: "Bladytamätningar",
                availableFor: ["all"],
                tooltip: "Följ hur biomassan växer till under säsongen och se vilka delar som växer till snabbare än andra.",
                available: null,
            },
            {
                caption: "Mognadsindex",
                availableFor: ["all"],
                tooltip: "Följ hur grödan mognar till och torkar upp under sommaren och hösten.",
                available: null,
            },                        
            {
                caption: "Höjddata",
                availableFor: ["all"],
                tooltip: "Se hur höjden och lutningen på ditt skifte ser ut.",
                available: null,
            },          
            {
                caption: "Historiskt/säsongens väder",
                availableFor: ["all"],
                tooltip: "Medeltemp, nederbörd, solinstrålning. Dygnsgrader, nederbörd, solinstrålning över säsonger.",
                available: null,
            },                
            {
                caption: "Digitala åkermarkskartan och egna markkarteringar",
                availableFor: ["all"],
                tooltip: "Tillgång till SLUs Digitala åkermarkskarta med lerhalt, sandhalt och silthalt för stora delar av Sverige. Möjlighet att ladda upp egna markkarteringar i Excelformat och textformat från exempelvis Hushållsningssällskapen.",
                available: null,
            },
            {
                caption: "Skördekarteringar",
                availableFor: ["all"],
                tooltip: "Möjlighet att ladda upp egna skördekartingar från AGCO, CLAAS, DataVäxt, John Deere, Topcon. Du ser torrvikt, våtvikt och vattenhalt för skörden över varje fält.",
                available: null,
            },
            {
                caption: "Observationer och insikter",
                availableFor: ["all"],
                tooltip: "Registrera skadeangrepp, grödstadie och bestockning. Skiftets historik och andra insikter",
                available: null,
            },            
        ]
    },
    {
        caption: "Utforska data",
        description: "Freja gör det enkelt att visa och jämföra alla datalager i en och samma visare. Du behöver inte längre använda flera olika program utan får alla lager presenterade på samma sätt. Frejas automatiserade tolkningar tar in information från flera år och datalager för att förenkla processen att hitta nya insikter.",
        subFeatures: [
            {
                caption: "Visa dina datalager i en och samma visare",
                availableFor: ["all"],
                tooltip: "",
                available: null,
            },
            {
                caption: "Filtrera och gruppera datapunkter",
                availableFor: ["all"],
                tooltip: "",
                available: null,
            },
            {
                caption: "Jämförelse av datalager",
                availableFor: ["all"],
                tooltip: "Jämför två datalager sida vid sida för att hitta nya insikter på dina skiften. Du kan exempelvis enkelt jämföra lerhaltskartan med skördekartor från olika år för att hur ser lerhalten påverkar skördens storlek.",
                available: null,
            },
            {
                caption: "Avancerade och automatiska jämförelser av datalager",
                availableFor: ["analyze", "control", "collaborate"],
                tooltip: "Spara jämförelser och använd färdiga paletter. Freja presenterar de starkaste kopplingarna med lagren.",
                available: null,
            },
            {
                caption: "Jämför datalager, insikter och observationer",
                availableFor: ["all"],
                tooltip: "Hitta nya insikter på dina skiften.",
                available: null,
            },
            {
                caption: "Tolkning av grönmassa och fuktighet under flera år",
                availableFor: ["analyze", "control", "collaborate"],
                tooltip: "Procentuella avvikelser och liknande områden",
                available: null,
            },
            {
                caption: "Tolkning av skörd och vattenhalt under flera år samt skördestatistik",
                availableFor: ["analyze", "control", "collaborate"],
                tooltip: "Skördepotential, liknande områden och avvikelser. Medelskörd, maximal skörd, skördevariation",
                available: null,
            },
            {
                caption: "Tolkning av skörd baserat på gröda",
                availableFor: ["analyze", "control", "collaborate"],
                tooltip: "Skördepotential, liknande områden och avvikelser",
                available: null,
            },   
            {
                caption: "Tolkning av markkartering",
                availableFor: ["analyze", "control", "collaborate"],
                tooltip: "Bördighet, näringsreserver och gödselbehov",
                available: "2022",
            },                                       
        ]
    },
    // {
    //     caption: "Övervakning",
    //     description: "Freja övervakar automatiskt dina skiften dagligen under växtodlingssäsongen och påminner när du behöver vara extra vaksam mot angrepp från djur och svampar. Du får också varningar om skiften eller delar av skiften har dålig tillväxt så att du snabbt kan sätta in åtgärder.",
    //     subFeatures: [
    //         {
    //             caption: "Varningar för risk för skadeangrepp",
    //             availableFor: ["analyze", "control", "collaborate"],
    //             tooltip: "Direkt i din mobil.",
    //             available: null,
    //         },
    //         {
    //             caption: "Varningar för avvikande grönmassa",
    //             availableFor: ["analyze", "control", "collaborate"],
    //             tooltip: "",
    //             available: null,
    //         },
    //         {
    //             caption: "Varningar för dålig tillväxt",
    //             availableFor: ["analyze", "control", "collaborate"],
    //             tooltip: "",
    //             available: null,
    //         },                                    
    //     ]
    // },
    {
        caption: "Exportera och dela",
        description: "Freja gör det enkelt att exportera kartor och tolkningar för utskrift eller för att skicka till kollegor, kompisar eller din rådgivare.",
        subFeatures: [
            {
                caption: "Exportera kartor som PDF-filer",
                availableFor: ["analyze", "control", "collaborate"],
                tooltip: "",
                available: null,
            },
            {
                caption: "Dela kartor och tolkningar med andra",
                availableFor: ["analyze", "control", "collaborate"],
                tooltip: "",
                available: null,
            },
            // {
            //     caption: "Skördeöversikt för skiften och gård.",
            //     availableFor: ["control", "collaborate"],
            //     tooltip: "Rapport med tolkningar av din skörd. Rapport med tolkningar av skörden på gårdens skiften.",
            //     available: null,
            // },                                          
        ]
    }, 
    {
        caption: "Skapa styrfiler",
        description: "Freja gör det riktigt enkelt att använda datalager och tolkningar för att skapa styrfiler för allt du behöver på din gård. Med några enkla knapptryckningar får du filer skapade som du kan ladda in i traktorns terminal med USB-minne eller trådlöst via Internet. Det är också enkelt att skapa styrfiler för flera skiften samtidigt, vilket spar mycket tid och energi.",
        subFeatures: [
            {
                caption: "Styrfiler för markbearbetning",
                availableFor: ["control", "collaborate"],
                tooltip: "",
                available: "2022",
            },
            {
                caption: "Styrfiler för sådd",
                availableFor: ["control", "collaborate"],
                tooltip: "Variera sådjupet och såmängden över skiftet",
                available: "2022",
            },
            {
                caption: "Styrfiler för gödning",
                availableFor: ["control", "collaborate"],
                tooltip: "Precisionsgödning för kväve, fosfor och kalium",
                available: null,
            },          
            {
                caption: "Styrfiler för kalkning",
                availableFor: ["control", "collaborate"],
                tooltip: "Precisionskalkning för struktur och pH",
                available: null,
            },                     
            {
                caption: "Bakgrundskartor för Yaras N-sensor",
                availableFor: ["control", "collaborate"],
                tooltip: "Använd skördepotentialen för att få ännu bättre skörd",
                available: null,
            },                                    
        ]
    },
    {
        caption: "Kom igång, support och utbildning",
        description: "Det ska vara enkelt att komma igång med att använda Freja. Därför lägger vi på AgriOpt mycket tid och energi på att hjälpa dig igång på flera olika sätt.",
        subFeatures: [
            {
                caption: "Digital uppstart",
                availableFor: ["all"],
                tooltip: "Två digitala möten för att få en personlig introduktion.",
                available: null,
            },
            {
                caption: "Digital uppföljning",
                availableFor: ["control", "collaborate"],
                tooltip: "Ytterligare två digitala möten för hjälp och vägledning.",
                available: null,
            },
            {
                caption: "Hjälpcenter",
                availableFor: ["all"],
                tooltip: "Steg-för-steg instruktioner för vanliga frågor.",
                available: null,
            },            
            // {
            //     caption: "Klart för start",
            //     availableFor: ["collaborate"],
            //     tooltip: "AgriOpt lägger upp din gård och laddar in de karteringar som du har. Vi kommer även ut till din gård för en utbildning på plats för dina anställda.",
            //     available: null,
            // },
            {
                caption: "Användarträffar",
                availableFor: ["all"],
                tooltip: "",
                available: null,
            },                 
            {
                caption: "Support via telefon och e-post",
                availableFor: ["all"],
                tooltip: "",
                available: null,
            },
            {
                caption: "Prioriterad support via telefon och e-post",
                availableFor: ["control", "collaborate"],
                tooltip: "",
                available: null,
            },                 
        ]
    },       
    {
        caption: "Plattformar",
        description: "",
        subFeatures: [
            {
                caption: "Freja på din dator, mobil och surfplatta",
                availableFor: ["all"],
                tooltip: "",
                available: null,
            },
            // {
            //     caption: "Flera användare på samma gård",
            //     availableFor: ["collaborate"],
            //     tooltip: "",
            //     available: "2022",
            // },
            // {
            //     caption: "Dela gården med din rådgivare",
            //     availableFor: ["control", "collaborate"],
            //     tooltip: "",
            //     available: "2022",
            // },            
            {
                caption: "Säker lagring av din data i norra Europa",
                availableFor: ["all"],
                tooltip: "",
                available: null,
            },         
        ]
    },   
]