import React, { Component } from "react";

import { 
  Header, 
  Segment,
  Icon,
  Button,
} from "semantic-ui-react";

class GuideSegment extends Component {

  state = {
    showSimpleMode: true,
  };
  
  render() {
    const {
      hidden,
      caption,
      open,
      complete,
      compact,
      children,
      disableNextStep,
      simpleModeQuestion,
      simpleModeDescription,
      simpleModeOpenText,
      simpleModeSkipText,
      onClickOpenSegment,
      onlyClickOpenSegmentIfComplete,
      onClickNextStep,
      doneButtonText,
      editButtonText,
      skipSimpleMode, 
    } = this.props;

    let showSimpleMode = skipSimpleMode && skipSimpleMode === true ? false : this.state.showSimpleMode;
    let disabled = !open && !complete;

    let segmentBackgroundColor = (open && simpleModeQuestion && showSimpleMode) ? "#9CB5B6" : null;
    segmentBackgroundColor = (!open && complete) ? "#89A275" : segmentBackgroundColor;

    let blockData;

    if (hidden) {
      return <div />
    };

    if (!simpleModeQuestion || !showSimpleMode) {

      blockData = 
        <React.Fragment>
          {(open && open === true) && children && children}
              
          {(open && open === true) &&
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: this.props.hideDoneButton ? null : ((compact || !children) ? "2em" : "5em"),
              }}
            >
              
              {(!this.props.onClickEdit && !this.props.onClickCalculation && !this.props.onClickCancel) && <div />}
              
              <div>
                {this.props.onClickEdit &&
                  <Button
                    style={{ 
                      marginTop: 10,
                      backgroundColor: "#767676", 
                      color: "white",
                    }}   
                    basic={this.props.editButtonBasic}               
                    size="large"
                    onClick={() => this.props.onClickEdit()}
                  >
                    {editButtonText ? editButtonText : "Redigera styrfil"}
                  </Button>
                }

                {this.props.onClickCalculation &&
                  <Button
                    basic
                    style={{ 
                      marginLeft: this.props.onClickEdit ? 10 : null,
                      marginTop: 10,
                      backgroundColor: "#767676", 
                      color: "white",
                    }}              
                    size="large"
                    onClick={() => this.props.onClickCalculation()}
                  >
                    Se beräkning
                  </Button>
                }                

                {this.props.onClickCancel &&
                  <Button
                    style={{ 
                      marginTop: 10,
                      backgroundColor: "#767676", 
                      color: "white",
                    }}                  
                    size="large"
                    onClick={() => this.props.onClickCancel()}
                  >
                    Avbryt
                  </Button>
                }  
              </div>            
              
              {!this.props.hideDoneButton &&
                <Button
                  primary
                  style={{
                    marginTop: 10,
                  }}
                  size="large"
                  disabled={disableNextStep}
                  onClick={() => onClickNextStep()}
                >
                  {doneButtonText ? doneButtonText : "Klar"}
                </Button>
              }
            </div>
          }
        </React.Fragment>

    } else {

      blockData = 
        <React.Fragment>
          {(open && open === true) &&
            <React.Fragment>
              <p
                style={{
                  whiteSpace: "pre-line",
                  marginTop: "5em",
                  marginBottom: simpleModeDescription ? "0.5em" : "1.5em",
                  fontSize: "175%",
                  textAlign: "center",
                  width: "80%",
                  marginLeft: "10%",
                }}
              >
                {simpleModeQuestion && simpleModeQuestion}
              </p>

              {simpleModeDescription && 
                <p
                  style={{
                    whiteSpace: "pre-line",
                    marginTop: "1em",
                    marginBottom: "1.5em",
                    fontSize: "110%",
                    textAlign: "center",
                    marginLeft: "20%",
                    width: "60%",
                  }}
                >
                  {simpleModeDescription && simpleModeDescription}
                </p>    
              }   
            </React.Fragment>       
          }

          {(open && open === true) &&
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "6em",
              }}
            >
              <Button
                inverted
                size="large"
                onClick={() => {
                  !this.props.simpleModeOnOpen && this.setState({ showSimpleMode: false });
                  this.props.simpleModeOnOpen && this.props.simpleModeOnOpen();
                  this.props.simpleModeAndOpen && this.props.simpleModeAndOpen();
                }}
              >
                {simpleModeOpenText ? simpleModeOpenText : "Ange"}
              </Button>

              <Button
                style={{
                  marginLeft: "1em",
                }}
                inverted
                size="large"
                onClick={() => {
                  this.props.onClickNextStep && this.props.onClickNextStep();
                  this.props.simpleModeOnSkip && this.props.simpleModeOnSkip();
                }}                
              >
                {simpleModeSkipText ? simpleModeSkipText : "Hoppa över"}
              </Button>            
            </div>
          }

        </React.Fragment>
    };

    let segmentTextColor = ((open && simpleModeQuestion && showSimpleMode) || (!open && complete)) ? "white" : null;
    segmentTextColor = disabled ? "grey" : segmentTextColor;

    let segmentClass = ((open && open === true) || (onlyClickOpenSegmentIfComplete && !complete)) ? null :"backgroundColorOnHover";
    segmentClass = disabled ? null : segmentClass;

    let segmentCursor = ((open && open === true) || (onlyClickOpenSegmentIfComplete && !complete)) ? null : "pointer";
    segmentCursor = disabled ? null : segmentCursor;

    return (
      <Segment
        className={segmentClass}
        style={{
          padding: "1.5em",
          backgroundColor: segmentBackgroundColor,
          color: segmentTextColor,
          transition: "max-height 1.5s",
          maxHeight: open && open === true ? "150vh" : "10vh",
          cursor: segmentCursor,
          ...this.props.style,
        }}
        onClick={((open && open === true) || (onlyClickOpenSegmentIfComplete && !complete)) ? () => {} : () => onClickOpenSegment()}
      >
        {!(open && simpleModeQuestion && showSimpleMode) &&
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: !disabled && complete ? "pointer" : null,
            }}
            onClick={!disabled && onClickOpenSegment && complete ? () => onClickOpenSegment() : () => {}}
          >
            <Header
              style={{
                fontWeight: 500,
                marginBottom: open && open === true ? null : -5,
                paddingBottom: open && open === true ? null : 0,
                color: segmentTextColor,
              }}
              as="h2"
            >
              {caption && caption}
            </Header>

            {complete &&
              <Icon
                className="iconButton"
                name={"check"}
                size="large"
                onClick={() => onClickOpenSegment && onClickOpenSegment()}
              />
            }

            {!disabled && onClickOpenSegment && !complete &&
              <Icon
                className="iconButton"
                name={(open && open === true) ? "chevron up" : "chevron down"}
                size="large"
                onClick={() => onClickOpenSegment()}
              />
            }
          </div>
        }
        
        {blockData && blockData}
      </Segment>
    );
  }
}

export default GuideSegment;
