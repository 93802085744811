export const getSafeFileName = (originalFileName) => {
    let newFileName;
    newFileName = originalFileName.toLowerCase();
    newFileName = newFileName.replace("å", "aa");
    newFileName = newFileName.replace("ä", "ae");
    newFileName = newFileName.replace("ö", "oe");
    newFileName = newFileName.replace("-", "_");
    newFileName = newFileName.replace(" ", "_");

    // Remove all special characters except _
    newFileName = newFileName.replace(/[^a-zA-Z0-9_.]/g, '_');

    return newFileName;
};