import React from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";

import { Confirm } from "semantic-ui-react";

import { cloudFunctions } from "@";

import GuidesTemplate from "@/containers/Guides/GuidesTemplate";
import MenuGuide from "@/components/guide/GuideMenu";

import SelectFields from "@/components/guide/GuideSelectFields";
import PhosphorousNeed from "@/components/guides/phosphorusV1/phosphorousNeed";
import AdjustFiles from "@/components/guide/GuideAdjustFiles";
import DownloadFiles from "@/components/guide/GuideDownloadFiles";

import { INITIAL_STATE_TEMPLATE } from "@/containers/Guides/GuidesTemplate";

import { CONTROL_GUIDES } from "@/constants/viewGuides";

import { getCurrentDate } from "@/helpers/dates";
import { reformatBooleansInPayload } from "@/helpers/guides";

const CURRENT_GUIDE = CONTROL_GUIDES.find((x) => x.name === "phosphorusV1");
const GUIDE_NAME = CURRENT_GUIDE.name;
const GUIDE_URL = CURRENT_GUIDE.url;
// const GUIDE_ICON = CURRENT_GUIDE.icon;

const GUIDE_PURPOSE = 'fosforkalka';
const COMPOUND_NAME = "Fosfor";
const COMPOUND_TYPE = "P";
const NO_COMPOUND_UNIT = 'kg P/ha';

const GUIDE_NO_VRA_MAP = "Freja bedömmer att detta skifte inte behöver fosforkalkas. Rätt fosforklass uppnås redan idag."


const INITIAL_STATE = {
  guideMode: 'basic',
  rationSize: 100,
  guideCompoundNutrientRation: 100.0,
  guideCrop: '',
  guideAimRation: {},
  guidePreviousRation: '0',
  guidePreviousRationCompoundType: 'none',
  guidePreviousRationCompoundWeight: '0',
  guideExpectedYield: {},
  guideExpectedYieldCalibration: {},
  showConfirmLeaveAdjustFiles: false,
  currentRationNo: 0,
  openSegment: 'guideMode',
  completedSteps: [],
};
class GuidePhosphorusPhV1 extends GuidesTemplate {

  state = {
    ...this.state,
    ...INITIAL_STATE,
  };

  onClickStartOver = () => {
    this.props.setGuidesWrapperState({ selectedFieldIds: [] });
    this.setState({ ...INITIAL_STATE_TEMPLATE, ...INITIAL_STATE });
    this.onSaveGuide(GUIDE_NAME, GUIDE_URL);
    this.onGotoPreviousStep(GUIDE_URL, "selectFields", false, { ...INITIAL_STATE_TEMPLATE, ...INITIAL_STATE });
  };

  getVraMapSuggestion = (selectedFieldIds) => {
    const {
      match: {
        params: {
          guideId,
        }
      },
      userId,
    } = this.props;

    const {
      rationSize,
      guidePreviousRation,
      currentRationNo,
      guideAimRation,
      guideExpectedYield,
      guideExpectedYieldCalibration,
    } = this.state;

    let guideSettings = {
      currentRationNo,
      expectedYield: guideExpectedYield,
      expectedYieldCalibration: guideExpectedYieldCalibration,
      ration: guideAimRation,
      previousRations: guidePreviousRation,
      rationSize: rationSize,
    };

    guideSettings = guideSettings && reformatBooleansInPayload(guideSettings);

    selectedFieldIds && selectedFieldIds.forEach((fieldId) => {

      this.clearVraMap(fieldId);

      cloudFunctions.httpsCallable('createVraMapSuggestion')(
        {
          userId: userId,
          fieldId: fieldId,
          guideId: guideId,
          endpoint: "/v1/getPhosphorusMap",
          payload: guideSettings,
        }
      )
        .catch(error => this.handleError(error));

      // window.fetch("http://localhost:5000/v1/getPhosphorusMap", {
      //   method: "post",
      //   body: JSON.stringify({
      //     user_name: userId,
      //     field_name: fieldId,
      //     guide_id: guideId,
      //     auth_token: "nnnn",
      //     payload: guideSettings,
      //   }),
      //   headers: {'Content-Type': 'application/json'}
      // })
      // .catch(error => console.log(error));      
    });
  };

  generateVraMapFile = (fieldId, geoJsonData) => {
    const {
      match: {
        params: {
          guideId,
        }
      },
      userId,
    } = this.props;

    const {
      currentRationNo,
    } = this.state;

    let guideCompoundNutrientRation = this.computeNutrientContent(COMPOUND_TYPE);

    const db = this.getDb();

    // Clear old files in database to trigger loading in frontend
    let rationString = typeof (currentRationNo) === "number" ? currentRationNo.toFixed(0) : "1";

    this.getUser().collection("guides").doc(guideId).update({
      ["downloadable_files_" + rationString + ".all_fields"]: '',
      ["downloadable_files_" + rationString + "." + fieldId]: '',
    });

    let geoJsonFieldKey = 'value';
    let compoundRatioContent = guideCompoundNutrientRation ? parseFloat(guideCompoundNutrientRation) : 100.0;

    let fieldName = db && fieldId && db.fields && db.fields[fieldId] && db.fields[fieldId].name;
    let dateString = getCurrentDate("YYYYMMDD");
    let fileName = fieldName.toLowerCase().replace(" ", "_") + "_fosfor_" + dateString;

    cloudFunctions.httpsCallable('createVraMapFiles')(
      {
        userId: userId,
        fieldId: fieldId,
        guideId: guideId,
        endpoint: "/v1/getVraMap",
        payload: {
          file_name: fileName,
          current_ration: currentRationNo.toFixed(0),
          layer_name: "Fosforgödsling",
          grid_names: ['fosforGiva'],
          geo_json_data: geoJsonData,
          geo_json_keys: [geoJsonFieldKey],
          compound_content: compoundRatioContent,
          nutrient_name: 'fosfor',
          color_map: "Spectral_r"
        }
      }
    )
      .then(res => console.log(res))
      .catch(error => this.handleError(error));
  };

  getFieldsWithoutPAlMap = () => {
    const db = this.getDb();

    let fields = db && db.fields;
    let fieldsSoilMaps = db && db.fieldsSoilMapsProcessed;

    let fieldIdsWithoutPAlMaps = fields && Object.keys(fields);

    fieldsSoilMaps && Object.keys(fieldsSoilMaps).forEach((x) => {
      let allKeys = [];

      let fieldSoilMaps = fieldsSoilMaps && x && fieldsSoilMaps[x];
      let uploadedKeys = fieldSoilMaps && fieldSoilMaps.rawdata_uploaded_soilmap_available_keys;

      uploadedKeys && Object.keys(uploadedKeys).forEach((year) => {
        allKeys = [...allKeys, ...uploadedKeys[year]];
      });

      if (allKeys.includes("p_al")) {
        fieldIdsWithoutPAlMaps = fieldIdsWithoutPAlMaps.filter((fieldId) => fieldId !== x);
      };
    });

    return fieldIdsWithoutPAlMaps ? fieldIdsWithoutPAlMaps : [];
  };

  getFieldsWithoutYieldMaps = () => {
    const db = this.getDb();

    let fields = db && db.fields;
    let fieldsYieldMaps = db && db.fieldsYieldMapsProcessed;

    let fieldIdsWithoutYieldMaps = fields && Object.keys(fields);

    fieldsYieldMaps && Object.keys(fieldsYieldMaps).forEach((x) => {
      let fieldYieldMaps = fieldsYieldMaps && x && fieldsYieldMaps[x];
      let uploadedYears = fieldYieldMaps && fieldYieldMaps.rawdata_harvest_maps_geojson;

      uploadedYears = uploadedYears && Object.keys(uploadedYears) && Object.keys(uploadedYears).length;

      if (uploadedYears && uploadedYears >= 1) {
        fieldIdsWithoutYieldMaps = fieldIdsWithoutYieldMaps.filter((fieldId) => fieldId !== x);
      };
    });

    return fieldIdsWithoutYieldMaps ? fieldIdsWithoutYieldMaps : [];
  };

  render() {
    const {
      match: {
        params: {
          layer,
          guideId,
        }
      },
      userId,
      userPlanType,
      cacheFieldData,
      updateCacheFieldData,
      isAdminUser,
    } = this.props;

    const {
      selectedFieldIds,
      guideSettings,
      currentRationNo,
    } = this.state;

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    let currentSettings = db && db.settings && db.settings.guides && db.settings.guides;
    let currentSettingsValue = currentSettings && currentSettings[GUIDE_NAME];

    let showFullGrid = currentSettingsValue &&
      typeof (currentSettingsValue['hide_help_text_col']) === "boolean" ?
      !currentSettingsValue['hide_help_text_col'] : true;

    let fieldId = null;

    let guide = guideId && db && db.guides && db.guides[guideId] && db.guides[guideId];

    let fieldsWithoutRelevantData = [...this.getFieldsWithoutPAlMap(), ...this.getFieldsWithoutYieldMaps()];

    let menuItems = [
      {
        name: "selectFields",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Välj skiften",
        description: "Markera de skiften som du vill använda denna guide för. Bara skiften med en uppladdad skördekartor samt uppladdad markkartering med fosforklasskarta kan hanteras av denna guide.",
        active: !layer || layer === "selectFields",
      },
      {
        name: "phosphorousNeed",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Fosforbehov",
        description: "Fyll i information för att beräkna fosforbehovet.",
        active: layer === "phosphorousNeed",
      },
      {
        name: "adjustFiles",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Justera förslag",
        description: "Godkänn och justera det styrfilsförslag som Freja har räknat fram åt dig.",
        active: layer === "adjustFiles",
      },
      {
        name: "downloadFiles",
        href: "",
        onClick: () => { },
        disabled: true,
        caption: "Hämta styrfiler",
        description: "Ladda ned en pdf-fil av styrfilen som du kan skriva ut samt styrfiler till de flesta terminaler på marknaden",
        active: layer === "downloadFiles",
      },
    ];

    let commonProps = {
      firebase: this.props.firebase,
      slideDirection: this.state.slideDirection,
      params: this.props.match.params,
      db,
      getUser: this.getUser,
      userId,
      guideId,
      guideUrl: GUIDE_URL,
      guideName: GUIDE_NAME,
      isAdminUser,
      fileBucketRef,
      showFullGrid,
      userPlanType,
      selectedFieldIds,
      toggleHelpTexts: () => this.toggleHelpTexts(GUIDE_NAME),
      cacheFieldData,
      updateCacheFieldData,
      setParentState: this.setParentState,
      currentMenuItem: menuItems.find((x) => x.active),
      currentRationNo: typeof (currentRationNo) === "number" && currentRationNo.toFixed(0),
    };

    return (
      <React.Fragment>
        <Confirm
          open={this.state.showConfirmLeaveAdjustFiles}
          onCancel={() => this.setState({ showConfirmLeaveAdjustFiles: false })}
          onConfirm={() => {
            this.onGotoPreviousStep(GUIDE_URL, "phosphorousNeed", true);
            this.setState({ showConfirmLeaveAdjustFiles: false });
          }}
          size="tiny"
          header={"Gå tillbaka"}
          content={"Om du går tillbaka från justera förslag kommer alla förändringar som du har gjort i styrfilsförslagen att försvinna. Är du helt säker på att du vill gå tillbaka?"}
          confirmButton={"Ja"}
          cancelButton={"Nej"}
        />

        <MenuGuide
          db={db}
          fieldId={fieldId}
          menuItems={menuItems}
        />

        {layer === "selectFields" &&
          <SelectFields
            {...commonProps}
            enforceSameCrop={false}
            onClickNextStep={() => this.onSaveGuide(GUIDE_NAME, GUIDE_URL, "phosphorousNeed")}
            onSelectField={this.onSelectField}
            fieldsWithoutRelevantData={fieldsWithoutRelevantData}
            fieldsWithoutRelevantDataReason="Detta skifte saknar markkartering med lättlösligt fosfor eller skördekartor och kan därför inte användas i denna guide."
            mainColumnBody="Markera alla de skiften som du vill skapa styrfiler för fosforgödsling till."
            requiredData="För att använda denna guide behöver du ha laddat upp eller skapat skördekartor från grönmassa. Guiden kräver också att du har laddat upp markkarteringar som innehåller mätningar av lättlösligt fosfor."
          />
        }

        {layer === "phosphorousNeed" &&
          <PhosphorousNeed
            {...this.state}
            {...commonProps}
            onClickPreviousStep={() => this.onGotoPreviousStep(GUIDE_URL, "selectFields", true)}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "adjustFiles", true)}

          />
        }

        {layer === "adjustFiles" &&
          <AdjustFiles
            {...this.state}
            {...commonProps}
            guidePurpose={GUIDE_PURPOSE}
            guideSettings={guideSettings}
            onClickPreviousStep={() => this.setState({ showConfirmLeaveAdjustFiles: true })}
            onClickNextStep={() => this.onGotoNextStep(GUIDE_URL, "downloadFiles", true)}
            tryGetVraMapSuggestionAgain={this.tryGetVraMapSuggestionAgain}
            generateVraMapFile={this.generateVraMapFile}
            computeSavings={this.computeSavings}
            saveAdjustedVraMapToDatabase={this.saveAdjustedVraMapToDatabase}
            descriptionNoSuggestion={GUIDE_NO_VRA_MAP}
            onClickStartOver={() => this.onClickStartOver()}
            geoJsonFieldKey={'value'}
            layer={'vraMap_phosphorus'}
            pureCompoundName={COMPOUND_NAME}
            currentRationNo={currentRationNo}
            compoundText={COMPOUND_NAME}
          />
        }

        {layer === "downloadFiles" &&
          <DownloadFiles
            {...this.state}
            {...commonProps}
            guide={guide}
            guidePurpose={GUIDE_PURPOSE}
            compoundUnit={NO_COMPOUND_UNIT}
            fileBucketRef={fileBucketRef}
            saveNoteToGuide={this.saveNoteToGuide}
            saveInsightForGuide={this.saveInsightForGuide}
            currentRationNo={currentRationNo}
            generateVraMapFile={this.generateVraMapFile}
            onClickStartOver={() => this.onClickStartOver()}
            menuItems={{
              previous: [
                {
                  caption: "Justera styrfiler",
                  onClick: () => this.onGotoPreviousStep(GUIDE_URL, "adjustFiles", false),
                }
              ],
              next: [
                {
                  caption: "Stäng guide",
                  onClick: this.onCloseGuide,
                },
              ],
            }}
          />
        }
      </React.Fragment>
    )
  }
}

export default compose(
  firestoreConnect(props => {
    const {
      selectedFieldIds,
    } = props;

    let results = [
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fields" },
        ],
        storeAs: 'fields'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsInfo" },
        ],
        storeAs: 'fieldsInfo'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsWeather", doc: "farm" },
        ],
        storeAs: 'fieldsWeather'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "insights" },
        ],
        storeAs: 'insights'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "guides" },
        ],
        storeAs: 'guides'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "reminders" },
        ],
        storeAs: 'reminders'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "profile" },
        ],
        storeAs: 'profile'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'settings'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSoilMapsProcessed" },
        ],
        storeAs: 'fieldsSoilMapsProcessed'
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsYieldMapsProcessed" },
        ],
        storeAs: 'fieldsYieldMapsProcessed'
      },
    ];

    selectedFieldIds && selectedFieldIds.forEach((fieldId) => {
      results = [...results,
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSoilMapsRaw", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSoilMapsRaw_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsMoistureMapsProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsMoistureMapsProcessed_' + fieldId
      },         
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSoilMapsProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSoilMapsProcessed_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsYieldMapsProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsYieldMapsProcessed_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSentinelHubRaw", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSentinelHubRaw_' + fieldId
      },
      {
        collection: 'users',
        doc: `${props.userId}`,
        subcollections: [
          { collection: "fieldsSentinelHubProcessed", doc: `${fieldId}` },
        ],
        storeAs: 'fieldsSentinelHubProcessed_' + fieldId
      },
      ];
    });

    return results;
  }),
  connect(store => {
    return {
      firestoreData: store.firestore.data
    };
  })
)(GuidePhosphorusPhV1);