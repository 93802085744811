import { getColorScales, getDataStats } from "@/components/fieldData/FieldDataColorScales";
import { DATA_LAYERS } from "@/constants/viewLayersAnalysis";
import { computeCorrelationMap } from "@/helpers/stats";
import { groupLabelGenerator } from "@/components/map/MapGroupsWidget/groupLabelGenerator";
import { GetDataFromAlias } from "@/constants/viewLayersAnalysis"


const getDateRange = (dates) => {
    if (!dates) {
        return null
    };

    let sortedDates = dates && dates.sort()
    let minDate = sortedDates && sortedDates[0];
    let maxDate = sortedDates && sortedDates[sortedDates.length - 1];

    return minDate + " - " + maxDate;
}

// // gets layerId for cases where the initial layerId is null or not a date
const getDateFromLayerId = (layer, layerId, fieldId, db) => {
    const sentinentalLayers = [
        'greenmassAnalysis',
        'greenmassAnalysis_clustering',
        'moistureAnalysis',
        'moistureAnalysis_clustering',
    ]
    if (sentinentalLayers.includes(layer)) {
        // If the currentLayer is an analysis, use the sentinel dates to get the date range i.e the layerId
        let currentProcessedSentinelMap = db && fieldId && db["fieldsSentinelHubProcessed_" + fieldId];
        let sentinelDates = currentProcessedSentinelMap &&
            currentProcessedSentinelMap.rawdata_sentinel_msavi_geojson &&
            Object.keys(currentProcessedSentinelMap.rawdata_sentinel_msavi_geojson).map(x => x)
        
        return (getDateRange(sentinelDates))

    }
    // check if layerId is not a date, then return null
    else if (isNaN(Date.parse(layerId))) {
        return (null)
    }
    return (layerId)

}

// creates all variables needed to create a PDF representation of the current layer(s)
export const getPDFvariables = (state, props, mapRefs, db) => {
    const {
        userId,
        match: {
            params: { fieldId }
        },
    } = props;

    const {
        intervalFilter,
        numberOfGroups,
        colorScaleType,
        activeView,
        compareUpperGeoJsonData,
        compareLowerGeoJsonData,
        compareUpperLeftLayer,
        compareLowerLeftLayer
    } = state;


    let payload = [];
    let mapStates = [];
    let loadingLayers;
    let layerNamesandIDs;

    // States for all layers
    mapRefs = mapRefs.filter(l => l != null)

    mapStates = mapRefs.map(l => l.state)
    layerNamesandIDs = mapRefs.map(l => [l.props.layer, l.props.layerId])

    if ([mapStates, layerNamesandIDs].some(entry => entry == null)) return

    // used to display loading symbol for currently loading layers and disable multiple download requests
    loadingLayers = layerNamesandIDs.map(l => l[0]).toString()

    mapStates && mapStates.forEach((currentMapState, i) => {

        let currentLayer = layerNamesandIDs[i][0]
        let currentLayerId = layerNamesandIDs[i][1]
        let dataFromDate = getDateFromLayerId(currentLayer, currentLayerId, fieldId, db)

        let geoJsonData;
        let statJson;
        let geoJsonFieldKey;
        let colorScales;
        let dataStats;
        let legendGroupLabels;

        if (activeView === "compare2correlation" && i === 2) {
            currentLayer = "correlation";

            let fieldDataLayerUpper = compareUpperLeftLayer && DATA_LAYERS[compareUpperLeftLayer.split("_")[0]];
            let geoJsonFieldKeyUpper = fieldDataLayerUpper && fieldDataLayerUpper.geoJsonFieldKey[compareUpperLeftLayer];

            let fieldDataLayerLower = compareLowerLeftLayer && DATA_LAYERS[compareLowerLeftLayer.split("_")[0]];
            let geoJsonFieldKeyLower = fieldDataLayerLower && fieldDataLayerLower.geoJsonFieldKey[compareLowerLeftLayer];
            let allMarkersUpper = compareUpperGeoJsonData && compareUpperGeoJsonData.features;

            allMarkersUpper && allMarkersUpper.sort(function (a, b) {
                return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
            });

            let allMarkersLower = compareLowerGeoJsonData && compareLowerGeoJsonData.features;

            allMarkersLower && allMarkersLower.sort(function (a, b) {
                return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
            });

            let correlationMap = computeCorrelationMap(allMarkersUpper, geoJsonFieldKeyUpper, allMarkersLower, geoJsonFieldKeyLower);
            correlationMap = correlationMap && correlationMap.map((x, idx) => ({ ...x, idx: idx }));
            colorScales = correlationMap && getColorScales("correlation", { features: correlationMap }, "correlation", "e", 5, null);
            dataStats = colorScales && correlationMap && getDataStats("correlation", colorScales, { features: correlationMap }, "correlation");
            geoJsonData = { features: correlationMap };
            geoJsonFieldKey = "correlation";


        } else {
            geoJsonFieldKey = currentMapState && currentMapState.geoJsonFieldKey && currentMapState.geoJsonFieldKey;
            geoJsonData = currentMapState && currentMapState.geoJsonData && currentMapState.geoJsonData;
            statJson = currentMapState && currentMapState.statJson && currentMapState.statJson;


            let sortedGeoJsonData = geoJsonData && geoJsonData.features && [...geoJsonData.features].map((x) => x);

            sortedGeoJsonData = sortedGeoJsonData && sortedGeoJsonData.sort(function (a, b) {
                return a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1];
            });


            sortedGeoJsonData = sortedGeoJsonData && { features: sortedGeoJsonData.map((x, idx) => ({ ...x, idx: idx })) };

            let filteredGeoJsonData = (intervalFilter && intervalFilter.min !== null && intervalFilter.max !== null)
                ? {
                    ...sortedGeoJsonData,
                    features: sortedGeoJsonData && sortedGeoJsonData.features
                        .filter(marker => marker.properties)
                        .filter(marker => {
                            let markerField = marker.properties[geoJsonFieldKey];
                            return ((intervalFilter.min <= markerField) && (intervalFilter.max >= markerField))
                        })
                }
                : sortedGeoJsonData;

            colorScales = filteredGeoJsonData && getColorScales(currentLayer, filteredGeoJsonData, geoJsonFieldKey, colorScaleType, numberOfGroups, undefined);
            dataStats = colorScales && sortedGeoJsonData && getDataStats(currentLayer, colorScales, sortedGeoJsonData, geoJsonFieldKey);

        }

        legendGroupLabels = dataStats["groups"].map(x => groupLabelGenerator({
            group: x,
            numberOfGroups: dataStats["groups"].length,
            layer: currentLayer,
            layerId: currentLayerId,
            statJson
        }));

        // extract title of map and captions from constant given the layer alias name in layer
        let layerFieldData = GetDataFromAlias(currentLayer)

        //Data used for PDF creation. 
        payload = [...payload,
        {
            userId: userId,
            fieldId: fieldId,
            layer: currentLayer,
            dataDate: dataFromDate,
            geoJsonFieldKey: geoJsonFieldKey,
            geoJsonData: geoJsonData,
            colorScales: colorScales,
            dataStats: dataStats,
            legendGroupLabels: legendGroupLabels,
            category: layerFieldData.category,
            caption: layerFieldData.caption,
            helpText: layerFieldData.helpText
        }
        ];

    });
    return { payload: payload, userId: userId, fieldId: fieldId, loadingLayers: loadingLayers }

};



