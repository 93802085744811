import React, { Component } from "react";

import { 
  Header,
  Progress,
  Table,
  Button,
  Segment,
  Icon
} from "semantic-ui-react";

import LoaderModal from "@/components/modals/LoaderModal";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";

import moment from 'moment';



class UploadSoilMapGuideHandleFiles extends Component {

  state = {
    uploading: false,
    uploadAgain: [],
  };

  toggleUploadAgain = (fileName) => {
    let currentValue = this.state.uploadAgain;

    if (currentValue.includes(fileName)) {
      let newValue = currentValue.filter((x) => x !== fileName);
      this.setState({uploadAgain: newValue})
    } else {
      this.setState({uploadAgain: [...currentValue, fileName]})
    }
  };

  uploadFiles = () => {
    const {
      db,
    } = this.props;

    let uploadedSoilMaps = db && db.fieldsSoilMapsUploaded;

    let pickedFiles = this.props.pickedFiles && Array.from(this.props.pickedFiles);
    
    pickedFiles = pickedFiles && pickedFiles.filter((file) => {
        let isUploaded = uploadedSoilMaps && !!Object.keys(uploadedSoilMaps).find(x => uploadedSoilMaps[x].original_file_name === file.name);

        return !isUploaded
    });

    pickedFiles = pickedFiles && pickedFiles.map((x) => x.name);

    this.props.uploadFiles(this.state.uploadAgain);
    this.setState({uploadedFiles: [...pickedFiles, ...this.state.uploadAgain]})
  }; 

  render () {
    const {
      db,
      pickedFilesUploadErrors,      
      uploading,
    } = this.props;

    const {
      uploadAgain,
      uploadedFiles,
    } = this.state;
    
    let uploadedSoilMaps = db && db.fieldsSoilMapsUploaded;

    let pickedFiles = this.props.pickedFiles && Array.from(this.props.pickedFiles);
    pickedFiles = pickedFiles && pickedFiles.sort((a, b) => {
      const textA = a.name && a.name.toUpperCase();
      const textB = b.name && b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    
    let noFiles = pickedFiles && pickedFiles.length;
    let totalPercentUploaded = 0;

    pickedFiles && pickedFiles.forEach((file) => {
      totalPercentUploaded += (this.props["progressUpload_" + file.name] ? this.props["progressUpload_" + file.name] / noFiles : 0);
    });

    totalPercentUploaded = totalPercentUploaded && Math.ceil(totalPercentUploaded);

    let menuItems = {
      previous: [
        {
        caption: "Tillbaka",
        onClick: this.props.onClickGoToStep1,
        disabled: this.props.uploading,
        }
      ],
      next: [
        {
        caption: "Ladda upp",
        onClick: this.uploadFiles,
        disabled: this.props.uploading,
        loading: this.props.uploading,
        }
      ]      
    };     

    let mainColumn = pickedFiles && 
      <React.Fragment>
        {this.props.uploading &&
          <Segment
            style={{
              padding: "1.5em",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "10%", height: "100%" }}>
              <Icon 
                style={{
                  marginTop: "0.5em",
                }}
                name="spinner" 
                loading 
                size="huge" 
              />
            </div>

            <div style={{ width: "90%"}}>
              <Header
                style={{
                  fontWeight: 500
                }}
              >
                Laddar upp filer...
              </Header>
              <p>
                Freja laddar upp dina filer. Stannar kvar på sidan sidan tills det är klart.
              </p>
              <Progress style={{ marginTop: 10 }} color="green" progress percent={totalPercentUploaded} />
            </div>
          </Segment>
        }

        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Filnamn</Table.HeaderCell>
              <Table.HeaderCell>Senast ändrad</Table.HeaderCell>
              <Table.HeaderCell>Filstorlek</Table.HeaderCell>
              <Table.HeaderCell>Status </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          
          <Table.Body>
            
          {pickedFiles && pickedFiles.map((file, idx) => {
              
              const isUploaded = uploadedSoilMaps && !!Object.keys(uploadedSoilMaps).find(fieldKey => uploadedSoilMaps[fieldKey].original_file_name === file.name);

              let showProgress = uploading;
              showProgress = (isUploaded && uploadAgain && !uploadAgain.includes(file.name)) ? false : showProgress;
              showProgress = (isUploaded && uploadedFiles &&  uploadedFiles.includes(file.name)) ? true : showProgress;

              const percent = this.props["progressUpload_" + file.name] ? this.props["progressUpload_" + file.name] : 0;
              const hasError = (pickedFilesUploadErrors && pickedFilesUploadErrors.includes(file.name)) ? true : false;

              return (
                  <Table.Row
                    key={idx}
                    style={{
                      padding: 12,
                      cursor: "pointer"
                    }}
                  >
                    <Table.Cell>
                      {file.name}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(file.lastModifiedDate).format("YYYY-MM-DD")}
                    </Table.Cell>
                    <Table.Cell>
                      {(file.size / 1000000).toFixed(2)} MB
                    </Table.Cell>
                    <Table.Cell>
                      {showProgress && "Laddar upp..."}
                      {!uploading && (!isUploaded || uploadAgain.includes(file.name)) && "Klar att laddas upp"}
                      {(isUploaded && !showProgress && !uploadAgain.includes(file.name)) && "Det finns en uppladdad fil med samma namn"}
                      {hasError && "Något gick fel"}
                    </Table.Cell>

                    <Table.Cell>
                      {(!uploading && isUploaded && !hasError && !uploadAgain.includes(file.name)) &&
                        <Button 
                          style={{ 
                            backgroundColor: "#C36126", 
                            color: "white",
                          }}                      
                          disabled={uploadAgain.includes(file.name)}
                          onClick={() => this.toggleUploadAgain(file.name)}
                        >
                          Ladda upp ändå
                        </Button>
                      }                    
                      
                      {showProgress &&
                        <Progress 
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          indicating 
                          success={!hasError}
                          error={hasError}
                          size="tiny" 
                          percent={percent} 
                        />
                      }
                    </Table.Cell>
                  </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </React.Fragment>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Uppladdade filer
        </Header>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Freja har hittat följande markkarteringar bland de filer
          som du valde att ladda upp. Grönt markerade kartor
          har laddats upp förut till Freja och har därmed redan
          kopplats till skiften.
        </p>   

        <Header
          as="h2"
          style={{
              marginTop: "1em",
              fontWeight: "normal",
              whiteSpace: "pre-line",
          }}
        >
          Ser det bra ut?
        </Header>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Om allt ser bra ut så klickar du på knappen ovan för att ladda upp markkarteringarna.
          Filer som redan har laddats upp kommer att hoppas över.
        </p>             
      </React.Fragment>
    
    if (pickedFiles) {
      return (
        <GuidesBasicGrid
          params={this.props.params}
          slideDirection={this.props.slideDirection}
          mainColumn={mainColumn}
          // mainColumnHeader="Välj skiften att skapa översikter för"
          helpColumn={helpColumn}
          showFullGrid={this.props.showFullGrid}
          toggleHelpTexts={this.props.toggleHelpTexts}
          menuItems={menuItems}
        />
      )
    }

    return (
      <LoaderModal caption="Hämtar data..." />
    )
  }
};

export default UploadSoilMapGuideHandleFiles;

