export const reformatBooleansInPayload = (payload) => {
  let newPayload = {...payload};

  newPayload && Object.keys(newPayload).forEach((x) => {
    if (typeof(newPayload[x]) === "boolean") {
      let reformattedBoolean = newPayload[x] ? "true" : "false";
      newPayload = { ...newPayload, [x]: reformattedBoolean }
    };
  });

  return newPayload;
};

export const checkForHumusInformation = (db, selectedFieldIds) => {

    let fieldIdsMissingHumus = [];

    selectedFieldIds && selectedFieldIds.forEach((x) => {
      let allKeys = [];
      
      let fieldInfoDb = db && x && db.fieldsInfo && db.fieldsInfo[x];
      let fieldSoilMaps = db && db['fieldsSoilMapsProcessed_' + x];
      
      let uploadedKeys = fieldSoilMaps && fieldSoilMaps.rawdata_uploaded_soilmap_available_keys;
      let humusContent = fieldInfoDb && fieldInfoDb.average_humus_content;
      
      uploadedKeys && Object.keys(uploadedKeys).forEach((year) => {
        allKeys = [...allKeys, ...uploadedKeys[year]];
      });

      if (!allKeys.includes("organic") && !humusContent) {
        fieldIdsMissingHumus = [...fieldIdsMissingHumus, x];
      };
    });

    return fieldIdsMissingHumus;
  };