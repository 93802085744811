import React from "react";
import { Header, Table, Popup, Icon } from "semantic-ui-react";

import { getCurrentColor } from "@/components/fieldData/FieldDataColorScales";

export const HarvestPotentialAnalysis = ({
  layer,
  layerId,
  statJson,
  selectedGroup,
  dataStats,
  colorScales,
  fieldSize,
  onSelectGroup,
  legendVisible,
  toggleLegendVisible,  
}) => {
  let yieldPotentialStats = statJson && layerId && statJson.yield_potential && statJson.yield_potential[layerId];
  
  let meanValue = yieldPotentialStats && yieldPotentialStats.mean && yieldPotentialStats.mean;
  let lowerCI = yieldPotentialStats &&yieldPotentialStats.low_ci && yieldPotentialStats.low_ci;
  let upperCI = yieldPotentialStats &&yieldPotentialStats.high_ci && yieldPotentialStats.high_ci;  
  let fraction = dataStats && dataStats.groups.map(x => x.samples.length / dataStats.noSamples);
  
  let meanValueArr = meanValue && Object.keys(meanValue).map(x => meanValue[x]);
  let lowerCIArr = lowerCI && Object.keys(lowerCI).map(x => lowerCI[x]);
  let upperCIArr = upperCI && Object.keys(upperCI).map(x => upperCI[x]);

  let meanHarvest = meanValueArr && fraction && meanValueArr.map((x, idx) => x * fraction[idx] * fieldSize);
  let lowerCIHarvest = lowerCIArr && fraction && lowerCIArr.map((x, idx) => x * fraction[idx] * fieldSize);
  let upperCIHarvest = upperCIArr && fraction && upperCIArr.map((x, idx) => x * fraction[idx] * fieldSize);

  if (selectedGroup !== null) {
    meanHarvest = meanHarvest && meanHarvest[selectedGroup];
    lowerCIHarvest = lowerCIHarvest && lowerCIHarvest[selectedGroup];
    upperCIHarvest = upperCIHarvest && upperCIHarvest[selectedGroup];
  } else {
    meanHarvest = meanHarvest && meanHarvest.reduce((a, b) => a + b);
    lowerCIHarvest = lowerCIHarvest && lowerCIHarvest.reduce((a, b) => a + b);
    upperCIHarvest = upperCIHarvest && upperCIHarvest.reduce((a, b) => a + b);
  }

  return (
    <div>
      <div
        style={{
          marginTop: 0,
          marginBottom: 0,
          padding: 0,
          display: "flex",
          justifyContent: "space-between",                
        }}
      >
        <Header 
          as="h4"
          style={{
            marginTop: 0,
            color: 'white',
            fontWeight: 500,
          }}        
        >
          Uppskattad skördepotential (ton/ha) {" "}
          <Popup
            position="top center"
            trigger={
              <Icon 
                name="question circle" 
                style={{ 
                  marginLeft: '0.25em', 
                  fontSize: '80%', 
                  lineHeight: '80%'
                }} 
              />              
            }
            style={{
              zIndex: '1000',
            }}
            content={
              "Denna karta visar den uppskattade skördepotentialen i varje område. Skördepotentialen baseras på historisk data som presenteras som intervall som den maximala skörden i 9 fall av 10 borde hamna inom."
            }
          />
        </Header>
        <Icon 
          style={{
            color: 'white',
            right: 0,
          }}
          name={legendVisible ? 'chevron down' : 'chevron up'} 
          onClick={() => toggleLegendVisible()}
        />       
      </div>     

      {legendVisible &&
        <Table 
          inverted 
          celled 
          style={{ 
            width: "100%", 
            textAlign: "center", 
            marginTop: 0,
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell style={{ fontWeight: 500 }}>Troligast</Table.HeaderCell>
              <Table.HeaderCell style={{ fontWeight: 500 }}>Lägst</Table.HeaderCell>
              <Table.HeaderCell style={{ fontWeight: 500 }}>Högst</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meanValue &&
              Object.keys(meanValue).map(index => (
                <Table.Row
                  key={"potential_table_row_" + index}
                  style={{
                    opacity:
                      selectedGroup !== null &&
                      (selectedGroup === parseInt(index) ? 1 : 0.5)
                  }}
                  onClick={() => {
                    onSelectGroup(parseInt(index));
                  }}
                >
                  <Table.Cell
                    style={{
                      backgroundColor: getCurrentColor(
                        colorScales,
                        parseInt(index),
                        selectedGroup
                      )
                    }}
                  />
                  <Table.Cell>
                    {meanValue &&
                      meanValue[index] !== null &&
                      meanValue[index].toFixed(1)}
                  </Table.Cell>
                  {/* + " ton/ha"} */}
                  <Table.Cell>
                    {lowerCI &&
                      lowerCI[index] !== null &&
                      lowerCI[index].toFixed(1)}
                  </Table.Cell>
                  {/* + " ton/ha"} */}
                  <Table.Cell>
                    {upperCI &&
                      upperCI[index] !== null &&
                      upperCI[index].toFixed(1)}
                  </Table.Cell>
                  {/* + " ton/ha"} */}
                </Table.Row>
              ))}
            <Table.Row
              style={{
                backgroundColor: "grey",
                color: "white",
                fontWeight: "normal",
              }}
            >
              <Table.Cell>Skörd</Table.Cell>
              <Table.Cell>
                {meanHarvest &&
                  meanHarvest !== null &&
                  meanHarvest.toFixed(0) + " ton"}
              </Table.Cell>
              <Table.Cell>
                {lowerCIHarvest &&
                  lowerCIHarvest !== null &&
                  lowerCIHarvest.toFixed(0) + " ton"}
              </Table.Cell>
              <Table.Cell>
                {upperCIHarvest &&
                  upperCIHarvest !== null &&
                  upperCIHarvest.toFixed(0) + " ton"}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      }
    </div>
  );
};
