import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import {
  firestoreConnect,
} from "react-redux-firebase";

import FieldDataUnlockFields from "@/components/fieldData/FieldDataUnlockFields";

import FieldManagementMain from "@/components/fieldManagement";

import GuidesAddGuide from "@/components/guideData/GuidesAddGuide";
import GuidesCollectData from "@/components/guideData/GuidesCollectData";
import GuidesFieldActiveList from "@/components/guideData/GuidesFieldActiveList";
import GuidesTypesList from "@/components/guideData/GuidesTypesList";
import GuidesTopMenu from "@/components/guideData/GuidesTopMenu";

import MenuFieldList from "@/components/menus/MenuFieldList";

import { COLLECT_GUIDES } from "@/constants/viewCollect";
import { CONTROL_GUIDES } from "@/constants/viewGuides";

const GUIDES = [...COLLECT_GUIDES, ...CONTROL_GUIDES];

const INITIAL_STATE = {
  activeView: "selectField",
  showAddPage: false,
  currentGuide: null,
  removedFieldIds: [],
  showUnlockFieldGuide: false,
  unlockFieldGuideFirstFieldId: null,
  collectDataError: null,
};

class GuidesPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    const currentField = this.props.match.params.fieldId;

    if (currentField) {
      this.setState({ activeView: "selectLayer" })
    } else {
      this.setState({ activeView: "selectField" })
    };
  };

  setParentState = (payload) => {
    this.setState(payload);
  };

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  toggleUnlockFieldGuide = (fieldId) => {
    let currentValue = this.state.showUnlockFieldGuide;
    
    this.setState({ 
      showUnlockFieldGuide: !currentValue,
      unlockFieldGuideFirstFieldId: fieldId ? fieldId : null,
    });
  };

  onDroppedCollectedFiles = (pickedFiles, uploadDataType) => {
    if (pickedFiles.length === 0) {
      this.setState({ collectDataError: "Inga filer accepterades. Har du valt rätt filtyp uppe till höger?" });
      return;
    } else {
      this.setState({ collectDataError: null });
    };

    let guideHref = GUIDES.find((x) => x.name === uploadDataType);
    guideHref = guideHref && guideHref.href && guideHref.href.replace("selectFields", "handleFiles").replace("gettingStarted", "handleFiles");

    this.props.history.push(
      guideHref,
      {pickedFiles: pickedFiles},
    );
  };  

  openField = (newFieldId) => {
    const {
      dataType,
    } = this.props;

    this.props.history.push("/" + dataType + "/" + newFieldId + (dataType === "collect" ? "/fieldInfo" : ""));    
    
    this.setState({ 
      showAddPage: false,
      activeView: "selectLayer",
    });
  };

  onClickSelectField = () => this.setState({ activeView: "selectField" });

  openLayer = (type, idx) => {
    const {
      match: { params },
      dataType,
    } = this.props;

    this.props.history.push("/" + dataType + "/" + params.fieldId + "/" + type + (idx ? "/" + idx : ""));
    
  };  

  onResumeGuide = (guideData, selectedGuideConstant) => {
    let guideHref = selectedGuideConstant && selectedGuideConstant.href
    guideHref = guideHref ? guideHref.replace("gettingStarted", guideData.current_step) : guideHref;
    guideHref = guideHref ? guideHref.replace("selectFields", guideData.current_step) : guideHref;
    guideHref = guideHref ? guideHref + "/" + guideData.id : guideHref;
    
    let savedStatePath = guideData && guideData.current_step_state_file_path && guideData.current_step_state_file_path;

    const fileBucketRef = this.props.firebase.storage().ref();

    savedStatePath && fileBucketRef.child(savedStatePath).getDownloadURL().then((url) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      
      xhr.onload = function(event) {
        // eslint-disable-next-line
        var blob = xhr.response;
      };
      xhr.open('GET', url);
      xhr.send();
    
      try {
        window.fetch(url).then(async (res) => {
          this.props.history.push(
            guideHref,
            await res.clone().json(),
          ) 
          // console.log(await res.clone().json())
        });
      } catch (error) {}
    }).catch(function(error) {
      // Handle any errors
    });

    !savedStatePath && this.props.history.push(guideHref)
  };  

  onAddGuide = (guideId) => {

    let selectedGuide = GUIDES.find((x) => x.name === guideId);

    if (selectedGuide && selectedGuide.href && selectedGuide.href.includes("https://")){
      var win = window.open(selectedGuide.href, '_blank');
      win.focus();
      
      return;
    };

    this.props.history.push(selectedGuide.href);
  };

  removeGuide = (guideId) => {
    const {
      removedFieldIds,
    } = this.state;

    this.setState({
      removedFieldIds: [...removedFieldIds, guideId]
    });

    guideId && this.getUser().collection('guides').doc(guideId).delete()
  };

  render() {
    const {
      match: {
        params: { fieldId, layer, layerId }
      },
      dataType,
      userId,
    } = this.props;

    const {
      activeView,
      currentGuide,
      showAddPage,
      removedFieldIds,
      showUnlockFieldGuide,
      unlockFieldGuideFirstFieldId, 
      collectDataError,     
    } = this.state;

    const db = this.getDb();
    const fields = db && db.fields;
    const fieldsInfo = db && db.fieldsInfo;
    const guides = db && db.guides && db.guides;
    const fileBucketRef = this.props.firebase.storage().ref();

    let guideIdsForField;
    
    guideIdsForField = (guides && fieldId && fieldId === "farm") ? Object.keys(guides)
      .filter((x) => !removedFieldIds.includes(x)) :
      guideIdsForField;

    guideIdsForField = (guides && fieldId && fieldId !== "farm") ? Object.keys(guides)
      .filter((x) => !removedFieldIds.includes(x))
      .filter((x) => (guides[x].field_id && guides[x].field_id === fieldId) || (guides[x].field_ids && guides[x].field_ids.includes(fieldId))) :
      guideIdsForField;
    
    let guidesForField = guides && guideIdsForField && guideIdsForField.map((x) => ({...guides[x], id: x}));

    let userPlanType = db && db.profile && 
      db.profile.plan && 
      db.profile.plan.plan_type && 
      db.profile.plan.plan_type;

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >    

        <FieldDataUnlockFields      
          db={db}
          getUser={this.getUser}
          userId={userId}
          fieldId={fieldId}
          open={showUnlockFieldGuide}
          firstFieldIdToUnlock={unlockFieldGuideFirstFieldId}
          onClose={this.toggleUnlockFieldGuide}
        />  

        <GuidesTopMenu 
          userId={this.props.userId}
          trueUserId={this.props.trueUserId}
          dataType={dataType}
          db={db}
          fieldId={fieldId}
          getUser={this.getUser}
          params={this.props.match.params}
          isAdminUser={this.props.isAdminUser}
          activeView={activeView}
          onClickSelectField={this.onClickSelectField}
          onClickAdd={() => this.props.history.push("/" + this.props.dataType)}
          addMode={!fieldId && showAddPage}          
        />
        
        <div        
          style={{
            height: "100vh",
            overflowY: "auto",
            paddingRight: "0.5em",
            paddingBottom: "2em",
            paddingLeft: "0.5em",
            minWidth: "25em",
            maxWidth: "25em",
            paddingTop: "70px",
          }}
        >
          {(activeView === "selectField" || dataType === "guides" ) &&
            <MenuFieldList
              db={db}
              userId={this.props.userId}
              fieldId={fieldId}
              openField={this.openField}
              showNewDataDots={false}
              hideFarmCard={false}
              openUnlockField={this.toggleUnlockFieldGuide}
            />
          }

          {activeView === "selectLayer" && dataType === "collect" &&
            <GuidesTypesList
              userId={this.props.userId}
              db={db}
              fieldId={fieldId}
              layer={layer}
              layerId={layerId}
              dataType={dataType}             
              openLayer={this.openLayer}
              isCompare={false}
            />
          }           
        </div>

        {fieldId && !currentGuide && dataType === "guides" &&
          <GuidesFieldActiveList
            db={db}
            fields={fields}
            fieldId={fieldId}
            dataType={dataType}
            guidesForField={guidesForField}
            removeGuide={this.removeGuide}
            onResumeGuide={this.onResumeGuide}
          />
        }

        {!fieldId && !currentGuide && dataType === "guides" &&
          <GuidesAddGuide
            db={db}
            setParentState={this.setParentState}
            dataType={dataType}
            userPlanType={userPlanType}
            onAddGuide={this.onAddGuide}
          />
        }

        {!fieldId && !currentGuide && dataType === "collect" &&
          <GuidesCollectData
            db={db}
            setParentState={this.setParentState}
            dataType={dataType}
            userPlanType={userPlanType}
            onAddGuide={this.onAddGuide}
            onDroppedCollectedFiles={this.onDroppedCollectedFiles}
            collectDataError={collectDataError}
          />
        };

        {fieldId && dataType === "collect" && 
          <FieldManagementMain
            db={db}        
            fields={fields}      
            field={fields && fields[fieldId]}
            fieldInfo={fieldsInfo && fieldsInfo[fieldId]}
            fieldId={fieldId}
            userId={userId}
            layer={layer}
            layerId={layerId}
            getDb={this.getDb}
            getUser={this.getUser}
            dataType={dataType}
            guidesForField={guidesForField}
            removeGuide={this.removeGuide}
            onResumeGuide={this.onResumeGuide}            
            fileBucketRef={fileBucketRef}
          />
        }     
      </div>
    );
  }
};

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => {
    const {
      match: {
        params: { fieldId },
      },
      dataType,
    } = props; 

    let results = [
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "fields" },
        ],
        storeAs: 'fields'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "fieldsInfo" },
        ],
        storeAs: 'fieldsInfo'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "guides" },
        ],
        storeAs: 'guides'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "profile" },
        ],
        storeAs: 'profile'
      },         
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'settings'
      },      
    ];
    
    if (fieldId && fieldId !== '' && fieldId !== 'farm') {
      results = [...results,
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsYieldMapsProcessed", doc: `${fieldId}` },
          ],
          storeAs: 'fieldsYieldMapsProcessed_' + fieldId
        },
      ];
    };
    
    // Information needed for file manager
    if (dataType && dataType === "collect") {
      results = [...results,
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsSoilMapsUploaded" },
          ],
          storeAs: 'fieldsSoilMapsUploaded'
        },
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsYieldMapsUploaded" },
          ],
          storeAs: 'fieldsYieldMapsUploaded'
        },                
      ];
      
      if (fieldId && fieldId !== '' && fieldId !== 'farm') {
        results = [...results,
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsSoilMapsRaw", doc: `${fieldId}` },
            ],
            storeAs: 'fieldsSoilMapsRaw_' + fieldId
          },
          {
            collection: 'users',
            doc: `${props.userId}`,
            subcollections: [
              { collection: "fieldsMoistureMapsProcessed", doc: `${fieldId}` },
            ],
            storeAs: 'fieldsMoistureMapsProcessed_' + fieldId
          },             
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsSoilMapsProcessed", doc: `${fieldId}` },
            ],
            storeAs: 'fieldsSoilMapsProcessed_' + fieldId
          },
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsYieldMapsRaw", doc: `${fieldId}` },
            ],
            storeAs: 'fieldsYieldMapsRaw_' + fieldId
          },
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsYieldMapsProcessed", doc: `${fieldId}` },
            ],
            storeAs: 'fieldsYieldMapsProcessed_' + fieldId
          },
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsSentinelHubRaw", doc: `${fieldId}` },
            ],
            storeAs: 'fieldsSentinelHubRaw_' + fieldId
          },
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsSentinelHubProcessed", doc: `${fieldId}` },
            ],
            storeAs: 'fieldsSentinelHubProcessed_' + fieldId
          },    
        ];
      };
  
      if (fieldId && fieldId === 'farm') {
        results = [...results,
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsSoilMapsRaw" },
            ],
            storeAs: 'fieldsSoilMapsRaw'
          },
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsSoilMapsProcessed" },
            ],
            storeAs: 'fieldsSoilMapsProcessed'
          },   
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsYieldMapsRaw" },
            ],
            storeAs: 'fieldsYieldMapsRaw'
          },
          { 
            collection: 'users', 
            doc: `${props.userId}`, 
            subcollections: [
              { collection: "fieldsYieldMapsProcessed" },
            ],
            storeAs: 'fieldsYieldMapsProcessed'
          },                       
        ];
      };          
    };

    return results
  }),
  withFirestoreData,
  withRouter
)(GuidesPage);
