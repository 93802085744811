import React, { Component } from "react";

import { 
  Segment,
  Header, 
} from "semantic-ui-react";

import moment from "moment";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";

import { PLAN_TYPES } from '@/constants/regions/plans';
import { PRODUCTION_AREAS } from '@/constants/regions/sweden';

const INITIAL_STATE = {
  errorMessage: null,
};

class RegistrationV1Summary extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {

    const {
      farm,
      invoicing,
      contact,
      plan,
    } = this.props;

    let invoicingAddress = invoicing && (invoicing.address1 + ', ' + invoicing.address2 + ', ' + invoicing.zipCode + ' ' + invoicing.town);
    invoicingAddress = invoicingAddress && invoicingAddress.replace(', ,', ',');

    let contactAddress = contact && (contact.address1 + ', ' + contact.address2 + ', ' + contact.zipCode + ' ' + contact.town);
    contactAddress = contactAddress && contactAddress.replace(', ,', ',');
    
    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],      
      next: [
        {
          caption: "Skapa konto",
          onClick: this.props.onClickNextStep,
        }
      ],
    };

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <Segment 
          style={{
            padding: "2em",           
          }}
        >    
          <Header 
            as='h1' 
            style={{
              fontWeight: "normal",
            }}
          >
            Sammanfattning
          </Header> 

          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
            }}
          >
            Grattis! Du har nu fyllt i alla uppgifter som Freja behöver för att skapa ditt användarkonto.
            Kontrollera uppgifterna nedanför. Stämmer du klickar du på knappen skapa konto nere till höger. 
            Du kan även gå tillbaka och rätta uppgifter genom att klicka på knappen tillbaka nere till vänster.
          </p>
        </Segment>

        <Segment 
          style={{
            padding: "2em",           
          }}
        >       
          <Header 
            as='h2' 
            style={{
              fontWeight: "normal",
            }}
          >
            Gårdsinformation
          </Header>         

          <div
            style={{
              fontWeight: "normal",
              fontSize: "120%",
              whiteSpace: "pre-line",
              lineHeight: "150%",
            }}                  
          >
            <b>Gårdens namn</b>: {farm && farm.name}. <br/>
            <b>Organisationsnummer</b>: {invoicing && invoicing.companyId}. <br/>
            <b>Produktionsområde</b>: {farm && farm.region && PRODUCTION_AREAS.find((x) => x.value === farm.region).text}. <br/>
          </div>      
        </Segment>

        <Segment 
          style={{
            padding: "2em",           
          }}
        >       
          <Header 
            as='h2' 
            style={{
              fontWeight: "normal",
            }}
          >
            Kontaktuppgifter
          </Header>         

          <div
            style={{
              fontWeight: "normal",
              fontSize: "120%",
              whiteSpace: "pre-line",
              lineHeight: "150%",
            }}                  
          >
            <b>Kontaktperson</b>: {contact && contact.firstName && contact.lastName && contact.firstName + " " + contact.lastName}. <br/>
            <b>Besöksadress</b>: {contactAddress}. <br/>
            <b>Faktureringsadress</b>: {invoicingAddress}. <br/>
            <b>Mailadress för faktura</b>: {invoicing && invoicing.email}.<br/>
            <b>Faktureringssätt</b>: {invoicing && invoicing.method}.
          </div>      
        </Segment>   

        <Segment 
          style={{
            padding: "2em",           
          }}
        >       
          <Header 
            as='h2' 
            style={{
              fontWeight: "normal",
            }}
          >
            Funktionspaket
          </Header>         

          <div
            style={{
              fontWeight: "normal",
              fontSize: "120%",
              whiteSpace: "pre-line",
              lineHeight: "150%",
            }}                  
          >
            <b>Paket</b>: {plan && plan.type && PLAN_TYPES.find((x) => x.name === plan.type) && PLAN_TYPES.find((x) => x.name === plan.type).caption}.<br/>
            <b>Pris</b>: {plan && plan.type && PLAN_TYPES.find((x) => x.name === plan.type) && PLAN_TYPES.find((x) => x.name === plan.type).price}.<br/>
            <b>Giltig till</b>: {plan && plan.validTo && moment(plan.validTo.toISOString()).format("YYYY-MM-DD")}.
          </div>      
        </Segment>               
      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader=""
        mainColumnBody=""    
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default RegistrationV1Summary;