import React from "react";

import {
  Form,
  Image,
  Button,
  Segment,
  Dimmer,
  Header,
  Loader,
} from "semantic-ui-react";

import { toast } from "react-toastify";

import { cloudFunctions } from "@";

import WidgetMenu from "@/components/menus/WidgetMenu";

import feedbackIllustration from "@/assets/illustrations/feedback.png";
class ContactSupportModal extends React.Component {
  state = {
    open: false,
    note: "",
    errorMessage: "",
    processingSubmit: false,
    activeView: "email",
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  sendMessage = message => {
    const {
      db,
      userId,
      params,
      view,
    } = this.props;

    this.setState({ processingSubmit: true });
    
    let email =
      db &&
      db.profile &&
      db.profile.contact &&
      db.profile.contact.email &&
      db.profile.contact.email;

    let firstName =
      db &&
      db.profile &&
      db.profile.contact &&
      db.profile.contact.first_name &&
      db.profile.contact.first_name;

    let lastName =
      db &&
      db.profile &&
      db.profile.contact &&
      db.profile.contact.last_name &&
      db.profile.contact.last_name;

    let farmName = 
      db &&
      db.profile &&
      db.profile.farm &&
      db.profile.farm.name &&
      db.profile.farm.name;

    let emailAddress = (email && firstName && lastName) ? firstName + ' ' + lastName + ' <' + email + '>' : null;
    emailAddress = emailAddress ? emailAddress : this.props.userId + "@agriopt.se";

    let fieldId = params && params.fieldId && params.fieldId;
    let fieldName = db && db.fields && db.fields[fieldId] && db.fields[fieldId] && db.fields[fieldId].name;
    let layer = params && params.layer && params.layer;
    let layerId = params && params.layerId && params.layerId;

    let longerMessage = "Ett nytt supportärende har inkommit för användaren: " + userId + ".\n\n\n";
    longerMessage = longerMessage + "==============================\n";
    longerMessage = longerMessage + "Supportinformation:\n";
    longerMessage = longerMessage + "==============================\n\n";
    longerMessage = longerMessage + "Användare: " + firstName + " " + lastName + "\n";
    longerMessage = longerMessage + "Gård: " + farmName + "\n\n";
    longerMessage = longerMessage + "userId: " + userId + "\n";
    longerMessage = longerMessage + "view: " + view + "\n\n";
    longerMessage = longerMessage + "fieldId: " + fieldId + "\n";
    longerMessage = longerMessage + "fieldName: " + fieldName + "\n\n";    
    longerMessage = longerMessage + "layer: " + layer + "\n";
    longerMessage = longerMessage + "layerId: " + layerId + "\n";
    
    longerMessage = longerMessage + "\n\n==============================\n";
    longerMessage = longerMessage + "Meddelande från kund:\n";
    longerMessage = longerMessage + "==============================\n\n";
    longerMessage = longerMessage + message;

    cloudFunctions.httpsCallable('sendSupportEmail')(
      {
        email: emailAddress,
        subject: 'Supportärende från Freja',
        message: longerMessage
      }
    )
      .then(x => {
        console.log("Feedback sent");
        
        toast.success("Tack, ditt meddelande har skickats till teamet bakom Freja. Vi kommer återkomma inom kort.",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
          hideProgressBar: false,
        }); 

        this.setState({ 
          processingSubmit: false, 
          note: "",
          open: false, 
        });
        
      })
      .catch(error => {
        console.log("Sending feedback failed...");
        console.log(error);

        this.setState({ 
          processingSubmit: false, 
        });
      });
  };

  onChangeText = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onClickSend = () => {
    if (this.state.note) {
      this.setState({ errorMessage: "" });
      this.sendMessage( this.state.note );
    } else {
      this.setState({
        errorMessage: "Vänligen ange ett meddelande."
      });
    }
  };

  handleItemClick = (item) => this.setState({ activeView: item });

  render() {
    const { 
      open, 
      note, 
      errorMessage,
      processingSubmit,
      activeView,
    } = this.state;

    const { 
      trigger,
    } = this.props;

    let menuItems = [
      {
        id: 'email',
        caption: "E-post",
        active: activeView === 'email',
        onClick: this.handleItemClick,
      },
      {
        id: 'phone',
        caption: "Telefon",
        active: activeView === 'phone',
        onClick: this.handleItemClick,       
        disabled: true,
      },           
    ];  

    return (
      <React.Fragment>
        {trigger && React.cloneElement(trigger, { onClick: this.toggleOpen })}

        {open &&
          <Dimmer 
            active 
            page
          >
            <div
              style={{
                height: "60%",
                width: "60vw",
                marginTop: "20vh",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                color: "black",
              }}
            >
              <Segment
                style={{
                  width: "50%",
                  height: "100%",
                  padding: "2em",
                }}
              >

                <WidgetMenu menuItems={menuItems} />

                <div
                  style={{
                    width: "67%",
                  }}            
                >
                {!processingSubmit && activeView === "email" &&
                  <React.Fragment>
                    <Form
                      size="large"
                      style={{ 
                        marginTop: "3em",
                        width: "80%",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >

                      <Form.TextArea  
                        autoFocus               
                        rows={15}
                        name="note"
                        placeholder="Hur kan vi hjälpa dig?"
                        onChange={e => this.onChangeText(e)}
                        value={note}
                      />

                      {errorMessage &&
                        <p
                          style={{ 
                            marginTop: "1em",
                            marginBottom: "1em",
                            color: "#D6946B", 
                          }}
                        >
                          {errorMessage}
                        </p>
                      }          

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5em",
                        }}
                      >
                        <Button
                          onClick={() => this.toggleOpen()}
                          style={{ 
                            backgroundColor: "#868D8E",
                            color: "white",                  
                          }}
                        >
                          Avbryt
                        </Button>   
                                                
                        <Button
                          onClick={() => this.onClickSend()}
                          style={{ 
                            backgroundColor: "#6B9D9F",
                            color: "white",                  
                          }}
                        >
                          Skicka meddelande
                        </Button>                   
                      </div>
                    </Form>
                  </React.Fragment>
                }

                {!processingSubmit && activeView === "phone" &&
                  <React.Fragment>
                    <Header>013 - 10 30 10 </Header>                    

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div />
                        <Button
                          onClick={() => this.toggleOpen()}
                          style={{ 
                            marginTop: "2em", 
                            backgroundColor: "#868D8E",
                            color: "white",                  
                          }}
                        >
                          Stäng
                        </Button>                      
                      </div>
                  </React.Fragment>
                }                

                {processingSubmit &&
                  <Loader 
                    active 
                    indeterminate 
                    size="massive"
                  >
                    <div
                      style={{
                        fontSize: "80%", 
                        marginTop: "0.5em",
                        color: "grey",
                        }}
                      >
                      Skickar meddelande...
                    </div>
                  </Loader>            
                }  
                </div>        
              </Segment>

              <Segment
                style={{
                  width: "50%",
                  height: "90%",
                  padding: "2em",
                  marginTop: "auto",
                  marginBottom: "auto",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  backgroundColor: "#f7f4f0",
                }}
              > 
                <Image 
                  style={{
                    height: processingSubmit ? "80%" : "70%",
                    marginTop: processingSubmit ? "5%" : null,
                    marginBottom: processingSubmit ? "15%" : null,
                    transition: "height 0.3s",
                  }}            
                  centered 
                  src={feedbackIllustration}             
                />

                {!processingSubmit &&
                  <Header
                    as="h2"
                    textAlign="center"
                    color="grey"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Kontakta support
                  </Header>
                }

                {!processingSubmit &&
                  <p
                    style={{
                      width: "75%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "center",
                    }}
                  >
                    Skriv ditt meddelande i rutan nedanför så kontaktar vi dig så snart vi kan via e-post eller telefon. 
                  </p>
                }
              </Segment>
            </div>
          </Dimmer>        
        }
      </React.Fragment>
    );
  }
}

export default ContactSupportModal;
