import moment from 'moment';

export const getCurrentDate = (format="YYYY-MM-DD") => {
    return moment().format(format)
};

export const getCurrentYear = (checkSeason=false) => {
    let currentDate = new Date();

    if (checkSeason) {
        if (currentDate.getMonth() < 4) {
            return (currentDate.getFullYear() - 1).toFixed(0)
        };
    };
    
    return currentDate.getFullYear().toFixed(0)
};

export const getPreviousYear = (checkSeason=false) => {
    let currentDate = new Date();

    if (checkSeason) {
        if (currentDate.getMonth() < 4) {
            return (currentDate.getFullYear() - 2).toFixed(0)
        };
    };

    return (currentDate.getFullYear() - 1).toFixed(0)
};

export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }