import React, { Component } from "react";

import { 
  Form,
} from "semantic-ui-react";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

import { SWEDISH_REGIONS } from '@/constants/regions/sweden';
import { emailIsValid } from '@/helpers/validators';

const INITIAL_STATE = {
  checkContactInformation: false,
  checkVisitingAddress: false,
  checkInvoicingAddress: false,
};

class RegistrationV1ContactInformation extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickNextStep = () => {
    const {
      completedStepsContact,
    } = this.props;

    this.props.setParentState({
      openSegmentContact: '',
      completedStepsContact: [...completedStepsContact, "invoicingAddress"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment) => {};

  onChange = (data, onlyNumbers=true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  }; 

  onChangeStruct = (e, data, variableName, onlyNumbers=true) => {
    let currentValue = this.props[variableName];

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      [variableName]: {...currentValue, [data.name]: value },
    });
  }; 
  
  setOpenSegment = (newSegment) => {
    const {
      openSegmentContact
    } = this.props;

    (openSegmentContact === newSegment) && this.props.setParentState({ openSegmentContact: null });
    (openSegmentContact !== newSegment) && this.props.setParentState({ openSegmentContact: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedStepsContact, 
    } = this.props;

    !completedStepsContact.includes(currentSegment) && this.props.setParentState({
      completedStepsContact: [...completedStepsContact, currentSegment],
    });

    this.setDefaultSettings(newSegment);

    !completedStepsContact.includes(newSegment) && this.setOpenSegment(newSegment);
    completedStepsContact.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  onCopyContactInformation = () => {
    this.props.setParentState({ 
      invoicing: {
        ...this.props.invoicing, 
        address1: this.props.contact.address1,
        address2: this.props.contact.address2,
        zipCode: this.props.contact.zipCode,
        town: this.props.contact.town,
        email: this.props.contact.email
      }
    });
  };

  render() {

    const {
      openSegmentContact,
      completedStepsContact,
      contact,
      invoicing,
    } = this.props;

    const {
      checkContactInformation,
      checkInvoicingAddress,
      checkVisitingAddress,
    } = this.state;
    
    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],      
      next: [
        {
          caption: "Gå vidare",
          onClick: this.onClickNextStep,
          disabled: completedStepsContact.length < 3,
        }
      ],
    };

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegment
          caption="Kontaktperson"
          open={openSegmentContact === "contactPerson"}
          complete={completedStepsContact && completedStepsContact.includes("contactPerson")}
          onlyClickOpenSegmentIfComplete={true}
          onClickOpenSegment={() => this.setOpenSegment("contactPerson")}
          onClickNextStep={() => {

            let validInformation = true;
            validInformation = contact && !contact.firstName ? false : validInformation;
            validInformation = contact && !contact.lastName ? false : validInformation;
            validInformation = contact && (!contact.email || !emailIsValid(contact.email)) ? false : validInformation;
            validInformation = contact && !contact.phone ? false : validInformation;

            if (validInformation) {
              this.setState({ checkContactInformation: false });
              this.gotoNextStep("contactPerson", "visitingAddress");
            } else {
              this.setState({ checkContactInformation: true });
            };
          }}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
              marginBottom: "2em",
            }}
          >
            Denna information använder vi för att kunna kontakta dig i supportärenden och liknande.
          </p>

          <Form>
            <Form.Group widths='equal'>
              <Form.Input
                focus
                autoFocus
                fluid
                placeholder='Ange ditt förnamn'
                label="Förnamn"
                name='firstName'
                value={contact && contact.firstName}
                error={checkContactInformation && contact && !contact.firstName && {content: "Ange ett förnamn", pointing: "below"}}
                onChange={(e, d) => this.onChangeStruct(e, d, 'contact', false)}
              />
              <Form.Input
                focus
                fluid
                placeholder='Ange ditt efternamn'
                label="Efternamn"
                name='lastName'
                value={contact && contact.lastName}
                error={checkContactInformation && contact && !contact.lastName && {content: "Ange ett efternamn", pointing: "below"}}
                onChange={(e, d) => this.onChangeStruct(e, d, 'contact', false)}
              />
            </Form.Group>     

            <Form.Group 
              widths='equal'
            >
              <Form.Input
                focus
                fluid
                placeholder='Ange en mailadress'
                label="Mailadress"
                iconPosition='left'
                icon='mail'
                name='email'
                value={contact && contact.email}
                error={checkContactInformation && contact && (!contact.email || !emailIsValid(contact.email)) && {content: "Ange en korrekt mailadress", pointing: "below"}}
                onChange={(e, d) => this.onChangeStruct(e, d, 'contact', false)}
              />

              <Form.Input
                focus
                fluid
                placeholder='Ange ett telefonnummer'
                label="Telefonnummer"
                iconPosition='left'
                icon='phone'
                name='phone'
                value={contact && contact.phone}
                error={checkContactInformation && contact && !contact.phone && {content: "Ange ett telefonnummer", pointing: "below"}}
                onChange={(e, d) => this.onChangeStruct(e, d, 'contact', false)}
              />
            </Form.Group>  
          </Form>
        </GuideSegment>

        <GuideSegment
          caption="Besöksadress"
          open={openSegmentContact === "visitingAddress"}
          complete={completedStepsContact && completedStepsContact.includes("visitingAddress")}
          onlyClickOpenSegmentIfComplete={true}
          onClickOpenSegment={() => this.setOpenSegment("visitingAddress")}
          onClickNextStep={() => {
            let validInformation = true;
            validInformation = contact && !contact.address1 ? false : validInformation;
            validInformation = contact && !contact.zipCode ? false : validInformation;
            validInformation = contact && !contact.town ? false : validInformation;
            validInformation = contact && !contact.region ? false : validInformation;

            if (validInformation) {
              this.setState({ checkVisitingAddress: false });
              this.gotoNextStep("visitingAddress", "invoicingAddress");
            } else {
              this.setState({ checkVisitingAddress: true });
            };
          }}          
        >
          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
              marginBottom: "2em",
            }}
          >
            Denna information använder vi vid gårdsbesök och för att kunna skicka en faktura. Om vill att fakturan ska skickas till någon annan adress kan du ange det i nästa steg.
          </p>

            <Form>
              <Form.Input
                autoFocus
                focus                    
                fluid
                label="Addressrad 1"
                placeholder='Ange adressrad'
                name='address1'
                value={contact && contact.address1}
                error={checkVisitingAddress && contact && !contact.address1 && {content: "Ange en addressrad", pointing: "below"}}
                onChange={(e, d) => this.onChangeStruct(e, d, 'contact', false)}
              />

              <Form.Input
                focus
                fluid
                label='Adressrad 2'
                placeholder='Ange adressrad'
                name='address2'
                value={contact && contact.address2}
                onChange={(e, d) => this.onChangeStruct(e, d, 'contact', false)}
              />

              <Form.Group widths='equal'>
                <Form.Input
                  focus
                  fluid
                  label='Postnummer'
                  placeholder='Ange ditt postnummer (NNN NN)'
                  name='zipCode'
                  value={contact && contact.zipCode}
                  error={checkVisitingAddress && contact && !contact.zipCode && {content: "Ange ett postnummer", pointing: "below"}}
                  onChange={(e, d) => this.onChangeStruct(e, d, 'contact')}
                />

                <Form.Input
                  focus
                  fluid
                  label='Postort'
                  placeholder='Ange en postort'
                  name='town'
                  value={contact && contact.town}
                  error={checkVisitingAddress && contact && !contact.town && {content: "Ange en postort", pointing: "below"}}
                  onChange={(e, d) => this.onChangeStruct(e, d, 'contact', false)}
                />
              </Form.Group>

              <Form.Select
                fluid
                search
                label="Län"
                placeholder='Välj ett län...'
                name='region'
                value={contact && contact.region}
                error={checkVisitingAddress && contact && !contact.region && {content: "Välj ett län", pointing: "below"}}
                options={SWEDISH_REGIONS}
                onChange={(e, d) => this.onChangeStruct(e, d, 'contact', false)}
              />
            </Form>
        </GuideSegment>

        <GuideSegment
          caption="Faktureringsadress"
          simpleModeQuestion="Vill du ange en faktureringsadress?"
          simpleModeDescription="Vi kan skicka din faktura till en annan adress om du exempelvis tar hjälp av någon för att sköta gårdens bokföring."
          simpleModeOpenText="Ange faktureringsaddress"
          simpleModeOnSkip={() => {
            this.onCopyContactInformation();
            this.onClickNextStep();
          }}
          open={openSegmentContact === "invoicingAddress"}
          complete={completedStepsContact && completedStepsContact.includes("invoicingAddress")}
          onlyClickOpenSegmentIfComplete={true}
          onClickOpenSegment={() => this.setOpenSegment("invoicingAddress")}
          onClickNextStep={() => {
            let validInformationAdress = true;
            validInformationAdress = invoicing && !invoicing.address1 ? false : validInformationAdress;
            validInformationAdress = invoicing && !invoicing.zipCode ? false : validInformationAdress;
            validInformationAdress = invoicing && !invoicing.town ? false : validInformationAdress;

            let validInformationEmail = true;
            validInformationEmail = invoicing && (!invoicing.email || !emailIsValid(invoicing.email)) ? false : validInformationEmail;

            if (validInformationAdress || validInformationEmail) {
              this.setState({ checkInvoicingAddress: false });
              this.onClickNextStep();
            } else {
              this.setState({ checkInvoicingAddress: true });
            };
          }}               
        >
          <p
            style={{
              whiteSpace: "pre-line",
              width: "80%",
              fontSize: "110%",
              marginBottom: "2em",
            }}
          >
            Denna information använder vi för att skicka din faktura. Ange en mailadress och/eller en postadress för din faktura.
          </p>

          <Form>
            <Form.Input
              focus                    
              fluid
              label="Mailadress"
              placeholder='Ange mailadress (frivilligt)'
              name='email'
              value={invoicing && invoicing.email}
              error={checkInvoicingAddress && invoicing && (!invoicing.email || !emailIsValid(invoicing.email)) && {content: "Ange en korrekt mailadress", pointing: "below"}}
              onChange={(e, d) => this.onChangeStruct(e, d, 'invoicing', false)}
            />

            <Form.Input
              focus                    
              fluid
              label="Addressrad 1"
              placeholder='Ange adressrad'
              name='address1'
              value={invoicing && invoicing.region}
              error={checkInvoicingAddress && invoicing && !invoicing.address1 && {content: "Ange en addressrad", pointing: "below"}}
              onChange={(e, d) => this.onChangeStruct(e, d, 'invoicing', false)}
            />

            <Form.Input
              focus
              fluid
              label='Adressrad 2'
              placeholder='Ange adressrad'
              name='address2'
              value={invoicing && invoicing.address2}
              onChange={(e, d) => this.onChangeStruct(e, d, 'invoicing', false)}
            />

            <Form.Group widths='equal'>
              <Form.Input
                focus
                fluid
                label='Postnummer'
                placeholder='Ange ditt postnummer (NNN NN)'
                name='zipCode'
                value={invoicing && invoicing.zipCode}
                error={checkInvoicingAddress && invoicing && !invoicing.zipCode && {content: "Ange ett postnummer", pointing: "below"}}
                onChange={(e, d) => this.onChangeStruct(e, d, 'invoicing')}
              />

              <Form.Input
                focus
                fluid
                label='Postort'
                placeholder='Ange en postort'
                name='town'
                value={invoicing && invoicing.town}
                error={checkInvoicingAddress && invoicing && !invoicing.town && {content: "Ange en postort", pointing: "below"}}
                onChange={(e, d) => this.onChangeStruct(e, d, 'invoicing', false)}
              />
            </Form.Group>
          </Form>
        </GuideSegment>        
      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader=""
        mainColumnBody=""    
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default RegistrationV1ContactInformation;