import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { 
  Container,
  Segment,
  Image,
  Header,
  Grid,
} from "semantic-ui-react";
  
import TermsList from "@/components/legal/TermsList";

import imgInformation from "@/assets/icons/guideSteps/information.svg";

const INITIAL_STATE = {};

class LegalPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  getOverviewPage = () => (
    <Container
      style={{
        padding: "2em",
        paddingBottom: "70px",
        width: "100%",
      }}
      >    
      <Segment 
        raised
        style={{
            marginTop: "3em",
            padding: "2em",
            paddingTop: "2em",
        }}
      >
        <Grid>
          <Grid.Row columns="2">
            
            <Grid.Column width={4}>
              <Image
                src={imgInformation}
                style={{
                  marginLeft: "5em",
                  width: '20em',
                }}
              />  
            </Grid.Column>
            
            <Grid.Column width={12}>
              <Header as="h1">
                Juridisk information
              </Header>

              <p
                style={{
                  fontSize: "120%",
                  whiteSpace: "pre-line",
                  maxWidth: "67%",
                }}
              >
                Här hittar du det finstilta men viktiga som beskriver din relation med AgriOpt och hur vi hanterar din uppladdade information och personuppgifter.
              </p>

              <p style={{ fontSize: "110%" }}>
                <Link to="/home/legal/user-terms">Användarvillkor</Link>
              </p>

              <p style={{ fontSize: "110%" }}>
              <Link to="/home/legal/privacy">Integritetspolicy</Link>
              </p>    

              <p style={{ fontSize: "110%" }}>
              <Link to="/home/legal/third-party-licences">Tredjepartslicenser</Link>
              </p>                
            </Grid.Column>        
          
          </Grid.Row>
        </Grid>
      </Segment>
                 
    </Container>
  )

  render() {
    const {
      subPage
    } = this.props;

    let pageData = '';

    switch(subPage) {

      case 'user-terms':
        pageData = <TermsList mode={subPage} />;
        break;

      case 'privacy':
        pageData = <TermsList mode={subPage} />;
        break;
        
      case 'third-party-licences':
        pageData = <TermsList mode={subPage} />;          
        break;
        
      default:
        pageData = this.getOverviewPage();
        break;
    };

    return (
      <React.Fragment>
        {pageData}
      </React.Fragment>
    )
  }
};

export default withRouter(LegalPage);
