import L from 'leaflet';

import { parseString } from 'xml2js';

import { grid_to_geodetic } from '@/helpers/maps';
import { SWEDISH_REGIONS } from "@/constants/regions/sweden";

export const saveSamApplication = (props, file) => {
  const {
    userId,
  } = props;
  
  const fileBucketRef = props.firebase.storage().ref();

  let unixTimeStamp = +new Date();
  unixTimeStamp = unixTimeStamp && unixTimeStamp.toFixed(0);
  
  const filePath = "samApplications/" + userId + "/" + userId + "_" + unixTimeStamp + "_" + file.name;
  const fileRef = fileBucketRef.child(filePath);
  
  fileRef.put(file)
    .then(x => console.log('Uploading SAM application to Filebucket done...'))
    .catch(error => {
      console.log('Uploading SAM application to Filebucket failed...');
      console.log(error)
    });
  
  props.firestore.collection("users").doc(userId)
    .collection("profile").doc("sam_applications")
    .set({
      [unixTimeStamp]: {
        original_file_name: file.name,
        date_uploaded: new Date(),
        file_path: filePath,
      }
    }, { merge: true })
    .then((x) => console.log("Wrote sam application data..."))
    .catch(error => {
      console.log('Database write failed...');
      console.log(error)
    });
};

export const parseSamApplication = (props, fileObject, onlyFields=false) => {
  let reader = new FileReader();
  
  reader.onload = (e) => {
    let content = reader.result;
    let cleanedString = content && content.replace("\ufeff", "");

    parseString(cleanedString, (err, results) => {
      if (err) {
        console.log('Error parsing XML file: ' + err);
        props.setParentState({ samApplicationError: 'Error parsing XML file.' });
        return;
      };

      !onlyFields && getFarmInformation(props, results);
      onlyFields && getFieldBlocks(props, results);

      props.setParentState({ isParsingFile: false });
    });
  };

  reader.readAsText(fileObject, 'ISO-8859-15');   
};

export const getSamApplicationFields = (props, fileString) => {
  let cleanedString = fileString && fileString.replace("\ufeff", "");

  parseString(cleanedString, (err, results) => {
    if (err) {
      console.log('Error parsing XML file: ' + err);
      props.setParentState({ samApplicationError: 'Error parsing XML file.' });
      return;
    };

    getFieldBlocks(props, results);
    props.setParentState({ isParsingFile: false });
  });
};

export const stringToTitleCase = (str) => {
  let splitStr = str.toLowerCase().split(' ');

  for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  };

  return splitStr.join(' '); 
};

export const getFarmInformation = (props, fileString) => {
  let customerData = fileString && fileString['SAMI_APPLICATION']['APPLICATION'][0]['CUSTOMER'][0];

  let companyId = customerData && customerData['ORGANISATIONID'];
  companyId = companyId ? companyId[0] && companyId[0].replace("-", "") : companyId;

  let isOrgNumber = companyId && companyId.length === 10 && companyId[2] > 1;
  companyId = !isOrgNumber && companyId.length === 12 ? companyId.substring(2) : companyId;
  companyId = companyId && (companyId.slice(0, 6) + '-' + companyId.slice(6, 10));

  let name = customerData && customerData['NAME'];
  name = name ? stringToTitleCase(name[0]) : '';

  let address1 = customerData && customerData['ONEADRESS'];
  address1 = address1 ? stringToTitleCase(address1[0]) : '';

  let address2 = customerData && customerData['TWOADDRESS'];
  address2 = address2 ? stringToTitleCase(address2[0]) : '';

  let zipCode = customerData && customerData['ZIP'];
  zipCode = zipCode ? zipCode[0].replace(" ", "") : '';
  zipCode = zipCode && (zipCode.slice(0, 3) + ' ' + zipCode.slice(3, 6));
  zipCode = zipCode ? zipCode : ''

  let town = customerData && customerData['CITY'];
  town = town ? stringToTitleCase(town[0]) : '';  

  let region = customerData && customerData['CUSTOMERSTR'];
  region = region ? region[0] : region;  
  region = region && SWEDISH_REGIONS.find((x) => x.key === region);
  region = region && region.value ? region.value : '';

  let phone = customerData && customerData['PHONES'];
  phone = phone ? phone[0][0] : phone;

  props.storeParsedInfo({
    contact: {
      address1: address1 ? address1 : address2,
      address2: address1 ? address2 : '',
      zipCode: zipCode,
      town: town,
      region: region,
      phone: phone,    
    },
    farm: {
      name: isOrgNumber ? name : '',
    },
    invoicing: {
      companyId: companyId,
      address1: address1 ? address1 : address2,
      address2: address1 ? address2 : '',
      zipCode: zipCode,
      town: town,
      method: 'E-postfaktura',
    },
  })
};

export const getFieldBlocks = (props, fileString) => {
  const {
    isAddingMode,
    existingFieldsSamIds,
  } = props;

  let timeStamp = fileString && fileString['SAMI_APPLICATION']['APPLICATION'][0]['APPLICATION_TIMESTAMP'][0];  

  let applicationYear = timeStamp && parseFloat(timeStamp['YEAR']);
  let applicationDate = timeStamp && (timeStamp['YEAR'] + '-' + timeStamp['MONTH'] + '-' + timeStamp['DAY'])

  let currentBlockIndex = 0;
  let blockData = [];
  let fieldIdAlreadyExists = [];

  let blocks = fileString && fileString['SAMI_APPLICATION']['BLOCKS'][0]['BLOCK'];

  blocks.forEach((x) => {
    let parcels = x && x.PARCELS && x.PARCELS[0] && x.PARCELS[0].PARCEL && x.PARCELS[0].PARCEL;

    parcels && parcels.forEach((y) => {
      let supportValues = y && y.SUPPORTVALUES[0].SUPPORTVALUE && y.SUPPORTVALUES[0].SUPPORTVALUE;
      let subBlockCropCode = supportValues && supportValues.find((x) => x.$.Type === 'CROPCODE');
      let subBlockArea = supportValues && supportValues.find((x) => x.$.Type === 'AREA');
      let mainBlockPolygons = x && getBlockPolygon(x.POLYGON[0]);
      let subBlockPolygons = y && getBlockPolygon(y.POLYGON[0]);

      let newEntry = {
        index: currentBlockIndex,
        name: "Skifte #" + (currentBlockIndex + 1).toFixed(0),
        blockId: parseInt(x.BLOCKID[0]),
        blockSubId: parseInt(y.PARCELID[0]),
        id: parseInt(x.BLOCKID[0]) + '_' + parseInt(y.PARCELID[0]),
        area: parseFloat(subBlockArea.VALUE[0]['_']),
        position: subBlockPolygons.mainPolygon,
        positionHoles: subBlockPolygons.holePolygons,
        positionMainBlock: mainBlockPolygons.mainPolygon,
        cropCode: subBlockCropCode && {[applicationYear]: subBlockCropCode.VALUE[0]['_']},
        cropText: subBlockCropCode && {[applicationYear]: subBlockCropCode.DESCR[0]},
      };

      if (newEntry && newEntry.cropText && !newEntry.cropText[applicationYear].includes("Skyddszon")) {
        blockData = [...blockData, newEntry];
        currentBlockIndex += 1;

        if (isAddingMode && existingFieldsSamIds.includes(newEntry.blockId)) {
          fieldIdAlreadyExists = [...fieldIdAlreadyExists, newEntry.id];
        };        
      };
    });

    if (!parcels || parcels.length === 0) {
      let subBlockArea = x && x['BLOCKAREA'] && x['BLOCKAREA'][0];
      let mainBlockPolygons = x && getBlockPolygon(x.POLYGON[0]);
      let subBlockPolygons = x && getBlockPolygon(x.POLYGON[0]);

      let newEntry = {
        index: currentBlockIndex,
        name: "Skifte #" + (currentBlockIndex + 1).toFixed(0),
        blockId: parseInt(x.BLOCKID[0]),
        blockSubId: null,
        id: parseInt(x.BLOCKID[0]),
        area: subBlockArea && parseFloat(subBlockArea) && parseFloat(parseFloat(subBlockArea).toFixed(2)),
        position: subBlockPolygons.mainPolygon,
        positionHoles: subBlockPolygons.holePolygons,
        positionMainBlock: mainBlockPolygons.mainPolygon,
        cropCode: null,
        cropText: null,
      };

      blockData = [...blockData, newEntry];
      currentBlockIndex += 1;

      if (isAddingMode && existingFieldsSamIds.includes(newEntry.blockId)) {
        fieldIdAlreadyExists = [...fieldIdAlreadyExists, newEntry.id];
      };      
    };
  
  });

  props.setParentState({ 
    fields: blockData,
    applicationDate,
    applicationYear,
    fieldIdAlreadyExists,
  });
};


export const getBlockPolygon = (polygonString) => {
    
  let parsedPolygons = polygonString && polygonString.replace('POLYGON ((', '').replace('))', '').replace(')', '');
  parsedPolygons = parsedPolygons && parsedPolygons.split('(');
  parsedPolygons = parsedPolygons && parsedPolygons.map((x) => x.split(', '));

  let polygonsCoordinates = parsedPolygons && parsedPolygons.map((subPolygon) => {
    let output = [];

    for (let i = 0; i < subPolygon.length; i++) {
        let coordinate = subPolygon[i].split(' ');
        
        let newX = parseFloat(coordinate[1]);
        let newY = parseFloat(coordinate[0]);
        
        if (!Number.isNaN(newX) && !Number.isNaN(newY)) {
          output.push([newX, newY]);
        } else {
            break;
        };
    };

    return output;
  });

  let mainPolygon = polygonsCoordinates && polygonsCoordinates[0].map((y) => grid_to_geodetic(y[0], y[1]));
  mainPolygon = mainPolygon && mainPolygon.map((y) => L.latLng(y[0], y[1]));
  
  let holePolygons = polygonsCoordinates && polygonsCoordinates.length > 1 && 
    polygonsCoordinates.filter((x, index) => index !== 0).map((holePolygon) => (
      holePolygon.map((y) => grid_to_geodetic(y[0], y[1])).map((y) => L.latLng(y[0], y[1]))
    ));
  
  return { 
    mainPolygon: mainPolygon, 
    holePolygons: holePolygons ? holePolygons : []
  }
};