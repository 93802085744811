import React, { Component } from "react";

import { 
  Button,
  Header, 
  Table, 
  Icon,
  Modal,
  Popup,
} from "semantic-ui-react";

import chroma from "chroma-js";

import { Bar } from "react-chartjs-2";

import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";

import FieldDataGuideCalibrateYieldMap from "@/components/fieldData/FieldDataGuideCalibrateYieldMap";

const INITIAL_STATE = {
  showStatsModal: false,
};

class MapStatsBasic extends Component {
  state = { ...INITIAL_STATE };

  toogleShowStatsModal = () => {
    let currentValue = this.state.showStatsModal;
    this.setState({ showStatsModal: !currentValue });
  };

  getThousandSeparators = (number, noDecimals=2) => {
    let output = '';

    output = (typeof(number) === "number" && number >= 1000.0) ? (Math.floor(number / 10) * 10).toLocaleString('sv') : output;
    output = (typeof(number) === "number" && number < 1000.0) ? Math.floor(number).toFixed(noDecimals) : output;

    return output;
  };

  render () {
    const {
      mode,
      dataStats,
      layer,
      fieldSize,
      legendVisible,
      toggleLegendVisible,
      selectedGroup,
    } = this.props;

    const {
      showStatsModal,
    } = this.state;

    if (!dataStats) {
      return <div />
    }

    // Get the data type of the current data layer (float, percent, integer, etc.)
    let dataLayer = getCurrentDataLayer(layer);

    let dataLayerType = dataLayer && dataLayer.dataType && dataLayer.dataType[layer];
    let dataHistogramLabels = dataLayer && dataLayer.histogramLabels && dataLayer.histogramLabels[layer.replace("_hotspot", "")];

    let isFloatLayer = dataLayerType === "float";

    if (!isFloatLayer) {
      return (
        <div />
      )
    };

    let noDecimals = typeof(dataHistogramLabels.noDecimals) === "number" ? dataHistogramLabels.noDecimals : 1;
    let prefix = dataHistogramLabels.prefix ? dataHistogramLabels.prefix : "";
    let postfix = dataHistogramLabels.postfix ? dataHistogramLabels.postfix : "";

    // Handle Sentinel layers
    let scaleFactor = 1.0;
    scaleFactor = layer.includes("greenmass") ? 100.0 : scaleFactor;
    scaleFactor = layer.includes("moisture") ? 100.0 : scaleFactor;
    scaleFactor = layer.includes("lai") ? 100.0 : scaleFactor;
    scaleFactor = layer.includes("psri") ? 100.0 : scaleFactor;

    noDecimals = layer.includes("greenmass") ? 0 : noDecimals;
    noDecimals = layer.includes("moisture") ? 0 : noDecimals;
    noDecimals = layer.includes("lai") ? 0 : noDecimals;
    noDecimals = layer.includes("psri") ? 0 : noDecimals;


    let values = dataStats && dataStats.values;
    let noBins = values && Math.floor(Math.sqrt(values.length));
    let barPlotLimits = values && chroma.limits(values, 'e', noBins);

    let barPlotGroups = [];
    let noSamples;
  
    if (barPlotLimits) {
      for (let idx = 0; idx < barPlotLimits.length - 1; idx++) {
        let subSamples = [];
  
        values && values.forEach((x) => {
          let currentValue = parseFloat(x) * scaleFactor;
  
          let lowerLimit = barPlotLimits[idx] * scaleFactor;
          let upperLimit = barPlotLimits[idx + 1] * scaleFactor;
  
          lowerLimit = (idx === 0) ? lowerLimit - 0.001 : lowerLimit;
          upperLimit = (idx === barPlotLimits.length - 2) ? upperLimit + 0.001 : upperLimit;
  
          if ((currentValue - lowerLimit) >= 0.0 && (currentValue - upperLimit) < 0.0) {
            subSamples = [...subSamples, { value: currentValue, idx: x.idx }];
          }
        });
  
        noSamples = subSamples && subSamples.length;
  
        barPlotGroups = [...barPlotGroups, {
          noSamples: noSamples,
          max: barPlotLimits[idx + 1] * scaleFactor,
          min: barPlotLimits[idx] * scaleFactor,
        }]
      }
    };

    let tableContent = null;

    if (selectedGroup) {
      let group = dataStats.groups[selectedGroup];
      let areaFraction = group.noSamples / group.noTotalSamples;
      let area = fieldSize * group.noSamples / group.noTotalSamples;

      tableContent = 
        <React.Fragment>
          <Table.Body>
            {areaFraction && typeof areaFraction === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                Markerad yta
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {area.toFixed(1) + " ha"}
                  {" (" + (100 * areaFraction).toFixed(0) + "% av tot.)"}
                </Table.Cell>
              </Table.Row>
            }
            {group && typeof group.min === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Lägsta värde
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + this.getThousandSeparators(group.min * scaleFactor, noDecimals) + postfix}
                </Table.Cell>
              </Table.Row>
            }
            {group && typeof group.max === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Högsta värde
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + this.getThousandSeparators(group.max * scaleFactor, noDecimals) + postfix}
                </Table.Cell>
              </Table.Row>
            }            
            {group && typeof group.mean === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Medelvärde
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + this.getThousandSeparators(group.mean * scaleFactor, noDecimals) + postfix}
                </Table.Cell>
              </Table.Row>
            }
            {(layer === "yieldMap" || layer.includes("yieldStatistics")) && group && typeof group.mean === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Beräknad skörd
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + (area * group.mean).toFixed(1) + postfix}
                  {" (" + (100 * area * group.mean / (dataStats.mean * fieldSize)).toFixed(0) + "% av tot.)"}
                </Table.Cell>
              </Table.Row>
            }
            {layer.includes("vraMap") && dataStats && typeof dataStats.mean === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Total giva
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + this.getThousandSeparators(fieldSize * group.mean, 0) + " kg"}
                  {" (" + (100 * area * group.mean / (dataStats.mean * fieldSize)).toFixed(0) + "% av tot.)"}
                </Table.Cell>
              </Table.Row>
            }                
          </Table.Body>
        </React.Fragment>
    } else {
      tableContent =
        <React.Fragment>
          <Table.Body>
            {dataStats && typeof dataStats.min === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Lägsta värde
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + this.getThousandSeparators(dataStats.min * scaleFactor, noDecimals) + postfix}
                </Table.Cell>
              </Table.Row>
            }
            {dataStats && typeof dataStats.max === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Högsta värde
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + this.getThousandSeparators(dataStats.max * scaleFactor, noDecimals) + postfix}
                </Table.Cell>
              </Table.Row>
            }            
            {dataStats && typeof dataStats.mean === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Medelvärde
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + this.getThousandSeparators(dataStats.mean * scaleFactor, noDecimals) + postfix}
                </Table.Cell>
              </Table.Row>
            }           
            {(layer === "yieldMap" || layer.includes("yieldStatistics")) && dataStats && typeof dataStats.mean === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Beräknad skörd
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + this.getThousandSeparators(fieldSize * dataStats.mean, 1) + postfix}
                </Table.Cell>
              </Table.Row>
            }
            {layer.includes("vraMap") && dataStats && typeof dataStats.mean === 'number' &&
              <Table.Row>
                <Table.Cell style={{ fontWeight: 500 }}>
                  Total giva
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {prefix + this.getThousandSeparators(fieldSize * dataStats.mean, 1) + " kg"}
                </Table.Cell>
              </Table.Row>
            }               
          </Table.Body>
        </React.Fragment>
    }
    
    return (
      <React.Fragment>
        <Modal 
          open={showStatsModal} 
        >
          <Modal.Header
            style={{ 
              fontWeight: 500,
            }}
          >
            Statistik
          </Modal.Header>

          <Modal.Content>
            <article
              className="canvas-container"
              style={{
                width: "100%",
                height: "40vh",
                background: "transparent",
                padding: "1em"
              }}
            >
              <Bar 
                data={{
                  labels: barPlotGroups && barPlotGroups.map((x) => x.min.toFixed(2) + " - " + x.max.toFixed(2)),
                  datasets: [
                    {
                      data: barPlotGroups && barPlotGroups.map((x) => Math.round(100.0 * fieldSize * x.noSamples / values.length) / 100.0),
                      backgroundColor: "#89A275",
                      borderColor: "#4C8440"
                    }
                  ]
                }}
                options={{ 
                  responsive: true,
                  maintainAspectRatio: false,
                  layout: {
                      padding: {
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0
                      }
                  },
                  animation: {
                      duration: 1000
                  },
                  legend: {
                    display: false,
                    position: "bottom",
                    labels: {
                      fontSize: 14,
                      fontColor: 'black',
                      padding: 20,
                    }
                  },
                  scales: {
                    yAxes: [{
                      ticks: {
                        fontSize: 14,
                        fontColor: 'grey',
                      },
                      scaleLabel: {
                        display: true,
                        fontSize: 12,
                        fontColor: 'black',
                        labelString: "Yta (ha)",
                      }
                    }],
                    xAxes: [{
                      ticks: {
                        fontSize: 14,
                        fontColor: 'grey',
                      },
                      scaleLabel: {
                        display: true,
                        fontSize: 12,
                        fontColor: 'black',
                        labelString: prefix + postfix,
                      }
                    }],           
                  }
                }} 
              />
            </article>

            <Table 
              celled 
              striped 
              columns="7"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center">Lägsta värde</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Högsta värde</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Median</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Medelvärde</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Standardavvikelse</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {dataStats && typeof(dataStats.min) === "number" && 
                  typeof(dataStats.max) === "number" && 
                  typeof(dataStats.median) === "number" && 
                  typeof(dataStats.mean) === "number" &&
                  typeof(dataStats.variance) === "number" &&
                  <Table.Row>
                    <Table.Cell textAlign="center">{prefix + (scaleFactor * dataStats.min).toFixed(noDecimals) + postfix}</Table.Cell>
                    <Table.Cell textAlign="center">{prefix + (scaleFactor * dataStats.max).toFixed(noDecimals) + postfix}</Table.Cell>
                    <Table.Cell textAlign="center">{prefix + (scaleFactor * dataStats.median).toFixed(noDecimals) + postfix}</Table.Cell>
                    <Table.Cell textAlign="center">{prefix + (scaleFactor * dataStats.mean).toFixed(noDecimals) + postfix}</Table.Cell>
                    <Table.Cell textAlign="center">{prefix + (scaleFactor * Math.sqrt(dataStats.variance)).toFixed(noDecimals) + postfix}</Table.Cell>
                  </Table.Row>
                }
              </Table.Body>
            </Table>             
          </Modal.Content>

          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}          
          >
            <div />
            <Button 
              style={{ 
                backgroundColor: "#868D8E", 
                color: "white",
              }}
              onClick={() => this.toogleShowStatsModal()}
            >
              Stäng
            </Button>   
          </Modal.Actions>             
        </Modal>
      
        <div
          style={{
            transition: "height 0.3s",
          }}
        >
          <div
            style={{
              marginTop: 0,
              marginBottom: 0,
              padding: 0,
              display: "flex",
              justifyContent: "space-between",                
            }}
          >
            <Header 
              as={mode === "vraMapEditor" ? "h6" : "h4"}
              style={{
                marginTop: 0,
                color: 'white',
                fontWeight: 500,
              }}        
            >
              {selectedGroup ? "Statistik för markerat område" : "Statistik"}
            </Header>

            <div>
              <Popup
                style={{
                  zIndex: "1000",
                }}
                trigger={
                  <Icon
                    className="iconButton"                
                    name="expand"
                    onClick={() => this.toogleShowStatsModal()}
                  />
                }
                content={
                  "Se mer statistik för detta datalager."
                }
              />   
            
              <Icon 
                style={{
                  marginLeft: 5,
                  right: 0,
                }}
                className="iconButton"
                name={legendVisible ? 'chevron down' : 'chevron up'} 
                onClick={() => toggleLegendVisible()}
              />       
            </div>
          </div>   

          {legendVisible &&
            <Table 
              striped 
              inverted 
              style={{
                marginTop: 0,
              }}
            >
              {tableContent && tableContent}
            </Table>
          }

          {legendVisible && layer === "yieldMap" && 
            <FieldDataGuideCalibrateYieldMap 
              {...this.props} 
              fieldInfo={this.props.fieldInfo}
            />
          }
        </div>
      </React.Fragment>
    );
  }
};

export default MapStatsBasic;

