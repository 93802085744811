import React, { Component } from "react";

import {
  Header,
  Dropdown,
  Table,
  Input,
} from "semantic-ui-react";

import { NUTRIENTS_TO_SKIP, NUTRIENTS_SHOW_IN_SUMMARY } from "@/constants/fertilizers";
import { NUTRIENT_FERTILIZERS } from "@/constants/fertilizers";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

const INITIAL_STATE = {};

class PlanBaseRation extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    this.setDefaultSettings("help");
  };

  componentDidUpdate = (prevProps) => {
    const {
      db,
      guideId,
      guidePreviousRation,
    } = this.props;

    let newDoc = db && guideId && db.guides && db.guides[guideId];
    let oldDoc = prevProps.db && prevProps.guideId && prevProps.db.guides && prevProps.db.guides[prevProps.guideId];

    if (newDoc && oldDoc && newDoc.suggested_base_ration !== oldDoc.suggested_base_ration) {
      if (typeof (guidePreviousRation) === "string") {
        this.setDefaultSettings("plan");
      };
    };
  };

  onClickNextStep = () => {
    const {
      completedStepsBaseRation,
    } = this.props;

    this.props.setParentState({
      openSegmentBaseRation: '',
      completedStepsBaseRation: [...completedStepsBaseRation, "previousRation", "plan"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment, data = null, forceUpdate = false) => {
    const {
      db,
      guideId,
      guideName,
    } = this.props;

    let dbDocGuide = db && guideId && db.guides && db.guides[guideId];
    let suggestedRation = dbDocGuide && dbDocGuide.suggested_base_ration && dbDocGuide.suggested_base_ration;

    switch (newSegment) {

      case "compound":
        let previousCompound = db && db.settings && db.settings.guides;
        previousCompound = previousCompound && previousCompound[guideName];
        previousCompound = previousCompound && previousCompound.compoundType;
        previousCompound = previousCompound ? previousCompound : "none";

        previousCompound && this.props.setParentState({ guideCompoundType: previousCompound });
        break;

      case "plan":
        suggestedRation && this.props.setParentState({ guidePreviousRation: suggestedRation });
        break;

      default:
        break;
    };
  };

  onChange = (data, onlyNumbers = true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  };

  onChangeStruct = (e, data, variableName, onlyNumbers = true) => {
    let currentValue = this.props[variableName];
    let newValue;

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    // Check if the current value is a dict, otherwise replace
    if (currentValue.constructor === Object) {
      newValue = { ...currentValue, [data.name]: value }
    } else {
      newValue = { [data.name]: value }
    }

    this.props.setParentState({
      [variableName]: newValue,
    });
  };

  setOpenSegment = (newSegment) => {
    const {
      openSegmentBaseRation
    } = this.props;

    (openSegmentBaseRation === newSegment) && this.props.setParentState({ openSegmentBaseRation: null });
    (openSegmentBaseRation !== newSegment) && this.props.setParentState({ openSegmentBaseRation: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedStepsBaseRation,
    } = this.props;

    !completedStepsBaseRation.includes(currentSegment) && this.props.setParentState({
      completedStepsBaseRation: [...completedStepsBaseRation, currentSegment],
    });

    this.setDefaultSettings(newSegment);
    !completedStepsBaseRation.includes(newSegment) && this.setOpenSegment(newSegment);
    completedStepsBaseRation.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  onChangeDropDown = (d, variableName) => {
    // this.props.setParentState({ [variableName]: d.value });

    // if (variableName === "guideCompoundType") {
    //   let currentFertilizer = NUTRIENT_FERTILIZERS.find((x) => x.value === d.value);
    //   let guideCompoundNutrientRation = currentFertilizer ? currentFertilizer.nutrients.N : '100'

    //   this.props.setParentState({ guideCompoundNutrientRation });
    // }
  };

  onComputePreviousRation = (variableName, value) => {
    const {
      guidePreviousRationCompoundType,
      guidePreviousRationCompoundWeight,
    } = this.props;

    if (variableName === "guidePreviousRationCompoundWeight") {
      let newWeight = value && value.replace(/[^\d,.]/g, '');
      newWeight = newWeight && newWeight.replace(",", ".");

      let currentFertilizer = NUTRIENT_FERTILIZERS.find((x) => x.value === guidePreviousRationCompoundType);
      let guideCompoundNutrientRation = currentFertilizer ? currentFertilizer.nutrients.N : '100';

      this.props.setParentState({
        guidePreviousRationCompoundWeight: newWeight,
        guidePreviousRation: value ? (parseFloat(value) * (parseFloat(guideCompoundNutrientRation) / 100.0)).toFixed(0) : '',
      });
    };

    if (variableName === "guidePreviousRationCompoundType") {
      let currentFertilizer = NUTRIENT_FERTILIZERS.find((x) => x.value === value);
      let guideCompoundNutrientRation = currentFertilizer ? currentFertilizer.nutrients.N : '100';

      this.props.setParentState({
        guidePreviousRationCompoundType: value,
        guidePreviousRation: value ? (parseFloat(guidePreviousRationCompoundWeight) * (parseFloat(guideCompoundNutrientRation) / 100.0)).toFixed(0) : '',
      });
    };
  };

  render() {

    const {
      db,
      guideId,
      compoundUnit,
      guideHelpBaseRation,
      guideCompoundType,
      guidePreviousRation,
      guidePreviousRationCompoundType,
      guidePreviousRationCompoundWeight,
      guideName,
      openSegmentBaseRation,
      completedStepsBaseRation,
      selectedFieldIds,
    } = this.props;

    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],
      next: [
        {
          caption: "Gå vidare",
          disabled: guideHelpBaseRation ? completedStepsBaseRation.length < 3 : completedStepsBaseRation.length < 2,
          onClick: this.onClickNextStep,
        }
      ],
    };

    let previousCompound = db && db.settings && db.settings.guides;
    previousCompound = previousCompound && previousCompound[guideName];
    previousCompound = previousCompound && previousCompound.compoundType;
    previousCompound = previousCompound ? previousCompound : "none";

    let currentFertilizer = guideCompoundType && NUTRIENT_FERTILIZERS.find((x) => x.value === guideCompoundType);
    let currentFertilizerNutrients = currentFertilizer && currentFertilizer.nutrients;

    let dbDocGuide = db && guideId && db.guides && db.guides[guideId];

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegment
          caption="Vill du ha hjälp med planeringen?"
          simpleModeQuestion="Vill du ha hjälp av Freja för att planera en rak grundgiva?"
          open={openSegmentBaseRation === "help"}
          complete={completedStepsBaseRation && completedStepsBaseRation.includes("help")}
          simpleModeOnSkip={() => {
            this.props.setParentState({ guideHelpBaseRation: false });
            this.gotoNextStep("help", "previousRation");
          }}
          simpleModeQuestionLeftButtonOnClick={() => {
            this.props.getNitrogenBaseRation(selectedFieldIds)
            this.props.setParentState({ guideHelpBaseRation: true });
            this.gotoNextStep("help", "compound");
          }}
          onClickOpenSegment={() => this.setOpenSegment("help")}
        />

        <GuideSegment
          caption="Gödslingsmedel"
          hidden={!guideHelpBaseRation}
          open={openSegmentBaseRation === "compound"}
          simpleModeQuestion="Vill du ange gödslingsmedel?"
          simpleModeDescription="Väljer du nej så beräknas styrfilen i kg N/ha och du får själv ställa in kvävehalten i terminalen."
          simpleModeOnSkip={() => {
            this.gotoNextStep("compound", "plan");
            this.props.setParentState({ guideCompoundType: 'none' });
          }}
          complete={completedStepsBaseRation && completedStepsBaseRation.includes("compound")}
          onClickOpenSegment={() => this.setOpenSegment("compound")}
          onClickNextStep={() => this.gotoNextStep("compound", "plan")}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 15,
              width: "80%",
            }}
          >
            Välj den typ av gödslingsmedel som du kommer att använda i din kvävegödning för denna giva.
            <strong> Du kan ändra detta senare när du justerar styrfilsförslag eller skapar styrfiler för ytterligare kompletteringsgivor.</strong>
          </p>

          <Dropdown
            fluid
            selection
            options={[
              ...NUTRIENT_FERTILIZERS,
              { key: 'none', value: 'none', text: "Rent kväve" },
            ]}
            onChange={(e, d) => this.onChangeDropDown(d, "guideCompoundType")}
            value={guideCompoundType ? guideCompoundType : previousCompound}
          />

          {currentFertilizerNutrients && Object.keys(currentFertilizerNutrients).length > 0 &&
            <React.Fragment>
              <Header
                as="h3"
                style={{
                  fontWeight: 500,
                }}
              >
                Näringsinnehåll
              </Header>

              <Table
                stackable
                celled
                size='small'
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <Table.Header>
                  <Table.Row>
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || !NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div />
                      };
                      return <Table.HeaderCell key={"tableHeader" + x} textAlign="center">{x}</Table.HeaderCell>
                    })}
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div />
                      };
                      return <Table.HeaderCell key={"tableHeader" + x} textAlign="center">{x}</Table.HeaderCell>
                    })}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || !NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div />
                      };
                      return <Table.Cell key={"tableCell" + x} textAlign="center">{currentFertilizerNutrients[x]} %</Table.Cell>
                    })}
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div />
                      };
                      return <Table.Cell key={"tableCell" + x} textAlign="center">{currentFertilizerNutrients[x]} %</Table.Cell>
                    })}
                  </Table.Row>
                </Table.Body>
              </Table>
            </React.Fragment>
          }
        </GuideSegment>

        <GuideSegment
          caption="Gödslingsplanering"
          hidden={!guideHelpBaseRation}
          open={openSegmentBaseRation === "plan"}
          complete={completedStepsBaseRation && completedStepsBaseRation.includes("plan")}
          onClickOpenSegment={() => this.setOpenSegment("plan")}
          onClickNextStep={() => {
            this.props.setParentState({ currentRationNo: 1 });
            this.onClickNextStep();
          }}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 15,
              width: "80%",
            }}
          >
            Baserat på dina val i planeringen så har Freja beräknat att du ska lägga följande raka grundgivor.
            Om du vill justera dessa kan du skriva nya värden i fältet till höger innan du går vidare.
          </p>

          <Table
            celled
            striped
            style={{
              marginTop: "1em",
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Skifte</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Frejas förslag</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Min grundgiva</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {selectedFieldIds && selectedFieldIds
                .map((y) => {

                  let fieldName = db && y && db.fields && db.fields[y] && db.fields[y].name;
                  let suggestedRation = dbDocGuide && dbDocGuide.suggested_base_ration && dbDocGuide.suggested_base_ration[y];


                  return (
                    <Table.Row
                      key={"suggestedBaseRationRow" + y}
                    >
                      <Table.Cell>{fieldName && fieldName}</Table.Cell>
                      <Table.Cell textAlign="center">{suggestedRation ? suggestedRation.toFixed(0) + " " + compoundUnit : '-'}</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Input
                          fluid
                          name={y}
                          value={guidePreviousRation && guidePreviousRation[y] ? guidePreviousRation[y] : ''}
                          // value={suggestedRation.toFixed(0)} 
                          onChange={(e, d) => this.onChangeStruct(e, d, "guidePreviousRation", true)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>

        </GuideSegment>

        <GuideSegment
          caption="Grundgiva"
          hidden={guideHelpBaseRation}
          open={openSegmentBaseRation === "previousRation"}
          complete={completedStepsBaseRation && completedStepsBaseRation.includes("previousRation")}
          onClickOpenSegment={() => this.setOpenSegment("previousRation")}
          onClickNextStep={() => {
            this.props.setParentState({ currentRationNo: 1 });
            this.onClickNextStep();
          }}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 15,
              width: "80%",
            }}
          >
            Hur mycket kvävegödsling har du eller kommer du lägga på som grundgiva?
          </p>

          <Header
            as="h6"
            style={{
              marginBottom: 5,
            }}
          >
            Grundgiva (kg N/ha)
          </Header>

          <Input
            style={{
              width: "50%"
            }}
            value={guidePreviousRation}
            name="guidePreviousRation"
            onChange={(e, d) => {
              this.onChange(d, true);
              this.props.setParentState({ guidePreviousRationCompoundWeight: '0' });
            }}
          />

          <p
            style={{
              whiteSpace: "pre-line",
              marginTop: "1em",
              marginBottom: 15,
              width: "80%",
            }}
          >
            Eller välj preparat och ange mängden i kg/ha av gödslingsmedlet.
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "49%"
              }}
            >
              <Header
                as="h6"
                style={{
                  marginBottom: 5,
                }}
              >
                Välj gödslingsmedel
              </Header>

              <Dropdown
                fluid
                selection
                options={NUTRIENT_FERTILIZERS}
                onChange={(e, d) => this.onComputePreviousRation("guidePreviousRationCompoundType", d.value)}
                value={guidePreviousRationCompoundType}
              />
            </div>

            <div
              style={{
                width: "49%"
              }}
            >
              <Header
                as="h6"
                style={{
                  marginBottom: 5,
                }}
              >
                Grundgiva (kg/ha)
              </Header>

              <Input
                style={{
                  width: "100%"
                }}
                value={guidePreviousRationCompoundWeight}
                onChange={(e, d) => this.onComputePreviousRation("guidePreviousRationCompoundWeight", d.value)}
              />
            </div>
          </div>


        </GuideSegment>

      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader="Grundgiva"
        mainColumnBody="Här planerar du din grundgiva eller anger en grundgiva som du redan har lagt."
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />
    )
  }
}

export default PlanBaseRation;