export const INSIGHT_TAGS = [
    {
        key: "goodYield", 
        value: "goodYield", 
        category: "yield", 
        text: "Skörd: Ofta bättre",
        effects: [],
    },
    {
        key: "badYield",
        value: "badYield",
        category: "yield", 
        text: "Skörd: Ofta sämre",
        effects: [],
    },
    {
        key: "moreFertilizer",
        value: "moreFertilizer",
        category: "fertilization", 
        text: "Gödsling: Behöver mer",
        effects: [],
    },
    {
        key: "lessFertilizer",
        value: "lessFertilizer",
        category: "fertilization", 
        text: "Gödsling: Behöver mindre",
        effects: [],
    },
    {
        key: "goodGermination",
        value: "goodGermination",
        category: "seeding", 
        text: "Sådd: Bra grodd",
        effects: [],
    },
    {
        key: "badGermination",
        value: "badGermination",
        category: "seeding", 
        text: "Sådd: Sämre grodd",
        effects: [],
    },
    {
        key: "sandy", 
        value: "sandy", 
        category: "soil", 
        text: "Jordmån: Sandig backe",
        effects: [],
    },
]