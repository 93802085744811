import React, { Component } from "react";

import {
  Segment,
  Header,
  Table,
} from "semantic-ui-react";

import { CROP_CODES } from "@/constants/cropCodes";

import { Bar } from "react-chartjs-2";

import LoaderModal from "@/components/modals/LoaderModal";

import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";

import { fetchData } from "@/helpers/dataFetcher";

const INITIAL_STATE = {
  statJson: null,
};

class FieldSummaryYield extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    const {
      db,
      fieldId,
      fileBucketRef,
    } = this.props;

    fetchData({
      db,
      fieldId: fieldId,
      layer: "summary_yield",
      layerId: "latest",
      plotName: "none",
      setParentState: (newState) => this.setState(newState),
      fileBucketRef, 
    });
  };

  componentDidUpdate = (prevProps) => {
    const {
      db,
      layer,
      fieldId,
      fileBucketRef,
    } = this.props;

    // Get the data type of the current data layer (float, percent, integer, etc.)
    let dataLayer = getCurrentDataLayer(layer);

    let dbStatDocName = dataLayer && dataLayer.dbStatDocName;
    dbStatDocName = typeof(dbStatDocName) === "string" ? dbStatDocName : dbStatDocName && dbStatDocName[layer];
    let dbDataColName = dbStatDocName && dbStatDocName.split("/")[0];

    let dbCollection = dbDataColName && db && fieldId && db[dbDataColName + "_" + fieldId];
    let prevDbCollection = dbDataColName && prevProps.db && fieldId && prevProps.db[dbDataColName + "_" + fieldId];
    
    let fieldHasChanged = prevProps.fieldId !== fieldId;
    let dbHasChanged =  dbCollection !== prevDbCollection;
    
    if (fieldHasChanged || dbHasChanged) {
        fetchData({
        db,
        fieldId: fieldId,
        layer: "summary_yield",
        layerId: "latest",
        plotName: "none",
        setParentState: (newState) => this.setState(newState),
        fileBucketRef, 
      });
    };
  };  

  render() {
    const {
      fieldId,
    } = this.props;

    const {
      statJson,
    } = this.state;

    const db = this.props.db;
    let hasLoadedData = true;

    // average_yield_field
    // average_yield_region
    // fraction

    let plotLabels = statJson && Object.keys(statJson);

    let plotData = {
      labels: plotLabels,
      datasets: [
        {
          type: "bar",
          label: "Skörd",
          data: plotLabels && statJson && plotLabels.map((x) => statJson[x]["fraction"]),
          fill: false,
          backgroundColor: "#4C8440",
          borderColor: "#4C8440"
        },    
      ]
    };

    let plotOptions = { 
      responsive: true,
      maintainAspectRatio: false,
      layout: {
          padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
          }
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false, 
        position: "bottom",
      },
      series: {
        showPoints: false,
      }
    };

    if (hasLoadedData) {
      return (
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 70,
            width: "100%",
            height: "calc(100vh - 70px)",
            overflowY: "auto",
            whiteSpace: "pre-line",
          }}
        >
          <div
            style={{
              padding: 20,
              paddingTop: 0,
              marginLeft: "5px",
              marginRight: "15px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div 
              style={{
                width: "67%"
              }}
            >
              <Segment
                style={{
                  padding: "1.5em",
                  fontWeight: "normal",
                }}
                loading={!statJson}
              >
                <Header
                  as="h3"
                  style={{
                    paddingTop: 0,
                    fontWeight: 500,
                    color: "grey",
                  }}
                >
                  Procentuell skillnad mellan ditt skiftes och regionens snittskördar
                </Header>

                <article 
                  className="canvas-container"
                  style={{
                    display: "block",
                    height: "40vh",
                    width: "100%",
                    padding: 0,
                    marginTop: 10,
                  }}          
                >
                  {plotData.labels && <Bar data={plotData} options={plotOptions} />}
                </article>
              </Segment>

              <Segment
                style={{
                  padding: "1.5em",
                  fontWeight: "normal",
                }}
              >
                <Header
                  as="h3"
                  style={{
                    paddingTop: 0,
                    fontWeight: 500,
                    color: "grey",
                  }}
                >
                  Dina skördar i jämförelse med regionen
                </Header>
                <Table
                  style={{
                    marginTop: "2em",
                  }}
                  celled
                  striped
                >
                  <Table.Header>
                    <Table.HeaderCell textAlign="center">År</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Gröda</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Skiftets snittskörd (ton/ha)</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Regionens snittskörd (ton/ha)</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Procentuell skillnad</Table.HeaderCell>
                  </Table.Header>
                  
                  <Table.Body>
                    {plotLabels && plotLabels.map((year) => {
                      let crop = db && db.fieldsInfo && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId].crop_code;
                      let cropText = crop && crop[year] && CROP_CODES[crop[year]] && CROP_CODES[crop[year]].shorttext;

                      let averageYieldField = statJson && statJson[year] && statJson[year]["average_yield_field"];
                      let averageYield = statJson && statJson[year] && statJson[year]["average_yield_region"];
                      
                      let fractionYield = statJson && statJson[year] && statJson[year]["fraction"];

                      let fractionYieldText = fractionYield ? fractionYield : "Saknas";
                      
                      if (!isNaN(fractionYieldText)) {
                        fractionYieldText = parseInt(fractionYieldText) > 0 ? fractionYieldText + " % bättre skörd" : fractionYieldText;
                        fractionYieldText = parseInt(fractionYieldText) < 0 ? fractionYieldText + " % sämre skörd" : fractionYieldText;
                      }

                      fractionYieldText = averageYield === averageYieldField ? "Genomsnittlig skörd" : fractionYieldText;

                      return (
                        <Table.Row
                          key={"fieldSummaryYieldYear" + year}
                        >
                          <Table.Cell textAlign="center">{year && year}</Table.Cell>
                          <Table.Cell textAlign="center">{cropText && cropText}</Table.Cell>
                          <Table.Cell textAlign="center">{averageYieldField ? averageYieldField : "Saknas"}</Table.Cell>
                          <Table.Cell textAlign="center">{averageYield ? averageYield : "Saknas"}</Table.Cell>
                          <Table.Cell textAlign="center">{fractionYieldText}</Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>                
              </Segment>

            </div>

            <div
              style={{
                width: "30%"
              }}
            >
              <Segment
                style={{
                  padding: "1.5em",
                  fontWeight: "normal",
                }}
              >
                <div
                  style={{
                    marginBottom: "1em",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Header
                    as="h3"
                    style={{
                      paddingTop: "0",
                      fontWeight: 500,
                      color: "grey",
                    }}
                  >
                    Frejas insikter
                  </Header>
                       
                  {/* <p
                    style={{
                      color: "#6B9D9F",
                      fontSize: "95%",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                    onClick={() => this.props.history.push("/collect/" + fieldId + "/seeding")}
                  >
                    Lägg till
                  </p> */}
                </div>

  
                  <Segment
                    basic
                    style={{
                      marginTop: "1em",
                      textAlign: "center",
                      color: "darkgrey",
                    }}
                  >
                    Freja har inga insikter för skörden ännu.
                  </Segment>
              </Segment>   
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      );
    }
  }
}

export default FieldSummaryYield;
