import React, { Component } from "react";

import {
  withFirestore as withFirestoreActions
} from "react-redux-firebase";

import { 
  Button, 
  Form, 
  Header, 
  Segment,
  Image, 
  Loader,
} from "semantic-ui-react";

import Background from "@/assets/backgrounds/bruno-martins-442354-unsplash-small.jpg";
import Illustration from "@/assets/illustrations/onboarding_guide_1.png";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  processingLogin: false,
};

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    const { email, password } = this.state;
    const { firebase } = this.props;

    const isInvalid = password === "" || email === "";
    
    this.setState({ processingLogin: true });

    if (isInvalid) {
        this.setState({ error: {message: "Ange den mailadress och det lösenord som du registrerade gården med för att logga in." }});
        this.setState({ processingLogin: false });
        return null
    }

    firebase
      .login({ email, password })
      .then(response => {
        this.setState({ processingLogin: false });
      })
      .catch(error => {
        this.setState({ error });
        this.setState({ processingLogin: false });
      });

    event.preventDefault();
  };

  render() {
    const { 
      email, 
      password, 
      error, 
      processingLogin 
    } = this.state;

    let errorMessage = error && error.message;

    errorMessage = errorMessage === "There is no user record corresponding to this identifier. The user may have been deleted." ? 
      "Inget konto hittades på denna mailadress." : errorMessage;

    errorMessage = errorMessage === "The password is invalid or the user does not have a password." ? 
      "Felaktigt lösenord." : errorMessage;      

    return (
      <div 
        style={{
          height: "100vh",
          paddingLeft: "20%",
          paddingRight: "20%",
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "hidden"          
        }}
      >
        <div
          style={{
            height: "60vh",
            marginTop: "20vh",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}
        >
          <Segment
            style={{
              width: "50%",
              height: "100%",
              padding: "2em",
            }}
          >
            <div
              style={{
                width: "67%",
              }}            
            >
            {!processingLogin &&
              <React.Fragment>
                <Form
                  size="large"
                  onSubmit={this.onSubmit}
                  style={{ 
                    width: "67%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Form.Input
                    autoFocus
                    className="inputNoBorders"
                    fluid
                    focus
                    size="large"
                    icon="user"
                    name="email"
                    iconPosition="right"
                    placeholder="Mailadress"
                    value={email}
                    onChange={this.onChange}
                  />

                  <Form.Input
                    fluid
                    focus
                    className="inputNoBorders"
                    size="large"
                    icon="lock"
                    name="password"
                    iconPosition="right"
                    placeholder="Lösenord"
                    type="password"
                    value={password}
                    onChange={this.onChange}
                  />

                  {errorMessage &&
                    <p
                      style={{ 
                        marginTop: "1em",
                        marginBottom: "1em",
                        color: "#D6946B", 
                      }}
                    >
                      {errorMessage}
                    </p>
                  }          

                  <Button
                    primary
                    fluid
                    size="large"
                    onClick={this.onSubmit}
                    style={{ 
                      marginTop: "2em", 
                    }}
                  >
                    Logga in
                  </Button>
                </Form>

                <p
                  style={{
                    width: "67%",
                    position: "absolute",
                    bottom: "0%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "1em",
                    }}
                  >
                    <a 
                      href="https://freja.agriopt.se/resetPassword"
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      Glömt ditt lösenord?
                    </a>
                  </p>

                  <a 
                    style={{ marginRight: 4 }}
                    href="https://agriopt.se/assets/agriopt-anvandarvillkor-current.pdf"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Användarvillkor
                  </a>          

                  &middot;

                  <a 
                    style={{ marginLeft: 4 }}
                    href="https://agriopt.se/assets/agriopt-integritetspolicy-current.pdf"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Integritets- och cookievillkor
                  </a>
                </p>
              </React.Fragment>
            }

            {processingLogin &&
              <Loader 
                active 
                indeterminate 
                size="massive"
              >
                <div
                   style={{
                     fontSize: "80%", 
                     marginTop: "0.5em",
                     color: "grey",
                    }}
                  >
                  Loggar in...
                </div>
              </Loader>            
            }  
            </div>        
          </Segment>

          <Segment
            style={{
              width: "50%",
              height: "90%",
              padding: "2em",
              marginTop: "auto",
              marginBottom: "auto",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: "#f7f4f0",
            }}
          > 
            <Image 
              style={{
                height: processingLogin ? "80%" : "70%",
                marginTop: processingLogin ? "5%" : null,
                marginBottom: processingLogin ? "15%" : null,
                transition: "height 0.3s",
              }}            
              centered 
              src={Illustration}             
            />

            {!processingLogin &&
              <Header
                as="h2"
                textAlign="center"
                color="grey"
                style={{
                  fontWeight: 500,
                }}
              >
                Logga in till Freja
              </Header>
            }

            {!processingLogin &&
              <p
                style={{
                  width: "75%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                }}
              >
                Har du inte något konto på Freja men är intresserad av att veta mer?
                <a 
                  style={{ marginLeft: 4 }}
                  href="https://www.agriopt.se"
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Läs mer på vår hemsida
                </a>            
                .    
              </p>
            }
          </Segment>
        </div>
      </div>
    );
  }
}

export default withFirestoreActions(LoginPage);
