export const MUD_TYPES = [
    { key: 'sv1', min: 0, max: 5, caption: 'Svagt lerig'},
    { key: '1', min: 5, max: 15, caption: 'Lerig'},
    { key: 'LL', min: 15, max: 25, caption: 'Lättlera'},
    { key: 'ML', min: 25, max: 40, caption: 'Mellanlera'},
    { key: 'SL', min: 40, max: 60, caption: 'Styv lera'},
    { key: 'MSL', min: 60, max: 100, caption: 'Mycket styv lera'},
  ];
  
  export const ORGANIC_TYPES = [
    { key: 'mf', min: 0, max: 2, caption: 'Mullfattig'},
    { key: 'nmh', min: 2, max: 3, caption: 'Något mullhaltig'},
    { key: 'mmh', min: 3, max: 6, caption: 'Måttlig mullhaltig'},
    { key: 'mr', min: 6, max: 12, caption: 'Mullrik'},
    { key: 'mkrmr', min: 12, max: 20, caption: 'Mycket mullrik'},
  ];
  
  export const PH_GOAL_PER_SOILTYPE = {
    mfsv1: "6.0",
    nmhsv1: "5.8",
    mmhsv1: "5.5",
    mrsv1: "5.2",
    mkrmrsv1: "5.0",
    mf1: "6.2",
    nmh1: "5.9",
    mmh1: "5.6",
    mr1: "5.3",
    mkrmr1: "5.1",
    mfLL: "6.3",
    nmhLL: "6.0",
    mmhLL: "5.7",
    mrLL: "5.4",
    mkrmrLL: "5.2",
    mfML: "6.4",
    nmhML: "6.1",
    mmhML: "5.8",
    mrML: "5.5",
    mkrmrML: "5.3",
    mfSL: "6.5",
    nmhSL: "6.2",
    mmhSL: "5.9",
    mrSL: "5.6",
    mkrmrSL: "5.4",
    mfMSL: "6.5",
    nmhMSL: "6.2",
    mmhMSL: "5.9",
    mrMSL: "5.6",
    mkrmrMSL: "5.4",
  };
  
  export const LIME_PER_HALF_PH_PER_SOILTYPE = {
    mfsv1: "500",
    nmhsv1: "1000",
    mmhsv1: "1500",
    mrsv1: "2500",
    mkrmrsv1: "4000",
    mf1: "1000",
    nmh1: "1500",
    mmh1: "2000",
    mr1: "3000",
    mkrmr1: "4500",
    mfLL: "2000",
    nmhLL: "2500",
    mmhLL: "3000",
    mrLL: "4000",
    mkrmrLL: "5500",
    mfML: "3000",
    nmhML: "3500",
    mmhML: "4000",
    mrML: "5000",
    mkrmrML: "6500",
    mfSL: "4000",
    nmhSL: "4500",
    mmhSL: "5000",
    mrSL: "6000",
    mkrmrSL: "7500",
    mfMSL: "5000",
    nmhMSL: "5000",
    mmhMSL: "5500",
    mrMSL: "7000",
    mkrmrMSL: "8500",
  };