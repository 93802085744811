import imgGreenMass from "@/assets/icons/dataTypes/green-mass-data.svg";
import imgMoisture from "@/assets/icons/dataTypes/moisture-data.svg";
import imgLeafArea from "@/assets/icons/dataTypes/leaf-area.svg";
import imgMaturity from "@/assets/icons/dataTypes/maturity.svg";
import imgSoil from "@/assets/icons/dataTypes/soil-data.svg";
import imgSatellite from "@/assets/icons/dataTypes/satellite-data.svg";
import imgVraMap from "@/assets/icons/dataTypes/as-applied-maps.svg";
import imgYield from "@/assets/icons/dataTypes/yield-data.svg";
import imgElevation from "@/assets/icons/dataTypes/height-data.svg";
import imgGroundWater from "@/assets/icons/dataTypes/groundwater-data.svg";

import {
  COLOR_LIMITS_CORRELATION,
  COLOR_LIMITS_CLUSTERING,
  COLOR_LIMITS_SEG_MEDIAN,
  COLOR_LIMITS_SEG_VAR,
  COLOR_SCALE_DIFF,
  COLOR_SCALE_HOTSPOT,
  COLOR_SCALE_PERCENT,
  COLOR_SCALE_CORRELATION,
  COLOR_SCALE_SEG_MEDIAN,
  COLOR_SCALE_SEG_VAR,
  HIST_LABELS_CLUSTERING,
  CLASS_MAP2,
  CLASS_MAP4,
  CLASS_MAP5,
  CLASS_MAP6,
} from "./colorScales";

import {
  MAPZEN_ATTRIBUTION,
  SENTINEL_ATTRIBUTION,
  SGU_ATTRIBUTION,
  SLU_MOISTURE_ATTRIBUTION,
} from "./map";

export const KEEP_DATE_LAYERS = [
  "greenmass",
  "moisture",
  "trueColor",
  "lai",
  "psri",
  "greenmassAnalysis",
  "moistureAnalysis",
  "laiAnalysis",
  "psriAnalysis",
  "yieldAnalysis",
  "yieldStatistics",
  "waterAnalysis"
];

export const SHOW_WEATHER_FOR_LAYERS = [
  "greenmass",
  "greenmass_percent",
  "moisture",
  "moisture_percent",
  "lai",
  "lai_percent",
  "psri",
  "psri_percent",
  "yieldMap",
  "yieldMap_dryYield",
  "yieldMap_wetYield",
  "yieldMap_dryYield_percent",
  "yieldMap_wetYield_percent",
  "yieldMap_waterContent_percent",
];

export const SHOW_BASIC_STATS_FOR_LAYERS = [
  "greenmass",
  "moisture",
  "lai",
  "psri",
  "vraMap",
  "vraMapUsed",
  "yieldMap",
  "yieldStatistics",
  "soilMapUploaded",
  "laiAnalysis_clustering",
  "soilMapSweden",
  "elevationMap",
];

export const SHOW_ADVANCED_STATS_FOR_LAYERS = [
  "yieldAnalysis_potential",
  "yieldAnalysis_potentialWinterWheat",
  "yieldAnalysis_clustering",
  "yieldAnalysis_segmentationMedian",
  "yieldAnalysis_segmentationVariance",
  "waterAnalysis_clustering",
  "moistureAnalysis_clustering",
  "greenmassAnalysis_clustering",
  "laiAnalysis_clustering",
  "psriAnalysis_clustering",
];

export const LAYERS_WITH_NEW_DATA_NOTIFICATIONS = {
  greenmass: 'sentinel',
  moisture: 'sentinel',
  lai: 'sentinel',
  psri: 'sentinel',
  trueColor: 'sentinel',
  greenmassAnalysis: 'sentinel',
  moistureAnalysis: 'sentinel',
  laiAnalysis: 'sentinel',
  psriAnalysis: 'sentinel',  
  moistureMapSweden: 'moistureMapSweden',
  soilMapSweden: 'soilMapSweden',
  soilMapUploaded: 'soilMapUploaded',
  soilMapAnalysis: 'soilMapUploaded',
  yieldMap: 'yieldMap',
  yieldAnalysis: 'yieldMap',
  yieldStatistics: 'yieldMap',
  waterAnalysis: 'yieldMap',
};

export const FIELD_DATA_LAYERS = {
  greenmass: {
    name: "greenmass",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['dates'],
    layerConnectedWith: "greenmassAnalysis",
    aliases: [
      "greenmass",
      "greenmass_percent",
      "greenmass_difference",
      "greenmass_hotspot",
      "greenmass_difference_hotspot"
    ],
    icon: imgGreenMass,
    caption: "Grönmassa",
    captions: {
      greenmass: "Grönmassa (MSAVI)",
      greenmass_percent: "Grönmassa som % av snitt",
      // greenmass_hotspot: "Avvikande grönmassa",
      // greenmass_difference: "Tillväxt",
      // greenmass_difference_hotspot: "Avvikande tillväxt",
    },
    shortCaptions: {
      greenmass: "Grönmassa",
      greenmass_percent: "Grönmassa som % av snitt",
      greenmass_hotspot: "Avvikande grönmassa",
      greenmass_difference: "Tillväxt i grönmassa",
      greenmass_difference_hotspot: "Avvikande tillväxt",
    },
    helpText: {
      greenmass: "Värdet på grönmassa från en bild tagen av en satellit. Ett högre värde motsvarar frodigare/tätare biomassa på marken.",
      greenmass_percent: "Värdet på grönmassan som procent av medelvärdet över skiftet. Detta visar områden som är tätare och glesare än snittet.",
      greenmass_difference: "Tillväxt i grönmassa i procent per dag sedan föregående bild.",
      greenmass_hotspot: "Sammanhängande områden med avvikande grönmassa i jämförelse med resten av fältet.",
      greenmass_difference_hotspot: "Sammanhängande områden med avvikande tillväxt i grönmassa.",
    },
    histogramLabels: {
      greenmass: {
        'first': "Glesare grönmassa",
        'middle': "Genomsnitt",
        'last': "Tätare grönmassa",
      },
      greenmass_percent: null,
      greenmass_hotspot: null,
      greenmass_difference: {
        'first': "Minskat",
        'middle': "Oförändrad",
        'last': "Ökat",
      },
      greenmass_difference_hotspot: null,
    },
    dbDataDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
    dbPathDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_msavi_path',
    dbStatDocName: 'fieldsSentinelHubRaw/rawdata_sentinel_optical_info_json',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      greenmass: "filtered",
      greenmass_percent: "filtered_percent",
      greenmass_difference: "diff",
      greenmass_hotspot: "hotspot",
      greenmass_difference_hotspot: "diff_hotspot"
    },
    dataType: {
      greenmass: "float",
      greenmass_percent: "percent",
      greenmass_difference: "difference",
      greenmass_hotspot: "hotspot",
      greenmass_difference_hotspot: "hotspot",
    },
    colorScale: {
      greenmass: "Greens",
      greenmass_percent: COLOR_SCALE_PERCENT,
      greenmass_difference: COLOR_SCALE_DIFF,
      greenmass_hotspot: COLOR_SCALE_HOTSPOT,
      greenmass_difference_hotspot: COLOR_SCALE_HOTSPOT,
    },
    colorLimit: {},
    onlyBeta: ["greenmass_difference", "greenmass_hotspot", "greenmass_difference_hotspot"],
    includedInPlans: ['all'],
  },
  ndvi: {
    name: "ndvi",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['dates'],
    layerConnectedWith: "greenmassAnalysis",
    aliases: [
      "ndvi",
      "ndvi_percent",
      "ndvi_difference",
      "ndvi_hotspot",
      "ndvi_difference_hotspot"
    ],
    icon: imgGreenMass,
    caption: "Grönmassa",
    captions: {
      ndvi: "Grönmassa (NDVI)",
      ndvi_percent: "Grönmassa som % av snitt",
      // ndvi_hotspot: "Avvikande grönmassa",
      // ndvi_difference: "Tillväxt",
      // ndvi_difference_hotspot: "Avvikande tillväxt",
    },
    shortCaptions: {
      ndvi: "Grönmassa",
      ndvi_percent: "Grönmassa som % av snitt",
      ndvi_hotspot: "Avvikande grönmassa",
      ndvi_difference: "Tillväxt i grönmassa",
      ndvi_difference_hotspot: "Avvikande tillväxt",
    },
    helpText: {
      ndvi: "Värdet på grönmassa från en bild tagen av en satellit. Ett högre värde motsvarar frodigare/tätare biomassa på marken.",
      ndvi_percent: "Värdet på grönmassan som procent av medelvärdet över skiftet. Detta visar områden som är tätare och glesare än snittet.",
      ndvi_difference: "Tillväxt i grönmassa i procent per dag sedan föregående bild.",
      ndvi_hotspot: "Sammanhängande områden med avvikande grönmassa i jämförelse med resten av fältet.",
      ndvi_difference_hotspot: "Sammanhängande områden med avvikande tillväxt i grönmassa.",
    },
    histogramLabels: {
      ndvi: {
        'first': "Glesare grönmassa",
        'middle': "Genomsnitt",
        'last': "Tätare grönmassa",
      },
      ndvi_percent: null,
      ndvi_hotspot: null,
      ndvi_difference: {
        'first': "Minskat",
        'middle': "Oförändrad",
        'last': "Ökat",
      },
      ndvi_difference_hotspot: null,
    },
    dbDataDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
    dbPathDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_ndvi_path',
    dbStatDocName: 'fieldsSentinelHubRaw/rawdata_sentinel_optical_info_json',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      ndvi: "filtered",
      ndvi_percent: "filtered_percent",
      ndvi_difference: "diff",
      ndvi_hotspot: "hotspot",
      ndvi_difference_hotspot: "diff_hotspot"
    },
    dataType: {
      ndvi: "float",
      ndvi_percent: "percent",
      ndvi_difference: "difference",
      ndvi_hotspot: "hotspot",
      ndvi_difference_hotspot: "hotspot",
    },
    colorScale: {
      ndvi: "Greens",
      ndvi_percent: COLOR_SCALE_PERCENT,
      ndvi_difference: COLOR_SCALE_DIFF,
      ndvi_hotspot: COLOR_SCALE_HOTSPOT,
      ndvi_difference_hotspot: COLOR_SCALE_HOTSPOT,
    },
    colorLimit: {},
    onlyBeta: ["ndvi_difference", "ndvi_hotspot", "ndvi_difference_hotspot"],
    includedInPlans: ['all'],
  },
  lai: {
    name: "lai",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['dates'],
    layerConnectedWith: "laiAnalysis",
    aliases: [
      "lai",
      "lai_percent",
      "lai_difference",
      "lai_hotspot",
      "lai_difference_hotspot"
    ],
    icon: imgLeafArea,
    caption: "Bladyta",
    captions: {
      lai: "Bladyta",
      lai_percent: "Bladyta som % av snitt",
      // lai_hotspot: "Avvikande bladyta",
      // lai_difference: "Tillväxt",
      // lai_difference_hotspot: "Avvikande tillväxt",
    },
    shortCaptions: {
      lai: "Bladyta",
      lai_percent: "Bladyta som % av snitt",
      lai_hotspot: "Avvikande bladyta",
      lai_difference: "Tillväxt i bladyta",
      lai_difference_hotspot: "Avvikande tillväxt",
    },
    helpText: {
      lai: "Värdet på bladytan från en bild tagen av en satellit. Ett högre värde motsvarar frodigare/tätare biomassa på marken.",
      lai_percent: "Värdet på bladytan som procent av medelvärdet över skiftet. Detta visar områden som är tätare och glesare än snittet.",
      lai_difference: "Tillväxt i bladyta i procent per dag sedan föregående bild.",
      lai_hotspot: "Sammanhängande områden med avvikande bladyta i jämförelse med resten av fältet.",
      lai_difference_hotspot: "Sammanhängande områden med avvikande tillväxt i bladyta.",
    },
    histogramLabels: {
      lai: {
        'first': "Mindre bladyta",
        'middle': "Genomsnitt",
        'last': "Större bladyta",
      },
      lai_percent: null,
      lai_hotspot: null,
      lai_difference: {
        'first': "Minskat",
        'middle': "Oförändrad",
        'last': "Ökat",
      },
      lai_difference_hotspot: null,
    },
    firstDate: "2017-01-01",
    dbDataDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
    dbPathDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_lai_path',
    dbStatDocName: 'fieldsSentinelHubRaw/rawdata_sentinel_optical_info_json',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      lai: "filtered",
      lai_percent: "filtered_percent",
      lai_difference: "diff",
      lai_hotspot: "hotspot",
      lai_difference_hotspot: "diff_hotspot"
    },
    dataType: {
      lai: "float",
      lai_percent: "percent",
      lai_difference: "difference",
      lai_hotspot: "hotspot",
      lai_difference_hotspot: "hotspot",
    },
    colorScale: {
      lai: "Greens",
      lai_percent: COLOR_SCALE_PERCENT,
      lai_difference: COLOR_SCALE_DIFF,
      lai_hotspot: COLOR_SCALE_HOTSPOT,
      lai_difference_hotspot: COLOR_SCALE_HOTSPOT,
    },
    colorLimit: {},
    showBetaLabel: false,
    onlyBeta: ["lai_difference", "lai_hotspot", "lai_difference_hotspot"],
    includedInPlans: ['all'],
  },
  psri: {
    name: "psri",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['dates'],
    layerConnectedWith: "psriAnalysis",
    aliases: [
      "psri",
      "psri_percent",
      "psri_difference",
      "psri_hotspot",
      "psri_difference_hotspot"
    ],
    icon: imgMaturity,
    caption: "Mognadsgrad",
    captions: {
      psri: "Mognadsgrad",
      psri_percent: "Mognadsgrad som % av snitt",
      // psri_hotspot: "Avvikande mognad",
      // psri_difference: "Förändring",
      // psri_difference_hotspot: "Avvikande förändring",
    },
    shortCaptions: {
      psri: "Mognadsgrad",
      psri_percent: "Mognadsgrad som % av snitt",
      psri_hotspot: "Avvikande mognad",
      psri_difference: "Förändring i mognad",
      psri_difference_hotspot: "Avvikande förändring",
    },
    helpText: {
      psri: "Värdet på grödans mognad från en bild tagen av en satellit. Ett högre värde motsvarar gulare/mognare gröda på marken.",
      psri_percent: "Värdet på mognaden som procent av medelvärdet över skiftet. Detta visar områden som är mer eller mindre mognet än snittet.",
      psri_difference: "Föändring i mognaden i procent per dag sedan föregående bild.",
      psri_hotspot: "Sammanhängande områden med avvikande mognad i jämförelse med resten av fältet.",
      psri_difference_hotspot: "Sammanhängande områden med avvikande förändring i mognad.",
    },
    histogramLabels: {
      psri: {
        'first': "Mindre mogen gröda",
        'middle': "Genomsnitt",
        'last': "Mer mogen gröda",
      },
      psri_percent: null,
      psri_hotspot: null,
      psri_difference: {
        'first': "Minskat",
        'middle': "Oförändrad",
        'last': "Ökat",
      },
      psri_difference_hotspot: null,
    },
    firstDate: "2017-01-01",
    dbDataDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
    dbPathDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_psri_path',
    dbStatDocName: 'fieldsSentinelHubRaw/rawdata_sentinel_optical_info_json',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      psri: "filtered",
      psri_percent: "filtered_percent",
      psri_difference: "diff",
      psri_hotspot: "hotspot",
      psri_difference_hotspot: "diff_hotspot"
    },
    dataType: {
      psri: "float",
      psri_percent: "percent",
      psri_difference: "difference",
      psri_hotspot: "hotspot",
      psri_difference_hotspot: "hotspot",
    },
    colorScale: {
      psri: "OrRd",
      psri_percent: COLOR_SCALE_PERCENT,
      psri_difference: COLOR_SCALE_DIFF,
      psri_hotspot: COLOR_SCALE_HOTSPOT,
      psri_difference_hotspot: COLOR_SCALE_HOTSPOT,
    },
    colorLimit: {},
    showBetaLabel: false,
    onlyBeta: ["psri_difference", "psri_hotspot", "psri_difference_hotspot"],
    includedInPlans: ['all'],
  },
  moisture: {
    name: "moisture",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['dates'],
    layerConnectedWith: "moistureAnalysis",
    aliases: [
      "moisture",
      "moisture_percent",
      "moisture_difference",
      "moisture_hotspot",
      "moisture_difference_hotspot",
    ],
    icon: imgMoisture,
    caption: "Fukthalt",
    captions: {
      moisture: "Fukthaltsvärde",
      moisture_percent: "Fukthalt som % av snitt",
      // moisture_hotspot: "Avvikande fuktighet",
      // moisture_difference: "Fuktförändring",
      // moisture_difference_hotspot: "Avvikande fuktförändring",
    },
    shortCaptions: {
      moisture: "Fukthalt",
      moisture_percent: "Fukthalt som % av snittet",
      moisture_hotspot: "Avvikande fuktighet",
      moisture_difference: "Fuktförändring",
      moisture_difference_hotspot: "Avvikande fuktförändring",
    },
    helpText: {
      moisture: "Värdet på fuktighet från en bild tagen av en satellit. Ett högre värde motsvarar fuktigare områden på skiftet.",
      moisture_percent: "Värdet på fuktigheten som procent av medelvärdet över skiftet. Detta visar områden som är fuktigare och torrare än snittet.",
      moisture_difference: "Förändring i fuktighet i procent per dag sedan föregående bild.",
      moisture_hotspot: "Sammanhängande områden med avvikande fuktighet i jämförelse med resten av fältet.",
      moisture_difference_hotspot: "Sammanhängande områden med avvikande förändring av fuktighet.",
    },
    histogramLabels: {
      moisture: {
        first: "Torrare gröda/mark",
        middle: "Genomsnitt",
        last: "Fuktigare gröda/mark",
      },
      moisture_percent: null,
      moisture_hotspot: null,
      moisture_difference: {
        first: "Minskat",
        middle: "Oförändrad",
        last: "Ökat",
      },
      moisture_difference_hotspot: null,
    },
    dbDataDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
    dbPathDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_ndwi_path',
    dbStatDocName: 'fieldsSentinelHubRaw/rawdata_sentinel_optical_info_json',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      moisture: "filtered",
      moisture_percent: "filtered_percent",
      moisture_hotspot: "diff",
      moisture_difference: "hotspot",
      moisture_difference_hotspot: "diff_hotspot",
    },
    dataType: {
      moisture: "float",
      moisture_percent: "percent",
      moisture_hotspot: "difference",
      moisture_difference: "hotspot",
      moisture_difference_hotspot: "hotspot",
    },
    colorScale: {
      moisture: "Blues",
      moisture_percent: COLOR_SCALE_PERCENT,
      moisture_hotspot: COLOR_SCALE_HOTSPOT,
      moisture_difference: COLOR_SCALE_DIFF,
      moisture_difference_hotspot: COLOR_SCALE_HOTSPOT,
    },
    colorLimit: {},
    onlyBeta: ["moisture_difference", "moisture_hotspot", "moisture_difference_hotspot"],
    includedInPlans: ['all'],
  },
  yieldMap: {
    name: "yieldMap",
    source: "yieldMap",
    layerIdTypes: ['years'],
    layerConnectedWith: "yieldAnalysis",
    aliases: [
      "yieldMap",
      "yieldMap_dryYield_percent",
      "yieldMap_wetYield",
      "yieldMap_wetYield_percent",
      "yieldMap_waterContent",
      "yieldMap_waterContent_percent"
    ],
    icon: imgYield,
    caption: "Skördekarta",
    captions: {
      yieldMap: "Torrvikt med 14% vattenhalt",
      yieldMap_dryYield_percent: "Torrvikt som % av snitt",
      yieldMap_wetYield: "Våtvikt",
      yieldMap_wetYield_percent: "Våtvikt som % av snitt",
      yieldMap_waterContent: "Vattenhalt",
      yieldMap_waterContent_percent: "Vattenhalt som % av snitt",
    },
    shortCaptions: {
      yieldMap: "Torrvikt",
      yieldMap_dryYield_percent: "Torrvikt som % av snitt",
      yieldMap_wetYield: "Våtvikt",
      yieldMap_wetYield_percent: "Våtvikt som % av snitt",
      yieldMap_waterContent: "Vattenhalt",
      yieldMap_waterContent_percent: "Vattenhalt som % av snitt",
    },
    helpText: {
      yieldMap: "Torrvikten hos din skörd omräknad med 14% vattenhalt.",
      yieldMap_dryYield_percent: "Torrvikt som procent av snittskörden på fältet omräknat med 14% vattenhalt.",
      yieldMap_wetYield: "Våtvikten hos din skörd.",
      yieldMap_wetYield_percent: "Våtvikt som procent av snittskörden på fältet.",
      yieldMap_waterContent: "Vattenhalt hos din skörd.",
      yieldMap_waterContent_percent: "Vattenhalt som procent av medelvärdet på vattenhalten på fältet.",
    },
    histogramLabels: {
      yieldMap: { postfix: " ton/ha", noDecimals: 2 },
      yieldMap_dryYield_percent: null,
      yieldMap_wetYield: { postfix: " ton/ha", noDecimals: 2 },
      yieldMap_wetYield_percent: null,
      yieldMap_waterContent: { postfix: "%" },
      yieldMap_waterContent_percent: null,
    },
    dbDataDocName: 'fieldsYieldMapsProcessed/rawdata_harvest_maps_geojson',
    dbStatDocName: 'fieldsYieldMapsProcessed/rawdata_harvest_maps_stats',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      yieldMap: "dry_yield",
      yieldMap_dryYield: "dry_yield",
      yieldMap_dryYield_percent: "dry_yield_percent",
      yieldMap_wetYield: "wet_yield",
      yieldMap_wetYield_percent: "wet_yield_percent",
      yieldMap_waterContent: "water_content",
      yieldMap_waterContent_percent: "water_content_percent",
    },
    dataType: {
      yieldMap: "float",
      yieldMap_dryYield: "float",
      yieldMap_dryYield_percent: "percent",
      yieldMap_wetYield: "float",
      yieldMap_wetYield_percent: "percent",
      yieldMap_waterContent: "float",
      yieldMap_waterContent_percent: "percent",
    },
    colorScale: {
      yieldMap: "Greens",
      yieldMap_dryYield: "Greens",
      yieldMap_dryYield_percent: COLOR_SCALE_PERCENT,
      yieldMap_wetYield: "Purples",
      yieldMap_wetYield_percent: COLOR_SCALE_PERCENT,
      yieldMap_waterContent: "Blues",
      yieldMap_waterContent_percent: COLOR_SCALE_PERCENT,
    },
    colorLimit: {},
    includedInPlans: ['all'],
  },

  elevationMap: {
    name: "elevationMap",
    layerIdTypes: {
      elevationMap: ['strings'],
      elevationMap_slope: ['strings'],
      elevationMap_angle: ['strings'],
      elevationMap_yieldMap: ['years']
    },
    attribution: MAPZEN_ATTRIBUTION,
    source: "",
    aliases: [
      "elevationMap",
      "elevationMap_slope",
      "elevationMap_angle",
      "elevationMap_yieldMap",
    ],
    icon: imgElevation,
    caption: "Höjdkarta",
    captions: {
      elevationMap: "Höjd (satellitdata)",
      elevationMap_slope: "Lutning i meter (satellitdata)",
      elevationMap_angle: "Lutning i grader (satellitdata)",
      elevationMap_yieldMap: "Höjd från skördekartor",
    },
    shortCaptions: {
      elevationMap: "Meter över havet",
      elevationMap_slope: "Lutning per meter",
      elevationMap_angle: "Lutning i grader",
      elevationMap_yieldMap: "Meter över havet",
    },
    helpText: {
      elevationMap: "Karta över skiftet i höjd över havet enligt mätning med radar från satellit.",
      elevationMap_slope: "Karta över lutningen på skiften enligt mätning med radar från satellit. Enheten är meter i höjdled per meter längs marken.",
      elevationMap_angle: "Karta över lutningen på skiften enligt mätning med radar från satellit. Enheten är grader per meter längs marken.",
      elevationMap_yieldMap: "Karta över skiftet i höjd över havet enligt mätning GPS i skördetröskan.",
    },
    histogramLabels: {
      elevationMap: { postfix: " m", noDecimals: 0 },
      elevationMap_slope: { postfix: " m", noDecimals: 1 },
      elevationMap_angle: { postfix: " grader", noDecimals: 0 },
      elevationMap_yieldMap: { postfix: " m", noDecimals: 0 },
    },
    dbDataDocName: 'fieldsSentinelHubProcessed/rawdata_sentinel_dem_geojson',
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      elevationMap: "dem",
      elevationMap_slope: "slope",
      elevationMap_angle: "angle",
      elevationMap_yieldMap: "elevation",
    },
    dataType: {
      elevationMap: "float",
      elevationMap_slope: "float",
      elevationMap_angle: "float",
      elevationMap_yieldMap: "float",
    },
    colorScale: {
      elevationMap: ['#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#e6f598', '#abdda4', '#66c2a5', '#3288bd', '#5e4fa2'].reverse(),
      elevationMap_slope: COLOR_SCALE_DIFF,
      elevationMap_angle: COLOR_SCALE_DIFF,
      elevationMap_yieldMap: ['#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#e6f598', '#abdda4', '#66c2a5', '#3288bd', '#5e4fa2'].reverse(),
    },
    colorLimit: {},
    includedInPlans: ['all'],
  },

  moistureMapSweden: {
    name: "moistureMapSweden",
    source: "moistureMapSweden",
    attribution: SLU_MOISTURE_ATTRIBUTION,
    layerIdTypes: ['years'],
    aliases: [
      "moistureMapSweden",
      "moistureMapSweden_moisture",
      "moistureMapSweden_percent",
    ],
    icon: imgGroundWater,
    caption: "Markfuktskarta",
    captions: {
      moistureMapSweden: "Markfukt",
      moistureMapSweden_percent: "Markfukt som % av snitt",
    },
    shortCaptions: {
      moistureMapSweden: "Markfukt",
      moistureMapSweden_percent: "Markfukt som % av snitt",
    },
    helpText: {
      moistureMapSweden: "Markfukten beräknad som ett index mellan 0 och 100. Informationen är hämtad från den Markfuktskartan som tagits fram av SLU och Skogsstyrelsen. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
      moistureMapSweden_percent: "Markfukten beräknad som procent av snittindexet på skiftet. Informationen är hämtad från den Markfuktskartan som tagits fram av SLU och Skogsstyrelsen. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
    },
    histogramLabels: {
      moistureMapSweden: {
        first: "Torrare jord",
        last: "Fuktigare jord",
      },
      moistureMapSweden_moisture: {
        first: "Torrare jord",
        last: "Fuktigare jord",
      },
      moistureMapSweden_percent: null,
    },
    dbDataDocName: 'fieldsMoistureMapsProcessed/rawdata_swedish_moisturemap_geojson',
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      moistureMapSweden: "moisture_class",
      moistureMapSweden_moisture: "moisture_class",
      moistureMapSweden_percent: "moisture_class_percent",
    },
    dataType: {
      moistureMapSweden: "float",
      moistureMapSweden_moisture: "float",
      moistureMapSweden_percent: "percent",
    },
    colorScale: {
      moistureMapSweden: "Blues",
      moistureMapSweden_moisture: "Blues",
      moistureMapSweden_percent: COLOR_SCALE_PERCENT,
    },
    colorLimit: {},
    includedInPlans: ['all'],
  },

  soilMapSweden: {
    name: "soilMapSweden",
    source: "soilMapSweden",
    attribution: SGU_ATTRIBUTION,
    layerIdTypes: ['years'],
    aliases: [
      "soilMapSweden",
      "soilMapSweden_clay",
      "soilMapSweden_sand",
      "soilMapSweden_silt",
      "soilMapSweden_texture"
    ],
    icon: imgSoil,
    caption: "Digitala åkermarkskartan",
    captions: {
      soilMapSweden: "Lerhalt",
      soilMapSweden_sand: "Sandhalt",
      soilMapSweden_silt: "Silthalt",
      soilMapSweden_texture: "Jordtextur (USDA)",
    },
    shortCaptions: {
      soilMapSweden: "Lerhalt",
      soilMapSweden_sand: "Sandhalt",
      soilMapSweden_silt: "Silthalt",
      soilMapSweden_texture: "Jordtextur",
    },
    helpText: {
      soilMapSweden: "Lerhalten i jorden som procent. Informationen är hämtad från den Digitala åkermarkskartan som tagits fram av SLU i samarbete med SGU. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
      soilMapSweden_sand: "Sandhalt i jorden som procent. Informationen är hämtad från den Digitala åkermarkskartan som tagits fram av SLU i samarbete med SGU. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
      soilMapSweden_silt: "Silthalt i jorden som procent. Informationen är hämtad från den Digitala åkermarkskartan som tagits fram av SLU i samarbete med SGU. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
      soilMapSweden_texture: "Jordtextur enligt USDA-skalan. Informationen är hämtad från den Digitala åkermarkskartan som tagits fram av SLU i samarbete med SGU. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
    },
    histogramLabels: {
      soilMapSweden: { postfix: "%", noDecimals: 0 },
      soilMapSweden_clay: { postfix: "%", noDecimals: 0 },
      soilMapSweden_sand: { postfix: "%", noDecimals: 0 },
      soilMapSweden_silt: { postfix: "%", noDecimals: 0 },
      soilMapSweden_texture: {
        first: "Lättare jord",
        last: "Tyngre jord",
      },
    },
    dbDataDocName: 'fieldsSoilMapsProcessed/rawdata_swedish_soilmap_geojson',
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      soilMapSweden: "clay",
      soilMapSweden_clay: "clay",
      soilMapSweden_sand: "sand",
      soilMapSweden_silt: "silt",
      soilMapSweden_texture: "texture",
    },
    dataType: {
      soilMapSweden: "float",
      soilMapSweden_clay: "float",
      soilMapSweden_sand: "float",
      soilMapSweden_silt: "float",
      soilMapSweden_texture: "float",
    },
    colorScale: {
      soilMapSweden: "Purples",
      soilMapSweden_clay: "Purples",
      soilMapSweden_sand: "Oranges",
      soilMapSweden_silt: "Blues",
      soilMapSweden_texture: "BrBG",
    },
    colorLimit: {},
    includedInPlans: ['all'],
  },

  soilMapUploaded: {
    name: "soilMapUploaded",
    source: "soilMapUploaded",
    layerIdTypes: ['years'],
    layerConnectedWith: "soilMapAnalysis",
    aliases: [
      "soilMapUploaded",
      "soilMapUploaded_ph",
      "soilMapUploaded_p_al",
      "soilMapUploaded_k_al",
      "soilMapUploaded_ca_al",
      "soilMapUploaded_mg_al",
      "soilMapUploaded_p_hcl",
      "soilMapUploaded_k_hcl",
      "soilMapUploaded_ca_hcl",
      "soilMapUploaded_mg_hcl",
      "soilMapUploaded_cu_hcl",
      "soilMapUploaded_bor",
      "soilMapUploaded_fe_al",
      "soilMapUploaded_al_al",
      "soilMapUploaded_cd_hno3",
      "soilMapUploaded_clay",
      "soilMapUploaded_sand",
      "soilMapUploaded_silt",
      "soilMapUploaded_organic",
      "soilMapUploaded_k_mg_ratio",
      "soilMapUploaded_samples",
    ],
    icon: imgSoil,
    caption: "Markkartering",
    captions: {
      soilMapUploaded: "pH-värde",
      soilMapUploaded_p_al: 'Lättlösligt fosfor',
      soilMapUploaded_k_al: 'Lättlösligt kalium',
      soilMapUploaded_ca_al: 'Lättlösligt kalcium',
      soilMapUploaded_mg_al: 'Lättlösligt magnesium',
      soilMapUploaded_p_hcl: 'Förråd fosfor',
      soilMapUploaded_k_hcl: 'Förråd kalium',
      soilMapUploaded_ca_hcl: 'Förråd kalcium',
      soilMapUploaded_mg_hcl: 'Förråd magnesium',
      soilMapUploaded_cu_hcl: 'Förråd koppar',
      soilMapUploaded_bor: "Bor",
      soilMapUploaded_fe_al: "Lättlösligt järn",
      soilMapUploaded_al_al: "Lättlösligt aluminium",
      soilMapUploaded_k_mg_ratio: "Kalium-magnesium-kvot",
      soilMapUploaded_cd_hno3: "Kadmium",
      soilMapUploaded_clay: "Lerhalt",
      soilMapUploaded_sand: "Sandhalt",
      soilMapUploaded_silt: "Silthalt",
      soilMapUploaded_organic: "Mullhalt",
      soilMapUploaded_samples: "Tagna markprover",
    },
    shortCaptions: {
      soilMapUploaded: "pH-värde",
      soilMapUploaded_p_al: 'Lättlösligt fosfor',
      soilMapUploaded_k_al: 'Lättlösligt kalium',
      soilMapUploaded_ca_al: 'Lättlösligt kalcium',
      soilMapUploaded_mg_al: 'Lättlösligt magnesium',
      soilMapUploaded_p_hcl: 'Förråd fosfor',
      soilMapUploaded_k_hcl: 'Förråd kalium',
      soilMapUploaded_ca_hcl: 'Förråd kalcium',
      soilMapUploaded_cu_hcl: 'Förråd koppar',
      soilMapUploaded_mg_hcl: 'Förråd magnesium',
      soilMapUploaded_bor: "Bor",
      soilMapUploaded_fe_al: "Lättlösligt järn",
      soilMapUploaded_al_al: "Lättlösligt aluminium",
      soilMapUploaded_cd_hno3: "Kadmium",
      soilMapUploaded_k_mg_ratio: "Kalium-magnesium-kvot",
      soilMapUploaded_clay: "Lerhalt",
      soilMapUploaded_sand: "Sandhalt",
      soilMapUploaded_silt: "Silthalt",
      soilMapUploaded_organic: "Mullhalt",
      soilMapUploaded_samples: "Tagna markprover",
    },
    helpText: {
      soilMapUploaded: "pH-värdet i jorden. Ett pH under 7 betyder att marken är sur och över 7 att den är basisk.",
      soilMapUploaded_ph: "pH-värdet i jorden. Ett pH under 7 betyder att marken är sur och över 7 att den är basisk.",
      soilMapUploaded_p_al: 'Lättlösligt fosfor är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_k_al: 'Lättlösligt kalium är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_ca_al: 'Lättlösligt kalcium är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_mg_al: 'Lättlösligt magnesium är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_p_hcl: 'Förråd fosfor är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_k_hcl: 'Förråd kalium är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_ca_hcl: 'Förråd kalcium är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_cu_hcl: 'Förråd koppar är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_mg_hcl: 'Förråd magnesium är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_bor: 'Förråd bor är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_fe_al: 'Lättlösligt järn är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_al_al: 'Lättlösligt aluminium är den mängd som grödan kan ta upp under flera växtsäsongen.',
      soilMapUploaded_cd_hno3: 'Halten kadmium.',
      soilMapUploaded_k_mg_ratio: 'Kvoten mellan Kalium och Magnesium. Eftersom dessa två näringsämnen konkurrerar med varandra ska kvoten inte vara för stor.',
      soilMapUploaded_clay: "Lerhalten i jorden som procent.",
      soilMapUploaded_sand: "Sandhalt i jorden som procent.",
      soilMapUploaded_silt: "Silthalt i jorden som procent.",
      soilMapUploaded_organic: "Mullhalt i jorden som procent.",
      soilMapUploaded_samples: "Platser där markprover togs som del av karteringen.",
    },
    histogramLabels: {
      soilMapUploaded: { prefix: "pH " },
      soilMapUploaded_p_al: { postfix: " mg (per kg)" },
      soilMapUploaded_k_al: { postfix: " mg (per kg)" },
      soilMapUploaded_ca_al: { postfix: " mg (per kg)" },
      soilMapUploaded_mg_al: { postfix: " mg (per kg)" },
      soilMapUploaded_p_hcl: { postfix: " mg (per kg)" },
      soilMapUploaded_k_hcl: { postfix: " mg (per kg)" },
      soilMapUploaded_ca_hcl: { postfix: " mg (per kg)" },
      soilMapUploaded_cu_hcl: { postfix: " mg (per 100g)" },
      soilMapUploaded_mg_hcl: { postfix: " mg (per kg)" },
      soilMapUploaded_bor: { postfix: " mg (per 100g)" },
      soilMapUploaded_fe_al: { postfix: " mg (per 100g)" },
      soilMapUploaded_al_al: { postfix: " mg (per 100g)" },
      soilMapUploaded_cd_hno3: { postfix: " mg (per 100g)" },
      soilMapUploaded_k_mg_ratio: { noDecimals: 2 },
      soilMapUploaded_clay: { postfix: "%", noDecimals: 0 },
      soilMapUploaded_sand: { postfix: "%", noDecimals: 0 },
      soilMapUploaded_silt: { postfix: "%", noDecimals: 0 },
      soilMapUploaded_organic: { postfix: "%", noDecimals: 1 },
      soilMapUploaded_samples: null,
    },
    dbDataDocName: 'fieldsSoilMapsProcessed/rawdata_uploaded_soilmap_geojson',
    dbStatDocName: null,
    dbMarkersDocName: 'fieldsSoilMapsRaw/samples_in_file',
    geoJsonFieldKey: {
      soilMapUploaded: "ph",
      soilMapUploaded_ph: "ph",
      soilMapUploaded_p_al: 'p_al',
      soilMapUploaded_k_al: 'k_al',
      soilMapUploaded_ca_al: 'ca_al',
      soilMapUploaded_mg_al: 'mg_al',
      soilMapUploaded_p_hcl: 'p_hcl',
      soilMapUploaded_k_hcl: 'k_hcl',
      soilMapUploaded_ca_hcl: 'ca_hcl',
      soilMapUploaded_cu_hcl: 'cu_hcl',
      soilMapUploaded_mg_hcl: 'mg_hcl',
      soilMapUploaded_bor: 'bor',
      soilMapUploaded_fe_al: 'fe_al',
      soilMapUploaded_al_al: 'al_al',
      soilMapUploaded_cd_hno3: 'cd_hno3',
      soilMapUploaded_k_mg_ratio: 'k_mg_ratio',
      soilMapUploaded_clay: "clay",
      soilMapUploaded_sand: "sand",
      soilMapUploaded_silt: "silt",
      soilMapUploaded_organic: "organic",
      soilMapUploaded_samples: "samples",
    },
    dataType: {
      soilMapUploaded: "float",
      soilMapUploaded_ph: "float",
      soilMapUploaded_p_al: 'float',
      soilMapUploaded_k_al: 'float',
      soilMapUploaded_ca_al: 'float',
      soilMapUploaded_mg_al: 'float',
      soilMapUploaded_p_hcl: 'float',
      soilMapUploaded_k_hcl: 'float',
      soilMapUploaded_ca_hcl: 'float',
      soilMapUploaded_cu_hcl: 'float',
      soilMapUploaded_mg_hcl: 'float',
      soilMapUploaded_bor: 'float',
      soilMapUploaded_fe_al: 'float',
      soilMapUploaded_al_al: 'float',
      soilMapUploaded_cd_hno3: 'float',
      soilMapUploaded_k_mg_ratio: 'float',
      soilMapUploaded_clay: "float",
      soilMapUploaded_sand: "float",
      soilMapUploaded_silt: "float",
      soilMapUploaded_organic: "float",
      soilMapUploaded_samples: "integer",
    },
    colorScale: {
      soilMapUploaded: "RdYlGn",
      soilMapUploaded_ph: "RdYlGn",
      soilMapUploaded_p_al: 'RdYlGn',
      soilMapUploaded_k_al: 'RdYlGn',
      soilMapUploaded_ca_al: 'RdYlGn',
      soilMapUploaded_mg_al: 'RdYlGn',
      soilMapUploaded_p_hcl: 'RdYlGn',
      soilMapUploaded_k_hcl: 'RdYlGn',
      soilMapUploaded_ca_hcl: 'RdYlGn',
      soilMapUploaded_cu_hcl: 'RdYlGn',
      soilMapUploaded_mg_hcl: 'RdYlGn',
      soilMapUploaded_bor: 'RdYlGn',
      soilMapUploaded_fe_al: 'RdYlGn',
      soilMapUploaded_al_al: 'RdYlGn',
      soilMapUploaded_cd_hno3: 'RdYlGn',
      soilMapUploaded_k_mg_ratio: 'RdYlGn',
      soilMapUploaded_clay: "Purples",
      soilMapUploaded_sand: "Oranges",
      soilMapUploaded_silt: "Blues",
      soilMapUploaded_organic: "Greens",
    },
    colorLimit: {},
    includedInPlans: ['all'],
  },

  trueColor: {
    name: "trueColor",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['dates'],
    aliases: ["trueColor"],
    icon: imgSatellite,
    caption: "Satellitbild",
    captions: {
      trueColor: "Färgbild",
    },
    shortCaptions: {
      trueColor: "Färgbild",
    },
    helpText: {
      trueColor: "Färgbild",
    },
    histogramLabels: {},
    dataType: {},
    dbDataDocName: "fieldsSentinelHubProcessed/rawdata_sentinel_dates",
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {},
    colorScale: {},
    colorLimit: {},
    includedInPlans: ['all'],
  },

  vraMapUsed: {
    name: "vraMapUsed",
    source: "",
    attribution: "",
    layerIdTypes: ['dates'],
    aliases: [
      "vraMapUsed",
      "vraMapUsedPhosphorus",
      "vraMapUsedPotassium",
      "vraMapUsedLimePh",
      "vraMapUsedLimeStructure",
    ],
    icon: imgVraMap,
    caption: "Styrfiler",
    captions: {
      vraMapUsed: "Kvävegödsling",
      vraMapUsedPhosphorus: "Fosforgödsling",
      vraMapUsedPotassium: "Kaliumgödsling",
      vraMapUsedLimePh: "pH-kalkning",
      vraMapUsedLimeStructure: "Strukturkalkning",
    },
    shortCaptions: {
      vraMapUsed: "Kvävegödsling",
      vraMapUsedPhosphorus: "Fosforgödsling",
      vraMapUsedPotassium: "Kaliumgödsling",
      vraMapUsedLimePh: "pH-kalkning",
      vraMapUsedLimeStructure: "Strukturkalkning",
    },
    helpText: {
      vraMapUsed: "Kvävegödsling",
      vraMapUsedPhosphorus: "Fosforgödsling",
      vraMapUsedPotassium: "Kaliumgödsling",
      vraMapUsedLimePh: "pH-kalkning",
      vraMapUsedLimeStructure: "Strukturkalkning",
    },
    histogramLabels: {
      vraMapUsed: { postfix: " kg N/ha", noDecimals: 0 },
      vraMapUsedPhosphorus: { postfix: " kg P/ha", noDecimals: 0 },
      vraMapUsedPotassium: { postfix: " kg K/ha", noDecimals: 0 },
      vraMapUsedLimePh: { postfix: " kg Ca/ha", noDecimals: 0 },
      vraMapUsedLimeStructure: { postfix: " kg Ca/ha", noDecimals: 0 },
    },
    dbDataDocName: {
      vraMapUsed: "fieldsVraMaps/used_vra_map_nitrogenV1",
      vraMapUsedPhosphorus: "fieldsVraMaps/used_vra_map_phosphorusV1",
      vraMapUsedPotassium: "fieldsVraMaps/used_vra_map_potassiumV1",
      vraMapUsedLimePh: "fieldsVraMaps/used_vra_map_limePhV1",
      vraMapUsedLimeStructure: "fieldsVraMaps/used_vra_map_limeStructureV1",
    },
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      vraMapUsed: "value",
      vraMapUsedPhosphorus: "value",
      vraMapUsedPotassium: "value",
      vraMapUsedLimePh: "value",
      vraMapUsedLimeStructure: "value",
    },
    dataType: {
      vraMapUsed: "float",
      vraMapUsedPhosphorus: "float",
      vraMapUsedPotassium: "float",
      vraMapUsedLimePh: "float",
      vraMapUsedLimeStructure: "float",
    },
    colorScale: {
      vraMapUsed: "spectralReversed",
      vraMapUsedPhosphorus: "spectralReversed",
      vraMapUsedPotassium: "spectralReversed",
      vraMapUsedLimePh: "spectralReversed",
      vraMapUsedLimeStructure: "spectralReversed",
    },
    colorLimit: {},
    includedInPlans: ['all'],
  },
};

export const FIELD_ANALYSIS_LAYERS = {
  greenmassAnalysis: {
    name: "greenmassAnalysis",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['years', 'crops'],
    layerConnectedWith: "greenmass",
    aliases: [
      "greenmassAnalysis",
      "greenmassAnalysis_clustering",
      "greenmassAnalysis_segmentationVariance",
      "greenmassAnalysis_segmentationMedian"],
    icon: imgGreenMass,
    shortCaption: "Grönmassa",
    caption: "Tolkningar av grönmassa",
    captions: {
      greenmassAnalysis: "Procentuell avvikelse (MSAVI)",
      greenmassAnalysis_clustering: "Liknande tillväxt",
      greenmassAnalysis_segmentationMedian: "Avvikelser i grönmassa",
      greenmassAnalysis_segmentationVariance: "Variation i grönmassa",
    },
    shortCaptions: {
      greenmassAnalysis: "Procentuell grönmassa",
      greenmassAnalysis_clustering: "Liknande tillväxt",
      greenmassAnalysis_segmentationVariance: "Variation grönmassa",
      greenmassAnalysis_segmentationMedian: "Snitt grönmassa",
    },
    helpText: {
      greenmassAnalysis: "Grönmassan som procent av snittet under flera år. Dessa områden är därför stabila över flera säsonger.",
      greenmassAnalysis_clustering: "Liknande utveckling av grönmassa på fältet. Dessa områden har liknande tillväxt under flera säsonger.",
      greenmassAnalysis_segmentationVariance: "Mycket/lite varierande grönmassa",
      greenmassAnalysis_segmentationMedian: "Ofta lägre/högre grönmassa än snitt",
    },
    histogramLabels: {
      greenmassAnalysis: null,
      greenmassAnalysis_clustering: HIST_LABELS_CLUSTERING,
      greenmassAnalysis_segmentationVariance: {
        "0": "Normal variation",
        "1": "Ofta gles gröda",
        "2": "Ofta frodig gröda",
        "3": "Hög variation"
      },
      greenmassAnalysis_segmentationMedian: {
        "-1": "Gles gröda",
        "0": "Normal grönmassa",
        "1": "Frodig gröda",
      },
    },
    dbDataDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_msavi_geojson',
    dbStatDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_msavi_stats',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      greenmassAnalysis: "percent_deviations",
      greenmassAnalysis_clustering: "filtered_clustering",
      greenmassAnalysis_segmentationVariance: "segmentation_variance",
      greenmassAnalysis_segmentationMedian: "segmentation_median",
    },
    dataType: {
      greenmassAnalysis: "percent",
      greenmassAnalysis_clustering: "integer",
      greenmassAnalysis_segmentationVariance: "integer",
      greenmassAnalysis_segmentationMedian: "integer",
    },
    colorScale: {
      greenmassAnalysis: COLOR_SCALE_PERCENT,
      greenmassAnalysis_clustering: "dark2",
      greenmassAnalysis_segmentationVariance: COLOR_SCALE_SEG_VAR,
      greenmassAnalysis_segmentationMedian: COLOR_SCALE_SEG_MEDIAN,
    },
    colorLimit: {
      greenmassAnalysis_clustering: COLOR_LIMITS_CLUSTERING,
      greenmassAnalysis_segmentationVariance: COLOR_LIMITS_SEG_VAR,
      greenmassAnalysis_segmentationMedian: COLOR_LIMITS_SEG_MEDIAN,
    },
    onlyBeta: [],
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },

  ndviAnalysis: {
    name: "ndviAnalysis",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['years', 'crops'],
    layerConnectedWith: "greenmass",
    aliases: [
      "ndviAnalysis",
      "ndviAnalysis_clustering",
      "ndviAnalysis_segmentationVariance",
      "ndviAnalysis_segmentationMedian"],
    icon: imgGreenMass,
    shortCaption: "Grönmassa",
    caption: "Tolkningar av grönmassa",
    captions: {
      ndviAnalysis: "Procentuell avvikelse (NDVI)",
      ndviAnalysis_clustering: "Liknande tillväxt",
      ndviAnalysis_segmentationMedian: "Avvikelser i grönmassa",
      ndviAnalysis_segmentationVariance: "Variation i grönmassa",
    },
    shortCaptions: {
      ndviAnalysis: "Procentuell grönmassa",
      ndviAnalysis_clustering: "Liknande tillväxt",
      ndviAnalysis_segmentationVariance: "Variation grönmassa",
      ndviAnalysis_segmentationMedian: "Snitt grönmassa",
    },
    helpText: {
      ndviAnalysis: "Grönmassan som procent av snittet under flera år. Dessa områden är därför stabila över flera säsonger.",
      ndviAnalysis_clustering: "Liknande utveckling av grönmassa på fältet. Dessa områden har liknande tillväxt under flera säsonger.",
      ndviAnalysis_segmentationVariance: "Mycket/lite varierande grönmassa",
      ndviAnalysis_segmentationMedian: "Ofta lägre/högre grönmassa än snitt",
    },
    histogramLabels: {
      ndviAnalysis: null,
      ndviAnalysis_clustering: HIST_LABELS_CLUSTERING,
      ndviAnalysis_segmentationVariance: {
        "0": "Normal variation",
        "1": "Ofta gles gröda",
        "2": "Ofta frodig gröda",
        "3": "Hög variation"
      },
      ndviAnalysis_segmentationMedian: {
        "-1": "Gles gröda",
        "0": "Normal grönmassa",
        "1": "Frodig gröda",
      },
    },
    dbDataDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_ndvi_geojson',
    dbStatDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_ndvi_stats',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      ndviAnalysis: "percent_deviations",
      ndviAnalysis_clustering: "filtered_clustering",
      ndviAnalysis_segmentationVariance: "segmentation_variance",
      ndviAnalysis_segmentationMedian: "segmentation_median",
    },
    dataType: {
      ndviAnalysis: "percent",
      ndviAnalysis_clustering: "integer",
      ndviAnalysis_segmentationVariance: "integer",
      ndviAnalysis_segmentationMedian: "integer",
    },
    colorScale: {
      ndviAnalysis: COLOR_SCALE_PERCENT,
      ndviAnalysis_clustering: "dark2",
      ndviAnalysis_segmentationVariance: COLOR_SCALE_SEG_VAR,
      ndviAnalysis_segmentationMedian: COLOR_SCALE_SEG_MEDIAN,
    },
    colorLimit: {
      ndviAnalysis_clustering: COLOR_LIMITS_CLUSTERING,
      ndviAnalysis_segmentationVariance: COLOR_LIMITS_SEG_VAR,
      ndviAnalysis_segmentationMedian: COLOR_LIMITS_SEG_MEDIAN,
    },
    onlyBeta: [],
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },

  laiAnalysis: {
    name: "laiAnalysis",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['years', 'crops'],
    layerConnectedWith: "lai",
    aliases: [
      "laiAnalysis",
      "laiAnalysis_clustering",
      "laiAnalysis_segmentationVariance",
      "laiAnalysis_segmentationMedian"],
    icon: imgLeafArea,
    shortCaption: "Bladyta",
    caption: "Tolkningar av bladyta",
    captions: {
      laiAnalysis: "Procentuell avvikelse",
      laiAnalysis_clustering: "Liknande bladyta",
      laiAnalysis_segmentationMedian: "Avvikelser i bladyta",
      laiAnalysis_segmentationVariance: "Variation i bladyta",
    },
    shortCaptions: {
      laiAnalysis: "Procentuell bladyta",
      laiAnalysis_clustering: "Liknande bladyta",
      laiAnalysis_segmentationVariance: "Variation bladyta",
      laiAnalysis_segmentationMedian: "Snitt bladyta",
    },
    helpText: {
      laiAnalysis: "Bladytan som procent av snittet under flera år. Dessa områden är därför stabila över flera säsonger.",
      laiAnalysis_clustering: "Liknande utveckling av bladyta på fältet. Dessa områden har liknande tillväxt under flera säsonger.",
      laiAnalysis_segmentationVariance: "Mycket/lite varierande bladyta",
      laiAnalysis_segmentationMedian: "Ofta lägre/högre bladyta än snitt",
    },
    histogramLabels: {
      laiAnalysis: null,
      laiAnalysis_clustering: HIST_LABELS_CLUSTERING,
      laiAnalysis_segmentationVariance: {
        "0": "Normal variation",
        "1": "Ofta gles gröda",
        "2": "Ofta frodig gröda",
        "3": "Hög variation"
      },
      laiAnalysis_segmentationMedian: {
        "-1": "Liten bladyta",
        "0": "Normal bladyta",
        "1": "Stor bladyta",
      },
    },
    dbDataDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_lai_geojson',
    dbStatDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_lai_stats',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      laiAnalysis: "percent_deviations",
      laiAnalysis_clustering: "filtered_clustering",
      laiAnalysis_segmentationVariance: "segmentation_variance",
      laiAnalysis_segmentationMedian: "segmentation_median",
    },
    dataType: {
      laiAnalysis: "percent",
      laiAnalysis_clustering: "integer",
      laiAnalysis_segmentationVariance: "integer",
      laiAnalysis_segmentationMedian: "integer",
    },
    colorScale: {
      laiAnalysis: COLOR_SCALE_PERCENT,
      laiAnalysis_clustering: "dark2",
      laiAnalysis_segmentationVariance: COLOR_SCALE_SEG_VAR,
      laiAnalysis_segmentationMedian: COLOR_SCALE_SEG_MEDIAN,
    },
    colorLimit: {
      laiAnalysis_clustering: COLOR_LIMITS_CLUSTERING,
      laiAnalysis_segmentationVariance: COLOR_LIMITS_SEG_VAR,
      laiAnalysis_segmentationMedian: COLOR_LIMITS_SEG_MEDIAN,
    },
    showBetaLabel: false,
    onlyBeta: [],
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },

  psriAnalysis: {
    name: "psriAnalysis",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['years', 'crops'],
    layerConnectedWith: "psri",
    aliases: [
      "psriAnalysis",
      "psriAnalysis_clustering",
      "psriAnalysis_segmentationVariance",
      "psriAnalysis_segmentationMedian"],
    icon: imgMaturity,
    shortCaption: "Mognadsgrad",
    caption: "Tolkningar av mognadsgrad",
    captions: {
      psriAnalysis: "Procentuell avvikelse",
      psriAnalysis_clustering: "Liknande mognad",
      psriAnalysis_segmentationMedian: "Avvikelser i mognad",
      psriAnalysis_segmentationVariance: "Variation i mognad",
    },
    shortCaptions: {
      psriAnalysis: "Procentuell mognad",
      psriAnalysis_clustering: "Liknande mognad",
      psriAnalysis_segmentationVariance: "Variation mognad",
      psriAnalysis_segmentationMedian: "Snitt mognad",
    },
    helpText: {
      psriAnalysis: "Mognaden som procent av snittet under flera år. Dessa områden är därför stabila över flera säsonger.",
      psriAnalysis_clustering: "Liknande utveckling av mognad på fältet. Dessa områden har liknande tillväxt under flera säsonger.",
      psriAnalysis_segmentationVariance: "Mycket/lite varierande mognad",
      psriAnalysis_segmentationMedian: "Ofta lägre/högre mognad än snitt",
    },
    histogramLabels: {
      psriAnalysis: null,
      psriAnalysis_clustering: HIST_LABELS_CLUSTERING,
      psriAnalysis_segmentationVariance: {
        "0": "Normal variation",
        "1": "Ofta lägre mognad",
        "2": "Ofta större mognad",
        "3": "Hög variation"
      },
      psriAnalysis_segmentationMedian: {
        "-1": "Lägre mognad",
        "0": "Normal mognad",
        "1": "Större mognad",
      },
    },
    dbDataDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_psri_geojson',
    dbStatDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_psri_stats',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      psriAnalysis: "percent_deviations",
      psriAnalysis_clustering: "filtered_clustering",
      psriAnalysis_segmentationVariance: "segmentation_variance",
      psriAnalysis_segmentationMedian: "segmentation_median",
    },
    dataType: {
      psriAnalysis: "percent",
      psriAnalysis_clustering: "integer",
      psriAnalysis_segmentationVariance: "integer",
      psriAnalysis_segmentationMedian: "integer",
    },
    colorScale: {
      psriAnalysis: COLOR_SCALE_PERCENT,
      psriAnalysis_clustering: "dark2",
      psriAnalysis_segmentationVariance: COLOR_SCALE_SEG_VAR,
      psriAnalysis_segmentationMedian: COLOR_SCALE_SEG_MEDIAN,
    },
    colorLimit: {
      psriAnalysis_clustering: COLOR_LIMITS_CLUSTERING,
      psriAnalysis_segmentationVariance: COLOR_LIMITS_SEG_VAR,
      psriAnalysis_segmentationMedian: COLOR_LIMITS_SEG_MEDIAN,
    },
    showBetaLabel: false,
    onlyBeta: [],
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },

  moistureAnalysis: {
    name: "moistureAnalysis",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['years', 'crops'],
    layerConnectedWith: "moisture",
    aliases: [
      "moistureAnalysis",
      "moistureAnalysis_clustering",
      "moistureAnalysis_segmentationVariance",
      "moistureAnalysis_segmentationMedian",
    ],
    icon: imgMoisture,
    shortCaption: "Fukthalt",
    caption: "Tolkningar av fukthalt",
    captions: {
      moistureAnalysis: "Procentuell avvikelse",
      moistureAnalysis_clustering: "Liknande fukthalt",
      moistureAnalysis_segmentationMedian: "Avvikelser i fukthalt",
      moistureAnalysis_segmentationVariance: "Variation i fukthalt",
    },
    shortCaptions: {
      moistureAnalysis: "Procentuell fukthalt",
      moistureAnalysis_clustering: "Liknande fukt",
      moistureAnalysis_segmentationVariance: "Variation fukthalt",
      moistureAnalysis_segmentationMedian: "Snitt fukthalt",
    },
    helpText: {
      moistureAnalysis: "Fuktigheten som procent av snittet under flera år. Dessa områden är därför stabila över flera säsonger.",
      moistureAnalysis_clustering: "Liknande utveckling av fuktigheten på fältet. Dessa områden har liknande förändring i fuktigheten under flera säsonger.",
      moistureAnalysis_segmentationVariance: "Variation fuktighet",
      moistureAnalysis_segmentationMedian: "Snitt fuktighet",
    },
    histogramLabels: {
      moistureAnalysis: null,
      moistureAnalysis_clustering: HIST_LABELS_CLUSTERING,
      moistureAnalysis_segmentationVariance: {
        "0": "Normal variation",
        "1": "Ofta torrt",
        "2": "Ofta fuktigt",
        "3": "Hög variation"
      },
      moistureAnalysis_segmentationMedian: {
        "-1": "Torrt",
        "0": "Normal fuktighet",
        "1": "Blött",
      },
    },
    dbDataDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_ndwi_geojson',
    dbStatDocName: 'fieldsSentinelHubProcessed/analysis_sentinel_ndwi_stats',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      moistureAnalysis: "percent_deviations",
      moistureAnalysis_clustering: "filtered_clustering",
      moistureAnalysis_segmentationVariance: "segmentation_variance",
      moistureAnalysis_segmentationMedian: "segmentation_median",
    },
    dataType: {
      moistureAnalysis: "percent",
      moistureAnalysis_clustering: "integer",
      moistureAnalysis_segmentationVariance: "integer",
      moistureAnalysis_segmentationMedian: "integer",
    },
    colorScale: {
      moistureAnalysis: COLOR_SCALE_PERCENT,
      moistureAnalysis_clustering: "dark2",
      moistureAnalysis_segmentationVariance: COLOR_SCALE_SEG_VAR,
      moistureAnalysis_segmentationMedian: COLOR_SCALE_SEG_MEDIAN,
    },
    colorLimit: {
      moistureAnalysis_clustering: COLOR_LIMITS_CLUSTERING,
      moistureAnalysis_segmentationVariance: COLOR_LIMITS_SEG_VAR,
      moistureAnalysis_segmentationMedian: COLOR_LIMITS_SEG_MEDIAN,
    },
    onlyBeta: [],
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },

  soilMapAnalysis: {
    name: "soilMapAnalysis",
    source: "soilMapUploaded",
    layerIdTypes: ['years'],
    layerConnectedWith: "soilMapUploaded",
    aliases: [
      "soilMapAnalysis",
      "soilMapAnalysis_p_al",
      "soilMapAnalysis_k_al",
      "soilMapAnalysis_mg_al",
      "soilMapAnalysis_p_hcl",
      "soilMapAnalysis_k_hcl",
      "soilMapAnalysis_mg_hcl",
      "soilMapAnalysis_k_mg_warning",
      "soilMapAnalysis_ca_need",
      "soilMapAnalysis_samples",
    ],
    icon: imgSoil,
    shortCaption: "Markkartering",
    caption: "Tolkningar av markkartering",
    captions: {
      soilMapAnalysis: 'Lättlösligt fosfor',
      // soilMapAnalysis_p_al: 'Lättlösligt fosfor',
      soilMapAnalysis_k_al: 'Lättlösligt kalium',
      soilMapAnalysis_mg_al: 'Lättlösligt magnesium',
      soilMapAnalysis_p_hcl: 'Förråd fosfor',
      soilMapAnalysis_k_hcl: 'Förråd kalium',
      soilMapAnalysis_mg_hcl: 'Förråd magnesium',
      soilMapAnalysis_k_mg_warning: "Kalium-magnesium-kvot",
      soilMapAnalysis_ca_need: "Kalkbehov",
      soilMapAnalysis_samples: "Tagna markprover",
    },
    shortCaptions: {
      soilMapAnalysis: 'Lättlösligt fosfor',
      // soilMapAnalysis_p_al: 'Lättlösligt fosfor',
      soilMapAnalysis_k_al: 'Lättlösligt kalium',
      soilMapAnalysis_mg_al: 'Lättlösligt magnesium',
      soilMapAnalysis_p_hcl: 'Förråd fosfor',
      soilMapAnalysis_k_hcl: 'Förråd kalium',
      soilMapAnalysis_mg_hcl: 'Förråd magnesium',
      soilMapAnalysis_k_mg_warning: "Kalium-magnesium-kvot",
      soilMapAnalysis_ca_need: "Kalkbehov",
      soilMapAnalysis_samples: "Tagna markprover",
    },
    helpText: {
      soilMapAnalysis: 'Lättlösligt fosfor är den mängd som grödan kan ta upp under växtsäsongen.',
      // soilMapAnalysis_p_al: 'Lättlösligt fosfor är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapAnalysis_k_al: 'Lättlösligt kalium är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapAnalysis_mg_al: 'Lättlösligt magnesium är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapAnalysis_p_hcl: 'Förråd fosfor är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapAnalysis_k_hcl: 'Förråd kalium är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapAnalysis_mg_hcl: 'Förråd magnesium är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapAnalysis_k_mg_warning: 'Varningar om kvoten mellan Kalium och Magnesium är för hög givet tillången på lättlösligt Kalium.',
      soilMapAnalysis_ca_need: "Kalkbehov i ton/ha för att uppnå rätt ett bra pH-värde.",
      soilMapAnalysis_samples: "Platser där markprover togs som del av karteringen.",
    },
    histogramLabels: {
      soilMapAnalysis: CLASS_MAP6,
      // soilMapAnalysis_p_al: CLASS_MAP6,
      soilMapAnalysis_k_al: CLASS_MAP5,
      soilMapAnalysis_mg_al: CLASS_MAP4,
      soilMapAnalysis_p_hcl: CLASS_MAP5,
      soilMapAnalysis_k_hcl: CLASS_MAP5,
      soilMapAnalysis_mg_hcl: CLASS_MAP5,
      soilMapAnalysis_k_mg_warning: CLASS_MAP2,
      soilMapAnalysis_ca_need: { postfix: " ton CaO/ha" },
      soilMapAnalysis_samples: null,
    },
    dbDataDocName: 'fieldsSoilMapsProcessed/rawdata_uploaded_soilmap_geojson',
    dbStatDocName: null,
    dbMarkersDocName: 'fieldsSoilMapsRaw/samples_in_file',
    geoJsonFieldKey: {
      soilMapAnalysis: "p_al",
      // soilMapAnalysis_p_al: 'p_al',
      soilMapAnalysis_k_al: 'k_al',
      soilMapAnalysis_mg_al: 'mg_al',
      soilMapAnalysis_p_hcl: 'p_hcl',
      soilMapAnalysis_k_hcl: 'k_hcl',
      soilMapAnalysis_mg_hcl: 'mg_hcl',
      soilMapAnalysis_k_mg_warning: 'k_mg_warning',
      soilMapAnalysis_ca_need: 'ca_need',
      soilMapAnalysis_samples: "samples",
    },
    dataType: {
      soilMapAnalysis: "integer",
      soilMapAnalysis_p_al: 'integer',
      soilMapAnalysis_k_al: 'integer',
      soilMapAnalysis_mg_al: 'integer',
      soilMapAnalysis_p_hcl: 'integer',
      soilMapAnalysis_k_hcl: 'integer',
      soilMapAnalysis_mg_hcl: 'integer',
      soilMapAnalysis_k_mg_warning: 'integer',
      soilMapAnalysis_ca_need: 'float',
      soilMapAnalysis_samples: "integer",
    },
    colorScale: {
      soilMapAnalysis: "Spectral",
      soilMapAnalysis_p_al: 'Spectral',
      soilMapAnalysis_k_al: 'Spectral',
      soilMapAnalysis_mg_al: 'RdYlGn',
      soilMapAnalysis_p_hcl: 'Spectral',
      soilMapAnalysis_k_hcl: 'Spectral',
      soilMapAnalysis_mg_hcl: 'RdYlGn',
      soilMapAnalysis_k_mg_warning: 'Spectral',
      soilMapAnalysis_ca_need: 'RdYlGn',
    },
    colorLimit: {
      soilMapAnalysis: [0.0, 2.0, 4.0, 8.0, 12.0, 16.0, 100000.0],
      soilMapAnalysis_p_al: [0.0, 2.0, 4.0, 8.0, 12.0, 16.0, 100000.0],
      soilMapAnalysis_k_al: [0.0, 4.0, 8.0, 16.0, 32.0, 100000.0],
      soilMapAnalysis_mg_al: [0.0, 4.0, 10.0, 100000.0],
      soilMapAnalysis_p_hcl: [0.0, 20.0, 41.0, 61.0, 80.0, 100000.0],
      soilMapAnalysis_k_hcl: [0.0, 50.0, 101.0, 201.0, 400.0, 100000.0],
      soilMapAnalysis_k_mg_warning: [-0.01, 0.5, 1.01],
    },
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },

  yieldStatistics: {
    name: "yieldStatistics",
    source: "yieldMap",
    layerIdTypes: ['crops'],
    layerConnectedWith: "yieldMap",
    aliases: [
      "yieldStatistics",
      "yieldStatistics_max",
      "yieldStatistics_goodYear",
      "yieldStatistics_badYear",
    ],
    icon: imgYield,
    shortCaption: "Skördestatistik",
    caption: "Statistik från skördekartor",
    captions: {
      yieldStatistics: "Snittskörd",
      yieldStatistics_max: "Maximal skörd",
      yieldStatistics_goodYear: "Uppskattad skörd ett bättre år",
      yieldStatistics_badYear: "Uppskattad skörd ett sämre år",
    },
    shortCaptions: {
      yieldStatistics: "Snittskörd",
      yieldStatistics_max: "Maximal skörd",
      yieldStatistics_goodYear: "Skörd bra år",
      yieldStatistics_badYear: "Skörd sämre år",
    },
    helpText: {
      yieldStatistics: "Denna karta visar den historiska snittskörden i varje punkt och baseras på de skördekartor som har laddats upp för detta skifte.",
      yieldStatistics_max: "Denna karta visar den maximala historiska skörden i varje punkt och baseras på de skördekartor som har laddats upp för detta skifte.",
      yieldStatistics_goodYear: "Denna karta visar den beräknade skörden ur ett historiskt perspektiv under ett bättre år baserat på de skördekartor som har laddats upp för detta skifte.",
      yieldStatistics_badYear: "Denna karta visar den beräknade skörden ur ett historiskt perspektiv under ett sämre år baserat på de skördekartor som har laddats upp för detta skifte.",
    },
    histogramLabels: {
      yieldStatistics: { postfix: " ton/ha" },
      yieldStatistics_max: { postfix: " ton/ha" },
      yieldStatistics_goodYear: { postfix: " ton/ha" },
      yieldStatistics_badYear: { postfix: " ton/ha" },
    },
    dbDataDocName: 'fieldsYieldMapsProcessed/analysis_stats_harvest_maps_geojson',
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      yieldStatistics: "yield_median",
      yieldStatistics_max: "yield_max",
      yieldStatistics_goodYear: "yield_good_year",
      yieldStatistics_badYear: "yield_bad_year",
    },
    dataType: {
      yieldStatistics: "float",
      yieldStatistics_max: "float",
      yieldStatistics_goodYear: "float",
      yieldStatistics_badYear: "float",
    },
    colorScale: {
      yieldStatistics: COLOR_SCALE_DIFF,
      yieldStatistics_max: COLOR_SCALE_DIFF,
      yieldStatistics_goodYear: COLOR_SCALE_DIFF,
      yieldStatistics_badYear: COLOR_SCALE_DIFF,
    },
    colorLimit: {},
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },

  yieldAnalysis: {
    name: "yieldAnalysis",
    source: "yieldMap",
    layerIdTypes: ['crops'],
    layerConnectedWith: "yieldMap",
    aliases: [
      "yieldAnalysis",
      "yieldAnalysis_potential",
      "yieldAnalysis_deviations",
      "yieldAnalysis_clustering",
      "yieldAnalysis_segmentationVariance",
      "yieldAnalysis_segmentationMedian",
    ],
    icon: imgYield,
    shortCaption: "Skördekartor",
    caption: "Tolkningar av skördekartor",
    captions: {
      yieldAnalysis: "Procentuell avvikelse",
      yieldAnalysis_potential: "Historisk skördepotential",
      yieldAnalysis_clustering: "Liknande skörd",
      yieldAnalysis_segmentationMedian: "Avvikelser i skörd",
      yieldAnalysis_segmentationVariance: "Variation i skörd",
    },
    shortCaptions: {
      yieldAnalysis: "Procentuell skörd",
      yieldAnalysis_potential: "Skördepotential",
      yieldAnalysis_clustering: "Liknande skörd",
      yieldAnalysis_segmentationVariance: "Skördevariation",
      yieldAnalysis_segmentationMedian: "Skördeavvikelser",
    },
    helpText: {
      yieldAnalysis: "Denna analys beskriver hur mycket varje punkt avvikter från snittskörden i procent i snitt över åren. En punkt som har ett värde på +15% har därför ofta 15% mer skörd än snittet på skiftet under varje år oberoende av gröda.",
      yieldAnalysis_potential: "Den uppskattade skördepotentialen baseras på uppladdade skördekartor från flera år. Vi beräknar skörden genom att använda oss av skördeanalysen med liknande skörd från år till år. Genom att bygga en modell för skörden kan vi delvis ta hänsyn till årsmånen för att hitta intervall på den maximala skörden samt det mest troliga utfallet.",
      yieldAnalysis_clustering: "Denna analys plockar fram ett antal områden på skiftet där skörden är liknande från år till år oberoende av om olika grödor har odlats. Vi visar också upp hur snittkörden för varje område har varit under de åren som skördekartor finns tillgängliga för.",
      yieldAnalysis_segmentationVariance: "Denna analys tar fram områden där skörden varierar mycket eller lite från år till år. Vi tar även fram områden som har stabil högre och lägre skörd baserat på uppladdade skördekartor. Denna information är viktig för att planera framtida insatser för att förbättrad skörden. Detta kan exempelvis göras genom att jämföra dessa områden och förstå de faktorer som påverkar skörden.",
      yieldAnalysis_segmentationMedian: "Denna analys tar fram områden där skörden ofta är sämre eller bättre. Denna information kan vara viktig för lära sig mer om vilka faktorer som avgör om skörden blir bättre eller sämre.",
    },
    histogramLabels: {
      yieldAnalysis: null,
      yieldAnalysis_potential: null,
      yieldAnalysis_clustering: HIST_LABELS_CLUSTERING,
      yieldAnalysis_segmentationVariance: {
        "0": "Normal variation",
        "1": "Ofta låg skörd",
        "2": "Ofta hög skörd",
        "3": "Hög variation"
      },
      yieldAnalysis_segmentationMedian: {
        "-1": "Låg skörd",
        "0": "Snittskörd",
        "1": "Hög skörd",
      }
    },
    dbDataDocName: 'fieldsYieldMapsProcessed/analysis_harvest_maps_dry_yield_geojson',
    dbStatDocName: 'fieldsYieldMapsProcessed/analysis_harvest_maps_dry_yield_stats',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      yieldAnalysis: "percent_deviations",
      yieldAnalysis_potential: "yield_potential",
      yieldAnalysis_clustering: "clustering",
      yieldAnalysis_segmentationVariance: "segmentation_variance",
      yieldAnalysis_segmentationMedian: "segmentation_median",
    },
    dataType: {
      yieldAnalysis: "percent",
      yieldAnalysis_potential: "integer",
      yieldAnalysis_clustering: "integer",
      yieldAnalysis_segmentationVariance: "integer",
      yieldAnalysis_segmentationMedian: "integer",
    },
    colorScale: {
      yieldAnalysis: COLOR_SCALE_PERCENT,
      yieldAnalysis_potential: "dark2",
      yieldAnalysis_clustering: "dark2",
      yieldAnalysis_segmentationVariance: COLOR_SCALE_SEG_VAR,
      yieldAnalysis_segmentationMedian: COLOR_SCALE_SEG_MEDIAN,
    },
    colorLimit: {
      yieldAnalysis_potential: COLOR_LIMITS_CLUSTERING,
      yieldAnalysis_clustering: COLOR_LIMITS_CLUSTERING,
      yieldAnalysis_segmentationVariance: COLOR_LIMITS_SEG_VAR,
      yieldAnalysis_segmentationMedian: COLOR_LIMITS_SEG_MEDIAN,
    },
    onlyBeta: [],
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },

  waterAnalysis: {
    name: "waterAnalysis",
    source: "yieldMap",
    layerIdTypes: ['crops'],
    layerConnectedWith: "yieldMap",
    aliases: [
      "waterAnalysis",
      "waterAnalysis_deviations",
      "waterAnalysis_clustering",
    ],
    icon: imgYield,
    shortCaption: "Vattenhalt",
    caption: "Tolkningar av vattenhalt",
    captions: {
      waterAnalysis: "Procentuell avvikelse",
      waterAnalysis_clustering: "Liknande vattenhalt",
    },
    shortCaptions: {
      waterAnalysis: "Procentuell vattenhalt",
      waterAnalysis_clustering: "Liknande vattenhalt",
    },
    helpText: {
      waterAnalysis: "Procentuell avvikelse i vattenhalten från snittet under flera år.",
      waterAnalysis_clustering: "Områden med liknande vattenhalt under flera år.",
    },
    histogramLabels: {
      waterAnalysis: null,
      waterAnalysis_clustering: HIST_LABELS_CLUSTERING,
    },
    dbDataDocName: 'fieldsYieldMapsProcessed/analysis_harvest_maps_water_content_geojson',
    dbStatDocName: 'fieldsYieldMapsProcessed/analysis_harvest_maps_water_content_stats',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      waterAnalysis: "percent_deviations",
      waterAnalysis_clustering: "clustering",
    },
    dataType: {
      waterAnalysis: "percent",
      waterAnalysis_deviations: "percent",
      waterAnalysis_clustering: "integer",
    },
    colorScale: {
      waterAnalysis: COLOR_SCALE_DIFF,
      waterAnalysis_clustering: "dark2",
    },
    colorLimit: {
      waterAnalysis_clustering: COLOR_LIMITS_CLUSTERING,
    },
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },

  correlation: {
    name: "correlation",
    source: "",
    layerIdTypes: [],
    aliases: [
      "correlation",
    ],
    icon: imgYield,
    caption: "Korrelationsanalys",
    captions: {
      correlation: "",
    },
    shortCaptions: {
      correlation: "",
    },
    helpText: {
      correlation: "",
    },
    histogramLabels: {
      correlation: {
        "0": "Båda stora",
        "1": "Båda små",
        "2": "Övre stor / Nedre liten",
        "3": "Övre liten / Nedre stor",
        "4": "Inget samband",
      }
    },
    dbDataDocName: null,
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      correlation: "correlation",
    },
    dataType: {
      correlation: "integer",
    },
    colorScale: {
      correlation: COLOR_SCALE_CORRELATION,
    },
    colorLimit: {
      correlation: COLOR_LIMITS_CORRELATION,
    },
    includedInPlans: ['none'],
  },

  vraMap: {
    name: "vraMap",
    source: "",
    layerIdTypes: [],
    aliases: [
      "vraMap",
      "vraMapTon",
      "vraMap_nitrogen",
      "vraMap_lime",
      "vraMap_phosphorus",
      "vraMap_potassium",
    ],
    icon: imgVraMap,
    caption: "Styrfil",
    captions: {
      vraMap: "",
      vraMapTon: "",
      vraMap_nitrogen: "",
      vraMap_lime: "",
      vraMap_phosphorus: "",
      vraMap_potassium: "",
    },
    shortCaptions: {
      vraMap: "Styrfil",
      vraMapTon: "Styrfil",
      vraMap_nitrogen: "Styrfil",
      vraMap_lime: "Styrfil",
      vraMap_phosphorus: "Styrfil",
      vraMap_potassium: "Styrfil",
    },
    helpText: {
      vraMap: "",
      vraMapTon: "",
      vraMap_nitrogen: "",
      vraMap_lime: "",
      vraMap_phosphorus: "",
      vraMap_potassium: "",
    },
    histogramLabels: {
      vraMap: { postfix: " kg/ha", noDecimals: 0 },
      vraMapTon: { postfix: " ton/ha", noDecimals: 0 },
      vraMap_nitrogen: { postfix: " kg N/ha", noDecimals: 0 },
      vraMap_lime: { postfix: " kg Ca/ha", noDecimals: 0 },
      vraMap_phosphorus: { postfix: " kg P/ha", noDecimals: 0 },
      vraMap_potassium: { postfix: " kg K/ha", noDecimals: 0 },
    },
    dbDataDocName: null,
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      vraMap: "value",
      vraMapTon: "value",
      vraMap_nitrogen: "value",
      vraMap_lime: "value",
      vraMap_phosphorus: "value",
      vraMap_potassium: "value",
    },
    dataType: {
      vraMap: "float",
      vraMapTon: "float",
      vraMap_nitrogen: "float",
      vraMap_lime: "float",
      vraMap_phosphorus: "float",
      vraMap_potassium: "float",
    },
    colorScale: {
      vraMap: "spectralReversed",
      vraMapTon: "spectralReversed",
      vraMap_nitrogen: "spectralReversed",
      vraMap_lime: "spectralReversed",
      vraMap_phosphorus: "spectralReversed",
      vraMap_potassium: "spectralReversed",
    },
    colorLimit: {},
    includedInPlans: ['none'],
  },
};

export const FIELD_SUMMARY_LAYERS = {
  summary: {
    name: "summary",
    source: "",
    attribution: "",
    layerIdTypes: {
      summary: ["years"],
      summary_yield: [],
    },
    aliases: [
      "summary",
      "summary_yield",
    ],
    icon: imgGreenMass,
    caption: "Frejas insikter",
    captions: {
      summary: "Tidslinje",
      summary_yield: "Skörd",
    },
    shortCaptions: {
      summary: "Tidslinje",
      summary_yield: "Skörd",
    },
    helpText: {
      summary: "Tidslinje",
      summary_yield: "Skörd",
    },
    histogramLabels: {},
    dbDataDocName: {
      summary: 'fieldsSentinelHubProcessed/analysis_sentinel_msavi_geojson',
      summary_yield: 'fieldsYieldMapsProcessed/rawdata_harvest_maps_geojson',
    },
    dbStatDocName: {
      summary: 'fieldsSentinelHubRaw/rawdata_sentinel_optical_info_json',
      summary_yield: 'fieldsYieldMapsProcessed/analysis_harvest_maps_national_stats',
    },
    dbMarkersDocName: null,
    geoJsonFieldKey: {},
    dataType: {},
    colorScale: {},
    colorLimit: {},
    hideMap: true,
    onlyBeta: ['summary', "summary_yield"],
    showBetaLabel: false,
    includedInPlans: ['developer', 'demo', 'analyze', 'control'],
  },
}

// searches through layer constant on variable layer which should
// correspond to an alias in the constant dictionaries
export const GetDataFromAlias = (layer) => {
  var layerFieldData = {}
  let layerConstants = [FIELD_DATA_LAYERS, FIELD_ANALYSIS_LAYERS]

  layerConstants.forEach(FIELD_DATA => {
    Object.keys(FIELD_DATA).forEach((fieldKey) => {
      let fieldDataItem = FIELD_DATA[fieldKey]
      if (fieldDataItem.aliases && Object.values(fieldDataItem.aliases).includes(layer)) {
        layerFieldData.icon = fieldDataItem.icon
        layerFieldData.includedInPlans = fieldDataItem.includedInPlans
        layerFieldData.category = fieldDataItem.caption
        layerFieldData.caption = fieldDataItem.captions[layer]
        layerFieldData.helpText = fieldDataItem.helpText[layer]
        layerFieldData.dataType = fieldDataItem.dataType[layer]
        layerFieldData.colorScale = fieldDataItem.colorScale[layer]
        layerFieldData.colorLimit = fieldDataItem.colorLimit[layer] ? fieldDataItem.colorLimit[layer] : null

      }
    })
  })
  return (layerFieldData)
}

export const DATA_LAYERS = { ...FIELD_DATA_LAYERS, ...FIELD_ANALYSIS_LAYERS, ...FIELD_SUMMARY_LAYERS };

export const getCurrentDataLayer = (layer) => {
  let currentLayer = DATA_LAYERS && layer && Object.keys(DATA_LAYERS).find((x) => DATA_LAYERS[x].aliases && DATA_LAYERS[x].aliases.includes(layer));
  currentLayer = currentLayer && DATA_LAYERS[currentLayer] && DATA_LAYERS[currentLayer];

  return currentLayer
};