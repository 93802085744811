import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { 
  Menu, 
  Icon, 
} from "semantic-ui-react";

import "./style.css";

const INITIAL_STATE = {};

class MenuAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };  

  render() {
    const {
      activeView,
    } = this.props;

    let menuItems = {
      impersonate: {
        caption: "Impersonate",
        icon: "user",
      },
      handleUsers: {
        caption: "Användarkonton",
        icon: "users",
      },
      regKeys: {
        caption: "Registreringsnycklar",
        icon: "key",
      },               
    };

    return (
      <React.Fragment>
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 1.0)",
            position: "fixed",
            top: 0,
            left: "85px",
            height: "55px",
            width: "100vw",
            paddingLeft: "0px",
            paddingRight: "85px",
            zIndex: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Menu
            pointing
            secondary
            fluid
            style={{
              textAlign: "center",
              marginTop: 0,
              height: "53px",
            }}
          >
            {menuItems && Object.keys(menuItems).map((x) => {
              let menuItem = menuItems[x];

              return (
                <Menu.Item
                  className="menuSecondaryItem"
                  key={'MenuAdminItem_' + x}
                  style={{
                    marginTop: 0,
                    height: "55px",
                    marginRight: "1em",
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    background: x === activeView ? "rgba(234, 204, 116, 0.25)" : "transparent",
                    borderBottom: x === activeView ? "4px solid rgba(234, 204, 116, 1.0)" : "4px solid transparent",
                    fontWeight: x === activeView ? 600 : null,
                  }}
                  active={x === activeView}
                  onClick={() => this.props.changeView(x)}
                >
                  <Icon.Group style={{ marginRight: 5}}>
                    <Icon size='large' name={menuItem.icon} />
                  </Icon.Group>
                  {menuItem.caption}
                </Menu.Item>
              )})}
          </Menu>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MenuAdmin);

