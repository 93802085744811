import React, { Component } from "react";

import { 
  Modal,
  Icon,
  Button,
  Popup,
} from "semantic-ui-react";

import FieldDataViewer from "@/components/fieldData/FieldDataViewer";

class GuideMapPreview extends Component {
  state = {
    showFullScreenMap: false,
  };

  toggleShowFullScreenMap = () => {
    let currentValue = this.state.showFullScreenMap;
    this.setState({ showFullScreenMap: !currentValue });
  };

  render () {
    const {
      db,
      userId,
      fieldId,
      layer,
      layerId,
      fileBucketRef,
      mode,
      hidden,
      hideInfoWidget,
      hideFullScreen,
      markers,
      hideAttribution,
    } = this.props;

    let field = db && fieldId && db.fields && db.fields[fieldId];
    let fieldInfo = db && fieldId && db.fieldsInfo && db.fieldsInfo[fieldId];
    let weatherData = db && db.fieldsWeather;

    const {
      showFullScreenMap,
    } = this.state;

    if (!showFullScreenMap && (!mode || mode === "icon")) {
      return (
        <Popup
          trigger={
          <Button 
            style={{
              cursor: "pointer",
            }}
            content="Visa karta"
            onClick={() => this.toggleShowFullScreenMap()}
            size="mini"
          />
          }
          content="Klicka för att visa karta i helskärmsläge"
        />
      )
    };

    if (!showFullScreenMap && mode === "map") {
      if (hidden) {
        return (
          <div />
        )
      } else {
        return (
          <FieldDataViewer        
            db={db}
            mode="minimal"
            userId={userId}
            field={field}
            fieldInfo={fieldInfo}
            fieldId={fieldId}
            layer={layer}
            layerId={layerId}
            lockedMap={true}
            markers={markers}
            showArealMap={true}
            markerFilteringOpen={false}
            weatherData={weatherData}
            showMissingData={true}
            dataType={null}
            fileBucketRef={fileBucketRef}
            markerScale={1.0}
            intervalFilter={null}
            numberOfGroups={5}
            colorScaleType="e"
            scale={null}
            selectedGroup={null}
            hideInfoWidget={hideInfoWidget}
            hideAttribution={hideAttribution}
            mapStyle={{
              top: 0,
              // width: "15vw",
              height: "30vh",
              ...this.props.mapStyle,
            }}
            mapOptions={{
              boundsOptions: { padding: [10, 10] },
              ...this.props.mapOptions,
            }}            
          >
            {!hideFullScreen &&
              <Button 
                icon 
                size="large" 
                style={{
                position: "absolute",
                  top: 5,
                  right: 0,
                  zIndex: 1000,
                }}
                onClick={() => this.toggleShowFullScreenMap()}
              >
                <Icon name="expand" />
              </Button>
            }
          </FieldDataViewer>
        )
      }
    };

    if (showFullScreenMap) {
      return (
        <Modal 
          basic 
          open={true} 
          size='fullscreen' 
        >
          <FieldDataViewer
            mapStyle={{
              top: 0,
              height: "90vh",
            }}
            mapOptions={{
              boundsOptions: { padding: [25, 25] },
            }}          
            db={db}
            mode="compact"
            userId={userId}
            field={field}
            fieldInfo={fieldInfo}
            fieldId={fieldId}
            layer={layer}
            layerId={layerId}
            lockedMap={true}
            showArealMap={true}
            markerFilteringOpen={false}
            weatherData={weatherData}
            showMissingData={true}
            dataType={null}
            fileBucketRef={fileBucketRef}
            markerScale={1.0}
            intervalFilter={null}
            numberOfGroups={5}
            colorScaleType="e"
            scale={null}
            selectedGroup={null}
          >
            <Button 
              icon 
              size="large" 
              style={{
              position: "absolute",
                top: 10,
                right: 10,
                zIndex: 10000,
              }}
              onClick={() => this.toggleShowFullScreenMap()}
            >
              <Icon name="close" />
            </Button>   
          </FieldDataViewer>
        </Modal>
      )
    }
  }
};

export default GuideMapPreview;