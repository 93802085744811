import React, { Component } from "react";

import { connect } from "react-redux";
import { compose } from "redux";

import { 
  firestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";

import { cloudFunctions } from "@";

import { toast } from "react-toastify";

import LoaderModal from "@/components/modals/LoaderModal";

import MenuGuide from "@/components/guide/GuideMenu";

import HarvestReportGuideGettingStarted from "@/components/guides/harvestReport/gettingStarted";
import HarvestReportGuideSelectFields from "@/components/guides/harvestReport/selectFields";
import HarvestReportGuideVerifyFields from "@/components/guides/harvestReport/verifyFields";
import HarvestReportGuideDownloadReports from "@/components/guides/harvestReport/downloadReports";

class GuidesHarvestReportPage extends Component {

  state = {
    selectedFieldIds: [],
    currentYear: null,
    currentFieldId: null,
    fieldIdsProcessed: [],
    showFullScreenMap: false,
    yieldMapsYears: null,
    yieldMapsYearsProcessed: [],
    yieldMapsYearsUse: [],
    yieldMapsYearsDoNotUse: [],
    fullScreenField: {},
    fullScreenMarkerData: {},
    fullScreenColorScales: {},
    editCropCode: null,
    editHarvestYield: null,
    editNitrogenRation: null,
    skipGettingStarted: false,
  };

  componentDidMount = () => {
    const { 
      match: {
        params: {
          layer,
        }
      },
    } = this.props;

    if (this.props.history.location.state) {
      this.setState(this.props.history.location.state);

      if ((!layer || layer === "gettingStarted") && 
        this.props.history && 
        this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.skipGettingStarted) {
        this.onClickGoToStep1();
        
        this.props.history.location.state &&
        this.props.history.location.state.skipGettingStarted &&
        this.setState({
          skipGettingStarted: this.props.history.location.state.skipGettingStarted
        });
      };
    };
  };

  componentDidUpdate = (prevProp) => {
    const { 
      match: {
        params: {
          layer,
        }
      },
    } = this.props;

    if (prevProp.history.location.state !== this.props.history.location.state) {
      this.setState(this.props.history.location.state);

      if ((!layer || layer === "gettingStarted") && 
        this.props.history && 
        this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.skipGettingStarted) {
        this.onClickGoToStep1();
        
        this.props.history.location.state &&
        this.props.history.location.state.skipGettingStarted &&
        this.setState({
          skipGettingStarted: this.props.history.location.state.skipGettingStarted
        });
      };
    };    
  };

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  onSelectField = (fieldId) => {
    let currentValue = this.state.selectedFieldIds;

    if (currentValue.includes(fieldId)) {
      this.setState({ selectedFieldIds: currentValue.filter((x) => x !== fieldId) });
    } else {
      this.setState({ selectedFieldIds: [...currentValue, fieldId ] });
    };
  };

  openHarvestMaps = (fieldKey) => {
    const db = this.getDb();
    const yieldMapsProcessed = db && db.fieldsYieldMapsProcessed;

    let lastYear = yieldMapsProcessed && fieldKey && 
      yieldMapsProcessed[fieldKey] &&
      yieldMapsProcessed[fieldKey].rawdata_harvest_maps_geojson &&
      Object.keys(yieldMapsProcessed[fieldKey].rawdata_harvest_maps_geojson).map((x) => x).sort().splice(-1, 1)[0];

    this.props.history.push("/guidesHarvestReport/fieldReport/" + fieldKey + "/" + lastYear);
  };

  createHarvestReport = (fieldId) => {
    const {
      match: {
        params: {
          guideId,
        }
      }
    } = this.props;

    const db = this.getDb();
    const fields = db && db.fields;
    let fieldName = fields && fieldId && fields[fieldId] && fields[fieldId].name ? fields[fieldId].name : "okänt skifte";

    const fieldReportRef = this.props.firestore
      .collection("users")
      .doc(this.props.userId)
      .collection("fieldsYieldMapsReport")
      .doc(fieldId);

    fieldReportRef.set({
      status: 'create_queued'
    });

    const farmReportRef = this.props.firestore
    .collection("users")
    .doc(this.props.userId)
    .collection("fieldsYieldMapsReport")
    .doc("farm");

    farmReportRef.set({
      status: 'create_queued',
      farm_report_pdf: null,
    });

    cloudFunctions.httpsCallable('createHarvestReport')(
      {
        userId: this.props.userId,
        fieldId: fieldId,
        guideId: guideId,
      }
    );

    toast.success("Alla skördekartor för " + fieldName + " har kontrollerats och Freja skapar nu en skördeöversikt.",
    {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });

    const guidesRef = this.props.firestore
      .collection("users")
      .doc(this.props.userId)
      .collection("guides")
      .doc(guideId);

      guidesRef.update({
      processed: false,
      current_step: 'download',
      date_latest_update: new Date(),
      downloadable_file: null,
    });    
  };

  handleError = (error) => {
    console.log(error);
    let trucatedError = error.toString().substring(0, 120);

    toast.error("Något gick tyvärr fel. Prova gärna igen och hör av dig till support@agriopt.se om det inte funkar då. Felmeddelande: " + trucatedError + ".",
    {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 15000,
      hideProgressBar: false,
    }); 
  };

  setParentState = (payload) => {
    this.setState(payload);
  };

  onResetGuide = () => {
    const {
      match: {
        params:{
          guideId
        }
      }
    } = this.props;

    this.setState({
      selectedFieldIds: [],
      currentYear: null,
      currentFieldId: null,
      fieldIdsProcessed: [],
      showFullScreenMap: false,
      yieldMapsYears: null,
      yieldMapsYearsProcessed: [],
      yieldMapsYearsUse: [],
      yieldMapsYearsDoNotUse: [],
      fullScreenField: {},
      fullScreenMarkerData: {},
      fullScreenColorScales: {},
      editCropCode: null,
      editHarvestYield: null,
      editNitrogenRation: null,  
    });

    this.props.history.push("/guidesHarvestReport/selectFields" + (guideId ? ("/" + guideId) : ""));    

    guideId && this.getUser().collection("guides").doc(guideId).update({
      current_step: 'selectFields',
      date_latest_update: new Date(),
    });
  }

  onSaveState = () => {
    const {
      userId,
      match: {
        params: {
          guideId,
        }
      },
      isAdminUser,
    } = this.props;

    if (isAdminUser) {
      return null
    };

    const fileBucketRef = this.props.firebase.storage().ref();
    const dbRef = this.getUser();

    const docRef = dbRef.collection("guides").doc(guideId);

    const fileName = guideId + "_current_state.json";
    const key = "users/" + userId + "/guides/" + guideId + "/" + fileName;
    
    const fileRef = fileBucketRef.child(key);
    guideId && fileRef.putString(JSON.stringify(this.state))
      .then((x) => {
        docRef.update({
          current_step_state_file_path: key,
        });
      })
  };  

  onClickGoToGettingStarted = () => {
    const {
      match: {
        params:{
          guideId
        }
      }
    } = this.props;
        
    this.props.history.push("/guidesHarvestReport/gettingStarted" + (guideId ? ("/" + guideId) : ""));    
  };

  onClickGoToStep1 = () => {
    const {
      match: {
        params:{
          guideId
        }
      },
      isAdminUser,
    } = this.props;

    this.props.history.push("/guidesHarvestReport/selectFields" + (guideId ? ("/" + guideId) : ""));    

    !isAdminUser && guideId && this.getUser().collection("guides").doc(guideId).update({
      current_step: 'selectFields',
      date_latest_update: new Date(),
    });
  };

  onClickGoToStep2 = () => {
    const {
      match: {
        params:{
          guideId
        }
      },
      isAdminUser,
    } = this.props;

    this.props.history.push("/guidesHarvestReport/verifyFields" + (guideId ? ("/" + guideId) : ""));        

    !isAdminUser && guideId && this.getUser().collection("guides").doc(guideId).update({
      field_ids: this.state.selectedFieldIds,
      current_step: 'verifyFields',
      date_latest_update: new Date(),
    });

    this.onSaveState();
  };

  onClickGoToStep3 = () => {
    const {
      match: {
        params:{
          guideId
        }
      },
      isAdminUser,
    } = this.props;

    this.props.history.push("/guidesHarvestReport/download" + (guideId ? ("/" + guideId) : ""));    

    !isAdminUser && guideId && this.getUser().collection("guides").doc(guideId).update({
      current_step: 'download',
      date_latest_update: new Date(),
    });

    this.onSaveState();
  };

  onHideSkipGettingStarted = () => {
    let db = this.getDb();
    let currentSettings = db && db.settings && db.settings.guides && db.settings.guides;
    let dbRefSettings = this.getUser().collection("settings").doc("guides");

    let currentSettingsValue = currentSettings && currentSettings['harvestReport'];
    
    if (currentSettings) {
      dbRefSettings.update({ ...currentSettings, 
        harvestReport: { ...currentSettingsValue,
          skip_get_started_announce: true,
        }
      }); 
    } else {
      dbRefSettings.set({ ...currentSettings, 
        harvestReport: { ...currentSettingsValue,
          skip_get_started_announce: true,
        }
      }); 
    };
  };

  toggleHelpTexts = () => {
    let db = this.getDb();
    let currentSettings = db && db.settings && db.settings.guides && db.settings.guides;
    let dbRefSettings = this.getUser().collection("settings").doc("guides");

    let currentSettingsValue = currentSettings && currentSettings['harvestReport'];

    let newSettingsSubValue = currentSettingsValue && 
      typeof(currentSettingsValue['hide_help_text_col']) === "boolean" ? 
      !currentSettingsValue['hide_help_text_col'] : true;
    
    if (currentSettings) {
      dbRefSettings.update({ ...currentSettings, 
        harvestReport: { ...currentSettingsValue,
          hide_help_text_col: newSettingsSubValue,
        }
      }); 
    } else {
      dbRefSettings.set({ ...currentSettings, 
        harvestReport: { ...currentSettingsValue,
          hide_help_text_col: newSettingsSubValue,
        }
      }); 
    };
  };

  render() {
    const {
      userId,
      match: {
        params: {
          layer, 
        }
      },
    } = this.props;

    const {
      selectedFieldIds, 
      skipGettingStarted,
      yieldMapsYears,
      yieldMapsYearsProcessed,
      yieldMapsYearsUse,
      yieldMapsYearsDoNotUse,
    } = this.state;

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    let currentSettings = db && db.settings && db.settings.guides && db.settings.guides;
    let currentSettingsValue = currentSettings && currentSettings['harvestReport'];
    
    let showSkipGettingStarted = currentSettingsValue && 
      typeof(currentSettingsValue['skip_get_started_announce']) === "boolean" ? 
      !currentSettingsValue['skip_get_started_announce'] : true;
    
    let showFullGrid = currentSettingsValue && 
      typeof(currentSettingsValue['hide_help_text_col']) === "boolean" ? 
      !currentSettingsValue['hide_help_text_col'] : true;

    const yieldMapsUploaded = db && db.fieldsYieldMapsUploaded;
    const yieldMapsRaw = db && db.fieldsYieldMapsRaw;
    const yieldMapsProcessed = db && db.fieldsYieldMapsProcessed;
    const reportYieldMaps = db && db.fieldsYieldMapsReport;

    const fields = db && db.fields;
    const fieldsInfo = db && db.fieldsInfo;

    let farmName = db && db.profile && db.profile.farm && db.profile.farm.name;

    let hasLoadedData =
      isLoaded(db) && !isEmpty(db) &&
      isLoaded(fields) && !isEmpty(fields) &&
      isLoaded(fieldsInfo) && !isEmpty(fieldsInfo) &&
      (isLoaded(yieldMapsUploaded) || isEmpty(yieldMapsUploaded)) &&
      (isLoaded(yieldMapsRaw) || isEmpty(yieldMapsRaw)) &&
      (isLoaded(yieldMapsProcessed) || isEmpty(yieldMapsProcessed)) &&
      (isLoaded(reportYieldMaps) || isEmpty(reportYieldMaps));
    
    let menuItems = [
      {
        name: "gettingStarted",
        href: "/guidesHarvestReport/gettingStarted",
        caption: "Kom igång",
        disabled: true,
      },
      {
        name: "selectFields",
        href: "/guidesHarvestReport/selectFields",
        caption: "Välj skiften",
        disabled: true,
      },
      {
        name: "verifyFields",
        href: "/guidesHarvestReport/verifyFields",
        caption: "Kontrollera skördekartor",
        disabled: true,
      },      
      {
        name: "download",
        href: "/guidesHarvestReport/download",
        caption: "Hämta skördeöversikter",
        disabled: true,
      },
    ];

    if (hasLoadedData) {
      return (
        <React.Fragment>
          <MenuGuide 
            db={db}
            menuItems={menuItems} 
          />  

          {layer === "gettingStarted" && 
            <HarvestReportGuideGettingStarted 
              onClickGoToStep1={this.onClickGoToStep1}
            />
          }

          {layer === "selectFields" &&
            <HarvestReportGuideSelectFields 
              db={db}
              userId={userId}
              fields={fields}
              fieldsInfo={fieldsInfo}
              yieldMapsRaw={yieldMapsRaw}
              yieldMapsProcessed={yieldMapsProcessed}
              onClickGoToGettingStarted={this.onClickGoToGettingStarted}          
              onClickGoToStep2={this.onClickGoToStep2}
              skipGettingStarted={skipGettingStarted}
              showSkipGettingStarted={showSkipGettingStarted}
              onHideSkipGettingStarted={this.onHideSkipGettingStarted}
              onSelectField={this.onSelectField}
              selectedFieldIds={selectedFieldIds}
              showFullGrid={showFullGrid}
              toggleHelpTexts={this.toggleHelpTexts}       
            />
          }

          {layer === "verifyFields" && 
            <HarvestReportGuideVerifyFields
              {...this.state}
              getUser={this.getUser}
              fields={fields} 
              fieldsInfo={fieldsInfo}
              fileBucketRef={fileBucketRef}
              selectedFieldIds={selectedFieldIds}
              yieldMapsUploaded={yieldMapsUploaded}
              yieldMapsProcessed={yieldMapsProcessed}
              createHarvestReport={this.createHarvestReport}
              yieldMapsYears={yieldMapsYears}
              yieldMapsYearsProcessed={yieldMapsYearsProcessed}
              yieldMapsYearsUse={yieldMapsYearsUse}
              yieldMapsYearsDoNotUse={yieldMapsYearsDoNotUse}
              setParentState={this.setParentState}
              onSaveState={this.onSaveState}
              onClickGoToStep1={this.onClickGoToStep1}     
              onClickGoToStep3={this.onClickGoToStep3}    
              showFullGrid={showFullGrid}
              toggleHelpTexts={this.toggleHelpTexts}       
            />
          }   

          {layer === "download" && 
            <HarvestReportGuideDownloadReports
              farmName={farmName}
              fileBucketRef={fileBucketRef}
              selectedFieldIds={selectedFieldIds}
              fields={fields}
              fieldsInfo={fieldsInfo}
              reportYieldMaps={reportYieldMaps}
              onResetGuide={this.onResetGuide} 
              showFullGrid={showFullGrid}
              toggleHelpTexts={this.toggleHelpTexts}          
            />
          }         
      
        </React.Fragment>
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      )
    }
  }
}

export default compose(
  firestoreConnect(props => [
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fields'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsInfo" },
      ],
      storeAs: 'fieldsInfo'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "guides" },
      ],
      storeAs: 'guides'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsYieldMapsUploaded" },
      ],
      storeAs: 'fieldsYieldMapsUploaded'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsYieldMapsRaw" },
      ],
      storeAs: 'fieldsYieldMapsRaw'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsYieldMapsProcessed" },
      ],
      storeAs: 'fieldsYieldMapsProcessed'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsYieldMapsReport" },
      ],
      storeAs: 'fieldsYieldMapsReport'
    },  
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'profile'
    },  
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "settings" },
      ],
      storeAs: 'settings'
    },
  ]),
  connect(store => {
    return {
      firestoreData: store.firestore.data
    };
  })
)(GuidesHarvestReportPage);
