import { cloudFunctions } from "@";

import { PLAN_TYPES } from '@/constants/regions/plans';

export function getUserInformation(db, userId, title) {
  const profile = db && db.profile && db.profile;

  const {
    farm: {
      name,
    },
    plan: {
      plan_name,
    },
    contact: {
      first_name,
      last_name,
    },
    invoicing: {
      company_id,
      address1,
      address2,
      zip_code,
      town,
      email,
      method,
    }
  } = profile;

  let emailAddress = (email && name) ? name + ' <' + email + '>' : "Unknown user <no-reply@agriopt.se>";

  let message = title + (userId ? userId : "Okänd") + ".\n\n\n";
  message = message + "==============================\n";
  message = message + "Fakturainformation:\n";
  message = message + "==============================\n\n";
  message = company_id ? message + "Organisationsnummer: " + company_id + "\n\n" : message;
  message = name ? message + name + "\n\n" : message;
  message = first_name && last_name ? message + first_name + " " + last_name + "\n" : message;
  message = address1 ? message + address1 + "\n" : message;
  message = address2 ? (message + address2 + "\n") : message;
  message = zip_code && town ? message + zip_code + " " + town + "\n\n" : message;
  message = email ? message + email + "\n" : message;
  message = method ? message + "Faktureringsmetod: " + method + "\n" : message;
  message = plan_name ? message + "Funktionspaket: " + plan_name + "\n" : message;

  return {
    email: emailAddress,
    message: message,
  }
};


export function sendSignUpEmailToSupport (db, userId) {

  const basicInfo = getUserInformation(db, userId, "En ny användare har registrerat sig: ")

  cloudFunctions.httpsCallable('sendSupportEmail')(
    {
      email: basicInfo.email,
      subject: 'En ny användare har registrerats',
      message: basicInfo.message
    }
  )
  .then(x => {
    console.log("Register e-mail sent to support@agriopt.se");
  })
  .catch(error => {
    console.log("Register e-mail failed to send to support@agriopt.se");
    console.log(error);
  });
};


export function sendNewFieldsEmailToSupport (db, userId, fieldsToAdd, isAddingMode=false) {
  const profile = db && db.profile && db.profile;

  const {
    plan: {
      plan_type,
    },
  } = profile;

  let header = "Nya skiften har lagts till för användaren: "
  header = isAddingMode ? header : "De första skiften har lagts till för användaren: "

  let basicInfo = getUserInformation(db, userId, header)

  let addedFieldsArea = fieldsToAdd && fieldsToAdd.map((x) => x.area);
  addedFieldsArea = addedFieldsArea && addedFieldsArea.reduce((a, b) => a + b, 0.0);     

  let priceFixed = plan_type && PLAN_TYPES.filter(x => x.name === plan_type)[0]
  priceFixed = priceFixed && priceFixed.priceFixed ? priceFixed.priceFixed : 0;
  
  let pricePerHectar = plan_type && PLAN_TYPES.filter(x => x.name === plan_type)[0]
  pricePerHectar = pricePerHectar && pricePerHectar.pricePerHectar ? pricePerHectar.pricePerHectar : 0;

  let message = basicInfo.message;
  message = message + "\n\n==============================\n";
  message = message + "Skiften som har lagts till:\n";
  message = message + "==============================\n\n";
  
  fieldsToAdd && fieldsToAdd.forEach((field) => {
    message = field.name && field.area ? message + field.name + " (" + field.area.toFixed(2) + " ha).\n" : message;
  });

  message = addedFieldsArea ? message + "\n\nTotal areal: " + addedFieldsArea.toFixed(2) + " ha.\n\n" : message

  message = priceFixed ? message + "Fast pris: " + priceFixed.toFixed(0) + " kr.\n" : message
  message = pricePerHectar ? message + "Areal pris: " + pricePerHectar.toFixed(0) + " kr/ha." : message

  console.log({
    email: basicInfo.email,
    subject: 'Ny gård har skapats / nya skiften har lagts till',
    message: message
  })

  // cloudFunctions.httpsCallable('sendSupportEmail')(
  //   {
  //     email: basicInfo.email,
  //     subject: 'Ny gård har skapats / nya skiften har lagts till',
  //     message: message
  //   }
  // )
  // .then(x => {
  //   console.log("Created farm e-mail sent to support@agriopt.se");
  // })
  // .catch(error => {
  //   console.log("Created farm e-mail failed to send to support@agriopt.se");
  //   console.log(error);
  // });
};