import React, { Component } from "react";
import { withRouter } from "react-router";

import {
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import {
  Container,
  Segment,
  Header,
  Table,
  Icon,
  Modal,
  Button,
  Dropdown,
  Form,
  Input,
} from "semantic-ui-react";

import { toast } from "react-toastify";

import { cloudFunctions } from "@";
import { CROP_CODES, CROP_VARIETIES } from "@/constants/cropCodes";
import { getCurrentYear } from "@/helpers/dates";

import LoaderModal from "@/components/modals/LoaderModal";

import FieldManagementHarvest from "@/components/fieldManagement/FieldManagementHarvest";
import FieldManagementNutrition from "@/components/fieldManagement/FieldManagementNutrition";
import FieldManagementSeeding from "@/components/fieldManagement/FieldManagementSeeding";

const INITIAL_STATE = {
  sortIdx: 1,
  selectedFieldId: null,
  currentView: 'both',
  editMode: false,
  currentYear: getCurrentYear(),
  filterCurrentCrop: 'all',
  filterPreviousCrop: 'all',
  editInformation: {},
  isSaving: false,
};
class PlanPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  updateDatabaseInformation = () => {
    const {
      db,
      userId,
      getUser,
    } = this.props;

    const {
      editInformation,
      currentYear,
    } = this.state;

    this.setState({ isSaving: true });

    const fieldIds = Object.keys(editInformation).map((x) => x);

    fieldIds && fieldIds.forEach((fieldId) => {
      let dbRef = getUser().collection("fieldsInfo").doc(fieldId);
      let payload = editInformation && editInformation[fieldId];
      
      let fieldInfo = db && db.fieldsInfo[fieldId] && db.fieldsInfo[fieldId];
  
      let currentKeysInFieldInfo = fieldInfo && Object.keys(fieldInfo).map((x) => x);
  
      // Iterate over all fields in payload
      Object.keys(payload).filter((key) => payload[key]).forEach((key) => {
        if (currentKeysInFieldInfo && currentKeysInFieldInfo.includes(key)) {
          dbRef.update({ [key + "." + currentYear]: payload[key] });
        } else {
          dbRef.update({ [key]: { [currentYear]: payload[key] } });
        };
      });
  
      payload && Object.keys(payload) && Object.keys(payload).includes("crop_code") &&
        cloudFunctions.httpsCallable('updatedCropCodes')(
          {
            userId: userId,
            fieldId: fieldId,
            year: currentYear,
          }
        );
    });

    let text = "Freja har sparat din nya växtodlingsplan.";

    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
      hideProgressBar: false,
    });

    this.setState({ editMode: false, isSaving: false, editInformation:{} });
  };

  setParentState = (newState) => this.setState(newState);
  
  toogleShowAll = () => {
    let currentValue = this.state.showAllFields;
    this.setState({ showAllFields: !currentValue });
  };

  onChange = (data) => this.setState({ [data.name]: data.value });

  onChangeField = (fieldId, fieldName, subFieldName, value, onlyNumbers=true) => {
    const {
      db,
    } = this.props;

    let {
      editInformation,
    } = this.state;

    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    const fieldsData = db && db.fields;
    let fieldSize = fieldsData && fieldsData[fieldId] && fieldsData[fieldId].field_size;

    let fieldInfo = editInformation && fieldId && editInformation[fieldId];
    
    if (!subFieldName || subFieldName === '') {
      fieldInfo = { ...fieldInfo, [fieldName]: value };
    } else {
      let subFieldInfo = fieldInfo && fieldName && fieldInfo[fieldName];

      subFieldInfo = {...subFieldInfo, [subFieldName]: value};
      fieldInfo = { ...fieldInfo, [fieldName]: subFieldInfo };
    };

    if (fieldName === "expected_harvest_yields_perha") {
      fieldInfo = { ...fieldInfo, expected_harvest_yields: (parseFloat(value) * parseFloat(fieldSize)).toFixed(1) };
    };

    if (fieldName === "expected_harvest_yields") {
      fieldInfo = { ...fieldInfo, expected_harvest_yields_perha: (parseFloat(value) / parseFloat(fieldSize)).toFixed(1) };
    };

    this.setState({ editInformation: {...editInformation, [fieldId]: fieldInfo }});
  }

  setSortIndex = idx => {
    if (idx === this.state.sortIdx) {
      this.setState({ sortIdx: -idx });
    } else {
      this.setState({ sortIdx: idx });
    };
  };

  sortFields = (a, b) => {
    const { 
      db,
    } = this.props;

    const {
      sortIdx,
    } = this.state;

    let fields = db && db.fields;

    const fieldA = fields && fields[a] && fields[a].name;
    const fieldB = fields && fields[b] && fields[b].name;

    const sizeA = fields && fields[a] && fields[a].field_size;
    const sizeB = fields && fields[b] && fields[b].field_size;
    
    switch (sortIdx) {
      case 1:
        return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
      case -1:
        return -1 * (fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0);
      case 2:
        return sizeB - sizeA;
      case -2:
        return sizeA - sizeB;

      default: 
        return -1;        
    };
  }; 

  openEditMode = (fieldIds) => {
    const { 
      db,
    } = this.props;

    const {
      currentYear,
      filterCurrentCrop,
      filterPreviousCrop,
    } = this.state;

    let editInformation = {}
    
    const fieldsData = db && db.fields;
    const fieldsInfoData = db && db.fieldsInfo;

    fieldIds && fieldIds.forEach((fieldId) => {

      let fieldSize = fieldsData && fieldsData[fieldId] && fieldsData[fieldId].field_size;
      
      let fieldPreviousCrop = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].crop_code;
      fieldPreviousCrop = fieldPreviousCrop && currentYear && fieldPreviousCrop[currentYear - 1];
  
      if (filterPreviousCrop !== "all" && fieldPreviousCrop !== filterPreviousCrop) {
        return null;
      };
  
      let fieldCrop = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].crop_code;
      fieldCrop = fieldCrop && currentYear && fieldCrop[currentYear];
      
      if (filterCurrentCrop !== "all" && fieldCrop !== filterCurrentCrop) {
        return null;
      };

      let fieldCropVariety = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].crop_variety;
      fieldCropVariety = fieldCropVariety && currentYear && fieldCropVariety[currentYear];
  
      let fieldExpectedYieldTotal = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].expected_harvest_yields;
      fieldExpectedYieldTotal = fieldExpectedYieldTotal && currentYear && fieldExpectedYieldTotal[currentYear];

      let fieldExpectedYield = fieldExpectedYieldTotal && (parseFloat(fieldExpectedYieldTotal) / parseFloat(fieldSize)).toFixed(1);

      let fieldExpectedFertilization = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].expected_fertilizations;
      fieldExpectedFertilization = fieldExpectedFertilization && currentYear && fieldExpectedFertilization[currentYear];

      fieldExpectedFertilization = {
        'N': fieldExpectedFertilization && fieldExpectedFertilization['N'] ? fieldExpectedFertilization['N'] : '',
        'P': fieldExpectedFertilization && fieldExpectedFertilization['P'] ? fieldExpectedFertilization['P'] : '',
        'K': fieldExpectedFertilization && fieldExpectedFertilization['K'] ? fieldExpectedFertilization['K'] : '',
      }

      editInformation = {...editInformation, 
        [fieldId]: {
          crop_code: fieldCrop ? fieldCrop : '',
          crop_variety: fieldCropVariety ? fieldCropVariety : '',
          expected_harvest_yields_perha: fieldExpectedYield ? fieldExpectedYield : '',
          expected_harvest_yields: fieldExpectedYieldTotal ? fieldExpectedYieldTotal : '',
          expected_fertilizations: fieldExpectedFertilization,
        }
      };
    });

    this.setState({ editInformation: editInformation, editMode: true });
  };

  getTableRowView = (fieldId) => {
    const { 
      db,
    } = this.props;

    const {
      currentYear,
      currentView,      
      filterCurrentCrop,
      filterPreviousCrop,
    } = this.state;

    const fieldsData = db && db.fields;
    const fieldsInfoData = db && db.fieldsInfo;

    let fieldName = fieldsData && fieldsData[fieldId] && fieldsData[fieldId].name;
    let fieldSize = fieldsData && fieldsData[fieldId] && fieldsData[fieldId].field_size;

    let fieldPreviousCrop = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].crop_code;
    fieldPreviousCrop = fieldPreviousCrop && currentYear && fieldPreviousCrop[currentYear - 1];

    let previousCropName = fieldPreviousCrop && CROP_CODES.find((x) => x.value === fieldPreviousCrop);
    previousCropName = previousCropName && previousCropName.shorttext && previousCropName.shorttext;

    if (filterPreviousCrop !== "all" && fieldPreviousCrop !== filterPreviousCrop) {
      return null;
    };

    let fieldCrop = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].crop_code;
    fieldCrop = fieldCrop && currentYear && fieldCrop[currentYear];
    
    let cropName = fieldCrop && CROP_CODES.find((x) => x.value === fieldCrop);
    cropName = cropName && cropName.shorttext && cropName.shorttext;

    if (filterCurrentCrop !== "all" && fieldCrop !== filterCurrentCrop) {
      return null;
    };
    
    let fieldCropVariety = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].crop_variety;
    fieldCropVariety = fieldCropVariety && currentYear && fieldCropVariety[currentYear];

    let fieldExpectedYieldTotal = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].expected_harvest_yields;
    fieldExpectedYieldTotal = fieldExpectedYieldTotal && currentYear && fieldExpectedYieldTotal[currentYear];

    let fieldExpectedYield = fieldExpectedYieldTotal && fieldSize && Math.round(parseFloat(fieldExpectedYieldTotal) / parseFloat(fieldSize)).toFixed(1);

    let fieldHarvestYieldTotal = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].harvest_yields;
    fieldHarvestYieldTotal = fieldHarvestYieldTotal && currentYear && fieldHarvestYieldTotal[currentYear];

    let fieldHarvestYield = fieldHarvestYieldTotal && fieldSize && Math.round(parseFloat(fieldHarvestYieldTotal) / parseFloat(fieldSize)).toFixed(1);

    let fieldExpectedFertilizations = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].expected_fertilizations && fieldsInfoData[fieldId].expected_fertilizations[currentYear];

    let expectedAmountN = fieldExpectedFertilizations && fieldExpectedFertilizations['N'] ? fieldExpectedFertilizations['N'] : '';
    let expectedAmountP = fieldExpectedFertilizations && fieldExpectedFertilizations['P'] ? fieldExpectedFertilizations['P'] : '';
    let expectedAmountK = fieldExpectedFertilizations && fieldExpectedFertilizations['K'] ? fieldExpectedFertilizations['K'] : '';

    let fieldFertilizations = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].fertilizations && fieldsInfoData[fieldId].fertilizations[currentYear];

    let amountN = 0.0;
    let amountP = 0.0;
    let amountK = 0.0;

    fieldFertilizations && fieldFertilizations.forEach((fertilization) => {
      amountN = fertilization && fertilization.nutrients && fertilization.nutrients['N'] ?
        amountN + parseFloat(fertilization.nutrients['N']) : amountN;

      amountP = fertilization && fertilization.nutrients && fertilization.nutrients['P'] ?
        amountP + parseFloat(fertilization.nutrients['P']) : amountP;
        
      amountK = fertilization && fertilization.nutrients && fertilization.nutrients['K'] ?
        amountK + parseFloat(fertilization.nutrients['K']) : amountK;                              
    });

    amountN = amountN && Math.round(amountN);
    amountP = amountP && Math.round(amountP);
    amountK = amountK && Math.round(amountK);

    return (
      <Table.Row
        key={"PlanPageTableRow_" + fieldId}
      >
        <Table.Cell>{fieldName ? fieldName : "-"}</Table.Cell>
        <Table.Cell textAlign="center">{fieldSize ? fieldSize.toFixed(0) : "-"}</Table.Cell>
        
        <Table.Cell>{previousCropName ? previousCropName : "-"}</Table.Cell>
        <Table.Cell>{cropName ? cropName : "-"}</Table.Cell>
        <Table.Cell>{fieldCropVariety ? fieldCropVariety : "-"}</Table.Cell>

        {['plan', 'both'].includes(currentView) && <Table.Cell textAlign="center">{fieldExpectedYield ? fieldExpectedYield : "-"}</Table.Cell>}
        {['plan', 'both'].includes(currentView) && <Table.Cell textAlign="center">{fieldExpectedYieldTotal ? fieldExpectedYieldTotal : "-"}</Table.Cell>}

        {['after', 'both'].includes(currentView) && <Table.Cell textAlign="center">{fieldHarvestYield ? fieldHarvestYield : "-"}</Table.Cell>}
        {['after', 'both'].includes(currentView) && <Table.Cell textAlign="center">{fieldHarvestYieldTotal ? fieldHarvestYieldTotal : "-"}</Table.Cell>}

        <Table.Cell>-</Table.Cell>
        <Table.Cell>-</Table.Cell>
        <Table.Cell>-</Table.Cell>

        {['plan', 'both'].includes(currentView) && <Table.Cell textAlign="center">{expectedAmountN ? expectedAmountN : '-'}</Table.Cell>}
        {['plan', 'both'].includes(currentView) && <Table.Cell textAlign="center">{expectedAmountP ? expectedAmountP : '-'}</Table.Cell>}
        {['plan', 'both'].includes(currentView) && <Table.Cell textAlign="center">{expectedAmountK ? expectedAmountK : '-'}</Table.Cell>}

        {['after', 'both'].includes(currentView) && <Table.Cell textAlign="center">{amountN ? amountN : '-'}</Table.Cell>}
        {['after', 'both'].includes(currentView) && <Table.Cell textAlign="center">{amountP ? amountP : '-'}</Table.Cell>}
        {['after', 'both'].includes(currentView) && <Table.Cell textAlign="center">{amountK ? amountK : '-'}</Table.Cell>}

        {currentView !== "plan" &&
          <Table.Cell textAlign="center">
            <Icon
              className="iconButton"
              onClick={() => this.setState({selectedFieldId: fieldId})}
              name="edit"
            />
          </Table.Cell>  
        }
      </Table.Row>
    )
  };

  getTableRowEdit = (fieldId) => {
    const { 
      db,
    } = this.props;

    const {
      currentYear,
      filterCurrentCrop,
      filterPreviousCrop,
      editInformation,
    } = this.state;

    const fieldsData = db && db.fields;
    const fieldsInfoData = db && db.fieldsInfo;

    let fieldName = fieldsData && fieldsData[fieldId] && fieldsData[fieldId].name;
    let fieldSize = fieldsData && fieldsData[fieldId] && fieldsData[fieldId].field_size;

    let fieldPreviousCrop = fieldsInfoData && fieldsInfoData[fieldId] && fieldsInfoData[fieldId].crop_code;
    fieldPreviousCrop = fieldPreviousCrop && currentYear && fieldPreviousCrop[currentYear - 1];

    let previousCropName = fieldPreviousCrop && CROP_CODES.find((x) => x.value === fieldPreviousCrop);
    previousCropName = previousCropName && previousCropName.shorttext && previousCropName.shorttext;

    if (filterPreviousCrop !== "all" && fieldPreviousCrop !== filterPreviousCrop) {
      return null;
    };

    let fieldCrop = editInformation && editInformation[fieldId] && editInformation[fieldId].crop_code;
    
    if (filterCurrentCrop !== "all" && fieldCrop !== filterCurrentCrop) {
      return null;
    };
    
    let fieldCropVariety = editInformation && editInformation[fieldId] && editInformation[fieldId].crop_variety;

    let fieldExpectedYieldTotal = editInformation && editInformation[fieldId] && editInformation[fieldId].expected_harvest_yields;
    let fieldExpectedYield = editInformation && editInformation[fieldId] && editInformation[fieldId].expected_harvest_yields_perha;

    let fieldFertilizations = editInformation && editInformation[fieldId] && editInformation[fieldId].expected_fertilizations;

    let amountN = fieldFertilizations && fieldFertilizations['N'];
    let amountP = fieldFertilizations && fieldFertilizations['P'];
    let amountK = fieldFertilizations && fieldFertilizations['K'];

    return (
      <Table.Row
        key={"PlanPageTableRow_" + fieldId}
      >
        <Table.Cell>{fieldName ? fieldName : "-"}</Table.Cell>
        <Table.Cell textAlign="center">{fieldSize ? fieldSize.toFixed(0) : "-"}</Table.Cell>
        
        <Table.Cell>{previousCropName ? previousCropName : "-"}</Table.Cell>
        <Table.Cell>
          <Dropdown
            selection
            search
            placeholder="Välj gröda..."
            // name="filterCurrentCrop"
            // onChange={(e, d) => this.onChange(d)}
            value={fieldCrop}
            options={CROP_CODES}
            onChange={(event, data) => this.onChangeField(fieldId, "crop_code", "", data.value)}
          />  
        </Table.Cell>

        <Table.Cell>
         {CROP_VARIETIES.find((x) => x.value === fieldCrop) &&
            <Dropdown
              fluid
              search
              selection
              options={CROP_VARIETIES.find((x) => x.value === fieldCrop).values}
              value={fieldCropVariety}
              closeOnChange={true}
              additionLabel="Annan... "
              additionPosition="bottom"
              allowAdditions={true}
              onChange={(event, data) => this.onChangeField(fieldId, "crop_variety", "", data.value, false)}
            />
          }
      </Table.Cell>

        <Table.Cell>
          <Input 
            onChange={(event, data) => {
              this.onChangeField(fieldId, "expected_harvest_yields_perha", "", data.value)
              
            }}
            value={fieldExpectedYield} 
          />
        </Table.Cell>

        <Table.Cell>
          <Input 
            onChange={(event, data) => {
              this.onChangeField(fieldId, "expected_harvest_yields", "", data.value)
            }}
            value={fieldExpectedYieldTotal} 
          />
        </Table.Cell>

        <Table.Cell>-</Table.Cell>
        <Table.Cell>-</Table.Cell>
        <Table.Cell>-</Table.Cell>

        <Table.Cell>
          <Input 
            value={amountN} 
            onChange={(event, data) => this.onChangeField(fieldId, "expected_fertilizations", "N", data.value)}
          />
        </Table.Cell>

        <Table.Cell>
          <Input 
            value={amountP} 
            onChange={(event, data) => this.onChangeField(fieldId, "expected_fertilizations", "P", data.value)}
          />
        </Table.Cell>

        <Table.Cell>
          <Input 
            value={amountK} 
            onChange={(event, data) => this.onChangeField(fieldId, "expected_fertilizations", "K", data.value)}
          />
        </Table.Cell>
      </Table.Row>
    )
  };

  cancelEditMode = () => this.setState({ editMode: false, isSaving: false, editInformation:{} });

  render() {
    const { 
      db,
      userId,
    } = this.props;

    const {
      sortIdx,
      selectedFieldId,
      currentYear,
      currentView,      
      filterCurrentCrop,
      filterPreviousCrop,
      editMode,
      isSaving,
    } = this.state;

    let availableYearList = ["2016", "2017", "2018", "2019", "2020", "2021", "2022"];

    let menuItemsYears = availableYearList && availableYearList.map((x) => (
      {
        key: x,
        value: x,
        text: "Säsong: " + x,
        onClick: () => this.setState({currentYear: x}),
      }
    )); 

    let menuItemsView = [
      {
        key: 'plan',
        value: 'plan',
        text: "Planering",
        onClick: () => {
          this.cancelEditMode(); 
          this.setState({currentView: 'plan'});
        },
      },
      {
        key: 'after',
        value: 'after',
        text: "Utfall",
        onClick: () => {
          this.cancelEditMode(); 
          this.setState({currentView: 'after'});
          }
      },      
      {
        key: 'both',
        value: 'both',
        text: "Planering och utfall",
        onClick: () => {
          this.cancelEditMode(); 
          this.setState({currentView: 'both'});
          }
      },
    ]
    let hasLoadedData = isLoaded(db) && !isEmpty(db) && isLoaded(db.fields) && !isEmpty(db.fields);

    const fieldsData = db && db.fields;

    let fieldIds = fieldsData && Object.keys(fieldsData).sort((a, b) => this.sortFields(a, b));
    fieldIds = fieldIds && fieldIds.filter((x) => ( typeof(db.fields[x].available_to_user) !== "boolean") || (typeof(db.fields[x].available_to_user) === "boolean" && db.fields[x].available_to_user === true));

    let selectedFieldName = selectedFieldId && fieldsData && fieldsData[selectedFieldId] && fieldsData[selectedFieldId].name;

    if (hasLoadedData) {
      return (
        <React.Fragment>

          <Modal 
            open={selectedFieldId} 
            size="small"
          >
            <Modal.Header
              style={{
                fontWeight: 500,
              }}
            >
              Redigera växtodlingsplan för {selectedFieldName} under säsong {currentYear}
            </Modal.Header>

            <Modal.Content>
              <Modal.Description>
                <FieldManagementSeeding
                  db={db}
                  userId={userId}
                  fieldId={selectedFieldId}
                  getUser={this.props.getUser}
                  widget={true}
                  widgetYear={currentYear} 
                  onResetState={() => this.setState({selectedFieldId: null})}
                />

                <FieldManagementNutrition
                  db={db}
                  userId={userId}
                  fieldId={selectedFieldId}
                  getUser={this.props.getUser}
                  widget={true}
                  widgetYear={currentYear} 
                  onResetState={() => this.setState({selectedFieldId: null})}
                />

                <FieldManagementHarvest
                  db={db}
                  userId={userId}
                  fieldId={selectedFieldId}
                  getUser={this.props.getUser}
                  widget={true}
                  widgetYear={2022} 
                  onResetState={() => this.setState({selectedFieldId: null})}
                />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions
              style={{ 
                display: "flex-end",
                justifyContent: "space-between"
              }}          
            >
              <Button 
                style={{ 
                  backgroundColor: "#6B9D9F", 
                  color: "white",
                }}
                onClick={() => this.setState({selectedFieldId: null})}
              >
                Stäng
              </Button>         
            </Modal.Actions>            
          </Modal>

          <Container
            style={{
              paddingTop: 50,
              backgroundColor: "#f7f4f0",
              overflow: "hidden",
              width: "100vw",
            }}
          >
            <div
              style={{
                padding: 20,
                paddingTop: 0,
                marginLeft: "5px",
                marginRight: "15px",
              }}
            >
              <div
                style={{
                  marginTop: "2em",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >

                <div style={{ width: "100%" }}>
                  <Segment
                    style={{
                      padding: "1.5em",
                      fontWeight: "normal",
                      height: "100%",
                    }}
                  >
                    <div 
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1em",
                      }}
                    >
                      <Header
                        as="h3"
                        style={{ 
                          paddingTop: "0",
                          fontWeight: 500,
                          color: "grey",
                        }}
                      >
                        Växtodlingsplan
                      </Header>
                        
                      <div>
                        <Dropdown
                          className="textLink"
                          style={{
                            marginRight: 10,
                          }}
                          options={menuItemsView}
                          value={currentView}
                        />

                        &bull;

                        <Dropdown
                          className="textLink"
                          style={{
                            marginLeft: 10,
                          }}
                          options={menuItemsYears}
                          value={currentYear}
                        />                                  
                      </div>
                    </div>                    
{/* 
                    <p
                      style={{
                        fontSize: "110%",
                        whiteSpace: "pre-line",
                        width: "67%"
                      }}
                    >
                      Här kan du se din växtodlingsplan för den säsong som du har valt uppe till höger. Du kan även välja att arbeta med planeringen eller se utfallet eller båda samtidigt. Du kan filtrera planen beroende på gröda och/eller förfrukt samt sortera listan genom att klicka på rubriken i tabellen.
                    </p>            */}

                    <Segment
                      secondary
                      style={{
                        marginTop: "1em",
                        marginBottom: "0.5em",
                        padding: "1em",
                        display: "flex",
                        justifyContent: "space-between",           
                      }}
                    >
                      <div
                        style={{
                          width: "25%",
                        }}
                      >
                        <Header 
                          as="h5"
                          style={{
                            marginBottom: 2,
                            fontSize: "9pt",
                            fontWeight: 500,
                          }}
                        >
                          Filtrera på odlad gröda
                        </Header> 

                        <Form 
                          // size="tiny"
                        >
                          <Dropdown
                            selection
                            placeholder="Välj gröda..."
                            name="filterCurrentCrop"
                            onChange={(e, d) => this.onChange(d)}
                            value={filterCurrentCrop}
                            options={[
                              {
                                key: 'all',
                                value: 'all',
                                text: 'Alla grödor',
                              },
                              ...CROP_CODES
                            ]}
                            
                          />  
                        </Form>
                      </div>
                    
                      <div
                        style={{
                          width: "25%",
                        }}
                      >
                        <Header 
                          as="h5"
                          style={{
                            marginBottom: 2,
                            fontSize: "9pt",
                            fontWeight: 500,
                          }}
                        >
                          Filtrera på förfrukt
                        </Header> 
                          <Form 
                            // size="tiny"
                          >
                            <Dropdown
                              selection
                              placeholder="Välj gröda..."
                              name="filterPreviousCrop"
                              onChange={(e, d) => this.onChange(d)}
                              value={filterPreviousCrop}
                              options={[
                                {
                                  key: 'all',
                                  value: 'all',
                                  text: 'Alla grödor',
                                },
                                ...CROP_CODES
                              ]}
                            />
                          </Form> 
                      </div>  

                      <div
                        style={{
                          width: "25%",
                        }}
                      />

                      <div style={{ width: "25%", display: "flex" }}>
                        {currentView === "plan" &&
                          <Button 
                            primary
                            disabled={isSaving}
                            loading={isSaving}
                            style={{
                              margin: "auto",
                            }}
                            onClick={() => editMode ? this.updateDatabaseInformation() : this.openEditMode(fieldIds)}
                          >
                            {editMode ? "Spara" : "Planera säsong"}
                          </Button>
                        }

                        {currentView === "plan" && editMode &&
                          <Button 
                            disabled={isSaving}
                            style={{
                              margin: "auto",
                            }}
                            onClick={() => this.cancelEditMode()}
                          >
                            Avbryt
                          </Button>
                        }                        
                      </div>
                    </Segment>   

                    <Table
                      fluid
                      sortable
                      selectable
                      striped
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell 
                            rowSpan='2'
                            onClick={() => this.setSortIndex(1)}
                          >
                            Skiftesnamn
                            {sortIdx === 1 && <Icon name="sort ascending" />}
                            {sortIdx === -1 && <Icon name="sort descending" />}
                          </Table.HeaderCell>

                          <Table.HeaderCell
                            rowSpan='2'                  
                            onClick={() => this.setSortIndex(2)}
                            textAlign="center"
                          >
                            Storlek (ha)
                            {sortIdx === 2 && <Icon name="sort ascending" />}
                            {sortIdx === -2 && <Icon name="sort descending" />}
                          </Table.HeaderCell>                        

                          <Table.HeaderCell
                            rowSpan='2'                       
                            // onClick={() => this.setSortIndex(3)}
                          >
                            Förfrukt
{/* 
                            {sortIdx === 3 && <Icon name="sort ascending" />}
                            {sortIdx === -3 && <Icon name="sort descending" />} */}
                          </Table.HeaderCell>

                          <Table.HeaderCell
                            rowSpan='2'                
                            // onClick={() => this.setSortIndex(3)}
                          >
                            Gröda

                            {/* {sortIdx === 4 && <Icon name="sort ascending" />}
                            {sortIdx === -4 && <Icon name="sort descending" />} */}
                          </Table.HeaderCell>
                          
                          <Table.HeaderCell        
                            rowSpan='2'             
                            // onClick={() => this.setSortIndex(4)}
                          >
                            Sort                                                 
                            {/* {sortIdx === 4 && <Icon name="sort ascending" />}
                            {sortIdx === -4 && <Icon name="sort descending" />}*/}
                          </Table.HeaderCell>       

                          {['plan', 'both'].includes(currentView) && 
                            <Table.HeaderCell  
                              colSpan='2'
                              // onClick={() => this.setSortIndex(4)}
                              textAlign="center"
                            >
                              Förväntad skörd
                              
                              {/* {sortIdx === 4 && <Icon name="sort ascending" />}
                              {sortIdx === -4 && <Icon name="sort descending" />}*/}
                            </Table.HeaderCell>                           
                          }

                          {['after', 'both'].includes(currentView) && 
                            <Table.HeaderCell  
                              colSpan='2'
                              // onClick={() => this.setSortIndex(4)}
                              textAlign="center"
                            >
                              Faktisk skörd
                              
                              {/* {sortIdx === 4 && <Icon name="sort ascending" />}
                              {sortIdx === -4 && <Icon name="sort descending" />}*/}
                            </Table.HeaderCell>                           
                          }                          

                          <Table.HeaderCell  
                            colSpan='3'
                            // onClick={() => this.setSortIndex(4)}
                            textAlign="center"
                          >
                            Markkartering
                            
                            {/* {sortIdx === 4 && <Icon name="sort ascending" />}
                            {sortIdx === -4 && <Icon name="sort descending" />}*/}
                          </Table.HeaderCell>  

                          {['plan', 'both'].includes(currentView) && 
                            <Table.HeaderCell                     
                              // onClick={() => this.setSortIndex(4)}
                              colSpan='3'
                              rowSpan='1'
                              textAlign="center"
                            >
                              Planerad gödsling (kg/ha)
                              
                              {/* {sortIdx === 4 && <Icon name="sort ascending" />}
                              {sortIdx === -4 && <Icon name="sort descending" />}                           */}
                            </Table.HeaderCell>
                          }

                          {['after', 'both'].includes(currentView) && 
                            <Table.HeaderCell                     
                              // onClick={() => this.setSortIndex(4)}
                              colSpan='3'
                              rowSpan='1'
                              textAlign="center"
                            >
                              Utförd gödsling (kg/ha)
                              
                              {/* {sortIdx === 4 && <Icon name="sort ascending" />}
                              {sortIdx === -4 && <Icon name="sort descending" />}                           */}
                            </Table.HeaderCell>
                          } 

                          {currentView !== "plan" && <Table.HeaderCell rowSpan='2' />}
                        </Table.Row>

                        <Table.Row>
                          {['plan', 'both'].includes(currentView) && <Table.HeaderCell textAlign="center">ton/ha</Table.HeaderCell>}
                          {['plan', 'both'].includes(currentView) && <Table.HeaderCell textAlign="center">ton</Table.HeaderCell>}
                          {['after', 'both'].includes(currentView) && <Table.HeaderCell textAlign="center">ton/ha</Table.HeaderCell>}
                          {['after', 'both'].includes(currentView) && <Table.HeaderCell textAlign="center">ton</Table.HeaderCell>}

                          <Table.HeaderCell textAlign="center">pH</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">P-Al</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">K-Al</Table.HeaderCell>

                          {['plan', 'both'].includes(currentView) &&<Table.HeaderCell textAlign="center">N</Table.HeaderCell>}
                          {['plan', 'both'].includes(currentView) &&<Table.HeaderCell textAlign="center">P</Table.HeaderCell>}
                          {['plan', 'both'].includes(currentView) &&<Table.HeaderCell textAlign="center">K</Table.HeaderCell>}
                          {['after', 'both'].includes(currentView) && <Table.HeaderCell textAlign="center">N</Table.HeaderCell>}
                          {['after', 'both'].includes(currentView) && <Table.HeaderCell textAlign="center">P</Table.HeaderCell>}
                          {['after', 'both'].includes(currentView) && <Table.HeaderCell textAlign="center">K</Table.HeaderCell>}
                        </Table.Row>                        
                      </Table.Header>

                      <Table.Body>
                        {!editMode && fieldIds && fieldIds.map((x) => this.getTableRowView(x))}
                        {editMode && fieldIds && fieldIds.map((x) => this.getTableRowEdit(x))}
                      </Table.Body>
                    </Table>
                  </Segment>                
                </div>
              </div>

              {/* <div style={{ width: "33%" }}></div>*/}
            </div>
            
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      );
    }
  }
}

export default withRouter(PlanPage);
 