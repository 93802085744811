import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Menu, Image } from "semantic-ui-react";

import imgStep0 from "@/assets/icons/guideSteps/information.svg";
import imgStep1 from "@/assets/icons/guideSteps/step-one.svg";
import imgStep2 from "@/assets/icons/guideSteps/step-two.svg";
import imgStep3 from "@/assets/icons/guideSteps/step-three.svg";
import imgStep4 from "@/assets/icons/guideSteps/step-four.svg";
import imgStep5 from "@/assets/icons/guideSteps/step-five.svg";
import imgStep6 from "@/assets/icons/guideSteps/step-six.svg";
import imgStep7 from "@/assets/icons/guideSteps/step-seven.svg";
import imgStep8 from "@/assets/icons/guideSteps/step-eight.svg";
import imgStep9 from "@/assets/icons/guideSteps/step-nine.svg";

import FieldCartoon from "@/components/misc/FieldCartoon";

import "./style.css"

class MenuGuide extends Component {
  
  render() {
    const {
      db,
      fieldId,
      match: { 
        params: { layer },
      },
      menuItems,
    } = this.props;

    let hasGettingStarted = menuItems && menuItems.find((x) => x.name === "gettingStarted");

    let menuIcons = [
      imgStep1,
      imgStep2,
      imgStep3,
      imgStep4,
      imgStep5,
      imgStep6,
      imgStep7,
      imgStep8,
      imgStep9,
    ];

    menuIcons = hasGettingStarted ? [imgStep0, ...menuIcons] : menuIcons;

    let fieldDb = fieldId && db && db.fields && db.fields[fieldId] && db.fields[fieldId];
    let position = fieldDb && fieldDb.position;
    let fieldName = fieldDb && fieldDb.name;

    let fieldCartoon = position &&
      <FieldCartoon 
        style={{
          marginRight: 10,
          zIndex: 20,
          width: "2em",
          height: "2em",
        }}
        position={position} 
        color="#e0ba8c" 
      />;
    
    let activeItem = menuItems && menuItems.find((x) => x.active);
    let activeItemHasSubItems = activeItem && activeItem.items;
  
    let activeItemIndex = menuItems.findIndex((y) => y.active);    
    let activeSubItemIndex = activeItemHasSubItems && activeItem.items.findIndex((y) => y.active);    

    let noItems = fieldCartoon ? (menuItems.length + 1) : menuItems.length;
    
    return (
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 1.0)",
          position: "absolute",
          left: "85px",
          height: activeItemHasSubItems ? "90px" : "54px",
          width: "100vw",
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: "85px",
          zIndex: 1000,
        }}
      >
        <Menu
          pointing
          secondary
          fluid
          widths={noItems}
          style={{
            paddingLeft: 0,
            textAlign: "center",
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          {fieldCartoon &&
            <Menu.Item
              style={{
                height: "100%",
                marginLeft: "-30px",
                backgroundColor: "rgba(234, 204, 116, 0.25)",
              }}
            >
              {fieldCartoon && fieldCartoon}
              {fieldName && fieldName}
            </Menu.Item>   
          }

          {menuItems.map((x, index) => {
            let isActive = x.active ? x.active : layer === x.name;
            let isDone = activeItemIndex && index < activeItemIndex;
            
            let imageFilter = null;
            imageFilter = isActive ? "brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(7315%) hue-rotate(341deg) brightness(109%) contrast(87%)" : imageFilter;
            imageFilter = isDone ? "brightness(0) saturate(100%) invert(44%) sepia(18%) saturate(1230%) hue-rotate(63deg) brightness(97%) contrast(86%)" : imageFilter;

            let backgroundColor = null
            backgroundColor = isActive ? "rgba(234, 204, 116, 0.25)" : backgroundColor;
            backgroundColor = isDone ? "rgba(137, 162, 117, 0.25)" : backgroundColor;

            return (
              <Menu.Item
                key={"menuItem_" + x.name}
                active={isActive}
                // disabled={x.disabled ? x.disabled : false}
                onClick={() => x.href ? this.props.history.push(x.href) : x.onClick && x.onClick(x.name)}
                style={{
                  backgroundColor: backgroundColor,
                  color: isDone ? "grey" : null,
                  cursor: x.disabled ? "initial" : "pointer",
                  fontWeight: 500,
                  borderColor: activeItemHasSubItems ? "transparent" : null,
                  transition: "background-color 1.25s",
                }}
              >
                <Image 
                  src={menuIcons[index]} 
                  style={{ 
                    width: "2em", 
                    marginLeft: 8,
                    marginRight: 8,
                    filter: imageFilter,
                  }} 
                />
                
                {x.caption}
              </Menu.Item>              
            )
          })}
        </Menu>
        
        {activeItemHasSubItems &&
          <Menu
            pointing
            secondary
            widths={activeItem.items.length + 2}
            style={{
              margin: 0,
              padding: 0,
              textAlign: "center",
              fontSize: "90%",
            }}
          >
            {activeItem.items.map((x, index) => {
              let isActive = x.active ? x.active : layer === x.name;
              let isDone = activeSubItemIndex && index < activeSubItemIndex;

              return (
                <Menu.Item
                  key={"menuItem_" + x.name}
                  active={isActive}
                  onClick={() => x.href ? this.props.history.push(x.href) : x.onClick && x.onClick(x.name)}
                  style={{
                    color: isDone ? "grey" : null,
                    cursor: x.disabled ? "initial" : "pointer",
                    fontWeight: 500,
                  }}
                > 
                  {x.caption}
                </Menu.Item>              
              )
            })}
          </Menu>
        }
      </div>
    );
  }
}

export default withRouter(MenuGuide);
