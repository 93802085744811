export const CEREALS_CROP_CODES = ["1", "2", "3", "4", "5", "8"];

export const CROP_CODES = [
    { key: '0', value: '0', text: 'Övrigt', shorttext: 'Övrigt' },  
    { key: '1', value: '1', text: '1: Korn (höst)', shorttext: 'Korn (höst)'},
    { key: '2', value: '2', text: '2: Korn (vår)', shorttext: 'Korn (vår)'},
    { key: '3', value: '3', text: '3: Havre', shorttext: 'Havre'},
    { key: '4', value: '4', text: '4: Vete (höst)', shorttext: 'Vete (höst)'},
    { key: '5', value: '5', text: '5: Vete (vår)', shorttext: 'Vete (vår)'},
    { key: '6', value: '6', text: '6: Blandningar av baljväxter eller klöver till grovfoder/ensilage', shorttext: 'Blandningar av baljväxter eller klöver till grovfoder/ensilage'},
    { key: '7', value: '7', text: '7: Rågvete (höst)', shorttext: 'Rågvete (höst)'},
    { key: '8', value: '8', text: '8: Råg', shorttext: 'Råg'},
    { key: '9', value: '9', text: '9: Majs', shorttext: 'Majs'},
    { key: '10', value: '10', text: '10: Bovete', shorttext: 'Bovete'},
    { key: '11', value: '11', text: '11: Spannmålsförsök', shorttext: 'Spannmålsförsök'},
    { key: '12', value: '12', text: '12: Blandsäd (stråsädesblandningar)', shorttext: 'Blandsäd (stråsädesblandningar)'},
    { key: '13', value: '13', text: '13: Blandsäd (spannmåls-/baljväxt-blandning), mer än 50% spannmål', shorttext: 'Blandsäd (spannmåls-/baljväxt-blandning), mer än 50% spannmål'},
    { key: '14', value: '14', text: '14: Kanariefrö', shorttext: 'Kanariefrö'},
    { key: '15', value: '15', text: '15: Hirs', shorttext: 'Hirs'},
    { key: '16', value: '16', text: '16: Stråsäd till grönfoder/ensilage', shorttext: 'Stråsäd till grönfoder/ensilage'},
    { key: '20', value: '20', text: '20: Raps (höst)', shorttext: 'Raps (höst)'},
    { key: '21', value: '21', text: '21: Raps (vår)', shorttext: 'Raps (vår)'},
    { key: '22', value: '22', text: '22: Rybs (höst)', shorttext: 'Rybs (höst)'},
    { key: '23', value: '23', text: '23: Rybs (vår)', shorttext: 'Rybs (vår)'},
    { key: '24', value: '24', text: '24: Solros', shorttext: 'Solros'},
    { key: '25', value: '25', text: '25: Oljeväxtförsök', shorttext: 'Oljeväxtförsök'},
    { key: '26', value: '26', text: '26: Högerukaraps', shorttext: 'Högerukaraps'},
    { key: '27', value: '27', text: '27: Vitsenap', shorttext: 'Vitsenap'},
    { key: '28', value: '28', text: '28: Oljerättika', shorttext: 'Oljerättika'},
    { key: '29', value: '29', text: '29: Rågvete (vår)', shorttext: 'Rågvete (vår)'},
    { key: '30', value: '30', text: '30: Ärter (ej konservärter)', shorttext: 'Ärter (ej konservärter)'},
    { key: '31', value: '31', text: '31: Konservärter', shorttext: 'Konservärter'},
    { key: '32', value: '32', text: '32: Åkerbönor', shorttext: 'Åkerbönor'},
    { key: '33', value: '33', text: '33: Sötlupiner', shorttext: 'Sötlupiner'},
    { key: '34', value: '34', text: '34: Proteingrödsblandningar (baljväxter/spannmål)*', shorttext: 'Proteingrödsblandningar (baljväxter/spannmål)*'},
    { key: '35', value: '35', text: '35: Bruna bönor', shorttext: 'Bruna bönor'},
    { key: '36', value: '36', text: '36: Vicker', shorttext: 'Vicker'},
    { key: '37', value: '37', text: '37: Kikärter', shorttext: 'Kikärter'},
    { key: '38', value: '38', text: '38: Sojabönor (oljeväxt)', shorttext: 'Sojabönor (oljeväxt)'},
    { key: '39', value: '39', text: '39: Sojabönor (foderväxt)', shorttext: 'Sojabönor (foderväxt)'},
    { key: '40', value: '40', text: '40: Oljelin', shorttext: 'Oljelin'},
    { key: '41', value: '41', text: '41: Spånadslin', shorttext: 'Spånadslin'},
    { key: '42', value: '42', text: '42: Hampa', shorttext: 'Hampa'},
    { key: '43', value: '43', text: '43: Bönor övriga', shorttext: 'Bönor övriga'},
    { key: '45', value: '45', text: '45: Matpotatis', shorttext: 'Matpotatis'},
    { key: '46', value: '46', text: '46: Stärkelsepotatis', shorttext: 'Stärkelsepotatis'},
    { key: '47', value: '47', text: '47: Sockerbetor', shorttext: 'Sockerbetor'},
    { key: '48', value: '48', text: '48: Foderbetor', shorttext: 'Foderbetor'},
    { key: '49', value: '49', text: '49: Slåtter och betesvall på åkermark med  en  vallgröda som inte är godkänd för varken miljöersättning eller ersättningar för ekologisk produktion', shorttext: 'Slåtter och betesvall på åkermark med  en  vallgröda som inte är godkänd för varken miljöersättning eller ersättningar för ekologisk produktion'},
    { key: '50', value: '50', text: '50: Slåtter och betesvall på åkermark', shorttext: 'Slåtter och betesvall på åkermark'},
    { key: '52', value: '52', text: '52: Betesmark (ej åker)', shorttext: 'Betesmark (ej åker)'},
    { key: '53', value: '53', text: '53: Slåtteräng (ej åker) ', shorttext: 'Slåtteräng (ej åker) '},
    { key: '54', value: '54', text: '54: Skogsbete ', shorttext: 'Skogsbete '},
    { key: '55', value: '55', text: '55: Fäbodbete som inte ger rätt till gårdsstöd och kompensationsstöd', shorttext: 'Fäbodbete som inte ger rätt till gårdsstöd och kompensationsstöd'},
    { key: '56', value: '56', text: '56: Alvarbete (Öland, Gotland)', shorttext: 'Alvarbete (Öland, Gotland)'},
    { key: '57', value: '57', text: '57: Slåttervall på åker (kontrakt med vallJ, Grovfodergrödadertork)', shorttext: 'Slåttervall på åker (kontrakt med vallJ, Grovfodergrödadertork)'},
    { key: '58', value: '58', text: '58: Gräsfrövall (ettårig)', shorttext: 'Gräsfrövall (ettårig)'},
    { key: '59', value: '59', text: '59: Gräsfrövall (flerårig)', shorttext: 'Gräsfrövall (flerårig)'},
    { key: '60', value: '60', text: '60: Träda', shorttext: 'Träda'},
    { key: '61', value: '61', text: '61: Fäbodbete som ger rätt till gårdsstöd och kompensationsstöd', shorttext: 'Fäbodbete som ger rätt till gårdsstöd och kompensationsstöd'},
    { key: '62', value: '62', text: '62: Klöverfrövall', shorttext: 'Klöverfrövall'},
    { key: '63', value: '63', text: '63: Energigräs ', shorttext: 'Energigräs '},
    { key: '65', value: '65', text: '65: Salix', shorttext: 'Salix'},
    { key: '66', value: '66', text: '66: Anpassade skyddszoner', shorttext: 'Anpassade skyddszoner'},
    { key: '67', value: '67', text: '67: Poppel', shorttext: 'Poppel'},
    { key: '68', value: '68', text: '68: Hybridasp', shorttext: 'Hybridasp'},
    { key: '70', value: '70', text: '70: Jordgubbsodling', shorttext: 'Jordgubbsodling'},
    { key: '71', value: '71', text: '71: Övrig bärodling', shorttext: 'Övrig bärodling'},
    { key: '72', value: '72', text: '72: Fruktodling', shorttext: 'Fruktodling'},
    { key: '74', value: '74', text: '74: Grönsaksodling (köksväxter)', shorttext: 'Grönsaksodling (köksväxter)'},
    { key: '77', value: '77', text: '77: Skyddszon mot vattendrag', shorttext: 'Skyddszon mot vattendrag'},
    { key: '78', value: '78', text: '78: Plantskolor med odling av permanenta grödor', shorttext: 'Plantskolor med odling av permanenta grödor'},
    { key: '79', value: '79', text: '79: Kryddväxter och utsäde grönsaker', shorttext: 'Kryddväxter och utsäde grönsaker'},
    { key: '80', value: '80', text: '80: Grönfoder', shorttext: 'Grönfoder'},
    { key: '81', value: '81', text: '81: Gröngödsling', shorttext: 'Gröngödsling'},
    { key: '82', value: '82', text: '82: Våtmark', shorttext: 'Våtmark'},
    { key: '83', value: '83', text: '83: Julgransodling', shorttext: 'Julgransodling'},
    { key: '85', value: '85', text: '85: Trädgårdsodling (ej köksväxter, frukt eller bär)', shorttext: 'Trädgårdsodling (ej köksväxter, frukt eller bär)'},
    { key: '86', value: '86', text: '86: Ej stödberättigande gröda (bara för ersättningarna inom ekologisk produktion)', shorttext: 'Ej stödberättigande gröda (bara för ersättningarna inom ekologisk produktion)'},
    { key: '87', value: '87', text: '87: Annan stödberättigande gröda (bara för ersättningarna inom ekologisk produktion)', shorttext: 'Annan stödberättigande gröda (bara för ersättningarna inom ekologisk produktion)'},
    { key: '88', value: '88', text: '88: Övrig odling på åkermark', shorttext: 'Övrig odling på åkermark'},
    { key: '89', value: '89', text: '89: Mosaikbetesmark', shorttext: 'Mosaikbetesmark'},
    { key: '90', value: '90', text: '90: Gräsfattiga marker', shorttext: 'Gräsfattiga marker'},
    { key: '95', value: '95', text: '95: Betesmark och slåtteräng under restaurering', shorttext: 'Betesmark och slåtteräng under restaurering'},
    { key: 'xx', value: 'xx', text: 'Övrigt', shorttext: 'Övrigt' },  
];

export const CROP_QUALITY = [
    { key: '1', value: '1', values: [
            {key: 'malt', value: 'malt', text: 'Malt'},
            {key: 'feed', value: 'feed', text: 'Foder'},
        ]
    },    
    { key: '2', value: '2', values: [
            {key: 'malt', value: 'malt', text: 'Malt'},
            {key: 'feed', value: 'feed', text: 'Foder'},
        ]
    },
    { key: '4', value: '4', values: [
            {key: 'bread', value: 'bread', text: 'Bröd'},
            {key: 'feed', value: 'feed', text: 'Foder'},
        ]
    },
    { key: '5', value: '5', values: [
            {key: 'bread', value: 'bread', text: 'Bröd'},
            {key: 'feed', value: 'feed', text: 'Foder'},
        ]
    },
];

export const CROP_VARIETIES = [
    { key: '1', value: '1', values: [
            {key: 'anisette', value: 'Anisette', text: 'Anisette'},
            {key: 'joker', value: 'Joker', text: 'Joker'},
            {key: 'matros', value: 'Matros', text: 'Matros'},
            {key: 'mercurrioo', value: 'Mercurrioo', text: 'Mercurrioo'},
            {key: 'suellen', value: 'SU Ellen', text: 'SU Ellen'},
            {key: 'tropper', value: 'Tropper', text: 'Tropper'},
            {key: 'verity', value: 'Verity', text: 'Verity'},
            {key: 'wootan', value: 'Wootan', text: 'Wootan'},
            {key: 'misc', value: 'misc', text: 'Ange egen...'},
        ]
    },    
    { key: '2', value: '2', values: [
            {key: 'anakin', value: 'Anakin', text: 'Anakin'},
            {key: 'astoria', value: 'Astoria', text: 'Astoria'},
            {key: 'beronesse', value: 'Beronesse', text: 'Beronesse'},
            {key: 'brioni', value: 'Brioni', text: 'Brioni'},
            {key: 'catriona', value: 'Catriona', text: 'Catriona'},
            {key: 'columbus', value: 'Columbus', text: 'Columbus'},
            {key: 'dragoon', value: 'Dragoon', text: 'Dragoon'},
            {key: 'fairytale', value: 'Fairytale', text: 'Fairytale'},
            {key: 'flair', value: 'Flair', text: 'Flair'},
            {key: 'gustav', value: 'Gustav', text: 'Gustav'},
            {key: 'henley', value: 'Henley', text: 'Henley'},
            {key: 'irina', value: 'Irina', text: 'Irina'},
            {key: 'kinnan', value: 'Kinnan', text: 'Kinnan'},
            {key: 'laureate', value: 'Laureate', text: 'Laureate'},
            {key: 'luhkas', value: 'Luhkas', text: 'Luhkas'},
            {key: 'makof', value: 'Makof', text: 'Makof'},
            {key: 'melius', value: 'Melius', text: 'Melius'},
            {key: 'mitja', value: 'Mitja', text: 'Mitja'},
            {key: 'otira', value: 'Otira', text: 'Otira'},
            {key: 'passenger', value: 'Passenger', text: 'Passenger'},
            {key: 'planet', value: 'Planet', text: 'Planet'},
            {key: 'prestige', value: 'Prestige', text: 'Prestige'},
            {key: 'propino', value: 'Propino', text: 'Propino'},
            {key: 'quench', value: 'Quench', text: 'Quench'},
            {key: 'rosalina', value: 'Rosalina', text: 'Rosalina'},
            {key: 'salome', value: 'Salome', text: 'Salome'},
            {key: 'sanette', value: 'Sanette', text: 'Sanette'},
            {key: 'sebastian', value: 'Sebastian', text: 'Sebastian'},
            {key: 'shandy', value: 'Shandy', text: 'Shandy'},
            {key: 'tamtam', value: 'Tamtam', text: 'Tamtam'},
            {key: 'thermus', value: 'Thermus', text: 'Thermus'},
            {key: 'tipple', value: 'Tipple', text: 'Tipple'},
            {key: 'vilgott', value: 'Vilgott', text: 'Vilgott'},
            {key: 'misc', value: 'misc', text: 'Ange egen...'},
        ]
    },
    { key: '4', value: '4', values: [
            {key: 'brons', value: 'Brons', text: 'Brons'},
            {key: 'ceylon', value: 'Ceylon', text: 'Ceylon'},
            {key: 'cubus', value: 'Cubus', text: 'Cubus'},
            {key: 'elixer', value: 'Elixer', text: 'Elixer'},
            {key: 'ellvis', value: 'Ellvis', text: 'Ellvis'},
            {key: 'etana', value: 'Etana', text: 'Etana'},
            {key: 'festival', value: 'Festival', text: 'Festival'},
            {key: 'frontal', value: 'Frontal', text: 'Frontal'},
            {key: 'hereford', value: 'Hereford', text: 'Hereford'},
            {key: 'informer', value: 'Informer', text: 'Informer'},
            {key: 'julius', value: 'Julius', text: 'Julius'},
            {key: 'kalmar', value: 'Kalmar', text: 'Kalmar'},
            {key: 'kosack', value: 'Kosack', text: 'Kosack'},
            {key: 'linus', value: 'Linus', text: 'Linus'},
            {key: 'mariboss', value: 'Mariboss', text: 'Mariboss'},
            {key: 'memory', value: 'Memory', text: 'Memory'},
            {key: 'nordh', value: 'Nordh', text: 'Nordh'},
            {key: 'norin', value: 'Norin', text: 'Norin'},
            {key: 'qlivin', value: 'Olivin', text: 'Olivin'},
            {key: 'praktik', value: 'Praktik', text: 'Praktik'},
            {key: 'reform', value: 'Reform', text: 'Reform'},
            {key: 'stava', value: 'Stava', text: 'Stava'},
            {key: 'torp', value: 'Torp', text: 'Torp'},
            {key: 'misc', value: 'misc', text: 'Ange egen...'},
        ]
    },
    { key: '5', value: '5', values: [
            {key: 'alderon', value: 'Alderon', text: 'Alderon'},
            {key: 'dacke', value: 'Dacke', text: 'Dacke'},
            {key: 'diskett', value: 'Diskett', text: 'Diskett'},
            {key: 'qryx', value: 'Oryx', text: 'Oryx'},
            {key: 'quarna', value: 'Quarna', text: 'Quarna'},
            {key: 'skye', value: 'Skye', text: 'Skye'},
            {key: 'triso', value: 'Triso', text: 'Triso'},
            {key: 'vinjett', value: 'Vinjett', text: 'Vinjett'},
            {key: 'misc', value: 'misc', text: 'Ange egen...'},
        ]
    },
    { key: '7', value: '7', values: [
            {key: 'cappricia', value: 'Cappricia', text: 'Cappricia'},
            {key: 'dequenz', value: 'Dequenz', text: 'Dequenz'},
            {key: 'dinaro', value: 'Dinaro', text: 'Dinaro'},
            {key: 'empero', value: 'Empero', text: 'Empero'},
            {key: 'falmoro', value: 'Falmoro', text: 'Falmoro'},
            {key: 'fidelio', value: 'Fidelio', text: 'Fidelio'},
            {key: 'remiko', value: 'Remiko', text: 'Remiko'},
            {key: 'tritikon', value: 'Tritikon', text: 'Tritikon'},
            {key: 'tulus', value: 'Tulus', text: 'Tulus'},
            {key: 'misc', value: 'misc', text: 'Ange egen...'},     
        ]
    },
    { key: '8', value: '8', values: [
            {key: 'amilo', value: 'Amilo', text: 'Amilo'},
            {key: 'bono', value: 'Bono', text: 'Bono'},
            {key: 'brasetto', value: 'Brasetto', text: 'Brasetto'},
            {key: 'evolo', value: 'Evolo', text: 'Evolo'},
            {key: 'herakles', value: 'Herakles', text: 'Herakles'},
            {key: 'kaskelott', value: 'Kaskelott', text: 'Kaskelott'},
            {key: 'marcelo', value: 'Marcelo', text: 'Marcelo'},
            {key: 'palazzo', value: 'Palazzo', text: 'Palazzo'},
            {key: 'performer', value: 'Performer', text: 'Performer'},
            {key: 'picasso', value: 'Picasso', text: 'Picasso'},
            {key: 'visello', value: 'Visello', text: 'Visello'},
            {key: 'misc', value: 'misc', text: 'Ange egen...'},   
        ]
    },
    { key: '29', value: '29', values: [
            {key: 'cappricia', value: 'Cappricia', text: 'Cappricia'},
            {key: 'dequenz', value: 'Dequenz', text: 'Dequenz'},
            {key: 'dinaro', value: 'Dinaro', text: 'Dinaro'},
            {key: 'empero', value: 'Empero', text: 'Empero'},
            {key: 'falmoro', value: 'Falmoro', text: 'Falmoro'},
            {key: 'fidelio', value: 'Fidelio', text: 'Fidelio'},
            {key: 'remiko', value: 'Remiko', text: 'Remiko'},
            {key: 'tritikon', value: 'Tritikon', text: 'Tritikon'},
            {key: 'tulus', value: 'Tulus', text: 'Tulus'},
            {key: 'misc', value: 'misc', text: 'Ange egen...'},
        ]
    },
];

export const USDA_SOIL_TEXTURE_SWEDISH = [
    { key: '1', value: '1', text: 'Sand' },
    { key: '2', value: '2', text: 'Sandig lättlera' },
    { key: '3', value: '3', text: 'Siltig sant' },
    { key: '4', value: '4', text: 'Lättlera' },
    { key: '5', value: '5', text: 'Sandig silt' },
    { key: '6', value: '6', text: 'Silt' },
    { key: '7', value: '7', text: 'Sandig lättlera' },
    { key: '8', value: '8', text: 'Mellanlera' },
    { key: '9', value: '9', text: 'Siltig lättlera' },
    { key: '10', value: '10', text: 'Sandig mellanlera' },
    { key: '11', value: '11', text: 'Siltig mellanlera' },
    { key: '12', value: '12', text: 'Styv lera' },
];

export const USDA_SOIL_TEXTURE = [
    { key: '1', value: '1', text: 'Sand' },
    { key: '2', value: '2', text: 'Loamy sand' },
    { key: '3', value: '3', text: 'Sandy loam' },
    { key: '4', value: '4', text: 'Loam' },
    { key: '5', value: '5', text: 'Silt loam' },
    { key: '6', value: '6', text: 'Silt' },
    { key: '7', value: '7', text: 'Sandy clay loam' },
    { key: '8', value: '8', text: 'Clay loam' },
    { key: '9', value: '9', text: 'Silty clay loam' },
    { key: '10', value: '10', text: 'Sandy clay' },
    { key: '11', value: '11', text: 'Silty clay' },
    { key: '12', value: '12', text: 'Clay' },
];