import React, { Component } from "react";

import { 
  Header,
  Popup,
  Icon,
  Modal,
  Button,
  Segment,
} from "semantic-ui-react";

import moment from 'moment';

import WeatherSeasonSummary from "@/components/weather/WeatherSeasonSummary";

import { fetchWeatherData, extractYears, extractDatesAndData } from "@/helpers/weather";

import {
  SEASON_LAST_DATE,
} from "constants/settings";

import {
  SHOW_WEATHER_FOR_LAYERS,
} from "@/constants/viewLayersAnalysis"

const INITIAL_STATE = {
  temperatureData: null,
  rainfallData: null,
  irradiationData: null,
  overridingLayerId: null,
  modalOpen: false,
};

class MapOverviewWeatherWidget extends Component {
  
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    const {
      weatherData,
      fileBucketRef,
    } = this.props;

    fetchWeatherData(weatherData, fileBucketRef, this.setParentState);
  };

  componentDidUpdate(prevProps) {
    const {
      weatherData,
      fileBucketRef,
    } = this.props;

    if (weatherData !== prevProps.weatherData) {
      fetchWeatherData(weatherData, fileBucketRef, this.setParentState);
    }
  };

  setParentState = (newState) => this.setState(newState);

  toggleModalOpen = () => {
    let currentValue = this.state.modalOpen;
    this.setState({ 
      modalOpen: !currentValue,
      overridingLayerId: null,
    });
  };

  handleChange = (event, {name, value}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  onClickPreviousDate = (oldDate) => {
    let oldDateMoment = moment(oldDate, "YYYY-MM-DD");
    let newDate = oldDateMoment.subtract(1, 'days');
    
    this.setState({
      overridingLayerId: newDate.format("YYYY-MM-DD")
    })
  };

  onClickNextDate = (oldDate) => {
    let oldDateMoment = moment(oldDate, "YYYY-MM-DD");
    let newDate = oldDateMoment.add(1, 'days');
    
    this.setState({
      overridingLayerId: newDate.format("YYYY-MM-DD")
    })
  };

  render () {
    const {
      mode,
      layer,
      layerId,
      weatherData,
      fileBucketRef,
    } = this.props;

    const {        
      irradiationData,
      temperatureData,
      rainfallData,
      modalOpen,
      overridingLayerId,
    } = this.state;

    let hasWeatherToShow = SHOW_WEATHER_FOR_LAYERS.includes(layer);

    let currentYear = layerId && layerId.split("-")[0];

    let availableYears = temperatureData && extractYears(temperatureData);

    let accIrradiation = extractDatesAndData(irradiationData, layerId) / 1000.0;
    let accTemperature = extractDatesAndData(temperatureData, layerId);
    let accRainfall = extractDatesAndData(rainfallData, layerId);

    let [ startDate, endDate ] = extractDatesAndData(temperatureData, layerId, null, false, true);
    startDate = startDate && moment(startDate).format("LL");
    endDate = endDate && moment(endDate).format("LL");

    hasWeatherToShow = typeof(accTemperature) !== "number" ? false : hasWeatherToShow;

    if (!hasWeatherToShow) {
      return (<div/>)
    }    

    if (availableYears && currentYear && !availableYears.includes(currentYear)) {
      return (
        <Segment
          basic 
          inverted
          style={{ 
            width: "100%", 
            marginTop: !mode.includes("compare") ? -3 : -7,
            background: "#444444",
            boxShadow: "0 1px 2px 0 rgba(174, 179, 179, 0.15)",
            borderRadius: "0.28571429rem",
            opacity: 0.95,
            padding: !mode.includes("compare") ? "1em" : "0.5.em",
          }}
        >
          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
              whiteSpace: "pre-line",
            }}
          >
            <Header
              as="h4"
              style={{
                marginTop: 0,
                fontWeight: 500,
                color: "white",
                fontSize: !mode.includes("compare") ? null : "80%",
              }}
            >
              Väder
            </Header>
          </div>

          <p
            style={{
              whiteSpace: "pre-line",
              fontSize: !mode.includes("compare") ? null : "80%",
            }}
          >
            Ingen väderdata tillgänglig för {currentYear}.
          </p>
        </Segment>
      )
    };

    let localLayerId = modalOpen && (overridingLayerId ? overridingLayerId : layerId);
    localLayerId = localLayerId && (moment(localLayerId, "YYYY-MM-DD") > moment(currentYear + SEASON_LAST_DATE, "YYYY-MM-DD") ? currentYear + SEASON_LAST_DATE : localLayerId);

    return (
      <React.Fragment>
        <Modal open={modalOpen}>
          <Modal.Header style={{ fontWeight: 500 }}>
            Jämförelse av säsongsväder
          </Modal.Header>

          <Modal.Content>
            <WeatherSeasonSummary
              type="modal"
              fileBucketRef={fileBucketRef}
              weatherData={weatherData}
              layer={layer}
              layerId={localLayerId}
            />      
          </Modal.Content>
          <Modal.Actions
            style={{ 
              display: "flex",
              justifyContent: "space-between"
            }}          
          >
            <div></div>
            <Button 
              style={{ 
                backgroundColor: "#6B9D9F",
                color: "white",
              }}
              onClick={() => this.toggleModalOpen()}
            >
              Stäng
            </Button>
          </Modal.Actions>
        </Modal>
      
        <Segment
          basic 
          inverted
          loading={typeof(accTemperature) !== "number" || typeof(accIrradiation) !== "number"}
          style={{ 
            width: "100%", 
            marginTop: !mode.includes("compare") ? -3 : -7,
            background: "#444444",
            boxShadow: "0 1px 2px 0 rgba(174, 179, 179, 0.15)",
            borderRadius: "0.28571429rem",
            opacity: 0.95,
            padding: !mode.includes("compare") ? "1em" : "0.5em",
          }}
        >
          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: !mode.includes("compare") ? null : "70%",
            }}
          >
            <Header
              as="h4"
              style={{
                marginTop: 0,
                fontWeight: 500,
                color: "white",
                fontSize: !mode.includes("compare") ? null : "130%",
              }}
            >
              Väder
            </Header>

            <div>       

              <Popup
                style={{
                  zIndex: "1000",
                }}
                trigger={
                  <Icon
                    className="iconButton"
                    name="expand"
                    onClick={() => this.toggleModalOpen()}
                  />
                }
                content={
                  "Utforska och jämför vädret mellan olika odlingssäsonger."
                }
              />                      
            </div>
          </div>   

          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              marginTop: 5,
              fontSize: !mode.includes("compare") ? null : "85%",
            }}
          >
            <Popup
              style={{
                zIndex: "1000",
              }}
              trigger={
                <div>
                  <Icon name="thermometer half" size="big" />
                  <div style={{ marginTop: 8 }} />
                  {accTemperature && typeof(accTemperature) === "number" ? Math.floor(accTemperature).toLocaleString().replace(/,/g," ",) : '-' }&deg;C
                </div>
              }
              content={
                "De ackumulerade dygnsgraderna under perioden " + startDate + " till " + endDate + "."
              }
            />   
                      
            <Popup
              style={{
                zIndex: "1000",
              }}
              trigger={
                <div>
                  <Icon name="rain" size="big" />
                  <div style={{ marginTop: 8 }} />
                  {accRainfall && typeof(accRainfall) === "number" ? accRainfall.toFixed(0) : '0'} mm
                </div>
              }
              content={
                "Den ackumulerade nedebörden under perioden " + startDate + " till " + endDate + "."
              }
            />   

            <Popup
              style={{
                zIndex: "1000",
              }}
              trigger={
                <div>
                  <Icon name="sun" size="big" />
                  <div style={{ marginTop: 8 }} />
                  {accIrradiation && typeof(accIrradiation) === "number" ? accIrradiation.toFixed(0) : '-'} kW
                </div>   
              }
              content={
                "Den ackumulerade solinstrålningen under perioden " + startDate + " till " + endDate + "."
              }
            />
          </div> 
        </Segment>
      </React.Fragment>
    )
  }
}

export default MapOverviewWeatherWidget;
