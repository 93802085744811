import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Header,
  Segment,
  Button,
  Icon, 
} from "semantic-ui-react";

import { CEREALS_CROP_CODES } from "@/constants/cropCodes";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import MenuFieldList from "@/components/menus/MenuFieldList";
import TopBarAnnouncement from "@/components/bars/TopBarAnnouncement";

class HarvestReportGuideSelectFields extends Component {

  state = {
    showAllFields: false,
  };

  toogleShowAll = () => {
    let currentValue = this.state.showAllFields;
    this.setState({ showAllFields: !currentValue });
  };

  checkEnoughData = (fieldId, withEnoughData) => {
    const {
      fields,
      fieldsInfo,
      yieldMapsProcessed,
    } = this.props;
    
    let field = fieldId && fields && fields[fieldId];
    let fieldInfo = fieldId && fieldsInfo && fieldsInfo[fieldId];

    let availableToUser = field && field.available_to_user;
    availableToUser = typeof(availableToUser) === "boolean" ? availableToUser : true;

    let availableYears = yieldMapsProcessed && fieldId && 
      yieldMapsProcessed[fieldId] && 
      yieldMapsProcessed[fieldId].rawdata_harvest_maps_geojson && 
      Object.keys(yieldMapsProcessed[fieldId].rawdata_harvest_maps_geojson).map((x) => x).sort()  
    
    let yearWithSupportedCrops = availableYears && fieldInfo && fieldInfo.crop_code && 
      availableYears.map((x) => fieldInfo.crop_code[x]).filter((y) => CEREALS_CROP_CODES.includes(y));

    let hasEnoughData = yearWithSupportedCrops && yearWithSupportedCrops.length && yearWithSupportedCrops.length > 4;

    let hasWinterWheatData = fieldInfo && fieldInfo.crop_code && availableYears && availableYears.map(x => fieldInfo.crop_code[x]).find(x => x === "4");

    let noYieldMaps = availableYears && availableYears.length;

    let reason = '';
    reason = !hasEnoughData ? "5 uppladdade skördekartor för stråsäd saknas." : reason;
    reason = !hasWinterWheatData ? "Uppladdade skördekartor för höstvete saknas." : reason;

    if (withEnoughData === true && (!hasEnoughData || !hasWinterWheatData)) {
      return {flag: false, reason: reason, noYieldMaps: noYieldMaps}
    };

    if (withEnoughData === false && hasEnoughData  && hasWinterWheatData) {
      return {flag: false, reason: reason, noYieldMaps: noYieldMaps}
    };

    if (!availableToUser) { 
      return {flag: false, reason: reason, noYieldMaps: noYieldMaps}
    };

    return {flag: true, reason: reason, noYieldMaps: noYieldMaps}
  };

  onClickGoToStep2 = () => {
    this.props.onClickGoToStep2();
  };

  render() {

    const {
      db,
      userId,
      fields,
      yieldMapsRaw,
      selectedFieldIds
    } = this.props;

    const {
      showAllFields,
    } = this.state;

    let fieldIds = yieldMapsRaw && fields && Object.keys(yieldMapsRaw)
      .sort(function(a, b) { 
        let sortedArray = [db.fields[b].name, db.fields[a].name].sort()

        if (sortedArray[0] === db.fields[b].name) {
          return 1.0
        } else {
          return -1.0
        }
      });
    
    let additionalInformationEnoughData = {};
    let fieldIdsEnoughData = [];
    
    fieldIds && fieldIds.forEach((x) => {
      let output = this.checkEnoughData(x, true);
      if (output.flag) {
        fieldIdsEnoughData = [...fieldIdsEnoughData, x];
        additionalInformationEnoughData = {...additionalInformationEnoughData, [x]: output}
      };
    });

    let additionalInformationNotEnoughData = {};
    let fieldIdsNotEnoughData = [];

    fieldIds && fieldIds.forEach((x) => {
      let output = this.checkEnoughData(x, true);
      if (!output.flag) {
        fieldIdsNotEnoughData = [...fieldIdsNotEnoughData, x];
        additionalInformationNotEnoughData = {...additionalInformationNotEnoughData, [x]: output}
      };
    });    
    
    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickGoToGettingStarted,
        }
      ],      
      next: [
        {
          caption: "Gå vidare",
          onClick: this.onClickGoToStep2,
          disabled: !selectedFieldIds || selectedFieldIds.length < 1,
        }
      ],
    };

    let mainColumn =
      <React.Fragment>
        {showAllFields &&
          <Header 
            style={{ 
              fontWeight: 500,
            }}
          >
            Skiften med tillräcklig mängd data för att skapa en översikt
          </Header>
        }

        <MenuFieldList
          db={db}
          userId={userId}
          fieldId={null}
          selectedFieldIds={selectedFieldIds}
          openField={this.props.onSelectField}
          compact={true}
          hideFarmCard={true}
          hideLockedFields={true}
          showOnlyFieldIds={fieldIdsEnoughData}
          additionalInformation={additionalInformationEnoughData}
        />

        {fieldIdsEnoughData.length === 0 && 
          <Segment 
            style={{ 
              padding: "2em" 
            }}
          >
            Inga skiften hittades.
          </Segment>
        }            
        {showAllFields &&
          <Header 
            style={{ 
              fontWeight: 500,
              marginTop: "2em", 
            }}
          >
            Skiften med otillräcklig mängd data för att skapa en översikt
          </Header> 
        }

        {showAllFields &&
          <MenuFieldList
            db={db}
            userId={userId}
            fieldId={null}
            selectedFieldIds={selectedFieldIds}
            // openField={this.selectField}
            compact={true}
            hideFarmCard={true}
            hideLockedFields={true}
            showOnlyFieldIds={fieldIdsNotEnoughData}
            additionalInformation={additionalInformationNotEnoughData}
            disableHover={true}
          />
        }
        
        {showAllFields && fieldIdsNotEnoughData.length === 0 && 
          <Segment 
            style={{ 
              padding: "2em" 
            }}
          >
            Inga skiften hittades.
          </Segment>
        }         
      </React.Fragment>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Välj skiften
        </Header>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Markera de skiften som du vill skapa skördeöversikter för. 
          Freja har redan lagt till de skiften där det finns tillräckligt med data för att skapa en översikt.
        </p>

        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Tillräcklig mängd data
        </Header>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          För att skapa en översikt kräver Freja skördekartor från minst fem år med stråsäd varav minst ett år med höstvete. 
          Vi har infört detta krav för att säkerställa en god kvalitet på skördeöversikten.
        </p>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Även om kravet på tillräcklig data inte uppnås så kan du ändå se alla skiften med skördekartor genom att klicka på knappen nedanför. Där får du också resa på vad som saknas hos varje skifte för att kunna skapa en skördeöversikt.
        </p>

        <Button
          fluid
          style={{
            marginTop: "1em",
            backgroundColor: "#6B9D9F",
            color: "white",
          }}
          onClick={() => this.toogleShowAll()}
        >
          {showAllFields ? "Dölj skiften utan tillräcklig data" : "Visa alla skiften"}
        </Button>

        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Har du fler skördekartor?
        </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Du kan ladda upp fler skördekartor genom att klicka på knappen nedan och sedan kan du komma tillbaka till denna guide för att fortsätta skapa skördeöversikter.
        </p>

        <Button
          fluid
          icon
          labelPosition="left"
          style={{
            marginTop: "1em",
            backgroundColor: "#6B9D9F",
            color: "white",
          }}
          onClick={() => this.props.history.push("/guidesUploadYieldMaps")}
        >
          <Icon name="upload" />
          Ladda upp skördekartor
        </Button>
      </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        // mainColumnHeader="Välj skiften att skapa översikter för"
        helpColumn={helpColumn}
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        menuItems={menuItems}
      >
        {this.props.showSkipGettingStarted &&
          <TopBarAnnouncement 
            text='Vi hoppade över att visa hur du kommer igång eftersom du nyligen har sett den informationen. Om du ändå vill se den kan du klicka på knappen "Tillbaka" längst ned till vänster på skärmen.'
            onHideBar={this.props.onHideSkipGettingStarted}
            skipGettingStarted={this.props.skipGettingStarted}
          />
        }
      </GuidesBasicGrid>        
    )
  }
}

export default withRouter(HarvestReportGuideSelectFields);