import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Header,
  Segment,
  Dropdown,
  Table,
} from "semantic-ui-react";

import FieldCartoon from "@/components/misc/FieldCartoon";

const NO_FIELDS_TO_SHOW = 3;

const INITIAL_STATE = {
  activeView: "maxGreenmass",
};

class FieldsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  openField = (fieldId) => {
    const {
      activeView,
    } = this.state;

    if (activeView === "maxGreenmass") {
      this.props.history.push("/layers/" + fieldId + "/greenmass")
    };

    if (activeView === "varianceGreenmass") {
      this.props.history.push("/layers/" + fieldId + "/greenmass_percent")
    };

    if (activeView === "maxHarvest") {
      this.props.history.push("/layers/" + fieldId + "/yieldMap")
    };        
  }

  getFieldCards = (fieldId, cartoonColor) => {
    const {
      db,
    } = this.props;

    let field = db && db.fields && db.fields[fieldId] && db.fields[fieldId];

    let name = field && field.name && field.name;
    let position = field && field.position && field.position;
    
    
    if (!position) {
      return null
    }

    return (
      <Table.Row
        key={`DashboardTopList_${fieldId}`}
        className="fieldCardOnHover"
        onClick={() => this.openField(fieldId)}
        style={{
          cursor: "pointer",
        }}
      >
       <Table.Cell
        style={{
          marginLeft: 10,
          marginRight: 10,
          width: 30,
        }}
       >
          {position && 
            <FieldCartoon
              className="fieldCartoon"
              style={{
                height: 20,
                width: 20,
              }}            
              color={cartoonColor}
              position={position}
            />
          }
        </Table.Cell>
        <Table.Cell
          style={{
            width: "calc(100%-50)",
          }}
        >
          {name && name}
        </Table.Cell>   
      </Table.Row>
    )
  };

  handleItemClick = (item) => this.setState({ activeView: item });

  render() {
    const { 
      db,
    } = this.props;

    const {
      activeView,
    } = this.state;

    let greenmassMean = db && db.dashboard && 
      db.dashboard.top_list_greenmass &&
      db.dashboard.top_list_greenmass.mean;

    let greenmassVariance = db && db.dashboard && 
      db.dashboard.top_list_greenmass &&
      db.dashboard.top_list_greenmass.variance;
    
    let menuItems = [
      {
        key: 'maxGreenmass',
        value: 'maxGreenmass',
        text: "Grönmassa",
        onClick: () => this.handleItemClick('maxGreenmass'),
      },
      {
        key: 'varianceGreenmass',
        value: 'varianceGreenmass',
        text: "Variation i grönmassa",
        onClick: () => this.handleItemClick('varianceGreenmass'),        
      },
      // {
      //   key: 'maxHarvest',
      //   value: 'maxHarvest',
      //   text: "Skörd",
      //   onClick: () => this.handleItemClick('maxHarvest'),
      //   fieldIds: greenmassMean,
      //   headerHighest: "Stora skördar av höstvete",
      //   headerLowest: "Små skördar av höstvete",        
      // },            
    ];

    let sortedFieldIds;

    if (activeView === 'maxGreenmass') {
      sortedFieldIds = greenmassMean;
    };

    if (activeView === 'varianceGreenmass') {
      sortedFieldIds = greenmassVariance;
    };

    sortedFieldIds = sortedFieldIds && Object.keys(sortedFieldIds).sort((a, b) => sortedFieldIds[b] - sortedFieldIds[a]);

    let fieldIdsHighest = sortedFieldIds && sortedFieldIds.slice(0, NO_FIELDS_TO_SHOW);
    let fieldIdsLowest = sortedFieldIds && sortedFieldIds.slice(1).slice(-NO_FIELDS_TO_SHOW);

    let fieldItemsHighest = fieldIdsHighest && fieldIdsHighest.map((x) => this.getFieldCards(x, "#89A275"));
    let fieldItemsLowest = fieldIdsLowest && fieldIdsLowest.map((x) => this.getFieldCards(x, "#D6946B"));

    if (!greenmassMean || !fieldIdsHighest || !fieldIdsLowest) {
      return <div />
    };

    return (
      <Segment
        basic
        style={{
          padding: "1.5em",
          backgroundColor: "#f7f4f0",
        }}
        loading={!greenmassMean || !fieldIdsHighest || !fieldIdsLowest}
      >
        <div 
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Header
            as="h3"
            style={{ 
              paddingTop: "0",
              fontWeight: 500,
              color: "grey",
            }}
          >
            Topplista
          </Header>
                            
          <Dropdown
            className="textLink"
            options={menuItems}
            value={activeView}
          />
        </div>
        
        <Table
          style={{
            margin: 0,
            width: "100%",
            backgroundColor: "white",
          }}
          compact
          striped
          basic="very"
        >
          <Table.Body>
            {fieldItemsHighest && fieldItemsHighest}
          </Table.Body>
        </Table>

        <Table
          style={{
            margin: 0,
            marginTop: "1em",
            width: "100%",
            backgroundColor: "white",
          }}
          compact
          striped
          basic="very"
        >
          <Table.Body>
            {fieldItemsLowest && fieldItemsLowest}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default withRouter(FieldsWidget);
