import React, { Component } from "react";

import { 
  Loader,
  Modal,
  Form,
  Button,
} from 'semantic-ui-react';

import queryString from 'query-string';

import { apiCheckRegKey, apiSetRegKey } from '@/constants/firebase';

import BasicPageLayout from '@/components/misc/BasicPageLayout';
import AccountInformation from '@/components/guides/registrationV1/accountInformation';

import Illustration from "@/assets/illustrations/onboarding_guide_3.png";

const INITIAL_STATE = {
  registrationKey: null,
  validRegKey: null,
  isCheckingKey: false,
  showCreatingUserLoader: false,
  errorMessageFirestore: null,
};

const CreatingUserLoader = ({open}) => (
  <Modal 
    dimmer='inverted' 
    open={open}
  >
    <Loader 
      size='massive'
    >
      Skapar användarkonto...
    </Loader>
  </Modal>
);

class RegistrationV1Part1 extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    const {
      match: {
        params: { key }
      },
    } = this.props;

    const {
      validRegKey,
      isCheckingKey,
    } = this.state;

    const value = queryString.parse(this.props.location.search);
    let givenKey = key ? key : value && value.key;
    givenKey = givenKey && givenKey.replace(" ", "");

    this.setState({ 
      registrationKey: givenKey,
      editRegistrationKey: givenKey,
    });

    let keyHasBeenChecked = typeof(validRegKey) === "boolean";

    if (!keyHasBeenChecked && !givenKey) {
      this.setState({ validRegKey: false });
      return null
    };

    if (!isCheckingKey && !keyHasBeenChecked && givenKey) {
      this.setState({
        isCheckingKey: true,
        registrationKey: givenKey,
      });

      window.fetch(apiCheckRegKey, {
        method: "post",
        body: JSON.stringify({key: givenKey}),
        headers: {'Content-Type': 'application/json'}
      })
      .then(async data => await data.json())
      .then(x => {
        this.setState({ 
          validRegKey: x.valid, 
          isCheckingKey: false 
        })

        x.valid && this.props.history.push("/register/" + givenKey + "/createUser");
      })
    };
  };  
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createUser = (accountInformation) => {
    this.setState({ showCreatingUserLoader: true });
    
    this.props.firebase
      .createUser({email: accountInformation.email1, password: accountInformation.password1})
      .then(x => {
        this.markRegKeyAsUsed(accountInformation);
        window.location.href = "/register2/farm";
      })
      .catch(error => {
        this.setState({ errorMessageFirestore: error.toString() });
        this.setState({ showCreatingUserLoader: false });
        console.log(error);
      });  
  };

  markRegKeyAsUsed = (accountInformation) => {
    const {
      registrationKey
    } = this.state;

    window.fetch(apiSetRegKey, {
      method: "post",
      body: JSON.stringify({
        keyUsed: registrationKey, 
        email: accountInformation.email1,
      }),
      headers: {'Content-Type': 'application/json'}
    })
    .then()
    .catch(error => {
      console.log('Marking reg key as used failed...');
      console.log(error)
    });        
  };  

  onSubmit = () => {
    window.location.href = "/register?key=" + this.state.editRegistrationKey;
  }

  render () {
    const {
      editRegistrationKey,
      validRegKey,
      showCreatingUserLoader,
      errorMessageFirestore,      
    } = this.state;

    let pageData = <BasicPageLayout
      imageSrc={Illustration}
      caption="Skapa användarkonto"
      description="Ange ditt användarnamn och lösenord i fälten till vänster för att börja guiden som hjälper dig att skapa ett konto och gård hos AgriOpt."
    >    
      <AccountInformation 
        createUser={this.createUser}
        showCreatingUserLoader={showCreatingUserLoader}
        errorMessageFirestore={errorMessageFirestore}
      />
    </BasicPageLayout>
  
    if (validRegKey === false) {
      pageData = <BasicPageLayout
        imageSrc={Illustration}
        caption="Ogiltig registreringsnyckel"
      >
        <div
          style={{
            width: "67%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",                              
          }}
        >
          <p
            style={{
              
              fontSize: '110%',
              textAlign: "center",
            }}
          >
            Du har tyvärr angivit en felaktig registeringsnyckel. Kontrollera nyckeln i fältet ovanför. 
          </p> 

          <Form
            style={{
              marginTop: "3em",
            }}          
            size="large"
            onSubmit={this.onSubmit}
          >
            <Form.Input
              autoFocus
              className="inputNoBorders"
              fluid
              focus
              size="large"
              icon="key"
              name="editRegistrationKey"
              iconPosition="right"
              label="Registreringsnyckel"
              value={editRegistrationKey}
              onChange={this.onChange}
            />            

            <Button
              primary
              fluid
              size="large"
              onClick={this.onSubmit}
              style={{ 
                marginTop: "1em",
              }}
              disabled={!editRegistrationKey || editRegistrationKey.length === 0}
            >
              Prova igen
            </Button>            
          </Form>

          <p
            style={{
              marginTop: "3em",
              fontSize: '110%',
              textAlign: "center",
            }}
          >
            Fungerar det ändå inte så är du välkommen att kontakta <a href="mailto:support@agriopt.se">support@agriopt.se</a> så löser vi problemet.
          </p> 
        </div>
      </BasicPageLayout>
    };
    
    if (validRegKey === null) {
      pageData = <BasicPageLayout 
        imageSrc={Illustration}
        showLoader={true} 
        caption="Kontrollerar registreringsnyckel..."
      />;
    };

    return (
      <React.Fragment>
        <CreatingUserLoader open={showCreatingUserLoader} />
        {pageData}
      </React.Fragment> 
    )
  }
};

export default RegistrationV1Part1;