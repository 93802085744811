import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Header,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";

import LoaderModal from "@/components/modals/LoaderModal";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";

import IconWithPlate from "@/components/misc/IconWithPlate";
import imgYieldMap from "@/assets/icons/dataTypes/yield-data.svg";

import UploadYieldMapsMapPreviewPerFile from "./mapPreviewPerFile";
import UploadYieldMapsMapPreviewPerField from "./mapPreviewPerField";

import { isLoaded } from "react-redux-firebase";

class UploadYieldMapsVerifyFiles extends Component {

  state = {};

  getPlaceholder = (isDone=false) => (
    <Segment
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1em",
        paddingTop: "2em",
        paddingBottom: "2em",
        backgroundColor: isDone ? "#89A275" : null,
        color: isDone ? "white" : null,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "12em",
          marginTop: "auto",
          marginBottom: "auto",          
          overflowY: "hidden",
        }}
      >
        <IconWithPlate
          size="10em"
          src={imgYieldMap}
        />
      </div>

      <div
        style={{
          height: "100%",
          width: "calc(100% - 12em)",
          marginLeft: "2em",
          marginRight: "2em",
          marginTop: "auto",
          marginBottom: "auto",              
          whiteSpace: "pre-line",
        }}          
      >
        <Header 
          as="h1" 
          style={{
            marginBottom: 10,
            fontWeight: 500,
            color: isDone ? "white" : null,
          }}
        >
          {!isDone && "Bearbetar uppladdade skördekartor..."}
          {isDone && "Uppladdade skördekartor har bearbetats"}
        </Header>
        
        {isDone &&
          <p
            style={{
              width: "80%",
              whiteSpace: "pre-line",
              fontSize: "110%",
            }}
          >
            Freja är klar med dina uppladdade skördekartor och du kan strax se dem i vyerna "Utforska" och "Tolka".
          </p>
        }
        
        {!isDone &&
          <p
            style={{
              width: "80%",
              whiteSpace: "pre-line",
              fontSize: "110%",
            }}
          >
            Freja håller just nu på att bearbeta de skördekartorna som du har laddat upp.
            Du kan se vilka kartor som hittades bland de filerna som du laddade upp här nedanför.
          </p>
        }

        {!isDone &&
          <p
            style={{
              width: "80%",
              whiteSpace: "pre-line",
              fontSize: "110%",
            }}
          >
            Under tiden som Freja arbetar kan du använda appen som vanligt.
            Om mer information behövs kommer en notis att visas under "Min gård".
            Om du vill komma tillbaka hit för att se hur det går hittar du denna guide under "Samla" genom att klicka på gårdsnamnet.
          </p>
        }
      </div>
    </Segment>
  )

  getMapPreviewPerFile = (status) => {
    const {
      db,
      guideId,
    } = this.props;

    let fields = db && db.fields && db.fields;
    let fieldsInfo = db && db.fieldsInfo && db.fieldsInfo;
    let guideData = guideId && db && db.guides && db.guides[guideId];
    let uploadedFileIds = guideData && guideData.files_uploaded_ids;

    return uploadedFileIds && uploadedFileIds.map((id) => {
      let uploadedFileData = id && db && db.fieldsYieldMapsUploaded && db.fieldsYieldMapsUploaded[id];

      if (!uploadedFileData) {
        return <div />;
      };

      if (uploadedFileData.status && uploadedFileData.status !== status) {
        return <div />;
      };

      return (
        <UploadYieldMapsMapPreviewPerFile
          key={id}
          fileId={id}
          fields={fields}
          fieldsInfo={fieldsInfo}
          filesToRemove={this.props.filesToRemove}
          onProcessFileAgain={this.props.onProcessFileAgain}
          onRemoveUploadedFile={this.props.onRemoveUploadedFile}
          uploadedFileData={uploadedFileData}  
        />
      )
    });
  };

  getMapPreviewPerField = (status) => {
    const {
      db,
      guideId,
    } = this.props;

    let fields = db && db.fields && db.fields;
    let fieldsInfo = db && db.fieldsInfo && db.fieldsInfo;
    let guideData = guideId && db && db.guides && db.guides[guideId];
    let uploadedFileIds = guideData && guideData.files_uploaded_ids;
    console.log("uploadedFileIds", uploadedFileIds)

    let allFieldIds = fields && Object.keys(fields).map((x) => x);
    allFieldIds = allFieldIds && allFieldIds.sort((a, b) => {
      const textA = fields[a] && fields[a].name && fields[a].name.toUpperCase();
      const textB = fields[b] && fields[b].name && fields[b].name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return allFieldIds && allFieldIds.map((fieldId) => {  

      let filteredUploadedFileData = [];

      uploadedFileIds && uploadedFileIds.forEach((id) => {
        let uploadedFileData = id && db && db.fieldsYieldMapsUploaded && db.fieldsYieldMapsUploaded[id];
        
        if (!uploadedFileData) {
          return;
        };

        if (!uploadedFileData.status || uploadedFileData.status !== status) {
          return;
        };

        if (uploadedFileData && uploadedFileData.fields_in_file && uploadedFileData.fields_in_file.includes(fieldId)) {
          filteredUploadedFileData = [...filteredUploadedFileData, uploadedFileData];
        };
      });

      if (filteredUploadedFileData.length === 0) {
        return <div />;
      };

      return (
        <UploadYieldMapsMapPreviewPerField
          key={fieldId}
          fieldId={fieldId}
          fields={fields}
          fieldsInfo={fieldsInfo}
          uploadedFileData={filteredUploadedFileData}  
        />
      )    
    });    
  }

  render () {
    const {
      db,
      guideId,
    } = this.props;

    let fields = db && db.fields && db.fields;
    
    let guideData = guideId && db && db.guides && db.guides[guideId];
    let uploadedFileIds = guideData && guideData.files_uploaded_ids;
    let processingIsDone = true;
    
    uploadedFileIds && uploadedFileIds.forEach((id) => {
      if (!processingIsDone) {
        return;
      };

      let uploadedFileData = id && db && db.fieldsYieldMapsUploaded && db.fieldsYieldMapsUploaded[id];

      if (uploadedFileData && uploadedFileData.status && ['in_queue', 'running'].includes(uploadedFileData.status)) {
        processingIsDone = false;
      };
    });
    
    let menuItems = {
      next: [
        {
          caption: "Stäng guiden",
          onClick: processingIsDone ? () => this.props.onGuideDone() : () => this.props.onCloseGuide(),
        }
      ]
    };

    let mainColumn = 
      <React.Fragment>
        {this.getPlaceholder(processingIsDone)}
        {this.getMapPreviewPerFile("need_more_info")}
        {this.getMapPreviewPerFile("running")} 
        {this.getMapPreviewPerField("done")}
        {this.getMapPreviewPerFile("in_queue")}
        {this.getMapPreviewPerFile("error")}
      </React.Fragment>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h2"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Uppladdade filer
        </Header>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Till vänster ser du de skördekartor som Freja hittade bland de filerna som du laddat upp.
          När bearbetningen är klar kommer du kunna se vilka skiften, år och grödor som skördekartorna kopplades ihop med.
          Du kan även se skördekartor som inte kundes läsas in eller matchas mot något skifte på din gård.
        </p>   

        <Header
          as="h2"
          style={{
              marginTop: "1em",
              fontWeight: "normal",
              whiteSpace: "pre-line",
          }}
        >
          Mer information behövs
        </Header>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          I vissa fall behöver du fylla i mer information om exempelvis vilket år skördekartan skapades eller vilken gröda som odlades då.
        </p>

        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Klicka på knappen "Redigera" och fyll i informationen som saknas så fortsätter Freja sedan med bearbetningen av skördekartan.
        </p>


        <Header
          as="h2"
          style={{
              marginTop: "1em",
              fontWeight: "normal",
              whiteSpace: "pre-line",
          }}
        >
          Ladda upp fler filer
        </Header>
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
          Om du vill ladda upp skördekartor kan du klicka på knappen nedanför.
        </p>

        <Button
          icon
          fluid
          style={{
            marginTop: "1em",
            backgroundColor: "#6B9D9F",
            color: "white",                      
          }}
          labelPosition="left"
          onClick={() => this.props.history.push("/guidesUploadYieldMaps/uploadFiles")}
        >
          <Icon name="upload" />
          Ladda upp fler skördekartor
        </Button>     
    </React.Fragment>

    if (isLoaded(fields) && isLoaded(guideData)) {
      return (
        <GuidesBasicGrid
          params={this.props.params}
          slideDirection={this.props.slideDirection}
          mainColumn={mainColumn}
          helpColumn={helpColumn}
          showFullGrid={this.props.showFullGrid}
          toggleHelpTexts={this.props.toggleHelpTexts}
          menuItems={menuItems}
        />
      )
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      )
    }
  }
}

export default withRouter(UploadYieldMapsVerifyFiles);