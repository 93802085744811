import React, { Component } from "react";

import { 
  Segment,
  Header,
  Image,
  Label,
} from "semantic-ui-react";

import { COLLECT_GUIDES } from "@/constants/viewCollect";
import { CONTROL_GUIDES } from "@/constants/viewGuides";
import { APP_VERSION } from "@/constants/firebase";

import IconWithPlate from "@/components/misc/IconWithPlate";

const INITIAL_STATE = {
    selectedGuideId: null,
};

class GuidesAddGuideWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  getGuideCard = (guideInfo) => {
    const {
      selectedGuideId,
    } = this.state;

    return (
      <Segment
        key={`guideCard_` + guideInfo.name}
        className={(guideInfo.name !== selectedGuideId && !guideInfo.disabled) ? "backgroundColorOnHover" : null}
        onClick={() => !guideInfo.disabled && this.props.onAddGuide(guideInfo.name)}
        style={{
          backgroundColor: (guideInfo.disabled || guideInfo.name === selectedGuideId) ? "lightgrey" : "#ffffff",
          padding: "1em",
          marginBottom: "1em",
          cursor: guideInfo.disabled ? null : "pointer",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "25%", margin: "auto" }}>
          {guideInfo.cartoon &&
            <IconWithPlate 
              size="6em" 
              src={guideInfo.cartoon} 
              plateBackground={guideInfo.disabled ? "#d9d9d9" : null}
              filter={guideInfo.disabled ? "brightness(0%) invert(27%) sepia(37%) saturate(0%) hue-rotate(155deg) brightness(96%) contrast(5%)" : null}
            />
          }
                      
          {guideInfo.icon && 
            <Image
              style={{
                width: "6em",
                margin: "auto",
              }}
              src={guideInfo.icon} 
          />}
        </div>

        <div 
          style={{ 
            width: "75%", 
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.35em",
            }}
          >
            <Header
              style={{ 
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              {guideInfo.caption && guideInfo.caption}
            </Header>

            {guideInfo.showBetaLabel && guideInfo.showBetaLabel === true && 
              <Label 
                style={{ 
                  backgroundColor: "#89A275",
                  color: "white",
                }} 
              >
                Nyhet
              </Label>
            }

            {guideInfo.disabled && guideInfo.disabled === true && 
              <Label
                style={{ 
                  backgroundColor: "#6B9D9F",
                  color: "white",
                }} 
              >
                Kommer snart!
              </Label>
            }            
          </div>

          <p style={{whiteSpace: 'pre-line'}}>{guideInfo.description && guideInfo.description}</p>
        </div>
      </Segment>
    )
  };

  render() {
    const {
      userPlanType,
      dataType,
      caption,
      description,
      guideType,
    } = this.props;

    const GUIDES = dataType && (dataType === "guides" ? CONTROL_GUIDES : COLLECT_GUIDES);

    let guideCards = GUIDES && GUIDES.map((guide) => {
      if (guide && guide.hiddenInLive && guide.hiddenInLive === true && APP_VERSION === "live") {
        return <div />
      };

      if (guideType && guide.guideType !== guideType) {
        return <div />;
      };
      
      if (!guide.hidden && (guide.includedInPlans.includes(userPlanType) || guide.includedInPlans.includes('all'))) {
        return this.getGuideCard(guide);
      } else {
        return null
      };
    });

    return (
        <Segment
          style={{
            height: "100%",
            padding: "1.5em",
            overflowY: "auto",
          }}
        >
          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.25em",
            }}
          >
            <Header
              as="h3"
              style={{ 
                paddingTop: 0,
                marginBottom: 0,
                fontWeight: 500,
                color: "grey",
              }}
            >
              {caption && caption}
            </Header>
                          
          {/* <p
            style={{
              color: "#6B9D9F",
              fontSize: "95%",
              cursor: "pointer",
              fontWeight: 600,
            }}
            onClick={() => this.markNotificationsRead()}
          >
            Läs mer
          </p> */}
        </div>
        <p 
          style={{
            marginBottom: "1.5em",
          }}
        >
          {description && description}
        </p>

        <div
          style={{
            
          }}             
        >
          {guideCards && guideCards}
        </div>
      </Segment>
    );
  }
};

export default GuidesAddGuideWidget;
