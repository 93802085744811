import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";

export const groupLabelGenerator = ({layer, layerId, group, numberOfGroups, statJson}) => {

  // Get the data type of the current data layer (float, percent, integer, etc.)
  let dataLayer = getCurrentDataLayer(layer);
  
  let dataLayerType = dataLayer && dataLayer.dataType && dataLayer.dataType[layer];
  let dataHistogramLabels = dataLayer && dataLayer.histogramLabels && dataLayer.histogramLabels[layer.replace("_hotspot", "")];

  let isFloatLayer = dataLayerType === "float";
  let isHotspotLayer = dataLayerType === "hotspot";
  let isIntegerLayer = dataLayerType === "integer";
  let isPercentLayer = dataLayerType === "percent";
  let isDifferenceLayer = dataLayerType === "difference";

  let label, labelString;

  // Layers with fixed labels
  if (dataHistogramLabels && typeof(dataHistogramLabels) === "object") {
    if (isFloatLayer || isHotspotLayer || isDifferenceLayer) {
      if (group.idx === 0) {
        label = dataHistogramLabels['first'] ? dataHistogramLabels['first'] : null;
      } else if (group.idx === (numberOfGroups - 1)) {
        label = dataHistogramLabels['last'] ? dataHistogramLabels['last'] : null;
      } else if (label === 2) {
        label = dataHistogramLabels['middle'] ? dataHistogramLabels['middle'] : null;
      } else {
        label = null
      };
    };
  };

  // Layers with unit labels
  if (dataHistogramLabels && 
     (dataHistogramLabels.prefix || dataHistogramLabels.postfix || dataHistogramLabels.noDecimals)
  ) {
    if (isFloatLayer) {
      let noDecimals = typeof(dataHistogramLabels.noDecimals) === "number" ? dataHistogramLabels.noDecimals : 1;
      let prefix = dataHistogramLabels.prefix ? dataHistogramLabels.prefix : "";
      let postfix = dataHistogramLabels.postfix ? dataHistogramLabels.postfix : "";
    
      label = group.max ?
        prefix + group.min.toFixed(noDecimals) + " till " + prefix + group.max.toFixed(noDecimals) +  postfix :
        group.min ? prefix + group.min.toFixed(noDecimals) + postfix : "";
    };
  };

  // Layers with percent units
  if (isPercentLayer || isDifferenceLayer) {
    label = group.max
      ? group.min.toFixed(0) + "% till " + group.max.toFixed(0) + "%"
      : group.min ? group.min.toFixed(0) + "%" : "";
  };    

  // Layers with integer units (categories)
  if (isIntegerLayer && dataHistogramLabels) {
    labelString = typeof(group.idx) === "string" ? group.idx : group.idx.toFixed(0)
    label = labelString && dataHistogramLabels[labelString];
  };

  // Yield potential
  if (layer === "yieldAnalysis_potential") {
    let yieldPotentialStats = statJson && statJson.yield_potential && 
      statJson.yield_potential[layerId] && statJson.yield_potential[layerId];
    
    let upperCI = yieldPotentialStats && yieldPotentialStats.high_ci && yieldPotentialStats.high_ci; 
    let maxValue = upperCI && upperCI[group.idx];
    label = maxValue && ("Upp till " + maxValue.toFixed(1) + " ton/ha");
  }
  
  return label;
};
