import React, { Component } from "react";

import { 
  Image, 
  Segment,
  Input,
} from "semantic-ui-react";

import FieldCartoon from "@/components/misc/FieldCartoon";

import ImageHectar from "@/assets/icons/hectar.svg"

class FieldDataUnlockFieldList extends Component {

  getFieldCards = (fieldId) => {
    const {
      db,
      fieldsToUnlockNames,
    } = this.props;

    let field = db && db.fields &&  db.fields[fieldId] && db.fields[fieldId];
    let position = field && field.position;
        
    let name = fieldId && fieldsToUnlockNames && fieldsToUnlockNames[fieldId];

    let size = field && field.field_size && field.field_size;
    size = size && size.toFixed(1) + " ha"
    
    let cartoonColor = "#e0ba8c";
    let backgroundColor = "#ffffff"

    return (
      <React.Fragment key={`fieldData_unlockFields_${fieldId}`}>
        <Segment
          style={{
            backgroundColor: backgroundColor,
            padding: "15px",
            paddingTop: 5,
            paddingRight: 12,
            paddingBottom: 5,
            marginTop: 10,
            marginBottom: 0,
            height: "100px"
          }}
        >
          <div
            style={{
              display: "flex", 
            }}
          >
            <div>
              {position && 
                <FieldCartoon
                  className="fieldCartoon"
                  style={{
                    margin: 0,
                    padding: 0,
                    marginTop: "15px",
                    height: "60px",
                    width: "60px",
                    zIndex: 20,
                  }}            
                  color={cartoonColor}
                  position={position}
                />
              }    
            </div>

            <div
              style={{
                marginLeft: 20,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Input
                value={name}
                name={fieldId}
                onChange={(event, data) => this.props.changeFieldsToUnlockNames(fieldId, data.value)}
                style={{
                  marginTop: 15,
                  marginBottom: 10,
                  width: "24em",
                }}
              />

              <p 
                style={{ 
                  marginBottom: 10, 
                  color: "grey", 
                }}
              >
                <Image 
                  inline 
                  src={ImageHectar} 
                  style={{ 
                    width: "15px", 
                  }} 
                /> 
                {"  " + size}
              </p>
          </div>
        </div>
        </Segment>
    </React.Fragment>
    )
  };

  render() {
    const {
      fieldIds,
    } = this.props;

    let fieldItems = fieldIds && fieldIds.map((x) => this.getFieldCards(x));

    return (
      <div
        style={{
          paddingTop: "-10px"
        }}      
      >
        {fieldItems && fieldItems}
      </div>
    );
  }
}

export default FieldDataUnlockFieldList;
