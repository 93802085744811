import React, { Component } from "react";

import { 
  Header, 
  Dropdown,
  Table,
  Segment,
} from "semantic-ui-react";

import { NUTRIENTS_TO_SKIP, NUTRIENTS_SHOW_IN_SUMMARY } from "@/constants/fertilizers";
import { NUTRIENT_FERTILIZERS } from "@/constants/fertilizers";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

const INITIAL_STATE = {};

class PlanNewRation extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    this.setDefaultSettings("updateYield");
  };

  onClickNextStep = () => {
    const {
      completedStepsPlanNewRation,
    } = this.props;

    this.props.setParentState({
      openSegmentPlanNewRation: '',
      completedStepsPlanNewRation: [...completedStepsPlanNewRation, "previousRation", "plan"],
    });
    
    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment, data=null, forceUpdate=false) => {
    const {
      db,
      guideName,
    } = this.props;

    switch(newSegment) {

      case "compound":
        let previousCompound = db && db.settings && db.settings.guides;
        previousCompound = previousCompound && previousCompound[guideName];
        previousCompound = previousCompound && previousCompound.compoundType;
        previousCompound = previousCompound ? previousCompound : "none";

        previousCompound && this.props.setParentState({ guideCompoundType: previousCompound});
        break;


      case "plan":
        break;

      default:
        break;
    };
  };

  onChange = (data, onlyNumbers=true) => {
    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({ [data.name]: value });
  };

  setOpenSegment = (newSegment) => {
    const {
      openSegmentPlanNewRation
    } = this.props;

    (openSegmentPlanNewRation === newSegment) && this.props.setParentState({ openSegmentPlanNewRation: null });
    (openSegmentPlanNewRation !== newSegment) && this.props.setParentState({ openSegmentPlanNewRation: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedStepsPlanNewRation, 
    } = this.props;

    !completedStepsPlanNewRation.includes(currentSegment) && this.props.setParentState({
      completedStepsPlanNewRation: [...completedStepsPlanNewRation, currentSegment],
    });

    this.setDefaultSettings(newSegment);
    !completedStepsPlanNewRation.includes(newSegment) && this.setOpenSegment(newSegment);
    completedStepsPlanNewRation.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  onChangeDropDown = (d, variableName) => {
    // this.props.setParentState({ [variableName]: d.value });

    // if (variableName === "guideCompoundType") {
    //   let currentFertilizer = NUTRIENT_FERTILIZERS.find((x) => x.value === d.value);
    //   let guideCompoundNutrientRation = currentFertilizer ? currentFertilizer.nutrients.N : '100'

    //   this.props.setParentState({ guideCompoundNutrientRation });
    // }
  };

  onComputePreviousRation = (variableName, value) => {
    const {
      guidePreviousRationCompoundType,
      guidePreviousRationCompoundWeight,
    } = this.props;

    if (variableName === "guidePreviousRationCompoundWeight") {
      let newWeight = value && value.replace(/[^\d,.]/g, '');
      newWeight = newWeight && newWeight.replace(",", ".");

      let currentFertilizer = NUTRIENT_FERTILIZERS.find((x) => x.value === guidePreviousRationCompoundType);
      let guideCompoundNutrientRation = currentFertilizer ? currentFertilizer.nutrients.N : '100';

      this.props.setParentState({
        guidePreviousRationCompoundWeight: newWeight,
        guidePreviousRation: (parseFloat(value) * (parseFloat(guideCompoundNutrientRation) / 100.0)).toFixed(0),
      });
    };

    if (variableName === "guidePreviousRationCompoundType") {
      let currentFertilizer = NUTRIENT_FERTILIZERS.find((x) => x.value === value);
      let guideCompoundNutrientRation = currentFertilizer ? currentFertilizer.nutrients.N : '100';

      this.props.setParentState({
        guidePreviousRationCompoundType: value,
        guidePreviousRation: (parseFloat(guidePreviousRationCompoundWeight) * (parseFloat(guideCompoundNutrientRation) / 100.0)).toFixed(0),
      });
    };
  };

  render() {

    const {
      db,
      guideCompoundType,
      guideName,
      openSegmentPlanNewRation,
      completedStepsPlanNewRation,
    } = this.props;
    
    let menuItems = {
      // previous: [
      //   {
      //     caption: "Tillbaka",
      //     onClick: this.props.onClickPreviousStep,
      //   }
      // ],      
      next: [
        {
          caption: "Gå vidare",
          onClick: this.onClickNextStep,
        }
      ],
    };

    let previousCompound = db && db.settings && db.settings.guides;
    previousCompound = previousCompound && previousCompound[guideName];
    previousCompound = previousCompound && previousCompound.compoundType;
    previousCompound = previousCompound ? previousCompound : "none";

    let currentFertilizer = guideCompoundType && NUTRIENT_FERTILIZERS.find((x) => x.value === guideCompoundType);
    let currentFertilizerNutrients = currentFertilizer && currentFertilizer.nutrients;

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >

        <Segment>
          Inställningar
        </Segment>

        <Segment>
          Gödslingen hittills
        </Segment>

        <GuideSegment
          caption="Förväntad skörd"
          simpleModeQuestion="Vill du uppdatera din förväntade skörd?"
          open={openSegmentPlanNewRation === "updateYield"}
          complete={completedStepsPlanNewRation && completedStepsPlanNewRation.includes("updateYield")}
          onClickNextStep={() => this.gotoNextStep("updateYield", "compound")}      
          onClickOpenSegment={() => this.setOpenSegment("updateYield")}
        />

        <GuideSegment
          caption="Gödslingsmedel"
          open={openSegmentPlanNewRation === "compound"}
          simpleModeQuestion="Vill du ange gödslingsmedel?"
          simpleModeDescription="Väljer du nej så beräknas styrfilen i kg N/ha och du får själv ställa in kvävehalten i terminalen."
          simpleModeOnSkip={() => {
            this.gotoNextStep("compound", "plan"); 
            this.props.setParentState({ guideCompoundType: 'none' });
          }}          
          complete={completedStepsPlanNewRation && completedStepsPlanNewRation.includes("compound")}
          onClickOpenSegment={() => this.setOpenSegment("compound")}
          onClickNextStep={() => this.onClickNextStep()}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: 15,
              width: "80%",
            }}
          >
            Välj den typ av gödslingsmedel som du kommer att använda i din kvävegödning för denna giva.
            <strong> Du kan ändra detta senare när du justerar styrfilsförslag eller skapar styrfiler för ytterligare kompletteringsgivor.</strong>
          </p>

          <Dropdown
            fluid
            selection
            options={[
              ...NUTRIENT_FERTILIZERS, 
              { key: 'none', value: 'none', text: "Rent kväve" },
            ]}
            onChange={(e, d) => this.onChangeDropDown(d, "guideCompoundType")}
            value={guideCompoundType ? guideCompoundType : previousCompound}
          />

          {currentFertilizerNutrients && Object.keys(currentFertilizerNutrients).length > 0 &&
            <React.Fragment>
              <Header
                as="h3"
                style={{
                  fontWeight: 500,
                }}
              >
                Näringsinnehåll
              </Header>

              <Table 
                stackable
                celled 
                size='small' 
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <Table.Header>
                  <Table.Row>
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || !NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div/>
                      };
                      return <Table.HeaderCell key={"tableHeader" + x} textAlign="center">{x}</Table.HeaderCell>
                    })}
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div/>
                      };
                      return <Table.HeaderCell key={"tableHeader" + x} textAlign="center">{x}</Table.HeaderCell>
                    })}                  
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || !NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div />
                      };
                      return <Table.Cell key={"tableCell" + x} textAlign="center">{currentFertilizerNutrients[x]} %</Table.Cell>
                    })}
                    {Object.keys(currentFertilizerNutrients).map((x) => {
                      if (currentFertilizerNutrients[x] <= 0.0 || NUTRIENTS_TO_SKIP.includes(x) || NUTRIENTS_SHOW_IN_SUMMARY.includes(x)) {
                        return <div />
                      };
                      return <Table.Cell key={"tableCell" + x} textAlign="center">{currentFertilizerNutrients[x]} %</Table.Cell>
                    })}
                  </Table.Row>
                </Table.Body>
              </Table>
            </React.Fragment>
          }
        </GuideSegment>          
      </div>

    let helpColumn = <React.Fragment></React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        guideSubMenuActive={this.props.guideSubMenuActive}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader="Planera giva"
        mainColumnBody="Här kan du uppdatera och justera din nästa kompletteringsgiva."
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default PlanNewRation;