import React, { Component } from "react";

import { 
  Header, 
  Table,
  Input,
  Button,
} from "semantic-ui-react";

import GuidesBasicGrid from "@/components/guideData/GuidesBasicGrid";
import GuideSegment from "@/components/guide/GuideSegment";

import GuideSegmentAdvancedMode from "@/components/guide/GuideSegmentAdvancedMode";
import GuideSegmentCompound from "@/components/guide/GuideSegmentCompound";
import GuideSegmentSplitRation from "@/components/guide/GuideSegmentSplitRation";

import { MUD_TYPES } from "./constants";

const INITIAL_STATE = {};

class NitrogenNeed extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickNextStep = () => {
    const {
      completedSteps,
    } = this.props;

    this.setDefaultSettings("need");

    this.props.setParentState({
      completedSteps: [...completedSteps, "split"],
    });

    this.props.onClickNextStep();
  };

  setDefaultSettings = (newSegment, forceUpdate=false) => {
    const {
      limePerMudType
    } = this.props;

    switch(newSegment) {
      case "need":
        let defaultLimePerMudType = {};

        MUD_TYPES && MUD_TYPES.forEach((x) => {
          defaultLimePerMudType = {...defaultLimePerMudType, [x.key]: x.defaultValue};
        });

        !forceUpdate && this.props.setParentState({ limePerMudType: {...defaultLimePerMudType, ...limePerMudType }});
        forceUpdate && this.props.setParentState({ limePerMudType: {...defaultLimePerMudType }});
        break; 

      default:
        break;
    }
  };

  onChange = (e, data, onlyNumbers=true) => {
    let currentValue = this.props.limePerMudType;

    let value = data.value;
    value = onlyNumbers ? value && value.replace(/[^\d,.]/g, '') : value;
    value = value && value.replace(",", ".");

    this.props.setParentState({
      limePerMudType: {...currentValue, [data.name]: value },
    });
  };

  onChangeSlider = (rationSize) => {
    this.props.setParentState({
      rationSize: rationSize,
    });
  };

  setOpenSegment = (newSegment) => {
    (this.props.openSegment === newSegment) && this.props.setParentState({ openSegment: null });
    (this.props.openSegment !== newSegment) && this.props.setParentState({ openSegment: newSegment });
  };

  gotoNextStep = (currentSegment, newSegment) => {
    const {
      completedSteps, 
    } = this.props;

    !completedSteps.includes(currentSegment) && this.props.setParentState({
      completedSteps: [...completedSteps, currentSegment],
    });

    this.setDefaultSettings(newSegment, false);
    !completedSteps.includes(newSegment) && this.setOpenSegment(newSegment);
    completedSteps.includes(newSegment) && this.setOpenSegment(currentSegment);
  };

  render() {
    
    const {
      guideMode,
      rationSize,
      limePerMudType,
      openSegment,
      completedSteps,
    } = this.props;

    let menuItems = {
      previous: [
        {
          caption: "Tillbaka",
          onClick: this.props.onClickPreviousStep,
        }
      ],      
      next: [
        {
          caption: "Gå vidare",
          hidden: completedSteps.length < 3,
          onClick: this.onClickNextStep,
        }
      ],
    };

    let mainColumn =
      <div
        style={{
          width: "67%",
          paddingBottom: "4em",
        }}
      >
        <GuideSegmentAdvancedMode
          open={openSegment === "guideMode"}
          disabled={openSegment !== "guideMode" && !completedSteps.includes("guideMode")}
          complete={completedSteps && completedSteps.includes("guideMode")}
          setParentState={this.props.setParentState}
          onClickOpenSegment={() => this.setOpenSegment("guideMode")}
          onClickNextStep={() => this.gotoNextStep("guideMode", "compound")}
        />

        <GuideSegmentCompound
          {...this.props}
          open={openSegment === "compound"}
          disabled={openSegment !== "compound" && !completedSteps.includes("compound")}
          complete={completedSteps && completedSteps.includes("compound")}
          onClickOpenSegment={() => this.setOpenSegment("compound")}
          onClickNextStep={() => this.gotoNextStep("compound", "need")}
          caption="Kalkningsmedel"
          question="Vill du ange kalkningsmedel?"
          description="Välj den typ av kalkningsmedel som du kommer att använda i din strukturkalkning."          
          compoundName="kalcium"
          compoundType="Ca"
        />
        
        <GuideSegment
          skipSimpleMode={guideMode && guideMode === "advanced"}
          caption="Kalkningsbehov"
          simpleModeQuestion="Vill du göra förändringar i Jordbruksverkets rekommendationer för kalkningsbehovet?"
          simpleModeOpenText="Justera"
          open={openSegment === "need"}
          complete={completedSteps && completedSteps.includes("need")}
          onClickOpenSegment={() => this.setOpenSegment("need")}
          onClickNextStep={() => this.gotoNextStep("need", "split")}
          simpleModeOnSkip={() => {
            this.gotoNextStep("need", "split") 
            this.setDefaultSettings("need", true);
          }}
          disableNextStep={MUD_TYPES && MUD_TYPES.find((x) => !limePerMudType[x.key] || limePerMudType[x.key].length === 0)}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              marginBottom: "4em",
              width: "80%",
              fontSize: "110%",
            }}
          >
            Freja använder Jordbruksverkets rekommendationer för strukturkalkning. Om du vill så kan du justera givorna vid olika lerhalter i tabellen nedanför.
          </p>          
          
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Jordtyp</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Lerhalt</Table.HeaderCell>
                <Table.HeaderCell>Kalkbehov (kg Ca/ha)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {MUD_TYPES && MUD_TYPES.map((x) => (
                <Table.Row
                  key={"tableRow_" + x.key}
                >
                  <Table.Cell>{x.caption}</Table.Cell>
                  <Table.Cell textAlign="center">{x.min.toFixed(0) + " - " + x.max.toFixed(0) + "%"}</Table.Cell>
                  <Table.Cell>
                    <Input 
                      fluid 
                      value={limePerMudType[x.key]} 
                      name={x.key}
                      onChange={(e, d) => this.onChange(e, d)}
                      error={!limePerMudType[x.key] || limePerMudType[x.key].length === 0}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </GuideSegment>

        <GuideSegmentSplitRation
          guideMode={guideMode}
          simpleModeQuestion="Vill du dela upp din strukturkalkning i flera omgångar?"
          simpleModeDescription='Välj "Ange" om du vill dela upp kalkningen i flera givor.'
          open={openSegment === "split"}
          complete={completedSteps && completedSteps.includes("split")}
          onClickOpenSegment={() => this.setOpenSegment("split")}
          onClickNextStep={() => this.onClickNextStep()}  
          setParentState={this.props.setParentState}
          rationSize={rationSize}
        />
      </div>

    let helpColumn = 
      <React.Fragment>
        <Header
          as="h3"
          style={{
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Vad baseras styrfilen på?
        </Header>
        
        <p
          style={{
            fontWeight: "normal",
            fontSize: "110%",
            whiteSpace: "pre-line",
          }}
        >
         Frejas styrfilsförslag bygger på välkända riktlinjer från Jordbruksverket som baseras på omfattade växtodlingsförsök. Läs mer genom att klicka på knapparna nedanför.
        </p> 

        <Button 
          fluid
          primary
          href="https://jordbruksverket.se/vaxter/odling/vaxtnaring/kalkning"
          target="_blank" 
          rel="noopener noreferrer"
          style={{
            marginTop: "1em",
            backgroundColor: "#BF8136",
            color: "white",
          }}          
        >
          Jordbruksverkets rekommendationer
        </Button>

        <Button
          style={{
            marginTop: 5,
            backgroundColor: "#BF8136",
            color: "white",
          }}
          fluid
          primary
          href="http://greppa.nu/atgarder/kalka-for-battre-markstruktur.html?I_praktiken=Markf%C3%B6rb%C3%A4ttra"
          target="_blank" 
          rel="noopener noreferrer"
        >
          Greppa näringens rekommendationer
        </Button>               
      </React.Fragment>

    return (
      <GuidesBasicGrid
        params={this.props.params}
        slideDirection={this.props.slideDirection}
        mainColumn={mainColumn}
        helpColumn={helpColumn}
        mainColumnHeader="Uppskatta kalkningsbehovet"
        mainColumnBody="För att kunna beräkna kalkningsbehovet behöver du fylla i informationen nedanför. Svara på alla frågor för att gå vidare till nästa steg."
        showFullGrid={this.props.showFullGrid}
        toggleHelpTexts={this.props.toggleHelpTexts}
        currentMenuItem={this.props.currentMenuItem}
        menuItems={menuItems}
      />        
    )
  }
}

export default NitrogenNeed;