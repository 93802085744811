import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Segment,
  Confirm,
  Form,
  Button,
  Dropdown,
  Image,
  Icon,
  Modal,
  Loader,
} from "semantic-ui-react";

import moment from 'moment';
import 'moment/locale/sv';

import { getSafeFileName } from "@/helpers/dataHandling";

import { cloudFunctions } from "@";

const INITIAL_STATE = {
  selectedUserId: '',
  showConfirmDeleteMessage: false,
  messageIdToBeDeleted: null,
  messagesRead: [],
  messagesDeleted: [],
  noMessagesToShow: 10,
  newMessageText: '',
  imageToUpload: null,
  fullscreenImageUrl: null,
  errorMessage: null,
  isSavingMessage: false,
};

class MenuMessages extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  fileInputRef = React.createRef();
  messagesEnd = React.createRef();

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.selectedUserId && this.props.db && prevProps.db && this.props.db.messages !== prevProps.db.messages) {
      this.scrollToBottom("smooth");
      this.markMessagesRead(this.state.selectedUserId);
    };

    if (this.state.selectedUserId !== prevState.selectedUserId) {
      this.scrollToBottom("auto");
    };

    if (this.state.selectedUserId && this.state !== prevState) {
      this.state && Object.keys(this.state)
        .filter((x) => x.includes("imageData_"))
        .forEach((x) => {
          if (!prevState[x] || this.state[x] !== prevState[x]) {
            this.scrollToBottom("auto");
          };
        });
    };
  };

  scrollToBottom = (behavior) => {
    this.messagesEnd && this.messagesEnd.scrollIntoView && this.messagesEnd.scrollIntoView({ behavior });
  };

  fileChange = e => {
    if (!e.target.files || e.target.files.length === 0) {
      this.setState({
        errorMessage: "Inget bild hittades."
      });

      return;
    };

    this.setState({ imageToUpload: e.target.files[0] });

    const reader = new FileReader();

    reader.addEventListener("load", () => {
      this.setState({
        imageData: reader.result,
      });
    }, false)

    reader.readAsDataURL(e.target.files[0])
  };

  markMessageRead = (id, currentValue) => {
    const {
      getUser,
    } = this.props;

    // let currentValue = db && id && db.messages && db.messages[id] && db.messages[id].seen;
    // currentValue = typeof(currentValue) === "boolean" ? currentValue : false;

    let dbRef = getUser().collection("messages").doc(id);
    dbRef.update({ seen: !currentValue });

    let currentMessagesRead = this.state.messagesRead;

    if (currentMessagesRead.includes(id)) {
      this.setState({ messagesRead: currentMessagesRead.filter((x) => x !== id) });
    } else {
      this.setState({ messagesRead: [...currentMessagesRead, id] });
    };
  };

  toggleDeleteMessage = (id) => {
    this.props.toggleShowMultipleFieldsModal && this.props.toggleShowMultipleFieldsModal();
    let currentValue = this.state.showConfirmDeleteMessage;

    this.setState({
      showConfirmDeleteMessage: !currentValue,
      messageIdToBeDeleted: id ? id : null,
    })
  };

  deleteMessage = () => {
    const {
      getUser,
    } = this.props;

    let id = this.state.messageIdToBeDeleted;
    getUser().collection("messages").doc(id).delete();

    let currentValue = this.state.messagesDeleted;
    this.setState({ messagesDeleted: [...currentValue, id] });
    this.toggleDeleteMessage()
  };

  markMessagesRead = (newSelectedUserId) => {
    const {
      db,
      getUser,
      trueUserId,
    } = this.props;

    const {
      selectedUserId,
    } = this.state;

    let fromUserId = newSelectedUserId ? newSelectedUserId : selectedUserId;

    let messages = db && db.messages && db.messages;
    messages = messages && Object.keys(messages).map(x => ({ id: x, ...db.messages[x] }));
    messages = messages && messages.filter((x) => x.toUserId === trueUserId);
    messages = messages && messages.filter((x) => !fromUserId || x.fromUserId === fromUserId);
    messages = messages && messages.filter((x) => !x.seen);

    messages && messages.forEach((x) => {
      let dbRef = getUser().collection("messages").doc(x.id);
      dbRef.update({ seen: true });
    });
  };

  saveNewMessage = () => {
    const {
      trueUserId,
      fileBucketRef,
    } = this.props;

    const {
      imageToUpload,
      newMessageText,
      selectedUserId,
    } = this.state;

    if (!newMessageText || newMessageText.length === 0) {
      return;
    };

    let allPromises = [];
    let filePathFrom = '';
    let filePathTo = '';

    this.setState({
      errorMessage: null,
      isSavingMessage: true,
    });

    if (imageToUpload) {
      let safeFileName = getSafeFileName(imageToUpload.name);

      filePathFrom = "messages/" + trueUserId + "/photos/" + Date.now() + "_" + safeFileName;
      filePathTo = "messages/" + selectedUserId + "/photos/" + Date.now() + "_" + safeFileName;

      allPromises.push(fileBucketRef.child(filePathFrom).put(imageToUpload));
      allPromises.push(fileBucketRef.child(filePathTo).put(imageToUpload));
    };

    let payloadFrom = {
      message: newMessageText,
      date: new Date(),
      fromUserId: trueUserId,
      toUserId: selectedUserId,
      imageFilePath: filePathFrom,
      seen: true,
    };

    allPromises.push(this.props.getUser().collection("messages").add(payloadFrom))

    let payloadTo = {
      message: newMessageText,
      date: new Date(),
      fromUserId: trueUserId,
      toUserId: selectedUserId,
      imageFilePath: filePathTo,
      seen: false,
    };

    allPromises.push(cloudFunctions.httpsCallable('createCopyOfNewUserToUserMessage')(
      {
        userId: trueUserId,
        dbDoc: payloadTo,
      }
    ));

    Promise.all(allPromises)
      .then(() => {
        this.setState({
          newMessageText: '',
          imageToUpload: null,
          imageData: null,
          errorMessage: null,
          isSavingMessage: false,
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.toString(),
          isSavingMessage: false,
        });
      });
  };

  getImageUrl = (id, filePath) => {
    let fileBucketRef = this.props.fileBucketRef;

    if (!this.state['imageData_' + id]) {
      fileBucketRef && fileBucketRef.child(filePath).getDownloadURL()
        .then(url => {
          this.setState({ ['imageData_' + id]: url });
        });
    };
  };

  getUserSegment = (userData, messages) => {
    let latestMessage = messages && messages.filter((x) => x.fromUserId === userData.id || x.toUserId === userData.id);
    latestMessage = latestMessage && latestMessage.length > 0 ? latestMessage[latestMessage.length - 1] : '';

    return (
      <React.Fragment
        key={`userSegment${userData.id}`}
      >
        <Segment
          basic
          className="fieldCardOnHover"
          style={{
            padding: "0.75em",
            margin: "1em",
            marginTop: 0,
            backgroundColor: "#f6f6f6",
            borderRadius: 5,
            width: "calc(100%-6em)",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={() => {
            this.markMessagesRead(userData.id);
            this.setState({ selectedUserId: userData.id });
          }}
        >
          <Icon
            style={{
              marginRight: "1em",
              marginTop: "auto",
              marginBottom: "auto",
            }}
            name="user"
            size="large"
          />

          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                whiteSpace: "normal",
              }}
            >
              {userData && userData.name ? userData.name : "Okänd användare"}
              <span style={{ fontSize: "80%" }}>
                {latestMessage && latestMessage.date && moment(latestMessage.date.toDate()).fromNow()}
              </span>
            </div>

            <span
              style={{
                fontSize: "80%",
                fontStyle: "italic",
              }}
            >
              {latestMessage && latestMessage.message}
              {(!latestMessage || !latestMessage.message) && "Klicka här för att skicka ett första meddelande"}
            </span>
          </div>
        </Segment>
      </React.Fragment>
    )
  };

  getMessageSegment = (data) => {
    const {
      trueUserId,
    } = this.props;

    const {
      messagesDeleted,
    } = this.state;

    let id = data && data.id;
    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();
    let message = data && data.message;
    let imageFilePath = data && data.imageFilePath;

    let fromUserId = data && data.fromUserId;
    let isWrittenByCurrentUser = fromUserId === trueUserId;

    imageFilePath && this.getImageUrl(id, imageFilePath);

    if (messagesDeleted && messagesDeleted.includes(id)) {
      return;
    };

    return (
      <React.Fragment
        key={id}
      >
        <Segment
          basic
          style={{
            margin: 7,
            padding: 10,
            marginBottom: 3,
            backgroundColor: isWrittenByCurrentUser ? "#f6f6f6" : "#6B9D9F",
            color: isWrittenByCurrentUser ? null : "white",
            borderRadius: 5,
            marginLeft: isWrittenByCurrentUser ? "5em" : "1em",
            marginRight: isWrittenByCurrentUser ? "1em" : "5em",
            width: "calc(100%-6em)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              {imageFilePath && !this.state['imageData_' + id] && <Loader active content='Hämtar bild...' />}
              {imageFilePath && this.state['imageData_' + id] &&
                <Image
                  style={{
                    marginBottom: 10,
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => this.toggleFullScreenImage(this.state['imageData_' + id])}
                  src={this.state['imageData_' + id]}
                />
              }

              <div
                style={{
                  marginBottom: 0,
                  width: "100%",
                  whiteSpace: "normal",
                }}
              >
                {message}
              </div>
            </div>

            <div
              style={{
                width: "1em",
                marginLeft: "1em",
                fontSize: "80%"
              }}
            >
              <Dropdown
                icon='ellipsis vertical'
                floating
                direction="left"
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon='trash'
                    text='Ta bort'
                    onClick={() => this.toggleDeleteMessage(id)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div
            style={{
              color: isWrittenByCurrentUser ? "grey" : "white",
              fontSize: "80%",
              width: "100%",
              textAlign: "right",
            }}
          >
            {date && date}
          </div>
        </Segment>

      </React.Fragment>
    )
  };

  toggleFullScreenImage = (url) => {
    this.props.toggleShowMultipleFieldsModal && this.props.toggleShowMultipleFieldsModal();

    this.setState({
      fullscreenImageUrl: url ? url : '',
    });
  };

  getNewImageSegment = () => {
    const {
      imageData,
    } = this.state;

    let isWrittenByCurrentUser = true;

    return (
      <Segment
        basic
        style={{
          margin: 7,
          padding: 10,
          marginBottom: 3,
          backgroundColor: isWrittenByCurrentUser ? "#f6f6f6" : "#6B9D9F",
          color: isWrittenByCurrentUser ? null : "white",
          borderRadius: 5,
          marginLeft: isWrittenByCurrentUser ? "5em" : "1em",
          marginRight: isWrittenByCurrentUser ? "1em" : "5em",
          width: "calc(100%-6em)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >

          <div
            style={{
              marginBottom: 0,
              marginLeft: 10,
              width: "90%",
              whiteSpace: "pre-line",
            }}
          >
            <Image src={imageData} />
          </div>

          <div
            style={{
              width: "1em",
              fontSize: "80%"
            }}
          >
            <Icon
              className="iconButton"
              onClick={() => {
                this.setState({
                  autoFocusMessage: false,
                  imageData: null,
                  imageToUpload: null,
                })
              }}
              name="close"
            />
          </div>
        </div>
      </Segment>
    )
  };

  getEmptySegment = () => {
    return (
      <Segment
        basic
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            fontWeight: 500,
            marginBottom: 0,
            textAlign: "center",
            color: "grey",
            marginTop: "40%",
            marginLeft: "15%",
            width: "70%",
            whiteSpace: "pre-line",
          }}
        >
          Freja hittade inga meddelanden.
          Skriv ett första i fältet nedanför!
        </div>
      </Segment>
    )
  };

  onChange = (event) => this.setState({ [event.target.name]: event.target.value });

  render() {

    const {
      db,
      widget,
    } = this.props;

    const {
      imageData,
      newMessageText,
      showConfirmDeleteMessage,
      fullscreenImageUrl,
      errorMessage,
      isSavingMessage,
      selectedUserId,
    } = this.state;

    let dbAdvisors = db && db.settings && db.settings.advisors;
    let dbAdvisorClients = db && db.settings && db.settings.advisorClients;

    let userList = dbAdvisors && Object.keys(dbAdvisors) ? Object.keys(dbAdvisors).map((x) => ({ ...dbAdvisors[x], id: x })) : [];
    userList = dbAdvisorClients && Object.keys(dbAdvisorClients) ? [...userList, ...Object.keys(dbAdvisorClients).map((x) => ({ ...dbAdvisorClients[x], id: x }))] : userList;
    userList = userList && userList.sort((a, b) => a.name < b.name);

    let messages = db && db.messages && db.messages;
    messages = messages && Object.keys(messages).map(x => ({ id: x, ...db.messages[x] }));
    messages = messages && messages.filter((x) => !selectedUserId || (x.fromUserId === selectedUserId || x.toUserId === selectedUserId));
    messages = messages && messages.sort((a, b) => a.date - b.date);

    let messageSegments = messages && messages.map((x) => this.getMessageSegment(x));
    messageSegments = messageSegments && messageSegments.filter((x) => x !== null);
    messageSegments = messageSegments && messageSegments.length > 0 ? messageSegments : this.getEmptySegment();

    let userSegments = !selectedUserId && userList && userList.map((x) => this.getUserSegment(x, messages));
    let selectedUserFarmName = selectedUserId && userList.find((x) => x.id === selectedUserId);
    selectedUserFarmName = selectedUserFarmName && selectedUserFarmName.name;

    return (
      <React.Fragment>
        <Modal
          basic
          open={fullscreenImageUrl}
          size="fullscreen"
          onClose={() => this.toggleFullScreenImage()}
        >
          <Image centered style={{ maxHeight: "75vh", maxWidth: "75vw" }} src={fullscreenImageUrl} />
        </Modal>

        <Confirm
          open={showConfirmDeleteMessage}
          header="Vill du ta bort meddelandet?"
          content="Denna handling går inte att ångra."
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.deleteMessage()}
          onCancel={() => this.toggleDeleteMessage()}
          size="tiny"
        />

        <Segment
          style={{
            padding: widget ? "0.75em" : 0,
            border: "0.5px lightgrey solid",
            borderRadius: 5,
            borderColor: widget ? "transparent" : null,
            boxShadow: widget ? "none" : null,
            // backgroundColor: "#f7f4f0"
            width: widget ? "100%" : "25vw",
            height: widget ? "100%" : null,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 0,
              padding: "1em",
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                fontSize: "110%",
              }}
            >
              Meddelanden
              {selectedUserFarmName && ": " + selectedUserFarmName}
            </p>

            {selectedUserId &&
              <div
                style={{
                  display: "inline-block important",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 10,
                }}
                className="textLink"
                onClick={() => {
                  this.markMessagesRead();
                  this.setState({ selectedUserId: null });
                }}
              >
                Adressbok
              </div>
            }
          </div>

          <Segment.Group
            style={{
              border: "none",
              boxShadow: "none",
              marginTop: 10,
              height: widget ? null : "50vh",
              width: "100%",
              overflowY: "auto",
            }}
          >
            {!selectedUserId && userSegments && userSegments}
            {selectedUserId && messageSegments && messageSegments}
            {selectedUserId && imageData && this.getNewImageSegment()}

            {errorMessage &&
              <div
                style={{
                  width: "100%",
                  paddingLeft: "4em",
                  paddingRight: "1em",
                  color: "#C36126",
                  textAlign: "right",
                  whiteSpace: "pre-line",
                }}
              >
                Något gick tyvärr fel, prova gärna igen. Felmeddelande: {errorMessage}
              </div>
            }

            <div
              style={{
                float: "left",
                clear: "both"
              }}
              ref={(el) => { this.messagesEnd = el; }}
            />
          </Segment.Group>

          {selectedUserId &&
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0.5em",
              }}
            >
              <Button
                basic
                icon="attach"
                disabled={isSavingMessage}
                onClick={() => this.fileInputRef.current.click()}
              />

              <input
                ref={this.fileInputRef}
                type="file"
                accept="image/*"
                hidden
                onChange={this.fileChange}
              />

              <Form
                style={{
                  width: "100%",
                }}
                onSubmit={() => this.saveNewMessage()}
              >
                <Form.Input
                  autoFocus
                  placeholder="Skriv ditt meddelande här..."
                  value={newMessageText}
                  name="newMessageText"
                  onChange={(e) => this.onChange(e)}
                  disabled={isSavingMessage}
                />
              </Form>

              <Button
                primary
                style={{
                  marginLeft: 10,
                }}
                disabled={isSavingMessage || ((!newMessageText || newMessageText.length === 0) && !imageData)}
                onClick={() => this.saveNewMessage()}
                loading={isSavingMessage}
              >
                Skicka
              </Button>
            </div>
          }

          <div style={{ margin: 0, padding: 0, height: "0.1em" }} />
        </Segment>
      </React.Fragment>
    )
  }
};

export default withRouter(MenuMessages);
