import React, { Component } from "react";

import {
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import {
  Container, Segment,
} from "semantic-ui-react";

import { fetchWeatherData } from "@/helpers/weather";

import LoaderModal from "@/components/modals/LoaderModal";

import WeatherAccumulated from "@/components/weather/WeatherAccumulated";
import WeatherDaily from "@/components/weather/WeatherDaily";
import WeatherForecastWidgetHorizontal from "@/components/weather/WeatherForecastWidgetHorizontal";
import WeatherLast24Hours from '@/components/weather/WeatherLast24Hours';
import WeatherSeasonSummary from "@/components/weather/WeatherSeasonSummary";

const INITIAL_STATE = {
  temperatureData: null,
  rainfallData: null,
  irradiationData: null,
  activePlotItem: 'last24',
};

class WeatherPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    const {
      db,
      fileBucketRef,
    } = this.props;

    const weatherData = db && db.fieldsWeather;

    fetchWeatherData(weatherData, fileBucketRef, this.setParentState)
  };

  componentDidUpdate(prevProps) {
    const {
      db,
      fileBucketRef,
    } = this.props;

    const weatherData = db && db.fieldsWeather;

    if (!this.state.temperatureData && prevProps.db && (weatherData !== prevProps.db.fieldsWeather)) {
      fetchWeatherData(weatherData, fileBucketRef, this.setParentState)
    }
  };

  setParentState = (newState) => this.setState(newState);

  handleItemClick = (item) => this.setState({ activePlotItem: item });

  render() {
    const {
      temperatureData,
      rainfallData,
      irradiationData,
    } = this.state;

    const db = this.props.db;
    const fileBucketRef = this.props.fileBucketRef;
    const weatherData = db && db.fieldsWeather;

    let weatherStation = db && db.settings && db.settings.weather && db.settings.weather.name;
    let weatherStationDist = db && db.settings && db.settings.weather && db.settings.weather.dist;

    let hasLoadedData = isLoaded(db) && !isEmpty(db) && weatherData;

    if (hasLoadedData) {
      return (
        <Container
          style={{
            paddingTop: 50,
            backgroundColor: "#f7f4f0",
            overflow: "hidden",
            width: "100vw",
          }}
        >
          <div
            style={{
              padding: 20,
              paddingTop: 0,
              marginLeft: "5px",
              marginRight: "15px",     
            }}
          >
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "space-between",         
              }}
            >
              <div 
                style={{ 
                  width: "30%",
                  height: "100%",
                }}
              >
                <Segment>
                  <p style={{ color: "grey", marginBottom: 0 }}>Väderstation</p>
                  <p style={{ fontWeight: 400, fontSize: "130%", marginTop: 0 }}>
                    {weatherStation && weatherStation}
                    <span style={{ color: "grey", marginLeft: 5, marginRight: 5 }}>&middot;</span>
                    {weatherStationDist && weatherStationDist.toFixed(1)} km från gården.
                  </p>        
                </Segment>

                <WeatherForecastWidgetHorizontal db={db} />

                <WeatherSeasonSummary
                  fileBucketRef={fileBucketRef}
                  weatherData={weatherData}
                  layer="greenmass"
                />                
              </div>

              <div 
                style={{ 
                  width: "68%",
                }}
              >
                <WeatherLast24Hours db={db} />

                <WeatherDaily
                  temperatureData={temperatureData}
                  rainfallData={rainfallData}
                  irradiationData={irradiationData}
                />            

                <WeatherAccumulated
                  temperatureData={temperatureData}
                  rainfallData={rainfallData}
                  irradiationData={irradiationData}
                />                    
              </div>
            </div>
          </div>
        </Container>
      );
    } else {
      return (
        <LoaderModal caption="Hämtar data..." />
      );
    }
  }
}

export default WeatherPage;
